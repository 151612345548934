import React, { useState, useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  insertCFEI,
  updateCFEI,
  deleteCFEI,
} from '../redux/actions/DocumentControllers/documentControllerActions'
import { useToast } from 'rc-toastr'

const CreateCFEI = ({ show, onHide, appID, empID }) => {
  const dispatch = useDispatch()

  const [state, setState] = useState({
    id: '',
    appID: '',
    typeOfInstallation: '',
    Use: '',
    loadConnection: '',
    transformer: '',
    generator: '',
    other: '',
    empID: empID,
    isEditting: false,
    submitted: false,
  })

  const dc = useSelector((state) => state.documentControllerReducer)
  const { insertedCFEI, retrievedCFEI, updatedCFEI, deletedCFEI } = dc

  const { toast } = useToast()

  const handleChange = (e) => {
    setState({
      ...state,
      appID: appID,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(insertCFEI(state))
    onHide()
    setState({
      id: '',
      appID: '',
      typeOfInstallation: '',
      Use: '',
      loadConnection: '',
      transformer: '',
      generator: '',
      other: '',
      empID: empID,
      isEditting: false,
      submitted: true,
    })
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    dispatch(updateCFEI(state))
    onHide()
    setState({
      id: '',
      appID: '',
      typeOfInstallation: '',
      Use: '',
      loadConnection: '',
      transformer: '',
      generator: '',
      other: '',
      empID: empID,
      isEditting: false,
      submitted: true,
    })
  }

  const handleCancel = () => {
    onHide()
    setState({
      id: '',
      appID: '',
      typeOfInstallation: '',
      Use: '',
      loadConnection: '',
      transformer: '',
      generator: '',
      other: '',
      empID: empID,
      isEditting: false,
    })
  }

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete details ?')) {
      onHide()
      dispatch(deleteCFEI({ id: appID }))
      setState({
        id: '',
        appID: '',
        typeOfInstallation: '',
        Use: '',
        loadConnection: '',
        transformer: '',
        generator: '',
        other: '',
        empID: empID,
        isEditting: false,
        submitted: true,
      })
    }
  }

  useEffect(() => {
    if (retrievedCFEI && retrievedCFEI.message) {
      const {
        id,
        typeOfInstallation,
        typeOfUse,
        loadConnection,
        transformer,
        generator,
        other,
      } = retrievedCFEI.message[0]

      setState((state) => ({
        ...state,
        id: id,
        appID: appID,
        typeOfInstallation: typeOfInstallation,
        Use: typeOfUse,
        loadConnection: loadConnection,
        transformer: transformer,
        generator: generator,
        other: other,
        empID: empID,
        isEditting: true,
      }))
    }

    if (state.submitted && insertedCFEI && !insertedCFEI.loading) {
      if (insertedCFEI && insertedCFEI.error) {
        toast.error(insertedCFEI.error)
        setState((state) => ({
          ...state,
          submitted: false,
        }))
      } else {
        toast.success(insertedCFEI.message)
        setState((state) => ({
          ...state,
          submitted: false,
        }))
      }
    }

    if (state.submitted && updatedCFEI && !updatedCFEI.loading) {
      if (updatedCFEI && updatedCFEI.error) {
        toast.error(updatedCFEI.error)
        setState((state) => ({
          ...state,
          submitted: false,
        }))
      } else {
        toast.success(updatedCFEI.message)
        setState((state) => ({
          ...state,
          submitted: false,
        }))
      }
    }

    if (state.submitted && deletedCFEI && !deletedCFEI.loading) {
      if (deletedCFEI && deletedCFEI.error) {
        toast.error(deletedCFEI.error)
        setState((state) => ({
          ...state,
          submitted: false,
        }))
      } else {
        toast.success(deletedCFEI.message)
        setState((state) => ({
          ...state,
          submitted: false,
        }))
      }
    }
  }, [insertedCFEI, retrievedCFEI, updatedCFEI, deletedCFEI])

  return (
    <Modal size='md' show={show} onHide={onHide} centered backdrop='static'>
      <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
        <Modal.Title>
          <h6 style={{ color: '#FFF', marginTop: '5px' }}>
            Certificate of Final Electrical Inspection / Completion
          </h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Label className='mt-1'>Type of Installation</Form.Label>
          <Form.Control
            as='select'
            size='sm'
            name='typeOfInstallation'
            onChange={handleChange}
            value={state.typeOfInstallation}
            required
          >
            <option>Choose here ...</option>
            <option>RESIDENT DWELLING</option>
            <option>RESIDENTIAL, HOTEL, APARTMENT</option>
            <option>EDUCATION & RECREATION</option>
            <option>INDUSTRIAL</option>
            <option>BUSINESS & MERCANTILE</option>
            <option>INDUSTRIAL</option>
            <option>STORAGE & HAZARDOUS</option>
            <option>ASSEMBLY OTHER THAN GROUP I</option>
            <option>ASSEMBLY OCCUPANT LOAD 1000 OR MORE</option>
            <option>ACCESSORY</option>
            <option>OTHERS(SPECIFY)</option>
          </Form.Control>
          <Form.Label className='mt-1'>Type of Occupancy or Use</Form.Label>
          <Form.Control
            as='select'
            size='sm'
            name='Use'
            onChange={handleChange}
            value={state.Use}
            required
          >
            <option>Choose here ...</option>
            <option>TEMPORARY</option>
            <option>NEW</option>
            <option>REMODEL / ALTERATION</option>
          </Form.Control>
          <Form.Label className='mt-1 text-danger'>Electrical Load</Form.Label>
          <div className='mx-1'>
            <Form.Label>Load Connection (kVA)</Form.Label>
            <Form.Control
              type='text'
              size='sm'
              name='loadConnection'
              onChange={handleChange}
              value={state.loadConnection}
            />
            <Form.Label>Transformer (kVA)</Form.Label>
            <Form.Control
              type='text'
              size='sm'
              name='transformer'
              onChange={handleChange}
              value={state.transformer}
            />
            <Form.Label>Generator (kVA)</Form.Label>
            <Form.Control
              type='text'
              size='sm'
              name='generator'
              onChange={handleChange}
              value={state.generator}
            />
            <Form.Label>Others</Form.Label>
            <Form.Control
              type='text'
              size='sm'
              name='other'
              onChange={handleChange}
              value={state.other}
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {retrievedCFEI && retrievedCFEI.message && (
          <Button size='sm' variant='warning' onClick={handleDelete}>
            Delete
          </Button>
        )}
        <Button size='sm' variant='danger' onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          size='sm'
          variant='success'
          onClick={state.isEditting ? handleUpdate : handleSubmit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateCFEI
