import {
  MESSAGE_ADD_REQUEST,
  MESSAGE_ADD_SUCCESS,
  MESSAGE_ADD_FAILED,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILED,
  GET_MESSAGE_BYID_REQUEST,
  GET_MESSAGE_BYID_SUCCESS,
  GET_MESSAGE_BYID_FAILED,
} from '../constants/messageConstant'

export const messageReducer = (
  state = { messageList: { loading: true } },
  action
) => {
  switch (action.type) {
    case MESSAGE_ADD_REQUEST:
      return { ...state, insertedMessage: { loading: true } }
    case MESSAGE_ADD_SUCCESS:
      return {
        ...state,
        insertedMessage: { loading: false, message: action.payload },
      }
    case MESSAGE_ADD_FAILED:
      return {
        ...state,
        insertedMessage: { loading: false, error: action.payload },
      }
    case GET_MESSAGES_REQUEST:
      return { ...state, messageList: { loading: true } }
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messageList: { loading: false, message: action.payload },
      }
    case GET_MESSAGES_FAILED:
      return {
        ...state,
        messageList: { loading: false, error: action.payload },
      }
    case GET_MESSAGE_BYID_REQUEST:
      return { ...state, messageID: { loading: true } }
    case GET_MESSAGE_BYID_SUCCESS:
      return { ...state, messageID: { message: action.payload } }
    case GET_MESSAGE_BYID_FAILED:
      return { ...state, messageID: { error: action.payload } }
    default:
      return state
  }
}
