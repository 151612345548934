import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_FORRECOMMENDATION_LIST_REQ,
  GET_FORRECOMMENDATION_LIST_SUCCESS,
  GET_FORRECOMMENDATION_LIST_FAILED,
  GET_RECOMMENDED_LIST_REQ,
  GET_RECOMMENDED_LIST_SUCCESS,
  GET_RECOMMENDED_LIST_FAILED,
  GET_APPLICATION_LIST_REQ,
  GET_APPLICATION_LIST_SUCCESS,
  GET_APPLICATION_LIST_FAILED,
  INSERT_ICOMMENT_REQ,
  INSERT_ICOMMENT_SUCCESS,
  INSERT_ICOMMENT_FAILED,
  INSERT_ECOMMENT_REQ,
  INSERT_ECOMMENT_SUCCESS,
  INSERT_ECOMMENT_FAILED,
  UPDATE_APPLICATION_STATUS_REQ,
  UPDATE_APPLICATION_STATUS_SUCCESS,
  UPDATE_APPLICATION_STATUS_FAILED,
  GET_RETURNED_LIST_REQ,
  GET_RETURNED_LIST_SUCCESS,
  GET_RETURNED_LIST_FAILED,
  GET_COMMENTS_REQ,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAILED,
  GET_ORDEROFPAYMENT_REQ,
  GET_ORDEROFPAYMENT_SUCCESS,
  GET_ORDEROFPAYMENT_FAILED,
  INSERT_ORDEROFPAYMENT_REQ,
  INSERT_ORDEROFPAYMENT_SUCCESS,
  INSERT_ORDEROFPAYMENT_FAILED,
} from '../../constants/Recommender/recommenderConstants'

export const retrieveForRecommendationList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FORRECOMMENDATION_LIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/recommender/for-recommendation-list`,
        config
      )

      dispatch({
        type: GET_FORRECOMMENDATION_LIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_FORRECOMMENDATION_LIST_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveRecommendedList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_RECOMMENDED_LIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/recommender/recommended-list`,
        config
      )

      dispatch({
        type: GET_RECOMMENDED_LIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_RECOMMENDED_LIST_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveApplicationList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APPLICATION_LIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/recommender/application-list`,
        config
      )

      dispatch({
        type: GET_APPLICATION_LIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_APPLICATION_LIST_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveReturnedList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_RETURNED_LIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/recommender/returned-list`,
        config
      )

      dispatch({
        type: GET_RETURNED_LIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_RETURNED_LIST_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveCommentList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COMMENTS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/recommender/comment-list/${payload.id}`,
        config
      )

      dispatch({
        type: GET_COMMENTS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_COMMENTS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveOrderofPayment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ORDEROFPAYMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/recommender/orderofpayment/${payload.id}`,
        config
      )

      // console.log(payload.id)

      dispatch({
        type: GET_ORDEROFPAYMENT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ORDEROFPAYMENT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const insertOrderofPayment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_ORDEROFPAYMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/recommender/orderofpayment/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_ORDEROFPAYMENT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_ORDEROFPAYMENT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const insertIComment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_ICOMMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/recommender/comment-inpector/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_ICOMMENT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_ICOMMENT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const insertEComment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_ECOMMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/recommender/comment-evaluator/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_ECOMMENT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_ECOMMENT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const updateApplicationStatus = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_APPLICATION_STATUS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/recommender/update-status/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_APPLICATION_STATUS_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveForRecommendationList())

      const textConfig = {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Basic bWlzQGxwLWJsZGdvLmNvbTpFbmdpbmVlcmluZzIwMjMh',
          'X-MEMS-API-ID': '45745164',
          'X-MEMS-API-KEY': 'd9b33ea724fb4e2f810232a406bb1062',
        },
      }

      const message =
        'The status of your application in Office of the Building Official in City Governement of Las Piñas has changed; Please login to your account at lp-bldgo.com website to check the application.'

      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/messaging`,
        params: {
          extensions: `destination=63${payload.mobileNo}&text=${message}`,
        },
        withCredentials: false,
      })
    } catch {
      dispatch({
        type: UPDATE_APPLICATION_STATUS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}
