import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
  Accordion,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  addAssessment,
  editAssessment,
  retrieveAssessmentDetails,
} from '../../redux/actions/Assessment/mechanicalAssessmentActions'
import { retrieveAppDetails } from '../../redux/actions/evaluatorActions'

const MechanicalAssessmentScreen = ({ history, match }) => {
  // Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const { getAppDetails } = evaluator

  const mechanicalAssessment = useSelector(
    (state) => state.mechanicalAssessment
  )

  const { assessmentDetails, createAssessment, updateAssessment } =
    mechanicalAssessment

  const [obj, setObj] = useState([
    {
      id_per_name: 1,
      name: 'Refrigeration',
      fee: 40,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 2,
      name: 'Ice Plants',
      fee: 60,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 3,
      name: 'Centralized',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 4,
      name: 'Window Type',
      fee: 60,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 5,
      name: 'Mechanical Vent',
      fee: 40,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 6,
      name: 'Escalator',
      fee: 10,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 7,
      name: 'Moving Walks',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 8,
      name: 'Funicular',
      fee: 200,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 9,
      name: 'Lineal Funicular',
      fee: 20,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 10,
      name: 'Cable Car',
      fee: 40,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 11,
      name: 'Lineal Cable',
      fee: 5,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 12,
      name: 'Dumbwaiters',
      fee: 600,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 13,
      name: 'Const Elev',
      fee: 2000,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 14,
      name: 'Passenger',
      fee: 5000,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 15,
      name: 'Freight',
      fee: 5000,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 16,
      name: 'Car Elev',
      fee: 5000,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 17,
      name: 'Range Boiler',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 18,
      name: 'Pressure Water',
      fee: 200,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 19,
      name: 'Water Sump',
      fee: 60,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 20,
      name: 'Auto Sprinkler',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 21,
      name: 'Diesel',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 22,
      name: 'Compressed Air',
      fee: 20,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 23,
      name: 'Gas Meter',
      fee: 100,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 24,
      name: 'Power Piping',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 25,
      name: 'Other Combust',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 26,
      name: 'Pressure Vessels',
      fee: 60,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 27,
      name: 'Other Machine',
      fee: 60,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 28,
      name: 'Pneumatic',
      fee: 10,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 29,
      name: 'Weigh Scale',
      fee: 50,
      units: 0,
      estimate: 0,
    },
  ])

  const [state, setState] = useState([])

  const [grandTotal, setGrandTotal] = useState(0)

  const [modal, setModal] = useState({
    successModal: false,
    updateModal: false,
  })

  const total = obj.reduce(
    (prevData, currData) => prevData + currData.estimate,
    0
  )

  const filtered = obj.filter((e) => e.estimate > 0)

  const isUpdating = JSON.parse(localStorage.getItem('assessmentSelected'))
    ? JSON.parse(localStorage.getItem('assessmentSelected'))
    : null
  // End Declaration

  //   Functions
  //CAT A
  const handleRangeDivA1 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 100:
        tempEstimate = e.target.value * 90
        break
      case e.target.value > 100:
        tempEstimate = 40 * (e.target.value - 100) + 9000
        break
      default:
        tempEstimate = e.target.value * 90
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT B
  const handleRangeDivA2 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 20:
        tempEstimate = e.target.value * 20
        break
      case e.target.value > 20:
        tempEstimate = 10 * (e.target.value - 20) + 400
        break
      default:
        tempEstimate = e.target.value * 20
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT D
  const handleRangeDivA3 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 7.5:
        tempEstimate = e.target.value * 500
        break
      case e.target.value > 7.5 && e.target.value <= 22:
        tempEstimate = e.target.value * 700
        break
      case e.target.value > 22 && e.target.value <= 37:
        tempEstimate = e.target.value * 900
        break
      case e.target.value > 37 && e.target.value <= 52:
        tempEstimate = e.target.value * 1200
        break
      case e.target.value > 52 && e.target.value <= 67:
        tempEstimate = e.target.value * 1400
        break
      case e.target.value > 67 && e.target.value <= 74:
        tempEstimate = e.target.value * 1600
        break
      case e.target.value > 74:
        tempEstimate = e.target.value * 5
        break
      default:
        tempEstimate = e.target.value * 500
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT H
  const handleRangeDivA4 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 50:
        tempEstimate = e.target.value * 25
        break
      case e.target.value > 50 && e.target.value <= 100:
        tempEstimate = e.target.value * 20
        break
      case e.target.value > 100:
        tempEstimate = e.target.value * 3
        break
      default:
        tempEstimate = e.target.value * 25
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT L
  const handleRangeDivA5 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 50:
        tempEstimate = e.target.value * 10
        break
      case e.target.value > 50 && e.target.value <= 100:
        tempEstimate = e.target.value * 12
        break
      case e.target.value > 100:
        tempEstimate = e.target.value * 3
        break
      default:
        tempEstimate = e.target.value * 10
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleChange = (e) => {
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: e.target.value * el.fee,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleCreateSubmit = () => {
    setModal({ successModal: true })
    dispatch(
      addAssessment({
        grandTotal,
        assessments: state,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
      })
    )
  }

  const handleUpdateSubmit = () => {
    setModal({ updateModal: true })
    dispatch(
      editAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
      })
    )
  }

  const handleBtnCancel = () => {
    if (isUpdating !== null) {
      history.push('/assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    } else {
      history.push('/for-assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    }
  }
  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(
      retrieveAppDetails({ appID: match.params.id, userID: userCredentials.id })
    )

    isUpdating !== null &&
      dispatch(
        retrieveAssessmentDetails({
          evalAppID: isUpdating.evalAppID,
          userID: userCredentials.id,
        })
      )
  }, [dispatch, match, userCredentials])

  useEffect(() => {
    setGrandTotal(() => total)

    setState(() => filtered)
  }, [total])

  useEffect(() => {
    if (isUpdating !== null) {
      if (assessmentDetails && assessmentDetails.message) {
        assessmentDetails.message.map((submitted) => {
          let temp = obj
          let index = obj.findIndex(
            (el) => el.id_per_name === submitted.id_per_name
          )
          temp[index].units = submitted.units
          temp[index].estimate = submitted.estimate
          temp[index] = { ...temp[index], id: submitted.id }
          setObj(temp)
        })
        setGrandTotal(() => assessmentDetails.message[0].grandTotal)
      }
    }
  }, [assessmentDetails])

  return (
    <UserMain>
      <Container className='bg-light pt-3'>
        <>
          <h1 className='text-center mb-4'>MECHANICAL FEES</h1>
          <Row>
            <Col
              xl={12}
              className='mb-4 pt-3'
              style={{ background: '#f9f6f1' }}
            >
              <h6>Note: </h6>
              <p>Click category to be assessed.</p>
            </Col>
          </Row>

          <Form
            onSubmit={(e) => {
              e.preventDefault()

              isUpdating !== null ? handleUpdateSubmit() : handleCreateSubmit()
            }}
          >
            <Accordion className='mx-0'>
              {/* A. Refrigeration, Air Conditioning and Mechanical Ventilation */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='0'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  A. REFRIGERATION, AIR CONDITIONING AND MECHANICAL VENTILATION
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Refrigeration (cold storage), per ton or fraction
                            thereof
                          </td>
                          <td className='text-center'>₱40.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Refrigeration'
                              value={obj[0].units}
                              placeholder='i.'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Refrigeration')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Ice Plants, per ton or fraction thereof
                          </td>
                          <td className='text-center'>₱60.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Ice Plants'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[1].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Ice Plants')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Packaged/Centralized Air Conditioning Systems: Up to
                            100 tons, per ton
                          </td>
                          <td className='text-center'>₱90.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Centralized'
                              placeholder='iii.-iv.'
                              onChange={handleRangeDivA1}
                              value={obj[2].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Centralized')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Every ton or fraction thereof above 100 tons
                          </td>
                          <td className='text-center'>₱40.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Window type air conditioners, per unit
                          </td>
                          <td className='text-center'>₱60.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Window Type'
                              placeholder='v.'
                              onChange={handleChange}
                              value={obj[3].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Window Type')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Mechanical Ventilation, per KW or fraction thereof
                            of blower or fan, or metric equivalent
                          </td>
                          <td className='text-center'>₱40.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Mechanical Vent'
                              placeholder='vi.'
                              onChange={handleChange}
                              value={obj[4].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Mechanical Vent')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vii.</td>
                          <td className='text-wrap' colSpan={2}>
                            In a series of AC/REF systems located in one
                            establishment, the total installed tons of
                            refrigeration shall be used as the basis of
                            computation for purposes of installation/inspection
                            fees, and shall not be considered individually.
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Row>
                      <p className='fw-bold'>For Evaluation Purposes</p>
                      <p className='fw-bold'>
                        For Commercial/Industrial Refrigeration without Ice
                        Making (refer to 5.a.i.):
                      </p>
                      <p>
                        1.10 kW per ton, for compressors up to 5 tons capacity.{' '}
                        <br />
                        1.00 kW per ton, for compressors above 5 tons up to 50
                        tons capacity.
                        <br />
                        0.97 kW per ton, for compressors above 50 tons capacity.
                        <br />
                        <br />
                        <p className='fw-bold'>
                          For Ice making (refer to 5.a.ii.):
                        </p>
                        3.50 kW per ton, for compressors up to 50 tons capacity.{' '}
                        <br />
                        3.25 kW per ton, for compressors above 5 up to 50 tons
                        capacity.
                        <br />
                        3.00 kW per ton, for compressors above 50 tons capacity.
                        <br />
                        <br />
                        <p className='fw-bold'>
                          For Air conditioning (refer to 5.a.iii.):
                        </p>
                        0.90 kW per ton, for compressors 1.2 to 5 tons capacity.{' '}
                        <br />
                        0.80 kW per ton, for above 5 up to 50 tons capacity.{' '}
                        <br />
                        0.70 kW per ton, for compressors above 50 tons capacity.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End A */}

              {/* B. Escalators and Moving Walks, funiculars and the likes */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='1'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  B. ESCALATORS AND MOVING WALKS, FUNICULARS AND LIKES
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='1'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Escalator and moving walk, per kW or fraction
                            thereof
                          </td>
                          <td className='text-center'>₱10.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Escalator'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[5].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Escalator')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Escalator and moving walks up to 20.00 lineal meters
                            of fraction thereof
                          </td>
                          <td className='text-center'>₱20.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Moving Walks'
                              placeholder='ii.-iii.'
                              onChange={handleRangeDivA2}
                              value={obj[6].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Moving Walks')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Every lineal meter or fraction thereof in excess of
                            20.00 lineal meters
                          </td>
                          <td className='text-center'>₱10.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Funicular, per kW or fraction thereof
                          </td>
                          <td className='text-center'>₱200.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Funicular'
                              placeholder='iv.'
                              onChange={handleChange}
                              value={obj[7].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Funicular')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (a) Per lineal meter travel
                          </td>
                          <td className='text-center'>₱20.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Lineal Funicular'
                              placeholder='a.'
                              onChange={handleChange}
                              value={obj[8].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Lineal Funicular')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Cable car, per kW or fraction thereof
                          </td>
                          <td className='text-center'>₱40.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Cable Car'
                              placeholder='v.'
                              onChange={handleChange}
                              value={obj[9].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Cable Car')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (a) Per lineal meter travel
                          </td>
                          <td className='text-center'>₱5.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Lineal Cable'
                              placeholder='a.'
                              onChange={handleChange}
                              value={obj[10].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Lineal Cable')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End B */}

              {/* C. Elevators, per unit*/}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='2'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  C. ELEVATORS, PER UNIT
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='2'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Motor driven dumbwaiters
                          </td>
                          <td className='text-center'>₱600.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Dumbwaiters'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[11].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Dumbwaiters')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Construction elevators for material
                          </td>
                          <td className='text-center'>₱2,000.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Const Elev'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[12].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Const Elev')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Passenger elevators
                          </td>
                          <td className='text-center'>₱5,000.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Passenger'
                              placeholder='iii.'
                              onChange={handleChange}
                              value={obj[13].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Passenger')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Freight Elevators
                          </td>
                          <td className='text-center'>₱5,000.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Freight'
                              placeholder='iv.'
                              onChange={handleChange}
                              value={obj[14].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {obj.find((el) => el.name === 'Freight').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Car elevators
                          </td>
                          <td className='text-center'>₱5,000.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Car Elev'
                              placeholder='v.'
                              onChange={handleChange}
                              value={obj[15].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Car Elev')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End C*/}

              {/* D. Boilers, per kW */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='3'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  D. BOILERS, PER kW
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='3'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Up to 7.5kW
                          </td>
                          <td className='text-center'>₱500.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Range Boiler'
                              placeholder='i.-vii.'
                              onChange={handleRangeDivA3}
                              value={obj[16].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Range Boiler')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 7.5kW to 22kW
                          </td>
                          <td className='text-center'>₱700.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 22kW to 37kW
                          </td>
                          <td className='text-center'>₱900.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 37kW to 52kW
                          </td>
                          <td className='text-center'>₱1,200.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 52kW to 67kW
                          </td>
                          <td className='text-center'>₱1,400.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 67kW to 74kW
                          </td>
                          <td className='text-center'>₱1,600.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Every kW or fraction thereof above 74kW
                          </td>
                          <td className='text-center'>₱5.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (b) Steam from this boiler used to propel any
                            prime-mover is exempted from fees.
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (c) Steam engines/turbines/etc. propelled from
                            geothermal source will use the same schedule of fees
                            above.
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End D */}

              {/* E. Pressurized Water Heaters */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='4'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  E. PRESSURERIZED WATER HEATERS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>
                            Pressurized water heaters, per unit
                          </td>
                          <td className='text-center align-middle'>₱200.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='number'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Pressure Water'
                                placeholder='Unit'
                                onChange={handleChange}
                                value={obj[17].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Pressure Water')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End E. */}

              {/* F. Water, sump and sewage pumps for commercial/industrial use */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='5'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  F. WATER, SUMP AND SEWAGE PUMPS FOR COMMERCIAL/INDUSTRIAL USE
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='5'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>
                            Water, sump and sewage pumps for
                            commercial/industrial use, per kW or fraction
                            thereof
                          </td>
                          <td className='text-center align-middle'>₱60.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='number'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Water Sump'
                                placeholder='Unit'
                                onChange={handleChange}
                                value={obj[18].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Water Sump')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End F. */}

              {/* G. Automatic Fire Sprinkler System */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='6'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  G. AUTOMATIC FIRE SPRINKLER SYSTEM
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='6'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>
                            Automatic fire sprinkler system, per sprinkler head
                          </td>
                          <td className='text-center align-middle'>₱4.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='number'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Auto Sprinkler'
                                placeholder='Unit'
                                onChange={handleChange}
                                value={obj[19].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Auto Sprinkler')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End G. */}

              {/* H. Diesel/Gasoline ICE, Steam, Gas Turbine/Engine, Hydro, Nuclear or solar Generating Units and the like */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='7'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  H. DIESEL/GASOLINE ICE, STEAM, GAS TURBINE/ENGINE, HYDRO
                  NUCLEAR OR SOLAR GENERATING UNITS AND THE LIKE
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='7'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>Every kW up to 50kW</td>
                          <td className='text-center align-middle'>₱25.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='number'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Diesel'
                                placeholder='i.-iii.'
                                onChange={handleRangeDivA4}
                                value={obj[20].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Diesel').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='align-middle'>
                            Above 50kW up to 100kW
                          </td>
                          <td className='text-center align-middle'>₱20.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='align-middle'>Every kW above 100kW</td>
                          <td className='text-center align-middle'>₱3.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End H. */}

              {/* I. Compressed Air, Vacuum, Commercial, Institutional and/or Industrial Gases */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='8'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  I. COMPRESSED AIR, VACCUM, COMMERCIAL, INSTITUTIONAL AND/OR
                  INDUSTRIAL GASES
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='8'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>Every kW up to 50kW</td>
                          <td className='text-center align-middle'>₱25.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='number'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Diesel'
                                placeholder='i.-iii.'
                                onChange={handleRangeDivA4}
                                value={obj[21].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Diesel').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='align-middle'>
                            Above 50kW up to 100kW
                          </td>
                          <td className='text-center align-middle'>₱20.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='align-middle'>Every kW above 100kW</td>
                          <td className='text-center align-middle'>₱3.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End I. */}

              {/* J. Gas Meter */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='9'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  J. GAS METER
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='9'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>Gas Meter, per unit</td>
                          <td className='text-center align-middle'>₱100.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='number'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Gas Meter'
                                placeholder='Unit'
                                onChange={handleChange}
                                value={obj[22].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Gas Meter')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End J. */}

              {/* K. Power piping for gas/steam/etc. */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='10'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  K. POWER PIPING FOR GAS/STEAM/ETC.
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='10'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>
                            Power piping for gas/steam/etc., per lineal meter or
                            fraction thereof or per cu. meter or fraction
                            thereof whichever is higher
                          </td>
                          <td className='text-center align-middle'>₱4.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='text'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Power Piping'
                                placeholder='Unit'
                                onChange={handleChange}
                                value={obj[23].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Power Piping')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End K. */}

              {/* L. Other Internal Combustion Engines, including cranes, forklifts, loaders, pumps, mixers, compressors and the like, not registered with the LTO  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='11'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  L. OTHER INTERNAL COMBUSTION ENGINES, INCLUDING CRANES,
                  FORKLIFTS, LOADERS, PUMPS, MIXERS, COMPRESSORS, AND THE LIKES,
                  NOT REGISTERED WITH THE LTO
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='11'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>Up to 50kW</td>
                          <td className='text-center align-middle'>₱10.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='text'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Other Combust'
                                placeholder='i.-iii.'
                                onChange={handleRangeDivA5}
                                value={obj[24].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Other Combust')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='align-middle'>
                            Above 50kW up to 100kW
                          </td>
                          <td className='text-center align-middle'>₱12.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='align-middle'>
                            Every above 100kW or fraction thereof
                          </td>
                          <td className='text-center align-middle'>₱3.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End L. */}

              {/* M. Pressure Vessels*/}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='12'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  M. PRESSURE VESSELS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='12'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>
                            Pressure Vessels, per cu. meter or fraction thereof
                          </td>
                          <td className='text-center align-middle'>₱60.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='number'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Pressure Vessels'
                                placeholder='Unit'
                                onChange={handleChange}
                                value={obj[25].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Pressure Vessels')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End M. */}

              {/*N. Other Machinery/Equipment for commercial/industrial/institutional use not elsewhere specified */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='13'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  N. OTHER MACHINERY/EQUIPMENT FOR
                  COMMERCIAL/INDUSTRIAL/INSTITUTIONAL USE NOT ELSEWHERE
                  SPECIFIED
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='13'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>
                            Other Machinery/Equipment for
                            commercial/industrial/institutional use not
                            elsewhere specified, per kW or fraction thereof
                          </td>
                          <td className='text-center align-middle'>₱60.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='number'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Other Machine'
                                placeholder='Unit'
                                onChange={handleChange}
                                value={obj[26].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Other Machine')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End N. */}

              {/*O. Pneumatic tubes, conveyors, monorails for materials handling and addition to existing supply and/or exhaust duct works and the like */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='14'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  O. PNEUMATIC TUBES, CONVEYORS, MONORAILS FOR MATERIALS
                  HANDLING AND ADDITION TO EXISTING SUPPLY AND/OR EXHAUST DUCT
                  WORKS AND THE LIKE
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='14'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>
                            Pneumatic tubes, conveyors, monorails for materials
                            handling and addition to existing supply and/or
                            exhaust duct works and the like, per lineal meters
                            or fraction
                          </td>
                          <td className='text-center align-middle'>₱10.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='number'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Pneumatic'
                                placeholder='Unit'
                                onChange={handleChange}
                                value={obj[27].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Pneumatic')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End 0. */}

              {/*P. Weighing Scale Structure  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='15'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  P. WEIGHING SCALE STRUCTURE
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='15'>
                  <Card.Body className='accordion-description'>
                    <Table responsive>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>Particulars</th>
                          <th>Fees</th>
                          <th>Unit</th>
                          <th>Estimated Fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>
                            Weighing Scale Structure, per ton or fraction
                            thereof
                          </td>
                          <td className='text-center align-middle'>₱50.00</td>
                          <td className='text-center align-middle'>
                            <Col xl={12}>
                              <Form.Control
                                type='number'
                                className='border border-secondary'
                                style={{
                                  marginLeft: '8px',
                                  marginRight: '5px',
                                }}
                                name='Weigh Scale'
                                placeholder='Unit'
                                onChange={handleChange}
                                value={obj[28].units}
                                onKeyDown={(e) =>
                                  e.target.value.length > 14 &&
                                  e.preventDefault()
                                }
                                min={0}
                              />
                            </Col>
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Weigh Scale')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <h6>NOTE:</h6>
                    <p>
                      Transfer of machine/equipment location within a building
                      requires a mechanical permit and payment of fees.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End P. */}
            </Accordion>
            <Row className='mt-3 mx-1'>
              <Col xl={8} className='text-end align-middle mt-2'>
                <h4>GRAND TOTAL:</h4>
              </Col>
              <Col xl={4} className='mb-5'>
                <Form.Control
                  type='text'
                  className='border border-secondary'
                  style={{
                    marginLeft: '8px',
                    marginRight: '5px',
                  }}
                  name='GrandTotal'
                  value={grandTotal}
                  placeholder='Grand Total'
                  disabled
                />
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col xl={12} className='text-end'>
                <Button type='submit' variant='success'>
                  Submit
                </Button>
                <Button
                  variant='danger'
                  className='mx-2'
                  onClick={handleBtnCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      </Container>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={createAssessment && createAssessment.loading}
        success={createAssessment && createAssessment.message}
        error={createAssessment && createAssessment.error}
      />
      {/* End Success Modal */}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateModal}
        onHide={() => {
          setModal({ updateModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={updateAssessment && updateAssessment.loading}
        success={updateAssessment && updateAssessment.message}
        error={updateAssessment && updateAssessment.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(MechanicalAssessmentScreen)
