export const electronicsData = [
  {
    id: 1,
    code_header: 'PART 1',
    header: 'Telecommunications Facilities Distribution System',
    code_id: 'Chapter 3',
    code_name: 'Structured Cabling System',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 2,
    code_header: 'PART 1',
    header: 'Telecommunications Facilities Distribution System',
    code_id: 'Chapter 4',
    code_name: 'Telecommunications Pathways and Spaces',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 3,
    code_header: 'PART 1',
    header: 'Telecommunications Facilities Distribution System',
    code_id: 'Chapter 5',
    code_name: 'Grounding and Bonding',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 4,
    code_header: 'PART 1',
    header: 'Telecommunications Facilities Distribution System',
    code_id: 'Chapter 6',
    code_name: 'Administration',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 5,
    code_header: 'PART 1',
    header: 'Telecommunications Facilities Distribution System',
    code_id: 'Chapter 7',
    code_name: 'Installation Guidelines',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 6,
    code_header: 'PART 1',
    header: 'Telecommunications Facilities Distribution System',
    code_id: 'Chapter 8',
    code_name: 'Field Testing',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 7,
    code_header: 'PART 2',
    header: 'Fire and Detection Alarm System',
    code_id: 'Chapter 1',
    code_name: 'Scope and Fundamentals',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 8,
    code_header: 'PART 2',
    header: 'Fire and Detection Alarm System',
    code_id: 'Chapter 2',
    code_name: 'Design Requirements',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 9,
    code_header: 'PART 2',
    header: 'Fire and Detection Alarm System',
    code_id: 'Chapter 3',
    code_name: 'Installation Requirements',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 10,
    code_header: 'PART 2',
    header: 'Fire and Detection Alarm System',
    code_id: 'Chapter 4',
    code_name: 'Maintenance, testing and Inspection',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 11,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-1',
    code_name: 'General Provisions',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 12,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-2',
    code_name: 'Service Entrance Facility',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 13,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-3',
    code_name: 'Backbone Distribution System',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 14,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-4',
    code_name: 'Horizontal Distribution System',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 15,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-5',
    code_name: 'Work Area',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 16,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-6',
    code_name: 'Outside Plant System',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 17,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-7',
    code_name: 'Headend (Core) System',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 18,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-8',
    code_name: 'Technical Operating Parameters',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 19,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-9',
    code_name: 'Signal Leakage',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 20,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-10',
    code_name: 'Grounding and Bonding',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 21,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-11',
    code_name: 'Cables and Connectors',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 22,
    code_header: 'PART 3',
    header: 'Cable Television System',
    code_id: 'Chapter 3-12',
    code_name: 'Testing',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 23,
    code_header: 'PART 4',
    header: 'Distributed Antenna System (DAS)',
    code_id: 'Chapter 4-1',
    code_name: 'Distributed Antenna System',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 24,
    code_header: 'PART 4',
    header: 'Distributed Antenna System (DAS)',
    code_id: 'Chapter 4-2',
    code_name: 'The RF Material Specifications',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 25,
    code_header: 'PART 4',
    header: 'Distributed Antenna System (DAS)',
    code_id: 'Chapter 4-3',
    code_name: 'The RF DAS Design Criteria',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 26,
    code_header: 'PART 4',
    header: 'Distributed Antenna System (DAS)',
    code_id: 'Chapter 4-4',
    code_name: 'The DAS Installation',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 27,
    code_header: 'PART 4',
    header: 'Distributed Antenna System (DAS)',
    code_id: 'Chapter 4-5',
    code_name: 'The DAS Technical Standards',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 28,
    code_header: 'PART 4',
    header: 'Distributed Antenna System (DAS)',
    code_id: 'Chapter 4-6',
    code_name: 'DAS Physical and Logical Installation and Quality Acceptance',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 29,
    code_header: 'PART 4',
    header: 'Distributed Antenna System (DAS)',
    code_id: '302.10.a-g',
    code_name:
      'Required Electronics Drawings: 1. Electronics plan/s Layouts, same scale as architectural floor plans, showing: Data and Voice, PABX, FDAS, CCTV, CATV, etc. 2. Isometric Diagrams of the Systems 3. Schedule of Equipment 4. General Notes and Specifications 5. Single Line Diagram; Riser Diagram',
    compliance: '',
    id_remarks: '',
  },
]
