import React, { useState, useEffect } from 'react'
import {
  Container,
  Col,
  Row,
  Form,
  Button,
  Table,
  Pagination,
  Modal,
  InputGroup,
  Card,
  Spinner,
} from 'react-bootstrap'
import { FaCheckCircle, FaSearch } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

// Components
import UserMain from '../layout/UserLayout/UserMain'

// Actions
import {
  userRetrieveApplicationHistory,
  RetrieveStatusTrack,
} from '../redux/actions/userApplicationActions'

const UserApplicationHistory = () => {
  const users = useSelector((state) => state.users)
  const { userCredentials } = users

  const [InitialState] = useState({
    userID: userCredentials.id,
  })

  const [appList, setAppList] = useState([])

  const dispatch = useDispatch()
  const applications = useSelector((state) => state.applications)
  const { appHistory, statusTracking } = applications

  useEffect(() => {
    dispatch(userRetrieveApplicationHistory(InitialState))
  }, [InitialState, dispatch])

  useEffect(() => {
    if (appHistory && !appHistory.loading) {
      setAppList(appHistory.applicationHistory)
    }
  }, [appHistory])

  // Pagination Area Forms
  const [dfpaginatedForms, setdfpaginatedForms] = useState([])
  const [dfcurrentPage, setdfcurrentPage] = useState(1)

  const [dfpageNumberLimit] = useState(5)
  const [maxdfPageNumberLimit, setmaxdfPageNumberLimit] = useState(5)
  const [mindfPageNumberLimit, setmindfPageNumberLimit] = useState(0)

  const dfpageSize = 5

  useEffect(() => {
    setdfpaginatedForms(_(_.reverse(appList)).slice(0).take(dfpageSize).value())
    setdfcurrentPage(1)
  }, [appList])

  const dfpageCount = appList ? Math.ceil(appList.length / dfpageSize) : 0

  const dfpages = _.range(1, dfpageCount + 1)

  const dfpagination = (pageNo) => {
    setdfcurrentPage(pageNo)
    const dfstartIndex = (pageNo - 1) * dfpageSize
    const dfpaginationForms = _(appList)
      .slice(dfstartIndex)
      .take(dfpageSize)
      .value()
    setdfpaginatedForms(dfpaginationForms)
  }

  const dfrenderPageNumber = dfpages.map((page) => {
    if (page < maxdfPageNumberLimit + 1 && page > mindfPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => dfpagination(page)}
          active={page === dfcurrentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const dfhandleNextbtn = () => {
    setdfcurrentPage(dfcurrentPage + 1)
    dfpagination(dfcurrentPage + 1)

    if (dfcurrentPage + 1 > maxdfPageNumberLimit) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit + dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit + dfpageNumberLimit)
    }
  }

  const dfhandlePrevbtn = () => {
    setdfcurrentPage(dfcurrentPage - 1)
    dfpagination(dfcurrentPage - 1)

    if ((dfcurrentPage - 1) % dfpageNumberLimit === 0) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit - dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit - dfpageNumberLimit)
    }
  }
  // End Pagination Area Forms

  const [show, setShow] = useState(false)

  const [appTrack, setAppTrack] = useState(false)

  const [forUpdate, setForUpdate] = useState({
    Application_ID: '',
    ApplicationName: '',
    Link: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Street: '',
    Area: '',
    Barangay: '',
    ScopeOfWork: '',
    UseType: '',
    Storey: '',
    Unit: '',
    FloorArea: '',
    EstimateCost: '',
    Structural: 0,
    Mechanical: 0,
    Architectural: 0,
    Sanitary: 0,
    Electrical: 0,
    Plumbing: 0,
    Electronics: 0,
  })

  const handleShow = (payload) => {
    setShow(true)
    setForUpdate({
      ...forUpdate,
      Application_ID: payload.appID,
      ApplicationName: payload.ApplicationName,
      Link: payload.Link,
      FirstName: payload.FirstName,
      MiddleName: payload.MiddleName,
      LastName: payload.LastName,
      Street: payload.Street,
      Area: payload.Area,
      Barangay: payload.Barangay,
      ScopeOfWork: payload.ScopeOfWork,
      UseType: payload.UseType,
      Storey: payload.Storey,
      Unit: payload.Unit,
      FloorArea: payload.FloorArea,
      EstimateCost: payload.EstimateCost,
      Structural: payload.Structural,
      Mechanical: payload.Mechanical,
      Architectural: payload.Architectural,
      Sanitary: payload.Sanitary,
      Electrical: payload.Electrical,
      Plumbing: payload.Plumbing,
      Electronics: payload.Electronics,
    })
  }

  const [searchTerm, setSearchTerm] = useState('')

  return (
    <UserMain>
      <Container fluid>
        {appHistory && !appHistory.loading ? (
          appHistory.error ? (
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <h5>{appHistory.error}</h5>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <>
              <Form>
                <Row className='justify-content-end px-3 pt-5'>
                  <Col md={8}></Col>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              {/* For Application Status Table */}
              <Row className='my-4 mx-3 shadow-lg'>
                <Col
                  xl={12}
                  className='border border-secondary rounded px-0'
                  style={{ background: '#FFF' }}
                >
                  <h5 className='mx-3 pt-4 pb-2'>Application Status</h5>
                  <Table bordered hover responsive size='sm'>
                    <thead className='bg-success text-light'>
                      <tr>
                        <th>Application No.</th>
                        <th>Application</th>
                        <th>Trades</th>
                        <th>Status</th>
                        <th>Date Transferred</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dfpaginatedForms
                        .filter((value) => {
                          if (searchTerm === '') {
                            return value
                          } else if (
                            value.ApplicationName.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          ) {
                            return value
                          } else {
                            return value.appID === parseInt(searchTerm)
                          }
                        })
                        .map((x, index) => (
                          <tr key={index}>
                            <td className='text-center'>{x.appID}</td>
                            <td>{x.ApplicationName}</td>
                            <td>{x.trades}</td>
                            <td>{x.status}</td>
                            <td>{x.ApplicationName}</td>
                            <td>
                              <div className='d-flex justify-content-center'>
                                <Button
                                  size='sm'
                                  variant='success'
                                  className='text-nowrap'
                                  onClick={() => handleShow(x)}
                                >
                                  View
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <hr />
                  <Pagination className='d-flex justify-content-end mx-2'>
                    <Pagination.Item
                      className='mx-1 mt-0 fs-6 text-nowrap'
                      onClick={dfhandlePrevbtn}
                      disabled={dfcurrentPage === dfpages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {dfrenderPageNumber}

                    <Pagination.Item
                      className='mx-1 mt-0 fs-6 text-nowrap'
                      onClick={dfhandleNextbtn}
                      disabled={
                        dfcurrentPage === dfpages[dfpages.length - 1]
                          ? true
                          : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </>
          )
        ) : (
          <Row className='my-4 mx-1'>
            <Col xl={12}>
              <Card>
                <Card.Body className='mt-2'>
                  <div className='text-center pt-3'>
                    <Spinner animation='border' />
                    <h3>Loading...</h3>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>

      {/* View Application */}
      <Modal
        size='xl'
        show={show}
        onHide={() => setShow(false)}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> View Records
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-end'>
            <Col md={4} className='text-end'>
              {appTrack ? (
                <Button
                  variant='warning'
                  className='text-light'
                  onClick={() => setAppTrack(false)}
                >
                  Application Summary
                </Button>
              ) : (
                <Button
                  variant='warning'
                  className='text-light'
                  onClick={() => {
                    setAppTrack(true)
                    dispatch(
                      RetrieveStatusTrack({
                        appID: forUpdate.Application_ID,
                        userID: userCredentials.id,
                      })
                    )
                  }}
                >
                  Track Application
                </Button>
              )}
            </Col>
          </Row>
          {!appTrack ? (
            <>
              <Form>
                <Row>
                  <Col md={12}>
                    {forUpdate.ApplicationName ===
                      'BUILDING CONSTRUCTION PERMIT' && (
                      <>
                        <h5>ATTACHED PERMIT TRADES</h5>
                        <p>Click the trades that include to your files</p>
                        <Row>
                          {_.zip(
                            [
                              'STRUCTURAL',
                              'MECHANICAL',
                              'ARCHITECTURAL',
                              'SANITARY',
                              'ELECTRICAL',
                              'PLUMBING',
                              'ELECTRONICS',
                            ],
                            [
                              'Structural',
                              'Mechanical',
                              'Architectural',
                              'Sanitary',
                              'Electrical',
                              'Plumbing',
                              'Electronics',
                            ],
                            [
                              forUpdate.Structural,
                              forUpdate.Mechanical,
                              forUpdate.Architectural,
                              forUpdate.Sanitary,
                              forUpdate.Electrical,
                              forUpdate.Plumbing,
                              forUpdate.Electronics,
                            ]
                          ).map((trades) => (
                            <Form.Group
                              className='my-2'
                              as={Col}
                              md={3}
                              key={trades[0]}
                            >
                              <Form.Check
                                type='checkbox'
                                label={trades[0]}
                                name={trades[1]}
                                checked={trades[2] === 1 ? true : false}
                                disabled={true}
                              />
                            </Form.Group>
                          ))}
                        </Row>
                        <hr />
                      </>
                    )}

                    <h5>Link</h5>
                    <Row>
                      <Form.Group as={Col} md={12}>
                        <Form.Control
                          type='text'
                          className='mt-2'
                          name='Link'
                          value={forUpdate.Link}
                          style={{
                            background: '#FFF',
                            border: '1px solid #ced4da',
                          }}
                          disabled={true}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </>
          ) : (
            <>
              <Row className='py-3 text-center'>
                <hr />
                <Col md={1}></Col>
                <Col md={2}>Date</Col>
                <Col md={3}>Status</Col>
                <Col md={3}>Remarks</Col>
                <Col md={3}>Trade</Col>
              </Row>
              {statusTracking && statusTracking.message ? (
                <>
                  {_.reverse(
                    statusTracking.message.map((track, index) => (
                      <div key={index}>
                        <Row className='py-3 text-center'>
                          <Col md={1} className='text-danger fw-bold'>
                            {index + 1}.
                          </Col>
                          <Col md={2}>{track.TimeStamp}</Col>
                          <Col md={3}>{track.status}</Col>
                          <Col md={3}>{track.remarks}</Col>
                          <Col md={3}>{track.trades}</Col>
                        </Row>
                        <hr className='m-0 bg-success' />
                      </div>
                    ))
                  )}
                </>
              ) : (
                <Row className='my-4 mx-1'>
                  <Col xl={12}>
                    <Card>
                      <Card.Body className='mt-2'>
                        <div className='text-center pt-3'>
                          <Spinner animation='border' />
                          <h3>Loading...</h3>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={() => {
              setShow(false)
              setAppTrack(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </UserMain>
  )
}

export default UserApplicationHistory
