import React from 'react'
import ReactDOM from 'react-dom'
import './bootstrap.min.css'
import { Main } from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import { ToastProvider } from 'rc-toastr'
import 'rc-toastr/dist/index.css'

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ToastProvider
        config={{
          position: 'top-right',
          duration: 2000,
        }}
      >
        <Main />
      </ToastProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
)
