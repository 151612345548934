import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  InputGroup,
  Button,
  Table,
  Pagination,
  Modal,
} from 'react-bootstrap'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FaSearch, FaEye, FaCheck } from 'react-icons/fa'
import moment from 'moment'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'

// Actions
import { retrieveWithAssessment } from '../../redux/actions/assessmentActions'
import { retrieveAssessmentDetails } from '../../redux/actions/Assessment/electronicsAssessmentActions'

const AssessmentListScreen = ({ history }) => {
  // Declaration
  const dispatch = useDispatch()

  const [state, setState] = useState([])

  const [payload, setPayload] = useState({})

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const assessments = useSelector((state) => state.assessments)

  const { withAssessment } = assessments

  const [searchTerm, setSearchTerm] = useState('')

  const [modal, setModal] = useState({
    viewManager: false,
    viewEvaluation: false,
  })

  // End Declaration

  // Pagination Area Forms
  const [paginatedForms, setPaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  const pageSize = 10

  useEffect(() => {
    setPaginatedForms(_(_.reverse(state)).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [state])

  const pageCount = state ? Math.ceil(state.length / pageSize) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    const paginationForms = _(state).slice(startIndex).take(pageSize).value()
    setPaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Pagination Area Form

  // Functions
  const handleViewAssessment = () => {
    switch (userCredentials.subroles) {
      case 'Electronics':
        dispatch(
          retrieveAssessmentDetails({
            evalAppID: payload.id,
            userID: userCredentials.id,
          })
        )

        localStorage.setItem(
          'assessmentSelected',
          JSON.stringify({
            evalAppID: payload.id,
            userID: userCredentials.id,
          })
        )

        history.push(`/electronics-assessments/${payload.applicationID}`)

        setModal({
          viewManager: false,
        })
        break
      case 'Mechanical':
        dispatch(
          retrieveAssessmentDetails({
            evalAppID: payload.id,
            userID: userCredentials.id,
          })
        )

        localStorage.setItem(
          'assessmentSelected',
          JSON.stringify({
            evalAppID: payload.id,
            userID: userCredentials.id,
          })
        )

        history.push(`/mechanical-assessments/${payload.applicationID}`)

        setModal({
          viewManager: false,
        })
        break
      case 'Electrical':
        dispatch(
          retrieveAssessmentDetails({
            evalAppID: payload.id,
            userID: userCredentials.id,
          })
        )

        localStorage.setItem(
          'assessmentSelected',
          JSON.stringify({
            evalAppID: payload.id,
            userID: userCredentials.id,
          })
        )

        history.push(`/electrical-assessments/${payload.applicationID}`)

        setModal({
          viewManager: false,
        })
        break
      case 'Plumbing':
        dispatch(
          retrieveAssessmentDetails({
            evalAppID: payload.id,
            userID: userCredentials.id,
          })
        )

        localStorage.setItem(
          'assessmentSelected',
          JSON.stringify({
            evalAppID: payload.id,
            userID: userCredentials.id,
          })
        )

        history.push(`/plumbing-assessments/${payload.applicationID}`)

        setModal({
          viewManager: false,
        })
        break
      case 'Structural':
        dispatch(
          retrieveAssessmentDetails({
            evalAppID: payload.id,
            userID: userCredentials.id,
          })
        )

        localStorage.setItem(
          'assessmentSelected',
          JSON.stringify({
            evalAppID: payload.id,
            userID: userCredentials.id,
          })
        )

        history.push(`/structural-assessments/${payload.applicationID}`)

        setModal({
          viewManager: false,
        })
        break
      case 'Architectural':
        if (payload.ApplicationName !== 'OCCUPANCY PERMIT') {
          dispatch(
            retrieveAssessmentDetails({
              evalAppID: payload.id,
              userID: userCredentials.id,
            })
          )

          localStorage.setItem(
            'assessmentSelected',
            JSON.stringify({
              evalAppID: payload.id,
              userID: userCredentials.id,
            })
          )

          history.push(`/accessories-fee/${payload.applicationID}`)

          setModal({
            viewManager: false,
          })
          break
        } else {
          dispatch(
            retrieveAssessmentDetails({
              evalAppID: payload.id,
              userID: userCredentials.id,
            })
          )

          localStorage.setItem(
            'assessmentSelected',
            JSON.stringify({
              evalAppID: payload.id,
              userID: userCredentials.id,
            })
          )

          history.push(`/certificate-of-occupancy/${payload.applicationID}`)

          setModal({
            viewManager: false,
          })
          break
        }
      default:
        // history.push(`/evaluated-list`)
        // console.log('testing')
        break
    }
  }
  // End Functions

  // useEffect
  useEffect(() => {
    dispatch(retrieveWithAssessment({ userID: userCredentials.id }))
    localStorage.removeItem('assessmentSelected')
  }, [userCredentials])
  useEffect(() => {
    if (withAssessment && withAssessment.message) {
      setState(() =>
        withAssessment.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.applicationID === parseInt(searchTerm)
            )
          }
        })
      )
    }
  }, [withAssessment, searchTerm])
  // End useEffect

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {withAssessment && withAssessment.message && (
            <>
              <Form>
                <Row className='justify-content-end px-3 pt-5'>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              <Row>
                <Col md={12}>
                  <h5 className='pt-3 pb-2 mb-0'>With Assesment List</h5>
                  <hr
                    className='mt-1 mb-3'
                    style={{
                      background: '#ffeb36',
                      paddingBottom: '2px',
                      width: '200px',
                    }}
                  />
                  <Table bordered hover responsive size='sm'>
                    <thead className='bg-info text-light'>
                      <tr>
                        <th>Application No.</th>
                        <th>Application</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Date of Assessment</th>
                        <th>Date Transferred</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedForms.map((x, index) => (
                        <tr key={index}>
                          <td className='text-center'>{x.applicationID}</td>
                          <td>{x.ApplicationName}</td>
                          <td>{x.applicationStatus}</td>
                          <td>{x.remarks}</td>
                          <td>
                            {moment(x.withAssessment).format(
                              'MMMM D, YYYY h:mma'
                            )}
                          </td>
                          <td>
                            {moment(x.TimeStamp).format('MMMM D, YYYY h:mma')}
                          </td>
                          <td className='text-center'>
                            <Button
                              variant='success'
                              onClick={() => {
                                setModal({
                                  viewManager: true,
                                })
                                setPayload(x)
                                // dispatch(
                                //   retrieveListReport({
                                //     appID: x.applicationID,
                                //     userID: userCredentials.id,
                                //   })
                                // )
                              }}
                              size='sm'
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    size='sm'
                    className='my-2 d-flex justify-content-end mx-2'
                  >
                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {renderPageNumber}

                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </>
          )}

          {withAssessment && withAssessment.loading && (
            <Row className='py-5 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {withAssessment && withAssessment.error && (
            <Row className='py-5 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Application for Assessment</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      <Modal
        size='xl'
        show={modal.viewManager}
        onHide={() => setModal({ viewManager: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            Application Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6}>
              <h5>Application No.:</h5>
            </Col>
            <Col xl={6} className='text-danger fs-4'>
              {payload.applicationID}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xl={6}>
              <h5>Application Name:</h5>
            </Col>
            <Col xl={6} className='text-danger fs-4'>
              {payload.ApplicationName}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xl={6}>
              <h5>Mode OF Application:</h5>
            </Col>
            <Col xl={6}>{payload.ModeofForms}</Col>
          </Row>
          <hr />
          <Row>
            <Col xl={6}>
              <h5>Application Status:</h5>
            </Col>
            <Col xl={6}>{payload.applicationStatus}</Col>
          </Row>
          <hr />
          <Row>
            <Col xl={6}>
              <h5>Date Applied:</h5>
            </Col>
            <Col xl={6}>{payload.TimeStamp}</Col>
          </Row>
          <hr />
          <Row>
            <Col xl={6}>
              <h5>Applicant Attached Link/Remarks:</h5>
            </Col>
            <Col xl={6}>{payload.Link}</Col>
          </Row>
          <hr />
          {/* <Row>
            <Col xl={6}>
              <h5>Document Controller Attached Link/Remarks:</h5>
            </Col>
            <Col xl={6}>{payload.Link}</Col>
          </Row> */}
          {/* <hr /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={handleViewAssessment}>
            View Assessment
          </Button>

          <Button
            variant='danger'
            onClick={() => {
              setModal({
                viewManager: false,
              })
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </UserMain>
  )
}

export default withRouter(AssessmentListScreen)
