import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
  Accordion,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  addAssessment,
  editAssessment,
  retrieveAssessmentDetails,
} from '../../redux/actions/Assessment/certOccupancyAssessmentActions'
import { retrieveAppDetails } from '../../redux/actions/evaluatorActions'

const CertificateOfOccupancyScreen = ({ history, match }) => {
  // Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const { getAppDetails } = evaluator

  const certOccupancyAssessment = useSelector(
    (state) => state.certOccupancyAssessment
  )

  const { assessmentDetails, createAssessment, updateAssessment } =
    certOccupancyAssessment

  const [obj, setObj] = useState([
    {
      id_per_name: 1,
      name: 'DivA1A2',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 2,
      name: 'DivB Bldg',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 3,
      name: 'DivCD Bldg',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 4,
      name: 'DivJ Bldg',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 5,
      name: 'DivJ2 Bldg',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 6,
      name: 'ChangeOccu',
      fee: 5,
      units: 0,
      estimate: 0,
    },
  ])

  const [state, setState] = useState([])

  const [grandTotal, setGrandTotal] = useState(0)

  const [modal, setModal] = useState({
    successModal: false,
    updateModal: false,
  })

  const total = obj.reduce(
    (prevData, currData) => prevData + currData.estimate,
    0
  )

  const OccuTotal = [
    obj.find((el) => el.name === 'DivA1A2').estimate,
    obj.find((el) => el.name === 'DivB Bldg').estimate,
    obj.find((el) => el.name === 'DivCD Bldg').estimate,
    obj.find((el) => el.name === 'DivJ Bldg').estimate,
    obj.find((el) => el.name === 'DivJ2 Bldg').estimate,
  ].reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const filtered = obj.filter((e) => e.estimate > 0)

  const isUpdating = JSON.parse(localStorage.getItem('assessmentSelected'))
    ? JSON.parse(localStorage.getItem('assessmentSelected'))
    : null
  // End Declaration

  // Functions
  // DIV A
  const handleRangeDivA1 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 150000:
        tempEstimate = 100
        break
      case e.target.value > 150000 && e.target.value <= 400000:
        tempEstimate = 200
        break
      case e.target.value > 400000 && e.target.value <= 850000:
        tempEstimate = 400
        break
      case e.target.value > 850000 && e.target.value <= 1200000:
        tempEstimate = 800
        break
      case e.target.value > 1200000:
        let sample = e.target.value - 1200000
        tempEstimate = Math.floor(sample / 1000000)
        let sample2 = tempEstimate * 800
        switch (true) {
          case sample > 12000000:
            tempEstimate = sample2 + 800
            break
          default:
            tempEstimate = sample2 + 800
        }
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //Div B
  const handleRangeDivA2 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 150000:
        tempEstimate = 200
        break
      case e.target.value > 150000 && e.target.value <= 400000:
        tempEstimate = 400
        break
      case e.target.value > 400000 && e.target.value <= 850000:
        tempEstimate = 800
        break
      case e.target.value > 850000 && e.target.value <= 1200000:
        tempEstimate = 1000
        break
      case e.target.value > 1200000:
        let sample = e.target.value - 1200000
        tempEstimate = Math.floor(sample / 1000000)
        let sample2 = tempEstimate * 1000
        switch (true) {
          case sample > 12000000:
            tempEstimate = sample2 + 1000
            break
          default:
            tempEstimate = sample2 + 1000
        }
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //DIV C
  const handleRangeDivA3 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 150000:
        tempEstimate = 150
        break
      case e.target.value > 150000 && e.target.value <= 400000:
        tempEstimate = 250
        break
      case e.target.value > 400000 && e.target.value <= 850000:
        tempEstimate = 600
        break
      case e.target.value > 850000 && e.target.value <= 1200000:
        tempEstimate = 900
        break
      case e.target.value > 1200000:
        let sample = e.target.value - 1200000
        tempEstimate = Math.floor(sample / 1000000)
        let sample2 = tempEstimate * 900
        switch (true) {
          case sample > 12000000:
            tempEstimate = sample2 + 900
            break
          default:
            tempEstimate = sample2 + 900
        }
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //DIV J
  const handleRangeDivA4 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 20:
        tempEstimate = 50
        break
      case e.target.value > 20 && e.target.value <= 500:
        tempEstimate = 240
        break
      case e.target.value > 500 && e.target.value <= 1000:
        tempEstimate = 360
        break
      case e.target.value > 1000 && e.target.value <= 5000:
        tempEstimate = 480
        break
      case e.target.value > 5000 && e.target.value <= 10000:
        tempEstimate = 1200
        break
      case e.target.value > 10000:
        tempEstimate = 1200 + 2400
        break
      default:
        tempEstimate = e.target.value * 50
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //DIV J2
  const handleRangeDivA5 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 10:
        tempEstimate = 800
        break
      case e.target.value > 10:
        let sample = e.target.value - 10
        let sample2 = sample * 50
        switch (true) {
          case sample > 10:
            tempEstimate = sample2 + 800
            break
          default:
            tempEstimate = sample2 + 800
        }
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  const handleChange = (e) => {
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: e.target.value * el.fee,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleCreateSubmit = () => {
    setModal({ successModal: true })
    dispatch(
      addAssessment({
        grandTotal,
        assessments: state,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
        occuTotal: OccuTotal,
        changeOfOccuTotal: obj.find((el) => el.name === 'ChangeOccu').estimate,
      })
    )
  }

  const handleUpdateSubmit = () => {
    setModal({ updateModal: true })
    dispatch(
      editAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
        occuTotal: OccuTotal,
        changeOfOccuTotal: obj.find((el) => el.name === 'ChangeOccu').estimate,
      })
    )
  }

  const handleBtnCancel = () => {
    if (isUpdating !== null) {
      history.push('/assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    } else {
      history.push('/for-assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    }
  }
  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(
      retrieveAppDetails({ appID: match.params.id, userID: userCredentials.id })
    )

    isUpdating !== null &&
      dispatch(
        retrieveAssessmentDetails({
          evalAppID: isUpdating.evalAppID,
          userID: userCredentials.id,
        })
      )
  }, [dispatch, match, userCredentials])

  useEffect(() => {
    setGrandTotal(() => total)

    setState(() => filtered)
  }, [total])

  useEffect(() => {
    if (isUpdating !== null) {
      if (assessmentDetails && assessmentDetails.message) {
        assessmentDetails.message.map((submitted) => {
          let temp = obj
          let index = obj.findIndex(
            (el) => el.id_per_name === submitted.id_per_name
          )
          temp[index].units = submitted.units
          temp[index].estimate = submitted.estimate
          temp[index] = { ...temp[index], id: submitted.id }
          setObj(temp)
        })
        setGrandTotal(() => assessmentDetails.message[0].grandTotal)
      }
    }
  }, [assessmentDetails])

  return (
    <UserMain>
      <Container className='bg-light pt-3'>
        <>
          <h1 className='text-center'>CERTIFICATE OF USE OR OCCUPANCY</h1>
          <h6 className='text-center mb-4'>
            Certificates of Use or Occupancy (Table II.G.1. for fixed costing)
          </h6>
          <Row>
            <Col
              xl={12}
              className='mb-4 pt-3'
              style={{ background: '#f9f6f1' }}
            >
              <h6>Note: </h6>
              <p>Click category to be assessed.</p>
            </Col>
          </Row>

          <Form
            onSubmit={(e) => {
              e.preventDefault()
              isUpdating !== null ? handleUpdateSubmit() : handleCreateSubmit()
            }}
          >
            <Accordion className='mx-0'>
              {/* A. Division A-1 and A-2 Buildings */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='0'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  A. DIVISION A-1 AND A-2 BUILDINGS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing up to ₱150,000.00
                          </td>
                          <td className='text-center'>₱100.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='DivA1A2'
                              placeholder='i.-v.'
                              onChange={handleRangeDivA1}
                              value={obj[0].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {obj.find((el) => el.name === 'DivA1A2').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing more than ₱150,000.00 up to ₱400,000.00
                          </td>
                          <td className='text-center'>₱200.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing more than ₱400,000.00 up to ₱850,000.00
                          </td>
                          <td className='text-center'>₱400.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing more than ₱850,000.00 up to ₱1,200,000.00
                          </td>
                          <td className='text-center'>₱800.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Every million or portion thereof in excess of
                            ₱1,200,000.00
                          </td>
                          <td className='text-center'>₱800.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End A */}

              {/* B. Division B-1/E-1, 2, 3/F-1/G-1, 2, 3, 4, 5/H-1, 2, 3, 4 and I-1 Buildings */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='1'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  B. DIVISION B-1/E-1, 2, 3/F-1/G-1, 2, 3, 4, 5/H-1, 2, 3, 4 and
                  I-1 BUILDINGS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='1'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing up to ₱150,000.00
                          </td>
                          <td className='text-center'>₱200.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='DivB Bldg'
                              placeholder='i.-v.'
                              onChange={handleRangeDivA2}
                              value={obj[1].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'DivB Bldg')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing more than ₱150,000.00 up to ₱400,000.00
                          </td>
                          <td className='text-center'>₱400.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing more than ₱400,000.00 up to ₱850,000.00
                          </td>
                          <td className='text-center'>₱800</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing more than ₱850,000.00 up to ₱1,200,000.00
                          </td>
                          <td className='text-center'>₱1,000</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Every million or portion thereof in excess of
                            ₱1,200,000
                          </td>
                          <td className='text-center'>₱1,000</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End B */}

              {/* C. Divisions C-1, 2/D-1, 2, 3 Buildings  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='2'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  C. DIVISION C-1, 2/D-1, 2, 3 BUILDINGS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='2'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing up to ₱150,000.00
                          </td>
                          <td className='text-center'>₱150.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='DivCD Bldg'
                              placeholder='i.-v.'
                              onChange={handleRangeDivA3}
                              value={obj[2].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'DivCD Bldg')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing more than ₱150,000.00 up to ₱400,000.00
                          </td>
                          <td className='text-center'>₱250.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing more than ₱400,000.00 up to ₱850,000.00
                          </td>
                          <td className='text-center'>₱600</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Costing more than ₱850,000.00 up to ₱1,200,000.00
                          </td>
                          <td className='text-center'>₱900</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Every million or portion thereof in excess of
                            ₱1,200,000
                          </td>
                          <td className='text-center'>₱900</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End C */}

              {/* D. Divisions J-1 Buildings/Structures */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='3'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  D. Divisions J-1 Buildings/Structures
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='3'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            With floor area up to 20.00 sq. meters
                          </td>
                          <td className='text-center'>₱50.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='DivJ Bldg'
                              placeholder='i.-vi.'
                              onChange={handleRangeDivA4}
                              value={obj[3].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'DivJ Bldg')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            With floor area above 20.00 sq. meters up to 500.00
                            sq. meters
                          </td>
                          <td className='text-center'>₱240.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            With floor area above 500.00 sq. meters up to 1,000
                            sq. meters
                          </td>
                          <td className='text-center'>₱360</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            With floor area above 1,000.00 sq. meters up to
                            5,000.00 sq. meters
                          </td>
                          <td className='text-center'>₱480</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            With floor area above 5,000 sq. meters up to
                            10,000.00 sq. meters
                          </td>
                          <td className='text-center'>₱1,200</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            With floor area above 10,000.00 sq. meters
                          </td>
                          <td className='text-center'>₱2,400</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End D */}

              {/* E. Division J-2 Structures */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='4'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  E. DIVISION J-2 STRUCTURES
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Garages, carports, balconies, terraces, lanais and
                            the like: 50% of the rare of the principal building,
                            of which they are accessories.
                          </td>
                          <td className='text-center'></td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='DivJ2 Bldg'
                              placeholder='i.-iii.'
                              onChange={handleRangeDivA5}
                              value={obj[4].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'DivJ2 Bldg')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Aviaries, aquariums, zoo structures and the like:
                            same rates as for Section 10.d. above.
                          </td>
                          <td className='text-center'></td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Towers such as for Radio and TV transmissions, cell
                            site, sign (ground or roof type) and water tank
                            supporting structures and the like in any location
                            shall be imposed fees as follows:
                          </td>
                          <td className='text-center'></td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (a) First 10.00 meters of height from the ground
                          </td>
                          <td className='text-center'>₱800</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (b) Every meter or fraction thereof in excess of
                            10.00 meters
                          </td>
                          <td className='text-center'>₱50</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End E */}

              {/* F. Change in Use/Occupancy */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='5'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  F. CHANGE IN USE/OCCUPANCY
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='5'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Change in Use/Occupancy, per sq. meter or fraction
                            thereof of area affected
                          </td>
                          <td className='text-center'>₱5.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='ChangeOccu'
                              placeholder='i.-iii.'
                              onChange={handleChange}
                              value={obj[5].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'ChangeOccu')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End F */}
            </Accordion>

            <Row className='mt-3 mx-1'>
              <Col xl={8} className='text-end align-middle mt-2'>
                <h4>GRAND TOTAL:</h4>
              </Col>
              <Col xl={4} className='mb-5'>
                <Form.Control
                  type='text'
                  className='border border-secondary'
                  style={{
                    marginLeft: '8px',
                    marginRight: '5px',
                  }}
                  name='GrandTotal'
                  value={grandTotal}
                  placeholder='Grand Total'
                  disabled
                />
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col xl={12} className='text-end'>
                <Button type='submit' variant='success'>
                  Submit
                </Button>
                <Button
                  variant='danger'
                  className='mx-2'
                  onClick={handleBtnCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      </Container>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={createAssessment && createAssessment.loading}
        success={createAssessment && createAssessment.message}
        error={createAssessment && createAssessment.error}
      />
      {/* End Success Modal */}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateModal}
        onHide={() => {
          setModal({ updateModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={updateAssessment && updateAssessment.loading}
        success={updateAssessment && updateAssessment.message}
        error={updateAssessment && updateAssessment.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(CertificateOfOccupancyScreen)
