import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  InputGroup,
  Button,
  Table,
  Pagination,
  Modal,
} from 'react-bootstrap'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FaSearch, FaEye, FaCheck } from 'react-icons/fa'
import moment from 'moment'

//Layout
import UserMain from '../../layout/UserLayout/UserMain'
import CommentsCard from '../../reusable/CommentsCard'
import SuccessModal from '../../reusable/SuccessModal'

// Modal
import EvaluationSheetScreen from '../../reusable/EvaluationSheetScreen'
import AddEditEvaluationSheet from '../../reusable/AddEditEvaluationSheet'

// Actions
import {
  retrieveForEvaluation,
  retrieveCommentList,
  insertComment,
} from '../../redux/actions/evaluatorActions'
import { retrieveDateApplied } from '../../redux/actions/adminApplicationActions'
import {
  retrieveEvaluationSheet,
  deleteEvaluationSheet,
  insertEvaluationSheet,
} from '../../redux/actions/DocumentControllers/documentControllerActions'

const ForEvaluationListScreen = ({ history }) => {
  // Declaration
  const dispatch = useDispatch()

  const [state, setState] = useState([])

  const [payload, setPayload] = useState({})

  const [commentDetails, setCommentDetails] = useState({
    commentToName: '',
    commentTo: '',
    comments: '',
  })

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const { forEvaluation, commentList, createdComment } = evaluator

  const [searchTerm, setSearchTerm] = useState('')

  const [modal, setModal] = useState({
    viewManager: false,
    viewEvaluation: false,
    viewComments: false,
    successComment: false,
    viewEvaluationSheet: false,
    editRemarks: false,
  })

  const [evaluationSheet, setEvaluationSheet] = useState({
    id: 0,
    trades: '',
    remarks: '',
    appID: 0,
    empID: 0,
  })

  const [userLevel, setUserLevel] = useState('')

  const adminApplication = useSelector((state) => state.adminApplication)

  const { dateApplied } = adminApplication

  const dc = useSelector((state) => state.documentControllerReducer)

  const { retrievedEvaluationSheet } = dc
  // End Declaration

  // Pagination Area Forms
  const [paginatedForms, setPaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  const pageSize = 10

  useEffect(() => {
    setPaginatedForms(_(_.reverse(state)).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [state])

  const pageCount = state ? Math.ceil(state.length / pageSize) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    const paginationForms = _(state).slice(startIndex).take(pageSize).value()
    setPaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Pagination Area Form

  // Function
  const handleCreateEvaluation = () => {
    switch (userCredentials.subroles) {
      case 'Electronics':
        history.push(`/create-evaluation-electronics/${payload.applicationID}`)
        setModal({
          viewManager: false,
        })
        break
      case 'Structural':
        history.push(`/create-evaluation-civil/${payload.applicationID}`)
        setModal({
          viewManager: false,
        })
        break
      case 'Architectural':
        history.push(
          `/create-evaluation-architectural/${payload.applicationID}`
        )
        setModal({
          viewManager: false,
        })
        break
      case 'Mechanical':
        history.push(`/create-evaluation-mechanical/${payload.applicationID}`)
        setModal({
          viewManager: false,
        })
        break
      case 'Electrical':
        history.push(`/create-evaluation-electrical/${payload.applicationID}`)
        setModal({
          viewManager: false,
        })
        break
      case 'Plumbing':
        history.push(`/create-evaluation-plumbing/${payload.applicationID}`)
        setModal({
          viewManager: false,
        })
        break
      default:
        history.push(`/evaluated-list`)
        break
    }
  }

  const handleEditEvaluationSheet = (e, selectedItem) => {
    setModal({ editRemarks: true })
    // console.log(selectedItem)
    if (selectedItem === undefined) {
      setEvaluationSheet({
        ...evaluationSheet,
        trades: e[1],
        appID: payload.appID,
        empID: userCredentials.id,
      })
    } else {
      setEvaluationSheet({
        ...evaluationSheet,
        id: selectedItem.id,
        trades: e[1],
        appID: payload.appID,
        empID: userCredentials.id,
        remarks: selectedItem.remarks,
      })
    }
  }

  const handleDeleteEvaluationSheet = (match) => {
    if (
      window.confirm('Are you sure you want to update status of application?')
    ) {
      dispatch(
        deleteEvaluationSheet({
          id: match.id,
          appID: match.appID,
        })
      )
      setEvaluationSheet({
        ...evaluationSheet,
        id: 0,
        trades: '',
        remarks: '',
        appID: 0,
      })
    }
  }

  const handleSubmitEvaluationSheet = (e) => {
    e.preventDefault()
    dispatch(
      insertEvaluationSheet({
        id: evaluationSheet.id,
        trades: evaluationSheet.trades,
        remarks: evaluationSheet.remarks,
        appID: evaluationSheet.appID,
        empID: evaluationSheet.empID,
      })
    )
    setModal({
      ...modal,
      viewEvaluationSheet: true,
      editRemarks: false,
    })
  }
  // End Function

  // UseEffect
  useEffect(() => {
    dispatch(retrieveForEvaluation({ userID: userCredentials.id }))
    localStorage.removeItem('evaluationSelected')
  }, [userCredentials])

  useEffect(() => {
    if (forEvaluation && forEvaluation.message) {
      setState(() =>
        forEvaluation.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.applicationID === parseInt(searchTerm)
            )
          }
        })
      )
    }

    if (userCredentials.roles !== 'evaluator') {
      setUserLevel(userCredentials.roles)
    } else {
      setUserLevel(userCredentials.subroles)
    }
  }, [forEvaluation, searchTerm, userCredentials])
  // End UseEffect

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {forEvaluation && forEvaluation.message && (
            <>
              <Form>
                <Row className='justify-content-end px-3 pt-5'>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              <Row>
                <Col
                  md={12}
                  // className='border border-secondary rounded px-0 bg-light'
                >
                  <h5 className='pt-3 pb-2 mb-0'>For Evaluation List</h5>
                  <hr
                    className='mt-1 mb-3'
                    style={{
                      background: '#ffeb36',
                      paddingBottom: '2px',
                      width: '200px',
                    }}
                  />
                  <Table bordered hover responsive size='sm'>
                    <thead className='bg-info text-light'>
                      <tr>
                        <th>Application No.</th>
                        <th>Application</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Date Transferred</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedForms.map((x, index) => (
                        <tr key={index}>
                          <td className='text-center'>{x.applicationID}</td>
                          <td>{x.ApplicationName}</td>
                          <td>{x.applicationStatus}</td>
                          <td>{x.remarks}</td>
                          <td>
                            {moment(x.TimeStamp).format('MMMM D, YYYY h:mma')}
                          </td>
                          <td className='text-center'>
                            <Button
                              variant='success'
                              size='sm'
                              onClick={() => {
                                setModal({
                                  viewManager: true,
                                })
                                setPayload(x)
                                // dispatch(
                                //   retrieveListReport({
                                //     appID: x.applicationID,
                                //     userID: userCredentials.id,
                                //   })
                                // )
                                dispatch(
                                  retrieveDateApplied({ id: x.applicationID })
                                )
                              }}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    size='sm'
                    className='my-2 d-flex justify-content-end mx-2'
                  >
                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {renderPageNumber}

                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </>
          )}

          {forEvaluation && forEvaluation.loading && (
            <Row className='py-5 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {forEvaluation && forEvaluation.error && (
            <Row className='py-5 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Application for Evaluation</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      <Modal
        size='lg'
        show={modal.viewManager}
        onHide={() => setModal({ viewManager: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            Application Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hover responsive size='sm'>
            <tbody>
              <tr>
                <td className='fw-bold'>Application No. :</td>
                <td>
                  {Object.keys(payload).length > 0 && payload.applicationID}
                </td>
              </tr>
              <tr>
                <td className='fw-bold'>Application Name :</td>
                <td>
                  {Object.keys(payload).length > 0 && payload.ApplicationName}
                </td>
              </tr>
              <tr>
                <td className='fw-bold'>Mode of Application :</td>
                <td>
                  {Object.keys(payload).length > 0 && payload.ModeofForms}
                </td>
              </tr>
              <tr>
                <td className='fw-bold'>Application Status :</td>
                <td>
                  {Object.keys(payload).length > 0 && payload.applicationStatus}
                </td>
              </tr>
              <tr>
                <td className='fw-bold'>Application Status :</td>
                <td>
                  {dateApplied &&
                    dateApplied.message &&
                    Object.keys(payload).length > 0 &&
                    moment(dateApplied.message[0].DateApplied).format(
                      'MMMM D, YYYY h:mma'
                    )}
                </td>
              </tr>
              <tr>
                <td className='fw-bold'>Applicant Attached Link:</td>
                <td>{Object.keys(payload).length > 0 && payload.Link}</td>
              </tr>
              <tr>
                <td className='fw-bold'>
                  {' '}
                  Document Controller Attached Link/Remarks:
                </td>
                <td>{Object.keys(payload).length > 0 && payload.remarks}</td>
              </tr>
              <tr>
                <td className='fw-bold'>Comment Manager</td>
                <td>
                  <Button
                    variant='success'
                    onClick={() => {
                      setModal({ viewManager: false, viewComments: true })
                      dispatch(
                        retrieveCommentList({ id: payload.applicationID })
                      )
                    }}
                    size='sm'
                  >
                    VIEW
                  </Button>
                </td>
              </tr>
              <tr>
                <td className='fw-bold'>Evaluation Manager</td>
                <td>
                  <Button
                    variant='success'
                    onClick={handleCreateEvaluation}
                    size='sm'
                  >
                    VIEW
                  </Button>
                </td>
              </tr>
              <tr>
                <td className='fw-bold'>Evaluation Sheet</td>
                <td>
                  <Button
                    variant='success'
                    onClick={() => {
                      dispatch(
                        retrieveEvaluationSheet({ id: payload.applicationID })
                      )
                      setModal({
                        ...modal,
                        viewManager: false,
                        viewEvaluationSheet: true,
                      })

                      // history.push(`/evaluation-sheet/${payload.applicationID}`)
                    }}
                    size='sm'
                  >
                    VIEW
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={() => {
              setModal({
                viewManager: false,
              })
            }}
            size='sm'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Evaluation Sheet */}
      <EvaluationSheetScreen
        show={modal.viewEvaluationSheet}
        onHide={() => {
          setModal({
            ...modal,
            viewEvaluationSheet: false,
            viewManager: true,
          })
        }}
        retrievedEvaluationSheet={retrievedEvaluationSheet}
        userLevel={userLevel}
        openAddEdit={() => {
          setModal({ ...modal, viewEvaluationSheet: false, editRemarks: true })
        }}
        closeAddEdit={() =>
          setModal({ ...modal, viewEvaluationSheet: true, editRemarks: false })
        }
        handleEditEvaluationSheet={handleEditEvaluationSheet}
        handleDeleteEvaluationSheet={handleDeleteEvaluationSheet}
      />
      {/* End Modal Evaluation Sheet */}

      {/* Modal Add / Edit Evaluation Sheet */}
      <AddEditEvaluationSheet
        show={modal.editRemarks}
        onHide={() => {
          setModal({
            ...modal,
            editRemarks: false,
            viewEvaluationSheet: true,
          })

          setEvaluationSheet({
            ...evaluationSheet,
            id: 0,
            remarks: '',
          })
        }}
        handleSubmitEvaluationSheet={handleSubmitEvaluationSheet}
        evaluationSheet={evaluationSheet}
        setEvaluationSheet={(e) =>
          setEvaluationSheet({
            ...evaluationSheet,
            remarks: e.target.value,
          })
        }
      />
      {/* End Modal Add / Edit Evaluation Sheet */}

      {/* Change Status */}
      <Modal
        size='lg'
        show={modal.viewComments}
        onHide={() => setModal({ viewComments: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            Comment Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className='pb-3'>
              <h6>NOTE:</h6>
              <i>Comment on this section will be shown to bldgo staff only</i>
            </div>

            <Row className='pb-2'>
              <Col xl='10'>
                <Form.Control
                  as='textarea'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  size='sm'
                  name='r_remarks'
                  required
                  placeholder='Insert remarks here ...'
                  onChange={(e) => {
                    setCommentDetails({
                      ...commentDetails,
                      comments: e.target.value,
                    })
                  }}
                />
              </Col>
              <Col className='d-flex align-items-end pt-2' xl='2'>
                <Button
                  onClick={() => {
                    dispatch(
                      insertComment({
                        id: payload.applicationID,
                        comments: commentDetails.comments,
                        createdBy: userCredentials.id,
                      })
                    )
                    setModal({
                      viewComments: false,
                      successComment: true,
                    })
                  }}
                  variant='success'
                  size='sm'
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
          <hr />
          <CommentsCard
            state={commentList && commentList.message && commentList.message}
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button
            variant='danger'
            onClick={() => setModal({ viewManager: true, viewComments: false })}
            size='sm'
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Change Status */}

      {/* Success Modal */}
      <SuccessModal
        show={modal.successComment}
        onHide={() => {
          setModal({ successComment: false })
          window.location.reload(true)
        }}
        transLoading={createdComment && createdComment.loading}
        success={createdComment && createdComment.message}
        error={createdComment && createdComment.error}
      />
      {/* End  Success Modal */}
    </UserMain>
  )
}

export default withRouter(ForEvaluationListScreen)
