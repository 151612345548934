import React, { useState, useEffect } from 'react'
import {
  Container,
  Col,
  Row,
  Form,
  Button,
  Table,
  Modal,
  Spinner,
  Pagination,
  InputGroup,
  Card,
  ListGroup,
  Toast,
} from 'react-bootstrap'
import {
  FaCheckCircle,
  FaSearch,
  FaCheck,
  FaEye,
  FaPenAlt,
  FaArrowDown,
} from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'
import _ from 'lodash'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import io from 'socket.io-client'
import sha1 from 'sha1'
import { QRCodeCanvas } from 'qrcode.react'

// Components
import UserMain from '../layout/UserLayout/UserMain'
import BldgPermit from '../reusable/BldgPermit'
import OrderPay from '../reusable/OrderPay'
import CertOccupancy from '../reusable/CertOccupancy'

// Modal
import EvaluationSheetScreen from '../reusable/EvaluationSheetScreen'

// Actions
import {
  userApplicationRetrieve,
  userUpdateApplication,
  userDeleteApplication,
  RetrieveStatusTrack,
  userGetOrderOfPayment,
  userRetrievedReleasePermit,
  retrieveListReport,
  retrieveEngrList,
  retrieveRecordedEng,
  updateRecordedEng,
  retrieveReleasedOccu,
  paymentInitialization,
} from '../redux/actions/userApplicationActions'

import { retrieveEvaluationSheet } from '../redux/actions/DocumentControllers/documentControllerActions'

import { getUserProfile } from '../redux/actions/userProfileAction'

import Loading from '../reusable/Loading'

// Helper
import { encryptPDF } from '../helpers/pdfEncryption'

const socket = io.connect(process.env.REACT_APP_URL)

const UserApplicationStatus = ({ history }) => {
  const [show, setShow] = useState(false)
  const [transactShow, setTransactShow] = useState(false)
  const [isTrade, setIsTrade] = useState()
  const [showToggle, setShowToggle] = useState(false)
  const [encryptedPdf, setEncryptedPdf] = useState(null)

  const [engineerDetails, setEngineerDetails] = useState({
    trade: '',
    inChargeName: '',
    inChargeType: '',
    inChargeProfession: '',
    inChargePrcNo: '',
    inChargePrcValidity: '',
    inChargePtrNo: '',
    inChargePtrValidity: '',
    inChargeID: '',
    designName: '',
    designProfession: '',
    designType: '',
    designPrcNo: '',
    designPrcValidity: '',
    designPtrNo: '',
    designPtrValidity: '',
    designChargeID: '',
    idInCharge: '',
    idDesign: '',
    disabled: true,
    selectedTrade: '',
  })

  const dispatch = useDispatch()
  const applications = useSelector((state) => state.applications)
  const {
    applicationsDetail,
    loading,
    error,
    updatedMessage,
    deletedMessage,
    statusTracking,
    retrievedOrderofPayment,
    retrievedReleasePermit,
    retrievedListReport,
    retrievedEngrList,
    retrievedRecordedEng,
    updatedRecordedEng,
    retrievedReleasedOccu,
    initializingPayment,
  } = applications

  const users = useSelector((state) => state.users)
  const { userCredentials } = users

  const documentControllerReducer = useSelector(
    (state) => state.documentControllerReducer
  )

  const { retrievedEvaluationSheet } = documentControllerReducer

  const [InitialState] = useState({
    // userID: userCredentials.id,
    roles: userCredentials.roles,
  })

  const [state, setState] = useState([])

  const [modal, setModal] = useState({
    viewOrderofPayment: false,
    viewListInspection: false,
    viewFilter: false,
    isProfession: false,
    viewEngineer: false,
    viewEvaluationSheet: false,
    viewPaymentGateway: false,
  })

  const [filterValue, setFilterValue] = useState({
    category: '',
    status: '',
  })

  const [forUpdate, setForUpdate] = useState({
    id: '',
    AppInfo_ID: '',
    Application_ID: '',
    AppID: '',
    ApplicationName: '',
    Link: '',
    BpNo: '',
    Structural: 0,
    Mechanical: 0,
    Architectural: 0,
    Sanitary: 0,
    Electrical: 0,
    Plumbing: 0,
    Electronics: 0,
    Status: '',
    Remarks: '',
    TimeStamp: '',
    userID: '',
    trades: '',
    status: 'APPLICATION VERIFICATION',
    remarks: 'UPDATED',
  })

  const [searchTerm, setSearchTerm] = useState('')

  const [appTrack, setAppTrack] = useState(false)

  const [recordEngineer, setRecordEngineer] = useState([])

  const [dataList, setDataList] = useState([])

  const [userLevel, setUserLevel] = useState('')

  const [penaltyValue, setPenaltyValue] = useState(0)

  const [opSubtotal, setOpSubtotal] = useState(0)

  const handleSetEncrypt = (value) => {
    setEncryptedPdf(value)
  }

  const handleEditModal = (payload) => {
    // dispatch(userApplicationRetrieveById(id, InitialState))
    setShow(true)
    setForUpdate({
      ...forUpdate,
      id: payload.id,
      AppInfo_ID: payload.AppInfo_ID,
      AppID: payload.appID,
      ApplicationName: payload.ApplicationName,
      Link: payload.Link,
      BpNo: payload.BpNo,
      Structural: payload.Structural,
      Mechanical: payload.Mechanical,
      Architectural: payload.Architectural,
      Sanitary: payload.Sanitary,
      Electrical: payload.Electrical,
      Plumbing: payload.Plumbing,
      Electronics: payload.Electronics,
      Status: payload.status,
      Remarks: payload.remarks,
      TimeStamp: payload.TimeStamp,
      userID: payload.userID,
    })
  }

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      if (event.target.checked) {
        setForUpdate({
          ...forUpdate,
          [event.target.name]: 1,
        })

        setIsTrade(event.target.name)
        setEngineerDetails({
          ...engineerDetails,
          trade: event.target.name,
          inChargeType: `Supervisor in-charge of ${event.target.name} works`,
          designType: 'Design professional, plans, and specification',
        })
        setShow(false)
        setModal({
          ...modal,
          isProfession: true,
        })
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this trade in your application ?'
          )
        ) {
          setForUpdate({
            ...forUpdate,
            [event.target.name]: 0,
          })
        }
      }
    } else {
      setForUpdate({
        ...forUpdate,
        [event.target.name]: event.target.value,
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // dispatch(userUpdateApplication(forUpdate))
    dispatch(
      userUpdateApplication({
        id: forUpdate.id,
        AppInfo_ID: forUpdate.AppInfo_ID,
        Application_ID: forUpdate.Application_ID,
        AppID: forUpdate.AppID,
        ApplicationName: forUpdate.ApplicationName,
        Link: forUpdate.Link,
        BpNo: forUpdate.BpNo,
        Structural: forUpdate.Structural,
        Mechanical: forUpdate.Mechanical,
        Architectural: forUpdate.Architectural,
        Sanitary: forUpdate.Sanitary,
        Electrical: forUpdate.Electrical,
        Plumbing: forUpdate.Plumbing,
        Electronics: forUpdate.Electronics,
        Status: forUpdate.Status,
        Remarks: forUpdate.Remarks,
        TimeStamp: forUpdate.TimeStamp,
        userID: forUpdate.userID,
        trades: forUpdate.trades,
        status: 'APPLICATION VERIFICATION',
        remarks: 'UPDATED',
        recordEngineer,
      })
    )
    setShow(false)
    setTransactShow(true)
  }

  const handleDelete = (id) => {
    if (window.confirm('Are you sure wanted to delete this application?')) {
      dispatch(userDeleteApplication(InitialState, id))
      setTransactShow(true)
    }
  }

  const handleConfirm = () => {
    setTransactShow(false)
    dispatch(userApplicationRetrieve(InitialState))
  }

  const handleOnClick = (data) => {
    setEngineerDetails({
      ...engineerDetails,
      inChargeName: data.Name,
      inChargeProfession: data.ProfessionName,
      inChargePrcNo: data.prcNo,
      inChargePrcValidity: data.prcValidity,
      inChargePtrNo: data.ptrNo,
      inChargePtrValidity: data.ptrValidity,
      inChargeID: data.id,
    })
  }

  const handleOnClickDesign = (data) => {
    setEngineerDetails({
      ...engineerDetails,
      designName: data.Name,
      designProfession: data.ProfessionName,
      designPrcNo: data.prcNo,
      designPrcValidity: data.prcValidity,
      designPtrNo: data.ptrNo,
      designPtrValidity: data.ptrValidity,
      designChargeID: data.id,
    })
  }

  // Pagination Area Forms
  const [dfpaginatedForms, setdfpaginatedForms] = useState([])
  const [dfcurrentPage, setdfcurrentPage] = useState(1)

  const [dfpageNumberLimit] = useState(5)
  const [maxdfPageNumberLimit, setmaxdfPageNumberLimit] = useState(5)
  const [mindfPageNumberLimit, setmindfPageNumberLimit] = useState(0)

  const dfpageSize = 10

  useEffect(() => {
    setdfpaginatedForms(_(_.reverse(state)).slice(0).take(dfpageSize).value())
    setdfcurrentPage(1)
  }, [state])

  const dfpageCount = state ? Math.ceil(state.length / dfpageSize) : 0

  const dfpages = _.range(1, dfpageCount + 1)

  const dfpagination = (pageNo) => {
    setdfcurrentPage(pageNo)
    const dfstartIndex = (pageNo - 1) * dfpageSize
    const dfpaginationForms = _(state)
      .slice(dfstartIndex)
      .take(dfpageSize)
      .value()
    setdfpaginatedForms(dfpaginationForms)
  }

  const dfrenderPageNumber = dfpages.map((page) => {
    if (page < maxdfPageNumberLimit + 1 && page > mindfPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => dfpagination(page)}
          active={page === dfcurrentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const dfhandleNextbtn = () => {
    setdfcurrentPage(dfcurrentPage + 1)
    dfpagination(dfcurrentPage + 1)

    if (dfcurrentPage + 1 > maxdfPageNumberLimit) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit + dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit + dfpageNumberLimit)
    }
  }

  const dfhandlePrevbtn = () => {
    setdfcurrentPage(dfcurrentPage - 1)
    dfpagination(dfcurrentPage - 1)

    if ((dfcurrentPage - 1) % dfpageNumberLimit === 0) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit - dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit - dfpageNumberLimit)
    }
  }
  // End Pagination Area Forms

  const [showViewApp, setShowViewApp] = useState(false)

  const handleViewApp = (payload) => {
    setShowViewApp(true)
    setForUpdate({
      ...forUpdate,
      Application_ID: payload.appID,
      ApplicationName: payload.ApplicationName,
      Link: payload.Link,
      Structural: payload.Structural,
      Mechanical: payload.Mechanical,
      Architectural: payload.Architectural,
      Sanitary: payload.Sanitary,
      Electrical: payload.Electrical,
      Plumbing: payload.Plumbing,
      Electronics: payload.Electronics,
      Status: payload.status,
      Remarks: payload.remarks,
      TimeStamp: payload.TimeStamp,
    })
    if (payload.ApplicationName !== 'OCCUPANCY PERMIT') {
      dispatch(
        userRetrievedReleasePermit({
          id: payload.appID,
          userID: userCredentials.id,
          category: payload.ApplicationName,
        })
      )
    } else {
      dispatch(
        retrieveReleasedOccu({
          id: payload.appID,
          // userID: userCredentials.id,
        })
      )
    }

    dispatch(
      userGetOrderOfPayment({
        id: payload.appID,
        userID: userCredentials.id,
      })
    )
    dispatch(retrieveListReport({ id: payload.appID }))
  }

  const handleSubmitEngrDetails = (e) => {
    e.preventDefault()
    let index = recordEngineer.findIndex((value) => value.trade === isTrade)

    if (index < 0) {
      let tempValue = recordEngineer

      tempValue.push(engineerDetails)

      setModal({
        ...modal,
        isProfession: false,
      })

      setEngineerDetails({
        trade: '',
        inChargeName: '',
        inChargeType: '',
        inChargeProfession: '',
        inChargePrcNo: '',
        inChargePrcValidity: '',
        inChargePtrNo: '',
        inChargePtrValidity: '',
        inChargeID: '',
        designName: '',
        designProfession: '',
        designType: '',
        designPrcNo: '',
        designPrcValidity: '',
        designPtrNo: '',
        designPtrValidity: '',
        designChargeID: '',
      })
    }
    setShow(true)
  }

  const onChange = (e) => {
    setEngineerDetails({
      ...engineerDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleUpdateEngineerSubmit = (e) => {
    e.preventDefault()
    dispatch(
      updateRecordedEng({
        inChargeID: engineerDetails.inChargeID,
        inChargeName: engineerDetails.inChargeName,
        inChargeProfession: engineerDetails.inChargeProfession,
        inChargeType: engineerDetails.inChargeType,
        idInCharge: engineerDetails.idInCharge,
        designChargeID: engineerDetails.designChargeID,
        designName: engineerDetails.designName,
        designProfession: engineerDetails.designProfession,
        designType: engineerDetails.designType,
        idDesign: engineerDetails.idDesign,
      })
    )
    setModal({ ...modal, viewEngineer: false })
    setShow(true)
    setEngineerDetails({
      trade: '',
      inChargeName: '',
      inChargeType: '',
      inChargeProfession: '',
      inChargePrcNo: '',
      inChargePrcValidity: '',
      inChargePtrNo: '',
      inChargePtrValidity: '',
      inChargeID: '',
      designName: '',
      designProfession: '',
      designType: '',
      designPrcNo: '',
      designPrcValidity: '',
      designPtrNo: '',
      designPtrValidity: '',
      designChargeID: '',
      disabled: true,
    })
  }

  const handleInitializingPayment = () => {
    // const appDigest = `${retrievedOrderofPayment.message[0].totalAmount}${forUpdate.Application_ID}2c1816316e65dbfcb0c34a25f3d6fe5589aef65d`

    // dispatch(
    //   paymentInitialization({
    //     txnid: forUpdate.Application_ID,
    //     amount: retrievedOrderofPayment.message[0].totalAmount,
    //     digest: sha1(appDigest),
    //   })
    // )

    setModal({ viewPaymentGateway: true })
  }

  useEffect(() => {
    dispatch(userApplicationRetrieve(InitialState))
    socket.on('received_change_status', () => {
      dispatch(userApplicationRetrieve(InitialState))
    })
    dispatch(getUserProfile({ id: userCredentials.id }))
    dispatch(retrieveEngrList({ name: '' }))
  }, [InitialState, dispatch, socket])

  useEffect(() => {
    if (applicationsDetail !== undefined) {
      setState(() =>
        applicationsDetail.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : typeof value === 'number' &&
                  String(value).includes(searchTerm)
            )
          }
        })
      )
    }

    if (retrievedEngrList && retrievedEngrList.message) {
      setDataList(retrievedEngrList.message)
    }

    if (retrievedRecordedEng && retrievedRecordedEng.message) {
      if (
        forUpdate.ApplicationName === 'BUILDING CONSTRUCTION PERMIT' ||
        forUpdate.ApplicationName === 'ELECTRICAL PERMIT'
      ) {
        setEngineerDetails({
          ...engineerDetails,
          inChargeName: retrievedRecordedEng.message[0].Name,
          inChargeType: retrievedRecordedEng.message[0].Type,
          inChargeProfession: retrievedRecordedEng.message[0].ProfessionName,
          inChargePrcNo: retrievedRecordedEng.message[0].prcNo,
          inChargePtrNo: retrievedRecordedEng.message[0].ptrNo,
          inChargeID: retrievedRecordedEng.message[0].taggedID,
          idInCharge: retrievedRecordedEng.message[0].id,
          idDesign: retrievedRecordedEng.message[1].id,
          designName: retrievedRecordedEng.message[1].Name,
          designProfession: retrievedRecordedEng.message[1].ProfessionName,
          designType: retrievedRecordedEng.message[1].Type,
          designPrcNo: retrievedRecordedEng.message[1].prcNo,
          designPtrNo: retrievedRecordedEng.message[1].ptrNo,
          designChargeID: retrievedRecordedEng.message[1].taggedID,
        })
      } else {
        setEngineerDetails({
          ...engineerDetails,
          inChargeName: retrievedRecordedEng.message[0].Name,
          inChargeType: retrievedRecordedEng.message[0].Type,
          inChargeProfession: retrievedRecordedEng.message[0].ProfessionName,
          inChargePrcNo: retrievedRecordedEng.message[0].prcNo,
          inChargePtrNo: retrievedRecordedEng.message[0].ptrNo,
          inChargeID: retrievedRecordedEng.message[0].taggedID,
          idInCharge: retrievedRecordedEng.message[0].id,
        })
      }
    } else {
      setEngineerDetails({
        ...engineerDetails,
        inChargeName: '',
        inChargeType: '',
        inChargeProfession: '',
        inChargePrcNo: '',
        inChargePtrNo: '',
        inChargeID: '',
        idInCharge: '',
        idDesign: '',
        designName: '',
        designProfession: '',
        designType: '',
        designPrcNo: '',
        designPtrNo: '',
        designChargeID: '',
      })
    }

    if (userCredentials.roles !== 'evaluator') {
      setUserLevel(userCredentials.roles)
    } else {
      setUserLevel(userCredentials.subroles)
    }

    if (retrievedOrderofPayment && retrievedOrderofPayment.message) {
      setOpSubtotal(
        retrievedOrderofPayment.message[0].LineAndGrade +
          retrievedOrderofPayment.message[0].StructuralFees +
          retrievedOrderofPayment.message[0].ArchitecturalFees +
          retrievedOrderofPayment.message[0].AdditionalRenovation +
          retrievedOrderofPayment.message[0].RoofDeck +
          retrievedOrderofPayment.message[0].DemolitionPermitFees +
          retrievedOrderofPayment.message[0].AncillaryStructure +
          retrievedOrderofPayment.message[0].TombsCanopies +
          retrievedOrderofPayment.message[0].PlumbingPermitFees +
          retrievedOrderofPayment.message[0].ElectricalPermitFees +
          retrievedOrderofPayment.message[0].MechanicalPermitFees +
          retrievedOrderofPayment.message[0].FencingPermitFees +
          retrievedOrderofPayment.message[0].Sidewalks +
          retrievedOrderofPayment.message[0].PavedAreas +
          retrievedOrderofPayment.message[0].StreetSidewalk +
          retrievedOrderofPayment.message[0].CertificateOccupancy +
          retrievedOrderofPayment.message[0].ChangeOccupancy +
          retrievedOrderofPayment.message[0].CertificateOccupancy10 +
          retrievedOrderofPayment.message[0].ElectronicsPermit +
          retrievedOrderofPayment.message[0].AnnualInspection +
          retrievedOrderofPayment.message[0].SignPermitFees +
          retrievedOrderofPayment.message[0].Certification +
          retrievedOrderofPayment.message[0].AdministrativeFive
      )
    }
  }, [
    applicationsDetail,
    searchTerm,
    userCredentials,
    retrievedEngrList,
    recordEngineer,
    retrievedRecordedEng,
    retrievedOrderofPayment,
  ])

  // console.log(forUpdate)

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='mt-4 shadow-lg rounded'
          style={{ background: '#FFF' }}
        >
          {!loading ? (
            error ? (
              <Row className='py-5 mx-1'>
                <Col xl={12}>
                  <Card>
                    <Card.Body className='mt-2'>
                      <h5>No application created ...</h5>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              <>
                <Form>
                  <Row className='justify-content-end px-3 pt-5'>
                    {/* <Col md={8}>
                      <Button
                        variant='info'
                        size='sm'
                        onClick={() => setModal({ ...modal, viewFilter: true })}
                      >
                        Filter
                      </Button>
                    </Col> */}
                    <Col md={4}>
                      <InputGroup>
                        <Form.Control
                          style={{
                            background: '#FFF',
                            border: '1px solid #ced4da',
                          }}
                          value={searchTerm}
                          placeholder='Input search keyword here . . .'
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm === '' ? (
                          <InputGroup.Text className='bg-info text-light'>
                            <FaSearch />
                          </InputGroup.Text>
                        ) : (
                          <Button
                            variant='danger'
                            onClick={() => setSearchTerm('')}
                          >
                            x
                          </Button>
                        )}
                      </InputGroup>
                    </Col>
                  </Row>
                </Form>
                {/* For Application Status Table */}
                <Row className='mx-3'>
                  <Col
                    xl={12}
                    className='border border-secondary rounded px-0'
                    style={{ background: '#FFF' }}
                  >
                    <h5 className='pt-4 pb-2 mb-0'>Application Status</h5>
                    <hr
                      className='mt-1 mb-3'
                      style={{
                        background: '#ffeb36',
                        paddingBottom: '2px',
                        width: '200px',
                      }}
                    />

                    <Table bordered hover responsive size='sm'>
                      <thead className='bg-info text-light'>
                        <tr>
                          <th>Application No.</th>
                          <th>Application</th>
                          <th>Status</th>
                          <th>Remarks</th>
                          <th>Date Transferred</th>
                          <th className='text-center'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dfpaginatedForms.map((x, index) => (
                          <tr key={index}>
                            <td className='text-center'>{x.appID}</td>
                            <td>{x.ApplicationName}</td>
                            <td>{x.status}</td>
                            <td>{x.remarks}</td>
                            <td className='text-nowrap'>
                              {moment(x.TimeStamp).format('MMMM D, YYYY')}
                            </td>
                            <td>
                              <div className='d-flex justify-content-center'>
                                {x.status === 'APPLICATION VERIFICATION' ||
                                x.status === 'FOR COMPLIANCE' ? (
                                  <>
                                    <Button
                                      size='sm'
                                      variant='info'
                                      className='text-nowrap'
                                      onClick={() => handleEditModal(x)}
                                    >
                                      {/* <FaRegEdit className='fs-4 mx-1' /> */}
                                      EDIT
                                    </Button>
                                    <Button
                                      size='sm'
                                      variant='danger'
                                      className='text-nowrap mx-1'
                                      onClick={() => handleDelete(x.appID)}
                                    >
                                      {/* <FaTrashAlt className='fs-4 mx-1' /> */}
                                      DELETE
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    size='sm'
                                    variant='success'
                                    className='text-nowrap'
                                    onClick={() => handleViewApp(x)}
                                  >
                                    {/* <FaEye className='fs-4 mx-1' /> */}
                                    VIEW
                                  </Button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination
                      size='sm'
                      className='d-flex justify-content-end py-2 mx-2 my-2'
                    >
                      <Pagination.Item
                        className='mt-0 fs-6 text-nowrap'
                        onClick={dfhandlePrevbtn}
                        disabled={dfcurrentPage === dfpages[0] ? true : false}
                      >
                        &lt;
                      </Pagination.Item>

                      {dfrenderPageNumber}

                      <Pagination.Item
                        className='mt-0 fs-6 text-nowrap'
                        onClick={dfhandleNextbtn}
                        disabled={
                          dfcurrentPage === dfpages[dfpages.length - 1]
                            ? true
                            : false
                        }
                      >
                        &gt;
                      </Pagination.Item>
                    </Pagination>
                  </Col>
                </Row>
              </>
            )
          ) : (
            <div className='text-center py-5'>
              <Spinner animation='border' />
              <h3>Loading...</h3>
            </div>
          )}
        </Container>
      </Container>

      {/* Edit Application Modal */}
      <Modal
        size='xl'
        show={show}
        onHide={() => setShow(false)}
        centered
        backdrop='static'
      >
        {InitialState.id !== '' ? (
          <>
            <Form onSubmit={handleSubmit}>
              <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
                <Modal.Title style={{ color: '#FFF' }}>
                  Edit Application
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={12}>
                    <>
                      <Row>
                        {forUpdate.ApplicationName ===
                          'BUILDING CONSTRUCTION PERMIT' && (
                          <Col xl={9}>
                            <h5>ATTACHED PERMIT TRADES</h5>
                            <p>Click the trades that include to your files</p>
                          </Col>
                        )}
                        {forUpdate.ApplicationName !== 'OCCUPANCY PERMIT' && (
                          <Row className='mb-1'>
                            <Col xl={9}></Col>
                            <Col className='text-end' xl={3}>
                              Edit Registered Engineer
                              <Button
                                className='mx-1'
                                variant='outline-success'
                                size='sm'
                                onClick={() => {
                                  setModal({ ...modal, viewEngineer: true })
                                  setShow(false)
                                }}
                              >
                                <FaPenAlt />
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Row>

                      {forUpdate.ApplicationName ===
                        'BUILDING CONSTRUCTION PERMIT' && (
                        <Row>
                          {_.zip(
                            [
                              'STRUCTURAL',
                              'MECHANICAL',
                              'ARCHITECTURAL',
                              'ELECTRICAL',
                              'PLUMBING/SANITARY',
                              'ELECTRONICS',
                            ],
                            [
                              'Structural',
                              'Mechanical',
                              'Architectural',
                              'Electrical',
                              'Plumbing',
                              'Electronics',
                            ],
                            [
                              forUpdate.Structural,
                              forUpdate.Mechanical,
                              forUpdate.Architectural,
                              forUpdate.Electrical,
                              forUpdate.Plumbing,
                              forUpdate.Electronics,
                            ]
                          ).map((trades) => (
                            <Form.Group
                              className='my-2'
                              as={Col}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={3}
                              key={trades[0]}
                            >
                              <Form.Check
                                type='checkbox'
                                label={trades[0]}
                                name={trades[1]}
                                checked={trades[2] === 1 ? true : false}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          ))}
                        </Row>
                      )}

                      <hr />
                    </>

                    {forUpdate.ApplicationName === 'OCCUPANCY PERMIT' && (
                      <>
                        <h5>Building Permit No.</h5>
                        <Form.Group className='mb-3' as={Col} md={12}>
                          <Form.Control
                            type='text'
                            className='mt-2'
                            name='BpNo'
                            placeholder='Input building permit no. here ...'
                            value={forUpdate.BpNo}
                            style={{
                              background: '#FFF',
                              border: '1px solid #ced4da',
                            }}
                            required
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </>
                    )}
                    <h5>Remarks</h5>
                    <p className='mx-3'>{forUpdate.Remarks}</p>

                    <h5>Link</h5>
                    <Row>
                      <Form.Group as={Col} md={12}>
                        <Form.Control
                          type='text'
                          className='mt-2'
                          name='Link'
                          value={forUpdate.Link}
                          style={{
                            background: '#FFF',
                            border: '1px solid #ced4da',
                          }}
                          required
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button size='sm' variant='info' className='mx-1' type='submit'>
                  SUBMIT
                </Button>
                <Button
                  size='sm'
                  variant='danger'
                  onClick={() => setShow(false)}
                >
                  CANCEL
                </Button>
              </Modal.Footer>
            </Form>
          </>
        ) : (
          <></>
        )}
      </Modal>
      {/* End Edit Application Modal */}

      <Modal
        show={transactShow}
        onHide={() => setTransactShow(false)}
        centered
        backdrop='static'
      >
        {updatedMessage && updatedMessage.message ? (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheckCircle className='mx-1' />
                Transaction Successfully Completed
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className='text-center'>{updatedMessage.message}</h6>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              <Button variant='info' onClick={handleConfirm}>
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : updatedMessage && updatedMessage.error ? (
          <>
            <Modal.Header style={{ background: '#d9534f' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheckCircle className='mx-1' />
                Unsuccessfull Transaction
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className='text-center'>{updatedMessage.error}</h6>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              <Button variant='info' onClick={handleConfirm}>
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : (
          updatedMessage && (
            <>
              <div className='text-center pt-3'>
                <Spinner animation='border' />
                <h6>Loading...</h6>
              </div>
            </>
          )
        )}

        {deletedMessage && deletedMessage.message ? (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheckCircle className='mx-3' />
                Transaction Successfully Completed
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3 className='text-center'>{deletedMessage.message}</h3>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              <Button variant='info' onClick={handleConfirm}>
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : deletedMessage && deletedMessage.error ? (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheckCircle className='mx-3' />
                Transaction Successfully Completed
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3 className='text-center'>{deletedMessage.error}</h3>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              <Button variant='info' onClick={handleConfirm}>
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : (
          deletedMessage && (
            <>
              <div className='text-center pt-3'>
                <Spinner animation='border' />
                <h3>Loading...</h3>
              </div>
            </>
          )
        )}
      </Modal>

      {/* List Inspection Report */}
      <Modal
        size='md'
        show={modal.viewListInspection}
        onHide={() => setModal({ viewListInspection: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> List of Inspection Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {retrievedListReport && retrievedListReport.error && (
            <h5 className='mt-3 text-center'>{retrievedListReport.error}</h5>
          )}

          {retrievedListReport && retrievedListReport.message && (
            <ListGroup variant='flush'>
              <ListGroup.Item action>
                Initial Inspection - January 31, 2023 8:22am
              </ListGroup.Item>
            </ListGroup>
          )}

          {retrievedListReport && retrievedListReport.loading && (
            <div className='text-center pt-3'>
              <Spinner animation='border' />
              <h3>Loading...</h3>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={() => {
              setShowViewApp(true)
              setModal({ viewListInspection: false })
            }}
          >
            BACK
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End List Inspection Report */}

      {/* View Application */}
      <Modal
        size='xl'
        show={showViewApp}
        onHide={() => setShowViewApp(false)}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> View Records
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!appTrack ? (
            <>
              <Form>
                <Row>
                  <Col md={12}>
                    {forUpdate.ApplicationName ===
                      'BUILDING CONSTRUCTION PERMIT' && (
                      <>
                        <h5>ATTACHED PERMIT TRADES</h5>
                        <p>Click the trades included your files</p>
                        <Row>
                          {_.zip(
                            [
                              'STRUCTURAL',
                              'MECHANICAL',
                              'ARCHITECTURAL',
                              'ELECTRICAL',
                              'PLUMBING/SANITARY',
                              'ELECTRONICS',
                            ],
                            [
                              'Structural',
                              'Mechanical',
                              'Architectural',
                              'Electrical',
                              'Plumbing',
                              'Electronics',
                            ],
                            [
                              forUpdate.Structural,
                              forUpdate.Mechanical,
                              forUpdate.Architectural,
                              forUpdate.Electrical,
                              forUpdate.Plumbing,
                              forUpdate.Electronics,
                            ]
                          ).map((trades) => (
                            <Form.Group
                              className='my-2'
                              as={Col}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={3}
                              key={trades[0]}
                            >
                              <Form.Check
                                type='checkbox'
                                label={trades[0]}
                                name={trades[1]}
                                checked={trades[2] === 1 ? true : false}
                                disabled={true}
                              />
                            </Form.Group>
                          ))}
                        </Row>
                        <hr />
                      </>
                    )}

                    <h5>Remarks</h5>
                    <p className='mx-3'>{forUpdate.Remarks}</p>

                    <h5>Link</h5>
                    <Row>
                      <Form.Group as={Col} md={12}>
                        <Form.Control
                          type='text'
                          className='mt-2'
                          name='Link'
                          value={forUpdate.Link}
                          style={{
                            background: '#FFF',
                            border: '1px solid #ced4da',
                          }}
                          disabled={true}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
                <Table className='mt-3' bordered responsive hover>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {appTrack ? 'Application Summary' : 'Track Application'}
                      </td>
                      <td>
                        {!appTrack && (
                          <Button
                            variant='info'
                            className='text-light'
                            onClick={() => {
                              setAppTrack(true)
                              dispatch(
                                RetrieveStatusTrack({
                                  appID: forUpdate.Application_ID,
                                  userID: userCredentials.id,
                                })
                              )
                            }}
                            size='sm'
                          >
                            View
                          </Button>
                        )}
                      </td>
                    </tr>

                    {(forUpdate.Status === 'RELEASE APPLICATION WITH REMARKS' ||
                      forUpdate.Status === 'RELEASE APPLICATION') && (
                      <tr>
                        <td>
                          Certificate{' '}
                          {retrievedReleasePermit && (
                            <QRCodeCanvas
                              value={
                                retrievedReleasePermit
                                  ? retrievedReleasePermit.message &&
                                    'https://lp-bldgo.com/verify-permit/' +
                                      retrievedReleasePermit.message[0]
                                        .categCode +
                                      ' ' +
                                      retrievedReleasePermit.message[0].id
                                  : ''
                              }
                              className='d-none'
                            />
                          )}
                          {retrievedReleasedOccu && (
                            <QRCodeCanvas
                              value={
                                retrievedReleasedOccu
                                  ? retrievedReleasedOccu.message &&
                                    'https://lp-bldgo.com/verify-permit/' +
                                      retrievedReleasedOccu.message[0]
                                        .categCode +
                                      ' ' +
                                      retrievedReleasedOccu.message[0].id
                                  : ''
                              }
                              className='d-none'
                            />
                          )}
                        </td>
                        <td>
                          {forUpdate.ApplicationName === 'OCCUPANCY PERMIT' && (
                            <>
                              <Button
                                size='sm'
                                variant='success'
                                onClick={() => {
                                  encryptPDF(
                                    {
                                      payload: {
                                        applicantName:
                                          forUpdate.Application_ID !== '' &&
                                          forUpdate.Application_ID,
                                      },
                                    },
                                    <CertOccupancy
                                      info={
                                        retrievedReleasedOccu &&
                                        retrievedReleasedOccu.message &&
                                        retrievedReleasedOccu.message
                                      }
                                    />,
                                    handleSetEncrypt
                                  )
                                }}
                              >
                                Generate Certificate
                              </Button>

                              {retrievedReleasedOccu &&
                                retrievedReleasedOccu.loading && (
                                  <Spinner animation='border' size='sm' />
                                )}

                              {encryptedPdf && (
                                <Button
                                  size='sm'
                                  variant='info'
                                  className='mx-2'
                                >
                                  <a
                                    href={URL.createObjectURL(encryptedPdf)}
                                    download='CFEI.pdf'
                                    style={{
                                      textDecoration: 'none',
                                      color: '#FFF',
                                    }}
                                  >
                                    <FaArrowDown />
                                  </a>
                                </Button>
                              )}
                            </>
                            // <PDFDownloadLink
                            //   document={
                            // <CertOccupancy
                            //   info={
                            //     retrievedReleasedOccu &&
                            //     retrievedReleasedOccu.message &&
                            //     retrievedReleasedOccu.message
                            //   }
                            // />
                            //   }
                            //   fileName='CertificateOfOccupancy.pdf'
                            // >
                            //   {({ loading }) =>
                            //     loading ? (
                            //       'Loading document...'
                            //     ) : (
                            //       <Button variant='info' size='sm'>
                            //         Download
                            //       </Button>
                            //     )
                            //   }
                            // </PDFDownloadLink>
                          )}
                          {forUpdate.ApplicationName ===
                            'BUILDING CONSTRUCTION PERMIT' && (
                            <PDFDownloadLink
                              document={
                                <BldgPermit
                                  info={
                                    retrievedReleasePermit &&
                                    retrievedReleasePermit.message &&
                                    retrievedReleasePermit.message
                                  }
                                />
                              }
                              fileName='CertificateOfBuildingPermit.pdf'
                            >
                              {({ loading }) =>
                                loading ? (
                                  'Loading document...'
                                ) : (
                                  <Button variant='info' size='sm'>
                                    View
                                  </Button>
                                )
                              }
                            </PDFDownloadLink>
                          )}

                          {(forUpdate.ApplicationName === 'ELECTRICAL PERMIT' ||
                            forUpdate.ApplicationName === 'FENCING PERMIT' ||
                            forUpdate.ApplicationName ===
                              'DEMOLITION PERMIT') && (
                            <>
                              <Button
                                variant='info'
                                size='sm'
                                onClick={() => setShowToggle(true)}
                              >
                                View
                              </Button>
                              <Toast
                                onClose={() => setShowToggle(false)}
                                show={showToggle}
                                delay={3000}
                                autohide
                                className='mt-2'
                              >
                                <Toast.Body>
                                  The certificate, will be sent to your email.
                                  Thank you
                                </Toast.Body>
                              </Toast>
                            </>
                          )}
                        </td>
                      </tr>
                    )}

                    {(forUpdate.Status === 'APPROVED APPLICATION' ||
                      forUpdate.Status === 'RELEASE APPLICATION WITH REMARKS' ||
                      forUpdate.Status === 'RELEASE APPLICATION') && (
                      <tr>
                        <td>Order of Payment</td>
                        <td>
                          <Button
                            size='sm'
                            variant='info'
                            className=' text-nowrap'
                            onClick={() => {
                              setShowViewApp(false)
                              setModal({ viewOrderofPayment: true })

                              let tempPenaltyValue = 0

                              tempPenaltyValue =
                                opSubtotal *
                                retrievedOrderofPayment.message[0].Penalty

                              setPenaltyValue(tempPenaltyValue)
                            }}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>Evaluation Sheet</td>
                      <td>
                        <Button
                          onClick={() =>
                            // history.push(
                            //   `/evaluation-sheet/${forUpdate.Application_ID}`
                            // )
                            {
                              setShowViewApp(false)
                              setModal({
                                ...modal,
                                viewEvaluationSheet: true,
                              })
                              dispatch(
                                retrieveEvaluationSheet({
                                  id: forUpdate.Application_ID,
                                })
                              )
                            }
                          }
                          variant='info'
                          size='sm'
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </>
          ) : (
            <>
              {statusTracking && statusTracking.message ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <td></td>
                      <td className='text-center'>Date</td>
                      <td>Status</td>
                      <td>Remarks</td>
                      {/* <td>Trade</td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {_.reverse(
                      statusTracking.message.map((track, index) => (
                        <tr key={index}>
                          <td> {index + 1}.</td>
                          <td>
                            {' '}
                            {moment(track.TimeStamp).format(
                              'MMMM D, YYYY h:mma'
                            )}
                          </td>
                          <td>{track.status}</td>
                          <td>{track.remarks}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              ) : (
                <div className='text-center'>
                  <Loading />
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {appTrack && (
            <Row>
              <Col xl={12} className='d-flex justify-content-end'>
                <Button
                  variant='danger'
                  className='text-light'
                  onClick={() => setAppTrack(false)}
                  size='sm'
                >
                  Back
                </Button>
              </Col>
            </Row>
          )}
          {!appTrack && (
            <Button
              variant='danger'
              size='sm'
              onClick={() => {
                setShowViewApp(false)
                setAppTrack(false)
              }}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Modal Evaluation Sheet */}
      <EvaluationSheetScreen
        show={modal.viewEvaluationSheet}
        onHide={() => {
          setShowViewApp(true)
          setModal({
            ...modal,
            viewEvaluationSheet: false,
          })
        }}
        retrievedEvaluationSheet={retrievedEvaluationSheet}
        userLevel={userLevel}
      />

      {/* Order of Payment */}
      <Modal
        size='xl'
        show={modal.viewOrderofPayment}
        onHide={() => setModal({ viewOrderofPayment: false })}
        centered
        backdrop='static'
      >
        {retrievedOrderofPayment && retrievedOrderofPayment.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                ORDER OF PAYMENT DETAILS
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xl='12' className='text-center'>
                  <p className='m-0 fs-6'>Metropolitan Manila Authority</p>
                  <p className='m-0 fs-6'>
                    Action Center for Infrastructure Development
                  </p>
                  <h6 className='m-0 fs-6'>OFFICE OF THE BUILDING OFFICIAL</h6>
                  <p className='m-0 fs-6'>City of Las Piñas</p>
                  <p className='m-0 fs-6'>Metro Manila</p>
                </Col>
              </Row>
              <Row>
                <Col xl='6'>
                  <div className='d-flex'>
                    <h6>PERMIT CODE: </h6>
                    <p className='fs-6 m-0 font-italic'>
                      {retrievedOrderofPayment.message[0].categCode +
                        ' ' +
                        retrievedOrderofPayment.message[0].id}
                    </p>
                  </div>
                  <div className='d-flex'>
                    <h6>Owner/Firm/Applicant: </h6>
                    <p className='fs-6 m-0 font-italic'>
                      {retrievedOrderofPayment.message[0].Owner}
                    </p>
                  </div>
                  <div className='d-flex'>
                    <h6>Project: </h6>
                    <p className='fs-6 m-0 font-italic'>
                      {retrievedOrderofPayment.message[0].scopeOfWork}
                    </p>
                  </div>
                  <div className='d-flex'>
                    <h6>Location: </h6>
                    <p className='fs-6 m-0 font-italic'>
                      {retrievedOrderofPayment.message[0].Project_Location}
                    </p>
                  </div>
                </Col>
                <Col xl='6'>
                  <div className='d-flex justify-content-end'>
                    <h6 className='m-0 align-middle'>DATE: </h6>
                    <p className='fs-6 m-0 font-italic'>
                      {moment(
                        retrievedOrderofPayment.message[0].recommendDate
                      ).format('MMMM D, YYYY h:mma')}
                    </p>
                  </div>
                </Col>
              </Row>
              <h5 className='text-center'>ORDER OF PAYMENT</h5>
              <p className='fs-6'>TO: CITY/MUNICIPAL TREASURER/CASHIER</p>
              <p className='fs-6'>
                Please collect the amoun specified below as perscribed in the
                implementing Rules and Regulations of the National Building Code
                (PD-1096).
              </p>

              <Container className='px-5'>
                <Table>
                  <tbody>
                    <tr>
                      <td className='p-1 align-middle'>001 Line and Grade</td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].LineAndGrade}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        002 Building Permit Fees
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].StructuralFees +
                          retrievedOrderofPayment.message[0].ArchitecturalFees}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        Additional/Renovation
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {
                          retrievedOrderofPayment.message[0]
                            .AdditionalRenovation
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>Roof Deck</td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].RoofDeck}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        003 Demolition Permit Fees
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {
                          retrievedOrderofPayment.message[0]
                            .DemolitionPermitFees
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        004 Ancillary Structure
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].AncillaryStructure}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        005 Construction of Tombs/Canopies
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].TombsCanopies}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        006 Plumbing Permit Fees
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].PlumbingPermitFees}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        007 Electrical Permit Fees
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {
                          retrievedOrderofPayment.message[0]
                            .ElectricalPermitFees
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        008 Mechanical Permit Fees
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {
                          retrievedOrderofPayment.message[0]
                            .MechanicalPermitFees
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        009 Fencing Permit Fees
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].FencingPermitFees}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        010 Construction of Sidewalks
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].Sidewalks}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>011 Paved Areas</td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].PavedAreas}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        012 Use of Street and Sidewalks
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].StreetSidewalk}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        013 Certificate of Use/Occupancy
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {
                          retrievedOrderofPayment.message[0]
                            .CertificateOccupancy
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        014 Change in Use/Occupancy
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].ChangeOccupancy}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        {' '}
                        015 Certificate of Occupancy prior to 10
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {
                          retrievedOrderofPayment.message[0]
                            .CertificateOccupancy10
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        016 Electronics Permit
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].ElectronicsPermit}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        017 Annual Inspection
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].AnnualInspection}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>018 Sign Permit Fees</td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].SignPermitFees}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>019 Certification</td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].Certification}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        020 Penalty/Surcharge
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {penaltyValue}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        021 Administrative Fine
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].AdministrativeFive}
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-bold text-end p-1 align-middle'>
                        SUB-TOTAL
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].subTotal}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Container>
              <p className='fs-6 pt-4 '>
                Please collect the amount specified below as perscribed in the
                approved City Ordinance No. 1373 - 16 series of 2016 Las Piñas
                City.
              </p>
              <h5 className='text-center mt-4'>BUILDING PERMIT</h5>
              <Container className='px-5'>
                <Table>
                  <tbody>
                    <tr>
                      <td className='p-1 align-middle'>001 Filling Fee</td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].FilingFee}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>002 Processing Fees</td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].ProcessingFee}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>
                        003 Complaint Filling Fees
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].ComplaintFilingFee}
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1 align-middle'>004 Others (Specify)</td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].Others}
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-bold text-end p-1 align-middle'>
                        SUB-TOTAL
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].subTotal1}
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-bold text-end p-1 align-middle'>
                        TOTAL AMOUNT
                      </td>
                      <td className='p-1 align-middle text-end'>
                        {retrievedOrderofPayment.message[0].totalAmount}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <br />
                <Row>
                  <Col xl={6}>
                    GENERATED BY:
                    <br />
                    <br />
                    <br />
                    <h6 className='fw-bold mb-0'>
                      {retrievedOrderofPayment.message[0].RecommendedBy}
                    </h6>
                    Engineer V
                  </Col>
                  <Col xl={6} className='text-end'>
                    APPROVED FOR PAYMENT:
                    <br />
                    <br />
                    <br />
                    <h6 className='fw-bold mb-0'>
                      {retrievedOrderofPayment.message[0].CityEngineer}
                    </h6>
                    City Engineer/Building Official
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='success' onClick={handleInitializingPayment}>
                PAYMENT
              </Button>
              <PDFDownloadLink
                document={
                  <OrderPay
                    info={
                      retrievedOrderofPayment &&
                      retrievedOrderofPayment.message &&
                      retrievedOrderofPayment.message
                    }
                    penaltyValue={0}
                  />
                }
                fileName='OrderOfPayment.pdf'
              >
                {({ loading }) =>
                  loading ? (
                    'Loading document...'
                  ) : (
                    <Button variant='info'>PRINT</Button>
                  )
                }
              </PDFDownloadLink>

              <Button
                variant='danger'
                onClick={() => setModal({ viewOrderofPayment: false })}
              >
                BACK
              </Button>
            </Modal.Footer>
          </>
        )}

        {retrievedOrderofPayment && retrievedOrderofPayment.loading && (
          <Row className='my-4 mx-1'>
            <Col xl={12}>
              <Card>
                <Card.Body className='mt-2'>
                  <div className='text-center pt-3'>
                    <Spinner animation='border' />
                    <h3>Loading...</h3>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {retrievedOrderofPayment && retrievedOrderofPayment.error && (
          <div>
            <h5>No Data Found</h5>
          </div>
        )}
      </Modal>
      {/* End Order of Payment */}

      {/* Modal Payment Gateway */}
      <Modal
        // size='xl'
        show={modal.viewPaymentGateway}
        onHide={() => setModal({ viewPaymentGateway: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title className='fs-6' style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            Payment Transactions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {initializingPayment && initializingPayment.loading && (
            <Spinner animation='border' />
          )}
          {initializingPayment && !initializingPayment.loading && (
            <>
              <h6>
                If you want to proceed to the online payment, you will be
                directed to our payment channel if you press the button proceed
              </h6>
              <Button
                className='mt-2'
                variant='primary'
                size='sm'
                onClick={() =>
                  window.open(`${initializingPayment.message}`, '_blink')
                }
              >
                Proceed
              </Button>
            </>
          )} */}
          <h5>Payment Instructions</h5>
          <hr style={{ width: '45%', marginTop: '0px' }} />

          <h4>We are working to serve you better</h4>

          <hr />

          <p>
            1.Print the order of payment and bring your own copy of signed &
            sealed Bill of materials
          </p>
          <p>2.Proceed to Counter 32/33 for Contractor's / Special Tax</p>
          <p>3.Proceed to miscellaneous Counter for payment</p>
          <p>
            4. Present a clear photocopy of the Official Receipts to the Office
            of the Building Official to claim or download your permit on your
            account.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            size='sm'
            onClick={() =>
              setModal({ viewPaymentGateway: false, viewOrderofPayment: true })
            }
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Payment Gateway */}

      <Modal
        size='lg'
        show={modal.viewFilter}
        onHide={() => setModal({ ...modal, viewFilter: false })}
        centered
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> Filter
          </Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            // console.log(filterValue)
          }}
        >
          <Modal.Body>
            <Form.Group>
              <Form.Label>Permit Category</Form.Label>
              <Form.Control
                as='select'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                size='sm'
                value={filterValue.category}
                onChange={(e) =>
                  setFilterValue({ ...filterValue, category: e.target.value })
                }
              >
                <option>Choose category here ...</option>
                <option value='BUILDING CONSTRUCTION PERMIT'>
                  BUILDING CONSTRUCTION PERMIT
                </option>
                <option value='OCCUPANCY PERMIT'>OCCUPANCY PERMIT</option>
                <option value='FENCING PERMIT'>FENCING PERMIT</option>
                <option value='DEMOLITION PERMIT'>DEMOLITION PERMIT</option>
                <option value='ELECTRICAL PERMIT'>ELECTRICAL PERMIT</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label className='mt-2'>Status</Form.Label>
              <Form.Control
                as='select'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                size='sm'
                value={filterValue.status}
                onChange={(e) =>
                  setFilterValue({ ...filterValue, status: e.target.value })
                }
              >
                <option>Choose status here ...</option>
                <option value='APPLICATION VERIFICATION'>
                  APPLICATION VERIFICATION
                </option>
                <option value='FOR INSPECTION AND EVALUATION'>
                  FOR INSPECTION AND EVALUATION
                </option>
                <option value='FOR APPROVAL'>FOR APPROVAL</option>
                <option value='APPROVED APPLICATION'>
                  APPROVED APPLICATION
                </option>
                <option value='RELEASE APPLICATION WITH REMARKS'>
                  RELEASE APPLICATION WITH REMARKS
                </option>
                <option value='RELEASE APPLICATION '>
                  RELEASE APPLICATION
                </option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => setModal({ filter: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size='md'
        show={modal.isProfession}
        onHide={() => setModal({ ...modal, isProfession: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            {isTrade} Professional
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitEngrDetails}>
          <Modal.Body className='pb-1'>
            <h5>List of registered engineer & architect</h5>

            <p className='p-0 m-0'>SUPERVISOR / IN-CHARGE</p>

            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='inChargeName'
                  onChange={onChange}
                  value={engineerDetails.inChargeName}
                  autoComplete='off'
                  required
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.inChargeName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClick(data)}
                        >
                          {data.Name + ' ' + data.ProfessionName}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargeProfession}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePrcNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePtrNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <p className='p-0 m-0 mt-3'>
              DESIGN PROFESSIONAL, PLANS AND SPECIFICATION
            </p>
            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>

                <Form.Control
                  type='text'
                  name='designName'
                  onChange={onChange}
                  value={engineerDetails.designName}
                  autoComplete='off'
                  required
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.designName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClickDesign(data)}
                        >
                          {data.Name + ' ' + data.ProfessionName}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designProfession}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designPrcNo}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designPtrNo}
                  required
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer className='p-1'>
            <Button variant='info' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  ...modal,
                  isProfession: false,
                })

                setEngineerDetails({
                  trade: '',
                  inChargeName: '',
                  inChargeType: '',
                  inChargeProfession: '',
                  inChargePrcNo: '',
                  inChargePrcValidity: '',
                  inChargePtrNo: '',
                  inChargePtrValidity: '',
                  inChargeID: '',
                  designName: '',
                  designProfession: '',
                  designType: '',
                  designPrcNo: '',
                  designPrcValidity: '',
                  designPtrNo: '',
                  designPtrValidity: '',
                  designChargeID: '',
                })

                setForUpdate({
                  ...forUpdate,
                  [`${isTrade}`]: 0,
                })
                setShow(true)
              }}
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size='md'
        show={modal.viewEngineer}
        onHide={() => setModal({ ...modal, viewEngineer: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>Edit Engineers</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleUpdateEngineerSubmit}>
          <Modal.Body>
            <Form.Control
              as='select'
              size='sm'
              onChange={(e) => {
                if (e.target.value === '') {
                  setEngineerDetails({
                    ...engineerDetails,
                    disabled: true,
                    selectedTrade: '',
                  })
                } else {
                  setEngineerDetails({
                    ...engineerDetails,
                    disabled: false,
                    selectedTrade: e.target.value,
                  })
                  dispatch(
                    retrieveRecordedEng({
                      appID: forUpdate.AppID,
                      trade: e.target.value,
                    })
                  )
                }
              }}
            >
              <option value=''>Choose trades here...</option>
              {forUpdate.ApplicationName === 'BUILDING CONSTRUCTION PERMIT' &&
                [
                  ['STRUCTURAL', 'Structural'],
                  ['ARCHITECTURAL', 'Architectural'],
                  ['MECHANICAL', 'Mechanical'],
                  ['ELECTRICAL', 'Electrical'],
                  ['PLUMBING/SANITARY', 'Plumbing'],
                  ['ELECTRONICS', 'Electronics'],
                ].map((e, index) => (
                  <option key={index} value={e[1]}>
                    {e[0]}
                  </option>
                ))}

              {forUpdate.ApplicationName === 'FENCING PERMIT' && (
                <option value='Architectural'>ARCHITECTURAL</option>
              )}

              {forUpdate.ApplicationName === 'DEMOLITION PERMIT' && (
                <option value='Architectural'>ARCHITECTURAL</option>
              )}

              {forUpdate.ApplicationName === 'ELECTRICAL PERMIT' && (
                <option value='Electrical'>ELECTRICAL</option>
              )}
            </Form.Control>
            <hr />
            <h6>List of registered engineer & architect</h6>

            <p className='p-0 m-0'>SUPERVISOR / IN-CHARGE</p>

            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='inChargeName'
                  onChange={onChange}
                  value={engineerDetails.inChargeName}
                  autoComplete='off'
                  required
                  size='sm'
                  disabled={engineerDetails.disabled}
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.inChargeName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClick(data)}
                        >
                          {data.Name + ' ' + data.ProfessionName}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargeProfession}
                  onChange={() => {}}
                  required
                  disabled={engineerDetails.disabled}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePrcNo}
                  onChange={() => {}}
                  required
                  disabled={engineerDetails.disabled}
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePtrNo}
                  onChange={() => {}}
                  required
                  disabled={engineerDetails.disabled}
                />
              </Form.Group>
            </Row>

            {(forUpdate.ApplicationName === 'BUILDING CONSTRUCTION PERMIT' ||
              forUpdate.ApplicationName === 'ELECTRICAL PERMIT') && (
              <>
                <p className='p-0 m-0 mt-3'>
                  DESIGN PROFESSIONAL, PLANS AND SPECIFICATION
                </p>
                <hr
                  className='my-1'
                  style={{
                    background: '#ffeb36',
                    paddingBottom: '2px',
                    width: '200px',
                  }}
                />
                <i style={{ fontSize: '11px' }}>
                  Note: Last Name First Name Middle Name "Select an engineer or
                  architect on the list"
                </i>
                <Row>
                  <Form.Group as={Col} xl={6}>
                    <Form.Label className='m-0'>
                      <h6 className='my-2'>
                        Engineer / Architect : <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>

                    <Form.Control
                      type='text'
                      name='designName'
                      onChange={onChange}
                      value={engineerDetails.designName}
                      autoComplete='off'
                      required
                      size='sm'
                      disabled={engineerDetails.disabled}
                    />
                    <div className='dropdown'>
                      {retrievedEngrList &&
                        retrievedEngrList.message &&
                        dataList
                          .filter((item) => {
                            let searchTerm =
                              engineerDetails.designName.toLowerCase()
                            let fullName = item.Name.toLowerCase()

                            return (
                              searchTerm &&
                              fullName.startsWith(searchTerm) &&
                              fullName !== searchTerm
                            )
                          })
                          .map((data, index) => (
                            <div
                              key={index}
                              className='dropdown-row'
                              onClick={() => handleOnClickDesign(data)}
                            >
                              {data.Name + ' ' + data.ProfessionName}
                            </div>
                          ))}
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} xl={6}>
                    <Form.Label className='m-0'>
                      <h6 className='my-2'>
                        Profession : <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      onChange={() => {}}
                      value={engineerDetails.designProfession}
                      required
                      disabled={engineerDetails.disabled}
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} xl={6}>
                    <Form.Label className='m-0'>
                      <h6 className='my-2'>
                        PRC No. : <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      onChange={() => {}}
                      value={engineerDetails.designPrcNo}
                      required
                      disabled={engineerDetails.disabled}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={6}>
                    <Form.Label className='m-0'>
                      <h6 className='my-2'>
                        PTR No. : <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      onChange={() => {}}
                      value={engineerDetails.designPtrNo}
                      required
                      disabled={engineerDetails.disabled}
                    />
                  </Form.Group>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' size='sm' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              size='sm'
              onClick={() => {
                setModal({ ...modal, viewEngineer: false })
                setShow(true)
                setEngineerDetails({
                  trade: '',
                  inChargeName: '',
                  inChargeType: '',
                  inChargeProfession: '',
                  inChargePrcNo: '',
                  inChargePrcValidity: '',
                  inChargePtrNo: '',
                  inChargePtrValidity: '',
                  inChargeID: '',
                  designName: '',
                  designProfession: '',
                  designType: '',
                  designPrcNo: '',
                  designPrcValidity: '',
                  designPtrNo: '',
                  designPtrValidity: '',
                  designChargeID: '',
                  disabled: true,
                })
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </UserMain>
  )
}

export default withRouter(UserApplicationStatus)
