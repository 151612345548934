export const createReport = [
  {
    id: 1,
    headers: ['LINE AND GRADE', 'EXISTING', 'REMARKS'],
    categories: ['Existing Trees', 'Lamp', 'Sign Boards', 'Ramp', 'Plant Box'],
  },
  {
    id: 2,
    headers: ['UTILITIES', 'EXISTING', 'REMARKS'],
    categories: [
      'Power Lines',
      'High Tension Wires',
      'Secondary Wires',
      'Guy Wires',
      'Telecommunications',
      'Aerial',
      'Underground',
      'Water Lines',
    ],
  },
  {
    id: 3,
    headers: ['ON-GOING CONSTRUCTION', 'EXISTING', 'REMARKS'],
    categories: [
      'Excavation',
      'Second Floor Beam',
      'Finising Stage',
      'Construction Materials',
      'Construction Debris',
    ],
  },
  {
    id: 4,
    headers: ['BOUNDARY LINES', 'EXISTING', 'REMARKS'],
    categories: [
      'Marking of boundary lines',
      'Signage',
      'Conforms with submitted plan',
    ],
  },
  {
    id: 5,
    headers: ['REAR', 'EXISTING', 'REMARKS'],
    categories: ['Rear_Firewall', 'Rear_Seatback'],
  },
  {
    id: 6,
    headers: ['LEFT', 'EXISTING', 'REMARKS'],
    categories: ['Left_Firewall', 'Left_Seatback'],
  },
  {
    id: 7,
    headers: ['RIGHT', 'EXISTING', 'REMARKS'],
    categories: ['Right_Firewall', 'Right_Seatback'],
  },
  {
    id: 8,
    headers: ['FRONT', 'EXISTING', 'REMARKS'],
    categories: ['Road Lot', 'Alley', 'Right of Way (ROW)', 'Access Road'],
  },
]
