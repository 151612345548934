import {
  APP_RETRIEVE_BY_STATUS_REQ,
  APP_RETRIEVE_BY_STATUS_SUCCESS,
  APP_RETRIEVE_BY_STATUS_FAILED,
  APP_DC_CHANGE_STATUS_REQ,
  APP_DC_CHANGE_STATUS_SUCCESS,
  APP_DC_CHANGE_STATUS_FAILED,
  REQUIRED_DOCUMENTS_REQ,
  REQUIRED_DOCUMENTS_SUCCESS,
  REQUIRED_DOCUMENTS_FAILED,
  GET_EVALUATORS_REQ,
  GET_EVALUATORS_SUCCESS,
  GET_EVALUATORS_FAILED,
  GET_INSPECTORS_REQ,
  GET_INSPECTORS_SUCCESS,
  GET_INSPECTORS_FAILED,
  GET_APPLICATION_INFORMATION_REQ,
  GET_APPLICATION_INFORMATION_SUCCESS,
  GET_APPLICATION_INFORMATION_FAILED,
  UPDATE_APPLICATION_INFORMATION_REQ,
  UPDATE_APPLICATION_INFORMATION_SUCCESS,
  UPDATE_APPLICATION_INFORMATION_FAILED,
  ADD_APPLICATION_INFORMATION_REQ,
  ADD_APPLICATION_INFORMATION_SUCCESS,
  ADD_APPLICATION_INFORMATION_FAILED,
  GET_SUBMITTED_DOCS_REQ,
  GET_SUBMITTED_DOCS_SUCCESS,
  GET_SUBMITTED_DOCS_FAILED,
  UPDATE_SUBMITTED_DOCS_REQ,
  UPDATE_SUBMITTED_DOCS_SUCCESS,
  UPDATE_SUBMITTED_DOCS_FAILED,
  SUBMIT_REQUIRED_DOCS_REQ,
  SUBMIT_REQUIRED_DOCS_SUCCESS,
  SUBMIT_REQUIRED_DOCS_FAILED,
  APP_ENGINEER_INFO_REQ,
  APP_ENGINEER_INFO_SUCCESS,
  APP_ENGINEER_INFO_FAILED,
  UPDATE_ENGINEER_INFO_REQ,
  UPDATE_ENGINEER_INFO_SUCCESS,
  UPDATE_ENGINEER_INFO_FAILED,
  GET_SUBMITTED_EVALUATORS_REQ,
  GET_SUBMITTED_EVALUATORS_SUCCESS,
  GET_SUBMITTED_EVALUATORS_FAILED,
  UPDATE_SUBMITTED_EVALUATORS_REQ,
  UPDATE_SUBMITTED_EVALUATORS_SUCCESS,
  UPDATE_SUBMITTED_EVALUATORS_FAILED,
  GET_SUBMITTED_INSPECTOR_REQ,
  GET_SUBMITTED_INSPECTOR_SUCCESS,
  GET_SUBMITTED_INSPECTOR_FAILED,
  UPDATE_SUBMITTED_INSPECTOR_REQ,
  UPDATE_SUBMITTED_INSPECTOR_SUCCESS,
  UPDATE_SUBMITTED_INSPECTOR_FAILED,
  GET_DATEAPPLIED_REQ,
  GET_DATEAPPLIED_SUCCESS,
  GET_DATEAPPLIED_FAILED,
} from '../constants/adminApplicationConstants'

export const adminApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case APP_RETRIEVE_BY_STATUS_REQ:
      return { ...state, loading: true, applicationDetails: { loading: true } }
    case APP_RETRIEVE_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        applicationDetails: {
          loading: false,
          message: action.payload,
          // forPayment: action.payload.forPayment,
          // forCompliance: action.payload.forCompliance,
          // forScheduling: action.payload.forScheduling,
          // Approved: action.payload.Approved,
          // Rejected: action.payload.Rejected,
        },
      }
    case APP_RETRIEVE_BY_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        applicationDetails: { loading: false, error: action.payload },
      }
    case APP_DC_CHANGE_STATUS_REQ:
      return { ...state, dcApplicationChange: { loading: true } }
    case APP_DC_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        dcApplicationChange: { loading: false, message: action.payload },
      }
    case APP_DC_CHANGE_STATUS_FAILED:
      return {
        ...state,
        dcApplicationChange: { loading: false, error: action.payload },
      }
    case REQUIRED_DOCUMENTS_REQ:
      return {
        ...state,
        requireDocs: { loading: true },
      }
    case REQUIRED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        requireDocs: { loading: false, message: action.payload },
      }
    case REQUIRED_DOCUMENTS_FAILED:
      return {
        ...state,
        requireDocs: { loading: false, error: action.payload },
      }
    case GET_EVALUATORS_REQ:
      return {
        ...state,
        evaluators: { loading: true },
      }
    case GET_EVALUATORS_SUCCESS:
      return {
        ...state,
        evaluators: { loading: false, message: action.payload },
      }
    case GET_EVALUATORS_FAILED:
      return {
        ...state,
        evaluators: { loading: false, error: action.payload },
      }
    case GET_INSPECTORS_REQ:
      return {
        ...state,
        inspectors: { loading: true },
      }
    case GET_INSPECTORS_SUCCESS:
      return {
        ...state,
        inspectors: { loading: false, message: action.payload },
      }
    case GET_INSPECTORS_FAILED:
      return {
        ...state,
        inspectors: { loading: false, error: action.payload },
      }
    case GET_APPLICATION_INFORMATION_REQ:
      return {
        ...state,
        applicationInfo: { loading: true },
      }
    case GET_APPLICATION_INFORMATION_SUCCESS:
      return {
        ...state,
        applicationInfo: { loading: false, message: action.payload },
      }
    case GET_APPLICATION_INFORMATION_FAILED:
      return {
        ...state,
        applicationInfo: { loading: false, error: action.payload },
      }
    case UPDATE_APPLICATION_INFORMATION_REQ:
      return {
        ...state,
        appUpdate: { loading: true },
      }
    case UPDATE_APPLICATION_INFORMATION_SUCCESS:
      return {
        ...state,
        appUpdate: { loading: false, message: action.payload },
      }
    case UPDATE_APPLICATION_INFORMATION_FAILED:
      return {
        ...state,
        appUpdate: { loading: false, error: action.payload },
      }
    case ADD_APPLICATION_INFORMATION_REQ:
      return {
        ...state,
        addApplication: { loading: true },
      }
    case ADD_APPLICATION_INFORMATION_SUCCESS:
      return {
        ...state,
        addApplication: { loading: false, message: action.payload },
      }
    case ADD_APPLICATION_INFORMATION_FAILED:
      return {
        ...state,
        addApplication: { loading: false, error: action.payload },
      }
    case GET_SUBMITTED_DOCS_REQ:
      return {
        ...state,
        submittedDocs: { loading: true },
      }
    case GET_SUBMITTED_DOCS_SUCCESS:
      return {
        ...state,
        submittedDocs: { loading: false, message: action.payload },
      }
    case GET_SUBMITTED_DOCS_FAILED:
      return {
        ...state,
        submittedDocs: { loading: false, error: action.payload },
      }
    case UPDATE_SUBMITTED_DOCS_REQ:
      return {
        ...state,
        updateSubmitDocs: { loading: true },
      }
    case UPDATE_SUBMITTED_DOCS_SUCCESS:
      return {
        ...state,
        updateSubmitDocs: { loading: false, message: action.payload },
      }
    case UPDATE_SUBMITTED_DOCS_FAILED:
      return {
        ...state,
        updateSubmitDocs: { loading: false, error: action.payload },
      }
    case SUBMIT_REQUIRED_DOCS_REQ:
      return {
        ...state,
        submitDocuments: { loading: true },
      }
    case SUBMIT_REQUIRED_DOCS_SUCCESS:
      return {
        ...state,
        submitDocuments: { loading: false, message: action.payload },
      }
    case SUBMIT_REQUIRED_DOCS_FAILED:
      return {
        ...state,
        submitDocuments: { loading: false, error: action.payload },
      }
    case APP_ENGINEER_INFO_REQ:
      return {
        ...state,
        submittedEng: { loading: true },
      }
    case APP_ENGINEER_INFO_SUCCESS:
      return {
        ...state,
        submittedEng: { loading: false, message: action.payload },
      }
    case APP_ENGINEER_INFO_FAILED:
      return {
        ...state,
        submittedEng: { loading: false, error: action.payload },
      }
    case UPDATE_ENGINEER_INFO_REQ:
      return {
        ...state,
        updatedEngInfo: { loading: true },
      }
    case UPDATE_ENGINEER_INFO_SUCCESS:
      return {
        ...state,
        updatedEngInfo: { loading: false, message: action.payload },
      }
    case UPDATE_ENGINEER_INFO_FAILED:
      return {
        ...state,
        updatedEngInfo: { loading: false, error: action.payload },
      }
    case GET_SUBMITTED_EVALUATORS_REQ:
      return {
        ...state,
        submittedEvaluators: { loading: true },
      }
    case GET_SUBMITTED_EVALUATORS_SUCCESS:
      return {
        ...state,
        submittedEvaluators: { loading: false, message: action.payload },
      }
    case GET_SUBMITTED_EVALUATORS_FAILED:
      return {
        ...state,
        submittedEvaluators: { loading: false, error: action.payload },
      }
    case UPDATE_SUBMITTED_EVALUATORS_REQ:
      return {
        ...state,
        updateEvaluators: { loading: true },
      }
    case UPDATE_SUBMITTED_EVALUATORS_SUCCESS:
      return {
        ...state,
        updateEvaluators: { loading: false, message: action.payload },
      }
    case UPDATE_SUBMITTED_EVALUATORS_FAILED:
      return {
        ...state,
        updateEvaluators: { loading: false, error: action.payload },
      }
    case GET_SUBMITTED_INSPECTOR_REQ:
      return {
        ...state,
        submittedInspector: { loading: true },
      }
    case GET_SUBMITTED_INSPECTOR_SUCCESS:
      return {
        ...state,
        submittedInspector: { loading: false, message: action.payload },
      }
    case GET_SUBMITTED_INSPECTOR_FAILED:
      return {
        ...state,
        submittedInspector: { loading: false, error: action.payload },
      }
    case UPDATE_SUBMITTED_INSPECTOR_REQ:
      return {
        ...state,
        updateInspector: { loading: true },
      }
    case UPDATE_SUBMITTED_INSPECTOR_SUCCESS:
      return {
        ...state,
        updateInspector: { loading: false, message: action.payload },
      }
    case UPDATE_SUBMITTED_INSPECTOR_FAILED:
      return {
        ...state,
        updateInspector: { loading: false, error: action.payload },
      }
    case GET_DATEAPPLIED_REQ:
      return {
        ...state,
        dateApplied: { loading: true },
      }
    case GET_DATEAPPLIED_SUCCESS:
      return {
        ...state,
        dateApplied: { loading: false, message: action.payload },
      }
    case GET_DATEAPPLIED_FAILED:
      return {
        ...state,
        dateApplied: { loading: false, error: action.payload },
      }

    default:
      return state
  }
}
