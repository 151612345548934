import {
  GET_ARCH_EVALUATION_DETAILS_REQ,
  GET_ARCH_EVALUATION_DETAILS_SUCCESS,
  GET_ARCH_EVALUATION_DETAILS_FAILED,
  CREATE_ARCH_EVALUATION_REQ,
  CREATE_ARCH_EVALUATION_SUCCESS,
  CREATE_ARCH_EVALUATION_FAILED,
  UPDATE_ARCH_EVALUATION_REQ,
  UPDATE_ARCH_EVALUATION_SUCCESS,
  UPDATE_ARCH_EVALUATION_FAILED,
} from '../constants/evaluatorArchConstants'

export const evaluatorArchReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ARCH_EVALUATION_DETAILS_REQ:
      return {
        ...state,
        evaluationArchDetails: { loading: true },
      }
    case GET_ARCH_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        evaluationArchDetails: { loading: false, message: action.payload },
      }
    case GET_ARCH_EVALUATION_DETAILS_FAILED:
      return {
        ...state,
        evaluationArchDetails: { loading: false, error: action.payload },
      }
    case CREATE_ARCH_EVALUATION_REQ:
      return {
        ...state,
        createArchEvaluation: { loading: true },
      }
    case CREATE_ARCH_EVALUATION_SUCCESS:
      return {
        ...state,
        createArchEvaluation: { loading: false, message: action.payload },
      }
    case CREATE_ARCH_EVALUATION_FAILED:
      return {
        ...state,
        createArchEvaluation: { loading: false, error: action.payload },
      }
    case UPDATE_ARCH_EVALUATION_REQ:
      return {
        ...state,
        updateArchEvaluation: { loading: true },
      }
    case UPDATE_ARCH_EVALUATION_SUCCESS:
      return {
        ...state,
        updateArchEvaluation: { loading: false, message: action.payload },
      }
    case UPDATE_ARCH_EVALUATION_FAILED:
      return {
        ...state,
        updateArchEvaluation: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
