export const CREATE_MECHANICAL_ASSESSMENT_REQ =
  'CREATE_MECHANICAL_ASSESSMENT_REQ'
export const CREATE_MECHANICAL_ASSESSMENT_SUCCESS =
  'CREATE_MECHANICAL_ASSESSMENT_SUCCESS'
export const CREATE_MECHANICAL_ASSESSMENT_FAILED =
  'CREATE_MECHANICAL_ASSESSMENT_FAILED'

export const UPDATE_MECHANICAL_ASSESSMENT_REQ =
  'UPDATE_MECHANICAL_ASSESSMENT_REQ'
export const UPDATE_MECHANICAL_ASSESSMENT_SUCCESS =
  'UPDATE_MECHANICAL_ASSESSMENT_SUCCESS'
export const UPDATE_MECHANICAL_ASSESSMENT_FAILED =
  'UPDATE_MECHANICAL_ASSESSMENT_FAILED'

export const GET_MECHANICAL_ASSESSMENT_REQ = 'GET_MECHANICAL_ASSESSMENT_REQ'
export const GET_MECHANICAL_ASSESSMENT_SUCCESS =
  'GET_MECHANICAL_ASSESSMENT_SUCCESS'
export const GET_MECHANICAL_ASSESSMENT_FAILED =
  'GET_MECHANICAL_ASSESSMENT_FAILED'
