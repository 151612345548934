import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProtectedRoute from '../reusable/ProtectedRoute'

// Screens
import UserDashboarScreen from '../screens/UserDashboard'
import ProfileScreen from '../screens/ProfileScreen'
import AddProfileScreen from '../screens/AddProfileScreen'
import ApplyScreen from '../screens/ApplyScreen'
import CategoryScreen from '../screens/CategoryScreen'
import userApplicationStatus from '../screens/userApplicationStatus'
import UserApplicationHistory from '../screens/UserApplicationHistory'
import EvaluationSheetScreen from '../screens/DcScreens/EvaluationSheetScreen'
import ExpiredTokenScreen from '../screens/ExpiredTokenScreen'

const UserRoutes = ({ user, cookies }) => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path='/dashboard'
        component={UserDashboarScreen}
        isAuth={user === null ? false : true}
        cookies={cookies}
      />
      <ProtectedRoute
        exact
        path='/profile'
        component={ProfileScreen}
        isAuth={user === null ? false : true}
        cookies={cookies}
      />
      <ProtectedRoute
        path='/create-user-profile'
        component={AddProfileScreen}
        isAuth={user === null ? false : true}
        cookies={cookies}
      />
      <ProtectedRoute
        exact
        path='/apply'
        component={ApplyScreen}
        isAuth={user === null ? false : true}
        cookies={cookies}
      />
      <ProtectedRoute
        exact
        path='/category'
        component={CategoryScreen}
        isAuth={user === null ? false : true}
        cookies={cookies}
      />
      <ProtectedRoute
        exact
        path='/user-application-status-list'
        component={userApplicationStatus}
        isAuth={user === null ? false : true}
        cookies={cookies}
      />
      <ProtectedRoute
        exact
        path='/user-application-history-list'
        component={UserApplicationHistory}
        isAuth={user === null ? false : true}
        cookies={cookies}
      />

      <ProtectedRoute
        exact
        path='/evaluation-sheet/:id?'
        component={EvaluationSheetScreen}
        isAuth={user === null ? false : true}
      />
      <Route component={ExpiredTokenScreen} />
    </Switch>
  )
}

export default UserRoutes
