export const CREATE_RENOVATION_ASSESSMENT_REQ =
  'CREATE_RENOVATION_ASSESSMENT_REQ'
export const CREATE_RENOVATION_ASSESSMENT_SUCCESS =
  'CREATE_RENOVATION_ASSESSMENT_SUCCESS'
export const CREATE_RENOVATION_ASSESSMENT_FAILED =
  'CREATE_RENOVATION_ASSESSMENT_FAILED'

export const UPDATE_RENOVATION_ASSESSMENT_REQ =
  'UPDATE_RENOVATION_ASSESSMENT_REQ'
export const UPDATE_RENOVATION_ASSESSMENT_SUCCESS =
  'UPDATE_RENOVATION_ASSESSMENT_SUCCESS'
export const UPDATE_RENOVATION_ASSESSMENT_FAILED =
  'UPDATE_RENOVATION_ASSESSMENT_FAILED'

export const GET_RENOVATION_ASSESSMENT_REQ = 'GET_RENOVATION_ASSESSMENT_REQ'
export const GET_RENOVATION_ASSESSMENT_SUCCESS =
  'GET_RENOVATION_ASSESSMENT_SUCCESS'
export const GET_RENOVATION_ASSESSMENT_FAILED =
  'GET_RENOVATION_ASSESSMENT_FAILED'
