export const GET_SCHED_APPLICATION_REQ = 'GET_SCHED_APPLICATION_REQ'
export const GET_SCHED_APPLICATION_SUCCESS = 'GET_SCHED_APPLICATION_SUCCESS'
export const GET_SCHED_APPLICATION_FAILED = 'GET_SCHED_APPLICATION_FAILED'

export const GET_WITH_REPORT_REQ = 'GET_WITH_REPORT_REQ'
export const GET_WITH_REPORT_SUCCESS = 'GET_WITH_REPORT_SUCCESS'
export const GET_WITH_REPORT_FAILED = 'GET_WITH_REPORT_FAILED'

export const GET_LIST_REPORT_REQ = 'GET_LIST_REPORT_REQ'
export const GET_LIST_REPORT_SUCCESS = 'GET_LIST_REPORT_SUCCESS'
export const GET_LIST_REPORT_FAILED = 'GET_LIST_REPORT_FAILED'

export const GET_REPORT_REQ = 'GET_REPORT_REQ'
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS'
export const GET_REPORT_FAILED = 'GET_REPORT_FAILED'

export const GET_IMAGE_REPORT_REQ = 'GET_IMAGE_REPORT_REQ'
export const GET_IMAGE_REPORT_SUCCESS = 'GET_IMAGE_REPORT_SUCCESS'
export const GET_IMAGE_REPORT_FAILED = 'GET_IMAGE_REPORT_FAILED'

export const GET_FOR_REPORT_REQ = 'GET_FOR_REPORT_REQ'
export const GET_FOR_REPORT_SUCCESS = 'GET_FOR_REPORT_SUCCESS'
export const GET_FOR_REPORT_FAILED = 'GET_FOR_REPORT_FAILED'

export const GET_COMMENTS_REQ = 'GET_COMMENTS_REQ'
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS'
export const GET_COMMENTS_FAILED = 'GET_COMMENTS_FAILED'

export const GET_VIOLATIONLIST_REQ = 'GET_VIOLATIONLIST_REQ'
export const GET_VIOLATIONLIST_SUCCESS = 'GET_VIOLATIONLIST_SUCCESS'
export const GET_VIOLATIONLIST_FAILED = 'GET_VIOLATIONLIST_FAILED'

export const GET_VIOLATIONDETAILS_REQ = 'GET_VIOLATIONDETAILS_REQ'
export const GET_VIOLATIONDETAILS_SUCCESS = 'GET_VIOLATIONDETAILS_SUCCESS'
export const GET_VIOLATIONDETAILS_FAILED = 'GET_VIOLATIONDETAILS_FAILED'

export const GET_VIOLATORLIST_REQ = 'GET_VIOLATORLIST_REQ'
export const GET_VIOLATORLIST_SUCCESS = 'GET_VIOLATORLIST_SUCCESS'
export const GET_VIOLATORLIST_FAILED = 'GET_VIOLATORLIST_FAILED'

export const GET_VIOLATIONSTATUS_REQ = 'GET_VIOLATIONSTATUS_REQ'
export const GET_VIOLATIONSTATUS_SUCCESS = 'GET_VIOLATIONSTATUS_SUCCESS'
export const GET_VIOLATIONSTATUS_FAILED = 'GET_VIOLATIONSTATUS_FAILED'

export const GET_APPINFO_REQ = 'GET_APPINFO_REQ'
export const GET_APPINFO_SUCCESS = 'GET_APPINFO_SUCCESS'
export const GET_APPINFO_FAILED = 'GET_APPINFO_FAILED'

export const GET_INSPECTORSUMMARY_REQ = 'GET_INSPECTORSUMMARY_REQ'
export const GET_INSPECTORSUMMARY_SUCCESS = 'GET_INSPECTORSUMMARY_SUCCESS'
export const GET_INSPECTORSUMMARY_FAILED = 'GET_INSPECTORSUMMARY_FAILED'

export const ADD_EDIT_REPORT_REQ = 'ADD_EDIT_REPORT_REQ'
export const ADD_EDIT_REPORT_SUCCESS = 'ADD_EDIT_REPORT_SUCCESS'
export const ADD_EDIT_REPORT_FAILED = 'ADD_EDIT_REPORT_FAILED'

export const ADD_REPORT_REQ = 'ADD_REPORT_REQ'
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS'
export const ADD_REPORT_FAILED = 'ADD_REPORT_FAILED'

export const ADD_VIOLATION_REQ = 'ADD_VIOLATION_REQ'
export const ADD_VIOLATION_SUCCESS = 'ADD_VIOLATION_SUCCESS'
export const ADD_VIOLATION_FAILED = 'ADD_VIOLATION_FAILED'

export const ADD_VIOLATION_DETAILS_REQ = 'ADD_VIOLATION_DETAILS_REQ'
export const ADD_VIOLATION_DETAILS_SUCCESS = 'ADD_VIOLATION_DETAILS_SUCCESS'
export const ADD_VIOLATION_DETAILS_FAILED = 'ADD_VIOLATION_DETAILS_FAILED'

export const INSERT_COMMENT_REQ = 'INSERT_COMMENT_REQ'
export const INSERT_COMMENT_SUCCESS = 'INSERT_COMMENT_SUCCESS'
export const INSERT_COMMENT_FAILED = 'INSERT_COMMENT_FAILED'

export const INSERT_IMAGES_REQ = 'INSERT_IMAGES_REQ'
export const INSERT_IMAGES_SUCCESS = 'INSERT_IMAGES_SUCCESS'
export const INSERT_IMAGES_FAILED = 'INSERT_IMAGES_FAILED'

export const UPDATE_IMAGES_REQ = 'UPDATE_IMAGES_REQ'
export const UPDATE_IMAGES_SUCCESS = 'UPDATE_IMAGES_SUCCESS'
export const UPDATE_IMAGES_FAILED = 'UPDATE_IMAGES_FAILED'

export const UPDATE_VIOLATION_REQ = 'UPDATE_VIOLATION_REQ'
export const UPDATE_VIOLATION_SUCCESS = 'UPDATE_VIOLATION_SUCCESS'
export const UPDATE_VIOLATION_FAILED = 'UPDATE_VIOLATION_FAILED'

export const UPDATE_VIOLATION_DETAILS_REQ = 'UPDATE_VIOLATION_DETAILS_REQ'
export const UPDATE_VIOLATION_DETAILS_SUCCESS =
  'UPDATE_VIOLATION_DETAILS_SUCCESS'
export const UPDATE_VIOLATION_DETAILS_FAILED = 'UPDATE_VIOLATION_DETAILS_FAILED'
