import {
  GET_FORRECOMMENDATION_LIST_REQ,
  GET_FORRECOMMENDATION_LIST_SUCCESS,
  GET_FORRECOMMENDATION_LIST_FAILED,
  GET_RECOMMENDED_LIST_REQ,
  GET_RECOMMENDED_LIST_SUCCESS,
  GET_RECOMMENDED_LIST_FAILED,
  GET_APPLICATION_LIST_REQ,
  GET_APPLICATION_LIST_SUCCESS,
  GET_APPLICATION_LIST_FAILED,
  INSERT_ICOMMENT_REQ,
  INSERT_ICOMMENT_SUCCESS,
  INSERT_ICOMMENT_FAILED,
  INSERT_ECOMMENT_REQ,
  INSERT_ECOMMENT_SUCCESS,
  INSERT_ECOMMENT_FAILED,
  INSERT_ORDEROFPAYMENT_REQ,
  INSERT_ORDEROFPAYMENT_SUCCESS,
  INSERT_ORDEROFPAYMENT_FAILED,
  UPDATE_APPLICATION_STATUS_REQ,
  UPDATE_APPLICATION_STATUS_SUCCESS,
  UPDATE_APPLICATION_STATUS_FAILED,
  GET_RETURNED_LIST_REQ,
  GET_RETURNED_LIST_SUCCESS,
  GET_RETURNED_LIST_FAILED,
  GET_COMMENTS_REQ,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAILED,
  GET_ORDEROFPAYMENT_REQ,
  GET_ORDEROFPAYMENT_SUCCESS,
  GET_ORDEROFPAYMENT_FAILED,
} from '../../constants/Recommender/recommenderConstants'

export const recommenderReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_FORRECOMMENDATION_LIST_REQ:
      return {
        ...state,
        forRecommendationList: { loading: true },
      }
    case GET_FORRECOMMENDATION_LIST_SUCCESS:
      return {
        ...state,
        forRecommendationList: { loading: false, message: action.payload },
      }
    case GET_FORRECOMMENDATION_LIST_FAILED:
      return {
        ...state,
        forRecommendationList: { loading: false, error: action.payload },
      }
    case GET_RECOMMENDED_LIST_REQ:
      return {
        ...state,
        recommendedList: { loading: true },
      }
    case GET_RECOMMENDED_LIST_SUCCESS:
      return {
        ...state,
        recommendedList: { loading: false, message: action.payload },
      }
    case GET_RECOMMENDED_LIST_FAILED:
      return {
        ...state,
        recommendedList: { loading: false, error: action.payload },
      }
    case GET_APPLICATION_LIST_REQ:
      return {
        ...state,
        applicationList: { loading: true },
      }
    case GET_APPLICATION_LIST_SUCCESS:
      return {
        ...state,
        applicationList: { loading: false, message: action.payload },
      }
    case GET_APPLICATION_LIST_FAILED:
      return {
        ...state,
        applicationList: { loading: false, error: action.payload },
      }
    case GET_RETURNED_LIST_REQ:
      return {
        ...state,
        returnedList: { loading: true },
      }
    case GET_RETURNED_LIST_SUCCESS:
      return {
        ...state,
        returnedList: { loading: false, message: action.payload },
      }
    case GET_RETURNED_LIST_FAILED:
      return {
        ...state,
        returnedList: { loading: false, error: action.payload },
      }
    case GET_COMMENTS_REQ:
      return {
        ...state,
        commentList: { loading: true },
      }
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        commentList: { loading: false, message: action.payload },
      }
    case GET_COMMENTS_FAILED:
      return {
        ...state,
        commentList: { loading: false, error: action.payload },
      }
    case GET_ORDEROFPAYMENT_REQ:
      return {
        ...state,
        orderofPayment: { loading: true },
      }
    case GET_ORDEROFPAYMENT_SUCCESS:
      return {
        ...state,
        orderofPayment: { loading: false, message: action.payload },
      }
    case GET_ORDEROFPAYMENT_FAILED:
      return {
        ...state,
        orderofPayment: { loading: false, error: action.payload },
      }
    case INSERT_ORDEROFPAYMENT_REQ:
      return {
        ...state,
        createdOP: { loading: true },
      }
    case INSERT_ORDEROFPAYMENT_SUCCESS:
      return {
        ...state,
        createdOP: { loading: false, message: action.payload },
      }
    case INSERT_ORDEROFPAYMENT_FAILED:
      return {
        ...state,
        createdOP: { loading: false, error: action.payload },
      }
    case INSERT_ICOMMENT_REQ:
      return {
        ...state,
        createIComment: { loading: true },
      }
    case INSERT_ICOMMENT_SUCCESS:
      return {
        ...state,
        createIComment: { loading: false, message: action.payload },
      }
    case INSERT_ICOMMENT_FAILED:
      return {
        ...state,
        createIComment: { loading: false, error: action.payload },
      }
    case INSERT_ECOMMENT_REQ:
      return {
        ...state,
        createEComment: { loading: true },
      }
    case INSERT_ECOMMENT_SUCCESS:
      return {
        ...state,
        createEComment: { loading: false, message: action.payload },
      }
    case INSERT_ECOMMENT_FAILED:
      return {
        ...state,
        createEComment: { loading: false, error: action.payload },
      }
    case UPDATE_APPLICATION_STATUS_REQ:
      return {
        ...state,
        updateAppStatus: { loading: true },
      }
    case UPDATE_APPLICATION_STATUS_SUCCESS:
      return {
        ...state,
        updateAppStatus: { loading: false, message: action.payload },
      }
    case UPDATE_APPLICATION_STATUS_FAILED:
      return {
        ...state,
        updateAppStatus: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
