import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Table, Pagination } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import { FaTrashAlt, FaUndoAlt } from 'react-icons/fa'

const UserTable = ({
  data,
  searchTerm,
  handleResetPassword,
  handleDeleteUser,
}) => {
  // Pagination Area Forms
  const [list, setList] = useState([])
  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)

  const pageCount = list ? Math.ceil(list.length / pageNumberLimit) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageNumberLimit
    const paginationForms = _(list)
      .slice(startIndex)
      .take(pageNumberLimit)
      .value()
    setpaginatedForms(paginationForms)
  }

  //   Display Pagination list
  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })
  // End Display Pagination list

  // Buttons
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Buttons

  // End Pagination Area Forms

  useEffect(() => {
    if (data) {
      setList(() =>
        data.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.id === parseInt(searchTerm)
            )
          }
        })
      )
    }
  }, [data, searchTerm])

  useEffect(() => {
    setpaginatedForms(_(list).slice(0).take(pageNumberLimit).value())
    setcurrentPage(1)
  }, [list, pageNumberLimit])
  return (
    <Container fluid>
      <Row className='my-4'>
        <Col
          xl={12}
          className='border border-secondary rounded px-0'
          style={{ background: '#FFF' }}
        >
          <Row>
            <Col xl={10}>
              <h5 className='mx-3 pt-4 pb-2'>Active Users</h5>
              <hr
                className='mt-1 mb-3'
                style={{
                  background: '#ffeb36',
                  paddingBottom: '2px',
                  width: '200px',
                }}
              />
            </Col>
          </Row>

          <Table size='sm' bordered hover responsive>
            <thead className='bg-info text-light'>
              <tr>
                <th>Name</th>
                <th>Username</th>
                <th>User Level</th>
                <th>Time Created</th>
                <th>Email Address</th>
                <th>Mobile No.</th>
                <th>Total No of Application</th>
                <th className='text-center'>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedForms.map((x) => (
                <tr key={x.id} className='text-nowrap'>
                  <td className='text-nowrap'>{x.Name}</td>
                  <td>{x.username}</td>
                  <td>{x.roles}</td>
                  <td>{moment(x.TimeStamp).format('MMMM DD, YYYY')}</td>
                  <td className='text-nowrap'>{x.EmailAddress}</td>
                  <td>{x.MobileNo}</td>
                  <td>{x.TotalApplication}</td>
                  <td className='text-center'>
                    <Button
                      size='sm'
                      variant='outline-success'
                      className='mx-1'
                      onClick={() => handleResetPassword(x)}
                    >
                      <FaUndoAlt />
                    </Button>
                    <Button
                      size='sm'
                      variant='outline-danger'
                      className='mx-1'
                      onClick={() => handleDeleteUser(x)}
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination size='sm' className='d-flex justify-content-end mx-2'>
            <Pagination.Item
              onClick={handlePrevbtn}
              disabled={currentPage === pages[0] ? true : false}
            >
              &lt;
            </Pagination.Item>

            {renderPageNumber}

            <Pagination.Item
              onClick={handleNextbtn}
              disabled={currentPage === pages[pages.length - 1] ? true : false}
            >
              &gt;
            </Pagination.Item>
          </Pagination>
        </Col>
      </Row>
    </Container>
  )
}

export default UserTable
