import React from 'react'
import { Modal, Table, Button, Card, Spinner } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import _ from 'lodash'
import moment from 'moment'
import Error from './Error'

const ApplicationTrackStatus = (props) => {
  return (
    <Modal
      size='lg'
      show={props.show}
      onHide={props.onHide}
      centered
      backdrop='static'
    >
      <Modal.Header style={{ background: '#4bbf73' }}>
        <Modal.Title style={{ color: '#FFF' }}>
          <FaCheckCircle className='mx-3' /> Application Tracking
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.statusTracking && props.statusTracking.message && (
          <Table bordered hover responsive size='sm'>
            <thead>
              <tr>
                <td></td>
                <td className='text-center'>Date</td>
                <td>Status</td>
                <td>Remarks</td>
              </tr>
            </thead>
            <tbody>
              {_.reverse(
                props.statusTracking.message.map((track, index) => (
                  <tr key={index}>
                    <td> {index + 1}.</td>
                    <td className='text-nowrap'>
                      {moment(track.TimeStamp).format('MMMM D, YYYY')}
                    </td>
                    <td className='text-nowrap'>{track.status}</td>
                    <td>{track.remarks}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}
        {props.statusTracking && props.statusTracking.loading && (
          <Card>
            <Card.Body className='mt-2'>
              <div className='text-center pt-3'>
                <Spinner animation='border' />
                <h3>Loading...</h3>
              </div>
            </Card.Body>
          </Card>
        )}
        {props.statusTracking && props.statusTracking.error && (
          <Error message='No Data Found' />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size='sm' variant='danger' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ApplicationTrackStatus
