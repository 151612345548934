export const CREATE_ELECTRICAL_ASSESSMENT_REQ =
  'CREATE_ELECTRICAL_ASSESSMENT_REQ'
export const CREATE_ELECTRICAL_ASSESSMENT_SUCCESS =
  'CREATE_ELECTRICAL_ASSESSMENT_SUCCESS'
export const CREATE_ELECTRICAL_ASSESSMENT_FAILED =
  'CREATE_ELECTRICAL_ASSESSMENT_FAILED'

export const UPDATE_ELECTRICAL_ASSESSMENT_REQ =
  'UPDATE_ELECTRICAL_ASSESSMENT_REQ'
export const UPDATE_ELECTRICAL_ASSESSMENT_SUCCESS =
  'UPDATE_ELECTRICAL_ASSESSMENT_SUCCESS'
export const UPDATE_ELECTRICAL_ASSESSMENT_FAILED =
  'UPDATE_ELECTRICAL_ASSESSMENT_FAILED'

export const GET_ELECTRICAL_ASSESSMENT_REQ = 'GET_ELECTRICAL_ASSESSMENT_REQ'
export const GET_ELECTRICAL_ASSESSMENT_SUCCESS =
  'GET_ELECTRICAL_ASSESSMENT_SUCCESS'
export const GET_ELECTRICAL_ASSESSMENT_FAILED =
  'GET_ELECTRICAL_ASSESSMENT_FAILED'
