export const GET_CIVIL_EVALUATION_DETAILS_REQ =
  'GET_CIVIL_EVALUATION_DETAILS_REQ'
export const GET_CIVIL_EVALUATION_DETAILS_SUCCESS =
  'GET_CIVIL_EVALUATION_DETAILS_SUCCESS'
export const GET_CIVIL_EVALUATION_DETAILS_FAILED =
  'GET_CIVIL_EVALUATION_DETAILS_FAILED'

export const CREATE_CIVIL_EVALUATION_REQ = 'CREATE_CIVIL_EVALUATION_REQ'
export const CREATE_CIVIL_EVALUATION_SUCCESS = 'CREATE_CIVIL_EVALUATION_SUCCESS'
export const CREATE_CIVIL_EVALUATION_FAILED = 'CREATE_CIVIL_EVALUATION_FAILED'

export const UPDATE_CIVIL_EVALUATION_REQ = 'UPDATE_CIVIL_EVALUATION_REQ'
export const UPDATE_CIVIL_EVALUATION_SUCCESS = 'UPDATE_CIVIL_EVALUATION_SUCCESS'
export const UPDATE_CIVIL_EVALUATION_FAILED = 'UPDATE_CIVIL_EVALUATION_FAILED'
