import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  HeaderCenter: {
    textAlign: 'center',
    fontSize: 10,
    width: '100%',
    paddingTop: '15px',
  },
})
const CertOccupancy = ({ info }) => {
  let uline1 = false
  let uline2 = false
  // console.log(info && info.length)
  return (
    <Document>
      <Page size='Letter' orientation='landscape' style={styles.body} wrap>
        <View style={{ border: '3px solid black' }}>
          <View style={{ border: '1px solid black', margin: '2px' }}>
            <View>
              <View style={styles.row}>
                <Text style={styles.HeaderCenter}>
                  Republic of the Philippines
                </Text>
              </View>
              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 10,
                    width: '100%',
                  }}
                >
                  Department of Public Works and Highway
                </Text>
              </View>
              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 10,
                    width: '100%',
                    fontWeight: 'bold',
                  }}
                >
                  City of Las Piñas
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 10,
                    width: '100%',
                  }}
                >
                  Metropolitan Manila
                </Text>
              </View>
              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 10,
                    width: '100%',
                    fontFamily: 'Times-Bold',
                  }}
                >
                  OFFICE OF THE BUILDING OFFICIAL
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 18,
                    width: '100%',
                    paddingTop: '8px',
                    paddingBottom: '10px',
                    fontFamily: 'Times-Bold',
                  }}
                >
                  CERTIFICATE OF OCCUPANCY
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '70%',
                    paddingTop: '20px',
                    paddingLeft: '20px',
                  }}
                >
                  Number:{' '}
                  {info && info.length > 0 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      {info[0].categCode + ' ' + info[0].id}
                    </Text>
                  ) : (
                    '__________________________________________'
                  )}
                </Text>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '30%',
                    paddingTop: '20px',
                    paddingRight: '20px',
                  }}
                >
                  Fees Paid:{' '}
                  {info && info.length > 0 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      {info[0].FeesPaid.toLocaleString()}
                    </Text>
                  ) : (
                    '__________________________'
                  )}
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '70%',
                    paddingTop: '5px',
                    paddingLeft: '20px',
                  }}
                >
                  Date Issued:{' '}
                  {uline1 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      December 25, 2022
                    </Text>
                  ) : (
                    '__________________________'
                  )}
                </Text>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '30%',
                    paddingTop: '5px',
                    paddingRight: '20px',
                  }}
                >
                  Official Receipt Number:{' '}
                  {info && info.length > 0 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      {info[0].OfficialReceiptNumber}
                    </Text>
                  ) : (
                    '______________'
                  )}
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '70%',
                    paddingTop: '5px',
                    paddingLeft: '20px',
                  }}
                >
                  FSIC Number:{' '}
                  {uline1 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      12345678
                    </Text>
                  ) : (
                    '__________________________'
                  )}
                </Text>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '30%',
                    paddingTop: '5px',
                    paddingRight: '20px',
                  }}
                >
                  Date Paid:{' '}
                  {info && info.length > 0 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      {moment(info[0].DatePaid).format('MMMM D, YYYY')}
                    </Text>
                  ) : (
                    '__________________________'
                  )}
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    fontSize: 10,
                    paddingTop: '10px',
                    paddingBottom: '15px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    textIndent: '30px',
                  }}
                >
                  This is to certify that the building / structure which has
                  been designed and constructed conforms to the issued Building
                  Permit No.
                  {uline1 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      12345678
                    </Text>
                  ) : (
                    '__________________________________________'
                  )}{' '}
                  dated{' '}
                  {uline2 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      December 25, 2022.
                    </Text>
                  ) : (
                    '__________________________________________'
                  )}
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '100%',
                    paddingTop: '5px',
                    paddingLeft: '50px',
                  }}
                >
                  Name of Owner{'                    '}:{' '}
                  {info && info.length > 0 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      {info[0].Owner}
                    </Text>
                  ) : (
                    '__________________________________________'
                  )}
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '100%',
                    paddingTop: '5px',
                    paddingLeft: '50px',
                  }}
                >
                  Name of Project{'                    '}:{' '}
                  {info && info.length > 0 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      {info[0].ApplicationName}
                    </Text>
                  ) : (
                    '__________________________________________'
                  )}
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '100%',
                    paddingTop: '5px',
                    paddingLeft: '50px',
                  }}
                >
                  Date of Completion{'               '}:{' '}
                  {info && info.length > 0 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      {moment(info[0].DateOfCompletion).format('MMMM D, YYYY')}
                    </Text>
                  ) : (
                    '__________________________________________'
                  )}
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '50%',
                    paddingTop: '5px',
                    paddingLeft: '50px',
                  }}
                >
                  Occupancy Character{'           '}:{' '}
                  {uline1 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      Lorem Ipsum Lorem Ipsum Lorem Ipsum
                    </Text>
                  ) : (
                    '______________________________'
                  )}
                </Text>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '50%',
                    paddingTop: '5px',
                    paddingRight: '20px',
                  }}
                >
                  {' '}
                  Group{' '}
                  {uline2 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      Lorem Ipsum Lorem Ipsum Lorem Ipsum
                    </Text>
                  ) : (
                    '__________________________________________'
                  )}
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'left',
                    fontSize: 10,
                    width: '100%',
                    paddingTop: '5px',
                    paddingLeft: '50px',
                  }}
                >
                  Located at / Along{'                 '}:{' '}
                  {info && info.length > 0 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      {info[0].Location}
                    </Text>
                  ) : (
                    '__________________________________________'
                  )}
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    fontSize: 10,
                    paddingTop: '10px',
                    paddingBottom: '15px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                >
                  The Owner shall properly maintain the building / structure to
                  enhance its architectural well-being, structural stability,
                  electrical, mechanical, sanitation, plumbing, electronics,
                  interior design and fire protective properties and shall not
                  be occupied or used for purposes other than its intended use
                  as stated above.
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    fontSize: 10,
                    paddingTop: '10px',
                    paddingBottom: '15px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                >
                  This CERTIFICATE OF OCCUPANCY is hereby issued pursuant to
                  Section 309 of the National Building Code of the Philippines
                  (PD 1096), its Revised IRR, other Referral Codes and JMC No.
                  2018 - 01.
                </Text>
              </View>

              <View
                style={{
                  border: '1px solid black',
                  margin: '2px',
                  width: '50%',
                  marginLeft: '20px',
                }}
              >
                <View style={styles.row}>
                  <Text
                    style={{
                      fontSize: 10,
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    A certified copy hereof shall be posted within the premises
                    of the building/structure and shall not be removed without
                    authority from the Building Official
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: '100%',
                  textAlign: 'center',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    width: '50%',
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 12,
                    width: '50%',
                    textAlign: 'center',
                    fontFamily: 'Times-Bold',
                  }}
                >
                  ENGR. ROSABELLA A. BANTOG
                </Text>
              </View>

              <View
                style={{
                  width: '100%',
                  textAlign: 'center',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    width: '50%',
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 10,
                    width: '50%',
                    textAlign: 'center',
                  }}
                >
                  Building Official
                </Text>
              </View>

              <View
                style={{
                  width: '100%',
                  textAlign: 'center',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    width: '50%',
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 10,
                    width: '50%',
                    textAlign: 'center',
                  }}
                >
                  Date{' '}
                  {info && info.length > 0 ? (
                    <Text style={{ textDecoration: 'underline' }}>
                      {moment(info[0].DateOfCompletion).format('MMMM D, YYYY')}
                    </Text>
                  ) : (
                    '__________________________________________'
                  )}
                </Text>
              </View>

              <View Style={styles.row}>
                <Text
                  style={{
                    fontSize: 10,
                    paddingTop: '25px',
                    paddingBottom: '10px',
                    fontFamily: 'Times-Bold',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                >
                  THIS CERTIFICATE MAY BE CANCELLED OR REVOKED PURSUANT TO
                  SECTIONS 305 OF THE NATIONAL BUILDING CODE OF THE PHILIPPINES
                  (PD 1096)
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <Text
            style={{
              fontSize: 8,
              paddingTop: '5px',
              textAlign: 'center',
              width: '100%',
            }}
          >
            This serves as provisional building permit valid only for 15 days.
            Kindly submit one (1) set of signed and sealed copies of your
            documents to the Office of the Building Official. Failure to submit
            shall render this provisionary permit void.
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default CertOccupancy
