export const CREATE_ANNUALINSPECTIONFEE_ASSESSMENT_REQ =
  'CREATE_ANNUALINSPECTIONFEE_ASSESSMENT_REQ'
export const CREATE_ANNUALINSPECTIONFEE_ASSESSMENT_SUCCESS =
  'CREATE_ANNUALINSPECTIONFEE_ASSESSMENT_SUCCESS'
export const CREATE_ANNUALINSPECTIONFEE_ASSESSMENT_FAILED =
  'CREATE_ANNUALINSPECTIONFEE_ASSESSMENT_FAILED'

export const UPDATE_ANNUALINSPECTIONFEE_ASSESSMENT_REQ =
  'UPDATE_ANNUALINSPECTIONFEE_ASSESSMENT_REQ'
export const UPDATE_ANNUALINSPECTIONFEE_ASSESSMENT_SUCCESS =
  'UPDATE_ANNUALINSPECTIONFEE_ASSESSMENT_SUCCESS'
export const UPDATE_ANNUALINSPECTIONFEE_ASSESSMENT_FAILED =
  'UPDATE_ANNUALINSPECTIONFEE_ASSESSMENT_FAILED'

export const GET_ANNUALINSPECTIONFEE_ASSESSMENT_REQ =
  'GET_ANNUALINSPECTIONFEE_ASSESSMENT_REQ'
export const GET_ANNUALINSPECTIONFEE_ASSESSMENT_SUCCESS =
  'GET_ANNUALINSPECTIONFEE_ASSESSMENT_SUCCESS'
export const GET_ANNUALINSPECTIONFEE_ASSESSMENT_FAILED =
  'GET_ANNUALINSPECTIONFEE_ASSESSMENT_FAILED'
