import {
  NEWS_AND_UPDATES_REQUEST,
  NEWS_AND_UPDATES_SUCCESS,
  NEWS_AND_UPDATES_FAILED,
  NEWS_AND_UPDATES_INSERT_REQUEST,
  NEWS_AND_UPDATES_INSERT_SUCCESS,
  NEWS_AND_UPDATES_INSERT_FAILED,
} from '../constants/newsandupdatesConstant'

export const newsandupdatesReducer = (
  state = {
    newsandupdatesDetail: [],
    createdNewsAndUpdates: { loading: true },
  },
  action
) => {
  switch (action.type) {
    case NEWS_AND_UPDATES_REQUEST:
      return { ...state, newsDetails: { loading: true } }
    case NEWS_AND_UPDATES_SUCCESS:
      return {
        ...state,
        newsDetails: { loading: false, message: action.payload },
      }
    case NEWS_AND_UPDATES_FAILED:
      return {
        ...state,
        newsDetails: { loading: false, error: action.payload },
      }
    case NEWS_AND_UPDATES_INSERT_REQUEST:
      return { createdNewsAndUpdates: { loading: true } }
    case NEWS_AND_UPDATES_INSERT_SUCCESS:
      return {
        createdNewsAndUpdates: {
          loading: false,
          message: action.payload,
          isSuccess: true,
        },
      }
    case NEWS_AND_UPDATES_INSERT_FAILED:
      return {
        createdNewsAndUpdates: {
          loading: false,
          error: action.payload,
          isSuccess: false,
        },
      }
    default:
      return state
  }
}
