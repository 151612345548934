import Cookie from 'js-cookie'
import axios from 'axios'
import {
  NEWS_AND_UPDATES_REQUEST,
  NEWS_AND_UPDATES_SUCCESS,
  NEWS_AND_UPDATES_FAILED,
  NEWS_AND_UPDATES_INSERT_REQUEST,
  NEWS_AND_UPDATES_INSERT_SUCCESS,
  NEWS_AND_UPDATES_INSERT_FAILED,
} from '../constants/newsandupdatesConstant'

export const newsandupdatesRetrieve = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: NEWS_AND_UPDATES_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/newsandupdates`,
        config
      )

      dispatch({
        type: NEWS_AND_UPDATES_SUCCESS,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: NEWS_AND_UPDATES_FAILED,
        payload: 'Unable to Retrieve News and Updates',
      })
    }
  }
}

export const newsandupdatesInsert = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: NEWS_AND_UPDATES_INSERT_REQUEST })

      await axios.post(
        `${process.env.REACT_APP_API_URL}/newsandupdates/add`,
        payload
      )

      dispatch({
        type: NEWS_AND_UPDATES_INSERT_SUCCESS,
        payload: 'Successfully Inserted News and Updates',
      })
    } catch (error) {
      dispatch({
        type: NEWS_AND_UPDATES_INSERT_FAILED,
        payload: 'Unable to Create News and Updates',
      })
    }
  }
}
