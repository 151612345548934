import React, { useState } from 'react'
import { Button, Modal, Spinner, Table } from 'react-bootstrap'
import { FaArrowDown, FaCheckCircle } from 'react-icons/fa'
import _ from 'lodash'
import moment from 'moment'
import { PDFDownloadLink, pdf } from '@react-pdf/renderer'
import { PDFDocument } from 'pdf-lib-plus-encrypt'
import { useSelector } from 'react-redux'

// PDF
import DcBldgPermit from './DcBldgPermit'
import DcCertOccupancy from './DcCertOccupancy'
import PrintCFEI from './PrintCFEI'

// Helper
import { encryptPDF } from '../helpers/pdfEncryption'

const ApplicationViewing = (props) => {
  const [encryptedPdf, setEncryptedPdf] = useState(null)

  const userApplications = useSelector((state) => state.applications)
  const { retrievedCFEI } = userApplications

  const handleSetEncrypt = (value) => {
    setEncryptedPdf(value)
  }

  // const generateEncryptedPdf = async () => {
  //   // Generate PDF using react-pdf
  //   const pdfBlob = await pdf(
  //     <PrintCFEI info={props.permitCFEI !== undefined && props.permitCFEI} />
  //   ).toBlob()

  //   // Load PDF into pdf-lib
  //   const existingPdfBytes = await pdfBlob.arrayBuffer()
  //   const pdfDoc = await PDFDocument.load(existingPdfBytes)

  //   // Encrypt the PDF to disable editing
  //   pdfDoc.encrypt({
  //     userPassword: 'Lpbldg0', // Replace with your user password
  //     ownerPassword: props.payload.applicantName, // Replace with your owner password
  //     permissions: {
  //       printing: 'highResolution', // 'lowResolution', 'highResolution', or false
  //       modifying: false,
  //       copying: false,
  //       annotating: false,
  //       fillingForms: false,
  //       contentAccessibility: false,
  //       documentAssembly: false,
  //     },
  //   })

  //   // Save the encrypted PDF
  //   const encryptedPdfBytes = await pdfDoc.save()
  //   const encryptedPdfBlob = new Blob([encryptedPdfBytes], {
  //     type: 'application/pdf',
  //   })
  //   setEncryptedPdf(encryptedPdfBlob)
  // }

  // console.log(props)

  return (
    <Modal
      size='lg'
      show={props.show}
      onHide={props.onHide}
      centered
      backdrop='static'
    >
      <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
        <Modal.Title style={{ color: '#FFF' }}>
          <FaCheckCircle className='mx-3' />
          Application Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive size='sm'>
          <tbody>
            <tr className='text-nowrap'>
              <td className='fw-bold'>Application No.:</td>
              <td>{props.payload.id}</td>
            </tr>
            <tr className='text-nowrap'>
              <td className='fw-bold'>Application Name:</td>
              <td>{props.payload.ApplicationName}</td>
            </tr>
            <tr className='text-nowrap'>
              <td className='fw-bold'>Mode Of Application:</td>
              <td>{props.payload.ModeofForms}</td>
            </tr>
            <tr className='text-nowrap'>
              <td className='fw-bold'>Application Status:</td>
              <td>{props.payload.status}</td>
            </tr>
            <tr className='text-nowrap'>
              <td className='fw-bold'>Application Date Applied:</td>
              <td>{moment(props.dateApplied).format('MMMM D, YYYY')}</td>
            </tr>
            <tr className='text-nowrap'>
              <td className='fw-bold'>Applied By:</td>
              <td>
                {props.payload.FullName !== null
                  ? props.payload.FullName
                  : props.payload.applicantName}
              </td>
            </tr>
            <tr className='text-nowrap'>
              <td className='fw-bold'>Applicant Attached Link:</td>
              <td>{props.payload.Link}</td>
            </tr>
            <tr>
              <td className='fw-bold'>Trades Permit Attached:</td>
              <td>
                {_.zip(
                  [
                    'STRUCTURAL',
                    'MECHANICAL',
                    'ARCHITECTURAL',
                    'SANITARY',
                    'ELECTRICAL',
                    'PLUMBING',
                    'ELECTRONICS',
                  ],
                  [
                    props.payload.Structural,
                    props.payload.Mechanical,
                    props.payload.Architectural,
                    props.payload.Sanitary,
                    props.payload.Electrical,
                    props.payload.Plumbing,
                    props.payload.Electronics,
                  ]
                ).map((trade) => trade[1] === 1 && trade[0] + ',' + ' ')}
              </td>
            </tr>
            <tr className='text-nowrap'>
              <td className='fw-bold'>Application Manager:</td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleViewApplicationManager}
                >
                  View
                </Button>
              </td>
            </tr>
            <tr className='text-nowrap'>
              <td className='fw-bold'>Track Application Status:</td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleViewApplicationTracking}
                >
                  View
                </Button>
              </td>
            </tr>
            {(props.payload.status === 'APPROVED APPLICATION' ||
              props.payload.status === 'RELEASE APPLICATION WITH REMARKS' ||
              props.payload.status === 'RELEASE APPLICATION') && (
              <tr className='text-nowrap'>
                <td className='fw-bold'>Payment Manager:</td>
                <td>
                  <Button
                    size='sm'
                    variant='success'
                    onClick={props.handleViewPaymentManager}
                  >
                    View
                  </Button>
                </td>
              </tr>
            )}

            <tr className='text-nowrap'>
              <td className='fw-bold'>Evaluation Sheet:</td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleViewEvaluationSheet}
                >
                  View
                </Button>
              </td>
            </tr>
            {/* 
            {props.payload.ApplicationName === 'ELECTRICAL PERMIT' && (
              <tr className='text-nowrap'>
                <td className='fw-bold'>Create CFEI:</td>
                <td>
                  <Button
                    size='sm'
                    variant='success'
                    onClick={props.handleViewCreateCFEI}
                  >
                    View
                  </Button>
                </td>
              </tr>
            )} */}

            {(props.payload.status === 'RELEASE APPLICATION' ||
              props.payload.status === 'RELEASE APPLICATION WITH REMARKS') &&
              props.payload.ApplicationName === 'ELECTRICAL PERMIT' && (
                <tr className='text-nowrap'>
                  <td className='fw-bold'>Download CFEI:</td>
                  <td>
                    {/* <PDFDownloadLink
                      document={
                        <PrintCFEI
                          info={
                            props.permitCFEI !== undefined && props.permitCFEI
                          }
                        />
                      }
                      fileName='CFEI.pdf'
                    >
                      {({ loading }) =>
                        loading ? (
                          'Loading document ...'
                        ) : (
                          <Button size='sm' variant='success'>
                            Download
                          </Button>
                        )
                      }
                    </PDFDownloadLink> */}

                    {retrievedCFEI && retrievedCFEI.message && (
                      <Button
                        size='sm'
                        variant='success'
                        // onClick={generateEncryptedPdf}
                        onClick={() => {
                          encryptPDF(
                            props,
                            <PrintCFEI
                              info={
                                props.permitCFEI !== undefined &&
                                props.permitCFEI
                              }
                            />,
                            handleSetEncrypt
                          )
                        }}
                      >
                        Generate Certificate
                      </Button>
                    )}

                    {retrievedCFEI && retrievedCFEI.loading && (
                      <Spinner animation='border' size='sm' />
                    )}

                    {encryptedPdf && (
                      <Button size='sm' variant='info' className='mx-2'>
                        <a
                          href={URL.createObjectURL(encryptedPdf)}
                          download='CFEI.pdf'
                          style={{ textDecoration: 'none', color: '#FFF' }}
                        >
                          <FaArrowDown />
                        </a>
                      </Button>
                    )}
                  </td>
                </tr>
              )}

            {(props.payload.ApplicationName === 'OCCUPANCY PERMIT' ||
              props.payload.ApplicationName ===
                'BUILDING CONSTRUCTION PERMIT') &&
              props.payload.status === 'RELEASE APPLICATION' && (
                <tr className='text-nowrap'>
                  <td className='fw-bold'>Release Permit:</td>
                  <td>
                    {props.payload.ApplicationName === 'OCCUPANCY PERMIT' && (
                      <PDFDownloadLink
                        document={
                          <DcCertOccupancy
                            info={
                              props.permitValue2 !== undefined &&
                              props.permitValue2
                            }
                          />
                        }
                        fileName='CertificateOfOccupancy.pdf'
                      >
                        {({ loading }) =>
                          loading ? (
                            'Loading document ...'
                          ) : (
                            <Button size='sm' variant='success'>
                              Download
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    )}

                    {props.payload.ApplicationName ===
                      'BUILDING CONSTRUCTION PERMIT' && (
                      <PDFDownloadLink
                        document={<DcBldgPermit info={props.permitValue} />}
                        fileName='CertificateOfBuildingPermit.pdf'
                      >
                        {({ loading }) =>
                          loading ? (
                            'Loading document ...'
                          ) : (
                            <Button size='sm' variant='success'>
                              Download
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                  </td>
                </tr>
              )}
            <tr className='text-nowrap'>
              <td className='fw-bold'>Assessor's Office Verification:</td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleViewAssessorsVerification}
                >
                  View
                </Button>
              </td>
            </tr>
            {(props.payload.status === 'FOR INSPECTION AND EVALUATION' ||
              props.userCredentials.roles !== 'document controller') && (
              <tr className='text-nowrap'>
                <td className='fw-bold'>Compute Contractor's Tax:</td>
                <td>
                  <Button
                    size='sm'
                    variant='success'
                    onClick={props.handleViewContractorsTaxComputation}
                  >
                    View
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button size='sm' variant='danger' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ApplicationViewing
