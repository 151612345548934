import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  VERIFY_QR_REQ,
  VERIFY_QR_SUCCESS,
  VERIFY_QR_FAILED,
  USER_ISAUTH_REQUEST,
  USER_ISAUTH_SUCCESS,
  USER_ISAUTH_FAILED,
  // USER_REGISTRATION_REQUEST,
  // USER_REGISTRATION_SUCCESS,
  // USER_REGISTRATION_FAILED,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILED,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  CREATE_ENGR_REQUEST,
  CREATE_ENGR_SUCCESS,
  CREATE_ENGR_FAILED,
  USER_EMPLOYEELOGIN_REQUEST,
  USER_EMPLOYEELOGIN_SUCCESS,
  USER_EMPLOYEELOGIN_FAILED,
  GET_EMAIL_REQUEST,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAILED,
  GET_RESET_PASSWORD_REQUEST,
  GET_RESET_PASSWORD_SUCCESS,
  GET_RESET_PASSWORD_FAILED,
  PUT_RESET_PASSWORD_REQUEST,
  PUT_RESET_PASSWORD_SUCCESS,
  PUT_RESET_PASSWORD_FAILED,
  GET_VERIFYPERMIT_REQUEST,
  GET_VERIFYPERMIT_SUCCESS,
  GET_VERIFYPERMIT_FAILED,
  CREATE_PROFINFO_REQ,
  CREATE_PROFINFO_SUCCESS,
  CREATE_PROFINFO_FAILED,
  DELETE_PROFINFO_REQ,
  DELETE_PROFINFO_SUCCESS,
  DELETE_PROFINFO_FAILED,
  USER_RPTLOGIN_REQUEST,
  USER_RPTLOGIN_SUCCESS,
  USER_RPTLOGIN_FAILED,
} from '../constants/userConstants'

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userCredentials: action.payload,
      }
    case USER_LOGIN_FAILED:
      return { loading: false, error: action.payload }
    case VERIFY_QR_REQ:
      return { ...state, verifying: { loading: true } }
    case VERIFY_QR_SUCCESS:
      return {
        ...state,
        verifying: { loading: false, message: action.payload },
      }
    case VERIFY_QR_FAILED:
      return { ...state, verifying: { loading: false, error: action.payload } }
    case USER_EMPLOYEELOGIN_REQUEST:
      return { loading: true }
    case USER_EMPLOYEELOGIN_SUCCESS:
      return {
        loading: false,
        userCredentials: action.payload,
      }
    case USER_EMPLOYEELOGIN_FAILED:
      return { loading: false, error: action.payload }

    case USER_LOGOUT_REQUEST:
      return { ...state, userlogout: { loading: true } }
    case USER_LOGOUT_SUCCESS:
      return { ...state, userlogout: { loading: false, message: 'Success' } }
    case USER_LOGOUT_FAILED:
      return { ...state, userlogout: { loading: false, error: 'Failed' } }

    case USER_ISAUTH_REQUEST:
      return { loading: true, isAuth: false }
    case USER_ISAUTH_SUCCESS:
      return {
        loading: false,
        userCredentials: action.payload.data,
        isAuth: action.payload.data.auth,
      }
    case USER_ISAUTH_FAILED:
      return { loading: false, isAuth: false }
    case CREATE_USER_REQUEST:
      return { ...state, createdUser: { loading: true } }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createdUser: { loading: false, message: action.payload },
      }
    case CREATE_USER_FAILED:
      return {
        ...state,
        createdUser: { loading: false, error: action.payload },
      }
    case CREATE_ENGR_REQUEST:
      return { ...state, createdEng: { loading: true } }
    case CREATE_ENGR_SUCCESS:
      return {
        ...state,
        createdEng: { loading: false, message: action.payload },
      }
    case CREATE_ENGR_FAILED:
      return {
        ...state,
        createdEng: { loading: false, error: action.payload },
      }
    case GET_EMAIL_REQUEST:
      return { ...state, retrievedEmail: { loading: true } }
    case GET_EMAIL_SUCCESS:
      return {
        ...state,
        retrievedEmail: { loading: false, message: action.payload },
      }
    case GET_EMAIL_FAILED:
      return {
        ...state,
        retrievedEmail: { loading: false, error: action.payload },
      }
    case GET_RESET_PASSWORD_REQUEST:
      return { ...state, retrievedResetPassword: { loading: true } }
    case GET_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        retrievedResetPassword: { loading: false, message: action.payload },
      }
    case GET_RESET_PASSWORD_FAILED:
      return {
        ...state,
        retrievedResetPassword: { loading: false, error: action.payload },
      }
    case PUT_RESET_PASSWORD_REQUEST:
      return { ...state, updatedPassword: { loading: true } }
    case PUT_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        updatedPassword: { loading: false, message: action.payload },
      }
    case PUT_RESET_PASSWORD_FAILED:
      return {
        ...state,
        updatedPassword: { loading: false, error: action.payload },
      }
    case GET_VERIFYPERMIT_REQUEST:
      return { ...state, verifiedPermit: { loading: true } }
    case GET_VERIFYPERMIT_SUCCESS:
      return {
        ...state,
        verifiedPermit: { loading: false, message: action.payload },
      }
    case GET_VERIFYPERMIT_FAILED:
      return {
        ...state,
        verifiedPermit: { loading: false, error: action.payload },
      }
    case CREATE_PROFINFO_REQ:
      return { ...state, createdProInfo: { loading: true } }
    case CREATE_PROFINFO_SUCCESS:
      return {
        ...state,
        createdProInfo: { loading: false, message: action.payload },
      }
    case CREATE_PROFINFO_FAILED:
      return {
        ...state,
        createdProInfo: { loading: false, error: action.payload },
      }
    case DELETE_PROFINFO_REQ:
      return { ...state, deletedProInfo: { loading: true } }
    case DELETE_PROFINFO_SUCCESS:
      return {
        ...state,
        deletedProInfo: { loading: false, message: action.payload },
      }
    case DELETE_PROFINFO_FAILED:
      return {
        ...state,
        deletedProInfo: { loading: false, error: action.payload },
      }
    case USER_RPTLOGIN_REQUEST:
      return { ...state, rptAccount: { loading: true } }
    case USER_RPTLOGIN_SUCCESS:
      return {
        ...state,
        rptAccount: { loading: false, message: action.payload },
      }
    case USER_RPTLOGIN_FAILED:
      return {
        ...state,
        rptAccount: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
