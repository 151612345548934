import React from 'react'
import { Row, Col, Button, Table, Card, Accordion, Form } from 'react-bootstrap'
import { toFixed } from './DecimalWoutRound'

const ConstructionRenovation = ({
  disabled,
  obj,
  handleChangeConst1,
  handleChangeConst2,
  handleChangeConst3,
  handleChangeConst4,
  handleChangeConst5,
  handleChangeConst6,
}) => {
  return (
    <>
      <h1 className='text-center my-4'>CONSTRUCTION RENOVATION</h1>
      <h6>
        Construction/addition/renovation/alteration of buildings/structures
        under Group/s and Sub-Divisions shall be assessed as follows:
      </h6>
      <Accordion className='mx-0'>
        {/*A. Division A-1  */}
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            className='accordion-top'
            style={{
              background: '#4bbf73',
              color: '#FFF',
              fontWeight: 'bold',
            }}
          >
            A. DIVISION A-1
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className='accordion-description'>
              <Table responsive className='mb-3'>
                <thead>
                  <tr className='text-center'>
                    <th>#</th>
                    <th>PARTICULARS</th>
                    <th></th>
                    <th>FEE</th>
                    <th className='text-center pr-0'>UNIT</th>
                    <th>EST. FEE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>i.</td>
                    <td className='text-wrap' colSpan={2}>
                      Original complete construction up to 20.00 sq.meters
                    </td>
                    <td>₱2.00 </td>
                    <td>
                      <Form.Control
                        type='text'
                        className='border border-secondary align-middle'
                        style={{
                          marginLeft: '25px',
                          marginRight: '25px',
                        }}
                        disabled={disabled.A1}
                        name='OrigConstruction'
                        placeholder='i.'
                        onChange={handleChangeConst1}
                        value={obj[29].units}
                        // onKeyDown={(e) =>
                        //   e.target.value.length > 14 && e.preventDefault()
                        // }
                        min={0}
                        maxLength={13}
                      />
                    </td>
                    <td className='align-middle'>
                      <p className='mb-0'>
                        ₱
                        {!disabled.A1 &&
                          disabled.A2 &&
                          toFixed(
                            obj.find((el) => el.name === 'OrigConstruction')
                              .estimate,
                            2
                          )}
                        {disabled.A1 &&
                          !disabled.A2 &&
                          toFixed(
                            obj.find(
                              (el) => el.name === 'RenovationConstruction'
                            ).estimate,
                            2
                          )}
                        {!disabled.A1 && !disabled.A2 && 0}
                      </p>
                    </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>ii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Additional/renovation/alteration up to 20.00 sq. meters
                      regardless of floor area of original construction
                    </td>
                    <td>₱2.40 </td>
                    <td>
                      <Form.Control
                        type='text'
                        className='border border-secondary align-middle'
                        style={{
                          marginLeft: '25px',
                          marginRight: '25px',
                        }}
                        disabled={disabled.A2}
                        name='RenovationConstruction'
                        placeholder='ii.-vi.'
                        onChange={handleChangeConst2}
                        value={obj[30].units}
                        // onKeyDown={(e) =>
                        //   e.target.value.length > 14 && e.preventDefault()
                        // }
                        min={0}
                        maxLength={13}
                      />
                    </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>iii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 20.00 sq. meters to 50.00 sq. meters
                    </td>
                    <td>₱3.40 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>iv.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 50.00 sq. meters to 100.00 sq. meters
                    </td>
                    <td>₱4.80 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>v.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 100.00 sq. m to 150 sq. meters
                    </td>
                    <td>₱6.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>vi.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 150.00 sq. meters
                    </td>
                    <td>₱7.20 </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {/* End A */}

        {/*B. Division A-2  */}
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='1'
            className='accordion-top'
            style={{
              background: '#4bbf73',
              color: '#FFF',
              fontWeight: 'bold',
            }}
          >
            B. DIVISION A-2
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='1'>
            <Card.Body className='accordion-description'>
              <Table responsive className='mb-3'>
                <thead>
                  <tr className='text-center'>
                    <th>#</th>
                    <th>PARTICULARS</th>
                    <th></th>
                    <th>FEE</th>
                    <th className='text-center pr-0'>UNIT</th>
                    <th>EST. FEE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>i.</td>
                    <td className='text-wrap' colSpan={2}>
                      Original complete construction up to 20.00 sq.meters
                    </td>
                    <td>₱3.00 </td>
                    <td>
                      <Form.Control
                        type='text'
                        className='border border-secondary align-middle'
                        style={{
                          marginLeft: '25px',
                          marginRight: '25px',
                        }}
                        name='OrigComplete'
                        placeholder='i.'
                        onChange={handleChangeConst3}
                        value={obj[31].units}
                        // onKeyDown={(e) =>
                        //   e.target.value.length > 14 && e.preventDefault()
                        // }
                        min={0}
                        maxLength={13}
                        disabled={disabled.A3}
                      />
                    </td>
                    <td className='align-middle'>
                      <p className='mb-0'>
                        ₱
                        {!disabled.A3 &&
                          disabled.A4 &&
                          toFixed(
                            obj.find((el) => el.name === 'OrigComplete')
                              .estimate,
                            2
                          )}
                        {disabled.A3 &&
                          !disabled.A4 &&
                          toFixed(
                            obj.find((el) => el.name === 'RenovationComplete')
                              .estimate,
                            2
                          )}
                        {!disabled.A3 && !disabled.A4 && 0}
                      </p>
                    </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>ii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Additional/renovation/alteration up to 20.00 sq. meters
                      regardless of floor area of original construction
                    </td>
                    <td>₱3.40 </td>
                    <td>
                      <Form.Control
                        type='text'
                        className='border border-secondary align-middle'
                        style={{
                          marginLeft: '25px',
                          marginRight: '25px',
                        }}
                        name='RenovationComplete'
                        placeholder='ii.-vi.'
                        onChange={handleChangeConst4}
                        value={obj[32].units}
                        // onKeyDown={(e) =>
                        //   e.target.value.length > 14 && e.preventDefault()
                        // }
                        min={0}
                        maxLength={13}
                        disabled={disabled.A4}
                      />
                    </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>iii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 20.00 sq. meters to 50.00 sq. meters
                    </td>
                    <td>₱5.20 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>iv.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 50.00 sq. meters to 100.00 sq. meters
                    </td>
                    <td>₱8.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>v.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 100.00 sq. m to 150 sq. meters
                    </td>
                    <td>₱8.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>vi.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 150.00 sq. meters
                    </td>
                    <td>₱8.40 </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {/* End B */}

        {/*C. DIVISION B-1/C-1/E-1, 2, 3/F-1/G-1, 2, 3, 4, 5/H-1, 2, 3, 4/I-1 AND J-1, 2, 3  */}
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='2'
            className='accordion-top'
            style={{
              background: '#4bbf73',
              color: '#FFF',
              fontWeight: 'bold',
            }}
          >
            C. DIVISION B-1/C-1/E-1, 2, 3/F-1/G-1, 2, 3, 4, 5/H-1, 2, 3, 4/I-1
            AND J-1, 2, 3
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='2'>
            <Card.Body className='accordion-description'>
              <Table responsive className='mb-3'>
                <thead>
                  <tr className='text-center'>
                    <th>#</th>
                    <th>PARTICULARS</th>
                    <th></th>
                    <th>FEE</th>
                    <th className='text-center pr-0'>UNIT</th>
                    <th>EST. FEE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>i.</td>
                    <td className='text-wrap' colSpan={2}>
                      Up to 5,000
                    </td>
                    <td>₱23.00 </td>
                    <td>
                      <Form.Control
                        type='text'
                        className='border border-secondary align-middle'
                        style={{
                          marginLeft: '25px',
                          marginRight: '25px',
                        }}
                        name='DivisionC'
                        placeholder='i.-x.'
                        onChange={handleChangeConst5}
                        value={obj[33].units}
                        // onKeyDown={(e) =>
                        //   e.target.value.length > 14 && e.preventDefault()
                        // }
                        min={0}
                        maxLength={13}
                      />
                    </td>
                    <td className='align-middle'>
                      <p className='mb-0'>
                        ₱
                        {toFixed(
                          obj.find((el) => el.name === 'DivisionC').estimate,
                          2
                        )}
                      </p>
                    </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>ii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 5,000 to 6,000
                    </td>
                    <td>₱22.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>iii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 6,000 to 7,000
                    </td>
                    <td>₱20.50 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>iv.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 7,000 to 8,000
                    </td>
                    <td>₱19.50 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>v.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 8,000 to 9,000
                    </td>
                    <td>₱18.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>vi.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 9,000 to 10,000
                    </td>
                    <td>₱17.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>vii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 10,000 to 15,000
                    </td>
                    <td>₱16.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>viii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 15,000 to 20,000
                    </td>
                    <td>₱15.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>ix.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 20,000 to 30,000
                    </td>
                    <td>₱14.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>x.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 30,000
                    </td>
                    <td>₱12.00 </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {/* End C */}

        {/* D. DIVISION C-2/D-1, 2, 3 */}
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='3'
            className='accordion-top'
            style={{
              background: '#4bbf73',
              color: '#FFF',
              fontWeight: 'bold',
            }}
          >
            D. DIVISION C-2/D-1, 2, 3
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='3'>
            <Card.Body className='accordion-description'>
              <Table responsive className='mb-3'>
                <thead>
                  <tr className='text-center'>
                    <th>#</th>
                    <th>PARTICULARS</th>
                    <th></th>
                    <th>FEE</th>
                    <th className='text-center pr-0'>UNIT</th>
                    <th>EST. FEE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>i.</td>
                    <td className='text-wrap' colSpan={2}>
                      Up to 5,000
                    </td>
                    <td>₱12.00 </td>
                    <td>
                      <Form.Control
                        type='text'
                        className='border border-secondary align-middle'
                        style={{
                          marginLeft: '25px',
                          marginRight: '25px',
                        }}
                        name='DivisionD'
                        placeholder='i.-x.'
                        onChange={handleChangeConst6}
                        value={obj[34].units}
                        // onKeyDown={(e) =>
                        //   e.target.value.length > 14 && e.preventDefault()
                        // }
                        min={0}
                        maxLength={13}
                      />
                    </td>
                    <td className='align-middle'>
                      <p className='mb-0'>
                        ₱
                        {toFixed(
                          obj.find((el) => el.name === 'DivisionD').estimate,
                          2
                        )}
                      </p>
                    </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>ii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 5,000 to 6,000
                    </td>
                    <td>₱11.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>iii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 6,000 to 7,000
                    </td>
                    <td>₱10.20</td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>iv.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 7,000 to 8,000
                    </td>
                    <td>₱9.60 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>v.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 8,000 to 9,000
                    </td>
                    <td>₱9.00 </td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>vi.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 9,000 to 10,000
                    </td>
                    <td>₱8.40</td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>vii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 10,000 to 15,000
                    </td>
                    <td>₱7.20</td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>viii.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 15,000 to 20,000
                    </td>
                    <td>₱6.60</td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>ix.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 20,000 to 30,000
                    </td>
                    <td>₱6.00</td>
                  </tr>
                  <tr className='text-justify align-middle'>
                    <td className='align-middle'>x.</td>
                    <td className='text-wrap' colSpan={2}>
                      Above 30,000
                    </td>
                    <td>₱5.00</td>
                  </tr>
                </tbody>
              </Table>
              <p>
                NOTE: Computation of the building fee in item 3.d. follows the
                example of Section 3.c. of this Schedule.
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {/* End D */}

        <Row className='pt-3'>
          <Col xl={12}>
            <Table responsive className='mb-5'>
              <thead>
                <tr className='text-center align-middle'>
                  <th colSpan={3}>
                    <h5>E. Division J-2 structures</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr colSpan={3}>
                  <td className='align-middle'>
                    Structures shall be assessed 50% of the rate of the
                    principal building of which they are accessories (Sections
                    3.a. to 3.d.).
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Accordion>
    </>
  )
}

export default ConstructionRenovation
