import React, { useEffect } from 'react'
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

// Components
import DownloadableForms from '../reusable/DownloadableForms'
import GuestMain from '../layout/GuestLayout/GuestMain'

// Actions
import { downloadableformsRetrieve } from '../redux/actions/downloadableformsActions'

// Layout
import Loading from '../reusable/Loading'

const DownloadScreen = ({ history, location }) => {
  // Declaration
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const downloadableforms = useSelector((state) => state.downloadableforms)
  const { downloadableformsDetail } = downloadableforms

  const redirect = location.search
    ? location.search.split('=')[1]
    : '/dashboard'
  // End Declaration

  // UseEffect
  useEffect(() => {
    dispatch(downloadableformsRetrieve())
    if (userCredentials) {
      history.push(redirect)
    }
  }, [userCredentials, history, redirect, dispatch])
  // End UseEffect

  return (
    <GuestMain>
      <Container
        fluid
        style={{
          background: '#f9f6f1',
          color: '#00304b',
          backgroundImage: 'url(./assets/img/lp-bldgo-bg2.webp)',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
        }}
        className='pb-3'
      >
        {downloadableformsDetail ? (
          <div>
            <Container className='bg-white shadow-sm pb-5'>
              <Row>
                <Col xl={12}>
                  <div className='pt-3 px-3'>
                    <h3
                      className='text-center fw-bold mb-0'
                      style={{ color: '#00304b' }}
                    >
                      Need Help?
                    </h3>
                    <div className='d-flex justify-content-center'>
                      <hr
                        style={{
                          background: '#ffeb36',
                          paddingBottom: '2px',
                          width: '200px',
                        }}
                      />
                    </div>
                    <p className='text-justify'>
                      Building permit and certificate of occupancy are required
                      by law when constructing or renovating structures. You may
                      download the necessary forms here and complete the
                      requirements in the checklist provided. Kindly submit them
                      to the Office of the Building Official (OBO) located at
                      the Ground Floor, Main Building, City Hall Compound, Brgy.
                      Pamplona Tres, Las Piñas City.
                    </p>

                    <div className='bg-light px-2 py-2'>
                      <h6>Our tip</h6>
                      <p>
                        Seek professional planners and builders to ensure
                        safety. Be clear on what you want and need during
                        development. Consider your budget and specific details
                        of what design should be provided. It is highly
                        recommended to work with them so that anything that may
                        cause a dispute along the line may be resolved as early
                        as possible.
                      </p>
                    </div>
                    <div>
                      <hr />

                      {/* Downloadable Forms Table Area */}
                      <DownloadableForms data={downloadableformsDetail} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div
            className='py-2 d-flex align-items-center justify-content-center'
            style={{
              height: '80vh',
            }}
          >
            <Loading />
          </div>
        )}
      </Container>
    </GuestMain>
  )
}

export default DownloadScreen
