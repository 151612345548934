import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import io from 'socket.io-client'

// Actions
import {
  updateApplicationStatus,
  retrieveApplicationComments,
} from '../redux/actions/DocumentControllers/documentControllerActions'

// Layout
import SuccessModal from './SuccessModal'
import CommentModal from '../modal/CommentModal'

const socket = io.connect(process.env.REACT_APP_URL)

const ChangeApplicationStatus = (props) => {
  const dispatch = useDispatch()

  const [state, setState] = useState({
    status: '',
    remarks: '',
    comments: '',
  })

  const [modal, setModal] = useState({
    success: false,
    comments: false,
  })

  const dc = useSelector((state) => state.documentControllerReducer)

  const { updatedStatus } = dc

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const handleStatusChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    if (
      (state.status === 'RELEASE APPLICATION' ||
        state.status === 'RELEASE APPLICATION WITH REMARKS') &&
      props.balance !== 0
    ) {
      e.preventDefault()
      alert('Order of Payment must be paid first')
      props.onHide()
    } else {
      e.preventDefault()
      dispatch(
        updateApplicationStatus({
          id: props.payload.statusID,
          status: state.status,
          remarks: state.remarks,
          withEvaluation: '',
          withInspection: '',
          application_ID: props.payload.id,
          userID: props.payload.userID,
          adminID: userCredentials.id,
          comments: state.comments,
          commentTo:
            state.status === 'FOR COMPLIANCE' ||
            state.status === 'APPROVED APPLICATION' ||
            state.status === 'RELEASE APPLICATION' ||
            state.status === 'RELEASE APPLICATION WITH REMARKS'
              ? props.payload.userID
              : userCredentials.id,
          createdBy: userCredentials.id,
          email: props.payload.EmailAddress,
          mobileNo: props.payload.MobileNo,
        })
      )
      props.onClose()
      setModal({ success: true })
    }
  }

  const handleViewComment = () => {
    setModal({ ...modal, comments: true })
    dispatch(retrieveApplicationComments({ id: props.payload.id }))
    props.onClose()
  }

  useEffect(() => {
    if (Object.keys(props.payload).length > 0) {
      setState((state) => ({
        ...state,
        status: props.payload.status,
        remarks: props.payload.remarks !== null ? props.payload.remarks : '',
      }))
    }
  }, [props])

  useEffect(() => {
    if (updatedStatus && updatedStatus.message) {
      socket.emit('send_change_status', {
        message: {
          appID: props.payload.id,
          notif: 'Update Application Status',
        },
      })
    }
  }, [updatedStatus])

  // console.log(props.balance)

  return (
    <>
      <Modal
        size='md'
        show={props.show}
        onHide={props.onHide}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-1' />
            Application Status
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Status</Form.Label>

              <Form.Control
                as='select'
                size='sm'
                name='status'
                value={state.status}
                onChange={handleStatusChange}
                required
              >
                {(props.payload.status === 'FOR COMPLIANCE' ||
                  props.payload.status === 'FOR INSPECTION AND EVALUATION' ||
                  props.payload.status === 'APPLICATION VERIFICATION') && (
                  <>
                    <option value=''>choose status here . . .</option>
                    <option value='FOR COMPLIANCE'>FOR COMPLIANCE</option>
                    <option value='FOR INSPECTION AND EVALUATION'>
                      FOR INSPECTION AND EVALUATION
                    </option>
                    <option value='APPLICATION VERIFICATION'>
                      APPLICATION VERIFICATION
                    </option>
                  </>
                )}

                {(props.payload.status === 'APPROVED APPLICATION' ||
                  props.payload.status === 'RELEASE APPLICATION' ||
                  props.payload.status ===
                    'RELEASE APPLICATION WITH REMARKS') && (
                  <>
                    <option value=''>Choose status here ...</option>
                    {props.balance === 0 && (
                      <option value='RELEASE APPLICATION'>
                        RELEASE APPLICATION
                      </option>
                    )}

                    <option value='RELEASE APPLICATION WITH REMARKS'>
                      RELEASE APPLICATION WITH REMARKS
                    </option>
                  </>
                )}
              </Form.Control>
              {props.balance !== 0 && (
                <i className='text-danger' style={{ fontSize: '11px' }}>
                  THE "RELEASE APPLICATION" STATUS WILL BE AVAILABLE ONCE THE
                  APPLICATION IS FULLY PAID
                </i>
              )}
            </Form.Group>

            <Form.Group className='mt-2'>
              <Form.Label>for office personnel comments:</Form.Label>
              <Form.Control
                as='textarea'
                size='sm'
                name='comments'
                onChange={handleStatusChange}
                placeholder='Ex: (Complete Requirements - www.google.com) link created for backup ... '
                required
              />
            </Form.Group>

            <Form.Group className='mt-2'>
              <Form.Label>for applicant remarks:</Form.Label>
              <Form.Control
                as='textarea'
                size='sm'
                name='remarks'
                placeholder='Ex: (Incomplete requirements: "Requirements needed") ... '
                onChange={handleStatusChange}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='info'
              size='sm'
              disabled={
                state.status === '' ||
                state.status === 'APPLICATION VERIFICATION'
                  ? true
                  : false
              }
            >
              Submit
            </Button>
            <Button
              type='submit'
              variant='success'
              size='sm'
              onClick={handleViewComment}
            >
              Comments
            </Button>
            <Button variant='danger' size='sm' onClick={props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Comments */}
      <CommentModal
        show={modal.comments}
        onHide={() => {
          setModal({ comments: false })
          props.onOpen()
        }}
      />
      {/* End Comments */}

      {/*Success Modal */}
      <SuccessModal
        show={modal.success}
        onHide={() => {
          props.onClose()
          setModal({ success: false })
        }}
        transLoading={updatedStatus && updatedStatus.loading}
        success={updatedStatus && updatedStatus.message}
        error={updatedStatus && updatedStatus.error}
      />
      {/* End Success Modal */}
    </>
  )
}

export default ChangeApplicationStatus
