import React, { useState, useEffect } from 'react'
import {
  Col,
  Container,
  Row,
  Table,
  InputGroup,
  Button,
  Form,
  Pagination,
  Modal,
} from 'react-bootstrap'
import { FaEllipsisH, FaSearch, FaCheckCircle } from 'react-icons/fa'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import io from 'socket.io-client'
import { useToast } from 'rc-toastr'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'
import Loading from '../../reusable/Loading'
import Error from '../../reusable/Error'

// Actions
import {
  insertGuestApplication,
  retriveGuestApplication,
  updateGuestApplication,
  deleteGuestApplication,
} from '../../redux/actions/DocumentControllers/documentControllerActions'

const socket = io.connect(process.env.REACT_APP_URL)

const ApplicationStatusScreen = () => {
  const dispatch = useDispatch()

  const [list, setList] = useState([])

  const [searchTerm, setSearchTerm] = useState('')

  const [modal, setModal] = useState({
    viewApplication: false,
    insertSuccess: false,
    updateSuccess: false,
    isEditting: false,
  })

  const [application, setApplication] = useState({
    transactionNo: '',
    permitNo: '',
    status: '',
    applicantName: '',
    createdBy: '',
    email: '',
  })

  const users = useSelector((state) => state.users)
  const { userCredentials } = users

  const documentControllerReducers = useSelector(
    (state) => state.documentControllerReducer
  )
  const {
    insertedGuestApplication,
    retrievedGuestApplication,
    updatedGuestApplication,
    deletedGuestApplication,
  } = documentControllerReducers
  // End Declaration

  // Function
  const handleOnChange = (e) => {
    setApplication({
      ...application,
      [e.target.name]: e.target.value,
    })
  }
  // End Function

  // Pagination
  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)

  // Pagination
  // Table Size
  const pageSize = 12

  // Every render of the page do the statement inside the useEffect
  useEffect(() => {
    // Set the Display size of the Table
    setpaginatedForms(_(list).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [list])

  // Count Number the length of the Array and Divide it to the page size
  const pageCount = list
    ? // Inline if-else statement
      // Math.ceil convert the parameter into the nearest integer value
      Math.ceil(list.length / pageSize)
    : 0

  // if (pageCount === 1) return null
  // Store the page count ito pages array
  // (1, pageCount) means create an area starting from 1 up to the number result of pageCount plus 1
  const pages = _.range(1, pageCount + 1)

  // fat arrow function for onClick in Pagination
  // pagination function will recieve an argument of pageNo so that if you are going to use this function you must submit a pageNo parameter ex. pagination(page)
  const pagination = (pageNo) => {
    // set the current page value to the argument receive by the function
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    // console.log(pageNo)
    // console.log(startIndex)
    const paginationForms = _(list).slice(startIndex).take(pageSize).value()
    setpaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    // setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    // setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }

  const handleUpdateSubmit = (e) => {
    e.preventDefault()
    dispatch(updateGuestApplication(application))
    setModal({
      viewApplication: false,
      updateSuccess: true,
    })
    setApplication({
      ...application,
      id: '',
      transactionNo: '',
      permitNo: '',
      status: '',
      applicantName: '',
      email: '',
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(insertGuestApplication(application))
    setModal({
      viewApplication: false,
      insertSuccess: true,
    })
    setApplication({
      ...application,
      id: '',
      transactionNo: '',
      permitNo: '',
      status: '',
      applicantName: '',
      email: '',
    })
  }

  const { toast } = useToast()
  const sayHello = (data) => {
    toast.success(data)
  }

  // End Pagination

  // useEffect
  useEffect(() => {
    dispatch(retriveGuestApplication())
    socket.on('received_new_application', (data) => {
      // if (window.confirm(`${data.message} do you want to reload the page ?`)) {
      sayHello(data.message)
      // }
    })
  }, [dispatch, socket])

  useEffect(() => {
    if (retrievedGuestApplication && retrievedGuestApplication.message) {
      setList(() =>
        retrievedGuestApplication.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else if (
            Object.values(column).some(
              (value) =>
                typeof value === 'string' &&
                value.toLowerCase().includes(searchTerm.toLowerCase())
            )
          ) {
            return column
          }
        })
      )
    }

    setApplication((application) => ({
      ...application,
      createdBy: userCredentials.id,
    }))
  }, [searchTerm, retrievedGuestApplication])
  // End useEffect

  console.log(list)

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {retrievedGuestApplication && !retrievedGuestApplication.loading && (
            <>
              {/* Search box */}

              <Row className='pt-5 mb-4'>
                <Col className='my-2' xl='8'>
                  <Button
                    size='sm'
                    variant='info'
                    onClick={() => setModal({ viewApplication: true })}
                  >
                    Add Application
                  </Button>
                </Col>
                {retrievedGuestApplication &&
                  retrievedGuestApplication.message && (
                    <Col className='my-2' xl='4'>
                      <InputGroup>
                        <Form.Control
                          style={{
                            background: '#FFF',
                            border: '1px solid #ced4da',
                          }}
                          value={searchTerm}
                          placeholder='Input search keyword here . . .'
                          onChange={(e) => setSearchTerm(e.target.value)}
                          size='sm'
                        />
                        {searchTerm === '' ? (
                          <InputGroup.Text className='bg-info text-light'>
                            <FaSearch />
                          </InputGroup.Text>
                        ) : (
                          <Button
                            variant='danger'
                            onClick={() => setSearchTerm('')}
                          >
                            x
                          </Button>
                        )}
                      </InputGroup>
                    </Col>
                  )}
              </Row>
              {/* End Search box */}

              {retrievedGuestApplication &&
                retrievedGuestApplication.message && (
                  <>
                    <h5 className='pt-3 pb-2 mb-0'>Application List</h5>
                    <hr
                      className='mt-1 mb-3'
                      style={{
                        background: '#ffeb36',
                        paddingBottom: '2px',
                        width: '200px',
                      }}
                    />

                    {/* Application Table */}
                    <Table size='sm' variant='white' bordered hover responsive>
                      <thead className='bg-info text-light'>
                        <tr>
                          <th className='text-center'>Transaction No.</th>
                          <th className='text-center'>Permit No.</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Applicant</th>
                          <th>CreatedBy</th>
                          <th className='text-center'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedForms.map((e, index) => (
                          <tr key={index}>
                            <td className='text-center'>{e.transactionNo}</td>
                            <td className='text-center'>{e.permitNo}</td>
                            <td>{e.status}</td>
                            <td>
                              {moment(e.timeStamp).format('MMMM D, YYYY')}
                            </td>
                            <td>{e.applicantName}</td>
                            <td>{e.CreatedBy}</td>
                            <td className='text-center'>
                              <Button
                                size='sm'
                                variant='outline-info'
                                onClick={() => {
                                  setModal({
                                    viewApplication: true,
                                    isEditting: true,
                                  })
                                  setApplication({
                                    ...application,
                                    id: e.id,
                                    transactionNo: e.transactionNo,
                                    permitNo:
                                      e.permitNo !== undefined
                                        ? e.permitNo
                                        : '',
                                    status: e.status,
                                    applicantName: e.applicantName,
                                    email: e.emailAddress,
                                  })
                                }}
                              >
                                <FaEllipsisH />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {paginatedForms.length > 0 && (
                      <Row className='mt-4'>
                        <Col className='d-flex justify-content-end'>
                          <Pagination size='sm'>
                            <Pagination.Item
                              onClick={handlePrevbtn}
                              disabled={currentPage === pages[0] ? true : false}
                            >
                              &lt;
                            </Pagination.Item>

                            {renderPageNumber}

                            <Pagination.Item
                              onClick={handleNextbtn}
                              disabled={
                                currentPage === pages[pages.length - 1]
                                  ? true
                                  : false
                              }
                            >
                              &gt;
                            </Pagination.Item>
                          </Pagination>
                        </Col>
                      </Row>
                    )}

                    {/* End Application Table */}
                  </>
                )}

              {retrievedGuestApplication && retrievedGuestApplication.error && (
                <div className='px-4'>
                  <Error message='No application found' />
                </div>
              )}
            </>
          )}

          {retrievedGuestApplication && retrievedGuestApplication.loading && (
            <div className='pt-4 px-4'>
              <Loading />
            </div>
          )}
        </Container>
      </Container>

      {/* Create Modal */}
      <Modal
        size='lg'
        show={modal.viewApplication}
        onHide={() => setModal({ viewApplication: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> Application
          </Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            !modal.isEditting ? handleSubmit(e) : handleUpdateSubmit(e)
          }}
        >
          <Modal.Body>
            <Form.Group>
              <Form.Label>Transaction No.</Form.Label>
              <Form.Control
                type='text'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                value={application.transactionNo}
                name='transactionNo'
                onChange={handleOnChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Permit No.</Form.Label>
              <Form.Control
                type='text'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                value={application.permitNo}
                name='permitNo'
                onChange={handleOnChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as='select'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                required
                value={application.status}
                onChange={handleOnChange}
                name='status'
              >
                <option>Choose here ...</option>
                <option value='APPLICATION VERIFICATION'>
                  APPLICATION VERIFICATION
                </option>
                <option value='FOR INSPECTION AND EVALUATION'>
                  FOR INSPECTION AND EVALUATION
                </option>
                <option value='FOR APPROVAL'>FOR APPROVAL</option>
                <option value='APPROVED APPLICATION'>
                  APPROVED APPLICATION
                </option>
                <option value='RELEASE APPLICATION WITH REMARKS'>
                  RELEASE APPLICATION WITH REMARKS
                </option>
                <option value='RELEASE APPLICATION'>RELEASE APPLICATION</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Applicant Name</Form.Label>
              <Form.Control
                type='text'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                value={application.applicantName}
                onChange={handleOnChange}
                required
                name='applicantName'
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='text'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                value={application.email}
                name='email'
                onChange={handleOnChange}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' variant='success'>
              Submit
            </Button>
            {modal.isEditting && (
              <Button
                variant='outline-danger'
                onClick={() => {
                  if (
                    window.confirm(`Are you sure you want to delete this application? 

"Note: if you delete this application, it will be deleted permanently."`)
                  ) {
                    setModal({
                      viewApplication: false,
                      isEditting: false,
                    })
                    dispatch(
                      deleteGuestApplication({ id: application.transactionNo })
                    )
                    setApplication({
                      ...application,
                      id: '',
                      transactionNo: '',
                      permitNo: '',
                      status: '',
                      applicantName: '',
                      email: '',
                    })
                  }
                }}
              >
                Delete
              </Button>
            )}
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewApplication: false,
                  isEditting: false,
                })
                setApplication({
                  ...application,
                  id: '',
                  transactionNo: '',
                  permitNo: '',
                  status: '',
                  applicantName: '',
                  email: '',
                })
              }}
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* End Create Modal */}

      {/*Insert Success Modal */}
      <SuccessModal
        show={modal.insertSuccess}
        onHide={() => {
          setModal({ insertSuccess: false })
          // window.location.reload(true)
        }}
        transLoading={
          insertedGuestApplication && insertedGuestApplication.loading
        }
        success={insertedGuestApplication && insertedGuestApplication.message}
        error={insertedGuestApplication && insertedGuestApplication.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default ApplicationStatusScreen
