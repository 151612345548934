export const GET_FOR_EVALUATION_REQ = 'GET_FOR_EVALUATION_REQ'
export const GET_FOR_EVALUATION_SUCCESS = 'GET_FOR_EVALUATION_SUCCESS'
export const GET_FOR_EVALUATION_FAILED = 'GET_FOR_EVALUATION_FAILED'

export const GET_EVALUATED_LIST_REQ = 'GET_EVALUATED_LIST_REQ'
export const GET_EVALUATED_LIST_SUCCESS = 'GET_EVALUATED_LIST_SUCCESS'
export const GET_EVALUATED_LIST_FAILED = 'GET_EVALUATED_LIST_FAILED'

export const CREATE_EVALUATION_REQ = 'CREATE_EVALUATION_REQ'
export const CREATE_EVALUATION_SUCCESS = 'CREATE_EVALUATION_SUCCESS'
export const CREATE_EVALUATION_FAILED = 'CREATE_EVALUATION_FAILED'

export const UPDATE_EVALUATION_REQ = 'UPDATE_EVALUATION_REQ'
export const UPDATE_EVALUATION_SUCCESS = 'UPDATE_EVALUATION_SUCCESS'
export const UPDATE_EVALUATION_FAILED = 'UPDATE_EVALUATION_FAILED'

export const GET_EVALUATION_DETAILS_REQ = 'GET_EVALUATION_DETAILS_REQ'
export const GET_EVALUATION_DETAILS_SUCCESS = 'GET_EVALUATION_DETAILS_SUCCESS'
export const GET_EVALUATION_DETAILS_FAILED = 'GET_EVALUATION_DETAILS_FAILED'

export const GET_APPDETAILS_REQ = 'GET_APPDETAILS_REQ'
export const GET_APPDETAILS_SUCCESS = 'GET_APPDETAILS_SUCCESS'
export const GET_APPDETAILS_FAILED = 'GET_APPDETAILS_FAILED'

export const GET_COMMENTLIST_REQ = 'GET_COMMENTLIST_REQ'
export const GET_COMMENTLIST_SUCCESS = 'GET_COMMENTLIST_SUCCESS'
export const GET_COMMENTLIST_FAILED = 'GET_COMMENTLIST_FAILED'

export const INSERT_COMMENT_REQ = 'INSERT_COMMENT_REQ'
export const INSERT_COMMENT_SUCCESS = 'INSERT_COMMENT_SUCCESS'
export const INSERT_COMMENT_FAILED = 'INSERT_COMMENT_FAILED'
