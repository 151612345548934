import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'

const ProtectedRoute = ({ isAuth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth === true) {
          return <Component />
        } else {
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      }}
    />
  )
}

export default withRouter(ProtectedRoute)
