export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED'

export const GET_ENGRPROFILE_REQUEST = 'GET_ENGRPROFILE_REQUEST'
export const GET_ENGRPROFILE_SUCCESS = 'GET_ENGRPROFILE_SUCCESS'
export const GET_ENGRPROFILE_FAILED = 'GET_ENGRPROFILE_FAILED'

export const GET_REGION_REQ = 'GET_REGION_REQ'
export const GET_REGION_SUCCESS = 'GET_REGION_SUCCESS'
export const GET_REGION_FAILED = 'GET_REGION_FAILED'

export const GET_CITIES_REQ = 'GET_CITIES_REQ'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_FAILED = 'GET_CITIES_FAILED'

export const GET_BARANGAY_REQ = 'GET_BARANGAY_REQ'
export const GET_BARANGAY_SUCCESS = 'GET_BARANGAY_SUCCESS'
export const GET_BARANGAY_FAILED = 'GET_BARANGAY_FAILED'

export const USER_UPDATE_BASICINFO_REQ = 'USER_UPDATE_BASICINFO_REQ'
export const USER_UPDATE_BASICINFO_SUCCESS = 'USER_UPDATE_BASICINFO_SUCCESS'
export const USER_UPDATE_BASICINFO_FAILED = 'USER_UPDATE_BASICINFO_FAILED'

export const USER_UPDATE_PERADDRESS_REQ = 'USER_UPDATE_PERADDRESS_REQ'
export const USER_UPDATE_PERADDRESS_SUCCESS = 'USER_UPDATE_PERADDRESS_SUCCESS'
export const USER_UPDATE_PERADDRESS_FAILED = 'USER_UPDATE_PERADDRESS_FAILED'

export const USER_UPDATE_PREADDRESS_REQ = 'USER_UPDATE_PREADDRESS_REQ'
export const USER_UPDATE_PREADDRESS_SUCCESS = 'USER_UPDATE_PREADDRESS_SUCCESS'
export const USER_UPDATE_PREADDRESS_FAILED = 'USER_UPDATE_PREADDRESS_FAILED'

export const USER_UPDATE_PROFESSIONINFO_REQ = 'USER_UPDATE_PROFESSIONINFO_REQ'
export const USER_UPDATE_PROFESSIONINFO_SUCCESS =
  'USER_UPDATE_PROFESSIONINFO_SUCCESS'
export const USER_UPDATE_PROFESSIONINFO_FAILED =
  'USER_UPDATE_PROFESSIONINFO_FAILED'

export const USER_UPDATE_USERCREDENTIALS_REQ = 'USER_UPDATE_USERCREDENTIALS_REQ'
export const USER_UPDATE_USERCREDENTIALS_SUCCESS =
  'USER_UPDATE_USERCREDENTIALS_SUCCESS'
export const USER_UPDATE_USERCREDENTIALS_FAILED =
  'USER_UPDATE_USERCREDENTIALS_FAILED'

export const USER_DELETE_USER_REQ = 'USER_DELETE_USER_REQ'
export const USER_DELETE_USER_SUCCESS = 'USER_DELETE_USER_SUCCESS'
export const USER_DELETE_USER_FAILED = 'USER_DELETE_USER_FAILED'

export const USER_ACCOUNT_VER_REQ = 'USER_ACCOUNT_VER_REQ'
export const USER_ACCOUNT_VER_SUCCESS = 'USER_ACCOUNT_VER_SUCCESS'
export const USER_ACCOUNT_VER_FAILED = 'USER_ACCOUNT_VER_FAILED'

// RUD Engineer
export const UPDATE_ENGRPROFINFO_REQ = 'UPDATE_ENGRPROFINFO_REQ'
export const UPDATE_ENGRPROFINFO_SUCCESS = 'UPDATE_ENGRPROFINFO_SUCCESS'
export const UPDATE_ENGRPROFINFO_FAILED = 'UPDATE_ENGRPROFINFO_FAILED'

export const GET_ENGATTACHMENTS_REQ = 'GET_ENGATTACHMENTS_REQ'
export const GET_ENGATTACHMENTS_SUCCESS = 'GET_ENGATTACHMENTS_SUCCESS'
export const GET_ENGATTACHMENTS_FAILED = 'GET_ENGATTACHMENTS_FAILED'
