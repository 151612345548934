import React from 'react'

// Component Layout
import Header from './GuestHeader'
import Footer from './GuestFooter'

const GuestMain = ({ children }) => {
  return (
    <div>
      <header>
        <Header />
      </header>
      <main className='guest' style={{ background: '#f9f6f1' }}>
        {children}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export default GuestMain
