import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  HeaderCenter: {
    textAlign: 'center',
    fontSize: 12,
    width: '100%',
    paddingTop: '10px',
  },
})

const DcBldgPermit = ({ info }) => {
  return (
    <Document file='BuildingPermitCertificate.pdf'>
      <Page size='Letter' orientation='landscape' style={styles.body} wrap>
        <View style={{ border: '3px solid black' }}>
          <View style={{ border: '1px solid black', margin: '2px' }}>
            <View>
              <View style={styles.row}>
                <Text style={styles.HeaderCenter}>
                  Republic of the Philippines
                </Text>
              </View>
              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 12,
                    width: '100%',
                  }}
                >
                  Department of Public Works and Highway
                </Text>
              </View>
              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 12,
                    width: '100%',
                    fontWeight: 'bold',
                  }}
                >
                  City of Las Piñas
                </Text>
              </View>

              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 12,
                    width: '100%',
                  }}
                >
                  Metropolitan Manila
                </Text>
              </View>
              <View style={styles.row}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 12,
                    width: '100%',
                  }}
                >
                  OFFICE OF THE BUILDING OFFICIAL
                </Text>
              </View>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 14,
                  width: '100%',
                  paddingTop: '8px',
                  paddingBottom: '15px',
                  fontFamily: 'Times-Bold',
                }}
              >
                BUILDING PERMIT
              </Text>
            </View>

            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Text style={{ width: '20%' }}></Text>
              <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
              <Text
                style={{
                  width: '11%',
                  textAlign: 'center',
                  fontSize: 12,
                }}
              >
                NEW
              </Text>

              <Text style={{ width: '10%' }}></Text>
              <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
              <Text style={{ width: '12%', textAlign: 'center', fontSize: 12 }}>
                RENEWAL
              </Text>

              <Text style={{ width: '10%' }}></Text>
              <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
              <Text style={{ width: '12%', textAlign: 'center', fontSize: 12 }}>
                AMENDATORY
              </Text>

              <Text style={{ width: '20%' }}></Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '70%',
                  paddingTop: '20px',
                  paddingLeft: '20px',
                }}
              >
                BUILDING PERMIT NO :
                {info !== undefined ? (
                  <Text style={{ textDecoration: 'underline' }}>
                    {info[0].categCode + ' ' + info[0].id}
                  </Text>
                ) : (
                  '_________'
                )}
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '30%',
                  paddingTop: '20px',
                  paddingRight: '20px',
                }}
              >
                OFFICIAL RECEIPT NO :
                {info !== undefined ? (
                  <Text style={{ textDecoration: 'underline' }}>
                    {info[0].orNo}
                  </Text>
                ) : (
                  '_________'
                )}
              </Text>
            </View>
            <View style={styles.row}>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '70%',
                  paddingTop: '5px',
                  paddingLeft: '20px',
                }}
              >
                DATE ISSUED :
                {info !== undefined ? (
                  <Text style={{ textDecoration: 'underline' }}>
                    {info[0].timeStamp}
                  </Text>
                ) : (
                  '_________'
                )}
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '30%',
                  paddingTop: '5px',
                  paddingRight: '20px',
                }}
              >
                DATE PAID :
                {info !== undefined ? (
                  <Text style={{ textDecoration: 'underline' }}>
                    {info[0].orDate}
                  </Text>
                ) : (
                  '_________'
                )}
              </Text>
            </View>
            <View style={styles.row}>
              <Text
                style={{
                  fontSize: 12,
                  paddingTop: '10px',
                  paddingBottom: '15px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  textIndent: '30px',
                }}
              >
                This PERMIT is issued pursuant to Sections 301, 302, 303, and
                304 of the National Building Code of the Philippines (PD 1096),
                its Revised IRR, other Referral Codes and JMC No. 2018 - 01.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '22%',
                  paddingTop: '5px',
                  paddingLeft: '50px',
                }}
              >
                Owner / Applicant :
              </Text>
              {info !== undefined ? (
                <Text
                  break
                  style={{
                    textDecoration: 'underline',
                    width: '78%',
                    fontSize: 12,
                    marginRight: '20px',
                  }}
                >
                  {info.map(
                    (e, index) =>
                      `${e.Owner} ${index !== info.length - 1 ? ',' : '.'} `
                  )}
                </Text>
              ) : (
                <Text
                  style={{
                    textDecoration: 'underline',
                    width: '78%',
                    fontSize: 12,
                    marginRight: '20px',
                  }}
                >
                  _________________________
                </Text>
              )}
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '50%',
                  paddingTop: '5px',
                  paddingLeft: '50px',
                }}
              >
                Form of Ownership : _________________________
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '50%',
                  paddingTop: '5px',
                  paddingRight: '20px',
                }}
              >
                and Economic Activity: _________________________
              </Text>
            </View>

            <View style={styles.row}>
              <View
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '50%',
                  paddingTop: '5px',
                  paddingLeft: '50px',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '30%', fontSize: 12 }}>
                  Scope of Work :
                </Text>
                {info !== undefined ? (
                  <Text
                    style={{
                      textDecoration: 'underline',
                      width: '70%',
                      fontSize: 12,
                      marginHorizontal: '10px',
                    }}
                  >
                    {info[0].scopeOfWork !== null ? info[0].scopeOfWork : ''}
                  </Text>
                ) : (
                  <Text
                    style={{
                      textDecoration: 'underline',
                      width: '70%',
                      fontSize: 12,
                      marginHorizontal: '10px',
                    }}
                  >
                    _________________________
                  </Text>
                )}
              </View>

              <View
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '50%',
                  paddingTop: '5px',
                  paddingRight: '20px',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '32%', fontSize: 12 }}>
                  Number of Unit/s :
                </Text>
                {info !== undefined ? (
                  <Text
                    style={{
                      textDecoration: 'underline',
                      width: '78%',
                      fontSize: 12,
                      marginHorizontal: '10px',
                    }}
                  >
                    {info[0].noOfUnits}
                  </Text>
                ) : (
                  <Text
                    style={{
                      textDecoration: 'underline',
                      width: '78%',
                      fontSize: 12,
                      marginHorizontal: '10px',
                    }}
                  >
                    _________________________
                  </Text>
                )}
              </View>
            </View>

            <View style={styles.row}>
              <View
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '50%',
                  paddingTop: '5px',
                  paddingLeft: '50px',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '40%', fontSize: 12 }}>
                  Occupancy Character :
                </Text>

                {info !== undefined ? (
                  <Text
                    style={{
                      textDecoration: 'underline',
                      width: '60%',
                      fontSize: 12,
                    }}
                  >
                    {info[0].useType !== null ? info[0].useType : ''}
                  </Text>
                ) : (
                  <Text
                    style={{
                      textDecoration: 'underline',
                      width: '60%',
                      fontSize: 12,
                    }}
                  >
                    _________________________
                  </Text>
                )}
              </View>

              <View
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '50%',
                  paddingTop: '5px',
                  paddingRight: '20px',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ width: '32%', fontSize: 12 }}>
                  and Classified as :
                </Text>
                {info !== undefined ? (
                  <Text
                    style={{
                      textDecoration: 'underline',
                      width: '78%',
                      fontSize: 12,
                    }}
                  >
                    {info[0].computeAs !== null ? info[0].computeAs : ''}
                  </Text>
                ) : (
                  <Text
                    style={{
                      textDecoration: 'underline',
                      width: '78%',
                      fontSize: 12,
                    }}
                  >
                    _________________________
                  </Text>
                )}
              </View>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '20%',
                  paddingTop: '5px',
                  paddingLeft: '50px',
                }}
              >
                Project Location :
              </Text>
              {info !== undefined ? (
                <Text
                  style={{
                    textDecoration: 'underline',
                    width: '70%',
                    fontSize: 12,
                    paddingTop: '5px',
                  }}
                >
                  {info[0].Project_Location !== null
                    ? info[0].Project_Location
                    : ''}
                </Text>
              ) : (
                <Text
                  style={{
                    textDecoration: 'underline',
                    width: '70%',
                    fontSize: 12,
                    paddingTop: '5px',
                  }}
                >
                  _________________________
                </Text>
              )}
            </View>
            <View style={styles.row}>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  width: '36%',
                  paddingTop: '5px',
                  paddingLeft: '50px',
                }}
              >
                Professional In-charge of Construction :
              </Text>
              {info !== undefined && info[0].Name !== null ? (
                <Text
                  style={{
                    textDecoration: 'underline',
                    fontSize: 12,
                    width: '54%',
                    paddingTop: '5px',
                  }}
                >
                  {info[0].Name !== null ? info[0].Name : ''}
                </Text>
              ) : (
                <Text
                  style={{
                    textDecoration: 'underline',
                    fontSize: 12,
                    width: '54%',
                    paddingTop: '5px',
                  }}
                >
                  _________________________
                </Text>
              )}
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  fontSize: 12,
                  paddingTop: '30px',
                  paddingBottom: '30px',
                  paddingLeft: '100px',
                  fontFamily: 'Times-Bold',
                }}
              >
                PERMIT ISSUED BY:
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  fontSize: 12,
                  textAlign: 'center',
                  width: '100%',
                  fontFamily: 'Times-Bold',
                }}
              >
                {info !== undefined
                  ? `ENGR. ${info[0].CityEngineer}`
                  : '____________'}
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  fontSize: 12,
                  paddingTop: '5px',
                  paddingBottom: '30px',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                Building Official
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  fontSize: 8,
                  paddingTop: '10px',
                  paddingBottom: '20px',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                THIS PERMIT MAY BE CANCELLED OR REVOKED PURSUANT TO SECTIONS 305
                AND 303 OF THE NATIONAL BUILDING CODE OF THE PHILIPPINES (PD
                1096)
              </Text>
            </View>
          </View>
        </View>
        {/* <View style={styles.row}>
          <Text
            style={{
              fontSize: 8,
              paddingTop: '5px',
              textAlign: 'center',
              width: '100%',
            }}
          >
            This serves as provisional building permit valid only for 15 days.
            Kindly submit one (1) set of signed and sealed copies of your
            documents to the Office of the Building Official. Failure to submit
            shall render this provisionary permit void.
          </Text>
        </View> */}

        {/* 2nd Page */}

        <View break style={{ border: '3px solid black' }}>
          <View style={{ border: '1px solid black', margin: '2px' }}>
            <Text
              style={{
                fontSize: 10,
                paddingLeft: '50px',
                paddingTop: '30px',
                paddingBottom: '20px',
                fontFamily: 'Times-Bold',
              }}
            >
              TERMS AND CONDITIONS:
            </Text>
            <View style={styles.row}>
              <Text style={{ paddingLeft: '20px', fontSize: 10 }}>1. </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                }}
              >
                That the proposed
                construction/erection/addition/alteration/renovation/conversion/repair/moving/demolition,
                etc. shall be in conformity with the provisions of the National
                Building Code of the Philippines (PD 1096), and other referral
                codes.
              </Text>
            </View>

            <View style={styles.row}>
              <Text style={{ paddingLeft: '20px', fontSize: 10 }}>2. </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                The Owner/Permittee shall be responsible in undertaking the
                following, with the assistance of the concerned professionals
                hired/commissioned by him, should the necessary Building Permit
                be issued:
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '10px',
                }}
              >
                a.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '10px',
                }}
              >
                That prior to commencement of the proposed project/s and
                construction, an actual relocation survey shall be conducted by
                a duly licensed Geodetic Engineer.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                b.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                That before commencing the excavation the person making or
                causing the excavation to be made shall notify in writing the
                owner of adjoining property not less than ten (10) days before
                such excavation is to be made and show how the adjoining
                property should be protected.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                c.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                That no person shall use or occupy a street, alley or public
                sidewalk for the performance of work covered by a building
                permit.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                d.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                That no person shall perform any work on any building or
                structure adjacent to a public way in general use for pedestrian
                travel, unless the pedestrians are protected.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                e.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                That the supervising Architectural/Civil Engineer shall keep at
                the jobsite at all times a logbook of daily construction
                activities wherein the actual daily progress of construction
                including test conducted, weather condition and other pertinent
                data are to be recorded, same shall be made available for
                scrutiny and comments by the OBO representative during the
                conduct of his/her inspection pursuant to section 207 of the
                National Building Code.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                f.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                That upon completion of the construction, the said licensed
                supervising Architect/Civil Engineer shall submit to the
                Building Official duly signed and sealed logbook, as-built plans
                and other documents and shall also prepare and submit a
                Certificate of Completion of the project stating that the
                construction of the building/structure conform to the provision
                of the Code, its IRR as well as the plans and specifications.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                g.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                All such, modifications and alterations likewise be submitted to
                the Building Official and the subsequent amendatory permit
                therefor issued before any work on said changes, modification
                and alteration shall be started. The as-built plans and
                specifications may be just an orderly and comprehensive
                compilation of all documents which include the originally
                submitted plans and specifications of all amendments thereto as
                actually built or they may be entirely new set of plans and
                specifications accurately describing and/or reflecting therein
                the building as actually built.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                h.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                That no building/structure shall be used until the Building
                Official has issued a Certificate of Occupancy therefore as
                provided in the Code. However, a partial Certificate of
                Occupancy may be issued for the Use/Occupancy of a portion or
                portions of a building/structure prior to the completion of the
                entire building/structure.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                i.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                When the construction is undertaken by contract, the work shall
                be done by a duly licensed and registered contractor pursuant to
                the provisions of the Contractor's License Law (RA 4566).
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                j.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                The Owner/Permittee shall submit a duly accomplished "Notice of
                Construction" to the Office of the Building Official prior to
                any construction activity.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                k.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                The Owner/Permittee shall put a Building Permit sign which
                complies with the prescribed dimensions and information, which
                shall remain posted on the construction site for the duration of
                the construction.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  paddingLeft: '40px',
                  fontSize: 10,
                  paddingTop: '5px',
                }}
              >
                l.{' '}
              </Text>
              <Text
                style={{
                  paddingLeft: '10px',
                  fontSize: 10,
                  width: '100%',
                  paddingRight: '10px',
                  paddingTop: '5px',
                }}
              >
                The Owner/Permittee shall submit required clearances from other
                agencies as stipulated under Section 302 (12) b of the IRR of
                the NBCP of other documents or clearances from local authorities
                thirty (30) calendar days after the issuance of a building
                permit.
              </Text>
            </View>

            <View style={styles.row}>
              <Text
                style={{
                  fontSize: 10,
                  textAlign: 'left',
                  width: '100%',
                  paddingTop: '40px',
                  paddingBottom: '20px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  fontFamily: 'Times-Bold',
                }}
              >
                THIS PERMIT MAY BE CANCELLED OR REVOKED PURSUANT TO SECTIONS 305
                AND 306 OF THE NATIONAL BUILDING CODE OF THE PHILIPPINES (PD
                1096)
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default DcBldgPermit
