import React, { useState, useEffect } from 'react'
import {
  FaCheckCircle,
  FaInfoCircle,
  FaPlus,
  FaUpload,
  FaSearch,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Tabs,
  Tab,
  Form,
  Modal,
  Spinner,
  Carousel,
  Pagination,
  InputGroup,
} from 'react-bootstrap'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  retrieveEngrProfile,
  retrieveEngAttachments,
  updateEngProfessionInfo,
  updateUserBasicInfo,
  updateUserPerAddress,
  updateUserPreAddress,
  updateUserCredentials,
  retrieveRegion,
} from '../../redux/actions/userProfileAction'

import {
  retrieveEngAppList,
  acceptEngApplication,
  declineEngApplication,
  updateEngAttachedImage,
} from '../../redux/actions/userApplicationActions'

import { createProInfo, deleteProInfo } from '../../redux/actions/userActions'

// Data
import professionList from '../../../data/professionsList'

const EngineerScreen = () => {
  const dispatch = useDispatch()

  const [key, setKey] = useState('profile')
  const [modal, setModal] = useState({
    viewAction: false,
    viewEditProf: false,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [engrInfo, setEngrInfo] = useState([])

  const [state, setState] = useState({
    FirstName: '',
    MiddleName: '',
    LastName: '',
    DOB: '',
    CivilStatus: '',
    Gender: '',
    MobileNo: '',
    EmailAddress: '',
    NoStreet: '',
    Subd: '',
    BrgyDistrict: '',
    Province: '',
    City: '',
    Country: 'Philippines',
    ZipCode: '',
    Pr_NoStreet: '',
    Pr_Subd: '',
    Pr_BrgyDistrict: '',
    Pr_Province: '',
    Pr_City: '',
    Pr_Country: 'Philippines',
    Pr_ZipCode: '',
    PRA_id: '',
    Pr_id: '',
  })

  const [address, setAddress] = useState({
    presentCities: [],
    permanentCities: [],
    presentBarangays: [],
    permanentBarangays: [],
  })

  const [profDetails, setProfDetails] = useState({
    profName: '',
    prcNo: '',
    prcValidity: '',
    ptrNo: '',
    ptrValidity: '',
  })
  const [credentials, setCredentials] = useState({
    password: '',
    confirmPassword: '',
  })
  const [data, setData] = useState([])
  const [payload, setPayload] = useState({})
  const [uploadImages, setUploadImages] = useState(null)

  const [success, setSuccess] = useState(false)

  const [addProfession, setAddProfession] = useState(false)

  const [newProfession, setNewProfession] = useState({
    profName: '',
    prcNo: '',
    prcValidity: '',
    ptrNo: '',
    ptrValidity: '',
  })

  const [profList, setProfList] = useState([])

  const user = useSelector((state) => state.users)
  const { userCredentials, createdProInfo, deletedProInfo } = user

  const userProfile = useSelector((state) => state.userProfile)
  const {
    retrievedEngrInfo,
    retrievedEngAttachments,
    message,
    retrievedRegion,
  } = userProfile

  const applications = useSelector((state) => state.applications)
  const { retrievedEngAppList } = applications

  const handleProfChange = (e) => {
    setProfDetails({
      ...profDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmitProfInfo = (e) => {
    e.preventDefault()
    dispatch(
      updateEngProfessionInfo({
        profName: profDetails.profName,
        prcNo: profDetails.prcNo,
        prcValidity: profDetails.prcValidity,
        ptrNo: profDetails.ptrNo,
        ptrValidity: profDetails.ptrValidity,
        id: profDetails.id,
        userID: userCredentials.id,
      })
    )
    setModal({ viewEditProf: false })
    setSuccess(true)
  }

  const handleSubmitBasicInfo = (e) => {
    if (window.confirm('Are you sure you want to submit the information?')) {
      e.preventDefault()
      dispatch(
        updateUserBasicInfo({
          FirstName: engrInfo[0].FirstName,
          MiddleName: engrInfo[0].MiddleName,
          LastName: engrInfo[0].LastName,
          DOB: engrInfo[0].DOB,
          CivilStatus: engrInfo[0].CivilStatus,
          Gender: engrInfo[0].Gender,
          MobileNo: engrInfo[0].MobileNo,
          EmailAddress: engrInfo[0].EmailAddress,
          B_id: engrInfo[0].B_id,
          // userID: userCredentials.id,
        })
      )
      setSuccess(true)
    }
    e.preventDefault()
  }

  const handleSubmitPresentAdd = (e) => {
    if (window.confirm('Are you sure you want to submit the information?')) {
      e.preventDefault()
      dispatch(
        updateUserPreAddress({
          Present_NoStreet: state.Pr_NoStreet,
          Present_Subd: state.Pr_Subd,
          Present_BrgyDistrict: state.Pr_BrgyDistrict,
          Present_Province: state.Pr_Province,
          Present_City: state.Pr_City,
          Present_Country: state.Pr_Country,
          Present_ZipCode: state.Pr_ZipCode,
          PRA_id: state.PRA_id,
          userID: userCredentials.id,
        })
      )
      setSuccess(true)
    }
    e.preventDefault()
  }

  const handleSubmitPermanentAdd = (e) => {
    if (window.confirm('Are you sure you want to submit the information?')) {
      e.preventDefault()
      dispatch(
        updateUserPerAddress({
          Permanent_NoStreet: state.NoStreet,
          Permanent_Subd: state.Subd,
          Permanent_BrgyDistrict: state.BrgyDistrict,
          Permanent_Province: state.Province,
          Permanent_City: state.City,
          Permanent_Country: state.Country,
          Permanent_ZipCode: state.ZipCode,
          Pr_id: state.Pr_id,
          userID: userCredentials.id,
        })
      )
      setSuccess(true)
    }
    e.preventDefault()
  }

  const handleSubmitCredentials = (e) => {
    if (window.confirm('Are you sure you want to submit the information?')) {
      e.preventDefault()
      if (credentials.password === credentials.confirmPassword) {
        dispatch(
          updateUserCredentials({
            username: engrInfo[0].username,
            password: credentials.password,
            id: userCredentials.id,
          })
        )
        setCredentials({
          password: '',
          confirmPassword: '',
        })
      } else {
        alert('password mismatched')
      }
      setSuccess(true)
    }
    e.preventDefault()
  }

  const handleChange = (e) => {
    const tempValue = engrInfo.map((value) => {
      return {
        ...value,
        [e.target.name]: e.target.value,
      }
    })

    setEngrInfo(tempValue)
  }

  const stateHandleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleAcceptApplication = (e) => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to accept this application?')) {
      dispatch(
        acceptEngApplication({
          id: payload.recordID,
          appID: payload.id,
          name:
            retrievedEngrInfo.message[0].LastName +
            ' ' +
            retrievedEngrInfo.message[0].FirstName +
            ' ' +
            retrievedEngrInfo.message[0].MiddleName,
          userID: userCredentials.id,
        })
      )
      setModal({ viewAction: false })
    }
  }

  const handleDeclineApplication = (e) => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to decline this application?')) {
      dispatch(
        declineEngApplication({
          id: payload.recordID,
          appID: payload.id,
          name:
            retrievedEngrInfo.message[0].LastName +
            ' ' +
            retrievedEngrInfo.message[0].FirstName +
            ' ' +
            retrievedEngrInfo.message[0].MiddleName,
          userID: userCredentials.id,
        })
      )
      setModal({ viewAction: false })
    }
  }

  const handleUploadImage = async (e) => {
    let files = e.target.files
    if (e.target.files.length > 3) {
      alert('You are only allowed to upload a maximum of 2 images!')
    }
    setUploadImages(files)
  }

  const handleImageSubmit = (e) => {
    if (window.confirm('Are you sure you want to change images attached?')) {
      e.preventDefault()
      dispatch(
        updateEngAttachedImage({
          images: uploadImages,
          userID: userCredentials.id,
          name:
            retrievedEngrInfo.message[0].LastName +
            ' ' +
            retrievedEngrInfo.message[0].FirstName +
            ' ' +
            retrievedEngrInfo.message[0].MiddleName,
        })
      )
    }
    e.preventDefault()
  }

  const handleChangeNew = (e) => {
    setNewProfession({
      ...newProfession,
      [e.target.name]: e.target.value,
    })
  }

  const handleOnSubmitProf = (e) => {
    if (window.confirm('Are you sure you want to submit this information? ')) {
      dispatch(
        createProInfo({
          profName: newProfession.profName,
          prcNo: newProfession.prcNo,
          prcValidity: newProfession.prcValidity,
          ptrNo: newProfession.ptrNo,
          ptrValidity: newProfession.ptrValidity,
          userID: userCredentials.id,
        })
      )
      setNewProfession({
        profName: '',
        prcNo: '',
        prcValidity: '',
        ptrNo: '',
        ptrValidity: '',
      })
    }
    e.preventDefault()
  }

  // Pagination

  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)
  // Table Size
  const pageSize = 10

  // Every render of the page do the statement inside the useEffect
  useEffect(() => {
    // Set the Display size of the Table
    setpaginatedForms(_(data).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [data])

  // Count Number the length of the Array and Divide it to the page size
  const pageCount = data
    ? // Inline if-else statement
      // Math.ceil convert the parameter into the nearest integer value
      Math.ceil(data.length / pageSize)
    : 0

  // if (pageCount === 1) return null
  // Store the page count ito pages array
  // (1, pageCount) means create an area starting from 1 up to the number result of pageCount plus 1
  const pages = _.range(1, pageCount + 1)

  // fat arrow function for onClick in Pagination
  // pagination function will recieve an argument of pageNo so that if you are going to use this function you must submit a pageNo parameter ex. pagination(page)
  const pagination = (pageNo) => {
    // set the current page value to the argument receive by the function
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    // console.log(pageNo)
    // console.log(startIndex)
    const paginationForms = _(data).slice(startIndex).take(pageSize).value()
    setpaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    // setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    // setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Pagination

  useEffect(() => {
    dispatch(retrieveEngrProfile({ userID: userCredentials.id }))
    dispatch(retrieveEngAttachments({ userID: userCredentials.id }))
    dispatch(retrieveRegion())
  }, [dispatch])

  useEffect(() => {
    if (retrievedEngrInfo && retrievedEngrInfo.message) {
      setEngrInfo(retrievedEngrInfo.message)

      setState({
        ...state,
        FirstName: retrievedEngrInfo.message[0].FirstName,
        MiddleName: retrievedEngrInfo.message[0].MiddleName,
        LastName: retrievedEngrInfo.message[0].LastName,
        DOB: retrievedEngrInfo.message[0].DOB,
        CivilStatus: retrievedEngrInfo.message[0].CivilStatus,
        Gender: retrievedEngrInfo.message[0].Gender,
        MobileNo: retrievedEngrInfo.message[0].MobileNo,
        EmailAddress: retrievedEngrInfo.message[0].EmailAddress,
        NoStreet: retrievedEngrInfo.message[0].NoStreet,
        Subd: retrievedEngrInfo.message[0].Subd,
        BrgyDistrict: retrievedEngrInfo.message[0].BrgyDistrict,
        Province: retrievedEngrInfo.message[0].Province,
        City: retrievedEngrInfo.message[0].City,
        ZipCode: retrievedEngrInfo.message[0].ZipCode,
        Pr_NoStreet: retrievedEngrInfo.message[0].Pr_NoStreet,
        Pr_Subd: retrievedEngrInfo.message[0].Pr_Subd,
        Pr_BrgyDistrict: retrievedEngrInfo.message[0].Pr_BrgyDistrict,
        Pr_Province: retrievedEngrInfo.message[0].Pr_Province,
        Pr_City: retrievedEngrInfo.message[0].Pr_City,
        Pr_ZipCode: retrievedEngrInfo.message[0].Pr_ZipCode,
        PRA_id: retrievedEngrInfo.message[0].Pr_id,
        Pr_id: retrievedEngrInfo.message[0].Pe_id,
      })

      dispatch(
        retrieveEngAppList({
          name:
            retrievedEngrInfo.message[0].LastName +
            ' ' +
            retrievedEngrInfo.message[0].FirstName +
            ' ' +
            retrievedEngrInfo.message[0].MiddleName,
          taggedID: userCredentials.id,
        })
      )
      let newArray = retrievedEngrInfo.message.map((e) => e.ProfessionName)

      setProfList(professionList.filter((el) => !newArray.includes(el)))
    }
  }, [retrievedEngrInfo])

  useEffect(() => {
    if (retrievedEngAppList && retrievedEngAppList.message) {
      // setData(() => retrievedEngAppList.message)
      setData(() =>
        retrievedEngAppList.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : typeof value === 'number' &&
                  String(value).includes(searchTerm)
            )
          }
        })
      )
    }
  }, [retrievedEngAppList, searchTerm])

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg px-4 pb-2'
          style={{ background: '#FFF' }}
        >
          <h5 className='pt-4 pb-2 mb-0'>Engineer Information</h5>
          <hr
            className='mt-1 mb-3'
            style={{
              background: '#ffeb36',
              paddingBottom: '2px',
              width: '200px',
            }}
          />
          <Row>
            <Col md={4} lg={5} xl={4} className='bg-light shadow-lg py-3'>
              <h6 className='fw-bold'>
                Copy of Professional Tax Receipt (PTR) and PRC ID
              </h6>
              <hr />

              <Carousel nextLabel='' prevLabel=''>
                {retrievedEngAttachments &&
                  retrievedEngAttachments.message &&
                  retrievedEngAttachments.message.map((e, index) => (
                    <Carousel.Item key={index} interval={10000}>
                      <img
                        className='my-2 mx-1'
                        src={`data:image/png;base64,${e.Image}`}
                        height='350'
                      />
                    </Carousel.Item>
                  ))}
              </Carousel>

              {retrievedEngAttachments && retrievedEngAttachments.loading && (
                <div className='text-center pt-3'>
                  <Spinner animation='border' />
                  <h6 className='pt-2'>Loading...</h6>
                </div>
              )}

              {retrievedEngAttachments && retrievedEngAttachments.message && (
                <Form onSubmit={handleImageSubmit}>
                  <Row className='justify-content-between'>
                    <Col xl={6}>
                      <Form.File
                        size='sm'
                        multiple
                        required
                        onChange={handleUploadImage}
                        className='mt-2'
                      />
                    </Col>
                    <Col xl={6} className='text-end'>
                      <Button type='submit' variant='info' size='sm'>
                        <FaUpload />
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
            <Col md={8} lg={6} xl={7} className='bg-light shadow-lg py-3 mx-3'>
              <h6 className='fw-bold'>DETAILS</h6>
              <hr />

              <div className=' px-2 py-3 '>
                <Tabs
                  className='mb-3'
                  defaultActiveKey={key}
                  onSelect={(k) => setKey(k)}
                >
                  <Tab eventKey='profile' title='BASIC INFO'>
                    <Container>
                      <Form onSubmit={handleSubmitBasicInfo}>
                        <Form.Label className='m-0 p-0'>
                          Basic Information
                        </Form.Label>
                        <hr className='m-0 mb-3' />
                        <Row>
                          <Form.Group as={Col} xl='4'>
                            <Form.Label className='m-0'>Last Name</Form.Label>
                            <Form.Control
                              type='text'
                              name='LastName'
                              size='sm'
                              value={
                                Object.keys(engrInfo).length > 0 &&
                                engrInfo[0].LastName
                              }
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group as={Col} xl='4'>
                            <Form.Label className='m-0'>First Name</Form.Label>
                            <Form.Control
                              type='text'
                              name='FirstName'
                              size='sm'
                              value={
                                Object.keys(engrInfo).length > 0 &&
                                engrInfo[0].FirstName
                              }
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group as={Col} xl='4'>
                            <Form.Label className='m-0'>Middle Name</Form.Label>
                            <Form.Control
                              type='text'
                              name='MiddleName'
                              size='sm'
                              value={
                                Object.keys(engrInfo).length > 0 &&
                                engrInfo[0].MiddleName
                              }
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col} xl='4'>
                            <Form.Label>BirthDate</Form.Label>
                            <Form.Control
                              type='date'
                              onKeyDown={(e) => e.preventDefault()}
                              name='DOB'
                              value={
                                Object.keys(engrInfo).length > 0 &&
                                engrInfo[0].DOB
                              }
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group as={Col} xl='4'>
                            <Form.Label>Mobile No.</Form.Label>
                            <Form.Control
                              type='text'
                              name='MobileNo'
                              maxLength={10}
                              size='sm'
                              value={
                                Object.keys(engrInfo).length > 0 &&
                                engrInfo[0].MobileNo
                              }
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group as={Col} xl='4'>
                            <Form.Label>Gender</Form.Label>
                            <Form.Control
                              as='select'
                              name='Gender'
                              value={
                                Object.keys(engrInfo).length > 0 &&
                                engrInfo[0].Gender
                              }
                              onChange={handleChange}
                            >
                              <option value=''>Choose...</option>
                              <option value='Male'>Male</option>
                              <option value='Female'>Female</option>
                            </Form.Control>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col} xl='4'>
                            <Form.Label>Civil Status</Form.Label>
                            <Form.Control
                              as='select'
                              name='CivilStatus'
                              size='sm'
                              value={
                                Object.keys(engrInfo).length > 0 &&
                                engrInfo[0].CivilStatus
                              }
                              onChange={handleChange}
                            >
                              <option value=''>Choose...</option>
                              <option>Single</option>
                              <option>Married</option>
                              <option>Divorce</option>
                              <option>Widowed</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group as={Col} xl='4'>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type='text'
                              name='EmailAddress'
                              size='sm'
                              value={
                                Object.keys(engrInfo).length > 0 &&
                                engrInfo[0].EmailAddress
                              }
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Row>

                        <div className='text-end'>
                          <Button
                            type='submit'
                            className='mt-2'
                            variant='info'
                            size='sm'
                          >
                            Update
                          </Button>
                        </div>
                      </Form>
                    </Container>
                  </Tab>

                  <Tab eventKey='address' title='ADDRESS'>
                    <Form onSubmit={handleSubmitPresentAdd}>
                      <Form.Label className='m-0 p-0'>
                        Present Address
                      </Form.Label>
                      <hr className='m-0 mb-3' />

                      <Row>
                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>Region</Form.Label>
                          <Form.Control
                            as='select'
                            name='Pr_Province'
                            size='sm'
                            required
                            value={state.Pr_Province}
                            onChange={(e) => {
                              setState({
                                ...state,
                                Pr_Province: e.target.value,
                                Pr_City: '',
                                Pr_BrgyDistrict: '',
                              })

                              const region =
                                retrievedRegion &&
                                retrievedRegion.message &&
                                retrievedRegion.message.find(
                                  (region) =>
                                    region.regionName === e.target.value
                                )

                              if (region !== undefined) {
                                axios
                                  .get(`/api/regions/${region.code}/cities/`)
                                  .then((res) => {
                                    setAddress({
                                      ...address,
                                      presentCities: res.data,
                                    })
                                  })
                              }
                            }}
                          >
                            {state.Pr_Province !== null ||
                            state.Pr_Province !== '' ? (
                              <option value={state.Pr_Province}>
                                {state.Pr_Province}
                              </option>
                            ) : (
                              <option value=''>Choose here ...</option>
                            )}

                            {retrievedRegion &&
                              retrievedRegion.message &&
                              retrievedRegion.message.map((e, index) => (
                                <option key={index} value={e.regionName}>
                                  {e.regionName}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>
                            City/Municipality
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='Pr_City'
                            size='sm'
                            required
                            value={state.Pr_City}
                            onChange={(e) => {
                              setState({
                                ...state,
                                Pr_City: e.target.value,
                                Pr_BrgyDistrict: '',
                              })

                              const city =
                                address.presentCities.length !== 0 &&
                                address.presentCities.find(
                                  (cities) => cities.name === e.target.value
                                )

                              if (city !== undefined) {
                                axios
                                  .get(`/api/cities/${city.code}/barangays/`)
                                  .then((res) => {
                                    setAddress({
                                      ...address,
                                      presentBarangays: res.data,
                                    })
                                  })
                              }
                            }}
                          >
                            {state.Pr_City !== null || state.Pr_City !== '' ? (
                              <option value={state.Pr_City}>
                                {state.Pr_City}
                              </option>
                            ) : (
                              <option value=''>Choose here ...</option>
                            )}
                            {address.presentCities.length !== 0 &&
                              address.presentCities.map((e, index) => (
                                <option key={index} value={e.name}>
                                  {e.name}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>
                            Brgy./District
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='Pr_BrgyDistrict'
                            size='sm'
                            required
                            value={state.Pr_BrgyDistrict}
                            onChange={stateHandleChange}
                          >
                            {state.Pr_BrgyDistrict !== null ||
                            state.Pr_BrgyDistrict !== '' ? (
                              <option value={state.Pr_BrgyDistrict}>
                                {state.Pr_BrgyDistrict}
                              </option>
                            ) : (
                              <option value=''>Choose here ...</option>
                            )}
                            {address.presentBarangays.length !== 0 &&
                              address.presentBarangays.map((element, index) => (
                                <option key={index} value={element.name}>
                                  {element.name}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>Zip Code</Form.Label>
                          <Form.Control
                            type='text'
                            name='Pr_ZipCode'
                            size='sm'
                            required
                            value={state.Pr_ZipCode}
                            onChange={stateHandleChange}
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>No. / Street</Form.Label>
                          <Form.Control
                            type='text'
                            name='Pr_NoStreet'
                            size='sm'
                            required
                            value={state.Pr_NoStreet}
                            onChange={stateHandleChange}
                          />
                        </Form.Group>
                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>Subd.</Form.Label>
                          <Form.Control
                            type='text'
                            name='Pr_Subd'
                            size='sm'
                            required
                            value={state.Pr_Subd}
                            onChange={stateHandleChange}
                          />
                        </Form.Group>
                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>Country</Form.Label>
                          <Form.Control
                            type='text'
                            name='Pr_Country'
                            size='sm'
                            required
                            value={state.Pr_Country}
                            onChange={stateHandleChange}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col xl={12} className='d-flex justify-content-end'>
                          <Button
                            size='sm'
                            className='mt-4'
                            variant='info'
                            type='submit'
                          >
                            EDIT
                          </Button>
                        </Col>
                      </Row>
                    </Form>

                    <Form onSubmit={handleSubmitPermanentAdd}>
                      <Form.Label className='m-0 p-0'>
                        Permanent Address
                      </Form.Label>
                      <hr className='m-0 mb-3' />

                      <Row>
                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>Region</Form.Label>
                          <Form.Control
                            as='select'
                            name='Province'
                            size='sm'
                            required
                            value={state.Province}
                            onChange={(e) => {
                              setState({
                                ...state,
                                Province: e.target.value,
                                City: '',
                                BrgyDistrict: '',
                              })

                              const region =
                                retrievedRegion &&
                                retrievedRegion.message &&
                                retrievedRegion.message.find(
                                  (region) =>
                                    region.regionName === e.target.value
                                )

                              if (region !== undefined) {
                                axios
                                  .get(`/api/regions/${region.code}/cities/`)
                                  .then((res) => {
                                    setAddress({
                                      ...address,
                                      permanentCities: res.data,
                                    })
                                  })
                              }
                            }}
                          >
                            {state.Province !== null ||
                            state.Province !== '' ? (
                              <option value={state.Province}>
                                {state.Province}
                              </option>
                            ) : (
                              <option value=''>Choose here ...</option>
                            )}

                            {retrievedRegion &&
                              retrievedRegion.message &&
                              retrievedRegion.message.map((e, index) => (
                                <option key={index} value={e.regionName}>
                                  {e.regionName}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>
                            City/Municipality
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='City'
                            size='sm'
                            required
                            value={state.City}
                            onChange={(e) => {
                              setState({
                                ...state,
                                City: e.target.value,
                                BrgyDistrict: '',
                              })

                              const city =
                                address.permanentCities.length !== 0 &&
                                address.permanentCities.find(
                                  (cities) => cities.name === e.target.value
                                )

                              if (city !== undefined) {
                                axios
                                  .get(`/api/cities/${city.code}/barangays/`)
                                  .then((res) => {
                                    setAddress({
                                      ...address,
                                      permanentBarangays: res.data,
                                    })
                                  })
                              }
                            }}
                          >
                            {state.City !== null || state.City !== '' ? (
                              <option value={state.City}>{state.City}</option>
                            ) : (
                              <option value=''>Choose here ...</option>
                            )}

                            {address.permanentCities.length !== 0 &&
                              address.permanentCities.map((e, index) => (
                                <option key={index} value={e.name}>
                                  {e.name}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>
                            Brgy./District
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='BrgyDistrict'
                            size='sm'
                            required
                            value={state.BrgyDistrict}
                            onChange={stateHandleChange}
                          >
                            {state.BrgyDistrict !== null ||
                            state.BrgyDistrict !== '' ? (
                              <option value={state.BrgyDistrict}>
                                {state.BrgyDistrict}
                              </option>
                            ) : (
                              <option value=''>Choose here ...</option>
                            )}
                            {address.permanentBarangays.length !== 0 &&
                              address.permanentBarangays.map(
                                (element, index) => (
                                  <option key={index} value={element.name}>
                                    {element.name}
                                  </option>
                                )
                              )}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>Zip Code</Form.Label>
                          <Form.Control
                            type='text'
                            name='ZipCode'
                            size='sm'
                            required
                            value={state.ZipCode}
                            onChange={stateHandleChange}
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>No. / Street</Form.Label>
                          <Form.Control
                            type='text'
                            name='NoStreet'
                            size='sm'
                            required
                            value={state.NoStreet}
                            onChange={stateHandleChange}
                          />
                        </Form.Group>

                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>Subd.</Form.Label>
                          <Form.Control
                            type='text'
                            name='Subd'
                            size='sm'
                            required
                            value={state.Subd}
                            onChange={stateHandleChange}
                          />
                        </Form.Group>

                        <Form.Group as={Col} xl='3'>
                          <Form.Label className='m-0'>Country</Form.Label>
                          <Form.Control
                            type='text'
                            name='Country'
                            size='sm'
                            required
                            value={state.Country}
                            onChange={stateHandleChange}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col xl={12} className='d-flex justify-content-end'>
                          <Button
                            className='mt-4'
                            variant='info'
                            size='sm'
                            type='submit'
                          >
                            EDIT
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>

                  <Tab
                    eventKey='professional-info'
                    title='PROFESSIONAL INFORMATION'
                  >
                    <Form.Label className='m-0 p-0'>
                      Professional Details
                    </Form.Label>
                    <hr className='m-0 mb-3' />

                    {Object.keys(engrInfo).length > 0 &&
                      engrInfo.map((e, index) => (
                        <Form key={index}>
                          <Row className='my-2'>
                            <Form.Group as={Col} xl={3}>
                              <Form.Label className='m-0'>
                                Profession
                              </Form.Label>
                              <Form.Control
                                type='text'
                                size='sm'
                                required
                                name='ProfessionName'
                                value={e.ProfessionName}
                                disabled
                                readOnly
                              />
                            </Form.Group>
                            <Form.Group as={Col} xl={3}>
                              <Form.Label className='m-0'>
                                PRC REG No.
                              </Form.Label>

                              <Form.Control
                                type='text'
                                size='sm'
                                required
                                name='prcNo'
                                value={e.prcNo}
                                onChange={(e) =>
                                  setProfDetails({
                                    ...profDetails,
                                    prcNo: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group as={Col} xl={3}>
                              <Form.Label className='m-0'>Validity</Form.Label>

                              <Form.Control
                                type='text'
                                size='sm'
                                required
                                name='prcValidity'
                                value={moment(e.prcValidity).format(
                                  'MMMM D, YYYY'
                                )}
                                onChange={(e) =>
                                  setProfDetails({
                                    ...profDetails,
                                    prcValidity: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group as={Col} xl={3}>
                              <Form.Label className='m-0'>PTR No.</Form.Label>

                              <Form.Control
                                type='text'
                                size='sm'
                                required
                                name='ptrNo'
                                value={e.ptrNo}
                                onChange={(e) =>
                                  setProfDetails({
                                    ...profDetails,
                                    ptrNo: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group as={Col} xl={3}>
                              <Form.Label className='m-0'>Validity</Form.Label>

                              <Form.Control
                                type='text'
                                size='sm'
                                required
                                name='ptrValidity'
                                value={moment(e.ptrValidity).format(
                                  'MMMM D, YYYY'
                                )}
                                onChange={(e) =>
                                  setProfDetails({
                                    ...profDetails,
                                    ptrValidity: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>

                            <Col xl={9} className='mt-2'>
                              <Button
                                className='mt-3'
                                variant='info'
                                size='sm'
                                onClick={() => {
                                  setProfDetails({
                                    id: e.PI_id,
                                    profName: e.ProfessionName,
                                    profNo: e.ProfessionNo,
                                    validity: e.validity,
                                  })
                                  setModal({
                                    viewEditProf: true,
                                  })
                                }}
                              >
                                EDIT
                              </Button>
                              {index !== 0 && (
                                <Button
                                  className='mt-3 mx-1'
                                  variant='danger'
                                  size='sm'
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        'Are you sure you want to delete this? '
                                      )
                                    ) {
                                      dispatch(deleteProInfo({ id: e.PI_id }))
                                    }
                                  }}
                                >
                                  DELETE
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Form>
                      ))}

                    {!addProfession && (
                      <Button
                        variant='danger'
                        size='sm'
                        className='my-2'
                        onClick={() => setAddProfession(true)}
                      >
                        <FaPlus />
                      </Button>
                    )}

                    {addProfession && (
                      <Form className='my-2' onSubmit={handleOnSubmitProf}>
                        <hr />
                        <Row>
                          <h6 className='fw-bold'>Add New Profession</h6>
                          <Form.Group as={Col} xl={3}>
                            <Form.Label className='m-0'>
                              Profession:{' '}
                            </Form.Label>
                            <Form.Control
                              as='select'
                              size='sm'
                              name='profName'
                              value={newProfession.profName}
                              onChange={handleChangeNew}
                              required
                            >
                              <option value=''>Choose here ...</option>
                              {profList.length > 0 &&
                                profList.map((prof, index) => (
                                  <option key={index} value={prof}>
                                    {prof}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group as={Col} xl={3}>
                            <Form.Label className='m-0'>
                              PRC reg no.:{' '}
                            </Form.Label>
                            <Form.Control
                              type='text'
                              size='sm'
                              name='prcNo'
                              value={newProfession.prcNo}
                              onChange={handleChangeNew}
                              required
                            />
                          </Form.Group>
                          <Form.Group as={Col} xl={3}>
                            <Form.Label className='m-0'>Validity: </Form.Label>
                            <Form.Control
                              type='date'
                              size='sm'
                              name='prcValidity'
                              value={newProfession.prcValidity}
                              onChange={handleChangeNew}
                              required
                            />
                          </Form.Group>
                          <Form.Group as={Col} xl={3}>
                            <Form.Label className='m-0'>PTR no.: </Form.Label>
                            <Form.Control
                              type='text'
                              size='sm'
                              name='ptrNo'
                              value={newProfession.ptrNo}
                              onChange={handleChangeNew}
                              required
                            />
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group as={Col} xl={3}>
                            <Form.Label className='m-0'>Validity</Form.Label>
                            <Form.Control
                              type='date'
                              size='sm'
                              name='ptrValidity'
                              value={newProfession.ptrValidity}
                              onChange={handleChangeNew}
                              required
                            />
                          </Form.Group>
                          <Form.Group as={Col} xl={9}>
                            <Button
                              size='sm'
                              variant='success'
                              className='mt-4 mx-1'
                              type='submit'
                            >
                              Submit
                            </Button>
                            <Button
                              size='sm'
                              variant='danger'
                              className='mt-4 mx-1'
                              onClick={() => {
                                setAddProfession(false)
                                setNewProfession({
                                  profName: '',
                                  prcNo: '',
                                  prcValidity: '',
                                  ptrNo: '',
                                  ptrValidity: '',
                                })
                              }}
                            >
                              Cancel
                            </Button>
                          </Form.Group>
                        </Row>
                      </Form>
                    )}
                  </Tab>

                  <Tab eventKey='user-credentials' title='USER CREDENTIALS'>
                    <Form onSubmit={handleSubmitCredentials}>
                      <Form.Label className='m-0 p-0'>
                        User Credentials
                      </Form.Label>
                      <hr className='m-0 mb-3' />
                      <Row>
                        <Form.Group as={Col} xl='6'>
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            type='text'
                            required
                            name='username'
                            value={
                              Object.keys(engrInfo).length > 0 &&
                              engrInfo[0].username !== null
                                ? engrInfo[0].username
                                : ''
                            }
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group as={Col} xl='6'>
                          <Form.Label className='d-flex justify-content-between'>
                            Password
                            <i className='fs-6'>
                              "NOTE: Maximum of (8) Characters Only."
                            </i>
                          </Form.Label>
                          <Form.Control
                            type='password'
                            name='password'
                            required
                            maxLength={8}
                            onChange={(e) =>
                              setCredentials({
                                ...credentials,
                                password: e.target.value,
                              })
                            }
                            value={credentials.password}
                          />
                        </Form.Group>
                        <Form.Group as={Col} xl='6' className='pt-2'>
                          <Form.Label className='d-flex justify-content-between'>
                            Confirm Password
                            <i className='fs-6'>
                              "NOTE: Maximum of (8) Characters Only."
                            </i>
                          </Form.Label>
                          <Form.Control
                            type='password'
                            name='confirmPassword'
                            required
                            maxLength={8}
                            onChange={(e) =>
                              setCredentials({
                                ...credentials,
                                confirmPassword: e.target.value,
                              })
                            }
                            value={credentials.confirmPassword}
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <Col xl={12} className='d-flex justify-content-end'>
                          <Button type='submit' className='mt-4' variant='info'>
                            Update
                          </Button>
                          <Button className='mt-4 mx-1' variant='danger'>
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
          <hr />

          <Form>
            <Row className='justify-content-end px-3 pt-5 pb-3'>
              <Col md={8}>
                <h5 className='mt-2 mb-0'>Application</h5>
                <hr
                  className='mt-1'
                  style={{
                    background: '#ffeb36',
                    paddingBottom: '2px',
                    width: '200px',
                  }}
                />
              </Col>
              <Col md={4}>
                <InputGroup>
                  <Form.Control
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    value={searchTerm}
                    placeholder='Input search keyword here . . .'
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm === '' ? (
                    <InputGroup.Text className='bg-info text-light'>
                      <FaSearch />
                    </InputGroup.Text>
                  ) : (
                    <Button variant='danger' onClick={() => setSearchTerm('')}>
                      x
                    </Button>
                  )}
                </InputGroup>
              </Col>
            </Row>
          </Form>

          {retrievedEngAppList && retrievedEngAppList.message && (
            <Table responsive size='sm' bordered hover>
              <thead className='bg-info text-light'>
                <tr>
                  <td>Application No.</td>
                  <td>Applicant Name</td>
                  <td>Application</td>
                  <td>Date Created</td>
                  <td>Engineer Type</td>
                  <td>Trade</td>
                  <td>Accepted</td>
                  <td className='text-center'>Action</td>
                </tr>
              </thead>
              <tbody>
                {paginatedForms.map((e, index) => (
                  <tr key={index}>
                    <td>{e.id}</td>
                    <td>{e.ApplicantName}</td>
                    <td>{e.ApplicationName}</td>
                    <td>{moment(e.TimeStamp).format('MMMM D, YYYY')}</td>
                    <td>{e.Type === null ? '' : e.Type}</td>
                    <td>{e.trade === null ? '' : e.trade}</td>
                    <td>
                      {e.accepted === 1
                        ? 'YES'
                        : e.accepted === null
                        ? ''
                        : 'NO'}
                    </td>
                    <td className='text-center'>
                      <Button
                        variant='success'
                        size='sm'
                        onClick={() => {
                          setModal({ viewAction: true })
                          setPayload(e)
                        }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {retrievedEngAppList && retrievedEngAppList.message && (
            <>
              <hr />
              <Pagination
                size='sm'
                className='d-flex justify-content-end mx-2 mt-3'
              >
                <Pagination.Item
                  className=' mt-0 fs-6 text-nowrap'
                  size='sm'
                  variant='info'
                  onClick={handlePrevbtn}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  &lt;
                </Pagination.Item>

                {renderPageNumber}

                <Pagination.Item
                  className=' mt-0 fs-6 text-nowrap'
                  size='sm'
                  variant='info'
                  onClick={handleNextbtn}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                >
                  &gt;
                </Pagination.Item>
              </Pagination>
            </>
          )}

          {retrievedEngAppList && retrievedEngAppList.loading && (
            <div className='text-center pt-3'>
              <Spinner animation='border' />
              <h6 className='pt-2'>Loading...</h6>
            </div>
          )}
        </Container>
      </Container>

      {/* Action Modal */}
      <Modal
        size='md'
        show={modal.viewAction}
        onHide={() => setModal({ ...modal, viewAction: false })}
        centered
        backdrop='static'
      >
        <Form onSubmit={handleAcceptApplication}>
          <Modal.Header style={{ background: '#4bbf73' }}>
            <Modal.Title className='fs-6' style={{ color: '#FFF' }}>
              <FaCheckCircle className='mx-3' />
              Application
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <tbody>
                <tr>
                  <td>APPLICANT NAME: </td>
                  <td>{payload.ApplicantName}</td>
                </tr>
                <tr>
                  <td>APPLICANTION: </td>
                  <td>{payload.ApplicationName}</td>
                </tr>
                <tr>
                  <td>DATE: </td>
                  <td>{moment(payload.TimeStamp).format('MMMM D, YYYY')}</td>
                </tr>
                <tr>
                  <td>STATUS: </td>
                  <td>{payload.status}</td>
                </tr>
                <tr>
                  <td>TRADES: </td>
                  <td>
                    {payload.Architectural === 1 && 'ARCHITECTURAL, '}
                    {payload.Structural === 1 && 'STRUCTURAL, '}
                    {payload.Mechanical === 1 && 'MECHANICAL, '}
                    {payload.Electronics === 1 && 'ELECTRONICS, '}
                    {payload.Electrical === 1 && 'ELECTRICAL, '}
                    {payload.Plumbing === 1 && 'PLUMBING'}
                  </td>
                </tr>
                <tr>
                  <td>LINK: </td>
                  <td>{payload.Link}</td>
                </tr>
                <tr>
                  <td>Date Accepted / Declined: </td>
                  <td>
                    {payload.dateAccepted === null
                      ? ''
                      : moment(payload.dateAccepted).format('MMMM D, YYYY')}
                  </td>
                </tr>
              </tbody>
            </Table>
            {payload.accepted === 0 && (
              <Form.Check
                required
                label='I hereby authorize the applicant to use my digital sign and seal for his/her application thru this online platform.
'
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            {payload.accepted !== 1 && (
              <Button variant='success' size='sm' type='submit'>
                Accept
              </Button>
            )}
            <Button
              variant='danger'
              size='sm'
              onClick={handleDeclineApplication}
            >
              Decline
            </Button>

            <Button
              variant='outline-danger'
              size='sm'
              onClick={() => setModal({ ...modal, viewAction: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* End Action Modal */}

      {/* Professional Info Edit */}
      <Modal
        show={modal.viewEditProf}
        onHide={() => setModal({ ...modal, viewEditProf: false })}
        centered
        backdrop='static'
      >
        <Modal.Header>
          <Modal.Title>
            <FaInfoCircle className='text-info' /> Edit Professional Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitProfInfo}>
            {/* Professional Name */}
            <Row className='py-2 align-items-center text-nowrap'>
              <Col md={4} className='fw-bold'>
                Professional Name
              </Col>
              <Form.Group as={Col} md={8}>
                <Form.Control
                  type='text'
                  name='profName'
                  required
                  value={profDetails.profName}
                  onChange={handleProfChange}
                />
              </Form.Group>
            </Row>

            {/* Professional No*/}
            <Row className='py-2 align-items-center text-nowrap'>
              <Col md={4} className='fw-bold'>
                Professional No.
              </Col>
              <Form.Group as={Col} md={8}>
                <Form.Control
                  type='text'
                  name='profNo'
                  required
                  value={profDetails.profNo}
                  onChange={handleProfChange}
                />
              </Form.Group>
            </Row>

            {/* Validity */}
            <Row className='py-2 align-items-center text-nowrap'>
              <Col md={4} className='fw-bold'>
                Validity
              </Col>
              <Form.Group as={Col} md={8}>
                <Form.Control
                  type='date'
                  name='validity'
                  required
                  value={profDetails.validity}
                  onChange={handleProfChange}
                />
              </Form.Group>
            </Row>
            {/* Buttons */}
            <Row>
              <Col xl={12} className='d-flex justify-content-end my-2 px-3'>
                <Button
                  className='mx-2'
                  variant='outline-danger'
                  onClick={() => setModal({ ...modal, viewEditProf: false })}
                >
                  Cancel
                </Button>
                <Button variant='outline-success' type='submit'>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* End Professional Info Edit */}

      {/*Submit Inspector Success Modal */}
      <SuccessModal
        show={success}
        onHide={() => {
          setSuccess(false)
          dispatch(retrieveEngrProfile({ userID: userCredentials.id }))
          dispatch(retrieveEngAttachments({ userID: userCredentials.id }))
        }}
        transLoading={message && message.loading}
        success={message && message.message}
        error={message && message.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default EngineerScreen
