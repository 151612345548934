import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_ISUPERVISOR_FORRECOMMENDATION_REQ,
  GET_ISUPERVISOR_FORRECOMMENDATION_SUCCESS,
  GET_ISUPERVISOR_FORRECOMMENDATION_FAILED,
  GET_INSPECTIONDETAILS_REQ,
  GET_INSPECTIONDETAILS_SUCCESS,
  GET_INSPECTIONDETAILS_FAILED,
  GET_ISUPERVISOR_RECOMMENDED_REQ,
  GET_ISUPERVISOR_RECOMMENDED_SUCCESS,
  GET_ISUPERVISOR_RECOMMENDED_FAILED,
  GET_ISUPERVISOR_LISTREPORT_REQ,
  GET_ISUPERVISOR_LISTREPORT_SUCCESS,
  GET_ISUPERVISOR_LISTREPORT_FAILED,
  INSERT_ISUPERVISOR_COMMENT_REQ,
  INSERT_ISUPERVISOR_COMMENT_SUCCESS,
  INSERT_ISUPERVISOR_COMMENT_FAILED,
  INSERT_ISUPERVISOR_RECOMMENDATION_REQ,
  INSERT_ISUPERVISOR_RECOMMENDATION_SUCCESS,
  INSERT_ISUPERVISOR_RECOMMENDATION_FAILED,
  INSERT_UPDATE_PENALTY_REQ,
  INSERT_UPDATE_PENALTY_SUCCESS,
  INSERT_UPDATE_PENALTY_FAILED,
  GET_ISUPERVISOR_PENALTY_REQ,
  GET_ISUPERVISOR_PENALTY_SUCCESS,
  GET_ISUPERVISOR_PENALTY_FAILED,
  INSERT_RETURNAPPINSPECTION_REQ,
  INSERT_RETURNAPPINSPECTION_SUCCESS,
  INSERT_RETURNAPPINSPECTION_FAILED,
  UPDATE_ADMINFINE_REQ,
  UPDATE_ADMINFINE_SUCCESS,
  UPDATE_ADMINFINE_FAILED,
} from '../../../constants/Inspectors/Supervisor/isupervisorConstants'

export const retrieveForRecommendationList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ISUPERVISOR_FORRECOMMENDATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspector/supervisor/i-for-recommendation-list`,
        config
      )

      dispatch({
        type: GET_ISUPERVISOR_FORRECOMMENDATION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ISUPERVISOR_FORRECOMMENDATION_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveInspectionDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_INSPECTIONDETAILS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspector/supervisor/inspection-details`,
        payload,
        config
      )

      dispatch({
        type: GET_INSPECTIONDETAILS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_INSPECTIONDETAILS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveRecommendedList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ISUPERVISOR_RECOMMENDED_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspector/supervisor/i-recommended-list`,
        config
      )

      dispatch({
        type: GET_ISUPERVISOR_RECOMMENDED_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ISUPERVISOR_RECOMMENDED_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveReportList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ISUPERVISOR_LISTREPORT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspector/supervisor/inspection-list/${payload.id}`,
        config
      )

      dispatch({
        type: GET_ISUPERVISOR_LISTREPORT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ISUPERVISOR_LISTREPORT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const insertInspectionComments = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_ISUPERVISOR_COMMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspector/supervisor/i-comment/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_ISUPERVISOR_COMMENT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_ISUPERVISOR_COMMENT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const insertRecommendation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_ISUPERVISOR_RECOMMENDATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspector/supervisor/i-recommend/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_ISUPERVISOR_RECOMMENDATION_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_ISUPERVISOR_RECOMMENDATION_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const updatePenaltyValue = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_UPDATE_PENALTY_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspector/supervisor/update-penalty/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_UPDATE_PENALTY_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_UPDATE_PENALTY_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const updateAdminFineValue = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ADMINFINE_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspector/supervisor/update-admin-fine/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_ADMINFINE_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_ADMINFINE_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const retrievePenalty = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ISUPERVISOR_PENALTY_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspector/supervisor/i-penalty/${payload.id}`,
        config
      )

      dispatch({
        type: GET_ISUPERVISOR_PENALTY_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ISUPERVISOR_PENALTY_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const returnAppInspection = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_RETURNAPPINSPECTION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspector/supervisor/return-application/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_RETURNAPPINSPECTION_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_RETURNAPPINSPECTION_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}
