import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Modal,
  Spinner,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FaSearch, FaPlus, FaCheckCircle, FaFileAlt } from 'react-icons/fa'
import _ from 'lodash'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'
import Loading from '../../reusable/Loading'

// Components
import EmployeeTable from '../../reusable/EmployeeTable'

// Actions
import {
  retrieveEmployeeList,
  retrieveEmployeeInfo,
  updateEmployeeInfo,
  createEmployeeInfo,
  deleteEmployeeInfo,
} from '../../redux/actions/Administrator/administratorActions'

import {
  createEmpSignature,
  retrieveEmpSignature,
  updateEmpSignature,
} from '../../redux/actions/SuperAdmin/superAdminActions'

const CreateAccountScreen = () => {
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const [showSignature, setShowSignature] = useState(false)
  const [uploadImages, setUploadImages] = useState(null)

  const [modal, setModal] = useState({
    createEmployee: false,
    editEmployee: false,
    updateSuccess: false,
    createSuccess: false,
    deleteSuccess: false,
    sigSuccess: false,
    updateSigSuccess: false,
  })

  const [empInfo, setEmpInfo] = useState({
    FirstName: '',
    MiddleName: '',
    LastName: '',
    username: '',
    password: '',
    id: 0,
    roles: '',
    subroles: '',
    TimeStamp: '',
    CreatedBy: '',
  })

  const users = useSelector((state) => state.users)

  const { userCredentials } = users

  const administratorReducer = useSelector(
    (state) => state.administratorReducer
  )

  const {
    retrievedEmployeeList,
    retrievedEmployeeInfo,
    updatedEmployeeInfo,
    createdEmployeeInfo,
    deletedEmployeeInfo,
  } = administratorReducer

  const superAdminReducers = useSelector((state) => state.superAdminReducer)

  const { createdEmpSignature, retrievedEmpSignature, updatedEmpSignature } =
    superAdminReducers

  // Functions
  const handleView = (x) => {
    setEmpInfo({
      ...empInfo,
      FirstName: x.FirstName !== null ? x.FirstName : '',
      MiddleName: x.MiddleName !== null ? x.MiddleName : '',
      LastName: x.LastName !== null ? x.LastName : '',
      username: x.username !== null ? x.username : '',
      id: x.id,
      roles: x.roles !== null ? x.roles : '',
      subroles: x.subroles !== null ? x.subroles : '',
      TimeStamp: x.TimeStamp !== null ? x.TimeStamp : '',
      CreatedBy: x.CreatedBy !== null ? x.CreatedBy : '',
      password: 'password',
    })
    dispatch(retrieveEmployeeInfo({ id: x.id }))
    setModal({
      editEmployee: true,
    })
  }

  const handleUploadImage = async (e) => {
    let files = e.target.files
    if (e.target.files.length > 3) {
      alert('You are only allowed to upload a maximum of 3 images!')
    }
    setUploadImages(files)
  }

  const handleUpdateInfo = () => {
    dispatch(
      updateEmployeeInfo({
        id: empInfo.id,
        username: empInfo.username,
        password: empInfo.password,
        roles: empInfo.roles,
        subroles: empInfo.subroles,
        createdBy: userCredentials.id,
        FirstName: empInfo.FirstName,
        MiddleName: empInfo.MiddleName,
        LastName: empInfo.LastName,
      })
    )
    setModal({
      editEmployee: false,
      updateSuccess: true,
    })
  }

  const handleCreateInfo = () => {
    dispatch(
      createEmployeeInfo({
        username: empInfo.username,
        password: empInfo.password,
        roles: empInfo.roles,
        subroles: empInfo.subroles,
        createdBy: userCredentials.id,
        FirstName: empInfo.FirstName,
        MiddleName: empInfo.MiddleName,
        LastName: empInfo.LastName,
      })
    )
    setModal({
      createEmployee: false,
      createSuccess: true,
    })
  }

  const handleChange = (e) => {
    setEmpInfo({
      ...empInfo,
      [e.target.name]: e.target.value,
    })
  }

  const handleAddEmployee = () => {
    setModal({
      createEmployee: true,
    })
    setEmpInfo({
      ...empInfo,
      password: 'password',
    })
  }

  const handleDeleteEmployee = (x) => {
    if (window.confirm('Are you sure wanted to delete this application?')) {
      dispatch(deleteEmployeeInfo({ id: x.id }))
      setModal({
        deleteSuccess: true,
      })
    }
  }

  const handleSubmitSignature = (e) => {
    // console.log('test')
    if (retrievedEmpSignature && retrievedEmpSignature.message) {
      dispatch(
        updateEmpSignature({
          images: uploadImages,
          id: retrievedEmpSignature.message[0].id,
          empID: retrievedEmpSignature.message[0].empID,
        })
      )

      setShowSignature(false)
      setModal({ ...modal, updateSigSuccess: true })
    } else {
      dispatch(
        createEmpSignature({
          images: uploadImages,
          empID: empInfo.id,
        })
      )

      setShowSignature(false)
      setModal({ ...modal, sigSuccess: true })
    }
  }

  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(retrieveEmployeeList())
  }, [dispatch])

  // End UseEffect

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          <Form>
            <Row className='justify-content-end px-3 pt-3 pb-2'>
              <Col md={4}>
                <InputGroup>
                  <Form.Control
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    value={searchTerm}
                    placeholder='Input search keyword here . . .'
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm === '' ? (
                    <InputGroup.Text className='bg-info text-light'>
                      <FaSearch />
                    </InputGroup.Text>
                  ) : (
                    <Button variant='danger' onClick={() => setSearchTerm('')}>
                      x
                    </Button>
                  )}
                </InputGroup>
              </Col>
            </Row>
          </Form>

          {retrievedEmployeeList && retrievedEmployeeList.message && (
            <EmployeeTable
              data={
                retrievedEmployeeList &&
                retrievedEmployeeList.message &&
                retrievedEmployeeList.message
              }
              searchTerm={searchTerm}
              handleView={handleView}
              handleAddEmployee={handleAddEmployee}
              handleDeleteEmployee={handleDeleteEmployee}
            />
          )}

          {retrievedEmployeeList && retrievedEmployeeList.loading && (
            <Loading />
          )}
        </Container>
      </Container>

      {/* Create Employee info */}
      <Modal
        size='lg'
        show={modal.createEmployee}
        onHide={() => setModal({ createEmployee: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            ADD EMPLOYEE
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type='text'
              style={{
                background: '#FFF',
                border: '1px solid #ced4da',
              }}
              size='sm'
              required
              value={empInfo.FirstName}
              name='FirstName'
              onChange={(e) => handleChange(e)}
            />
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              type='text'
              style={{
                background: '#FFF',
                border: '1px solid #ced4da',
              }}
              size='sm'
              required
              value={empInfo.MiddleName}
              name='MiddleName'
              onChange={(e) => handleChange(e)}
            />
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type='text'
              style={{
                background: '#FFF',
                border: '1px solid #ced4da',
              }}
              size='sm'
              required
              value={empInfo.LastName}
              name='LastName'
              onChange={(e) => handleChange(e)}
            />

            <Form.Label>Username</Form.Label>
            <Form.Control
              type='text'
              style={{
                background: '#FFF',
                border: '1px solid #ced4da',
              }}
              size='sm'
              required
              value={empInfo.username}
              name='username'
              onChange={(e) => handleChange(e)}
            />

            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              style={{
                background: '#FFF',
                border: '1px solid #ced4da',
              }}
              size='sm'
              required
              name='password'
              value={empInfo.password}
              disabled
            />

            <Form.Label>Roles</Form.Label>
            <Form.Control
              as='select'
              style={{
                background: '#FFF',
                border: '1px solid #ced4da',
              }}
              size='sm'
              required
              value={empInfo.roles}
              name='roles'
              onChange={(e) => handleChange(e)}
            >
              <option value=''>Choose a role here ...</option>
              <option value='admin'>admin</option>
              <option value='document controller'>document controller</option>
              <option value='evaluator'>evaluator</option>
              <option value='inspector'>inspector</option>
              <option value='recommender'>recommender</option>
            </Form.Control>
            <Form.Label>Sub-roles</Form.Label>
            <Form.Control
              as='select'
              style={{
                background: '#FFF',
                border: '1px solid #ced4da',
              }}
              size='sm'
              required
              value={empInfo.subroles}
              name='subroles'
              onChange={(e) => handleChange(e)}
            >
              {empInfo.roles === 'inspector' && (
                <>
                  <option value=''>Choose sub-role here ...</option>
                  <option value='supervisor'>supervisor</option>
                </>
              )}
              {empInfo.roles === 'evaluator' && (
                <>
                  <option value=''>Choose sub-role here ...</option>
                  <option value='Structural'>Structural</option>
                  <option value='Electronics'>Electronics</option>
                  <option value='Electrical'>Electrical</option>
                  <option value='Mechanical'>Mechanical</option>
                  <option value='Plumbing'>Plumbing</option>
                  <option value='Architectural'>Architectural</option>
                </>
              )}
            </Form.Control>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              handleCreateInfo()
            }}
          >
            SUBMIT
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              setModal({
                editEmployee: false,
              })
              setEmpInfo({
                FirstName: '',
                MiddleName: '',
                LastName: '',
                username: '',
                password: '',
                id: 0,
                roles: '',
                subroles: '',
                TimeStamp: '',
                CreatedBy: '',
              })
            }}
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Employee info  */}

      {/* Update Employee info */}
      <Modal
        size='lg'
        show={modal.editEmployee}
        onHide={() => setModal({ editEmployee: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            EDIT EMPLOYEE INFORMATION
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {retrievedEmployeeInfo && retrievedEmployeeInfo.message && (
            <Form>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                size='sm'
                required
                value={empInfo.FirstName}
                name='FirstName'
                onChange={(e) => handleChange(e)}
              />
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type='text'
                size='sm'
                required
                value={empInfo.MiddleName}
                name='MiddleName'
                onChange={(e) => handleChange(e)}
              />
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                size='sm'
                required
                value={empInfo.LastName}
                name='LastName'
                onChange={(e) => handleChange(e)}
              />

              <Form.Label>Username</Form.Label>
              <Form.Control
                type='text'
                size='sm'
                required
                value={empInfo.username}
                name='username'
                onChange={(e) => handleChange(e)}
              />

              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                size='sm'
                required
                name='password'
                value={empInfo.password}
                disabled
              />

              <Form.Label>Roles</Form.Label>
              <Form.Control
                as='select'
                size='sm'
                required
                value={empInfo.roles}
                name='roles'
                onChange={(e) => handleChange(e)}
              >
                <option value=''>Choose a role here ...</option>
                <option value='ict'>ict</option>
                <option value='admin'>admin</option>
                <option value='document controller'>document controller</option>
                <option value='evaluator'>evaluator</option>
                <option value='inspector'>inspector</option>
                <option value='recommender'>recommender</option>
              </Form.Control>
              <Form.Label>Sub-roles</Form.Label>
              <Form.Control
                as='select'
                size='sm'
                required
                value={empInfo.subroles}
                name='subroles'
                onChange={(e) => handleChange(e)}
              >
                {empInfo.roles === 'inspector' && (
                  <>
                    <option value=''>Choose sub-role here ...</option>
                    <option value='supervisor'>supervisor</option>
                  </>
                )}
                {empInfo.roles === 'evaluator' && (
                  <>
                    <option value=''>Choose sub-role here ...</option>
                    <option value='Structural'>Structural</option>
                    <option value='Electronics'>Electronics</option>
                    <option value='Electrical'>Electrical</option>
                    <option value='Mechanical'>Mechanical</option>
                    <option value='Plumbing'>Plumbing</option>
                    <option value='Architectural'>Architectural</option>
                  </>
                )}
              </Form.Control>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='info'
            size='sm'
            onClick={() => {
              setShowSignature(true)
              setModal({ ...modal, editEmployee: false })
              dispatch(retrieveEmpSignature({ empID: empInfo.id }))
            }}
          >
            Add Signature
          </Button>
          <Button
            variant='success'
            onClick={() => {
              handleUpdateInfo()
            }}
            size='sm'
          >
            Submit
          </Button>
          <Button
            variant='danger'
            onClick={() => {
              setModal({
                editEmployee: false,
              })
              setEmpInfo({
                FirstName: '',
                MiddleName: '',
                LastName: '',
                username: '',
                password: '',
                id: 0,
                roles: '',
                subroles: '',
                TimeStamp: '',
                CreatedBy: '',
              })
            }}
            size='sm'
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Employee info  */}

      {/* View & Create Image Modal */}
      <Modal
        size='lg'
        show={showSignature}
        onHide={() => setShowSignature(false)}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaFileAlt className='mx-3' />
            Image Uploading and Viewing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-center'>
            <Form.File
              id='image-file'
              size='sm'
              className='my-2'
              multiple
              onChange={handleUploadImage}
              required
            />
          </div>
          {retrievedEmpSignature && retrievedEmpSignature.message && (
            <div>
              {retrievedEmpSignature.message.map((image, index) => (
                <Row key={index}>
                  <img
                    key={index}
                    className='my-2'
                    src={`data:image/png;base64,${image.Image}`}
                  />
                </Row>
              ))}
            </div>
          )}

          {retrievedEmpSignature && retrievedEmpSignature.loading && (
            <div className='text-center pt-3'>
              <Spinner animation='border' />
              <h3>Loading...</h3>
            </div>
          )}

          {retrievedEmpSignature && retrievedEmpSignature.error && (
            <h5 className='text-center my-2'>No Image/s Uploaded Yet</h5>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' size='sm' onClick={handleSubmitSignature}>
            Upload
          </Button>
          <Button
            onClick={() => setShowSignature(false)}
            variant='danger'
            size='sm'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End View & Create Image Modal */}

      {/* Success Update Employee Info */}
      <SuccessModal
        show={modal.updateSuccess}
        onHide={() => {
          setModal({ updateSuccess: false })
          window.location.reload(true)
        }}
        transLoading={updatedEmployeeInfo && updatedEmployeeInfo.loading}
        success={updatedEmployeeInfo && updatedEmployeeInfo.message}
        error={updatedEmployeeInfo && updatedEmployeeInfo.error}
      />
      {/* End Success Update Employee Info */}

      {/* Success Create Employee Info */}
      <SuccessModal
        show={modal.createSuccess}
        onHide={() => {
          setModal({ createSuccess: false })
          window.location.reload(true)
        }}
        transLoading={createdEmployeeInfo && createdEmployeeInfo.loading}
        success={createdEmployeeInfo && createdEmployeeInfo.message}
        error={createdEmployeeInfo && createdEmployeeInfo.error}
      />
      {/* End Success Create Employee Info */}

      {/* Success Delete Employee Info */}
      <SuccessModal
        show={modal.deleteSuccess}
        onHide={() => {
          setModal({ deleteSuccess: false })
          window.location.reload(true)
        }}
        transLoading={deletedEmployeeInfo && deletedEmployeeInfo.loading}
        success={deletedEmployeeInfo && deletedEmployeeInfo.message}
        error={deletedEmployeeInfo && deletedEmployeeInfo.error}
      />
      {/* End Success Delete Employee Info */}

      {/* Success Create Employee Signature */}
      <SuccessModal
        show={modal.sigSuccess}
        onHide={() => {
          setModal({ sigSuccess: false })
          window.location.reload(true)
        }}
        transLoading={createdEmpSignature && createdEmpSignature.loading}
        success={createdEmpSignature && createdEmpSignature.message}
        error={createdEmpSignature && createdEmpSignature.error}
      />
      {/* End Success Create Employee Signature */}

      {/* Success Update Employee Signature */}
      <SuccessModal
        show={modal.updateSigSuccess}
        onHide={() => {
          setModal({ updateSigSuccess: false })
          window.location.reload(true)
        }}
        transLoading={updatedEmpSignature && updatedEmpSignature.loading}
        success={updatedEmpSignature && updatedEmpSignature.message}
        error={updatedEmpSignature && updatedEmpSignature.error}
      />
      {/* End Success Update Employee Signature */}
    </UserMain>
  )
}

export default CreateAccountScreen
