import {
  GET_CIVIL_EVALUATION_DETAILS_REQ,
  GET_CIVIL_EVALUATION_DETAILS_SUCCESS,
  GET_CIVIL_EVALUATION_DETAILS_FAILED,
  CREATE_CIVIL_EVALUATION_REQ,
  CREATE_CIVIL_EVALUATION_SUCCESS,
  CREATE_CIVIL_EVALUATION_FAILED,
  UPDATE_CIVIL_EVALUATION_REQ,
  UPDATE_CIVIL_EVALUATION_SUCCESS,
  UPDATE_CIVIL_EVALUATION_FAILED,
} from '../constants/evaluatorCivilConstants'

export const evaluatorCivilReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_CIVIL_EVALUATION_DETAILS_REQ:
      return {
        ...state,
        evaluationCivilDetails: { loading: true },
      }
    case GET_CIVIL_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        evaluationCivilDetails: { loading: false, message: action.payload },
      }
    case GET_CIVIL_EVALUATION_DETAILS_FAILED:
      return {
        ...state,
        evaluationCivilDetails: { loading: false, error: action.payload },
      }
    case CREATE_CIVIL_EVALUATION_REQ:
      return {
        ...state,
        createCivilEvaluation: { loading: true },
      }
    case CREATE_CIVIL_EVALUATION_SUCCESS:
      return {
        ...state,
        createCivilEvaluation: { loading: false, message: action.payload },
      }
    case CREATE_CIVIL_EVALUATION_FAILED:
      return {
        ...state,
        createCivilEvaluation: { loading: false, error: action.payload },
      }
    case UPDATE_CIVIL_EVALUATION_REQ:
      return {
        ...state,
        updateCivilEvaluation: { loading: true },
      }
    case UPDATE_CIVIL_EVALUATION_SUCCESS:
      return {
        ...state,
        updateCivilEvaluation: { loading: false, message: action.payload },
      }
    case UPDATE_CIVIL_EVALUATION_FAILED:
      return {
        ...state,
        updateCivilEvaluation: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
