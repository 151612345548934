import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Modal,
  Card,
  Pagination,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { FaCheckCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'
import _ from 'lodash'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import Loading from '../../reusable/Loading'
import Error from '../../reusable/Error'
import CollectionReport from '../../reusable/CollectionReport'

// Actions
import { retrieveCollectionReport } from '../../redux/actions/DocumentControllers/documentControllerActions'

const ReportScreen = () => {
  // Declaration
  const dispatch = useDispatch()

  const [filterData, setFilterData] = useState({
    filtered: false,
    application: '',
    status: '',
    dateStart: '',
    dateEnd: '',
    data: [],
    status: '',
  })
  const [modal, setModal] = useState({
    viewFilter: false,
  })

  const dc = useSelector((state) => state.documentControllerReducer)

  const { retrievedCollectionReport } = dc

  //   End Declaration

  // Pagination Area Forms
  const [dfpaginatedForms, setdfpaginatedForms] = useState([])
  const [dfcurrentPage, setdfcurrentPage] = useState(1)

  const [dfpageNumberLimit] = useState(5)
  const [maxdfPageNumberLimit, setmaxdfPageNumberLimit] = useState(5)
  const [mindfPageNumberLimit, setmindfPageNumberLimit] = useState(0)

  const dfpageSize = 10

  useEffect(() => {
    setdfpaginatedForms(
      _(_.reverse(filterData.data)).slice(0).take(dfpageSize).value()
    )
    setdfcurrentPage(1)
  }, [filterData.data])

  const dfpageCount = filterData.data
    ? Math.ceil(filterData.data.length / dfpageSize)
    : 0

  const dfpages = _.range(1, dfpageCount + 1)

  const dfpagination = (pageNo) => {
    setdfcurrentPage(pageNo)
    const dfstartIndex = (pageNo - 1) * dfpageSize
    const dfpaginationForms = _(filterData.data)
      .slice(dfstartIndex)
      .take(dfpageSize)
      .value()
    setdfpaginatedForms(dfpaginationForms)
  }

  const dfrenderPageNumber = dfpages.map((page) => {
    if (page < maxdfPageNumberLimit + 1 && page > mindfPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => dfpagination(page)}
          active={page === dfcurrentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const dfhandleNextbtn = () => {
    setdfcurrentPage(dfcurrentPage + 1)
    dfpagination(dfcurrentPage + 1)

    if (dfcurrentPage + 1 > maxdfPageNumberLimit) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit + dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit + dfpageNumberLimit)
    }
  }

  const dfhandlePrevbtn = () => {
    setdfcurrentPage(dfcurrentPage - 1)
    dfpagination(dfcurrentPage - 1)

    if ((dfcurrentPage - 1) % dfpageNumberLimit === 0) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit - dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit - dfpageNumberLimit)
    }
  }
  // End Pagination Area Forms

  // Functions
  const handleFilterChange = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    })
  }
  // End Functions

  // useEffect
  useEffect(() => {
    if (retrievedCollectionReport && retrievedCollectionReport.message) {
      setFilterData({
        ...filterData,
        data: retrievedCollectionReport && retrievedCollectionReport.message,
      })
    }
  }, [retrievedCollectionReport])
  // End useEffect

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          <div>
            <h3 className='pt-3 fw-bold mb-0' style={{ color: '#00304b' }}>
              COLLECTION REPORT
            </h3>
            <hr
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <Row className='mt-2 mx-2'>
              <Col md={8} className='mb-2'>
                <Button
                  size='sm'
                  variant='info'
                  onClick={() =>
                    setModal({
                      viewFilter: true,
                    })
                  }
                >
                  Filter
                </Button>
              </Col>
            </Row>

            <Row className='mx-1'>
              <Col md={6} xl={6}>
                <div className='d-flex pt-1'>
                  <h6 className='pt-1'>No. of Release : </h6>
                  <p className='pb-0'>
                    {retrievedCollectionReport &&
                      retrievedCollectionReport.message &&
                      retrievedCollectionReport.message.length}
                  </p>
                </div>
              </Col>
              <Col md={6} xl={6}>
                <div className='d-flex pt-1 justify-content-center'>
                  <h6 className='pt-1'>TOTAL COLLECTION : </h6>
                  <p className='pb-0 text-danger'>
                    {retrievedCollectionReport &&
                      retrievedCollectionReport.message &&
                      filterData.data.reduce(
                        (accu, current) => accu + current.amount,
                        0
                      )}
                  </p>
                </div>
              </Col>
            </Row>
            <div className='d-flex mx-3'>
              <h6 className='pt-1'>Date Start : </h6>
              <p className='pb-0'>
                {retrievedCollectionReport &&
                  retrievedCollectionReport.message &&
                  filterData.dateStart}
              </p>
            </div>
            <div className='d-flex mx-3'>
              <h6 className='pt-1'>Date End : </h6>
              <p className='pb-0'>
                {retrievedCollectionReport &&
                  retrievedCollectionReport.message &&
                  filterData.dateEnd}
              </p>
            </div>

            {filterData.data === undefined ||
              (retrievedCollectionReport &&
                retrievedCollectionReport.message &&
                filterData.data.length !== 0 && (
                  <>
                    <Table
                      size='sm'
                      striped
                      bordered
                      hover
                      responsive
                      className='mx-2'
                    >
                      <thead className='text-center'>
                        <tr>
                          <td className='text-nowrap'>APP ID:</td>
                          <td>APPLICATION</td>
                          <td>STATUS</td>
                          <td className='text-nowrap'>OR NO</td>
                          <td className='text-nowrap'>OR DATE</td>
                          <td>APPLICANT</td>
                          <td>AMOUNT</td>
                          <td>Encoded By</td>
                        </tr>
                      </thead>
                      <tbody className='text-center'>
                        {dfpaginatedForms.map((e, index) => (
                          <tr key={index}>
                            <td>{e.appID}</td>
                            <td>{e.ApplicationName}</td>
                            <td>{e.status}</td>
                            <td>{e.orNo}</td>
                            <td>{e.orDate}</td>
                            <td>{e.Applicant}</td>
                            <td>{e.amount}</td>
                            <td>
                              {e.EncodedBy !== null
                                ? e.EncodedBy
                                : 'Paid Online'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination
                      size='sm'
                      className='d-flex justify-content-end mx-2 my-2'
                    >
                      <Pagination.Item
                        className=' mt-0 fs-6 text-nowrap'
                        size='sm'
                        variant='info'
                        onClick={dfhandlePrevbtn}
                        disabled={dfcurrentPage === dfpages[0] ? true : false}
                      >
                        &lt;
                      </Pagination.Item>

                      {dfrenderPageNumber}

                      <Pagination.Item
                        className=' mt-0 fs-6 text-nowrap'
                        size='sm'
                        variant='info'
                        onClick={dfhandleNextbtn}
                        disabled={
                          dfcurrentPage === dfpages[dfpages.length - 1]
                            ? true
                            : false
                        }
                      >
                        &gt;
                      </Pagination.Item>
                    </Pagination>
                    <div className='d-flex justify-content-end mt-3'>
                      <PDFDownloadLink
                        document={
                          <CollectionReport
                            values={filterData.data}
                            dateStart={filterData.dateStart}
                            dateEnd={filterData.dateEnd}
                          />
                        }
                        fileName='CollectionReport.pdf'
                      >
                        {({ loading }) =>
                          loading ? (
                            'Loading document...'
                          ) : (
                            <Button variant='info' size='sm'>
                              PRINT
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    </div>
                  </>
                ))}

            {retrievedCollectionReport && retrievedCollectionReport.loading ? (
              <Loading />
            ) : (
              <>
                {filterData.data === undefined ||
                  (filterData.data.length === 0 && (
                    <Error message='kindly use the "filter" button, to display record you desire' />
                  ))}
              </>
            )}
          </div>
        </Container>
      </Container>

      {/* Filter Modal */}
      <Modal
        size='lg'
        show={modal.viewFilter}
        onHide={() => setModal({ viewFilter: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> Filter
          </Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            setFilterData({ ...filterData, filtered: true })
            dispatch(retrieveCollectionReport(filterData))
            setModal({ viewFilter: false })
          }}
        >
          <Modal.Body>
            <Form.Group>
              <Form.Label>Application</Form.Label>
              <Form.Control
                as='select'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                name='application'
                onChange={(e) => handleFilterChange(e)}
                value={filterData.application}
              >
                <option value=''>ALL</option>
                <option value='APPLICATION VERIFICATION'>
                  APPLICATION VERIFICATION
                </option>
                <option value='FOR INSPECTION AND EVALUATION'>
                  FOR INSPECTION AND EVALUATION
                </option>
                <option value='FOR APPROVAL'>FOR APPROVAL</option>
                <option value='APPROVED APPLICATION'>
                  APPROVED APPLICATION
                </option>
                <option value='RELEASE APPLICATION WITH REMARKS'>
                  RELEASE APPLICATION WITH REMARKS
                </option>
                <option value='RELEASE APPLICATION'>RELEASE APPLICATION</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className='pt-1'>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as='select'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                name='status'
                onChange={(e) => handleFilterChange(e)}
                value={filterData.status}
              >
                <option value=''>ALL</option>
                <option value='BUILDING CONSTRUCTION PERMIT'>
                  BUILDING CONSTRUCTION PERMIT
                </option>
                <option value='OCCUPANCY PERMIT'>OCCUPANCY PERMIT</option>
                <option value='FENCING PERMIT'>FENCING PERMIT</option>
                <option value='DEMOLITION PERMIT'>DEMOLITION PERMIT</option>
                <option value='ELECTRICAL PERMIT'>ELECTRICAL PERMIT</option>
                <option value='RELEASE APPLICATION'>RELEASE APPLICATION</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className='pt-1'>
              <Form.Label>Date Start</Form.Label>
              <Form.Control
                type='date'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                required
                name='dateStart'
                onChange={(e) => handleFilterChange(e)}
                value={filterData.dateStart}
              />
            </Form.Group>
            <Form.Group className='pt-1'>
              <Form.Label>Date End</Form.Label>
              <Form.Control
                type='date'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                required
                name='dateEnd'
                onChange={(e) => handleFilterChange(e)}
                min={filterData.dateStart}
                value={filterData.dateEnd}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' variant='success' size='sm'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewFilter: false,
                })
              }}
              size='sm'
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/*End Filter Modal */}
    </UserMain>
  )
}

export default withRouter(ReportScreen)
