import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProtectedRoute from '../reusable/ProtectedRoute'

// Components
import DcDashboardScreen from '../screens/DcScreens/DcDashboardScreen'
import DcForPaymentScreen from '../screens/DcScreens/DcForPaymentScreen'
import CategoryScreen from '../screens/CategoryScreen'
import DcCreateScreen from '../screens/DcScreens/DcCreateScreen'
import ManageApplication from '../screens/DcScreens/ManageApplication'
import ApplicationInformationScreen from '../screens/DcScreens/ApplicationInformationScreen'
import ApplicationInfoEditScreen from '../screens/DcScreens/ApplicationInfoEditScreen'
import RoutingSlipInfoScreen from '../screens/DcScreens/RoutingSlipInfoScreen'
import ExpiredTokenScreen from '../screens/ExpiredTokenScreen'
import EmployeeProfileScreen from '../screens/DcScreens/EmployeeProfileScreen'
import EvaluationSheetScreen from '../screens/DcScreens/EvaluationSheetScreen'
import ReportScreen from '../screens/DcScreens/ReportScreen'
import DCCategoryScreen from '../screens/DcScreens/DCCategoryScreen'
import ApplicationStatusScreen from '../screens/DcScreens/ApplicationStatusScreen'

const DcRoutes = ({ user }) => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path='/dashboard'
        component={DcDashboardScreen}
        isAuth={user === null ? false : true}
      />
      <ProtectedRoute
        path='/applications'
        component={DcForPaymentScreen}
        isAuth={user === null ? false : true}
      />
      <ProtectedRoute
        path='/category'
        component={DCCategoryScreen}
        isAuth={user === null ? false : true}
      />
      <ProtectedRoute
        path='/walkin-applications'
        component={DcCreateScreen}
        isAuth={user === null ? false : true}
      />
      <ProtectedRoute
        exact
        path='/application/:id?'
        component={ManageApplication}
        isAuth={user === null ? false : true}
      />
      <ProtectedRoute
        exact
        path='/application-information/:id?'
        component={ApplicationInformationScreen}
        isAuth={user === null ? false : true}
      />
      <ProtectedRoute
        exact
        path='/application-add-edit-information/:id?'
        component={ApplicationInfoEditScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/routing-slip/:id?'
        component={RoutingSlipInfoScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/profile'
        component={EmployeeProfileScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/evaluation-sheet/:id?'
        component={EvaluationSheetScreen}
        isAuth={user === null ? false : true}
      />

      {/* <ProtectedRoute
        exact
        path='/application-status'
        component={ApplicationStatusScreen}
        isAuth={user === null ? false : true}
      /> */}

      <ProtectedRoute
        exact
        path='/reports'
        component={ReportScreen}
        isAuth={user === null ? false : true}
      />

      <Route component={ExpiredTokenScreen} />
    </Switch>
  )
}

export default DcRoutes
