import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_ESUPERVISOR_FORRECOMMENDATION_REQ,
  GET_ESUPERVISOR_FORRECOMMENDATION_SUCCESS,
  GET_ESUPERVISOR_FORRECOMMENDATION_FAILED,
  GET_ESUPERVISOR_RECOMMENDED_REQ,
  GET_ESUPERVISOR_RECOMMENDED_SUCCESS,
  GET_ESUPERVISOR_RECOMMENDED_FAILED,
  GET_EVALUATIONLIST_RECOMMENDED_REQ,
  GET_EVALUATIONLIST_RECOMMENDED_SUCCESS,
  GET_EVALUATIONLIST_RECOMMENDED_FAILED,
  GET_ESUPERVISOR_RECOMMEND_REQ,
  GET_ESUPERVISOR_RECOMMEND_SUCCESS,
  GET_ESUPERVISOR_RECOMMEND_FAILED,
  INSERT_COMMENT_REQ,
  INSERT_COMMENT_SUCCESS,
  INSERT_COMMENT_FAILED,
} from '../../../constants/Evaluators/Supervisor/supervisorConstants'

export const retrieveForRecommendationList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ESUPERVISOR_FORRECOMMENDATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/evaluator/supervisor/for-recommendation-list`,
        config
      )

      dispatch({
        type: GET_ESUPERVISOR_FORRECOMMENDATION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ESUPERVISOR_FORRECOMMENDATION_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveRecommendedList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ESUPERVISOR_RECOMMENDED_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/evaluator/supervisor/recommended-list`,
        config
      )

      dispatch({
        type: GET_ESUPERVISOR_RECOMMENDED_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ESUPERVISOR_RECOMMENDED_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveEvaluationList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EVALUATIONLIST_RECOMMENDED_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/evaluator/supervisor/evaluation-list/${payload.id}`,
        config
      )

      dispatch({
        type: GET_EVALUATIONLIST_RECOMMENDED_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_EVALUATIONLIST_RECOMMENDED_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const insertRecommendApplication = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ESUPERVISOR_RECOMMEND_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/evaluator/supervisor/e-recommend-application/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: GET_ESUPERVISOR_RECOMMEND_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: GET_ESUPERVISOR_RECOMMEND_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const insertEvaluationComments = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_COMMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/evaluator/supervisor/comment/${payload.id}`,
        payload,
        config
      )

      // console.log(payload)

      dispatch({
        type: INSERT_COMMENT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_COMMENT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}
