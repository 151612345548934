import React from 'react'

const FaqPaymentChannel = () => {
  return (
    <div>
      <h6>
        Order of payment can be viewed from your "application" once the status
        is changed to "Approved Application".
      </h6>

      <h6>To pay the amount declared:</h6>

      <p>
        1.Print the order of payment and bring your own copy of signed & sealed
        Bill of materials
      </p>

      <p>2.Proceed to Counter 32/33 for Contractor's / Special Tax</p>

      <p>3.Proceed to miscellaneous Counter for payment</p>

      <p>
        4. Present a clear photocopy of the Official Receipts to the Office of
        the Building Official to claim or download your permit on your account.
      </p>
    </div>
  )
}

export default FaqPaymentChannel
