import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Style
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'

// Data
import { provinces } from '../../../data/provinces'

// Revised Actions
import {
  retrieveAllApplications,
  retrieveApplicationInformation,
  insertApplicationInformation,
  updateApplicationInformation,
} from '../../redux/actions/DocumentControllers/documentControllerActions'

const ApplicationInfoEditScreen = ({ history, match }) => {
  // Declaration
  const [state, setState] = useState({})

  const [appInfo, setAppInfo] = useState({
    names: [],
    Street: '',
    Area: '',
    Barangay: '',
    Exempted: '',
    id: '',
    dcID: '',
    scopeOfWork: '',
    sw_specify: '',
    useType: '',
    noOfStorey: '',
    floorArea: '',
    computeAs: '',
    noOfUnits: '',
    scheduleOfConstructionStart: '',
    scheduleOfConstructionEnd: '',
    contractor: '',
    // rc_number: '',
    // rc_date: '',
    // rc_place: '',
    estimateCost: 0,
    ec_building: 0,
    ec_electrical: 0,
    ec_plumbing: 0,
    ec_mechanical: 0,
    ec_others: 0,
    StreetLI: '',
    AreaLI: '',
    BarangayLI: '',
    TCTNoLI: '',
    TaxDecNoLI: '',
    LotOwner: '',
    Address: '',
    ResCert: '',
    DateIssued: '',
    PlaceIssued: '',
    Barangays: [],
  })

  const [modal, setModal] = useState({
    updated: false,
    added: false,
  })

  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const dc = useSelector((state) => state.documentControllerReducer)

  const {
    allApplications,
    applicationInformation,
    insertedApplicationInfo,
    updatedApplicationInfo,
  } = dc

  // End Declaration

  // Functions
  const editHandleChange = (e) => {
    setAppInfo({
      ...appInfo,
      [e.target.name]: e.target.value,
    })
  }

  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(retrieveAllApplications())
    dispatch(retrieveApplicationInformation({ id: match.params.id }))
  }, [dispatch, match])

  useEffect(() => {
    if (allApplications && allApplications.message) {
      let params = match.params.id
      const application = allApplications.message.find(
        (app) => app.id === parseInt(params)
      )
      setState(application)
    }
    if (applicationInformation && applicationInformation.message) {
      setAppInfo((info) => ({
        ...info,
        names: applicationInformation.message.map((x) => ({
          lastName: x.lastName,
          firstName: x.firstName,
          middleName: x.middleName,
        })),
        Street: applicationInformation.message[0].ConstStreet,
        Area: applicationInformation.message[0].ConstArea,
        Barangay: applicationInformation.message[0].ConstBarangay,
        Exempted: applicationInformation.message[0].Exempted,
        scopeOfWork: applicationInformation.message[0].scopeOfWork,
        sw_specify: applicationInformation.message[0].sw_specify,
        useType: applicationInformation.message[0].useType,
        noOfStorey: applicationInformation.message[0].noOfStoreys,
        floorArea: applicationInformation.message[0].floorArea,
        computeAs: applicationInformation.message[0].computeAs,
        noOfUnits: applicationInformation.message[0].noOfUnits,
        scheduleOfConstructionStart:
          applicationInformation.message[0].scheduleOfConstructionStart,
        scheduleOfConstructionEnd:
          applicationInformation.message[0].scheduleOfConstructionEnd,
        contractor: applicationInformation.message[0].contractor,
        estimateCost: applicationInformation.message[0].estimateCost,
        ec_building: applicationInformation.message[0].ec_building,
        ec_electrical: applicationInformation.message[0].ec_electrical,
        ec_plumbing: applicationInformation.message[0].ec_plumbing,
        ec_mechanical: applicationInformation.message[0].ec_mechanical,
        ec_others: applicationInformation.message[0].ec_others,
        // rc_number: applicationInformation.message[0].rc_number,
        // rc_date: applicationInformation.message[0].rc_date,
        // rc_place: applicationInformation.message[0].rc_place,
        TCTNoLI: applicationInformation.message[0].TCTNo,
        TaxDecNoLI: applicationInformation.message[0].TaxDecNo,
        LotOwner: applicationInformation.message[0].LotOwner,
        Address: applicationInformation.message[0].Address,
        ResCert: applicationInformation.message[0].ResCert,
        DateIssued: applicationInformation.message[0].DateIssued,
        PlaceIssued: applicationInformation.message[0].PlaceIssued,
        constructionID: applicationInformation.message[0].constructionID,
        siteLotID: applicationInformation.message[0].siteLotID,
        scopeID: applicationInformation.message[0].scopeID,
        id: parseInt(match.params.id),
        dcID: userCredentials.id,
        Barangays: provinces[0].cities[0].barangays,
      }))
    } else {
      setAppInfo((info) => ({
        ...info,
        id: parseInt(match.params.id),
        dcID: userCredentials.id,
        names: [{ lastName: '', firstName: '', middleName: '' }],
        Barangays: provinces[0].cities[0].barangays,
      }))
    }
  }, [allApplications, match, applicationInformation, userCredentials, state])
  // End UseEffect

  return (
    <UserMain>
      <Container>
        {applicationInformation && !applicationInformation.loading && (
          <Row>
            <Col xl={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  if (
                    applicationInformation &&
                    applicationInformation.message
                  ) {
                    if (
                      window.confirm(
                        'Are you sure you want to update status of application?'
                      )
                    ) {
                      dispatch(updateApplicationInformation(appInfo))
                      setModal({ updated: true })
                    }
                  } else if (
                    applicationInformation &&
                    applicationInformation.error
                  ) {
                    if (
                      window.confirm(
                        'Are you sure you want to update status of application?'
                      )
                    ) {
                      dispatch(insertApplicationInformation(appInfo))
                      setModal({ added: true })
                    }
                  }
                }}
                className='py-3 bg-white mb-3 shadow-sm'
              >
                <h5 className='mx-3'>APPLICANT NAME/S</h5>
                <Row className='mx-3 mt-3'>
                  <Col md={4}>
                    <h6>
                      Last Name <i className='text-danger'>*</i>
                    </h6>
                  </Col>
                  <Col md={4}>
                    <h6>
                      First Name <i className='text-danger'>*</i>
                    </h6>
                  </Col>
                  <Col md={4}>
                    <h6>
                      Middle Name <i className='text-danger'>*</i>
                    </h6>
                  </Col>
                </Row>

                {appInfo.names.map((info, index) => (
                  <Row className='mx-3' key={index}>
                    <Form.Group className='mt-1' as={Col} xl={4}>
                      <Form.Control
                        size='sm'
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={info.lastName}
                        name='lastName'
                        onChange={(e) => {
                          let response = appInfo.names
                          response[index].lastName = e.target.value
                          setAppInfo({
                            ...appInfo,
                            names: response,
                          })
                        }}
                        required
                      />
                    </Form.Group>
                    <Form.Group className='mt-1' as={Col} xl={4}>
                      <Form.Control
                        size='sm'
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={info.firstName}
                        name='firstName'
                        onChange={(e) => {
                          let response = appInfo.names
                          response[index].firstName = e.target.value
                          setAppInfo({
                            ...appInfo,
                            names: response,
                          })
                        }}
                        required
                      />
                    </Form.Group>
                    <Form.Group className='mt-1' as={Col} xl={4}>
                      <Form.Control
                        size='sm'
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        name='middleName'
                        value={info.middleName}
                        onChange={(e) => {
                          let response = appInfo.names
                          response[index].middleName = e.target.value
                          setAppInfo({
                            ...appInfo,
                            names: response,
                          })
                        }}
                        required
                      />
                    </Form.Group>
                  </Row>
                ))}

                {/* Names Action Btn */}
                <Row>
                  <Col xl={12} className='mt-2 d-flex justify-content-end'>
                    <Button
                      onClick={() =>
                        setAppInfo({
                          ...appInfo,
                          names: [
                            ...appInfo.names,
                            { lastName: '', firstName: '', middleName: '' },
                          ],
                        })
                      }
                      size='sm'
                      variant='info'
                    >
                      +
                    </Button>
                    <Button
                      onClick={() => {
                        let response = appInfo.names
                        if (appInfo.names.length > 1) {
                          response.pop(response.length - 1)
                        }
                        setAppInfo({
                          ...appInfo,
                          names: response,
                        })
                      }}
                      size='sm'
                      variant='danger'
                      className='mx-2'
                    >
                      -
                    </Button>
                  </Col>
                </Row>
                {/* End Names Action Btn */}

                {/* Construction Site */}
                <h5 className='mx-3'>Construction Site</h5>
                <Row className='mx-3 mt-3'>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Street <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.Street}
                      name='Street'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Area <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.Area}
                      name='Area'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Barangay <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      as='select'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.Barangay}
                      name='Barangay'
                      onChange={editHandleChange}
                      required
                    >
                      <option value=''>Select Barangay here ...</option>
                      {appInfo.Barangays.map((element, index) => (
                        <option key={index} value={element.barangay}>
                          {element.barangay}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>Exempted</h6>
                    <Form.Control
                      as='select'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.Exempted}
                      name='Exempted'
                      onChange={editHandleChange}
                    >
                      <option value=''>Choose here ...</option>
                      <option value='Yes'>Yes</option>
                      <option value='No'>No</option>
                    </Form.Control>
                  </Form.Group>
                </Row>
                {/*End Construction Site */}

                {/* Scope of Work */}
                <h5 className='mx-3 mt-3 mb-0'>Scope of Work</h5>
                <Row className='mx-3 mt-3'>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Scope of Work <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      as='select'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.scopeOfWork}
                      name='scopeOfWork'
                      onChange={editHandleChange}
                      required
                    >
                      <option value=''>Choose here ...</option>
                      <option value='NEW CONSTRUCTION'>NEW CONSTRUCTION</option>
                      <option value='RENOVATION'>RENOVATION</option>
                      <option value='RAISING'>RAISING</option>
                      <option value='ERECTION'>ERECTION</option>
                      <option value='CONVERSION'>CONVERSION</option>
                      <option value='DEMOLITION'>DEMOLITION</option>
                      <option value='ADDITION'>ADDITION</option>
                      <option value='REPAIR'>REPAIR</option>
                      <option value='ACESSORY BUILDING/STRUCTURE'>
                        ACESSORY BUILDING/STRUCTURE
                      </option>
                      <option value='ALTERATION'>ALTERATION</option>
                      <option value='MOVING'>MOVING</option>
                      <option value='OTHERS/(SPECIFY)'>OTHERS/(SPECIFY)</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md={3}>
                    <h6>Specify</h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.sw_specify}
                      name='sw_specify'
                      onChange={editHandleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Use Type <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      as='select'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.useType}
                      name='useType'
                      onChange={editHandleChange}
                    >
                      <option value=''>Choose here ...</option>
                      <option value='Residential'>Residential</option>
                      <option value='Commercial'>Commercial</option>
                    </Form.Control>
                  </Form.Group>
                </Row>
                <Row className='mx-3 mt-3'>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      No of Storeys <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.noOfStorey}
                      name='noOfStorey'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Floor Area <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.floorArea}
                      name='floorArea'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Compute As <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      as='select'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.computeAs}
                      name='computeAs'
                      onChange={editHandleChange}
                      required
                    >
                      <option value={0}>Choose here ...</option>
                      <option value='Residential'>Residential</option>
                      <option value='Commercial'>Commercial</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      No of Units <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.noOfUnits}
                      name='noOfUnits'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className='mx-3 mt-3'>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Start of Construction <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='date'
                      size='sm'
                      onKeyDown={(e) => e.preventDefault()}
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      max={appInfo.scheduleOfConstructionEnd}
                      value={appInfo.scheduleOfConstructionStart}
                      name='scheduleOfConstructionStart'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      End of Construction <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='date'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                      min={appInfo.scheduleOfConstructionStart}
                      value={appInfo.scheduleOfConstructionEnd}
                      name='scheduleOfConstructionEnd'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>Contractor</h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.contractor}
                      name='contractor'
                      onChange={editHandleChange}
                    />
                  </Form.Group>
                </Row>
                {/*End Scope of Work*/}

                {/* Project Cost */}
                <h5 className='mx-3 mt-3 mb-0'>Project Cost</h5>
                <Row className='mx-3 mt-3'>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Estimate Cost <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={
                        appInfo.estimateCost > 0 ? appInfo.estimateCost : ''
                      }
                      name='estimateCost'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Building (Estimate Cost) <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.ec_building > 0 ? appInfo.ec_building : ''}
                      name='ec_building'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>Electrical (Estimate Cost)</h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      // value={
                      //   appInfo.ec_electrical > 0 ? appInfo.ec_electrical : ''
                      // }
                      name='ec_electrical'
                      onChange={editHandleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>Plumbing (Estimate Cost)</h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.ec_plumbing > 0 ? appInfo.ec_plumbing : ''}
                      name='ec_plumbing'
                      onChange={editHandleChange}
                    />
                  </Form.Group>
                </Row>
                <Row className='mx-3 mt-3'>
                  <Form.Group as={Col} md={3}>
                    <h6>Mechanical (Estimate Cost)</h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={
                        appInfo.ec_mechanical > 0 ? appInfo.ec_mechanical : ''
                      }
                      name='ec_mechanical'
                      onChange={editHandleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>Others (Estimate Cost)</h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.ec_others > 0 ? appInfo.ec_others : ''}
                      name='ec_others'
                      onChange={editHandleChange}
                    />
                  </Form.Group>
                </Row>
                {/* End Project Cost */}

                {/* Residence Certificate */}
                {/* <h5 className='mx-3 mt-3 mb-0'>Residence Certificate</h5>
                <Row className='mx-3 mt-3'>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Number <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.rc_number}
                      name='rc_number'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Date <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='date'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                      value={appInfo.rc_date}
                      name='rc_date'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Place <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.rc_place}
                      name='rc_place'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                </Row> */}
                {/* End Residence Certificate */}

                {/* Site and Lot Information */}
                <h5 className='mx-3 mt-3 mb-0'>SITE AND LOT INFORMATION</h5>
                <Row className='mx-3 mt-3'>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      TCT/CCT NO. <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.TCTNoLI}
                      name='TCTNoLI'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      TAX DEC. NO. <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.TaxDecNoLI}
                      name='TaxDecNoLI'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <h6>
                      LOT OWNER/S <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.LotOwner}
                      name='LotOwner'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className='mx-3 mt-3'>
                  <Form.Group as={Col} md={12}>
                    <h6>
                      Address <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.Address}
                      name='Address'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className='mx-3 mt-3'>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Resident Certificate <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.ResCert}
                      name='ResCert'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3}>
                    <h6>
                      Date Issued <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='date'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                      value={appInfo.DateIssued}
                      name='DateIssued'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <h6>
                      Place Issued <i className='text-danger'>*</i>
                    </h6>
                    <Form.Control
                      type='text'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.PlaceIssued}
                      name='PlaceIssued'
                      onChange={editHandleChange}
                      required
                    />
                  </Form.Group>
                </Row>
                {/* End Site and Lot Information */}

                {/* Action Button */}
                <Row className='text-center my-3 pt-2'>
                  <Col xl={12}>
                    <Button
                      type='submit'
                      size='sm'
                      className='mx-2'
                      variant='info'
                    >
                      {applicationInformation && applicationInformation.error
                        ? 'Submit'
                        : 'Update'}
                    </Button>
                    <Button
                      onClick={() =>
                        history.push(
                          `/application-information/${match.params.id}`
                        )
                      }
                      size='sm'
                      variant='danger'
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                {/* End Action Button */}
              </Form>
            </Col>
          </Row>
        )}

        {applicationInformation && applicationInformation.loading && (
          <div className='text-center pt-3'>
            <Spinner animation='border' />
            <h3>Loading...</h3>
          </div>
        )}
      </Container>

      {/* Success Modal */}
      <SuccessModal
        show={modal.updated}
        onHide={() => {
          setModal({ updated: false })
          dispatch(retrieveApplicationInformation({ id: match.params.id }))
        }}
        transLoading={updatedApplicationInfo && updatedApplicationInfo.loading}
        success={updatedApplicationInfo && updatedApplicationInfo.message}
        error={updatedApplicationInfo && updatedApplicationInfo.error}
      />
      {/* End Success Modal */}

      {/* Success Modal */}
      <SuccessModal
        show={modal.added}
        onHide={() => {
          setModal({ added: false })
          dispatch(retrieveApplicationInformation({ id: match.params.id }))
        }}
        transLoading={
          insertedApplicationInfo && insertedApplicationInfo.loading
        }
        success={insertedApplicationInfo && insertedApplicationInfo.message}
        error={insertedApplicationInfo && insertedApplicationInfo.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(ApplicationInfoEditScreen)
