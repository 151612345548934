import React, { useState, useEffect } from 'react'
import { Container, Row, Col, InputGroup, Button, Form } from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import Loading from '../../reusable/Loading'
import Error from '../../reusable/Error'

// Components
import UserTable from '../../reusable/UserTable'

// Actions
import {
  retrieveUserList,
  updateUserPassword,
  deleteUser,
} from '../../redux/actions/SuperAdmin/superAdminActions'

const UserMaintenanceScreen = () => {
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const [modal, setModal] = useState({
    viewUser: false,
  })

  const superAdminReducer = useSelector((state) => state.superAdminReducer)
  const { retrievedUserList, updatedUserPassword } = superAdminReducer

  const handleResetPassword = (data) => {
    if (
      window.confirm('Are you sure you want to reset password of this user ?')
    ) {
      // console.log(data)
      // console.log('Reset Password')
      dispatch(updateUserPassword({ id: data.id, email: data.EmailAddress }))
    }
  }

  const handleDeleteUser = (data) => {
    if (window.confirm('Are you sure you want to delete this user ?')) {
      // console.log(data)
      // console.log('User Deleted')
      dispatch(deleteUser({ id: data.id, email: data.EmailAddress }))
    }
  }

  // useEffect
  useEffect(() => {
    dispatch(retrieveUserList())
  }, [dispatch])

  return (
    <UserMain>
      <Container fluid>
        {retrievedUserList && retrievedUserList.loading && (
          <Container
            fluid
            className='my-2 shadow-lg pb-5'
            style={{ background: '#FFF' }}
          >
            <div className='pt-5'>
              <Loading />
            </div>
          </Container>
        )}

        {retrievedUserList && retrievedUserList.message && (
          <Container
            fluid
            className='my-2 shadow-lg pb-5'
            style={{ background: '#FFF' }}
          >
            <Form className='pt-4'>
              <Row className='justify-content-end px-3 pt-3'>
                <Col md={4}>
                  <InputGroup>
                    <Form.Control
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={searchTerm}
                      placeholder='Input search keyword here . . .'
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {searchTerm === '' ? (
                      <InputGroup.Text className='bg-info text-light'>
                        <FaSearch />
                      </InputGroup.Text>
                    ) : (
                      <Button
                        variant='danger'
                        onClick={() => setSearchTerm('')}
                      >
                        x
                      </Button>
                    )}
                  </InputGroup>
                </Col>
              </Row>
            </Form>

            <UserTable
              data={
                retrievedUserList &&
                retrievedUserList.message &&
                retrievedUserList.message
              }
              searchTerm={searchTerm}
              handleResetPassword={handleResetPassword}
              handleDeleteUser={handleDeleteUser}
            />
          </Container>
        )}

        {retrievedUserList && retrievedUserList.error && (
          <Container
            fluid
            className='my-2 shadow-lg pb-5'
            style={{ background: '#FFF' }}
          >
            <div className='pt-5'>
              <Error message={retrievedUserList.error} />
            </div>
          </Container>
        )}
      </Container>
    </UserMain>
  )
}

export default UserMaintenanceScreen
