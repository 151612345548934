import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'

// Style
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'

// Revised Actions
import {
  retrieveAllApplications,
  retrieveApplicationInformation,
} from '../../redux/actions/DocumentControllers/documentControllerActions'

const ApplicationInformationScreen = ({ history, match }) => {
  // Declaration
  const [state, setState] = useState({})

  const dispatch = useDispatch()

  const dc = useSelector((state) => state.documentControllerReducer)

  const { allApplications, applicationInformation } = dc
  // End Declaration

  // UseEffect
  useEffect(() => {
    dispatch(retrieveAllApplications())
    dispatch(retrieveApplicationInformation({ id: match.params.id }))
  }, [dispatch, match])

  useEffect(() => {
    if (allApplications && allApplications.message) {
      let params = match.params.id
      const application = allApplications.message.find(
        (app) => app.id === parseInt(params)
      )
      setState(application)
    }
  }, [allApplications, match, state])

  return (
    <UserMain>
      <Container>
        {applicationInformation && applicationInformation.message && (
          <Row className='bg-white mb-3 shadow-lg'>
            <Col xl={12} className='mt-3'>
              <h5 style={{ color: '#1f9bcf' }}>Application Information</h5>
              <hr />

              {/* Applicant Name/s */}
              <h5 className='mt-4 mb-3'>Applicant Name/s</h5>
              {applicationInformation.message.map((info, index) => (
                <Row key={index} className='mx-4 my-2'>
                  <Col xl={3} className='my-1'>
                    <p>Last Name: </p>
                    <h6>{info.lastName}</h6>
                  </Col>
                  <Col xl={3} className='my-1'>
                    <p>First Name: </p>
                    <h6>{info.firstName}</h6>
                  </Col>
                  <Col xl={3} className='my-1'>
                    <p>Middle Name: </p>
                    <h6>{info.middleName}</h6>
                  </Col>
                </Row>
              ))}
              {/* End Applicant Name/s */}

              {/* Construction Site */}
              <h5 className='my-3'>Construction Site</h5>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Street: </p>
                  <h6>{applicationInformation.message[0].ConstStreet}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Area: </p>
                  <h6>{applicationInformation.message[0].ConstArea}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Barangay: </p>
                  <h6>{applicationInformation.message[0].ConstBarangay}</h6>
                </Col>
              </Row>
              {/*End Construction Site */}

              {/* Scope of Work */}
              <h5 className='my-3'>Scope of Work</h5>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Scope of Work: </p>
                  <h6>{applicationInformation.message[0].scopeOfWork}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Specified Scope of Work: </p>
                  <h6>{applicationInformation.message[0].sw_specify}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Use Type: </p>
                  <h6>{applicationInformation.message[0].useType}</h6>
                </Col>
              </Row>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>No. of Storeys: </p>
                  <h6>{applicationInformation.message[0].noOfStoreys}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Floor Area: </p>
                  <h6>{applicationInformation.message[0].floorArea}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Compute As: </p>
                  <h6>{applicationInformation.message[0].computeAs}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>No. of Units: </p>
                  <h6>{applicationInformation.message[0].noOfUnits}</h6>
                </Col>
              </Row>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Schedule of Construction (Start): </p>
                  <h6>
                    {
                      applicationInformation.message[0]
                        .scheduleOfConstructionStart
                    }
                  </h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Schedule of Construction (End): </p>
                  <h6>
                    {
                      applicationInformation.message[0]
                        .scheduleOfConstructionEnd
                    }
                  </h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Contractor: </p>
                  <h6>{applicationInformation.message[0].contractor}</h6>
                </Col>
              </Row>

              <h6 className='mt-4 mx-3'>Project Cost (ESTIMATE COST)</h6>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Estimate Cost: </p>
                  <h6>{applicationInformation.message[0].estimateCost}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Building (Estimate Cost): </p>
                  <h6>{applicationInformation.message[0].ec_building}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Electrical (Estimate Cost): </p>
                  <h6>{applicationInformation.message[0].ec_electrical}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Plumbing (Estimate Cost): </p>
                  <h6>{applicationInformation.message[0].ec_plumbing}</h6>
                </Col>
              </Row>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Mechanical (Estimate Cost): </p>
                  <h6>{applicationInformation.message[0].ec_mechanical}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Others (Estimate Cost): </p>
                  <h6>{applicationInformation.message[0].ec_others}</h6>
                </Col>
              </Row>
              {/* End Scope of Work */}

              {/* Site and Lot Information */}
              <h5 className='my-3'>Site and Lot Information</h5>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>TCT/CCT NO.: </p>
                  <h6>{applicationInformation.message[0].TCTNo}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>TAX DEC. NO.: </p>
                  <h6>{applicationInformation.message[0].TaxDecNo}</h6>
                </Col>
                <Col xl={6}>
                  <p>LOT OWNER/S: </p>
                  <h6>{applicationInformation.message[0].LotOwner}</h6>
                </Col>
              </Row>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>ADDRESS: </p>
                  <h6>{applicationInformation.message[0].Address}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>RESIDENT CERTIFICATE: </p>
                  <h6>{applicationInformation.message[0].ResCert}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>DATE ISSUED: </p>
                  <h6>{applicationInformation.message[0].DateIssued}</h6>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>PLACE ISSUED: </p>
                  <h6>{applicationInformation.message[0].PlaceIssued}</h6>
                </Col>
              </Row>
              {/* End Site and Lot Information */}

              {/* Action Btn */}
              <Row className='text-center pt-5 pb-2'>
                <Col xl={12}>
                  <Button
                    size='sm'
                    onClick={() =>
                      history.push(
                        `/application-add-edit-information/${match.params.id}`
                      )
                    }
                    variant='info'
                    className='mx-2'
                  >
                    Edit
                  </Button>
                  <Button
                    size='sm'
                    onClick={() =>
                      history.push(`/application/${match.params.id}`)
                    }
                    variant='danger'
                  >
                    Back
                  </Button>
                </Col>
              </Row>
              {/*End Action Btn */}
            </Col>
          </Row>
        )}

        {applicationInformation && applicationInformation.error && (
          <Row className='bg-white mb-3'>
            <Col xl={12} className='mt-3'>
              <h5 style={{ color: '#1f9bcf' }}>Application Information</h5>
              <hr />

              {/* Applicant Name/s */}
              <h5 className='mt-4 mb-3'>Applicant Name/s</h5>
              <Row className='mx-4 my-2'>
                <Col xl={3} className='my-1'>
                  <p>Last Name: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>First Name: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Middle Name: </p>
                </Col>
              </Row>
              {/* End Applicant Name/s */}

              {/* Construction Site */}
              <h5 className='my-3'>Construction Site</h5>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Street: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Area: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Barangay: </p>
                </Col>
              </Row>
              {/*End Construction Site */}

              {/* Scope of Work */}
              <h5 className='my-3'>Scope of Work</h5>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Scope of Work: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Specified Scope of Work: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Use Type: </p>
                </Col>
              </Row>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>No. of Storeys: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Floor Area: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Compute As: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>No. of Units: </p>
                </Col>
              </Row>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Schedule of Construction (Start): </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Schedule of Construction (End): </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Contractor: </p>
                </Col>
              </Row>

              <h6 className='mt-4 mx-3'>Project Cost (ESTIMATE COST)</h6>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Estimate Cost: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Building (Estimate Cost): </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Electrical (Estimate Cost): </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Plumbing (Estimate Cost): </p>
                </Col>
              </Row>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Mechanical (Estimate Cost): </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Others (Estimate Cost): </p>
                </Col>
              </Row>

              <h6 className='mt-4 mx-3'>Residence Certificate</h6>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Number: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Date: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Place: </p>
                </Col>
              </Row>
              {/* End Scope of Work */}

              {/* Site and Lot Information */}
              <h5 className='my-3'>Site and Lot Information</h5>
              <h6 className='mt-4 mx-3'>Construction Site</h6>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>Street: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Area: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>Barangay: </p>
                </Col>
              </Row>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>TCT/CCT NO.: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>TAX DEC. NO.: </p>
                </Col>
                <Col xl={6}>
                  <p>LOT OWNER/S: </p>
                </Col>
              </Row>
              <Row className='mx-4'>
                <Col xl={3} className='my-1'>
                  <p>ADDRESS: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>RESIDENT CERTIFICATE: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>DATE ISSUED: </p>
                </Col>
                <Col xl={3} className='my-1'>
                  <p>PLACE ISSUED: </p>
                </Col>
              </Row>
              {/* End Site and Lot Information */}

              <Row className='text-center py-3'>
                <Col xl={12}>
                  <Button
                    onClick={() =>
                      history.push(
                        `/application-add-edit-information/${match.params.id}`
                      )
                    }
                    size='sm'
                    variant='info'
                    className='mx-2'
                  >
                    Add
                  </Button>
                  <Button
                    size='sm'
                    onClick={() =>
                      history.push(`/application/${match.params.id}`)
                    }
                    variant='danger'
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {applicationInformation && applicationInformation.loading && (
          <div className='text-center pt-3'>
            <Spinner animation='border' />
            <h3>Loading...</h3>
          </div>
        )}
      </Container>
    </UserMain>
  )
}

export default withRouter(ApplicationInformationScreen)
