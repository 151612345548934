import React from 'react'
import { Nav, Navbar, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import { FaUserCircle } from 'react-icons/fa'

const GuestHeader = () => {
  const RefreshPage = () => {
    setInterval(() => {
      window.location.reload()
    }, 10)
  }
  return (
    <header>
      <Navbar expand='lg' collapseOnSelect className='py-3'>
        <Container fluid>
          <img
            alt=''
            src='assets/logo/Ootb-logo.webp'
            width='70'
            height='65'
            className='k mr-1'
          />
          <LinkContainer to='/'>
            <Navbar.Brand className='d-none d-xl-block'>
              Office of the Building Official
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse
            id='basic-navbar-nav'
            // className='d-flex justify-content-end'
          >
            <Nav className='me-auto'></Nav>
            <Nav>
              <LinkContainer className='mx-2' to='/contact-us'>
                <Nav.Link>Contact Us</Nav.Link>
              </LinkContainer>
              <LinkContainer className='mx-2' to='/downloadable-forms'>
                <Nav.Link>Downloadable Forms</Nav.Link>
              </LinkContainer>
              {/* <LinkContainer className='mx-2' to='/application-list'>
                <Nav.Link>Applications</Nav.Link>
              </LinkContainer> */}
              <LinkContainer className='mx-2' to='/permit-verification'>
                <Nav.Link>Verification</Nav.Link>
              </LinkContainer>
              <LinkContainer className='mx-2' to='/login'>
                <Nav.Link>
                  <FaUserCircle className='mr-2' />
                  Sign in
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default GuestHeader
