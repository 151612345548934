import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProtectedRoute from '../reusable/ProtectedRoute'

// Components
import ICTDashboardScreen from '../screens/ICTSuperAdmin/ICTDashboardScreen'
import ExpiredTokenScreen from '../screens/ExpiredTokenScreen'
import VerifyUserScreen from '../screens/Administrator/VerifyUserScreen'
import CreateAccountScreen from '../screens/Administrator/CreateAccountScreen'
import UserMaintenanceScreen from '../screens/ICTSuperAdmin/UserMaintenanceScreen'
import NovReportScreen from '../screens/ICTSuperAdmin/NovReportScreen'
import ApplicationReportScreen from '../screens/ICTSuperAdmin/ApplicationReportScreen'
import NewsNUpdateScreen from '../screens/ICTSuperAdmin/NewsNUpdateScreen'
import ReportScreen from '../screens/DcScreens/ReportScreen'
import AnnualReportScreen from '../screens/Administrator/AnnualReportScreen'

const ICTRoutes = ({ user }) => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path='/dashboard'
        component={ICTDashboardScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/verify-user'
        component={VerifyUserScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/employee-maintenance'
        component={CreateAccountScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/user-maintenance'
        component={UserMaintenanceScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/nov-report'
        component={NovReportScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/application-report'
        component={ReportScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/news-updates-list'
        component={NewsNUpdateScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/annual-report'
        component={AnnualReportScreen}
        isAuth={user === null ? false : true}
      />

      <Route component={ExpiredTokenScreen} />
    </Switch>
  )
}

export default ICTRoutes
