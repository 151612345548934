import Cookie from 'js-cookie'
import axios from 'axios'
import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILED,
  GET_ENGRPROFILE_REQUEST,
  GET_ENGRPROFILE_SUCCESS,
  GET_ENGRPROFILE_FAILED,
  GET_REGION_REQ,
  GET_REGION_SUCCESS,
  GET_REGION_FAILED,
  GET_CITIES_REQ,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILED,
  GET_BARANGAY_REQ,
  GET_BARANGAY_SUCCESS,
  GET_BARANGAY_FAILED,
  USER_UPDATE_BASICINFO_REQ,
  USER_UPDATE_BASICINFO_SUCCESS,
  USER_UPDATE_BASICINFO_FAILED,
  USER_UPDATE_PERADDRESS_REQ,
  USER_UPDATE_PERADDRESS_SUCCESS,
  USER_UPDATE_PERADDRESS_FAILED,
  USER_UPDATE_PREADDRESS_REQ,
  USER_UPDATE_PREADDRESS_SUCCESS,
  USER_UPDATE_PREADDRESS_FAILED,
  USER_UPDATE_PROFESSIONINFO_REQ,
  USER_UPDATE_PROFESSIONINFO_SUCCESS,
  USER_UPDATE_PROFESSIONINFO_FAILED,
  USER_UPDATE_USERCREDENTIALS_REQ,
  USER_UPDATE_USERCREDENTIALS_SUCCESS,
  USER_UPDATE_USERCREDENTIALS_FAILED,
  USER_DELETE_USER_REQ,
  USER_DELETE_USER_SUCCESS,
  USER_DELETE_USER_FAILED,
  USER_ACCOUNT_VER_REQ,
  USER_ACCOUNT_VER_SUCCESS,
  USER_ACCOUNT_VER_FAILED,
  UPDATE_ENGRPROFINFO_REQ,
  UPDATE_ENGRPROFINFO_SUCCESS,
  UPDATE_ENGRPROFINFO_FAILED,
  GET_ENGATTACHMENTS_REQ,
  GET_ENGATTACHMENTS_SUCCESS,
  GET_ENGATTACHMENTS_FAILED,
} from '../constants/userProfileConstants'

export const getUserProfile = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_PROFILE_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/profile`,
        payload,
        config
      )

      dispatch({
        type: USER_PROFILE_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: USER_PROFILE_FAILED,
        payload: 'No Details Found',
      })
    }
  }
}

export const retrieveEngrProfile = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ENGRPROFILE_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/profile`,
        config
      )

      dispatch({
        type: GET_ENGRPROFILE_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_ENGRPROFILE_FAILED,
        payload: 'No Details Found',
      })
    }
  }
}

export const retrieveRegion = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_REGION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      // const { data } = await axios.get(`/regions`)

      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/address`,
        params: { extensions: payload.extensions },
        withCredentials: false,
      })

      dispatch({
        type: GET_REGION_SUCCESS,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: GET_REGION_FAILED,
        payload: 'No Details Found',
      })
    }
  }
}

export const retrieveEngAttachments = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ENGATTACHMENTS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/attachments-info`,
        config
      )

      dispatch({
        type: GET_ENGATTACHMENTS_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_ENGATTACHMENTS_FAILED,
        payload: 'Unable to Retrieved Attachments',
      })
    }
  }
}

export const updateUserBasicInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_BASICINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/update/basic-info`,
        payload,
        config
      )

      dispatch({
        type: USER_UPDATE_BASICINFO_SUCCESS,
        payload: data.message,
      })

      dispatch(getUserProfile({ id: payload.userID }))
    } catch (err) {
      dispatch({
        type: USER_UPDATE_BASICINFO_FAILED,
        payload: 'Unable to Update Basic Info',
      })
    }
  }
}

export const updateUserPerAddress = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_PERADDRESS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/update/permanent-address`,
        payload,
        config
      )

      dispatch({
        type: USER_UPDATE_PERADDRESS_SUCCESS,
        payload: data.message,
      })
      dispatch(getUserProfile({ id: payload.userID }))
    } catch (err) {
      dispatch({
        type: USER_UPDATE_PERADDRESS_FAILED,
        payload: 'Unable to Update Permanent Address',
      })
    }
  }
}

export const updateUserPreAddress = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_PREADDRESS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/update/present-address`,
        payload,
        config
      )

      dispatch({
        type: USER_UPDATE_PREADDRESS_SUCCESS,
        payload: data.message,
      })

      dispatch(getUserProfile({ id: payload.userID }))
    } catch (err) {
      dispatch({
        type: USER_UPDATE_PREADDRESS_FAILED,
        payload: 'Unable to Update Permanent Address',
      })
    }
  }
}

export const updateUserProfessionInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_PROFESSIONINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/update/professsional-info`,
        payload,
        config
      )

      dispatch({
        type: USER_UPDATE_PROFESSIONINFO_SUCCESS,
        payload: data.message,
      })
      dispatch(getUserProfile({ id: payload.userID }))
    } catch (err) {
      dispatch({
        type: USER_UPDATE_PROFESSIONINFO_FAILED,
        payload: 'Unable to Update Professional Info',
      })
    }
  }
}

export const updateUserCredentials = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_USERCREDENTIALS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/update/user-credentials`,
        payload,
        config
      )

      dispatch({
        type: USER_UPDATE_USERCREDENTIALS_SUCCESS,
        payload: data.message,
      })
      // dispatch(getUserProfile({ id: payload.userID }))
    } catch (err) {
      // console.log(err)
      dispatch({
        type: USER_UPDATE_USERCREDENTIALS_FAILED,
        payload: err.response && err.response.data.message,
      })
    }
  }
}

export const deleteUserAccount = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_DELETE_USER_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/delete/user-account`,
        payload,
        config
      )

      dispatch({
        type: USER_DELETE_USER_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: USER_DELETE_USER_FAILED,
        payload: 'Unable to Delete Account',
      })
    }
  }
}

// Verifying Account
export const accountVerification = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_ACCOUNT_VER_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/register`,
        payload,
        config
      )

      dispatch({
        type: USER_ACCOUNT_VER_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: USER_ACCOUNT_VER_FAILED,
        payload: 'Failed',
      })
    }
  }
}

// RUD Engineer
export const updateEngProfessionInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ENGRPROFINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/update/engr-prof-info`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_ENGRPROFINFO_SUCCESS,
        payload: data.message,
      })
      dispatch(retrieveEngrProfile({ userID: payload.userID }))
      dispatch(getUserProfile({ userID: payload.userID }))
      dispatch(retrieveEngAttachments({ userID: payload.userID }))
    } catch (err) {
      dispatch({
        type: UPDATE_ENGRPROFINFO_FAILED,
        payload: 'Unable to Update Professional Info',
      })
    }
  }
}
