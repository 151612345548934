import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Spinner,
} from 'react-bootstrap'
import { FaCheckCircle, FaExclamationCircle, FaUserAlt } from 'react-icons/fa'
import _ from 'lodash'
import io from 'socket.io-client'
import { useToast } from 'rc-toastr'

import { withRouter } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { categoriesRetrive } from '../../redux/actions/categoriesActions'
import { retrieveEngrList } from '../../redux/actions/userApplicationActions'
import { insertWalkinApplication } from '../../redux/actions/DocumentControllers/documentControllerActions'

// Components
import UserMain from '../../layout/UserLayout/UserMain'

const socket = io.connect(process.env.REACT_APP_URL)

const DCCategoryScreen = () => {
  const dispatch = useDispatch()
  const categories = useSelector((state) => state.categories)
  const { categoriesDetail } = categories

  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const userApplication = useSelector((state) => state.applications)
  const { retrievedEngrList } = userApplication

  const documentControllerReducer = useSelector(
    (state) => state.documentControllerReducer
  )

  const { insertedApplication } = documentControllerReducer

  const [InitialState, setInitialState] = useState({
    userID: userCredentials.id,
    ApplicationName: '',
    Link: 'WALK-IN APPLICATION',
    ModeofForms: '',
    Structural: 0,
    Mechanical: 0,
    Architectural: 0,
    Sanitary: 0,
    Electrical: 0,
    Plumbing_Sanitary: 0,
    Electronics: 0,
    BpNo: '',
    applicantName: '',
    mobileNo: '',
    emailAddress: '',
  })

  const [modalShow, setModalShow] = useState({
    show: false,
    successShow: false,
    warning: false,
    isWebApp: false,
    isDownloadableForm: false,
    isProfession: false,
    notRegistered: false,
  })

  const [isTrade, setIsTrade] = useState('')

  const [engineerDetails, setEngineerDetails] = useState({
    trade: '',
    inChargeName: '',
    inChargeType: '',
    inChargeProfession: '',
    inChargePrcNo: '',
    inChargePrcValidity: '',
    inChargePtrNo: '',
    inChargePtrValidity: '',
    inChargeID: '',
    designName: '',
    designProfession: '',
    designType: '',
    designPrcNo: '',
    designPrcValidity: '',
    designPtrNo: '',
    designPtrValidity: '',
    designChargeID: '',
  })

  const [dataList, setDataList] = useState([])

  const [recordEngineer, setRecordEngineer] = useState([])

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      if (event.target.checked) {
        setInitialState({
          ...InitialState,
          [event.target.name]: 1,
        })

        setIsTrade(event.target.name)

        // setEngineerDetails({
        //   ...engineerDetails,
        //   trade: event.target.name,
        //   inChargeType: `Supervisor in-charge of ${event.target.name} works`,
        //   designType: 'Design professional, plans, and specification',
        // })

        // setModalShow({
        //   ...modalShow,
        //   isProfession: true,
        //   isDownloadableForm: false,
        // })
      } else {
        setInitialState({
          ...InitialState,
          [event.target.name]: 0,
        })

        // let filtered = recordEngineer.filter(
        //   (e) => e.trade !== event.target.name
        // )
        // setRecordEngineer(filtered)
      }
    } else {
      setInitialState({
        ...InitialState,
        [event.target.name]: event.target.value,
      })
    }
  }

  const handleShowModal = (permit) => {
    setModalShow({
      ...modalShow,
      show: true,
    })
    setInitialState({
      ...InitialState,
      ApplicationName: permit,
    })
  }

  const handleCheckDlForm = () => {
    if (InitialState.ModeofForms !== '') {
      if (InitialState.ModeofForms === 'Downloadable Forms') {
        setInitialState({
          ...InitialState,
          isDownloadableForm: true,
        })
        setModalShow({ ...modalShow, isDownloadableForm: true, show: false })
      } else {
        setModalShow({
          ...modalShow,
          show: false,
          warning: true,
        })
        // history.push({
        //   pathname: '/building-permit',
        //   state: {
        //     InitialState,
        //   },
        // })
      }
    }
  }

  const handleWebAppBack = () => {
    setModalShow({ ...modalShow, show: true, warning: false })
  }

  // const handleWebAppSubmit = () => {
  //   setInitialState({
  //     ...InitialState,
  //   })
  //   dispatch(userApplicationInsert(InitialState))
  //   history.push({
  //     pathname: '/building-permit',
  //     state: {
  //       InitialState,
  //     },
  //   })
  // }

  const handleSubmit = (event) => {
    event.preventDefault()
    dispatch(
      insertWalkinApplication({
        ApplicationName: InitialState.ApplicationName,
        Link: InitialState.Link,
        ModeofForms: InitialState.ModeofForms,
        userID: InitialState.userID,
        BpNo: InitialState.BpNo,
        Structural: InitialState.Structural,
        Mechanical: InitialState.Mechanical,
        Architectural: InitialState.Architectural,
        Sanitary: InitialState.Sanitary,
        Electrical: InitialState.Electrical,
        Plumbing_Sanitary: InitialState.Plumbing_Sanitary,
        Electronics: InitialState.Electronics,
        applicantName: InitialState.applicantName,
        recordEngineer: [],
        MobileNo: InitialState.mobileNo,
        EmailAddress: InitialState.emailAddress,
      })
    )
    setModalShow({
      ...modalShow,
      show: false,
      isDownloadableForm: false,
      successShow: true,
    })

    setInitialState({
      ...InitialState,
      ModeofForms: '',
      Structural: 0,
      Mechanical: 0,
      Architectural: 0,
      Sanitary: 0,
      Electrical: 0,
      Plumbing_Sanitary: 0,
      Electronics: 0,
      BpNo: '',
      applicantName: '',
      mobileNo: '',
      emailAddress: '',
    })

    setRecordEngineer([])

    setEngineerDetails({
      trade: '',
      inChargeName: '',
      inChargeType: '',
      inChargeProfession: '',
      inChargePrcNo: '',
      inChargePrcValidity: '',
      inChargePtrNo: '',
      inChargePtrValidity: '',
      inChargeID: '',
      designName: '',
      designProfession: '',
      designType: '',
      designPrcNo: '',
      designPrcValidity: '',
      designPtrNo: '',
      designPtrValidity: '',
      designChargeID: '',
    })

    setIsTrade('')
  }

  const onChange = (e) => {
    setEngineerDetails({
      ...engineerDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleOnClick = (data) => {
    setEngineerDetails({
      ...engineerDetails,
      inChargeName: data.Name,
      inChargeProfession: data.ProfessionName,
      inChargePrcNo: data.prcNo,
      inChargePrcValidity: data.prcValidity,
      inChargePtrNo: data.ptrNo,
      inChargePtrValidity: data.ptrValidity,
      inChargeID: data.id,
    })
  }

  const handleOnClickDesign = (data) => {
    setEngineerDetails({
      ...engineerDetails,
      designName: data.Name,
      designProfession: data.ProfessionName,
      designPrcNo: data.prcNo,
      designPrcValidity: data.prcValidity,
      designPtrNo: data.ptrNo,
      designPtrValidity: data.ptrValidity,
      designChargeID: data.id,
    })
  }

  const handleSubmitEngrDetails = (e) => {
    e.preventDefault()
    let index = recordEngineer.findIndex((value) => value.trade === isTrade)

    if (index < 0) {
      let tempValue = recordEngineer

      tempValue.push(engineerDetails)

      setModalShow({
        ...modalShow,
        isProfession: false,
        notRegistered: false,
        isDownloadableForm: true,
      })

      setEngineerDetails({
        trade: '',
        inChargeName: '',
        inChargeType: '',
        inChargeProfession: '',
        inChargePrcNo: '',
        inChargePrcValidity: '',
        inChargePtrNo: '',
        inChargePtrValidity: '',
        inChargeID: '',
        designName: '',
        designProfession: '',
        designType: '',
        designPrcNo: '',
        designPrcValidity: '',
        designPtrNo: '',
        designPtrValidity: '',
        designChargeID: '',
      })
    }
  }

  const renderApplicationForm = (applicationName) => {
    switch (applicationName) {
      case 'OCCUPANCY PERMIT':
        return (
          <>
            {userCredentials.roles !== 'user' ? (
              <h5>Remarks</h5>
            ) : (
              <h5>
                Link <i className='text-danger'>*</i>
              </h5>
            )}

            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='Link'
                  value={InitialState.Link}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  disabled
                />
              </Form.Group>
            </Row>

            <div className='d-flex mt-2'>
              <h5>
                Applicant Name <i className='text-danger mx-2'>*</i>
              </h5>
              <p className='m-0'>(Last Name, First Name Middle Name)</p>
            </div>

            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='applicantName'
                  value={InitialState.applicantName}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  placeholder='input applicant name here ... (Last Name, First Name Middle Name)'
                  onChange={(event) => {
                    setInitialState({
                      ...InitialState,
                      [event.target.name]: event.target.value,
                      Architectural: 1,
                    })
                  }}
                  required
                />
              </Form.Group>
            </Row>
            <div className='mt-3' style={{ background: '#f9f6f1' }}>
              <h6 className='pt-2'>Note: </h6>
              <i className='fs-6 text-dark'>
                Kindly make sure that the requirements indicated on the
                checklist of application are included in the link you are going
                to attach in this submission.
              </i>
            </div>
          </>
        )
      case 'FENCING PERMIT':
        return (
          <>
            {/* <h5>List of registered engineer & architect</h5>

            <p className='p-0 m-0'>SUPERVISOR / IN-CHARGE</p>

            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='inChargeName'
                  onChange={onChange}
                  value={engineerDetails.inChargeName}
                  autoComplete='off'
                  required
                  size='sm'
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.inChargeName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => {
                            recordEngineer.push({
                              inChargeName: data.Name,
                              inChargeProfession: data.ProfessionName,
                              inChargePrcNo: data.prcNo,
                              inChargePrcValidity: data.prcValidity,
                              inChargePtrNo: data.ptrNo,
                              inChargePtrValidity: data.ptrValidity,
                              inChargeID: data.id,
                              trade: 'Architectural',
                              inChargeType: `Supervisor in-charge of Architectural works`,
                            })
                            setEngineerDetails({
                              ...engineerDetails,
                              inChargeName: data.Name,
                              inChargeProfession: data.ProfessionName,
                              inChargePrcNo: data.prcNo,
                              inChargePrcValidity: data.prcValidity,
                              inChargePtrNo: data.ptrNo,
                              inChargePtrValidity: data.ptrValidity,
                              inChargeID: data.id,
                              trade: 'Architectural',
                              inChargeType: `Supervisor in-charge of Architectural works`,
                            })

                            setInitialState({
                              ...InitialState,
                              Architectural: 1,
                            })
                          }}
                        >
                          {data.Name}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargeProfession}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePrcNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePtrNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row> */}

            {userCredentials.roles !== 'user' ? (
              <h5>Remarks</h5>
            ) : (
              <h5>
                Link <i className='text-danger'>*</i>
              </h5>
            )}

            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='Link'
                  value={InitialState.Link}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  disabled
                />
              </Form.Group>
            </Row>

            <div className='d-flex mt-2'>
              <h5>
                Applicant Name <i className='text-danger mx-2'>*</i>
              </h5>
              <p className='m-0'>(Last Name, First Name Middle Name)</p>
            </div>
            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='applicantName'
                  value={InitialState.applicantName}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={(event) => {
                    setInitialState({
                      ...InitialState,
                      [event.target.name]: event.target.value,
                      Architectural: 1,
                    })
                  }}
                  placeholder='input applicant name here ... (Last Name, First Name Middle Name)'
                  required
                />
              </Form.Group>
            </Row>
            <div className='mt-3' style={{ background: '#f9f6f1' }}>
              <h6 className='pt-2'>Note: </h6>
              <i className='fs-6 text-dark'>
                Kindly make sure that the requirements indicated on the
                checklist of application are included in the link you are going
                to attach in this submission.
              </i>
            </div>
          </>
        )
      case 'DEMOLITION PERMIT':
        return (
          <>
            {userCredentials.roles !== 'user' ? (
              <h5>Remarks</h5>
            ) : (
              <h5>
                Link <i className='text-danger'>*</i>
              </h5>
            )}

            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='Link'
                  value={InitialState.Link}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  disabled
                />
              </Form.Group>
            </Row>
            <div className='d-flex mt-2'>
              <h5>
                Applicant Name <i className='text-danger mx-2'>*</i>
              </h5>
              <p className='m-0'>(Last Name, First Name Middle Name)</p>
            </div>
            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='applicantName'
                  value={InitialState.applicantName}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={(event) => {
                    setInitialState({
                      ...InitialState,
                      [event.target.name]: event.target.value,
                      Architectural: 1,
                    })
                  }}
                  placeholder='input applicant name here ... (Last Name, First Name Middle Name)'
                  required
                />
              </Form.Group>
            </Row>
            <div className='mt-3' style={{ background: '#f9f6f1' }}>
              <h6 className='pt-2'>Note: </h6>
              <i className='fs-6 text-dark'>
                Kindly make sure that the requirements indicated on the
                checklist of application are included in the link you are going
                to attach in this submission.
              </i>
            </div>
          </>
        )
      case 'ELECTRICAL PERMIT':
        return (
          <>
            {/* <div className='d-flex justify-content-end'>
              <Form.Check
                type='checkbox'
                label='Click to tag registered engineer'
                name='Electrical'
                onChange={handleChange}
                checked={InitialState.Electrical === 1 && true}
                required
              />
            </div> */}

            {userCredentials.roles !== 'user' ? (
              <h5>Remarks</h5>
            ) : (
              <h5>
                Link <i className='text-danger'>*</i>
              </h5>
            )}

            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='Link'
                  value={InitialState.Link}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  disabled
                />
              </Form.Group>
            </Row>

            <div className='d-flex mt-2'>
              <h5>
                Applicant Name <i className='text-danger mx-2'>*</i>
              </h5>
              <p className='m-0'>(Last Name, First Name Middle Name)</p>
            </div>
            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='applicantName'
                  value={InitialState.applicantName}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={(event) => {
                    setInitialState({
                      ...InitialState,
                      [event.target.name]: event.target.value,
                      Electrical: 1,
                    })
                  }}
                  placeholder='input applicant name here ... (Last Name, First Name Middle Name)'
                  required
                />
              </Form.Group>
            </Row>
            <div className='mt-3' style={{ background: '#f9f6f1' }}>
              <h6 className='pt-2'>Note: </h6>
              <i className='fs-6 text-dark'>
                Kindly make sure that the requirements indicated on the
                checklist of application are included in the link you are going
                to attach in this submission.
              </i>
            </div>
          </>
        )
      default:
        return (
          <>
            <h5>ATTACHED PERMIT TRADES</h5>
            <p>Click the trades that include to your files</p>
            <Row>
              {_.zip(
                [
                  'STRUCTURAL',
                  'MECHANICAL',
                  'ARCHITECTURAL',
                  'PLUMBING/SANITARY',
                  'ELECTRICAL',
                  'ELECTRONICS',
                ],
                [
                  'Structural',
                  'Mechanical',
                  'Architectural',
                  'Plumbing_Sanitary',
                  'Electrical',
                  'Electronics',
                ]
              ).map((trades) => (
                <Form.Group
                  className='my-2'
                  as={Col}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={3}
                  key={trades[0]}
                >
                  <Form.Check
                    type='checkbox'
                    label={trades[0]}
                    name={trades[1]}
                    onChange={handleChange}
                    checked={InitialState[`${trades[1]}`] === 1 && true}
                  />
                </Form.Group>
              ))}
            </Row>
            <hr />

            {userCredentials.roles !== 'user' ? (
              <h6>Remarks</h6>
            ) : (
              <h6>
                Link <i className='text-danger'>*</i>
              </h6>
            )}

            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='Link'
                  value={InitialState.Link}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  disabled
                  size='sm'
                />
              </Form.Group>
            </Row>

            <div className='d-flex mt-2'>
              <h6>
                Applicant Name <i className='text-danger mx-2'>*</i>
              </h6>
              <p className='m-0'>(Last Name, First Name Middle Name)</p>
            </div>

            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='applicantName'
                  value={InitialState.applicantName}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={handleChange}
                  placeholder='input applicant name here ... (Last Name, First Name Middle Name)'
                  required
                  size='sm'
                />
              </Form.Group>
            </Row>

            {/* <Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className='mt-2 mb-0'>
                  <h6>
                    Mobile No<i className='text-danger mx-2'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='mobileNo'
                  value={InitialState.mobileNo}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={handleChange}
                  placeholder='input applicant mobile # here ...'
                  required
                  size='sm'
                />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label className='mt-2 mb-0'>
                  <h6>
                    Email Address<i className='text-danger mx-2'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='emailAddress'
                  value={InitialState.emailAddress}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={handleChange}
                  placeholder='input applicant email address here ...'
                  required
                  size='sm'
                />
              </Form.Group>
            </Row> */}
            <div className='mt-3' style={{ background: '#f9f6f1' }}>
              <h6 className='pt-2'>Note: </h6>
              <i className='fs-6 text-dark'>
                Kindly make sure that the requirements indicated on the
                checklist of application are included in the link you are going
                to attach in this submission.
              </i>
            </div>
          </>
        )
    }
  }

  const { toast } = useToast()

  const sayHello = (data) => {
    toast.success(data)
  }

  useEffect(() => {
    dispatch(categoriesRetrive())
    dispatch(retrieveEngrList({ name: '' }))
    socket.on('received_new_application', (data) => {
      // if (window.confirm(`${data.message} do you want to reload the page ?`)) {
      sayHello(data.message)
      // }
    })
  }, [dispatch, socket])

  useEffect(() => {
    if (retrievedEngrList && retrievedEngrList.message) {
      setDataList(retrievedEngrList.message)
    }
    if (insertedApplication && insertedApplication.message) {
      socket.emit('send_new_application', {
        message: 'New Application Created',
      })
    }
  }, [retrievedEngrList, recordEngineer, insertedApplication])

  // console.log(InitialState)
  return (
    <UserMain>
      <Container>
        <Container
          fluid
          className='my-2 shadow-lg pb-5 rounded'
          style={{ background: '#FFF' }}
        >
          <Row className='text-center py-3'>
            <Col xl={12}>
              <h3>Our list of Permit</h3>
              <div className='d-flex justify-content-center'>
                <hr
                  style={{
                    color: '#000',
                    width: '200px',
                  }}
                />
              </div>
              <p>"Choose desired permit to apply for"</p>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Row className='justify-content-center'>
                {categoriesDetail &&
                  categoriesDetail.map((category) => (
                    <Col
                      xl={4}
                      key={category.id}
                      className='py-2'
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Card
                        border='success'
                        style={{
                          width: '18rem',
                        }}
                        className=' shadow-sm'
                      >
                        <Card.Header
                          style={{ background: '#28a745', color: '#FFF' }}
                          className='py-3'
                        >
                          {category.PermitName}
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>{category.PermitDescription}</Card.Text>
                        </Card.Body>
                        <Card.Footer className='bg-white'>
                          <Row>
                            <Col xl={12} className='d-flex justify-content-end'>
                              {/* <Button
                            variant='success'
                            onClick={() => history.push(`${category.url}`)}
                          > */}
                              <Button
                                variant='success'
                                onClick={() =>
                                  handleShowModal(category.PermitName)
                                }
                              >
                                Apply
                              </Button>
                            </Col>
                          </Row>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* <ModalBuilding show={show} onHide={() => setShow(false)} centered /> */}
      <Modal
        size='lg'
        show={modalShow.show}
        onHide={() => setModalShow({ ...modalShow, show: false })}
        centered
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            {InitialState.ApplicationName}
          </Modal.Title>
        </Modal.Header>
        {InitialState.ApplicationName === 'OCCUPANCY PERMIT' ? (
          <>
            <Modal.Body>
              Building Permit No. <i className='text-danger'>*</i>
              <Row className='mb-2'>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    type='text'
                    className='mt-2'
                    name='BpNo'
                    placeholder='Input building permit no. here ...'
                    value={InitialState.BpNo}
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              Please choose what kind of forms you are going to use for your
              application
              <Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    value={InitialState.ModeofForms}
                    as='select'
                    className='mt-2'
                    name='ModeofForms'
                    onChange={handleChange}
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                  >
                    <option value=''>Choose...</option>
                    {['Downloadable Forms', 'Website Application Forms'].map(
                      (x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              {InitialState.BpNo === '' ? (
                <Button variant='info' disabled>
                  Next
                </Button>
              ) : (
                <Button variant='info' onClick={() => handleCheckDlForm()}>
                  Next
                </Button>
              )}
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              Please choose what kind of forms you are going to use for your
              application
              <Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    value={InitialState.ModeofForms}
                    as='select'
                    className='mt-2'
                    name='ModeofForms'
                    onChange={handleChange}
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                  >
                    <option value=''>Choose...</option>
                    {['Downloadable Forms', 'Website Application Forms'].map(
                      (x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              <Button variant='info' onClick={() => handleCheckDlForm()}>
                Next
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>

      <Modal
        show={modalShow.successShow}
        onHide={() => setModalShow({ ...modalShow, successShow: false })}
        centered
      >
        {insertedApplication && insertedApplication.message ? (
          <>
            <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheckCircle className='mx-3' />
                Transaction Successfully Completed
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className='text-center'>{insertedApplication.message}</h6>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              <Button
                variant='info'
                onClick={() =>
                  setModalShow({ ...modalShow, successShow: false })
                }
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : insertedApplication && insertedApplication.error ? (
          <>
            <Modal.Header style={{ background: '#d9534f', color: '#FFF' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheckCircle className='mx-3' />
                Transaction Failed
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className='text-center'>{insertedApplication.error}</h6>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              <Button
                variant='info'
                onClick={() =>
                  setModalShow({ ...modalShow, successShow: false })
                }
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : (
          insertedApplication && (
            <>
              <div className='text-center pt-3'>
                <Spinner animation='border' />
                <h3>Loading...</h3>
              </div>
            </>
          )
        )}
      </Modal>

      <Modal
        show={modalShow.warning}
        onHide={() => setModalShow({ ...modalShow, warning: false })}
        centered
      >
        <Modal.Header style={{ background: '#1f9bcf', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaExclamationCircle className='mx-3' />
            Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Sorry for the inconvinience, this Feature will be available soon.
          </h5>
          {/* <Row>
            <Col md={12} className='text-center'>
              Web App Forms needs to fill out forms in the website to fill the
              permit
            </Col>
          </Row>
          <Row className='my-2'>
            <Col md={12} className='text-center'>
              <p>
                <i className='fw-bold mx-2'>Note:</i>
                by clicking submit button it will insert the application into
                yuor drafts, until you are not finish with the application.
              </p>
            </Col>
          </Row> */}
        </Modal.Body>
        <Modal.Footer className='p-1'>
          <Button variant='danger' onClick={handleWebAppBack}>
            Back
          </Button>
          {/* <Button variant='info' onClick={handleWebAppSubmit}>
            Submit
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        size='xl'
        show={modalShow.isDownloadableForm}
        onHide={() => setModalShow({ ...modalShow, isDownloadableForm: false })}
        centered
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            {InitialState.ApplicationName}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {renderApplicationForm(InitialState.ApplicationName)}
          </Modal.Body>
          <Modal.Footer className='p-1'>
            <Button variant='info' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setModalShow({
                  ...modalShow,
                  isDownloadableForm: false,
                  show: true,
                })

                setInitialState({
                  ...InitialState,
                  ModeofForms: '',
                  Structural: 0,
                  Mechanical: 0,
                  Architectural: 0,
                  Sanitary: 0,
                  Electrical: 0,
                  Plumbing_Sanitary: 0,
                  Electronics: 0,
                  BpNo: '',
                  mobileNo: '',
                  emailAddress: '',
                  applicantName: '',
                })

                setRecordEngineer([])

                setIsTrade('')
              }}
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* List of Registered Engineer */}
      <Modal
        size='md'
        show={modalShow.isProfession}
        onHide={() => setModalShow({ ...modalShow, isProfession: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            {isTrade} Professional
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitEngrDetails}>
          <Modal.Body className='pb-1'>
            <h5>List of registered engineer & architect</h5>

            <p className='p-0 m-0'>SUPERVISOR / IN-CHARGE</p>

            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='inChargeName'
                  onChange={onChange}
                  value={engineerDetails.inChargeName}
                  autoComplete='off'
                  required
                  size='sm'
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.inChargeName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClick(data)}
                        >
                          {data.Name}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargeProfession}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePrcNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePtrNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <p className='p-0 m-0 mt-3'>
              DESIGN PROFESSIONAL, PLANS AND SPECIFICATION
            </p>
            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>

                <Form.Control
                  type='text'
                  name='designName'
                  onChange={onChange}
                  value={engineerDetails.designName}
                  autoComplete='off'
                  required
                  size='sm'
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.designName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClickDesign(data)}
                        >
                          {data.Name}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designProfession}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designPrcNo}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designPtrNo}
                  required
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer className='p-1'>
            <Button
              variant='success'
              type='button'
              size='sm'
              onClick={() => {
                setModalShow({
                  ...modalShow,
                  isProfession: false,
                  notRegistered: true,
                })
                setEngineerDetails({
                  ...engineerDetails,
                  inChargeName: '',
                  inChargeProfession: '',
                  inChargePrcNo: '',
                  inChargePrcValidity: '',
                  inChargePtrNo: '',
                  inChargePtrValidity: '',
                  inChargeID: 0,
                  designName: '',
                  designProfession: '',
                  designPrcNo: '',
                  designPrcValidity: '',
                  designPtrNo: '',
                  designPtrValidity: '',
                  designChargeID: 0,
                })
              }}
            >
              <FaUserAlt />
            </Button>
            <Button variant='info' type='submit' size='sm'>
              Submit
            </Button>
            <Button
              variant='danger'
              size='sm'
              onClick={() => {
                setModalShow({
                  ...modalShow,
                  isDownloadableForm: true,
                  isProfession: false,
                })

                setEngineerDetails({
                  trade: '',
                  inChargeName: '',
                  inChargeType: '',
                  inChargeProfession: '',
                  inChargePrcNo: '',
                  inChargePrcValidity: '',
                  inChargePtrNo: '',
                  inChargePtrValidity: '',
                  inChargeID: '',
                  designName: '',
                  designProfession: '',
                  designType: '',
                  designPrcNo: '',
                  designPrcValidity: '',
                  designPtrNo: '',
                  designPtrValidity: '',
                  designChargeID: '',
                })

                setInitialState({
                  ...InitialState,
                  [`${isTrade}`]: 0,
                })
              }}
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* End List of Registered Engineer */}

      {/* Not Registered Engineer */}
      <Modal
        size='md'
        show={modalShow.notRegistered}
        onHide={() => setModalShow({ ...modalShow, notRegistered: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            {isTrade} Professional
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitEngrDetails}>
          <Modal.Body className='pb-1'>
            <h5>Not registered engineer & architect</h5>

            <p className='p-0 m-0'>SUPERVISOR / IN-CHARGE</p>

            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='inChargeName'
                  onChange={onChange}
                  value={engineerDetails.inChargeName}
                  autoComplete='off'
                  required
                  size='sm'
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargeProfession}
                  onChange={onChange}
                  name='inChargeProfession'
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePrcNo}
                  onChange={onChange}
                  name='inChargePrcNo'
                  required
                />
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC Validity : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='date'
                  size='sm'
                  value={engineerDetails.inChargePrcValidity}
                  onChange={onChange}
                  name='inChargePrcValidity'
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePtrNo}
                  onChange={onChange}
                  name='inChargePtrNo'
                  required
                />
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR Validity : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='date'
                  size='sm'
                  value={engineerDetails.inChargePtrValidity}
                  onChange={onChange}
                  name='inChargePtrValidity'
                  required
                />
              </Form.Group>
            </Row>

            <p className='p-0 m-0 mt-3'>
              DESIGN PROFESSIONAL, PLANS AND SPECIFICATION
            </p>
            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>

                <Form.Control
                  type='text'
                  name='designName'
                  onChange={onChange}
                  value={engineerDetails.designName}
                  autoComplete='off'
                  required
                  size='sm'
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={onChange}
                  value={engineerDetails.designProfession}
                  required
                  name='designProfession'
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={onChange}
                  value={engineerDetails.designPrcNo}
                  required
                  name='designPrcNo'
                />
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC Validity : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='date'
                  size='sm'
                  onChange={onChange}
                  value={engineerDetails.designPrcValidity}
                  required
                  name='designPrcValidity'
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={onChange}
                  value={engineerDetails.designPtrNo}
                  required
                  name='designPtrNo'
                />
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='date'
                  size='sm'
                  onChange={onChange}
                  value={engineerDetails.designPtrValidity}
                  required
                  name='designPtrValidity'
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer className='p-1'>
            <Button variant='info' type='submit' size='sm'>
              Submit
            </Button>
            <Button
              variant='danger'
              size='sm'
              onClick={() => {
                setModalShow({
                  ...modalShow,
                  isProfession: true,
                  notRegistered: false,
                })

                setEngineerDetails({
                  trade: '',
                  inChargeName: '',
                  inChargeType: '',
                  inChargeProfession: '',
                  inChargePrcNo: '',
                  inChargePrcValidity: '',
                  inChargePtrNo: '',
                  inChargePtrValidity: '',
                  inChargeID: '',
                  designName: '',
                  designProfession: '',
                  designType: '',
                  designPrcNo: '',
                  designPrcValidity: '',
                  designPtrNo: '',
                  designPtrValidity: '',
                  designChargeID: '',
                })

                setInitialState({
                  ...InitialState,
                  [`${isTrade}`]: 0,
                })
              }}
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* End Not Registered Engineer */}
    </UserMain>
  )
}

export default withRouter(DCCategoryScreen)
