import React from 'react'
import { Card, Spinner } from 'react-bootstrap'

const Loading = () => {
  return (
    <div>
      <Card>
        <Card.Body className='mt-2'>
          <div className='text-center pt-3'>
            <Spinner animation='border' />
            <h3>Loading...</h3>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Loading
