import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  InputGroup,
  Button,
  Table,
  Pagination,
  Modal,
} from 'react-bootstrap'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  FaSearch,
  FaEye,
  FaCheck,
  FaClipboardCheck,
  FaEllipsisH,
} from 'react-icons/fa'
import moment from 'moment'

//Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  retrieveReturnedList,
  insertIComment,
  insertEComment,
  updateApplicationStatus,
} from '../../redux/actions/Recommender/recommenderActions'
import { retrieveDateApplied } from '../../redux/actions/adminApplicationActions'

import { retrieveReport } from '../../redux/actions/inspectionActions'

import {
  retrieveReportList,
  retrievePenalty,
} from '../../redux/actions/Inspectors/Supervisors/isupervisorActions'

import { retrieveEvaluationList } from '../../redux/actions/Evaluators/Supervisors/supervisorActions'

import { retrieveEvaluationDetails as CivilEvaluation } from '../../redux/actions/evaluationCivilActions'
import { retrieveEvaluationDetails as ArchEvaluation } from '../../redux/actions/evaluatorArchActions'
import { retrieveEvaluationDetails as ElectronicsEvaluation } from '../../redux/actions/evaluatorActions'
import { retrieveEvaluationDetails as ElecEvaluation } from '../../redux/actions/evaluatorElectricalActions'
import { retrieveEvaluationDetails as MechEvaluation } from '../../redux/actions/evaluatorMechActions'
import { retrieveEvaluationDetails as PlumEvaluation } from '../../redux/actions/evaluatorPlumbingActions'
import { retrieveAssessmentDetails as CivilAssessment } from '../../redux/actions/Assessment/structuralAssessmentActions'
import { retrieveAssessmentDetails as ElecAssessment } from '../../redux/actions/Assessment/electricalAssessmentActions'
import { retrieveAssessmentDetails as ElectronicsAssessment } from '../../redux/actions/Assessment/electronicsAssessmentActions'
import { retrieveAssessmentDetails as MechAssessment } from '../../redux/actions/Assessment/mechanicalAssessmentActions'
import { retrieveAssessmentDetails as PlumAssessment } from '../../redux/actions/Assessment/plumbingAssessmentActions'

// Data
import { createReport } from '../../../data/createReport'

const ReturnedListScreen = () => {
  // Declaration
  const dispatch = useDispatch()

  const [state, setState] = useState([])

  const [payload, setPayload] = useState({})

  const [searchTerm, setSearchTerm] = useState('')

  const [modal, setModal] = useState({
    viewManager: false,
    viewReport: false,
    viewReportList: false,
    viewAssessment: false,
    iSuccessModal: false,
    eSuccessModal: false,
    viewEvaluationList: false,
    viewEvaluation: false,
    recommendSuccess: false,
  })

  const [penaltyInfo, setPenaltyInfo] = useState('')

  const [evalDetails, setEvalDetails] = useState([])

  const [assessDetails, setAssessDetails] = useState([])

  const [commentDetails, setCommentDetails] = useState({
    comments: '',
    appID: 0,
  })

  const [recommenderComments, setRecommenderComments] = useState({
    i_comments: '',
    e_comments: '',
    r_remarks: '',
    appID: 0,
  })

  const [activeTrade, setActiveTrade] = useState('')

  const users = useSelector((state) => state.users)

  const { userCredentials } = users

  const recommender = useSelector((state) => state.recommender)

  const { returnedList, createIComment, createEComment, updateAppStatus } =
    recommender

  const adminApplication = useSelector((state) => state.adminApplication)

  const { dateApplied } = adminApplication

  const isupervisor = useSelector((state) => state.isupervisor)

  const { listReport, penaltyDetail } = isupervisor

  const esupervisor = useSelector((state) => state.esupervisor)

  const { evaluationList } = esupervisor

  const inspection = useSelector((state) => state.inspection)

  const { existingReport } = inspection

  // Evaluators

  const evaluator = useSelector((state) => state.evaluator)

  const { evaluationDetails } = evaluator

  const civilEvaluator = useSelector((state) => state.civilEvaluator)

  const { evaluationCivilDetails } = civilEvaluator

  const archEvaluator = useSelector((state) => state.archEvaluator)

  const { evaluationArchDetails } = archEvaluator

  const electricalEvaluator = useSelector((state) => state.electricalEvaluator)

  const { evaluationElectricalDetails } = electricalEvaluator

  const mechanicalEvaluator = useSelector((state) => state.mechanicalEvaluator)

  const { evaluationMechDetails } = mechanicalEvaluator

  const plumbingEvaluator = useSelector((state) => state.plumbingEvaluator)

  const { evaluationPlumbingDetails } = plumbingEvaluator

  // End Evaluators

  // Assessments

  const structuralAssessment = useSelector(
    (state) => state.structuralAssessment
  )

  const CivilAssessDetails = structuralAssessment.assessmentDetails

  const electronicsAssessment = useSelector(
    (state) => state.electronicsAssessment
  )

  const ElectronicsAssesDetails = electronicsAssessment.assessmentDetails

  const electricalAssessment = useSelector(
    (state) => state.electricalAssessment
  )

  const ElectricalAssessDetails = electricalAssessment.assessmentDetails

  const mechanicalAssessment = useSelector(
    (state) => state.mechanicalAssessment
  )

  const MechAssessDetails = mechanicalAssessment.assessmentDetails

  const plumbingAssessment = useSelector((state) => state.plumbingAssessment)

  const PlumAssessDetails = plumbingAssessment.assessmentDetails

  // End Assessments

  // End Declaration

  // Pagination Area Forms
  const [paginatedForms, setPaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  const pageSize = 5

  useEffect(() => {
    setPaginatedForms(_(_.reverse(state)).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [state])

  const pageCount = state ? Math.ceil(state.length / pageSize) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    const paginationForms = _(state).slice(startIndex).take(pageSize).value()
    setPaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Pagination Area Form

  // Function
  const handleViewEvaluation = (id, evaluatorID, trade) => {
    switch (trade) {
      case 'Electronics':
        dispatch(ElectronicsEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(ElectronicsAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Structural':
        dispatch(CivilEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(CivilAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Architectural':
        dispatch(ArchEvaluation({ userID: evaluatorID, evalAppID: id }))
        // dispatch(CivilAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Mechanical':
        dispatch(MechEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(MechAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Electrical':
        dispatch(ElecEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(ElecAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Plumbing':
        dispatch(PlumEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(PlumAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      default:
        //console.log('NO Evaluation')
        break
    }
  }
  // End Function

  // UseEffect
  useEffect(() => {
    dispatch(retrieveReturnedList())
  }, [])

  useEffect(() => {
    if (returnedList && returnedList.message) {
      setState(() =>
        returnedList.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.id === parseInt(searchTerm)
            )
          }
        })
      )
    }
    if (activeTrade !== '') {
      switch (activeTrade) {
        case 'Structural':
          if (evaluationCivilDetails && evaluationCivilDetails.message) {
            setEvalDetails(evaluationCivilDetails.message)
          }
          if (CivilAssessDetails && CivilAssessDetails.message) {
            setAssessDetails(CivilAssessDetails.message)
          }
          break
        case 'Electronics':
          if (evaluationDetails && evaluationDetails.message) {
            setEvalDetails(evaluationDetails.message)
          }
          if (ElectronicsAssesDetails && ElectronicsAssesDetails.message) {
            setAssessDetails(ElectronicsAssesDetails.message)
          }
          break
        case 'Architectural':
          if (evaluationArchDetails && evaluationArchDetails.message) {
            setEvalDetails(evaluationArchDetails.message)
          }
          // if (CivilAssessDetails && CivilAssessDetails.message) {
          //   setAssessDetails(CivilAssessDetails.message)
          // }
          break
        case 'Mechanical':
          if (evaluationMechDetails && evaluationMechDetails.message) {
            setEvalDetails(evaluationMechDetails.message)
          }
          if (MechAssessDetails && MechAssessDetails.message) {
            setAssessDetails(MechAssessDetails.message)
          }
          break
        case 'Electrical':
          if (
            evaluationElectricalDetails &&
            evaluationElectricalDetails.message
          ) {
            setEvalDetails(evaluationElectricalDetails.message)
          }
          if (ElectricalAssessDetails && ElectricalAssessDetails.message) {
            setAssessDetails(ElectricalAssessDetails.message)
          }
          break
        case 'Plumbing':
          if (evaluationPlumbingDetails && evaluationPlumbingDetails.message) {
            setEvalDetails(evaluationPlumbingDetails.message)
          }
          if (PlumAssessDetails && PlumAssessDetails.message) {
            setAssessDetails(PlumAssessDetails.message)
          }
          break
        default:
          //console.log('Empty')
          break
      }
    }
  }, [returnedList, searchTerm])
  // End UseEffect
  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {returnedList && returnedList.message && (
            <>
              <Form>
                <Row className='justify-content-end px-3 pt-5'>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              <Row className='my-4'>
                <Col md={12} style={{ background: '#FFF' }}>
                  <h5 className='pt-3 pb-2 mb-0'>Returned Application List</h5>
                  <hr
                    className='mt-1 mb-3'
                    style={{
                      background: '#ffeb36',
                      paddingBottom: '2px',
                      width: '200px',
                    }}
                  />
                  <Table bordered hover responsive size='sm'>
                    <thead className='bg-info text-light'>
                      <tr>
                        <th className='text-nowrap'>Application No.</th>
                        <th>Application</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Date Transferred</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedForms.map((x, index) => (
                        <tr key={index}>
                          <td className='text-center'>{x.id}</td>
                          <td>{x.ApplicationName}</td>
                          <td>{x.status}</td>
                          <td>{x.remarks}</td>
                          <td>
                            {moment(x.TimeStamp).format('MMMM D, YYYY h:mma')}
                          </td>
                          <td className='text-center'>
                            <Button
                              size='sm'
                              variant='outline-success'
                              onClick={() => {
                                dispatch(retrieveDateApplied({ id: x.id }))
                                // dispatch(retrievePenalty({ id: x.id }))
                                setModal({
                                  viewManager: true,
                                })
                                setRecommenderComments({
                                  ...recommenderComments,
                                  r_remarks: x.RecommenderRemarks,
                                  i_comments:
                                    x.IComments !== null ? x.IComments : '',
                                  e_comments:
                                    x.EComments !== null ? x.EComments : '',
                                  appID: x.id,
                                })
                                setPayload(x)
                              }}
                            >
                              <FaEllipsisH />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    size='sm'
                    className='d-flex justify-content-end mx-2'
                  >
                    <Pagination.Item
                      className=' mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {renderPageNumber}

                    <Pagination.Item
                      className=' mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </>
          )}

          {returnedList && returnedList.loading && (
            <Row className='py-4'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {returnedList && returnedList.error && (
            <Row className='py-4'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Application Returned</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      {/* Application Details Modal */}
      <Modal
        size='lg'
        show={modal.viewManager}
        onHide={() => setModal({ viewManager: false })}
        centered
        backdrop='static'
      >
        {dateApplied && dateApplied.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Application Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <tbody>
                  <tr className='fs-4'>
                    <td className='fw-bold'>Application No.:</td>
                    <td>{payload.id}</td>
                  </tr>
                  <tr className='fs-4'>
                    <td className='fw-bold'>Application Name:</td>
                    <td>{payload.ApplicationName}</td>
                  </tr>
                  <tr className='fs-4'>
                    <td className='fw-bold'>Mode of Application:</td>
                    <td>{payload.ModeofForms}</td>
                  </tr>
                  <tr className='fs-4'>
                    <td className='fw-bold'>Application Status:</td>
                    <td>{payload.status}</td>
                  </tr>
                  <tr className='fs-4'>
                    <td className='fw-bold'>Date Applied:</td>
                    <td>
                      {moment(dateApplied.message[0].DateApplied).format(
                        'MMMM D, YYYY h:mma'
                      )}
                    </td>
                  </tr>
                  <tr className='fs-4'>
                    <td className='fw-bold'>Applicant Attached Link:</td>
                    <td>{payload.Link}</td>
                  </tr>
                  <tr className='fs-4'>
                    <td className='fw-bold'>
                      Document Controller Attached Link / Remarks:
                    </td>
                    <td>{payload.remarks}</td>
                  </tr>
                </tbody>
              </Table>
              <Form
                onSubmit={(e) => {
                  if (
                    window.confirm(
                      `Are you sure want to recommendation? 
Note: once submitted you will see the application in the recommended list screen`
                    )
                  ) {
                    e.preventDefault()
                    setModal({ recommendSuccess: true })
                    dispatch(
                      updateApplicationStatus({
                        id: payload.statusID,
                        trade: payload.trade,
                        status: 'FOR APPROVAL',
                        remarks: payload.remarks,
                        withEvaluation: payload.withEvaluation,
                        withInspection: payload.withInspection,
                        application_ID: payload.id,
                        userID: payload.userID,
                        dcID: payload.adminID,
                        iSupervisor: payload.iSupervisor,
                        eSupervisor: payload.eSupervisor,
                        comment_remarks: recommenderComments.r_remarks,
                        userLevel: userCredentials.roles,
                        RID: payload.RID,
                      })
                    )
                  } else {
                    e.preventDefault()
                  }
                }}
              >
                <h5 className='mt-3'>
                  Remarks <i className='text-danger'>*</i>
                </h5>
                <h6 className='mx-2 mb-3'>
                  <i>
                    (For the remarks to the city engineer, kindly use this
                    section.)
                  </i>
                </h6>
                <Row>
                  <Col xl='8'>
                    <Form.Control
                      type='text'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      size='sm'
                      name='r_remarks'
                      required
                      placeholder='Insert remarks here ...'
                      onChange={(e) =>
                        setRecommenderComments({
                          ...recommenderComments,
                          r_remarks: e.target.value,
                        })
                      }
                      value={
                        recommenderComments.r_remarks !== null
                          ? recommenderComments.r_remarks
                          : ''
                      }
                    />
                  </Col>
                  <Col xl='4'>
                    <Button size='sm' type='submit' variant='success'>
                      <FaClipboardCheck className='fs-3 px-0' /> Recommend
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='warning'
                onClick={() => {
                  dispatch(retrieveEvaluationList({ id: payload.id }))
                  setModal({ viewEvaluationList: true, viewManager: false })
                }}
              >
                Evaluation/s
              </Button>
              <Button
                variant='info'
                onClick={() => {
                  dispatch(retrieveReportList({ id: payload.id }))
                  setModal({ viewReportList: true, viewManager: false })
                  // if (penaltyDetail && penaltyDetail.message) {
                  //   setPenaltyInfo(penaltyDetail.message[0].penalty)
                  // }
                }}
              >
                Inspection Report
              </Button>

              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewManager: false,
                  })
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        )}

        {dateApplied && dateApplied.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}

        {dateApplied && dateApplied.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}
      </Modal>
      {/* End Application Details Modal */}

      {/* List of Inspection Report Modal */}
      <Modal
        size='xl'
        show={modal.viewReportList}
        onHide={() => setModal({ viewReportList: false })}
        centered
        backdrop='static'
      >
        {listReport && listReport.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Inspection List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Date Scheduled</th>
                    <th>Inspection Date</th>
                    <th>Inspector</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {listReport.message.map((list) => (
                    <tr key={list.id}>
                      <td className='text-danger'>{list.category}</td>
                      <td className='text-danger'>{list.inspectionSched}</td>
                      <td className='text-danger'>{list.inspectionDate}</td>
                      <td>{list.inspectID}</td>
                      <td className='text-center'>
                        <Button
                          variant='success'
                          disabled={list.inspectionDate !== null ? false : true}
                          onClick={() => {
                            setCommentDetails({
                              comments: list.comments,
                              appID: list.appID,
                            })
                            setModal({
                              viewReport: true,
                              viewReportList: false,
                            })
                            dispatch(
                              retrieveReport({
                                inspectionID: list.inspectionID,
                                userID: list.inspectID,
                                category: list.category,
                              })
                            )
                          }}
                        >
                          <FaEye /> View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />
              <h5 className='mx-2'>Application Penalty</h5>
              <h4 className='mx-3'>
                {payload.penalty !== null
                  ? payload.penalty.toLocaleString()
                  : ''}
              </h4>
              <hr />
              <Form
                onSubmit={(e) => {
                  if (
                    window.confirm(
                      `Are you sure want to return recommendation? 
Note: once submitted you will see the application in the returned list`
                    )
                  ) {
                    e.preventDefault()
                    // setModal({ successModal: true })
                    dispatch(
                      insertIComment({
                        comments: recommenderComments.i_comments,
                        adminID: userCredentials.id,
                        id: payload.id,
                        userLevel: userCredentials.roles,
                      })
                    )
                    setModal({
                      iSuccessModal: true,
                    })
                  } else {
                    e.preventDefault()
                  }
                }}
              >
                <h5>Comment to the Inspector Supervisor</h5>
                <h6 className='mx-2 mb-3'>
                  <i>
                    (For the comment to the supervisor for the application,
                    kindly use this section.)
                  </i>
                </h6>
                <Row>
                  <Col xl='9'>
                    <Form.Control
                      type='text'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      size='sm'
                      name='icomments'
                      placeholder='Insert Comment here ...'
                      onChange={(e) =>
                        setRecommenderComments({
                          ...recommenderComments,
                          i_comments: e.target.value,
                        })
                      }
                      value={recommenderComments.i_comments}
                    />
                  </Col>
                  <Col xl='3'>
                    <Button size='sm' type='submit' variant='info'>
                      <FaClipboardCheck className='fs-3 px-0' /> Return
                      Recommendation
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewReportList: false,
                    viewManager: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {listReport && listReport.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReportList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {listReport && listReport.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReportList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End List of Inspection Report Modal */}

      {/* Inspection Report */}
      <Modal
        size='xl'
        show={modal.viewReport}
        onHide={() => setModal({ viewReport: false })}
        centered
        backdrop='static'
      >
        {existingReport && existingReport.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Inspection Report
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Category</h5>
              <p className='mx-2'>
                <i>{existingReport.message[0].category}</i>
              </p>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[0].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[0].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {existingReport.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          existingReport.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[1].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[1].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {existingReport.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          existingReport.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[2].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[2].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {existingReport.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          existingReport.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[3].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[3].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {existingReport.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          existingReport.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[4].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[4].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el.split('Rear_')}</td>
                      <td className='align-middle py-0'>
                        {existingReport.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          existingReport.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[5].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[5].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el.split('Left_')}</td>
                      <td className='align-middle py-0'>
                        {existingReport.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          existingReport.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[6].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[6].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>
                        {el.split('Right_')}
                      </td>
                      <td className='align-middle py-0'>
                        {existingReport.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          existingReport.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[7].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[7].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {existingReport.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          existingReport.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />
              <Form
              // onSubmit={(e) => {
              //   if (
              //     window.confirm(
              //       'Are you sure want to submit a comment for this inspection?'
              //     )
              //   ) {
              //     e.preventDefault()
              //     dispatch(
              //       insertInspectionComments({
              //         comment: commentDetails.comments,
              //         id: commentDetails.appID,
              //         userID: userCredentials.id,
              //       })
              //     )
              //     setModal({
              //       viewReport: false,
              //     })
              //   }
              // }}
              >
                <h5>Supervisor Comment</h5>
                <h6 className='mx-2'>
                  <i>
                    (For your comment to the inspector of this application,
                    Kindly use this section.)
                  </i>
                </h6>
                <Row className='mx-2'>
                  <Col xl='9'>
                    <Form.Control
                      type='text'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      size='sm'
                      name='comments'
                      placeholder='Insert comment here ...'
                      onChange={(e) =>
                        setCommentDetails({
                          ...commentDetails,
                          comments: e.target.value,
                        })
                      }
                      value={commentDetails.comments}
                      disabled={true}
                    />
                  </Col>
                  {/* <Col xl='3'>
                    <Button size='sm' type='submit' variant='info'>
                      Submit
                    </Button>
                  </Col> */}
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewReport: false,
                    viewReportList: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {existingReport && existingReport.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReport: false,
                  viewReportList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {existingReport && existingReport.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReport: false,
                  viewReportList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Inspection Report */}

      {/*Inspector Comment Success Modal */}
      <SuccessModal
        show={modal.iSuccessModal}
        onHide={() => {
          setModal({ iSuccessModal: false })
          window.location.reload(true)
        }}
        transLoading={createIComment && createIComment.loading}
        success={createIComment && createIComment.message}
        error={createIComment && createIComment.error}
      />
      {/* End Success Modal */}

      {/* Evaluation List */}
      <Modal
        size='xl'
        show={modal.viewEvaluationList}
        onHide={() => setModal({ viewEvaluationList: false })}
        centered
        backdrop='static'
      >
        {evaluationList && evaluationList.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Evaluation and Assessment List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Trade</th>
                    <th>Date Evaluated</th>
                    <th>Date Assessed</th>
                    <th>Evaluator</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {evaluationList.message.map((list) => (
                    <tr key={list.id}>
                      <td className='fw-bold'>{list.trade}</td>
                      <td className='text-danger'>{list.withEvaluation}</td>
                      <td className='text-danger'>{list.withAssessment}</td>
                      <td>{list.evaluatorID}</td>
                      <td className='text-center'>
                        <Button
                          variant='success'
                          disabled={
                            list.withEvaluation && list.withAssessment !== null
                              ? false
                              : true
                          }
                          onClick={() => {
                            setCommentDetails({
                              comments: list.comments,
                              appID: list.id,
                            })
                            handleViewEvaluation(
                              list.id,
                              list.evaluatorID,
                              list.trade
                            )
                          }}
                        >
                          <FaEye /> View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Form
                onSubmit={(e) => {
                  if (
                    window.confirm(
                      `Are you sure want to return recommendation? 
Note: once submitted you will see the application in the returned list`
                    )
                  ) {
                    e.preventDefault()
                    // setModal({ successModal: true })
                    dispatch(
                      insertEComment({
                        comments: recommenderComments.e_comments,
                        adminID: userCredentials.id,
                        id: payload.id,
                        userLevel: userCredentials.roles,
                      })
                    )
                    setModal({
                      eSuccessModal: true,
                    })
                  } else {
                    e.preventDefault()
                  }
                }}
              >
                <h5>Comment to the Evaluator Supervisor</h5>
                <h6 className='mx-2 mb-3'>
                  <i>
                    (For the comment to the supervisor for the application,
                    kindly use this section.)
                  </i>
                </h6>
                <Row>
                  <Col xl='9'>
                    <Form.Control
                      type='text'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      size='sm'
                      name='ecomments'
                      placeholder='Insert Comment here ...'
                      onChange={(e) =>
                        setRecommenderComments({
                          ...recommenderComments,
                          e_comments: e.target.value,
                        })
                      }
                      value={recommenderComments.e_comments}
                    />
                  </Col>
                  <Col xl='3'>
                    <Button size='sm' type='submit' variant='info'>
                      <FaClipboardCheck className='fs-3 px-0' /> Return
                      Recommendation
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewEvaluationList: false,
                    viewManager: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {evaluationList && evaluationList.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluationList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {evaluationList && evaluationList.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluationList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Evaluation List */}

      {/* Evaluation Info */}
      <Modal
        size='xl'
        show={modal.viewEvaluation}
        onHide={() => setModal({ viewEvaluation: false })}
        centered
        backdrop='static'
      >
        {evalDetails.length > 0 && activeTrade !== '' && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Evaluation and Assessment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>SCOPE OF WORK</h5>
              <p className='mx-2'>
                <i>{evalDetails.length > 0 && evalDetails[0].scope}</i>
              </p>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Code ID</th>
                    <th>JMC AND CODE REGULATIONS</th>
                    <th>COMPLIANCE (MARK CHECK / STATUS)</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {evalDetails.map((list) => (
                    <tr key={list.id}>
                      <td>{list.code_id}</td>
                      <td>{list.code_name}</td>
                      <td className='text-center'>
                        {list.compliance === 1 ? 'YES' : 'NO'}
                      </td>
                      <td>{list.id_remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <h5>Assessment</h5>
              <p className='mx-2'>
                <i className='fs-5'>Total Amount : </i>
                <i className='text-danger fs-3 fw-bold'>
                  ₱{' '}
                  {assessDetails.length > 0 &&
                    assessDetails[0].grandTotal.toLocaleString()}
                </i>
              </p>
              <hr />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewEvaluation: false,
                    viewEvaluationList: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {evalDetails.length <= 0 && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluation: false,
                  viewEvaluationList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Evaluation Info */}

      {/*Evaluator Comment Success Modal */}
      <SuccessModal
        show={modal.eSuccessModal}
        onHide={() => {
          setModal({ eSuccessModal: false })
          window.location.reload(true)
        }}
        transLoading={createEComment && createEComment.loading}
        success={createEComment && createEComment.message}
        error={createEComment && createEComment.error}
      />
      {/* End Success Modal */}

      {/*Recommend Application Success Modal */}
      <SuccessModal
        show={modal.recommendSuccess}
        onHide={() => {
          setModal({ recommendSuccess: false })
          window.location.reload(true)
        }}
        transLoading={updateAppStatus && updateAppStatus.loading}
        success={updateAppStatus && updateAppStatus.message}
        error={updateAppStatus && updateAppStatus.error}
      />
      {/* End Recommend Application Success Modal */}
    </UserMain>
  )
}

export default ReturnedListScreen
