import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
  Accordion,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  addAssessment,
  editAssessment,
  retrieveAssessmentDetails,
} from '../../redux/actions/Assessment/plumbingAssessmentActions'
import { retrieveAppDetails } from '../../redux/actions/evaluatorActions'

//

const PlumbingAssessmentScreen = ({ history, match }) => {
  //Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const { getAppDetails } = evaluator

  const plumbingAssessment = useSelector((state) => state.plumbingAssessment)

  const { assessmentDetails, createAssessment, updateAssessment } =
    plumbingAssessment

  const [obj, setObj] = useState([
    {
      id_per_name: 1,
      name: 'Installation',
      fee: 24,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 2,
      name: 'Water Closet',
      fee: 7,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 3,
      name: 'Floor Drain',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 4,
      name: 'Sink',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 5,
      name: 'Lavatory',
      fee: 7,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 6,
      name: 'Faucet',
      fee: 2,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 7,
      name: 'Shower',
      fee: 2,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 8,
      name: 'Slop',
      fee: 7,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 9,
      name: 'Urinal',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 10,
      name: 'Bath Tub',
      fee: 7,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 11,
      name: 'Grease',
      fee: 7,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 12,
      name: 'Garage',
      fee: 7,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 13,
      name: 'Bidet',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 14,
      name: 'Dental Cusp',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 15,
      name: 'Water Heater',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 16,
      name: 'Drinking',
      fee: 2,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 17,
      name: 'Fountain Sink',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 18,
      name: 'Laundry Sink',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 19,
      name: 'Lab Sink',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 20,
      name: 'Sterilizer',
      fee: 2,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 21,
      name: 'Water Meter',
      fee: 2,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 22,
      name: 'Range 12',
      fee: 8,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 23,
      name: 'Range Above',
      fee: 10,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 24,
      name: 'Digestion Chamber',
      units: 0,
      estimate: 0,
    },
  ])

  const [state, setState] = useState([])

  const [grandTotal, setGrandTotal] = useState(0)

  const [modal, setModal] = useState({
    successModal: false,
    updateModal: false,
  })

  const total = obj.reduce(
    (prevData, currData) => prevData + currData.estimate,
    0
  )

  const filtered = obj.filter((e) => e.estimate > 0)

  const isUpdating = JSON.parse(localStorage.getItem('assessmentSelected'))
    ? JSON.parse(localStorage.getItem('assessmentSelected'))
    : null
  //End Declaration

  //   Funtions
  const handleRangeDivA1 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 5:
        tempEstimate = e.target.value * 24
        break
      case e.target.value > 5:
        tempEstimate = 7 * (e.target.value - 5) + 120
        break
      default:
        tempEstimate = e.target.value * 24
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleChange = (e) => {
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: e.target.value * el.fee,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleCreateSubmit = () => {
    setModal({ successModal: true })
    dispatch(
      addAssessment({
        grandTotal,
        assessments: state,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
      })
    )
  }

  const handleUpdateSubmit = () => {
    setModal({ updateModal: true })
    dispatch(
      editAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
      })
    )
  }

  const handleBtnCancel = () => {
    if (isUpdating !== null) {
      history.push('/assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    } else {
      history.push('/for-assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    }
  }
  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(
      retrieveAppDetails({ appID: match.params.id, userID: userCredentials.id })
    )

    isUpdating !== null &&
      dispatch(
        retrieveAssessmentDetails({
          evalAppID: isUpdating.evalAppID,
          userID: userCredentials.id,
        })
      )
  }, [dispatch, match, userCredentials])

  useEffect(() => {
    setGrandTotal(() => total)

    setState(() => filtered)
  }, [obj])

  useEffect(() => {
    if (isUpdating !== null) {
      if (assessmentDetails && assessmentDetails.message) {
        assessmentDetails.message.map((submitted) => {
          let temp = obj
          let index = obj.findIndex(
            (el) => el.id_per_name === submitted.id_per_name
          )
          temp[index].units = submitted.units
          temp[index].estimate = submitted.estimate
          temp[index] = { ...temp[index], id: submitted.id }
          setObj(temp)
        })
        setGrandTotal(() => assessmentDetails.message[0].grandTotal)
      }
    }
  }, [assessmentDetails])

  // console.log(obj)

  return (
    <UserMain>
      <Container className='bg-light pt-3'>
        <>
          <h1 className='text-center mb-4'>PLUMBING FEES</h1>
          <Row>
            <Col
              xl={12}
              className='mb-4 pt-3'
              style={{ background: '#f9f6f1' }}
            >
              <h6>Note: </h6>
              <p>Click category to be assessed.</p>
            </Col>
          </Row>

          <Form
            onSubmit={(e) => {
              e.preventDefault()

              isUpdating !== null ? handleUpdateSubmit() : handleCreateSubmit()
            }}
          >
            <Accordion className='mx-0'>
              {/* A. Installation Fees */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='0'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  A. INSTALLATION FEES
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Installation Fees, one (1) “UNIT” composed of one
                            (1) water closet, two (2) floor drains, one (1)
                            lavatory, one (1) sink with ordinary trap, three (3)
                            faucets and one (1) shower head. A partial part
                            thereof shall be charged as that of the cost of a
                            whole “UNIT”.
                          </td>
                          <td className='text-center'>₱24.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Installation'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[0].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Installation')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End A */}

              {/* B. Every fixture in excess of one unit */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='1'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  B. EVERY FIXTURE IN EXCESS OF ONE UNIT
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='1'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each water closet
                          </td>
                          <td className='text-center'>₱7.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Water Closet'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[1].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Water Closet')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each floor drain
                          </td>
                          <td className='text-center'>₱3.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Floor Drain'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[2].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Floor Drain')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each sink
                          </td>
                          <td className='text-center'>₱3.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Sink'
                              placeholder='iii.'
                              onChange={handleChange}
                              value={obj[3].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Sink').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each lavatory
                          </td>
                          <td className='text-center'>₱7.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Lavatory'
                              placeholder='iv.'
                              onChange={handleChange}
                              value={obj[4].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Lavatory')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each faucet
                          </td>
                          <td className='text-center'>₱2.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Faucet'
                              placeholder='v.'
                              onChange={handleChange}
                              value={obj[5].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Faucet').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each shower head
                          </td>
                          <td className='text-center'>₱2.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Shower'
                              placeholder='vi.'
                              onChange={handleChange}
                              value={obj[6].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Shower').estimate}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End B */}

              {/* C. Special Plumbing Fixtures  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='2'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  C. SPECIAL PLUMBING FIXTURES
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='2'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each slop sink
                          </td>
                          <td className='text-center'>₱7.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Slop'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[7].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Slop').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each urinal
                          </td>
                          <td className='text-center'>₱4.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Urinal'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[8].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Urinal').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each bath tub
                          </td>
                          <td className='text-center'>₱7.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Bath Tub'
                              placeholder='iii.'
                              onChange={handleChange}
                              value={obj[9].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Bath Tub')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each grease trap
                          </td>
                          <td className='text-center'>₱7.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Grease'
                              placeholder='iv.'
                              onChange={handleChange}
                              value={obj[10].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Grease').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each garage trap
                          </td>
                          <td className='text-center'>₱7.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Garage'
                              placeholder='v.'
                              onChange={handleChange}
                              value={obj[11].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Garage').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each bidet
                          </td>
                          <td className='text-center'>₱4.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Bidet'
                              placeholder='vi.'
                              onChange={handleChange}
                              value={obj[12].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Bidet').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>vii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each dental cuspidor
                          </td>
                          <td className='text-center'>₱4.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Dental Cusp'
                              placeholder='vii.'
                              onChange={handleChange}
                              value={obj[13].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Dental Cusp')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>viii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each gas-fired water heater
                          </td>
                          <td className='text-center'>₱4.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Water Heater'
                              placeholder='viii.'
                              onChange={handleChange}
                              value={obj[14].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Water Heater')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ix.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each drinking fountain
                          </td>
                          <td className='text-center'>₱2.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Drinking'
                              placeholder='ix.'
                              onChange={handleChange}
                              value={obj[15].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Drinking')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>x.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each bar or soda fountain sink
                          </td>
                          <td className='text-center'>₱4.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Fountain Sink'
                              placeholder='x.'
                              onChange={handleChange}
                              value={obj[16].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Fountain Sink')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>xi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each laundry sink
                          </td>
                          <td className='text-center'>₱4.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Laundry Sink'
                              placeholder='xi.'
                              onChange={handleChange}
                              value={obj[17].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Laundry Sink')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>xii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each laboratory sink
                          </td>
                          <td className='text-center'>₱4.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Lab Sink'
                              placeholder='xii.'
                              onChange={handleChange}
                              value={obj[18].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Lab Sink')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>xiii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Each fixed-type sterilizer
                          </td>
                          <td className='text-center'>₱2.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Sterilizer'
                              placeholder='xiii.'
                              onChange={handleChange}
                              value={obj[19].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Sterilizer')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End C */}

              {/* D. Each Water Meter   */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='3'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  D. EACH WATER METER
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='3'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Each water meter
                          </td>
                          <td className='text-center'>₱2.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Water Meter'
                              placeholder='Unit'
                              onChange={handleChange}
                              value={obj[20].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Water Meter')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            12 to 25mm
                          </td>
                          <td className='text-center'>₱8.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Range 12'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[21].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Range 12')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 25mm
                          </td>
                          <td className='text-center'>₱10.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Range Above'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[22].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Range Above')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End D */}

              {/* E. Construction of Septic Tank, applicable in all Groups  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='4'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  E. CONSTRUCTION OF SEPTIC TANK, APPLICABLE IN ALL GROUPS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Up to 5.00 cu. meters of digestion chamber
                          </td>
                          <td className='text-center'>₱24.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Digestion Chamber'
                              placeholder='i.-ii.'
                              onChange={handleRangeDivA1}
                              value={obj[23].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find(
                                  (el) => el.name === 'Digestion Chamber'
                                ).estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Every cu. meter or fraction thereof in excess of
                            5.00 cu. meters
                          </td>
                          <td className='text-center'>₱7.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End E */}
            </Accordion>

            <Row className='mt-3 mx-1'>
              <Col xl={8} className='text-end align-middle mt-2'>
                <h4>GRAND TOTAL:</h4>
              </Col>
              <Col xl={4} className='mb-5'>
                <Form.Control
                  type='text'
                  className='border border-secondary'
                  style={{
                    marginLeft: '8px',
                    marginRight: '5px',
                  }}
                  name='GrandTotal'
                  value={grandTotal}
                  placeholder='Grand Total'
                  disabled
                />
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col xl={12} className='text-end'>
                <Button type='submit' variant='success'>
                  Submit
                </Button>
                <Button
                  variant='danger'
                  className='mx-2'
                  onClick={handleBtnCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      </Container>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={createAssessment && createAssessment.loading}
        success={createAssessment && createAssessment.message}
        error={createAssessment && createAssessment.error}
      />
      {/* End Success Modal */}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateModal}
        onHide={() => {
          setModal({ updateModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={updateAssessment && updateAssessment.loading}
        success={updateAssessment && updateAssessment.message}
        error={updateAssessment && updateAssessment.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(PlumbingAssessmentScreen)
