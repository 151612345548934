const professionList = [
  'Civil Engineer',
  'Architect',
  'Professional Electrical Engineer',
  'Professional Electronics Engineer',
  'Professional Mechanical Engineer',
  'Structural Engineer',
  'Geodetic Engineer',
  'Master Plumber',
  'Electrical Engineer',
  'Electronics Engineer',
  'Mechanical Engineer',
  'Master Electrician',
]

export default professionList
