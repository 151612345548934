import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
  Accordion,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  addAssessment,
  editAssessment,
  retrieveAssessmentDetails,
} from '../../redux/actions/Assessment/annualInspectionFeeActions'
import { retrieveAppDetails } from '../../redux/actions/evaluatorActions'

const AnnualInspectionFeeScreen = ({ history, match }) => {
  // Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const { getAppDetails } = evaluator

  const annualInspectionFee = useSelector((state) => state.annualInspectionFee)

  const { assessmentDetails, createAssessment, updateAssessment } =
    annualInspectionFee

  const [obj, setObj] = useState([
    {
      id: 1,
      name: 'Req Services',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 120,
      units: 0,
      estimate: 0,
    },
    {
      id: 2,
      name: 'Comm Appen',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      units: 0,
      estimate: 0,
    },
    {
      id: 3,
      name: 'Comm Flr Area',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      units: 0,
      estimate: 0,
    },
    {
      id: 4,
      name: 'FC Cinema',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 1200,
      units: 0,
      estimate: 0,
    },
    {
      id: 5,
      name: 'SC Cinema',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 720,
      units: 0,
      estimate: 0,
    },
    {
      id: 6,
      name: 'TC Cinema',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 520,
      units: 0,
      estimate: 0,
    },
    {
      id: 7,
      name: 'Grandstands',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 720,
      units: 0,
      estimate: 0,
    },
    {
      id: 8,
      name: 'Plumbing Annual',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 60,
      units: 0,
      estimate: 0,
    },
    {
      id: 9,
      name: 'Ref Annual',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 0,
      units: 0,
      estimate: 0,
    },
    {
      id: 10,
      name: 'Aircon Annual',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 40,
      units: 0,
      estimate: 0,
    },
    {
      id: 11,
      name: 'Packaged Air',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 0,
      units: 0,
      estimate: 0,
    },
    {
      id: 12,
      name: 'A_KW',
      name_1: 'A_QTY',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 0,
      units: 0,
      estimate: 0,
    },
    {
      id: 13,
      name: 'Esc Annual',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 120,
      units: 0,
      estimate: 0,
    },
    {
      id: 14,
      name: 'Funicular Annual',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 50,
      units: 0,
      estimate: 0,
    },
    {
      id: 15,
      name: 'FL Annual',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 10,
      units: 0,
      estimate: 0,
    },
    {
      id: 16,
      name: 'Cable Annual',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 25,
      units: 0,
      estimate: 0,
    },
    {
      id: 17,
      name: 'CC Annual',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 2,
      units: 0,
      estimate: 0,
    },
    {
      id: 18,
      name: 'Passenger Annual',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 500,
      units: 0,
      estimate: 0,
    },
    {
      id: 19,
      name: 'Freight Elev',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 400,
      units: 0,
      estimate: 0,
    },
    {
      id: 20,
      name: 'Motordumb',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 50,
      units: 0,
      estimate: 0,
    },
    {
      id: 21,
      name: 'ElevConst',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 400,
      units: 0,
      estimate: 0,
    },
    {
      id: 22,
      name: 'ElevCars',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 500,
      units: 0,
      estimate: 0,
    },
    {
      id: 23,
      name: 'Landing Elev',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 50,
      units: 0,
      estimate: 0,
    },
    {
      id: 24,
      name: 'Boiler Max',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 0,
      units: 0,
      estimate: 0,
    },
    {
      id: 25,
      name: 'Pressurized Water',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 120,
      units: 0,
      estimate: 0,
    },
    {
      id: 26,
      name: 'Auto Fire',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 2,
      units: 0,
      estimate: 0,
    },
    {
      id: 27,
      name: 'Water Sewage',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 0,
      units: 0,
      estimate: 0,
    },
    {
      id: 28,
      name: 'B_KW',
      name_1: 'B_QTY',
      estimate_kw: 0,
      fee: 0,
      kw: 0,
      estimate_units: 0,
      units: 0,
      estimate: 0,
    },
    {
      id: 29,
      name: 'Vacuum',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 10,
      units: 0,
      estimate: 0,
    },
    {
      id: 30,
      name: 'Gas Steam',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 2,
      units: 0,
      estimate: 0,
    },
    {
      id: 31,
      name: 'Forklifts Loaders',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 0,
      units: 0,
      estimate: 0,
    },
    {
      id: 32,
      name: 'Other Industrial',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 0,
      units: 0,
      estimate: 0,
    },
    {
      id: 33,
      name: 'Press Vess',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 40,
      units: 0,
      estimate: 0,
    },
    {
      id: 34,
      name: 'Conveyors',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 2.4,
      units: 0,
      estimate: 0,
    },
    {
      id: 35,
      name: 'Weigh Structure',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 30,
      units: 0,
      estimate: 0,
    },
    {
      id: 36,
      name: 'Calibration',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 24,
      units: 0,
      estimate: 0,
    },
    {
      id: 37,
      name: 'Test Capacity',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 30,
      units: 0,
      estimate: 0,
    },
    {
      id: 38,
      name: 'Mech Ride',
      name_1: '',
      estimate_kw: 0,
      kw: 0,
      estimate_units: 0,
      fee: 30,
      units: 0,
      estimate: 0,
    },
  ])

  const [disabled, setDisabled] = useState({
    A: true,
    B: true,
  })

  const [state, setState] = useState([])

  const [grandTotal, setGrandTotal] = useState(0)

  const [modal, setModal] = useState({
    successModal: false,
    updateModal: false,
  })

  const total = obj.reduce(
    (prevData, currData) => prevData + currData.estimate,
    0
  )

  const filtered = obj.filter((e) => e.estimate > 0)

  const isUpdating = JSON.parse(localStorage.getItem('assessmentSelected'))
    ? JSON.parse(localStorage.getItem('assessmentSelected'))
    : null
  // End Declaration

  // Functions
  const handleRangeDivA1 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 3:
        tempEstimate = 150
        break
      case e.target.value > 3:
        tempEstimate = 50 * (e.target.value - 3) + 150
        break
      default:
        tempEstimate = e.target.value * 150
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  const handleRangeDivA2 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 100:
        tempEstimate = 120
        break
      case e.target.value > 100 && e.target.value <= 200:
        tempEstimate = 240
        break
      case e.target.value > 200 && e.target.value <= 350:
        tempEstimate = 480
        break
      case e.target.value > 350 && e.target.value <= 500:
        tempEstimate = 720
        break
      case e.target.value > 500 && e.target.value <= 750:
        tempEstimate = 960
        break
      case e.target.value > 750 && e.target.value <= 1000:
        tempEstimate = 1200
        break
      case e.target.value > 1000:
        tempEstimate = 1200 * (e.target.value - 1000)
        break
      default:
        tempEstimate = e.target.value * 120
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  const handleRangeDivA3 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 10:
        tempEstimate = 100
        break
      case e.target.value > 10:
        tempEstimate = 3 * (e.target.value - 10) + 100
        break
      default:
        tempEstimate = e.target.value * 100
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  //F. i. Ref Annual
  const handleRangeDivA4 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 100:
        tempEstimate = e.target.value * 25
        break
      case e.target.value > 100 && e.target.value <= 150:
        tempEstimate = 2500 + (1000 + (e.target.value - 150) * 20)
        break
      case e.target.value > 150 && e.target.value <= 300:
        tempEstimate = 3500 + (2250 + (e.target.value - 300) * 15)
        break
      case e.target.value > 300 && e.target.value <= 500:
        tempEstimate = 5750 + (2000 + (e.target.value - 500) * 10)
        break
      case e.target.value > 500:
        let sample = e.target.value - 500
        tempEstimate = sample * 5 + 7750
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //F. iii. Packaged Air
  const handleRangeDivA5 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 100:
        tempEstimate = e.target.value * 25
        break
      case e.target.value > 100 && e.target.value <= 150:
        tempEstimate = 2500 + (1000 + (e.target.value - 150) * 20)
        break
      case e.target.value > 150 && e.target.value <= 300:
        tempEstimate = 3500 + (2250 + (e.target.value - 300) * 15)
        break
      case e.target.value > 300 && e.target.value <= 500:
        tempEstimate = 5750 + (2000 + (e.target.value - 500) * 10)
        break
      case e.target.value > 500:
        let sample = e.target.value - 500
        tempEstimate = sample * 5 + 7750
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //F. iv. Mechanical Venti Qty
  const handleRangeDivA6 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case obj[11].kw <= 1:
        tempEstimate = e.target.value * 10
        break
      case obj[11].kw > 1 && obj[11].kw <= 7.5:
        tempEstimate = e.target.value * 50
        break
      case obj[11].kw > 7.5:
        tempEstimate = e.target.value * 20
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name_1 === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //F. iv. Mechanical Venti KW
  const handleRangeDivA7 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 1:
        tempEstimate = 10
        break
      case e.target.value > 1 && e.target.value <= 7.5:
        tempEstimate = 50
        break
      case e.target.value > 7.5:
        tempEstimate = 20
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            kw: e.target.value,
            estimate_kw: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
    setDisabled({ ...disabled, A: false })
  }
  //F. vii. Boiler Max
  const handleRangeDivA8 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 7.5:
        tempEstimate = 400
        break
      case e.target.value > 7.5 && e.target.value <= 22:
        tempEstimate = 550
        break
      case e.target.value > 22 && e.target.value <= 37:
        tempEstimate = 600
        break
      case e.target.value > 37 && e.target.value <= 52:
        tempEstimate = 650
        break
      case e.target.value > 52 && e.target.value <= 67:
        tempEstimate = 800
        break
      case e.target.value > 67 && e.target.value <= 74:
        tempEstimate = 900
        break
      case e.target.value > 74:
        let sample = e.target.value - 74
        tempEstimate = sample * 4
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //F. x. Water Sewage
  const handleRangeDivA9 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 5:
        tempEstimate = e.target.value * 55
        break
      case e.target.value > 5 && e.target.value <= 10:
        tempEstimate = e.target.value * 90
        break
      case e.target.value > 10:
        let sample = e.target.value - 10
        tempEstimate = sample * 2 + 900
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //F. xi. Internal Combustion Qty
  const handleRangeDivA10 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case obj[27].kw <= 50:
        tempEstimate = e.target.value * 15
        break
      case obj[27].kw > 50 && obj[27].kw <= 100:
        tempEstimate = e.target.value * 10
        break
      case obj[27].kw > 100:
        tempEstimate = e.target.value * 2.4
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name_1 === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //F. xi. Internal Combustion KW
  const handleRangeDivA11 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 50:
        tempEstimate = 15
        break
      case e.target.value > 50 && e.target.value <= 100:
        tempEstimate = 10
        break
      case e.target.value > 100:
        tempEstimate = 2.4
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            kw: e.target.value,
            estimate_kw: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
    setDisabled({ ...disabled, B: false })
  }
  //F. xv. Other Industrial
  const handleRangeDivA12 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 0.5:
        tempEstimate = e.target.value * 8
        break
      case e.target.value > 0.5 && e.target.value <= 1:
        tempEstimate = e.target.value * 23
        break
      case e.target.value > 1 && e.target.value <= 3:
        tempEstimate = e.target.value * 39
        break
      case e.target.value > 3 && e.target.value <= 5:
        tempEstimate = e.target.value * 55
        break
      case e.target.value > 5 && e.target.value <= 10:
        tempEstimate = e.target.value * 80
        break
      case e.target.value > 10:
        let sample = e.target.value - 10
        tempEstimate = sample * 4 + 800
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleChange = (e) => {
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: e.target.value * el.fee,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleCreateSubmit = () => {
    setModal({ successModal: true })
    dispatch(
      addAssessment({
        grandTotal,
        assessments: state,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
      })
    )
  }

  const handleUpdateSubmit = () => {
    setModal({ updateModal: true })
    dispatch(
      editAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
      })
    )
  }

  const handleBtnCancel = () => {
    if (isUpdating !== null) {
      history.push('/assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    } else {
      history.push('/for-assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    }
  }
  // End Functions

  //UseEffect
  useEffect(() => {
    dispatch(
      retrieveAppDetails({ appID: match.params.id, userID: userCredentials.id })
    )

    isUpdating !== null &&
      dispatch(
        retrieveAssessmentDetails({
          evalAppID: isUpdating.evalAppID,
          userID: userCredentials.id,
        })
      )
  }, [dispatch, match, userCredentials])

  useEffect(() => {
    setGrandTotal(() => total)

    setState(() => filtered)
  }, [total])

  useEffect(() => {
    if (isUpdating !== null) {
      if (assessmentDetails && assessmentDetails.message) {
        assessmentDetails.message.map((submitted) => {
          let temp = obj
          let index = obj.findIndex(
            (el) => el.id_per_name === submitted.id_per_name
          )
          temp[index].units = submitted.units
          temp[index].estimate = submitted.estimate
          temp[index] = { ...temp[index], id: submitted.id }
          setObj(temp)
        })
        setGrandTotal(() => assessmentDetails.message[0].grandTotal)
      }
    }
  }, [assessmentDetails])

  // console.log(filtered)

  return (
    <UserMain>
      <Container className='bg-light pt-3'>
        <>
          <h1 className='text-center mb-4'>ANNUAL INSPECTION FEES</h1>
          <Row>
            <Col
              xl={12}
              className='mb-4 pt-3'
              style={{ background: '#f9f6f1' }}
            >
              <h6>Note: </h6>
              <p>Click category to be assessed.</p>
            </Col>
          </Row>

          <Form
            onSubmit={(e) => {
              e.preventDefault()

              isUpdating !== null ? handleUpdateSubmit() : handleCreateSubmit()
            }}
          >
            <Accordion className='mx-0'>
              {/* A. Division A-1 and A-2 */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='0'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  A. Division A-1 and A-2 Buildings
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Single detached dwelling units and duplexes are not
                            subject to annual inspections
                          </td>
                          <td className='text-center'></td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            If the owner request inspections, the fee for each
                            of the services enumerated below is
                          </td>
                          <td className='text-center'>₱120.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Req Services'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[0].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Req Services')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Land Use Conformity
                          </td>
                          <td className='text-center'></td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Architectural Presentability
                          </td>
                          <td className='text-center'></td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Structural Stability
                          </td>
                          <td className='text-center'></td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Sanitary and Health Requirements
                          </td>
                          <td className='text-center'></td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Fire-Resistive Requirements
                          </td>
                          <td className='text-center'></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End A */}

              {/* B. Divisions B-1/D-1, 2, 3/E-1, 2, 3/F-1/G-1, 2, 3, 4, 5/ H-1, 2, 3, 4/ and I-1, Commercial, Industrial Institutional buildings and appendages shall be assessed area as follows  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='1'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  B. DIVISION B-1/D-1, 2, 3/E-1, 2, 3/F-1/G-1, 2, 3, 4, 5/ H-1,
                  2, 3, 4/ and I-1, COMMERCIAL, INDUSTRIAL INSTITUTIONAL
                  BUILDINGS AND APPENDAGES SHALL BE ASSESSED AREA AS FOLLOWS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='1'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Appendage of up to 3.00 cu. meters
                          </td>
                          <td className='text-center'>₱150.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Comm Appen'
                              placeholder='i.-ii. Appendage'
                              onChange={handleRangeDivA1}
                              value={obj[1].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Comm Appen')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>

                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Every sq. meter or fraction thereof in excess of
                            3.00 sq. meters
                          </td>
                          <td className='text-center'>₱50.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Floor area to 100.00 sq. meters
                          </td>
                          <td className='text-center'>₱120.00</td>
                          <td className='text-center'>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Comm Flr Area'
                              placeholder='iii.-ix.'
                              onChange={handleRangeDivA2}
                              value={obj[2].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Comm Flr Area')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Above 100.00 sq. meters up to 200.00 sq. meters
                          </td>
                          <td className='text-center'>₱240.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Above 200.00 sq. meters up to 350.00 sq. meters
                          </td>
                          <td className='text-center'>₱480.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Above 350.00 sq. meters up to 500.00 sq. meters
                          </td>
                          <td className='text-center'>₱720.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Above 500.00 sq. meters up to 750.00 sq. meters
                          </td>
                          <td className='text-center'>₱960.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Above 750.00 sq. meters up to 1000.00 sq. meters
                          </td>
                          <td className='text-center'>₱1,200.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Every 1000 sq. meters or its portion in excess of
                            1000.00 sq. meters
                          </td>
                          <td className='text-center'>₱1,200.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End B */}

              {/*  C. Divisions C-1, 2, Amusement Houses, Gymnasia and the like */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='2'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  C. DIVISIONS C-1, 2, AMUSEMENT HOUSES, GYMNASIA AND THE LIKE
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='2'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            First class cinematographs or theaters
                          </td>
                          <td className='text-center'>₱1,200.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='FC Cinema'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[3].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'FC Cinema')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Second class cinematographs or theaters
                          </td>
                          <td className='text-center'>₱720.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='SC Cinema'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[4].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'SC Cinema')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Third class cinematographs or theaters
                          </td>
                          <td className='text-center'>₱520.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='TC Cinema'
                              placeholder='iii.'
                              onChange={handleChange}
                              value={obj[5].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'TC Cinema')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Grandstands/Bleachers, Gymnasia and the like
                          </td>
                          <td className='text-center'>₱720.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Grandstands'
                              placeholder='iv.'
                              onChange={handleChange}
                              value={obj[6].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Grandstands')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End C */}

              {/*  D. Annual Plumbing Inspection Fees */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='3'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  D. ANNUAL PLUMBING INSPECTION FEES
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='3'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Annual plumbing inspection fees, each plumbing unit
                          </td>
                          <td className='text-center'>₱60.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Plumbing Annual'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[7].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Plumbing Annual')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End D */}

              {/*  E. Electrical Inspection Fees*/}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='4'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  E. ELECTRICAL INSPECTION FEES
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center align-middle'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>
                            A one time electrical inspection fee equivalent to
                            10% of Total Electrical Permit Fees shall be charged
                            to cover all inspection trips during construction.
                          </td>
                          <td className='text-center align-middle'></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='align-middle'>
                            Annual Inspection Fees are the same as in Section
                            4.e.
                          </td>
                          <td className='text-center align-middle'></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End E */}

              {/*  F. Annual Mechanical Inspection Fees */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='5'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  F. ANNUAL MECHANICAL INSPECTION FEES
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='5'>
                  <Accordion className='mx-0'>
                    {/* I. Refrigeration and Ice Plant, per ton: */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='0'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        i. Refrigeration and Ice Plant, per ton:{' '}
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='0'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-3'>
                            <thead>
                              <tr className='text-center align-middle'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>i.</td>
                                <td className='align-middle' colSpan={2}>
                                  (a) Up to 100 tons capacity
                                </td>
                                <td className='text-center align-middle'>
                                  ₱25.00
                                </td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Ref Annual'
                                    placeholder='a.-e.'
                                    onChange={handleRangeDivA4}
                                    value={obj[8].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td>
                                  ₱
                                  {
                                    obj.find((el) => el.name === 'Ref Annual')
                                      .estimate
                                  }
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>ii.</td>
                                <td className='align-middle'>
                                  (b) Above 100 tons up to 150 tons
                                </td>
                                <td className='text-center align-middle'>
                                  ₱20.00
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>ii.</td>
                                <td className='align-middle'>
                                  (c) Above 150 tons up to 300 tons
                                </td>
                                <td className='text-center align-middle'>
                                  ₱15.00
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>ii.</td>
                                <td className='align-middle'>
                                  (d) Above 300 tons up to 500 tons
                                </td>
                                <td className='text-center align-middle'>
                                  ₱10.00
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>ii.</td>
                                <td className='align-middle'>
                                  (e) Every ton or fraction thereof above 500
                                  tons
                                </td>
                                <td className='text-center align-middle'>
                                  ₱5.00
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End I. Refrigeration and Ice Plant, per ton: */}

                    {/* II. Air Conditioning Systems: Window type air conditioners */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='1'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        ii. Air Conditioning Systems: Window type air
                        conditioners
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='1'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-3'>
                            <thead>
                              <tr className='text-center align-middle'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>i.</td>
                                <td className='align-middle' colSpan={2}>
                                  Air Conditioning Systems: Window type air
                                  conditioners, per unit
                                </td>
                                <td className='text-center align-middle'>
                                  ₱40.00
                                </td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Aircon Annual'
                                    placeholder='Unit'
                                    onChange={handleChange}
                                    value={obj[9].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td>
                                  ₱
                                  {
                                    obj.find(
                                      (el) => el.name === 'Aircon Annual'
                                    ).estimate
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End II. Air Conditioning Systems: Window type air conditioners*/}

                    {/*III. Packaged or centralized air conditioning systems:*/}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='2'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        iii. Packaged or centralized air conditioning systems:
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='2'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-3'>
                            <thead>
                              <tr className='text-center align-middle'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>i.</td>
                                <td className='align-middle' colSpan={2}>
                                  (a) First 100 tons, per ton
                                </td>
                                <td className='text-center align-middle'>
                                  ₱25.00
                                </td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Packaged Air'
                                    placeholder='a.-e.'
                                    onChange={handleRangeDivA5}
                                    value={obj[10].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td>
                                  ₱
                                  {
                                    obj.find((el) => el.name === 'Packaged Air')
                                      .estimate
                                  }
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>ii.</td>
                                <td className='align-middle' colSpan={2}>
                                  (b) Above 100 tons up to 150 tons, per ton
                                </td>
                                <td className='text-center align-middle'>
                                  ₱20.00
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>iii.</td>
                                <td className='align-middle' colSpan={2}>
                                  (c) Above 150 tons up to 300 tons
                                </td>
                                <td className='text-center align-middle'>
                                  ₱15.00
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>iv.</td>
                                <td className='align-middle' colSpan={2}>
                                  (d) Above 300 tons up to 500 tons
                                </td>
                                <td className='text-center align-middle'>
                                  ₱10.00
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>v.</td>
                                <td className='align-middle' colSpan={2}>
                                  (e) Every ton or fraction thereof above 500
                                  tons
                                </td>
                                <td className='text-center align-middle'>
                                  ₱5.00
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End III. Packaged or centralized air conditioning systems: */}

                    {/*IV. Mechanical Ventilation, per unit, per kW */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='3'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        iv. Mechanical Ventilation, per unit, per kW
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='3'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-3'>
                            <thead>
                              <tr className='text-center align-middle'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>i.</td>
                                <td className='align-middle' colSpan={2}>
                                  Up to 1kW
                                </td>
                                <td className='text-center align-middle'>
                                  ₱10.00
                                </td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='A_KW'
                                    placeholder='in kW'
                                    onChange={handleRangeDivA7}
                                    value={obj[11].kw}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td></td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>ii.</td>
                                <td className='align-middle' colSpan={2}>
                                  Above 1kW to 7.5kW
                                </td>
                                <td className='text-center align-middle'>
                                  ₱50.00
                                </td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='A_QTY'
                                    disabled={disabled.A}
                                    placeholder='Qty.'
                                    onChange={handleRangeDivA6}
                                    value={obj[11].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name_1 === 'A_QTY')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'>ii.</td>
                                <td className='align-middle' colSpan={2}>
                                  Every above 7.5kW
                                </td>
                                <td className='text-center align-middle'>
                                  ₱20.00
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End IV. Mechanical Ventilation, per unit, per kW */}

                    {/*V. Escalators and Moving Walks; Funiculars and the like */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='4'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        v. Escalators and Moving Walks; Funiculars and the like
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='4'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-3'>
                            <thead>
                              <tr className='text-center align-middle'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'></td>
                                <td className='align-middle' colSpan={2}>
                                  (a) Escalator and Moving Walks, per unit
                                </td>
                                <td className='text-center align-middle'>
                                  ₱120.00
                                </td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Esc Annual'
                                    placeholder='a.'
                                    onChange={handleChange}
                                    value={obj[12].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'Esc Annual')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'></td>
                                <td className='align-middle' colSpan={2}>
                                  (b) Funiculars, per kW or fraction thereof
                                </td>
                                <td className='text-center align-middle'>
                                  ₱50.00
                                </td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Funicular Annual'
                                    placeholder='b.'
                                    onChange={handleChange}
                                    value={obj[13].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Funicular Annual'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className=' text-justify align-middle'>
                                <td className='align-middle'></td>
                                <td className='align-middle' colSpan={2}>
                                  (c) Per lineal meter or fraction thereof of
                                  travel
                                </td>
                                <td className='text-center align-middle'>
                                  ₱10.00
                                </td>
                                <td className='text-center'>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='FL Annual'
                                    placeholder='c.'
                                    onChange={handleChange}
                                    value={obj[14].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'FL Annual')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (d) Cable Car, per kW or fraction thereof
                                </td>
                                <td className='text-center'>₱25.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Cable Annual'
                                    placeholder='d.'
                                    onChange={handleChange}
                                    value={obj[15].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Cable Annual'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (e) Per lineal meter of travel
                                </td>
                                <td className='text-center'>₱2.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='CC Annual'
                                    placeholder='e.'
                                    onChange={handleChange}
                                    value={obj[16].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'CC Annual')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End V. Escalators and Moving Walks; Funiculars and the like */}

                    {/*VI. Elevators, per unit  */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='5'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        vi. Elevators, per unit
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='5'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (a) Passenger elevators
                                </td>
                                <td className='text-center'>₱500.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Passenger Annual'
                                    placeholder='a.'
                                    onChange={handleChange}
                                    value={obj[17].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Passenger Annual'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (b) Freight Elevators
                                </td>
                                <td className='text-center'>₱400.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Freight Elev'
                                    placeholder='b.'
                                    onChange={handleChange}
                                    value={obj[18].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Freight Elev'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (c) Motor Driven Dumbwaiters
                                </td>
                                <td className='text-center'>₱50.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Motordumb'
                                    placeholder='c.'
                                    onChange={handleChange}
                                    value={obj[19].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'Motordumb')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (d) Construction Elevators for materials
                                </td>
                                <td className='text-center'>₱400.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='ElevConst'
                                    placeholder='d.'
                                    onChange={handleChange}
                                    value={obj[20].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'ElevConst')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (e) Car Elevators
                                </td>
                                <td className='text-center'>₱500.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='ElevCars'
                                    placeholder='e.'
                                    onChange={handleChange}
                                    value={obj[21].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'ElevCars')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (f) Every landing above first five (5)
                                  landings for all the above elevators
                                </td>
                                <td className='text-center'>₱50.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Landing Elev'
                                    placeholder='f.'
                                    onChange={handleChange}
                                    value={obj[22].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Landing Elev'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End VI. Elevators, per unit  */}

                    {/*VII.  Boilers, per unit*/}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='6'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        vii. Boilers, per unit
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='6'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (a) Up to 7.5kW
                                </td>
                                <td className='text-center'>₱400.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Boiler Max'
                                    placeholder='a.-g.'
                                    onChange={handleRangeDivA8}
                                    value={obj[23].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'Boiler Max')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (b) Above 7.5kW to 22kW
                                </td>
                                <td className='text-center'>₱400.00</td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (c) Above 22kW to 37kW
                                </td>
                                <td className='text-center'>₱50.00</td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (d) Above 37kW to 52kW
                                </td>
                                <td className='text-center'>₱400.00</td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (e) Above 52kW to 67kW
                                </td>
                                <td className='text-center'>₱500.00</td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (f) Above 67kW to 74kW
                                </td>
                                <td className='text-center'>₱50.00</td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (g) Every kW or fraction thereof above 74kW
                                </td>
                                <td className='text-center'>₱50.00</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End VII.  Boilers, per unit*/}

                    {/*IX. Automatic Fire Extinguishers*/}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='8'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        ix. Automatic Fire Extinguishers
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='8'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  Automatic Fire Extinguishers, per sprinkler
                                  head
                                </td>
                                <td className='text-center'>₱2.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Auto Fire'
                                    placeholder='a.'
                                    onChange={handleChange}
                                    value={obj[25].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'Auto Fire')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End IX. Automatic Fire Extinguishers*/}

                    {/* X. Water, Sump and Sewage pumps or buildings/structures for
                    commercial/ industrial purposes*/}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='9'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        X. Water, Sump and Sewage pumps or buildings/structures
                        for commercial/ industrial purposes
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='9'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (a) Up to 5kW
                                </td>
                                <td className='text-center'>₱55.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Water Sewage'
                                    placeholder='a.-c.'
                                    onChange={handleRangeDivA9}
                                    value={obj[26].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Water Sewage'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (b) Above 5kW to 10kW
                                </td>
                                <td className='text-center'>₱90.00</td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (c) Every kW or fraction thereof above 10kW
                                </td>
                                <td className='text-center'>₱2.00</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End  X. Water, Sump and Sewage pumps or buildings/structures for
                    commercial/ industrial purposes*/}

                    {/* XI. Diesel/Gasoline Internal Combustion Engine, Gas
                    Turbine/Engine, Hydro, Nuclear or Solar Generating Units and
                    the like */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='10'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        xi. Diesel/Gasoline Internal Combustion Engine, Gas
                        Turbine/Engine, Hydro, Nuclear or Solar Generating Units
                        and the like
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='10'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (a) Per kW, up to 50kW
                                </td>
                                <td className='text-center'>₱15.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='B_KW'
                                    placeholder='in KW'
                                    onChange={handleRangeDivA11}
                                    value={obj[27].kw}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (b) Above 50kW up to 100kW
                                </td>
                                <td className='text-center'>₱10.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='B_QTY'
                                    disabled={disabled.B}
                                    placeholder='Qty.'
                                    onChange={handleRangeDivA10}
                                    value={obj[27].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name_1 === 'B_QTY')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (c) Every kW or fraction thereof above 100kW
                                </td>
                                <td className='text-center'>₱2.40</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End XI. Diesel/Gasoline Internal Combustion Engine, Gas
                    Turbine/Engine, Hydro, Nuclear or Solar Generating Units and
                    the like */}

                    {/* XII. Compressed air, vacuum, commercial/
                    institutional/industrial gases */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='11'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        xii. Compressed air, vacuum, commercial/
                        institutional/industrial gases
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='11'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  Compressed air, vacuum, commercial/
                                  institutional/industrial gases, per outlet
                                </td>
                                <td className='text-center'>₱10.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Vacuum'
                                    placeholder='Unit'
                                    onChange={handleChange}
                                    value={obj[28].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'Vacuum')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End XII. Compressed air, vacuum, commercial/
                    institutional/industrial gases */}

                    {/* XIII. Power piping for gas/steam/etc. */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='12'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        xiii. Power piping for gas/steam/etc.
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='12'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  Power piping for gas/steam/etc., per lineal
                                  meter or fraction thereof or per cu. meter or
                                  fraction thereof, whichever is higher
                                </td>
                                <td className='text-center'>₱2.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Gas Steam'
                                    placeholder='Unit'
                                    onChange={handleChange}
                                    value={obj[29].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'Gas Steam')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End XIII. Power piping for gas/steam/etc. */}

                    {/* XIV. Other Internal Combustion Engines, including Cranes,
                    Forklifts, Loaders, Mixers, Compressors and the like */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='13'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        xiv. Other Internal Combustion Engines, including
                        Cranes, Forklifts, Loaders, Mixers, Compressors and the
                        like
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='13'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (a) Per unit, up to 10kW
                                </td>
                                <td className='text-center'>₱100.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Forklifts Loaders'
                                    placeholder='a.-b.'
                                    onChange={handleRangeDivA3}
                                    value={obj[30].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Forklifts Loaders'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (b) Every kW above 10kW
                                </td>
                                <td className='text-center'>₱3.00</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    {/* End XIV. Other Internal Combustion Engines, including Cranes,
                    Forklifts, Loaders, Mixers, Compressors and the like*/}

                    {/* XV. Other machineries and/or equipment for commercial/
                    industrial/institutional use not elsewhere specified, per
                    unit */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='14'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        xv. Other machineries and/or equipment for commercial/
                        industrial/institutional use not elsewhere specified,
                        per unit
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='14'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (a) Up to 1/2 kW
                                </td>
                                <td className='text-center'>₱8.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Other Industrial'
                                    placeholder='a.-f.'
                                    onChange={handleRangeDivA12}
                                    value={obj[31].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Other Industrial'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (b) Above 1/2 kW up to 1kW
                                </td>
                                <td className='text-center'>₱23.00</td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (c) Above 1kW up to 3kW
                                </td>
                                <td className='text-center'>₱39.00</td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (d) Above 3kW up to 5kW
                                </td>
                                <td className='text-center'>₱55.00</td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (e) Above 5kW up to 10kW
                                </td>
                                <td className='text-center'>₱80.00</td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (f) Every kW above 10kW or fraction thereof
                                </td>
                                <td className='text-center'>₱4.00</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End XV. Other machineries and/or equipment for commercial/
                    industrial/institutional use not elsewhere specified, per
                    unit*/}

                    {/* XVI. Pressure Vessels */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='15'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        xvi. Pressure Vessels
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='15'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  Pressure Vessels, per cu. Meter or fraction
                                  thereof
                                </td>
                                <td className='text-center'>₱40.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Press Vess'
                                    placeholder='Unit'
                                    onChange={handleChange}
                                    value={obj[32].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'Press Vess')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End XVI. Pressure Vessels*/}

                    {/* XVII. Pneumatic tubes, Conveyors, Monorails for materials
                    handling */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='16'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        xvii. Pneumatic tubes, Conveyors, Monorails for
                        materials handling
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='16'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  Pneumatic tubes, Conveyors, Monorails for
                                  materials handling, per lineal meter or
                                  fraction thereof
                                </td>
                                <td className='text-center'>₱2.40</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Conveyors'
                                    placeholder='Unit'
                                    onChange={handleChange}
                                    value={obj[33].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'Conveyors')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End XVII. Pneumatic tubes, Conveyors, Monorails for materials
                    handling*/}

                    {/* XVIII. Weighing Scale Structure */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='17'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        xviii. Weighing Scale Structure
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='17'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  Weighing Scale Structure, per ton or fraction
                                  thereof
                                </td>
                                <td className='text-center'>₱30.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Weigh Structure'
                                    placeholder='Unit'
                                    onChange={handleChange}
                                    value={obj[34].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Weigh Structure'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End XVIII. Weighing Scale Structure*/}

                    {/*  XIX. Testing/Calibration of pressure gauge, per unit */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='18'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        xix. Testing/Calibration of pressure gauge, per unit
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='18'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  Testing/Calibration of pressure gauge, per
                                  unit
                                </td>
                                <td className='text-center'>₱24.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Calibration'
                                    placeholder='Unit'
                                    onChange={handleChange}
                                    value={obj[35].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Calibration'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  (a) Up to 100 tons capacity
                                </td>
                                <td className='text-center'>₱30.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Test Capacity'
                                    placeholder='Unit'
                                    onChange={handleChange}
                                    value={obj[36].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find(
                                        (el) => el.name === 'Test Capacity'
                                      ).estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End  XIX. Testing/Calibration of pressure gauge, per unit*/}

                    {/*  XX. Every mechanical ride inspection, etc., used in
                    amusement centers of fairs, such as ferris wheel, and the
                    like */}
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey='19'
                        className='accordion-top'
                        style={{
                          background: '#FFF',
                          color: '#000',
                          fontWeight: 'bold',
                        }}
                      >
                        xx. Every mechanical ride inspection, etc., used in
                        amusement centers of fairs, such as ferris wheel, and
                        the like
                        <i className='text-danger'>(click here...)</i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='19'>
                        <Card.Body className='accordion-description'>
                          <Table responsive className='mb-5'>
                            <thead>
                              <tr className='text-center'>
                                <th>#</th>
                                <th>PARTICULARS</th>
                                <th></th>
                                <th>FEE</th>
                                <th className='text-center pr-0'>UNIT</th>
                                <th>EST. FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='text-justify align-middle text-wrap'>
                                <td className='align-middle'></td>
                                <td className='text-wrap' colSpan={2}>
                                  Every mechanical ride inspection, etc., used
                                  in amusement centers of fairs, such as ferris
                                  wheel, and the like, per unit
                                </td>
                                <td className='text-center'>₱30.00</td>
                                <td>
                                  <Form.Control
                                    type='number'
                                    className='border border-secondary align-middle'
                                    style={{
                                      marginLeft: '25px',
                                      marginRight: '25px',
                                    }}
                                    name='Mech Ride'
                                    placeholder='Unit'
                                    onChange={handleChange}
                                    value={obj[37].units}
                                    onKeyDown={(e) =>
                                      e.target.value.length > 14 &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <p className='mb-0'>
                                    ₱
                                    {
                                      obj.find((el) => el.name === 'Mech Ride')
                                        .estimate
                                    }
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* End  XX. Every mechanical ride inspection, etc., used in
                    amusement centers of fairs, such as ferris wheel, and the
                    like*/}
                  </Accordion>
                </Accordion.Collapse>
              </Card>
              {/* End F */}
            </Accordion>

            <Row>
              <Col xl={12} className='mx-3 mt-2'>
                <h5>
                  G. Annual Electronics Inspection Fees shall be the same as the
                  fees in Section 7 of this Schedule.
                </h5>
              </Col>
            </Row>
            <Row className='mt-3 mx-1'>
              <Col xl={8} className='text-end align-middle mt-2'>
                <h4>GRAND TOTAL:</h4>
              </Col>
              <Col xl={4} className='mb-5'>
                <Form.Control
                  type='text'
                  className='border border-secondary'
                  style={{
                    marginLeft: '8px',
                    marginRight: '5px',
                  }}
                  name='GrandTotal'
                  value={grandTotal}
                  placeholder='Grand Total'
                  disabled
                />
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col xl={12} className='text-end'>
                <Button type='submit' variant='success'>
                  Submit
                </Button>
                <Button
                  variant='danger'
                  className='mx-2'
                  onClick={handleBtnCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      </Container>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={createAssessment && createAssessment.loading}
        success={createAssessment && createAssessment.message}
        error={createAssessment && createAssessment.error}
      />
      {/* End Success Modal */}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateModal}
        onHide={() => {
          setModal({ updateModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={updateAssessment && updateAssessment.loading}
        success={updateAssessment && updateAssessment.message}
        error={updateAssessment && updateAssessment.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(AnnualInspectionFeeScreen)
