import React from 'react'

// Style
import { Modal, Button, Spinner } from 'react-bootstrap'
import { FaCheckCircle, FaRegTimesCircle } from 'react-icons/fa'

const SuccessModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} centered backdrop='static'>
      {!props.transLoading && props.success && (
        <>
          <Modal.Header style={{ background: '#28a745' }}>
            <Modal.Title style={{ color: '#FFF' }}>
              <FaCheckCircle className='mx-3' />
              Success
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className='text-center'>{props.success}</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} variant='info'>
              Okay
            </Button>
          </Modal.Footer>
        </>
      )}
      {!props.transLoading && props.error && (
        <>
          <Modal.Header style={{ background: 'red' }}>
            <Modal.Title style={{ color: '#FFF' }}>
              <FaRegTimesCircle className='mx-3' />
              TRANSACTION FAILED
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className='text-center'>{props.error}</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} variant='info'>
              Okay
            </Button>
          </Modal.Footer>
        </>
      )}
      {props.transLoading && (
        <div className='text-center pt-3'>
          <Spinner animation='border' />
          <h3>Loading...</h3>
        </div>
      )}
    </Modal>
  )
}

export default SuccessModal
