import Cookie from 'js-cookie'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { userReducer } from './components/redux/reducers/userReducers'
import { messageReducer } from './components/redux/reducers/messageReducers'
import { userApplicationReducer } from './components/redux/reducers/userApplicationReducers'
import { downloadableformsReducer } from './components/redux/reducers/downloadableformsReducers'
import { newsandupdatesReducer } from './components/redux/reducers/newsandupdatesReducers'
import { appointmentsReducer } from './components/redux/reducers/appointmentsReducers'
import { categoriesReducers } from './components/redux/reducers/categoriesReducers'
import { excavationpermitReducer } from './components/redux/reducers/excavationpermitReducers'
import { userProfileReducer } from './components/redux/reducers/userProfileReducers'
import { adminApplicationReducer } from './components/redux/reducers/adminApplicationReducers'
import { createApplicationsInfoReducer } from './components/redux/reducers/createApplicationInfoReducers'
import { routingSlipReducers } from './components/redux/reducers/routingSlipReducers'
import { inspectionReducers } from './components/redux/reducers/inspectionReducers'
import { evaluatorReducers } from './components/redux/reducers/evaluatorReducers'
import { evaluatorCivilReducers } from './components/redux/reducers/evaluatorCivilReducers'
import { evaluatorArchReducers } from './components/redux/reducers/evaluatorArchReducers'
import { evaluatorElectricalReducers } from './components/redux/reducers/evaluatorElectricalReducers'
import { evaluatorMechReducers } from './components/redux/reducers/evaluatorMechReducers'
import { evaluatorPlumbingReducers } from './components/redux/reducers/evaluatorPlumbingReducers'

// Assessments
import { assessmentReducers } from './components/redux/reducers/assessmentReducers'
import { electronicsAssessmentReducers } from './components/redux/reducers/Assessment/electronicsAssessmentReducers'
import { electricalAssessmentReducers } from './components/redux/reducers/Assessment/electricalAssessmentReducers'
import { mechanicalAssessmentReducers } from './components/redux/reducers/Assessment/mechanicalAssessmentReducers'
import { plumbingAssessmentReducers } from './components/redux/reducers/Assessment/plumbingAssessmentReducers'
import { structuralAssessmentReducers } from './components/redux/reducers/Assessment/structuralAssessmentReducers'
import { renovationAssessmentReducers } from './components/redux/reducers/Assessment/renovationAssessmentReducers'
import { certOccupancyAssessmentReducers } from './components/redux/reducers/Assessment/certOccupancyAssessmentReducers'
import { accessoriesFeeAssessmentReducers } from './components/redux/reducers/Assessment/accessoriesFeeAssessmentReducers'
import { certificatesAssessmentReducers } from './components/redux/reducers/Assessment/certificatesAssessmentReducers'
import { annualInspectionFeeReducers } from './components/redux/reducers/Assessment/annualInspectionFeeReducers'

// E-Supervisor
import { esupervisorReducers } from './components/redux/reducers/Evaluators/Supervisors/supervisorReducers'

// I-Supervisor
import { isupervisorReducers } from './components/redux/reducers/Inspectors/Supervisor/isupervisorReducers'

// Recommender
import { recommenderReducers } from './components/redux/reducers/Recommender/recommenderReducers'

// Administrator
import { administratorReducers } from './components/redux/reducers/Administrator/AdministratorReducers'

// Administrator
import { documentControllerReducers } from './components/redux/reducers/DocumentController/documentControllerReducers'

import { superAdminReducers } from './components/redux/reducers/Super Admin/superAdminReducers'

const reducer = combineReducers({
  users: userReducer,
  messages: messageReducer,
  applications: userApplicationReducer,
  downloadableforms: downloadableformsReducer,
  newsandupdates: newsandupdatesReducer,
  appointments: appointmentsReducer,
  categories: categoriesReducers,
  excavationpermit: excavationpermitReducer,
  userProfile: userProfileReducer,
  adminApplication: adminApplicationReducer,
  adminCreateApplication: createApplicationsInfoReducer,
  routingSlip: routingSlipReducers,
  inspection: inspectionReducers,
  evaluator: evaluatorReducers,
  civilEvaluator: evaluatorCivilReducers,
  archEvaluator: evaluatorArchReducers,
  electricalEvaluator: evaluatorElectricalReducers,
  mechanicalEvaluator: evaluatorMechReducers,
  plumbingEvaluator: evaluatorPlumbingReducers,
  assessments: assessmentReducers,
  electronicsAssessment: electronicsAssessmentReducers,
  electricalAssessment: electricalAssessmentReducers,
  mechanicalAssessment: mechanicalAssessmentReducers,
  plumbingAssessment: plumbingAssessmentReducers,
  structuralAssessment: structuralAssessmentReducers,
  renovationAssessment: renovationAssessmentReducers,
  certOccupancyAssessment: certOccupancyAssessmentReducers,
  accessoriesFeeAssessment: accessoriesFeeAssessmentReducers,
  certificatesAssessment: certificatesAssessmentReducers,
  annualInspectionFee: annualInspectionFeeReducers,
  esupervisor: esupervisorReducers,
  isupervisor: isupervisorReducers,
  recommender: recommenderReducers,
  administratorReducer: administratorReducers,
  documentControllerReducer: documentControllerReducers,
  superAdminReducer: superAdminReducers,
})

const getCookies = Cookie.get('userInfo')
  ? // ? JSON.parse(Cookie.get('userInfo'))
    JSON.parse(localStorage.getItem('userInfo'))
  : null

const getInspectorReport = JSON.parse(localStorage.getItem('reportSelected'))
  ? JSON.parse(localStorage.getItem('reportSelected'))
  : null

const initialState = {
  users: { userCredentials: getCookies },
  inspection: { isUpdating: getInspectorReport },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
 // composeWithDevTools(applyMiddleware(...middleware))
)

export default store
