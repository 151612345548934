import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  Container,
  Table,
  Row,
  Col,
  Pagination,
  Button,
  Card,
  Spinner,
  Modal,
  Form,
  InputGroup,
} from 'react-bootstrap'
import { FaCheck, FaSearch } from 'react-icons/fa'
import moment from 'moment'
import io from 'socket.io-client'
import { withRouter } from 'react-router-dom'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  retrieveUserverifyingList,
  retrieveUserValidID,
  updateVerifyUser,
} from '../../redux/actions/Administrator/administratorActions'

const socket = io.connect(process.env.REACT_APP_URL)

const VerifyUserScreen = () => {
  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    viewManager: false,
    successVerify: false,
  })
  const [payload, setPayload] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [newRegistered, setNewRegistered] = useState('')

  // Pagination Area Forms
  const [list, setList] = useState([])
  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(10)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)

  const administratorReducers = useSelector(
    (state) => state.administratorReducer
  )
  const {
    retrievedUserVerifyingList,
    retrievedUserValidID,
    updatedVerifyUser,
  } = administratorReducers

  useEffect(() => {
    setpaginatedForms(_(list).slice(0).take(pageNumberLimit).value())
    setcurrentPage(1)
  }, [list, pageNumberLimit])

  const pageCount = list ? Math.ceil(list.length / pageNumberLimit) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageNumberLimit
    const paginationForms = _(list)
      .slice(startIndex)
      .take(pageNumberLimit)
      .value()
    setpaginatedForms(paginationForms)
  }

  //   Display Pagination list
  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })
  // End Display Pagination list

  // Buttons
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Buttons

  // End Pagination Area Forms

  const handleView = (x) => {
    setPayload(x)
    setModal({ viewManager: true })
    dispatch(retrieveUserValidID({ userID: x.id }))
  }

  useEffect(() => {
    dispatch(retrieveUserverifyingList())
    socket.on('received_registration', (data) => {
      // if (window.confirm(`${data.message} do you want to reload the page ?`)) {
      dispatch(retrieveUserverifyingList())
      // }
    })
  }, [dispatch, socket])

  useEffect(() => {
    if (retrievedUserVerifyingList && retrievedUserVerifyingList.message) {
      setList(retrievedUserVerifyingList.message)
    }
    if (updatedVerifyUser && updatedVerifyUser.message) {
      socket.emit('send_registration', { message: 'Verified Account' })
    }
  }, [retrievedUserVerifyingList, updatedVerifyUser])

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {retrievedUserVerifyingList && retrievedUserVerifyingList.message && (
            <div>
              {/* <Form>
                <Row className='justify-content-end px-3 pt-3'>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        // onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form> */}
              <Row className='my-2 mx-4'>
                <Col xl={12} className='border border-secondary rounded px-0'>
                  <h5 className='pt-3 pb-2 mb-0'>Users for verification</h5>
                  <hr
                    className='mt-1 mb-3'
                    style={{
                      background: '#ffeb36',
                      paddingBottom: '2px',
                      width: '200px',
                    }}
                  />
                  <Table size='sm' bordered hover responsive>
                    <thead className='bg-info text-light'>
                      <tr className='text-center'>
                        <th>User ID</th>
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Last Name</th>
                        <th>Username</th>
                        <th>Email Address</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedForms.map((x) => (
                        <tr key={x.id} className='text-center'>
                          <td>{x.id}</td>
                          <td>{x.FirstName}</td>
                          <td>{x.MiddleName}</td>
                          <td>{x.LastName}</td>
                          <td>{x.username}</td>
                          <td>{x.EmailAddress}</td>
                          <td>
                            <Button
                              size='sm'
                              variant='success'
                              onClick={() => handleView(x)}
                            >
                              VIEW
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Pagination
                    size='sm'
                    className='d-flex justify-content-end mx-2'
                  >
                    <Pagination.Item
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {renderPageNumber}

                    <Pagination.Item
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </div>
          )}

          {retrievedUserVerifyingList && retrievedUserVerifyingList.loading && (
            <Row className='my-4 mx-1 pt-5'>
              <Col xl={12}>
                <Card>
                  <Card.Body>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {retrievedUserVerifyingList && retrievedUserVerifyingList.error && (
            <Row className='my-4 mx-1 pt-5'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No User For Verification</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      {/* View Manager Modal */}
      <Modal
        size='lg'
        show={modal.viewManager}
        onHide={() => setModal({ viewManager: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            View Manager
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hover responsive size='sm'>
            <tbody>
              <tr>
                <td>USER ID</td>
                <td>{Object.keys(payload).length > 0 && payload.id}</td>
              </tr>
              <tr>
                <td>FIRST NAME</td>
                <td>{Object.keys(payload).length > 0 && payload.FirstName}</td>
              </tr>
              <tr>
                <td>MIDDLE NAME</td>
                <td>{Object.keys(payload).length > 0 && payload.MiddleName}</td>
              </tr>
              <tr>
                <td>LAST NAME</td>
                <td>{Object.keys(payload).length > 0 && payload.LastName}</td>
              </tr>
              <tr>
                <td>GENDER</td>
                <td>{Object.keys(payload).length > 0 && payload.Gender}</td>
              </tr>
              <tr>
                <td>DATE OF BIRTH</td>
                <td>
                  {Object.keys(payload).length > 0 &&
                    moment(payload.DOB).format('MMMM D, YYYY')}
                </td>
              </tr>
              <tr>
                <td>CIVIL STATUS</td>
                <td>
                  {Object.keys(payload).length > 0 && payload.CivilStatus}
                </td>
              </tr>
              <tr>
                <td>MOBILE NO.</td>
                <td>{Object.keys(payload).length > 0 && payload.MobileNo}</td>
              </tr>
              <tr>
                <td>EMAIL ADDRESS</td>
                <td>
                  {Object.keys(payload).length > 0 && payload.EmailAddress}
                </td>
              </tr>
              <tr>
                <td>User Level</td>
                <td>{Object.keys(payload).length > 0 && payload.roles}</td>
              </tr>
              <tr>
                <td>REGISTRATION DATE</td>
                <td>
                  {Object.keys(payload).length > 0 &&
                    moment(payload.TimeStamp).format('MMMM D, YYYY hh:mma')}
                </td>
              </tr>
            </tbody>
          </Table>
          <h6>Valid ID</h6>
          <div>
            {retrievedUserValidID && retrievedUserValidID.loading && (
              <div className='text-center'>
                <Spinner animation='border' />
                <h6>Loading</h6>
              </div>
            )}
            {retrievedUserValidID &&
              retrievedUserValidID.message &&
              retrievedUserValidID.message.map((x, index) => (
                <Row key={index}>
                  <img
                    key={index}
                    className='my-2'
                    src={`data:image/png;base64,${x.Image}`}
                  />
                </Row>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={() => {
              setModal({
                viewManager: false,
              })
            }}
          >
            Close
          </Button>
          <Button
            variant='success'
            onClick={() => {
              if (
                window.confirm(
                  'Are you sure you want to update status of application?'
                )
              ) {
                dispatch(
                  updateVerifyUser({
                    userID: Object.keys(payload).length > 0 && payload.id,
                    email: payload.EmailAddress,
                  })
                )
                setModal({
                  viewManager: false,
                  successVerify: true,
                })
              }
            }}
          >
            Verify
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End View Manager Modal */}

      {/* Success Modal */}
      <SuccessModal
        show={modal.successVerify}
        onHide={() => {
          setModal({ successVerify: false })
          window.location.reload(true)
        }}
        transLoading={updatedVerifyUser && updatedVerifyUser.loading}
        success={updatedVerifyUser && updatedVerifyUser.message}
        error={updatedVerifyUser && updatedVerifyUser.error}
      />
      {/* End  Success Modal */}
    </UserMain>
  )
}

export default withRouter(VerifyUserScreen)
