import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_TOTAL_APP_REQ,
  GET_TOTAL_APP_SUCCESS,
  GET_TOTAL_APP_FAILED,
  GET_EMPLOYEES_REQ,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILED,
  GET_USERS_REQ,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  GET_USERS_FOR_VERIFICATION_REQ,
  GET_USERS_FOR_VERIFICATION_SUCCESS,
  GET_USERS_FOR_VERIFICATION_FAILED,
  GET_REGISTERED_ENG_REQ,
  GET_REGISTERED_ENG_SUCCESS,
  GET_REGISTERED_ENG_FAILED,
  GET_NOTICE_OF_VIOLATION_REQ,
  GET_NOTICE_OF_VIOLATION_SUCCESS,
  GET_NOTICE_OF_VIOLATION_FAILED,
  GET_NEWS_N_UPDATES_REQ,
  GET_NEWS_N_UPDATES_SUCCESS,
  GET_NEWS_N_UPDATES_FAILED,
  GET_MESSAGES_REQ,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILED,
  GET_USERLIST_REQ,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_FAILED,
  GET_APPSUMMARY_REQ,
  GET_APPSUMMARY_SUCCESS,
  GET_APPSUMMARY_FAILED,
  GET_NEWSNUPDATELIST_REQ,
  GET_NEWSNUPDATELIST_SUCCESS,
  GET_NEWSNUPDATELIST_FAILED,
  GET_EMPSIGNATURE_REQ,
  GET_EMPSIGNATURE_SUCCESS,
  GET_EMPSIGNATURE_FAILED,
  UPDATE_DLFORMS_REQ,
  UPDATE_DLFORMS_SUCCESS,
  UPDATE_DLFORMS_FAILED,
  UPDATE_USERPASSWORD_REQ,
  UPDATE_USERPASSWORD_SUCCESS,
  UPDATE_USERPASSWORD_FAILED,
  UPDATE_NEWSNUPDATEINFO_REQ,
  UPDATE_NEWSNUPDATEINFO_SUCCESS,
  UPDATE_NEWSNUPDATEINFO_FAILED,
  UPDATE_EMPSIGNATURE_REQ,
  UPDATE_EMPSIGNATURE_SUCCESS,
  UPDATE_EMPSIGNATURE_FAILED,
  CREATE_DLFORMS_REQ,
  CREATE_DLFORMS_SUCCESS,
  CREATE_DLFORMS_FAILED,
  CREATE_NEWSNUPDATEINFO_REQ,
  CREATE_NEWSNUPDATEINFO_SUCCESS,
  CREATE_NEWSNUPDATEINFO_FAILED,
  CREATE_EMPSIGNATURE_REQ,
  CREATE_EMPSIGNATURE_SUCCESS,
  CREATE_EMPSIGNATURE_FAILED,
  DELETE_DLFORMS_REQ,
  DELETE_DLFORMS_SUCCESS,
  DELETE_DLFORMS_FAILED,
  DELETE_USER_REQ,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_NEWSNUPDATEINFO_REQ,
  DELETE_NEWSNUPDATEINFO_SUCCESS,
  DELETE_NEWSNUPDATEINFO_FAILED,
} from '../../constants/Super Admin/superAdminConstants'

import { downloadableformsRetrieve } from '../downloadableformsActions'

// Retrieved

// Total No. of Application
export const retrieveTApp = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TOTAL_APP_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/applications`,
        config
      )

      dispatch({
        type: GET_TOTAL_APP_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_TOTAL_APP_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Total No. of Employee
export const retrieveTEmployees = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EMPLOYEES_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/employees`,
        config
      )

      dispatch({
        type: GET_EMPLOYEES_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_EMPLOYEES_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Total No. of Users
export const retrieveTUsers = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USERS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/users`,
        config
      )

      dispatch({
        type: GET_USERS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_USERS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Total No. of Users for verification
export const retrieveTUsersForVerification = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USERS_FOR_VERIFICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/users-for-verification`,
        config
      )

      dispatch({
        type: GET_USERS_FOR_VERIFICATION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_USERS_FOR_VERIFICATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Total No. of Registered Engineer
export const retrieveTRegisteredEng = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_REGISTERED_ENG_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/registered-engr`,
        config
      )

      dispatch({
        type: GET_REGISTERED_ENG_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_REGISTERED_ENG_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Total No. of Notice of Violation
export const retrieveTNOV = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_NOTICE_OF_VIOLATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/notice-of-violation`,
        config
      )

      dispatch({
        type: GET_NOTICE_OF_VIOLATION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_NOTICE_OF_VIOLATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Total No. of News N Updates
export const retrieveTNUP = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_NEWS_N_UPDATES_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/news-n-updates`,
        config
      )

      dispatch({
        type: GET_NEWS_N_UPDATES_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_NEWS_N_UPDATES_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Total No. of Message
export const retrieveTMessages = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MESSAGES_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/messages`,
        config
      )

      dispatch({
        type: GET_MESSAGES_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_MESSAGES_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// User List
export const retrieveUserList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USERLIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/user-list`,
        config
      )

      dispatch({
        type: GET_USERLIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_USERLIST_FAILED,
        payload: 'No Registered User Found',
      })
    }
  }
}

// Application Summary
export const retrieveAppSummary = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APPSUMMARY_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/application-summary/${payload.year}`,
        config
      )

      dispatch({
        type: GET_APPSUMMARY_SUCCESS,
        payload: data.result[0],
      })
    } catch {
      dispatch({
        type: GET_APPSUMMARY_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// News N Update List
export const retrieveNewsNUpdateList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_NEWSNUPDATELIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/news-n-update-list`,
        config
      )

      dispatch({
        type: GET_NEWSNUPDATELIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_NEWSNUPDATELIST_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Get Employee Signature
export const retrieveEmpSignature = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EMPSIGNATURE_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/s-admin/employee-signature/${payload.empID}`,
        config
      )

      dispatch({
        type: GET_EMPSIGNATURE_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_EMPSIGNATURE_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// UPDATE

// Update Download Forms
export const updateDlForm = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_DLFORMS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/s-admin/update-dl-forms/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_DLFORMS_SUCCESS,
        payload: data.message,
      })

      dispatch(downloadableformsRetrieve())
    } catch {
      dispatch({
        type: UPDATE_DLFORMS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Update User Password
export const updateUserPassword = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USERPASSWORD_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/s-admin/update-user/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_USERPASSWORD_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveUserList())
    } catch {
      dispatch({
        type: UPDATE_USERPASSWORD_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Update News N Update
export const updateNewsNUpdateInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_NEWSNUPDATEINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      if (payload.url !== null) {
        Object.values(payload.url).forEach((element) => {
          formData.append('images', element)
        })
      } else {
        formData.append('images', [])
      }
      formData.append('header', payload.header)
      formData.append('subheader', payload.subheader)
      formData.append('body', payload.body)
      formData.append('userID', payload.userID)
      formData.append('id', payload.id)

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/s-admin/update-news-n-update`,
        formData,
        config
      )

      dispatch({
        type: UPDATE_NEWSNUPDATEINFO_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveNewsNUpdateList())
    } catch {
      dispatch({
        type: UPDATE_NEWSNUPDATEINFO_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Update News N Update
export const updateEmpSignature = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_EMPSIGNATURE_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      console.log('test Action update')

      const formData = new FormData()
      if (payload.images !== null) {
        Object.values(payload.images).forEach((element) => {
          formData.append('images', element)
        })
      } else {
        formData.append('images', [])
      }
      formData.append('id', payload.id)
      formData.append('empID', payload.empID)

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/s-admin/update-emp-sig`,
        formData,
        config
      )

      dispatch({
        type: UPDATE_EMPSIGNATURE_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveEmpSignature({ empID: payload.empID }))
    } catch {
      dispatch({
        type: UPDATE_EMPSIGNATURE_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

// CREATE

// Create Download Forms
export const createDlForm = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_DLFORMS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/s-admin/create-dl-forms`,
        payload,
        config
      )

      dispatch({
        type: CREATE_DLFORMS_SUCCESS,
        payload: data.message,
      })

      dispatch(downloadableformsRetrieve())
    } catch {
      dispatch({
        type: CREATE_DLFORMS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Create News N Update Info
export const createNewsNUpdateInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_NEWSNUPDATEINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      if (payload.url !== null) {
        Object.values(payload.url).forEach((element) => {
          formData.append('images', element)
        })
      } else {
        formData.append('images', [])
      }

      formData.append('header', payload.header)
      formData.append('subheader', payload.subheader)
      formData.append('body', payload.body)
      formData.append('userID', payload.userID)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/s-admin/create-news-n-update`,
        formData,
        config
      )

      dispatch({
        type: CREATE_NEWSNUPDATEINFO_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveNewsNUpdateList())
    } catch {
      dispatch({
        type: CREATE_NEWSNUPDATEINFO_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Create Employee Signature
export const createEmpSignature = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_EMPSIGNATURE_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      if (payload.images !== null) {
        Object.values(payload.images).forEach((element) => {
          formData.append('images', element)
        })
      } else {
        formData.append('images', [])
      }

      formData.append('empID', payload.empID)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/s-admin/add-signatures`,
        formData,
        config
      )

      dispatch({
        type: CREATE_EMPSIGNATURE_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveEmpSignature({ empID: payload.empID }))
    } catch {
      dispatch({
        type: CREATE_EMPSIGNATURE_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

// DELETE

// Delete Download Forms
export const deleteDlForm = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_DLFORMS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/s-admin/delete-dl-forms/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: DELETE_DLFORMS_SUCCESS,
        payload: data.message,
      })

      dispatch(downloadableformsRetrieve())
    } catch {
      dispatch({
        type: DELETE_DLFORMS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Delete User
export const deleteUser = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_USER_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/s-admin/delete-user/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveUserList())
    } catch {
      dispatch({
        type: DELETE_USER_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// Delete News N Update
export const deleteNewsNUpdate = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_NEWSNUPDATEINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/s-admin/delete-news-n-update/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: DELETE_NEWSNUPDATEINFO_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveNewsNUpdateList())
    } catch {
      dispatch({
        type: DELETE_NEWSNUPDATEINFO_FAILED,
        payload: 'No data found',
      })
    }
  }
}
