import React, { useState, useEffect } from 'react'
import { Table, Button, Col, Pagination, Card } from 'react-bootstrap'
import _ from 'lodash'

// Icons
import { FaDownload } from 'react-icons/fa'

const DownloadableForms = ({ data }) => {
  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)

  // Pagination
  // Table Size
  const pageSize = 10

  // Every render of the page do the statement inside the useEffect
  useEffect(() => {
    // Set the Display size of the Table
    setpaginatedForms(_(data).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [data])

  // Count Number the length of the Array and Divide it to the page size
  const pageCount = data
    ? // Inline if-else statement
      // Math.ceil convert the parameter into the nearest integer value
      Math.ceil(data.length / pageSize)
    : 0

  // if (pageCount === 1) return null
  // Store the page count ito pages array
  // (1, pageCount) means create an area starting from 1 up to the number result of pageCount plus 1
  const pages = _.range(1, pageCount + 1)

  // fat arrow function for onClick in Pagination
  // pagination function will recieve an argument of pageNo so that if you are going to use this function you must submit a pageNo parameter ex. pagination(page)
  const pagination = (pageNo) => {
    // set the current page value to the argument receive by the function
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    // console.log(pageNo)
    // console.log(startIndex)
    const paginationForms = _(data).slice(startIndex).take(pageSize).value()
    setpaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    // setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    // setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Pagination
  return (
    <>
      <Card>
        <Card.Header>
          <h5 className=' pt-4 pb-2'>Downloadable Forms</h5>
          <hr
            className='mt-1 mb-3'
            style={{
              background: '#ffeb36',
              paddingBottom: '2px',
              width: '200px',
            }}
          />
        </Card.Header>
        <Card.Body>
          <Table size='sm' variant='white' bordered hover responsive>
            <thead className='bg-info text-light'>
              <tr>
                <th>File Name</th>
                <th className='text-center'>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedForms.map((paginatedForm) => (
                <tr key={paginatedForm.id}>
                  <td>{paginatedForm.FormName}</td>
                  <td className='text-center'>
                    <Button
                      variant='outline-info'
                      size='sm'
                      onClick={() => window.open(`${paginatedForm.Link}`)}
                    >
                      <FaDownload />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {data.length > 1 && (
            <Col xl={12} className='pt-1 d-flex justify-content-end'>
              <Pagination size='sm'>
                <Pagination.Item
                  onClick={handlePrevbtn}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  &lt;
                </Pagination.Item>

                {renderPageNumber}

                <Pagination.Item
                  onClick={handleNextbtn}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                >
                  &gt;
                </Pagination.Item>
              </Pagination>
            </Col>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default DownloadableForms
