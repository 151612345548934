import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Table,
  Modal,
  Card,
  Spinner,
} from 'react-bootstrap'
import {
  FaEdit,
  FaEllipsisH,
  FaFileAlt,
  FaPencilAlt,
  FaPlusCircle,
  FaSearch,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'
import NoticeOfViolationList from '../../reusable/NoticeOfViolationList'

// Actions
import {
  insertViolation,
  insertViolationDetails,
  updateViolation,
  updateViolationDetails,
  retrieveViolatorList,
  retrieveViolationList,
  retrieveViolationDetails,
} from '../../redux/actions/inspectionActions'

const NoticeOfViolationListScreen = () => {
  // Declaration
  const dispatch = useDispatch()

  const [violation, setViolation] = useState({
    lastName: '',
    firstName: '',
    middleName: '',
    location: '',
    image: null,
    empID: 0,
    isEditting: false,
    id: 0,
    imageIDs: [],
    ctrNo: '',
    offenseNo: '',
    offenseDate: '',
    statusOfActivities: '',
    inspectorRemarks: '',
    owner: '',
    dateReportedOffice: '',
    complied: 0,
    AttachmentID: 0,
    dateReportedRemarks: '',
  })

  const [searchTerm, setSearchTerm] = useState('')

  const [modal, setModal] = useState({
    createViolation: false,
    editViolation: false,
    listOfViolation: false,
    addViolation: false,
    successViolation: false,
    editSuccessViolation: false,
  })

  const [editName, setEditName] = useState(false)

  const users = useSelector((state) => state.users)

  const { userCredentials } = users

  const inspection = useSelector((state) => state.inspection)

  const {
    addViolation,
    retrievedViolatorList,
    retrievedViolationList,
    retrievedViolationDetails,
    edittedViolation,
  } = inspection
  // End Declaration

  // Function
  const handleSubmitViolation = (e) => {
    e.preventDefault()
    dispatch(
      insertViolation({
        owner: `${violation.lastName} ${violation.firstName} ${violation.middleName}`,
        location: violation.location,
        image: violation.image,
        empID: violation.empID,
        ctrNo: violation.ctrNo,
        offenseNo: '1st',
        offenseDate: violation.offenseDate,
        statusOfActivities: violation.statusOfActivities,
        inspectorRemarks: violation.inspectorRemarks,
      })
    )
    setModal({ createViolation: false, successViolation: true })
    setViolation({
      lastName: '',
      firstName: '',
      middleName: '',
      location: '',
      image: null,
      empID: 0,
      isEditting: false,
      id: 0,
      imageIDs: [],
      ctrNo: '',
      offenseNo: '',
      offenseDate: '',
      statusOfActivities: '',
      inspectorRemarks: '',
      owner: '',
      dateReportedOffice: '',
      complied: 0,
      AttachmentID: 0,
      dateReportedRemarks: '',
    })
  }

  const handleChange = (e) => {
    setViolation({
      ...violation,
      [e.target.name]: e.target.value,
      empID: userCredentials.id,
    })
  }

  const handleChangeUploadImage = async (e) => {
    if (violation.isEditting) {
      let files = e.target.files

      if (e.target.files.length > 3) {
        alert(
          'you exceed to the maximum no. of image upload. (Max. of 3 Images only)'
        )
      }

      const iDs = retrievedViolationDetails.message.map(
        (element) => element.AttachmentID
      )

      // console.log(iDs)

      setViolation({
        ...violation,
        image: files,
        imageIDs: iDs,
      })
    } else {
      let files = e.target.files

      if (e.target.files.length > 3) {
        alert(
          'you exceed to the maximum no. of image upload. (Max. of 3 Images only)'
        )
      }

      setViolation({
        ...violation,
        image: files,
      })
    }
  }

  const handleViewViolation = (e) => {
    dispatch(retrieveViolatorList({ id: e.id }))
    setModal({
      listOfViolation: true,
    })

    setViolation({
      ...violation,
      owner: e.owner,
      location: e.location,
      id: e.id,
      // isEditting: true,
    })
  }

  const handleSubmitEditViolation = (e) => {
    e.preventDefault()
    dispatch(
      updateViolation({
        owner: `${violation.lastName} ${violation.firstName} ${violation.middleName}`,
        location: violation.location,
        empID: violation.empID,
        id: violation.id,
      })
    )
    setModal({ editViolation: false, editSuccessViolation: true })
    setViolation({
      lastName: '',
      firstName: '',
      middleName: '',
      location: '',
      image: null,
      empID: 0,
      isEditting: false,
      id: 0,
      imageIDs: [],
      ctrNo: '',
      offenseNo: '',
      offenseDate: '',
      statusOfActivities: '',
      inspectorRemarks: '',
      owner: '',
      dateReportedOffice: '',
      complied: 0,
      AttachmentID: 0,
      dateReportedRemarks: '',
    })
    setEditName(false)
  }

  const handleViewViolationDetail = (value) => {
    dispatch(retrieveViolationDetails({ id: value.id }))
    setModal({
      editViolation: true,
      listOfViolation: false,
    })

    setViolation({
      ...violation,
      isEditting: true,
      ctrNo: value.ctrlNo,
      offenseNo: value.offenseNo,
      offenseDate: value.offenseDate,
      statusOfActivities: value.statusOfActivities,
      inspectorRemarks: value.inspectorRemarks,
      dateReportedOffice:
        value.dateReportedOffice !== null && value.dateReportedOffice,
      dateReportedRemarks: value.dateReportedRemarks,
      complied: value.complied,
      AttachmentID: value.id,
    })
  }
  //   End Function

  // useEffect
  useEffect(() => {
    dispatch(retrieveViolationList())
  }, [dispatch])
  // End useEffect

  // console.log(violation)
  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg'
          style={{ background: '#FFF' }}
        >
          <Row className='mx-1'>
            <Col xl={6}>
              <Button
                size='sm'
                className='mt-4'
                variant='success'
                onClick={() => setModal({ createViolation: true })}
              >
                Create
              </Button>
            </Col>
            {retrievedViolationList && retrievedViolationList.message && (
              <Col xl={6}>
                <Form className='mt-4'>
                  <InputGroup>
                    <Form.Control
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={searchTerm}
                      placeholder='Input search keyword here . . .'
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {searchTerm === '' ? (
                      <InputGroup.Text className='bg-info text-light'>
                        <FaSearch />
                      </InputGroup.Text>
                    ) : (
                      <Button
                        variant='danger'
                        onClick={() => setSearchTerm('')}
                      >
                        x
                      </Button>
                    )}
                  </InputGroup>
                </Form>
              </Col>
            )}
          </Row>
          {retrievedViolationList && retrievedViolationList.message && (
            <>
              <NoticeOfViolationList
                data={retrievedViolationList && retrievedViolationList.message}
                searchTerm={searchTerm}
                handleView={handleViewViolation}
              />
            </>
          )}

          {retrievedViolationList && retrievedViolationList.loading && (
            <Container fluid>
              <Row className='py-5 mx-1'>
                <Col xl={12}>
                  <Card>
                    <Card.Body className='mt-2'>
                      <div className='text-center pt-3'>
                        <Spinner animation='border' />
                        <h3>Loading...</h3>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          )}

          {retrievedViolationList && retrievedViolationList.error && (
            <Row className='py-5 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Violation List Report</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      {/* List Of Violation */}
      <Modal
        size='xl'
        show={modal.listOfViolation}
        onHide={() => setModal({ listOfViolation: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title className='fs-6' style={{ color: '#FFF' }}>
            <FaFileAlt className='mx-3' /> List of Violation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!editName && (
            <div className='d-flex justify-content-between'>
              <div>
                <h6 className='mx-1'>
                  NAME/OWNER: <i>{violation.owner}</i>
                </h6>
                <h6 className='mx-1'>
                  LOCATION: <i>{violation.location}</i>
                </h6>
              </div>
              <div className='text-end'>
                <Button
                  size='sm'
                  variant='info'
                  className='mx-1'
                  onClick={() => setEditName(true)}
                >
                  <FaPencilAlt />
                </Button>
              </div>
            </div>
          )}

          {editName && (
            <Form onSubmit={handleSubmitEditViolation}>
              <Row>
                <Form.Group as={Col} xl='3'>
                  <Form.Label className='fs-6'>Last Name</Form.Label>
                  <Form.Control
                    type='text'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    value={violation.lastName}
                    name='lastName'
                    placeholder='input last name here...'
                    onChange={handleChange}
                    required
                    size='sm'
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label className='fs-6'>First Name</Form.Label>
                  <Form.Control
                    type='text'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    value={violation.firstName}
                    name='firstName'
                    placeholder='input first name here...'
                    onChange={handleChange}
                    required
                    size='sm'
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label className='fs-6'>Middle Name</Form.Label>
                  <Form.Control
                    type='text'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    value={violation.middleName}
                    name='middleName'
                    placeholder='input middle name here...'
                    onChange={handleChange}
                    required
                    size='sm'
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label className='fs-6'>Location</Form.Label>
                  <Form.Control
                    type='text'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    name='location'
                    placeholder='input location here...'
                    onChange={handleChange}
                    required
                    size='sm'
                  />
                </Form.Group>
              </Row>
              <div className='mt-2 d-flex justify-content-end'>
                <Button
                  variant='success'
                  size='sm'
                  className='mx-1'
                  type='submit'
                >
                  Submit
                </Button>
                <Button
                  variant='danger'
                  size='sm'
                  onClick={() => setEditName(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}

          <hr className='mt-1 mb-1' />

          <div className='d-flex justify-content-between align-items-center'>
            <h6 className='mb-0'>ADD VIOLATION</h6>
            <Button
              variant='info'
              size='sm'
              className='mx-1'
              onClick={() => {
                setModal({ addViolation: true })
              }}
            >
              <FaPlusCircle />
            </Button>
          </div>

          <hr className='mb-1 mt-1' />
          <Table
            className='mt-2 shadow-lg'
            size='sm'
            striped
            hover
            bordered
            responsive
          >
            <thead>
              <tr className='text-nowrap text-center'>
                <td>Issued Notice</td>
                <td>Ctr No.</td>
                <td>Date Issued</td>
                <td>Status of Activities</td>
                <td>Date Reported Office</td>
                <td>Inspector Remarks</td>
                <td>Complied</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {retrievedViolatorList &&
                retrievedViolatorList.message &&
                retrievedViolatorList.message.map((value, index) => (
                  <tr key={index}>
                    <td>{value.offenseNo}</td>
                    <td className='text-nowrap'>{value.ctrlNo}</td>
                    <td>{moment(value.offenseDate).format('MMMM DD, YYYY')}</td>
                    <td>{value.statusOfActivities}</td>
                    <td>
                      {value.dateReportedOffice !== null &&
                        moment(value.dateReportedOffice).format(
                          'MMMM DD, YYYY'
                        )}
                    </td>
                    <td>{value.inspectorRemarks}</td>
                    <td>
                      {value.complied !== null
                        ? value.complied !== 0
                          ? 'YES'
                          : 'NO'
                        : 'NO'}
                    </td>
                    <td className='text-center'>
                      <Button
                        variant='outline-success'
                        size='sm'
                        onClick={() => handleViewViolationDetail(value)}
                      >
                        <FaEllipsisH />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={() => setModal({ listOfViolation: false })}
            size='sm'
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Violation */}
      <Modal
        size='lg'
        show={modal.editViolation}
        onHide={() => setModal({ editViolation: false })}
        centered
        backdrop='static'
      >
        <Form>
          <Modal.Header style={{ background: '#28a745' }}>
            <Modal.Title className='fs-6' style={{ color: '#FFF' }}>
              <FaFileAlt className='mx-3' /> Edit Violation Report
            </Modal.Title>
          </Modal.Header>
          {retrievedViolationDetails && retrievedViolationDetails.message && (
            <Modal.Body>
              <Row>
                <Form.Group as={Col} xl='4'>
                  <Form.Label>Issued Notice</Form.Label>
                  <Form.Control
                    as='select'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    value={violation.offenseNo}
                    name='offenseNo'
                    onChange={handleChange}
                    required
                  >
                    <option value=''>Choose here ...</option>
                    <option value='2nd'>2nd</option>
                    <option value='3rd'>3rd</option>
                    <option value='Stoppage Order'>Stoppage Order</option>
                    {userCredentials &&
                      userCredentials.subroles === 'supervisor' && (
                        <option value='Final Letter'>Final Letter</option>
                      )}
                    {userCredentials &&
                      userCredentials.subroles === 'supervisor' && (
                        <option value='Endorsed To Legal'>
                          Endorsed To Legal
                        </option>
                      )}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xl='4'>
                  <Form.Label>Ctr No.</Form.Label>
                  <Form.Control
                    type='text'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    value={violation.ctrNo}
                    name='ctrNo'
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xl='4'>
                  <Form.Label>Date Issued</Form.Label>
                  <Form.Control
                    type='date'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    value={violation.offenseDate}
                    name='offenseDate'
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>

              <Form.Group>
                <Form.Label>Status Of Activities</Form.Label>
                <Form.Control
                  type='textarea'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  value={violation.statusOfActivities}
                  name='statusOfActivities'
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Inspector Remarks</Form.Label>
                <Form.Control
                  type='textarea'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  value={violation.inspectorRemarks}
                  name='inspectorRemarks'
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              {userCredentials && userCredentials.subroles == 'supervisor' && (
                <Row>
                  <Form.Group as={Col} xl='4'>
                    <Form.Label>Date Reported Office</Form.Label>
                    <Form.Control
                      type='date'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={violation.dateReportedOffice}
                      name='dateReportedOffice'
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl='4'>
                    <Form.Label>Report Remarks</Form.Label>
                    <Form.Control
                      as='textarea'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      name='dateReportedRemarks'
                      value={violation.dateReportedRemarks}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl='2'>
                    <Form.Label></Form.Label>
                    <Form.Check
                      className='mt-3'
                      label='Complied'
                      required
                      checked={violation.complied !== 0 ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setViolation({
                            ...violation,
                            complied: 1,
                          })
                        } else {
                          setViolation({
                            ...violation,
                            complied: 0,
                          })
                        }
                      }}
                    />
                  </Form.Group>
                </Row>
              )}

              <Form.Group>
                <Form.Label>Attachments</Form.Label>
                <Form.File
                  id='image-file'
                  size='sm'
                  className='my-2'
                  multiple
                  onChange={handleChangeUploadImage}
                />
                <h6 className='mt-3'>
                  Note:
                  <i>
                    Maximum of 3 images only, and Maximum size of 25 mb per
                    image.
                  </i>
                </h6>
              </Form.Group>
              {retrievedViolationDetails.message.map((el, index) => (
                <Row key={index}>
                  <img
                    className='py-2'
                    src={`data:image/png;base64,${el.image}`}
                  />
                </Row>
              ))}
            </Modal.Body>
          )}

          {retrievedViolationDetails && retrievedViolationDetails.loading && (
            <Modal.Body>
              <Row className='my-4 mx-1'>
                <Col xl={12}>
                  <Card>
                    <Card.Body className='mt-2'>
                      <div className='text-center pt-3'>
                        <Spinner animation='border' />
                        <h3>Loading...</h3>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Modal.Body>
          )}

          {retrievedViolationDetails && retrievedViolationDetails.error && (
            <Modal.Body>
              <Row className='my-4 mx-1'>
                <Col xl={12}>
                  <Card>
                    <Card.Body className='mt-2'>
                      <div className='text-center pt-3'>
                        <h3>404 Not Found</h3>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Modal.Body>
          )}
          <Modal.Footer>
            {retrievedViolationDetails && retrievedViolationDetails.message && (
              <Button
                variant='info'
                type='submit'
                size='sm'
                onClick={(e) => {
                  if (window.confirm('Are you sure you want to update?')) {
                    e.preventDefault()
                    dispatch(
                      updateViolationDetails({
                        image: violation.image,
                        imageIDs: violation.imageIDs,
                        ctrlNo: violation.ctrNo,
                        offenseNo: violation.offenseNo,
                        offenseDate: violation.offenseDate,
                        statusOfActivities: violation.statusOfActivities,
                        inspectorRemarks: violation.inspectorRemarks,
                        dateReportedOffice: violation.dateReportedOffice,
                        dateReportedRemarks: violation.dateReportedRemarks,
                        complied: violation.complied,
                        id: violation.AttachmentID,
                      })
                    )
                    setModal({
                      ...modal,
                      editViolation: false,
                    })

                    setViolation({
                      lastName: '',
                      firstName: '',
                      middleName: '',
                      location: '',
                      image: null,
                      empID: 0,
                      isEditting: false,
                      id: 0,
                      imageIDs: [],
                      ctrNo: '',
                      offenseNo: '',
                      offenseDate: '',
                      statusOfActivities: '',
                      inspectorRemarks: '',
                      owner: '',
                      dateReportedOffice: '',
                      complied: 0,
                      AttachmentID: 0,
                      dateReportedRemarks: '',
                    })
                  }
                  e.preventDefault()
                }}
              >
                Submit
              </Button>
            )}

            <Button
              variant='danger'
              onClick={() => {
                setModal({ editViolation: false, listOfViolation: true })
                setViolation({
                  lastName: '',
                  firstName: '',
                  middleName: '',
                  location: '',
                  image: null,
                  empID: 0,
                  isEditting: false,
                  id: 0,
                  imageIDs: [],
                  ctrNo: '',
                  offenseNo: '',
                  offenseDate: '',
                  statusOfActivities: '',
                  inspectorRemarks: '',
                  owner: '',
                  dateReportedOffice: '',
                  complied: 0,
                  AttachmentID: 0,
                  dateReportedRemarks: '',
                })
              }}
              size='sm'
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Create Violation */}
      <Modal
        size='lg'
        show={modal.createViolation}
        onHide={() => setModal({ createViolation: false })}
        centered
        backdrop='static'
      >
        <Form onSubmit={handleSubmitViolation}>
          <Modal.Header style={{ background: '#28a745' }}>
            <Modal.Title className='fs-6' style={{ color: '#FFF' }}>
              <FaFileAlt className='mx-3' /> Create Violation Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label className='mb-0'>Owner/Name</Form.Label>
            <hr
              className='mt-1 mb-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <Row>
              <Form.Group as={Col} xl='4'>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type='text'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  value={violation.lastName}
                  name='lastName'
                  placeholder='input last name here...'
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl='4'>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type='text'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  value={violation.firstName}
                  name='firstName'
                  placeholder='input first name here...'
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl='4'>
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  type='text'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  value={violation.middleName}
                  name='middleName'
                  placeholder='input middle name here...'
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl='4'>
                <Form.Label>CTR No.</Form.Label>
                <Form.Control
                  type='textarea'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  name='ctrNo'
                  value={violation.ctrNo}
                  onChange={handleChange}
                  placeholder='input ctr no. here...'
                  required
                />
              </Form.Group>

              <Form.Group as={Col} xl='4'>
                <Form.Label>Date Issued</Form.Label>
                <Form.Control
                  type='date'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  name='offenseDate'
                  value={violation.offenseDate}
                  onChange={handleChange}
                  placeholder='input address of the location here...'
                  required
                />
              </Form.Group>
            </Row>

            <Form.Group>
              <Form.Label>Location</Form.Label>
              <Form.Control
                type='textarea'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                name='location'
                value={violation.location}
                onChange={handleChange}
                placeholder='input address of the location here...'
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Status of Activities</Form.Label>
              <Form.Control
                as='textarea'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                name='statusOfActivities'
                value={violation.statusOfActivities}
                onChange={handleChange}
                placeholder='input address of the location here...'
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Inspector Remarks</Form.Label>
              <Form.Control
                as='textarea'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                name='inspectorRemarks'
                value={violation.inspectorRemarks}
                onChange={handleChange}
                placeholder='input address of the location here...'
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Attachments</Form.Label>
              <Form.File
                id='image-file'
                size='sm'
                className='my-2'
                multiple
                onChange={handleChangeUploadImage}
                required
              />
              <h6 className='mt-3'>
                Note:
                <i>
                  Maximum of 3 images only, and Maximum size of 25 mb per image.
                </i>
              </h6>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => setModal({ createViolation: false })}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/*Add New Violation */}
      <Modal
        size='md'
        show={modal.addViolation}
        onHide={() => setModal({ addViolation: false })}
        centered
        backdrop='static'
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            dispatch(
              insertViolationDetails({
                image: violation.image,
                novID: violation.id,
                offenseNo: violation.offenseNo,
                ctrNo: violation.ctrNo,
                offenseDate: violation.offenseDate,
                statusOfActivities: violation.statusOfActivities,
                inspectorRemarks: violation.inspectorRemarks,
                empID: violation.empID,
              })
            )
            setModal({
              listOfViolation: true,
              addViolation: false,
            })
          }}
        >
          <Modal.Header style={{ background: '#28a745' }}>
            <Modal.Title style={{ color: '#FFF' }}>
              <FaFileAlt className='mx-3' /> Add Violation 1
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group as={Col} xl='4'>
                <Form.Label>Issued Notice:</Form.Label>
                <Form.Control
                  as='select'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  name='offenseNo'
                  value={violation.offenseNo}
                  onChange={handleChange}
                  placeholder='input ctr no. here...'
                  required
                >
                  <option value=''>Choose here ...</option>
                  <option value='2nd'>2nd</option>
                  <option value='3rd'>3rd</option>
                  <option value='Stoppage Order'>Stoppage Order</option>
                  {userCredentials &&
                    userCredentials.subroles === 'supervisor' && (
                      <option value='Final Letter'>Final Letter</option>
                    )}
                  {userCredentials &&
                    userCredentials.subroles === 'supervisor' && (
                      <option value='Endorsed To Legal'>
                        Endorsed To Legal
                      </option>
                    )}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} xl='4'>
                <Form.Label>CTR No.</Form.Label>
                <Form.Control
                  type='textarea'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  name='ctrNo'
                  value={violation.ctrNo}
                  onChange={handleChange}
                  placeholder='input ctr no. here...'
                  required
                />
              </Form.Group>

              <Form.Group as={Col} xl='4'>
                <Form.Label>Date Issued</Form.Label>
                <Form.Control
                  type='date'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  name='offenseDate'
                  value={violation.offenseDate}
                  onChange={handleChange}
                  placeholder='input address of the location here...'
                  required
                />
              </Form.Group>
            </Row>

            <Form.Group>
              <Form.Label>Status of Activities</Form.Label>
              <Form.Control
                as='textarea'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                name='statusOfActivities'
                value={violation.statusOfActivities}
                onChange={handleChange}
                placeholder='input address of the location here...'
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Inspector Remarks</Form.Label>
              <Form.Control
                as='textarea'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                name='inspectorRemarks'
                value={violation.inspectorRemarks}
                onChange={handleChange}
                placeholder='input address of the location here...'
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Attachments</Form.Label>
              <Form.File
                id='image-file'
                size='sm'
                className='my-2'
                multiple
                onChange={handleChangeUploadImage}
                required
              />
              <h6 className='mt-3'>
                Note:
                <i>
                  Maximum of 3 images only, and Maximum size of 25 mb per image.
                </i>
              </h6>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' size='sm' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setModal({ addViolation: false, listOfViolation: true })
                setViolation({
                  ...violation,
                  owner: '',
                  location: '',
                  isEditting: false,
                })
              }}
              size='sm'
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successViolation}
        onHide={() => {
          setModal({ successViolation: false })
        }}
        transLoading={addViolation && addViolation.loading}
        success={addViolation && addViolation.message}
        error={addViolation && addViolation.error}
      />
      {/* End Success Modal */}

      {/*Edit Success Modal */}
      <SuccessModal
        show={modal.editSuccessViolation}
        onHide={() => {
          setModal({ editSuccessViolation: false })
        }}
        transLoading={edittedViolation && edittedViolation.loading}
        success={edittedViolation && edittedViolation.message}
        error={edittedViolation && edittedViolation.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default NoticeOfViolationListScreen
