import React, { useEffect, useState } from 'react'
import { Row, Col, Image, Form, Button, Spinner } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  retrieveResetPassword,
  userResetPassword,
} from '../redux/actions/userActions'

// Layout
import { FaCheck, FaTimes } from 'react-icons/fa'

const ResetPasswordScreen = ({ match, history }) => {
  //Declaration
  const dispatch = useDispatch()

  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
  })

  const [submitBtn, setSubmitBtn] = useState(true)

  const users = useSelector((state) => state.users)
  const { retrievedResetPassword, updatedPassword } = users
  // End Declarataio

  // Functions
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(
      userResetPassword({
        id: match.params.id,
        token: match.params.token,
        password: state.password,
      })
    )
  }
  // End Functions

  // useEffect
  useEffect(() => {
    dispatch(
      retrieveResetPassword({ id: match.params.id, token: match.params.token })
    )
    setInterval(() => {
      dispatch(
        retrieveResetPassword({
          id: match.params.id,
          token: match.params.token,
          password: state.password,
        })
      )
    }, [300000])
  }, [dispatch])

  useEffect(() => {
    if (state.password !== '' && state.password === state.confirmPassword) {
      setSubmitBtn(false)
    } else {
      setSubmitBtn(true)
    }
    if (updatedPassword && updatedPassword.message) {
      setState({
        password: '',
        confirmPassword: '',
      })
      setSubmitBtn(true)
    }
  }, [state.password, state.confirmPassword, updatedPassword])

  //console.log(state)
  return (
    <>
      <div
        className='login-main d-flex justify-content-center align-items-center bg-img center-vh'
        style={{ backgroundImage: 'url(../../assets/img/login-bg.png)' }}
      >
        <Row>
          <Col md={12}>
            <div className='border border-secondary shadow-lg p-3 pb-5 bg-white'>
              {retrievedResetPassword && retrievedResetPassword.message && (
                <Row className='pt-2'>
                  <Col md={12} className='text-center'>
                    <div className='py-3'>
                      <Image
                        src='../../assets/logo/Ootb-logo.png'
                        roundedCircle
                        width='80'
                        height='80'
                        className='shadow-lg'
                      />
                    </div>

                    <h3 className='pt-3'>RESET PASSWORD</h3>
                  </Col>
                  <Col md={12} className='px-5 py-2'>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className='py-2'>
                        <Form.Label>
                          <h6>New Password</h6>
                        </Form.Label>
                        <Form.Control
                          type='password'
                          placeholder='input new password here ...'
                          name='password'
                          style={{
                            background: '#FFF',
                            border: '1px solid #ced4da',
                          }}
                          onChange={(e) => handleChange(e)}
                          value={state.password}
                        />
                      </Form.Group>
                      <Form.Group className='py-2'>
                        <Form.Label>
                          <h6>Confirm Password</h6>
                        </Form.Label>
                        <Form.Control
                          type='password'
                          placeholder='input confirm password here ...'
                          name='confirmPassword'
                          style={{
                            background: '#FFF',
                            border: '1px solid #ced4da',
                          }}
                          onChange={handleChange}
                          value={state.confirmPassword}
                        />
                      </Form.Group>
                      {state.password !== '' && (
                        <>
                          {state.password === state.confirmPassword ? (
                            <div className='d-flex'>
                              <h6 className='text-success mt-1'>
                                Password and Confirm Password matched
                              </h6>
                              <FaCheck className='text-success' />
                            </div>
                          ) : (
                            <div className='d-flex'>
                              <h6 className='text-danger mt-1'>
                                Password and Confirm Password mismatched
                              </h6>
                              <FaTimes className='text-danger' />
                            </div>
                          )}
                        </>
                      )}

                      <div className='d-grid gap-2 mt-3'>
                        <Button
                          disabled={submitBtn}
                          variant='success'
                          type='submit'
                        >
                          Submit
                        </Button>
                      </div>

                      {updatedPassword && updatedPassword.message && (
                        <div className='d-grid gap-2 mt-3'>
                          <Button
                            variant='danger'
                            type='button'
                            onClick={() => history.push('/')}
                          >
                            Return
                          </Button>
                        </div>
                      )}
                    </Form>
                  </Col>
                </Row>
              )}

              {retrievedResetPassword && retrievedResetPassword.error && (
                <Row className='pt-2'>
                  <Col md={12} className='text-center'>
                    <div className='py-3'>
                      <Image
                        src='../../assets/logo/Ootb-logo.png'
                        roundedCircle
                        width='80'
                        height='80'
                        className='shadow-lg'
                      />
                    </div>

                    <h3 className='pt-3'>TOKEN EXPIRED</h3>
                  </Col>
                  <Col md={12} className='px-5 py-2'>
                    <div className='d-grid gap-2 mt-3'>
                      <Button
                        variant='danger'
                        type='button'
                        onClick={() => history.push('/')}
                      >
                        RETURN
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}

              {retrievedResetPassword && retrievedResetPassword.loading && (
                <Row className='pt-2'>
                  <Col md={12} className='text-center'>
                    <div className='py-3'>
                      <Image
                        src='../../assets/logo/Ootb-logo.png'
                        roundedCircle
                        width='80'
                        height='80'
                        className='shadow-lg'
                      />
                    </div>

                    <Spinner className='mt-3' animation='border' />
                  </Col>
                  <Col md={12} className='px-5 py-2'>
                    <div className='d-grid gap-2 mt-3'>
                      <Button
                        variant='danger'
                        type='button'
                        onClick={() => history.push('/')}
                      >
                        RETURN
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default withRouter(ResetPasswordScreen)
