import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Modal } from 'react-bootstrap'
import GuestMain from '../layout/GuestLayout/GuestMain'

// Redux Requirements
import { useSelector } from 'react-redux'
import { FaQuestionCircle } from 'react-icons/fa'

const HomeScreen = ({ history, location }) => {
  const user = useSelector((state) => state.users)
  const { userCredentials, userlogout } = user

  const [view, setView] = useState(true)

  const redirect = location.search
    ? location.search.split('=')[1]
    : '/dashboard'

  useEffect(() => {
    if (userlogout && !userlogout.loading) {
      if (userlogout.message) {
        history.push('/')
        window.location.reload()
      }
    } else {
      if (userCredentials) {
        history.push(redirect)
      }
    }
  }, [userCredentials, history, redirect, userlogout])

  const handleDownload = () => {
    // Create a link element
    const link = document.createElement('a')
    // Set the href attribute to the path of the PDF file
    link.href = `${process.env.PUBLIC_URL}/assets/files/Online_Manual.pdf`
    // Set the download attribute with the desired filename
    link.download = 'manual.pdf'
    // Append the link to the body (required for Firefox)
    document.body.appendChild(link)
    // Programmatically click the link to trigger the download
    link.click()
    // Remove the link from the body
    document.body.removeChild(link)
  }

  return (
    <GuestMain>
      <Container fluid style={{ background: '#f9f6f1', color: '#00304b' }}>
        <Row>
          <Col
            as='div'
            className='image-banner'
            style={{ backgroundImage: 'url(./assets/img/home-bg.webp)' }}
          >
            <Container fluid className='login-form'>
              <Row>
                <Col as='div' className='d-flex justify-content-end mr-5 mt-5'>
                  <h4 style={{ color: '#1a1a1a' }}>
                    TRY OUR ONLINE BUILDING PERMIT & CERTIFICATE OF OCCUPANCY
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col as='div' className='d-flex justify-content-end mt-4'>
                  <h1 style={{ color: '#1a1a1a' }}>Register Online Now!</h1>
                </Col>
              </Row>
              <Row>
                <Col as='div' className='d-flex justify-content-end mr-5 mt-4'>
                  <Button
                    onClick={() => {
                      history.push('/registration')
                    }}
                    variant='success'
                  >
                    Register
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Container fluid>
          <Row className='article-main'>
            <Col xs={12} xl={1} className='article-logo mr-4 my-4'>
              <img src='assets/logo/logo-main.webp' alt='/' className='mr-2' />
              <img src='assets/logo/OurhomeLogo.webp' alt='/' />
            </Col>
            <Col xs={12} xl={2} className='article-text'>
              <h6>City of Las Piñas</h6>
              <p>
                Alabang-Zapote Rd, Las Piñas
                <br /> NCR Philippines <br />
                laspinascitygov@yahoo.com <br />
                Monday - Friday | 8am - 5pm
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Modal
        show={view}
        onHide={() => setView(false)}
        backdrop='static'
        size='xl'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaQuestionCircle className='mx-3' />
            How to Apply Building / Occupancy Permit Online
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <img src='assets/img/flowchart.png' alt='/' />
          </Row>
          <Button className='mx-3' size='sm' onClick={handleDownload}>
            Download Manual
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' variant='danger' onClick={() => setView(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </GuestMain>
  )
}

export default HomeScreen
