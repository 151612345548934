import React from 'react'

const FaqFileSecurity = () => {
  return (
    <div>
      <h6>
        The security of your files will depend on the cloud storage you will
        choose, just make sure to share files on our official email{' '}
        <b>"engineeringoffice.laspinas@gmail.com"</b>
      </h6>
      <p style={{ marginLeft: '20px' }}>
        Example of cloud storage: Google Drive, OneDrive, and WeTransfer
      </p>

      <h6>
        Once the application is <b>verified</b>, our staff will download all the
        files and requirements you submitted will be scanned and stored in our
        cloud storage and servers
      </h6>
    </div>
  )
}

export default FaqFileSecurity
