import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_FORAPPROVAL_REQ,
  GET_FORAPPROVAL_SUCCESS,
  GET_FORAPPROVAL_FAILED,
  GET_APPROVEDAPPLICATIONS_REQ,
  GET_APPROVEDAPPLICATIONS_SUCCESS,
  GET_APPROVEDAPPLICATIONS_FAILED,
  UPDATE_APPLICATION_STATUS_REQ,
  UPDATE_APPLICATION_STATUS_SUCCESS,
  UPDATE_APPLICATION_STATUS_FAILED,
  GET_COMMENTSLIST_REQ,
  GET_COMMENTSLIST_SUCCESS,
  GET_COMMENTSLIST_FAILED,
  GET_APPLICATIONSLIST_REQ,
  GET_APPLICATIONSLIST_SUCCESS,
  GET_APPLICATIONSLIST_FAILED,
  GET_ORDEROFPAYMENT_REQ,
  GET_ORDEROFPAYMENT_SUCCESS,
  GET_ORDEROFPAYMENT_FAILED,
  GET_PERMITNO_REQ,
  GET_PERMITNO_SUCCESS,
  GET_PERMITNO_FAILED,
  GET_EMPLOYEELIST_REQ,
  GET_EMPLOYEELIST_SUCCESS,
  GET_EMPLOYEELIST_FAILED,
  GET_FORAPPROVALOP_REQ,
  GET_FORAPPROVALOP_SUCCESS,
  GET_FORAPPROVALOP_FAILED,
  GET_USERLIST_REQ,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_FAILED,
  GET_VALIDID_REQ,
  GET_VALIDID_SUCCESS,
  GET_VALIDID_FAILED,
  GET_ANNUALCOLLECTION_REQ,
  GET_ANNUALCOLLECTION_SUCCESS,
  GET_ANNUALCOLLECTION_FAILED,
  UPDATE_EMPLOYEE_INFO_REQ,
  UPDATE_EMPLOYEE_INFO_SUCCESS,
  UPDATE_EMPLOYEE_INFO_FAILED,
  UPDATE_VERIFYUSER_REQ,
  UPDATE_VERIFYUSER_SUCCESS,
  UPDATE_VERIFYUSER_FAILED,
  GET_EMPLOYEEINFO_REQ,
  GET_EMPLOYEEINFO_SUCCESS,
  GET_EMPLOYEEINFO_FAILED,
  INSERT_EMPLOYEE_INFO_REQ,
  INSERT_EMPLOYEE_INFO_SUCCESS,
  INSERT_EMPLOYEE_INFO_FAILED,
  DELETE_EMPLOYEE_INFO_REQ,
  DELETE_EMPLOYEE_INFO_SUCCESS,
  DELETE_EMPLOYEE_INFO_FAILED,
} from '../../constants/Administrator/AdministratorConstants'

export const retrieveForApprovalList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FORAPPROVAL_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/for-approval-list`,
        config
      )

      dispatch({
        type: GET_FORAPPROVAL_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_FORAPPROVAL_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveApprovedList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APPROVEDAPPLICATIONS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/approved-list`,
        config
      )

      dispatch({
        type: GET_APPROVEDAPPLICATIONS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_APPROVEDAPPLICATIONS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveApplicationList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APPLICATIONSLIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/applications-list`,
        config
      )

      dispatch({
        type: GET_APPLICATIONSLIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_APPLICATIONSLIST_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveCommentsList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COMMENTSLIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/comments-list/${payload.id}`,
        config
      )

      dispatch({
        type: GET_COMMENTSLIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_COMMENTSLIST_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveOrderofPayment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ORDEROFPAYMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/orderofpayment/${payload.id}`,
        config
      )

      dispatch({
        type: GET_ORDEROFPAYMENT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ORDEROFPAYMENT_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveForApprovalOp = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FORAPPROVALOP_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/for-approval-op/${payload.id}`,
        config
      )

      dispatch({
        type: GET_FORAPPROVALOP_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_FORAPPROVALOP_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrievePermitNo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PERMITNO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/permitno/${payload.id}`,
        config
      )

      dispatch({
        type: GET_PERMITNO_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_PERMITNO_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveEmployeeList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EMPLOYEELIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/employee-list`,
        config
      )

      dispatch({
        type: GET_EMPLOYEELIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_EMPLOYEELIST_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveEmployeeInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EMPLOYEEINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/employee-info/${payload.id}`,
        config
      )

      dispatch({
        type: GET_EMPLOYEEINFO_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_EMPLOYEEINFO_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveUserverifyingList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USERLIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/user-verifying-list`,
        config
      )

      dispatch({
        type: GET_USERLIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_USERLIST_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveUserValidID = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VALIDID_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/user-id/${payload.userID}`,
        config
      )

      dispatch({
        type: GET_VALIDID_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_VALIDID_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveAnnualCollection = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ANNUALCOLLECTION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrator/annual-collection/${payload.year}`,
        config
      )

      dispatch({
        type: GET_ANNUALCOLLECTION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ANNUALCOLLECTION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateApplicationStatus = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_APPLICATION_STATUS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/administrator/update-status/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_APPLICATION_STATUS_SUCCESS,
        payload: data.message,
      })
      // YmxkZ29AbGFzcGluYXNjaXR5Lmdvdi5waDpFbmdpbmVlcmluZzIwMjMh
      const textConfig = {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Basic bWlzQGxwLWJsZGdvLmNvbTpFbmdpbmVlcmluZzIwMjMh',
          'X-MEMS-API-ID': '45745164',
          'X-MEMS-API-KEY': 'd9b33ea724fb4e2f810232a406bb1062',
        },
      }

      const message =
        'The status of your application in Office of the Building Official in City Governement of Las Piñas has changed; Please login to your account at lp-bldgo.com website to check the application.'

      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/messaging`,
        params: {
          extensions: `destination=63${payload.mobileNo}&text=${message}`,
        },
        withCredentials: false,
      })
    } catch {
      dispatch({
        type: UPDATE_APPLICATION_STATUS_FAILED,
        payload: 'Failed to Update Application Status',
      })
    }
  }
}

export const updateEmployeeInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_EMPLOYEE_INFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/administrator/employee-info/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_EMPLOYEE_INFO_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_EMPLOYEE_INFO_FAILED,
        payload: 'Failed to Update Employee Information',
      })
    }
  }
}

export const updateVerifyUser = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_VERIFYUSER_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/administrator/verify-user`,
        { userID: payload.userID, email: payload.email },
        config
      )

      dispatch({
        type: UPDATE_VERIFYUSER_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_VERIFYUSER_FAILED,
        payload: 'Failed to Update Employee Information',
      })
    }
  }
}

export const createEmployeeInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_EMPLOYEE_INFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/administrator/employee-info`,
        payload,
        config
      )

      dispatch({
        type: INSERT_EMPLOYEE_INFO_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_EMPLOYEE_INFO_FAILED,
        payload: 'Failed to Add Employee',
      })
    }
  }
}

export const deleteEmployeeInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_EMPLOYEE_INFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/administrator/employee-info/${payload.id}`,
        config
      )

      dispatch({
        type: DELETE_EMPLOYEE_INFO_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: DELETE_EMPLOYEE_INFO_FAILED,
        payload: 'Failed to Delete Employee',
      })
    }
  }
}
