import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILED,
  GET_ENGRPROFILE_REQUEST,
  GET_ENGRPROFILE_SUCCESS,
  GET_ENGRPROFILE_FAILED,
  GET_REGION_REQ,
  GET_REGION_SUCCESS,
  GET_REGION_FAILED,
  GET_CITIES_REQ,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILED,
  GET_BARANGAY_REQ,
  GET_BARANGAY_SUCCESS,
  GET_BARANGAY_FAILED,
  USER_UPDATE_BASICINFO_REQ,
  USER_UPDATE_BASICINFO_SUCCESS,
  USER_UPDATE_BASICINFO_FAILED,
  USER_UPDATE_PERADDRESS_REQ,
  USER_UPDATE_PERADDRESS_SUCCESS,
  USER_UPDATE_PERADDRESS_FAILED,
  USER_UPDATE_PREADDRESS_REQ,
  USER_UPDATE_PREADDRESS_SUCCESS,
  USER_UPDATE_PREADDRESS_FAILED,
  USER_UPDATE_PROFESSIONINFO_REQ,
  USER_UPDATE_PROFESSIONINFO_SUCCESS,
  USER_UPDATE_PROFESSIONINFO_FAILED,
  USER_UPDATE_USERCREDENTIALS_REQ,
  USER_UPDATE_USERCREDENTIALS_SUCCESS,
  USER_UPDATE_USERCREDENTIALS_FAILED,
  USER_DELETE_USER_REQ,
  USER_DELETE_USER_SUCCESS,
  USER_DELETE_USER_FAILED,
  USER_ACCOUNT_VER_REQ,
  USER_ACCOUNT_VER_SUCCESS,
  USER_ACCOUNT_VER_FAILED,
  UPDATE_ENGRPROFINFO_REQ,
  UPDATE_ENGRPROFINFO_SUCCESS,
  UPDATE_ENGRPROFINFO_FAILED,
  GET_ENGATTACHMENTS_REQ,
  GET_ENGATTACHMENTS_SUCCESS,
  GET_ENGATTACHMENTS_FAILED,
} from '../constants/userProfileConstants'

export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return { ...state, loading: true }
    case USER_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: action.payload }
    case USER_PROFILE_FAILED:
      return { ...state, loading: false, error: action.payload }
    case USER_UPDATE_BASICINFO_REQ:
      return {
        ...state,
        message: { loading: true },
      }
    case GET_ENGRPROFILE_REQUEST:
      return { ...state, retrievedEngrInfo: { loading: true } }
    case GET_ENGRPROFILE_SUCCESS:
      return {
        ...state,
        retrievedEngrInfo: { loading: false, message: action.payload },
      }
    case GET_ENGRPROFILE_FAILED:
      return {
        ...state,
        retrievedEngrInfo: { loading: false, error: action.payload },
      }
    case GET_REGION_REQ:
      return { ...state, retrievedRegion: { loading: true } }
    case GET_REGION_SUCCESS:
      return {
        ...state,
        retrievedRegion: { loading: false, message: action.payload },
      }
    case GET_REGION_FAILED:
      return {
        ...state,
        retrievedRegion: { loading: false, error: action.payload },
      }
    case USER_UPDATE_BASICINFO_SUCCESS:
      return {
        ...state,
        message: { loading: false, message: action.payload },
      }
    case USER_UPDATE_BASICINFO_FAILED:
      return {
        ...state,
        message: { loading: false, error: action.payload },
      }
    case USER_UPDATE_PERADDRESS_REQ:
      return {
        ...state,
        message: { loading: true },
      }
    case USER_UPDATE_PERADDRESS_SUCCESS:
      return {
        loading: false,
        profile: state.profile,
        message: { loading: false, message: action.payload },
      }
    case USER_UPDATE_PERADDRESS_FAILED:
      return {
        loading: false,
        profile: state.profile,
        message: { loading: false, error: action.payload },
      }
    case USER_UPDATE_PREADDRESS_REQ:
      return {
        loading: true,
        profile: state.profile,
        message: { loading: true },
      }
    case USER_UPDATE_PREADDRESS_SUCCESS:
      return {
        loading: false,
        profile: state.profile,
        message: { loading: false, message: action.payload },
      }
    case USER_UPDATE_PREADDRESS_FAILED:
      return {
        loading: false,
        profile: state.profile,
        message: { loading: false, error: action.payload },
      }
    case USER_UPDATE_PROFESSIONINFO_REQ:
      return {
        loading: true,
        profile: state.profile,
        message: { loading: true },
      }
    case USER_UPDATE_PROFESSIONINFO_SUCCESS:
      return {
        loading: false,
        profile: state.profile,
        message: { loading: false, message: action.payload },
      }
    case USER_UPDATE_PROFESSIONINFO_FAILED:
      return {
        loading: false,
        profile: state.profile,
        message: { loading: false, error: action.payload },
      }
    case USER_UPDATE_USERCREDENTIALS_REQ:
      return {
        ...state,
        updatedUserCredentials: { loading: true },
      }
    case USER_UPDATE_USERCREDENTIALS_SUCCESS:
      return {
        ...state,
        updatedUserCredentials: { loading: false, message: action.payload },
      }
    case USER_UPDATE_USERCREDENTIALS_FAILED:
      return {
        ...state,
        updatedUserCredentials: { loading: false, error: action.payload },
      }
    case USER_DELETE_USER_REQ:
      return {
        loading: true,
        profile: state.profile,
        userDeleted: { loading: true },
      }
    case USER_DELETE_USER_SUCCESS:
      return {
        loading: false,
        profile: state.profile,
        userDeleted: { loading: false, message: action.payload },
      }
    case USER_DELETE_USER_FAILED:
      return {
        loading: false,
        profile: state.profile,
        userDeleted: { loading: false, error: action.payload },
      }
    case USER_ACCOUNT_VER_REQ:
      return {
        ...state,
        accountVerified: { loading: true },
      }
    case USER_ACCOUNT_VER_SUCCESS:
      return {
        ...state,
        accountVerified: { loading: false, message: action.payload },
      }
    case USER_ACCOUNT_VER_FAILED:
      return {
        ...state,
        accountVerified: { loading: false, error: action.payload },
      }
    case UPDATE_ENGRPROFINFO_REQ:
      return {
        ...state,
        updatedEngrProfInfo: { loading: true },
      }
    case UPDATE_ENGRPROFINFO_SUCCESS:
      return {
        ...state,
        updatedEngrProfInfo: { loading: false, message: action.payload },
      }
    case UPDATE_ENGRPROFINFO_FAILED:
      return {
        ...state,
        updatedEngrProfInfo: { loading: false, error: action.payload },
      }
    case GET_ENGATTACHMENTS_REQ:
      return {
        ...state,
        retrievedEngAttachments: { loading: true },
      }
    case GET_ENGATTACHMENTS_SUCCESS:
      return {
        ...state,
        retrievedEngAttachments: { loading: false, message: action.payload },
      }
    case GET_ENGATTACHMENTS_FAILED:
      return {
        ...state,
        retrievedEngAttachments: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
