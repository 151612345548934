import {
  GET_SCHED_APPLICATION_REQ,
  GET_SCHED_APPLICATION_SUCCESS,
  GET_SCHED_APPLICATION_FAILED,
  GET_WITH_REPORT_REQ,
  GET_WITH_REPORT_SUCCESS,
  GET_WITH_REPORT_FAILED,
  GET_LIST_REPORT_REQ,
  GET_LIST_REPORT_SUCCESS,
  GET_LIST_REPORT_FAILED,
  GET_REPORT_REQ,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILED,
  GET_FOR_REPORT_REQ,
  GET_FOR_REPORT_SUCCESS,
  GET_FOR_REPORT_FAILED,
  GET_COMMENTS_REQ,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAILED,
  GET_VIOLATIONLIST_REQ,
  GET_VIOLATIONLIST_SUCCESS,
  GET_VIOLATIONLIST_FAILED,
  GET_IMAGE_REPORT_REQ,
  GET_IMAGE_REPORT_SUCCESS,
  GET_IMAGE_REPORT_FAILED,
  GET_VIOLATIONDETAILS_REQ,
  GET_VIOLATIONDETAILS_SUCCESS,
  GET_VIOLATIONDETAILS_FAILED,
  GET_VIOLATORLIST_REQ,
  GET_VIOLATORLIST_SUCCESS,
  GET_VIOLATORLIST_FAILED,
  GET_VIOLATIONSTATUS_REQ,
  GET_VIOLATIONSTATUS_SUCCESS,
  GET_VIOLATIONSTATUS_FAILED,
  GET_APPINFO_REQ,
  GET_APPINFO_SUCCESS,
  GET_APPINFO_FAILED,
  GET_INSPECTORSUMMARY_REQ,
  GET_INSPECTORSUMMARY_SUCCESS,
  GET_INSPECTORSUMMARY_FAILED,
  ADD_EDIT_REPORT_REQ,
  ADD_EDIT_REPORT_SUCCESS,
  ADD_EDIT_REPORT_FAILED,
  UPDATE_IMAGES_REQ,
  UPDATE_IMAGES_SUCCESS,
  UPDATE_IMAGES_FAILED,
  ADD_REPORT_REQ,
  ADD_REPORT_SUCCESS,
  ADD_REPORT_FAILED,
  ADD_VIOLATION_REQ,
  ADD_VIOLATION_SUCCESS,
  ADD_VIOLATION_FAILED,
  ADD_VIOLATION_DETAILS_REQ,
  ADD_VIOLATION_DETAILS_SUCCESS,
  ADD_VIOLATION_DETAILS_FAILED,
  INSERT_COMMENT_REQ,
  INSERT_COMMENT_SUCCESS,
  INSERT_COMMENT_FAILED,
  INSERT_IMAGES_REQ,
  INSERT_IMAGES_SUCCESS,
  INSERT_IMAGES_FAILED,
  UPDATE_VIOLATION_REQ,
  UPDATE_VIOLATION_SUCCESS,
  UPDATE_VIOLATION_FAILED,
  UPDATE_VIOLATION_DETAILS_REQ,
  UPDATE_VIOLATION_DETAILS_SUCCESS,
  UPDATE_VIOLATION_DETAILS_FAILED,
} from '../constants/inspectionConstants'

export const inspectionReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_SCHED_APPLICATION_REQ:
      return {
        ...state,
        forInspection: { loading: true },
      }
    case GET_SCHED_APPLICATION_SUCCESS:
      return {
        ...state,
        forInspection: { loading: false, message: action.payload },
      }
    case GET_SCHED_APPLICATION_FAILED:
      return {
        ...state,
        forInspection: { loading: false, error: action.payload },
      }
    case GET_WITH_REPORT_REQ:
      return {
        ...state,
        withInspectionReport: { loading: true },
      }
    case GET_WITH_REPORT_SUCCESS:
      return {
        ...state,
        withInspectionReport: { loading: false, message: action.payload },
      }
    case GET_WITH_REPORT_FAILED:
      return {
        ...state,
        withInspectionReport: { loading: false, error: action.payload },
      }
    case GET_LIST_REPORT_REQ:
      return {
        ...state,
        listReport: { loading: true },
      }
    case GET_LIST_REPORT_SUCCESS:
      return {
        ...state,
        listReport: { loading: false, message: action.payload },
      }
    case GET_LIST_REPORT_FAILED:
      return {
        ...state,
        listReport: { loading: false, error: action.payload },
      }
    case GET_REPORT_REQ:
      return {
        ...state,
        existingReport: { loading: true },
      }
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        existingReport: { loading: false, message: action.payload },
      }
    case GET_REPORT_FAILED:
      return {
        ...state,
        existingReport: { loading: false, error: action.payload },
      }
    case GET_IMAGE_REPORT_REQ:
      return {
        ...state,
        existingImageReport: { loading: true },
      }
    case GET_IMAGE_REPORT_SUCCESS:
      return {
        ...state,
        existingImageReport: { loading: false, message: action.payload },
      }
    case GET_IMAGE_REPORT_FAILED:
      return {
        ...state,
        existingImageReport: { loading: false, error: action.payload },
      }
    case GET_COMMENTS_REQ:
      return {
        ...state,
        commentList: { loading: true },
      }
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        commentList: { loading: false, message: action.payload },
      }
    case GET_COMMENTS_FAILED:
      return {
        ...state,
        commentList: { loading: false, error: action.payload },
      }
    case GET_FOR_REPORT_REQ:
      return {
        ...state,
        forReport: { loading: true },
      }
    case GET_FOR_REPORT_SUCCESS:
      return {
        ...state,
        forReport: { loading: false, message: action.payload },
      }
    case GET_FOR_REPORT_FAILED:
      return {
        ...state,
        forReport: { loading: false, error: action.payload },
      }
    case GET_VIOLATIONLIST_REQ:
      return {
        ...state,
        retrievedViolationList: { loading: true },
      }
    case GET_VIOLATIONLIST_SUCCESS:
      return {
        ...state,
        retrievedViolationList: { loading: false, message: action.payload },
      }
    case GET_VIOLATIONLIST_FAILED:
      return {
        ...state,
        retrievedViolationList: { loading: false, error: action.payload },
      }
    case GET_VIOLATORLIST_REQ:
      return {
        ...state,
        retrievedViolatorList: { loading: true },
      }
    case GET_VIOLATORLIST_SUCCESS:
      return {
        ...state,
        retrievedViolatorList: { loading: false, message: action.payload },
      }
    case GET_VIOLATORLIST_FAILED:
      return {
        ...state,
        retrievedViolatorList: { loading: false, error: action.payload },
      }
    case GET_VIOLATIONDETAILS_REQ:
      return {
        ...state,
        retrievedViolationDetails: { loading: true },
      }
    case GET_VIOLATIONDETAILS_SUCCESS:
      return {
        ...state,
        retrievedViolationDetails: { loading: false, message: action.payload },
      }
    case GET_VIOLATIONDETAILS_FAILED:
      return {
        ...state,
        retrievedViolationDetails: { loading: false, error: action.payload },
      }
    case GET_VIOLATIONSTATUS_REQ:
      return {
        ...state,
        retrievedViolationStatus: { loading: true },
      }
    case GET_VIOLATIONSTATUS_SUCCESS:
      return {
        ...state,
        retrievedViolationStatus: { loading: false, message: action.payload },
      }
    case GET_VIOLATIONSTATUS_FAILED:
      return {
        ...state,
        retrievedViolationStatus: { loading: false, error: action.payload },
      }
    case GET_INSPECTORSUMMARY_REQ:
      return {
        ...state,
        retrievedInspectorSummary: { loading: true },
      }
    case GET_INSPECTORSUMMARY_SUCCESS:
      return {
        ...state,
        retrievedInspectorSummary: { loading: false, message: action.payload },
      }
    case GET_INSPECTORSUMMARY_FAILED:
      return {
        ...state,
        retrievedInspectorSummary: { loading: false, error: action.payload },
      }
    case GET_APPINFO_REQ:
      return {
        ...state,
        retrievedAppInfo: { loading: true },
      }
    case GET_APPINFO_SUCCESS:
      return {
        ...state,
        retrievedAppInfo: { loading: false, message: action.payload },
      }
    case GET_APPINFO_FAILED:
      return {
        ...state,
        retrievedAppInfo: { loading: false, error: action.payload },
      }
    case ADD_EDIT_REPORT_REQ:
      return {
        ...state,
        editReport: { loading: true },
      }
    case ADD_EDIT_REPORT_SUCCESS:
      return {
        ...state,
        editReport: { loading: false, message: action.payload },
      }
    case ADD_EDIT_REPORT_FAILED:
      return {
        ...state,
        editReport: { loading: false, error: action.payload },
      }
    case UPDATE_IMAGES_REQ:
      return {
        ...state,
        reAttachedImages: { loading: true },
      }
    case UPDATE_IMAGES_SUCCESS:
      return {
        ...state,
        reAttachedImages: { loading: false, message: action.payload },
      }
    case UPDATE_IMAGES_FAILED:
      return {
        ...state,
        reAttachedImages: { loading: false, error: action.payload },
      }
    case ADD_REPORT_REQ:
      return {
        ...state,
        addReport: { loading: true },
      }
    case ADD_REPORT_SUCCESS:
      return {
        ...state,
        addReport: { loading: false, message: action.payload },
      }
    case ADD_REPORT_FAILED:
      return {
        ...state,
        addReport: { loading: false, error: action.payload },
      }
    case ADD_VIOLATION_REQ:
      return {
        ...state,
        addViolation: { loading: true },
      }
    case ADD_VIOLATION_SUCCESS:
      return {
        ...state,
        addViolation: { loading: false, message: action.payload },
      }
    case ADD_VIOLATION_FAILED:
      return {
        ...state,
        addViolation: { loading: false, error: action.payload },
      }
    case ADD_VIOLATION_DETAILS_REQ:
      return {
        ...state,
        addViolationDetails: { loading: true },
      }
    case ADD_VIOLATION_DETAILS_SUCCESS:
      return {
        ...state,
        addViolationDetails: { loading: false, message: action.payload },
      }
    case ADD_VIOLATION_DETAILS_FAILED:
      return {
        ...state,
        addViolationDetails: { loading: false, error: action.payload },
      }
    case ADD_REPORT_FAILED:
      return {
        ...state,
        addReport: { loading: false, error: action.payload },
      }
    case INSERT_COMMENT_REQ:
      return {
        ...state,
        createdComment: { loading: true },
      }
    case INSERT_COMMENT_SUCCESS:
      return {
        ...state,
        createdComment: { loading: false, message: action.payload },
      }
    case INSERT_COMMENT_FAILED:
      return {
        ...state,
        createdComment: { loading: false, error: action.payload },
      }
    case INSERT_IMAGES_REQ:
      return {
        ...state,
        attachedImages: { loading: true },
      }
    case INSERT_IMAGES_SUCCESS:
      return {
        ...state,
        attachedImages: { loading: false, message: action.payload },
      }
    case INSERT_IMAGES_FAILED:
      return {
        ...state,
        attachedImages: { loading: false, error: action.payload },
      }
    case UPDATE_VIOLATION_REQ:
      return {
        ...state,
        edittedViolation: { loading: true },
      }
    case UPDATE_VIOLATION_SUCCESS:
      return {
        ...state,
        edittedViolation: { loading: false, message: action.payload },
      }
    case UPDATE_VIOLATION_FAILED:
      return {
        ...state,
        edittedViolation: { loading: false, error: action.payload },
      }
    case UPDATE_VIOLATION_DETAILS_REQ:
      return {
        ...state,
        edittedViolationDetails: { loading: true },
      }
    case UPDATE_VIOLATION_DETAILS_SUCCESS:
      return {
        ...state,
        edittedViolationDetails: { loading: false, message: action.payload },
      }
    case UPDATE_VIOLATION_DETAILS_FAILED:
      return {
        ...state,
        edittedViolationDetails: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
