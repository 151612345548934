export const GET_FORRECOMMENDATION_LIST_REQ = 'GET_FORRECOMMENDATION_LIST_REQ'
export const GET_FORRECOMMENDATION_LIST_SUCCESS =
  'GET_FORRECOMMENDATION_LIST_SUCCESS'
export const GET_FORRECOMMENDATION_LIST_FAILED =
  'GET_FORRECOMMENDATION_LIST_FAILED'

export const GET_RECOMMENDED_LIST_REQ = 'GET_RECOMMENDED_LIST_REQ'
export const GET_RECOMMENDED_LIST_SUCCESS = 'GET_RECOMMENDED_LIST_SUCCESS'
export const GET_RECOMMENDED_LIST_FAILED = 'GET_RECOMMENDED_LIST_FAILED'

export const GET_APPLICATION_LIST_REQ = 'GET_APPLICATION_LIST_REQ'
export const GET_APPLICATION_LIST_SUCCESS = 'GET_APPLICATION_LIST_SUCCESS'
export const GET_APPLICATION_LIST_FAILED = 'GET_APPLICATION_LIST_FAILED'

export const GET_RETURNED_LIST_REQ = 'GET_RETURNED_LIST_REQ'
export const GET_RETURNED_LIST_SUCCESS = 'GET_RETURNED_LIST_SUCCESS'
export const GET_RETURNED_LIST_FAILED = 'GET_RETURNED_LIST_FAILED'

export const GET_COMMENTS_REQ = 'GET_COMMENTS_REQ'
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS'
export const GET_COMMENTS_FAILED = 'GET_COMMENTS_FAILED'

export const GET_ORDEROFPAYMENT_REQ = 'GET_ORDEROFPAYMENT_REQ'
export const GET_ORDEROFPAYMENT_SUCCESS = 'GET_ORDEROFPAYMENT_SUCCESS'
export const GET_ORDEROFPAYMENT_FAILED = 'GET_ORDEROFPAYMENT_FAILED'

export const INSERT_ORDEROFPAYMENT_REQ = 'INSERT_ORDEROFPAYMENT_REQ'
export const INSERT_ORDEROFPAYMENT_SUCCESS = 'INSERT_ORDEROFPAYMENT_SUCCESS'
export const INSERT_ORDEROFPAYMENT_FAILED = 'INSERT_ORDEROFPAYMENT_FAILED'

export const INSERT_ICOMMENT_REQ = 'INSERT_ICOMMENT_REQ'
export const INSERT_ICOMMENT_SUCCESS = 'INSERT_ICOMMENT_SUCCESS'
export const INSERT_ICOMMENT_FAILED = 'INSERT_ICOMMENT_FAILED'

export const INSERT_ECOMMENT_REQ = 'INSERT_ECOMMENT_REQ'
export const INSERT_ECOMMENT_SUCCESS = 'INSERT_ECOMMENT_SUCCESS'
export const INSERT_ECOMMENT_FAILED = 'INSERT_ECOMMENT_FAILED'

export const UPDATE_APPLICATION_STATUS_REQ = 'UPDATE_APPLICATION_STATUS_REQ'
export const UPDATE_APPLICATION_STATUS_SUCCESS =
  'UPDATE_APPLICATION_STATUS_SUCCESS'
export const UPDATE_APPLICATION_STATUS_FAILED =
  'UPDATE_APPLICATION_STATUS_FAILED'
