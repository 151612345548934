import axios from 'axios'
import {
  CATEGORIES_USER_REQUEST,
  CATEGORIES_USER_SUCCESS,
  CATEGORIES_USER_FAILED,
} from '../constants/categoriesConstant'

export const categoriesRetrive = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: CATEGORIES_USER_REQUEST })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/userapplications/categories`
      )

      dispatch({
        type: CATEGORIES_USER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CATEGORIES_USER_FAILED,
        payload: 'No Categories Found! ',
      })
    }
  }
}
