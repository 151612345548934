import React, { useEffect } from 'react'
import { Container, Image, Row, Spinner } from 'react-bootstrap'
import { FaHandPaper } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// Actions
import { verifiedQr } from '../redux/actions/userActions'

const QrVerifyingScreen = ({ match }) => {
  const img = process.env.PUBLIC_URL + '/assets/logo/verifiedLogo.png'
  const img2 = process.env.PUBLIC_URL + '/assets/logo/notVerifiedLogo.png'

  const users = useSelector((state) => state.users)

  const { verifying } = users

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(verifiedQr(match.params.id))
  }, [dispatch])
  return (
    <>
      <Container>
        {/* <FaHandPaper className='text-danger' style={{ fontSize: '1920px' }} /> */}
        {verifying && verifying.loading && (
          <div className='text-center pt-3 my-5'>
            <Spinner animation='border' />
            <h3>Loading...</h3>
          </div>
        )}

        {verifying && !verifying.loading && verifying.message && (
          <div>
            <Row>
              <Image src={img} />
            </Row>
            <Container>
              <div className='d-flex justify-content-around flex-wrap'>
                <div className='d-flex'>
                  <h5>Permit No:</h5>
                  <p className='mt-1 mx-2 text-danger'>
                    {verifying.message.result[0].PermitNo}
                  </p>
                </div>
                <div className='d-flex'>
                  <h5>Owner:</h5>
                  <div>
                    {verifying.message.result.map((e, index) => (
                      <p key={index} className='mt-1 mx-2'>
                        {e.Owner}
                      </p>
                    ))}
                  </div>
                </div>
                <div className='d-flex'>
                  <h5>Date Released:</h5>
                  <p className='mt-1 mx-2'>
                    {moment(verifying.message.result[0].dateApproved).format(
                      'MMMM D, YYYY'
                    )}
                  </p>
                </div>
                <div className='d-flex'>
                  <h5>Status:</h5>
                  <p className='mt-1 mx-2 text-success'>Verified</p>
                </div>
              </div>
            </Container>
          </div>
        )}

        {verifying && !verifying.loading && verifying.error && (
          <div>
            <Row>
              <Image src={img2} />
            </Row>
          </div>
        )}
      </Container>
    </>
  )
}

export default QrVerifyingScreen
