import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Modal,
  Spinner,
} from 'react-bootstrap'
import {
  FaEye,
  FaEyeSlash,
  FaMinus,
  FaPlus,
  FaCheckCircle,
} from 'react-icons/fa'
import { useSelector } from 'react-redux'
import GuestMain from '../layout/GuestLayout/GuestMain'
// import { userRegistration } from '../redux/actions/userActions'

const DetailedRegistrationScreen = (props) => {
  const basicInfo = props.location.state.props.Registration
  const [additionalInfo, setAdditionalInfo] = useState({
    FirstName: basicInfo.FirstName,
    MiddleName: basicInfo.MiddleName,
    LastName: basicInfo.LastName,
    CivilStatus: basicInfo.CivilStatus,
    Gender: basicInfo.Gender,
    MobileNo: basicInfo.MobileNo,
    EmailAddress: basicInfo.EmailAddress,
    DOB: basicInfo.DOB,
    roles: 'user',
    TermsNCondition: false,
    PresentNoStreet: '',
    PresentSubd: '',
    PresentBrgyDistrict: '',
    PresentProvince: '',
    PresentCity: '',
    PresentCountry: 'Philippines',
    PresentZipCode: '',
    PermanentNoStreet: '',
    PermanentSubd: '',
    PermanentBrgyDistrict: '',
    PermanentProvince: '',
    PermanentCity: '',
    PermanentCountry: 'Philippines',
    PermanentZipCode: '',
    ProfessionName: '',
    ProfessionNo: '',
    username: '',
    password: '',
    confirmPassword: '',
  })

  const [disabled, setDisabled] = useState(false)

  const [btnDisabled, setBtnDisabled] = useState(true)

  // const dispatch = useDispatch()
  const user = useSelector((state) => state.users)
  const { userInformation, userCredentials } = user

  const redirect = props.location.search
    ? props.location.search.split('=')[1]
    : '/dashboard'

  useEffect(() => {
    if (userCredentials) {
      props.history.push(redirect)
    }
  }, [userCredentials, props.history, redirect])

  let [profession, setProfession] = useState([0])
  const [hidden, setHidden] = useState()

  const [showPassword, setShowPassword] = useState({
    password: 'password',
    confirmPassword: 'password',
  })

  const [show, setShow] = useState(false)

  const handleChange = (e) => {
    setAdditionalInfo({
      ...additionalInfo,
      [e.target.name]: e.target.value,
    })

    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        setAdditionalInfo({
          ...additionalInfo,
          PermanentNoStreet: additionalInfo.PresentNoStreet,
          PermanentSubd: additionalInfo.PresentSubd,
          PermanentBrgyDistrict: additionalInfo.PresentBrgyDistrict,
          PermanentProvince: additionalInfo.PresentProvince,
          PermanentCity: additionalInfo.PresentCity,
          PermanentZipCode: additionalInfo.PresentZipCode,
        })
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  }

  const handleCancel = () => {
    props.history.push('/')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    //console.log('submit')
    // dispatch(userRegistration(additionalInfo))
    // if (!error) {
    setShow(true)
    // }
  }

  const handleAddProfession = () => {
    let temp = profession.length - 1
    setProfession([...profession, temp + 1])
    //console.log(profession)
    if (profession.length === 2) {
      setHidden(true)
    } else {
      setHidden(false)
    }
  }

  const handleRemoveProfession = () => {
    profession.pop()
    setProfession([...profession])
    if (profession.length === 1) {
      setHidden(false)
    } else {
      setHidden(true)
    }
  }

  const formValidation = () => {
    if (
      additionalInfo.password === additionalInfo.confirmPassword &&
      additionalInfo.password !== '' &&
      additionalInfo.confirmPassword !== ''
    ) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
  }

  useEffect(() => {
    formValidation()
  })

  // useEffect(() => {
  //   if (userInformation) {
  //     if (userInformation.message) {
  //       setShow(true)
  //     } else if (userInformation.error) {
  //       setTimeout(() => {
  //         setShow(true)
  //       }, 1000)
  //     } else {
  //       setShow(false)
  //     }
  //   }
  // })

  const handleConfirm = () => {
    props.history.push('/')
    setAdditionalInfo({
      FirstName: basicInfo.FirstName,
      MiddleName: basicInfo.MiddleName,
      LastName: basicInfo.LastName,
      CivilStatus: basicInfo.CivilStatus,
      Gender: basicInfo.Gender,
      MobileNo: basicInfo.MobileNo,
      EmailAddress: basicInfo.EmailAddress,
      DOB: basicInfo.DOB,
      roles: 'user',
      TermsNCondition: false,
      PresentNoStreet: '',
      PresentSubd: '',
      PresentBrgyDistrict: '',
      PresentProvince: '',
      PresentCity: '',
      PresentCountry: 'Philippines',
      PresentZipCode: '',
      PermanentNoStreet: '',
      PermanentSubd: '',
      PermanentBrgyDistrict: '',
      PermanentProvince: '',
      PermanentCity: '',
      PermanentCountry: 'Philippines',
      PermanentZipCode: '',
      ProfessionName: '',
      ProfessionNo: '',
      username: '',
      password: '',
      confirmPassword: '',
    })
  }

  const handleErrorSubmit = () => {
    setShow(false)
    setAdditionalInfo({
      ...additionalInfo,
      username: '',
      password: '',
      confirmPassword: '',
    })
  }

  return (
    <GuestMain>
      <div style={{ background: '#f9f6f1' }}>
        <Container>
          <Row>
            <Col>
              <div className='my-3 shadow-lg' style={{ background: '#FFF' }}>
                <div className='pt-3 px-3'>
                  <p>Additional Information</p>
                  <hr />
                </div>
                <Row>
                  <Col sm={12}>
                    <div>
                      <Form className='form-area' onSubmit={handleSubmit}>
                        {/* User Address */}

                        <div className='border border-success py-2 px-3 mx-3'>
                          {/* Present Address Row */}

                          <p className='my-0 fw-bold'>Present Address</p>
                          <hr className='mt-0' />
                          <Row>
                            <Form.Group as={Col} md='4'>
                              <Form.Label>No. / Street *</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='No. / Street'
                                name='PresentNoStreet'
                                onChange={handleChange}
                                value={additionalInfo.PresentNoStreet}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                              />
                            </Form.Group>
                            <Form.Group as={Col} md='4'>
                              <Form.Label>Subd./Comp.*</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Subd./Brgy./Dist1'
                                name='PresentSubd'
                                onChange={handleChange}
                                value={additionalInfo.PresentSubd}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                              />
                            </Form.Group>
                            <Form.Group as={Col} md='4'>
                              <Form.Label>Brgy/Dstrict*</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Subd./Brgy./Dist2'
                                name='PresentBrgyDistrict'
                                onChange={handleChange}
                                value={additionalInfo.PresentBrgyDistrict}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                              />
                            </Form.Group>
                          </Row>
                          <Row className='my-3'>
                            <Form.Group as={Col} md='3'>
                              <Form.Label>Province *</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Province'
                                name='PresentProvince'
                                onChange={handleChange}
                                value={additionalInfo.PresentProvince}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                              />
                            </Form.Group>
                            <Form.Group as={Col} md='4'>
                              <Form.Label>City/Municipality*</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='City/Municipality'
                                name='PresentCity'
                                onChange={handleChange}
                                value={additionalInfo.PresentCity}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                              />
                            </Form.Group>

                            <Form.Group as={Col} md='3'>
                              <Form.Label>Country</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Country'
                                name='PresentCountry'
                                value={additionalInfo.PresentCountry}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                disabled={true}
                              />
                            </Form.Group>

                            <Form.Group as={Col} md='2'>
                              <Form.Label>Zip Code</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Zip Code'
                                name='PresentZipCode'
                                onChange={handleChange}
                                value={additionalInfo.PresentZipCode}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                              />
                            </Form.Group>
                          </Row>

                          {/* Permanent Address Row */}

                          <p className='mt-3 mb-0 fw-bold'>Permanent Address</p>
                          <hr className='mt-0' />
                          <Row>
                            <Form.Group className='mb-3 d-flex justify-content-end'>
                              <Form.Check
                                type='checkbox'
                                label='SAME AS ABOVE'
                                className='text-info fw-bold'
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Row>

                          <Row>
                            <Form.Group as={Col} md='4'>
                              <Form.Label>No. / Street *</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='No. / Street'
                                name='PermanentNoStreet'
                                onChange={handleChange}
                                value={additionalInfo.PermanentNoStreet}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                disabled={disabled}
                              />
                            </Form.Group>

                            <Form.Group as={Col} md='4'>
                              <Form.Label>Subd./Brgy./Dist1*</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Subd./Brgy./Dist1'
                                name='PermanentSubd'
                                onChange={handleChange}
                                value={additionalInfo.PermanentSubd}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                disabled={disabled}
                              />
                            </Form.Group>

                            <Form.Group as={Col} md='4'>
                              <Form.Label>Subd./Brgy./Dist2</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Subd./Brgy./Dist2'
                                name='PermanentBrgyDistrict'
                                onChange={handleChange}
                                value={additionalInfo.PermanentBrgyDistrict}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                disabled={disabled}
                              />
                            </Form.Group>
                          </Row>
                          <Row className='my-3'>
                            <Form.Group as={Col} md='3'>
                              <Form.Label>Province *</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Province'
                                name='PermanentProvince'
                                onChange={handleChange}
                                value={additionalInfo.PermanentProvince}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                disabled={disabled}
                              />
                            </Form.Group>

                            <Form.Group as={Col} md='4'>
                              <Form.Label>City/Municipality*</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='City/Municipality'
                                name='PermanentCity'
                                onChange={handleChange}
                                value={additionalInfo.PermanentCity}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                disabled={disabled}
                              />
                            </Form.Group>

                            <Form.Group as={Col} md='3'>
                              <Form.Label>Country</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Country'
                                name='PermanentCountry'
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                value={additionalInfo.PermanentCountry}
                                disabled={true}
                              />
                            </Form.Group>

                            <Form.Group as={Col} md='2'>
                              <Form.Label>Zip Code</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Zip Code'
                                name='PermanentZipCode'
                                onChange={handleChange}
                                value={additionalInfo.PermanentZipCode}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                disabled={disabled}
                              />
                            </Form.Group>
                          </Row>
                        </div>

                        {/* User Profession */}
                        <div className='border border-success py-2 px-3 mx-3 my-3'>
                          <div>
                            <p className='my-0 fw-bold'>
                              Professional Information
                            </p>
                            <hr className='mt-0' />
                            {profession.map((profession) => (
                              <Row className='my-2' key={profession}>
                                <Form.Group as={Col} md='6'>
                                  <Form.Label>Profession Name *</Form.Label>
                                  <Form.Control
                                    required
                                    type='text'
                                    placeholder='Profession Name'
                                    name={`ProfessionName` + profession}
                                    onChange={handleChange}
                                    style={{
                                      background: '#FFF',
                                      border: '1px solid #ced4da',
                                    }}
                                  />
                                </Form.Group>

                                <Form.Group as={Col} md='6'>
                                  <Form.Label>Profession No.</Form.Label>
                                  <Form.Control
                                    required
                                    type='text'
                                    placeholder='XXXXX'
                                    name={`ProfessionNo` + profession}
                                    onChange={handleChange}
                                    style={{
                                      background: '#FFF',
                                      border: '1px solid #ced4da',
                                    }}
                                  />
                                </Form.Group>
                              </Row>
                            ))}
                          </div>

                          {!hidden ? (
                            <Row className='mt-2'>
                              <Form.Group as={Col}>
                                <Button
                                  hidden={hidden}
                                  onClick={handleAddProfession}
                                  variant='info'
                                >
                                  <FaPlus />
                                </Button>
                              </Form.Group>
                            </Row>
                          ) : (
                            <Row className='mt-2'>
                              <Form.Group as={Col}>
                                <Button
                                  onClick={handleRemoveProfession}
                                  variant='danger'
                                >
                                  <FaMinus />
                                </Button>
                              </Form.Group>
                            </Row>
                          )}
                        </div>

                        {/* User Credentials */}
                        <div className='border border-success py-2 px-3 mx-3 my-3'>
                          <p className=' mb-0 fw-bold'>User Credentials</p>
                          <hr className='mt-0' />
                          <Row>
                            <Form.Group as={Col} md='4'>
                              <Form.Label>Username</Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Username'
                                name='username'
                                onChange={handleChange}
                                value={additionalInfo.username}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                              />
                            </Form.Group>

                            <Form.Group as={Col} md='4'>
                              <Form.Label>Password</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  required
                                  type={showPassword.password}
                                  placeholder='Password'
                                  name='password'
                                  minLength={6}
                                  maxLength={8}
                                  onChange={handleChange}
                                  value={additionalInfo.password}
                                  style={{
                                    background: '#FFF',
                                    border: '1px solid #ced4da',
                                  }}
                                />
                                {showPassword.password === 'password' ? (
                                  <Button
                                    variant='outline-secondary'
                                    onClick={() =>
                                      setShowPassword({
                                        ...showPassword,
                                        password: 'text',
                                      })
                                    }
                                  >
                                    <FaEye />
                                  </Button>
                                ) : (
                                  <Button
                                    variant='outline-secondary'
                                    onClick={() =>
                                      setShowPassword({
                                        ...showPassword,
                                        password: 'password',
                                      })
                                    }
                                  >
                                    <FaEyeSlash />
                                  </Button>
                                )}
                              </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md='4'>
                              <Form.Label>Confirm Password</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  required
                                  type={showPassword.confirmPassword}
                                  placeholder='Confirm Password'
                                  name='confirmPassword'
                                  minLength={6}
                                  maxLength={8}
                                  onChange={handleChange}
                                  value={additionalInfo.confirmPassword}
                                  style={{
                                    background: '#FFF',
                                    border: '1px solid #ced4da',
                                  }}
                                />
                                {showPassword.confirmPassword === 'password' ? (
                                  <Button
                                    variant='outline-secondary'
                                    onClick={() =>
                                      setShowPassword({
                                        ...showPassword,
                                        confirmPassword: 'text',
                                      })
                                    }
                                  >
                                    <FaEye />
                                  </Button>
                                ) : (
                                  <Button
                                    variant='outline-secondary'
                                    onClick={() =>
                                      setShowPassword({
                                        ...showPassword,
                                        confirmPassword: 'password',
                                      })
                                    }
                                  >
                                    <FaEyeSlash />
                                  </Button>
                                )}
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </div>

                        <Row className='my-2 mx-1'>
                          <Col md={12}>
                            <Button
                              variant='outline-danger'
                              onClick={handleCancel}
                            >
                              Cancel
                            </Button>
                            <Button
                              style={{ marginLeft: '10px' }}
                              variant='success'
                              type='submit'
                              disabled={btnDisabled}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={show} onHide={() => setShow(false)} centered>
        {userInformation && userInformation.loading ? (
          <div className='text-center pt-3'>
            <Spinner animation='border' />
            <h3>Loading...</h3>
          </div>
        ) : (
          <>
            {userInformation && userInformation.message && (
              <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
                <Modal.Title style={{ color: '#FFF' }}>
                  <FaCheckCircle className='mx-3' />
                  Transaction Successfully Completed
                </Modal.Title>
              </Modal.Header>
            )}
            {userInformation && userInformation.error && (
              <Modal.Header style={{ background: '#d9534f', color: '#FFF' }}>
                <Modal.Title style={{ color: '#FFF' }}>
                  <FaCheckCircle className='mx-3' />
                  Transaction Successfully Completed
                </Modal.Title>
              </Modal.Header>
            )}
            <Modal.Body>
              {userInformation && userInformation.message && (
                <h5 className='text-center'>
                  {userInformation.message.message}
                </h5>
              )}
              {userInformation && userInformation.error && (
                <h5 className='text-center'>{userInformation.error}</h5>
              )}
            </Modal.Body>
            <Modal.Footer className='p-1'>
              {userInformation && userInformation.message && (
                <Button variant='info' onClick={handleConfirm}>
                  Okay
                </Button>
              )}
              {userInformation && userInformation.error && (
                <Button variant='info' onClick={handleErrorSubmit}>
                  Okay
                </Button>
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>
    </GuestMain>
  )
}

DetailedRegistrationScreen.defaultProps = {
  background: '#FFF',
  border: '1px solid #ced4da',
}

export default DetailedRegistrationScreen
