import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  InputGroup,
  Button,
  Table,
  Pagination,
  Modal,
} from 'react-bootstrap'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  FaSearch,
  FaEye,
  FaCheck,
  FaClipboardCheck,
  FaEllipsisH,
  FaPencilAlt,
  FaFolderOpen,
} from 'react-icons/fa'
import moment from 'moment'

//Layout
import UserMain from '../../../layout/UserLayout/UserMain'
import SuccessModal from '../../../reusable/SuccessModal'
import CommentsCard from '../../../reusable/CommentsCard'

//Actions
import {
  retrieveReportList,
  insertInspectionComments,
  retrievePenalty,
  insertRecommendation,
  retrieveRecommendedList,
  returnAppInspection,
  retrieveInspectionDetails,
  updatePenaltyValue,
  updateAdminFineValue,
} from '../../../redux/actions/Inspectors/Supervisors/isupervisorActions'
import { retrieveDateApplied } from '../../../redux/actions/adminApplicationActions'
import {
  retrieveReport,
  retrieveCommentList,
  retrieveImageReport,
} from '../../../redux/actions/inspectionActions'

// Data
import { createReport } from '../../../../data/createReport'

const RecommendedListScreen = () => {
  // Declaration
  const dispatch = useDispatch()

  const [state, setState] = useState([])

  const [payload, setPayload] = useState({})

  const [searchTerm, setSearchTerm] = useState('')

  const [modal, setModal] = useState({
    viewManager: false,
    viewReport: false,
    viewReportList: false,
    viewAssessment: false,
    successModal: false,
    viewComments: false,
    returnSuccessModal: false,
    updatePenalty: false,
    updateAdminFine: false,
  })

  const [commentDetails, setCommentDetails] = useState({
    comments: '',
    appID: 0,
  })

  const [penaltyInfo, setPenaltyInfo] = useState({
    penalty: 0,
    adminFine: 0,
    appID: 0,
  })

  const users = useSelector((state) => state.users)

  const { userCredentials } = users

  const isupervisor = useSelector((state) => state.isupervisor)

  const {
    listReport,
    penaltyDetail,
    createRecommendation,
    recommendedList,
    returnedAppInspection,
    retrievedInspectionDetails,
    updatedPenaltyValue,
    updatedAdminFine,
  } = isupervisor

  const adminApplication = useSelector((state) => state.adminApplication)

  const { dateApplied } = adminApplication

  const inspection = useSelector((state) => state.inspection)

  const { commentList, existingImageReport } = inspection

  // Pagination Area Forms
  const [paginatedForms, setPaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  const pageSize = 5

  useEffect(() => {
    setPaginatedForms(_(_.reverse(state)).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [state])

  const pageCount = state ? Math.ceil(state.length / pageSize) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    const paginationForms = _(state).slice(startIndex).take(pageSize).value()
    setPaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Pagination Area Form

  // Functions
  const disabledRecommendation = () => {
    if (listReport && listReport.message) {
      let value = listReport.message.map((list) => {
        if (list.inspectionDate !== null) {
          return false
        }
        return true
      })
      let checker = value.some((x) => x === true)

      return checker
    }
  }

  const handleSubmitComment = () => {
    if (commentDetails.status !== 'Return Application') {
      dispatch(
        insertRecommendation({
          penalty: penaltyInfo,
          userID: userCredentials.id,
          id: payload.id,
          comments: commentDetails.comments,
          statusID: payload.statusID,
          penaltyID:
            penaltyDetail &&
            penaltyDetail.message &&
            penaltyDetail.message[0].PenaltyID,
        })
      )
      setModal({
        viewComments: false,
        successModal: true,
      })
    } else {
      dispatch(
        returnAppInspection({
          commentTo: commentDetails.commentTo,
          commentFrom: userCredentials.id,
          id: payload.id,
          comments: commentDetails.comments,
        })
      )
      setModal({
        viewComments: false,
        returnSuccessModal: true,
      })
    }
    // setModal({
    //   viewComments: false,
    //   successModal: true,
    // })
  }
  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(retrieveRecommendedList())
  }, [])

  useEffect(() => {
    if (recommendedList && recommendedList.message) {
      setState(() =>
        recommendedList.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.id === parseInt(searchTerm)
            )
          }
        })
      )
    }
  }, [recommendedList, searchTerm])
  // End UseEffect

  // console.log(updatedAdminFine)

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {recommendedList && recommendedList.message && (
            <>
              <Form>
                <Row className='justify-content-end px-3 pt-5'>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              <Row className='my-4'>
                <Col md={12} style={{ background: '#FFF' }}>
                  <h5 className='pt-3 pb-2 mb-0'>Recommended List</h5>
                  <hr
                    className='mt-1 mb-3'
                    style={{
                      background: '#ffeb36',
                      paddingBottom: '2px',
                      width: '200px',
                    }}
                  />
                  <Table bordered hover responsive size='sm'>
                    <thead className='bg-info text-light'>
                      <tr>
                        <th>Application No.</th>
                        <th>Application</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Date Transferred</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedForms.map((x, index) => (
                        <tr key={index}>
                          <td className='text-center'>{x.id}</td>
                          <td>{x.ApplicationName}</td>
                          <td>{x.status}</td>
                          <td>{x.remarks}</td>
                          <td>
                            {moment(x.TimeStamp).format('MMMM D, YYYY h:mma')}
                          </td>
                          <td className='text-center'>
                            <Button
                              size='sm'
                              variant='outline-success'
                              onClick={() => {
                                dispatch(retrieveDateApplied({ id: x.id }))
                                dispatch(retrievePenalty({ id: x.id }))
                                setModal({
                                  viewManager: true,
                                })
                                setPayload(x)
                                dispatch(
                                  retrieveCommentList({
                                    id: x.id,
                                  })
                                )
                              }}
                            >
                              <FaEllipsisH />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    size='sm'
                    className='d-flex justify-content-end mx-2'
                  >
                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {renderPageNumber}

                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </>
          )}

          {recommendedList && recommendedList.loading && (
            <Row className='py-4'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {recommendedList && recommendedList.error && (
            <Row className='py-4'>
              <Col xl={12}>
                <div className='text-center pt-5'>
                  <FaFolderOpen
                    className='text-danger'
                    style={{ fontSize: '80px' }}
                  />
                  <h2 className='mt-3'>No data found</h2>
                  <h5 className='mt-1' style={{ color: '#4e73df' }}>
                    no application recommended yet . . .
                  </h5>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      <Modal
        size='lg'
        show={modal.viewManager}
        onHide={() => setModal({ viewManager: false })}
        centered
        backdrop='static'
      >
        {dateApplied && dateApplied.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Application Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xl={6}>
                  <h5>Application No.:</h5>
                </Col>
                <Col xl={6} className='text-danger fs-4'>
                  {payload.id}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xl={6}>
                  <h5>Application Name:</h5>
                </Col>
                <Col xl={6} className='text-danger fs-4'>
                  {payload.ApplicationName}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xl={6}>
                  <h5>Mode OF Application:</h5>
                </Col>
                <Col xl={6}>{payload.ModeofForms}</Col>
              </Row>
              <hr />
              <Row>
                <Col xl={6}>
                  <h5>Application Status:</h5>
                </Col>
                <Col xl={6}>{payload.status}</Col>
              </Row>
              <hr />
              <Row>
                <Col xl={6}>
                  <h5>Date Applied:</h5>
                </Col>
                <Col xl={6}>
                  {moment(dateApplied.message[0].DateApplied).format(
                    'MMMM D, YYYY h:mma'
                  )}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xl={6}>
                  <h5>Applicant Attached Link/Remarks:</h5>
                </Col>
                <Col xl={6}>{payload.Link}</Col>
              </Row>
              <hr />
              <Row>
                <Col xl={6}>
                  <h5>Document Controller Attached Link/Remarks:</h5>
                </Col>
                <Col xl={6}>{payload.remarks}</Col>
              </Row>
              <hr />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='info'
                onClick={() => {
                  dispatch(retrieveReportList({ id: payload.id }))
                  setModal({ viewReportList: true, viewManager: false })
                  if (penaltyDetail && penaltyDetail.message) {
                    setPenaltyInfo({
                      penalty: penaltyDetail.message[0].Penalty,
                      adminFine: penaltyDetail.message[0].AdministrativeFive,
                      appID: payload.id,
                    })
                  }
                }}
              >
                View Inspection Report
              </Button>

              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewManager: false,
                  })
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        )}

        {dateApplied && dateApplied.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}

        {dateApplied && dateApplied.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}
      </Modal>

      <Modal
        size='xl'
        show={modal.viewReportList}
        onHide={() => setModal({ viewReportList: false })}
        centered
        backdrop='static'
      >
        {listReport && listReport.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Inspection List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Date Scheduled</th>
                    <th>Inspection Date</th>
                    <th>Inspector</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {listReport.message.map((list) => (
                    <tr key={list.id}>
                      <td className='text-danger'>{list.category}</td>
                      <td className='text-danger'>
                        {moment(list.inspectionSched).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td className='text-danger'>
                        {moment(list.inspectionDate).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>{list.EmployeeName}</td>
                      <td className='text-center'>
                        <Button
                          variant='success'
                          disabled={list.inspectionDate !== null ? false : true}
                          onClick={() => {
                            setModal({
                              viewReport: true,
                              viewReportList: false,
                            })
                            // dispatch(
                            //   retrieveReport({
                            //     inspectionID: list.inspectionID,
                            //     userID: list.inspectID,
                            //     category: list.category,
                            //     timeStamp: list.inspectionDate,
                            //   })
                            // )

                            dispatch(
                              retrieveInspectionDetails({
                                inspectionID: list.inspectionID,
                                category: list.category,
                                userID: list.inspectID,
                              })
                            )
                            dispatch(
                              retrieveImageReport({
                                inspectionID: list.inspectionID,
                                category: list.category,
                                userID: list.inspectID,
                              })
                            )
                          }}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className='d-flex'>
                {/* Penalty */}
                <Form
                  className='mx-1'
                  onSubmit={(e) => {
                    e.preventDefault()
                    dispatch(
                      updatePenaltyValue({
                        penalty: penaltyInfo.penalty / 100,
                        appID: penaltyInfo.appID,
                      })
                    )
                    setModal({
                      viewReportList: false,
                      updatePenalty: true,
                    })
                  }}
                >
                  <Form.Group>
                    <Form.Label>Penalty %:</Form.Label>
                    <Form.Control
                      type='text'
                      value={penaltyInfo.penalty.toLocaleString('en', {
                        style: 'percent',
                      })}
                      onChange={(e) =>
                        e.target.value <= 100 &&
                        setPenaltyInfo({
                          ...penaltyInfo,
                          penalty: e.target.value,
                        })
                      }
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl='12' className='mt-1'>
                    <Button variant='success' size='sm' type='submit'>
                      Update
                    </Button>
                  </Form.Group>
                </Form>

                {/* admin Fine */}
                <Form
                  className='mx-1'
                  onSubmit={(e) => {
                    e.preventDefault()
                    dispatch(
                      updateAdminFineValue({
                        adminFine: penaltyInfo.adminFine,
                        appID: penaltyInfo.appID,
                      })
                    )
                    setModal({
                      ...modal,
                      viewReportList: false,
                      updateAdminFine: true,
                    })
                  }}
                >
                  <Form.Group>
                    <Form.Label>Admininstrative Fine:</Form.Label>
                    <Form.Control
                      type='number'
                      value={penaltyInfo.adminFine}
                      onChange={(e) =>
                        setPenaltyInfo({
                          ...penaltyInfo,
                          adminFine: e.target.value,
                        })
                      }
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl='12' className='mt-1'>
                    <Button variant='success' size='sm' type='submit'>
                      Update
                    </Button>
                  </Form.Group>
                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='success'
                onClick={() => {
                  setModal({
                    viewReportList: false,
                    viewComments: true,
                  })
                }}
              >
                View Comments
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewReportList: false,
                    viewManager: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {listReport && listReport.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReportList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {listReport && listReport.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReportList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>

      <Modal
        size='xl'
        show={modal.viewReport}
        onHide={() => setModal({ viewReport: false })}
        centered
        backdrop='static'
      >
        {retrievedInspectionDetails && retrievedInspectionDetails.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Inspection Report
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Category</h5>
              <p className='mx-2'>
                <i>{retrievedInspectionDetails.message[0].category}</i>
              </p>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[0].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[0].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[1].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[1].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[2].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[2].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[3].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[3].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[4].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[4].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el.split('Rear_')}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[5].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[5].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el.split('Left_')}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[6].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[6].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>
                        {el.split('Right_')}
                      </td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[7].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[7].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <h6 className='mt-2'>Image Attachments</h6>
              {existingImageReport && existingImageReport.message && (
                <div>
                  {existingImageReport.message.map((image, index) => (
                    <Row key={index}>
                      <img
                        key={index}
                        className='my-2'
                        src={`data:image/png;base64,${image.URL}`}
                      />
                    </Row>
                  ))}
                </div>
              )}
              {existingImageReport && existingImageReport.loading && (
                <div className='text-center pt-3'>
                  <Spinner animation='border' />
                  <h3>Loading...</h3>
                </div>
              )}
              {existingImageReport && existingImageReport.error && (
                <h5>No Image/s Uploaded Yet</h5>
              )}

              {/* <Form
                onSubmit={(e) => {
                  if (
                    window.confirm(
                      'Are you sure want to submit a comment for this evaluation?'
                    )
                  ) {
                    e.preventDefault()
                    dispatch(
                      insertInspectionComments({
                        comment: commentDetails.comments,
                        id: commentDetails.appID,
                        userID: userCredentials.id,
                      })
                    )
                    setModal({
                      viewReport: false,
                    })
                  }
                }}
              >
                <h5>Comment</h5>
                <h6 className='mx-2'>
                  <i>
                    (For your comment to the evaluation and assessment of the
                    evaluator use this section.)
                  </i>
                </h6>
                <Row className='mx-2'>
                  <Col xl='9'>
                    <Form.Control
                      disabled={true}
                      type='text'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      size='sm'
                      name='comments'
                      placeholder='Insert comment here ...'
                      onChange={(e) =>
                        setCommentDetails({
                          ...commentDetails,
                          comments: e.target.value,
                        })
                      }
                      value={commentDetails.comments}
                    />
                  </Col>
                  <Col xl='3'>
                    <Button
                      size='sm'
                      type='submit'
                      variant='info'
                      disabled={true}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form> */}
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant='info'
                onClick={() => {
                  setModal({
                    viewEvaluation: false,
                    viewAssessment: true,
                  })
                }}
              >
                Next
              </Button> */}
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewReport: false,
                    viewReportList: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {retrievedInspectionDetails && retrievedInspectionDetails.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReport: false,
                  viewReportList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {retrievedInspectionDetails && retrievedInspectionDetails.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReport: false,
                  viewReportList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>

      {/* Comment  */}
      <Modal
        size='lg'
        show={modal.viewComments}
        onHide={() => setModal({ viewComments: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            Comment Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className='pb-3'>
              {/* <Form.Group as={Col} xl='6' className='my-1'>
                <Form.Control
                  type='text'
                  disabled
                  placeholder='Receiver Name'
                  size='sm'
                  value={commentDetails.commentToName}
                />
              </Form.Group> */}
              <Form.Group as={Col} xl='6' className='my-1'>
                <Form.Control
                  as='select'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  size='sm'
                  onChange={(e) => {
                    e.target.value === 'Return Application'
                      ? setCommentDetails({
                          ...commentDetails,
                          commentTo:
                            listReport &&
                            listReport.message &&
                            listReport.message[0].inspectID,
                          commentToName:
                            listReport &&
                            listReport.message &&
                            listReport.message[0].EmployeeName,
                          appID: 0,
                          status: e.target.value,
                        })
                      : setCommentDetails({
                          ...commentDetails,
                          commentTo: '',
                          commentToName: 'HIGHER POSITION',
                          appID: 0,
                          status: e.target.value,
                        })
                  }}
                  value={commentDetails.status}
                >
                  <option>Choose task here ...</option>
                  <option value='Return Application'>Return Application</option>
                </Form.Control>
              </Form.Group>
            </Row>

            <Row className='pb-2'>
              {commentDetails.status === 'Recommend Application' ? (
                <Col xl='12'>
                  <Form.Control
                    as='textarea'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    size='sm'
                    name='r_remarks'
                    required
                    placeholder='Insert comment here ...'
                    onChange={(e) => {
                      setCommentDetails({
                        ...commentDetails,
                        comments: e.target.value,
                      })
                    }}
                  />
                </Col>
              ) : (
                <Col xl='10'>
                  <Form.Control
                    as='textarea'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    size='sm'
                    name='r_remarks'
                    required
                    placeholder='Insert comment here ...'
                    onChange={(e) => {
                      setCommentDetails({
                        ...commentDetails,
                        comments: e.target.value,
                      })
                    }}
                  />
                </Col>
              )}
              {commentDetails.status !== 'Recommend Application' && (
                <Col className='d-flex align-items-end pt-2' xl='2'>
                  <Button
                    onClick={() => {
                      handleSubmitComment()
                    }}
                    variant='success'
                    size='sm'
                  >
                    Submit
                  </Button>
                </Col>
              )}
            </Row>

            {commentDetails.status === 'Recommend Application' && (
              <Row>
                <Col xl='10'>
                  <Form.Label className='mb-0'>Penalty</Form.Label>
                  <Form.Control
                    type='number'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    size='sm'
                    name='penalty'
                    required
                    placeholder='Insert penalty here ...'
                    onChange={(e) =>
                      e.target.value <= 100 && setPenaltyInfo(e.target.value)
                    }
                    value={penaltyInfo}
                  />
                </Col>
                <Col className='d-flex align-items-end pt-4' xl='2'>
                  <Button
                    onClick={() => {
                      handleSubmitComment()
                    }}
                    variant='success'
                    size='sm'
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
          <hr />
          <CommentsCard
            state={commentList && commentList.message && commentList.message}
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button
            variant='danger'
            onClick={() =>
              setModal({ viewReportList: true, viewComments: false })
            }
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Comment  */}

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          window.location.reload(true)
        }}
        transLoading={createRecommendation && createRecommendation.loading}
        success={createRecommendation && createRecommendation.message}
        error={createRecommendation && createRecommendation.error}
      />
      {/* End Success Modal */}

      {/*Return Success Modal */}
      <SuccessModal
        show={modal.returnSuccessModal}
        onHide={() => {
          setModal({ returnSuccessModal: false })
          window.location.reload(true)
        }}
        transLoading={returnedAppInspection && returnedAppInspection.loading}
        success={returnedAppInspection && returnedAppInspection.message}
        error={returnedAppInspection && returnedAppInspection.error}
      />
      {/* End Success Modal */}

      {/*Update Penalty Success Modal */}
      <SuccessModal
        show={modal.updatePenalty}
        onHide={() => {
          setModal({ updatePenalty: false })
        }}
        transLoading={updatedPenaltyValue && updatedPenaltyValue.loading}
        success={updatedPenaltyValue && updatedPenaltyValue.message}
        error={updatedPenaltyValue && updatedPenaltyValue.error}
      />
      {/* End Success Modal */}

      {/*Update Admin Fine Success Modal */}
      <SuccessModal
        show={modal.updateAdminFine}
        onHide={() => {
          setModal({ ...modal, updateAdminFine: false })
        }}
        transLoading={updatedAdminFine && updatedAdminFine.loading}
        success={updatedAdminFine && updatedAdminFine.message}
        error={updatedAdminFine && updatedAdminFine.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(RecommendedListScreen)
