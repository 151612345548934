import {
  GET_ISUPERVISOR_FORRECOMMENDATION_REQ,
  GET_ISUPERVISOR_FORRECOMMENDATION_SUCCESS,
  GET_ISUPERVISOR_FORRECOMMENDATION_FAILED,
  GET_INSPECTIONDETAILS_REQ,
  GET_INSPECTIONDETAILS_SUCCESS,
  GET_INSPECTIONDETAILS_FAILED,
  GET_ISUPERVISOR_RECOMMENDED_REQ,
  GET_ISUPERVISOR_RECOMMENDED_SUCCESS,
  GET_ISUPERVISOR_RECOMMENDED_FAILED,
  GET_ISUPERVISOR_LISTREPORT_REQ,
  GET_ISUPERVISOR_LISTREPORT_SUCCESS,
  GET_ISUPERVISOR_LISTREPORT_FAILED,
  INSERT_ISUPERVISOR_COMMENT_REQ,
  INSERT_ISUPERVISOR_COMMENT_SUCCESS,
  INSERT_ISUPERVISOR_COMMENT_FAILED,
  INSERT_ISUPERVISOR_RECOMMENDATION_REQ,
  INSERT_ISUPERVISOR_RECOMMENDATION_SUCCESS,
  INSERT_ISUPERVISOR_RECOMMENDATION_FAILED,
  INSERT_UPDATE_PENALTY_REQ,
  INSERT_UPDATE_PENALTY_SUCCESS,
  INSERT_UPDATE_PENALTY_FAILED,
  GET_ISUPERVISOR_PENALTY_REQ,
  GET_ISUPERVISOR_PENALTY_SUCCESS,
  GET_ISUPERVISOR_PENALTY_FAILED,
  INSERT_RETURNAPPINSPECTION_REQ,
  INSERT_RETURNAPPINSPECTION_SUCCESS,
  INSERT_RETURNAPPINSPECTION_FAILED,
  UPDATE_ADMINFINE_REQ,
  UPDATE_ADMINFINE_SUCCESS,
  UPDATE_ADMINFINE_FAILED,
} from '../../../constants/Inspectors/Supervisor/isupervisorConstants'

export const isupervisorReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ISUPERVISOR_FORRECOMMENDATION_REQ:
      return {
        ...state,
        forRecommendation: { loading: true },
      }

    case GET_ISUPERVISOR_FORRECOMMENDATION_SUCCESS:
      return {
        ...state,
        forRecommendation: { loading: false, message: action.payload },
      }

    case GET_ISUPERVISOR_FORRECOMMENDATION_FAILED:
      return {
        ...state,
        forRecommendation: { loading: false, error: action.payload },
      }
    case GET_INSPECTIONDETAILS_REQ:
      return {
        ...state,
        retrievedInspectionDetails: { loading: true },
      }

    case GET_INSPECTIONDETAILS_SUCCESS:
      return {
        ...state,
        retrievedInspectionDetails: { loading: false, message: action.payload },
      }

    case GET_INSPECTIONDETAILS_FAILED:
      return {
        ...state,
        retrievedInspectionDetails: { loading: false, error: action.payload },
      }
    case GET_ISUPERVISOR_RECOMMENDED_REQ:
      return {
        ...state,
        recommendedList: { loading: true },
      }

    case GET_ISUPERVISOR_RECOMMENDED_SUCCESS:
      return {
        ...state,
        recommendedList: { loading: false, message: action.payload },
      }

    case GET_ISUPERVISOR_RECOMMENDED_FAILED:
      return {
        ...state,
        recommendedList: { loading: false, error: action.payload },
      }
    case GET_ISUPERVISOR_LISTREPORT_REQ:
      return {
        ...state,
        listReport: { loading: true },
      }

    case GET_ISUPERVISOR_LISTREPORT_SUCCESS:
      return {
        ...state,
        listReport: { loading: false, message: action.payload },
      }

    case GET_ISUPERVISOR_LISTREPORT_FAILED:
      return {
        ...state,
        listReport: { loading: false, error: action.payload },
      }
    case INSERT_ISUPERVISOR_COMMENT_REQ:
      return {
        ...state,
        createComment: { loading: true },
      }

    case INSERT_ISUPERVISOR_COMMENT_SUCCESS:
      return {
        ...state,
        createComment: { loading: false, message: action.payload },
      }

    case INSERT_ISUPERVISOR_COMMENT_FAILED:
      return {
        ...state,
        createComment: { loading: false, error: action.payload },
      }
    case INSERT_ISUPERVISOR_RECOMMENDATION_REQ:
      return {
        ...state,
        createRecommendation: { loading: true },
      }

    case INSERT_ISUPERVISOR_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        createRecommendation: { loading: false, message: action.payload },
      }

    case INSERT_ISUPERVISOR_RECOMMENDATION_FAILED:
      return {
        ...state,
        createRecommendation: { loading: false, error: action.payload },
      }
    case INSERT_UPDATE_PENALTY_REQ:
      return {
        ...state,
        updatedPenaltyValue: { loading: true },
      }
    case INSERT_UPDATE_PENALTY_SUCCESS:
      return {
        ...state,
        updatedPenaltyValue: { loading: false, message: action.payload },
      }
    case INSERT_UPDATE_PENALTY_FAILED:
      return {
        ...state,
        updatedPenaltyValue: { loading: false, error: action.payload },
      }
    case UPDATE_ADMINFINE_REQ:
      return {
        ...state,
        updatedAdminFine: { loading: true },
      }
    case UPDATE_ADMINFINE_SUCCESS:
      return {
        ...state,
        updatedAdminFine: { loading: false, message: action.payload },
      }
    case UPDATE_ADMINFINE_FAILED:
      return {
        ...state,
        updatedAdminFine: { loading: false, error: action.payload },
      }
    case GET_ISUPERVISOR_PENALTY_REQ:
      return {
        ...state,
        penaltyDetail: { loading: true },
      }
    case GET_ISUPERVISOR_PENALTY_SUCCESS:
      return {
        ...state,
        penaltyDetail: { loading: false, message: action.payload },
      }
    case GET_ISUPERVISOR_PENALTY_FAILED:
      return {
        ...state,
        penaltyDetail: { loading: false, error: action.payload },
      }
    case INSERT_RETURNAPPINSPECTION_REQ:
      return {
        ...state,
        returnedAppInspection: { loading: true },
      }
    case INSERT_RETURNAPPINSPECTION_SUCCESS:
      return {
        ...state,
        returnedAppInspection: { loading: false, message: action.payload },
      }
    case INSERT_RETURNAPPINSPECTION_FAILED:
      return {
        ...state,
        returnedAppInspection: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
