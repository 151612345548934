import {
  GET_TOTAL_APP_REQ,
  GET_TOTAL_APP_SUCCESS,
  GET_TOTAL_APP_FAILED,
  GET_EMPLOYEES_REQ,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILED,
  GET_USERS_REQ,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  GET_USERS_FOR_VERIFICATION_REQ,
  GET_USERS_FOR_VERIFICATION_SUCCESS,
  GET_USERS_FOR_VERIFICATION_FAILED,
  GET_REGISTERED_ENG_REQ,
  GET_REGISTERED_ENG_SUCCESS,
  GET_REGISTERED_ENG_FAILED,
  GET_NOTICE_OF_VIOLATION_REQ,
  GET_NOTICE_OF_VIOLATION_SUCCESS,
  GET_NOTICE_OF_VIOLATION_FAILED,
  GET_NEWS_N_UPDATES_REQ,
  GET_NEWS_N_UPDATES_SUCCESS,
  GET_NEWS_N_UPDATES_FAILED,
  GET_MESSAGES_REQ,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILED,
  GET_USERLIST_REQ,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_FAILED,
  GET_APPSUMMARY_REQ,
  GET_APPSUMMARY_SUCCESS,
  GET_APPSUMMARY_FAILED,
  GET_NEWSNUPDATELIST_REQ,
  GET_NEWSNUPDATELIST_SUCCESS,
  GET_NEWSNUPDATELIST_FAILED,
  GET_EMPSIGNATURE_REQ,
  GET_EMPSIGNATURE_SUCCESS,
  GET_EMPSIGNATURE_FAILED,
  UPDATE_DLFORMS_REQ,
  UPDATE_DLFORMS_SUCCESS,
  UPDATE_DLFORMS_FAILED,
  UPDATE_USERPASSWORD_REQ,
  UPDATE_USERPASSWORD_SUCCESS,
  UPDATE_USERPASSWORD_FAILED,
  UPDATE_NEWSNUPDATEINFO_REQ,
  UPDATE_NEWSNUPDATEINFO_SUCCESS,
  UPDATE_NEWSNUPDATEINFO_FAILED,
  UPDATE_EMPSIGNATURE_REQ,
  UPDATE_EMPSIGNATURE_SUCCESS,
  UPDATE_EMPSIGNATURE_FAILED,
  CREATE_DLFORMS_REQ,
  CREATE_DLFORMS_SUCCESS,
  CREATE_DLFORMS_FAILED,
  CREATE_NEWSNUPDATEINFO_REQ,
  CREATE_NEWSNUPDATEINFO_SUCCESS,
  CREATE_NEWSNUPDATEINFO_FAILED,
  CREATE_EMPSIGNATURE_REQ,
  CREATE_EMPSIGNATURE_SUCCESS,
  CREATE_EMPSIGNATURE_FAILED,
  DELETE_DLFORMS_REQ,
  DELETE_DLFORMS_SUCCESS,
  DELETE_DLFORMS_FAILED,
  DELETE_USER_REQ,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_NEWSNUPDATEINFO_REQ,
  DELETE_NEWSNUPDATEINFO_SUCCESS,
  DELETE_NEWSNUPDATEINFO_FAILED,
} from '../../constants/Super Admin/superAdminConstants'

export const superAdminReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_TOTAL_APP_REQ:
      return {
        ...state,
        retrievedTApp: { loading: true },
      }
    case GET_TOTAL_APP_SUCCESS:
      return {
        ...state,
        retrievedTApp: { loading: false, message: action.payload },
      }
    case GET_TOTAL_APP_FAILED:
      return {
        ...state,
        retrievedTApp: { loading: false, error: action.payload },
      }
    case GET_EMPLOYEES_REQ:
      return {
        ...state,
        retrievedTEmployees: { loading: true },
      }
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        retrievedTEmployees: { loading: false, message: action.payload },
      }
    case GET_EMPLOYEES_FAILED:
      return {
        ...state,
        retrievedTEmployees: { loading: false, error: action.payload },
      }
    case GET_USERS_REQ:
      return {
        ...state,
        retrievedTUsers: { loading: true },
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        retrievedTUsers: { loading: false, message: action.payload },
      }
    case GET_USERS_FAILED:
      return {
        ...state,
        retrievedTUsers: { loading: false, error: action.payload },
      }
    case GET_USERS_FOR_VERIFICATION_REQ:
      return {
        ...state,
        retrievedTUsersForVerification: { loading: true },
      }
    case GET_USERS_FOR_VERIFICATION_SUCCESS:
      return {
        ...state,
        retrievedTUsersForVerification: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_USERS_FOR_VERIFICATION_FAILED:
      return {
        ...state,
        retrievedTUsersForVerification: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_REGISTERED_ENG_REQ:
      return {
        ...state,
        retrievedTRegisteredEng: { loading: true },
      }
    case GET_REGISTERED_ENG_SUCCESS:
      return {
        ...state,
        retrievedTRegisteredEng: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_REGISTERED_ENG_FAILED:
      return {
        ...state,
        retrievedTRegisteredEng: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_NOTICE_OF_VIOLATION_REQ:
      return {
        ...state,
        retrievedTNOV: { loading: true },
      }
    case GET_NOTICE_OF_VIOLATION_SUCCESS:
      return {
        ...state,
        retrievedTNOV: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_NOTICE_OF_VIOLATION_FAILED:
      return {
        ...state,
        retrievedTNOV: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_NEWS_N_UPDATES_REQ:
      return {
        ...state,
        retrievedTNUP: { loading: true },
      }
    case GET_NEWS_N_UPDATES_SUCCESS:
      return {
        ...state,
        retrievedTNUP: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_NEWS_N_UPDATES_FAILED:
      return {
        ...state,
        retrievedTNUP: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_MESSAGES_REQ:
      return {
        ...state,
        retrievedTMessages: { loading: true },
      }
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        retrievedTMessages: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_MESSAGES_FAILED:
      return {
        ...state,
        retrievedTMessages: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_USERLIST_REQ:
      return {
        ...state,
        retrievedUserList: { loading: true },
      }
    case GET_USERLIST_SUCCESS:
      return {
        ...state,
        retrievedUserList: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_USERLIST_FAILED:
      return {
        ...state,
        retrievedUserList: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_APPSUMMARY_REQ:
      return {
        ...state,
        retrievedAppSummary: { loading: true },
      }
    case GET_APPSUMMARY_SUCCESS:
      return {
        ...state,
        retrievedAppSummary: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_APPSUMMARY_FAILED:
      return {
        ...state,
        retrievedAppSummary: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_NEWSNUPDATELIST_REQ:
      return {
        ...state,
        retrievedNewsNUpdateList: { loading: true },
      }
    case GET_NEWSNUPDATELIST_SUCCESS:
      return {
        ...state,
        retrievedNewsNUpdateList: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_NEWSNUPDATELIST_FAILED:
      return {
        ...state,
        retrievedNewsNUpdateList: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_EMPSIGNATURE_REQ:
      return {
        ...state,
        retrievedEmpSignature: { loading: true },
      }
    case GET_EMPSIGNATURE_SUCCESS:
      return {
        ...state,
        retrievedEmpSignature: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_EMPSIGNATURE_FAILED:
      return {
        ...state,
        retrievedEmpSignature: {
          loading: false,
          error: action.payload,
        },
      }
    case UPDATE_DLFORMS_REQ:
      return {
        ...state,
        updatedDlForm: { loading: true },
      }
    case UPDATE_DLFORMS_SUCCESS:
      return {
        ...state,
        updatedDlForm: {
          loading: false,
          message: action.payload,
        },
      }
    case UPDATE_DLFORMS_FAILED:
      return {
        ...state,
        updatedDlForm: {
          loading: false,
          error: action.payload,
        },
      }
    case UPDATE_USERPASSWORD_REQ:
      return {
        ...state,
        updatedUserPassword: { loading: true },
      }
    case UPDATE_USERPASSWORD_SUCCESS:
      return {
        ...state,
        updatedUserPassword: {
          loading: false,
          message: action.payload,
        },
      }
    case UPDATE_USERPASSWORD_FAILED:
      return {
        ...state,
        updatedUserPassword: {
          loading: false,
          error: action.payload,
        },
      }
    case UPDATE_NEWSNUPDATEINFO_REQ:
      return {
        ...state,
        updatedNewsNUpdateInfo: { loading: true },
      }
    case UPDATE_NEWSNUPDATEINFO_SUCCESS:
      return {
        ...state,
        updatedNewsNUpdateInfo: {
          loading: false,
          message: action.payload,
        },
      }
    case UPDATE_NEWSNUPDATEINFO_FAILED:
      return {
        ...state,
        updatedNewsNUpdateInfo: {
          loading: false,
          error: action.payload,
        },
      }

    case UPDATE_EMPSIGNATURE_REQ:
      return {
        ...state,
        updatedEmpSignature: { loading: true },
      }
    case UPDATE_EMPSIGNATURE_SUCCESS:
      return {
        ...state,
        updatedEmpSignature: {
          loading: false,
          message: action.payload,
        },
      }
    case UPDATE_EMPSIGNATURE_FAILED:
      return {
        ...state,
        updatedEmpSignature: {
          loading: false,
          error: action.payload,
        },
      }

    case CREATE_DLFORMS_REQ:
      return {
        ...state,
        createdDlForm: { loading: true },
      }
    case CREATE_DLFORMS_SUCCESS:
      return {
        ...state,
        createdDlForm: {
          loading: false,
          message: action.payload,
        },
      }
    case CREATE_DLFORMS_FAILED:
      return {
        ...state,
        createdDlForm: {
          loading: false,
          error: action.payload,
        },
      }
    case CREATE_NEWSNUPDATEINFO_REQ:
      return {
        ...state,
        createdNewsNUpdateInfo: { loading: true },
      }
    case CREATE_NEWSNUPDATEINFO_SUCCESS:
      return {
        ...state,
        createdNewsNUpdateInfo: {
          loading: false,
          message: action.payload,
        },
      }
    case CREATE_NEWSNUPDATEINFO_FAILED:
      return {
        ...state,
        createdNewsNUpdateInfo: {
          loading: false,
          error: action.payload,
        },
      }

    case CREATE_EMPSIGNATURE_REQ:
      return {
        ...state,
        createdEmpSignature: { loading: true },
      }
    case CREATE_EMPSIGNATURE_SUCCESS:
      return {
        ...state,
        createdEmpSignature: {
          loading: false,
          message: action.payload,
        },
      }
    case CREATE_EMPSIGNATURE_FAILED:
      return {
        ...state,
        createdEmpSignature: {
          loading: false,
          error: action.payload,
        },
      }

    case DELETE_DLFORMS_REQ:
      return {
        ...state,
        deletedDlForm: { loading: true },
      }
    case DELETE_DLFORMS_SUCCESS:
      return {
        ...state,
        deletedDlForm: {
          loading: false,
          message: action.payload,
        },
      }
    case DELETE_DLFORMS_FAILED:
      return {
        ...state,
        deletedDlForm: {
          loading: false,
          error: action.payload,
        },
      }
    case DELETE_USER_REQ:
      return {
        ...state,
        deletedUser: { loading: true },
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deletedUser: {
          loading: false,
          message: action.payload,
        },
      }
    case DELETE_USER_FAILED:
      return {
        ...state,
        deletedUser: {
          loading: false,
          error: action.payload,
        },
      }
    case DELETE_NEWSNUPDATEINFO_REQ:
      return {
        ...state,
        deletedNewsNUpdate: { loading: true },
      }
    case DELETE_NEWSNUPDATEINFO_SUCCESS:
      return {
        ...state,
        deletedNewsNUpdate: {
          loading: false,
          message: action.payload,
        },
      }
    case DELETE_NEWSNUPDATEINFO_FAILED:
      return {
        ...state,
        deletedNewsNUpdate: {
          loading: false,
          error: action.payload,
        },
      }
    default:
      return state
  }
}
