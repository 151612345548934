import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Pagination, Table, Button, Container, Row, Col } from 'react-bootstrap'
import { FaEllipsisH } from 'react-icons/fa'

const NoticeOfViolationList = ({ data, searchTerm, handleView }) => {
  // Pagination Area Forms
  const [list, setList] = useState([])
  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(10)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)

  useEffect(() => {
    if (data) {
      setList(() =>
        data.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.id === parseInt(searchTerm)
            )
          }
        })
      )
    }
  }, [data, searchTerm])

  useEffect(() => {
    setpaginatedForms(_(list).slice(0).take(pageNumberLimit).value())
    setcurrentPage(1)
  }, [list, pageNumberLimit])

  const pageCount = list ? Math.ceil(list.length / pageNumberLimit) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageNumberLimit
    const paginationForms = _(list)
      .slice(startIndex)
      .take(pageNumberLimit)
      .value()
    setpaginatedForms(paginationForms)
  }

  //   Display Pagination list
  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
          size='sm'
          variant='info'
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })
  // End Display Pagination list

  // Buttons
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Buttons

  // End Pagination Area Forms
  return (
    <Container fluid>
      <Row className='my-4'>
        <Col xl={12} style={{ background: '#FFF' }}>
          <h5 className='pt-3 pb-2 mb-0'>Notice of Violation</h5>
          <hr
            className='mt-1 mb-3'
            style={{
              background: '#ffeb36',
              paddingBottom: '2px',
              width: '200px',
            }}
          />
          <Table size='sm' bordered hover responsive>
            <thead className='bg-info text-light'>
              <tr>
                <td className='text-center'>ID</td>
                <td>OWNER</td>
                <td>LOCATION</td>
                <td>DATE CREATED</td>
                <td>CREATED BY</td>
                <td className='text-center'>ACTIONS</td>
              </tr>
            </thead>
            <tbody>
              {paginatedForms.map((x, index) => (
                <tr key={index}>
                  <td className='text-center'>{x.id}</td>
                  <td>{x.owner}</td>
                  <td className='text-nowrap'>{x.location}</td>
                  <td>{moment(x.timeStamp).format('MMMM D, YYYY h:mma')}</td>
                  <td className='text-nowrap'>{x.CreatedBy}</td>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        size='sm'
                        onClick={() => handleView(x)}
                        variant='light'
                        className='text-nowrap mx-1 text-success'
                      >
                        <FaEllipsisH />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination size='sm' className='d-flex justify-content-end mx-2'>
            <Pagination.Item
              className='mt-0 fs-6 text-nowrap text-success'
              variant='info'
              onClick={handlePrevbtn}
              disabled={currentPage === pages[0] ? true : false}
            >
              &lt;
            </Pagination.Item>

            {renderPageNumber}

            <Pagination.Item
              className='mt-0 fs-6 text-nowrap text-success'
              variant='info'
              onClick={handleNextbtn}
              disabled={currentPage === pages[pages.length - 1] ? true : false}
            >
              &gt;
            </Pagination.Item>
          </Pagination>
        </Col>
      </Row>
      {/* Data Table */}

      {/* End Data Table */}
    </Container>
  )
}

export default NoticeOfViolationList
