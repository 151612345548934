import {
  USER_APPOINTMENT_REQUEST,
  USER_APPOINTMENT_SUCCESS,
  USER_APPOINTMENT_FAILED,
  USER_APPOINTMENT_INSERT_SUCCESS,
  USER_APPOINTMENT_INSERT_FAILED,
  USER_APPOINTMENT_GETBYID_REQUEST,
  USER_APPOINTMENT_GETBYID_SUCCESS,
  USER_APPOINTMENT_GETBYID_FAILED,
  USER_APPOINTMENT_UPDATE_REQUEST,
  USER_APPOINTMENT_UPDATE_SUCCESS,
  USER_APPOINTMENT_UPDATE_FAILED,
  USER_APPOINTMENT_DELETE_REQUEST,
  USER_APPOINTMENT_DELETE_SUCCESS,
  USER_APPOINTMENT_DELETE_FAILED,
  GET_APPOINTMENT_COUNT_REQ,
  GET_APPOINTMENT_COUNT_SUCCESS,
  GET_APPOINTMENT_COUNT_FAILED,
} from '../constants/appointmentsConstant'

export const appointmentsReducer = (
  state = { appointmentsDetail: [] },
  action
) => {
  switch (action.type) {
    case USER_APPOINTMENT_REQUEST:
      return { loading: true, state }
    case USER_APPOINTMENT_SUCCESS:
      return { loading: false, appointmentsDetail: action.payload }
    case USER_APPOINTMENT_FAILED:
      return {
        loading: false,
        error: action.payload,
      }
    case USER_APPOINTMENT_INSERT_SUCCESS:
      return {
        loading: false,
        ...state,
        result: action.payload,
      }
    case USER_APPOINTMENT_INSERT_FAILED:
      return { loading: false, error: action.payload }
    case USER_APPOINTMENT_GETBYID_REQUEST:
      return { ...state }
    case USER_APPOINTMENT_GETBYID_SUCCESS:
      return { ...state, singleAppointment: action.payload }
    case USER_APPOINTMENT_GETBYID_FAILED:
      return { ...state, error: action.payload }
    case USER_APPOINTMENT_UPDATE_REQUEST:
      return { ...state }
    case USER_APPOINTMENT_UPDATE_SUCCESS:
      return { ...state, updatedMessage: action.payload }
    case USER_APPOINTMENT_UPDATE_FAILED:
      return { ...state, error: action.payload }
    case USER_APPOINTMENT_DELETE_REQUEST:
      return { ...state }
    case USER_APPOINTMENT_DELETE_SUCCESS:
      return { ...state, deletedMessage: action.payload }
    case USER_APPOINTMENT_DELETE_FAILED:
      return { ...state, error: action.payload }
    case GET_APPOINTMENT_COUNT_REQ:
      return { ...state, appointmentCount: { loading: true } }
    case GET_APPOINTMENT_COUNT_SUCCESS:
      return {
        ...state,
        appointmentCount: { loading: false, result: action.payload },
      }
    case GET_APPOINTMENT_COUNT_FAILED:
      return {
        ...state,
        appointmentCount: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
