import {
  CREATE_ELECTRICAL_ASSESSMENT_REQ,
  CREATE_ELECTRICAL_ASSESSMENT_SUCCESS,
  CREATE_ELECTRICAL_ASSESSMENT_FAILED,
  UPDATE_ELECTRICAL_ASSESSMENT_REQ,
  UPDATE_ELECTRICAL_ASSESSMENT_SUCCESS,
  UPDATE_ELECTRICAL_ASSESSMENT_FAILED,
  GET_ELECTRICAL_ASSESSMENT_REQ,
  GET_ELECTRICAL_ASSESSMENT_SUCCESS,
  GET_ELECTRICAL_ASSESSMENT_FAILED,
} from '../../constants/Assessment/electricalAssessmentConstants'

export const electricalAssessmentReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ELECTRICAL_ASSESSMENT_REQ:
      return {
        ...state,
        createAssessment: { loading: true },
      }
    case CREATE_ELECTRICAL_ASSESSMENT_SUCCESS:
      return {
        ...state,
        createAssessment: { loading: false, message: action.payload },
      }
    case CREATE_ELECTRICAL_ASSESSMENT_FAILED:
      return {
        ...state,
        createAssessment: { loading: false, error: action.payload },
      }
    case UPDATE_ELECTRICAL_ASSESSMENT_REQ:
      return {
        ...state,
        updateAssessment: { loading: true },
      }
    case UPDATE_ELECTRICAL_ASSESSMENT_SUCCESS:
      return {
        ...state,
        updateAssessment: { loading: false, message: action.payload },
      }
    case UPDATE_ELECTRICAL_ASSESSMENT_FAILED:
      return {
        ...state,
        updateAssessment: { loading: false, error: action.payload },
      }
    case GET_ELECTRICAL_ASSESSMENT_REQ:
      return {
        ...state,
        assessmentDetails: { loading: true },
      }
    case GET_ELECTRICAL_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessmentDetails: { loading: false, message: action.payload },
      }
    case GET_ELECTRICAL_ASSESSMENT_FAILED:
      return {
        ...state,
        assessmentDetails: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
