import React, { useEffect } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { FaSignOutAlt, FaUserAlt, FaHome } from 'react-icons/fa'
// Redux Requirements
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import { Logout } from '../../redux/actions/userActions'

const UserHeader = ({ history, icons }) => {
  const user = useSelector((state) => state.users)
  const { userCredentials, userlogout } = user
  const dispatch = useDispatch()

  const handleLogout = (e) => {
    dispatch(Logout())
  }

  // console.log(userlogout)

  useEffect(() => {
    if (userlogout && !userlogout.loading) {
      if (userlogout.message) {
        history.push('/')
        window.location.reload()
      }
    }
  }, [userlogout])

  return (
    <header className='header-main'>
      <div style={{ color: '#FFF' }}>
        <Navbar className='pb-0 pt-3 mx-3 pb-3' expand='lg' collapseOnSelect>
          <Nav className='me-auto'>{icons}</Nav>
          {/* <Nav>
            <LinkContainer to='/dashboard'>
              <Nav.Link>dashboard</Nav.Link>
            </LinkContainer>
          </Nav> */}
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav variant='underline' className='me-auto' active=''>
              <Nav.Item>
                <Nav.Link href='/dashboard'>
                  <FaHome className='fs-5 text-success d-lg-none d-xl-block' />
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Nav>
              {userCredentials.roles !== 'engr' ? (
                <Nav.Item>
                  <Nav.Link href='/profile'>
                    <FaUserAlt className='mr-1 text-info' />
                    {userCredentials.username}
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Link>
                  <FaUserAlt className='mr-1 text-info' />
                  {userCredentials.username}
                </Nav.Link>
              )}

              {/* <LinkContainer className='mx-1'> */}
              <Nav.Link onClick={handleLogout}>
                <FaSignOutAlt className='mr-1 text-danger' />
                Logout
              </Nav.Link>
              {/* </LinkContainer> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      {/* <hr /> */}
    </header>
  )
}

export default withRouter(UserHeader)
