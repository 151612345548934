import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 65,
    paddingHorizontal: 20,
  },
  row: {
    flexDirection: 'row',
  },
  rowEnd: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  rowBorderBottom: {
    flexDirection: 'row',
    borderBottomColor: '#e4e1e3',
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 10,
  },
  headerLeft: {
    fontSize: 11,
    width: '50%',
  },
  headerRight: {
    textAlign: 'right',
    fontSize: 11,
    width: '50%',
  },
  headerCenter: {
    textAlign: 'center',
    fontSize: 12,
    width: '100%',
  },
  colLabel: {
    marginTop: 15,
    marginBottom: 5,
    fontSize: 11,
    width: '25%',
    color: '#9f959b',
    textIndent: '30px',
  },
  colData: {
    marginTop: 7,
    fontSize: 11,
    width: '25%',
    color: 'black',
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  colSignatureRight: {
    fontSize: 11,
    width: '35%',
    color: 'black',
    textTransform: 'capitalize',
    textAlign: 'center',
    borderTop: '1px',
    marginTop: 80,
    marginLeft: '50px',
  },
  colSignatureLeft: {
    fontSize: 11,
    width: '35%',
    color: 'black',
    textTransform: 'capitalize',
    textAlign: 'center',
    borderTop: '1px',
    marginTop: 80,
    marginLeft: '50px',
  },
  colDataFull: {
    marginTop: 7,
    fontSize: 11,
    width: '100%',
    color: 'black',
    textTransform: 'capitalize',
    textIndent: '30px',
    textAlign: 'justify',
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 20,
  },
})

const PrintRoutingSlip = ({
  appInfo,
  submittedDocs,
  dcTimeProcess,
  evaluatorsTimeProcess,
  inspectorTimeProcess,
  tradesInfo,
}) => {
  return (
    <Document>
      <Page size='Legal' style={styles.body} wrap>
        {/* Header */}
        <View style={styles.row}>
          <Text style={styles.headerLeft}>CITY OF LAS PINAS</Text>
          <Text style={styles.headerRight}>09-Feb-22 15:03:14</Text>
        </View>
        <View style={styles.rowBorderBottom}>
          <Text style={styles.headerLeft}>Office of the City Engineer</Text>
        </View>
        {/*End Header */}

        <View style={styles.row}>
          <Text style={styles.headerCenter}>ROUTING SLIP</Text>
        </View>

        {/* Application No. */}
        <>
          <View style={styles.row}>
            <Text style={styles.title}>APPLICATION NO.: </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.colLabel}>
              {appInfo && appInfo.message && appInfo.message[0].appID}
            </Text>
          </View>
        </>

        {/* Applicant Name */}
        <>
          <View style={styles.row}>
            <Text style={styles.title}>APPLICANT</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.colLabel}>Last Name:</Text>
            <Text style={styles.colLabel}>First Name:</Text>
            <Text style={styles.colLabel}>Middle Name:</Text>
          </View>
          {appInfo &&
            appInfo.message &&
            appInfo.message.map((el, index) => (
              <View key={index} style={styles.row}>
                <Text style={styles.colData}>{el.lastName}</Text>
                <Text style={styles.colData}>{el.firstName}</Text>
                <Text style={styles.colData}>{el.middleName}</Text>
              </View>
            ))}
        </>
        {/*End Applicant Name */}

        {/* Address */}
        <>
          <View style={styles.row}>
            <Text style={styles.colLabel}>Address:</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.colDataFull}>
              {appInfo && appInfo.message && appInfo.message[0].Address}
            </Text>
          </View>
        </>
        {/* End Address */}

        {/* Site */}
        <>
          <View style={styles.row}>
            <Text style={styles.title}>SITE</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.colLabel}>Street:</Text>
            <Text style={styles.colLabel}>Area:</Text>
            <Text style={styles.colLabel}>Barangay:</Text>
          </View>
          <View style={styles.rowBorderBottom}>
            <Text style={styles.colData}>
              {appInfo && appInfo.message && appInfo.message[0].ConstStreet}
            </Text>
            <Text style={styles.colData}>
              {appInfo && appInfo.message && appInfo.message[0].ConstArea}
            </Text>
            <Text style={styles.colData}>
              {appInfo && appInfo.message && appInfo.message[0].ConstBarangay}
            </Text>
          </View>
        </>
        {/* End Site */}

        {/* Permits */}
        <>
          <View style={styles.row}>
            <Text style={styles.title}>
              {appInfo && appInfo.message && appInfo.message[0].ApplicationName}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.colLabel}>Scope of Work:</Text>
            <Text style={styles.colLabel}>Specified:</Text>
            <Text style={styles.colLabel}>Use Type:</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.colData}>
              {appInfo && appInfo.message && appInfo.message[0].scopeOfWork}
            </Text>
            <Text style={styles.colData}>
              {appInfo && appInfo.message && appInfo.message[0].sw_specify}
            </Text>
            <Text style={styles.colData}>
              {appInfo && appInfo.message && appInfo.message[0].useType}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.colLabel}>No. of Storeys:</Text>
            <Text style={styles.colLabel}>Floor Area:</Text>
            <Text style={styles.colLabel}>Compute As:</Text>
            <Text style={styles.colLabel}>No. of Units:</Text>
          </View>
          <View style={styles.rowBorderBottom}>
            <Text style={styles.colData}>
              {appInfo && appInfo.message && appInfo.message[0].noOfStoreys}
            </Text>
            <Text style={styles.colData}>
              {appInfo && appInfo.message && appInfo.message[0].floorArea}
            </Text>
            <Text style={styles.colData}>
              {appInfo && appInfo.message && appInfo.message[0].computeAs}
            </Text>
            <Text style={styles.colData}>
              {appInfo && appInfo.message && appInfo.message[0].noOfUnits}
            </Text>
          </View>

          {tradesInfo &&
            tradesInfo.map((el, index) => (
              <View key={index}>
                <View style={styles.row}>
                  <Text style={styles.title}>{el.trade}</Text>
                </View>

                <View style={styles.rowBorderBottom}>
                  <Text style={styles.colLabel}>Scope of Work:</Text>
                  <Text style={styles.colLabel}>Specified:</Text>
                </View>
              </View>
            ))}
        </>
        {/* End Permits */}

        {/* Schedules */}
        <View>
          <View style={{ flexDirection: 'row', marginBottom: 20 }}>
            <Text style={styles.colLabel}></Text>
            <Text style={styles.colLabel}>Date/Time In:</Text>
            <Text style={styles.colLabel}>Date/Time Out:</Text>
            <Text style={styles.colLabel}>Person in-charge:</Text>
          </View>
          <View style={styles.rowBorderBottom}>
            <Text style={styles.colData}>Document Controller</Text>
            <Text style={styles.colData}>
              {dcTimeProcess && dcTimeProcess[0].Time_In}
            </Text>
            <Text style={styles.colData}>
              {dcTimeProcess && dcTimeProcess[0].Time_out}
            </Text>
            <Text style={styles.colData}>
              {dcTimeProcess && dcTimeProcess[0].EmployeeName}
            </Text>
          </View>
          <View style={styles.rowBorderBottom}>
            <Text style={styles.colData}>Inspector</Text>
            <Text style={styles.colData}>
              {inspectorTimeProcess &&
                inspectorTimeProcess !== false &&
                inspectorTimeProcess[0].timeStamp}
            </Text>
            <Text style={styles.colData}>
              {inspectorTimeProcess &&
                inspectorTimeProcess !== false &&
                inspectorTimeProcess[0].inspectionDate}
            </Text>
            <Text style={styles.colData}>
              {inspectorTimeProcess &&
                inspectorTimeProcess !== false &&
                inspectorTimeProcess[0].EmployeeName}
            </Text>
          </View>

          {tradesInfo &&
            tradesInfo.map((el, index) => (
              <View key={index} style={styles.rowBorderBottom}>
                <Text style={styles.colData}>{el.trade}</Text>
                <Text style={styles.colData}>{el.timeStamp}</Text>
                <Text style={styles.colData}>{el.withAssessment}</Text>
                <Text style={styles.colData}>{el.EmployeeName}</Text>
              </View>
            ))}

          {/* {evaluatorsTimeProcess &&
            evaluatorsTimeProcess.map((el, index) => (
              <View key={index} style={styles.rowBorderBottom}>
                <Text style={styles.colData}>{el.trade}</Text>
                <Text style={styles.colData}>{el.Time_In}</Text>
                <Text style={styles.colData}>{el.Time_Out}</Text>
                <Text style={styles.colData}></Text>
              </View>
            ))} */}
        </View>
        {/* End Schedules */}

        {/* Submitted Documents */}
        <>
          <View style={styles.row}>
            <Text style={styles.title}>Submitted Documents</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.colLabel}>File Name:</Text>
            <Text style={styles.colLabel}>Date Submitted:</Text>
          </View>
          {submittedDocs &&
            submittedDocs.message &&
            submittedDocs.message.map((el, index) => (
              <View style={styles.rowBorderBottom}>
                <Text style={styles.colData}>{el.FileName}</Text>
                <Text style={styles.colData}>{el.timeStamp}</Text>
              </View>
            ))}
          {/* <View style={styles.rowBorderBottom}></View> */}
        </>
        {/* End Submitted Documents */}
      </Page>
    </Document>
  )
}

export default PrintRoutingSlip
