import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
  Pagination,
  Modal,
  Form,
  Spinner,
  Carousel,
} from 'react-bootstrap'
import {
  FaCreditCard,
  FaPlusCircle,
  FaCalendarAlt,
  FaRegFileAlt,
  FaCheckCircle,
  FaDownload,
  FaInfoCircle,
} from 'react-icons/fa'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'

// Components
import UserMain from '../layout/UserLayout/UserMain'
import DownloadableForms from '../reusable/DownloadableForms'

// Actions
import { downloadableformsRetrieve } from '../redux/actions/downloadableformsActions'
import { newsandupdatesRetrieve } from '../redux/actions/newsandupdatesActions'
import {
  getApplicationForVerification,
  getApplicationForInspection,
  getApplicationForCompliance,
  getApplicationApproved,
} from '../redux/actions/userApplicationActions'
import { getUserProfile } from '../redux/actions/userProfileAction'

const UserDashboarScreen = ({ background, history, border }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const downloadableforms = useSelector((state) => state.downloadableforms)
  const { downloadableformsDetail } = downloadableforms

  const newsandupdates = useSelector((state) => state.newsandupdates)
  const { newsDetails } = newsandupdates

  const applications = useSelector((state) => state.applications)
  const {
    // userApplicationsDetails,
    appVerification,
    appInspection,
    appCompliance,
    appApproved,
  } = applications

  const userProfile = useSelector((state) => state.userProfile)
  const { profile } = userProfile

  const [userInfo] = useState({
    userID: userCredentials.id,
  })

  const [details] = useState({
    id: userCredentials.id,
  })

  const [newsID, setNewsID] = useState({
    id: '',
  })

  const [showNews, setShowNews] = useState(false)

  const [currentNews, setCurrentNews] = useState([])

  // Pagination Area News and Updates
  const [nAupaginatedForms, setnAupaginatedForms] = useState([])
  const [nAucurrentPage, setnAucurrentPage] = useState(1)

  const [nAupageNumberLimit] = useState(3)
  const [maxnAuPageNumberLimit, setmaxnAuPageNumberLimit] = useState(3)
  const [minnAuPageNumberLimit, setminnAuPageNumberLimit] = useState(0)

  const nAupageSize = 3

  useEffect(() => {
    setnAupaginatedForms(_(currentNews).slice(0).take(nAupageSize).value())
    setnAucurrentPage(1)
  }, [currentNews])

  const nAupageCount = currentNews
    ? Math.ceil(currentNews.length / nAupageSize)
    : 0

  const nAupages = _.range(1, nAupageCount + 1)

  const nAupagination = (pageNo) => {
    setnAucurrentPage(pageNo)
    const nAustartIndex = (pageNo - 1) * nAupageSize
    const nAupaginationForms = _(currentNews)
      .slice(nAustartIndex)
      .take(nAupageSize)
      .value()
    setnAupaginatedForms(nAupaginationForms)
  }

  const nAurenderPageNumber = nAupages.map((page) => {
    if (page < maxnAuPageNumberLimit + 1 && page > minnAuPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => nAupagination(page)}
          active={page === nAucurrentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const nAuhandleNextbtn = () => {
    setnAucurrentPage(nAucurrentPage + 1)
    nAupagination(nAucurrentPage + 1)

    if (nAucurrentPage + 1 > maxnAuPageNumberLimit) {
      setmaxnAuPageNumberLimit(maxnAuPageNumberLimit + nAupageNumberLimit)
      setminnAuPageNumberLimit(minnAuPageNumberLimit + nAupageNumberLimit)
    }
  }

  const nAuhandlePrevbtn = () => {
    setnAucurrentPage(nAucurrentPage - 1)
    nAupagination(nAucurrentPage - 1)

    if ((nAucurrentPage - 1) % nAupageNumberLimit === 0) {
      setmaxnAuPageNumberLimit(maxnAuPageNumberLimit - nAupageNumberLimit)
      setminnAuPageNumberLimit(minnAuPageNumberLimit - nAupageNumberLimit)
    }
  }
  // End Pagination Area Downloadable Forms

  const handleEditModal = (payload) => {
    setNewsID({
      id: payload,
    })
    setShowNews(true)
  }

  // UseEffect
  useEffect(() => {
    dispatch(getApplicationForVerification(userInfo))
    dispatch(getApplicationForInspection(userInfo))
    dispatch(getApplicationForCompliance(userInfo))
    dispatch(getApplicationApproved(userInfo))
    dispatch(downloadableformsRetrieve())
    dispatch(newsandupdatesRetrieve())
    dispatch(getUserProfile(details))
  }, [dispatch, userInfo, details])

  useEffect(() => {
    if (newsDetails && newsDetails.message) {
      setCurrentNews(newsDetails.message)
    }
  })
  // End UseEffect

  return (
    <UserMain>
      <Container fluid>
        {/* Verification Card */}
        {profile && profile[0].PRA_id === null && (
          <Card bg='warning' className='mb-3 mt-3'>
            <Card.Header>
              <h4 className='text-light'>Complete account details</h4>
            </Card.Header>
            <Card.Body>
              <blockquote className='blockquote  mb-0'>
                <p className='text-white'>
                  --- To complete account details kindly direct to your profile
                  page.
                </p>
              </blockquote>
            </Card.Body>
          </Card>
        )}
        {/*End Verification Card */}

        {/* Application Action Btn */}
        <Row className='mb-1 mt-3 mx-2'>
          <Col
            xl={12}
            className='border border-secondary rounded px-0'
            style={{ background: '#0e3207' }}
          >
            <Row>
              <Col sm={12} md={8} lg={9} xl={6}>
                <h5 className='mx-3 pt-3' style={{ color: '#FFF' }}>
                  Applying for Permits?
                </h5>
              </Col>
              <Col
                sm={12}
                md={4}
                lg={3}
                xl={6}
                className='d-flex justify-content-end py-2 rounded'
              >
                {profile && profile[0].PRA_id !== null ? (
                  <Button
                    onClick={() => {
                      history.push('/apply')
                    }}
                    className='mx-3 text-white'
                    variant='success'
                  >
                    <FaPlusCircle /> Apply
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      alert(
                        'You must complete your account details first at the Profile page'
                      )
                    }}
                    className='mx-3 text-white'
                    variant='success'
                  >
                    <FaPlusCircle /> Apply
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {/*End Application Action Btn */}

        {/* Application Details */}
        <Row className='my-3'>
          <Col md={3} className='my-1'>
            <Row
              className='pt-4 px-4 border border-secondary rounded mx-2 shadow-sm'
              style={{ background: '#FFF' }}
            >
              <Col sm={12} md={12} lg={6} xl={6} className='pb-3'>
                <FaCreditCard style={{ fontSize: '70px', color: '#1f9bcf' }} />
              </Col>
              <Col
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className='text-end fw-bold fs-3'
              >
                <p className='fs-6'>TOTAL NUMBER</p>{' '}
                {appVerification && appVerification.loading ? (
                  <Spinner animation='border' />
                ) : appVerification && appVerification.message ? (
                  appVerification.message.length
                ) : (
                  0
                )}
              </Col>
              <hr />
              <h6 className='pb-2'>VERIFICATION</h6>
            </Row>
          </Col>

          <Col md={3} className='my-1'>
            <Row
              className='pt-4 px-4 border border-secondary rounded mx-2 shadow-sm'
              style={{ background: '#FFF' }}
            >
              <Col sm={12} md={12} lg={6} xl={6} className='pb-3'>
                <FaCalendarAlt style={{ fontSize: '70px', color: '#f0ad4e' }} />
              </Col>
              <Col
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className='text-end fw-bold fs-3'
              >
                <p className='fs-6'>TOTAL NUMBER</p>
                {appInspection && appInspection.loading ? (
                  <Spinner animation='border' />
                ) : appInspection && appInspection.message ? (
                  appInspection.message.length
                ) : (
                  0
                )}
              </Col>
              <hr />
              <h6 className='pb-2'> Inspection</h6>
            </Row>
          </Col>

          <Col md={3} className='my-1'>
            <Row
              className='pt-4 px-4 border border-secondary rounded mx-2 shadow-sm'
              style={{ background: '#FFF' }}
            >
              <Col sm={12} md={12} lg={6} xl={6} className='pb-3'>
                <FaRegFileAlt style={{ fontSize: '70px', color: '#d9534f' }} />
              </Col>
              <Col
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className='text-end fw-bold fs-3'
              >
                <p className='fs-6'>TOTAL NUMBER</p>
                {appCompliance && appCompliance.loading ? (
                  <Spinner animation='border' />
                ) : appCompliance && appCompliance.message ? (
                  appCompliance.message.length
                ) : (
                  0
                )}
              </Col>
              <hr />
              <h6 className='pb-2'> COMPLIANCE</h6>
            </Row>
          </Col>

          <Col md={3} className='my-1'>
            <Row
              className='pt-4 px-4 border border-secondary rounded mx-2 shadow-sm'
              style={{ background: '#FFF' }}
            >
              <Col sm={12} md={12} lg={6} xl={6} className='pb-3'>
                <FaCheckCircle style={{ fontSize: '70px', color: '#4bbf73' }} />
              </Col>
              <Col
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className='text-end fw-bold fs-3'
              >
                <p className='fs-6'>TOTAL NUMBER</p>
                {appApproved && appApproved.loading ? (
                  <Spinner animation='border' />
                ) : appApproved && appApproved.message ? (
                  appApproved.message.length
                ) : (
                  0
                )}
              </Col>
              <hr />
              <h6 className='pb-2'>APPROVED </h6>
            </Row>
          </Col>
        </Row>
        {/*End Application Details */}

        {/* Downloadable Forms and News & Updates Table */}
        <Row className='align-items-center justify-content-around my-2'>
          <Col xl={9}>
            {/* Downloadable Forms Table Area */}
            {downloadableformsDetail ? (
              <DownloadableForms data={downloadableformsDetail} />
            ) : (
              <div className='text-center py-5'>
                <Spinner animation='border' />
                <h3>Loading...</h3>
              </div>
            )}
          </Col>

          <Col xl={3}>
            <Card>
              <Card.Body>
                <h5 className='my-3'>News & Updates</h5>
                <hr
                  className='mt-1 mb-3'
                  style={{
                    background: '#ffeb36',
                    paddingBottom: '2px',
                    width: '200px',
                  }}
                />
                {newsDetails && newsDetails.message && (
                  <Carousel prevLabel='' nextLabel=''>
                    {newsDetails.message.map((newsandupdate, index) => (
                      <Carousel.Item key={index} interval={5000}>
                        <Card className='mb-3'>
                          <Card.Img
                            variant='top'
                            src={`data:image/png;base64,${newsandupdate.url}`}
                            height='300'
                          />
                          <Card.Body>
                            <Card.Title>{newsandupdate.header}</Card.Title>
                            <Card.Text>{newsandupdate.subheader}</Card.Text>
                            <Row>
                              <Button onClick={() => handleEditModal(index)}>
                                View
                              </Button>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                )}

                {newsDetails && newsDetails.loading && (
                  <div className='text-center py-5'>
                    <Spinner animation='border' />
                    <h3>Loading...</h3>
                  </div>
                )}

                {newsDetails && newsDetails.error && (
                  <Row className='my-4 mx-1'>
                    <Col xl={12}>
                      <Card>
                        <Card.Body className='mt-2'>
                          <div className='text-center pt-3'>
                            <h3>No Latest News and Updates</h3>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* News and Update Article */}
      </Container>

      <Modal
        show={showNews}
        onHide={() => setShowNews(false)}
        centered
        backdrop='static'
        size='lg'
      >
        {newsID.id !== '' && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaInfoCircle /> News and Updates
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Title:</h5>
              <hr className='my-1' />
              <p className='mx-3'>{currentNews[newsID.id].header}</p>

              <h6>Sub-Title:</h6>
              <hr className='my-1' />
              <p className='mx-3'>{currentNews[newsID.id].subheader}</p>

              <h6>Details:</h6>
              <hr className='my-1' />
              <p className='mx-3'>{currentNews[newsID.id].body}</p>

              <h6>Attachment</h6>
              <hr className='my-1' />
              <Row>
                <img
                  src={`data:image/png;base64,${currentNews[newsID.id].url}`}
                />
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size='sm'
                variant='danger'
                onClick={() => {
                  setShowNews(false)
                  setNewsID({
                    id: '',
                  })
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </UserMain>
  )
}

UserDashboarScreen.defaultProps = {
  background: '#FFF',
  border: '1px solid #ced4da',
}

export default withRouter(UserDashboarScreen)
