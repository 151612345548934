import React, { useState, useEffect } from 'react'
import { Container, InputGroup, Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// Actions
import { verifyPermit } from '../redux/actions/userActions'

// Layout
import GuestMain from '../layout/GuestLayout/GuestMain'
import { FaCheck, FaSearch, FaTimes } from 'react-icons/fa'
import Loading from '../reusable/Loading'

const PermitVerificationScreen = () => {
  const dispatch = useDispatch()

  const [permit, setPermit] = useState('')

  const users = useSelector((state) => state.users)

  const { verifiedPermit } = users

  return (
    <GuestMain>
      <Container
        fluid
        style={{
          background: '#f9f6f1',
          color: '#00304b',
          backgroundImage: 'url(./assets/img/lp-bldgo-bg2.webp)',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        <Container
          className='bg-white shadow-sm px-4 pb-5'
          style={{
            height: '80vh',
            overflowY: 'auto',
          }}
        >
          {/* Header */}
          <h3 className='text-center fw-bold mb-0' style={{ color: '#00304b' }}>
            Permit Verification
          </h3>
          <div className='d-flex justify-content-center'>
            <hr
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
          </div>
          {/*End Header*/}

          {/* Search Box */}
          <Row>
            <Col className='my-2' xl='8'></Col>
            <Col className='my-2' xl='4'>
              <InputGroup>
                <Form.Control
                  type='text'
                  placeholder='search permit No. here ...'
                  onChange={(e) => setPermit(e.target.value)}
                />
                <Button
                  variant='info'
                  onClick={() => {
                    dispatch(verifyPermit({ permit: permit }))
                  }}
                >
                  <FaSearch />
                </Button>
              </InputGroup>
            </Col>
          </Row>
          {/*End Search Box */}

          <div>
            <p className='fs-6 mb-0 text-end'>
              * kindly input complete permit number to verify the permit. ex:{' '}
              <i className='text-danger'>BP 20231000</i>
            </p>

            <hr
              style={{
                background: '#000',
                paddingBottom: '2px',
              }}
            />
          </div>

          {verifiedPermit && verifiedPermit.message && (
            <div className='my-3'>
              <p className='fw-bold'>
                Permit:{' '}
                <i className='text-info'>
                  {verifiedPermit.message[0].Permit}{' '}
                  <FaCheck className='text-success' />
                </i>
              </p>
              <p className='fw-bold'>
                Owner:{' '}
                <i className='text-info'>
                  {verifiedPermit.message.map(
                    (el, index) =>
                      el.Owner +
                      (index !== verifiedPermit.message.length - 1 ? ', ' : '.')
                  )}{' '}
                  <FaCheck className='text-success' />
                </i>
              </p>
              <p className='fw-bold'>
                Released Date:{' '}
                <i className='text-info'>
                  {moment(verifiedPermit.message[0].timeStamp).format(
                    'MMMM D, YYYY'
                  )}{' '}
                  <FaCheck className='text-success' />
                </i>
              </p>
              <p className='fw-bold'>
                Status:{' '}
                <i className='text-info'>
                  Verified <FaCheck className='text-success' />
                </i>
              </p>
            </div>
          )}

          {verifiedPermit && verifiedPermit.error && (
            <div className='my-3'>
              <h6 className='fw-bold text-center'>
                Not found <FaTimes className='fs-1 text-danger' />
              </h6>
            </div>
          )}

          {verifiedPermit && verifiedPermit.loading && (
            <div className='my-3'>
              <Loading />
            </div>
          )}
        </Container>
      </Container>
    </GuestMain>
  )
}

export default PermitVerificationScreen
