import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

const Error = ({ message }) => {
  return (
    <div>
      <Row className='my-4 mx-1'>
        <Col xl={12}>
          <Card>
            <Card.Body className='mt-2'>
              <div className='text-center pt-3'>
                <h3>{message}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Error
