import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import moment from 'moment'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  body: {
    paddingVertical: 40,
    paddingHorizontal: 40,
  },
})

const PrintCFEI = ({ info }) => {
  // console.log(info)
  return (
    <Document>
      <Page size='Legal' orientation='portrait' style={styles.body} wrap>
        <View>
          <Text style={{ fontSize: '6px' }}>DPWH FORM NO. 96 006 E</Text>
        </View>
        <View style={{ textAlign: 'center', paddingTop: '10px' }}>
          <Text style={{ fontSize: '7px', marginVertical: '2px' }}>
            REPUBLIC OF THE PHILIPPINES
          </Text>
          <Text style={{ fontSize: '11px', fontFamily: 'Times-Bold' }}>
            DEPARTMENT OF PUBLIC WORKS AND HIGHWAYS
          </Text>
          <Text style={{ fontSize: '7px', marginVertical: '2px' }}>
            OFFICE OF THE LOCAL BUILDING OFFICIAL
          </Text>
        </View>

        <View style={{ textAlign: 'center', paddingTop: '15px' }}>
          <Text style={{ fontSize: '11px', fontFamily: 'Times-Bold' }}>
            CITY OF LAS PIÑAS
          </Text>
          <Text style={{ fontSize: '7px', marginVertical: '2px' }}>
            AREA CODE __________
          </Text>
        </View>

        <View style={{ textAlign: 'center', paddingTop: '10px' }}>
          <Text style={{ fontSize: '11px', fontFamily: 'Times-Bold' }}>
            CERTIFICATE OF FINAL ELECTRICAL INSPECTION / COMPLETION
          </Text>
        </View>
        <Text
          style={{ fontSize: '7px', paddingTop: '5', textAlign: 'justify' }}
        >
          THIS IS TO CERTIFY THAT FINAL INSPECTION OF THE ELECTRICAL
          INSTALLATION HAD BEEN CONDUCTED ON THE BUILDING AND/OR PREMISES
          COVERED BY BUILDING PERMIT NO.{' '}
          {info !== undefined
            ? info[0] !== undefined && info[0].categCode + ' ' + info[0].id
            : '_______________'}{' '}
          ISSUED ON{' '}
          {info !== undefined
            ? info[0] !== undefined && info[0].timeStamp.slice(0, 10)
            : '_______________'}{' '}
          AND THE SAME WERE FOUND COMPLETED IN ACCORDANCE WITH THE APPROVED AND
          SPECIFICATIONS ON FILE WITH THE OFFICE OF THE BUILDING OFFICIAL AND IN
          ACCORDANCE WITH PHILIPPINE ELECTRICAL CODE PROVISIONS.
        </Text>

        <View
          style={{
            flexDirection: 'row',
            marginTop: '5px',
            paddingTop: '5px',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <Text style={{ fontSize: '7px', width: '30%', marginLeft: '2px' }}>
            NAME OF THE OWNER/APPLICANT
          </Text>
          <View style={{ width: '20%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px' }}>LAST NAME</Text>
            <Text style={{ fontSize: '8px', fontFamily: 'Times-Bold' }}>
              {info !== undefined && info[0] !== undefined && info[0].lastName}
            </Text>
          </View>
          <View style={{ width: '20%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px' }}>FIRST NAME</Text>
            <Text style={{ fontSize: '8px', fontFamily: 'Times-Bold' }}>
              {info !== undefined && info[0] !== undefined && info[0].firstName}
            </Text>
          </View>
          <View style={{ width: '20%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px' }}>MIDDLE NAME</Text>
            <Text style={{ fontSize: '8px', fontFamily: 'Times-Bold' }}>
              {info !== undefined &&
                info[0] !== undefined &&
                info[0].middleName}
            </Text>
          </View>
          <View style={{ width: '10%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px', borderLeft: '1px solid black' }}>
              T.I.N
            </Text>
            <Text
              style={{ fontSize: '7px', borderLeft: '1px solid black' }}
            ></Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            paddingTop: '5px',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <View style={{ width: '30%' }}>
            <Text style={{ fontSize: '7px', paddingLeft: '2px' }}>
              ADDRESS NO.,
            </Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px' }}></Text>
          </View>
          <View style={{ width: '20%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px' }}>STREET</Text>
            <Text style={{ fontSize: '7px', fontFamily: 'Times-Bold' }}>
              {info !== undefined &&
                info[0] !== undefined &&
                info[0].Blk +
                  ' ' +
                  info[0].Lot +
                  ' ' +
                  info[0].Street +
                  ' ' +
                  info[0].Area}
            </Text>
          </View>
          <View style={{ width: '20%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px' }}>BARANGAY</Text>
            <Text style={{ fontSize: '7px', fontFamily: 'Times-Bold' }}>
              {info !== undefined && info[0] !== undefined && info[0].Barangay}
            </Text>
          </View>
          <View style={{ width: '20%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px' }}>CITY/MUNICIPALITY</Text>
            <Text style={{ fontSize: '7px', fontFamily: 'Times-Bold' }}></Text>
          </View>
          <View style={{ width: '10%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px', borderLeft: '1px solid black' }}>
              TEL./FAX NO.
            </Text>
            <Text
              style={{ fontSize: '7px', borderLeft: '1px solid black' }}
            ></Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            paddingTop: '5px',
            paddingBottom: '5px',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <View style={{ width: '20%' }}>
            <Text style={{ fontSize: '7px', paddingLeft: '2px' }}>
              LOCATION OF INSTALLATION:
            </Text>
          </View>
          <View style={{ width: '20%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px' }}>NO.,</Text>
            <Text style={{ fontSize: '7px' }}></Text>
          </View>
          <View style={{ width: '20%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px' }}>STREET,</Text>
            <Text style={{ fontSize: '7px' }}></Text>
          </View>
          <View style={{ width: '20%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px' }}>BARANGAY</Text>
            <Text style={{ fontSize: '7px' }}></Text>
          </View>
          <View style={{ width: '20%', textAlign: 'center' }}>
            <Text style={{ fontSize: '7px' }}>CITY/MUNICIPALITY</Text>
            <Text style={{ fontSize: '7px' }}></Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            paddingTop: '2px',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <View style={styles.row}>
            <Text style={{ fontSize: '7px', paddingLeft: '2px' }}>
              TYPE OF OCCUPANCY OR USE:
            </Text>
            <Text
              style={{
                fontSize: '7px',
                paddingLeft: '2px',
                fontFamily: 'Times-Bold',
              }}
            >
              {info !== undefined && info[0] !== undefined && info[0].useType}
            </Text>
          </View>
        </View>

        <View
          style={{
            paddingTop: '2px',
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              marginTop: '3px',
              marginLeft: '10px',
            }}
          >
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              A. RESIDENTIAL DWELLING
            </Text>
            <Text
              style={{
                width: '1.5%',
                border: '1px solid black',
                fontSize: '6px',
                fontFamily: 'Times-Bold',
              }}
            ></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              E. BUSINESS & MERCANTILE
            </Text>
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              I. ASSEMBLY OCCUPANT LOAD 1000 OR MORE
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              marginTop: '3px',
              marginLeft: '10px',
            }}
          >
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              B. RESIDENTIAL, HOTEL, APARTMENT
            </Text>
            <Text
              style={{
                width: '1.5%',
                border: '1px solid black',
                fontSize: '6px',
                fontFamily: 'Times-Bold',
              }}
            ></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              F. INDUSTRIAL
            </Text>
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              J. ACCESSORY
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              marginTop: '3px',
              marginLeft: '10px',
            }}
          >
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              C. EDUCATION & RECREATION
            </Text>
            <Text
              style={{
                width: '1.5%',
                border: '1px solid black',
                fontSize: '6px',
                fontFamily: 'Times-Bold',
                color: 'red',
              }}
            ></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              F. STORAGE & HAZARDOUS
            </Text>
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              K. OTHERS (SPECIFY)
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              marginTop: '3px',
              marginLeft: '10px',
            }}
          >
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              D. INSTITUTIONAL
            </Text>
            <Text
              style={{
                width: '1.5%',
                border: '1px solid black',
                fontSize: '6px',
                fontFamily: 'Times-Bold',
                color: 'red',
              }}
            ></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              H. ASSEMBLY OTHER THAN GROUP I
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            paddingTop: '2px',
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <Text
            style={{
              fontSize: '7px',
              paddingLeft: '2px',
              width: '50%',
              marginVertical: '2px',
            }}
          >
            START OF INSTALLATION:
          </Text>
          <Text
            style={{
              fontSize: '7px',
              paddingLeft: '2px',
              width: '50%',
              marginVertical: '2px',
            }}
          >
            DATE OF COMPLETION:
          </Text>
        </View>

        <View
          style={{
            textAlign: 'center',
            paddingVertical: '5px',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            marginTop: '10px',
          }}
        >
          <Text style={{ fontSize: '7px' }}>OUTLET / DEVICES / EQUIPMENT</Text>
          <Text
            style={{
              fontSize: '7px',
              fontFamily: 'Times-Bold',
              marginTop: '5px',
            }}
          >{`Load (KVA)  ${
            info !== undefined
              ? info[0] !== undefined &&
                info.find((e) => e.name === 'A_KVA') !== undefined
                ? parseFloat(info.find((e) => e.name === 'A_KVA').kva).toFixed(
                    2
                  )
                : '0.00'
              : 0.0
          } / ${
            info !== undefined
              ? info[0] !== undefined &&
                info.find((e) => e.name === 'B_KVA') !== undefined
                ? parseFloat(info.find((e) => e.name === 'B_KVA').kva).toFixed(
                    2
                  )
                : '0.00'
              : 0.0
          } / ${
            info !== undefined
              ? info[0] !== undefined &&
                info.find((e) => e.name === 'A_KVA') !== undefined
                ? parseFloat(info.find((e) => e.name === 'A_KVA').kva).toFixed(
                    2
                  )
                : '0.00'
              : 0.0
          }`}</Text>
        </View>

        <View
          style={{
            borderTop: '1px solid black',
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                fontSize: '7px',
                marginLeft: '10px',
                width: '50%',
                borderRight: '1px solid black',
              }}
            >
              <Text>NUMBER OF OUTLETS:</Text>
              <View
                style={{
                  flexDirection: 'row',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
              >
                <View style={{ width: '50%' }}>
                  <Text>_____ LIGHT</Text>
                  <Text>_____ CONVENIENCE/RECEPTACLE</Text>
                  <Text>_____ SPO, AIRCON</Text>
                </View>
                <View style={{ width: '50%' }}>
                  <Text>_____ SPO, COOKING UNIT</Text>
                  <Text>_____ SPO, WATER HEATER</Text>
                  <Text>_____ SPO, WATER PUMP</Text>
                </View>
              </View>
            </View>

            <View style={{ fontSize: '7px', marginLeft: '10px', width: '50%' }}>
              <Text>NUMBER OF EQUIPMENT/WIRING DEVICES:</Text>
              <View
                style={{
                  flexDirection: 'row',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
              >
                <View style={{ width: '50%' }}>
                  <Text>_____ TOGGLE SWITCH</Text>
                  <Text>_____ BELL/BUZZERS</Text>
                  <Text>_____ PUSH BUTTONS</Text>
                </View>
                <View style={{ width: '50%' }}>
                  <Text>_____ FA DETECTORS</Text>
                  <Text>_____ OTHERS (SEE ATTACHED LIST)</Text>
                  <Text>_____ </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            textAlign: 'center',
            paddingVertical: '5px',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            marginTop: '10px',
          }}
        >
          <Text style={{ fontSize: '7px' }}>
            PERSON IN-CHARGE OF INSTALLATION
          </Text>
        </View>

        <View
          style={{
            flexDirection: 'row',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: '33%',
              paddingLeft: '10px',
            }}
          >
            <Text
              style={{
                width: '5%',
                border: '1px solid black',
                marginVertical: '5px',
              }}
            ></Text>
            <Text
              style={{
                fontSize: '7px',
                borderRight: '1px solid black',
                width: '100%',
                paddingVertical: '5px',

                textAlign: 'center',
              }}
            >
              PROFESSIONAL ELECTRICAL ENGINEER
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '33%',
              paddingLeft: '10px',
            }}
          >
            <Text
              style={{
                width: '5%',
                border: '1px solid black',
                marginVertical: '5px',
              }}
            ></Text>
            <Text
              style={{
                fontSize: '7px',
                borderRight: '1px solid black',
                width: '100%',
                paddingVertical: '5px',
                paddingLeft: '2px',
              }}
            >
              REGISTERED ELECTRICAL ENGINEER
            </Text>
          </View>

          <View
            style={{
              width: '33%',
              paddingLeft: '10px',
              paddingVertical: '2px',
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  width: '5%',
                  border: '1px solid black',
                }}
              ></Text>
              <Text style={{ fontSize: '7px', paddingLeft: '2px' }}>
                REGISTERED MASTER ELECTRICIAN
              </Text>
            </View>
            <Text style={{ fontSize: '7px' }}>
              (Not Exceeding 600 Volts & 500 KVA)
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <View
            style={{
              width: '66%',
            }}
          >
            <Text
              style={{
                fontSize: '7px',
                borderRight: '1px solid black',
                paddingTop: '5px',
                paddingBottom: '3px',
                borderBottom: '1px solid black',
                paddingLeft: '10px',
              }}
            >
              NAME
            </Text>
            <Text
              style={{
                fontSize: '7px',
                borderRight: '1px solid black',
                paddingVertical: '5px',
                paddingLeft: '10px',
              }}
            >
              SIGNATURE
            </Text>
          </View>

          <View
            style={{
              width: '33%',
              paddingLeft: '10px',
            }}
          >
            <Text
              style={{
                fontSize: '7px',
                paddingTop: '5px',
                paddingBottom: '3px',
              }}
            >
              PRC REG. NO.
            </Text>
            <Text
              style={{
                fontSize: '7px',
                paddingBottom: '5px',
              }}
            >
              VALIDITY
            </Text>
          </View>
        </View>

        <Text
          style={{
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            fontSize: '7px',
            paddingVertical: '5px',
            paddingLeft: '10px',
          }}
        >
          ADDRESS
        </Text>

        <View
          style={{
            fontSize: '7px',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            paddingBottom: '3px',
          }}
        >
          <View style={styles.row}>
            <Text
              style={{
                width: '33%',
                fontSize: '7px',
                paddingLeft: '10px',
                paddingVertical: '5px',
                borderRight: '1px solid black',
              }}
            >
              PTR NO.
            </Text>
            <Text
              style={{
                width: '33%',
                fontSize: '7px',
                paddingLeft: '10px',
                paddingVertical: '5px',
                borderRight: '1px solid black',
              }}
            >
              DATE ISSUED
            </Text>
            <Text
              style={{
                width: '33%',
                fontSize: '7px',
                paddingVertical: '5px',
                paddingLeft: '10px',
              }}
            >
              PLACE ISSUED
            </Text>
          </View>
        </View>

        <View
          style={{
            fontSize: '7px',
            borderTop: '1px solid black',
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            paddingBottom: '3px',
          }}
        >
          <View style={styles.row}>
            <Text
              style={{
                width: '33%',
                fontSize: '7px',
                paddingLeft: '10px',
                paddingVertical: '5px',
                borderRight: '1px solid black',
              }}
            >
              CTC NO.
            </Text>
            <Text
              style={{
                width: '33%',
                fontSize: '7px',
                paddingLeft: '10px',
                paddingVertical: '5px',
                borderRight: '1px solid black',
              }}
            >
              DATE ISSUED
            </Text>
            <Text
              style={{
                width: '33%',
                fontSize: '7px',
                paddingVertical: '5px',
                paddingLeft: '10px',
              }}
            >
              T.I.N.
            </Text>
          </View>
        </View>

        <View
          style={{
            textAlign: 'center',
            paddingVertical: '5px',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            marginTop: '10px',
          }}
        >
          <Text style={{ fontSize: '7px' }}>
            ELECTRICAL CONTRACTOR (200-AMPERE MAIN AND ABOVE)
          </Text>
        </View>

        <View
          style={{
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            flexDirection: 'row',
          }}
        >
          <Text
            style={{
              fontSize: '7px',
              width: '50%',
              borderRight: '1px solid black',
              paddingLeft: '10px',
            }}
          >
            NAME
          </Text>
          <View style={{ width: '50%' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{ fontSize: '7px', width: '50%', paddingLeft: '10px' }}
              >
                PCAB LIC. NO.
              </Text>
              <Text style={{ fontSize: '7px', width: '50%' }}>
                (SPECIALTY ELECTRICAL)
              </Text>
            </View>
            <Text style={{ fontSize: '7px', paddingLeft: '10px' }}>
              VALIDITY
            </Text>
          </View>
        </View>

        <View
          style={{
            borderTop: '1px solid black',
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            flexDirection: 'row',
          }}
        >
          <Text
            style={{
              fontSize: '7px',
              width: '70%',
              borderRight: '1px solid black',
              paddingLeft: '10px',
              paddingVertical: '5px',
            }}
          >
            ADDRESS
          </Text>
          <Text
            style={{
              fontSize: '7px',
              width: '30%',
              paddingLeft: '10px',
              paddingVertical: '5px',
            }}
          >
            TEL./FAX NO.
          </Text>
        </View>

        <View
          style={{
            paddingVertical: '5px',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            marginTop: '10px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: '7px', paddingLeft: '10px' }}>
              TYPE OF INSTALLATION:
            </Text>
            <Text
              style={{
                fontSize: '7px',
                paddingLeft: '10px',
                fontFamily: 'Times-Bold',
              }}
            >
              {info !== undefined &&
                info[0] !== undefined &&
                info[0].scopeOfWork}{' '}
              {info !== undefined &&
                info[0] !== undefined &&
                info[0].sw_specify !== null &&
                info[0].sw_specify}
              {/* HELLO */}
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              marginLeft: '10px',
              paddingTop: '5px',
            }}
          >
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              TEMPORARY
            </Text>
            <Text
              style={{
                width: '1.5%',
                border: '1px solid black',
                fontSize: '6px',
                fontFamily: 'Times-Bold',
              }}
            ></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              NEW
            </Text>
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '30%' }}>
              REMODEL/ALTERATION
            </Text>
          </View>
        </View>

        <View
          style={{
            paddingVertical: '5px',
            borderTop: '1px solid black',
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: '7px', paddingLeft: '10px' }}>
              TYPES OF WIRING:
            </Text>
            <Text style={{ fontSize: '7px', paddingLeft: '10px' }}></Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              marginLeft: '10px',
              paddingTop: '5px',
            }}
          >
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '20%' }}>
              OPEN WIRING
            </Text>
            <Text
              style={{
                width: '1.5%',
                border: '1px solid black',
                fontSize: '6px',
                fontFamily: 'Times-Bold',
              }}
            ></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '20%' }}>
              CONDUITS
            </Text>
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '20%' }}>
              CABLE
            </Text>
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '20%' }}>
              ARMORED CABLE
            </Text>
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px', width: '20%' }}>
              RACEWAYS
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              paddingLeft: '10px',
              paddingTop: '5px',
            }}
          >
            <Text style={{ width: '1.5%', border: '1px solid black' }}></Text>
            <Text style={{ fontSize: '7px', paddingLeft: '2px' }}>OTHERS</Text>
          </View>
        </View>

        <View style={styles.row}>
          <Text
            style={{
              width: '33%',
              fontSize: '7px',
              paddingTop: '10px',
              paddingLeft: '2px',
            }}
          >
            INSPECTED BY:
          </Text>
          <Text
            style={{
              width: '33%',
              fontSize: '7px',
              paddingTop: '10px',
              paddingLeft: '2px',
            }}
          >
            RECOMMENDING APPROVAL:
          </Text>
          <Text
            style={{
              width: '33%',
              fontSize: '7px',
              paddingTop: '10px',
              paddingLeft: '2px',
            }}
          >
            APPROVED BY:
          </Text>
        </View>

        <View
          style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}
        >
          <View
            style={{
              width: '30%',
              paddingTop: '30px',
              paddingLeft: '2px',
              position: 'absolute',
            }}
          >
            {info !== undefined && info[0] !== undefined && (
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <Image
                  src={`data:image/png;base64,${info[0].InspectorSign}`}
                  style={{ height: '50px', width: '40%' }}
                />
              </View>
            )}
            {/* <Text style={{ fontSize: '8px', fontFamily: 'Times-Bold' }}>
              {info !== undefined && info[0] !== undefined && info[0].Inspector}
            </Text> */}
            <Text
              style={{
                fontSize: '7px',
                fontFamily: 'Times-Bold',
                position: 'absolute',
                marginTop: '40px',
                marginLeft: '60px',
              }}
            >
              {info !== undefined &&
                info[0] !== undefined &&
                moment(info[0].InspectTime).format('MMMM Do YYYY, h:mm a')}
            </Text>
            {/* <Text style={{ fontSize: '7px' }}></Text> */}
          </View>
          <View
            style={{
              width: '30%',
              paddingTop: '30px',
              paddingLeft: '2px',
              marginLeft: '180px',
              position: 'absolute',
              // marginHorizontal: '3px',
            }}
          >
            {info !== undefined && info[0] !== undefined && (
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <Image
                  src={`data:image/png;base64,${info[0].EvaluatorSign}`}
                  style={{ height: '50px', width: '40%' }}
                />
              </View>
            )}
            <Text
              style={{
                fontSize: '7px',
                fontFamily: 'Times-Bold',
                position: 'absolute',
                marginTop: '40px',
                marginLeft: '80px',
              }}
            >
              {info !== undefined &&
                info[0] !== undefined &&
                moment(info[0].evaluationTime).format('MMMM Do YYYY, h:mm a')}
            </Text>
            {/* <Text style={{ fontSize: '8px', fontFamily: 'Times-Bold' }}>
              {info !== undefined && info[0] !== undefined && info[0].Evaluator}
            </Text> */}
            {/* <Text style={{ fontSize: '7px' }}></Text> */}
          </View>
          <View
            style={{
              width: '30%',
              paddingTop: '25px',
              paddingLeft: '2px',
              marginLeft: '350px',
              position: 'absolute',
              // marginHorizontal: '3px',
            }}
          >
            {info !== undefined && info[0] !== undefined && (
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <Image
                  src={`data:image/png;base64,${info[0].RecommenderSign}`}
                  style={{ height: '50px', width: '40%' }}
                />
              </View>
            )}
            <Text
              style={{
                fontSize: '7px',
                fontFamily: 'Times-Bold',
                position: 'absolute',
                marginTop: '45px',
                marginLeft: '70px',
              }}
            >
              {info !== undefined &&
                info[0] !== undefined &&
                moment(info[0].RecommendedTime).format('MMMM Do YYYY, h:mm a')}
            </Text>
            {/* <Text style={{ fontSize: '7px' }}></Text> InspectTime */}
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            textAlign: 'center',
            marginTop: '60px',
          }}
        >
          <View
            style={{
              width: '30%',
              paddingTop: '5px',
              paddingLeft: '2px',
              borderTop: '1px solid black',
              marginHorizontal: '3px',
            }}
          >
            <Text style={{ fontSize: '7px' }}>ELECTRICAL INSPECTOR</Text>
            <Text style={{ fontSize: '7px' }}>
              (Signature Over-Printed Name)
            </Text>
          </View>
          <View
            style={{
              width: '40%',
              paddingTop: '5px',
              paddingLeft: '2px',
              borderTop: '1px solid black',
              marginHorizontal: '3px',
            }}
          >
            <Text style={{ fontSize: '7px' }}>
              ELECTRICAL ENGINEER OF THE BUILDING OFFICE{' '}
            </Text>
            <Text style={{ fontSize: '7px' }}>
              (Signature Over-Printed Name)
            </Text>
          </View>
          <View
            style={{
              width: '30%',
              paddingTop: '5px',
              paddingLeft: '2px',
              borderTop: '1px solid black',
              marginHorizontal: '3px',
            }}
          >
            <Text style={{ fontSize: '7px' }}>BUILDING OFFICIAL</Text>
            <Text style={{ fontSize: '7px' }}>
              (Signature Over-Printed Name)
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            textAlign: 'center',
            paddingTop: '40px',
          }}
        >
          <View
            style={{
              width: '29%',
              paddingTop: '5px',
              paddingLeft: '2px',
              borderTop: '1px solid black',
              marginHorizontal: '3px',
            }}
          >
            <Text style={{ fontSize: '7px' }}>PRC REG. NO. & VALIDITY</Text>
          </View>
          <View
            style={{
              width: '39%',
              paddingTop: '5px',
              paddingLeft: '2px',
              borderTop: '1px solid black',
              marginHorizontal: '3px',
            }}
          >
            <Text style={{ fontSize: '7px' }}>PRC REG. NO. & VALIDITY</Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            textAlign: 'center',
            paddingTop: '10px',
          }}
        >
          <View
            style={{
              width: '30%',
              paddingTop: '5px',
              paddingLeft: '2px',
              marginHorizontal: '3px',
            }}
          >
            <Text style={{ fontSize: '7px' }}>
              AMOUNT PAID P _____________________
            </Text>
          </View>
          <View
            style={{
              width: '40%',
              paddingTop: '5px',
              paddingLeft: '2px',
              marginHorizontal: '3px',
            }}
          >
            <Text style={{ fontSize: '7px' }}>
              O.R. NO. _____________________
            </Text>
          </View>
          <View
            style={{
              width: '40%',
              paddingTop: '5px',
              paddingLeft: '2px',
              marginHorizontal: '3px',
            }}
          >
            <Text style={{ fontSize: '7px' }}>DATE _____________________</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default PrintCFEI
