export const CREATE_PLUMBING_ASSESSMENT_REQ = 'CREATE_PLUMBING_ASSESSMENT_REQ'
export const CREATE_PLUMBING_ASSESSMENT_SUCCESS =
  'CREATE_PLUMBING_ASSESSMENT_SUCCESS'
export const CREATE_PLUMBING_ASSESSMENT_FAILED =
  'CREATE_PLUMBING_ASSESSMENT_FAILED'

export const UPDATE_PLUMBING_ASSESSMENT_REQ = 'UPDATE_PLUMBING_ASSESSMENT_REQ'
export const UPDATE_PLUMBING_ASSESSMENT_SUCCESS =
  'UPDATE_PLUMBING_ASSESSMENT_SUCCESS'
export const UPDATE_PLUMBING_ASSESSMENT_FAILED =
  'UPDATE_PLUMBING_ASSESSMENT_FAILED'

export const GET_PLUMBING_ASSESSMENT_REQ = 'GET_PLUMBING_ASSESSMENT_REQ'
export const GET_PLUMBING_ASSESSMENT_SUCCESS = 'GET_PLUMBING_ASSESSMENT_SUCCESS'
export const GET_PLUMBING_ASSESSMENT_FAILED = 'GET_PLUMBING_ASSESSMENT_FAILED'
