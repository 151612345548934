import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  InputGroup,
  Button,
  Table,
  Pagination,
  Modal,
  Accordion,
} from 'react-bootstrap'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { withRouter } from 'react-router-dom'
import {
  FaSearch,
  FaEye,
  FaCheck,
  FaClipboardCheck,
  FaEllipsisH,
} from 'react-icons/fa'
import moment from 'moment'
import io from 'socket.io-client'

//Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'
import CommentsCard from '../../reusable/CommentsCard'
import RecommenderOrderPay from '../../reusable/RecommenderOrderPay'

// Actions
import {
  retrieveForApprovalList,
  updateApplicationStatus,
  retrieveCommentsList,
  retrieveForApprovalOp,
} from '../../redux/actions/Administrator/administratorActions'
import { retrieveDateApplied } from '../../redux/actions/adminApplicationActions'

import {
  retrieveReportList,
  retrieveInspectionDetails,
} from '../../redux/actions/Inspectors/Supervisors/isupervisorActions'

import { retrieveEvaluationList } from '../../redux/actions/Evaluators/Supervisors/supervisorActions'

import {
  retrieveReport,
  retrieveImageReport,
} from '../../redux/actions/inspectionActions'

import { retrieveEvaluationDetails as CivilEvaluation } from '../../redux/actions/evaluationCivilActions'
import { retrieveEvaluationDetails as ArchEvaluation } from '../../redux/actions/evaluatorArchActions'
import { retrieveEvaluationDetails as ElectronicsEvaluation } from '../../redux/actions/evaluatorActions'
import { retrieveEvaluationDetails as ElecEvaluation } from '../../redux/actions/evaluatorElectricalActions'
import { retrieveEvaluationDetails as MechEvaluation } from '../../redux/actions/evaluatorMechActions'
import { retrieveEvaluationDetails as PlumEvaluation } from '../../redux/actions/evaluatorPlumbingActions'
import { retrieveAssessmentDetails as CivilAssessment } from '../../redux/actions/Assessment/structuralAssessmentActions'
import { retrieveAssessmentDetails as ElecAssessment } from '../../redux/actions/Assessment/electricalAssessmentActions'
import { retrieveAssessmentDetails as ElectronicsAssessment } from '../../redux/actions/Assessment/electronicsAssessmentActions'
import { retrieveAssessmentDetails as MechAssessment } from '../../redux/actions/Assessment/mechanicalAssessmentActions'
import { retrieveAssessmentDetails as PlumAssessment } from '../../redux/actions/Assessment/plumbingAssessmentActions'
import { retrieveAssessmentDetails as ArchAssessment } from '../../redux/actions/Assessment/accessoriesFeeAssessmentActions'

// Data
import { createReport } from '../../../data/createReport'

const socket = io.connect(process.env.REACT_APP_URL)

const ForApprovalListScreen = () => {
  // Declaration
  const dispatch = useDispatch()

  const [state, setState] = useState([])

  const [payload, setPayload] = useState({})

  const [categoryCode, setCategoryCode] = useState([])

  const [searchTerm, setSearchTerm] = useState('')

  const [modal, setModal] = useState({
    viewManager: false,
    viewReport: false,
    viewReportList: false,
    viewAssessment: false,
    viewChangeStatus: false,
    iSuccessModal: false,
    eSuccessModal: false,
    viewEvaluationList: false,
    viewEvaluation: false,
    recommendSuccess: false,
    approvedSuccess: false,
    viewOrderofPayment: false,
  })

  const [commentDetails, setCommentDetails] = useState({
    applicationStatus: '',
    comments: '',
    commentTo: '',
    commentToName: '',
    createdBy: '',
    appID: '',
  })

  const [penaltyInfo, setPenaltyInfo] = useState('')

  const [penaltyValue, setPenaltyValue] = useState(0)

  const [opSubtotal, setOpSubtotal] = useState(0)

  // const [generatedOP, setGeneratedOP] = useState({
  //   subtotal: 0,
  //   subtotal1: 0,
  //   totalAmount: 0,
  //   withPenalty: 0,
  // })

  const [cancelBtn, setCancelBtn] = useState(false)

  const administratorReducer = useSelector(
    (state) => state.administratorReducer
  )

  const [activeTrade, setActiveTrade] = useState('')

  const users = useSelector((state) => state.users)

  const { userCredentials } = users

  const {
    forApprovalList,
    updateAppStatus,
    commentsList,
    retrievedForApprovalOp,
  } = administratorReducer

  const adminApplication = useSelector((state) => state.adminApplication)

  const { dateApplied } = adminApplication

  const isupervisor = useSelector((state) => state.isupervisor)

  const { listReport, penaltyDetail, retrievedInspectionDetails } = isupervisor

  const esupervisor = useSelector((state) => state.esupervisor)

  const { evaluationList } = esupervisor

  const [evalDetails, setEvalDetails] = useState([])

  const [assessDetails, setAssessDetails] = useState([])

  const inspection = useSelector((state) => state.inspection)

  const { existingReport, existingImageReport } = inspection

  // Evaluators

  const evaluator = useSelector((state) => state.evaluator)

  const { evaluationDetails } = evaluator

  const civilEvaluator = useSelector((state) => state.civilEvaluator)

  const { evaluationCivilDetails } = civilEvaluator

  const archEvaluator = useSelector((state) => state.archEvaluator)

  const { evaluationArchDetails } = archEvaluator

  const electricalEvaluator = useSelector((state) => state.electricalEvaluator)

  const { evaluationElectricalDetails } = electricalEvaluator

  const mechanicalEvaluator = useSelector((state) => state.mechanicalEvaluator)

  const { evaluationMechDetails } = mechanicalEvaluator

  const plumbingEvaluator = useSelector((state) => state.plumbingEvaluator)

  const { evaluationPlumbingDetails } = plumbingEvaluator

  // End Evaluators

  // Assessments

  const structuralAssessment = useSelector(
    (state) => state.structuralAssessment
  )

  const CivilAssessDetails = structuralAssessment.assessmentDetails

  const electronicsAssessment = useSelector(
    (state) => state.electronicsAssessment
  )

  const ElectronicsAssesDetails = electronicsAssessment.assessmentDetails

  const electricalAssessment = useSelector(
    (state) => state.electricalAssessment
  )

  const ElectricalAssessDetails = electricalAssessment.assessmentDetails

  const mechanicalAssessment = useSelector(
    (state) => state.mechanicalAssessment
  )

  const MechAssessDetails = mechanicalAssessment.assessmentDetails

  const plumbingAssessment = useSelector((state) => state.plumbingAssessment)

  const PlumAssessDetails = plumbingAssessment.assessmentDetails

  const accessoriesFee = useSelector((state) => state.accessoriesFeeAssessment)

  const { assessmentDetails } = accessoriesFee
  // End Assessments

  // End Declaration

  // Pagination Area Forms

  const [paginatedForms, setPaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  const pageSize = 5

  useEffect(() => {
    setPaginatedForms(_(_.reverse(state)).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [state])

  const pageCount = state ? Math.ceil(state.length / pageSize) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    const paginationForms = _(state).slice(startIndex).take(pageSize).value()
    setPaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }

  // End Pagination Area Form

  // Functions

  const handleSubmitComments = () => {
    if (commentDetails.applicationStatus === 'FOR APPROVAL') {
      // console.log('Submit New Status')
    }
    dispatch(
      updateApplicationStatus({
        id: payload.statusID,
        status: commentDetails.applicationStatus,
        remarks: payload.remarks,
        withEvaluation: payload.withEvaluation,
        withInspection: payload.withInspection,
        application_ID: payload.id,
        userID: payload.userID,
        adminID: userCredentials.id,
        comments: commentDetails.comments,
        commentTo: commentDetails.commentTo,
        createdBy: userCredentials.id,
        categories: categoryCode,
        email: payload.EmailAddress,
        mobileNo: payload.MobileNo,
      })
    )
    setModal({
      viewChangeStatus: false,
      approvedSuccess: true,
    })
    socket.emit('send_change_status', {
      message: {
        appID: payload.id,
        notif: 'Update Application Status',
      },
    })
  }

  const handleView = (x) => {
    dispatch(retrieveDateApplied({ id: x.id }))
    dispatch(retrieveCommentsList({ id: x.id }))
    dispatch(retrieveForApprovalOp({ id: x.id }))
    // dispatch(retrievePenalty({ id: x.id }))

    setCommentDetails({
      ...commentDetails,
      applicationStatus: x.status,
      appID: x.id,
    })
    setModal({
      viewManager: true,
    })
    setPayload(x)

    let tradeCode = []

    x.Mechanical === 1 && tradeCode.push(['MP', 'MECHANICAL PERMIT'])
    x.Electrical === 1 && tradeCode.push(['EP', 'ELECTRICAL PERMIT'])
    x.Electronics === 1 && tradeCode.push(['LP', 'ELECTRONICS PERMIT'])
    x.Plumbing === 1 && tradeCode.push(['SP', 'PLUMBING PERMIT'])

    switch (x.ApplicationName) {
      case 'BUILDING CONSTRUCTION PERMIT':
        return setCategoryCode([
          ['BP', 'BUILDING CONSTRUCTION PERMIT'],
          ...tradeCode,
        ])
      case 'OCCUPANCY PERMIT':
        return setCategoryCode([['CO', 'CERTIFICATE IN USE/OCCUPANCY']])
      case 'FENCING PERMIT':
        return setCategoryCode([['FP', 'FENCING PERMIT']])
      case 'DEMOLITION PERMIT':
        return setCategoryCode([['DP', 'DEMOLITION PERMIT']])
      case 'ELECTRICAL PERMIT':
        return setCategoryCode([['CFEI', 'ELECTRICAL PERMIT - CFEI']])
      default:
        return setCategoryCode([])
    }
  }

  const handleViewEvaluation = (id, evaluatorID, trade) => {
    switch (trade) {
      case 'Electronics':
        dispatch(ElectronicsEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(ElectronicsAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Structural':
        dispatch(CivilEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(CivilAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Architectural':
        dispatch(ArchEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(ArchAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Mechanical':
        dispatch(MechEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(MechAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Electrical':
        dispatch(ElecEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(ElecAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Plumbing':
        dispatch(PlumEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(PlumAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      default:
        // console.log('NO Evaluation')
        break
    }
  }

  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(retrieveForApprovalList())
    socket.on('received_new_application', (data) => {
      // if (window.confirm(`${data.message} do you want to reload the page ?`)) {
      dispatch(retrieveForApprovalList())
      // }
    })
  }, [dispatch, socket])

  useEffect(() => {
    if (forApprovalList && forApprovalList.message) {
      setState(() =>
        forApprovalList.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.id === parseInt(searchTerm)
            )
          }
        })
      )
    }
    if (activeTrade !== '') {
      if (cancelBtn) {
        switch (activeTrade) {
          case 'Structural':
            if (evaluationCivilDetails && evaluationCivilDetails.message) {
              setEvalDetails(() => evaluationCivilDetails.message)
            }
            if (CivilAssessDetails && CivilAssessDetails.message) {
              setAssessDetails(() => CivilAssessDetails.message)
            }
            break
          case 'Electronics':
            if (evaluationDetails && evaluationDetails.message) {
              setEvalDetails(() => evaluationDetails.message)
            }
            if (ElectronicsAssesDetails && ElectronicsAssesDetails.message) {
              setAssessDetails(() => ElectronicsAssesDetails.message)
            }
            break
          case 'Architectural':
            if (evaluationArchDetails && evaluationArchDetails.message) {
              setEvalDetails(() => evaluationArchDetails.message)
            }
            if (assessmentDetails && assessmentDetails.message) {
              setAssessDetails(assessmentDetails.message)
            }
            break
          case 'Mechanical':
            if (evaluationMechDetails && evaluationMechDetails.message) {
              setEvalDetails(() => evaluationMechDetails.message)
            }
            if (MechAssessDetails && MechAssessDetails.message) {
              setAssessDetails(() => MechAssessDetails.message)
            }
            break
          case 'Electrical':
            if (
              evaluationElectricalDetails &&
              evaluationElectricalDetails.message
            ) {
              setEvalDetails(() => evaluationElectricalDetails.message)
            }
            if (ElectricalAssessDetails && ElectricalAssessDetails.message) {
              setAssessDetails(() => ElectricalAssessDetails.message)
            }
            break
          case 'Plumbing':
            if (
              evaluationPlumbingDetails &&
              evaluationPlumbingDetails.message
            ) {
              setEvalDetails(() => evaluationPlumbingDetails.message)
            }
            if (PlumAssessDetails && PlumAssessDetails.message) {
              setAssessDetails(() => PlumAssessDetails.message)
            }
            break
          default:
            // console.log('Empty')
            break
        }
      }
    }
    if (retrievedForApprovalOp && retrievedForApprovalOp.message) {
      setOpSubtotal(
        retrievedForApprovalOp.message[0].LineAndGrade +
          retrievedForApprovalOp.message[0].StructuralFees +
          retrievedForApprovalOp.message[0].ArchitecturalFees +
          retrievedForApprovalOp.message[0].AdditionalRenovation +
          retrievedForApprovalOp.message[0].RoofDeck +
          retrievedForApprovalOp.message[0].DemolitionPermitFees +
          retrievedForApprovalOp.message[0].AncillaryStructure +
          retrievedForApprovalOp.message[0].TombsCanopies +
          retrievedForApprovalOp.message[0].PlumbingPermitFees +
          retrievedForApprovalOp.message[0].ElectricalPermitFees +
          retrievedForApprovalOp.message[0].MechanicalPermitFees +
          retrievedForApprovalOp.message[0].FencingPermitFees +
          retrievedForApprovalOp.message[0].Sidewalks +
          retrievedForApprovalOp.message[0].PavedAreas +
          retrievedForApprovalOp.message[0].StreetSidewalk +
          retrievedForApprovalOp.message[0].CertificateOccupancy +
          retrievedForApprovalOp.message[0].ChangeOccupancy +
          retrievedForApprovalOp.message[0].CertificateOccupancy10 +
          retrievedForApprovalOp.message[0].ElectronicsPermit +
          retrievedForApprovalOp.message[0].AnnualInspection +
          retrievedForApprovalOp.message[0].SignPermitFees +
          retrievedForApprovalOp.message[0].Certification +
          retrievedForApprovalOp.message[0].AdministrativeFive
      )
    }
    if (updateAppStatus && updateAppStatus.message) {
      socket.emit('send_new_application', {
        message: 'Update Application Status',
      })
    }
  }, [
    forApprovalList,
    searchTerm,
    CivilAssessDetails,
    evaluationDetails,
    ElectronicsAssesDetails,
    evaluationArchDetails,
    assessmentDetails,
    evaluationMechDetails,
    MechAssessDetails,
    evaluationElectricalDetails,
    ElectricalAssessDetails,
    evaluationPlumbingDetails,
    PlumAssessDetails,
    retrievedForApprovalOp,
    updateAppStatus,
  ])
  // End UseEffect

  console.log(
    retrievedForApprovalOp &&
      retrievedForApprovalOp.message &&
      retrievedForApprovalOp.message[0]
  )
  console.log(retrievedForApprovalOp)

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {forApprovalList && forApprovalList.message && (
            <>
              <Form>
                <Row className='justify-content-end px-3 pt-5'>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              <Row className='my-4'>
                <Col md={12} style={{ background: '#FFF' }}>
                  <h5 className='pt-3 pb-2 mb-0'>For Approval List</h5>
                  <hr
                    className='mt-1 mb-3'
                    style={{
                      background: '#ffeb36',
                      paddingBottom: '2px',
                      width: '200px',
                    }}
                  />
                  <Table striped bordered hover responsive size='sm'>
                    <thead className='bg-info text-light'>
                      <tr>
                        <th className='text-nowrap'>Application No.</th>
                        <th>Application</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Date Transferred</th>
                        <th>Applicant Name</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedForms.map((x, index) => (
                        <tr key={index}>
                          <td className='text-center'>{x.id}</td>
                          <td>{x.ApplicationName}</td>
                          <td>{x.status}</td>
                          <td>{x.remarks}</td>
                          <td>
                            {moment(x.TimeStamp).format('MMMM D, YYYY h:mma')}
                          </td>
                          <td className='text-nowrap'>{x.FullName}</td>
                          <td className='text-center'>
                            <Button
                              variant='outline-success'
                              onClick={() => handleView(x)}
                              size='sm'
                            >
                              <FaEllipsisH />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    size='sm'
                    className='d-flex justify-content-end mx-2'
                  >
                    <Pagination.Item
                      className=' mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {renderPageNumber}

                    <Pagination.Item
                      className=' mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </>
          )}

          {forApprovalList && forApprovalList.loading && (
            <Row className='py-4'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {forApprovalList && forApprovalList.error && (
            <Row className='py-4'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No application for approval</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      {/* Application Details Modal */}
      <Modal
        size='lg'
        show={modal.viewManager}
        onHide={() => setModal({ viewManager: false })}
        centered
        backdrop='static'
      >
        {dateApplied && dateApplied.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Application Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <tbody>
                  <tr>
                    <td className='fw-bold'>Application No.:</td>
                    <td>{Object.keys(payload).length > 0 && payload.id}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Application Name:</td>
                    <td>
                      {Object.keys(payload).length > 0 &&
                        payload.ApplicationName}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Mode of Application:</td>
                    <td>
                      {Object.keys(payload).length > 0 && payload.ModeofForms}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Application Status:</td>
                    <td>{Object.keys(payload).length > 0 && payload.status}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Date Applied:</td>
                    <td>
                      {Object.keys(payload).length > 0 &&
                        moment(dateApplied.message[0].DateApplied).format(
                          'MMMM D, YYYY h:mma'
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Applicant Attached Link:</td>
                    <td>{Object.keys(payload).length > 0 && payload.Link}</td>
                  </tr>
                  {/* <tr>
                    <td className='fw-bold'>
                      Document Controller Attached Link / Remarks:
                    </td>
                    <td>
                      {Object.keys(payload).length > 0 && payload.remarks}
                    </td>
                  </tr> */}
                  <tr>
                    <td className='fw-bold text-nowrap'>
                      Application Status Manager:
                    </td>
                    <td>
                      <Button
                        variant='success'
                        onClick={() => {
                          setModal({
                            viewChangeStatus: true,
                            viewManager: false,
                          })
                          dispatch(retrieveCommentsList({ id: payload.id }))
                        }}
                        size='sm'
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-nowrap'>
                      Evaluation/s Viewer:
                    </td>
                    <td>
                      <Button
                        variant='success'
                        onClick={() => {
                          dispatch(retrieveEvaluationList({ id: payload.id }))
                          setModal({
                            viewEvaluationList: true,
                            viewManager: false,
                          })
                        }}
                        size='sm'
                      >
                        VIEW
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-nowrap'>
                      Inspection Report Viewer:
                    </td>
                    <td>
                      <Button
                        variant='success'
                        onClick={() => {
                          dispatch(retrieveReportList({ id: payload.id }))
                          setModal({ viewReportList: true, viewManager: false })
                          if (penaltyDetail && penaltyDetail.message) {
                            setPenaltyInfo(penaltyDetail.message[0].penalty)
                          }
                          // setRecommenderComments({
                          //   ...recommenderComments,
                          //   i_comments:
                          //     payload.InspectorComment === null
                          //       ? ''
                          //       : payload.InspectorComment,
                          // })
                        }}
                        size='sm'
                      >
                        VIEW
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-nowrap'>
                      Order of Payment Viewer:
                    </td>
                    <td>
                      <Button
                        variant='success'
                        onClick={() => {
                          dispatch(retrieveReportList({ id: payload.id }))

                          let tempPenaltyValue = 0

                          tempPenaltyValue =
                            opSubtotal *
                            retrievedForApprovalOp.message[0].Penalty

                          setPenaltyValue(tempPenaltyValue)

                          setModal({
                            viewOrderofPayment: true,
                            viewManager: false,
                          })

                          if (penaltyDetail && penaltyDetail.message) {
                            setPenaltyInfo(penaltyDetail.message[0].penalty)
                          }
                        }}
                        size='sm'
                      >
                        VIEW
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewManager: false,
                  })
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        )}

        {dateApplied && dateApplied.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}

        {dateApplied && dateApplied.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}
      </Modal>
      {/* End Application Details Modal */}

      {/* Change Status */}
      <Modal
        size='lg'
        show={modal.viewChangeStatus}
        onHide={() => setModal({ viewChangeStatus: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            Application Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className='mb-3'>
              {/* <Col xl='6'>
                <Form.Label>
                  <h6>Comment To (Name):</h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  style={{
                    background: '#f7f7f8',
                    border: '1px solid #ced4da',
                  }}
                  size='sm'
                  required
                  disabled
                  value={commentDetails.commentToName}
                />
              </Col> */}
              <Col xl='6'>
                <Form.Label>
                  <h6>Application Status:</h6>
                </Form.Label>
                <Form.Control
                  as='select'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  size='sm'
                  required
                  value={commentDetails.applicationStatus}
                  onChange={(e) => {
                    e.target.value === 'FOR INSPECTION AND EVALUATION'
                      ? setCommentDetails({
                          ...commentDetails,
                          applicationStatus: e.target.value,
                          commentTo: payload.empID,
                          commentToName: payload.EmployeeName,
                        })
                      : setCommentDetails({
                          ...commentDetails,
                          applicationStatus: e.target.value,
                          commentTo: userCredentials.id,
                          commentToName: '',
                        })
                  }}
                >
                  <option value=''>change status here...</option>
                  <option value='FOR INSPECTION AND EVALUATION'>
                    FOR INSPECTION AND EVALUATION
                  </option>
                  <option value='FOR APPROVAL'>FOR APPROVAL</option>
                  <option value='APPROVED APPLICATION'>
                    APPROVED APPLICATION
                  </option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col xl='10'>
                <Form.Control
                  as='textarea'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  size='sm'
                  name='r_remarks'
                  required
                  placeholder='Insert remarks here ...'
                  onChange={(e) => {
                    setCommentDetails({
                      ...commentDetails,
                      comments: e.target.value,
                    })
                  }}
                />
              </Col>
              <Col className='d-flex align-items-end my-2' xl='2'>
                <Button
                  onClick={() => handleSubmitComments()}
                  variant='success'
                  size='sm'
                  disabled={
                    commentDetails.applicationStatus === 'FOR APPROVAL' ||
                    commentDetails.applicationStatus === ''
                      ? true
                      : false
                  }
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
          <hr />
          <CommentsCard
            state={commentsList && commentsList.message && commentsList.message}
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button
            variant='danger'
            onClick={() => {
              setModal({
                viewManager: true,
                viewChangeStatus: false,
              })
            }}
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Change Status */}

      {/* List of Inspection Report Modal */}
      <Modal
        size='xl'
        show={modal.viewReportList}
        onHide={() => setModal({ viewReportList: false })}
        centered
        backdrop='static'
      >
        {listReport && listReport.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Inspection List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Date Scheduled</th>
                    <th>Inspection Date</th>
                    <th>Inspector</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {listReport.message.map((list) => (
                    <tr key={list.id}>
                      <td>{list.category}</td>
                      <td>
                        {moment(list.inspectionSched).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>
                        {moment(list.inspectionDate).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>{list.EmployeeName}</td>
                      <td className='text-center'>
                        <Button
                          variant='success'
                          disabled={list.inspectionDate !== null ? false : true}
                          onClick={() => {
                            setCommentDetails({
                              comments: list.comments,
                              appID: list.appID,
                            })
                            setModal({
                              viewReport: true,
                              viewReportList: false,
                            })
                            dispatch(
                              retrieveInspectionDetails({
                                inspectionID: list.inspectionID,
                                userID: list.inspectID,
                                category: list.category,
                              })
                            )
                            dispatch(
                              retrieveImageReport({
                                inspectionID: list.inspectionID,
                                category: list.category,
                                userID: list.inspectID,
                              })
                            )
                          }}
                          size='sm'
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewReportList: false,
                    viewManager: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {listReport && listReport.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReportList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {listReport && listReport.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReportList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End List of Inspection Report Modal */}

      {/* Inspection Report */}
      <Modal
        size='xl'
        show={modal.viewReport}
        onHide={() => setModal({ viewReport: false })}
        centered
        backdrop='static'
      >
        {retrievedInspectionDetails && retrievedInspectionDetails.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Inspection Report
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Category</h5>
              <p className='mx-2'>
                <i>{retrievedInspectionDetails.message[0].category}</i>
              </p>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[0].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[0].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[1].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[1].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[2].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[2].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[3].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[3].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[4].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[4].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el.split('Rear_')}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[5].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[5].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el.split('Left_')}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[6].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[6].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>
                        {el.split('Right_')}
                      </td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[7].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[7].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <h6 className='mt-2'>Image Attachments</h6>
              {existingImageReport && existingImageReport.message && (
                <div>
                  {existingImageReport.message.map((image, index) => (
                    <Row key={index}>
                      <img
                        key={index}
                        className='my-2'
                        src={`data:image/png;base64,${image.URL}`}
                      />
                    </Row>
                  ))}
                </div>
              )}
              {existingImageReport && existingImageReport.loading && (
                <div className='text-center pt-3'>
                  <Spinner animation='border' />
                  <h3>Loading...</h3>
                </div>
              )}
              {existingImageReport && existingImageReport.error && (
                <h5>No Image/s Uploaded Yet</h5>
              )}
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant='info'
                onClick={() => {
                  setModal({
                    viewEvaluation: false,
                    viewAssessment: true,
                  })
                }}
              >
                Next
              </Button> */}
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewReport: false,
                    viewReportList: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {retrievedInspectionDetails && retrievedInspectionDetails.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReport: false,
                  viewReportList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {retrievedInspectionDetails && retrievedInspectionDetails.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReport: false,
                  viewReportList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Inspection Report */}

      {/* Evaluation List */}
      <Modal
        size='xl'
        show={modal.viewEvaluationList}
        onHide={() => setModal({ viewEvaluationList: false })}
        centered
        backdrop='static'
      >
        {evaluationList && evaluationList.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Evaluation and Assessment List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Trade</th>
                    <th>Date Evaluated</th>
                    <th>Date Assessed</th>
                    <th>Evaluator</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {evaluationList.message.map((list) => (
                    <tr key={list.id}>
                      <td className='fw-bold'>{list.trade}</td>
                      <td>
                        {moment(list.withEvaluation).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>
                        {moment(list.withAssessment).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>{list.EmployeeName}</td>
                      <td className='text-center'>
                        <Button
                          variant='success'
                          disabled={
                            list.withEvaluation && list.withAssessment !== null
                              ? false
                              : true
                          }
                          onClick={() => {
                            setCommentDetails({
                              comments: list.comments,
                              appID: list.id,
                            })
                            handleViewEvaluation(
                              list.id,
                              list.evaluatorID,
                              list.trade
                            )
                            setCancelBtn(true)
                          }}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewEvaluationList: false,
                    viewManager: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {evaluationList && evaluationList.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluationList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {evaluationList && evaluationList.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluationList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Evaluation List */}

      {/* Evaluation Info */}
      <Modal
        size='xl'
        show={modal.viewEvaluation}
        onHide={() => setModal({ viewEvaluation: false })}
        centered
        backdrop='static'
      >
        {evalDetails.length > 0 && activeTrade !== '' && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Evaluation and Assessment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>SCOPE OF WORK</h5>
              <p className='mx-2'>
                <i>{evalDetails.length > 0 && evalDetails[0].scope}</i>
              </p>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Code ID</th>
                    <th>JMC AND CODE REGULATIONS</th>
                    <th>COMPLIANCE (MARK CHECK / STATUS)</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {evalDetails.map((list) => (
                    <tr key={list.id}>
                      <td>{list.code_id}</td>
                      <td>{list.code_name}</td>
                      <td className='text-center'>
                        {list.compliance === 1 ? 'YES' : 'NO'}
                      </td>
                      <td>{list.id_remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <h5>Assessment</h5>
              <p className='mx-2'>
                <i className='fs-5'>Total Amount : </i>
                <i className='text-danger fs-3 fw-bold'>
                  ₱{' '}
                  {assessDetails.length > 0 &&
                    assessDetails[0].grandTotal.toLocaleString()}
                </i>
              </p>
              <hr />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewEvaluation: false,
                    viewEvaluationList: true,
                  })
                  setEvalDetails([])
                  setAssessDetails([])
                  setCancelBtn(false)
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {evalDetails.length <= 0 && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluation: false,
                  viewEvaluationList: true,
                })
                setEvalDetails([])
                setAssessDetails([])
                setCancelBtn(false)
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Evaluation Info */}

      {/* Order of Payment */}
      <Modal
        size='xl'
        show={modal.viewOrderofPayment}
        onHide={() => setModal({ viewOrderofPayment: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            ORDER OF PAYMENT DETAILS
          </Modal.Title>
        </Modal.Header>
        {retrievedForApprovalOp && retrievedForApprovalOp.message && (
          <Modal.Body>
            <Row>
              <Col xl='12' className='text-center'>
                <p className='m-0 fs-6'>Metropolitan Manila Authority</p>
                <p className='m-0 fs-6'>
                  Action Center for Infrastructure Development
                </p>
                <h6 className='m-0 fs-6'>OFFICE OF THE BUILDING OFFICIAL</h6>
                <p className='m-0 fs-6'>City of Las Piñas</p>
                <p className='m-0 fs-6'>Metro Manila</p>
              </Col>
            </Row>
            <Row>
              <Col xl='6'>
                <div className='d-flex'>
                  <h6>Application ID: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {retrievedForApprovalOp.message[0].applicationID}
                  </p>
                </div>
                <div className='d-flex'>
                  <h6>Owner/Firm/Applicant: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {retrievedForApprovalOp.message[0].Owner}
                  </p>
                </div>
                <div className='d-flex'>
                  <h6>Project: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {retrievedForApprovalOp.message[0].category}
                  </p>
                </div>
                <div className='d-flex'>
                  <h6>Location: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {retrievedForApprovalOp.message[0].Project_Location}
                  </p>
                </div>
              </Col>
              <Col xl='6'>
                <div className='d-flex justify-content-end'>
                  <h6 className='m-0 align-middle'>DATE: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {moment(retrievedForApprovalOp.message[0].timeStamp).format(
                      'MMMM D, YYYY h:mma'
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <h5 className='text-center'>ORDER OF PAYMENT</h5>
            <p className='fs-6'>TO: CITY/MUNICIPAL TREASURER/CASHIER</p>
            <p className='fs-6'>
              Please collect the amoun specified below as perscribed in the
              implementing Rules and Regulations of the National Building Code
              (PD-1096).
            </p>
            <Container className='px-5'>
              <Table>
                <tbody>
                  <tr>
                    <td className='p-1 align-middle'>001 Line and Grade</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].LineAndGrade !==
                        null &&
                        retrievedForApprovalOp.message[0].LineAndGrade.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      002 Building Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {(
                        retrievedForApprovalOp.message[0].StructuralFees +
                        retrievedForApprovalOp.message[0].ArchitecturalFees
                      ).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>Additional/Renovation</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0]
                        .AdditionalRenovation !== null &&
                        retrievedForApprovalOp.message[0].AdditionalRenovation.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>Roof Deck</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].RoofDeck !== null &&
                        retrievedForApprovalOp.message[0].RoofDeck.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      003 Demolition Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0]
                        .DemolitionPermitFees !== null &&
                        retrievedForApprovalOp.message[0].DemolitionPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      004 Ancillary Structure
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].AncillaryStructure !==
                        null &&
                        retrievedForApprovalOp.message[0].AncillaryStructure.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      005 Construction of Tombs/Canopies
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].TombsCanopies !==
                        null &&
                        retrievedForApprovalOp.message[0].TombsCanopies.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      006 Plumbing Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].PlumbingPermitFees !==
                        null &&
                        retrievedForApprovalOp.message[0].PlumbingPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      007 Electrical Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0]
                        .ElectricalPermitFees !== null &&
                        retrievedForApprovalOp.message[0].ElectricalPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      008 Mechanical Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0]
                        .MechanicalPermitFees !== null &&
                        retrievedForApprovalOp.message[0].MechanicalPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      009 Fencing Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].FencingPermitFees !==
                        null &&
                        retrievedForApprovalOp.message[0].FencingPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      010 Construction of Sidewalks
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].Sidewalks !== null &&
                        retrievedForApprovalOp.message[0].Sidewalks.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>011 Paved Areas</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].PavedAreas !== null &&
                        retrievedForApprovalOp.message[0].PavedAreas.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      012 Use of Street and Sidewalks
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].StreetSidewalk !==
                        null &&
                        retrievedForApprovalOp.message[0].StreetSidewalk.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      013 Certificate of Use/Occupancy
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0]
                        .CertificateOccupancy !== null &&
                        retrievedForApprovalOp.message[0].CertificateOccupancy.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      014 Change in Use/Occupancy
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].ChangeOccupancy !==
                        null &&
                        retrievedForApprovalOp.message[0].ChangeOccupancy.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      015 Certificate of Occupancy prior to 10
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0]
                        .CertificateOccupancy10 !== null &&
                        retrievedForApprovalOp.message[0].CertificateOccupancy10.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>016 Electronics Permit</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].ElectronicsPermit !==
                        null &&
                        retrievedForApprovalOp.message[0].ElectronicsPermit.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>017 Annual Inspection</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].AnnualInspection !==
                        null &&
                        retrievedForApprovalOp.message[0].AnnualInspection.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>018 Sign Permit Fees</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].SignPermitFees !==
                        null &&
                        retrievedForApprovalOp.message[0].SignPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>019 Certification</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].Certification !==
                        null &&
                        retrievedForApprovalOp.message[0].Certification.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>020 Penalty/Surcharge</td>
                    <td className='p-1 align-middle text-end'>
                      {penaltyValue.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      021 Administrative Fine
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].AdministrativeFive !==
                        null &&
                        retrievedForApprovalOp.message[0].AdministrativeFive.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      SUB-TOTAL
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].subTotal !== null &&
                        retrievedForApprovalOp.message[0].subTotal.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <p className='fs-6 pt-4 '>
              Please collect the amount specified below as perscribed in the
              approved City Ordinance No. 1373 - 16 series of 2016 Las Piñas
              City.
            </p>
            <h5 className='text-center mt-4'>BUILDING PERMIT</h5>
            <Container className='px-5'>
              <Table>
                <tbody>
                  <tr>
                    <td className='p-1 align-middle'>001 Filling Fee</td>
                    <td className='p-1 align-middle text-end'>300</td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>002 Processing Fees</td>
                    <td className='p-1 align-middle text-end'>750</td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      003 Complaint Filling Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].ComplaintFilingFee !==
                        null &&
                        retrievedForApprovalOp.message[0].ComplaintFilingFee.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>004 Others (Specify)</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].Others !== null &&
                        retrievedForApprovalOp.message[0].Others.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      SUB-TOTAL
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].subTotal1 !== null &&
                        retrievedForApprovalOp.message[0].subTotal1.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      TOTAL AMOUNT
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].totalAmount !== null &&
                        retrievedForApprovalOp.message[0].totalAmount.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Row className='px-5'>
                <h6>PREPARED BY :</h6>
                <h6>{retrievedForApprovalOp.message[0].RecommendedBy}</h6>
              </Row>
            </Container>
            <p className='fs-6'>
              Please collect the amoun specified below as perscribed.
            </p>
            <h5 className='text-center mt-4'>OFFICE OF THE CITY TREASURER</h5>
            <Container className='px-5'>
              <Table>
                <tbody>
                  <tr>
                    <td className='p-1 align-middle'>001 Contractor's Tax</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].contractors_tax !==
                        null &&
                        retrievedForApprovalOp.message[0].contractors_tax.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      SUB-TOTAL
                    </td>
                    <td className='p-1 align-middle text-end text-danger'>
                      {retrievedForApprovalOp.message[0].contractors_tax !==
                        null &&
                        retrievedForApprovalOp.message[0].contractors_tax.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <hr />
            <h5 className='fs-6 mx-5 mt-2 text-center'>Summary</h5>
            <Container className='px-5'>
              <Table>
                <tbody>
                  <tr>
                    <td className='p-1 align-middle'>001 Building Permit</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].subTotal !== null &&
                        retrievedForApprovalOp.message[0].subTotal.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      002 Processing Fee/Filing Fee
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].subTotal1 !== null &&
                        retrievedForApprovalOp.message[0].subTotal1.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>003 Contractor's Tax</td>
                    <td className='p-1 align-middle text-end'>
                      {retrievedForApprovalOp.message[0].contractors_tax !==
                        null &&
                        retrievedForApprovalOp.message[0].contractors_tax.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      GRAND TOTAL AMOUNT
                    </td>
                    <td className='p-1 align-middle text-end fw-bold text-danger'>
                      {retrievedForApprovalOp.message[0].grandTotal !== null &&
                        retrievedForApprovalOp.message[0].grandTotal.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Modal.Body>
        )}
        <Modal.Footer>
          {retrievedForApprovalOp && retrievedForApprovalOp.message && (
            <PDFDownloadLink
              document={
                <RecommenderOrderPay
                  info={
                    retrievedForApprovalOp &&
                    retrievedForApprovalOp.message &&
                    retrievedForApprovalOp.message
                  }
                  penaltyValue={penaltyValue}
                  generatedOP={{
                    subtotal:
                      retrievedForApprovalOp.message[0].subTotal !== null &&
                      retrievedForApprovalOp.message[0].subTotal,
                    subtotal1:
                      retrievedForApprovalOp.message[0].subTotal1 !== null &&
                      retrievedForApprovalOp.message[0].subTotal1,
                    totalAmount:
                      retrievedForApprovalOp.message[0].totalAmount !== null &&
                      retrievedForApprovalOp.message[0].totalAmount,
                    withPenalty:
                      retrievedForApprovalOp.message[0].subTotal !== null &&
                      retrievedForApprovalOp.message[0].subTotal,
                    grandTotal:
                      retrievedForApprovalOp.message[0].grandTotal !== null &&
                      retrievedForApprovalOp.message[0].grandTotal,
                  }}
                />
              }
              fileName='OrderOfPayment.pdf'
            >
              {({ loading }) =>
                loading ? (
                  'Loading document...'
                ) : (
                  <Button variant='info'>PRINT</Button>
                )
              }
            </PDFDownloadLink>
          )}
          <Button
            variant='danger'
            onClick={() =>
              setModal({ viewManager: true, viewOrderofPayment: false })
            }
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Order of Payment */}

      {/*Recommend Application Success Modal */}
      <SuccessModal
        show={modal.approvedSuccess}
        onHide={() => {
          setModal({ approvedSuccess: false })
          window.location.reload(true)
        }}
        transLoading={updateAppStatus && updateAppStatus.loading}
        success={updateAppStatus && updateAppStatus.message}
        error={updateAppStatus && updateAppStatus.error}
      />
      {/* End Recommend Application Success Modal */}
    </UserMain>
  )
}

export default withRouter(ForApprovalListScreen)
