import React from 'react'
import { Image, Row, Col, Container } from 'react-bootstrap'

const FaqAppUpdate = () => {
  return (
    <div>
      <div>
        <h6>
          All your application will be displayed on the <b>"TRANSACTION"</b>{' '}
          section, where we have <b>"STATUS"</b> page.
        </h6>
        <div className='text-center mt-3'>
          <img src='assets/img/faqupdate1.png' width='50%' />
          <p className='mt-2'>Figure 1.1: Transaction Section</p>
        </div>
        <Row>
          <img src='assets/img/faqupdate2.png' />
          <p className='mt-2 text-center'>Figure 1.2: Status Page</p>
        </Row>

        <p style={{ background: '#f9f6f1' }} className='p-2'>
          Note: Every stataus change/progess on you application will be notified
          thru, the email address and mobile number you registered on our
          website. You can verify the email and text message by simply logging
          in on account registered to our website and check the <b>"STATUS"</b>{' '}
          page.
        </p>
      </div>
    </div>
  )
}

export default FaqAppUpdate
