import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

// Action
import { updateInspector } from '../redux/actions/DocumentControllers/documentControllerActions'

// Layout
import SuccessModal from './SuccessModal'
import Loading from './Loading'

const AppInspector = (props) => {
  const dispatch = useDispatch()

  const [state, setState] = useState({
    inspectID: 0,
    inspectionSched: '',
    transaction: false,
    EmailAddress: '',
  })

  const [appDate, setAppDate] = useState('')

  const [modal, setModal] = useState({
    success: false,
  })

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const dc = useSelector((state) => state.documentControllerReducer)

  const { inspectionList, submittedInspector, updatedInspector } = dc

  //   Function
  const handleChange = (e) => {
    if (e.target.name === 'inspectID') {
      let selectedEmployee =
        inspectionList &&
        inspectionList.message.find(
          (employee) => employee.EmployeeID === parseInt(e.target.value)
        )
      setState({
        ...state,
        [e.target.name]: e.target.value,
        EmailAddress: selectedEmployee.EmailAddress,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const minDate = () => {
    let dateMin = new Date()

    let minYear = dateMin.getFullYear()
    let minMonth = ('0' + (dateMin.getMonth() + 1)).slice(-2)
    let minDay = ('0' + dateMin.getDate()).slice(-2)

    let minDate = minYear + '-' + minMonth + '-' + minDay

    if (appDate === '') {
      setAppDate(minDate)
    }
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    dispatch(
      updateInspector({
        id: props.payload.id,
        dcID: userCredentials.id,
        inspectionSched: state.inspectionSched,
        inspectID: state.inspectID,
        transaction: state.transaction,
        EmailAddress: state.EmailAddress,
      })
    )
    props.onClose()
    setModal({ success: true })
  }
  // End Function

  //   useEffect
  useEffect(() => {
    minDate()
    // sendNewStatus()
    if (submittedInspector && submittedInspector.message) {
      setState((state) => ({
        ...state,
        inspectID: submittedInspector.message[0].inspectID,
        inspectionSched: submittedInspector.message[0].inspectionSched,
        transaction: true,
      }))
    }
  }, [submittedInspector])
  // End useEffect

  // console.log(state)
  return (
    <div>
      <Modal
        size='md'
        show={props.show}
        onHide={props.onHide}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-1' /> Application Inspector
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleOnSubmit}>
          {inspectionList && inspectionList.message && (
            <Modal.Body>
              <Form.Group>
                <Form.Label>Inspector</Form.Label>
                <Form.Control
                  as='select'
                  required
                  onChange={handleChange}
                  name='inspectID'
                  value={state.inspectID}
                >
                  <option value={0}>Choose Inspector here ...</option>
                  {inspectionList &&
                    inspectionList.message &&
                    inspectionList.message.map((el) => (
                      <option key={el.EmployeeID} value={el.EmployeeID}>
                        {el.EmployeeName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Inspection Schedule</Form.Label>
                <Form.Control
                  type='date'
                  name='inspectionSched'
                  required
                  value={state.inspectionSched}
                  min={appDate}
                  onKeyDown={(e) => e.preventDefault()}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={handleChange}
                />
              </Form.Group>
            </Modal.Body>
          )}

          {inspectionList && inspectionList.loading && (
            <Modal.Body>
              <Loading />
            </Modal.Body>
          )}

          <Modal.Footer>
            <Button variant='info' size='sm' type='submit'>
              Submit
            </Button>
            <Button variant='danger' size='sm' onClick={props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/*Success Modal */}
      <SuccessModal
        show={modal.success}
        onHide={() => {
          props.onHide()
          setModal({ success: false })
        }}
        transLoading={updatedInspector && updatedInspector.loading}
        success={updatedInspector && updatedInspector.message}
        error={updatedInspector && updatedInspector.error}
      />
      {/* End Success Modal */}
    </div>
  )
}

export default AppInspector
