export const GET_ESUPERVISOR_FORRECOMMENDATION_REQ =
  'GET_ESUPERVISOR_FORRECOMMENDATION_REQ'
export const GET_ESUPERVISOR_FORRECOMMENDATION_SUCCESS =
  'GET_ESUPERVISOR_FORRECOMMENDATION_SUCCESS'
export const GET_ESUPERVISOR_FORRECOMMENDATION_FAILED =
  'GET_ESUPERVISOR_FORRECOMMENDATION_FAILED'

export const GET_ESUPERVISOR_RECOMMENDED_REQ = 'GET_ESUPERVISOR_RECOMMENDED_REQ'
export const GET_ESUPERVISOR_RECOMMENDED_SUCCESS =
  'GET_ESUPERVISOR_RECOMMENDED_SUCCESS'
export const GET_ESUPERVISOR_RECOMMENDED_FAILED =
  'GET_ESUPERVISOR_RECOMMENDED_FAILED'

export const GET_EVALUATIONLIST_RECOMMENDED_REQ =
  'GET_EVALUATIONLIST_RECOMMENDED_REQ'
export const GET_EVALUATIONLIST_RECOMMENDED_SUCCESS =
  'GET_EVALUATIONLIST_RECOMMENDED_SUCCESS'
export const GET_EVALUATIONLIST_RECOMMENDED_FAILED =
  'GET_EVALUATIONLIST_RECOMMENDED_FAILED'

export const GET_ESUPERVISOR_RECOMMEND_REQ = 'GET_ESUPERVISOR_RECOMMEND_REQ'
export const GET_ESUPERVISOR_RECOMMEND_SUCCESS =
  'GET_ESUPERVISOR_RECOMMEND_SUCCESS'
export const GET_ESUPERVISOR_RECOMMEND_FAILED =
  'GET_ESUPERVISOR_RECOMMEND_FAILED'

export const INSERT_COMMENT_REQ = 'INSERT_COMMENT_REQ'
export const INSERT_COMMENT_SUCCESS = 'INSERT_COMMENT_SUCCESS'
export const INSERT_COMMENT_FAILED = 'INSERT_COMMENT_FAILED'
