import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import moment from 'moment'
import _ from 'lodash'

const ViewAppInfo = (props) => {
  return (
    <div>
      <Modal
        size='md'
        show={props.show}
        onHide={props.onHide}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-1' />
            Application Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive size='sm'>
            <tbody>
              <tr className='text-nowrap'>
                <td className='fw-bold'>Application No.:</td>
                <td>{props.payload !== undefined && props.payload.id}</td>
              </tr>
              <tr className='text-nowrap'>
                <td className='fw-bold'>Application Name:</td>
                <td>
                  {props.payload !== undefined && props.payload.ApplicationName}
                </td>
              </tr>
              <tr className='text-nowrap'>
                <td className='fw-bold'>Mode Of Application:</td>
                <td>
                  {props.payload !== undefined && props.payload.ModeofForms}
                </td>
              </tr>
              <tr className='text-nowrap'>
                <td className='fw-bold'>Application Status:</td>
                <td>{props.payload !== undefined && props.payload.status}</td>
              </tr>
              <tr className='text-nowrap'>
                <td className='fw-bold'>Application Date Applied:</td>
                <td>{moment(props.dateApplied).format('MMMM D, YYYY')}</td>
              </tr>
              <tr className='text-nowrap'>
                <td className='fw-bold'>Applied By:</td>
                <td>
                  {props.payload !== undefined &&
                  props.payload.FullName !== null
                    ? props.payload.FullName
                    : props.payload !== undefined &&
                      props.payload.applicantName}
                </td>
              </tr>
              <tr className='text-nowrap'>
                <td className='fw-bold'>Applicant Attached Link:</td>
                <td>{props.payload !== undefined && props.payload.Link}</td>
              </tr>
              <tr>
                <td className='fw-bold'>Trades Permit Attached:</td>
                <td>
                  {props.payload !== undefined &&
                    _.zip(
                      [
                        'STRUCTURAL',
                        'MECHANICAL',
                        'ARCHITECTURAL',
                        'ELECTRICAL',
                        'PLUMBING',
                        'ELECTRONICS',
                      ],
                      [
                        props.payload.Structural,
                        props.payload.Mechanical,
                        props.payload.Architectural,
                        props.payload.Electrical,
                        props.payload.Plumbing,
                        props.payload.Electronics,
                      ]
                    ).map((trade) => trade[1] === 1 && trade[0] + ',' + ' ')}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' size='sm' onClick={props.onHide}>
            Back
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ViewAppInfo
