import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Form, Button } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

// Actions
import { retrieveEmail } from '../redux/actions/userActions'

const ForgotPasswordScreen = ({ history }) => {
  // Declaration
  const dispatch = useDispatch()

  const users = useSelector((state) => state.users)
  const { retrievedEmail } = users

  const [state, setState] = useState({
    email: '',
  })
  // End Declaration

  // Functions
  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(retrieveEmail(state))
  }
  // End Functions

  useEffect(() => {
    if (retrievedEmail && retrievedEmail.message) {
      setState({
        email: '',
      })
    }
  }, [retrievedEmail])

  return (
    <div
      className='login-main d-flex justify-content-center align-items-center bg-img center-vh'
      style={{ backgroundImage: 'url(./assets/img/login-bg.png)' }}
    >
      <Row>
        <Col md={12}>
          <div className='border border-secondary shadow-lg p-3 pb-5 bg-white'>
            <Row className='pt-2'>
              <Col md={12} className='text-center'>
                <div className='py-3'>
                  <Image
                    src='assets/logo/Ootb-logo.png'
                    roundedCircle
                    width='80'
                    height='80'
                    className='shadow-lg'
                  />
                </div>

                <h3 className='pt-3'>FORGOT PASSWORD</h3>
              </Col>
              <Col md={12} className='px-5 py-2'>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className='py-2'>
                    <Form.Label>
                      <h6>EMAIL ADDRESS</h6>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='input email address here ...'
                      onChange={handleChange}
                      name='email'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      required
                      value={state.email}
                    />
                  </Form.Group>
                  {retrievedEmail && retrievedEmail.error && (
                    <p className='text-danger m-0 fs-6'>
                      email address you entered not yet registered ...
                    </p>
                  )}

                  {retrievedEmail && retrievedEmail.message && (
                    <p className='text-secondary m-0 fs-6'>
                      Instruction for changing password will be sent to your
                      email.
                    </p>
                  )}

                  {retrievedEmail && retrievedEmail.message ? (
                    <div className='d-grid gap-2 mt-3'>
                      <Button
                        variant='danger'
                        onClick={() => {
                          history.push('/')
                          window.location.reload(true)
                        }}
                      >
                        RETURN
                      </Button>
                    </div>
                  ) : (
                    <div className='d-grid gap-2 mt-3'>
                      <Button variant='success' type='submit'>
                        Submit
                      </Button>
                    </div>
                  )}
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(ForgotPasswordScreen)
