import React, { useState } from 'react'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
} from 'react-pro-sidebar'
import { NavLink } from 'react-router-dom'
import { Image } from 'react-bootstrap'

// Icons
import { BiTransfer } from 'react-icons/bi'
import {
  FaChartPie,
  FaUserCircle,
  FaClipboardList,
  FaSearchLocation,
  FaClipboardCheck,
  FaUserAlt,
  FaFile,
  FaFileSignature,
  FaArchive,
  FaUserLock,
  FaHdd,
  FaFileContract,
  FaNewspaper,
  FaToolbox,
} from 'react-icons/fa'
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi'

// React Redux
import { useSelector } from 'react-redux'

const Sidebar = ({ fontSize, toggled, handleToggleSidebar }) => {
  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const userProfile = useSelector((state) => state.userProfile)
  const { profile } = userProfile

  let [menuCollapse, setMenuCollapse] = useState(false)

  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)
  }

  return (
    <div>
      <ProSidebar
        collapsed={menuCollapse}
        toggled={toggled}
        onToggle={() => handleToggleSidebar(false)}
        breakPoint='xl'
        collapsedWidth='100px'
      >
        <SidebarHeader className='pt-3 text-center'>
          <div className='py-2'>
            <h4 style={{ color: '#FFF' }}>
              <Image
                src='assets/logo/Ootb-logo.webp'
                roundedCircle
                width='50'
                height='50'
                className='shadow-lg my-2'
              />
              <br />
              Office of the Building Official
              {/* {menuCollapse ? (
                <Image
                  src='assets/logo/Ootb-logo.webp'
                  roundedCircle
                  width='50'
                  height='50'
                  className='shadow-lg'
                />
              ) : (
                'Office of the Building Official'
              )} */}
            </h4>
          </div>
          {/* <div className='closemenu'>
            {menuCollapse ? (
              <FiArrowRightCircle
                className='.d-none .d-sm-block .d-md-none'
                onClick={() => setMenuCollapse(false)}
              />
            ) : (
              <FiArrowLeftCircle
                className='.d-none .d-sm-block .d-md-none'
                onClick={() => setMenuCollapse(true)}
              />
            )}
          </div> */}
        </SidebarHeader>

        {userCredentials && userCredentials.roles === 'user' && (
          <Menu>
            <MenuItem>
              <NavLink
                to='/dashboard'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                {/* <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i> */}
                <FaChartPie style={{ fontSize }} className='mx-2' />
                DASHBOARD
              </NavLink>
            </MenuItem>

            <SubMenu
              title='TRANSACTION'
              className='my-3'
              icon={
                <BiTransfer
                  className='mr-2'
                  style={{ width: '40px', height: 'auto', fontSize: '60px' }}
                />
              }
            >
              {profile && profile[0].PRA_id !== null ? (
                <MenuItem>
                  <NavLink
                    to='/apply'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    Apply
                  </NavLink>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() =>
                    alert(
                      'You must complete your account details first at the Profile page'
                    )
                  }
                >
                  Apply
                </MenuItem>
              )}

              <MenuItem>
                <NavLink
                  to='/user-application-status-list'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  Status
                </NavLink>
              </MenuItem>
              {/* <MenuItem>
                <NavLink
                  to='/user-application-history-list'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  History
                </NavLink>
              </MenuItem> */}
            </SubMenu>

            {/* <MenuItem className='my-4'>
            <NavLink
              to='/appointment'
              activeStyle={{
                fontWeight: '700',
                color: '#FFF',
              }}
            >
              <FaCalendarAlt style={{ fontSize }} className='mx-2' />
              APPOINTMENT
            </NavLink>
          </MenuItem> */}
            <MenuItem className='my-4'>
              <NavLink
                to='/profile'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                <FaUserCircle style={{ fontSize }} className='mx-2' />
                PROFILE
              </NavLink>
            </MenuItem>
          </Menu>
        )}

        {userCredentials && userCredentials.roles === 'document controller' && (
          <Menu>
            <MenuItem className='my-4'>
              <NavLink
                to='/dashboard'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                {/* <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i> */}
                <FaChartPie style={{ fontSize }} className='mx-2' />
                DASHBOARD
              </NavLink>
            </MenuItem>

            <SubMenu
              title='TRANSACTION'
              className='my-3'
              icon={
                <FaClipboardList
                  className='mr-2'
                  style={{ width: '20px', height: 'auto' }}
                />
              }
            >
              <MenuItem>
                <NavLink
                  to='/applications'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  APPLICATIONS
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to='/walkin-applications'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  WALK-IN APPLICATION
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to='/category'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  CREATE APPLICATION
                </NavLink>
              </MenuItem>

              {/* <MenuItem>
                <NavLink
                  to='/application-status'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  APPLICATION STATUS
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to='/application-status'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  OFFICIAL RECEIPT
                </NavLink>
              </MenuItem> */}
            </SubMenu>

            {/* <MenuItem className='my-4'>
              <NavLink
                to='/reports'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i>
                <FaHdd style={{ fontSize }} className='mx-2' />
                REPORT
              </NavLink>
            </MenuItem> */}
          </Menu>
        )}

        {userCredentials && userCredentials.roles === 'recommender' && (
          <Menu>
            <MenuItem className='my-4'>
              <NavLink
                to='/dashboard'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                {/* <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i> */}
                <FaChartPie style={{ fontSize }} className='mx-2' />
                DASHBOARD
              </NavLink>
            </MenuItem>

            <SubMenu
              title='TRANSACTION'
              className='my-3'
              icon={
                <FaClipboardList
                  className='mr-2'
                  style={{ width: '20px', height: 'auto' }}
                />
              }
            >
              <MenuItem>
                <NavLink
                  to='/for-recommendation-list'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  FOR RECOMMENDATION LIST
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to='/recommendation-list'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  RECOMMENDED LIST
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to='/applications'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  APPLICATION LIST
                </NavLink>
              </MenuItem>

              {/* <MenuItem>
                <NavLink
                  to='/returned-list'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  RETURNED LIST
                </NavLink>
              </MenuItem> */}
            </SubMenu>
          </Menu>
        )}

        {userCredentials && userCredentials.roles === 'engr' && (
          <Menu>
            <MenuItem className='my-4'>
              <NavLink
                to='/dashboard'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                {/* <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i> */}
                <FaChartPie style={{ fontSize }} className='mx-2' />
                DASHBOARD
              </NavLink>
            </MenuItem>
          </Menu>
        )}

        {userCredentials &&
          userCredentials.roles === 'inspector' &&
          userCredentials.subroles !== 'supervisor' && (
            <Menu>
              {/* <MenuItem className='my-4'>
                <NavLink
                  to='/dashboard'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i>
                  <FaChartPie style={{ fontSize }} className='mx-2' />
                  DASHBOARD
                </NavLink>
              </MenuItem> */}

              <SubMenu
                title='INSPECTOR'
                className='my-3'
                icon={
                  <FaSearchLocation
                    className='mr-2 fs-6'
                    style={{ width: '20px', height: 'auto' }}
                  />
                }
              >
                <MenuItem>
                  <NavLink
                    to='/dashboard'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    FOR INSPECTION
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to='/inspection-list'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    INSPECTION REPORTS
                  </NavLink>
                </MenuItem>
              </SubMenu>

              <MenuItem className='my-4'>
                <NavLink
                  to='/notice-of-violation'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  {/* <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i> */}
                  <FaArchive style={{ fontSize }} className='mx-2 fs-6' />
                  NOTICE OF VIOLATION
                </NavLink>
              </MenuItem>
            </Menu>
          )}

        {userCredentials &&
          userCredentials.roles === 'inspector' &&
          userCredentials.subroles === 'supervisor' && (
            <Menu>
              <MenuItem className='my-4'>
                <NavLink
                  to='/dashboard'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  {/* <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i> */}
                  <FaChartPie style={{ fontSize }} className='mx-2' />
                  DASHBOARD
                </NavLink>
              </MenuItem>

              <SubMenu
                title='INSPECTOR'
                className='my-3'
                icon={
                  <FaSearchLocation
                    className='mr-2'
                    style={{ width: '20px', height: 'auto' }}
                  />
                }
              >
                <MenuItem>
                  <NavLink
                    to='/for-recommendation-list'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    FOR RECOMMENDATION
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to='/recommendation-list'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    RECOMMENDED
                  </NavLink>
                </MenuItem>
              </SubMenu>

              <MenuItem className='my-4'>
                <NavLink
                  to='/notice-of-violation'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  {/* <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i> */}
                  <FaArchive style={{ fontSize }} className='mx-2 fs-6' />
                  NOTICE OF VIOLATION
                </NavLink>
              </MenuItem>
            </Menu>
          )}

        {userCredentials && userCredentials.roles === 'admin' && (
          <Menu>
            <MenuItem className='my-4'>
              <NavLink
                to='/dashboard'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                {/* <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i> */}
                <FaChartPie style={{ fontSize }} className='mx-2 fs-6' />
                DASHBOARD
              </NavLink>
            </MenuItem>

            <SubMenu
              title='TRANSACTIONS'
              className='my-3'
              icon={
                <FaSearchLocation
                  className='mr-2'
                  style={{ width: '20px', height: 'auto' }}
                />
              }
            >
              <MenuItem>
                <NavLink
                  to='/for-approval-list'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  <FaFileSignature style={{ fontSize }} className='mx-2 fs-6' />
                  FOR APPROVAL LIST
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to='/approved-list'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  <FaFileContract style={{ fontSize }} className='mx-2 fs-6' />
                  APPROVED LIST
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  to='/applications'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  <FaFile style={{ fontSize }} className='mx-2 fs-6' />
                  APPLICATION LIST
                </NavLink>
              </MenuItem>
            </SubMenu>

            <MenuItem>
              <NavLink
                to='/notice-of-violation'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                {/* <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i> */}
                <FaArchive style={{ fontSize }} className='mx-2 fs-6' />
                NOTICE OF VIOLATION
              </NavLink>
            </MenuItem>

            <MenuItem>
              <NavLink
                to='/reports'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                <FaArchive style={{ fontSize }} className='mx-2 fs-6' />
                REPORTS
              </NavLink>
            </MenuItem>

            {/* <MenuItem>
              <NavLink
                to='/employee-maintenance'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                <FaUserAlt style={{ fontSize }} className='mx-2 fs-6' />
                EMPLOYEE MAINTENANCE
              </NavLink>
            </MenuItem>

            <MenuItem>
              <NavLink
                to='/verify-user'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                <FaUserAlt style={{ fontSize }} className='mx-2 fs-6' />
                VERIFY USER ACCOUNT
              </NavLink>
            </MenuItem> */}
          </Menu>
        )}

        {userCredentials &&
          userCredentials.roles === 'evaluator' &&
          userCredentials.subroles !== 'supervisor' && (
            <Menu>
              {/* <MenuItem className='my-4'>
                <NavLink
                  to='/dashboard'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i>
                  <FaChartPie style={{ fontSize }} className='mx-2' />
                  DASHBOARD
                </NavLink>
              </MenuItem> */}

              <SubMenu
                title='EVALUATOR'
                className='my-3'
                icon={
                  <FaClipboardCheck
                    className='mr-2'
                    style={{ width: '20px', height: 'auto' }}
                  />
                }
              >
                {/* /create-evaluation-electronics */}
                <MenuItem>
                  <NavLink
                    to='/dashboard'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    FOR EVALUATION
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to='/evaluated-list'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    EVALUATED
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to='/for-assessment-list'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    FOR ASSESSMENT
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to='/assessment-list'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    WITH ASSESSMENT
                  </NavLink>
                </MenuItem>
              </SubMenu>
            </Menu>
          )}

        {userCredentials &&
          userCredentials.roles === 'evaluator' &&
          userCredentials.subroles === 'supervisor' && (
            <Menu>
              <MenuItem className='my-4'>
                <NavLink
                  to='/dashboard'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  {/* <i className='fas fa-chart-pie mr-2' style={{ fontSize }}></i> */}
                  <FaChartPie style={{ fontSize }} className='mx-2' />
                  DASHBOARD
                </NavLink>
              </MenuItem>

              <SubMenu
                title='EVALUATOR'
                className='my-3'
                icon={
                  <FaClipboardCheck
                    className='mr-2'
                    style={{ width: '20px', height: 'auto' }}
                  />
                }
              >
                {/* /create-evaluation-electronics */}
                <MenuItem>
                  <NavLink
                    to='/for-recommendation-list'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    FOR RECOMMENDATION
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to='/recommendation-list'
                    activeStyle={{
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    RECOMMENDED
                  </NavLink>
                </MenuItem>
              </SubMenu>
            </Menu>
          )}

        {userCredentials && userCredentials.roles === 'ict' && (
          <Menu>
            <MenuItem className='my-4'>
              <NavLink
                to='/dashboard'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                <FaChartPie style={{ fontSize }} className='mx-2' />
                DASHBOARD
              </NavLink>
            </MenuItem>

            <SubMenu
              title='MAINTENANCE'
              className='my-3'
              icon={
                <FaToolbox
                  className='mr-2'
                  style={{ width: '20px', height: 'auto' }}
                />
              }
            >
              <MenuItem>
                <NavLink
                  to='/employee-maintenance'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  <FaUserAlt className='mx-2' />
                  Employee Maintenance
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to='/user-maintenance'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  <FaUserLock className='mx-2' />
                  User Maintenance
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to='/verify-user'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  <FaUserLock className='mx-2' />
                  User Verification
                </NavLink>
              </MenuItem>
            </SubMenu>

            <SubMenu
              title='REPORT'
              className='my-3'
              icon={
                <FaClipboardCheck
                  className='mr-2'
                  style={{ width: '20px', height: 'auto' }}
                />
              }
            >
              <MenuItem>
                <NavLink
                  to='/nov-report'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  NOV Report
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to='/application-report'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  Application Report
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to='/annual-report'
                  activeStyle={{
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  Annual Report
                </NavLink>
              </MenuItem>
            </SubMenu>

            <MenuItem className='my-4'>
              <NavLink
                to='/news-updates-list'
                activeStyle={{
                  fontWeight: '700',
                  color: '#FFF',
                }}
              >
                <FaNewspaper
                  style={{ width: '20px', height: 'auto' }}
                  className='mx-2'
                />
                NEWS & UPDATES
              </NavLink>
            </MenuItem>
          </Menu>
        )}
      </ProSidebar>
    </div>
  )
}

Sidebar.defaultProps = {
  fontSize: '30px',
  background: '#0e3207',
}

export default Sidebar
