import Cookie from 'js-cookie'
import axios from 'axios'
import {
  CREATE_CERTIFICATES_ASSESSMENT_REQ,
  CREATE_CERTIFICATES_ASSESSMENT_SUCCESS,
  CREATE_CERTIFICATES_ASSESSMENT_FAILED,
  GET_CERTIFICATES_ASSESSMENT_REQ,
  GET_CERTIFICATES_ASSESSMENT_SUCCESS,
  GET_CERTIFICATES_ASSESSMENT_FAILED,
  UPDATE_CERTIFICATES_ASSESSMENT_REQ,
  UPDATE_CERTIFICATES_ASSESSMENT_SUCCESS,
  UPDATE_CERTIFICATES_ASSESSMENT_FAILED,
} from '../../constants/Assessment/certificatesAssessmentConstants'

export const retrieveAssessmentDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CERTIFICATES_ASSESSMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/assessment/certifications/assessment-details/${payload.evalAppID}`,
        payload,
        config
      )

      dispatch({
        type: GET_CERTIFICATES_ASSESSMENT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_CERTIFICATES_ASSESSMENT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const addAssessment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_CERTIFICATES_ASSESSMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/assessment/certifications`,
        payload,
        config
      )

      dispatch({
        type: CREATE_CERTIFICATES_ASSESSMENT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: CREATE_CERTIFICATES_ASSESSMENT_FAILED,
        payload: 'TRANSACTION FAILED',
      })
    }
  }
}

export const editAssessment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CERTIFICATES_ASSESSMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/assessment/certifications`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_CERTIFICATES_ASSESSMENT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_CERTIFICATES_ASSESSMENT_FAILED,
        payload: 'TRANSACTION FAILED',
      })
    }
  }
}
