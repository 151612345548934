import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  InputGroup,
  Button,
  Table,
  Pagination,
  Modal,
} from 'react-bootstrap'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { withRouter } from 'react-router-dom'
import { FaSearch, FaEye, FaCheck, FaEllipsisH } from 'react-icons/fa'
import moment from 'moment'
import io from 'socket.io-client'

//Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'
import CommentsCard from '../../reusable/CommentsCard'
import RecommenderOrderPay from '../../reusable/RecommenderOrderPay'

// Actions
import {
  retrieveForRecommendationList,
  insertIComment,
  insertEComment,
  insertOrderofPayment,
  updateApplicationStatus,
  retrieveCommentList,
  retrieveOrderofPayment,
} from '../../redux/actions/Recommender/recommenderActions'
import { retrieveDateApplied } from '../../redux/actions/adminApplicationActions'

import {
  retrieveReportList,
  retrievePenalty,
  retrieveInspectionDetails,
} from '../../redux/actions/Inspectors/Supervisors/isupervisorActions'

import { retrieveEvaluationList } from '../../redux/actions/Evaluators/Supervisors/supervisorActions'

import {
  retrieveReport,
  retrieveImageReport,
} from '../../redux/actions/inspectionActions'

import { retrieveEvaluationDetails as CivilEvaluation } from '../../redux/actions/evaluationCivilActions'
import { retrieveEvaluationDetails as ArchEvaluation } from '../../redux/actions/evaluatorArchActions'
import { retrieveEvaluationDetails as ElectronicsEvaluation } from '../../redux/actions/evaluatorActions'
import { retrieveEvaluationDetails as ElecEvaluation } from '../../redux/actions/evaluatorElectricalActions'
import { retrieveEvaluationDetails as MechEvaluation } from '../../redux/actions/evaluatorMechActions'
import { retrieveEvaluationDetails as PlumEvaluation } from '../../redux/actions/evaluatorPlumbingActions'
import { retrieveAssessmentDetails as CivilAssessment } from '../../redux/actions/Assessment/structuralAssessmentActions'
import { retrieveAssessmentDetails as ElecAssessment } from '../../redux/actions/Assessment/electricalAssessmentActions'
import { retrieveAssessmentDetails as ElectronicsAssessment } from '../../redux/actions/Assessment/electronicsAssessmentActions'
import { retrieveAssessmentDetails as MechAssessment } from '../../redux/actions/Assessment/mechanicalAssessmentActions'
import { retrieveAssessmentDetails as PlumAssessment } from '../../redux/actions/Assessment/plumbingAssessmentActions'
import { retrieveAssessmentDetails as ArchAssessment } from '../../redux/actions/Assessment/accessoriesFeeAssessmentActions'

// Data
import { createReport } from '../../../data/createReport'

const socket = io.connect(process.env.REACT_APP_URL)

const ForRecommendationListScreen = () => {
  // Declaration
  const dispatch = useDispatch()

  const [state, setState] = useState([])

  const [payload, setPayload] = useState({})

  const [searchTerm, setSearchTerm] = useState('')

  const [generatedOP, setGeneratedOP] = useState({
    subtotal: 0,
    subtotal1: 0,
    totalAmount: 0,
    withPenalty: 0,
    grandTotal: 0,
  })

  const [modal, setModal] = useState({
    viewManager: false,
    viewReport: false,
    viewReportList: false,
    viewAssessment: false,
    iSuccessModal: false,
    eSuccessModal: false,
    viewEvaluationList: false,
    recommendSuccess: false,
    viewComments: false,
    viewOrderofPayment: false,
    viewSuccessOP: false,
  })

  const [penaltyInfo, setPenaltyInfo] = useState('')

  const [penaltyValue, setPenaltyValue] = useState(0)

  const [evalDetails, setEvalDetails] = useState([])

  const [assessDetails, setAssessDetails] = useState([])

  const [commentDetails, setCommentDetails] = useState({
    comments: '',
    appID: 0,
  })

  const [recommenderComments, setRecommenderComments] = useState({
    i_comments: '',
    e_comments: '',
    r_remarks: '',
    appID: 0,
  })

  const [activeTrade, setActiveTrade] = useState('')

  const users = useSelector((state) => state.users)

  const { userCredentials } = users

  const recommender = useSelector((state) => state.recommender)

  const {
    forRecommendationList,
    createIComment,
    createEComment,
    updateAppStatus,
    commentList,
    orderofPayment,
    createdOP,
  } = recommender

  const adminApplication = useSelector((state) => state.adminApplication)

  const { dateApplied } = adminApplication

  const isupervisor = useSelector((state) => state.isupervisor)

  const { listReport, penaltyDetail, retrievedInspectionDetails } = isupervisor

  const esupervisor = useSelector((state) => state.esupervisor)

  const { forRecommendation, evaluationList, recommendApplication } =
    esupervisor

  const inspection = useSelector((state) => state.inspection)

  const { existingReport, existingImageReport } = inspection

  // Evaluators

  const evaluator = useSelector((state) => state.evaluator)

  const { evaluationDetails } = evaluator

  const civilEvaluator = useSelector((state) => state.civilEvaluator)

  const { evaluationCivilDetails } = civilEvaluator

  const archEvaluator = useSelector((state) => state.archEvaluator)

  const { evaluationArchDetails } = archEvaluator

  const electricalEvaluator = useSelector((state) => state.electricalEvaluator)

  const { evaluationElectricalDetails } = electricalEvaluator

  const mechanicalEvaluator = useSelector((state) => state.mechanicalEvaluator)

  const { evaluationMechDetails } = mechanicalEvaluator

  const plumbingEvaluator = useSelector((state) => state.plumbingEvaluator)

  const { evaluationPlumbingDetails } = plumbingEvaluator

  // End Evaluators

  // Assessments

  const structuralAssessment = useSelector(
    (state) => state.structuralAssessment
  )

  const CivilAssessDetails = structuralAssessment.assessmentDetails

  const electronicsAssessment = useSelector(
    (state) => state.electronicsAssessment
  )

  const ElectronicsAssesDetails = electronicsAssessment.assessmentDetails

  const electricalAssessment = useSelector(
    (state) => state.electricalAssessment
  )

  const ElectricalAssessDetails = electricalAssessment.assessmentDetails

  const mechanicalAssessment = useSelector(
    (state) => state.mechanicalAssessment
  )

  const MechAssessDetails = mechanicalAssessment.assessmentDetails

  const plumbingAssessment = useSelector((state) => state.plumbingAssessment)

  const PlumAssessDetails = plumbingAssessment.assessmentDetails

  const accessoriesFee = useSelector((state) => state.accessoriesFeeAssessment)

  const { assessmentDetails } = accessoriesFee
  // End Assessments

  // End Declaration

  // Pagination Area Forms
  const [paginatedForms, setPaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  const pageSize = 5

  useEffect(() => {
    setPaginatedForms(_(_.reverse(state)).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [state])

  const pageCount = state ? Math.ceil(state.length / pageSize) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    const paginationForms = _(state).slice(startIndex).take(pageSize).value()
    setPaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Pagination Area Form

  // Function
  const handleViewEvaluation = (id, evaluatorID, trade) => {
    switch (trade) {
      case 'Electronics':
        dispatch(ElectronicsEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(ElectronicsAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Structural':
        dispatch(CivilEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(CivilAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Architectural':
        dispatch(ArchEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(ArchAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Mechanical':
        dispatch(MechEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(MechAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Electrical':
        dispatch(ElecEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(ElecAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Plumbing':
        dispatch(PlumEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(PlumAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      default:
        // console.log('NO Evaluation')
        break
    }
  }

  const handleSubmitComment = () => {
    if (orderofPayment.message[0].totalAmount !== null) {
      dispatch(
        updateApplicationStatus({
          id: payload.statusID,
          trade: null,
          status: commentDetails.status,
          remarks: payload.remarks,
          withEvaluation: payload.withEvaluation,
          withInspection: payload.withInspection,
          application_ID: payload.id,
          userID: payload.userID,
          dcID: userCredentials.id,
          iSupervisor: payload.iSupervisor,
          eSupervisor: payload.eSupervisor,
          comments: commentDetails.comments,
          email: payload.EmailAddress,
          mobileNo: payload.MobileNo,
        })
      )

      socket.emit('send_change_status', {
        message: {
          appID: payload.id,
          notif: 'Update Application Status',
        },
      })

      setModal({
        viewComments: false,
        recommendSuccess: true,
      })
    } else {
      window.alert('You must generate order of payment first')
    }
  }
  // End Function

  // UseEffect
  useEffect(() => {
    dispatch(retrieveForRecommendationList())
    socket.on('received_new_application', (data) => {
      // if (window.confirm(`${data.message} do you want to reload the page ?`)) {
      dispatch(retrieveForRecommendationList())
      // }
    })
  }, [dispatch, socket])

  useEffect(() => {
    if (forRecommendationList && forRecommendationList.message) {
      setState(() =>
        forRecommendationList.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.id === parseInt(searchTerm)
            )
          }
        })
      )
    }
    if (activeTrade !== '') {
      switch (activeTrade) {
        case 'Structural':
          if (evaluationCivilDetails && evaluationCivilDetails.message) {
            setEvalDetails(() => evaluationCivilDetails.message)
          }
          if (CivilAssessDetails && CivilAssessDetails.message) {
            setAssessDetails(() => CivilAssessDetails.message)
          }
          break
        case 'Electronics':
          if (evaluationDetails && evaluationDetails.message) {
            setEvalDetails(() => evaluationDetails.message)
          }
          if (ElectronicsAssesDetails && ElectronicsAssesDetails.message) {
            setAssessDetails(() => ElectronicsAssesDetails.message)
          }
          break
        case 'Architectural':
          if (evaluationArchDetails && evaluationArchDetails.message) {
            setEvalDetails(() => evaluationArchDetails.message)
          }
          if (assessmentDetails && assessmentDetails.message) {
            setAssessDetails(assessmentDetails.message)
          }
          break
        case 'Mechanical':
          if (evaluationMechDetails && evaluationMechDetails.message) {
            setEvalDetails(() => evaluationMechDetails.message)
          }
          if (MechAssessDetails && MechAssessDetails.message) {
            setAssessDetails(() => MechAssessDetails.message)
          }
          break
        case 'Electrical':
          if (
            evaluationElectricalDetails &&
            evaluationElectricalDetails.message
          ) {
            setEvalDetails(() => evaluationElectricalDetails.message)
          }
          if (ElectricalAssessDetails && ElectricalAssessDetails.message) {
            setAssessDetails(() => ElectricalAssessDetails.message)
          }
          break
        case 'Plumbing':
          if (evaluationPlumbingDetails && evaluationPlumbingDetails.message) {
            setEvalDetails(() => evaluationPlumbingDetails.message)
          }
          if (PlumAssessDetails && PlumAssessDetails.message) {
            setAssessDetails(() => PlumAssessDetails.message)
          }
          break
        default:
          // console.log('Empty')
          break
      }
    }
    if (orderofPayment && orderofPayment.message) {
      setGeneratedOP(() => ({
        ...generatedOP,
        subtotal:
          orderofPayment.message[0].LineAndGrade +
          orderofPayment.message[0].StructuralFees +
          orderofPayment.message[0].ArchitecturalFees +
          orderofPayment.message[0].AdditionalRenovation +
          orderofPayment.message[0].RoofDeck +
          orderofPayment.message[0].DemolitionPermitFees +
          orderofPayment.message[0].AncillaryStructure +
          orderofPayment.message[0].TombsCanopies +
          orderofPayment.message[0].PlumbingPermitFees +
          orderofPayment.message[0].ElectricalPermitFees +
          orderofPayment.message[0].MechanicalPermitFees +
          orderofPayment.message[0].FencingPermitFees +
          orderofPayment.message[0].Sidewalks +
          orderofPayment.message[0].PavedAreas +
          orderofPayment.message[0].StreetSidewalk +
          orderofPayment.message[0].CertificateOccupancy +
          orderofPayment.message[0].ChangeOccupancy +
          orderofPayment.message[0].CertificateOccupancy10 +
          orderofPayment.message[0].ElectronicsPermit +
          orderofPayment.message[0].AnnualInspection +
          orderofPayment.message[0].SignPermitFees +
          orderofPayment.message[0].Certification +
          orderofPayment.message[0].AdministrativeFive,
        subtotal1:
          orderofPayment.message[0].ComplaintFilingFee +
          orderofPayment.message[0].Others +
          300 +
          750,
      }))
    }
    if (updateAppStatus && updateAppStatus.message) {
      socket.emit('send_new_application', {
        message: 'Update Application Status',
      })
    }
  }, [
    forRecommendationList,
    searchTerm,
    evaluationCivilDetails,
    CivilAssessDetails,
    evaluationDetails,
    ElectronicsAssesDetails,
    evaluationArchDetails,
    assessmentDetails,
    evaluationMechDetails,
    MechAssessDetails,
    evaluationElectricalDetails,
    ElectricalAssessDetails,
    evaluationPlumbingDetails,
    PlumAssessDetails,
    orderofPayment,
    updateAppStatus,
  ])
  // End UseEffect

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {forRecommendationList && forRecommendationList.message && (
            <>
              <Form>
                <Row className='justify-content-end px-3 pt-5'>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              <Row className='my-4'>
                <Col md={12} style={{ background: '#FFF' }}>
                  <h5 className='pt-3 pb-2 mb-0'>For Recommendation List</h5>
                  <hr
                    className='mt-1 mb-3'
                    style={{
                      background: '#ffeb36',
                      paddingBottom: '2px',
                      width: '200px',
                    }}
                  />
                  <Table bordered hover responsive size='sm'>
                    <thead className='bg-info text-light'>
                      <tr>
                        <th>Application No.</th>
                        <th>Application</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Date Transferred</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedForms.map((x, index) => (
                        <tr key={index}>
                          <td className='text-center'>{x.id}</td>
                          <td>{x.ApplicationName}</td>
                          <td>{x.status}</td>
                          <td>{x.remarks}</td>
                          <td>
                            {moment(x.TimeStamp).format('MMMM D, YYYY h:mma')}
                          </td>
                          <td className='text-center'>
                            <Button
                              size='sm'
                              variant='outline-success'
                              onClick={() => {
                                dispatch(retrieveDateApplied({ id: x.id }))
                                dispatch(retrievePenalty({ id: x.id }))
                                setModal({
                                  viewManager: true,
                                })
                                setRecommenderComments({
                                  ...recommenderComments,
                                  r_remarks: x.RecommenderRemarks,
                                  appID: x.id,
                                })
                                setPayload(x)
                                dispatch(retrieveCommentList({ id: x.id }))
                                dispatch(retrieveOrderofPayment({ id: x.id }))
                              }}
                            >
                              <FaEllipsisH />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Pagination
                    size='sm'
                    className='d-flex justify-content-end mx-2'
                  >
                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {renderPageNumber}

                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </>
          )}

          {forRecommendationList && forRecommendationList.loading && (
            <Row className='py-4'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {forRecommendationList && forRecommendationList.error && (
            <Row className='py-4'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Application for Recommendation</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      {/* Application Details Modal */}
      <Modal
        size='lg'
        show={modal.viewManager}
        onHide={() => setModal({ viewManager: false })}
        centered
        backdrop='static'
      >
        {dateApplied && dateApplied.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Application Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <tbody>
                  <tr>
                    <td className='fw-bold'>Application No.:</td>
                    <td>{payload.id}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Application Name:</td>
                    <td>{payload.ApplicationName}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Mode of Application:</td>
                    <td>{payload.ModeofForms}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Application Status:</td>
                    <td>{payload.status}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Date Applied:</td>
                    <td>
                      {moment(dateApplied.message[0].DateApplied).format(
                        'MMMM D, YYYY h:mma'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Applicant Attached Link:</td>
                    <td>{payload.Link}</td>
                  </tr>
                  {/* <tr>
                    <td className='fw-bold'>
                      Document Controller Attached Link / Remarks:
                    </td>
                    <td>{payload.remarks}</td>
                  </tr> */}
                  <tr>
                    <td className='fw-bold'>Recommender Manager:</td>
                    <td>
                      <Button
                        size='sm'
                        type='submit'
                        variant='success'
                        onClick={() => {
                          setModal({
                            viewManager: false,
                            viewComments: true,
                          })
                          dispatch(retrieveCommentList({ id: payload.id }))
                          dispatch(retrieveOrderofPayment({ id: payload.id }))
                        }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Evaluation Viewer:</td>
                    <td>
                      <Button
                        size='sm'
                        type='submit'
                        variant='success'
                        onClick={() => {
                          dispatch(retrieveEvaluationList({ id: payload.id }))
                          setModal({
                            viewEvaluationList: true,
                            viewManager: false,
                          })
                        }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Inspection Viewer:</td>
                    <td>
                      <Button
                        size='sm'
                        type='submit'
                        variant='success'
                        onClick={() => {
                          dispatch(retrieveReportList({ id: payload.id }))
                          setModal({ viewReportList: true, viewManager: false })
                          if (penaltyDetail && penaltyDetail.message) {
                            setPenaltyInfo(penaltyDetail.message[0].penalty)
                          }
                        }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Generate Order of Payment:</td>
                    <td>
                      <Button
                        size='sm'
                        type='submit'
                        variant='success'
                        onClick={() => {
                          setModal({
                            viewManager: false,
                            viewOrderofPayment: true,
                          })

                          let tempPenaltyValue = 0

                          tempPenaltyValue =
                            generatedOP.subtotal *
                            orderofPayment.message[0].Penalty

                          setPenaltyValue(tempPenaltyValue)

                          setGeneratedOP({
                            ...generatedOP,
                            withPenalty:
                              generatedOP.subtotal + tempPenaltyValue,
                            totalAmount:
                              generatedOP.subtotal1 +
                              generatedOP.subtotal +
                              tempPenaltyValue,
                            grandTotal:
                              generatedOP.subtotal1 +
                              generatedOP.subtotal +
                              tempPenaltyValue +
                              orderofPayment.message[0].contractors_tax,
                          })

                          // setGeneratedOP({
                          //   ...generatedOP,
                          //   totalAmount:
                          //     generatedOP.subtotal * orderofPayment.message &&
                          //     orderofPayment.message[0].AdditionalRenovation !==
                          //       null
                          //       ? orderofPayment.message[0].Penalty
                          //       : 0 +
                          //         generatedOP.subtotal +
                          //         generatedOP.subtotal1,
                          //   withPenalty:
                          //     generatedOP.subtotal * orderofPayment.message &&
                          //     orderofPayment.message[0].AdditionalRenovation !==
                          //       null
                          //       ? orderofPayment.message[0].Penalty
                          //       : 0 + generatedOP.subtotal,
                          // })
                        }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewManager: false,
                  })
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        )}

        {dateApplied && dateApplied.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}

        {dateApplied && dateApplied.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}
      </Modal>
      {/* End Application Details Modal */}

      {/* List of Inspection Report Modal */}
      <Modal
        size='xl'
        show={modal.viewReportList}
        onHide={() => setModal({ viewReportList: false })}
        centered
        backdrop='static'
      >
        {listReport && listReport.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Inspection List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Date Scheduled</th>
                    <th>Inspection Date</th>
                    <th>Inspector</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {listReport.message.map((list) => (
                    <tr key={list.id}>
                      <td>{list.category}</td>
                      <td>
                        {moment(list.inspectionSched).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>
                        {moment(list.inspectionDate).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>{list.EmployeeName}</td>
                      <td className='text-center'>
                        <Button
                          variant='success'
                          disabled={list.inspectionDate !== null ? false : true}
                          onClick={() => {
                            setCommentDetails({
                              comments: list.comments,
                              appID: list.appID,
                            })
                            setModal({
                              viewReport: true,
                              viewReportList: false,
                            })
                            dispatch(
                              retrieveInspectionDetails({
                                inspectionID: list.inspectionID,
                                category: list.category,
                                userID: list.inspectID,
                              })
                            )
                            dispatch(
                              retrieveImageReport({
                                inspectionID: list.inspectionID,
                                category: list.category,
                                userID: list.inspectID,
                              })
                            )
                          }}
                          size='sm'
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />
              {/* <h5 className='mx-2'>Application Penalty</h5>
              <h4 className='mx-3'>
                {payload.penalty !== null
                  ? payload.penalty.toLocaleString()
                  : ''}
              </h4>
              <hr />
              <Form
                onSubmit={e => {
                  if (
                    window.confirm(
                      `Are you sure want to return recommendation? 
Note: once submitted you will see the application in the returned list`
                    )
                  ) {
                    e.preventDefault()
                    // setModal({ successModal: true })
                    dispatch(
                      insertIComment({
                        comments: recommenderComments.i_comments,
                        adminID: userCredentials.id,
                        id: payload.id,
                        userLevel: userCredentials.roles,
                      })
                    )
                    setModal({
                      iSuccessModal: true,
                    })
                  } else {
                    e.preventDefault()
                  }
                }}
              >
                <h5>Comment to the Inspector Supervisor</h5>
                <h6 className='mx-2 mb-3'>
                  <i>
                    (For the comment to the supervisor for the application,
                    kindly use this section.)
                  </i>
                </h6>
                <Row>
                  <Col xl='9'>
                    <Form.Control
                      type='text'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      size='sm'
                      name='icomments'
                      placeholder='Insert Comment here ...'
                      onChange={e =>
                        setRecommenderComments({
                          ...recommenderComments,
                          i_comments: e.target.value,
                        })
                      }
                      value={recommenderComments.i_comments}
                    />
                  </Col>
                  <Col xl='3'>
                    <Button size='sm' type='submit' variant='info'>
                      <FaClipboardCheck className='fs-3 px-0' /> Return
                      Recommendation
                    </Button>
                  </Col>
                </Row>
              </Form> */}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewReportList: false,
                    viewManager: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {listReport && listReport.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReportList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {listReport && listReport.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReportList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End List of Inspection Report Modal */}

      {/* Inspection Report */}
      <Modal
        size='xl'
        show={modal.viewReport}
        onHide={() => setModal({ viewReport: false })}
        centered
        backdrop='static'
      >
        {retrievedInspectionDetails && retrievedInspectionDetails.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Inspection Report
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Category</h5>
              <p className='mx-2'>
                <i>{retrievedInspectionDetails.message[0].category}</i>
              </p>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[0].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[0].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[1].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[1].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[2].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[2].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[3].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[3].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[4].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[4].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el.split('Rear_')}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[5].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[5].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el.split('Left_')}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[6].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[6].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>
                        {el.split('Right_')}
                      </td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    {createReport[7].headers.map((el, index) => (
                      <th key={index} className='fw-bold'>
                        {el}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createReport[7].categories.map((el, index) => (
                    <tr key={index}>
                      <td className='align-middle py-0'>{el}</td>
                      <td className='align-middle py-0'>
                        {retrievedInspectionDetails.message.find(
                          (x) => x.sub_category === el
                        ).existing === 1
                          ? 'YES'
                          : 'NO'}
                      </td>
                      <td className='align-middle py-0'>
                        {
                          retrievedInspectionDetails.message.find(
                            (x) => x.sub_category === el
                          ).remarks
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              <h6 className='mt-2'>Image Attachments</h6>
              {existingImageReport && existingImageReport.message && (
                <div>
                  {existingImageReport.message.map((image, index) => (
                    <Row key={index}>
                      <img
                        key={index}
                        className='my-2'
                        src={`data:image/png;base64,${image.URL}`}
                      />
                    </Row>
                  ))}
                </div>
              )}
              {existingImageReport && existingImageReport.loading && (
                <div className='text-center pt-3'>
                  <Spinner animation='border' />
                  <h3>Loading...</h3>
                </div>
              )}
              {existingImageReport && existingImageReport.error && (
                <h5>No Image/s Uploaded Yet</h5>
              )}
              <Form
              // onSubmit={(e) => {
              //   if (
              //     window.confirm(
              //       'Are you sure want to submit a comment for this inspection?'
              //     )
              //   ) {
              //     e.preventDefault()
              //     dispatch(
              //       insertInspectionComments({
              //         comment: commentDetails.comments,
              //         id: commentDetails.appID,
              //         userID: userCredentials.id,
              //       })
              //     )
              //     setModal({
              //       viewReport: false,
              //     })
              //   }
              // }}
              >
                {/* <h5>Supervisor Comment</h5>
                <h6 className='mx-2'>
                  <i>
                    (For your comment to the inspector of this application,
                    Kindly use this section.)
                  </i>
                </h6>
                <Row className='mx-2'>
                  <Col xl='9'>
                    <Form.Control
                      type='text'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      size='sm'
                      name='comments'
                      placeholder='Insert comment here ...'
                      onChange={(e) =>
                        setCommentDetails({
                          ...commentDetails,
                          comments: e.target.value,
                        })
                      }
                      value={commentDetails.comments}
                      disabled={true}
                    />
                  </Col> */}
                {/* <Col xl='3'>
                    <Button size='sm' type='submit' variant='info'>
                      Submit
                    </Button>
                  </Col> */}
                {/* </Row> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant='info'
                onClick={() => {
                  setModal({
                    viewEvaluation: false,
                    viewAssessment: true,
                  })
                }}
              >
                Next
              </Button> */}
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewReport: false,
                    viewReportList: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {retrievedInspectionDetails && retrievedInspectionDetails.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReport: false,
                  viewReportList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {retrievedInspectionDetails && retrievedInspectionDetails.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReport: false,
                  viewReportList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Inspection Report */}

      {/* Comment  */}
      <Modal
        size='lg'
        show={modal.viewComments}
        onHide={() => setModal({ viewComments: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            Comment Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className='pb-3'>
              {/* <Form.Group as={Col} xl='6' className='my-1'>
                <Form.Control
                  type='text'
                  disabled
                  placeholder='Receiver Name'
                  size='sm'
                  value={commentDetails.commentToName}
                />
              </Form.Group> */}
              <Form.Group as={Col} xl='6' className='my-1'>
                <Form.Control
                  as='select'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  size='sm'
                  onChange={(e) => {
                    e.target.value === 'FOR INSPECTION AND EVALUATION'
                      ? setCommentDetails({
                          ...commentDetails,
                          commentTo: '',
                          commentToName: '',
                          appID: 0,
                          status: e.target.value,
                        })
                      : setCommentDetails({
                          ...commentDetails,
                          commentTo: '',
                          commentToName: 'HIGHER POSITION',
                          appID: 0,
                          status: e.target.value,
                        })
                  }}
                  value={commentDetails.status}
                >
                  <option>Choose task here ...</option>
                  <option value='FOR APPROVAL'>FOR APPROVAL</option>
                  <option value='FOR INSPECTION AND EVALUATION'>
                    FOR INSPECTION AND EVALUATION
                  </option>
                </Form.Control>
              </Form.Group>
            </Row>

            <Row className='pb-2'>
              <Col xl='10'>
                <Form.Control
                  as='textarea'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  size='sm'
                  name='r_remarks'
                  required
                  placeholder='Insert comment here ...'
                  onChange={(e) => {
                    setCommentDetails({
                      ...commentDetails,
                      comments: e.target.value,
                    })
                  }}
                />
              </Col>

              <Col className='d-flex align-items-end pt-2' xl='2'>
                <Button
                  onClick={handleSubmitComment}
                  variant='success'
                  size='sm'
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
          <hr />
          <CommentsCard
            state={commentList && commentList.message && commentList.message}
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button
            variant='danger'
            onClick={() => setModal({ viewManager: true, viewComments: false })}
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Comment  */}

      {/*Inspector Comment Success Modal */}
      <SuccessModal
        show={modal.iSuccessModal}
        onHide={() => {
          setModal({ iSuccessModal: false })
          // window.location.reload(true)
        }}
        transLoading={createIComment && createIComment.loading}
        success={createIComment && createIComment.message}
        error={createIComment && createIComment.error}
      />
      {/* End Success Modal */}

      {/* Evaluation List */}
      <Modal
        size='xl'
        show={modal.viewEvaluationList}
        onHide={() => setModal({ viewEvaluationList: false })}
        centered
        backdrop='static'
      >
        {evaluationList && evaluationList.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Evaluation and Assessment List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Trade</th>
                    <th>Date Evaluated</th>
                    <th>Date Assessed</th>
                    <th>Evaluator</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {evaluationList.message.map((list) => (
                    <tr key={list.id}>
                      <td className='fw-bold'>{list.trade}</td>
                      <td>
                        {moment(list.withEvaluation).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>
                        {moment(list.withAssessment).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>{list.EmployeeName}</td>
                      <td className='text-center'>
                        <Button
                          variant='success'
                          disabled={
                            list.withEvaluation && list.withAssessment !== null
                              ? false
                              : true
                          }
                          onClick={() => {
                            setCommentDetails({
                              comments: list.comments,
                              appID: list.id,
                            })
                            handleViewEvaluation(
                              list.id,
                              list.evaluatorID,
                              list.trade
                            )
                          }}
                          size='sm'
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* <Form
                onSubmit={e => {
                  if (
                    window.confirm(
                      `Are you sure want to return recommendation? 
Note: once submitted you will see the application in the returned list`
                    )
                  ) {
                    e.preventDefault()
                    // setModal({ successModal: true })
                    dispatch(
                      insertEComment({
                        comments: recommenderComments.e_comments,
                        adminID: userCredentials.id,
                        id: payload.id,
                        userLevel: userCredentials.roles,
                      })
                    )
                    setModal({
                      eSuccessModal: true,
                    })
                  } else {
                    e.preventDefault()
                  }
                }}
              >
                <h5>Comment to the Evaluator Supervisor</h5>
                <h6 className='mx-2 mb-3'>
                  <i>
                    (For the comment to the supervisor for the application,
                    kindly use this section.)
                  </i>
                </h6>
                <Row>
                  <Col xl='9'>
                    <Form.Control
                      type='text'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      size='sm'
                      name='ecomments'
                      placeholder='Insert Comment here ...'
                      onChange={e =>
                        setRecommenderComments({
                          ...recommenderComments,
                          e_comments: e.target.value,
                        })
                      }
                      value={recommenderComments.e_comments}
                    />
                  </Col>
                  <Col xl='3'>
                    <Button size='sm' type='submit' variant='info'>
                      <FaClipboardCheck className='fs-3 px-0' /> Return
                      Recommendation
                    </Button>
                  </Col>
                </Row>
              </Form> */}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewEvaluationList: false,
                    viewManager: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {evaluationList && evaluationList.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluationList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {evaluationList && evaluationList.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluationList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Evaluation List */}

      {/* Evaluation Info */}
      <Modal
        size='xl'
        show={modal.viewEvaluation}
        onHide={() => setModal({ viewEvaluation: false })}
        centered
        backdrop='static'
      >
        {evalDetails.length > 0 && activeTrade !== '' && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Evaluation and Assessment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>SCOPE OF WORK</h5>
              <p className='mx-2'>
                <i>{evalDetails.length > 0 && evalDetails[0].scope}</i>
              </p>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Code ID</th>
                    <th>JMC AND CODE REGULATIONS</th>
                    <th>COMPLIANCE (MARK CHECK / STATUS)</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {evalDetails.map((list) => (
                    <tr key={list.id}>
                      <td>{list.code_id}</td>
                      <td>{list.code_name}</td>
                      <td className='text-center'>
                        {list.compliance === 1 ? 'YES' : 'NO'}
                      </td>
                      <td>{list.id_remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <h5>Assessment</h5>
              <p className='mx-2'>
                <i className='fs-5'>Total Amount : </i>
                <i className='text-danger fs-3 fw-bold'>
                  ₱{' '}
                  {assessDetails.length > 0 &&
                    assessDetails[0].grandTotal.toLocaleString()}
                </i>
              </p>
              <hr />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewEvaluation: false,
                    viewEvaluationList: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {evalDetails.length <= 0 && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluation: false,
                  viewEvaluationList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Evaluation Info */}

      {/* Order of Payment */}
      <Modal
        size='xl'
        show={modal.viewOrderofPayment}
        onHide={() => setModal({ viewOrderofPayment: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            ORDER OF PAYMENT DETAILS
          </Modal.Title>
        </Modal.Header>
        {orderofPayment && orderofPayment.message && (
          <Modal.Body>
            <Row>
              <Col xl='12' className='text-center'>
                <p className='m-0 fs-6'>Metropolitan Manila Authority</p>
                <p className='m-0 fs-6'>
                  Action Center for Infrastructure Development
                </p>
                <h6 className='m-0 fs-6'>OFFICE OF THE BUILDING OFFICIAL</h6>
                <p className='m-0 fs-6'>City of Las Piñas</p>
                <p className='m-0 fs-6'>Metro Manila</p>
              </Col>
            </Row>
            <Row>
              <Col xl='6'>
                <div className='d-flex'>
                  <h6>Owner/Firm/Applicant: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {orderofPayment.message[0].Owner}
                  </p>
                </div>
                <div className='d-flex'>
                  <h6>Project: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {orderofPayment.message[0].category}
                  </p>
                </div>
                <div className='d-flex'>
                  <h6>Location: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {orderofPayment.message[0].Project_Location}
                  </p>
                </div>
              </Col>
              <Col xl='6'>
                <div className='d-flex justify-content-end'>
                  <h6 className='m-0 align-middle'>DATE: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {moment(orderofPayment.message[0].timeStamp).format(
                      'MMMM D, YYYY h:mma'
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <h5 className='text-center'>ORDER OF PAYMENT</h5>
            <p className='fs-6'>TO: CITY/MUNICIPAL TREASURER/CASHIER</p>
            <p className='fs-6'>
              Please collect the amoun specified below as perscribed in the
              implementing Rules and Regulations of the National Building Code
              (PD-1096).
            </p>
            <Container className='px-5'>
              <Table>
                <tbody>
                  <tr>
                    <td className='p-1 align-middle'>001 Line and Grade</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].LineAndGrade !== null &&
                        orderofPayment.message[0].LineAndGrade.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      002 Building Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {(
                        orderofPayment.message[0].StructuralFees +
                        orderofPayment.message[0].ArchitecturalFees
                      ).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>Additional/Renovation</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].AdditionalRenovation !==
                        null &&
                        orderofPayment.message[0].AdditionalRenovation.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>Roof Deck</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].RoofDeck !== null &&
                        orderofPayment.message[0].RoofDeck.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      003 Demolition Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].DemolitionPermitFees !==
                        null &&
                        orderofPayment.message[0].DemolitionPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      004 Ancillary Structure
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].AncillaryStructure !== null &&
                        orderofPayment.message[0].AncillaryStructure.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      005 Construction of Tombs/Canopies
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].TombsCanopies !== null &&
                        orderofPayment.message[0].TombsCanopies.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      006 Plumbing Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].PlumbingPermitFees !== null &&
                        orderofPayment.message[0].PlumbingPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      007 Electrical Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].ElectricalPermitFees !==
                        null &&
                        orderofPayment.message[0].ElectricalPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      008 Mechanical Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].MechanicalPermitFees !==
                        null &&
                        orderofPayment.message[0].MechanicalPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      009 Fencing Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].FencingPermitFees !== null &&
                        orderofPayment.message[0].FencingPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      010 Construction of Sidewalks
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].Sidewalks !== null &&
                        orderofPayment.message[0].Sidewalks.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>011 Paved Areas</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].PavedAreas !== null &&
                        orderofPayment.message[0].PavedAreas.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      012 Use of Street and Sidewalks
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].StreetSidewalk !== null &&
                        orderofPayment.message[0].StreetSidewalk.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      013 Certificate of Use/Occupancy
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].CertificateOccupancy !==
                        null &&
                        orderofPayment.message[0].CertificateOccupancy.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      014 Change in Use/Occupancy
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].ChangeOccupancy !== null &&
                        orderofPayment.message[0].ChangeOccupancy.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      015 Certificate of Occupancy prior to 10
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].CertificateOccupancy10 !==
                        null &&
                        orderofPayment.message[0].CertificateOccupancy10.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>016 Electronics Permit</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].ElectronicsPermit !== null &&
                        orderofPayment.message[0].ElectronicsPermit.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>017 Annual Inspection</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].AnnualInspection !== null &&
                        orderofPayment.message[0].AnnualInspection.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>018 Sign Permit Fees</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].SignPermitFees !== null &&
                        orderofPayment.message[0].SignPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>019 Certification</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].Certification !== null &&
                        orderofPayment.message[0].Certification.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>020 Penalty/Surcharge</td>
                    <td className='p-1 align-middle text-end'>
                      {penaltyValue.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      021 Administrative Fine
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].AdministrativeFive !== null &&
                        orderofPayment.message[0].AdministrativeFive.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      SUB-TOTAL
                    </td>
                    <td className='p-1 align-middle text-end text-danger'>
                      {Object.keys(generatedOP).length > 0 &&
                        generatedOP.withPenalty &&
                        generatedOP.withPenalty.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <p className='fs-6 pt-4 '>
              Please collect the amount specified below as perscribed in the
              approved City Ordinance No. 1373 - 16 series of 2016 Las Piñas
              City.
            </p>
            <h5 className='text-center mt-4'>BUILDING PERMIT</h5>
            <Container className='px-5'>
              <Table>
                <tbody>
                  <tr>
                    <td className='p-1 align-middle'>001 Filling Fee</td>
                    <td className='p-1 align-middle text-end'>300</td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>002 Processing Fees</td>
                    <td className='p-1 align-middle text-end'>750</td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      003 Complaint Filling Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].ComplaintFilingFee}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>004 Others (Specify)</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].Others}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      SUB-TOTAL
                    </td>
                    <td className='p-1 align-middle text-end text-danger'>
                      {Object.keys(generatedOP).length > 0 &&
                        generatedOP.subtotal1.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      OCE TOTAL AMOUNT
                    </td>
                    <td className='p-1 align-middle fw-bold text-end'>
                      {Object.keys(generatedOP).length > 0 &&
                        generatedOP.totalAmount &&
                        generatedOP.totalAmount.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <p className='fs-6'>
              Please collect the amoun specified below as perscribed.
            </p>
            <h5 className='text-center mt-4'>OFFICE OF THE CITY TREASURER</h5>
            <Container className='px-5'>
              <Table>
                <tbody>
                  <tr>
                    <td className='p-1 align-middle'>001 Contractor's Tax</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].contractors_tax !== null &&
                        orderofPayment.message[0].contractors_tax.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      SUB-TOTAL
                    </td>
                    <td className='p-1 align-middle text-end text-danger'>
                      {orderofPayment.message[0].contractors_tax !== null &&
                        orderofPayment.message[0].contractors_tax.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <hr />
            <h5 className='fs-6 mx-5 text-center'>Summary</h5>
            <Container className='px-5'>
              <Table>
                <tbody>
                  <tr>
                    <td className='p-1 align-middle'>001 Building Permit</td>
                    <td className='p-1 align-middle text-end'>
                      {Object.keys(generatedOP).length > 0 &&
                        generatedOP.subtotal &&
                        generatedOP.subtotal.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      002 Processing Fee/Filing Fee
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {Object.keys(generatedOP).length > 0 &&
                        generatedOP.subtotal1 &&
                        generatedOP.subtotal1.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>003 Contractor's Tax</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message[0].contractors_tax !== null &&
                        orderofPayment.message[0].contractors_tax.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      GRAND TOTAL AMOUNT
                    </td>
                    <td className='p-1 align-middle text-end fw-bold text-danger'>
                      {Object.keys(generatedOP).length > 0 &&
                        generatedOP.grandTotal &&
                        generatedOP.grandTotal.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Modal.Body>
        )}
        {orderofPayment && orderofPayment.error && (
          <h5 className='text-center py-3'>
            No Order of Payment Generated, Kindly contact your service provider.
          </h5>
        )}
        <Modal.Footer>
          {orderofPayment && orderofPayment.message && (
            <Button
              variant='success'
              onClick={() => {
                dispatch(
                  insertOrderofPayment({
                    subtotal: generatedOP.subtotal + penaltyValue,
                    subtotal1: generatedOP.subtotal1,
                    totalAmount: generatedOP.totalAmount,
                    orderofPaymentID:
                      orderofPayment.message && orderofPayment.message[0].id,
                    id: payload.id,
                    recommenderID: userCredentials.id,
                    penaltyValue: penaltyValue,
                    grandTotal: generatedOP.grandTotal,
                  })
                )
                setModal({
                  viewOrderofPayment: false,
                  viewSuccessOP: true,
                })
              }}
            >
              SAVE
            </Button>
          )}

          {orderofPayment && orderofPayment.message && (
            <PDFDownloadLink
              document={
                <RecommenderOrderPay
                  info={
                    orderofPayment &&
                    orderofPayment.message &&
                    orderofPayment.message
                  }
                  penaltyValue={penaltyValue}
                  generatedOP={generatedOP}
                />
              }
              fileName='OrderOfPayment.pdf'
            >
              {({ loading }) =>
                loading ? (
                  'Loading document...'
                ) : (
                  <Button variant='info'>PRINT</Button>
                )
              }
            </PDFDownloadLink>
          )}

          <Button
            variant='danger'
            onClick={() =>
              setModal({ viewManager: true, viewOrderofPayment: false })
            }
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Order of Payment */}

      {/*Evaluator Comment Success Modal */}
      <SuccessModal
        show={modal.eSuccessModal}
        onHide={() => {
          setModal({ eSuccessModal: false })
          // window.location.reload(true)
        }}
        transLoading={createEComment && createEComment.loading}
        success={createEComment && createEComment.message}
        error={createEComment && createEComment.error}
      />
      {/* End Success Modal */}

      {/*Recommend Application Success Modal */}
      <SuccessModal
        show={modal.recommendSuccess}
        onHide={() => {
          setModal({ recommendSuccess: false })
          // window.location.reload(true)
        }}
        transLoading={updateAppStatus && updateAppStatus.loading}
        success={updateAppStatus && updateAppStatus.message}
        error={updateAppStatus && updateAppStatus.error}
      />
      {/* End Recommend Application Success Modal */}

      {/*Generate Order of Payment Success Modal */}
      <SuccessModal
        show={modal.viewSuccessOP}
        onHide={() => {
          setModal({ viewSuccessOP: false, viewManager: true })
          // window.location.reload(true)
        }}
        transLoading={createdOP && createdOP.loading}
        success={createdOP && createdOP.message}
        error={createdOP && createdOP.error}
      />
      {/* End Generate Order of Payment Success Modal */}
    </UserMain>
  )
}

export default withRouter(ForRecommendationListScreen)
