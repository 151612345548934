export const CREATE_STRUCTURAL_ASSESSMENT_REQ =
  'CREATE_STRUCTURAL_ASSESSMENT_REQ'
export const CREATE_STRUCTURAL_ASSESSMENT_SUCCESS =
  'CREATE_STRUCTURAL_ASSESSMENT_SUCCESS'
export const CREATE_STRUCTURAL_ASSESSMENT_FAILED =
  'CREATE_STRUCTURAL_ASSESSMENT_FAILED'

export const UPDATE_STRUCTURAL_ASSESSMENT_REQ =
  'UPDATE_STRUCTURAL_ASSESSMENT_REQ'
export const UPDATE_STRUCTURAL_ASSESSMENT_SUCCESS =
  'UPDATE_STRUCTURAL_ASSESSMENT_SUCCESS'
export const UPDATE_STRUCTURAL_ASSESSMENT_FAILED =
  'UPDATE_STRUCTURAL_ASSESSMENT_FAILED'

export const GET_STRUCTURAL_ASSESSMENT_REQ = 'GET_STRUCTURAL_ASSESSMENT_REQ'
export const GET_STRUCTURAL_ASSESSMENT_SUCCESS =
  'GET_STRUCTURAL_ASSESSMENT_SUCCESS'
export const GET_STRUCTURAL_ASSESSMENT_FAILED =
  'GET_STRUCTURAL_ASSESSMENT_FAILED'
