import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap'
import { FaCheckCircle, FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

// Data
import { provinces } from '../../data/provinces'

// Actions
import {
  insertApplicationInformation,
  updateApplicationInformation,
} from '../redux/actions/DocumentControllers/documentControllerActions'

import { retrieveRegion } from '../redux/actions/userProfileAction'

// Layout
import SuccessModal from './SuccessModal'
import Loading from './Loading'
import { formatCurrency } from '../helpers/formatCurrency'

const ApplicationInformation = (props) => {
  const dispatch = useDispatch()

  const [appInfo, setAppInfo] = useState({
    names: [],
    Lot: '',
    Blk: '',
    Street: '',
    Area: '',
    Barangay: '',
    Exempted: '',
    id: '',
    dcID: '',
    scopeOfWork: '',
    sw_specify: '',
    useType: '',
    noOfStorey: '',
    floorArea: '',
    computeAs: '',
    noOfUnits: '',
    scheduleOfConstructionStart: '',
    scheduleOfConstructionEnd: '',
    contractor: '',
    estimateCost: 0,
    ec_building: 0,
    ec_electrical: 0,
    ec_plumbing: 0,
    ec_mechanical: 0,
    ec_others: 0,
    StreetLI: '',
    AreaLI: '',
    BarangayLI: '',
    TCTNoLI: '',
    TaxDecNoLI: '',
    LotOwner: '',
    Address: '',
    ResCert: '',
    DateIssued: '',
    PlaceIssued: '',
    Barangays: [],
    ownerRegion: '',
    ownerCity: '',
    ownerBarangay: '',
    ownerStreet: '',
  })

  const [address, setAddress] = useState({
    presentCities: [],
    permanentCities: [],
    presentBarangays: [],
    permanentBarangays: [],
  })

  const [modal, setModal] = useState({
    insertSuccess: false,
    updateSuccess: false,
  })

  const userProfile = useSelector((state) => state.userProfile)
  const { retrievedRegion } = userProfile

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const dc = useSelector((state) => state.documentControllerReducer)

  const {
    insertedApplicationInfo,
    updatedApplicationInfo,
    applicationInformation,
  } = dc

  // Functions
  const editHandleChange = (e) => {
    setAppInfo({
      ...appInfo,
      [e.target.name]: e.target.value,
    })
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    if (applicationInformation && applicationInformation.message) {
      if (
        window.confirm('Are you sure you want to update status of application?')
      ) {
        dispatch(updateApplicationInformation(appInfo))
        setModal({
          updateSuccess: true,
        })
        setAppInfo({
          names: [],
          Street: '',
          Area: '',
          Barangay: '',
          Exempted: '',
          id: '',
          dcID: '',
          scopeOfWork: '',
          sw_specify: '',
          useType: '',
          noOfStorey: '',
          floorArea: '',
          computeAs: '',
          noOfUnits: '',
          scheduleOfConstructionStart: '',
          scheduleOfConstructionEnd: '',
          contractor: '',
          estimateCost: 0,
          ec_building: 0,
          ec_electrical: 0,
          ec_plumbing: 0,
          ec_mechanical: 0,
          ec_others: 0,
          StreetLI: '',
          AreaLI: '',
          BarangayLI: '',
          TCTNoLI: '',
          TaxDecNoLI: '',
          LotOwner: '',
          Address: '',
          ResCert: '',
          DateIssued: '',
          PlaceIssued: '',
          Barangays: [],
          ownerRegion: '',
          ownerCity: '',
          ownerBarangay: '',
          ownerStreet: '',
        })
        props.onClose()
      }
    } else if (applicationInformation && applicationInformation.error) {
      if (
        window.confirm('Are you sure you want to insert status of application?')
      ) {
        dispatch(insertApplicationInformation(appInfo))
        setModal({
          insertSuccess: true,
        })
        setAppInfo({
          names: [],
          Street: '',
          Area: '',
          Barangay: '',
          Exempted: '',
          id: '',
          dcID: '',
          scopeOfWork: '',
          sw_specify: '',
          useType: '',
          noOfStorey: '',
          floorArea: '',
          computeAs: '',
          noOfUnits: '',
          scheduleOfConstructionStart: '',
          scheduleOfConstructionEnd: '',
          contractor: '',
          estimateCost: 0,
          ec_building: 0,
          ec_electrical: 0,
          ec_plumbing: 0,
          ec_mechanical: 0,
          ec_others: 0,
          StreetLI: '',
          AreaLI: '',
          BarangayLI: '',
          TCTNoLI: '',
          TaxDecNoLI: '',
          LotOwner: '',
          Address: '',
          ResCert: '',
          DateIssued: '',
          PlaceIssued: '',
          Barangays: [],
          ownerRegion: '',
          ownerCity: '',
          ownerBarangay: '',
          ownerStreet: '',
        })
        props.onClose()
      }
    }
  }

  // End Function

  //   useEffect
  useEffect(() => {
    dispatch(retrieveRegion({ extensions: '/regions' }))
  }, [dispatch])

  useEffect(() => {
    if (applicationInformation && applicationInformation.message) {
      setAppInfo((info) => ({
        ...info,
        names: applicationInformation.message.map((x) => ({
          lastName: x.lastName,
          firstName: x.firstName,
          middleName: x.middleName,
        })),
        Street: applicationInformation.message[0].ConstStreet,
        Area: applicationInformation.message[0].ConstArea,
        Barangay: applicationInformation.message[0].ConstBarangay,
        Exempted: applicationInformation.message[0].Exempted,
        scopeOfWork: applicationInformation.message[0].scopeOfWork,
        sw_specify: applicationInformation.message[0].sw_specify,
        useType: applicationInformation.message[0].useType,
        noOfStorey: applicationInformation.message[0].noOfStoreys,
        floorArea: applicationInformation.message[0].floorArea,
        computeAs: applicationInformation.message[0].computeAs,
        noOfUnits: applicationInformation.message[0].noOfUnits,
        scheduleOfConstructionStart:
          applicationInformation.message[0].scheduleOfConstructionStart,
        scheduleOfConstructionEnd:
          applicationInformation.message[0].scheduleOfConstructionEnd,
        contractor:
          applicationInformation.message[0].contractor === null
            ? ''
            : applicationInformation.message[0].contractor,
        estimateCost: applicationInformation.message[0].estimateCost,
        ec_building: applicationInformation.message[0].ec_building,
        ec_electrical: applicationInformation.message[0].ec_electrical,
        ec_plumbing: applicationInformation.message[0].ec_plumbing,
        ec_mechanical: applicationInformation.message[0].ec_mechanical,
        ec_others: applicationInformation.message[0].ec_others,
        Blk: applicationInformation.message[0].Blk,
        Lot: applicationInformation.message[0].Lot,
        // rc_place: applicationInformation.message[0].rc_place,
        TCTNoLI: applicationInformation.message[0].TCTNo,
        TaxDecNoLI: applicationInformation.message[0].TaxDecNo,
        LotOwner: applicationInformation.message[0].LotOwner,
        Address: applicationInformation.message[0].Address,
        ResCert: applicationInformation.message[0].ResCert,
        DateIssued: applicationInformation.message[0].DateIssued,
        PlaceIssued: applicationInformation.message[0].PlaceIssued,
        constructionID: applicationInformation.message[0].constructionID,
        siteLotID: applicationInformation.message[0].siteLotID,
        scopeID: applicationInformation.message[0].scopeID,
        id: props.payload.id,
        dcID: userCredentials.id,
        Barangays: provinces[0].cities[0].barangays,
        ownerRegion: applicationInformation.message[0].ownerRegion,
        ownerCity: applicationInformation.message[0].ownerCity,
        ownerBarangay: applicationInformation.message[0].ownerBarangay,
        ownerStreet: applicationInformation.message[0].ownerStreet,
      }))
    } else {
      setAppInfo((info) => ({
        ...info,
        id: props.payload.id,
        dcID: userCredentials.id,
        names: [{ lastName: '', firstName: '', middleName: '' }],
        Barangays: provinces[0].cities[0].barangays,
      }))
    }
  }, [applicationInformation, userCredentials])
  // End useEffect

  // console.log(formatCurrency(appInfo.ec_building))

  return (
    <>
      <Modal
        size='xl'
        show={props.show}
        onHide={props.onHide}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            Application Information
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleOnSubmit}>
          {((applicationInformation && applicationInformation.message) ||
            (applicationInformation && applicationInformation.error)) && (
            <Modal.Body>
              {appInfo.names.map((info, index) => (
                <Row key={index}>
                  <Form.Group as={Col} xl='4'>
                    <Form.Label>
                      Last Name <i className='text-danger'>*</i>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      value={info.lastName}
                      name='lastName'
                      onChange={(e) => {
                        let response = appInfo.names
                        response[index].lastName = e.target.value
                        setAppInfo({
                          ...appInfo,
                          names: response,
                        })
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl='4'>
                    <Form.Label>
                      First Name <i className='text-danger'>*</i>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      value={info.firstName}
                      name='firstName'
                      onChange={(e) => {
                        let response = appInfo.names
                        response[index].firstName = e.target.value
                        setAppInfo({
                          ...appInfo,
                          names: response,
                        })
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl='4'>
                    <Form.Label>
                      Middle Name <i className='text-danger'>*</i>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      name='middleName'
                      value={info.middleName}
                      onChange={(e) => {
                        let response = appInfo.names
                        response[index].middleName = e.target.value
                        setAppInfo({
                          ...appInfo,
                          names: response,
                        })
                      }}
                      required
                    />
                  </Form.Group>
                </Row>
              ))}

              {/* Owner Address */}
              <h6 className='my-2'>Owner's Address</h6>
              <hr className='m-0' style={{ width: '150px', color: 'red' }} />
              <Row>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Region <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    size='sm'
                    as='select'
                    value={appInfo.ownerRegion}
                    name='ownerRegion'
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setAppInfo({
                            ...appInfo,
                            ownerRegion: e.target.value,
                          })
                        : setAppInfo({
                            ...appInfo,
                            ownerRegion: '',
                            ownerCity: '',
                            ownerBarangay: '',
                            ownerStreet: '',
                          })

                      const region =
                        retrievedRegion &&
                        retrievedRegion.message &&
                        retrievedRegion.message.find(
                          (region) => region.regionName === e.target.value
                        )

                      if (region !== undefined) {
                        axios({
                          method: 'GET',
                          url: `${process.env.REACT_APP_API_URL}/address`,
                          params: {
                            extensions: `/regions/${region.code}/cities/`,
                          },
                        }).then((res) => {
                          setAddress({
                            ...address,
                            presentCities: res.data,
                          })
                        })
                      }
                    }}
                  >
                    <option value=''>Choose Region here ...</option>
                    {retrievedRegion &&
                      retrievedRegion.message &&
                      retrievedRegion.message.map((e, index) => (
                        <option key={index} value={e.regionName}>
                          {e.regionName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    City <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    size='sm'
                    value={appInfo.ownerCity}
                    name='ownerCity'
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setAppInfo({
                            ...appInfo,
                            ownerCity: e.target.value,
                            ownerBarangay: '',
                          })
                        : setAppInfo({
                            ...appInfo,
                            ownerCity: '',
                            ownerBarangay: '',
                            ownerStreet: '',
                          })

                      const city =
                        address.presentCities.length !== 0 &&
                        address.presentCities.find(
                          (cities) => cities.name === e.target.value
                        )

                      if (city !== undefined) {
                        axios({
                          method: 'GET',
                          url: `${process.env.REACT_APP_API_URL}/address`,
                          params: {
                            extensions: `/cities/${city.code}/barangays/`,
                          },
                        }).then((res) => {
                          setAddress({
                            ...address,
                            presentBarangays: res.data,
                          })
                        })
                      }
                    }}
                    required
                  >
                    {applicationInformation.message && (
                      <option
                        value={applicationInformation.message[0].ownerCity}
                      >
                        {applicationInformation.message[0].ownerCity}
                      </option>
                    )}
                    {!applicationInformation.message && (
                      <option value=''>Choose City here ...</option>
                    )}
                    {address.presentCities.length !== 0 &&
                      address.presentCities.map((e, index) => (
                        <option key={index} value={e.name}>
                          {e.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Barangay <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    size='sm'
                    value={appInfo.ownerBarangay}
                    name='ownerBarangay'
                    onChange={editHandleChange}
                    required
                  >
                    {applicationInformation.message && (
                      <option
                        value={applicationInformation.message[0].ownerBarangay}
                      >
                        {applicationInformation.message[0].ownerBarangay}
                      </option>
                    )}
                    {!applicationInformation.message && (
                      <option value=''>Choose Barangay here ...</option>
                    )}
                    {address.presentBarangays.length !== 0 &&
                      address.presentBarangays.map((element, index) => (
                        <option key={index} value={element.name}>
                          {element.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Street & Area <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.ownerStreet}
                    name='ownerStreet'
                    onChange={editHandleChange}
                    required
                    placeholder='input street and area here ...'
                  />
                </Form.Group>
              </Row>
              {/* End Owner Address */}

              <div className='text-end my-2'>
                <Button
                  variant='info'
                  size='sm'
                  className='mx-1'
                  onClick={() =>
                    setAppInfo({
                      ...appInfo,
                      names: [
                        ...appInfo.names,
                        { lastName: '', firstName: '', middleName: '' },
                      ],
                    })
                  }
                >
                  <FaPlusCircle />
                </Button>
                <Button
                  variant='danger'
                  size='sm'
                  className='mx-1'
                  onClick={() => {
                    let response = appInfo.names
                    if (appInfo.names.length > 1) {
                      response.pop(response.length - 1)
                    }
                    setAppInfo({
                      ...appInfo,
                      names: response,
                    })
                  }}
                >
                  <FaMinusCircle />
                </Button>
              </div>
              <hr className='m-0' />

              {/* Construction Site */}
              <h6 className='my-2'>Construction Site</h6>
              <hr className='m-0' style={{ width: '150px', color: 'red' }} />
              <Row>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Lot <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.Lot}
                    name='Lot'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Blk <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.Blk}
                    name='Blk'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Street <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.Street}
                    name='Street'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Area <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.Area}
                    name='Area'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Barangay <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    size='sm'
                    value={appInfo.Barangay}
                    name='Barangay'
                    onChange={editHandleChange}
                    required
                  >
                    <option value=''>Select Barangay here ...</option>
                    {appInfo.Barangays.map((element, index) => (
                      <option key={index} value={element.barangay}>
                        {element.barangay}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Exempted <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    as='select'
                    value={appInfo.Exempted}
                    name='Exempted'
                    onChange={editHandleChange}
                  >
                    <option value=''>Choose here ...</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                  </Form.Control>
                </Form.Group>
              </Row>
              {/* End Construction Site */}

              {/* Scope of Work */}
              <h6 className='my-2'>Scope of Work</h6>
              <hr className='m-0' style={{ width: '150px', color: 'red' }} />
              <Row>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Scope <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    size='sm'
                    value={appInfo.scopeOfWork}
                    name='scopeOfWork'
                    onChange={editHandleChange}
                    required
                  >
                    <option value=''>Choose here ...</option>
                    {props.payload.ApplicationName !== 'ELECTRICAL PERMIT' &&
                      [
                        'NEW CONSTRUCTION',
                        'RENOVATION',
                        'RAISING',
                        'ERECTION',
                        'CONVERSION',
                        'DEMOLITION',
                        'ADDITION',
                        'REPAIR',
                        'ACESSORY BUILDING/STRUCTURE',
                        'ALTERATION',
                        'MOVING',
                        'OTHERS/(SPECIFY)',
                      ].map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}

                    {props.payload.ApplicationName === 'ELECTRICAL PERMIT' &&
                      [
                        'NEW CONNECTION',
                        'TEMPORARY CONNECTION',
                        'TEMP. CONST. LIGHTS',
                        'SEPARATION OF METER',
                        'RECONNECTION OF METER',
                        'RELOCATION OF METER',
                        'ADDITIONAL LOAD',
                        'REDUCTION OF LOAD',
                        'REMODEL OF SERVICE ENTRANCE',
                        'TEMPORARY POWER INTERRUPTION / RESTORATION',
                        'SEP. OF METER-WATER PUMP',
                        'OTHERS',
                        'POWER RESTORATION',
                        'RELOCATION OF SERVICE ENTRANCE',
                        'DOWNGRADE OF SERVICE',
                        'UPGRADING OF SERVICE ENTRANCE',
                        'CHANGE LOAD',
                        'TEMPORARY TO PERMANENT',
                      ].map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>Specify</Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.sw_specify}
                    name='sw_specify'
                    onChange={editHandleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Use Type <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.useType}
                    name='useType'
                    onChange={editHandleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    No of Storeys <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.noOfStorey}
                    name='noOfStorey'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Floor Area <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.floorArea}
                    name='floorArea'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Compute As <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    size='sm'
                    value={appInfo.computeAs}
                    name='computeAs'
                    onChange={editHandleChange}
                    required
                  >
                    <option>Choose here ...</option>
                    <option value='Residential'>Residential</option>
                    <option value='Commercial'>Commercial</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    No of Units <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.noOfUnits}
                    name='noOfUnits'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>Start of Construction</Form.Label>
                  <Form.Control
                    type='date'
                    size='sm'
                    onKeyDown={(e) => e.preventDefault()}
                    max={appInfo.scheduleOfConstructionEnd}
                    value={appInfo.scheduleOfConstructionStart}
                    name='scheduleOfConstructionStart'
                    onChange={editHandleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>End of Construction</Form.Label>
                  <Form.Control
                    type='date'
                    size='sm'
                    onKeyDown={(e) => e.preventDefault()}
                    min={appInfo.scheduleOfConstructionStart}
                    value={appInfo.scheduleOfConstructionEnd}
                    name='scheduleOfConstructionEnd'
                    onChange={editHandleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>Contractor </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.contractor}
                    name='contractor'
                    onChange={editHandleChange}
                  />
                </Form.Group>
              </Row>
              {/* End Scope of Work */}

              {/* Project Cost */}
              <h6 className='my-2'>Project Cost</h6>
              <hr className='m-0' style={{ width: '150px', color: 'red' }} />
              <Row>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Estimate Cost <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.estimateCost > 0 ? appInfo.estimateCost : 0}
                    name='estimateCost'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    Building (Estimate Cost) <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.ec_building > 0 ? appInfo.ec_building : 0}
                    name='ec_building'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>Electrical (Estimate Cost)</Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={
                      appInfo.ec_electrical > 0 ? appInfo.ec_electrical : 0
                    }
                    name='ec_electrical'
                    onChange={editHandleChange}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>Plumbing (Estimate Cost)</Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.ec_plumbing > 0 ? appInfo.ec_plumbing : 0}
                    name='ec_plumbing'
                    onChange={editHandleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>Mechanical (Estimate Cost)</Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={
                      appInfo.ec_mechanical > 0 ? appInfo.ec_mechanical : 0
                    }
                    name='ec_mechanical'
                    onChange={editHandleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>Others (Estimate Cost)</Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.ec_others > 0 ? appInfo.ec_others : 0}
                    name='ec_others'
                    onChange={editHandleChange}
                  />
                </Form.Group>
              </Row>
              {/* End Project Cost */}

              {/* Site and Lot Information */}
              <h6 className='my-2'>Site and Lot Information</h6>
              <hr className='m-0' style={{ width: '150px', color: 'red' }} />
              <Row>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    TCT/CCT NO. <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.TCTNoLI}
                    name='TCTNoLI'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>
                    TAX DEC. NO. <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.TaxDecNoLI}
                    name='TaxDecNoLI'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xl='6'>
                  <Form.Label>
                    LOT OWNER/S <i className='text-danger'>*</i>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.LotOwner}
                    name='LotOwner'
                    onChange={editHandleChange}
                    required
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>Resident Certificate</Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.ResCert}
                    name='ResCert'
                    onChange={editHandleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>Date Issued</Form.Label>
                  <Form.Control
                    type='date'
                    size='sm'
                    onKeyDown={(e) => e.preventDefault()}
                    value={appInfo.DateIssued}
                    name='DateIssued'
                    onChange={editHandleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xl='3'>
                  <Form.Label>Place Issued</Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    value={appInfo.PlaceIssued}
                    name='PlaceIssued'
                    onChange={editHandleChange}
                  />
                </Form.Group>
              </Row>

              <Form.Group>
                <Form.Label>
                  Address <i className='text-danger'>*</i>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={appInfo.Address}
                  name='Address'
                  onChange={editHandleChange}
                  required
                  placeholder='Street, Area, Barangay, Municipality'
                />
              </Form.Group>
              {/* End Site and Lot Information */}
            </Modal.Body>
          )}

          {applicationInformation && applicationInformation.loading && (
            <Modal.Body>
              <Loading />
            </Modal.Body>
          )}

          <Modal.Footer>
            <Button variant='info' size='sm' type='submit'>
              Submit
            </Button>
            <Button variant='danger' size='sm' onClick={props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <SuccessModal
        show={modal.insertSuccess}
        onHide={() => {
          props.onHide()
          setModal({ insertSuccess: false })
        }}
        transLoading={
          insertedApplicationInfo && insertedApplicationInfo.loading
        }
        success={insertedApplicationInfo && insertedApplicationInfo.message}
        error={insertedApplicationInfo && insertedApplicationInfo.error}
      />

      <SuccessModal
        show={modal.updateSuccess}
        onHide={() => {
          props.onHide()
          setModal({ updateSuccess: false })
        }}
        transLoading={updatedApplicationInfo && updatedApplicationInfo.loading}
        success={updatedApplicationInfo && updatedApplicationInfo.message}
        error={updatedApplicationInfo && updatedApplicationInfo.error}
      />
    </>
  )
}

export default ApplicationInformation
