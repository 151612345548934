import {
  USER_ADD_EXCAPERMIT_REQUEST,
  USER_ADD_EXCAPERMIT_SUCCESS,
  USER_ADD_EXCAPERMIT_FAILED,
  USER_RETRIEVE_EXCAPERMIT_BYID,
} from '../constants/excavationpermitConstant'

export const excavationpermitReducer = (
  state = { excapermitDetail: {} },
  action
) => {
  switch (action.type) {
    case USER_ADD_EXCAPERMIT_REQUEST:
      return { loading: true, state }
    case USER_ADD_EXCAPERMIT_SUCCESS:
      return { loading: false, excapermitDetail: action.payload }
    case USER_ADD_EXCAPERMIT_FAILED:
      return { loading: false, error: action.payload }
    case USER_RETRIEVE_EXCAPERMIT_BYID:
      return { ...state, information: action.payload }
    default:
      return state
  }
}
