import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
  Accordion,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  addAssessment,
  editAssessment,
  retrieveAssessmentDetails,
} from '../../redux/actions/Assessment/electricalAssessmentActions'
import { retrieveAppDetails } from '../../redux/actions/evaluatorActions'

const ElectricalAssessmentScreen = ({ history, match }) => {
  // Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const { getAppDetails } = evaluator

  const electricalAssessment = useSelector(
    (state) => state.electricalAssessment
  )

  const { assessmentDetails, createAssessment, updateAssessment } =
    electricalAssessment

  const [obj, setObj] = useState([
    {
      id_per_name: 1,
      name: 'A_KVA',
      name_2: 'A_Qty',
      estimate_kva: 0,
      kva: 0,
      estimate_units: 0,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 2,
      name: 'B_KVA',
      name_2: 'B_Qty',
      estimate_kva: 0,
      kva: 0,
      estimate_units: 0,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 4,
      name: 'Pole Location',
      name_2: '',
      estimate_kva: 0,
      kva: 0,
      estimate_units: 0,
      fee: 30,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 5,
      name: 'Guy Attach',
      name_2: '',
      estimate_kva: 0,
      kva: 0,
      estimate_units: 0,
      fee: 30,
      units: 0,
      estimate: 0,
    },
  ])

  const [state, setState] = useState([])

  const [disabled, setDisabled] = useState({
    A: true,
    B: true,
  })

  const [grandTotal, setGrandTotal] = useState(0)
  const [modal, setModal] = useState({
    successModal: false,
    updateModal: false,
  })

  const total = obj.reduce(
    (prevData, currData) => prevData + currData.estimate,
    0
  )

  const filtered = obj.filter((e) => e.estimate > 0)

  const isUpdating = JSON.parse(localStorage.getItem('assessmentSelected'))
    ? JSON.parse(localStorage.getItem('assessmentSelected'))
    : null

  // End Declaration

  //   Function
  //CAT A KVA
  const handleRangeDivA1 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 5:
        tempEstimate = 200
        break
      case e.target.value > 5 && e.target.value <= 50:
        tempEstimate = 200 * (e.target.value * 20)
        break
      case e.target.value > 50 && e.target.value <= 300:
        tempEstimate = e.target.value * 10 + 1100
        break
      case e.target.value > 300 && e.target.value <= 1500:
        tempEstimate = e.target.value * 5
        break
      case e.target.value > 1500 && e.target.value <= 6000:
        tempEstimate = e.target.value * 2.5
        break
      case e.target.value > 6000:
        tempEstimate = e.target.value * 1.5
        break

      default:
        tempEstimate = 200
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            kva: e.target.value,
            estimate_kva: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
    setDisabled({ ...disabled, A: false })
  }
  //CAT A UNIt
  const handleRangeDivA2 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case obj[0].kva <= 5:
        tempEstimate = (parseFloat(obj[0].kva) + 200) * e.target.value
        break
      case obj[0].kva > 5 && obj[0].kva <= 50:
        tempEstimate = e.target.value * ((obj[0].kva * 20) + 200)   ///
        break
      case obj[0].kva > 50 && obj[0].kva <= 300:
        tempEstimate = e.target.value * ((obj[0].kva * 10) + 1100) 
        break
      case obj[0].kva > 300 && obj[0].kva <= 1500:
        tempEstimate = e.target.value * ((obj[0].kva * 5) + 3600) 
        break
      case obj[0].kva > 1500 && obj[0].kva <= 6000:
        tempEstimate = e.target.value * ((obj[0].kva * 2.5) + 9600) 
        break
      case obj[0].kva > 6000:
        tempEstimate = e.target.value * ((obj[0].kva * 1.25) + 20850) 
        break
      default:
        tempEstimate = (parseFloat(obj[0].kva) + 200) * e.target.value
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name_2 === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate_units: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name_2 === e.target.name) {
          return {
            ...el,
            estimate: el.estimate_units,
          }
        } else {
          return el
        }
      })
    })
  }

  //CAT B KVA
  const handleRangeDivA3 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 5:
        tempEstimate = 0
        break
      case e.target.value > 5 && e.target.value <= 50:
        tempEstimate = e.target.value * 4
        break
      case e.target.value > 50 && e.target.value <= 300:
        tempEstimate = e.target.value * 2
        break
      case e.target.value > 300 && e.target.value <= 1500:
        tempEstimate = e.target.value * 1
        break
      case e.target.value > 1500 && e.target.value <= 6000:
        tempEstimate = e.target.value * 0.5
        break
      case e.target.value > 6000:
        tempEstimate = e.target.value * 0.25
        break
      default:
        tempEstimate = 0
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            kva: e.target.value,
            estimate_kva: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
    setDisabled({ ...disabled, B: false })
  }
  //CAT B UNIT
  const handleRangeDivA4 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case obj[1].kva <= 5:
        tempEstimate = (parseFloat(obj[1].kva) + 40) * e.target.value
        break
      case obj[1].kva > 5 && obj[1].kva <= 50:
        tempEstimate =e.target.value * ((obj[1].kva * 4) + 40) 
        break
      case obj[1].kva > 50 && obj[1].kva <= 300:
        tempEstimate = e.target.value * ((obj[1].kva * 2) + 220) 
        break
      case obj[1].kva > 300 && obj[1].kva <= 1500:
        tempEstimate = e.target.value * ((obj[1].kva * 1) + 720) 
        break
      case obj[1].kva > 1500 && obj[1].kva <= 6000:
        tempEstimate = e.target.value * ((obj[1].kva * 0.5) + 1920) 
        break
      case obj[1].kva > 6000:
        tempEstimate = e.target.value * ((obj[1].kva * 0.25) + 4170) 
        break
      default:
        tempEstimate = (parseFloat(obj[1].kva) + 40) * e.target.value
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name_2 === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate_units: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name_2 === e.target.name) {
          return {
            ...el,
            estimate: el.estimate_kva + el.estimate_units,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleChange = (e) => {
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: e.target.value * el.fee,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleCreateSubmit = () => {
    setModal({ successModal: true })
    dispatch(
      addAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
      })
    )
  }

  const handleUpdateSubmit = () => {
    setModal({ updateModal: true })
    dispatch(
      editAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
      })
    )
  }

  const handleBtnCancel = () => {
    if (isUpdating !== null) {
      history.push('/assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    } else {
      history.push('/for-assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    }
  }
  // End Function

  //UseEffect
  useEffect(() => {
    dispatch(
      retrieveAppDetails({ appID: match.params.id, userID: userCredentials.id })
    )

    isUpdating !== null &&
      dispatch(
        retrieveAssessmentDetails({
          evalAppID: isUpdating.evalAppID,
          userID: userCredentials.id,
        })
      )
  }, [dispatch, match, userCredentials])

  useEffect(() => {
    setGrandTotal(() => parseInt(total * 100) / 100)

    setState(() => filtered)
  }, [total])

  useEffect(() => {
    if (isUpdating !== null) {
      if (assessmentDetails && assessmentDetails.message) {
        assessmentDetails.message.map((submitted) => {
          let temp = obj
          let index = obj.findIndex(
            (el) => el.id_per_name === submitted.id_per_name
          )
          temp[index].units = submitted.units
          temp[index].estimate = submitted.estimate
          temp[index].estimate_kva = submitted.estimate_kva
          temp[index].kva = submitted.kva
          temp[index].estimate_units = submitted.estimate_units
          // temp[index] = { ...temp[index], id: submitted.id }
          setObj(temp)
        })
        setGrandTotal(() => assessmentDetails.message[0].grandTotal)
      }
    }
  }, [assessmentDetails])

  // console.log(obj)
  return (
    <UserMain>
      <Container className='bg-light pt-3'>
        <>
          <h1 className='text-center mb-4'>ELECTRICAL FEES</h1>
          <Row>
            <Col
              xl={12}
              className='mb-4 pt-3'
              style={{ background: '#f9f6f1' }}
            >
              <h6>Note: </h6>
              <p>Click category to be assessed.</p>
            </Col>
          </Row>

          <Form
            onSubmit={(e) => {
              e.preventDefault()

              isUpdating !== null ? handleUpdateSubmit() : handleCreateSubmit()
            }}
          >
            <Accordion className='mx-0'>
              {/* A. Total Connected Load  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='0'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  A. TOTAL CONNECTED LOAD
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>PER UNIT + PER kVA</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            5 kVA or less 12312
                          </td>
                          <td className='text-center'>₱200.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='A_KVA'
                              placeholder='i.-vi. (kVA)'
                              onChange={(e) => {
                                setObj((currObj) => {
                                  return currObj.map((el) => {
                                    if (el.name === e.target.name) {
                                      return {
                                        ...el,
                                        kva: e.target.value,
                                        units: 0,
                                        estimate: 0
                                      }
                                    } else {
                                      return el
                                    }
                                  })
                                })
                                setDisabled({ ...disabled, A: false })
                              }}
                              value={obj[0].kva}
                              required
                              min={0}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              step={0.01}
                            />
                          </td>
                          <td className='align-middle'></td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Over 5kVA to 50kVA
                          </td>
                          <td className='text-center'>₱200.00 + 20.00/kVA</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='A_Qty'
                              placeholder='i.-vi. (Unit Qty.)'
                              disabled={disabled.A}
                              onChange={handleRangeDivA2}
                              value={obj[0].units}
                              required
                              min={0}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              step={0.01}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {obj.find((el) => el.name_2 === 'A_Qty').estimate.toLocaleString()}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Over 50kVA to 300kVA
                          </td>
                          <td className='text-center'>₱1,100.00 + 10.00/kVA</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Over 300kVA to 1,500kVA
                          </td>
                          <td className='text-center'>₱3,600.00 + 5.00/kVA</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Over 1,500 kVA to 6,000 kVA
                          </td>
                          <td className='text-center'>₱9,600.00 + 2.50/kVA</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Over 6,000 kVA
                          </td>
                          <td className='text-center'>₱20,850.00 + 1.25/kVA</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End A */}

              {/* B.Total Transformer/Uninterrupted Power Supply (UPS)/Generator Capacity (kVA) */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='1'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  B. TOTAL TRANSFORMER / UNINTERRUPTED POWER SUPPLY (UPS) /
                  GENERATOR CAPACITY (kVA)
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='1'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>PER UNIT + PER kVA</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            5 kVA or less
                          </td>
                          <td className='text-center'>₱40.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='B_KVA'
                              placeholder='i.-vi. (kVA)'
                              onChange={(e) => {
                                setObj((currObj) => {
                                  return currObj.map((el) => {
                                    if (el.name === e.target.name) {
                                      return {
                                        ...el,
                                        kva: e.target.value,
                                        units: 0,
                                        estimate: 0
                                      }
                                    } else {
                                      return el
                                    }
                                  })
                                })
                                setDisabled({ ...disabled, B: false })
                              }}
                              value={obj[1].kva}
                              required
                              min={0}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              step={0.01}
                            />
                          </td>
                          <td className='align-middle'></td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Over 5kVA to 50kVA
                          </td>
                          <td className='text-center'>₱40.00 + 4.00/kVA</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='B_Qty'
                              placeholder='i.-vi. (Unit Qty.)'
                              disabled={disabled.B}
                              onChange={handleRangeDivA4}
                              value={obj[1].units}
                              required
                              min={0}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              step={0.01}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {obj.find((el) => el.name_2 === 'B_Qty').estimate.toLocaleString()}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Over 50kVA to 300kVA
                          </td>
                          <td className='text-center'>₱220.00 + 2.00/kVA</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Over 300kVA to 1,500kVA
                          </td>
                          <td className='text-center'>₱720.00 + 1.00/kVA</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Over 1,500 kVA to 6,000 kVA
                          </td>
                          <td className='text-center'>₱1,920.00 + 0.50/kVA</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Over 6,000 kVA
                          </td>
                          <td className='text-center'>₱4,170.00 + 0.25/kVA</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End B */}

              {/* C. Pole/Attachment Location Plan Permit*/}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='2'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  C. POLE/ATTACHMENT LOCATION PLAN PERMIT
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='2'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Power Supply Pole Location
                          </td>
                          <td className='text-center'>₱30.00/pole</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Pole Location'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[2].units}
                              required
                              min={0}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              step={0.01}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Pole Location')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Guying Attachment
                          </td>
                          <td className='text-center'>₱30.00/attachment</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Guy Attach'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[3].units}
                              required
                              min={0}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              step={0.01}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Guy Attach')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End C */}

              {/*  D. Miscellaneous Fees: Electric Meter for union separation, alteration, reconnection or relocation and issuance of Wiring Permit */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='4'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  D. MISCELLANEOUS FEES: ELECTRIC METER FOR UNION SEPERATION,
                  ALTERATION, RECONNECTION OR RELOCATION AND ISSUANCE OF WIRING
                  PERMIT
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center align-middle'>
                          <th>Use or Character of Occupancy</th>
                          <th>Electric Meter</th>
                          <th>Wiring Permit Issuance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>Residential</td>
                          <td className='align-middle text-center'>₱15.00</td>
                          <td className='text-center align-middle'>₱15.00</td>
                        </tr>
                        <tr className='text-justify'>
                          <td className='align-middle'>
                            Commercial/Industrial
                          </td>
                          <td className='align-middle text-center'>₱60.00</td>
                          <td className='text-center align-middle'>₱36.00</td>
                        </tr>
                        <tr className='text-justify'>
                          <td className='align-middle'>Institutional</td>
                          <td className='align-middle text-center'>₱30.00</td>
                          <td className='text-center align-middle'>₱12.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End D */}
            </Accordion>

            {/* E: */}
            <Row className='pt-5'>
              <Col xl={12}>
                <Table responsive className='mb-5'>
                  <thead>
                    <tr className='text-center align-middle'>
                      <th colSpan={3}>
                        <h5>E. Formula for Computation of Fees</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr colSpan={3}>
                      <td className='align-middle'>
                        The Total Electrical Fees shall be the sum of Sections
                        4.a to 4.d of this Rule.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            {/* End E. */}

            {/* F. */}
            <Row>
              <Col xl={12}>
                <Table responsive className='mb-5'>
                  <thead>
                    <tr className='text-center align-middle'>
                      <th colSpan={3}>
                        <h5>F. Forfeiture of Fees</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr colSpan={3}>
                      <td className='align-middle'>
                        If the electrical work or installation is found not in
                        conformity with the minimum safety requirements of the
                        Philippine Electrical Codes and the Electrical
                        Engineering Law (RA 7920), and the Owner fails to
                        perform corrective actions within the reasonable time
                        provided by the Building Official, the latter and/or
                        their duly authorized representative shall forthwith
                        cancel the permit and the fees thereon shall be
                        forfeited.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            {/* End F. */}

            <Row className='mt-3 mx-1'>
              <Col xl={8} className='text-end align-middle mt-2'>
                <h4>GRAND TOTAL:</h4>
              </Col>
              <Col xl={4} className='mb-5'>
                <Form.Control
                  type='text'
                  className='border border-secondary'
                  style={{
                    marginLeft: '8px',
                    marginRight: '5px',
                  }}
                  name='GrandTotal'
                  value={grandTotal}
                  placeholder='Grand Total'
                  disabled
                />
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col xl={12} className='text-end'>
                <Button variant='success' type='submit'>
                  Submit
                </Button>
                <Button
                  variant='danger'
                  className='mx-2'
                  onClick={handleBtnCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      </Container>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={createAssessment && createAssessment.loading}
        success={createAssessment && createAssessment.message}
        error={createAssessment && createAssessment.error}
      />
      {/* End Success Modal */}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateModal}
        onHide={() => {
          setModal({ updateModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={updateAssessment && updateAssessment.loading}
        success={updateAssessment && updateAssessment.message}
        error={updateAssessment && updateAssessment.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(ElectricalAssessmentScreen)
