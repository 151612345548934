import {
  GET_ESUPERVISOR_FORRECOMMENDATION_REQ,
  GET_ESUPERVISOR_FORRECOMMENDATION_SUCCESS,
  GET_ESUPERVISOR_FORRECOMMENDATION_FAILED,
  GET_ESUPERVISOR_RECOMMENDED_REQ,
  GET_ESUPERVISOR_RECOMMENDED_SUCCESS,
  GET_ESUPERVISOR_RECOMMENDED_FAILED,
  GET_EVALUATIONLIST_RECOMMENDED_REQ,
  GET_EVALUATIONLIST_RECOMMENDED_SUCCESS,
  GET_EVALUATIONLIST_RECOMMENDED_FAILED,
  GET_ESUPERVISOR_RECOMMEND_REQ,
  GET_ESUPERVISOR_RECOMMEND_SUCCESS,
  GET_ESUPERVISOR_RECOMMEND_FAILED,
  INSERT_COMMENT_REQ,
  INSERT_COMMENT_SUCCESS,
  INSERT_COMMENT_FAILED,
} from '../../../constants/Evaluators/Supervisor/supervisorConstants'

export const esupervisorReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ESUPERVISOR_FORRECOMMENDATION_REQ:
      return {
        ...state,
        forRecommendation: { loading: true },
      }

    case GET_ESUPERVISOR_FORRECOMMENDATION_SUCCESS:
      return {
        ...state,
        forRecommendation: { loading: false, message: action.payload },
      }

    case GET_ESUPERVISOR_FORRECOMMENDATION_FAILED:
      return {
        ...state,
        forRecommendation: { loading: false, error: action.payload },
      }
    case GET_ESUPERVISOR_RECOMMENDED_REQ:
      return {
        ...state,
        recommendedList: { loading: true },
      }

    case GET_ESUPERVISOR_RECOMMENDED_SUCCESS:
      return {
        ...state,
        recommendedList: { loading: false, message: action.payload },
      }

    case GET_ESUPERVISOR_RECOMMENDED_FAILED:
      return {
        ...state,
        recommendedList: { loading: false, error: action.payload },
      }
    case GET_EVALUATIONLIST_RECOMMENDED_REQ:
      return {
        ...state,
        evaluationList: { loading: true },
      }

    case GET_EVALUATIONLIST_RECOMMENDED_SUCCESS:
      return {
        ...state,
        evaluationList: { loading: false, message: action.payload },
      }

    case GET_EVALUATIONLIST_RECOMMENDED_FAILED:
      return {
        ...state,
        evaluationList: { loading: false, error: action.payload },
      }
    case GET_ESUPERVISOR_RECOMMEND_REQ:
      return {
        ...state,
        recommendApplication: { loading: true },
      }

    case GET_ESUPERVISOR_RECOMMEND_SUCCESS:
      return {
        ...state,
        recommendApplication: { loading: false, message: action.payload },
      }

    case GET_ESUPERVISOR_RECOMMEND_FAILED:
      return {
        ...state,
        recommendApplication: { loading: false, error: action.payload },
      }
    case INSERT_COMMENT_REQ:
      return {
        ...state,
        createComments: { loading: true },
      }

    case INSERT_COMMENT_SUCCESS:
      return {
        ...state,
        createComments: { loading: false, message: action.payload },
      }

    case INSERT_COMMENT_FAILED:
      return {
        ...state,
        createComments: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
