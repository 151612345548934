import {
  DOWNLOADABLE_FORMS_REQUEST,
  DOWNLOADABLE_FORMS_SUCCESS,
  DOWNLOADABLE_FORMS_FAILED,
  GET_GUEST_APPLICATION_REQUEST,
  GET_GUEST_APPLICATION_SUCCESS,
  GET_GUEST_APPLICATION_FAILED,
} from '../constants/downloadableformsConstant'

export const downloadableformsReducer = (
  state = { downloadableformsDetail: [] },
  action
) => {
  switch (action.type) {
    case DOWNLOADABLE_FORMS_REQUEST:
      return { loading: true, state }
    case DOWNLOADABLE_FORMS_SUCCESS:
      return { loading: false, downloadableformsDetail: action.payload }
    case DOWNLOADABLE_FORMS_FAILED:
      return { loading: false, error: action.payload }
    case GET_GUEST_APPLICATION_REQUEST:
      return { ...state, retrievedGuestApplication: { loading: true } }
    case GET_GUEST_APPLICATION_SUCCESS:
      return {
        ...state,
        retrievedGuestApplication: { loading: false, message: action.payload },
      }
    case GET_GUEST_APPLICATION_FAILED:
      return {
        ...state,
        retrievedGuestApplication: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
