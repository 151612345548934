import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProtectedRoute from '../reusable/ProtectedRoute'

// Components
import DcDashboardScreen from '../screens/DcScreens/DcDashboardScreen'
import ExpiredTokenScreen from '../screens/ExpiredTokenScreen'
import EmployeeProfileScreen from '../screens/DcScreens/EmployeeProfileScreen'

// Evaluators
import CreateElectronicsScreen from '../screens/EvaluatorScreens/CreateElectronicsScreen'
import CreateCivilStrucScreen from '../screens/EvaluatorScreens/CreateCivilStrucScreen'
import CreateArchitectScreen from '../screens/EvaluatorScreens/CreateArchitectScreen'
import CreateElectricalScreen from '../screens/EvaluatorScreens/CreateElectricalScreen'
import CreateMechanicalScreen from '../screens/EvaluatorScreens/CreateMechanicalScreen'
import CreatePlumbingScreen from '../screens/EvaluatorScreens/CreatePlumbingScreen'
import ForEvaluationListScreen from '../screens/EvaluatorScreens/ForEvaluationListScreen'
import EvaluatedListScreen from '../screens/EvaluatorScreens/EvaluatedListScreen'
import EvaluationSheetScreen from '../screens/DcScreens/EvaluationSheetScreen'

// Assessments
import ElectronicsAssessmentScreen from '../screens/AssessmentScreens/ElectronicsAssessmentScreen'
import ElectricalAssessmentScreen from '../screens/AssessmentScreens/ElectricalAssessmentScreen'
import MechanicalAssessmentScreen from '../screens/AssessmentScreens/MechanicalAssessmentScreen'
import PlumbingAssessmentScreen from '../screens/AssessmentScreens/PlumbingAssessmentScreen'
import StructuralAssessmentScreen from '../screens/AssessmentScreens/StructuralAssessmentScreen'
import AccessoriesFeeScreen from '../screens/AssessmentScreens/AccessoriesFeeScreen'
import AnnualInspectionFeeScreen from '../screens/AssessmentScreens/AnnualInspectionFeeScreen'
import CertificateOfOccupancyScreen from '../screens/AssessmentScreens/CertificateOfOccupancyScreen'
import CertificationsScreen from '../screens/AssessmentScreens/CertificationsScreen'
import ConstructionRenovationScreen from '../screens/AssessmentScreens/ConstructionRenovationScreen'
import AssessmentListScreen from '../screens/AssessmentScreens/AssessmentListScreen'
import ForAssessmentListScreen from '../screens/AssessmentScreens/ForAssessmentListScreen'

// Supervisor
import ForRecommendationListScreen from '../screens/EvaluatorScreens/Supervisor/ForRecommendationListScreen'
import RecommendedListScreen from '../screens/EvaluatorScreens/Supervisor/RecommendedListScreen'

const EvaluatorRoutes = ({ user, subroles }) => {
  if (subroles !== 'supervisor') {
    return (
      <Switch>
        {/* <ProtectedRoute
          exact
          path='/dashboard'
          component={DcDashboardScreen}
          isAuth={user === null ? false : true}
        /> */}

        {/* Evaluators */}
        <ProtectedRoute
          exact
          path='/create-evaluation-electronics/:id?'
          component={CreateElectronicsScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/create-evaluation-civil/:id?'
          component={CreateCivilStrucScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/create-evaluation-architectural/:id?'
          component={CreateArchitectScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/create-evaluation-electrical/:id?'
          component={CreateElectricalScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/create-evaluation-mechanical/:id?'
          component={CreateMechanicalScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/create-evaluation-plumbing/:id?'
          component={CreatePlumbingScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/dashboard'
          component={ForEvaluationListScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/evaluated-list'
          component={EvaluatedListScreen}
          isAuth={user === null ? false : true}
        />
        {/* End Evaluators */}

        {/* Assessments */}
        <ProtectedRoute
          exact
          path='/electronics-assessments/:id?'
          component={ElectronicsAssessmentScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/electrical-assessments/:id?'
          component={ElectricalAssessmentScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/mechanical-assessments/:id?'
          component={MechanicalAssessmentScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/plumbing-assessments/:id?'
          component={PlumbingAssessmentScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/structural-assessments/:id?'
          component={StructuralAssessmentScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/accessories-fee/:id?'
          component={AccessoriesFeeScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/annual-inspection-fee/:id?'
          component={AnnualInspectionFeeScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/certificate-of-occupancy/:id?'
          component={CertificateOfOccupancyScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/certifications/:id?'
          component={CertificationsScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/construction-renovation/:id?'
          component={ConstructionRenovationScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/for-assessment-list'
          component={ForAssessmentListScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/assessment-list'
          component={AssessmentListScreen}
          isAuth={user === null ? false : true}
        />
        {/* End Assessments */}

        <ProtectedRoute
          exact
          path='/profile'
          component={EmployeeProfileScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/evaluation-sheet/:id?'
          component={EvaluationSheetScreen}
          isAuth={user === null ? false : true}
        />

        <Route component={ExpiredTokenScreen} />
      </Switch>
    )
  }

  return (
    <Switch>
      <ProtectedRoute
        exact
        path='/dashboard'
        component={DcDashboardScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/for-recommendation-list'
        component={ForRecommendationListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/recommendation-list'
        component={RecommendedListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/profile'
        component={EmployeeProfileScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/evaluation-sheet/:id?'
        component={EvaluationSheetScreen}
        isAuth={user === null ? false : true}
      />

      <Route component={ExpiredTokenScreen} />
    </Switch>
  )
}

export default EvaluatorRoutes
