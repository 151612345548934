import React, { useState, useEffect, useRef } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Spinner,
} from 'react-bootstrap'
import { FaExclamationCircle } from 'react-icons/fa'
import ReCAPTCHA from 'react-google-recaptcha'

// Components
import GuestMain from '../layout/GuestLayout/GuestMain'
import SuccessModal from '../reusable/SuccessModal'

// Redux Requirements
import { useSelector, useDispatch } from 'react-redux'

// Actions
import { createUser } from '../redux/actions/userActions'

const RegistrationScreen = ({ background, border, history, location }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users)
  const { userCredentials, createdUser } = user

  const redirect = location.search
    ? location.search.split('=')[1]
    : '/dashboard'

  useEffect(() => {
    if (userCredentials) {
      history.push(redirect)
    }
  }, [userCredentials, history, redirect])

  const [ProceedShow, setProceedShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [modal, setModal] = useState({
    created: false,
  })

  const [DOBLimit, setDOBLimit] = useState({
    maxDate: '',
    minDate: '',
  })

  const [Registration, setRegistration] = useState({
    FirstName: '',
    MiddleName: '',
    LastName: '',
    CivilStatus: '',
    Gender: '',
    MobileNo: '',
    EmailAddress: '',
    DOB: '',
    username: '',
    password: '',
    confirmPassword: '',
    TermsNCondition: 0,
  })

  const [uploadImages, setUploadImages] = useState(null)

  const [token, setToken] = useState('')
  const reCaptcha = useRef()

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!token) {
      alert('You must verify the Captcha')
    } else {
      if (Registration.password === Registration.confirmPassword) {
        setModal({ created: true })
        dispatch(
          createUser({
            FirstName: Registration.FirstName,
            MiddleName: Registration.MiddleName,
            LastName: Registration.LastName,
            CivilStatus: Registration.CivilStatus,
            Gender: Registration.Gender,
            MobileNo: Registration.MobileNo,
            EmailAddress: Registration.EmailAddress,
            DOB: Registration.DOB,
            username: Registration.username,
            password: Registration.password,
            confirmPassword: Registration.confirmPassword,
            TermsNCondition: Registration.TermsNCondition,
            images: uploadImages,
          })
        )
      } else {
        alert('password and corfirm password mismatched!')
      }
    }
  }

  const handleChange = (event) => {
    setRegistration({
      ...Registration,
      [event.target.name]: event.target.value,
    })
  }

  const maxDate = () => {
    let dateMax = new Date()
    let dateMin = new Date()

    dateMax.setFullYear(dateMax.getFullYear() - 18)

    dateMin.setFullYear(dateMin.getFullYear() - 85)

    let maxYear = dateMax.getFullYear()
    let maxMonth = ('0' + (dateMax.getMonth() + 1)).slice(-2)
    let maxDay = ('0' + dateMax.getDate()).slice(-2)

    let minYear = dateMin.getFullYear()
    let minMonth = ('0' + (dateMin.getMonth() + 1)).slice(-2)
    let minDay = ('0' + dateMin.getDate()).slice(-2)

    let maxDate = maxYear + '-' + maxMonth + '-' + maxDay

    let minDate = minYear + '-' + minMonth + '-' + minDay

    if (DOBLimit.maxDate === '' && DOBLimit.minDate === '') {
      setDOBLimit({
        maxDate: maxDate,
        minDate: minDate,
      })
    }
  }

  const acceptDuration = () => {
    setProceedShow(true)
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
    }, 10000)
  }

  const handleClose = () => {
    setChecked(false)
    setProceedShow(false)
    setRegistration({
      ...Registration,
      TermsNCondition: 0,
    })
  }

  const handleAccept = () => {
    setChecked(true)
    setProceedShow(false)
    setRegistration({
      ...Registration,
      TermsNCondition: 1,
    })
  }

  const handleUploadImage = async (e) => {
    let files = e.target.files
    if (e.target.files.length > 3) {
      alert('You are only allowed to upload a maximum of 3 images!')
    }
    setUploadImages(files)
  }

  useEffect(() => {
    maxDate()
  })

  return (
    <GuestMain>
      <Container fluid style={{ background: '#f9f6f1' }}>
        <Row>
          <Col md={9}>
            <div
              className='my-3 border border-secondary shadow-lg'
              style={{ background: '#FFF' }}
            >
              <Form className='py-3 px-5' onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <h6>
                        Account Name
                        <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    sm={12}
                    md={12}
                    lg={3}
                    xl={3}
                    className='my-1'
                  >
                    <Form.Control
                      name='FirstName'
                      type='text'
                      placeholder='First name (Dave Jr, Dave III)'
                      style={{ background, border }}
                      onChange={handleChange}
                      value={Registration.FirstName}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    sm={12}
                    md={12}
                    lg={3}
                    xl={3}
                    className='my-1'
                  >
                    <Form.Control
                      name='MiddleName'
                      type='text'
                      placeholder='Middle name'
                      style={{ background, border }}
                      onChange={handleChange}
                      value={Registration.MiddleName}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    sm={12}
                    md={12}
                    lg={3}
                    xl={3}
                    className='my-1'
                  >
                    <Form.Control
                      name='LastName'
                      type='text'
                      placeholder='Last name'
                      style={{ background, border }}
                      onChange={handleChange}
                      value={Registration.LastName}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className='my-2'>
                  <Form.Group as={Col} sm={12} md={12} lg={3} xl={3}>
                    <Form.Label>
                      <h6>
                        Birth Date: <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <br />
                    <Form.Control
                      name='DOB'
                      className='mt-1'
                      type='date'
                      max={DOBLimit.maxDate}
                      min={DOBLimit.minDate}
                      onKeyDown={(e) => e.preventDefault()}
                      style={{ background, border }}
                      onChange={handleChange}
                      value={Registration.DOB}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={12} md={12} lg={3} xl={3}>
                    <Form.Label>
                      <h6>
                        Civil Status:
                        <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <br />
                    <Form.Control
                      as='select'
                      className='my-1 mr-sm-2'
                      //   custom
                      size='md'
                      name='CivilStatus'
                      onChange={handleChange}
                      style={{ background }}
                      value={Registration.CivilStatus}
                    >
                      <option value=''>Choose...</option>
                      <option value='Single'>Single</option>
                      <option value='Married'>Married</option>
                      <option value='Divorced'>Divorced</option>
                      <option value='Widowed'>Widowed</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} sm={12} md={12} lg={3} xl={3}>
                    <Form.Label>
                      <h6>
                        Gender: <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <br />
                    <Form.Control
                      as='select'
                      className='my-1 mr-sm-2'
                      //   custom
                      size='md'
                      name='Gender'
                      onChange={handleChange}
                      style={{ background }}
                      value={Registration.Gender}
                      required
                    >
                      <option value=''>Choose...</option>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} sm={12} md={12} lg={3} xl={3}>
                    <Form.Label>
                      <h6>
                        Mobile No.: <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <br />
                    <Form.Control
                      type='text'
                      placeholder='9123456789'
                      style={{ background, border }}
                      className='mt-1'
                      name='MobileNo'
                      maxLength={10}
                      onChange={handleChange}
                      value={Registration.MobileNo}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <h6>
                        Email Address: <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <br />
                    <Form.Control
                      type='text'
                      placeholder='enter your email address here ... '
                      style={{ background, border }}
                      name='EmailAddress'
                      onChange={handleChange}
                      value={Registration.EmailAddress}
                      required
                    />
                  </Form.Group>
                </Row>

                <Row className='my-2'>
                  <Form.Group as={Col} sm={12} md={12} lg={12} xl={6}>
                    <Form.Label>
                      <h6>
                        Username: <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <br />
                    <Form.Control
                      name='username'
                      type='text'
                      placeholder='input username here . . .'
                      style={{ background, border }}
                      onChange={handleChange}
                      value={Registration.username}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={12} md={12} lg={12} xl={6}>
                    <Form.Label>
                      <h6>
                        Password: <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <br />
                    <Form.Control
                      name='password'
                      type='password'
                      placeholder='input password here . . .'
                      maxLength={12}
                      style={{ background, border }}
                      onChange={handleChange}
                      value={Registration.password}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={12} md={12} lg={12} xl={6}>
                    <Form.Label>
                      <h6>
                        Confirm Password: <i className='text-danger'>*</i>
                      </h6>
                    </Form.Label>
                    <br />
                    <Form.Control
                      name='confirmPassword'
                      type='password'
                      placeholder='input confirm password here'
                      maxLength={12}
                      style={{ background, border }}
                      onChange={handleChange}
                      value={Registration.confirmPassword}
                      required
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} md={12}>
                    <Form.File
                      id='image-file'
                      size='sm'
                      multiple
                      onChange={handleUploadImage}
                      required
                    />

                    <Form.Label>
                      <p className='mb-0'>
                        <i className='text-danger'>*</i>
                        <i>
                          NOTE: UPLOAD IMAGE OF VALID ID (Front and Back. 2
                          Images Only, Max of 25mb)
                        </i>
                      </p>
                    </Form.Label>
                  </Form.Group>
                </Row>

                <ReCAPTCHA
                  className='mt-1'
                  ref={reCaptcha}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(token) => setToken(token)}
                  onExpired={(e) => setToken('')}
                  size='compact'
                />
                <Row className='my-2'>
                  <Form.Group>
                    <Form.Check
                      label='Agree to terms and conditions'
                      feedback='You must agree before submitting.'
                      className='pt-2'
                      onClick={acceptDuration}
                      checked={
                        Registration.TermsNCondition > 0 ? checked : false
                      }
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Row>

                <Form.Row>
                  <Button
                    variant='outline-danger'
                    onClick={() => history.push('/')}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: '10px' }}
                    variant='success'
                    type='submit'
                  >
                    Proceed
                  </Button>
                </Form.Row>
              </Form>
            </div>
          </Col>
          <Col sm={3}>
            <img
              src='assets/img/portrait.jpg'
              alt='/'
              className='d-none d-xl-block'
              style={{ height: '88vh', width: '464px' }}
            />
          </Col>
        </Row>
      </Container>

      <Modal show={ProceedShow} onHide={handleClose}>
        <Modal.Header style={{ background: '#f0ad4e', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaExclamationCircle className='mx-3' />
            TERMS AND CONDITIONS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className='p-2' style={{ background: '#f9f6f1' }}>
            NOTE: READ AND UNDERSTAND CAREFULLY
          </h6>
          <Row>
            <Col md={12} className='mt-3'>
              <h6>Data Privacy Consent & Agreements</h6>
              <hr />
              <p className='fs-6'>
                <i>
                  <b>
                    Registration under my consent, I fully understand and concur
                    that by submitting, I hereby agree to the following:
                  </b>
                </i>
                <br />
                <br />
                1. Gives my full consent to the City Government of Las Piñas –
                Office of the Building Official, affiliates and service
                providers to collect, access, store, and process any personal
                data that I may provide;
                <br />
                <br />
                2. Personal data set may include, but not limited to, my name,
                address, mobile and telephone numbers, e-mail address that are
                required to process my online application or request;
                <br />
                <br />
                3. For validation and verification of the information I
                submitted related to my online application or request; <br />
                <br />
                4. To be informed, to access, to object to any alteration or
                damages, to file a complaint, to rectify and to data
                transferability, and to undergo procedures, conditions, and
                exceptions that shall be complied <br /> <br />
                5. The online application thru a third-party network provider,
                and I shall be subjected to their respective terms and services
                <br />
                <br />
                6. I have read and understand the Data Privacy Consent and its
                implementing rules and regulations
                <br />
                <br />
                <i>
                  <b>By submitting, you confirm that:</b>
                </i>
                <br />
                <br />
                1. All information provided are valid and correct.
                <br />
                <br />
                2. You agree to the Office of the Building Official's Terms and
                Use and Privacy Policy of the Online Building Permit
                Application.
              </p>
            </Col>
            <hr />
          </Row>
        </Modal.Body>
        <Modal.Footer className='p-1'>
          <Button variant='danger' onClick={handleClose}>
            DECLINE
          </Button>
          {!loading ? (
            <Button variant='info' onClick={handleAccept}>
              ACCEPT
            </Button>
          ) : (
            <Button variant='info' disabled={true} onClick={handleAccept}>
              <Spinner animation='border' size='sm' />
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Success Modal */}
      <SuccessModal
        show={modal.created}
        onHide={() => {
          setModal({ created: false })
          if (createdUser && createdUser.error) {
            setRegistration({
              ...Registration,
              password: '',
              confirmPassword: '',
              EmailAddress: '',
              username: '',
            })
          } else {
            setRegistration({
              FirstName: '',
              MiddleName: '',
              LastName: '',
              CivilStatus: '',
              Gender: '',
              MobileNo: '',
              EmailAddress: '',
              DOB: '',
              username: '',
              password: '',
              confirmPassword: '',
              TermsNCondition: 0,
            })
          }
        }}
        transLoading={createdUser && createdUser.loading}
        success={createdUser && createdUser.message}
        error={createdUser && createdUser.error}
      />
      {/* End Success Modal */}
    </GuestMain>
  )
}

RegistrationScreen.defaultProps = {
  background: '#FFF',
  border: '1px solid #ced4da',
}

export default RegistrationScreen
