import {
  GET_FORAPPROVAL_REQ,
  GET_FORAPPROVAL_SUCCESS,
  GET_FORAPPROVAL_FAILED,
  GET_APPROVEDAPPLICATIONS_REQ,
  GET_APPROVEDAPPLICATIONS_SUCCESS,
  GET_APPROVEDAPPLICATIONS_FAILED,
  UPDATE_APPLICATION_STATUS_REQ,
  UPDATE_APPLICATION_STATUS_SUCCESS,
  UPDATE_APPLICATION_STATUS_FAILED,
  GET_COMMENTSLIST_REQ,
  GET_COMMENTSLIST_SUCCESS,
  GET_COMMENTSLIST_FAILED,
  GET_APPLICATIONSLIST_REQ,
  GET_APPLICATIONSLIST_SUCCESS,
  GET_APPLICATIONSLIST_FAILED,
  GET_ORDEROFPAYMENT_REQ,
  GET_ORDEROFPAYMENT_SUCCESS,
  GET_ORDEROFPAYMENT_FAILED,
  GET_PERMITNO_REQ,
  GET_PERMITNO_SUCCESS,
  GET_PERMITNO_FAILED,
  GET_EMPLOYEELIST_REQ,
  GET_EMPLOYEELIST_SUCCESS,
  GET_EMPLOYEELIST_FAILED,
  GET_EMPLOYEEINFO_REQ,
  GET_EMPLOYEEINFO_SUCCESS,
  GET_EMPLOYEEINFO_FAILED,
  GET_FORAPPROVALOP_REQ,
  GET_FORAPPROVALOP_SUCCESS,
  GET_FORAPPROVALOP_FAILED,
  GET_USERLIST_REQ,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_FAILED,
  GET_VALIDID_REQ,
  GET_VALIDID_SUCCESS,
  GET_VALIDID_FAILED,
  GET_ANNUALCOLLECTION_REQ,
  GET_ANNUALCOLLECTION_SUCCESS,
  GET_ANNUALCOLLECTION_FAILED,
  UPDATE_EMPLOYEE_INFO_REQ,
  UPDATE_EMPLOYEE_INFO_SUCCESS,
  UPDATE_EMPLOYEE_INFO_FAILED,
  UPDATE_VERIFYUSER_REQ,
  UPDATE_VERIFYUSER_SUCCESS,
  UPDATE_VERIFYUSER_FAILED,
  INSERT_EMPLOYEE_INFO_REQ,
  INSERT_EMPLOYEE_INFO_SUCCESS,
  INSERT_EMPLOYEE_INFO_FAILED,
  DELETE_EMPLOYEE_INFO_REQ,
  DELETE_EMPLOYEE_INFO_SUCCESS,
  DELETE_EMPLOYEE_INFO_FAILED,
} from '../../constants/Administrator/AdministratorConstants'

export const administratorReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_FORAPPROVAL_REQ:
      return {
        ...state,
        forApprovalList: { loading: true },
      }
    case GET_FORAPPROVAL_SUCCESS:
      return {
        ...state,
        forApprovalList: { loading: false, message: action.payload },
      }
    case GET_FORAPPROVAL_FAILED:
      return {
        ...state,
        forApprovalList: { loading: false, error: action.payload },
      }
    case UPDATE_APPLICATION_STATUS_REQ:
      return {
        ...state,
        updateAppStatus: { loading: true },
      }
    case UPDATE_APPLICATION_STATUS_SUCCESS:
      return {
        ...state,
        updateAppStatus: { loading: false, message: action.payload },
      }
    case UPDATE_APPLICATION_STATUS_FAILED:
      return {
        ...state,
        updateAppStatus: { loading: false, error: action.payload },
      }
    case GET_COMMENTSLIST_REQ:
      return {
        ...state,
        commentsList: { loading: true },
      }
    case GET_COMMENTSLIST_SUCCESS:
      return {
        ...state,
        commentsList: { loading: false, message: action.payload },
      }
    case GET_COMMENTSLIST_FAILED:
      return {
        ...state,
        commentsList: { loading: false, error: action.payload },
      }
    case GET_APPROVEDAPPLICATIONS_REQ:
      return {
        ...state,
        approvedList: { loading: true },
      }
    case GET_APPROVEDAPPLICATIONS_SUCCESS:
      return {
        ...state,
        approvedList: { loading: false, message: action.payload },
      }
    case GET_APPROVEDAPPLICATIONS_FAILED:
      return {
        ...state,
        approvedList: { loading: false, error: action.payload },
      }
    case GET_APPLICATIONSLIST_REQ:
      return {
        ...state,
        applicationsList: { loading: true },
      }
    case GET_APPLICATIONSLIST_SUCCESS:
      return {
        ...state,
        applicationsList: { loading: false, message: action.payload },
      }
    case GET_APPLICATIONSLIST_FAILED:
      return {
        ...state,
        applicationsList: { loading: false, error: action.payload },
      }
    case GET_ORDEROFPAYMENT_REQ:
      return {
        ...state,
        orderofPayment: { loading: true },
      }
    case GET_ORDEROFPAYMENT_SUCCESS:
      return {
        ...state,
        orderofPayment: { loading: false, message: action.payload },
      }
    case GET_ORDEROFPAYMENT_FAILED:
      return {
        ...state,
        orderofPayment: { loading: false, error: action.payload },
      }
    case GET_PERMITNO_REQ:
      return {
        ...state,
        retrievedPermitNo: { loading: true },
      }
    case GET_PERMITNO_SUCCESS:
      return {
        ...state,
        retrievedPermitNo: { loading: false, message: action.payload },
      }
    case GET_PERMITNO_FAILED:
      return {
        ...state,
        retrievedPermitNo: { loading: false, error: action.payload },
      }
    case GET_EMPLOYEELIST_REQ:
      return {
        ...state,
        retrievedEmployeeList: { loading: true },
      }
    case GET_EMPLOYEELIST_SUCCESS:
      return {
        ...state,
        retrievedEmployeeList: { loading: false, message: action.payload },
      }
    case GET_EMPLOYEELIST_FAILED:
      return {
        ...state,
        retrievedEmployeeList: { loading: false, error: action.payload },
      }
    case GET_EMPLOYEEINFO_REQ:
      return {
        ...state,
        retrievedEmployeeInfo: { loading: true },
      }
    case GET_EMPLOYEEINFO_SUCCESS:
      return {
        ...state,
        retrievedEmployeeInfo: { loading: false, message: action.payload },
      }
    case GET_EMPLOYEEINFO_FAILED:
      return {
        ...state,
        retrievedEmployeeInfo: { loading: false, error: action.payload },
      }
    case GET_FORAPPROVALOP_REQ:
      return {
        ...state,
        retrievedForApprovalOp: { loading: true },
      }
    case GET_FORAPPROVALOP_SUCCESS:
      return {
        ...state,
        retrievedForApprovalOp: { loading: false, message: action.payload },
      }
    case GET_FORAPPROVALOP_FAILED:
      return {
        ...state,
        retrievedForApprovalOp: { loading: false, error: action.payload },
      }
    case GET_USERLIST_REQ:
      return {
        ...state,
        retrievedUserVerifyingList: { loading: true },
      }
    case GET_USERLIST_SUCCESS:
      return {
        ...state,
        retrievedUserVerifyingList: { loading: false, message: action.payload },
      }
    case GET_USERLIST_FAILED:
      return {
        ...state,
        retrievedUserVerifyingList: { loading: false, error: action.payload },
      }
    case GET_VALIDID_REQ:
      return {
        ...state,
        retrievedUserValidID: { loading: true },
      }
    case GET_VALIDID_SUCCESS:
      return {
        ...state,
        retrievedUserValidID: { loading: false, message: action.payload },
      }
    case GET_VALIDID_FAILED:
      return {
        ...state,
        retrievedUserValidID: { loading: false, error: action.payload },
      }
    case GET_ANNUALCOLLECTION_REQ:
      return {
        ...state,
        retrievedAnnualCollection: { loading: true },
      }
    case GET_ANNUALCOLLECTION_SUCCESS:
      return {
        ...state,
        retrievedAnnualCollection: { loading: false, message: action.payload },
      }
    case GET_ANNUALCOLLECTION_FAILED:
      return {
        ...state,
        retrievedAnnualCollection: { loading: false, error: action.payload },
      }
    case UPDATE_EMPLOYEE_INFO_REQ:
      return {
        ...state,
        updatedEmployeeInfo: { loading: true },
      }
    case UPDATE_EMPLOYEE_INFO_SUCCESS:
      return {
        ...state,
        updatedEmployeeInfo: { loading: false, message: action.payload },
      }
    case UPDATE_EMPLOYEE_INFO_FAILED:
      return {
        ...state,
        updatedEmployeeInfo: { loading: false, error: action.payload },
      }
    case UPDATE_VERIFYUSER_REQ:
      return {
        ...state,
        updatedVerifyUser: { loading: true },
      }
    case UPDATE_VERIFYUSER_SUCCESS:
      return {
        ...state,
        updatedVerifyUser: { loading: false, message: action.payload },
      }
    case UPDATE_VERIFYUSER_FAILED:
      return {
        ...state,
        updatedVerifyUser: { loading: false, error: action.payload },
      }
    case INSERT_EMPLOYEE_INFO_REQ:
      return {
        ...state,
        createdEmployeeInfo: { loading: true },
      }
    case INSERT_EMPLOYEE_INFO_SUCCESS:
      return {
        ...state,
        createdEmployeeInfo: { loading: false, message: action.payload },
      }
    case INSERT_EMPLOYEE_INFO_FAILED:
      return {
        ...state,
        createdEmployeeInfo: { loading: false, error: action.payload },
      }
    case DELETE_EMPLOYEE_INFO_REQ:
      return {
        ...state,
        deletedEmployeeInfo: { loading: true },
      }
    case DELETE_EMPLOYEE_INFO_SUCCESS:
      return {
        ...state,
        deletedEmployeeInfo: { loading: false, message: action.payload },
      }
    case DELETE_EMPLOYEE_INFO_FAILED:
      return {
        ...state,
        deletedEmployeeInfo: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
