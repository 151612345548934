import React from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Cookie from 'js-cookie'
import Cookies from 'js-cookie'

// SCSS style
import './styles/index.scss'

import ProtectedRoute from './components/reusable/ProtectedRoute'

import LoginScreen from './components/screens/LoginScreen'
import EmployeeLogin from './components/screens/EmployeeLogin'
import ForgotPasswordScreen from './components/screens/ForgotPasswordScreen'
import ResetPasswordScreen from './components/screens/ResetPasswordScreen'

import RegistrationScreen from './components/screens/RegistrationScreen'
import DetailedRegistrationScreen from './components/screens/DetailedRegistrationScreen'
import DownloadScreen from './components/screens/DownloadScreen'
import ContactUsScreen from './components/screens/ContactUsScreen'
import HomeScreen from './components/screens/HomeScreen'
import ApplicationStatusScreen from './components/screens/ApplicationStatusScreen'
import ExpiredTokenScreen from './components/screens/ExpiredTokenScreen'
import RegistrationEngineerScreen from './components/screens/RegistrationEngineerScreen'
import EngineerScreen from './components/screens/AccreditedEngineer/EngineerScreen'
import PermitVerificationScreen from './components/screens/PermitVerificationScreen'
import QrVerifyingScreen from './components/screens/QrVerifyingScreen'

// Routes
import UserRoutes from './components/routes/UserRoutes'
import DcRoutes from './components/routes/DcRoutes'
import InspectorRoutes from './components/routes/InspectorRoutes'
import EvaluatorRoutes from './components/routes/EvaluatorRoutes'
import RecommenderRoutes from './components/routes/RecommenderRoutes'
import AdminRoutes from './components/routes/AdminRoutes'
import ICTRoutes from './components/routes/ICTRoutes'

const App = () => {
  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const cookie = Cookies.get('userInfo') ? Cookies.get('userInfo') : null

  // console.log(Cookie.get('userInfo'))

  const SwitchCase = (props) => {
    switch (props.value) {
      case 'user':
        return <UserRoutes user={props.value} cookies={cookie} />
      case 'document controller':
        return <DcRoutes user={props.value} cookies={cookie} />
      case 'inspector':
        return (
          <InspectorRoutes
            user={props.value}
            subroles={props.subroles}
            cookies={cookie}
          />
        )
      case 'evaluator':
        return (
          <EvaluatorRoutes
            user={props.value}
            subroles={props.subroles}
            cookies={cookie}
          />
        )
      case 'recommender':
        return (
          <RecommenderRoutes
            user={props.value}
            subroles={props.subroles}
            cookies={cookie}
          />
        )
      case 'admin':
        return <AdminRoutes user={props.value} cookies={cookie} />
      case 'ict':
        return <ICTRoutes user={props.value} cookies={cookie} />
      case 'engr':
        return (
          <ProtectedRoute
            exact
            path='/dashboard'
            component={EngineerScreen}
            isAuth={user === null ? false : true}
          />
        )
      default:
        return <Route exact path='/dashboard' component={HomeScreen} />
    }
  }

  return (
    <main>
      <Switch>
        <Route exact path='/' component={HomeScreen} />
        <Route exact path='/login' component={LoginScreen} />
        <Route exact path='/forgotpassword' component={ForgotPasswordScreen} />
        <Route
          path='/reset-password/:id?/:token?'
          component={ResetPasswordScreen}
        />
        <Route exact path='/employee-login' component={EmployeeLogin} />
        {/* <Route exact path='/registration' component={RegistrationScreen} /> */}
        <Route
          exact
          path='/registration'
          component={RegistrationEngineerScreen}
        />
        <Route
          exact
          path='/registration-additional-info'
          component={DetailedRegistrationScreen}
        />
        <Route exact path='/downloadable-forms' component={DownloadScreen} />
        <Route exact path='/contact-us' component={ContactUsScreen} />
        {/* <Route
          exact
          path='/application-list'
          component={ApplicationStatusScreen}
        /> */}
        <Route
          exact
          path='/permit-verification'
          component={PermitVerificationScreen}
        />
        <Route path='/verify-permit/:id?' component={QrVerifyingScreen} />
        {userCredentials && userCredentials.roles && (
          <SwitchCase
            value={userCredentials && userCredentials.roles}
            subroles={userCredentials && userCredentials.subroles}
          />
        )}
        <Route component={ExpiredTokenScreen} />
      </Switch>
    </main>
  )
}

export const Main = withRouter((props) => {
  // console.log(props)
  return <App {...props} />
})

export default App
