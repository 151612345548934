export const CREATE_CERTOCCUPANCY_ASSESSMENT_REQ =
  'CREATE_CERTOCCUPANCY_ASSESSMENT_REQ'
export const CREATE_CERTOCCUPANCY_ASSESSMENT_SUCCESS =
  'CREATE_CERTOCCUPANCY_ASSESSMENT_SUCCESS'
export const CREATE_CERTOCCUPANCY_ASSESSMENT_FAILED =
  'CREATE_CERTOCCUPANCY_ASSESSMENT_FAILED'

export const UPDATE_CERTOCCUPANCY_ASSESSMENT_REQ =
  'UPDATE_CERTOCCUPANCY_ASSESSMENT_REQ'
export const UPDATE_CERTOCCUPANCY_ASSESSMENT_SUCCESS =
  'UPDATE_CERTOCCUPANCY_ASSESSMENT_SUCCESS'
export const UPDATE_CERTOCCUPANCY_ASSESSMENT_FAILED =
  'UPDATE_CERTOCCUPANCY_ASSESSMENT_FAILED'

export const GET_CERTOCCUPANCY_ASSESSMENT_REQ =
  'GET_CERTOCCUPANCY_ASSESSMENT_REQ'
export const GET_CERTOCCUPANCY_ASSESSMENT_SUCCESS =
  'GET_CERTOCCUPANCY_ASSESSMENT_SUCCESS'
export const GET_CERTOCCUPANCY_ASSESSMENT_FAILED =
  'GET_CERTOCCUPANCY_ASSESSMENT_FAILED'
