// RETRIEVE
export const GET_EMPLOYEEDETAILS_REQ = 'GET_EMPLOYEEDETAILS_REQ'
export const GET_EMPLOYEEDETAILS_SUCCESS = 'GET_EMPLOYEEDETAILS_SUCCESS'
export const GET_EMPLOYEEDETAILS_FAILED = 'GET_EMPLOYEEDETAILS_FAILED'

export const GET_ALLAPPLICATIONS_REQ = 'GET_ALLAPPLICATIONS_REQ'
export const GET_ALLAPPLICATIONS_SUCCESS = 'GET_ALLAPPLICATIONS_SUCCESS'
export const GET_ALLAPPLICATIONS_FAILED = 'GET_ALLAPPLICATIONS_FAILED'

export const GET_FILTEREDAPPLICATION_REQ = 'GET_FILTEREDAPPLICATION_REQ'
export const GET_FILTEREDAPPLICATION_SUCCESS = 'GET_FILTEREDAPPLICATION_SUCCESS'
export const GET_FILTEREDAPPLICATION_FAILED = 'GET_FILTEREDAPPLICATION_FAILED'

export const GET_APPCOMMENTS_REQ = 'GET_APPCOMMENTS_REQ'
export const GET_APPCOMMENTS_SUCCESS = 'GET_APPCOMMENTS_SUCCESS'
export const GET_APPCOMMENTS_FAILED = 'GET_APPCOMMENTS_FAILED'

export const GET_APPLICATIONINFORMATION_REQ = 'GET_APPLICATIONINFORMATION_REQ'
export const GET_APPLICATIONINFORMATION_SUCCESS =
  'GET_APPLICATIONINFORMATION_SUCCESS'
export const GET_APPLICATIONINFORMATION_FAILED =
  'GET_APPLICATIONINFORMATION_FAILED'

export const GET_REQUIREMENTLIST_REQ = 'GET_REQUIREMENTLIST_REQ'
export const GET_REQUIREMENTLIST_SUCCESS = 'GET_REQUIREMENTLIST_SUCCESS'
export const GET_REQUIREMENTLIST_FAILED = 'GET_REQUIREMENTLIST_FAILED'

export const GET_SUBMITTEDREQUIREMENTS_REQ = 'GET_SUBMITTEDREQUIREMENTS_REQ'
export const GET_SUBMITTEDREQUIREMENTS_SUCCESS =
  'GET_SUBMITTEDREQUIREMENTS_SUCCESS'
export const GET_SUBMITTEDREQUIREMENTS_FAILED =
  'GET_SUBMITTEDREQUIREMENTS_FAILED'

export const GET_TRADEEVALUATORS_REQ = 'GET_TRADEEVALUATORS_REQ'
export const GET_TRADEEVALUATORS_SUCCESS = 'GET_TRADEEVALUATORS_SUCCESS'
export const GET_TRADEEVALUATORS_FAILED = 'GET_TRADEEVALUATORS_FAILED'

export const GET_INSPECTORS_REQ = 'GET_INSPECTORS_REQ'
export const GET_INSPECTORS_SUCCESS = 'GET_INSPECTORS_SUCCESS'
export const GET_INSPECTORS_FAILED = 'GET_INSPECTORS_FAILED'

export const GET_SUBMITTEDEVALUATORS_REQ = 'GET_SUBMITTEDEVALUATORS_REQ'
export const GET_SUBMITTEDEVALUATORS_SUCCESS = 'GET_SUBMITTEDEVALUATORS_SUCCESS'
export const GET_SUBMITTEDEVALUATORS_FAILED = 'GET_SUBMITTEDEVALUATORS_FAILED'

export const GET_SUBMITTEDINSPECTOR_REQ = 'GET_SUBMITTEDINSPECTOR_REQ'
export const GET_SUBMITTEDINSPECTOR_SUCCESS = 'GET_SUBMITTEDINSPECTOR_SUCCESS'
export const GET_SUBMITTEDINSPECTOR_FAILED = 'GET_SUBMITTEDINSPECTOR_FAILED'

export const GET_ENGINEERINFORMATION_REQ = 'GET_ENGINEERINFORMATION_REQ'
export const GET_ENGINEERINFORMATION_SUCCESS = 'GET_ENGINEERINFORMATION_SUCCESS'
export const GET_ENGINEERINFORMATION_FAILED = 'GET_ENGINEERINFORMATION_FAILED'

export const GET_ORDERPAYMENT_REQ = 'GET_ORDERPAYMENT_REQ'
export const GET_ORDERPAYMENT_SUCCESS = 'GET_ORDERPAYMENT_SUCCESS'
export const GET_ORDERPAYMENT_FAILED = 'GET_ORDERPAYMENT_FAILED'

export const GET_AMOUNTPAID_REQ = 'GET_AMOUNTPAID_REQ'
export const GET_AMOUNTPAID_SUCCESS = 'GET_AMOUNTPAID_SUCCESS'
export const GET_AMOUNTPAID_FAILED = 'GET_AMOUNTPAID_FAILED'

export const GET_EVALUATIONSHEET_REQ = 'GET_EVALUATIONSHEET_REQ'
export const GET_EVALUATIONSHEET_SUCCESS = 'GET_EVALUATIONSHEET_SUCCESS'
export const GET_EVALUATIONSHEET_FAILED = 'GET_EVALUATIONSHEET_FAILED'

export const GET_COLLECTIONREPORT_REQ = 'GET_COLLECTIONREPORT_REQ'
export const GET_COLLECTIONREPORT_SUCCESS = 'GET_COLLECTIONREPORT_SUCCESS'
export const GET_COLLECTIONREPORT_FAILED = 'GET_COLLECTIONREPORT_FAILED'

export const GET_CREATEDAPP_REQ = 'GET_CREATEDAPP_REQ'
export const GET_CREATEDAPP_SUCCESS = 'GET_CREATEDAPP_SUCCESS'
export const GET_CREATEDAPP_FAILED = 'GET_CREATEDAPP_FAILED'

export const GET_GUEST_APPLICATION_REQ = 'GET_GUEST_APPLICATION_REQ'
export const GET_GUEST_APPLICATION_SUCCESS = 'GET_GUEST_APPLICATION_SUCCESS'
export const GET_GUEST_APPLICATION_FAILED = 'GET_GUEST_APPLICATION_FAILED'

export const GET_GUEST_APPLICATION_BYID_REQ = 'GET_GUEST_APPLICATION_BYID_REQ'
export const GET_GUEST_APPLICATION_BYID_SUCCESS =
  'GET_GUEST_APPLICATION_BYID_SUCCESS'
export const GET_GUEST_APPLICATION_BYID_FAILED =
  'GET_GUEST_APPLICATION_BYID_FAILED'

export const GET_CFEI_REQ = 'GET_CFEI_REQ'
export const GET_CFEI_SUCCESS = 'GET_CFEI_SUCCESS'
export const GET_CFEI_FAILED = 'GET_CFEI_FAILED'

export const GET_CONTRACTORSTAX_REQ = 'GET_CONTRACTORSTAX_REQ'
export const GET_CONTRACTORSTAX_SUCCESS = 'GET_CONTRACTORSTAX_SUCCESS'
export const GET_CONTRACTORSTAX_FAILED = 'GET_CONTRACTORSTAX_FAILED'

export const GET_APPSTATUSTRACKING_REQ = 'GET_APPSTATUSTRACKING_REQ'
export const GET_APPSTATUSTRACKING_SUCCESS = 'GET_APPSTATUSTRACKING_SUCCESS'
export const GET_APPSTATUSTRACKING_FAILED = 'GET_APPSTATUSTRACKING_FAILED'

export const GET_RPTVERIFICATION_REQ = 'GET_RPTVERIFICATION_REQ'
export const GET_RPTVERIFICATION_SUCCESS = 'GET_RPTVERIFICATION_SUCCESS'
export const GET_RPTVERIFICATION_FAILED = 'GET_RPTVERIFICATION_FAILED'

export const GET_RPTLOGIN_REQ = 'GET_RPTLOGIN_REQ'
export const GET_RPTLOGIN_SUCCESS = 'GET_RPTLOGIN_SUCCESS'
export const GET_RPTLOGIN_FAILED = 'GET_RPTLOGIN_FAILED'

export const GET_CTVERIFICATION_REQ = 'GET_CTVERIFICATION_REQ'
export const GET_CTVERIFICATION_SUCCESS = 'GET_CTVERIFICATION_SUCCESS'
export const GET_CTVERIFICATION_FAILED = 'GET_CTVERIFICATION_FAILED'

export const GET_CTLOGIN_REQ = 'GET_CTLOGIN_REQ'
export const GET_CTLOGIN_SUCCESS = 'GET_CTLOGIN_SUCCESS'
export const GET_CTLOGIN_FAILED = 'GET_CTLOGIN_FAILED'

// END RETRIEVE

// UPDATE

export const UPDATE_APPSTATUS_REQ = 'UPDATE_APPSTATUS_REQ'
export const UPDATE_APPSTATUS_SUCCESS = 'UPDATE_APPSTATUS_SUCCESS'
export const UPDATE_APPSTATUS_FAILED = 'UPDATE_APPSTATUS_FAILED'

export const UPDATE_RELEASEPERMIT_REQ = 'UPDATE_RELEASEPERMIT_REQ'
export const UPDATE_RELEASEPERMIT_SUCCESS = 'UPDATE_RELEASEPERMIT_SUCCESS'
export const UPDATE_RELEASEPERMIT_FAILED = 'UPDATE_RELEASEPERMIT_FAILED'

export const UPDATE_EMPLOYEEDETAILS_REQ = 'UPDATE_EMPLOYEEDETAILS_REQ'
export const UPDATE_EMPLOYEEDETAILS_SUCCESS = 'UPDATE_EMPLOYEEDETAILS_SUCCESS'
export const UPDATE_EMPLOYEEDETAILS_FAILED = 'UPDATE_EMPLOYEEDETAILS_FAILED'

export const UPDATE_EMPLOYEECREDENTIALS_REQ = 'UPDATE_EMPLOYEECREDENTIALS_REQ'
export const UPDATE_EMPLOYEECREDENTIALS_SUCCESS =
  'UPDATE_EMPLOYEECREDENTIALS_SUCCESS'
export const UPDATE_EMPLOYEECREDENTIALS_FAILED =
  'UPDATE_EMPLOYEECREDENTIALS_FAILED'

export const UPDATE_APPINFORMATION_REQ = 'UPDATE_APPINFORMATION_REQ'
export const UPDATE_APPINFORMATION_SUCCESS = 'UPDATE_APPINFORMATION_SUCCESS'
export const UPDATE_APPINFORMATION_FAILED = 'UPDATE_APPINFORMATION_FAILED'

export const UPDATE_SUBMITTEDREQUIREMENTS_REQ =
  'UPDATE_SUBMITTEDREQUIREMENTS_REQ'
export const UPDATE_SUBMITTEDREQUIREMENTS_SUCCESS =
  'UPDATE_SUBMITTEDREQUIREMENTS_SUCCESS'
export const UPDATE_SUBMITTEDREQUIREMENTS_FAILED =
  'UPDATE_SUBMITTEDREQUIREMENTS_FAILED'

export const UPDATE_ENGINEERINFORMATION_REQ = 'UPDATE_ENGINEERINFORMATION_REQ'
export const UPDATE_ENGINEERINFORMATION_SUCCESS =
  'UPDATE_ENGINEERINFORMATION_SUCCESS'
export const UPDATE_ENGINEERINFORMATION_FAILED =
  'UPDATE_ENGINEERINFORMATION_FAILED'

export const UPDATE_EVALUATORS_REQ = 'UPDATE_EVALUATORS_REQ'
export const UPDATE_EVALUATORS_SUCCESS = 'UPDATE_EVALUATORS_SUCCESS'
export const UPDATE_EVALUATORS_FAILED = 'UPDATE_EVALUATORS_FAILED'

export const UPDATE_INSPECTOR_REQ = 'UPDATE_INSPECTOR_REQ'
export const UPDATE_INSPECTOR_SUCCESS = 'UPDATE_INSPECTOR_SUCCESS'
export const UPDATE_INSPECTOR_FAILED = 'UPDATE_INSPECTOR_FAILED'

export const UPDATE_ORDEROFPAYMENT_REQ = 'UPDATE_ORDEROFPAYMENT_REQ'
export const UPDATE_ORDEROFPAYMENT_SUCCESS = 'UPDATE_ORDEROFPAYMENT_SUCCESS'
export const UPDATE_ORDEROFPAYMENT_FAILED = 'UPDATE_ORDEROFPAYMENT_FAILED'

export const UPDATE_GUEST_APPLICATION_REQ = 'UPDATE_GUEST_APPLICATION_REQ'
export const UPDATE_GUEST_APPLICATION_SUCCESS =
  'UPDATE_GUEST_APPLICATION_SUCCESS'
export const UPDATE_GUEST_APPLICATION_FAILED = 'UPDATE_GUEST_APPLICATION_FAILED'

export const UPDATE_CFEI_REQ = 'UPDATE_CFEI_REQ'
export const UPDATE_CFEI_SUCCESS = 'UPDATE_CFEI_SUCCESS'
export const UPDATE_CFEI_FAILED = 'UPDATE_CFEI_FAILED'

export const UPDATE_CONTRACTORSTAX_REQ = 'UPDATE_CONTRACTORSTAX_REQ'
export const UPDATE_CONTRACTORSTAX_SUCCESS = 'UPDATE_CONTRACTORSTAX_SUCCESS'
export const UPDATE_CONTRACTORSTAX_FAILED = 'UPDATE_CONTRACTORSTAX_FAILED'

// END UPDATE

// INSERT

export const INSERT_APPINFO_REQ = 'INSERT_APPINFO_REQ'
export const INSERT_APPINFO_SUCCESS = 'INSERT_APPINFO_SUCCESS'
export const INSERT_APPINFO_FAILED = 'INSERT_APPINFO_FAILED'

export const INSERT_ORDEROFPAYMENT_REQ = 'INSERT_ORDEROFPAYMENT_REQ'
export const INSERT_ORDEROFPAYMENT_SUCCESS = 'INSERT_ORDEROFPAYMENT_SUCCESS'
export const INSERT_ORDEROFPAYMENT_FAILED = 'INSERT_ORDEROFPAYMENT_FAILED'

export const INSERT_NEWSANDUPDATES_REQ = 'INSERT_NEWSANDUPDATES_REQ'
export const INSERT_NEWSANDUPDATES_SUCCESS = 'INSERT_NEWSANDUPDATES_SUCCESS'
export const INSERT_NEWSANDUPDATES_FAILED = 'INSERT_NEWSANDUPDATES_FAILED'

export const INSERT_EVALUATIONSHEET_REQ = 'INSERT_EVALUATIONSHEET_REQ'
export const INSERT_EVALUATIONSHEET_SUCCESS = 'INSERT_EVALUATIONSHEET_SUCCESS'
export const INSERT_EVALUATIONSHEET_FAILED = 'INSERT_EVALUATIONSHEET_FAILED'

export const INSERT_APPLICATION_REQ = 'INSERT_APPLICATION_REQ'
export const INSERT_APPLICATION_SUCCESS = 'INSERT_APPLICATION_SUCCESS'
export const INSERT_APPLICATION_FAILED = 'INSERT_APPLICATION_FAILED'

export const INSERT_GUEST_APPLICATION_REQ = 'INSERT_GUEST_APPLICATION_REQ'
export const INSERT_GUEST_APPLICATION_SUCCESS =
  'INSERT_GUEST_APPLICATION_SUCCESS'
export const INSERT_GUEST_APPLICATION_FAILED = 'INSERT_GUEST_APPLICATION_FAILED'

export const INSERT_CFEI_REQ = 'INSERT_CFEI_REQ'
export const INSERT_CFEI_SUCCESS = 'INSERT_CFEI_SUCCESS'
export const INSERT_CFEI_FAILED = 'INSERT_CFEI_FAILED'

// END INSERT

// DELETE

export const DELETE_EVALUATIONSHEET_REQ = 'DELETE_EVALUATIONSHEET_REQ'
export const DELETE_EVALUATIONSHEET_SUCCESS = 'DELETE_EVALUATIONSHEET_SUCCESS'
export const DELETE_EVALUATIONSHEET_FAILED = 'DELETE_EVALUATIONSHEET_FAILED'

export const DELETE_GUEST_APPLICATION_REQ = 'DELETE_GUEST_APPLICATION_REQ'
export const DELETE_GUEST_APPLICATION_SUCCESS =
  'DELETE_GUEST_APPLICATION_SUCCESS'
export const DELETE_GUEST_APPLICATION_FAILED = 'DELETE_GUEST_APPLICATION_FAILED'

export const DELETE_CFEI_REQ = 'DELETE_CFEI_REQ'
export const DELETE_CFEI_SUCCESS = 'DELETE_CFEI_SUCCESS'
export const DELETE_CFEI_FAILED = 'DELETE_CFEI_FAILED'

// END DELETE
