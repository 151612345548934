import React, { useState, useEffect } from 'react'
import {
  Form,
  Button,
  Row,
  Col,
  Container,
  Table,
  Card,
  Spinner,
} from 'react-bootstrap'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'

// Data
import { electronicsData } from '../../../data/electronincsData'

// Actions
import {
  addEvaluation,
  retrieveAppDetails,
  retrieveEvaluationDetails,
  editEvaluation,
} from '../../redux/actions/evaluatorActions'

// Components
import SuccessModal from '../../reusable/SuccessModal'

const CreateElectronicsScreen = ({ history, match }) => {
  // Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const {
    getAppDetails,
    createEvaluation,
    evaluationDetails,
    updateEvaluation,
  } = evaluator

  const [state, setState] = useState([])
  const [scope, setScope] = useState('')
  const [modal, setModal] = useState({
    successModal: false,
    updateModal: false,
  })
  const unique = [
    ...new Map(
      electronicsData.map((item) => [item.code_header, item])
    ).values(),
  ]

  const isUpdating = JSON.parse(localStorage.getItem('evaluationSelected'))
    ? JSON.parse(localStorage.getItem('evaluationSelected'))
    : null
  // End Declaration

  // Functions
  const handleYestoAll = () => {
    electronicsData.forEach((e) => {
      setState((currState) => {
        return currState.map((el) => {
          if (el.id === e.id) {
            return { ...el, compliance: 1 }
          } else {
            return el
          }
        })
      })
    })
  }

  const handleNotoAll = () => {
    electronicsData.forEach((e) => {
      setState((currState) => {
        return currState.map((el) => {
          if (el.id === e.id) {
            return { ...el, compliance: 0 }
          } else {
            return el
          }
        })
      })
    })
  }

  const handleNAtoAll = () => {
    electronicsData.forEach((e) => {
      setState((currState) => {
        return currState.map((el) => {
          if (el.id === e.id) {
            return { ...el, compliance: 2 }
          } else {
            return el
          }
        })
      })
    })
  }

  const handleYes = (e) => {
    // const newState = state.map((el) => {
    //   if (el.id === parseInt(e.target.name)) {
    //     return { ...el, compliance: 1 }
    //   } else {
    //     return el
    //   }
    // })
    // setState(newState)

    setState((currState) => {
      return currState.map((el) => {
        if (el.id === parseInt(e.target.name)) {
          return { ...el, compliance: 1 }
        } else {
          return el
        }
      })
    })
  }

  const handleNo = (e) => {
    setState((currState) => {
      return currState.map((el) => {
        if (el.id === parseInt(e.target.name)) {
          return { ...el, compliance: 0 }
        } else {
          return el
        }
      })
    })
  }

  const handleNA = (e) => {
    setState((currState) => {
      return currState.map((el) => {
        if (el.id === parseInt(e.target.name)) {
          return { ...el, compliance: 2 }
        } else {
          return el
        }
      })
    })
  }

  const handleRemarks = (e) => {
    setState((currState) => {
      return currState.map((el) => {
        if (el.id === parseInt(e.target.name)) {
          return { ...el, id_remarks: e.target.value }
        } else {
          return el
        }
      })
    })
  }

  const handleBtnCancel = () => {
    if (isUpdating !== null) {
      history.push('/evaluated-list')
      localStorage.removeItem('evaluationSelected')
      setState([])
      setScope('')
    } else {
      // history.push('/for-evaluation-list')
      history.push('/dashboard')
      localStorage.removeItem('evaluationSelected')
      setState([])
      setScope('')
    }
  }

  const handleCreateSubmit = () => {
    setModal({ successModal: true })
    dispatch(
      addEvaluation({
        scope: scope,
        evaluations: state,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
      })
    )
  }

  const handleUpdateSubmit = () => {
    setModal({ updateModal: true })
    dispatch(
      editEvaluation({
        scope: scope,
        evaluations: state,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
      })
    )
  }
  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(
      retrieveAppDetails({ appID: match.params.id, userID: userCredentials.id })
    )

    isUpdating !== null &&
      dispatch(
        retrieveEvaluationDetails({
          evalAppID: isUpdating.evalAppID,
          userID: userCredentials.id,
        })
      )
  }, [dispatch, match, userCredentials])

  useEffect(() => {
    if (isUpdating !== null) {
      setState(() => [])
      if (evaluationDetails && evaluationDetails.message) {
        evaluationDetails.message.map((data) => {
          setState((state) => [
            ...state,
            {
              detail_id: data.id,
              id: data.id_per_code,
              code_header: data.code_header,
              header: data.header,
              code_id: data.code_id,
              code_name: data.code_name,
              compliance: data.compliance,
              id_remarks: data.id_remarks,
            },
          ])
        })
        setScope(() => evaluationDetails.message[0].scope)
      }
    } else {
      electronicsData.map((data) => {
        setState((state) => [
          ...state,
          {
            id: data.id,
            code_header: data.code_header,
            header: data.header,
            code_id: data.code_id,
            code_name: data.code_name,
            compliance: data.compliance,
            id_remarks: data.id_remarks,
          },
        ])
      })
    }
  }, [electronicsData, evaluationDetails])
  // End UseEffect

  // console.log(isUpdating)
  // console.log(state.length > 0 && state.find((x) => x.id === 1))
  // console.log(state)
  // console.log(scope)

  return (
    <UserMain>
      <Container className='bg-white py-3'>
        {state.length > 0 && (
          <>
            <h1 className='text-center'>ELECTRONICS PLANS AND DOCUMENTS</h1>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                isUpdating !== null
                  ? handleUpdateSubmit()
                  : handleCreateSubmit()
              }}
            >
              <Table responsive bordered className='border border-secondary'>
                <thead>
                  <tr>
                    <th colSpan={4} className='text-center'>
                      <h5>Check Application</h5>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {[
                      '1. Single Dwelling Residential Building not more than 3-storey and shall not exceed 1,500 sq. m floor area',
                      '2. Commercial Building of not more than 2-storey',
                      ' 3. Renovation within a mall with issued Building Permit',
                      '4. Warehouse storing non-hazardous substance',
                    ].map((app, index) => (
                      <td key={index}>
                        <Row>
                          <Col xl={2}>
                            <Form.Check
                              name={app}
                              type='radio'
                              onChange={(e) => setScope(e.target.name)}
                              value={scope}
                              checked={scope === app && true}
                            />
                          </Col>
                          <Form.Group as={Col} xl={10}>
                            <Form.Label>{app}</Form.Label>
                          </Form.Group>
                        </Row>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>

              {/* <Card className='mt-4'>
                <Card.Body>
                  <Row>
                    <Col xl='8'>Batch select of evaluation</Col>
                    <Col xl='4'>
                      <Form.Check
                        inline
                        name='All'
                        type='radio'
                        label='Yes to all'
                        onClick={() => handleYestoAll()}
                      />

                      <Form.Check
                        inline
                        name='All'
                        type='radio'
                        label='No to all'
                        onClick={() => handleNotoAll()}
                      />
                      <Form.Check
                        inline
                        name='All'
                        type='radio'
                        label='N/A to all'
                        onClick={() => handleNAtoAll()}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card> */}
              <Table
                size='sm'
                responsive
                bordered
                className='border border-secondary'
              >
                <thead className='bg-success text-light  align-middle'>
                  <tr>
                    <th className='text-center text-nowrap'>
                      <h4 className='text-light'>Code ID</h4>
                    </th>
                    <th className='text-center'>
                      <h4 className='text-light'>JMC and Code Regulations</h4>
                    </th>
                    <th className='text-center pb-0'>
                      <h4 className='text-light'>Compliance</h4>
                      <h6 className='text-light'>(Mark Check / Status)</h6>
                    </th>
                    <th className='text-center px-5'>
                      <h4 className='text-light  mx-5 px-5'>ID</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='bg-dark text-white'>
                    <td></td>
                    <td className='align-middle'>Batch Select</td>
                    <td colSpan={2} className='align-middle'>
                      <Form.Check
                        inline
                        name='All'
                        type='radio'
                        label='Yes'
                        onClick={() => handleYestoAll()}
                      />

                      <Form.Check
                        inline
                        name='All'
                        type='radio'
                        label='No'
                        onClick={() => handleNotoAll()}
                      />
                      <Form.Check
                        inline
                        name='All'
                        type='radio'
                        label='N/A'
                        onClick={() => handleNAtoAll()}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan={3}>Reference - Philippine Electronics Code</td>
                  </tr>
                  {unique.map((data, index) => (
                    <>
                      <tr style={{ background: '#D8FFDB ' }}>
                        <td>{data.code_header}</td>
                        <td colSpan={3}>{data.header}</td>
                      </tr>
                      {electronicsData.map((info, index) => (
                        <>
                          {data.code_header === info.code_header && (
                            <tr>
                              <td className='text-end text-nowrap'>
                                {info.code_id}
                              </td>
                              <td>{info.code_name}</td>
                              <td className='d-flex align-middle'>
                                <Form.Check
                                  name={info.id}
                                  type='radio'
                                  label='YES'
                                  value={
                                    state.length > 0 &&
                                    state.find((x) => x.id === info.id)
                                      .compliance
                                  }
                                  checked={
                                    state.length > 0 &&
                                    state.find((x) => x.id === info.id)
                                      .compliance === 1 &&
                                    true
                                  }
                                  onChange={(e) => handleYes(e)}
                                  required
                                />
                                <Form.Check
                                  name={info.id}
                                  type='radio'
                                  label='NO'
                                  className='mx-2'
                                  value={
                                    state.length > 0 &&
                                    state.find((x) => x.id === info.id)
                                      .compliance
                                  }
                                  checked={
                                    state.length > 0 &&
                                    state.find((x) => x.id === info.id)
                                      .compliance === 0 &&
                                    true
                                  }
                                  onChange={handleNo}
                                  required
                                />
                                <Form.Check
                                  name={info.id}
                                  type='radio'
                                  label='N/A'
                                  value={
                                    state.length > 0 &&
                                    state.find((x) => x.id === info.id)
                                      .compliance
                                  }
                                  checked={
                                    state.length > 0 &&
                                    state.find((x) => x.id === info.id)
                                      .compliance === 2 &&
                                    true
                                  }
                                  onChange={handleNA}
                                  required
                                />
                              </td>
                              <td className='py-1 align-middle'>
                                <Form.Control
                                  style={{
                                    background: '#FFF',
                                    border: '1px solid #ced4da',
                                  }}
                                  type='text'
                                  size='sm'
                                  onChange={handleRemarks}
                                  name={info.id}
                                  value={
                                    state.length > 0 &&
                                    state.find((x) => x.id === info.id)
                                      .id_remarks
                                  }
                                />
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </>
                  ))}
                </tbody>
              </Table>

              <Row>
                <Col md={12} className='pt-1 pb-4 text-end'>
                  <Button variant='info' type='submit'>
                    Submit
                  </Button>

                  <Button
                    variant='danger'
                    className='mx-2'
                    onClick={handleBtnCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}

        {state.length <= 0 && (
          <Row className='my-4 mx-1'>
            <Col xl={12}>
              <Card>
                <Card.Body className='mt-2'>
                  <div className='text-center pt-3'>
                    <Spinner animation='border' />
                    <h3>Loading...</h3>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          isUpdating !== null
            ? history.push('/evaluated-list')
            : // : history.push('/for-evaluation-list')
              history.push('/dashboard')
        }}
        transLoading={createEvaluation && createEvaluation.loading}
        success={createEvaluation && createEvaluation.message}
        error={createEvaluation && createEvaluation.error}
      />
      {/* End Success Modal */}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateModal}
        onHide={() => {
          setModal({ updateModal: false })
          isUpdating !== null
            ? history.push('/evaluated-list')
            : // : history.push('/for-evaluation-list')
              history.push('/dashboard')
        }}
        transLoading={updateEvaluation && updateEvaluation.loading}
        success={updateEvaluation && updateEvaluation.message}
        error={updateEvaluation && updateEvaluation.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(CreateElectronicsScreen)
