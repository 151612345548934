import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  InputGroup,
  Button,
  Table,
  Pagination,
  Modal,
} from 'react-bootstrap'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FaSearch, FaEye, FaCheck, FaClipboardCheck } from 'react-icons/fa'
import moment from 'moment'

//Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

const ReturnedListScreen = () => {
  return (
    <UserMain>
      <Container fluid>
        <h1>Hello Mother Father</h1>
      </Container>
    </UserMain>
  )
}

export default ReturnedListScreen
