import Cookie from 'js-cookie'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import {
  Form,
  Button,
  Row,
  Col,
  Container,
  Table,
  Card,
  Modal,
  Spinner,
} from 'react-bootstrap'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  FaHouseUser,
  FaFileAlt,
  FaPencilAlt,
  FaFolderOpen,
} from 'react-icons/fa'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'

// Table Data
import { createReport } from '../../../data/createReport'

// Actions
import {
  updateReport,
  createNewReport,
  attachImageReport,
  reAttachImageReport,
  retrieveForReport,
  retrieveReport,
  retrieveImageReport,
} from '../../redux/actions/inspectionActions'

// Components
import SuccessModal from '../../reusable/SuccessModal'

const EditReportScreen = (props) => {
  // Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const inspection = useSelector((state) => state.inspection)

  const {
    forReport,
    existingReport,
    editReport,
    addReport,
    existingImageReport,
    attachedImages,
    reAttachedImages,
    isUpdating,
  } = inspection

  const [report, setReport] = useState([])

  const [uploadImages, setUploadImages] = useState(null)

  const [showImages, setShowImages] = useState(false)

  const [value, setValue] = useState({
    category: '',
  })
  const [currentIndex, setCurrentIndex] = useState()
  const [currentRemarks, setCurrentRemarks] = useState('')

  let currentValue = report

  //   const isUpdating = JSON.parse(localStorage.getItem('reportSelected'))
  //     ? JSON.parse(localStorage.getItem('reportSelected'))
  //     : null

  const [modal, setModal] = useState({
    updateSuccess: false,
    createSuccess: false,
    attachedSuccess: false,
    reAttachedSuccess: false,
  })

  // End Declaration

  // Functions
  const handleChangeYes = (e) => {
    // let index = report.findIndex((el) => el[0] === e.target.name)
    // if (index >= 0) {
    //   currentValue[index][1] = 1
    //   setReport(currentValue)
    //   setCurrentIndex(index)
    // } else {
    //   setReport([...report, [e.target.name, 1, '']])
    // }

    setReport((currReport) => {
      return currReport.map((el) => {
        if (el[0] === e.target.name) {
          return [el[0], 1, '', el[3]]
        } else {
          return el
        }
      })
    })
  }

  const handleChangeNo = (e) => {
    setReport((currReport) => {
      return currReport.map((el) => {
        if (el[0] === e.target.name) {
          return [el[0], 0, '', el[3]]
        } else {
          return el
        }
      })
    })
  }

  const handleUpdateSubmit = () => {
    if (window.confirm('Are you sure you want to this inspection report?')) {
      dispatch(
        updateReport({
          category: value.category,
          reports: report,
          inspectionID: forReport.message.id,
          userID: userCredentials.id,
          images: uploadImages,
        })
      )
      setModal({ updateSuccess: true })
    }
  }

  const handleBackBtn = () => {
    props.history.push(`/inspection-list`)
    localStorage.removeItem('reportSelected')
    setReport([])
    setValue({
      category: '',
    })
  }

  const handleYestoAll = (value) => {
    value.forEach((e) => {
      setReport((currReport) => {
        return currReport.map((el) => {
          if (el[0] === e) {
            return [el[0], 1, '', el[3]]
          } else {
            return el
          }
        })
      })
    })
  }

  const handleNotoAll = (value) => {
    value.forEach((e) => {
      setReport((currReport) => {
        return currReport.map((el) => {
          if (el[0] === e) {
            return [el[0], 0, '', el[3]]
          } else {
            return el
          }
        })
      })
    })
  }

  const handleUploadImage = async (e) => {
    let files = e.target.files
    if (e.target.files.length > 3) {
      alert('You are only allowed to upload a maximum of 3 images!')
    }
    setUploadImages(files)
  }

  const handleUploadImages = () => {
    if (value.category === '') {
      alert('Title of the inspection is required.')
    } else {
      dispatch(
        attachImageReport({
          inspectionID: forReport.message.id,
          userID: userCredentials.id,
          images: uploadImages,
          category: value.category,
        })
      )
      setShowImages(false)
      setModal({ attachedSuccess: true })
    }
  }

  const handleUpdateImages = () => {
    if (value.category === '') {
      alert('Title of the inspection is required.')
    } else {
      dispatch(
        reAttachImageReport({
          inspectionID: forReport.message.id,
          userID: userCredentials.id,
          images: uploadImages,
          category: value.category,
        })
      )
      setShowImages(false)
      setModal({ reAttachedSuccess: true })
    }
  }

  const handleCreateSubmit = async () => {
    // const formData = new FormData()
    // Object.values(uploadImages).forEach((e) => {
    //   formData.append('images', e)
    // })

    // try {
    //   const config = {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       Authorization: `Bearer ${Cookie.get('userInfo')}`,
    //     },
    //   }

    //   const { data } = await axios.post(
    //     `/api/inspection/add-report`,
    //     formData,
    //     config
    //   )
    // } catch (err) {
    //   console.log(err)
    // }

    dispatch(
      createNewReport({
        category: value.category,
        reports: report,
        inspectionID: forReport.message.id,
        userID: userCredentials.id,
        images: uploadImages,
      })
    )
    setModal({ createSuccess: true })
  }

  // End Functions

  // useEffect
  useEffect(() => {
    dispatch(
      retrieveForReport({
        appID: props.match.params.id,
        userID: userCredentials.id,
      })
    )

    dispatch(
      retrieveReport({
        inspectionID:
          isUpdating !== null
            ? isUpdating.inspectionID
            : props.location.state.inspectionID,
        userID: userCredentials.id,
        category:
          isUpdating !== null
            ? isUpdating.category
            : props.location.state.category,
        timeStamp:
          isUpdating !== null
            ? isUpdating.timeStamp
            : props.location.state.timeStamp,
      })
    )
  }, [dispatch, props.match, userCredentials])

  //   console.log(props.location.state.inspectionID)

  useEffect(() => {
    if (existingReport && existingReport.message) {
      setValue(() => ({
        category: existingReport.message[0].category,
      }))
      setReport(() =>
        existingReport.message.map((el) => [
          el.sub_category,
          el.existing,
          el.remarks,
          el.id,
        ])
      )
    }
  }, [existingReport, existingImageReport])
  // End useEffect
  return (
    <UserMain>
      <Container fluid>
        {existingReport && existingReport.loading && (
          <Container
            fluid
            className='my-2 shadow-lg pb-2'
            style={{ background: '#FFF' }}
          >
            <div className='text-center py-5'>
              <Spinner animation='border' />
              <h6 className='mt-3'>
                Please wait while we are loading your report ....
              </h6>
            </div>
          </Container>
        )}
        {existingReport &&
          !existingReport.loading &&
          existingReport.message && (
            <Container
              fluid
              className='my-2 shadow-lg pb-2'
              style={{ background: '#FFF' }}
            >
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleUpdateSubmit()
                }}
              >
                <h4 className='text-center pt-3'>CRITERIA</h4>
                <hr />

                <Row
                  className='bg-white shadow-sm py-2 px-3 mx-1'
                  style={{ width: '50rem' }}
                >
                  <Col xl={3} className='d-flex align-items-center'>
                    <h5 className='m-0'>
                      Category: <i className='text-danger'>*</i>{' '}
                    </h5>
                  </Col>
                  <Form.Group as={Col} xl={9}>
                    <Form.Control
                      type='text'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      size='sm'
                      value={value.category}
                      onChange={(e) => setValue({ category: e.target.value })}
                      required
                    />
                  </Form.Group>
                </Row>

                <Card border='success' className='mt-2 mb-4 shadow-sm'>
                  <Card.Header style={{ background: '#4bbf73', color: '#FFF' }}>
                    <Form.Check
                      inline
                      name='All'
                      type='radio'
                      label='Yes to all'
                      onClick={() =>
                        handleYestoAll([
                          'Existing Trees',
                          'Lamp',
                          'Sign Boards',
                          'Ramp',
                          'Plant Box',
                        ])
                      }
                    />

                    <Form.Check
                      inline
                      name='All'
                      type='radio'
                      label='No to all'
                      onClick={() =>
                        handleNotoAll([
                          'Existing Trees',
                          'Lamp',
                          'Sign Boards',
                          'Ramp',
                          'Plant Box',
                        ])
                      }
                    />
                  </Card.Header>
                  <Card.Body>
                    <Table
                      responsive
                      bordered
                      hover
                      className='border border-dark'
                      style={{ background: '#FFF' }}
                    >
                      <thead>
                        <tr>
                          {createReport[0].headers.map((el, index) => (
                            <th key={index} className='text-center p-0'>
                              <h4 className='p-0 mt-2'>{el}</h4>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {createReport[0].categories.map((el, index) => (
                          <tr key={index}>
                            <td className='text-end align-middle py-0'>{el}</td>
                            <td className='py-0 align-middle'>
                              <Row>
                                <Form.Group as={Col} xl={6}>
                                  <Form.Check
                                    inline
                                    type='radio'
                                    name={el}
                                    value={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1]
                                    }
                                    checked={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1] ===
                                        1 &&
                                      true
                                    }
                                    label='Yes'
                                    onChange={handleChangeYes}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group as={Col} xl={6}>
                                  <Form.Check
                                    inline
                                    type='radio'
                                    name={el}
                                    value={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1]
                                    }
                                    checked={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1] ===
                                        0 &&
                                      true
                                    }
                                    label='No'
                                    onChange={handleChangeNo}
                                    required
                                  />
                                </Form.Group>
                              </Row>
                            </td>
                            <td className='py-2'>
                              <Form.Group as={Col} xl={12}>
                                <Form.Control
                                  size='sm'
                                  style={{
                                    background: '#FFF',
                                    border: '1px solid #ced4da',
                                  }}
                                  type='text'
                                  placeholder='input remarks here ...'
                                  name={el}
                                  value={
                                    report.length > 0 &&
                                    report.find((x) => x[0] === el)[2]
                                  }
                                  onChange={(e) => {
                                    let index = report.findIndex(
                                      (x) => x[0] === el
                                    )

                                    if (index >= 0) {
                                      currentValue[index][2] = e.target.value
                                      setReport(currentValue)
                                      setCurrentIndex(index)
                                      setCurrentRemarks(e.target.value)
                                    } else {
                                      setReport([
                                        ...report,
                                        [e.target.name, 1, e.target.value],
                                      ])
                                    }
                                  }}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td className='text-end align-middle py-0'>Others</td>
                          <td className='py-2' colSpan={2}>
                            <Form.Group as={Col} xl={12}>
                              <Form.Control
                                size='sm'
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                name='LineGrade_Others'
                                type='text'
                                placeholder='input remarks here ...'
                                value={
                                  report.length > 0 &&
                                  report.find(
                                    (x) => x[0] === 'LineGrade_Others'
                                  )[2]
                                }
                                onChange={(e) => {
                                  let index = report.findIndex(
                                    (x) => x[0] === e.target.name
                                  )

                                  if (index >= 0) {
                                    currentValue[index][2] = e.target.value
                                    setReport(currentValue)
                                    setCurrentIndex(index)
                                    setCurrentRemarks(e.target.value)
                                  } else {
                                    setReport([
                                      ...report,
                                      [e.target.name, 1, e.target.value],
                                    ])
                                  }
                                }}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>

                {/* 2nd Box */}
                <Card border='success' className='mt-2 mb-4 shadow-sm'>
                  <Card.Header style={{ background: '#4bbf73', color: '#FFF' }}>
                    <Form.Check
                      inline
                      name='All_Utils'
                      type='radio'
                      label='Yes to all'
                      onClick={() =>
                        handleYestoAll([
                          'Power Lines',
                          'High Tension Wires',
                          'Secondary Wires',
                          'Guy Wires',
                          'Telecommunications',
                          'Aerial',
                          'Underground',
                          'Water Lines',
                        ])
                      }
                    />

                    <Form.Check
                      inline
                      name='All_Utils'
                      type='radio'
                      label='No to all'
                      onClick={() =>
                        handleNotoAll([
                          'Power Lines',
                          'High Tension Wires',
                          'Secondary Wires',
                          'Guy Wires',
                          'Telecommunications',
                          'Aerial',
                          'Underground',
                          'Water Lines',
                        ])
                      }
                    />
                  </Card.Header>
                  <Card.Body>
                    <Table
                      responsive
                      bordered
                      hover
                      className='border border-dark'
                      style={{ background: '#FFF' }}
                    >
                      <thead>
                        <tr>
                          {createReport[1].headers.map((el, index) => (
                            <th key={index} className='text-center p-0'>
                              <h4 className='p-0 mt-2'>{el}</h4>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {createReport[1].categories.map((el, index) => (
                          <tr key={index}>
                            <td className='text-end align-middle py-0'>{el}</td>
                            <td className='py-0 align-middle'>
                              <Row>
                                <Form.Group as={Col} xl={6}>
                                  <Form.Check
                                    inline
                                    type='radio'
                                    name={el}
                                    value={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1]
                                    }
                                    checked={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1] ===
                                        1 &&
                                      true
                                    }
                                    label='Yes'
                                    required
                                    onChange={handleChangeYes}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} xl={6}>
                                  <Form.Check
                                    inline
                                    type='radio'
                                    name={el}
                                    value={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1]
                                    }
                                    checked={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1] ===
                                        0 &&
                                      true
                                    }
                                    label='No'
                                    required
                                    onChange={handleChangeNo}
                                  />
                                </Form.Group>
                              </Row>
                            </td>
                            <td className='py-2'>
                              <Form.Group as={Col} xl={12}>
                                <Form.Control
                                  size='sm'
                                  style={{
                                    background: '#FFF',
                                    border: '1px solid #ced4da',
                                  }}
                                  type='text'
                                  placeholder='input remarks here ...'
                                  name={el}
                                  value={
                                    report.length > 0 &&
                                    report.find((x) => x[0] === el)[2]
                                  }
                                  onChange={(e) => {
                                    let index = report.findIndex(
                                      (x) => x[0] === el
                                    )

                                    if (index >= 0) {
                                      currentValue[index][2] = e.target.value
                                      setReport(currentValue)
                                      setCurrentIndex(index)
                                      setCurrentRemarks(e.target.value)
                                    } else {
                                      setReport([
                                        ...report,
                                        [e.target.name, 1, e.target.value],
                                      ])
                                    }
                                  }}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td className='text-end align-middle py-0'>Others</td>
                          <td className='py-2' colSpan={2}>
                            <Form.Group as={Col} xl={12}>
                              <Form.Control
                                size='sm'
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                type='text'
                                placeholder='input remarks here ...'
                                name='Utilities_Others'
                                value={
                                  report.length > 0 &&
                                  report.find(
                                    (x) => x[0] === 'Utilities_Others'
                                  )[2]
                                }
                                onChange={(e) => {
                                  let index = report.findIndex(
                                    (x) => x[0] === e.target.name
                                  )

                                  if (index >= 0) {
                                    currentValue[index][2] = e.target.value
                                    setReport(currentValue)
                                    setCurrentIndex(index)
                                    setCurrentRemarks(e.target.value)
                                  } else {
                                    setReport([
                                      ...report,
                                      [e.target.name, 1, e.target.value],
                                    ])
                                  }
                                }}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>

                {/* 3rd Box */}
                <Card border='success' className='mt-2 mb-4 shadow-sm'>
                  <Card.Header style={{ background: '#4bbf73', color: '#FFF' }}>
                    <Form.Check
                      inline
                      name='All_Construction'
                      type='radio'
                      label='Yes to all'
                      onClick={() =>
                        handleYestoAll([
                          'Excavation',
                          'Second Floor Beam',
                          'Finising Stage',
                          'Construction Materials',
                          'Construction Debris',
                        ])
                      }
                    />

                    <Form.Check
                      inline
                      name='All_Construction'
                      type='radio'
                      label='No to all'
                      onClick={() =>
                        handleNotoAll([
                          'Excavation',
                          'Second Floor Beam',
                          'Finising Stage',
                          'Construction Materials',
                          'Construction Debris',
                        ])
                      }
                    />
                  </Card.Header>
                  <Card.Body>
                    <Table
                      responsive
                      bordered
                      hover
                      className='border border-dark'
                      style={{ background: '#FFF' }}
                    >
                      <thead>
                        <tr>
                          {createReport[2].headers.map((el, index) => (
                            <th key={index} className='text-center p-0'>
                              <h4 className='p-0 mt-2'>{el}</h4>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {createReport[2].categories.map((el, index) => (
                          <tr key={index}>
                            <td className='text-end align-middle py-0'>{el}</td>
                            <td className='py-0 align-middle'>
                              <Row>
                                <Form.Group as={Col} xl={6}>
                                  <Form.Check
                                    inline
                                    type='radio'
                                    name={el}
                                    value={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1]
                                    }
                                    checked={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1] ===
                                        1 &&
                                      true
                                    }
                                    label='Yes'
                                    required
                                    onChange={handleChangeYes}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} xl={6}>
                                  <Form.Check
                                    inline
                                    type='radio'
                                    name={el}
                                    value={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1]
                                    }
                                    checked={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1] ===
                                        0 &&
                                      true
                                    }
                                    label='No'
                                    required
                                    onChange={handleChangeNo}
                                  />
                                </Form.Group>
                              </Row>
                            </td>
                            <td className='py-2'>
                              <Form.Group as={Col} xl={12}>
                                <Form.Control
                                  size='sm'
                                  style={{
                                    background: '#FFF',
                                    border: '1px solid #ced4da',
                                  }}
                                  type='text'
                                  placeholder='input remarks here ...'
                                  name={el}
                                  value={
                                    report.length > 0 &&
                                    report.find((x) => x[0] === el)[2]
                                  }
                                  onChange={(e) => {
                                    let index = report.findIndex(
                                      (x) => x[0] === el
                                    )

                                    if (index >= 0) {
                                      currentValue[index][2] = e.target.value
                                      setReport(currentValue)
                                      setCurrentIndex(index)
                                      setCurrentRemarks(e.target.value)
                                    } else {
                                      setReport([
                                        ...report,
                                        [e.target.name, 1, e.target.value],
                                      ])
                                    }
                                  }}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td className='text-end align-middle py-0'>Others</td>
                          <td className='py-2' colSpan={2}>
                            <Form.Group as={Col} xl={12}>
                              <Form.Control
                                size='sm'
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                type='text'
                                placeholder='input remarks here ...'
                                name='On-going_Others'
                                value={
                                  report.length > 0 &&
                                  report.find(
                                    (x) => x[0] === 'On-going_Others'
                                  )[2]
                                }
                                onChange={(e) => {
                                  let index = report.findIndex(
                                    (x) => x[0] === e.target.name
                                  )

                                  if (index >= 0) {
                                    currentValue[index][2] = e.target.value
                                    setReport(currentValue)
                                    setCurrentIndex(index)
                                    setCurrentRemarks(e.target.value)
                                  } else {
                                    setReport([
                                      ...report,
                                      [e.target.name, 1, e.target.value],
                                    ])
                                  }
                                }}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-end align-middle py-0'>
                            Percentage of the on-going construction
                          </td>
                          <td className='py-2' colSpan={2}>
                            <Form.Group as={Col} xl={12}>
                              <Form.Control
                                size='sm'
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #ced4da',
                                }}
                                type='text'
                                placeholder='input remarks here ...'
                                name='Percentage'
                                value={
                                  report.length > 0 &&
                                  report.find((x) => x[0] === 'Percentage')[2]
                                }
                                onChange={(e) => {
                                  let index = report.findIndex(
                                    (x) => x[0] === e.target.name
                                  )

                                  if (index >= 0) {
                                    currentValue[index][2] = e.target.value
                                    setReport(currentValue)
                                    setCurrentIndex(index)
                                    setCurrentRemarks(e.target.value)
                                  } else {
                                    setReport([
                                      ...report,
                                      [e.target.name, 1, e.target.value],
                                    ])
                                  }
                                }}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>

                {/* Box 4 */}
                <Card border='success' className='mt-2 mb-4 shadow-sm'>
                  <Card.Header style={{ background: '#4bbf73', color: '#FFF' }}>
                    <Form.Check
                      inline
                      name='All_Boundary'
                      type='radio'
                      label='Yes to all'
                      onClick={() =>
                        handleYestoAll([
                          'Marking of boundary lines',
                          'Signage',
                          'Conforms with submitted plan',
                        ])
                      }
                    />

                    <Form.Check
                      inline
                      name='All_Boundary'
                      type='radio'
                      label='No to all'
                      onClick={() =>
                        handleNotoAll([
                          'Marking of boundary lines',
                          'Signage',
                          'Conforms with submitted plan',
                        ])
                      }
                    />
                  </Card.Header>
                  <Card.Body>
                    <Table
                      responsive
                      bordered
                      hover
                      className='border border-dark'
                      style={{ background: '#FFF' }}
                    >
                      <thead>
                        <tr>
                          {createReport[3].headers.map((el, index) => (
                            <th key={index} className='text-center p-0'>
                              <h4 className='p-0 mt-2'>{el}</h4>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {createReport[3].categories.map((el, index) => (
                          <tr key={index}>
                            <td className='text-end align-middle py-0'>{el}</td>
                            <td className='py-0 align-middle'>
                              <Row>
                                <Form.Group as={Col} xl={6}>
                                  <Form.Check
                                    inline
                                    type='radio'
                                    name={el}
                                    value={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1]
                                    }
                                    checked={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1] ===
                                        1 &&
                                      true
                                    }
                                    label='Yes'
                                    required
                                    onChange={handleChangeYes}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} xl={6}>
                                  <Form.Check
                                    inline
                                    type='radio'
                                    name={el}
                                    value={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1]
                                    }
                                    checked={
                                      report.length > 0 &&
                                      report.find((x) => x[0] === el)[1] ===
                                        0 &&
                                      true
                                    }
                                    label='No'
                                    required
                                    onChange={handleChangeNo}
                                  />
                                </Form.Group>
                              </Row>
                            </td>
                            <td className='py-2'>
                              <Form.Group as={Col} xl={12}>
                                <Form.Control
                                  size='sm'
                                  style={{
                                    background: '#FFF',
                                    border: '1px solid #ced4da',
                                  }}
                                  type='text'
                                  placeholder='input remarks here ...'
                                  name={el}
                                  value={
                                    report.length > 0 &&
                                    report.find((x) => x[0] === el)[2]
                                  }
                                  onChange={(e) => {
                                    let index = report.findIndex(
                                      (x) => x[0] === el
                                    )

                                    if (index >= 0) {
                                      currentValue[index][2] = e.target.value
                                      setReport(currentValue)
                                      setCurrentIndex(index)
                                      setCurrentRemarks(e.target.value)
                                    } else {
                                      setReport([
                                        ...report,
                                        [e.target.name, 1, e.target.value],
                                      ])
                                    }
                                  }}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
                <hr />

                <h4 className='text-center pt-5'>PROPOSED STRUCTURE</h4>
                <hr />

                {/* Rear */}
                <Row className='justify-content-center'>
                  <Col xl={6}>
                    <Card border='success' className='mt-2 mb-0 shadow-sm'>
                      <Card.Header
                        style={{ background: '#4bbf73', color: '#FFF' }}
                      >
                        <Form.Check
                          inline
                          name='All_Rear'
                          type='radio'
                          label='Yes to all'
                          onClick={() =>
                            handleYestoAll(['Rear_Firewall', 'Rear_Seatback'])
                          }
                        />

                        <Form.Check
                          inline
                          name='All_Rear'
                          type='radio'
                          label='No to all'
                          onClick={() =>
                            handleNotoAll(['Rear_Firewall', 'Rear_Seatback'])
                          }
                        />
                      </Card.Header>
                      <Card.Body>
                        <Table
                          responsive
                          bordered
                          hover
                          className='border border-dark'
                          style={{ background: '#FFF' }}
                        >
                          <thead>
                            <tr>
                              {createReport[4].headers.map((el, index) => (
                                <th key={index} className='text-center p-0'>
                                  <h4 className='p-0 mt-2'>{el}</h4>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {createReport[4].categories.map((el, index) => (
                              <tr key={index}>
                                <td className='text-end align-middle py-0'>
                                  {el.split('Rear_')}
                                </td>
                                <td className='py-0 align-middle'>
                                  <Row>
                                    <Form.Group as={Col} xl={6}>
                                      <Form.Check
                                        inline
                                        type='radio'
                                        name={el}
                                        value={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1]
                                        }
                                        checked={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1] ===
                                            1 &&
                                          true
                                        }
                                        label='Yes'
                                        onChange={handleChangeYes}
                                        required
                                      />
                                    </Form.Group>
                                    <Form.Group as={Col} xl={6}>
                                      <Form.Check
                                        inline
                                        type='radio'
                                        name={el}
                                        value={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1]
                                        }
                                        checked={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1] ===
                                            0 &&
                                          true
                                        }
                                        label='No'
                                        onChange={handleChangeNo}
                                        required
                                      />
                                    </Form.Group>
                                  </Row>
                                </td>
                                <td className='py-2'>
                                  <Form.Group as={Col} xl={12}>
                                    <Form.Control
                                      size='sm'
                                      style={{
                                        background: '#FFF',
                                        border: '1px solid #ced4da',
                                      }}
                                      type='text'
                                      placeholder='input remarks here ...'
                                      name={el}
                                      value={
                                        report.length > 0 &&
                                        report.find((x) => x[0] === el)[2]
                                      }
                                      onChange={(e) => {
                                        let index = report.findIndex(
                                          (x) => x[0] === el
                                        )

                                        if (index >= 0) {
                                          currentValue[index][2] =
                                            e.target.value
                                          setReport(currentValue)
                                          setCurrentIndex(index)
                                          setCurrentRemarks(e.target.value)
                                        } else {
                                          setReport([
                                            ...report,
                                            [e.target.name, 1, e.target.value],
                                          ])
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td className='align-middle'>Others</td>
                              <td colSpan={2}>
                                <Form.Group as={Col} xl={12}>
                                  <Form.Control
                                    size='sm'
                                    style={{
                                      background: '#FFF',
                                      border: '1px solid #ced4da',
                                    }}
                                    type='text'
                                    placeholder='input remarks here ...'
                                    name='Rear_Others'
                                    value={
                                      report.length > 0 &&
                                      report.find(
                                        (x) => x[0] === 'Rear_Others'
                                      )[2]
                                    }
                                    onChange={(e) => {
                                      let index = report.findIndex(
                                        (x) => x[0] === e.target.name
                                      )

                                      if (index >= 0) {
                                        currentValue[index][2] = e.target.value
                                        setReport(currentValue)
                                        setCurrentIndex(index)
                                        setCurrentRemarks(e.target.value)
                                      } else {
                                        setReport([
                                          ...report,
                                          [e.target.name, 1, e.target.value],
                                        ])
                                      }
                                    }}
                                  />
                                </Form.Group>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {/* End Rear */}

                {/* Center */}
                <Row>
                  {/* Left */}
                  <Col xl={5} className='px-0'>
                    <Card border='success' className='mt-1 shadow-sm'>
                      <Card.Header
                        style={{ background: '#4bbf73', color: '#FFF' }}
                      >
                        <Form.Check
                          inline
                          name='All_Left'
                          type='radio'
                          label='Yes to all'
                          onClick={() =>
                            handleYestoAll(['Left_Firewall', 'Left_Seatback'])
                          }
                        />

                        <Form.Check
                          inline
                          name='All_Left'
                          type='radio'
                          label='No to all'
                          onClick={() =>
                            handleNotoAll(['Left_Firewall', 'Left_Seatback'])
                          }
                        />
                      </Card.Header>
                      <Card.Body>
                        <Table
                          responsive
                          bordered
                          hover
                          className='border border-dark'
                          style={{ background: '#FFF' }}
                        >
                          <thead>
                            <tr>
                              {createReport[5].headers.map((el, index) => (
                                <th key={index} className='text-center p-0'>
                                  <h4 className='p-0 mt-2'>{el}</h4>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {createReport[5].categories.map((el, index) => (
                              <tr key={index}>
                                <td className='text-end align-middle py-0'>
                                  {el.split('Left_')}
                                </td>
                                <td className='py-0 align-middle'>
                                  <Row>
                                    <Form.Group as={Col} xl={6}>
                                      <Form.Check
                                        inline
                                        type='radio'
                                        name={el}
                                        value={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1]
                                        }
                                        checked={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1] ===
                                            1 &&
                                          true
                                        }
                                        label='Yes'
                                        onChange={handleChangeYes}
                                        required
                                      />
                                    </Form.Group>
                                    <Form.Group as={Col} xl={6}>
                                      <Form.Check
                                        inline
                                        type='radio'
                                        name={el}
                                        value={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1]
                                        }
                                        checked={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1] ===
                                            0 &&
                                          true
                                        }
                                        label='No'
                                        onChange={handleChangeNo}
                                        required
                                      />
                                    </Form.Group>
                                  </Row>
                                </td>
                                <td className='py-2'>
                                  <Form.Group as={Col} xl={12}>
                                    <Form.Control
                                      size='sm'
                                      style={{
                                        background: '#FFF',
                                        border: '1px solid #ced4da',
                                      }}
                                      type='text'
                                      placeholder='input remarks here ...'
                                      name={el}
                                      value={
                                        report.length > 0 &&
                                        report.find((x) => x[0] === el)[2]
                                      }
                                      onChange={(e) => {
                                        let index = report.findIndex(
                                          (x) => x[0] === el
                                        )

                                        if (index >= 0) {
                                          currentValue[index][2] =
                                            e.target.value
                                          setReport(currentValue)
                                          setCurrentIndex(index)
                                          setCurrentRemarks(e.target.value)
                                        } else {
                                          setReport([
                                            ...report,
                                            [e.target.name, 1, e.target.value],
                                          ])
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td className='align-middle'>Others</td>
                              <td colSpan={2}>
                                <Form.Group as={Col} xl={12}>
                                  <Form.Control
                                    size='sm'
                                    style={{
                                      background: '#FFF',
                                      border: '1px solid #ced4da',
                                    }}
                                    type='text'
                                    placeholder='input remarks here ...'
                                    name='Left_Others'
                                    value={
                                      report.length > 0 &&
                                      report.find(
                                        (x) => x[0] === 'Left_Others'
                                      )[2]
                                    }
                                    onChange={(e) => {
                                      let index = report.findIndex(
                                        (x) => x[0] === e.target.name
                                      )

                                      if (index >= 0) {
                                        currentValue[index][2] = e.target.value
                                        setReport(currentValue)
                                        setCurrentIndex(index)
                                        setCurrentRemarks(e.target.value)
                                      } else {
                                        setReport([
                                          ...report,
                                          [e.target.name, 1, e.target.value],
                                        ])
                                      }
                                    }}
                                  />
                                </Form.Group>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* End Left */}

                  <Col xl={2} className='d-flex mt-1 px-0'>
                    <Card variant='flush' className='shadow-sm'>
                      <Card.Body className='text-center d-flex align-items-center'>
                        <h5>
                          <FaHouseUser className='fs-1 mx-1' />
                          PROPOSED STRUCTURE
                        </h5>
                      </Card.Body>
                    </Card>
                  </Col>

                  {/* Right */}
                  <Col xl={5} className='px-0'>
                    <Card border='success' className='mt-1 shadow-sm'>
                      <Card.Header
                        style={{ background: '#4bbf73', color: '#FFF' }}
                      >
                        <Form.Check
                          inline
                          name='All_Right'
                          type='radio'
                          label='Yes to all'
                          onClick={() =>
                            handleYestoAll(['Right_Firewall', 'Right_Seatback'])
                          }
                        />

                        <Form.Check
                          inline
                          name='All_Right'
                          type='radio'
                          label='No to all'
                          onClick={() =>
                            handleNotoAll(['Right_Firewall', 'Right_Seatback'])
                          }
                        />
                      </Card.Header>
                      <Card.Body>
                        <Table
                          responsive
                          bordered
                          hover
                          className='border border-dark'
                          style={{ background: '#FFF' }}
                        >
                          <thead>
                            <tr>
                              {createReport[6].headers.map((el, index) => (
                                <th key={index} className='text-center p-0'>
                                  <h4 className='p-0 mt-2'>{el}</h4>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {createReport[6].categories.map((el, index) => (
                              <tr key={index}>
                                <td className='text-end align-middle py-0'>
                                  {el.split('Right_')}
                                </td>
                                <td className='py-0 align-middle'>
                                  <Row>
                                    <Form.Group as={Col} xl={6}>
                                      <Form.Check
                                        inline
                                        type='radio'
                                        name={el}
                                        value={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1]
                                        }
                                        checked={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1] ===
                                            1 &&
                                          true
                                        }
                                        label='Yes'
                                        onChange={handleChangeYes}
                                        required
                                      />
                                    </Form.Group>
                                    <Form.Group as={Col} xl={6}>
                                      <Form.Check
                                        inline
                                        type='radio'
                                        name={el}
                                        value={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1]
                                        }
                                        checked={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1] ===
                                            0 &&
                                          true
                                        }
                                        label='No'
                                        onChange={handleChangeNo}
                                        required
                                      />
                                    </Form.Group>
                                  </Row>
                                </td>
                                <td className='py-2'>
                                  <Form.Group as={Col} xl={12}>
                                    <Form.Control
                                      size='sm'
                                      style={{
                                        background: '#FFF',
                                        border: '1px solid #ced4da',
                                      }}
                                      type='text'
                                      placeholder='input remarks here ...'
                                      name={el}
                                      value={
                                        report.length > 0 &&
                                        report.find((x) => x[0] === el)[2]
                                      }
                                      onChange={(e) => {
                                        let index = report.findIndex(
                                          (x) => x[0] === el
                                        )

                                        if (index >= 0) {
                                          currentValue[index][2] =
                                            e.target.value
                                          setReport(currentValue)
                                          setCurrentIndex(index)
                                          setCurrentRemarks(e.target.value)
                                        } else {
                                          setReport([
                                            ...report,
                                            [e.target.name, 1, e.target.value],
                                          ])
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td className='align-middle'>Others</td>
                              <td colSpan={2}>
                                <Form.Group as={Col} xl={12}>
                                  <Form.Control
                                    size='sm'
                                    style={{
                                      background: '#FFF',
                                      border: '1px solid #ced4da',
                                    }}
                                    type='text'
                                    placeholder='input remarks here ...'
                                    name='Right_Others'
                                    value={
                                      report.length > 0 &&
                                      report.find(
                                        (x) => x[0] === 'Right_Others'
                                      )[2]
                                    }
                                    onChange={(e) => {
                                      let index = report.findIndex(
                                        (x) => x[0] === e.target.name
                                      )

                                      if (index >= 0) {
                                        currentValue[index][2] = e.target.value
                                        setReport(currentValue)
                                        setCurrentIndex(index)
                                        setCurrentRemarks(e.target.value)
                                      } else {
                                        setReport([
                                          ...report,
                                          [e.target.name, 1, e.target.value],
                                        ])
                                      }
                                    }}
                                  />
                                </Form.Group>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* End Right */}
                </Row>
                {/* End Center */}

                {/* Front */}
                <Row className='justify-content-center'>
                  <Col xl={6}>
                    <Card border='success' className='mt-1 mb-4 shadow-sm'>
                      <Card.Header
                        style={{ background: '#4bbf73', color: '#FFF' }}
                      >
                        <Form.Check
                          inline
                          name='All_Front'
                          type='radio'
                          label='Yes to all'
                          onClick={() =>
                            handleYestoAll([
                              'Road Lot',
                              'Alley',
                              'Right of Way (ROW)',
                              'Access Road',
                            ])
                          }
                        />

                        <Form.Check
                          inline
                          name='All_Front'
                          type='radio'
                          label='No to all'
                          onClick={() =>
                            handleNotoAll([
                              'Road Lot',
                              'Alley',
                              'Right of Way (ROW)',
                              'Access Road',
                            ])
                          }
                        />
                      </Card.Header>
                      <Card.Body>
                        <Table
                          responsive
                          bordered
                          hover
                          className='border border-dark'
                          style={{ background: '#FFF' }}
                        >
                          <thead>
                            <tr>
                              {createReport[7].headers.map((el, index) => (
                                <th key={index} className='text-center p-0'>
                                  <h4 className='p-0 mt-2'>{el}</h4>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {createReport[7].categories.map((el, index) => (
                              <tr key={index}>
                                <td className='text-end align-middle py-0'>
                                  {el}
                                </td>
                                <td className='py-0 align-middle'>
                                  <Row>
                                    <Form.Group as={Col} xl={6}>
                                      <Form.Check
                                        inline
                                        type='radio'
                                        name={el}
                                        value={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1]
                                        }
                                        checked={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1] ===
                                            1 &&
                                          true
                                        }
                                        label='Yes'
                                        onChange={handleChangeYes}
                                        required
                                      />
                                    </Form.Group>
                                    <Form.Group as={Col} xl={6}>
                                      <Form.Check
                                        inline
                                        type='radio'
                                        name={el}
                                        value={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1]
                                        }
                                        checked={
                                          report.length > 0 &&
                                          report.find((x) => x[0] === el)[1] ===
                                            0 &&
                                          true
                                        }
                                        label='No'
                                        onChange={handleChangeNo}
                                        required
                                      />
                                    </Form.Group>
                                  </Row>
                                </td>
                                <td className='py-2'>
                                  <Form.Group as={Col} xl={12}>
                                    <Form.Control
                                      size='sm'
                                      style={{
                                        background: '#FFF',
                                        border: '1px solid #ced4da',
                                      }}
                                      type='text'
                                      placeholder='input remarks here ...'
                                      name={el}
                                      value={
                                        report.length > 0 &&
                                        report.find((x) => x[0] === el)[2]
                                      }
                                      onChange={(e) => {
                                        let index = report.findIndex(
                                          (x) => x[0] === el
                                        )

                                        if (index >= 0) {
                                          currentValue[index][2] =
                                            e.target.value
                                          setReport(currentValue)
                                          setCurrentIndex(index)
                                          setCurrentRemarks(e.target.value)
                                        } else {
                                          setReport([
                                            ...report,
                                            [e.target.name, 1, e.target.value],
                                          ])
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td className='align-middle'>Others</td>
                              <td colSpan={2}>
                                <Form.Group as={Col} xl={12}>
                                  <Form.Control
                                    size='sm'
                                    style={{
                                      background: '#FFF',
                                      border: '1px solid #ced4da',
                                    }}
                                    type='text'
                                    placeholder='input remarks here ...'
                                    name='Front_Others'
                                    value={
                                      report.length > 0 &&
                                      report.find(
                                        (x) => x[0] === 'Front_Others'
                                      )[2]
                                    }
                                    onChange={(e) => {
                                      let index = report.findIndex(
                                        (x) => x[0] === e.target.name
                                      )

                                      if (index >= 0) {
                                        currentValue[index][2] = e.target.value
                                        setReport(currentValue)
                                        setCurrentIndex(index)
                                        setCurrentRemarks(e.target.value)
                                      } else {
                                        setReport([
                                          ...report,
                                          [e.target.name, 1, e.target.value],
                                        ])
                                      }
                                    }}
                                  />
                                </Form.Group>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {/* End Front */}

                {/* Add Images OSO CREW*/}
                {/* {isUpdating === null && (
                <Card>
                  <Card.Header style={{ background: '#4bbf73', color: '#FFF' }}>
                    Attachments
                  </Card.Header>
                  <Card.Body>
                    <h6>Insert image here ...</h6>
                    <Form.File
                      id='image-file'
                      size='sm'
                      className='my-2'
                      multiple
                      onChange={handleUploadImage}
                    />
                  </Card.Body>
                </Card>
              )} */}

                {/* End Add Images */}

                {/* Image Uploading and Viewing */}
                {/* <Card border='success' className='mt-2 mb-4 shadow-sm'>
              <Card.Header style={{ background: '#4bbf73', color: '#FFF' }}>
                <h6 className='pt-2 text-light'>Image Uploading and Viewing</h6>
              </Card.Header>
              <Card.Body>
                <div className='d-flex justify-content-center'>
                  <Form.File
                    id='image-file'
                    size='sm'
                    className='my-2'
                    multiple
                    onChange={handleUploadImage}
                  />
                </div>
                {isUpdating !== null && (
                  <div style={{ background: '#f9f6f1' }}>
                    <h6 className='text-center py-2'>
                      <i>
                        NOTE: Once you upload new photos or images previous images
                        will be deleted.
                      </i>
                    </h6>
                  </div>
                )}
                {existingImageReport && existingImageReport.message && (
                  <Row>
                    {existingImageReport.message.map((image) => (
                      <img
                        className='my-2'
                        src={`data:image/png;base64,${image.URL}`}
                      />
                    ))}
                  </Row>
                )}
              </Card.Body>
            </Card>
            <hr /> */}
                <hr />

                <Row>
                  <Col md={12} className='pt-1 pb-2 text-end'>
                    <Button
                      variant='info'
                      onClick={() => {
                        setShowImages(true)
                        dispatch(
                          retrieveImageReport({
                            inspectionID: forReport.message.id,
                            userID: userCredentials.id,
                            category: value.category,
                          })
                        )
                      }}
                    >
                      View Attach Images
                    </Button>

                    <Button className='mx-1' type='submit' variant='success'>
                      Update
                    </Button>

                    <Button variant='danger' onClick={handleBackBtn}>
                      Back
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          )}

        {existingReport && !existingReport.loading && existingReport.error && (
          <Container
            fluid
            className='my-2 shadow-lg pb-2'
            style={{ background: '#FFF' }}
          >
            <div className='text-center py-5'>
              <FaFolderOpen
                className='text-danger'
                style={{ fontSize: '80px' }}
              />
              <h6>We are sorry, but there's no report found</h6>
            </div>
          </Container>
        )}
      </Container>

      {/* View Images Modal*/}
      <Modal
        size='xl'
        show={showImages}
        onHide={() => setShowImages(false)}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaFileAlt className='mx-3' />
            Image Uploading and Viewing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-center'>
            <Form.File
              id='image-file'
              size='sm'
              className='my-2'
              multiple
              onChange={handleUploadImage}
              required
            />
          </div>

          {existingImageReport && existingImageReport.loading && (
            <div className='text-center pt-3'>
              <Spinner animation='border' />
              <h3>Loading...</h3>
            </div>
          )}
          {existingImageReport && existingImageReport.message && (
            <div>
              {existingImageReport.message.map((image, index) => (
                <Row>
                  <img
                    key={index}
                    className='my-2'
                    src={`data:image/png;base64,${image.URL}`}
                  />
                </Row>
              ))}
            </div>
          )}
          {existingImageReport && existingImageReport.error && (
            <h5>No Image/s Uploaded Yet</h5>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdateImages} variant='success' size='sm'>
            Upload
          </Button>
          <Button
            onClick={() => setShowImages(false)}
            variant='danger'
            size='sm'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End View Images Modal*/}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateSuccess}
        onHide={() => {
          setModal({ updateSuccess: false })
          props.history.push('/inspection-list')
        }}
        transLoading={editReport && editReport.loading}
        success={editReport && editReport.message}
        error={editReport && editReport.error}
      />
      {/* End Success Modal */}

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.createSuccess}
        onHide={() => {
          setModal({ createSuccess: false })
          props.history.push('/inspection-list')
        }}
        transLoading={addReport && addReport.loading}
        success={addReport && addReport.message}
        error={addReport && addReport.error}
      />
      {/* End Success Modal */}

      {/*Attached Image Success Modal */}
      <SuccessModal
        show={modal.attachedSuccess}
        onHide={() => {
          setModal({ attachedSuccess: false })
        }}
        transLoading={attachedImages && attachedImages.loading}
        success={attachedImages && attachedImages.message}
        error={attachedImages && attachedImages.error}
      />
      {/* End Success Modal */}

      {/*ReAttached Image Success Modal */}
      <SuccessModal
        show={modal.reAttachedSuccess}
        onHide={() => {
          setModal({ reAttachedSuccess: false })
        }}
        transLoading={reAttachedImages && reAttachedImages.loading}
        success={reAttachedImages && reAttachedImages.message}
        error={reAttachedImages && reAttachedImages.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(EditReportScreen)
