import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  InputGroup,
  Button,
  Table,
  Pagination,
  Modal,
} from 'react-bootstrap'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FaSearch, FaEye, FaCheck, FaEllipsisH } from 'react-icons/fa'
import moment from 'moment'

//Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'
import CommentsCard from '../../reusable/CommentsCard'

// Actions
import {
  retrieveApplicationList,
  retrieveCommentsList,
  retrieveOrderofPayment,
} from '../../redux/actions/Administrator/administratorActions'
import { retrieveDateApplied } from '../../redux/actions/adminApplicationActions'

import { retrieveReportList } from '../../redux/actions/Inspectors/Supervisors/isupervisorActions'

import { retrieveEvaluationList } from '../../redux/actions/Evaluators/Supervisors/supervisorActions'

const ForApprovalListScreen = ({ history }) => {
  // Declaration
  const dispatch = useDispatch()

  const [state, setState] = useState([])

  const [payload, setPayload] = useState({})

  const [categoryCode, setCategoryCode] = useState([])

  const [searchTerm, setSearchTerm] = useState('')

  const [modal, setModal] = useState({
    viewManager: false,
    viewReport: false,
    viewReportList: false,
    viewAssessment: false,
    viewChangeStatus: false,
    iSuccessModal: false,
    eSuccessModal: false,
    viewEvaluationList: false,
    recommendSuccess: false,
    approvedSuccess: false,
    viewOrderofPayment: false,
    viewFilterModal: false,
  })

  const [filter, setFilter] = useState({
    status: '',
  })

  const [commentDetails, setCommentDetails] = useState({
    applicationStatus: '',
    comments: '',
    commentTo: '',
    commentToName: '',
    createdBy: '',
    appID: '',
  })

  const [setPenaltyInfo] = useState('')

  const administratorReducer = useSelector(
    (state) => state.administratorReducer
  )

  const { applicationsList, updateAppStatus, commentsList, orderofPayment } =
    administratorReducer

  const adminApplication = useSelector((state) => state.adminApplication)

  const { dateApplied } = adminApplication

  const isupervisor = useSelector((state) => state.isupervisor)

  const { listReport, penaltyDetail } = isupervisor

  const esupervisor = useSelector((state) => state.esupervisor)

  const { evaluationList } = esupervisor

  // End Declaration

  // Pagination Area Forms

  const [paginatedForms, setPaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  const pageSize = 10

  useEffect(() => {
    setPaginatedForms(_(_.reverse(state)).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [state])

  const pageCount = state ? Math.ceil(state.length / pageSize) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    const paginationForms = _(state).slice(startIndex).take(pageSize).value()
    setPaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }

  // End Pagination Area Form

  // Functions

  // const handleSubmitComments = () => {
  //   if (commentDetails.applicationStatus === 'FOR APPROVAL') {
  //     console.log('Submit New Status')
  //   }
  //   dispatch(
  //     updateApplicationStatus({
  //       id: payload.statusID,
  //       status: commentDetails.applicationStatus,
  //       remarks: payload.remarks,
  //       withEvaluation: payload.withEvaluation,
  //       withInspection: payload.withInspection,
  //       application_ID: payload.id,
  //       userID: payload.userID,
  //       adminID: userCredentials.id,
  //       comments: commentDetails.comments,
  //       commentTo: commentDetails.commentTo,
  //       createdBy: userCredentials.id,
  //       categories: categoryCode,
  //     })
  //   )
  //   setModal({
  //     viewChangeStatus: false,
  //     approvedSuccess: true,
  //   })
  // }

  const handleView = (x) => {
    dispatch(retrieveDateApplied({ id: x.id }))
    dispatch(retrieveCommentsList({ id: x.id }))
    dispatch(retrieveOrderofPayment({ id: x.id }))

    setCommentDetails({
      ...commentDetails,
      applicationStatus: x.status,
      appID: x.id,
    })
    setModal({
      viewManager: true,
    })
    setPayload(x)

    let tradeCode = []

    x.Mechanical === 1 && tradeCode.push(['MP', 'Mechanical Permit'])
    x.Electrical === 1 && tradeCode.push(['EP', 'Electrical Permit'])
    x.Electronics === 1 && tradeCode.push(['LP', 'Electronics Permit'])
    x.Plumbing === 1 && tradeCode.push(['SP', 'Plumbing Permit'])

    switch (x.ApplicationName) {
      case 'BUILDING CONSTRUCTION PERMIT':
        return setCategoryCode([
          ['BP', 'BUILDING CONSTRUCTION PERMIT'],
          ...tradeCode,
        ])
      case 'OCCUPANCY PERMIT':
        return setCategoryCode([['CO', 'CERTIFICATE IN USE/OCCUPANCY']])
      case 'FENCING PERMIT':
        return setCategoryCode([['FP', 'FENCING PERMIT']])
      case 'DEMOLITION PERMIT':
        return setCategoryCode([['DP', 'DEMOLITION PERMIT']])
      case 'ELECTRICAL PERMIT':
        return setCategoryCode([['EP', 'ELECTRICAL PERMIT']])
      default:
        return setCategoryCode([])
    }
  }

  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(retrieveApplicationList())
  }, [dispatch])

  useEffect(() => {
    if (applicationsList && applicationsList.message) {
      setState(() =>
        applicationsList.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.id === parseInt(searchTerm)
            )
          }
        })
      )
    }
  }, [applicationsList, searchTerm])
  // End UseEffect

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {applicationsList && applicationsList.message && (
            <>
              <Form>
                <Row className='justify-content-end px-3 pt-5'>
                  <Col md={8}>
                    <Button
                      size='sm'
                      variant='info'
                      onClick={() => setModal({ viewFilterModal: true })}
                    >
                      Filter
                    </Button>
                  </Col>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              <Row className='my-4'>
                <Col md={12} style={{ background: '#FFF' }}>
                  <h5 className='pt-3 pb-2 mb-0'>Application List</h5>
                  <hr
                    className='mt-1 mb-3'
                    style={{
                      background: '#ffeb36',
                      paddingBottom: '2px',
                      width: '200px',
                    }}
                  />
                  <Table bordered hover responsive size='sm'>
                    <thead className='bg-info text-light'>
                      <tr>
                        <th>Application No.</th>
                        <th>Application</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Date Transferred</th>
                        <th>Applicant Name</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedForms.map((x, index) => (
                        <tr key={index}>
                          <td className='text-center'>{x.id}</td>
                          <td className='text-nowrap'>{x.ApplicationName}</td>
                          <td className='text-nowrap'>{x.status}</td>
                          <td>{x.remarks}</td>
                          <td>
                            {moment(x.TimeStamp).format('MMMM D, YYYY h:mma')}
                          </td>
                          <td className='text-nowrap'>
                            {x.FullName !== null ? x.FullName : x.applicantName}
                          </td>
                          <td className='text-center'>
                            <Button
                              variant='outline-success'
                              onClick={() => handleView(x)}
                              size='sm'
                            >
                              <FaEllipsisH />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    size='sm'
                    className='d-flex justify-content-end mx-2'
                  >
                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {renderPageNumber}

                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </>
          )}

          {applicationsList && applicationsList.loading && (
            <Row className='my-2 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {applicationsList && applicationsList.error && (
            <Row className='my-2 mx-1'>
              <Col xl={12}>
                <Card className='mt-5'>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No application found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      {/* Application Details Modal */}
      <Modal
        size='lg'
        show={modal.viewManager}
        onHide={() => setModal({ viewManager: false })}
        centered
        backdrop='static'
      >
        {dateApplied && dateApplied.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Application Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <tbody>
                  {Object.keys(payload).length > 0 &&
                    payload.status === 'APPROVED APPLICATION' && (
                      <tr>
                        <td className='fw-bold'>Permit No.:</td>
                        <td className='text-danger'>
                          {Object.keys(payload).length > 0 && payload.id}
                        </td>
                      </tr>
                    )}

                  <tr>
                    <td className='fw-bold'>Application No.:</td>
                    <td>{Object.keys(payload).length > 0 && payload.id}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Application Name:</td>
                    <td>
                      {Object.keys(payload).length > 0 &&
                        payload.ApplicationName}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Mode of Application:</td>
                    <td>
                      {Object.keys(payload).length > 0 && payload.ModeofForms}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Application Status:</td>
                    <td>{Object.keys(payload).length > 0 && payload.status}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Date Applied:</td>
                    <td>
                      {Object.keys(payload).length > 0 &&
                        moment(dateApplied.message[0].DateApplied).format(
                          'MMMM D, YYYY h:mma'
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Applicant Attached Link:</td>
                    <td>{Object.keys(payload).length > 0 && payload.Link}</td>
                  </tr>
                  {/* <tr>
                    <td className='fw-bold'>
                      Document Controller Attached Link / Remarks:
                    </td>
                    <td>
                      {Object.keys(payload).length > 0 && payload.remarks}
                    </td>
                  </tr> */}
                  {Object.keys(payload).length > 0 &&
                    (payload.status === 'APPROVED APPLICATION' ||
                      payload.status === 'FOR APPROVAL') && (
                      <tr>
                        <td className='fw-bold text-nowrap'>
                          Application Comment Viewer:
                        </td>
                        <td>
                          <Button
                            variant='success'
                            onClick={() => {
                              setModal({
                                viewChangeStatus: true,
                                viewManager: false,
                              })
                            }}
                            size='sm'
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    )}
                  {Object.keys(payload).length > 0 &&
                    (payload.status === 'FOR INSPECTION AND EVALUATION' ||
                      payload.status === 'FOR COMPLIANCE' ||
                      payload.status === 'APPLICATION VERIFICATION') && (
                      <tr>
                        <td className='fw-bold text-nowrap'>
                          Application Manager:
                        </td>
                        <td>
                          <Button
                            variant='success'
                            onClick={() => {
                              history.push(`/application/${payload.id}`)
                            }}
                            size='sm'
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    )}

                  <tr>
                    <td className='fw-bold text-nowrap'>
                      Evaluation/s Viewer:
                    </td>
                    <td>
                      <Button
                        variant='success'
                        onClick={() => {
                          dispatch(retrieveEvaluationList({ id: payload.id }))
                          setModal({
                            viewEvaluationList: true,
                            viewManager: false,
                          })
                        }}
                        size='sm'
                      >
                        VIEW
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-nowrap'>
                      Inspection Report Viewer:
                    </td>
                    <td>
                      <Button
                        variant='success'
                        onClick={() => {
                          dispatch(retrieveReportList({ id: payload.id }))
                          setModal({ viewReportList: true, viewManager: false })
                          if (penaltyDetail && penaltyDetail.message) {
                            setPenaltyInfo(penaltyDetail.message[0].penalty)
                          }
                          // setRecommenderComments({
                          //   ...recommenderComments,
                          //   i_comments:
                          //     payload.InspectorComment === null
                          //       ? ''
                          //       : payload.InspectorComment,
                          // })
                        }}
                        size='sm'
                      >
                        VIEW
                      </Button>
                    </td>
                  </tr>
                  {/* <tr>
                    <td className='fw-bold text-nowrap'>
                      Order of Payment Viewer:
                    </td>
                    <td>
                      <Button
                        variant='success'
                        onClick={() => {
                          dispatch(retrieveReportList({ id: payload.id }))
                          setModal({
                            viewOrderofPayment: true,
                            viewManager: false,
                          })
                          if (penaltyDetail && penaltyDetail.message) {
                            setPenaltyInfo(penaltyDetail.message[0].penalty)
                          }
                        }}
                        size='sm'
                      >
                        VIEW
                      </Button>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewManager: false,
                  })
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        )}

        {dateApplied && dateApplied.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}

        {dateApplied && dateApplied.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}
      </Modal>
      {/* End Application Details Modal */}

      {/* Change Status */}
      <Modal
        size='lg'
        show={modal.viewChangeStatus}
        onHide={() => setModal({ viewChangeStatus: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            Comment Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommentsCard
            state={commentsList && commentsList.message && commentsList.message}
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button
            variant='danger'
            onClick={() => {
              setModal({
                viewManager: true,
                viewChangeStatus: false,
              })
            }}
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Change Status */}

      {/* List of Inspection Report Modal */}
      <Modal
        size='xl'
        show={modal.viewReportList}
        onHide={() => setModal({ viewReportList: false })}
        centered
        backdrop='static'
      >
        {listReport && listReport.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Inspection List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Date Scheduled</th>
                    <th>Inspection Date</th>
                    <th>Inspector</th>
                  </tr>
                </thead>
                <tbody>
                  {listReport.message.map((list) => (
                    <tr key={list.id}>
                      <td className='text-danger'>{list.category}</td>
                      <td className='text-danger'>
                        {moment(list.inspectionSched).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td className='text-danger'>
                        {moment(list.inspectionDate).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>{list.EmployeeName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewReportList: false,
                    viewManager: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {listReport && listReport.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReportList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {listReport && listReport.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewReportList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End List of Inspection Report Modal */}

      {/* Evaluation List */}
      <Modal
        size='xl'
        show={modal.viewEvaluationList}
        onHide={() => setModal({ viewEvaluationList: false })}
        centered
        backdrop='static'
      >
        {evaluationList && evaluationList.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Evaluation and Assessment List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Trade</th>
                    <th>Date Evaluated</th>
                    <th>Date Assessed</th>
                    <th>Evaluator</th>
                  </tr>
                </thead>
                <tbody>
                  {evaluationList.message.map((list) => (
                    <tr key={list.id}>
                      <td className='fw-bold'>{list.trade}</td>
                      <td>
                        {moment(list.withEvaluation).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>
                        {moment(list.withAssessment).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>{list.EmployeeName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewEvaluationList: false,
                    viewManager: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {evaluationList && evaluationList.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluationList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {evaluationList && evaluationList.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluationList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Evaluation List */}

      {/* Order of Payment */}
      {/* <Modal
        size='xl'
        show={modal.viewOrderofPayment}
        onHide={() => setModal({ viewOrderofPayment: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            ORDER OF PAYMENT DETAILS
          </Modal.Title>
        </Modal.Header>
        {orderofPayment && orderofPayment.message && (
          <Modal.Body>
            <Row>
              <Col xl='12' className='text-center'>
                <p className='m-0 fs-6'>Metropolitan Manila Authority</p>
                <p className='m-0 fs-6'>
                  Action Center for Infrastructure Development
                </p>
                <h6 className='m-0 fs-6'>OFFICE OF THE BUILDING OFFICIAL</h6>
                <p className='m-0 fs-6'>City of Las Piñas</p>
                <p className='m-0 fs-6'>Metro Manila</p>
              </Col>
            </Row>
            <Row>
              <Col xl='6'>
                <div className='d-flex'>
                  <h6>CODE: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {orderofPayment.message.OrderofPaymentID}
                  </p>
                </div>
                <div className='d-flex'>
                  <h6>Owner/Firm/Applicant: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {orderofPayment.message.ApplicantName}
                  </p>
                </div>
                <div className='d-flex'>
                  <h6>Project: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {orderofPayment.message.ApplicationName}
                  </p>
                </div>
                <div className='d-flex'>
                  <h6>Location: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {orderofPayment.message.Location}
                  </p>
                </div>
              </Col>
              <Col xl='6'>
                <div className='d-flex justify-content-end'>
                  <h6 className='m-0 align-middle'>DATE: </h6>
                  <p className='fs-6 m-0 font-italic'>
                    {orderofPayment.message.createdDate}
                  </p>
                </div>
              </Col>
            </Row>
            <h5 className='text-center'>ORDER OF PAYMENT</h5>
            <p className='fs-6'>TO: CITY/MUNICIPAL TREASURER/CASHIER</p>
            <p className='fs-6'>
              Please collect the amoun specified below as perscribed in the
              implementing Rules and Regulations of the National Building Code
              (PD-1096).
            </p>
            <Container className='px-5'>
              <Table responsive>
                <tbody>
                  <tr>
                    <td className='p-1 align-middle'>001 Line and Grade</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.LineAndGrade !== null &&
                        orderofPayment.message.LineAndGrade.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      002 Building Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {(
                        orderofPayment.message.StructuralFees +
                        orderofPayment.message.ArchitecturalFees
                      ).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>Additional/Renovation</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.AdditionalRenovation !== null &&
                        orderofPayment.message.AdditionalRenovation.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>Roof Deck</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.RoofDeck !== null &&
                        orderofPayment.message.RoofDeck.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      003 Demolition Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.DemolitionPermitFees !== null &&
                        orderofPayment.message.DemolitionPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      004 Ancillary Structure
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.AncillaryStructure !== null &&
                        orderofPayment.message.AncillaryStructure.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      005 Construction of Tombs/Canopies
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.TombsCanopies !== null &&
                        orderofPayment.message.TombsCanopies.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      006 Plumbing Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.PlumbingPermitFees !== null &&
                        orderofPayment.message.PlumbingPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      007 Electrical Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.ElectricalPermitFees !== null &&
                        orderofPayment.message.ElectricalPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      008 Mechanical Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.MechanicalPermitFees !== null &&
                        orderofPayment.message.MechanicalPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      009 Fencing Permit Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.FencingPermitFees !== null &&
                        orderofPayment.message.FencingPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      010 Construction of Sidewalks
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.Sidewalks !== null &&
                        orderofPayment.message.Sidewalks.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>011 Paved Areas</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.PavedAreas !== null &&
                        orderofPayment.message.PavedAreas.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      012 Use of Street and Sidewalks
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.StreetSidewalk !== null &&
                        orderofPayment.message.StreetSidewalk.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      013 Certificate of Use/Occupancy
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.CertificateOccupancy !== null &&
                        orderofPayment.message.CertificateOccupancy.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      014 Change in Use/Occupancy
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.ChangeOccupancy !== null &&
                        orderofPayment.message.ChangeOccupancy.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      015 Certificate of Occupancy prior to 10
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.CertificateOccupancy10 !== null &&
                        orderofPayment.message.CertificateOccupancy10.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>016 Electronics Permit</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.ElectronicsPermit !== null &&
                        orderofPayment.message.ElectronicsPermit.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>017 Annual Inspection</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.AnnualInspection !== null &&
                        orderofPayment.message.AnnualInspection.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>018 Sign Permit Fees</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.SignPermitFees !== null &&
                        orderofPayment.message.SignPermitFees.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>019 Certification</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.Certification !== null &&
                        orderofPayment.message.Certification.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>020 Penalty/Surcharge</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.Penalty}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      021 Administrative Fine
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.AdministrativeFive !== null &&
                        orderofPayment.message.AdministrativeFive.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      SUB-TOTAL
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.subTotal !== null &&
                        orderofPayment.message.subTotal.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <p className='fs-6 pt-4 '>
              Please collect the amount specified below as perscribed in the
              approved City Ordinance No. 1373 - 16 series of 2016 Las Piñas
              City.
            </p>
            <h5 className='text-center mt-4'>BUILDING PERMIT</h5>
            <Container className='px-5'>
              <Table responsive>
                <tbody>
                  <tr>
                    <td className='p-1 align-middle'>001 Filling Fee</td>
                    <td className='p-1 align-middle text-end'>300</td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>002 Processing Fees</td>
                    <td className='p-1 align-middle text-end'>750</td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>
                      003 Complaint Filling Fees
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.ComplaintFilingFee !== null &&
                        orderofPayment.message.ComplaintFilingFee.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='p-1 align-middle'>004 Others (Specify)</td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.Others !== null &&
                        orderofPayment.message.Others.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      SUB-TOTAL
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.subTotal1 !== null &&
                        orderofPayment.message.subTotal1.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-end p-1 align-middle'>
                      TOTAL AMOUNT
                    </td>
                    <td className='p-1 align-middle text-end'>
                      {orderofPayment.message.totalAmount !== null &&
                        orderofPayment.message.totalAmount.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Row className='px-5'>
                <h6>PREPARED BY :</h6>
                <h6>{orderofPayment.message.EmployeeName}</h6>
              </Row>
            </Container>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant='info'>PRINT</Button>
          <Button
            variant='danger'
            onClick={() =>
              setModal({ viewManager: true, viewOrderofPayment: false })
            }
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* End Order of Payment */}

      {/* Filter Modal */}
      <Modal
        size='md'
        show={modal.viewFilterModal}
        onHide={() => setModal({ viewFilterModal: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            FILTER
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as='select'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                required
                onChange={(e) =>
                  setFilter({
                    status: e.target.value,
                  })
                }
              >
                <option>CHOOSE STATUS HERE ...</option>
                {[
                  'APPLICATION VERIFICATION',
                  'FOR INSPECTION AND EVALUATION',
                  'FOR APPROVAL',
                  'APPROVED APPLICATION',
                  'FOR COMPLIANCE',
                  'RELEASE APPLICATION WITH REMARKS',
                  'RELEASE APPLICATION',
                ].map((el) => (
                  <option value={el}>{el}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' type='submit'>
              SUBMIT
            </Button>
            <Button
              variant='danger'
              onClick={() => setModal({ viewFilterModal: false })}
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* End Filter Modal */}

      {/*Recommend Application Success Modal */}
      <SuccessModal
        show={modal.approvedSuccess}
        onHide={() => {
          setModal({ approvedSuccess: false })
          window.location.reload(true)
        }}
        transLoading={updateAppStatus && updateAppStatus.loading}
        success={updateAppStatus && updateAppStatus.message}
        error={updateAppStatus && updateAppStatus.error}
      />
      {/* End Recommend Application Success Modal */}
    </UserMain>
  )
}

export default withRouter(ForApprovalListScreen)
