import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Row,
  Spinner,
  Table,
  Pagination,
  Modal,
  Form,
} from 'react-bootstrap'
import {
  FaEye,
  FaFileAlt,
  FaFileArchive,
  FaHardHat,
  FaInbox,
  FaNewspaper,
  FaUserAlt,
  FaUserCheck,
  FaUserEdit,
  FaCheckCircle,
} from 'react-icons/fa'
import _ from 'lodash'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'

// Actions
import { downloadableformsRetrieve } from '../../redux/actions/downloadableformsActions'
import {
  retrieveTApp,
  retrieveTEmployees,
  retrieveTUsers,
  retrieveTUsersForVerification,
  retrieveTRegisteredEng,
  retrieveTNOV,
  retrieveTNUP,
  retrieveTMessages,
  updateDlForm,
  createDlForm,
  deleteDlForm,
} from '../../redux/actions/SuperAdmin/superAdminActions'

const ICTDashboardScreen = () => {
  const dispatch = useDispatch()

  const [dlForms, setDlForms] = useState([])
  const [payload, setPayload] = useState({
    id: 0,
    FormName: '',
    Link: '',
  })
  const [isEdittingForm, setIsEdittingForm] = useState(false)
  const [modal, setModal] = useState({
    viewDownloadForms: false,
  })

  const downloadableforms = useSelector((state) => state.downloadableforms)
  const { downloadableformsDetail } = downloadableforms

  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const superAdminReducer = useSelector((state) => state.superAdminReducer)
  const {
    retrievedTApp,
    retrievedTEmployees,
    retrievedTUsers,
    retrievedTUsersForVerification,
    retrievedTRegisteredEng,
    retrievedTNOV,
    retrievedTNUP,
    retrievedTMessages,
    updatedDlForm,
    createdDlForm,
    deletedDlForm,
  } = superAdminReducer

  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)

  const handleChangeForm = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  const updateSubmittedForm = (e) => {
    e.preventDefault()
    dispatch(
      updateDlForm({
        id: payload.id,
        FormName: payload.FormName,
        Link: payload.Link,
        userID: userCredentials.id,
      })
    )
    setIsEdittingForm(false)
    setModal({
      ...modal,
      viewDownloadForms: false,
    })
    setPayload({
      id: 0,
      FormName: '',
      Link: '',
    })
  }

  const createForm = (e) => {
    e.preventDefault()
    dispatch(
      createDlForm({
        FormName: payload.FormName,
        Link: payload.Link,
        userID: userCredentials.id,
      })
    )
    setIsEdittingForm(false)
    setModal({
      ...modal,
      viewDownloadForms: false,
    })
    setPayload({
      id: 0,
      FormName: '',
      Link: '',
    })
  }

  const deleteForm = (e) => {
    if (window.confirm('are you sure you want to delete this form?')) {
      dispatch(
        deleteDlForm({
          id: payload.id,
          userID: userCredentials.id,
        })
      )
      setIsEdittingForm(false)
      setModal({
        ...modal,
        viewDownloadForms: false,
      })
      setPayload({
        id: 0,
        FormName: '',
        Link: '',
      })
    }
  }

  // Pagination
  // Table Size
  const pageSize = 10

  // Every render of the page do the statement inside the useEffect
  useEffect(() => {
    // Set the Display size of the Table
    setpaginatedForms(_(dlForms).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [dlForms])

  // Count Number the length of the Array and Divide it to the page size
  const pageCount = dlForms
    ? // Inline if-else statement
      // Math.ceil convert the parameter into the nearest integer value
      Math.ceil(dlForms.length / pageSize)
    : 0

  // if (pageCount === 1) return null
  // Store the page count ito pages array
  // (1, pageCount) means create an area starting from 1 up to the number result of pageCount plus 1
  const pages = _.range(1, pageCount + 1)

  // fat arrow function for onClick in Pagination
  // pagination function will recieve an argument of pageNo so that if you are going to use this function you must submit a pageNo parameter ex. pagination(page)
  const pagination = (pageNo) => {
    // set the current page value to the argument receive by the function
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    // console.log(pageNo)
    // console.log(startIndex)
    const paginationForms = _(dlForms).slice(startIndex).take(pageSize).value()
    setpaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    // setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    // setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Pagination

  useEffect(() => {
    dispatch(downloadableformsRetrieve())
    dispatch(retrieveTApp())
    dispatch(retrieveTEmployees())
    dispatch(retrieveTUsers())
    dispatch(retrieveTUsersForVerification())
    dispatch(retrieveTRegisteredEng())
    dispatch(retrieveTNOV())
    dispatch(retrieveTNUP())
    dispatch(retrieveTMessages())
  }, [dispatch])

  useEffect(() => {
    if (downloadableformsDetail) {
      setDlForms(downloadableformsDetail)
    }
  }, [downloadableformsDetail])

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          <Row className='pt-3'>
            <Col className='mt-2' xl='3'>
              <Card bg='info' text='light'>
                <Card.Header>
                  <div className='d-flex justify-content-between align-items-center'>
                    <FaFileAlt className='fs-1' />
                    <div>
                      <h6 className='fw-bold'>Total No. : </h6>
                      {retrievedTApp && retrievedTApp.message && (
                        <h1 className='m-0 fw-bold text-end'>
                          {retrievedTApp.message.totalApplication}
                        </h1>
                      )}
                      {retrievedTApp && retrievedTApp.loading && (
                        <Spinner animation='border' />
                      )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>Applications</Card.Body>
              </Card>
            </Col>
            <Col className='mt-2' xl='3'>
              <Card bg='secondary' text='dark'>
                <Card.Header>
                  <div className='d-flex justify-content-between align-items-center'>
                    <FaUserAlt className='fs-1' />
                    <div>
                      <h6 className='fw-bold'>Total No. : </h6>
                      {retrievedTEmployees && retrievedTEmployees.message && (
                        <h1 className='m-0 fw-bold text-end'>
                          {retrievedTEmployees.message.totalApplication}
                        </h1>
                      )}
                      {retrievedTEmployees && retrievedTEmployees.loading && (
                        <Spinner animation='border' />
                      )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>Employee</Card.Body>
              </Card>
            </Col>
            <Col className='mt-2' xl='3'>
              <Card bg='success' text='light'>
                <Card.Header>
                  <div className='d-flex justify-content-between align-items-center'>
                    <FaUserCheck className='fs-1' />
                    <div>
                      <h6 className='fw-bold'>Total No. : </h6>
                      {retrievedTUsers && retrievedTUsers.message && (
                        <h1 className='m-0 fw-bold text-end'>
                          {retrievedTUsers.message.totalApplication}
                        </h1>
                      )}
                      {retrievedTUsers && retrievedTUsers.loading && (
                        <Spinner animation='border' />
                      )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>User</Card.Body>
              </Card>
            </Col>
            <Col className='mt-2' xl='3'>
              <Card bg='danger' text='light'>
                <Card.Header>
                  <div className='d-flex justify-content-between align-items-center'>
                    <FaUserEdit className='fs-1' />
                    <div>
                      <h6 className='fw-bold'>Total No. : </h6>
                      {retrievedTUsersForVerification &&
                        retrievedTUsersForVerification.message && (
                          <h1 className='m-0 fw-bold text-end'>
                            {
                              retrievedTUsersForVerification.message
                                .totalApplication
                            }
                          </h1>
                        )}
                      {retrievedTUsersForVerification &&
                        retrievedTUsersForVerification.loading && (
                          <Spinner animation='border' />
                        )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>User for verification</Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className='pt-3'>
            <Col className='mt-2' xl='3'>
              <Card bg='warning' text='dark'>
                <Card.Header>
                  <div className='d-flex justify-content-between align-items-center'>
                    <FaFileArchive className='fs-1' />
                    <div>
                      <h6 className='fw-bold'>Total No. : </h6>
                      {retrievedTNOV && retrievedTNOV.message && (
                        <h1 className='m-0 fw-bold text-end'>
                          {retrievedTNOV.message.totalApplication}
                        </h1>
                      )}
                      {retrievedTNOV && retrievedTNOV.loading && (
                        <Spinner animation='border' />
                      )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>Notive of Violation</Card.Body>
              </Card>
            </Col>
            <Col className='mt-2' xl='3'>
              <Card bg='primary' text='light'>
                <Card.Header>
                  <div className='d-flex justify-content-between align-items-center'>
                    <FaNewspaper className='fs-1' />
                    <div>
                      <h6 className='fw-bold'>Total No. : </h6>
                      {retrievedTNUP && retrievedTNUP.message && (
                        <h1 className='m-0 fw-bold text-end'>
                          {retrievedTNUP.message.totalApplication}
                        </h1>
                      )}
                      {retrievedTNUP && retrievedTNUP.loading && (
                        <Spinner animation='border' />
                      )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>News & Updates</Card.Body>
              </Card>
            </Col>
            <Col className='mt-2' xl='3'>
              <Card bg='dark' text='light'>
                <Card.Header>
                  <div className='d-flex justify-content-between align-items-center'>
                    <FaInbox className='fs-1' />
                    <div>
                      <h6 className='fw-bold'>Total No. : </h6>
                      {retrievedTMessages && retrievedTMessages.message && (
                        <h1 className='m-0 fw-bold text-end'>
                          {retrievedTMessages.message.totalApplication}
                        </h1>
                      )}
                      {retrievedTMessages && retrievedTMessages.loading && (
                        <Spinner animation='border' />
                      )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>Messages</Card.Body>
              </Card>
            </Col>
            <Col className='mt-2' xl='3'>
              <Card bg='light' text='dark'>
                <Card.Header>
                  <div className='d-flex justify-content-between align-items-center'>
                    <FaHardHat className='fs-1' />
                    <div>
                      <h6 className='fw-bold'>Total No. : </h6>
                      {retrievedTRegisteredEng &&
                        retrievedTRegisteredEng.message && (
                          <h1 className='m-0 fw-bold text-end'>
                            {retrievedTRegisteredEng.message.totalApplication}
                          </h1>
                        )}
                      {retrievedTRegisteredEng &&
                        retrievedTRegisteredEng.loading && (
                          <Spinner animation='border' />
                        )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>Registered Engineer</Card.Body>
              </Card>
            </Col>
          </Row>

          <hr className='mt-4' />

          <Row className='mx-2'>
            <Col xl={9} className='shadow-lg'>
              {downloadableformsDetail ? (
                <>
                  <h5 className='pt-3 pb-2 mb-0'>Downloadable Forms</h5>
                  <hr
                    className='mt-1 mb-1'
                    style={{
                      background: '#ffeb36',
                      paddingBottom: '2px',
                      width: '200px',
                    }}
                  />
                  <Button
                    variant='outline-success'
                    size='sm'
                    className='mb-2'
                    onClick={() => setModal({ viewDownloadForms: true })}
                  >
                    CREATE NEW FORM
                  </Button>
                  <Table size='sm' variant='white' bordered hover responsive>
                    <thead className='bg-info text-light'>
                      <tr>
                        <th>File Name</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedForms.map((paginatedForm) => (
                        <tr key={paginatedForm.id}>
                          <td>{paginatedForm.FormName}</td>
                          <td className='text-center'>
                            <Button
                              variant='success'
                              size='sm'
                              onClick={() => {
                                setModal({ viewDownloadForms: true })
                                setPayload(paginatedForm)
                                setIsEdittingForm(true)
                              }}
                            >
                              <FaEye />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <div className='text-center py-5'>
                  <Spinner animation='border' />
                  <h3>Loading...</h3>
                </div>
              )}
              {dlForms.length > 1 && (
                <Col xl={12} className='pt-1 d-flex justify-content-end'>
                  <Pagination size='sm'>
                    <Pagination.Item
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {renderPageNumber}

                    <Pagination.Item
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              )}
            </Col>
            <Col xl={3} className='shadow-lg'>
              <h5 className='pt-3 pb-2 mb-0'>News & Updates</h5>
              <hr
                className='mt-1 mb-3'
                style={{
                  background: '#ffeb36',
                  paddingBottom: '2px',
                  width: '200px',
                }}
              />

              <Carousel prevLabel='' nextLabel=''>
                <Carousel.Item interval={5000}>
                  <Card className='mb-3'>
                    <Card.Img
                      variant='top'
                      src='assets/img/sample-profile.jpg'
                      height='300'
                    />
                    <Card.Body>
                      <Card.Title>Card Title</Card.Title>
                      <Card.Text>Lorem ipsum</Card.Text>
                      <Button>View</Button>
                    </Card.Body>
                  </Card>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                  <Card className='mb-3'>
                    <Card.Img
                      variant='top'
                      src='assets/img/sample-profile.jpg'
                      height='300'
                    />
                    <Card.Body>
                      <Card.Title>Card Title2</Card.Title>
                      <Card.Text>Lorem ipsum2</Card.Text>
                      <Button>View</Button>
                    </Card.Body>
                  </Card>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </Container>

      <Modal
        size='md'
        show={modal.viewDownloadForms}
        onHide={() => setModal({ viewDownloadForms: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> Downloadable Form
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={isEdittingForm ? updateSubmittedForm : createForm}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                File Name : <i className='text-danger'>*</i>
              </Form.Label>
              <Form.Control
                type='text'
                value={payload.FormName}
                name='FormName'
                onChange={handleChangeForm}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Link : <i className='text-danger'>*</i>
              </Form.Label>
              <Form.Control
                type='text'
                value={payload.Link}
                name='Link'
                onChange={handleChangeForm}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' variant='success' type='submit'>
              Submit
            </Button>

            {isEdittingForm && (
              <Button size='sm' variant='info' onClick={deleteForm}>
                Delete
              </Button>
            )}

            <Button
              size='sm'
              variant='danger'
              onClick={() => {
                setModal({ viewDownloadForms: false })
                setPayload({
                  id: 0,
                  FormName: '',
                  Link: '',
                })
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </UserMain>
  )
}

export default ICTDashboardScreen
