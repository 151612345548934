import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProtectedRoute from '../reusable/ProtectedRoute'

// Components
import ForRecommendationListScreen from '../screens/Recommender/ForRecommendationListScreen'
import ForRecommendationListScreen2 from '../screens/Recommender/ForRecommendationListScreen2'
import RecommendedListScreen from '../screens/Recommender/RecommendedListScreen'
import ApplicationListScreen from '../screens/Recommender/ApplicationListScreen'
import ReturnedListScreen from '../screens/Recommender/ReturnedListScreen'
import DcDashboardScreen from '../screens/DcScreens/DcDashboardScreen'
import ExpiredTokenScreen from '../screens/ExpiredTokenScreen'
import EmployeeProfileScreen from '../screens/DcScreens/EmployeeProfileScreen'
import DcForPaymentScreen from '../screens/DcScreens/DcForPaymentScreen'
import RoutingSlipInfoScreen from '../screens/DcScreens/RoutingSlipInfoScreen'

const RecommenderRoutes = ({ user }) => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path='/dashboard'
        component={DcDashboardScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/for-recommendation-list'
        component={ForRecommendationListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/recommendation-list'
        component={RecommendedListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/applications'
        component={DcForPaymentScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/returned-list'
        component={ReturnedListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/profile'
        component={EmployeeProfileScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/routing-slip/:id?'
        component={RoutingSlipInfoScreen}
        isAuth={user === null ? false : true}
      />

      <Route component={ExpiredTokenScreen} />
    </Switch>
  )
}

export default RecommenderRoutes
