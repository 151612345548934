import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import moment from 'moment'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import PaginationTable from '../../reusable/PaginationTable'
import SuccessModal from '../../reusable/SuccessModal'
import Loading from '../../reusable/Loading'
import Error from '../../reusable/Error'

// Modal
import ApplicationViewing from '../../reusable/ApplicationViewing'
import ApplicationTrackStatus from '../../reusable/ApplicationTrackStatus'
import ApplicationManager from '../../modal/ApplicationManager'
import ChangeApplicationStatus from '../../reusable/ChangeApplicationStatus'
import ApplicationInformation from '../../reusable/ApplicationInformation'
import RequireDocuments from '../../reusable/RequireDocuments'
import EngArchModal from '../../reusable/EngArchModal'
import AppEvaluators from '../../reusable/AppEvaluators'
import AppInspector from '../../reusable/AppInspector'
import EvaluationSheetScreen from '../../reusable/EvaluationSheetScreen'
import AddEditEvaluationSheet from '../../reusable/AddEditEvaluationSheet'
import CreateCFEI from '../../reusable/CreateCFEI'

// Style
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Form,
  InputGroup,
  Spinner,
  Card,
  Table,
} from 'react-bootstrap'
import { FaCheckCircle, FaSearch, FaEye, FaShare } from 'react-icons/fa'
import io from 'socket.io-client'
import { useToast } from 'rc-toastr'

// Actions
import {
  RetrieveStatusTrack,
  userRetrievedReleasePermit,
  retrieveReleasedOccu,
  userRetrievedReleaseCFEI,
} from '../../redux/actions/userApplicationActions'
import {
  retrieveAllApplications,
  retrieveApplicationInformation,
  retrieveSubmittedOrderofPayment,
  updateReleasePermit,
  updateContractorsTax,
  retrieveAmountPaid,
  retrieveEvaluationSheet,
  retrievefilterApplications,
  retrieveSubmittedRequirements,
  retrieveRequirementList,
  retrieveSubmittedEngineers,
  retrieveEvaluators,
  retrieveSubmittedEvaluators,
  retrieveInspectors,
  retrieveSubmittedInspector,
  retriveCFEI,
  retrieveContractorsTax,
  retrieveAppStatTracking,
  retriveRPT,
  retriveCT,
  rptLogin,
  ctLogin,
  deleteEvaluationSheet,
  insertEvaluationSheet,
} from '../../redux/actions/DocumentControllers/documentControllerActions'
import { retrieveDateApplied } from '../../redux/actions/adminApplicationActions'

// const socket = io.connect('http://localhost:5000')

const socket = io.connect(process.env.REACT_APP_URL)

const DcForPaymentScreen = ({ history }) => {
  // React-Redux Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)
  const { userCredentials } = user
  // End React-Redux Declaration

  // Declaration
  const [searchTerm, setSearchTerm] = useState('')

  const [state, setState] = useState({})

  const [filterData, setFilterData] = useState({
    filtered: false,
    data: [],
    status: '',
    ApplicationName: '',
  })

  const [releaseDetails, setReleaseDetails] = useState({
    amount: 0,
    orNo: '',
    orDate: '',
    formattedAmount: '',
  })

  const [payload, setPayload] = useState({})

  const [showViewing, setShowViewing] = useState(false)

  const [showTracking, setShowTracking] = useState(false)

  const [showRelease, setShowRelease] = useState(false)

  const [successRelease, setSuccessRelease] = useState(false)

  const [userLevel, setUserLevel] = useState('')

  const [modal, setModal] = useState({
    viewFilter: false,
    applicationViewing: false,
    trackStatusViewing: false,
    applicationManager: false,
    status: false,
    applicationInfo: false,
    requiredDocs: false,
    engArch: false,
    evaluators: false,
    inspector: false,
    routingSlip: false,
    viewEvaluationSheet: false,
    editRemarks: false,
    viewCreateCFEI: false,
    assessorsViewing: false,
    constractorTaxViewing: false,
  })

  const [evaluationSheet, setEvaluationSheet] = useState({
    id: 0,
    trades: '',
    remarks: '',
    appID: 0,
    empID: 0,
  })

  const [rpt, setRpt] = useState({
    TDN: '',
    PIN: '',
    active: false,
  })

  const [ctValue, setCtValue] = useState({
    TranNo: '',
    TranDate: '',
    Contractor: '',
    Gross: '',
    Particular: '',
    active: false,
    contractorsTaxComputed: 0,
  })

  const userApplications = useSelector((state) => state.applications)
  const {
    statusTracking,
    retrievedReleasePermit,
    retrievedReleasedOccu,
    retrievedCFEI,
  } = userApplications

  const dc = useSelector((state) => state.documentControllerReducer)

  const {
    allApplications,
    filteredApplication,
    releasedPermit,
    retrievedAppStatTracking,
    retrievedRPT,
    retrievedCT,
    retrievedContractorsTax,
    submittedOrderofPayment,
    paidAmountList,
    retrievedEvaluationSheet,
    updatedContractorsTax,
  } = dc

  const adminApplication = useSelector((state) => state.adminApplication)

  const { dateApplied } = adminApplication

  const totalPaid =
    paidAmountList && paidAmountList.message !== undefined
      ? paidAmountList.message.reduce(
          (acc, currentValue) => acc + currentValue.amount,
          0
        )
      : 0

  const balance =
    submittedOrderofPayment &&
    submittedOrderofPayment.message &&
    parseFloat(submittedOrderofPayment.message[0].totalAmount) -
      parseFloat(totalPaid)
  // End Declaration

  // Functions
  // const sendNewStatus = () => {
  //   socket.emit('send_status', {
  //     message: userCredentials.id,
  //   })
  // }

  const handleView = (payload) => {
    setPayload(payload)
    // setShowViewing(true)
    setModal({ applicationViewing: true })
    dispatch(retrieveDateApplied({ id: payload.id }))
    dispatch(rptLogin())
    dispatch(ctLogin())
    setState({
      ...state,
      app_status_id: payload.statusID,
      appID: payload.id,
      userID: payload.userID,
      dcID: userCredentials.id,
      applicationName: payload.ApplicationName,
      status: payload.status,
      remarks: payload.remarks,
    })

    switch (payload.ApplicationName) {
      case 'OCCUPANCY PERMIT':
        dispatch(
          retrieveReleasedOccu({
            id: payload.id,
            // userID: userCredentials.id,
          })
        )
        break
      case 'ELECTRICAL PERMIT':
        dispatch(
          userRetrievedReleaseCFEI({
            id: payload.id,
          })
        )
        break
      case 'FENCING PERMIT':
        break
      case 'DEMOLITION PERMIT':
        break
      default:
        dispatch(
          userRetrievedReleasePermit({
            id: payload.id,
            userID: payload.userID,
          })
        )
        break
    }

    // if (payload.ApplicationName !== 'OCCUPANCY PERMIT') {
    //   dispatch(
    //     userRetrievedReleasePermit({
    //       id: payload.id,
    //       userID: payload.userID,
    //     })
    //   )
    // } else {
    //   dispatch(
    //     retrieveReleasedOccu({
    //       id: payload.id,
    //       // userID: userCredentials.id,
    //     })
    //   )
    // }
  }

  const handleAmountChange = (e) => {
    const newValue = Number(e.target.value.replace(/[^0-9.-]+/g, ''))
    if (parseFloat(newValue) > parseFloat(balance)) {
      return
    }
    setReleaseDetails({
      ...releaseDetails,
      amount: newValue,
      formattedAmount: newValue,
    })
  }

  function formatCurrency(value) {
    // console.log(value)
    if (isNaN(value)) {
      return 0
    } else {
      if (value !== null) {
        return value.toLocaleString('en-PH', {
          style: 'currency',
          currency: 'PHP',
        })
      } else {
        return value
      }
    }
  }

  const handleViewApplicationTracking = () => {
    setModal({ applicationViewing: false, trackStatusViewing: true })
    // console.log(payload.id)
    dispatch(
      retrieveAppStatTracking({
        appID: payload.id,
        // userID: payload.userID,
      })
    )
  }

  const handleViewAssessorsVerification = () => {
    setModal({ ...modal, assessorsViewing: true, applicationViewing: false })
  }

  const handleViewContractorsTaxComputation = () => {
    setModal({
      ...modal,
      constractorTaxViewing: true,
      applicationViewing: false,
    })
    dispatch(retrieveContractorsTax({ id: payload.id }))
  }

  const handleViewPaymentManager = () => {
    setModal({ applicationViewing: false })
    setShowRelease(true)
    dispatch(
      retrieveSubmittedOrderofPayment({
        id: payload.id,
      })
    )
    dispatch(retrieveAmountPaid({ id: payload.id }))
  }

  const handleViewEvaluationSheet = () => {
    dispatch(retrieveEvaluationSheet({ id: payload.id }))
    // history.push(`/evaluation-sheet/${payload.id}`)
    setModal({
      ...modal,
      applicationViewing: false,
      viewEvaluationSheet: true,
    })
  }

  const handleViewCreateCFEI = () => {
    // dispatch(retrieveEvaluationSheet({ id: payload.id }))
    // history.push(`/evaluation-sheet/${payload.id}`)
    setModal({
      ...modal,
      applicationViewing: false,
      viewCreateCFEI: true,
    })
    dispatch(retriveCFEI({ id: payload.id }))
  }

  const handleViewApplicationManager = () => {
    setModal({ applicationViewing: false, applicationManager: true })
    // sendNewStatus()
    dispatch(retrieveAmountPaid({ id: payload.id }))
    dispatch(
      retrieveSubmittedOrderofPayment({
        id: payload.id,
      })
    )
  }

  const handleChangeStatus = () => {
    setModal({
      ...modal,
      applicationManager: false,
      status: true,
    })
  }

  const handleApplicationInfo = () => {
    setModal({
      ...modal,
      applicationManager: false,
      applicationInfo: true,
    })
    dispatch(retrieveApplicationInformation({ id: payload.id }))
  }

  const handleRequiredDocuments = () => {
    setModal({
      ...modal,
      applicationManager: false,
      requiredDocs: true,
    })
    dispatch(retrieveSubmittedRequirements({ id: payload.id }))
    dispatch(retrieveRequirementList({ pmCode: payload.ApplicationName }))
  }

  const handleEngArchModal = () => {
    setModal({
      ...modal,
      applicationManager: false,
      engArch: true,
    })

    dispatch(retrieveSubmittedEngineers({ id: payload.id }))
  }

  const handleApplicationEvaluator = () => {
    setModal({
      ...modal,
      applicationManager: false,
      evaluators: true,
    })
    dispatch(retrieveEvaluators())
    dispatch(retrieveSubmittedEvaluators({ id: payload.id }))
  }

  const handleApplicationInspector = () => {
    setModal({
      ...modal,
      applicationManager: false,
      inspector: true,
    })
    dispatch(retrieveInspectors())
    dispatch(retrieveSubmittedInspector({ id: payload.id }))
  }

  const handleRoutingSlip = () => {
    history.push(`/routing-slip/${payload.id}`)
  }

  const handleEditEvaluationSheet = (e, selectedItem) => {
    setModal({ editRemarks: true })
    // console.log(selectedItem)
    if (selectedItem === undefined) {
      setEvaluationSheet({
        ...evaluationSheet,
        trades: e[1],
        appID: payload.id,
        empID: userCredentials.id,
      })
    } else {
      setEvaluationSheet({
        ...evaluationSheet,
        id: selectedItem.id,
        trades: e[1],
        appID: payload.id,
        empID: userCredentials.id,
        remarks: selectedItem.remarks,
      })
    }
  }

  const handleDeleteEvaluationSheet = (match) => {
    if (
      window.confirm('Are you sure you want to update status of application?')
    ) {
      dispatch(
        deleteEvaluationSheet({
          id: match.id,
          appID: match.appID,
        })
      )
      setEvaluationSheet({
        ...evaluationSheet,
        id: 0,
        trades: '',
        remarks: '',
        appID: 0,
      })
    }
  }

  const handleSubmitEvaluationSheet = (e) => {
    e.preventDefault()
    dispatch(
      insertEvaluationSheet({
        id: evaluationSheet.id,
        trades: evaluationSheet.trades,
        remarks: evaluationSheet.remarks,
        appID: evaluationSheet.appID,
        empID: evaluationSheet.empID,
      })
    )
    setModal({
      ...modal,
      viewEvaluationSheet: true,
      editRemarks: false,
    })
  }

  const { toast } = useToast()
  const sayHello = (data) => {
    toast.success(data)
  }

  // End Function

  // UseEffect
  useEffect(() => {
    dispatch(retrieveAllApplications())
    socket.on('received_new_application', (data) => {
      // if (window.confirm(`${data.message} do you want to reload the page ?`)) {
      dispatch(retrieveAllApplications())
      sayHello(data.message)
      // }
    })

    socket.on('received_change_status', (data) => {
      sayHello(`${data.message.notif}, Application ID ${data.message.appID}`)
      // console.log(data)
    })
  }, [dispatch, socket])

  // Min Date
  const [appDate, setAppDate] = useState('')

  const minDate = () => {
    let dateMin = new Date()

    let minYear = dateMin.getFullYear()
    let minMonth = ('0' + (dateMin.getMonth() + 1)).slice(-2)
    let minDay = ('0' + dateMin.getDate()).slice(-2)

    let minDate = minYear + '-' + minMonth + '-' + minDay

    if (appDate === '') {
      setAppDate(minDate)
    }
  }

  useEffect(() => {
    minDate()
  })

  useEffect(() => {
    if (filterData.filtered) {
      setFilterData({
        ...filterData,
        data: filteredApplication && filteredApplication.message,
      })
    }

    if (userCredentials.roles !== 'evaluator') {
      setUserLevel(userCredentials.roles)
    } else {
      setUserLevel(userCredentials.subroles)
    }

    if (submittedOrderofPayment && submittedOrderofPayment.message) {
      // submittedOrderofPayment.message[0].totalAmount
      setReleaseDetails({
        ...releaseDetails,
        amount: submittedOrderofPayment.message[0].totalAmount,
      })
    }

    if (retrievedCT && retrievedCT.message) {
      setCtValue({
        ...ctValue,
        contractorsTaxComputed: retrievedCT.message[0].Contractors_Tax,
      })
    }

    if (
      !retrievedCT &&
      retrievedContractorsTax &&
      retrievedContractorsTax.message
    ) {
      setCtValue({
        ...ctValue,
        contractorsTaxComputed:
          retrievedContractorsTax.message[0].contractors_tax,
      })
    }
    if (updatedContractorsTax && updatedContractorsTax.message) {
      setInterval(() => {
        // setModal({
        //   constractorTaxViewing: false,
        //   applicationViewing: true,
        // })
        window.location.reload(true)
      }, [3000])
    }
  }, [
    filteredApplication,
    userCredentials,
    submittedOrderofPayment,
    retrievedCT,
    retrievedContractorsTax,
    updatedContractorsTax,
  ])
  // End Min Date

  // console.log(retrievedReleasePermit && retrievedReleasePermit.message)

  // console.log('Total Paid: ' + totalPaid)
  // console.log(retrievedCFEI)

  //let date = new Date()
  /// console.log(date.toLocaleString('PST'))

  // console.log(retrievedCT && retrievedCT.message)

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {allApplications && allApplications.message && (
            <>
              {/* Search Bar */}
              <Form className='mx-3'>
                <Row className='justify-content-end pt-5'>
                  <Col md={4} className='my-2'>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                        size='sm'
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>
              {/* End Search Bar */}

              <PaginationTable
                data={
                  !filterData.filtered
                    ? allApplications.message
                    : filterData.data
                }
                searchTerm={searchTerm}
                handleView={handleView}
              />
            </>
          )}

          {allApplications && allApplications.loading && (
            <div className='pt-5'>
              <Loading />
            </div>
          )}

          {allApplications && allApplications.error && (
            <div className='pt-5'>
              <Error message='No application found' />
            </div>
          )}
        </Container>
      </Container>

      {/*  Application Viewing */}
      <ApplicationViewing
        show={modal.applicationViewing}
        onHide={() => {
          setModal({ applicationViewing: false })
          window.location.reload(true)
        }}
        payload={Object.keys(payload).length > 0 && payload}
        dateApplied={
          dateApplied &&
          dateApplied.message &&
          dateApplied.message[0].DateApplied
        }
        handleViewApplicationTracking={handleViewApplicationTracking}
        handleViewPaymentManager={handleViewPaymentManager}
        handleViewEvaluationSheet={handleViewEvaluationSheet}
        handleViewApplicationManager={handleViewApplicationManager}
        handleViewCreateCFEI={handleViewCreateCFEI}
        permitValue={retrievedReleasePermit && retrievedReleasePermit.message}
        permitValue2={retrievedReleasedOccu && retrievedReleasedOccu.message}
        permitCFEI={retrievedCFEI && retrievedCFEI.message}
        handleViewAssessorsVerification={handleViewAssessorsVerification}
        handleViewContractorsTaxComputation={
          handleViewContractorsTaxComputation
        }
        userCredentials={userCredentials}
      />
      {/* End User Application Viewing */}

      {/* Application Manager */}
      <ApplicationManager
        show={modal.applicationManager}
        onHide={() =>
          setModal({ applicationViewing: true, applicationManager: false })
        }
        payload={Object.keys(payload).length > 0 && payload}
        handleChangeStatus={handleChangeStatus}
        handleApplicationInfo={handleApplicationInfo}
        handleRequiredDocuments={handleRequiredDocuments}
        handleEngArchModal={handleEngArchModal}
        handleApplicationEvaluator={handleApplicationEvaluator}
        handleApplicationInspector={handleApplicationInspector}
        handleRoutingSlip={handleRoutingSlip}
      />
      {/* End Application Manager */}

      {/* Application Tracking Modal */}
      <ApplicationTrackStatus
        show={modal.trackStatusViewing}
        onHide={() =>
          setModal({ applicationViewing: true, trackStatusViewing: false })
        }
        statusTracking={retrievedAppStatTracking && retrievedAppStatTracking}
      />
      {/*End Application Tracking Modal */}

      {/* Change Application Status */}
      <ChangeApplicationStatus
        show={modal.status}
        onHide={() => setModal({ applicationManager: true, status: false })}
        onClose={() => setModal({ applicationManager: false })}
        onOpen={() => setModal({ ...modal, status: true })}
        payload={payload}
        balance={balance}
      />
      {/* End Change Application Status */}

      {/* Application Information */}
      <ApplicationInformation
        show={modal.applicationInfo}
        onHide={() =>
          setModal({
            ...modal,
            applicationManager: true,
            applicationInfo: false,
          })
        }
        payload={payload}
        onClose={() =>
          setModal({ applicationManager: false, applicationInfo: false })
        }
        onOpen={() => setModal({ ...modal, applicationInfo: true })}
      />
      {/* End Application Information */}

      {/* Required Documents */}
      <RequireDocuments
        show={modal.requiredDocs}
        onHide={() =>
          setModal({
            ...modal,
            applicationManager: true,
            requiredDocs: false,
          })
        }
        payload={payload}
        onClose={() =>
          setModal({ applicationManager: false, requiredDocs: false })
        }
        onOpen={() => setModal({ ...modal, requiredDocs: true })}
      />
      {/* End Required Documents */}

      {/* Evaluators */}
      <AppEvaluators
        show={modal.evaluators}
        onHide={() =>
          setModal({
            ...modal,
            applicationManager: true,
            evaluators: false,
          })
        }
        payload={payload}
        onClose={() =>
          setModal({ applicationManager: false, evaluators: false })
        }
        onOpen={() => setModal({ ...modal, evaluators: true })}
      />
      {/* End Evaluators */}

      {/* Inspector */}
      <AppInspector
        show={modal.inspector}
        onHide={() =>
          setModal({
            ...modal,
            applicationManager: true,
            inspector: false,
          })
        }
        payload={payload}
        onClose={() =>
          setModal({ applicationManager: false, inspector: false })
        }
        onOpen={() => setModal({ ...modal, inspector: true })}
      />
      {/* End Inspector */}

      {/* Engineer Architect Modal */}
      <EngArchModal
        show={modal.engArch}
        onHide={() =>
          setModal({
            ...modal,
            applicationManager: true,
            engArch: false,
          })
        }
        payload={payload}
        onClose={() => setModal({ applicationManager: false, engArch: false })}
        onOpen={() => setModal({ ...modal, engArch: true })}
      />
      {/* End Engineer Architect Modal */}

      {/* Payment Manager Modal */}
      <Modal
        size='xl'
        show={showRelease}
        onHide={() => setShowRelease(false)}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> Payment Manager
          </Modal.Title>
        </Modal.Header>
        {submittedOrderofPayment && submittedOrderofPayment.message && (
          <Modal.Body>
            <Table responsive>
              <tbody>
                <tr>
                  <td>Application No:</td>
                  <td>{submittedOrderofPayment.message[0].applicationID}</td>
                </tr>
                <tr>
                  <td>Applicant Name:</td>
                  <td>{submittedOrderofPayment.message[0].ApplicantName}</td>
                </tr>
                <tr>
                  <td>Order of Payment ID:</td>
                  <td>{submittedOrderofPayment.message[0].id}</td>
                </tr>
                <tr>
                  <td>Other Permit Sub-Total:</td>
                  <td>
                    {formatCurrency(
                      submittedOrderofPayment.message[0].subTotal
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Building Permit Sub-Total:</td>
                  <td>
                    {formatCurrency(
                      submittedOrderofPayment.message[0].subTotal1
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Total Amount:</td>
                  <td className='text-danger'>
                    {formatCurrency(
                      submittedOrderofPayment.message[0].totalAmount
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table responsive>
              <thead>
                <tr className='text-nowrap'>
                  <td>OR No.:</td>
                  <td>OR Date:</td>
                  <td>Referrence No.:</td>
                  <td>Referrence Date:</td>
                  <td>Amount:</td>
                  <td>Encoded By:</td>
                  <td>Encoded Date:</td>
                </tr>
              </thead>
              <tbody>
                {paidAmountList &&
                  paidAmountList.message &&
                  paidAmountList.message.map((list, index) => (
                    <tr key={index} className='text-nowrap'>
                      <td>{list.orNo}</td>
                      <td>
                        {list.orDate !== null &&
                          moment(list.orDate).format('MMMM D, YYYY')}
                      </td>
                      <td>{list.refNo}</td>
                      <td>
                        {list.reDate !== null &&
                          moment(list.reDate).format('MMMM D, YYYY')}
                      </td>
                      <td>{formatCurrency(list.amount)}</td>
                      <td>{list.EncodedBy}</td>
                      <td>
                        {list.encodedDate !== null &&
                          moment(list.encodedDate).format('MMMM D, YYYY h:mma')}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td colSpan={4}>
                    <h5>Total</h5>
                  </td>
                  <td className='text-danger'>
                    {formatCurrency(totalPaid !== undefined ? totalPaid : 0)}
                  </td>
                  <td>
                    <h5>Balance</h5>
                  </td>
                  <td className='text-danger'>
                    {formatCurrency(balance !== undefined ? balance : '0')}
                  </td>
                </tr>
              </tbody>
            </Table>
            {paidAmountList && paidAmountList.error && (
              <Container className='px-5'>
                <Form>
                  <Form.Label>OR No.</Form.Label>
                  <Form.Control
                    type='text'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    name='orNo'
                    onChange={(e) =>
                      setReleaseDetails({
                        ...releaseDetails,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type='text'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    name='amount'
                    value={releaseDetails.amount}
                    onChange={handleAmountChange}
                    onBlur={() =>
                      setReleaseDetails({
                        ...releaseDetails,
                        formattedAmount: formatCurrency(releaseDetails.amount),
                      })
                    }
                    disabled
                  />
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type='date'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    name='orDate'
                    onChange={(e) =>
                      setReleaseDetails({
                        ...releaseDetails,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Form>
              </Container>
            )}

            {paidAmountList &&
              paidAmountList.message &&
              paidAmountList.message[0].orDate === null && (
                <Container>
                  <Form>
                    <Row>
                      <Form.Group as={Col} md='6'>
                        <Form.Label>Or No:</Form.Label>
                        <Form.Control
                          type='text'
                          name='orNo'
                          onChange={(e) =>
                            setReleaseDetails({
                              ...releaseDetails,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} md='6'>
                        <Form.Label>Or Date:</Form.Label>
                        <Form.Control
                          type='date'
                          name='orDate'
                          onChange={(e) =>
                            setReleaseDetails({
                              ...releaseDetails,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                </Container>
              )}
          </Modal.Body>
        )}
        {submittedOrderofPayment && submittedOrderofPayment.loading && (
          <Loading />
        )}
        <Modal.Footer>
          {paidAmountList &&
            paidAmountList.error &&
            submittedOrderofPayment &&
            submittedOrderofPayment.message && (
              <Button
                variant='success'
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(
                    updateReleasePermit({
                      id: payload.id,
                      amount: releaseDetails.amount,
                      orNo: releaseDetails.orNo,
                      orDate: releaseDetails.orDate,
                      encodedBy: userCredentials.id,
                      releaseBy: userCredentials.id,
                    })
                  )
                  setShowRelease(false)
                  setSuccessRelease(true)
                }}
              >
                Submit
              </Button>
            )}

          {paidAmountList &&
            paidAmountList.message &&
            paidAmountList.message[0].orDate === null && (
              <Button
                variant='success'
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(
                    updateReleasePermit({
                      id: payload.id,
                      amount: releaseDetails.amount,
                      orNo: releaseDetails.orNo,
                      orDate: releaseDetails.orDate,
                      encodedBy: userCredentials.id,
                      releaseBy: userCredentials.id,
                    })
                  )
                  setShowRelease(false)
                  setSuccessRelease(true)
                }}
              >
                Submit
              </Button>
            )}

          <Button
            variant='danger'
            onClick={() => {
              setShowRelease(false)
              setModal({
                ...modal,
                applicationViewing: true,
              })
            }}
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/*End Payment Manager Modal */}

      {/* Modal Evaluation Sheet */}
      <EvaluationSheetScreen
        show={modal.viewEvaluationSheet}
        onHide={() => {
          setModal({
            ...modal,
            viewEvaluationSheet: false,
            applicationViewing: true,
          })
        }}
        retrievedEvaluationSheet={retrievedEvaluationSheet}
        userLevel={userLevel}
        openAddEdit={() => {
          setModal({ ...modal, viewEvaluationSheet: false, editRemarks: true })
        }}
        closeAddEdit={() =>
          setModal({ ...modal, viewEvaluationSheet: true, editRemarks: false })
        }
        handleEditEvaluationSheet={handleEditEvaluationSheet}
        handleDeleteEvaluationSheet={handleDeleteEvaluationSheet}
      />

      {/* Create CFEI */}
      <CreateCFEI
        show={modal.viewCreateCFEI}
        onHide={() => {
          setModal({
            ...modal,
            viewCreateCFEI: false,
            applicationViewing: true,
          })
        }}
        appID={payload.id}
        empID={userCredentials.id}
        alert={false}
      />

      {/* Modal Add / Edit Evaluation Sheet */}
      <AddEditEvaluationSheet
        show={modal.editRemarks}
        onHide={() => {
          setModal({
            ...modal,
            editRemarks: false,
            viewEvaluationSheet: true,
          })

          setEvaluationSheet({
            ...evaluationSheet,
            id: 0,
            remarks: '',
          })
        }}
        handleSubmitEvaluationSheet={handleSubmitEvaluationSheet}
        evaluationSheet={evaluationSheet}
        setEvaluationSheet={(e) =>
          setEvaluationSheet({
            ...evaluationSheet,
            remarks: e.target.value,
          })
        }
      />
      {/* End Modal Add / Edit Evaluation Sheet */}

      {/* Filter Modal */}
      <Modal
        size='lg'
        show={modal.viewFilter}
        onHide={() => setModal({ viewFilter: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> Filter
          </Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            setFilterData({ ...filterData, filtered: true })
            dispatch(
              retrievefilterApplications({
                status: filterData.status,
                ApplicationName: filterData.ApplicationName,
              })
            )
            setModal({ viewFilter: false })
          }}
        >
          <Modal.Body>
            <Form.Group>
              <Form.Label>Permit Category</Form.Label>
              <Form.Control
                as='select'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                required
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    ApplicationName: e.target.value,
                  })
                }
              >
                <option>Choose here ...</option>
                <option value='BUILDING CONSTRUCTION PERMIT'>
                  BUILDING CONSTRUCTION PERMIT
                </option>
                <option value='OCCUPANCY PERMIT'>OCCUPANCY PERMIT</option>
                <option value='FENCING PERMIT'>FENCING PERMIT</option>
                <option value='DEMOLITION PERMIT'>DEMOLITION PERMIT</option>
                <option value='ELECTRICAL PERMIT'>ELECTRICAL PERMIT</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as='select'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                required
                onChange={(e) =>
                  setFilterData({ ...filterData, status: e.target.value })
                }
              >
                <option>Choose here ...</option>
                <option value='APPLICATION VERIFICATION'>
                  APPLICATION VERIFICATION
                </option>
                <option value='FOR INSPECTION AND EVALUATION'>
                  FOR INSPECTION AND EVALUATION
                </option>
                <option value='FOR APPROVAL'>FOR APPROVAL</option>
                <option value='APPROVED APPLICATION'>
                  APPROVED APPLICATION
                </option>
                <option value='RELEASE APPLICATION WITH REMARKS'>
                  RELEASE APPLICATION WITH REMARKS
                </option>
                <option value='RELEASE APPLICATION'>RELEASE APPLICATION</option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' variant='success'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewFilter: false,
                })
              }}
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/*End Filter Modal */}

      {/* Assessor's Verification Modal */}
      <Modal
        size='md'
        show={modal.assessorsViewing}
        onHide={() =>
          setModal({ assessorsViewing: false, applicationViewing: true })
        }
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            Assessor's Verification
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <Form.Control
              type='text'
              size='sm'
              style={{ marginRight: '10px' }}
              onChange={(e) => setRpt({ ...rpt, TDN: e.target.value })}
              placeholder='TDN ... ex.(F-001-00362)'
            />
            <Form.Control
              type='text'
              size='sm'
              onChange={(e) => setRpt({ ...rpt, PIN: e.target.value })}
              placeholder='PIN ... ex.(125-00-001-001-001)'
            />
            <Button
              size='sm'
              onClick={() => {
                if (rpt.TDN === '' || rpt.PIN === '') {
                  alert('TDN & PIN must not empty')
                } else {
                  setRpt({ ...rpt, active: true })
                  dispatch(
                    retriveRPT({
                      TDN: rpt.TDN,
                      PIN: rpt.PIN,
                    })
                  )
                }
              }}
            >
              <FaShare />
            </Button>
          </InputGroup>

          {!rpt.active && (
            <div className='text-center pt-3'>
              <h6>Tax Declaration Verification</h6>
            </div>
          )}

          {rpt.active && retrievedRPT && retrievedRPT.message && (
            <Table size='sm' responsive hover className='mt-3'>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>TDN</td>
                  <td>{retrievedRPT.message.TDN}</td>
                </tr>
                <tr>
                  <td>PIN</td>
                  <td>{retrievedRPT.message.PIN}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{retrievedRPT.message.Location}</td>
                </tr>
                <tr>
                  <td>Owner</td>
                  <td>{retrievedRPT.message.Owner}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{retrievedRPT.message.Address}</td>
                </tr>
                <tr>
                  <td>TctNo</td>
                  <td>{retrievedRPT.message.TctNo}</td>
                </tr>
                <tr>
                  <td>Area</td>
                  <td>{retrievedRPT.message.Area}</td>
                </tr>
                <tr>
                  <td>LastOrNo</td>
                  <td>{retrievedRPT.message.LastOrNo}</td>
                </tr>
                <tr>
                  <td>LastDtPaid</td>
                  <td>{retrievedRPT.message.LastDtPaid}</td>
                </tr>
                <tr>
                  <td>Period</td>
                  <td>{retrievedRPT.message.Period}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>{retrievedRPT.message.Amount}</td>
                </tr>
                <tr>
                  <td>RefTdn</td>
                  <td>{retrievedRPT.message.RefTdn}</td>
                </tr>
                <tr>
                  <td>LotNo</td>
                  <td>{retrievedRPT.message.LotNo}</td>
                </tr>
                <tr>
                  <td>BlkNo</td>
                  <td>{retrievedRPT.message.BlkNo}</td>
                </tr>
                <tr>
                  <td>Barangay</td>
                  <td>{retrievedRPT.message.Barangay}</td>
                </tr>
              </tbody>
            </Table>
          )}

          {rpt.active && retrievedRPT && retrievedRPT.loading && (
            <div className='text-center pt-3'>
              <Spinner animation='border' />
              <h3>Loading...</h3>
            </div>
          )}

          {rpt.active && retrievedRPT && retrievedRPT.error && (
            <div className='text-center pt-3'>
              <h3>No Data Found</h3>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size='sm'
            variant='danger'
            onClick={() => {
              setRpt({ ...rpt, active: false })
              setModal({ assessorsViewing: false, applicationViewing: true })
            }}
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/*End Assessor's Verification Modal */}

      {/* Contractors Tax Verification Modal */}
      <Modal
        size='md'
        show={modal.constractorTaxViewing}
        onHide={() =>
          setModal({ constractorTaxViewing: false, applicationViewing: true })
        }
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            Treasury Office Computation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className='mb-3'>
            <Form.Control
              type='text'
              size='sm'
              style={{ marginRight: '10px' }}
              placeholder='Input Transaction No. here ...'
              onChange={(e) =>
                setCtValue({ ...ctValue, TranNo: e.target.value })
              }
            />
            <Form.Control
              type='text'
              size='sm'
              placeholder='Input Trans Date (9-25-2024)'
              onChange={(e) =>
                setCtValue({ ...ctValue, TranDate: e.target.value })
              }
            />
          </InputGroup>
          <InputGroup className='mb-3'>
            <Form.Control
              type='text'
              size='sm'
              style={{ marginRight: '10px' }}
              placeholder='Input Contractor here ...'
              onChange={(e) =>
                setCtValue({ ...ctValue, Contractor: e.target.value })
              }
            />
            <Form.Control
              type='text'
              size='sm'
              placeholder='Input Gross Amount here ...'
              onChange={(e) =>
                setCtValue({ ...ctValue, Gross: e.target.value })
              }
            />
          </InputGroup>
          <Row>
            <Col xl={7}>
              <InputGroup>
                <Form.Control
                  type='text'
                  size='sm'
                  placeholder='Input particular here ...'
                  onChange={(e) =>
                    setCtValue({ ...ctValue, Particular: e.target.value })
                  }
                />
                <Button
                  size='sm'
                  onClick={() => {
                    dispatch(
                      retriveCT({
                        TranNo: ctValue.TranNo,
                        TranDate: ctValue.TranDate,
                        Contractor: ctValue.Contractor,
                        Gross: ctValue.Gross,
                        Particular: ctValue.Particular,
                      })
                    )
                  }}
                >
                  <FaShare />
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <hr className='my-3 text-danger' />
          {retrievedContractorsTax && !retrievedContractorsTax.loading && (
            <div>
              <h6>
                Submitted Contractors Amount:{' '}
                {retrievedContractorsTax.message[0].contractors_tax}
              </h6>
            </div>
          )}
          {retrievedCT && retrievedCT.loading && (
            <div className='text-center pt-3'>
              <Spinner animation='border' />
              <h6>Please wait while we're computing the contractor's tax</h6>
            </div>
          )}

          {retrievedCT && !retrievedCT.loading && (
            <Form>
              <Row>
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type='text'
                    disabled
                    value={
                      retrievedCT &&
                      retrievedCT.message &&
                      retrievedCT.message[0].Contractors_Tax
                    }
                  />
                </Form.Group>
              </Row>
              {retrievedCT && retrievedCT.message && (
                <div className='mt-3 text-end'>
                  <Button
                    size='sm'
                    variant='success'
                    onClick={() => {
                      dispatch(
                        updateContractorsTax({
                          contractors_tax: ctValue.contractorsTaxComputed,
                          appID: payload.id,
                        })
                      )
                    }}
                    disabled={
                      !updatedContractorsTax
                        ? false
                        : updatedContractorsTax && updatedContractorsTax.loading
                        ? true
                        : false
                    }
                  >
                    {!updatedContractorsTax ? (
                      'Submit'
                    ) : updatedContractorsTax &&
                      !updatedContractorsTax.loading ? (
                      'Submit'
                    ) : (
                      <Spinner className='m-0 p-0' animation='grow' size='sm' />
                    )}
                  </Button>
                  {updatedContractorsTax &&
                    !updatedContractorsTax.loading &&
                    updatedContractorsTax.message && (
                      <h6>Updated Successfully</h6>
                    )}
                  {updatedContractorsTax &&
                    !updatedContractorsTax.loading &&
                    updatedContractorsTax.error && <h6>Transaction Failed</h6>}
                </div>
              )}
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size='sm'
            variant='danger'
            onClick={() => {
              setRpt({ ...rpt, active: false })
              setModal({
                constractorTaxViewing: false,
                applicationViewing: true,
              })
            }}
            disabled={updatedContractorsTax && !updatedContractorsTax.loading}
          >
            {!updatedContractorsTax
              ? 'Back'
              : updatedContractorsTax &&
                !updatedContractorsTax.loading && (
                  <Spinner animation='border' size='sm' />
                )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/*End Contractors Tax Verification Modal */}

      {/*Success Modal */}
      <SuccessModal
        show={successRelease}
        onHide={() => {
          setSuccessRelease(false)
          // window.location.reload(true)
        }}
        transLoading={releasedPermit && releasedPermit.loading}
        success={releasedPermit && releasedPermit.message}
        error={releasedPermit && releasedPermit.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(DcForPaymentScreen)
