import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_FOR_EVALUATION_REQ,
  GET_FOR_EVALUATION_SUCCESS,
  GET_FOR_EVALUATION_FAILED,
  GET_EVALUATED_LIST_REQ,
  GET_EVALUATED_LIST_SUCCESS,
  GET_EVALUATED_LIST_FAILED,
  CREATE_EVALUATION_REQ,
  CREATE_EVALUATION_SUCCESS,
  CREATE_EVALUATION_FAILED,
  UPDATE_EVALUATION_REQ,
  UPDATE_EVALUATION_SUCCESS,
  UPDATE_EVALUATION_FAILED,
  GET_EVALUATION_DETAILS_REQ,
  GET_EVALUATION_DETAILS_SUCCESS,
  GET_EVALUATION_DETAILS_FAILED,
  GET_APPDETAILS_REQ,
  GET_APPDETAILS_SUCCESS,
  GET_APPDETAILS_FAILED,
  GET_COMMENTLIST_REQ,
  GET_COMMENTLIST_SUCCESS,
  GET_COMMENTLIST_FAILED,
  INSERT_COMMENT_REQ,
  INSERT_COMMENT_SUCCESS,
  INSERT_COMMENT_FAILED,
} from '../constants/evaluatorConstants'

export const retrieveForEvaluation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FOR_EVALUATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/evaluator/${payload.userID}`,
        config
      )

      dispatch({
        type: GET_FOR_EVALUATION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_FOR_EVALUATION_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveEvaluatedList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EVALUATED_LIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/evaluator/evaluated-list/${payload.userID}`,
        config
      )

      dispatch({
        type: GET_EVALUATED_LIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_EVALUATED_LIST_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveCommentList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COMMENTLIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/evaluator/comment-list/${payload.id}`,
        config
      )

      dispatch({
        type: GET_COMMENTLIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_COMMENTLIST_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveEvaluationDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EVALUATION_DETAILS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/evaluator/evaluation-details/${payload.userID}`,
        payload,
        config
      )

      dispatch({
        type: GET_EVALUATION_DETAILS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_EVALUATION_DETAILS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveAppDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APPDETAILS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/evaluator/eval-app-details/${payload.userID}`,
        payload,
        config
      )

      dispatch({
        type: GET_APPDETAILS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_APPDETAILS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const addEvaluation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_EVALUATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/evaluator`,
        payload,
        config
      )

      dispatch({
        type: CREATE_EVALUATION_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: CREATE_EVALUATION_FAILED,
        payload: 'TRANSACTION FAILED',
      })
    }
  }
}

export const insertComment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_COMMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/evaluator/comment/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_COMMENT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_COMMENT_FAILED,
        payload: 'TRANSACTION FAILED',
      })
    }
  }
}

export const editEvaluation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_EVALUATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/evaluator`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_EVALUATION_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_EVALUATION_FAILED,
        payload: 'TRANSACTION FAILED',
      })
    }
  }
}
