import {
  CHANGE_STATUS_REQ,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAILED,
  INSERT_SCOPEOFWORK_REQ,
  INSERT_SCOPEOFWORK_SUCCESS,
  INSERT_SCOPEOFWORK_FAILED,
} from '../constants/createApplicationInfoConstants'

export const createApplicationsInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_STATUS_REQ:
      return { changedStatus: { loading: true } }
    case CHANGE_STATUS_SUCCESS:
      return { changedStatus: { loading: false, message: action.payload } }
    case CHANGE_STATUS_FAILED:
      return { changedStatus: { loading: false, error: action.payload } }
    case INSERT_SCOPEOFWORK_REQ:
      return { scopeOfWork: { loading: true } }
    case INSERT_SCOPEOFWORK_SUCCESS:
      return { scopeOfWork: { loading: false, message: action.payload } }
    case INSERT_SCOPEOFWORK_FAILED:
      return { scopeOfWork: { loading: false, error: action.payload } }
    default:
      return state
  }
}
