import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Card,
  Button,
  Spinner,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { FaMobileAlt, FaEnvelope } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  retrieveEmployeeDetails,
  updateEmployeeDetails,
  upateEmployeeCredentials,
} from '../../redux/actions/DocumentControllers/documentControllerActions'

const EmployeeProfileScreen = ({ background, border, borderRadius }) => {
  // Declaration
  const dispatch = useDispatch()

  const [state, setState] = useState({
    EmployeeID: 0,
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Gender: '',
    MobileNo: '',
    EmailAddress: '',
    roles: '',
    subroles: '',
    username: '',
    password: '',
    cPassword: '',
  })

  const [modal, setModal] = useState({
    empDetails: false,
    empCredentials: false,
  })

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const dc = useSelector((state) => state.documentControllerReducer)

  const { employeeDetails, updatedEmployeeDetails, updateEmployeeCredentials } =
    dc
  // End Declaration

  // Functions
  const handleOnChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleDetailsOnSubmit = (e) => {
    e.preventDefault()
    dispatch(
      updateEmployeeDetails({
        id: state.EmployeeID,
        FirstName: state.FirstName,
        MiddleName: state.MiddleName,
        LastName: state.LastName,
        Gender: state.Gender,
        MobileNo: state.MobileNo,
        EmailAddress: state.EmailAddress,
      })
    )
    setModal({ empDetails: true })
  }

  const handleCredentialsOnSubmit = (e) => {
    if (state.password !== state.cPassword) {
      alert('password and confirm password mismatch')
    }
    dispatch(
      upateEmployeeCredentials({
        id: state.EmployeeID,
        username: state.username,
        password: state.password,
      })
    )
    setModal({ empCredentials: true })
  }
  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(retrieveEmployeeDetails({ id: userCredentials.id }))
  }, [dispatch])

  useEffect(() => {
    if (employeeDetails && employeeDetails.message) {
      setState({
        ...state,
        EmployeeID: employeeDetails.message[0].EmployeeID,
        FirstName: employeeDetails.message[0].FirstName,
        MiddleName: employeeDetails.message[0].MiddleName,
        LastName: employeeDetails.message[0].LastName,
        Gender: employeeDetails.message[0].Gender,
        MobileNo: employeeDetails.message[0].MobileNo,
        EmailAddress: employeeDetails.message[0].EmailAddress,
        roles: employeeDetails.message[0].roles,
        subroles: employeeDetails.message[0].subroles,
        username: employeeDetails.message[0].username,
      })
    }
  }, [employeeDetails])
  // End UseEffect

  return (
    <UserMain>
      <Container fluid>
        {employeeDetails && employeeDetails.message && (
          <Row className='d-flex justify-content-around'>
            <Col
              xl='3'
              style={{ background }}
              className='py-4 shadow-lg text-center align-self-center'
            >
              <Image
                src='assets/img/userAvatar3.png'
                roundedCircle
                width='150'
                height='150'
              />
              <h6>
                {state.FirstName +
                  ' ' +
                  (state.MiddleName !== null ? state.MiddleName : '') +
                  ' ' +
                  state.LastName}
              </h6>
              <div className='d-flex justify-content-center'>
                <FaMobileAlt className='mt-1 mx-2' />
                <p>{state.MobileNo}</p>
              </div>
              <div className='d-flex justify-content-center'>
                <FaEnvelope className='mt-1 mx-2' />
                <p>{state.EmailAddress}</p>
              </div>
              <hr />
            </Col>
            <Col xl='8' style={{ background }} className='py-4 shadow-lg'>
              <h3>Edit Profile</h3>
              <hr />
              <Form>
                <Card border='success'>
                  <Card.Body>
                    <h6>Employee Details</h6>
                    <hr />

                    <Row className='my-2'>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type='text'
                          style={{ background, border, borderRadius }}
                          name='LastName'
                          value={state.LastName}
                          onChange={handleOnChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type='text'
                          style={{ background, border, borderRadius }}
                          name='FirstName'
                          value={state.FirstName}
                          onChange={handleOnChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control
                          type='text'
                          style={{ background, border, borderRadius }}
                          name='MiddleName'
                          value={
                            state.MiddleName !== null ? state.MiddleName : ''
                          }
                          onChange={handleOnChange}
                        />
                      </Form.Group>
                    </Row>
                    <Row className='my-2'>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                          as='select'
                          style={{ background, border, borderRadius }}
                          name='Gender'
                          value={state.Gender}
                          onChange={handleOnChange}
                          required
                        >
                          <option value=''>choose gender here ...</option>
                          <option value='Male'>Male</option>
                          <option value='Female'>Female</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>Mobile No.</Form.Label>
                        <Form.Control
                          type='text'
                          style={{ background, border, borderRadius }}
                          name='MobileNo'
                          value={state.MobileNo}
                          onChange={handleOnChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>Email Address.</Form.Label>
                        <Form.Control
                          type='text'
                          style={{ background, border, borderRadius }}
                          name='EmailAddress'
                          value={state.EmailAddress}
                          onChange={handleOnChange}
                          required
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col className='mt-3 d-flex justify-content-end'>
                        <Button
                          type='button'
                          onClick={handleDetailsOnSubmit}
                          variant='info'
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>

                    <h6>Employee Credentials</h6>
                    <hr />
                    <Row className='my-2'>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                          type='text'
                          style={{ background, border, borderRadius }}
                          name='username'
                          value={state.username}
                          onChange={handleOnChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>UserLevel/Role</Form.Label>
                        <Form.Control
                          type='text'
                          style={{
                            background: '#f7f7f8',
                            border,
                            borderRadius,
                          }}
                          name='roles'
                          disabled
                          value={state.roles}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>Sub-role</Form.Label>
                        <Form.Control
                          type='text'
                          style={{
                            background: '#f7f7f8',
                            border,
                            borderRadius,
                          }}
                          name='subroles'
                          value={state.subroles !== null ? state.subroles : ''}
                          disabled
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type='password'
                          style={{ background, border, borderRadius }}
                          name='password'
                          value={state.password}
                          onChange={handleOnChange}
                          maxLength={12}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xl='4'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type='password'
                          style={{ background, border, borderRadius }}
                          name='cPassword'
                          value={state.cPassword}
                          onChange={handleOnChange}
                          maxLength={12}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col className='mt-3 d-flex justify-content-end'>
                        <Button
                          type='button'
                          onClick={handleCredentialsOnSubmit}
                          variant='info'
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        )}

        {employeeDetails && employeeDetails.loading && (
          <Row className='my-4 mx-1'>
            <Col xl={12}>
              <Card>
                <Card.Body className='mt-2'>
                  <div className='text-center pt-3'>
                    <Spinner animation='border' />
                    <h3>Loading...</h3>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {employeeDetails && employeeDetails.error && (
          <Row className='my-4 mx-1'>
            <Col xl={12}>
              <Card>
                <Card.Body className='mt-2'>
                  <div className='text-center pt-3'>
                    <h3>No Profile Available</h3>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>

      {/*Employee Details Success Modal */}
      <SuccessModal
        show={modal.empDetails}
        onHide={() => {
          setModal({ empDetails: false })
          window.location.reload(true)
        }}
        transLoading={updatedEmployeeDetails && updatedEmployeeDetails.loading}
        success={updatedEmployeeDetails && updatedEmployeeDetails.message}
        error={updatedEmployeeDetails && updatedEmployeeDetails.error}
      />
      {/* End Employee Details Success Modal */}

      {/*Employee Credentials Success Modal */}
      <SuccessModal
        show={modal.empCredentials}
        onHide={() => {
          setModal({ empCredentials: false })
          window.location.reload(true)
        }}
        transLoading={
          updateEmployeeCredentials && updateEmployeeCredentials.loading
        }
        success={updateEmployeeCredentials && updateEmployeeCredentials.message}
        error={updateEmployeeCredentials && updateEmployeeCredentials.error}
      />
      {/* End Employee Credentials Success Modal */}
    </UserMain>
  )
}

EmployeeProfileScreen.defaultProps = {
  background: '#FFF',
  border: '1px solid #ced4da',
  borderRadius: '5px',
}

export default withRouter(EmployeeProfileScreen)
