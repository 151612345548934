import React, { useState, useEffect } from 'react'
import {
  Form,
  Modal,
  Table,
  Pagination,
  Button,
  Row,
  Col,
  Spinner,
} from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

// Actions
import { updateSubmittedRequirements } from '../redux/actions/DocumentControllers/documentControllerActions'

// Layout
import SuccessModal from './SuccessModal'

const RequireDocuments = (props) => {
  const dispatch = useDispatch()

  const [list, setList] = useState([])

  const [filenames, setFilenames] = useState([])

  const [modal, setModal] = useState({
    insertSuccess: false,
  })

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const dc = useSelector((state) => state.documentControllerReducer)

  const { requirementList, submittedRequirements, updateRequirements } = dc

  // Req Docs Pagination
  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(10)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)

  useEffect(() => {
    setpaginatedForms(_(_.reverse(list)).slice(0).take(pageNumberLimit).value())
    setcurrentPage(1)
  }, [list, pageNumberLimit])

  const pageCount = list ? Math.ceil(list.length / pageNumberLimit) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageNumberLimit
    const paginationForms = _(list)
      .slice(startIndex)
      .take(pageNumberLimit)
      .value()
    setpaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Req Docs Pagination

  // Functions
  const handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        let responses = list
        let index = responses.findIndex((el) => el[0] === e.target.value)
        responses[index][1] = 1
        setFilenames([...filenames, [e.target.value, 1]])
        setList(responses)
      } else {
        let responses = list
        let index = responses.findIndex((el) => el[0] === e.target.value)
        responses[index][1] = 0
        setFilenames(filenames.filter((y) => y[0] !== e.target.value))
        setList(responses)
      }
    }
  }

  const handleOnSubmit = (e) => {
    if (submittedRequirements && submittedRequirements.message) {
      if (
        window.confirm('Are you sure you want to update submitted documents?')
      ) {
        e.preventDefault()
        let filtered = list.filter((e) => e[1] > 0)
        dispatch(
          updateSubmittedRequirements({
            dcID: userCredentials.id,
            id: props.payload.id,
            filenames: filtered,
            transaction: true,
          })
        )
        setModal({
          insertSuccess: true,
        })
        props.onClose()
      }
    } else {
      e.preventDefault()
      let filtered = list.filter((e) => e[1] > 0)
      dispatch(
        updateSubmittedRequirements({
          dcID: userCredentials.id,
          id: props.payload.id,
          filenames: filtered,
          transaction: false,
        })
      )
      setModal({
        insertSuccess: true,
      })
      props.onClose()
    }
  }
  // End Functions

  //   useEffect
  useEffect(() => {
    if (submittedRequirements && submittedRequirements.message) {
      let tempRequirements = []
      submittedRequirements.message.map((submitted) => {
        tempRequirements.push([submitted.FileName, 1])
      })
      setFilenames(tempRequirements)
      if (requirementList && requirementList.message) {
        let cpyRequirementList = requirementList.message.map((list) => {
          let arr = []
          let existing = tempRequirements.find((e) => e[0] === list.description)

          if (existing) {
            arr.push(list.description, 1)
          } else {
            arr.push(list.description, 0)
          }

          return arr
        })
        setList(cpyRequirementList)
        // console.log(cpyRequirementList)
      }
    } else {
      if (requirementList && requirementList.message) {
        setList(requirementList.message.map((list) => [list.description, 0]))
      }
    }
  }, [requirementList, submittedRequirements])
  // End useEffect

  // console.log(props.payload.ApplicationName)
  return (
    <>
      <Modal
        size='md'
        show={props.show}
        onHide={props.onHide}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            Required Documents
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleOnSubmit}>
          <Modal.Body>
            {submittedRequirements && submittedRequirements.message && (
              <>
                <h6>Submitted Requirements</h6>
                {filenames.map((file, index) => (
                  <p key={index} className='fs-6 my-1'>
                    {index + 1}. <i>{file[0]}</i>
                  </p>
                ))}

                <hr className='m-0 mb-2 text-danger' />
              </>
            )}

            {submittedRequirements && submittedRequirements.loading && (
              <>
                <div className='text-center py-3'>
                  <Spinner animation='border' />
                  <h6>Loading submitted required documents ...</h6>
                </div>
                <hr className='m-0 mb-2 text-danger' />
              </>
            )}

            {submittedRequirements && submittedRequirements.error && (
              <>
                <div className='text-center py-3'>
                  <h6>No Required Documents Submitted</h6>
                </div>
                <hr className='m-0 mb-2 text-danger' />
              </>
            )}

            {requirementList && requirementList.message && (
              <>
                <h6 className='mt-3'>
                  Kindly check the box if the documents are included in the
                  application requirements submitted by the applicant.
                </h6>
                <Table size='sm' responsive bordered>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedForms.map((x) => (
                      <tr key={x[0]}>
                        <td>{x[0]}</td>
                        <td className='d-flex justify-content-center'>
                          <Form.Group className='mb-3'>
                            <Form.Check
                              type='checkbox'
                              name='filenames'
                              value={x[0]}
                              onChange={handleChange}
                              checked={x[1] === 1 ? true : false}
                            />
                          </Form.Group>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row>
                  <Col xl={12}>
                    <Pagination
                      size='sm'
                      className='d-flex justify-content-end mx-2 mb-0'
                    >
                      <Pagination.Item
                        className='mt-0 fs-6 text-nowrap'
                        size='sm'
                        variant='info'
                        onClick={handlePrevbtn}
                        disabled={currentPage === pages[0] ? true : false}
                      >
                        &lt;
                      </Pagination.Item>

                      {renderPageNumber}

                      <Pagination.Item
                        className='mt-0 fs-6 text-nowrap'
                        size='sm'
                        variant='info'
                        onClick={handleNextbtn}
                        disabled={
                          currentPage === pages[pages.length - 1] ? true : false
                        }
                      >
                        &gt;
                      </Pagination.Item>
                    </Pagination>
                  </Col>
                </Row>
              </>
            )}

            {requirementList && requirementList.loading && (
              <div className='text-center pt-3'>
                <Spinner animation='border' />
                <h6>Loading required documents ...</h6>
              </div>
            )}

            {requirementList && requirementList.error && (
              <div className='text-center pt-3'>
                <p>
                  No Required Documents loaded, please try to refresh the page
                </p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' type='submit' size='sm'>
              Submit
            </Button>
            <Button
              variant='danger'
              size='sm'
              onClick={() => {
                props.onHide()
                setFilenames([])
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/*Success Modal */}
      <SuccessModal
        show={modal.insertSuccess}
        onHide={() => {
          props.onHide()
          setModal({ insertSuccess: false })
        }}
        transLoading={updateRequirements && updateRequirements.loading}
        success={updateRequirements && updateRequirements.message}
        error={updateRequirements && updateRequirements.error}
      />
      {/* End Success Modal */}
    </>
  )
}

export default RequireDocuments
