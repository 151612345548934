export const provinces = [
  {
    id: 1,
    province: 'NCR, Fourth District',
    cities: [
      {
        id: 1,
        city: 'City of Las Piñas',
        barangays: [
          {
            id: 1,
            barangay: 'Alamanza Uno',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Almanza Dos',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'B. F. International Village',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Daniel Fajardo',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Elias Aldana',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Ilaya',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Manuyo Uno',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Manuyo Dos',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Pamplona Uno',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Pamplona Dos',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Pamplona Tres',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Pilar',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Pulang Lupa Uno',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Pulang Lupa Dos',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Talon Uno',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Talon Dos',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Talon Tres',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Talon Kuatro',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Talon Singko',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Zapote',
            zipcode: '',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    province: 'REGION I - ILOCOS REGION',
    cities: [
      {
        id: 1,
        city: 'BATAC CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Ablan Poblacion',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Acosta Poblacion',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Baay',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Baligat',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Baoa East',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Baoa West',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Barani',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Ben-agan',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Bil-loca',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Biningan',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Bungon',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Callaguip',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Camandingan',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Camguidan',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Cangrunaan',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Capacuan',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Caunayan',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Colo',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Dariwdiw',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Lacub',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Mabaleng',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Magnuang',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Maipalig',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Nagbacalan',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Naguirangan',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Palongpong',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Palpalicong',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Parangopong',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Payao',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Pimentel',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Quiling Norte',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Quiling Sur',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Quiom',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Rayuray',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Ricarte Poblacion',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'San Julian',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'San Mateo',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'San Pedro',
            zipcode: '',
          },
          {
            id: 39,
            barangay: 'Suabit',
            zipcode: '',
          },
          {
            id: 40,
            barangay: 'Sumader',
            zipcode: '',
          },
          {
            id: 41,
            barangay: 'Tabug',
            zipcode: '',
          },
          {
            id: 42,
            barangay: 'Valdez Poblacion',
            zipcode: '',
          },
        ],
      },
      {
        id: 2,
        city: 'LAOAG CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Barangay No. 1, San Lorenzo',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Barangay No. 10, San Jose',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Barangay No. 11, Santa Balbina',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Barangay No. 12, San Isidro',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Barangay No. 13, Nuestra Señora de Visitacion',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Barangay No. 14, Santo Tomas',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Barangay No. 15, San Guillermo',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Barangay No. 16, San Jacinto',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Barangay No. 17, San Francisco',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Barangay No. 18, San Quirino',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Barangay No. 19, Santa Marcela',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Barangay No. 2, Santa Joaquina',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Barangay No. 20, San Miguel',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Barangay No. 21, San Pedro',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Barangay No. 22, San Andres',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Barangay No. 23, San Matias',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Barangay No. 24, Nuestra Señora de Consolacion',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Barangay No. 25, Santa Cayetana',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Barangay No. 26, San Marcelino',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Barangay No. 27, Nuestra Señora de Soledad',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Barangay No. 28, San Bernardo',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Barangay No. 29, Santo Tomas',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Barangay No. 3, Nuestra Señora del Rosario',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Barangay No. 30-A, Suyo',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Barangay No. 30-B, Santa Maria',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Barangay No. 31, Talingaan',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Barangay No. 32-A, La Paz East',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Barangay No. 32-B, La Paz West',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Barangay No. 32-C La Paz East',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Barangay No. 33-A, La Paz Proper',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Barangay No. 33-B, La Paz Proper',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Barangay No. 34-A, Gabu Norte West',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Barangay No. 34-B, Gabu Norte East',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Barangay No. 35, Gabu Sur',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Barangay No. 36, Araniw',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Barangay No. 37, Calayab',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Barangay No. 38-A, Mangato East',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'Barangay No. 38-B, Mangato West',
            zipcode: '',
          },
          {
            id: 39,
            barangay: 'Barangay No. 39, Santa Rosa',
            zipcode: '',
          },
          {
            id: 40,
            barangay: 'Barangay No. 4, San Guillermo',
            zipcode: '',
          },
          {
            id: 41,
            barangay: 'Barangay No. 40, Balatong',
            zipcode: '',
          },
          {
            id: 42,
            barangay: 'Barangay No. 41, Balacad',
            zipcode: '',
          },
          {
            id: 43,
            barangay: 'Barangay No. 42, Apaya',
            zipcode: '',
          },
          {
            id: 44,
            barangay: 'Barangay No. 43, Cavit',
            zipcode: '',
          },
          {
            id: 45,
            barangay: 'Barangay No. 44, Zamboanga',
            zipcode: '',
          },
          {
            id: 46,
            barangay: 'Barangay No. 46, Nalbo',
            zipcode: '',
          },
          {
            id: 47,
            barangay: 'Barangay No. 47, Bengcag',
            zipcode: '',
          },
          {
            id: 48,
            barangay: 'Barangay No. 48-A, Cabungaan North',
            zipcode: '',
          },
          {
            id: 49,
            barangay: 'Barangay No. 48-B, Cabungaan South',
            zipcode: '',
          },
          {
            id: 50,
            barangay: 'Barangay No. 49-A, Darayday',
            zipcode: '',
          },
          {
            id: 51,
            barangay: 'Barangay No. 49-B, Raraburan',
            zipcode: '',
          },
          {
            id: 52,
            barangay: 'Barangay No. 5, San Pedro',
            zipcode: '',
          },
          {
            id: 53,
            barangay: 'Barangay No. 50, Buttong',
            zipcode: '',
          },
          {
            id: 54,
            barangay: 'Barangay No. 51-A, Nangalisan East',
            zipcode: '',
          },
          {
            id: 55,
            barangay: 'Barangay No. 51-B, Nangalisan West',
            zipcode: '',
          },
          {
            id: 56,
            barangay: 'Barangay No. 52-A, San Mateo',
            zipcode: '',
          },
          {
            id: 57,
            barangay: 'Barangay No. 52-B, Lataag',
            zipcode: '',
          },
          {
            id: 58,
            barangay: 'Barangay No. 53, Rioeng',
            zipcode: '',
          },
          {
            id: 59,
            barangay: 'Barangay No. 54-A, Lagui-Sail',
            zipcode: '',
          },
          {
            id: 59,
            barangay: 'Barangay No. 54-A, Lagui-Sail',
            zipcode: '',
          },
          {
            id: 60,
            barangay: 'Barangay No. 54-B, Camangaan',
            zipcode: '',
          },
          {
            id: 61,
            barangay: 'Barangay No. 55-A, Barit-Pandan',
            zipcode: '',
          },
          {
            id: 62,
            barangay: 'Barangay No. 55-B, Salet-Bulangon',
            zipcode: '',
          },
          {
            id: 63,
            barangay: 'Barangay No. 55-C, Vira',
            zipcode: '',
          },
          {
            id: 64,
            barangay: 'Barangay No. 56-A, Bacsil North',
            zipcode: '',
          },
          {
            id: 65,
            barangay: 'Barangay No. 56-B, Bacsil South',
            zipcode: '',
          },
          {
            id: 66,
            barangay: 'Barangay No. 57, Pila',
            zipcode: '',
          },
          {
            id: 67,
            barangay: 'Barangay No. 58, Casili',
            zipcode: '',
          },
          {
            id: 68,
            barangay: 'Barangay No. 59-A, Dibua South',
            zipcode: '',
          },
          {
            id: 69,
            barangay: 'Barangay No. 59-B, Dibua North',
            zipcode: '',
          },
          {
            id: 70,
            barangay: 'Barangay No. 6, San Agustin',
            zipcode: '',
          },
          {
            id: 71,
            barangay: 'Barangay No. 60-A, Caaoacan',
            zipcode: '',
          },
          {
            id: 72,
            barangay: 'Barangay No. 60-B, Madiladig',
            zipcode: '',
          },
          {
            id: 73,
            barangay: 'Barangay No. 61, Cataban',
            zipcode: '',
          },
          {
            id: 74,
            barangay: 'Barangay No. 62-A, Navotas North',
            zipcode: '',
          },
          {
            id: 75,
            barangay: 'Barangay No. 62-B, Navotas South',
            zipcode: '',
          },
          {
            id: 76,
            barangay: 'Barangay No. 7-A, Nuestra Señora de Natividad',
            zipcode: '',
          },
          {
            id: 77,
            barangay: 'Barangay No. 7-B, Nuestra Señora de Natividad',
            zipcode: '',
          },
          {
            id: 78,
            barangay: 'Barangay No. 8, San Vicente',
            zipcode: '',
          },
          {
            id: 79,
            barangay: 'Barangay No. 9, Santa Angela',
            zipcode: '',
          },
        ],
      },
      {
        id: 3,
        city: 'CANDON CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Allangigan Primero',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Allangigan Segundo',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Amguid',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Ayudante',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Bagani Camposanto',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Bagani Gabor',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Bagani Tocgo',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Bagani Ubbog',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Bagar',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Balingaoan',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Bugnay',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Calaoaan',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Calongbuyan',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Caterman',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Cubcubboot',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Darapidap',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Langlangca Primero',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Langlangca Segundo',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Oaig-Daya',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Palacapac',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Paras',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Parioc Primero',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Parioc Segundo',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Patpata Primero',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Patpata Segundo',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Paypayad',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Salvador Primero',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Salvador Segundo',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'San Agustin',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'San Andres',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'San Antonio',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'San Isidro',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'San Jose',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'San Juan',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'San Nicolas',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'San Pedro',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Santo Tomas',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'Tablac',
            zipcode: '',
          },
          {
            id: 39,
            barangay: 'Talogtog',
            zipcode: '',
          },
          {
            id: 40,
            barangay: 'Tamurong Primero',
            zipcode: '',
          },
          {
            id: 41,
            barangay: 'Tamurong Segundo',
            zipcode: '',
          },
          {
            id: 42,
            barangay: 'Villarica',
            zipcode: '',
          },
        ],
      },
      {
        id: 4,
        city: 'VIGAN CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Ayusan Norte',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Ayusan Sur',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Barangay I',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Barangay II',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Barangay III ',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Barangay IV ',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Barangay V',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Barangay VI',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Barraca',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Beddeng Laud',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Beddeng Daya',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Bongtolan',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Bulala',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Cabalangegan',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Cabaroan Daya',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Cabaroan Laud',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Camangaan',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Capangpangan',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Mindoro',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Nagsangalan',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Pantay Daya',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Pantay Fatima',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Pantay Laud',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Paoa',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Paratong',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Pong-ol',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Purok-a-bassit',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Purok-a-dakkel',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Raois',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Rugsuanan',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Salindeg',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'San Jose',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'San Julian Norte',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'San Julian Sur',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'San Pedro',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Tamag',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Barangay VII',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'Barangay VIII',
            zipcode: '',
          },
          {
            id: 39,
            barangay: 'Barangay IX (Cuta)',
            zipcode: '',
          },
        ],
      },
      {
        id: 5,
        city: 'SAN FERNANDO CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Abut',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Apaleng',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Bacsil',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Bangbangolan',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Bangcusay',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Barangay I',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Barangay II',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Barangay III',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Barangay IV',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Baraoas',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Bato',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Biday',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Birunget',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Bungro',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Cabaroan',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Cabarsican',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Cadaclan',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Calabugao',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Camansi',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Canaoay',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Carlatan',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Catbangen',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Dallangayan Este',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Dallangayan Oeste',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Dalumpinas Este',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Dalumpinas Oeste',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Ilocanos Norte',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Ilocanos Sur',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Langcuas',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Lingsat',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Madayegdeg',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Mameltac',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Masicong',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Nagyubuyuban',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Namtutan',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Narra Este',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Narra Oeste',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'Pacpaco',
            zipcode: '',
          },
          {
            id: 39,
            barangay: 'Pagdalagan',
            zipcode: '',
          },
          {
            id: 40,
            barangay: 'Pagdaraoan',
            zipcode: '',
          },
          {
            id: 41,
            barangay: 'Pagudpud',
            zipcode: '',
          },
          {
            id: 42,
            barangay: 'Pao Norte',
            zipcode: '',
          },
          {
            id: 43,
            barangay: 'Pao Sur',
            zipcode: '',
          },
          {
            id: 44,
            barangay: 'Parian',
            zipcode: '',
          },
          {
            id: 45,
            barangay: 'Pias',
            zipcode: '',
          },
          {
            id: 46,
            barangay: 'Poro',
            zipcode: '',
          },
          {
            id: 47,
            barangay: 'Puspus',
            zipcode: '',
          },
          {
            id: 48,
            barangay: 'Sacyud',
            zipcode: '',
          },
          {
            id: 49,
            barangay: 'Sagayad',
            zipcode: '',
          },
          {
            id: 50,
            barangay: 'San Agustin',
            zipcode: '',
          },
          {
            id: 51,
            barangay: 'San Francisco',
            zipcode: '',
          },
          {
            id: 52,
            barangay: 'San Vicente',
            zipcode: '',
          },
          {
            id: 53,
            barangay: 'Santiago Norte',
            zipcode: '',
          },
          {
            id: 54,
            barangay: 'Santiago Sur',
            zipcode: '',
          },
          {
            id: 55,
            barangay: 'Saoay',
            zipcode: '',
          },
          {
            id: 56,
            barangay: 'Sevilla',
            zipcode: '',
          },
          {
            id: 57,
            barangay: 'Siboan-Otong',
            zipcode: '',
          },
          {
            id: 58,
            barangay: 'Tanqui',
            zipcode: '',
          },
          {
            id: 59,
            barangay: 'Tanquigan',
            zipcode: '',
          },
        ],
      },
      {
        id: 6,
        city: 'ALAMINOS CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Alos',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Amandiego',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Amangbangan',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Balangobong',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Balayang',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Bisocol',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Bolaney',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Baleyadaan',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Bued',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Cabatuan',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Cayucay',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Dulacac',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Inerangan',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Landoc',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Linmansangan',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Lucap',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Maawi',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Macatiw',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Magsaysay',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Mona',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Palamis',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Pandan',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Pangapisan',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Poblacion',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Pocal-Pocal',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Pogo',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Polo',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Quibuar',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Sabangan',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'San Antonio',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'San Jose',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'San Roque',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'San Vicente',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Santa Maria',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Tanaytay',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Tangcarang',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Tawintawin',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'Telbang',
            zipcode: '',
          },
          {
            id: 39,
            barangay: 'Victoria',
            zipcode: '',
          },
        ],
      },
      {
        id: 7,
        city: 'DAGUPAN CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Bacayao Norte',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Bacayao Sur',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Barangay I',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Barangay II',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Barangay III',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Barangay IV',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Bolosan',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Bonuan Binloc',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Bonuan Boquig',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Bonuan Gueset',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Calmay',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Carael',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Caranglaan',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Herrero-Perez',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Lasip Chico',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Lasip Grande',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Lomboy',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Lucao',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Malued',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Mamalingling',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Manguin',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Mayombo',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Pantal',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Poblacion Oeste',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Pogo Chico',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Pogo Grande',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Pugaro',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Salapingao',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Salisay',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Tambac',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Tapuac',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Tebeng',
            zipcode: '',
          },
        ],
      },
      {
        id: 8,
        city: 'SAN CARLOS CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Abanon',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Agdao',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Anando',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Ano',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Antipangol',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Aponit',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Bacnar',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Balaya',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Balayong',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Baldog',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Balite Sur',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Balococ',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Bani',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Bega',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Bocboc',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Bugallon-Posadas Street',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Bogaoan',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Bolingit',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Bolosan',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Bonifacio',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Buenglat',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Burgos Padlan',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Cacaritan',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Caingal',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Calobaoan',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Calomboyan',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Capataan',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Caoayan-Kiling',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Cobol',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Coliling',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Cruz',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Doyong',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Gamata',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Guelew',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Ilang',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Inerangan',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Isla',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'Libas',
            zipcode: '',
          },
          {
            id: 40,
            barangay: 'Lilimasan',
            zipcode: '',
          },
          {
            id: 41,
            barangay: 'Longos',
            zipcode: '',
          },
          {
            id: 42,
            barangay: 'Lucban',
            zipcode: '',
          },
          {
            id: 43,
            barangay: 'Mabalbalino',
            zipcode: '',
          },
          {
            id: 44,
            barangay: 'Mabini',
            zipcode: '',
          },
          {
            id: 45,
            barangay: 'Magtaking',
            zipcode: '',
          },
          {
            id: 46,
            barangay: 'Malacañang',
            zipcode: '',
          },
          {
            id: 47,
            barangay: 'Maliwara',
            zipcode: '',
          },
          {
            id: 48,
            barangay: 'Mamarlao',
            zipcode: '',
          },
          {
            id: 49,
            barangay: 'Manzon',
            zipcode: '',
          },
          {
            id: 50,
            barangay: 'Matagdem',
            zipcode: '',
          },
          {
            id: 51,
            barangay: 'Mestizo Norte',
            zipcode: '',
          },
          {
            id: 52,
            barangay: 'Naguilayan',
            zipcode: '',
          },
          {
            id: 53,
            barangay: 'Nelintap',
            zipcode: '',
          },
          {
            id: 54,
            barangay: 'Padilla-Gomez',
            zipcode: '',
          },
          {
            id: 55,
            barangay: 'Pagal',
            zipcode: '',
          },
          {
            id: 56,
            barangay: 'Palaming',
            zipcode: '',
          },
          {
            id: 57,
            barangay: 'Palaris',
            zipcode: '',
          },
          {
            id: 58,
            barangay: 'Palospos',
            zipcode: '',
          },
          {
            id: 59,
            barangay: 'Pangalangan',
            zipcode: '',
          },
          {
            id: 60,
            barangay: 'Pangoloan',
            zipcode: '',
          },
          {
            id: 61,
            barangay: 'Pangpang',
            zipcode: '',
          },
          {
            id: 62,
            barangay: 'Paitan-Panoypoy',
            zipcode: '',
          },
          {
            id: 63,
            barangay: 'Parayao',
            zipcode: '',
          },
          {
            id: 64,
            barangay: 'Payapa',
            zipcode: '',
          },
          {
            id: 65,
            barangay: 'Payar',
            zipcode: '',
          },
          {
            id: 66,
            barangay: 'Perez Boulevard',
            zipcode: '',
          },
          {
            id: 67,
            barangay: 'Polo',
            zipcode: '',
          },
          {
            id: 68,
            barangay: 'Quezon Boulevard',
            zipcode: '',
          },
          {
            id: 69,
            barangay: 'Quintong',
            zipcode: '',
          },
          {
            id: 70,
            barangay: 'Rizal',
            zipcode: '',
          },
          {
            id: 71,
            barangay: 'Roxas Boulevard',
            zipcode: '',
          },
          {
            id: 72,
            barangay: 'Salinap',
            zipcode: '',
          },
          {
            id: 73,
            barangay: 'San Juan',
            zipcode: '',
          },
          {
            id: 74,
            barangay: 'San Pedro-Taloy',
            zipcode: '',
          },
          {
            id: 75,
            barangay: 'Sapinit',
            zipcode: '',
          },
          {
            id: 76,
            barangay: 'PNR Station Site',
            zipcode: '',
          },
          {
            id: 77,
            barangay: 'Supo',
            zipcode: '',
          },
          {
            id: 78,
            barangay: 'Talang',
            zipcode: '',
          },
          {
            id: 79,
            barangay: 'Tamayo',
            zipcode: '',
          },
          {
            id: 80,
            barangay: 'Tandoc',
            zipcode: '',
          },
          {
            id: 81,
            barangay: 'Tarece',
            zipcode: '',
          },
          {
            id: 82,
            barangay: 'Tarectec',
            zipcode: '',
          },
          {
            id: 83,
            barangay: 'Tayambani',
            zipcode: '',
          },
          {
            id: 84,
            barangay: 'Tebag',
            zipcode: '',
          },
          {
            id: 85,
            barangay: 'Turac',
            zipcode: '',
          },
          {
            id: 86,
            barangay: 'M. Soriano St.',
            zipcode: '',
          },
          {
            id: 87,
            barangay: 'Tandang Sora',
            zipcode: '',
          },
        ],
      },
      {
        id: 9,
        city: 'URDANETA CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Anonas',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Bactad East',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Bayaoas',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Bolaoen',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Cabaruan',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Cabuloan',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Camanang',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Camantiles',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Casantaan',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Catablan',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Cayambanan',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Consolacion',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Dilan Paurido',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Labit Proper',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Labit West',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Mabanogbog',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Macalong',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Nancalobasaan',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Nancamaliran East',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Nancamaliran West',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Nancayasan',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Oltama',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Palina East',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Palina West',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Pedro T. Orata/ Bactad Proper',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Pinmaludpod',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Poblacion',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'San Jose',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'San Vicente',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Santa Lucia',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Santo Domingo',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Sugcong',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Tipuso',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Tulong',
            zipcode: '',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    province: 'REGION II - CAGAYAN VALLEY',
    cities: [
      {
        id: 1,
        city: 'TUGEGARAO CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Annafunan East',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Annafunan West',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Atulayan Norte',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Atulayan Sur',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Bagay',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Buntun',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Caggay',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Capatan',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Carig Norte (Reyes)',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Carig Sur (Carig)',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Caritan Centro',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Caritan Norte',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Caritan Sur',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Cataggaman Nuevo',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Cataggaman Pardo',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Cataggaman Viejo',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Centro 1 (Bagumbayan)',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Centro 2 (Poblacion)',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Centro 3 (Poblacion)',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Centro 4 (Poblacion)',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Centro 5 (Bagumbayan)',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Centro 6 (Poblacion)',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Centro 7 (Poblacion)',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Centro 8 (Poblacion)',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Centro 9 (Bagumbayan)',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Centro 10 (Riverside)',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Centro 11 (Balzain West)',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Centro 12 (Balzain East)',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Dadda',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Gosi Norte',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Gosi Sur',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Quiling Sur',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Larion Alto',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Larion Bajo',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Leonarda',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Libag Norte',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Libag Sur',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'Linao East',
            zipcode: '',
          },
          {
            id: 39,
            barangay: 'Linao Norte',
            zipcode: '',
          },
          {
            id: 40,
            barangay: 'Linao West',
            zipcode: '',
          },
          {
            id: 41,
            barangay: 'Namabbalan Norte',
            zipcode: '',
          },
          {
            id: 42,
            barangay: 'Namabbalan Sur',
            zipcode: '',
          },
          {
            id: 43,
            barangay: 'Pallua Norte',
            zipcode: '',
          },
          {
            id: 44,
            barangay: 'Pallua Sur',
            zipcode: '',
          },
          {
            id: 45,
            barangay: 'Pengue-Ruyu',
            zipcode: '',
          },
          {
            id: 46,
            barangay: 'San Gabriel',
            zipcode: '',
          },
          {
            id: 47,
            barangay: 'Tagga',
            zipcode: '',
          },
          {
            id: 48,
            barangay: 'Tanza',
            zipcode: '',
          },
          {
            id: 49,
            barangay: 'Ugac Norte',
            zipcode: '',
          },
          {
            id: 50,
            barangay: 'Ugac Sur',
            zipcode: '',
          },
        ],
      },
      {
        id: 2,
        city: 'CAUAYAN CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Alicaocao',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Alinam',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Amobocan',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Andarayan',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Bacolod',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Baringin Norte',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Baringin Sur',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Buena Suerte',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Bugallon',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Buyon',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Cabaruan',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Carabatan Chica',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Carabatan Grande',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Carabatan Punta',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Carabatan Bacareno',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Casalatan',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Cassap Fuera',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Catalina',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Culalabat',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Dabburab',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'De Vera',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Dianao',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Dissimuray',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'District I (Poblacion)',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'District II (Poblacion)',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'District III (Poblacion)',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Duminit',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Faustino (Sipay)',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Gagabutan',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Gappal',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Guayabal',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Labinab',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Linglingay',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Mabantad',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Maligaya',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Manaoag',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Marabulig I',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'Marabulig II',
            zipcode: '',
          },
          {
            id: 39,
            barangay: 'Minante I',
            zipcode: '',
          },
          {
            id: 40,
            barangay: 'Minante II',
            zipcode: '',
          },
          {
            id: 41,
            barangay: 'Nagcampegan',
            zipcode: '',
          },
          {
            id: 42,
            barangay: 'Naganacan',
            zipcode: '',
          },
          {
            id: 43,
            barangay: 'Nagrumbuan',
            zipcode: '',
          },
          {
            id: 44,
            barangay: 'Nungnungan I',
            zipcode: '',
          },
          {
            id: 45,
            barangay: 'Nungnungan II',
            zipcode: '',
          },
          {
            id: 46,
            barangay: 'Pinoma',
            zipcode: '',
          },
          {
            id: 47,
            barangay: 'Rizaluna',
            zipcode: '',
          },
          {
            id: 48,
            barangay: 'Rizal',
            zipcode: '',
          },
          {
            id: 49,
            barangay: 'Rogus',
            zipcode: '',
          },
          {
            id: 50,
            barangay: 'San Antonio',
            zipcode: '',
          },
          {
            id: 51,
            barangay: 'San Fermin (Poblacion)',
            zipcode: '',
          },
          {
            id: 52,
            barangay: 'San Francisco',
            zipcode: '',
          },
          {
            id: 53,
            barangay: 'San Isidro',
            zipcode: '',
          },
          {
            id: 53,
            barangay: 'San Luis',
            zipcode: '',
          },
          {
            id: 54,
            barangay: 'San Pablo',
            zipcode: '',
          },
          {
            id: 55,
            barangay: 'Santa Luciana (Daburab 2)',
            zipcode: '',
          },
          {
            id: 56,
            barangay: 'Santa Maria',
            zipcode: '',
          },
          {
            id: 57,
            barangay: 'Sillawit',
            zipcode: '',
          },
          {
            id: 58,
            barangay: 'Sinippil',
            zipcode: '',
          },
          {
            id: 59,
            barangay: 'Tagaran',
            zipcode: '',
          },
          {
            id: 60,
            barangay: 'Turayong',
            zipcode: '',
          },
          {
            id: 61,
            barangay: 'Union',
            zipcode: '',
          },
          {
            id: 62,
            barangay: 'Villa Luna',
            zipcode: '',
          },
          {
            id: 63,
            barangay: 'Villa Concepcion',
            zipcode: '',
          },
          {
            id: 64,
            barangay: 'Villaflor',
            zipcode: '',
          },
        ],
      },
      {
        id: 3,
        city: 'ILAGAN CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Aggasian',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Alibagu',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Alinguigan 1st',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Alinguigan 2nd',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Alinguigan 3rd',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Arusip',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Baculud (Poblacion)',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Baligatan',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Ballacong',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Bangag',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Batong-Labang',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Bigao',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Cabannungan 1st',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Cabannungan 2nd',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Cabeseria 2 (Dappat)',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Cabeseria 3 (San Fernando)',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Cabeseria 4 (San Manuel)',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Cabeseria 5 (Baribad)',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Cabeseria 6 and 24 (Villa Marcos)',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Cabeseria 7 (Nangalisan)',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Cabeseria 9 and 11 (Capogotan)',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Cabeseria 10 (Lupigui)',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Cabeseria 14 and 16 (Casilagan)',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Cabeseria 17 and 21 (San Rafael)',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Cabeseria 19 (Villa Suerte)',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Cabeseria 22 (Sablang)',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Cabeseria 23 (San Francisco)',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Cabeseria 25 (Santa Lucia)',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Cabeseria 27 (Abuan)',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Cadu',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Calamagui 1st',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Calamagui 2nd',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Camunatan',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Capellan',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Capo',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Carikkikan Norte',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Carikkikan Sur',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'Centro - San Antonio',
            zipcode: '',
          },
          {
            id: 39,
            barangay: 'Centro Poblacion',
            zipcode: '',
          },
          {
            id: 40,
            barangay: 'Fugu',
            zipcode: '',
          },
          {
            id: 41,
            barangay: 'Fuyo',
            zipcode: '',
          },
          {
            id: 42,
            barangay: 'Gayong-Gayong Norte',
            zipcode: '',
          },
          {
            id: 43,
            barangay: 'Gayong-Gayong Sur',
            zipcode: '',
          },
          {
            id: 44,
            barangay: 'Guinatan',
            zipcode: '',
          },
          {
            id: 45,
            barangay: 'Imelda Bliss Village',
            zipcode: '',
          },
          {
            id: 46,
            barangay: 'Lullutan',
            zipcode: '',
          },
          {
            id: 47,
            barangay: 'Malalam',
            zipcode: '',
          },
          {
            id: 48,
            barangay: 'Malasin (Angeles)',
            zipcode: '',
          },
          {
            id: 49,
            barangay: 'Manaring',
            zipcode: '',
          },
          {
            id: 50,
            barangay: 'Mangcuram',
            zipcode: '',
          },
          {
            id: 51,
            barangay: 'Marana I',
            zipcode: '',
          },
          {
            id: 52,
            barangay: 'Marana II',
            zipcode: '',
          },
          {
            id: 53,
            barangay: 'Marana III',
            zipcode: '',
          },
          {
            id: 53,
            barangay: 'Minabang',
            zipcode: '',
          },
          {
            id: 54,
            barangay: 'Morado',
            zipcode: '',
          },
          {
            id: 55,
            barangay: 'Naguilian Norte',
            zipcode: '',
          },
          {
            id: 56,
            barangay: 'Naguilian Sur',
            zipcode: '',
          },
          {
            id: 57,
            barangay: 'Namnama',
            zipcode: '',
          },
          {
            id: 58,
            barangay: 'Nanaguan',
            zipcode: '',
          },
          {
            id: 59,
            barangay: 'Osmeña (Sinippil)',
            zipcode: '',
          },
          {
            id: 60,
            barangay: 'Paliueg',
            zipcode: '',
          },
          {
            id: 61,
            barangay: 'Pasa',
            zipcode: '',
          },
          {
            id: 62,
            barangay: 'Pilar',
            zipcode: '',
          },
          {
            id: 63,
            barangay: 'Quimalabasa',
            zipcode: '',
          },
          {
            id: 64,
            barangay: 'Rang-ayan (Bintacan)',
            zipcode: '',
          },
          {
            id: 65,
            barangay: 'Rang-ayan (Bintacan)',
            zipcode: '',
          },
          {
            id: 66,
            barangay: 'Rugao',
            zipcode: '',
          },
          {
            id: 67,
            barangay: 'Salindingan',
            zipcode: '',
          },
          {
            id: 68,
            barangay: 'San Andres (Angarilla)',
            zipcode: '',
          },
          {
            id: 69,
            barangay: 'San Felipe',
            zipcode: '',
          },
          {
            id: 70,
            barangay: 'San Ignacio (Canapi)',
            zipcode: '',
          },
          {
            id: 71,
            barangay: 'San Isidro',
            zipcode: '',
          },
          {
            id: 72,
            barangay: 'San Juan',
            zipcode: '',
          },
          {
            id: 73,
            barangay: 'San Lorenzo',
            zipcode: '',
          },
          {
            id: 74,
            barangay: 'San Pablo',
            zipcode: '',
          },
          {
            id: 75,
            barangay: 'San Rodrigo',
            zipcode: '',
          },
          {
            id: 76,
            barangay: 'San Vicente (Poblacion)',
            zipcode: '',
          },
          {
            id: 77,
            barangay: 'Santa Barbara (Poblacion)',
            zipcode: '',
          },
          {
            id: 78,
            barangay: 'Santa Catalina',
            zipcode: '',
          },
          {
            id: 79,
            barangay: 'Santa Isabel Norte',
            zipcode: '',
          },
          {
            id: 80,
            barangay: 'Santa Isabel Sur',
            zipcode: '',
          },
          {
            id: 81,
            barangay: 'Santa Maria (Cabeseria 8)',
            zipcode: '',
          },
          {
            id: 82,
            barangay: 'Santa Victoria',
            zipcode: '',
          },
          {
            id: 83,
            barangay: 'Santo Tomas',
            zipcode: '',
          },
          {
            id: 84,
            barangay: 'Siffu',
            zipcode: '',
          },
          {
            id: 85,
            barangay: 'Sindon Bayabo',
            zipcode: '',
          },
          {
            id: 86,
            barangay: 'Sindon Maride',
            zipcode: '',
          },
          {
            id: 87,
            barangay: 'Sipay',
            zipcode: '',
          },
          {
            id: 88,
            barangay: 'Tangcul',
            zipcode: '',
          },
          {
            id: 89,
            barangay: 'Villa Imelda (Maplas)',
            zipcode: '',
          },
        ],
      },
      {
        id: 4,
        city: 'ILAGAN CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Abra',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Ambalatungan',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Balintocatoc',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Baluarte',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Bannawag Norte',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Batal',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Buenavista',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Cabulay',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Calao East',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Calao West',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Calaocan',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Centro East',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Centro West',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Divisoria',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Dubinan East',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Dubinan West',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Luna',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Mabini',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Malvar',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Nabbuan',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Naggasican',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Patul',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Plaridel',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Rizal',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Rosario',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Sagana',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Salvador',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'San Andres',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'San Isidro',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'San Jose',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Santa Rosa',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Sinili',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Sinsayon',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Victory Norte',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Victory Sur',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Villa Gonzaga',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Villasis',
            zipcode: '',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    province: 'REGION III – CENTRAL LUZON',
    cities: [
      {
        id: 1,
        city: 'BALANGA CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Bagumbayan',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Cabog-Cabog',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Munting Batangas (Cadre)',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Cataning',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Central',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Cupang Proper',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Cupang West',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Dangcol (Bernabe)',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Ibayo',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Malabia',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Poblacion Barcenas',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Puerto Rivas Ibaba',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Puerto Rivas Itaas',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Puerto Rivas Lote',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'San Jose',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Sibacan',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Camacho',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Talisay',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Tanato',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Tenejero',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Tortugas',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Tuyo',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Bagong Silang',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Cupang North',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Doña Francisca',
            zipcode: '',
          },
        ],
      },
      {
        id: 2,
        city: 'MALOLOS CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Anilao',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Atlag',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Babatnin',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Bagna',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Bagong Bayan',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Balayong',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Balite',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Bangkal',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Barihan',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Bulihan',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Bungahan',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Caingin',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Calero',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Caliligawan',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Canalate',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Caniogan',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Catmon',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Cofradia',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Dakila',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Guinhawa',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Liang',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Ligas',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Longos',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Look 1st',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Look 2nd',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Lugam',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Mabolo',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'Mambog',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'Masile',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'Matimbo',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Mojon',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Namayan',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Niugan',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Pamarawan',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Panasahan',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Pinagbakahan',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'San Agustin',
            zipcode: '',
          },
          {
            id: 38,
            barangay: 'San Gabriel',
            zipcode: '',
          },
          {
            id: 39,
            barangay: 'San Juan',
            zipcode: '',
          },
          {
            id: 40,
            barangay: 'San Pablo',
            zipcode: '',
          },
          {
            id: 41,
            barangay: 'San Vicente',
            zipcode: '',
          },
          {
            id: 42,
            barangay: 'Santiago',
            zipcode: '',
          },
          {
            id: 43,
            barangay: 'Santisima Trinidad',
            zipcode: '',
          },
          {
            id: 44,
            barangay: 'Santol',
            zipcode: '',
          },
          {
            id: 45,
            barangay: 'Sto. Cristo del Rosario',
            zipcode: '',
          },
          {
            id: 46,
            barangay: 'Sto. Niño Bautista',
            zipcode: '',
          },
          {
            id: 47,
            barangay: 'Sto. Rosario',
            zipcode: '',
          },
          {
            id: 48,
            barangay: 'Sumapang Bata',
            zipcode: '',
          },
          {
            id: 49,
            barangay: 'Sumapang Matanda',
            zipcode: '',
          },
          {
            id: 50,
            barangay: 'Taal',
            zipcode: '',
          },
          {
            id: 51,
            barangay: 'Tikay',
            zipcode: '',
          },
        ],
      },
      {
        id: 3,
        city: 'MEYCAUAYAN CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Bahay-Pare',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Bancal',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Banga',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Bayugo',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Caingin',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Calvario',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Camalig',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Hulo',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Iba',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Langka',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Lawa',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Libtong',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Liputan',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Longos',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Malhacan',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Pajo',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Pandayan',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Pantok',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Perez',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Poblacion',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Saluysoy',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'St. Francis (Gasak)',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Tugatog',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Ubihan',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Zamora',
            zipcode: '',
          },
        ],
      },
      {
        id: 4,
        city: 'SAN JOSE DEL MONTE CITY',
        barangays: [
          {
            id: 1,
            barangay: 'Assumption',
            zipcode: '',
          },
          {
            id: 2,
            barangay: 'Bagong Buhay',
            zipcode: '',
          },
          {
            id: 3,
            barangay: 'Bagong Buhay II',
            zipcode: '',
          },
          {
            id: 4,
            barangay: 'Bagong Buhay III',
            zipcode: '',
          },
          {
            id: 5,
            barangay: 'Citrus',
            zipcode: '',
          },
          {
            id: 6,
            barangay: 'Ciudad Real',
            zipcode: '',
          },
          {
            id: 7,
            barangay: 'Dulong Bayan',
            zipcode: '',
          },
          {
            id: 8,
            barangay: 'Fatima',
            zipcode: '',
          },
          {
            id: 9,
            barangay: 'Fatima II',
            zipcode: '',
          },
          {
            id: 10,
            barangay: 'Fatima III',
            zipcode: '',
          },
          {
            id: 11,
            barangay: 'Fatima IV',
            zipcode: '',
          },
          {
            id: 12,
            barangay: 'Fatima V',
            zipcode: '',
          },
          {
            id: 13,
            barangay: 'Francisco Homes-Guijo',
            zipcode: '',
          },
          {
            id: 14,
            barangay: 'Francisco Homes-Mulawin',
            zipcode: '',
          },
          {
            id: 15,
            barangay: 'Francisco Homes-Narra',
            zipcode: '',
          },
          {
            id: 16,
            barangay: 'Francisco Homes-Yakal',
            zipcode: '',
          },
          {
            id: 17,
            barangay: 'Gaya-gaya',
            zipcode: '',
          },
          {
            id: 18,
            barangay: 'Graceville',
            zipcode: '',
          },
          {
            id: 19,
            barangay: 'Gumaoc Central',
            zipcode: '',
          },
          {
            id: 20,
            barangay: 'Gumaoc East',
            zipcode: '',
          },
          {
            id: 21,
            barangay: 'Gumaoc West',
            zipcode: '',
          },
          {
            id: 22,
            barangay: 'Kaybanban',
            zipcode: '',
          },
          {
            id: 23,
            barangay: 'Kaypian',
            zipcode: '',
          },
          {
            id: 24,
            barangay: 'Lawang Pari',
            zipcode: '',
          },
          {
            id: 25,
            barangay: 'Maharlika',
            zipcode: '',
          },
          {
            id: 26,
            barangay: 'Minuyan',
            zipcode: '',
          },
          {
            id: 27,
            barangay: 'Minuyan II',
            zipcode: '',
          },
          {
            id: 28,
            barangay: 'San Andres',
            zipcode: '',
          },
          {
            id: 29,
            barangay: 'San Isidro',
            zipcode: '',
          },
          {
            id: 30,
            barangay: 'San Jose',
            zipcode: '',
          },
          {
            id: 31,
            barangay: 'Santa Rosa',
            zipcode: '',
          },
          {
            id: 32,
            barangay: 'Sinili',
            zipcode: '',
          },
          {
            id: 33,
            barangay: 'Sinsayon',
            zipcode: '',
          },
          {
            id: 34,
            barangay: 'Victory Norte',
            zipcode: '',
          },
          {
            id: 35,
            barangay: 'Victory Sur',
            zipcode: '',
          },
          {
            id: 36,
            barangay: 'Villa Gonzaga',
            zipcode: '',
          },
          {
            id: 37,
            barangay: 'Villasis',
            zipcode: '',
          },
        ],
      },
    ],
  },
]
