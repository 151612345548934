import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_SCHED_APPLICATION_REQ,
  GET_SCHED_APPLICATION_SUCCESS,
  GET_SCHED_APPLICATION_FAILED,
  GET_WITH_REPORT_REQ,
  GET_WITH_REPORT_SUCCESS,
  GET_WITH_REPORT_FAILED,
  GET_LIST_REPORT_REQ,
  GET_LIST_REPORT_SUCCESS,
  GET_LIST_REPORT_FAILED,
  GET_REPORT_REQ,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILED,
  GET_IMAGE_REPORT_REQ,
  GET_IMAGE_REPORT_SUCCESS,
  GET_IMAGE_REPORT_FAILED,
  GET_FOR_REPORT_REQ,
  GET_FOR_REPORT_SUCCESS,
  GET_FOR_REPORT_FAILED,
  GET_COMMENTS_REQ,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAILED,
  GET_VIOLATIONLIST_REQ,
  GET_VIOLATIONLIST_SUCCESS,
  GET_VIOLATIONLIST_FAILED,
  GET_VIOLATORLIST_REQ,
  GET_VIOLATORLIST_SUCCESS,
  GET_VIOLATORLIST_FAILED,
  GET_VIOLATIONDETAILS_REQ,
  GET_VIOLATIONDETAILS_SUCCESS,
  GET_VIOLATIONDETAILS_FAILED,
  GET_VIOLATIONSTATUS_REQ,
  GET_VIOLATIONSTATUS_SUCCESS,
  GET_VIOLATIONSTATUS_FAILED,
  GET_APPINFO_REQ,
  GET_APPINFO_SUCCESS,
  GET_APPINFO_FAILED,
  GET_INSPECTORSUMMARY_REQ,
  GET_INSPECTORSUMMARY_SUCCESS,
  GET_INSPECTORSUMMARY_FAILED,
  ADD_EDIT_REPORT_REQ,
  ADD_EDIT_REPORT_SUCCESS,
  ADD_EDIT_REPORT_FAILED,
  UPDATE_IMAGES_REQ,
  UPDATE_IMAGES_SUCCESS,
  UPDATE_IMAGES_FAILED,
  UPDATE_VIOLATION_REQ,
  UPDATE_VIOLATION_SUCCESS,
  UPDATE_VIOLATION_FAILED,
  UPDATE_VIOLATION_DETAILS_REQ,
  UPDATE_VIOLATION_DETAILS_SUCCESS,
  UPDATE_VIOLATION_DETAILS_FAILED,
  ADD_REPORT_REQ,
  ADD_REPORT_SUCCESS,
  ADD_REPORT_FAILED,
  ADD_VIOLATION_REQ,
  ADD_VIOLATION_SUCCESS,
  ADD_VIOLATION_FAILED,
  ADD_VIOLATION_DETAILS_REQ,
  ADD_VIOLATION_DETAILS_SUCCESS,
  ADD_VIOLATION_DETAILS_FAILED,
  INSERT_COMMENT_REQ,
  INSERT_COMMENT_SUCCESS,
  INSERT_COMMENT_FAILED,
  INSERT_IMAGES_REQ,
  INSERT_IMAGES_SUCCESS,
  INSERT_IMAGES_FAILED,
} from '../constants/inspectionConstants'

export const retrieveSchedApplication = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SCHED_APPLICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspection/application-for-inspection/${payload.userID}`,
        config
      )

      dispatch({
        type: GET_SCHED_APPLICATION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_SCHED_APPLICATION_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveWithReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_WITH_REPORT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspection/application-with-report/${payload.userID}`,
        config
      )

      dispatch({
        type: GET_WITH_REPORT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_WITH_REPORT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveListReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_LIST_REPORT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/list-report/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: GET_LIST_REPORT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_LIST_REPORT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveForReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FOR_REPORT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: GET_FOR_REPORT_SUCCESS,
        payload: data.result[0],
      })
    } catch {
      dispatch({
        type: GET_FOR_REPORT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_REPORT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/existing-report/${payload.inspectionID}`,
        payload,
        config
      )

      dispatch({
        type: GET_REPORT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_REPORT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveImageReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_IMAGE_REPORT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/existing-images-report/${payload.inspectionID}`,
        payload,
        config
      )

      dispatch({
        type: GET_IMAGE_REPORT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_IMAGE_REPORT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveCommentList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COMMENTS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspection/comment-list/${payload.id}`,
        config
      )

      dispatch({
        type: GET_COMMENTS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_COMMENTS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveViolationList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VIOLATIONLIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspection/violation-list`,
        config
      )

      dispatch({
        type: GET_VIOLATIONLIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_VIOLATIONLIST_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveViolatorList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VIOLATORLIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspection/violator-list/${payload.id}`,
        config
      )

      dispatch({
        type: GET_VIOLATORLIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_VIOLATORLIST_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveViolationDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VIOLATIONDETAILS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspection/violation-details/${payload.id}`,
        config
      )

      dispatch({
        type: GET_VIOLATIONDETAILS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_VIOLATIONDETAILS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveViolationStatus = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VIOLATIONSTATUS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspection/violation-status/${payload.year}`,
        config
      )

      dispatch({
        type: GET_VIOLATIONSTATUS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_VIOLATIONSTATUS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveInspectorSummary = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_INSPECTORSUMMARY_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspection/inspector-summary/${payload.year}`,
        config
      )

      dispatch({
        type: GET_INSPECTORSUMMARY_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_INSPECTORSUMMARY_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveAppInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APPINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/inspection/app-info/${payload.id}`,
        config
      )

      dispatch({
        type: GET_APPINFO_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_APPINFO_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const updateReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_EDIT_REPORT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/edit-report`,
        payload,
        config
      )

      dispatch({
        type: ADD_EDIT_REPORT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: ADD_EDIT_REPORT_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const createNewReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_REPORT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      if (payload.images !== null) {
        Object.values(payload.images).forEach((element) => {
          formData.append('images', element)
        })
      } else {
        formData.append('images', [])
      }

      formData.append('inspectionID', payload.inspectionID)
      formData.append('userID', payload.userID)
      formData.append('category', payload.category)
      formData.append('reports', JSON.stringify(payload.reports))

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/add-report`,
        formData,
        config
      )

      dispatch({
        type: ADD_REPORT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: ADD_REPORT_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const attachImageReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_IMAGES_REQ })

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      Object.values(payload.images).forEach((element) => {
        formData.append('images', element)
      })
      formData.append('inspectionID', payload.inspectionID)
      formData.append('userID', payload.userID)
      formData.append('category', payload.category)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/upload-images`,
        formData,
        config
      )

      dispatch({
        type: INSERT_IMAGES_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_IMAGES_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const reAttachImageReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_IMAGES_REQ })

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      Object.values(payload.images).forEach((element) => {
        formData.append('images', element)
      })
      formData.append('inspectionID', payload.inspectionID)
      formData.append('userID', payload.userID)
      formData.append('category', payload.category)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/reupload-images`,
        formData,
        config
      )

      dispatch({
        type: UPDATE_IMAGES_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_IMAGES_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const insertComment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_COMMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/comment/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_COMMENT_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_COMMENT_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const insertViolation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_VIOLATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      Object.values(payload.image).forEach((el) => {
        formData.append('images', el)
      })
      formData.append('owner', payload.owner)
      formData.append('location', payload.location)
      formData.append('empID', payload.empID)
      formData.append('ctrNo', payload.ctrNo)
      formData.append('offenseNo', payload.offenseNo)
      formData.append('offenseDate', payload.offenseDate)
      formData.append('statusOfActivities', payload.statusOfActivities)
      formData.append('inspectorRemarks', payload.inspectorRemarks)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/add-violation`,
        formData,
        config
      )

      dispatch({
        type: ADD_VIOLATION_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveViolationList())
    } catch (err) {
      dispatch({
        type: ADD_VIOLATION_FAILED,
        payload: err.response && err.response.data.message,
      })
    }
  }
}

export const insertViolationDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_VIOLATION_DETAILS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      Object.values(payload.image).forEach((el) => {
        formData.append('images', el)
      })
      formData.append('novID', payload.novID)
      formData.append('offenseNo', payload.offenseNo)
      formData.append('ctrNo', payload.ctrNo)
      formData.append('offenseDate', payload.offenseDate)
      formData.append('statusOfActivities', payload.statusOfActivities)
      formData.append('inspectorRemarks', payload.inspectorRemarks)
      formData.append('empID', payload.empID)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/inspection/add-violation-details`,
        formData,
        config
      )

      dispatch({
        type: ADD_VIOLATION_DETAILS_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveViolatorList({ id: payload.novID }))
    } catch (err) {
      console.log(payload)
      dispatch({
        type: ADD_VIOLATION_DETAILS_FAILED,
        payload: err.response && err.response.data.message,
      })
    }
  }
}

export const updateViolation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_VIOLATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/inspection/edit-violation/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_VIOLATION_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveViolationList())
    } catch (err) {
      dispatch({
        type: UPDATE_VIOLATION_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const updateViolationDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_VIOLATION_DETAILS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      if (payload.image != null) {
        Object.values(payload.image).forEach((el) => {
          formData.append('images', el)
        })
      }

      if (payload.imageIDs != null) {
        Object.values(payload.imageIDs).forEach((el) => {
          formData.append('imageIDs', el)
        })
      }

      formData.append('ctrlNo', payload.ctrlNo)
      formData.append('offenseNo', payload.offenseNo)
      formData.append('offenseDate', payload.offenseDate)
      formData.append('statusOfActivities', payload.statusOfActivities)
      formData.append('inspectorRemarks', payload.inspectorRemarks)
      formData.append('dateReportedOffice', payload.dateReportedOffice)
      formData.append('dateReportedRemarks', payload.dateReportedRemarks)
      formData.append('complied', payload.complied)
      formData.append('id', payload.id)

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/inspection/edit-violation-details/${payload.id}`,
        formData,
        config
      )

      dispatch({
        type: UPDATE_VIOLATION_DETAILS_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveViolationList())
    } catch (err) {
      dispatch({
        type: UPDATE_VIOLATION_DETAILS_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}
