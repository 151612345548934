import React, { useState, useEffect } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Card, Pagination } from 'react-bootstrap'

const CommentsCard = ({ state }) => {
  const [paginatedForms, setPaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  const pageSize = 5

  useEffect(() => {
    setPaginatedForms(_(_.reverse(state)).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [state])

  const pageCount = state ? Math.ceil(state.length / pageSize) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    const paginationForms = _(state).slice(startIndex).take(pageSize).value()
    setPaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
          size='sm'
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }

  return (
    <>
      {paginatedForms.map((comment, index) => (
        <div key={index}>
          <Card variant='light' className='my-2'>
            <Card.Header>
              <h6>Message:</h6>
            </Card.Header>
            <Card.Body>
              <p className='px-3'>{comment.comments}</p>
            </Card.Body>
            <Card.Footer className='pb-0'>
              <p className='text-end mb-1'>
                From: {comment.EmployeeName}, <br />
                <i className='fs-6'>
                  {moment(comment.timeStamp).format('MMMM Do YYYY, h:mm:ss a')}
                </i>
              </p>
            </Card.Footer>
          </Card>
          <hr />
        </div>
      ))}

      {paginatedForms.length !== 0 && (
        <Pagination
          size='sm'
          className='d-flex my-0 justify-content-center mx-2 my-2'
        >
          <Pagination.Item
            size='sm'
            onClick={handlePrevbtn}
            disabled={currentPage === pages[0] ? true : false}
          >
            &lt;
          </Pagination.Item>

          {renderPageNumber}

          <Pagination.Item
            size='sm'
            onClick={handleNextbtn}
            disabled={currentPage === pages[pages.length - 1] ? true : false}
          >
            &gt;
          </Pagination.Item>
        </Pagination>
      )}
    </>
  )
}

export default CommentsCard
