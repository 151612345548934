import { pdf } from '@react-pdf/renderer'
import { PDFDocument } from 'pdf-lib-plus-encrypt'

export const encryptPDF = async (props, file, handleSetEncrypt) => {
  // Generate PDF using react-pdf
  const pdfBlob = await pdf(
    // <PrintCFEI info={props.permitCFEI !== undefined && props.permitCFEI} />
    file
  ).toBlob()

  console.log(props.payload.applicantName)

  // Load PDF into pdf-lib
  const existingPdfBytes = await pdfBlob.arrayBuffer()
  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  // Encrypt the PDF to disable editing
  pdfDoc.encrypt({
    userPassword: 'Lpbldg0', // Replace with your user password
    ownerPassword: props.payload.applicantName, // Replace with your owner password
    // ownerPassword: '123', // Replace with your owner password
    permissions: {
      printing: 'highResolution', // 'lowResolution', 'highResolution', or false
      modifying: false,
      copying: false,
      annotating: false,
      fillingForms: false,
      contentAccessibility: false,
      documentAssembly: false,
    },
  })

  // Save the encrypted PDF
  const encryptedPdfBytes = await pdfDoc.save()
  const encryptedPdfBlob = new Blob([encryptedPdfBytes], {
    type: 'application/pdf',
  })
  handleSetEncrypt(encryptedPdfBlob)
  //   setEncryptedPdf(encryptedPdfBlob)
}
