export const civilData = [
  {
    id: 1,
    code_header: '',
    header: '',
    code_id: '1201',
    code_name: 'General Design and Construction Requirements',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 2,
    code_header: '',
    header: '',
    code_id: '1202',
    code_name:
      'Excavation and fills Footing, foundations and retaining walls (safely sustain the superimposed loads under seismic or any external forces for stability of structure',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 3,
    code_header: '',
    header: '',
    code_id: '1205',
    code_name:
      ' a. Floor Costruction (Chapter 5 - Fire Resistive standards and Chapter 6 - Type of Construction),  b. Floors framed and secured into framework and supporting walls (to form an integral part of the whole building), c. Types of floor construction (keeps beams and girders from lateral buckling), d. Roof construction, fire-resistive requirements for type of construction prescribed, e. Roof Trusses f. Attics, g. Roff drainage System, h. Flashing and counter flashing provided at juncture of the roof and vertical surfaces',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 4,
    code_header: '',
    header: '',
    code_id: '301.5.b.i-iii, 301.5.c - f',
    code_name:
      'Required civil/structural drawings and details: a. Foundation Plan/s, same scale as architechtural floor plan/s, showing: gridlines, complete dimensions,column-footing-wall footing labels., b. Floor Framing Plan/s, same scale as architectural floor plan/s, showing: gridlines, complete dimensions, beams-girders labels, slab labels., c. Roof Framing/Roof Deck Framing Plan, same scale as architectural roof plan, showing: gridlines, complete dimensions, truss/rafter labels, layout out of purlins and its corresponding sizes and spacings, roof beam-girder labels.,  d. Schedule of Columns, Footings, Wall Footings, floor Slabs,  and tfheir corresponding details., e. Schedule of Beams and Girders/Details., f. Schedule of Trusses, Truss Details., and g. Blow-up Detail of Connections: truss to roof beam, purlin to truss, etc.',
    compliance: '',
    id_remarks: '',
  },
]
