import Cookie from 'js-cookie'
import axios from 'axios'
import {
  MESSAGE_ADD_REQUEST,
  MESSAGE_ADD_SUCCESS,
  MESSAGE_ADD_FAILED,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILED,
  GET_MESSAGE_BYID_REQUEST,
  GET_MESSAGE_BYID_SUCCESS,
  GET_MESSAGE_BYID_FAILED,
} from '../constants/messageConstant'

export const messageInsert = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: MESSAGE_ADD_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // console.log(payload)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/message/add`,
        payload,
        config
      )

      dispatch({
        type: MESSAGE_ADD_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: MESSAGE_ADD_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const messagesRetrieve = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_MESSAGES_REQUEST,
      })
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/message`,
        config
      )

      dispatch({
        type: GET_MESSAGES_SUCCESS,
        payload: data.result,
      })
    } catch (error) {
      dispatch({
        type: GET_MESSAGES_FAILED,
        payload: 'No message found',
      })
    }
  }
}

export const messagesRetrieveById = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_MESSAGE_BYID_REQUEST,
      })
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/message/${payload}`,
        config
      )

      dispatch({
        type: GET_MESSAGE_BYID_SUCCESS,
        payload: data.result,
      })
    } catch (error) {
      dispatch({
        type: GET_MESSAGE_BYID_FAILED,
        payload: 'No message found',
      })
    }
  }
}
