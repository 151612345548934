export const electricalData = [
  {
    id: 1,
    code_header: '',
    header: '',
    code_id: '1301',
    code_name:
      '1. Reference - Philippine Electrical Code 2. Overload Service Entrance 3. Attachments on and Clearances from building4. Open Supply Conductors attached to Building 5. Conductors passing by or over Buildings 6. Clearance of Service Drops 7. Wiring Methods 8. Transformers 9. Provisions for Transformer Vault 10. Required capacitors installed on electric circuits in buildings 11. Emergency power systems 12. Electrical room 13. Service equipment 14. Metering facilities 15. Design and installation',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 2,
    code_header: '',
    header: '',
    code_id: '301.6.a-g',
    code_name:
      'Required electrical drawings, schedules/computations: 1. Location and Site Plan 2. Electrical Legends or Symbols 3. General Notes and/or Specifications 4. Electrical Layouts (power, lighting), per floor 5. Schedule of Loads and Computations, Transformers, Generating/UPS Units, if any 6. Design Analysis 7. One Line Diagram, Electrical Riser Diagram',
    compliance: '',
    id_remarks: '',
  },
]
