import {
  CATEGORIES_USER_REQUEST,
  CATEGORIES_USER_SUCCESS,
  CATEGORIES_USER_FAILED,
} from '../constants/categoriesConstant'

export const categoriesReducers = (state = {}, action) => {
  switch (action.type) {
    case CATEGORIES_USER_REQUEST:
      return { loading: true, state }
    case CATEGORIES_USER_SUCCESS:
      return { loading: false, categoriesDetail: action.payload }
    case CATEGORIES_USER_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
