import axios from 'axios'
import {
  DOWNLOADABLE_FORMS_REQUEST,
  DOWNLOADABLE_FORMS_SUCCESS,
  DOWNLOADABLE_FORMS_FAILED,
  GET_GUEST_APPLICATION_REQUEST,
  GET_GUEST_APPLICATION_SUCCESS,
  GET_GUEST_APPLICATION_FAILED,
} from '../constants/downloadableformsConstant'

export const downloadableformsRetrieve = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: DOWNLOADABLE_FORMS_REQUEST,
      })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/downloadableforms`
      )

      dispatch({
        type: DOWNLOADABLE_FORMS_SUCCESS,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: DOWNLOADABLE_FORMS_FAILED,
        payload: 'Unable to Retrieve Downloadable Forms',
      })
    }
  }
}

export const retrieveGuestApplication = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_GUEST_APPLICATION_REQUEST,
      })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/downloadableforms/application-list`
      )

      dispatch({
        type: GET_GUEST_APPLICATION_SUCCESS,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: GET_GUEST_APPLICATION_FAILED,
        payload: 'No Application found',
      })
    }
  }
}
