import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { withRouter } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
// import io from 'socket.io-client'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'
import CommentsCard from '../../reusable/CommentsCard'

// Style
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Pagination,
  Table,
} from 'react-bootstrap'
import {
  FaCheckCircle,
  FaPencilAlt,
  FaBook,
  FaFileArchive,
  FaFileUpload,
  FaHardHat,
  FaTags,
  FaCalendarDay,
  FaExclamationTriangle,
} from 'react-icons/fa'

// Revised Actions
import {
  retrieveAllApplications,
  retrieveApplicationComments,
  retrieveRequirementList,
  retrieveSubmittedRequirements,
  retrieveEvaluators,
  retrieveInspectors,
  retrieveSubmittedEvaluators,
  retrieveSubmittedInspector,
  retrieveSubmittedEngineers,
  retrieveSubmittedOrderofPayment,
  updateApplicationStatus,
  updateSubmittedRequirements,
  updateEngineerInformation,
  updateEvaluators,
  updateInspector,
  updateOrderOfPayment,
  insertOrderofPayment,
} from '../../redux/actions/DocumentControllers/documentControllerActions'

// const socket = io.connect('http://localhost:5000')

// const socket = io.connect(process.env.REACT_APP_URL)

const ManageApplication = ({ history, match }) => {
  // Declaration
  const [state, setState] = useState({
    status: '',
    remarks: '',
    comments: '',
    inspectID: 0,
    inspectionSched: '',
  })

  const [receivedStatus, setReceivedStatus] = useState('')

  const [reload, setReload] = useState(false)

  const [filenames, setFilenames] = useState([])

  const [listDocuments, setListDocuments] = useState([])

  const [modal, setModal] = useState({
    changeStatus: false,
    updateDocs: false,
    addDocs: false,
    engineer: false,
    evaluators: false,
    inspector: false,
    updateOP: false,
    insertOP: false,
  })

  const [showUpdating, setShowUpdating] = useState(false)

  const [showSubmittedDocs, setShowSubmittedDocs] = useState({
    add: false,
    edit: false,
    isEditting: false,
  })

  const [showEng, setShowEng] = useState(false)

  const [showEvaluator, setShowEvaluator] = useState(false)

  const [showInspector, setShowInspector] = useState(false)

  const [recordEngineers, setRecordEngineers] = useState([])

  const [tradesEvaluators, setTradesEvaluators] = useState([
    ['Structural', 0],
    ['Mechanical', 0],
    ['Architectural', 0],
    ['Sanitary', 0],
    ['Electrical', 0],
    ['Plumbing', 0],
    ['Electronics', 0],
  ])

  const [evalUpdateValue, setEvalUpdateValue] = useState([])

  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const dc = useSelector((state) => state.documentControllerReducer)

  const {
    allApplications,
    applicationComments,
    requirementList,
    submittedRequirements,
    updatedStatus,
    evaluatorList,
    inspectionList,
    submittedEvaluatorList,
    submittedInspector,
    submittedEngineers,
    submittedOrderofPayment,
    updateRequirements,
    updatedEngineerInformation,
    updatedEvaluators,
    updatedInspector,
    insertedOrderPayment,
    updatedOrderOfPayment,
  } = dc

  const [editEvaluator, setEditEvaluator] = useState({
    structural: false,
    mechanical: false,
    architectural: false,
    sanitary: false,
    electrical: false,
    plumbing: false,
    electronics: false,
    isEditting: false,
  })

  const [orderOfPayment, setOrderOfPayment] = useState(false)

  const [nationalCode, setNationalCode] = useState({})
  const [cityOrdinance, setCityOrdinance] = useState({
    fillingFee: 300,
    processingFee: 750,
  })

  const [permitCode, setPermitCode] = useState('')
  const [newStatus, setNewStatus] = useState('')
  // End Declaration

  // Req Docs Pagination
  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(10)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(10)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)

  useEffect(() => {
    setpaginatedForms(
      _(_.reverse(listDocuments)).slice(0).take(pageNumberLimit).value()
    )
    setcurrentPage(1)
  }, [listDocuments, pageNumberLimit])

  const pageCount = listDocuments
    ? Math.ceil(listDocuments.length / pageNumberLimit)
    : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageNumberLimit
    const paginationForms = _(listDocuments)
      .slice(startIndex)
      .take(pageNumberLimit)
      .value()
    setpaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Req Docs Pagination

  // Functions
  const handleChangeNational = (e) => {
    setNationalCode({
      ...nationalCode,
      [e.target.name]: parseFloat(e.target.value),
    })
  }

  const handleChangeCity = (e) => {
    if (parseFloat(e.target.value) >= 0) {
      setCityOrdinance({
        ...cityOrdinance,
        [e.target.name]: parseFloat(e.target.value),
      })
    }
  }

  const handleSubmitOrderOfPayment = (e) => {
    e.preventDefault()
    dispatch(
      insertOrderofPayment({
        id: match.params.id,
        permitCode: permitCode,
        lineAndGrade: nationalCode.lineAndGrade ? nationalCode.lineAndGrade : 0,
        building: nationalCode.building ? nationalCode.building : 0,
        additional: nationalCode.additional ? nationalCode.additional : 0,
        roofDeck: nationalCode.roofDeck ? nationalCode.roofDeck : 0,
        demolition: nationalCode.demolition ? nationalCode.demolition : 0,
        ancillary: nationalCode.ancillary ? nationalCode.ancillary : 0,
        constructionOfTombs: nationalCode.constructionOfTombs
          ? nationalCode.constructionOfTombs
          : 0,
        plumbing: nationalCode.plumbing ? nationalCode.plumbing : 0,
        electrical: nationalCode.electrical ? nationalCode.electrical : 0,
        mechanical: nationalCode.mechanical ? nationalCode.mechanical : 0,
        fencing: nationalCode.fencing ? nationalCode.fencing : 0,
        constructionOfSidewalks: nationalCode.constructionOfSidewalks
          ? nationalCode.constructionOfSidewalks
          : 0,
        pavedAreas: nationalCode.pavedAreas ? nationalCode.pavedAreas : 0,
        useOfStreetSidewalks: nationalCode.useOfStreetSidewalks
          ? nationalCode.useOfStreetSidewalks
          : 0,
        certificateOfUseOccupancy: nationalCode.certificateOfUseOccupancy
          ? nationalCode.certificateOfUseOccupancy
          : 0,
        changeInUseOccupancy: nationalCode.changeInUseOccupancy
          ? nationalCode.changeInUseOccupancy
          : 0,
        certificate10: nationalCode.certificate10
          ? nationalCode.certificate10
          : 0,
        electronics: nationalCode.electronics ? nationalCode.electronics : 0,
        annual: nationalCode.annual ? nationalCode.annual : 0,
        signPermit: nationalCode.signPermit ? nationalCode.signPermit : 0,
        certification: nationalCode.certification
          ? nationalCode.certification
          : 0,
        penalty: nationalCode.penalty ? nationalCode.penalty : 0,
        administrativeFine: nationalCode.administrativeFine
          ? nationalCode.administrativeFine
          : 0,
        fillingFee: cityOrdinance.fillingFee ? cityOrdinance.fillingFee : 0,
        processingFee: cityOrdinance.processingFee
          ? cityOrdinance.processingFee
          : 0,
        complaint: cityOrdinance.complaint ? cityOrdinance.complaint : 0,
        others: cityOrdinance.others ? cityOrdinance.others : 0,
        nationalSum: nationalSum,
        citySum: citySum,
        totalAmount: OpTotalAmount,
        userID: userCredentials.id,
      })
    )
    setOrderOfPayment(false)
    setModal({ insertOP: true })
  }

  const handleUpdateOrderOfPayment = (e) => {
    e.preventDefault()
    dispatch(
      updateOrderOfPayment({
        oid:
          submittedOrderofPayment &&
          submittedOrderofPayment.message &&
          submittedOrderofPayment.message[0].id,
        id: match.params.id,
        permitCode: permitCode,
        lineAndGrade: nationalCode.lineAndGrade ? nationalCode.lineAndGrade : 0,
        building: nationalCode.building ? nationalCode.building : 0,
        additional: nationalCode.additional ? nationalCode.additional : 0,
        roofDeck: nationalCode.roofDeck ? nationalCode.roofDeck : 0,
        demolition: nationalCode.demolition ? nationalCode.demolition : 0,
        ancillary: nationalCode.ancillary ? nationalCode.ancillary : 0,
        constructionOfTombs: nationalCode.constructionOfTombs
          ? nationalCode.constructionOfTombs
          : 0,
        plumbing: nationalCode.plumbing ? nationalCode.plumbing : 0,
        electrical: nationalCode.electrical ? nationalCode.electrical : 0,
        mechanical: nationalCode.mechanical ? nationalCode.mechanical : 0,
        fencing: nationalCode.fencing ? nationalCode.fencing : 0,
        constructionOfSidewalks: nationalCode.constructionOfSidewalks
          ? nationalCode.constructionOfSidewalks
          : 0,
        pavedAreas: nationalCode.pavedAreas ? nationalCode.pavedAreas : 0,
        useOfStreetSidewalks: nationalCode.useOfStreetSidewalks
          ? nationalCode.useOfStreetSidewalks
          : 0,
        certificateOfUseOccupancy: nationalCode.certificateOfUseOccupancy
          ? nationalCode.certificateOfUseOccupancy
          : 0,
        changeInUseOccupancy: nationalCode.changeInUseOccupancy
          ? nationalCode.changeInUseOccupancy
          : 0,
        certificate10: nationalCode.certificate10
          ? nationalCode.certificate10
          : 0,
        electronics: nationalCode.electronics ? nationalCode.electronics : 0,
        annual: nationalCode.annual ? nationalCode.annual : 0,
        signPermit: nationalCode.signPermit ? nationalCode.signPermit : 0,
        certification: nationalCode.certification
          ? nationalCode.certification
          : 0,
        penalty: nationalCode.penalty ? nationalCode.penalty : 0,
        administrativeFine: nationalCode.administrativeFine
          ? nationalCode.administrativeFine
          : 0,
        fillingFee: cityOrdinance.fillingFee ? cityOrdinance.fillingFee : 0,
        processingFee: cityOrdinance.processingFee
          ? cityOrdinance.processingFee
          : 0,
        complaint: cityOrdinance.complaint ? cityOrdinance.complaint : 0,
        others: cityOrdinance.others ? cityOrdinance.others : 0,
        nationalSum: nationalSum,
        citySum: citySum,
        totalAmount: OpTotalAmount,
        userID: userCredentials.id,
      })
    )
    setOrderOfPayment(false)
    setModal({ updateOP: true })
  }

  const handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        let responses = listDocuments
        let index = responses.findIndex((el) => el[0] === e.target.value)
        responses[index][1] = 1
        setFilenames([...filenames, [e.target.value, 1]])
        setListDocuments(responses)
      } else {
        let responses = listDocuments
        let index = responses.findIndex((el) => el[0] === e.target.value)
        responses[index][1] = 0
        setFilenames(filenames.filter((y) => y[0] !== e.target.value))
        setListDocuments(responses)
      }
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleEvaluatorChange = (e) => {
    let tradeIndex = tradesEvaluators.findIndex((el) => el[0] === e.target.name)
    let evalIndex = evalUpdateValue.findIndex((el) => el[1] === e.target.name)
    if (submittedEvaluatorList && submittedEvaluatorList.message) {
      let response = submittedEvaluatorList.message
      let index = response.findIndex((el) => el.trade === e.target.name)

      if (evalIndex < 0) {
        setEvalUpdateValue([
          ...evalUpdateValue,
          [response[index].id, e.target.name, parseInt(e.target.value)],
        ])
      } else {
        if (evalUpdateValue[evalIndex][0] === response[index].id) {
          let response3 = evalUpdateValue
          response3[evalIndex][2] = parseInt(e.target.value)
          setEvalUpdateValue([...response3])
        }
      }

      let response1 = tradesEvaluators
      response1[tradeIndex][1] = parseInt(e.target.value)
      setTradesEvaluators([...response1])
    } else {
      let response = tradesEvaluators
      response[tradeIndex][1] = parseInt(e.target.value)
      setTradesEvaluators([...response])
    }
  }
  // End Functions

  // Min Date
  const [appDate, setAppDate] = useState('')

  const minDate = () => {
    let dateMin = new Date()

    let minYear = dateMin.getFullYear()
    let minMonth = ('0' + (dateMin.getMonth() + 1)).slice(-2)
    let minDay = ('0' + dateMin.getDate()).slice(-2)

    let minDate = minYear + '-' + minMonth + '-' + minDay

    if (appDate === '') {
      setAppDate(minDate)
    }
  }

  useEffect(() => {
    minDate()
    // sendNewStatus()
  })
  // End Min Date

  let nationalSum = 0

  for (const nationalValue of Object.values(nationalCode)) {
    nationalSum += nationalValue
  }

  const cityValues = Object.values(cityOrdinance)

  let citySum = cityValues.reduce((accumulator, value) => {
    return accumulator + value
  }, 0)

  let OpTotalAmount = nationalSum + citySum

  //UseEffect
  useEffect(() => {
    dispatch(retrieveAllApplications())
    dispatch(retrieveRequirementList())
    dispatch(retrieveSubmittedRequirements({ id: match.params.id }))
    dispatch(retrieveApplicationComments({ id: match.params.id }))
    dispatch(retrieveEvaluators())
    dispatch(retrieveInspectors())
    dispatch(retrieveSubmittedEvaluators({ id: match.params.id }))
    dispatch(retrieveSubmittedInspector({ id: match.params.id }))
    dispatch(retrieveSubmittedEngineers({ id: match.params.id }))
    dispatch(retrieveSubmittedOrderofPayment({ id: match.params.id }))
    // socket.on('received_status', (data) => {
    //   setNewStatus(data.message)
    //   // console.log(data)
    // })
  }, [dispatch, reload, match.params.id])

  useEffect(() => {
    if (allApplications && allApplications.message) {
      let params = match.params.id
      const application = allApplications.message.find(
        (app) => app.id === parseInt(params)
      )
      setState(application)
      setReceivedStatus(application.status)
    }
    if (submittedEngineers && submittedEngineers.message) {
      setRecordEngineers(submittedEngineers.message)
    } else {
      setRecordEngineers([
        { engType: '', engineer: '', engName: '', engPrcNo: '' },
      ])
    }
    if (submittedEvaluatorList && submittedEvaluatorList.message) {
      submittedEvaluatorList.message.forEach((evaluator) => {
        const response = tradesEvaluators
        let index = response.findIndex((el) => el[0] === evaluator.trade)
        response[index][1] = evaluator.evaluatorID

        setTradesEvaluators(response)
        setEditEvaluator({
          structural: true,
          mechanical: true,
          architectural: true,
          sanitary: true,
          electrical: true,
          plumbing: true,
          electronics: true,
        })
      })
    }
    if (submittedInspector && submittedInspector.message) {
      setState((state) => ({
        ...state,
        inspectID: submittedInspector.message[0].inspectID,
        inspectionSched: submittedInspector.message[0].inspectionSched,
      }))
    }
    if (submittedOrderofPayment && submittedOrderofPayment.message) {
      setNationalCode({
        lineAndGrade: submittedOrderofPayment.message[0].LineAndGrade,
        building: submittedOrderofPayment.message[0].BuildingPermitFees,
        additional: submittedOrderofPayment.message[0].AdditionalRenovation,
        roofDeck: submittedOrderofPayment.message[0].RoofDeck,
        demolition: submittedOrderofPayment.message[0].DemolitionPermitFees,
        ancillary: submittedOrderofPayment.message[0].AncillaryStructure,
        constructionOfTombs: submittedOrderofPayment.message[0].TombsCanopies,
        plumbing: submittedOrderofPayment.message[0].PlumbingPermitFees,
        electrical: submittedOrderofPayment.message[0].ElectricalPermitFees,
        mechanical: submittedOrderofPayment.message[0].MechanicalPermitFees,
        fencing: submittedOrderofPayment.message[0].FencingPermitFees,
        constructionOfSidewalks: submittedOrderofPayment.message[0].Sidewalks,
        pavedAreas: submittedOrderofPayment.message[0].PavedAreas,
        useOfStreetSidewalks: submittedOrderofPayment.message[0].StreetSidewalk,
        certificateOfUseOccupancy:
          submittedOrderofPayment.message[0].CertificateOccupancy,
        changeInUseOccupancy:
          submittedOrderofPayment.message[0].ChangeOccupancy,
        certificate10:
          submittedOrderofPayment.message[0].CertificateOccupancy10,
        electronics: submittedOrderofPayment.message[0].ElectronicsPermit,
        annual: submittedOrderofPayment.message[0].AnnualInspection,
        signPermit: submittedOrderofPayment.message[0].SignPermitFees,
        certification: submittedOrderofPayment.message[0].Certification,
        penalty: submittedOrderofPayment.message[0].Penalty,
        administrativeFine:
          submittedOrderofPayment.message[0].AdministrativeFive,
      })
      setPermitCode(submittedOrderofPayment.message[0].permitCode)
      setCityOrdinance({
        ...cityOrdinance,
        complaint: submittedOrderofPayment.message[0].ComplaintFilingFee,
        others: submittedOrderofPayment.message[0].Others,
      })
    }
  }, [
    allApplications,
    submittedEngineers,
    submittedEvaluatorList,
    match,
    submittedInspector,
    submittedOrderofPayment,
  ])
  //  End UseEffect

  return (
    <UserMain>
      <Container
        fluid
        className='application-actions'
        style={{
          color: '#00304b',
          backgroundImage: 'url(../assets/img/guest-bg1.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        <Container
          className='bg-white shadow-sm px-4 pb-5'
          style={{
            height: '86vh',
            overflowY: 'auto',
          }}
        >
          <h3 className='mt-3 text-center'>Application Manager</h3>

          {/* {newStatus !== userCredentials.id && (
              <h4>
                <FaExclamationTriangle /> This Application is currently editting
                by : {newStatus}
              </h4>
            )} */}

          <Row className='mt-5 text-center'>
            <Col xl={12}>
              <p
                onClick={() => {
                  setShowUpdating(true)
                  setState({ ...state, dcID: userCredentials.id })
                }}
              >
                <FaBook /> CHANGE STATUS
              </p>

              {receivedStatus !== 'APPLICATION VERIFICATION' &&
                receivedStatus !== 'FOR COMPLIANCE' && (
                  <>
                    <p
                      onClick={() => {
                        history.push(`/application-information/${state.id}`)
                      }}
                    >
                      <FaFileArchive /> ADD/EDIT APPLICATION INFORMATION
                    </p>

                    {submittedRequirements && submittedRequirements.message ? (
                      <p
                        onClick={() => {
                          setShowSubmittedDocs({
                            edit: true,
                          })
                          setState({ ...state, dcID: userCredentials.id })
                          requirementList &&
                            setListDocuments(
                              requirementList.message.map((list) => [
                                list.description,
                                0,
                              ])
                            )
                        }}
                      >
                        <FaFileUpload /> ADD/EDIT SUBMITTED REQUIRED DOCUMENTS
                        INFORMATION
                      </p>
                    ) : (
                      <p
                        onClick={() => {
                          setShowSubmittedDocs({
                            add: true,
                          })
                          requirementList &&
                            setListDocuments(
                              requirementList.message.map((list) => [
                                list.description,
                                0,
                              ])
                            )
                        }}
                      >
                        <FaFileUpload /> ADD/EDIT SUBMITTED REQUIRED DOCUMENTS /
                        INFORMATION
                      </p>
                    )}

                    <p
                      onClick={() => {
                        setShowEng(true)
                        setState({ ...state, dcID: userCredentials.id })
                      }}
                    >
                      <FaHardHat /> ADD/EDIT ENGINEER/ARCHITECT INFORMATION
                    </p>

                    <p
                      onClick={() => {
                        setShowEvaluator(true)
                        setState({ ...state, dcID: userCredentials.id })
                      }}
                    >
                      <FaTags /> ADD/EDIT APPLICATION EVALUATOR/S
                    </p>

                    <p
                      onClick={() => {
                        setShowInspector(true)
                        setState({ ...state, dcID: userCredentials.id })
                      }}
                    >
                      <FaCalendarDay /> ADD/EDIT APPLICATION INSPECTOR
                    </p>

                    <LinkContainer to={`/routing-slip/${state.id}`}>
                      <p>VIEW ROUTING SLIP</p>
                    </LinkContainer>
                  </>
                )}
              {receivedStatus === 'APPLICATION VERIFICATION' &&
                receivedStatus !== 'FOR COMPLIANCE' && (
                  <h6 className='mt-5' style={{ color: '#313538' }}>
                    "Note: Other features will be displayed if the application
                    status has changed to application verification or for
                    compliance."
                  </h6>
                )}

              {/* <p
                  onClick={() => {
                    setOrderOfPayment(true)
                    setState({ ...state, dcID: userCredentials.id })
                  }}
                >
                  ORDER OF PAYMENT
                </p> */}
            </Col>
          </Row>
          <div className='text-end mb-3'>
            <Button
              onClick={() => history.push('/applications')}
              variant='danger'
            >
              Back
            </Button>
          </div>
        </Container>
      </Container>

      {/* Update Application Status */}
      <Modal
        size='lg'
        show={showUpdating}
        onHide={() => setShowUpdating(false)}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            Update Application Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              {
                if (
                  window.confirm(
                    'Are you sure you want to update status of application?'
                  )
                ) {
                  setShowUpdating(false)
                  dispatch(
                    updateApplicationStatus({
                      id: state.statusID,
                      status: state.status,
                      remarks: state.remarks,
                      withEvaluation: '',
                      withInspection: '',
                      application_ID: state.id,
                      userID: state.userID,
                      adminID: userCredentials.id,
                      comments: state.comments,
                      commentTo:
                        state.status === 'FOR COMPLIANCE' ||
                        state.status === 'APPROVED APPLICATION' ||
                        state.status === 'RELEASE APPLICATION' ||
                        state.status === 'RELEASE APPLICATION WITH REMARKS'
                          ? state.userID
                          : userCredentials.id,
                      createdBy: userCredentials.id,
                    })
                  )
                  setModal({ changeStatus: true })
                }
              }
            }}
          >
            <Row>
              <Col xl='6'>
                <Form.Label>
                  <h6>Comment To (Name):</h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  style={{
                    background: '#f7f7f8',
                    border: '1px solid #ced4da',
                  }}
                  size='sm'
                  disabled
                  value={
                    state.status === 'FOR COMPLIANCE' ||
                    state.status === 'APPROVED APPLICATION' ||
                    state.status === 'RELEASE APPLICATION' ||
                    state.status === 'RELEASE APPLICATION WITH REMARKS'
                      ? state.FullName
                      : ''
                  }
                />
              </Col>
              <Col xl='6'>
                <Form.Label>
                  <h6>Application Status:</h6>
                </Form.Label>
                <Form.Control
                  as='select'
                  size='sm'
                  name='status'
                  value={state.status}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  required
                  onChange={handleChange}
                >
                  {receivedStatus !== 'APPROVED APPLICATION' && (
                    <>
                      <option value=''>choose status here . . .</option>
                      <option value='FOR COMPLIANCE'>FOR COMPLIANCE</option>
                      <option value='FOR INSPECTION AND EVALUATION'>
                        FOR INSPECTION AND EVALUATION
                      </option>
                      <option value='APPLICATION VERIFICATION'>
                        APPLICATION VERIFICATION
                      </option>
                    </>
                  )}
                  {receivedStatus === 'APPROVED APPLICATION' && (
                    <>
                      <option value=''>Choose status here ...</option>
                      <option value='RELEASE APPLICATION'>
                        RELEASE APPLICATION
                      </option>
                      <option value='RELEASE APPLICATION WITH REMARKS'>
                        RELEASE APPLICATION WITH REMARKS
                      </option>
                    </>
                  )}

                  {/* <option value='APPROVED APPLICATION'>
                      APPROVED APPLICATION
                    </option> */}
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col xl='12'>
                <Form.Control
                  as='textarea'
                  className='my-2'
                  name='comments'
                  // value={state.remarks === null ? '' : state.remarks}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  required
                  onChange={handleChange}
                  placeholder="Insert comments  and/or  link/s  here  (For Employee's  view  only) 
              (Complete Requirements - www.google.com) ... "
                />
              </Col>
            </Row>
            <Row>
              <Col xl='10'>
                <Form.Control
                  as='textarea'
                  className='my-2'
                  name='remarks'
                  // value={state.remarks === null ? '' : state.remarks}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  required
                  onChange={handleChange}
                  placeholder='Insert remarks here 
              (Complete Requirements) ... '
                />
              </Col>
              <Col className='d-flex align-items-end' xl='2'>
                <Button
                  variant='success'
                  size='sm'
                  disabled={
                    state.status === '' ||
                    state.status === 'APPLICATION VERIFICATION'
                      ? true
                      : false
                  }
                  type='submit'
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>

          <CommentsCard
            state={
              applicationComments &&
              applicationComments.message &&
              applicationComments.message
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={() => {
              setShowUpdating(false)
            }}
          >
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>
      {/*End Update Application Status */}

      {/* Submitted Required Docs */}
      <Modal
        size='lg'
        show={showSubmittedDocs.edit}
        onHide={() => setShowSubmittedDocs({ edit: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            Submitted Required Documents
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='fs-4 fw-bold'>File Name/s:</p>
          {submittedRequirements &&
            submittedRequirements.message &&
            submittedRequirements.message.map((docs, index) => (
              <Row key={index}>
                <Col sm={2}>
                  <p>{index + 1}.</p>
                </Col>
                <Col sm={10}>
                  <h5>{docs.FileName}</h5>
                </Col>
                <hr />
              </Row>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='info'
            onClick={() => {
              setShowSubmittedDocs({
                edit: false,
                isEditting: true,
              })

              submittedRequirements &&
                submittedRequirements.message.map((submitted) => {
                  let temp = listDocuments
                  let index = temp.findIndex(
                    (el) => el[0] === submitted.FileName
                  )
                  temp[index][1] = 1
                  setListDocuments(temp)
                })
            }}
          >
            Edit
          </Button>
          <Button
            variant='danger'
            onClick={() => {
              setShowSubmittedDocs({ edit: false })
            }}
          >
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>
      {/*End Submitted Required Docs */}

      {/* isEditting Submitted Required Docs */}
      <Modal
        size='lg'
        show={showSubmittedDocs.isEditting}
        onHide={() => setShowSubmittedDocs({ isEditting: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            Required Documents
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Kindly check the box if the documents are included in the
            application requirements submitted by the applicant.
          </h5>
          <Table responsive>
            <thead>
              <tr>
                <th>Description</th>
                <th className='text-center'>Submitted</th>
              </tr>
            </thead>
            <tbody>
              {paginatedForms.map((x) => (
                <tr key={x[0]}>
                  <td>{x[0]}</td>
                  <td className='d-flex justify-content-center'>
                    <Form.Group className='mb-3'>
                      <Form.Check
                        type='checkbox'
                        name='filenames'
                        value={x[0]}
                        onChange={handleChange}
                        checked={x[1] === 1 ? true : false}
                      />
                    </Form.Group>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination size='sm' className='d-flex justify-content-end mx-2'>
            <Pagination.Item
              className='mt-0 fs-6 text-nowrap'
              size='sm'
              variant='info'
              onClick={handlePrevbtn}
              disabled={currentPage === pages[0] ? true : false}
            >
              &lt;
            </Pagination.Item>

            {renderPageNumber}

            <Pagination.Item
              className='mt-0 fs-6 text-nowrap'
              size='sm'
              variant='info'
              onClick={handleNextbtn}
              disabled={currentPage === pages[pages.length - 1] ? true : false}
            >
              &gt;
            </Pagination.Item>
          </Pagination>
          <hr />
          <Row>
            <Col xl={12} className='d-flex justify-content-end'>
              <Button
                onClick={() => {
                  if (
                    window.confirm(
                      'Are you sure you want to update submitted documents?'
                    )
                  ) {
                    let filtered = listDocuments.filter((list) => list[1] > 0)
                    dispatch(
                      updateSubmittedRequirements({
                        dcID: userCredentials.id,
                        id: state.id,
                        filenames: filtered,
                        transaction: true,
                      })
                    )
                    setShowSubmittedDocs({ isEditting: false })
                    setModal({
                      updateDocs: true,
                    })
                  }
                }}
                variant='info'
                className='mx-2'
              >
                Submit
              </Button>
              <Button
                onClick={() => {
                  setShowSubmittedDocs({ isEditting: false })
                }}
                variant='danger'
                className='mx-2'
              >
                BACK
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* End isEditting Submitted Required Docs */}

      {/* add Submitted Required Docs */}
      <Modal
        size='lg'
        show={showSubmittedDocs.add}
        onHide={() => setShowSubmittedDocs({ add: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            Required Documents
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Kindly check the box if the documents are included in the
            application requirements submitted by the applicant.
          </h5>
          <Table responsive>
            <thead>
              <tr>
                <th>Description</th>
                <th className='text-center'>Submitted</th>
              </tr>
            </thead>
            <tbody>
              {paginatedForms.map((x) => (
                <tr key={x[0]}>
                  <td>{x[0]}</td>
                  <td className='d-flex justify-content-center'>
                    <Form.Group className='mb-3'>
                      <Form.Check
                        type='checkbox'
                        name='filenames'
                        value={x[0]}
                        onChange={handleChange}
                        checked={x[1] === 1 ? true : false}
                      />
                    </Form.Group>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination size='sm' className='d-flex justify-content-end mx-2'>
            <Pagination.Item
              className='mt-0 fs-6 text-nowrap'
              size='sm'
              variant='info'
              onClick={handlePrevbtn}
              disabled={currentPage === pages[0] ? true : false}
            >
              &lt;
            </Pagination.Item>

            {renderPageNumber}

            <Pagination.Item
              className='mt-0 fs-6 text-nowrap'
              size='sm'
              variant='info'
              onClick={handleNextbtn}
              disabled={currentPage === pages[pages.length - 1] ? true : false}
            >
              &gt;
            </Pagination.Item>
          </Pagination>
          <hr />
          <Row>
            <Col xl={12} className='d-flex justify-content-end'>
              <Button
                onClick={() => {
                  if (window.confirm('Are you sure you want to submit?')) {
                    let filtered = listDocuments.filter((list) => list[1] > 0)
                    dispatch(
                      updateSubmittedRequirements({
                        dcID: userCredentials.id,
                        id: state.id,
                        filenames: filtered,
                        transaction: false,
                      })
                    )
                    setShowSubmittedDocs({ add: false })
                    setModal({
                      addDocs: true,
                    })
                  }
                }}
                variant='info'
                className='mx-2'
              >
                SUBMIT
              </Button>
              <Button
                onClick={() => {
                  setShowSubmittedDocs({ add: false })
                }}
                variant='danger'
                className='mx-2'
              >
                BACK
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* End add Submitted Required Docs */}

      {/*Engineer/Architect Information */}
      <Modal
        size='lg'
        show={showEng}
        onHide={() => setShowEng(false)}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            Project Engineer / Architect Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              if (window.confirm('Are you sure you want to submit?')) {
                e.preventDefault()
                submittedEngineers && submittedEngineers.message
                  ? dispatch(
                      updateEngineerInformation({
                        dcID: userCredentials.id,
                        id: state.id,
                        recordEngineers,
                        isEditting: true,
                      })
                    )
                  : dispatch(
                      updateEngineerInformation({
                        dcID: userCredentials.id,
                        id: state.id,
                        recordEngineers,
                        isEditting: false,
                      })
                    )
                setShowEng(false)
                setModal({ engineer: true })
              }
            }}
          >
            <Row>
              <Col xl={3}>
                <h5>Category</h5>
              </Col>
              <Col xl={3}>
                <h5>Type</h5>
              </Col>
              <Col xl={3}>
                <h5>Name</h5>
              </Col>
              <Col xl={3}>
                <h5>PRC ID No.</h5>
              </Col>
            </Row>

            {recordEngineers.map((info, index) => (
              <Row key={index}>
                <Form.Group className='my-2' as={Col} xl={3}>
                  <Form.Control
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    value={info.engineer}
                    name='engineer'
                    onChange={(e) => {
                      let response = recordEngineers
                      response[index].engineer = e.target.value
                      setRecordEngineers([...response])
                    }}
                    required
                    placeholder='category'
                  />
                  {/* <Form.Control
                      as='select'
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={appInfo.Barangay}
                      name='Barangay'
                      onChange={editHandleChange}
                      required
                    >
                      <option value=''>Select Barangay here ...</option>
                      {engineerCategory.map((element, index) => (
                        <option key={index} value={element.barangay}>
                          {element.barangay}
                        </option>
                      ))}
                    </Form.Control> */}
                </Form.Group>
                <Form.Group className='mt-1' as={Col} xl={3}>
                  <Form.Control
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    value={info.engType}
                    name='engType'
                    onChange={(e) => {
                      let response = recordEngineers
                      response[index].engType = e.target.value
                      setRecordEngineers([...response])
                    }}
                    required
                    placeholder='Engineer Type'
                  />
                </Form.Group>
                <Form.Group className='mt-1' as={Col} xl={3}>
                  <Form.Control
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    name='engName'
                    value={info.engName}
                    onChange={(e) => {
                      let response = recordEngineers
                      response[index].engName = e.target.value
                      setRecordEngineers([...response])
                    }}
                    required
                    placeholder='Full Name'
                  />
                </Form.Group>
                <Form.Group className='mt-1' as={Col} xl={3}>
                  <Form.Control
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    name='engPrcNo'
                    value={info.engPrcNo}
                    onChange={(e) => {
                      let response = recordEngineers
                      response[index].engPrcNo = e.target.value
                      setRecordEngineers([...response])
                    }}
                    required
                  />
                </Form.Group>
              </Row>
            ))}

            {/* Names Action Btn */}
            <Row>
              <Col xl={12} className='my-2'>
                <Button
                  onClick={() =>
                    setRecordEngineers([
                      ...recordEngineers,
                      { engType: '', engineer: '', engName: '', engPrcNo: '' },
                    ])
                  }
                  size='sm'
                  variant='info'
                >
                  +
                </Button>
                <Button
                  onClick={() => {
                    let response = recordEngineers
                    if (recordEngineers.length > 1) {
                      response.pop(response.length - 1)
                    }
                    setRecordEngineers([...response])
                  }}
                  size='sm'
                  variant='danger'
                  className='mx-2'
                >
                  -
                </Button>
              </Col>
            </Row>
            {/* End Names Action Btn */}

            <hr />
            <Row>
              <Col xl={12} className='d-flex justify-content-end'>
                <Button type='submit' variant='info' className='mx-2'>
                  SUBMIT
                </Button>
                <Button
                  onClick={() => {
                    setShowEng(false)
                  }}
                  variant='danger'
                  className='mx-2'
                >
                  BACK
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* End Engineer/Architect Information */}

      {/*Evaluators Information */}
      <Modal
        size='lg'
        show={showEvaluator}
        onHide={() => setShowEvaluator(false)}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            Choose Evaluators of Permit/s
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              if (
                window.confirm(
                  'Are you sure you want to update status of application?'
                )
              ) {
                let trades = tradesEvaluators.filter((el) => el[1] > 0)
                dispatch(
                  updateEvaluators({
                    dcID: userCredentials.id,
                    id: state.id,
                    tradesEvaluators: trades,
                    isEditting: false,
                    updateValues: [],
                    // updateValues: evalUpdateValue,
                  })
                )
                // dispatch(retrieveSubmittedEvaluators({ id: match.params.id }))
                setModal({ evaluators: true })
                setShowEvaluator(false)
              }
            }}
          >
            {state.Structural === 1 && (
              <>
                <Row>
                  <Col xl='11'>
                    <h5>STRUCTURAL EVALUATOR</h5>
                  </Col>
                  <Col
                    xl='1'
                    className='text-end'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setEditEvaluator({
                        isEditting: true,
                        structural: false,
                        mechanical: true,
                        architectural: true,
                        sanitary: true,
                        electrical: true,
                        plumbing: true,
                        electronics: true,
                      })
                    }
                  >
                    <FaPencilAlt className='text-success' />
                  </Col>
                </Row>

                <Form.Group as={Col} md={12}>
                  <Form.Control
                    as='select'
                    className='my-2'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    name='Structural'
                    required
                    value={tradesEvaluators[0][1]}
                    onChange={(e) => {
                      handleEvaluatorChange(e)
                    }}
                    disabled={editEvaluator.structural}
                  >
                    <option value=''>choose evaluator here . . .</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Structural' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                </Form.Group>
                {submittedEvaluatorList &&
                  submittedEvaluatorList.message &&
                  editEvaluator.structural === false && (
                    <div className='d-flex justify-content-center mb-1'>
                      <Button
                        variant='info'
                        size='sm'
                        className='mx-2'
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to update status of application?'
                            )
                          ) {
                            dispatch(
                              updateEvaluators({
                                dcID: userCredentials.id,
                                id: state.id,
                                tradesEvaluators: '',
                                isEditting: editEvaluator.isEditting,
                                updateValues: evalUpdateValue,
                              })
                            )
                            setModal({ evaluators: true })
                            setShowEvaluator(false)
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant='danger'
                        size='sm'
                        onClick={() =>
                          setEditEvaluator({
                            ...editEvaluator,
                            structural: true,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
              </>
            )}

            {state.Mechanical === 1 && (
              <>
                <Row>
                  <Col xl='11'>
                    <h5>MECHANICAL EVALUATOR</h5>
                  </Col>
                  <Col
                    xl='1'
                    className='text-end'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setEditEvaluator({
                        isEditting: true,
                        mechanical: false,
                        structural: true,
                        architectural: true,
                        sanitary: true,
                        electrical: true,
                        plumbing: true,
                        electronics: true,
                      })
                    }
                  >
                    <FaPencilAlt className='text-success' />
                  </Col>
                </Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    as='select'
                    className='my-2'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    name='Mechanical'
                    required
                    value={tradesEvaluators[1][1]}
                    onChange={(e) => {
                      handleEvaluatorChange(e)
                    }}
                    disabled={editEvaluator.mechanical}
                  >
                    <option value=''>choose evaluator here . . .</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Mechanical' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                </Form.Group>
                {submittedEvaluatorList &&
                  submittedEvaluatorList.message &&
                  editEvaluator.mechanical === false && (
                    <div className='d-flex justify-content-center mb-1'>
                      <Button
                        variant='info'
                        size='sm'
                        className='mx-2'
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to update status of application?'
                            )
                          ) {
                            dispatch(
                              updateEvaluators({
                                dcID: userCredentials.id,
                                id: state.id,
                                tradesEvaluators: '',
                                isEditting: editEvaluator.isEditting,
                                updateValues: evalUpdateValue,
                              })
                            )
                            setModal({ evaluators: true })
                            setShowEvaluator(false)
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant='danger'
                        size='sm'
                        onClick={() =>
                          setEditEvaluator({
                            ...editEvaluator,
                            mechanical: true,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
              </>
            )}

            {state.Architectural === 1 && (
              <>
                <Row>
                  <Col xl='11'>
                    <h5>ARCHITECTURAL EVALUATOR</h5>
                  </Col>
                  <Col
                    xl='1'
                    className='text-end'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setEditEvaluator({
                        isEditting: true,
                        architectural: false,
                        structural: true,
                        mechanical: true,
                        sanitary: true,
                        electrical: true,
                        plumbing: true,
                        electronics: true,
                      })
                    }
                  >
                    <FaPencilAlt className='text-success' />
                  </Col>
                </Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    as='select'
                    className='my-2'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    name='Architectural'
                    required
                    value={tradesEvaluators[2][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.architectural}
                  >
                    <option value=''>choose evaluator here . . .</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Architectural' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                </Form.Group>
                {submittedEvaluatorList &&
                  submittedEvaluatorList.message &&
                  editEvaluator.architectural === false && (
                    <div className='d-flex justify-content-center mb-1'>
                      <Button
                        variant='info'
                        size='sm'
                        className='mx-2'
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to update status of application?'
                            )
                          ) {
                            dispatch(
                              updateEvaluators({
                                dcID: userCredentials.id,
                                id: state.id,
                                tradesEvaluators: '',
                                isEditting: editEvaluator.isEditting,
                                updateValues: evalUpdateValue,
                              })
                            )
                            setModal({ evaluators: true })
                            setShowEvaluator(false)
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant='danger'
                        size='sm'
                        onClick={() =>
                          setEditEvaluator({
                            ...editEvaluator,
                            architectural: true,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
              </>
            )}

            {(state.ApplicationName === 'FENCING PERMIT' ||
              state.ApplicationName === 'DEMOLITION PERMIT') && (
              <>
                <Row>
                  <Col xl='11'>
                    <h5>ARCHITECTURAL EVALUATOR</h5>
                  </Col>
                  <Col
                    xl='1'
                    className='text-end'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setEditEvaluator({
                        isEditting: true,
                        architectural: false,
                        structural: true,
                        mechanical: true,
                        sanitary: true,
                        electrical: true,
                        plumbing: true,
                        electronics: true,
                      })
                    }
                  >
                    <FaPencilAlt className='text-success' />
                  </Col>
                </Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    as='select'
                    className='my-2'
                    name='Architectural'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={tradesEvaluators[2][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.architectural}
                  >
                    <option value=''>choose evaluator here . . .</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Architectural' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                </Form.Group>
                {submittedEvaluatorList &&
                  submittedEvaluatorList.message &&
                  editEvaluator.architectural === false && (
                    <div className='d-flex justify-content-center mb-1'>
                      <Button
                        variant='info'
                        size='sm'
                        className='mx-2'
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to update status of application?'
                            )
                          ) {
                            dispatch(
                              updateEvaluators({
                                dcID: userCredentials.id,
                                id: state.id,
                                tradesEvaluators: '',
                                isEditting: editEvaluator.isEditting,
                                updateValues: evalUpdateValue,
                              })
                            )
                            setModal({ evaluators: true })
                            setShowEvaluator(false)
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant='danger'
                        size='sm'
                        onClick={() =>
                          setEditEvaluator({
                            ...editEvaluator,
                            architectural: true,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
              </>
            )}

            {state.ApplicationName === 'ELECTRICAL PERMIT' && (
              <>
                <Row>
                  <Col xl='11'>
                    <h5>ELECTRICAL EVALUATOR</h5>
                  </Col>
                  <Col
                    xl='1'
                    className='text-end'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setEditEvaluator({
                        isEditting: true,
                        electrical: false,
                        structural: true,
                        mechanical: true,
                        architectural: true,
                        sanitary: true,
                        plumbing: true,
                        electronics: true,
                      })
                    }
                  >
                    <FaPencilAlt className='text-success' />
                  </Col>
                </Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    as='select'
                    className='my-2'
                    name='Electrical'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={tradesEvaluators[4][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.sanitary}
                  >
                    <option value=''>choose evaluator here . . .</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Electrical' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                </Form.Group>
                {submittedEvaluatorList &&
                  submittedEvaluatorList.message &&
                  editEvaluator.electrical === false && (
                    <div className='d-flex justify-content-center mb-1'>
                      <Button
                        variant='info'
                        size='sm'
                        className='mx-2'
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to update status of application?'
                            )
                          ) {
                            dispatch(
                              updateEvaluators({
                                dcID: userCredentials.id,
                                id: state.id,
                                tradesEvaluators: '',
                                isEditting: editEvaluator.isEditting,
                                updateValues: evalUpdateValue,
                              })
                            )
                            setModal({ evaluators: true })
                            setShowEvaluator(false)
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant='danger'
                        size='sm'
                        onClick={() =>
                          setEditEvaluator({
                            ...editEvaluator,
                            electrical: true,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
              </>
            )}

            {state.Sanitary === 1 && (
              <>
                <Row>
                  <Col xl='11'>
                    <h5>SANITARY EVALUATOR</h5>
                  </Col>
                  <Col
                    xl='1'
                    className='text-end'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setEditEvaluator({
                        isEditting: true,
                        sanitary: false,
                        structural: true,
                        mechanical: true,
                        architectural: true,
                        electrical: true,
                        plumbing: true,
                        electronics: true,
                      })
                    }
                  >
                    <FaPencilAlt className='text-success' />
                  </Col>
                </Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    as='select'
                    className='my-2'
                    name='Sanitary'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={tradesEvaluators[3][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.sanitary}
                  >
                    <option value=''>choose evaluator here . . .</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Sanitary' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                </Form.Group>
                {submittedEvaluatorList &&
                  submittedEvaluatorList.message &&
                  editEvaluator.sanitary === false && (
                    <div className='d-flex justify-content-center mb-1'>
                      <Button
                        variant='info'
                        size='sm'
                        className='mx-2'
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to update status of application?'
                            )
                          ) {
                            dispatch(
                              updateEvaluators({
                                dcID: userCredentials.id,
                                id: state.id,
                                tradesEvaluators: '',
                                isEditting: editEvaluator.isEditting,
                                updateValues: evalUpdateValue,
                              })
                            )
                            setModal({ evaluators: true })
                            setShowEvaluator(false)
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant='danger'
                        size='sm'
                        onClick={() =>
                          setEditEvaluator({
                            ...editEvaluator,
                            sanitary: true,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
              </>
            )}

            {state.Electrical === 1 && (
              <>
                <Row>
                  <Col xl='11'>
                    <h5>ELECTRICAL EVALUATOR</h5>
                  </Col>
                  <Col
                    xl='1'
                    className='text-end'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setEditEvaluator({
                        isEditting: true,
                        electrical: false,
                        structural: true,
                        mechanical: true,
                        architectural: true,
                        sanitary: true,
                        plumbing: true,
                        electronics: true,
                      })
                    }
                  >
                    <FaPencilAlt className='text-success' />
                  </Col>
                </Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    as='select'
                    className='my-2'
                    name='Electrical'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={tradesEvaluators[4][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.electrical}
                  >
                    <option value=''>choose evaluator here . . .</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Electrical' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                </Form.Group>
                {submittedEvaluatorList &&
                  submittedEvaluatorList.message &&
                  editEvaluator.electrical === false && (
                    <div className='d-flex justify-content-center mb-1'>
                      <Button
                        variant='info'
                        size='sm'
                        className='mx-2'
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to update status of application?'
                            )
                          ) {
                            dispatch(
                              updateEvaluators({
                                dcID: userCredentials.id,
                                id: state.id,
                                tradesEvaluators: '',
                                isEditting: editEvaluator.isEditting,
                                updateValues: evalUpdateValue,
                              })
                            )
                            setModal({ evaluators: true })
                            setShowEvaluator(false)
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant='danger'
                        size='sm'
                        onClick={() =>
                          setEditEvaluator({
                            ...editEvaluator,
                            electrical: true,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
              </>
            )}

            {state.Plumbing === 1 && (
              <>
                <Row>
                  <Col xl='11'>
                    <h5>PLUMBING EVALUATOR</h5>
                  </Col>
                  <Col
                    xl='1'
                    className='text-end'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setEditEvaluator({
                        isEditting: true,
                        plumbing: false,
                        structural: true,
                        mechanical: true,
                        architectural: true,
                        sanitary: true,
                        electrical: true,
                        electronics: true,
                      })
                    }
                  >
                    <FaPencilAlt className='text-success' />
                  </Col>
                </Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    as='select'
                    className='my-2'
                    name='Plumbing'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={tradesEvaluators[5][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.plumbing}
                  >
                    <option value=''>choose evaluator here . . .</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Plumbing' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                </Form.Group>
                {submittedEvaluatorList &&
                  submittedEvaluatorList.message &&
                  editEvaluator.plumbing === false && (
                    <div className='d-flex justify-content-center mb-1'>
                      <Button
                        variant='info'
                        size='sm'
                        className='mx-2'
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to update status of application?'
                            )
                          ) {
                            dispatch(
                              updateEvaluators({
                                dcID: userCredentials.id,
                                id: state.id,
                                tradesEvaluators: '',
                                isEditting: editEvaluator.isEditting,
                                updateValues: evalUpdateValue,
                              })
                            )
                            setModal({ evaluators: true })
                            setShowEvaluator(false)
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant='danger'
                        size='sm'
                        onClick={() =>
                          setEditEvaluator({
                            ...editEvaluator,
                            plumbing: true,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
              </>
            )}

            {state.Electronics === 1 && (
              <>
                <Row>
                  <Col xl='11'>
                    <h5>ELECTRONICS EVALUATOR</h5>
                  </Col>
                  <Col
                    xl='1'
                    className='text-end'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setEditEvaluator({
                        isEditting: true,
                        electronics: false,
                        plumbing: true,
                        structural: true,
                        mechanical: true,
                        architectural: true,
                        sanitary: true,
                        electrical: true,
                      })
                    }
                  >
                    <FaPencilAlt className='text-success' />
                  </Col>
                </Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    as='select'
                    className='my-2'
                    name='Electronics'
                    // value={payload.Link}
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={tradesEvaluators[6][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.electronics}
                  >
                    <option value=''>choose evaluator here . . .</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Electronics' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                </Form.Group>
                {submittedEvaluatorList &&
                  submittedEvaluatorList.message &&
                  editEvaluator.electronics === false && (
                    <div className='d-flex justify-content-center mb-1'>
                      <Button
                        variant='info'
                        size='sm'
                        className='mx-2'
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to update status of application?'
                            )
                          ) {
                            dispatch(
                              updateEvaluators({
                                dcID: userCredentials.id,
                                id: state.id,
                                tradesEvaluators: '',
                                isEditting: editEvaluator.isEditting,
                                updateValues: evalUpdateValue,
                              })
                            )
                            setModal({ evaluators: true })
                            setShowEvaluator(false)
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant='danger'
                        size='sm'
                        onClick={() =>
                          setEditEvaluator({
                            ...editEvaluator,
                            electronics: true,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
              </>
            )}

            <hr />
            <Row>
              <Col xl={12} className='d-flex justify-content-end'>
                <Button type='submit' variant='info' className='mx-2'>
                  SUBMIT
                </Button>
                <Button
                  onClick={() => {
                    setShowEvaluator(false)
                  }}
                  variant='danger'
                  className='mx-2'
                >
                  BACK
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* End Evaluators Information */}

      {/*Inspector Information */}
      <Modal
        size='lg'
        show={showInspector}
        onHide={() => setShowInspector(false)}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            Choose Inspector for the Permit/s
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              dispatch(
                updateInspector({
                  id: state.id,
                  dcID: state.dcID,
                  inspectionSched: state.inspectionSched,
                  inspectID: state.inspectID,
                })
              )
              setModal({ inspector: true })
              setShowInspector(false)
            }}
          >
            <h5>Inspector</h5>
            <Row className='my-2'>
              <Form.Group as={Col} xl={12}>
                <Form.Control
                  as='select'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  required
                  onChange={handleChange}
                  name='inspectID'
                  value={state.inspectID}
                >
                  <option value={0}>Choose inspector here . . . </option>
                  {inspectionList &&
                    inspectionList.message &&
                    inspectionList.message.map((el) => (
                      <option key={el.EmployeeID} value={el.EmployeeID}>
                        {el.EmployeeName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Row>

            <h5>Inspection Schedule</h5>
            <Row className='my-2'>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  required
                  className='mt-2'
                  type='date'
                  name='inspectionSched'
                  value={state.inspectionSched}
                  min={appDate}
                  onKeyDown={(e) => e.preventDefault()}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col xl={12} className='d-flex justify-content-end'>
                <Button type='submit' variant='info' className='mx-2'>
                  SUBMIT
                </Button>
                <Button
                  onClick={() => {
                    setShowInspector(false)
                  }}
                  variant='danger'
                  className='mx-2'
                >
                  BACK
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* End Inspector Information */}

      {/*Order of Payment */}
      <Modal
        size='lg'
        show={orderOfPayment}
        onHide={() => setOrderOfPayment(false)}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' />
            CREATE ORDER OF PAYMENT
          </Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            submittedOrderofPayment && submittedOrderofPayment.message
              ? handleUpdateOrderOfPayment(e)
              : handleSubmitOrderOfPayment(e)
          }}
        >
          <Modal.Body>
            <Row className='py-2'>
              <Col xl={4}>Application No.:</Col>
              <Col xl={8} className='text-center'>
                {match.params.id}
              </Col>
            </Row>
            <Row className='py-2'>
              <Col xl={4}>Applicant Name:</Col>
              <Col xl={8} className='text-center'>
                {state.FullName}
              </Col>
            </Row>
            <Row className='py-2'>
              <Col xl={4}>Applicantion Name:</Col>
              <Col xl={8} className='text-center'>
                {state.ApplicationName}
              </Col>
            </Row>

            <Row className='py-2'>
              <Col xl={4} className='d-flex align-items-center'>
                PERMIT CODE:
              </Col>
              <Col xl={8} className='text-center'>
                <Form.Control
                  type='text'
                  className='border border-secondary align-middle'
                  name='permitCode'
                  placeholder='BP 12345'
                  onChange={(e) => setPermitCode(e.target.value)}
                  value={permitCode}
                  required
                />
              </Col>
            </Row>
            {_.zip(
              [
                'Line and Grade',
                'Building Permit Fees',
                'Additional/Renovation',
                'Roof Deck',
                'Demolition Permit Fees',
                'Ancillary Structure',
                'Construction of Tombs/Canopies',
                'Plumbing Permit Fees',
                'Electrical Permit Fees',
                'Mechanical Permit Fees',
                'Fencing Permit Fees',
                'Construction of Sidewalks',
                'Paved Areas',
                'Use of Street and Sidewalks',
                'Certificate of Use/Occupancy',
                'Change in Use/Occupancy',
                'Certificate of Occupancy prior to 10',
                'Electronics Permit',
                'Annual Inspection',
                'Sign Permit Fees',
                'Certification',
                'Penalty/Surcharge',
                'Administrative Fine',
              ],
              [
                'lineAndGrade',
                'building',
                'additional',
                'roofDeck',
                'demolition',
                'ancillary',
                'constructionOfTombs',
                'plumbing',
                'electrical',
                'mechanical',
                'fencing',
                'constructionOfSidewalks',
                'pavedAreas',
                'useOfStreetSidewalks',
                'certificateOfUseOccupancy',
                'changeInUseOccupancy',
                'certificate10',
                'electronics',
                'annual',
                'signPermit',
                'certification',
                'penalty',
                'administrativeFine',
              ],
              [
                nationalCode.lineAndGrade,
                nationalCode.building,
                nationalCode.additional,
                nationalCode.roofDeck,
                nationalCode.demolition,
                nationalCode.ancillary,
                nationalCode.constructionOfTombs,
                nationalCode.plumbing,
                nationalCode.electrical,
                nationalCode.mechanical,
                nationalCode.fencing,
                nationalCode.constructionOfSidewalks,
                nationalCode.pavedAreas,
                nationalCode.useOfStreetSidewalks,
                nationalCode.certificateOfUseOccupancy,
                nationalCode.changeInUseOccupancy,
                nationalCode.certificate10,
                nationalCode.electronics,
                nationalCode.annual,
                nationalCode.signPermit,
                nationalCode.certification,
                nationalCode.penalty,
                nationalCode.administrativeFine,
              ]
            ).map((element, index) => (
              <Row key={index} className='py-2'>
                <Col xl={4} className='d-flex align-items-center'>
                  {element[0] + ':'}
                </Col>
                <Col xl={8} className='text-center'>
                  <Form.Control
                    type='number'
                    className='border border-secondary align-middle'
                    name={element[1]}
                    value={
                      submittedOrderofPayment &&
                      submittedOrderofPayment.message &&
                      element[2]
                    }
                    placeholder='1000'
                    min={0}
                    onKeyDown={(e) =>
                      e.target.value.length > 14 && e.preventDefault()
                    }
                    step={0.01}
                    onChange={(e) => handleChangeNational(e)}
                  />
                </Col>
              </Row>
            ))}
            <Row className='py-2'>
              <Col
                xl={4}
                className='fw-bold d-flex align-items-center justify-content-end'
              >
                SUB-TOTAL
              </Col>
              <Col xl={8} className='text-center fs-4'>
                &#8369; {nationalSum.toLocaleString()}
              </Col>
            </Row>
            <hr />
            <Row className='py-2'>
              <Col xl={4} className='d-flex align-items-center'>
                Filling Fee
              </Col>
              <Col xl={8} className='text-center'>
                300.00
              </Col>
            </Row>
            <Row className='py-2'>
              <Col xl={4} className='d-flex align-items-center'>
                Processing Fee
              </Col>
              <Col xl={8} className='text-center'>
                750.00
              </Col>
            </Row>
            {_.zip(
              ['Complaint Filling Fee', 'Others (Specify)'],
              ['complaint', 'others'],
              [cityOrdinance.complaint, cityOrdinance.others]
            ).map((element, index) => (
              <Row key={index} className='py-2'>
                <Col xl={4} className='d-flex align-items-center'>
                  {element[0] + ':'}
                </Col>
                <Col xl={8} className='text-center'>
                  <Form.Control
                    type='number'
                    className='border border-secondary align-middle'
                    name={element[1]}
                    value={
                      submittedOrderofPayment &&
                      submittedOrderofPayment.message &&
                      element[2]
                    }
                    placeholder='1000'
                    min={0}
                    onKeyDown={(e) =>
                      e.target.value.length > 14 && e.preventDefault()
                    }
                    step={0.01}
                    onChange={(e) => handleChangeCity(e)}
                  />
                </Col>
              </Row>
            ))}
            <Row className='py-2'>
              <Col
                xl={4}
                className='fw-bold d-flex align-items-center justify-content-end'
              >
                SUB-TOTAL
              </Col>
              <Col xl={8} className='text-center fs-4'>
                &#8369; {citySum.toLocaleString()}
              </Col>
            </Row>
            <hr />

            <Row className='py-2'>
              <Col
                xl={4}
                className='fw-bold d-flex align-items-center justify-content-end'
              >
                <h4>TOTAL AMOUNT</h4>
              </Col>
              <Col xl={8} className='text-center fs-3'>
                &#8369; {OpTotalAmount.toLocaleString()}
              </Col>
            </Row>
            <hr />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              type='button'
              onClick={() => {
                setOrderOfPayment(false)
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* End Order of Payment */}

      {/* Change Status Success Modal */}
      <SuccessModal
        show={modal.changeStatus}
        onHide={() => {
          setModal({ changeStatus: false })
          // window.location.reload(true)
        }}
        transLoading={updatedStatus && updatedStatus.loading}
        success={updatedStatus && updatedStatus.message}
        error={updatedStatus && updatedStatus.error}
      />
      {/* End Success Modal */}

      {/*Update Docs Success Modal */}
      <SuccessModal
        show={modal.updateDocs}
        onHide={() => {
          setModal({ updateDocs: false })
          // window.location.reload(true)
        }}
        transLoading={updateRequirements && updateRequirements.loading}
        success={updateRequirements && updateRequirements.message}
        error={updateRequirements && updateRequirements.error}
      />
      {/* End Success Modal */}

      {/*Submit Required Docs Success Modal */}
      <SuccessModal
        show={modal.addDocs}
        onHide={() => {
          setModal({ addDocs: false })
          // window.location.reload(true)
        }}
        transLoading={updateRequirements && updateRequirements.loading}
        success={updateRequirements && updateRequirements.message}
        error={updateRequirements && updateRequirements.error}
      />
      {/* End Success Modal */}

      {/*Engineer Info Success Modal */}
      <SuccessModal
        show={modal.engineer}
        onHide={() => {
          setModal({ engineer: false })
          // window.location.reload(true)
        }}
        transLoading={
          updatedEngineerInformation && updatedEngineerInformation.loading
        }
        success={
          updatedEngineerInformation && updatedEngineerInformation.message
        }
        error={updatedEngineerInformation && updatedEngineerInformation.error}
      />
      {/* End Success Modal */}

      {/*Submit Evaluators Success Modal */}
      <SuccessModal
        show={modal.evaluators}
        onHide={() => {
          setModal({ evaluators: false })
          setTradesEvaluators([
            ['Structural', 0],
            ['Mechanical', 0],
            ['Architectural', 0],
            ['Sanitary', 0],
            ['Electrical', 0],
            ['Plumbing', 0],
            ['Electronics', 0],
          ])
          // window.location.reload(true)
        }}
        transLoading={updatedEvaluators && updatedEvaluators.loading}
        success={updatedEvaluators && updatedEvaluators.message}
        error={updatedEvaluators && updatedEvaluators.error}
      />
      {/* End Success Modal */}

      {/*Submit Inspector Success Modal */}
      <SuccessModal
        show={modal.inspector}
        onHide={() => {
          setModal({ inspector: false })
          // window.location.reload(true)
        }}
        transLoading={updatedInspector && updatedInspector.loading}
        success={updatedInspector && updatedInspector.message}
        error={updatedInspector && updatedInspector.error}
      />
      {/* End Success Modal */}

      {/*Insert OP Success Modal */}
      <SuccessModal
        show={modal.insertOP}
        onHide={() => {
          setModal({ insertOP: false })
          // window.location.reload(true)
        }}
        transLoading={insertedOrderPayment && insertedOrderPayment.loading}
        success={insertedOrderPayment && insertedOrderPayment.message}
        error={insertedOrderPayment && insertedOrderPayment.error}
      />
      {/* End Success Modal */}

      {/*Update OP Success Modal */}
      <SuccessModal
        show={modal.updateOP}
        onHide={() => {
          setModal({ updateOP: false })
          // window.location.reload(true)
        }}
        transLoading={updatedOrderOfPayment && updatedOrderOfPayment.loading}
        success={updatedOrderOfPayment && updatedOrderOfPayment.message}
        error={updatedOrderOfPayment && updatedOrderOfPayment.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(ManageApplication)
