export const GET_FORAPPROVAL_REQ = 'GET_FORAPPROVAL_REQ'
export const GET_FORAPPROVAL_SUCCESS = 'GET_FORAPPROVAL_SUCCESS'
export const GET_FORAPPROVAL_FAILED = 'GET_FORAPPROVAL_FAILED'

export const GET_APPROVEDAPPLICATIONS_REQ = 'GET_APPROVEDAPPLICATIONS_REQ'
export const GET_APPROVEDAPPLICATIONS_SUCCESS =
  'GET_APPROVEDAPPLICATIONS_SUCCESS'
export const GET_APPROVEDAPPLICATIONS_FAILED = 'GET_APPROVEDAPPLICATIONS_FAILED'

export const GET_APPLICATIONSLIST_REQ = 'GET_APPLICATIONSLIST_REQ'
export const GET_APPLICATIONSLIST_SUCCESS = 'GET_APPLICATIONSLIST_SUCCESS'
export const GET_APPLICATIONSLIST_FAILED = 'GET_APPLICATIONSLIST_FAILED'

export const GET_COMMENTSLIST_REQ = 'GET_COMMENTSLIST_REQ'
export const GET_COMMENTSLIST_SUCCESS = 'GET_COMMENTSLIST_SUCCESS'
export const GET_COMMENTSLIST_FAILED = 'GET_COMMENTSLIST_FAILED'

export const GET_ORDEROFPAYMENT_REQ = 'GET_ORDEROFPAYMENT_REQ'
export const GET_ORDEROFPAYMENT_SUCCESS = 'GET_ORDEROFPAYMENT_SUCCESS'
export const GET_ORDEROFPAYMENT_FAILED = 'GET_ORDEROFPAYMENT_FAILED'

export const GET_FORAPPROVALOP_REQ = 'GET_FORAPPROVALOP_REQ'
export const GET_FORAPPROVALOP_SUCCESS = 'GET_FORAPPROVALOP_SUCCESS'
export const GET_FORAPPROVALOP_FAILED = 'GET_FORAPPROVALOP_FAILED'

export const GET_PERMITNO_REQ = 'GET_PERMITNO_REQ'
export const GET_PERMITNO_SUCCESS = 'GET_PERMITNO_SUCCESS'
export const GET_PERMITNO_FAILED = 'GET_PERMITNO_FAILED'

export const GET_EMPLOYEELIST_REQ = 'GET_EMPLOYEELIST_REQ'
export const GET_EMPLOYEELIST_SUCCESS = 'GET_EMPLOYEELIST_SUCCESS'
export const GET_EMPLOYEELIST_FAILED = 'GET_EMPLOYEELIST_FAILED'

export const GET_EMPLOYEEINFO_REQ = 'GET_EMPLOYEEINFO_REQ'
export const GET_EMPLOYEEINFO_SUCCESS = 'GET_EMPLOYEEINFO_SUCCESS'
export const GET_EMPLOYEEINFO_FAILED = 'GET_EMPLOYEEINFO_FAILED'

export const GET_USERLIST_REQ = 'GET_USERLIST_REQ'
export const GET_USERLIST_SUCCESS = 'GET_USERLIST_SUCCESS'
export const GET_USERLIST_FAILED = 'GET_USERLIST_FAILED'

export const GET_VALIDID_REQ = 'GET_VALIDID_REQ'
export const GET_VALIDID_SUCCESS = 'GET_VALIDID_SUCCESS'
export const GET_VALIDID_FAILED = 'GET_VALIDID_FAILED'

export const GET_ANNUALCOLLECTION_REQ = 'GET_ANNUALCOLLECTION_REQ'
export const GET_ANNUALCOLLECTION_SUCCESS = 'GET_ANNUALCOLLECTION_SUCCESS'
export const GET_ANNUALCOLLECTION_FAILED = 'GET_ANNUALCOLLECTION_FAILED'

export const UPDATE_APPLICATION_STATUS_REQ = 'UPDATE_APPLICATION_STATUS_REQ'
export const UPDATE_APPLICATION_STATUS_SUCCESS =
  'UPDATE_APPLICATION_STATUS_SUCCESS'
export const UPDATE_APPLICATION_STATUS_FAILED =
  'UPDATE_APPLICATION_STATUS_FAILED'

export const UPDATE_VERIFYUSER_REQ = 'UPDATE_VERIFYUSER_REQ'
export const UPDATE_VERIFYUSER_SUCCESS = 'UPDATE_VERIFYUSER_SUCCESS'
export const UPDATE_VERIFYUSER_FAILED = 'UPDATE_VERIFYUSER_FAILED'

export const UPDATE_EMPLOYEE_INFO_REQ = 'UPDATE_EMPLOYEE_INFO_REQ'
export const UPDATE_EMPLOYEE_INFO_SUCCESS = 'UPDATE_EMPLOYEE_INFO_SUCCESS'
export const UPDATE_EMPLOYEE_INFO_FAILED = 'UPDATE_EMPLOYEE_INFO_FAILED'

export const INSERT_EMPLOYEE_INFO_REQ = 'INSERT_EMPLOYEE_INFO_REQ'
export const INSERT_EMPLOYEE_INFO_SUCCESS = 'INSERT_EMPLOYEE_INFO_SUCCESS'
export const INSERT_EMPLOYEE_INFO_FAILED = 'INSERT_EMPLOYEE_INFO_FAILED'

export const DELETE_EMPLOYEE_INFO_REQ = 'DELETE_EMPLOYEE_INFO_REQ'
export const DELETE_EMPLOYEE_INFO_SUCCESS = 'DELETE_EMPLOYEE_INFO_SUCCESS'
export const DELETE_EMPLOYEE_INFO_FAILED = 'DELETE_EMPLOYEE_INFO_FAILED'
