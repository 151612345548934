import {
  GET_FOR_EVALUATION_REQ,
  GET_FOR_EVALUATION_SUCCESS,
  GET_FOR_EVALUATION_FAILED,
  GET_EVALUATED_LIST_REQ,
  GET_EVALUATED_LIST_SUCCESS,
  GET_EVALUATED_LIST_FAILED,
  CREATE_EVALUATION_REQ,
  CREATE_EVALUATION_SUCCESS,
  CREATE_EVALUATION_FAILED,
  UPDATE_EVALUATION_REQ,
  UPDATE_EVALUATION_SUCCESS,
  UPDATE_EVALUATION_FAILED,
  GET_EVALUATION_DETAILS_REQ,
  GET_EVALUATION_DETAILS_SUCCESS,
  GET_EVALUATION_DETAILS_FAILED,
  GET_APPDETAILS_REQ,
  GET_APPDETAILS_SUCCESS,
  GET_APPDETAILS_FAILED,
  GET_COMMENTLIST_REQ,
  GET_COMMENTLIST_SUCCESS,
  GET_COMMENTLIST_FAILED,
  INSERT_COMMENT_REQ,
  INSERT_COMMENT_SUCCESS,
  INSERT_COMMENT_FAILED,
} from '../constants/evaluatorConstants'

export const evaluatorReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_FOR_EVALUATION_REQ:
      return {
        ...state,
        forEvaluation: { loading: true },
      }
    case GET_FOR_EVALUATION_SUCCESS:
      return {
        ...state,
        forEvaluation: { loading: false, message: action.payload },
      }
    case GET_FOR_EVALUATION_FAILED:
      return {
        ...state,
        forEvaluation: { loading: false, error: action.payload },
      }
    case GET_COMMENTLIST_REQ:
      return {
        ...state,
        commentList: { loading: true },
      }
    case GET_COMMENTLIST_SUCCESS:
      return {
        ...state,
        commentList: { loading: false, message: action.payload },
      }
    case GET_COMMENTLIST_FAILED:
      return {
        ...state,
        commentList: { loading: false, error: action.payload },
      }
    case GET_EVALUATED_LIST_REQ:
      return {
        ...state,
        evaluatedList: { loading: true },
      }
    case GET_EVALUATED_LIST_SUCCESS:
      return {
        ...state,
        evaluatedList: { loading: false, message: action.payload },
      }
    case GET_EVALUATED_LIST_FAILED:
      return {
        ...state,
        evaluatedList: { loading: false, error: action.payload },
      }
    case CREATE_EVALUATION_REQ:
      return {
        ...state,
        createEvaluation: { loading: true },
      }
    case CREATE_EVALUATION_SUCCESS:
      return {
        ...state,
        createEvaluation: { loading: false, message: action.payload },
      }
    case CREATE_EVALUATION_FAILED:
      return {
        ...state,
        createEvaluation: { loading: false, error: action.payload },
      }
    case INSERT_COMMENT_REQ:
      return {
        ...state,
        createdComment: { loading: true },
      }
    case INSERT_COMMENT_SUCCESS:
      return {
        ...state,
        createdComment: { loading: false, message: action.payload },
      }
    case INSERT_COMMENT_FAILED:
      return {
        ...state,
        createdComment: { loading: false, error: action.payload },
      }
    case GET_EVALUATION_DETAILS_REQ:
      return {
        ...state,
        evaluationDetails: { loading: true },
      }
    case GET_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        evaluationDetails: { loading: false, message: action.payload },
      }
    case GET_EVALUATION_DETAILS_FAILED:
      return {
        ...state,
        evaluationDetails: { loading: false, error: action.payload },
      }
    case GET_APPDETAILS_REQ:
      return {
        ...state,
        getAppDetails: { loading: true },
      }
    case GET_APPDETAILS_SUCCESS:
      return {
        ...state,
        getAppDetails: { loading: false, message: action.payload },
      }
    case GET_APPDETAILS_FAILED:
      return {
        ...state,
        getAppDetails: { loading: false, error: action.payload },
      }
    case UPDATE_EVALUATION_REQ:
      return {
        ...state,
        updateEvaluation: { loading: true },
      }
    case UPDATE_EVALUATION_SUCCESS:
      return {
        ...state,
        updateEvaluation: { loading: false, message: action.payload },
      }
    case UPDATE_EVALUATION_FAILED:
      return {
        ...state,
        updateEvaluation: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
