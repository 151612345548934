import React, { useState, useEffect } from 'react'
import { Pagination, Row, Col, Table, Button } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa'
import _ from 'lodash'

// Layout
import Loading from './Loading'
import Error from './Error'

const NewsNUpdatesColumn = ({ newsDetails, handleViewNewsNUpdates }) => {
  // Pagination Area

  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)

  const pageSize = 5

  useEffect(() => {
    setpaginatedForms(
      _(newsDetails && newsDetails.message)
        .slice(0)
        .take(pageSize)
        .value()
    )
    setcurrentPage(1)
  }, [newsDetails])

  const pageCount =
    newsDetails && newsDetails.message
      ? Math.ceil(newsDetails.message.length / pageSize)
      : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    const paginationForms = _(newsDetails && newsDetails.message)
      .slice(startIndex)
      .take(pageSize)
      .value()
    setpaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }

  // console.log(newsDetails)
  return (
    <>
      {newsDetails && newsDetails.loading && (
        <div>
          <Loading />
        </div>
      )}

      {newsDetails && newsDetails.message && (
        <Row className='mx-2 mt-3 mb-2 justify-content-center'>
          <Col
            xl={12}
            className='border border-secondary rounded px-3 shadow-lg'
            style={{ background: '#FFF' }}
          >
            <h5 className='pt-4 pb-2 mb-0'>News & Updates</h5>
            <hr
              className='mt-1 mb-3'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <Row>
              <Col md={12}>
                <Table bordered striped hover responsive>
                  <thead>
                    <tr>
                      <th>Header</th>
                      <th>SubHeader</th>
                      <th className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedForms.map((messages) => (
                      <tr key={messages.id}>
                        <td>{messages.header}</td>
                        <td>{messages.subheader}</td>
                        <td className='text-center'>
                          <Button
                            variant='success'
                            onClick={() => handleViewNewsNUpdates(messages)}
                          >
                            <FaEye />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Col xl={12} className='py-2 d-flex justify-content-end'>
              <Pagination size='sm' style={{ marginRight: '10px' }}>
                <Pagination.Item
                  onClick={handlePrevbtn}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  &lt;
                </Pagination.Item>

                {renderPageNumber}

                <Pagination.Item
                  onClick={handleNextbtn}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                >
                  &gt;
                </Pagination.Item>
              </Pagination>
            </Col>
          </Col>
        </Row>
      )}

      {newsDetails && newsDetails.error && (
        <div
          className='my-2 shadow-lg pb-5 px-2 rounded mx-2'
          style={{ background: '#FFF' }}
        >
          <h5 className='pt-4 pb-2 mb-0'>Messages</h5>
          <hr
            className='mt-1 mb-3'
            style={{
              background: '#ffeb36',
              paddingBottom: '2px',
              width: '200px',
            }}
          />
          <Error message={newsDetails.error} />
        </div>
      )}
    </>
  )
}

export default NewsNUpdatesColumn
