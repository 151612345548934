import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_DC_TIME_PROCESS,
  GET_EVALUATORS_TIME_PROCESS,
  GET_INSPECTOR_TIME_PROCESS,
  GET_TRADES_INFO_REQ,
  GET_TRADES_INFO_SUCCESS,
  GET_TRADES_INFO_FAILED,
} from '../constants/routingSlipConstants'

export const retrieveDcProcessTime = (payload) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/application-dc-process-time/${payload.appID}`,
        config
      )

      dispatch({
        type: GET_DC_TIME_PROCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_DC_TIME_PROCESS,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveEvaluatorProcessTime = (payload) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/application-evaluator-process-time/${payload.appID}`,
        config
      )

      dispatch({
        type: GET_EVALUATORS_TIME_PROCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_EVALUATORS_TIME_PROCESS,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveInspectorProcessTime = (payload) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/application-inspector-process-time/${payload.appID}`,
        config
      )

      dispatch({
        type: GET_INSPECTOR_TIME_PROCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_INSPECTOR_TIME_PROCESS,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveTradesInformation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_TRADES_INFO_REQ,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/routing-slip/trades-information/${payload.appID}`,
        config
      )

      dispatch({
        type: GET_TRADES_INFO_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_TRADES_INFO_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}
