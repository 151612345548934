import axios from 'axios'
import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import {
  FaEnvelope,
  FaPhoneAlt,
  FaMobileAlt,
  FaMapMarkerAlt,
} from 'react-icons/fa'
import ReCAPTCHA from 'react-google-recaptcha'

// Redux Requirements
import { useDispatch, useSelector } from 'react-redux'
import { messageInsert } from '../redux/actions/messageActions'

// Components
import GuestMain from '../layout/GuestLayout/GuestMain'
import SuccessModal from '../reusable/SuccessModal'

const ContactUsScreen = ({ background, border, history, location }) => {
  const dispatch = useDispatch()

  const [successShow, setSuccessShow] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [successInfo, setSuccessInfo] = useState('')
  const [failedInfo, setFailedInfo] = useState('')

  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const message = useSelector((state) => state.messages)
  const { messageDetail, loading, error } = message

  const redirect = location.search
    ? location.search.split('=')[1]
    : '/dashboard'

  useEffect(() => {
    if (userCredentials) {
      history.push(redirect)
    }
  }, [userCredentials, history, redirect])

  const [messages, setMessages] = useState({
    name: '',
    email: '',
    contactNo: '',
    message: '',
    image: null,
  })

  const [token, setToken] = useState('')
  const reCaptcha = useRef()

  const handleChange = (e) => {
    setMessages({
      ...messages,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    if (!token) {
      alert('You must verify the captcha')
    } else {
      e.preventDefault()

      if (window.confirm('Are you sure wanted to submit this message?')) {
        // dispatch(messageInsert(messages))

        // console.log(messages)

        const formData = new FormData()
        formData.append('image', messages.image)
        formData.append('name', messages.name)
        formData.append('email', messages.email)
        formData.append('contactNo', messages.contactNo)
        formData.append('message', messages.message)

        try {
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }

          const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/message/add-image`,
            formData,
            config
          )

          // setImage(data)
          setSuccessShow(true)
          setSuccessInfo(data.message)
          setUploading(false)
          e.preventDefault()
          setMessages({
            name: '',
            email: '',
            contactNo: '',
            message: '',
            image: null,
          })
        } catch (err) {
          e.preventDefault()
          setSuccessShow(true)
          setFailedInfo(
            `Kindly Check your Image either the file size and file format. (Ex. 25MB size only. jpg, jpeg, png format only.)`
          )
          //console.log(err)
          setUploading(false)
        }
      }
    }
  }

  const handleUploadImage = async (e) => {
    const file = e.target.files[0]
    // const formData = new FormData()
    // formData.append('image', file)
    setMessages({ ...messages, image: file })

    // setUploading(true)

    // try {
    //   const config = {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   }

    //   const { data } = await axios.post('${process.env.REACT_APP_API_URL}/upload', formData, config)

    //   // setImage(data)
    //   setUploading(false)
    // } catch (err) {
    //   console.log(error)
    //   setUploading(false)
    // }
  }

  return (
    <GuestMain>
      <Container
        fluid
        style={{
          background: '#f9f6f1',
          color: '#00304b',
          backgroundImage: 'url(./assets/img/lp-bldgo-bg2.webp)',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        <Container className='bg-white shadow-sm px-4 pb-5'>
          <h3 className='text-center fw-bold mb-0' style={{ color: '#00304b' }}>
            Contact Us
          </h3>
          <div className='d-flex justify-content-center'>
            <hr
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
          </div>
          <div className='text-center bg-light py-2'>
            <p className='m-0'>
              Please let us know how we can serve you better. Fill in the
              required information below, or you can talk to us thru the
              available platforms.
            </p>
          </div>
          <Row>
            <Col sm={12} md={6}>
              <Container className='shadow-lg mt-3 py-5 px-5'>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Form.Group as={Col} md={12} className='py-2'>
                      <Form.Control
                        type='text'
                        placeholder='Your Name'
                        name='name'
                        value={messages.name}
                        onChange={handleChange}
                        style={{ background, border }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={12} className='py-2'>
                      <Form.Control
                        type='text'
                        placeholder='Enter email'
                        name='email'
                        value={messages.email}
                        onChange={handleChange}
                        style={{ background, border }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={12} className='py-2'>
                      <Form.Control
                        type='text'
                        placeholder='9123456789'
                        name='contactNo'
                        value={messages.contactNo}
                        maxLength={10}
                        onChange={handleChange}
                        style={{ background, border }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={12} className='py-2'>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        placeholder='Message'
                        name='message'
                        value={messages.message}
                        onChange={handleChange}
                        style={{ background, border, resize: 'none' }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.File
                        id='image-file'
                        size='sm'
                        onChange={handleUploadImage}
                      />
                      {uploading && <Spinner animation='border' />}
                    </Form.Group>
                    <h6 className='mt-2 mb-0'>
                      Note: "1 File Only. Max of 25MB"
                    </h6>

                    <ReCAPTCHA
                      className='my-2'
                      ref={reCaptcha}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={(token) => setToken(token)}
                      onExpired={(e) => setToken('')}
                      size='compact'
                    />
                  </Row>
                  <Button variant='success' type='submit'>
                    Submit
                  </Button>
                </Form>
              </Container>
            </Col>
            <Col sm={12} md={6}>
              {/* <div className='right-container'> */}
              <Container className='mt-3 py-5 px-5'>
                <Row>
                  <Col md={12}>
                    <h1 style={{ color: '#00304b' }}>
                      Getting in Touch is easy!
                    </h1>
                  </Col>
                </Row>
                <div className='d-flex my-5'>
                  <FaEnvelope
                    className='mx-2 fs-1'
                    style={{ color: '#0d3206' }}
                  />
                  <p className='mb-0 mt-3'>lpengineering.building@gmail.com</p>
                </div>

                <div className='d-flex my-5'>
                  <FaPhoneAlt
                    className='mx-2 fs-1'
                    style={{ color: '#0d3206' }}
                  />
                  <p className='mb-0 mt-3'>(02)8332-9660</p>
                </div>

                <div className='d-flex my-5'>
                  <FaMobileAlt
                    className='mx-2 fs-1'
                    style={{ color: '#0d3206' }}
                  />
                  <p className='mb-0 mt-3'>
                    +63942-388-2557 || +63905-454-6958
                  </p>
                </div>
                <div className='d-flex my-5'>
                  <FaMapMarkerAlt
                    className='mx-2 fs-1'
                    style={{ color: '#0d3206' }}
                  />
                  <p className='mb-0 mt-3'>
                    Alabang-Zapote Rd, Las Piñas NCR Philippines
                  </p>
                </div>
              </Container>
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Change Status Success Modal */}
      <SuccessModal
        show={successShow}
        onHide={() => {
          setSuccessShow(false)
          window.location.reload()
        }}
        transLoading={uploading}
        success={successInfo}
        error={failedInfo}
      />
      {/* End Success Modal */}
    </GuestMain>
  )
}

ContactUsScreen.defaultProps = {
  background: '#FFF',
  border: '1px solid #ced4da',
}

export default ContactUsScreen
