import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

// Layout
import UserMain from '../layout/UserLayout/UserMain'

// Style
import { Container, Row, Col, Form, Button } from 'react-bootstrap'

// Actions
import { accountVerification } from '../redux/actions/userProfileAction'
import { retrieveRegion } from '../redux/actions/userProfileAction'

// Components
import SuccessModal from '../reusable/SuccessModal'

// Data
import { provinces } from '../../data/provinces'

const AddProfileScreen = ({ history }) => {
  // Declaration
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const userProfile = useSelector((state) => state.userProfile)
  const { accountVerified, retrievedRegion } = userProfile

  const [state, setState] = useState({
    PresentNoStreet: '',
    PresentSubd: '',
    PresentBrgyDistrict: '',
    PresentProvince: '',
    PresentCity: '',
    PresentZipCode: '',
    PresentCountry: 'Philippines',
    PermanentNoStreet: '',
    PermanentSubd: '',
    PermanentBrgyDistrict: '',
    PermanentProvince: '',
    PermanentCity: '',
    PermanentCountry: 'Philippines',
    PermanentZipCode: '',
    Profession: [{ ProfessionName: '', ProfessionNo: '' }],
    userID: 0,
    same: 0,
    provinces: [],
    cities: [],
    barangays: [],
    Permanentcities: [],
    Permanentbarangays: [],
    disabled: false,
  })

  const [address, setAddress] = useState({
    presentCities: [],
    permanentCities: [],
    presentBarangays: [],
    permanentBarangays: [],
  })

  const [modal, setModal] = useState({
    created: false,
  })

  //   End Declaration

  //   Function
  const handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        setState({
          ...state,
          [e.target.name]: 1,
          PermanentNoStreet: state.PresentNoStreet,
          PermanentSubd: state.PresentSubd,
          PermanentBrgyDistrict: state.PresentBrgyDistrict,
          PermanentProvince: state.PresentProvince,
          PermanentCity: state.PresentCity,
          PermanentZipCode: state.PresentZipCode,
          Permanentcities: state.cities,
          Permanentbarangays: state.barangays,
          disabled: true,
        })
      } else {
        setState({
          ...state,
          [e.target.name]: 0,
          PermanentNoStreet: '',
          PermanentSubd: '',
          PermanentBrgyDistrict: '',
          PermanentProvince: '',
          PermanentCity: '',
          PermanentZipCode: '',
          Permanentcities: [],
          Permanentbarangays: [],
          disabled: false,
        })
      }
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }
  // End Function

  // UseEffect
  useEffect(() => {
    // setState((state) => ({
    //   ...state,
    //   userID: userCredentials.id,
    //   // provinces: provinces,
    // }))
    dispatch(retrieveRegion({ extensions: '/regions' }))
  }, [])

  // console.log(retrievedRegion)

  return (
    <UserMain>
      <Container fluid>
        <Row className='mx-0'>
          <Col xl={12}>
            <Form
              onSubmit={(event) => {
                event.preventDefault()
                setModal({ created: true })
                dispatch(accountVerification(state))
              }}
              className='pt-3 bg-white pb-5 mb-3 shadow-lg'
            >
              {/* Present Address */}
              <h5 className='mx-3'>Present Address</h5>
              <Row className='mx-3 mt-3 mb-3'>
                <Form.Group as={Col} md={3}>
                  <h6>Region:</h6>
                  <Form.Control
                    as='select'
                    size='sm'
                    required
                    value={state.PresentProvince}
                    name='PresentProvince'
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setState({
                            ...state,
                            PresentProvince: e.target.value,
                            PresentBrgyDistrict: '',
                            PresentCity: '',
                          })
                        : setState({
                            ...state,
                            PresentProvince: e.target.value,
                            PresentBrgyDistrict: '',
                            PresentCity: '',
                          })

                      const region =
                        retrievedRegion &&
                        retrievedRegion.message &&
                        retrievedRegion.message.find(
                          (region) => region.regionName === e.target.value
                        )

                      if (region !== undefined) {
                        axios({
                          method: 'GET',
                          url: `${process.env.REACT_APP_API_URL}/address`,
                          params: {
                            extensions: `/regions/${region.code}/cities/`,
                          },
                        }).then((res) => {
                          setAddress({
                            ...address,
                            presentCities: res.data,
                          })
                        })
                      }
                    }}
                  >
                    <option value=''>Choose here ...</option>
                    {retrievedRegion &&
                      retrievedRegion.message &&
                      retrievedRegion.message.map((e, index) => (
                        <option key={index} value={e.regionName}>
                          {e.regionName}
                        </option>
                      ))}
                  </Form.Control>
                  {/* <Form.Control
                    as='select'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PresentProvince}
                    name='PresentProvince'
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setState({
                            ...state,
                            PresentProvince: e.target.value,
                            cities: state.provinces.find(
                              (el) => el.province === e.target.value
                            ).cities,
                          })
                        : setState({
                            ...state,
                            PresentProvince: '',
                            PresentCity: '',
                            PresentBrgyDistrict: '',
                            cities: [],
                            barangays: [],
                          })
                    }}
                  >
                    <option value=''>Select Province here ...</option>
                    {state.provinces.map((element, index) => (
                      <option key={index} value={element.province}>
                        {element.province}
                      </option>
                    ))}
                  </Form.Control> */}
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <h6>City / Municipality:</h6>
                  <Form.Control
                    as='select'
                    size='sm'
                    required
                    value={state.PresentCity}
                    name='PresentCity'
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setState({
                            ...state,
                            PresentCity: e.target.value,
                            PresentBrgyDistrict: '',
                          })
                        : setState({
                            ...state,
                            PresentCity: e.target.value,
                            PresentBrgyDistrict: '',
                          })

                      const city =
                        address.presentCities.length !== 0 &&
                        address.presentCities.find(
                          (cities) => cities.name === e.target.value
                        )

                      if (city !== undefined) {
                        axios({
                          method: 'GET',
                          url: `${process.env.REACT_APP_API_URL}/address`,
                          params: {
                            extensions: `/cities/${city.code}/barangays/`,
                          },
                        }).then((res) => {
                          setAddress({
                            ...address,
                            presentBarangays: res.data,
                          })
                        })
                      }
                    }}
                  >
                    <option value=''>Choose here ...</option>
                    {address.presentCities.length !== 0 &&
                      address.presentCities.map((e, index) => (
                        <option key={index} value={e.name}>
                          {e.name}
                        </option>
                      ))}
                  </Form.Control>
                  {/* <Form.Control
                    as='select'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PresentCity}
                    name='PresentCity'
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setState({
                            ...state,
                            PresentCity: e.target.value,
                            barangays: state.cities.find(
                              (el) => el.city === e.target.value
                            ).barangays,
                          })
                        : setState({
                            ...state,
                            PresentCity: e.target.value,
                            PresentBrgyDistrict: '',
                            barangays: [],
                          })
                    }}
                  >
                    <option value=''>
                      Select City / Municipality here ...
                    </option>
                    {state.cities.map((element, index) => (
                      <option key={index} value={element.city}>
                        {element.city}
                      </option>
                    ))}
                  </Form.Control> */}
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <h6>Barangay / District :</h6>
                  <Form.Control
                    as='select'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PresentBrgyDistrict}
                    name='PresentBrgyDistrict'
                    onChange={handleChange}
                  >
                    <option value=''>Choose here ...</option>
                    {address.presentBarangays.length !== 0 &&
                      address.presentBarangays.map((element, index) => (
                        <option key={index} value={element.name}>
                          {element.name}
                        </option>
                      ))}
                  </Form.Control>
                  {/* <Form.Control
                    as='select'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PresentBrgyDistrict}
                    name='PresentBrgyDistrict'
                    onChange={handleChange}
                  >
                    <option value=''>Select Barangay here ...</option>
                    {state.barangays.map((element, index) => (
                      <option key={index} value={element.barangay}>
                        {element.barangay}
                      </option>
                    ))}
                  </Form.Control> */}
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <h6>Zipcode :</h6>
                  <Form.Control
                    type='text'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PresentZipCode}
                    name='PresentZipCode'
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className='mx-3 mt-3 mb-3'>
                <Form.Group as={Col} md={3}>
                  <h6>No. / Street:</h6>
                  <Form.Control
                    type='text'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PresentNoStreet}
                    name='PresentNoStreet'
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <h6>Subd. :</h6>
                  <Form.Control
                    type='text'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PresentSubd}
                    name='PresentSubd'
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <h6>Country :</h6>
                  <Form.Control
                    type='text'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PresentCountry}
                    name='PresentCountry'
                    disabled
                  />
                </Form.Group>

                <Form.Group as={Col} xl={3}>
                  <Form.Check
                    label='same with permanent address'
                    className='pt-4'
                    name='same'
                    onChange={handleChange}
                    type='checkbox'
                  />
                </Form.Group>
              </Row>
              {/* End Present Address */}

              {/* Permanent Address */}
              <h5 className='mx-3'>Permanent Address</h5>
              <Row className='mx-3 mt-3 mb-3'>
                <Form.Group as={Col} md={3}>
                  <h6>Province:</h6>
                  <Form.Control
                    as='select'
                    disabled={state.disabled}
                    size='sm'
                    required
                    value={state.PermanentProvince}
                    name='PermanentProvince'
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setState({
                            ...state,
                            PermanentProvince: e.target.value,
                            PermanentCity: '',
                            PermanentBrgyDistrict: '',
                          })
                        : setState({
                            ...state,
                            PermanentProvince: e.target.value,
                            PermanentCity: '',
                            PermanentBrgyDistrict: '',
                          })

                      const region =
                        retrievedRegion &&
                        retrievedRegion.message &&
                        retrievedRegion.message.find(
                          (region) => region.regionName === e.target.value
                        )

                      if (region !== undefined) {
                        axios({
                          method: 'GET',
                          url: `${process.env.REACT_APP_API_URL}/address`,
                          params: {
                            extensions: `/regions/${region.code}/cities/`,
                          },
                        }).then((res) => {
                          setAddress({
                            ...address,
                            permanentCities: res.data,
                          })
                        })
                      }
                    }}
                  >
                    <option value=''>Choose here ...</option>
                    {retrievedRegion &&
                      retrievedRegion.message &&
                      retrievedRegion.message.map((e, index) => (
                        <option key={index} value={e.regionName}>
                          {e.regionName}
                        </option>
                      ))}
                  </Form.Control>
                  {/* <Form.Control
                    disabled={state.disabled}
                    as='select'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PermanentProvince}
                    name='PermanentProvince'
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setState({
                            ...state,
                            PermanentProvince: e.target.value,
                            Permanentcities: state.provinces.find(
                              (el) => el.province === e.target.value
                            ).cities,
                          })
                        : setState({
                            ...state,
                            PermanentProvince: e.target.value,
                            PermanentCity: '',
                            PermanentBrgyDistrict: '',
                            Permanentcities: [],
                            Permanentbarangays: [],
                          })
                    }}
                  >
                    <option value=''>Select Province here ...</option>
                    {state.provinces.map((element, index) => (
                      <option key={index} value={element.province}>
                        {element.province}
                      </option>
                    ))}
                  </Form.Control> */}
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <h6>City / Municipality:</h6>
                  <Form.Control
                    as='select'
                    disabled={state.disabled}
                    size='sm'
                    required
                    value={state.PermanentCity}
                    name='PermanentCity'
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setState({
                            ...state,
                            PermanentCity: e.target.value,
                            PermanentBrgyDistrict: '',
                          })
                        : setState({
                            ...state,
                            PermanentCity: e.target.value,
                            PermanentBrgyDistrict: '',
                          })

                      const city =
                        address.permanentCities.length !== 0 &&
                        address.permanentCities.find(
                          (cities) => cities.name === e.target.value
                        )

                      if (city !== undefined) {
                        axios({
                          method: 'GET',
                          url: `${process.env.REACT_APP_API_URL}/address`,
                          params: {
                            extensions: `/cities/${city.code}/barangays/`,
                          },
                        }).then((res) => {
                          setAddress({
                            ...address,
                            permanentBarangays: res.data,
                          })
                        })
                      }
                    }}
                  >
                    <option value=''>Choose here ...</option>
                    {address.permanentCities.length !== 0 &&
                      address.permanentCities.map((element, index) => (
                        <option key={index} value={element.name}>
                          {element.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <h6>Barangay / District :</h6>
                  <Form.Control
                    as='select'
                    disabled={state.disabled}
                    size='sm'
                    required
                    value={state.PermanentBrgyDistrict}
                    name='PermanentBrgyDistrict'
                    onChange={handleChange}
                  >
                    <option value=''>Choose here ...</option>
                    {address.permanentBarangays.length !== 0 &&
                      address.permanentBarangays.map((element, index) => (
                        <option key={index} value={element.name}>
                          {element.name}
                        </option>
                      ))}
                  </Form.Control>
                  {/* <Form.Control
                    disabled={state.disabled}
                    as='select'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PermanentBrgyDistrict}
                    name='PermanentBrgyDistrict'
                    onChange={handleChange}
                  >
                    <option value=''>Select Barangay here ...</option>
                    {state.Permanentbarangays.map((element, index) => (
                      <option key={index} value={element.barangay}>
                        {element.barangay}
                      </option>
                    ))}
                  </Form.Control> */}
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <h6>Zipcode :</h6>
                  <Form.Control
                    disabled={state.disabled}
                    type='text'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PermanentZipCode}
                    name='PermanentZipCode'
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className='mx-3 mt-3 mb-3'>
                <Form.Group as={Col} md={3}>
                  <h6>No. / Street:</h6>
                  <Form.Control
                    type='text'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PermanentNoStreet}
                    name='PermanentNoStreet'
                    onChange={handleChange}
                    disabled={state.same > 0 ? true : false}
                  />
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <h6>Subd. :</h6>
                  <Form.Control
                    type='text'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PermanentSubd}
                    name='PermanentSubd'
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <h6>Country :</h6>
                  <Form.Control
                    type='text'
                    size='sm'
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    value={state.PermanentCountry}
                    name='PermanentCountry'
                    disabled
                  />
                </Form.Group>
              </Row>
              {/* End Permanent Address */}

              {/* Professional Information */}
              <h5 className='mx-3'>Professional Information</h5>
              {/* <Row className='mx-3 mt-3'>
                <Col sm={12} md={3} xl={3}>
                  <h6>Profession Name :</h6>
                </Col>
                <Col sm={12} md={3} xl={3}>
                  <h6>Profession No. :</h6>
                </Col>
              </Row> */}

              {state.Profession.map((info, index) => (
                <Row className='mx-3' key={index}>
                  <Form.Group className='mt-1' as={Col} xl={3}>
                    <h6>Profession Name :</h6>
                    <Form.Control
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={info.ProfessionName}
                      name='ProfessionName'
                      onChange={(e) => {
                        let response = state.Profession
                        response[index].ProfessionName = e.target.value
                        setState({
                          ...state,
                          Profession: response,
                        })
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group className='mt-1' as={Col} xl={3}>
                    <h6>Profession No. :</h6>
                    <Form.Control
                      size='sm'
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                      value={info.ProfessionNo}
                      name='ProfessionNo'
                      onChange={(e) => {
                        let response = state.Profession
                        response[index].ProfessionNo = e.target.value
                        setState({
                          ...state,
                          Profession: response,
                        })
                      }}
                      required
                    />
                  </Form.Group>
                </Row>
              ))}

              {/* Professional Info Action Btn */}
              <Row>
                <Col xl={12} className='mt-2 mb-3 mx-4'>
                  {state.Profession.length < 3 && (
                    <Button
                      onClick={() =>
                        setState({
                          ...state,
                          Profession: [
                            ...state.Profession,
                            { ProfessionName: '', ProfessionNo: '' },
                          ],
                        })
                      }
                      size='sm'
                      variant='info'
                    >
                      +
                    </Button>
                  )}
                  {state.Profession.length > 0 && (
                    <Button
                      onClick={() => {
                        let response = state.Profession
                        if (state.Profession.length > 1) {
                          response.pop(response.length - 1)
                        }
                        setState({
                          ...state,
                          Profession: response,
                        })
                      }}
                      size='sm'
                      variant='danger'
                      className='mx-2'
                    >
                      -
                    </Button>
                  )}
                </Col>
              </Row>
              {/* End Professional Info Action Btn */}
              {/* Professional Information */}

              {/* Action Button */}
              <Row className='text-center mt-4 mb-3'>
                <Col xl={12}>
                  <Button
                    type='submit'
                    size='sm'
                    className='mx-2'
                    variant='info'
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={() => history.push(`/profile`)}
                    size='sm'
                    variant='danger'
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              {/* End Action Button */}
            </Form>
          </Col>
        </Row>
      </Container>

      {/* Success Modal */}
      <SuccessModal
        show={modal.created}
        onHide={() => {
          setModal({ created: false })
          history.push('/profile')
        }}
        transLoading={accountVerified && accountVerified.loading}
        success={accountVerified && accountVerified.message}
        error={accountVerified && accountVerified.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(AddProfileScreen)
