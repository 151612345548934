import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Table } from 'react-bootstrap'
import { FaCheckCircle, FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// Actions
import { updateEngineerInformation } from '../redux/actions/DocumentControllers/documentControllerActions'

// Layout
import SuccessModal from './SuccessModal'

const EngArchModal = (props) => {
  const dispatch = useDispatch()
  const [recordEngineers, setRecordEngineers] = useState([
    { Type: '', Engineer: '', Name: '', engPrcNo: '' },
  ])

  const [modal, setModal] = useState({
    success: false,
  })

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const dc = useSelector((state) => state.documentControllerReducer)

  const { submittedEngineers, updatedEngineerInformation } = dc

  //   Function
  const handleOnSubmit = (e) => {
    if (window.confirm('Are you sure you want to submit the information?')) {
      e.preventDefault()
      submittedEngineers && submittedEngineers.message
        ? dispatch(
            updateEngineerInformation({
              dcID: userCredentials.id,
              id: props.payload.id,
              recordEngineers,
              isEditting: true,
            })
          )
        : dispatch(
            updateEngineerInformation({
              dcID: userCredentials.id,
              id: props.payload.id,
              recordEngineers,
              isEditting: false,
            })
          )

      props.onClose()
      setModal({ success: true })
    }
  }
  // End Function

  //   useEffect
  useEffect(() => {
    if (submittedEngineers && submittedEngineers.message) {
      setRecordEngineers(submittedEngineers.message)
    }
  }, [submittedEngineers])
  // End useEffect

  return (
    <>
      <Modal
        size='xl'
        show={props.show}
        onHide={props.onHide}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-1' />
            Project Engineer/Architect Information
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleOnSubmit}>
          <Modal.Body className='pb-0'>
            <Table bordered hover responsive size='sm'>
              <thead>
                <tr>
                  <td>Created</td>
                  <td>Profession</td>
                  <td>Name</td>
                  <td>Type</td>
                  <td>Trade</td>
                  <td>Accepted</td>
                  <td className='text-nowrap'>Time Accepted/Declined</td>
                </tr>
              </thead>
              <tbody>
                {recordEngineers.map((info, index) => (
                  <tr key={index} className='text-nowrap'>
                    <td>
                      {info.timeStamp !== null
                        ? moment(info.timeStamp).format('MMMM D, YYYY')
                        : ''}
                    </td>
                    <td>{info.Engineer}</td>
                    <td className='fw-bold'>{info.Name}</td>
                    <td>{info.Type}</td>
                    <td className='fw-bold'>{info.trade}</td>
                    <td className='fw-bold text-danger text-center'>
                      {info.accepted !== 1
                        ? info.accepted === 0
                          ? 'NO'
                          : ''
                        : 'YES'}
                    </td>
                    <td>
                      {info.dateAccepted !== null
                        ? moment(info.dateAccepted).format('MMMM D, YYYY')
                        : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* {recordEngineers.map((info, index) => (
              <div key={index}>
                <Row>
                  <Form.Group as={Col} xl={6}>
                    <Form.Label>Profession</Form.Label>
                    <Form.Control
                      type='text'
                      value={info.Engineer}
                      name='Engineer'
                      onChange={(e) => {
                        let response = recordEngineers
                        response[index].Engineer = e.target.value
                        setRecordEngineers([...response])
                      }}
                      required
                      placeholder='Profession'
                      size='sm'
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={6}>
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                      type='text'
                      value={info.Type}
                      name='Type'
                      onChange={(e) => {
                        let response = recordEngineers
                        response[index].Type = e.target.value
                        setRecordEngineers([...response])
                      }}
                      required
                      placeholder='Engineer Type'
                      size='sm'
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} xl={6}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='text'
                      name='Name'
                      value={info.Name}
                      onChange={(e) => {
                        let response = recordEngineers
                        response[index].Name = e.target.value
                        setRecordEngineers([...response])
                      }}
                      required
                      placeholder='Full Name'
                    />
                  </Form.Group>
                  <Form.Group as={Col} xl={6}>
                    <Form.Label>PRC ID No.</Form.Label>
                    <Form.Control
                      type='text'
                      name='engPrcNo'
                      value={info.engPrcNo}
                      onChange={(e) => {
                        let response = recordEngineers
                        response[index].engPrcNo = e.target.value
                        setRecordEngineers([...response])
                      }}
                      required
                    />
                  </Form.Group>
                </Row>
                <hr className='my-2 text-success' />
              </div>
            ))} */}

            {/* <div className='text-end my-2'>
              <Button
                variant='info'
                size='sm'
                className='mx-1'
                onClick={() =>
                  setRecordEngineers([
                    ...recordEngineers,
                    { engType: '', engineer: '', engName: '', engPrcNo: '' },
                  ])
                }
              >
                <FaPlusCircle />
              </Button>
              <Button
                variant='danger'
                size='sm'
                className='mx-1'
                onClick={() => {
                  let response = recordEngineers
                  if (recordEngineers.length > 1) {
                    response.pop(response.length - 1)
                  }
                  setRecordEngineers([...response])
                }}
              >
                <FaMinusCircle />
              </Button>
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            {/* <Button type='submit' variant='info' size='sm'>
              Submit
            </Button> */}
            <Button variant='danger' size='sm' onClick={props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/*Success Modal */}
      <SuccessModal
        show={modal.success}
        onHide={() => {
          setModal({ success: false })
          props.onHide()
        }}
        transLoading={
          updatedEngineerInformation && updatedEngineerInformation.loading
        }
        success={
          updatedEngineerInformation && updatedEngineerInformation.message
        }
        error={updatedEngineerInformation && updatedEngineerInformation.error}
      />
      {/* End Success Modal */}
    </>
  )
}

export default EngArchModal
