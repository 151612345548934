export const mechanicalData = [
  {
    id: 1,
    code_header: '',
    header: '',
    code_id: '1302',
    code_name:
      '1. Reference - Philippine Mechanical Code 2. Guarding of Moving and Dangerous Parts 3. Cranes 4. Hoists 5. Elevators, for public or private use 6. Escalators 7. Boilers and Pressure Vessels 8. Refrigeration and Air Conditioning 9. Water Pumping for buildings/structures 10. Piping for fuel, gas and steam',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 2,
    code_header: '',
    header: '',
    code_id: '301.7.a-k',
    code_name:
      'Required mechanical drawings: 1. Location and Site Plan, Vicinity Map 2. Mechanical Plan/s/Layouts, same scale as architectural floor plan/s showing: A/C layout, Fire Suppression System, ventilation system 3. Schedule of Machines/Equipment 4. Mechanical Details: Elevator, escalator, dumbwaiter, others as deemed necessary',
    compliance: '',
    id_remarks: '',
  },
]
