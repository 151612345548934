import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProtectedRoute from '../reusable/ProtectedRoute'

// Components
import ExpiredTokenScreen from '../screens/ExpiredTokenScreen'

// City Engineer Unique Screen
import ForApprovalListScreen from '../screens/Administrator/ForApprovalListScreen'
import ApprovedListScreen from '../screens/Administrator/ApprovedListScreen'
import ReturnListScreen from '../screens/Administrator/ReturnedListScreen'
import ApplicationListScreen from '../screens/Administrator/ApplicationListScreen'
import ManageApplication from '../screens/DcScreens/ManageApplication'
import DcDashboardScreen from '../screens/DcScreens/DcDashboardScreen'
import CreateAccountScreen from '../screens/Administrator/CreateAccountScreen'
import EmployeeProfileScreen from '../screens/DcScreens/EmployeeProfileScreen'
import VerifyUserScreen from '../screens/Administrator/VerifyUserScreen'
import ReportScreen from '../screens/DcScreens/ReportScreen'
import NoticeOfViolationListScreen from '../screens/InspectorScreens/NoticeOfViolationListScreen'
import DcForPaymentScreen from '../screens/DcScreens/DcForPaymentScreen'
import RoutingSlipInfoScreen from '../screens/DcScreens/RoutingSlipInfoScreen'

const AdminRoutes = ({ user }) => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path='/dashboard'
        component={DcDashboardScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/for-approval-list'
        component={ForApprovalListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/approved-list'
        component={ApprovedListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/applications'
        component={DcForPaymentScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/returned-list'
        component={ReturnListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/application/:id?'
        component={ManageApplication}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/employee-maintenance'
        component={CreateAccountScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/verify-user'
        component={VerifyUserScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/profile'
        component={EmployeeProfileScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/reports'
        component={ReportScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/notice-of-violation'
        component={NoticeOfViolationListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/routing-slip/:id?'
        component={RoutingSlipInfoScreen}
        isAuth={user === null ? false : true}
      />

      <Route component={ExpiredTokenScreen} />
    </Switch>
  )
}

export default AdminRoutes
