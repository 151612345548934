export const USER_APPLICATIONS_REQUEST = 'USER_APPLICATIONS_REQUEST'
export const USER_APPLICATIONS_SUCCESS = 'USER_APPLICATIONS_SUCCESS'
export const USER_APPLICATIONS_FAILED = 'USER_APPLICATIONS_FAILED'

export const USER_INSERT_APPLICATION_REQUEST = 'USER_INSERT_APPLICATION_REQUEST'
export const USER_INSERT_APPLICATION_SUCCESS = 'USER_INSERT_APPLICATION_SUCCESS'
export const USER_INSERT_APPLICATION_FAILED = 'USER_INSERT_APPLICATION_FAILED'

export const USER_APPLICATIONS_REQUEST_BYID = 'USER_APPLICATIONS_REQUEST_BYID'
export const USER_APPLICATIONS_SUCCESS_BYID = 'USER_APPLICATIONS_SUCCESS_BYID'
export const USER_APPLICATIONS_FAILED_BYID = 'USER_APPLICATIONS_FAILED_BYID'

export const USER_APPLICATIONS_RETRIEVE_BYSTATUS_REQUEST =
  'USER_APPLICATIONS_RETRIEVE_BYSTATUS_REQUEST'
export const USER_APPLICATIONS_RETRIEVE_BYSTATUS =
  'USER_APPLICATIONS_RETRIEVE_BYSTATUS'
export const USER_APPLICATIONS_RETRIEVE_BYSTATUS_FAILED =
  'USER_APPLICATIONS_RETRIEVE_BYSTATUS_FAILED'

export const USER_APPLICATION_UPDATE_REQ = 'USER_APPLICATION_UPDATE_REQ'
export const USER_APPLICATION_UPDATE_SUCCESS = 'USER_APPLICATION_UPDATE_SUCCESS'
export const USER_APPLICATION_UPDATE_FAILED = 'USER_APPLICATION_UPDATE_FAILED'

export const USER_APPLICATION_DELETE_REQ = 'USER_APPLICATION_DELETE_REQ'
export const USER_APPLICATION_DELETE_SUCCESS = 'USER_APPLICATION_DELETE_SUCCESS'
export const USER_APPLICATION_DELETE_FAILED = 'USER_APPLICATION_DELETE_FAILED'

export const USER_APPLICATION_HISTORY_REQ = 'USER_APPLICATION_HISTORY_REQ'
export const USER_APPLICATION_HISTORY_SUCCESS =
  'USER_APPLICATION_HISTORY_SUCCESS'
export const USER_APPLICATION_HISTORY_FAILED = 'USER_APPLICATION_HISTORY_FAILED'

// USER DASHBOARD
export const USER_GETAPPLICATIONFORVERIFICATION_REQ =
  'USER_GETAPPLICATIONFORVERIFICATION_REQ'
export const USER_GETAPPLICATIONFORVERIFICATION_SUCCESS =
  'USER_GETAPPLICATIONFORVERIFICATION_SUCCESS'
export const USER_GETAPPLICATIONFORVERIFICATION_FAILED =
  'USER_GETAPPLICATIONFORVERIFICATION_FAILED'

export const USER_GETAPPLICATIONFORINSPECTION_REQ =
  'USER_GETAPPLICATIONFORINSPECTION_REQ'
export const USER_GETAPPLICATIONFORINSPECTION_SUCCESS =
  'USER_GETAPPLICATIONFORINSPECTION_SUCCESS'
export const USER_GETAPPLICATIONFORINSPECTION_FAILED =
  'USER_GETAPPLICATIONFORINSPECTION_FAILED'

export const USER_GETAPPLICATIONFORCOMPLIANCE_REQ =
  'USER_GETAPPLICATIONFORCOMPLIANCE_REQ'
export const USER_GETAPPLICATIONFORCOMPLIANCE_SUCCESS =
  'USER_GETAPPLICATIONFORCOMPLIANCE_SUCCESS'
export const USER_GETAPPLICATIONFORCOMPLIANCE_FAILED =
  'USER_GETAPPLICATIONFORCOMPLIANCE_FAILED'

export const USER_GETAPPLICATIONAPPROVED_REQ = 'USER_GETAPPLICATIONAPPROVED_REQ'
export const USER_GETAPPLICATIONAPPROVED_SUCCESS =
  'USER_GETAPPLICATIONAPPROVED_SUCCESS'
export const USER_GETAPPLICATIONAPPROVED_FAILED =
  'USER_GETAPPLICATIONAPPROVED_FAILED'

export const USER_APPSTATUSTRACK_REQ = 'USER_APPSTATUSTRACK_REQ'
export const USER_APPSTATUSTRACK_SUCCESS = 'USER_APPSTATUSTRACK_SUCCESS'
export const USER_APPSTATUSTRACK_FAILED = 'USER_APPSTATUSTRACK_FAILED'

export const GET_ORDEROFPAYMENT_REQ = 'GET_ORDEROFPAYMENT_REQ'
export const GET_ORDEROFPAYMENT_SUCCESS = 'GET_ORDEROFPAYMENT_SUCCESS'
export const GET_ORDEROFPAYMENT_FAILED = 'GET_ORDEROFPAYMENT_FAILED'

export const GET_RELEASEPERMIT_REQ = 'GET_RELEASEPERMIT_REQ'
export const GET_RELEASEPERMIT_SUCCESS = 'GET_RELEASEPERMIT_SUCCESS'
export const GET_RELEASEPERMIT_FAILED = 'GET_RELEASEPERMIT_FAILED'

export const GET_LIST_REPORT_REQ = 'GET_LIST_REPORT_REQ'
export const GET_LIST_REPORT_SUCCESS = 'GET_LIST_REPORT_SUCCESS'
export const GET_LIST_REPORT_FAILED = 'GET_LIST_REPORT_FAILED'

export const GET_ENGAPPLIST_REQ = 'GET_ENGAPPLIST_REQ'
export const GET_ENGAPPLIST_SUCCESS = 'GET_ENGAPPLIST_SUCCESS'
export const GET_ENGAPPLIST_FAILED = 'GET_ENGAPPLIST_FAILED'

export const ACCEPT_ENGAPPLICATION_REQ = 'ACCEPT_ENGAPPLICATION_REQ'
export const ACCEPT_ENGAPPLICATION_SUCCESS = 'ACCEPT_ENGAPPLICATION_SUCCESS'
export const ACCEPT_ENGAPPLICATION_FAILED = 'ACCEPT_ENGAPPLICATION_FAILED'

export const DECLINE_ENGAPPLICATION_REQ = 'DECLINE_ENGAPPLICATION_REQ'
export const DECLINE_ENGAPPLICATION_SUCCESS = 'DECLINE_ENGAPPLICATION_SUCCESS'
export const DECLINE_ENGAPPLICATION_FAILED = 'DECLINE_ENGAPPLICATION_FAILED'

export const UPDATE_ENGIMAGEATTACHED_REQ = 'UPDATE_ENGIMAGEATTACHED_REQ'
export const UPDATE_ENGIMAGEATTACHED_SUCCESS = 'UPDATE_ENGIMAGEATTACHED_SUCCESS'
export const UPDATE_ENGIMAGEATTACHED_FAILED = 'UPDATE_ENGIMAGEATTACHED_FAILED'

export const GET_ENGLIST_REQ = 'GET_ENGLIST_REQ'
export const GET_ENGLIST_SUCCESS = 'GET_ENGLIST_SUCCESS'
export const GET_ENGLIST_FAILED = 'GET_ENGLIST_FAILED'

export const GET_RECORDED_ENG_REQ = 'GET_RECORDED_ENG_REQ'
export const GET_RECORDED_ENG_SUCCESS = 'GET_RECORDED_ENG_SUCCESS'
export const GET_RECORDED_ENG_FAILED = 'GET_RECORDED_ENG_FAILED'

export const GET_RELEASED_OCCU_REQ = 'GET_RELEASED_OCCU_REQ'
export const GET_RELEASED_OCCU_SUCCESS = 'GET_RELEASED_OCCU_SUCCESS'
export const GET_RELEASED_OCCU_FAILED = 'GET_RELEASED_OCCU_FAILED'

export const UPDATE_RECORDED_ENG_REQ = 'UPDATE_RECORDED_ENG_REQ'
export const UPDATE_RECORDED_ENG_SUCCESS = 'UPDATE_RECORDED_ENG_SUCCESS'
export const UPDATE_RECORDED_ENG_FAILED = 'UPDATE_RECORDED_ENG_FAILED'

export const PAYMENT_INITIALIZE_REQ = 'PAYMENT_INITIALIZE_REQ'
export const PAYMENT_INITIALIZE_SUCCESS = 'PAYMENT_INITIALIZE_SUCCESS'
export const PAYMENT_INITIALIZE_FAILED = 'PAYMENT_INITIALIZE_FAILED'

export const GET_RELEASECFEI_REQ = 'GET_RELEASECFEI_REQ'
export const GET_RELEASECFEI_SUCCESS = 'GET_RELEASECFEI_SUCCESS'
export const GET_RELEASECFEI_FAILED = 'GET_RELEASECFEI_FAILED'
