export const MESSAGE_ADD_REQUEST = 'MESSAGE_ADD_REQUEST'
export const MESSAGE_ADD_SUCCESS = 'MESSAGE_ADD_SUCCESS'
export const MESSAGE_ADD_FAILED = 'MESSAGE_ADD_FAILED'

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED'

export const GET_MESSAGE_BYID_REQUEST = 'GET_MESSAGE_BYID_REQUEST'
export const GET_MESSAGE_BYID_SUCCESS = 'GET_MESSAGE_BYID_SUCCESS'
export const GET_MESSAGE_BYID_FAILED = 'GET_MESSAGE_BYID_FAILED'
