import React, { useEffect, useState } from 'react'
import {
  Accordion,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Image,
  Table,
  Pagination,
} from 'react-bootstrap'
import _ from 'lodash'
import { FaDownload } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import UserMain from '../layout/UserLayout/UserMain'
import { downloadableformsRetrieve } from '../redux/actions/downloadableformsActions'

// Actions
import { getUserProfile } from '../redux/actions/userProfileAction'
import FaqAppUpdate from '../props/FaqAppUpdate'
import FaqFileSecurity from '../props/FaqFileSecurity'
import FaqAppSubmit from '../props/FaqAppSubmit'
import FaqPaymentChannel from '../props/FaqPaymentChannel'
// import Layout from '../layout/Layout'
// import tutorialForms from '../data/tutorialForms'

const ApplyScreen = ({ history }) => {
  const dispatch = useDispatch()
  const downloadableforms = useSelector((state) => state.downloadableforms)
  const { downloadableformsDetail } = downloadableforms

  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const userProfile = useSelector((state) => state.userProfile)
  const { profile } = userProfile

  useEffect(() => {
    dispatch(downloadableformsRetrieve())
    dispatch(getUserProfile({ id: userCredentials.id }))
  }, [dispatch])

  // Pagination Area Downloadable Forms
  const [dfpaginatedForms, setdfpaginatedForms] = useState([])
  const [dfcurrentPage, setdfcurrentPage] = useState(1)

  const [dfpageNumberLimit] = useState(5)
  const [maxdfPageNumberLimit, setmaxdfPageNumberLimit] = useState(5)
  const [mindfPageNumberLimit, setmindfPageNumberLimit] = useState(0)

  const dfpageSize = 5

  useEffect(() => {
    setdfpaginatedForms(
      _(downloadableformsDetail).slice(0).take(dfpageSize).value()
    )
    setdfcurrentPage(1)
  }, [downloadableformsDetail])

  const dfpageCount = downloadableformsDetail
    ? Math.ceil(downloadableformsDetail.length / dfpageSize)
    : 0

  const dfpages = _.range(1, dfpageCount + 1)

  const dfpagination = (pageNo) => {
    setdfcurrentPage(pageNo)
    const dfstartIndex = (pageNo - 1) * dfpageSize
    const dfpaginationForms = _(downloadableformsDetail)
      .slice(dfstartIndex)
      .take(dfpageSize)
      .value()
    setdfpaginatedForms(dfpaginationForms)
  }

  const dfrenderPageNumber = dfpages.map((page) => {
    if (page < maxdfPageNumberLimit + 1 && page > mindfPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => dfpagination(page)}
          active={page === dfcurrentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const dfhandleNextbtn = () => {
    setdfcurrentPage(dfcurrentPage + 1)
    dfpagination(dfcurrentPage + 1)

    if (dfcurrentPage + 1 > maxdfPageNumberLimit) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit + dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit + dfpageNumberLimit)
    }
  }

  const dfhandlePrevbtn = () => {
    setdfcurrentPage(dfcurrentPage - 1)
    dfpagination(dfcurrentPage - 1)

    if ((dfcurrentPage - 1) % dfpageNumberLimit === 0) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit - dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit - dfpageNumberLimit)
    }
  }
  // End Pagination Area Downloadable Forms

  return (
    <UserMain>
      <Container fluid style={{ background: '#FFF' }}>
        <Row>
          <Col lg={12} xl={9} className='choices-container-col'>
            <Form className='col-form-area'>
              <div className='application-header'>
                <p>
                  Welcome to the Office of the Building Official Online
                  Application
                </p>
                <hr />
              </div>
              <div className='application-you-should-know'>
                <h6>You should know</h6>
                <p>
                  Fill up your application for Building Permit using
                  downloadable forms. Ensure to provide relevant information for
                  your project.
                  <br />
                  <br />
                  You may see the checklist of requirements to guide you in
                  providing required information for the application.
                  <br />
                  <br />
                  You may also call us through (02) 8332 – 9660 to clarify your
                  concerns. <br />
                  <br />
                  Suppose you are not familiar with preparing applications for
                  BPCO, we suggest that you find yourself an experienced
                  professional to assist you in furnishing required drawing
                  plans and necessary information to apply on your behalf.
                  <br />
                  <br />
                </p>
              </div>
              <div className='application-how-to-apply'>
                <h3>How to Apply</h3>
                <p>
                  Online: applying online will conserve your time and effort. It
                  will also allow you to track your application in real time.
                  Submit e-copies of your files thru online storage. You may
                  transmit them using the following <br />
                  1. Google Drive
                  <br />
                  2. OneDrive
                  <br />
                  3. Other online sharing platforms (WeShare, Mediafire,
                  Dropbox, etc.)
                  <br />
                </p>
                <p>
                  On-site: download application forms here and submit all
                  documents and forms at the Office of the Building Official,
                  Main Building, City Hall Compound, Las Piñas.
                </p>
              </div>

              <hr style={{ background: '#afafb4', paddingBottom: '2px' }} />
              <div className='border border-success p-3'>
                <Row>
                  <Col sm={12} md={9} lg={10}>
                    <p className='fs-5 mb-0 text-dark mx-3'>
                      Apply for Building Permit Online
                    </p>
                  </Col>
                  <Col sm={12} md={3} lg={2}>
                    {profile && profile[0].PRA_id !== null ? (
                      <Button
                        variant='success'
                        onClick={() => history.push('/category')}
                      >
                        Apply
                      </Button>
                    ) : (
                      <Button
                        variant='success'
                        onClick={() =>
                          alert(
                            'You must complete your account details first at the Profile page'
                          )
                        }
                      >
                        Apply
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
              <div className='application-our-tip'>
                <h6>Our tip</h6>
                <p>
                  Proper documentation of requirements and forms is the best way
                  to get approval for BPCO.
                </p>
                <p>
                  Our Document Controllers will check all applications before
                  acceptance to ensure that all required information is provided
                  to avoid delay.
                </p>

                <p>
                  Please note that applications with incomplete requirements and
                  information will not <br /> be accepted. The standard
                  processing time, set by ARTA, will not start/continue <br />
                  until the missing information has been provided and
                  resubmitted.
                </p>

                <p>
                  A non-acceptance letter may be issued if an applicant is
                  unable to provide a complete application. Upon completion of
                  application deficits, it may be submitted at any time. All
                  applications will incur processing fees whether they are
                  accepted or not.
                </p>
              </div>
              <div className='application-downloadable-forms'>
                <h4>Online Application PDF Guidelines</h4>
                <hr className='mb-0' />
                <Table variant='dark' bordered striped hover responsive>
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dfpaginatedForms &&
                      dfpaginatedForms.map((tutorialForm) => (
                        <tr key={tutorialForm.id}>
                          <td style={{ fontSize: '16px' }}>
                            {tutorialForm.FormName}
                          </td>
                          <td className='text-center'>
                            <Button
                              variant='info'
                              onClick={() => {
                                window.open(`${tutorialForm.Link}`, '_blank')
                              }}
                            >
                              <FaDownload />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <Pagination
                  size='sm'
                  className='d-flex justify-content-end mx-2'
                >
                  <Pagination.Item
                    size='sm'
                    onClick={dfhandlePrevbtn}
                    disabled={dfcurrentPage === dfpages[0] ? true : false}
                  >
                    &lt;
                  </Pagination.Item>

                  {dfrenderPageNumber}

                  <Pagination.Item
                    size='sm'
                    onClick={dfhandleNextbtn}
                    disabled={
                      dfcurrentPage === dfpages[dfpages.length - 1]
                        ? true
                        : false
                    }
                  >
                    &gt;
                  </Pagination.Item>
                </Pagination>
              </div>
              <br />
              <hr style={{ background: '#afafb4', paddingBottom: '2px' }} />
              <div className='application-process-timeline'>
                <h4>Application Timeline</h4>
                <p id='timeline-title' style={{ color: '#00304b' }}>
                  As per Joint Memorandum Circular (JMC) No. 2019 – 001, the IRR
                  of R.A. 10132 otherwise known as Ease of Doing Business and
                  Efficient Government Service Delivery Act of 2018, statutory
                  timeframe for simple transactions shall not be longer than
                  five (5) working days.
                  {/* <b style={{ color: '#00304b' }}>maximum of 10 working days</b> */}
                </p>
                <p style={{ color: '#00304b' }}>
                  However, statutory timeframe stops when Request for
                  Information (RFI) is issued to the applicants. This may also
                  include pull-out of plans, or submitting updated documents.
                  Once RFI is complied/answered, the timeframe will reset to
                  five (5) working days.
                </p>
                {/* <p>
                  You can <b>track</b> your application in the transaction
                  status of your account:
                </p>
                <ul>
                  <li>Evaluate submitted plans and documents </li>
                  <li>Site Inspection and Submission of report </li>
                  <li>Assessment & Processing of applied permit </li>
                  <li>Signing of Approved Permit </li>
                  <li>Release of Order of Payment </li>
                  <li>Receive the amount as per Order of Payment</li>
                  <li>Release of Permit</li>
                </ul>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi
                </p> */}
              </div>
              <div className='application-faqs'>
                <h3>FAQs</h3>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='0'
                      className='accordion-top'
                    >
                      Where can I see the update for my Application?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='0'>
                      <Card.Body className='accordion-description'>
                        <FaqAppUpdate />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='1'
                      className='accordion-bottom'
                    >
                      How about the Security of my Files and Requirements?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='1'>
                      <Card.Body>
                        <FaqFileSecurity />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='2'
                      className='accordion-top'
                    >
                      Where to Submit the Requirements?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='2'>
                      <Card.Body className='accordion-description'>
                        <FaqAppSubmit />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='3'
                      className='accordion-bottom'
                    >
                      Can i view the order of payment? how about payment
                      channel?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='3'>
                      <Card.Body>
                        <FaqPaymentChannel />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </Form>
          </Col>
          <Col xl={3} className='d-none d-xl-block m-0'>
            <Image
              src='assets/img/apply-bg.webp'
              alt='/'
              height='2270'
              width='340'
            />
          </Col>
        </Row>
      </Container>
    </UserMain>
  )
}

export default withRouter(ApplyScreen)
