import Cookie from 'js-cookie'
import axios from 'axios'
import {
  APP_RETRIEVE_BY_STATUS_REQ,
  APP_RETRIEVE_BY_STATUS_SUCCESS,
  APP_RETRIEVE_BY_STATUS_FAILED,
  APP_DC_CHANGE_STATUS_REQ,
  APP_DC_CHANGE_STATUS_SUCCESS,
  APP_DC_CHANGE_STATUS_FAILED,
  REQUIRED_DOCUMENTS_REQ,
  REQUIRED_DOCUMENTS_SUCCESS,
  REQUIRED_DOCUMENTS_FAILED,
  GET_EVALUATORS_REQ,
  GET_EVALUATORS_SUCCESS,
  GET_EVALUATORS_FAILED,
  GET_INSPECTORS_REQ,
  GET_INSPECTORS_SUCCESS,
  GET_INSPECTORS_FAILED,
  GET_APPLICATION_INFORMATION_REQ,
  GET_APPLICATION_INFORMATION_SUCCESS,
  GET_APPLICATION_INFORMATION_FAILED,
  UPDATE_APPLICATION_INFORMATION_REQ,
  UPDATE_APPLICATION_INFORMATION_SUCCESS,
  UPDATE_APPLICATION_INFORMATION_FAILED,
  ADD_APPLICATION_INFORMATION_REQ,
  ADD_APPLICATION_INFORMATION_SUCCESS,
  ADD_APPLICATION_INFORMATION_FAILED,
  GET_SUBMITTED_DOCS_REQ,
  GET_SUBMITTED_DOCS_SUCCESS,
  GET_SUBMITTED_DOCS_FAILED,
  UPDATE_SUBMITTED_DOCS_REQ,
  UPDATE_SUBMITTED_DOCS_SUCCESS,
  UPDATE_SUBMITTED_DOCS_FAILED,
  SUBMIT_REQUIRED_DOCS_REQ,
  SUBMIT_REQUIRED_DOCS_SUCCESS,
  SUBMIT_REQUIRED_DOCS_FAILED,
  APP_ENGINEER_INFO_REQ,
  APP_ENGINEER_INFO_SUCCESS,
  APP_ENGINEER_INFO_FAILED,
  UPDATE_ENGINEER_INFO_REQ,
  UPDATE_ENGINEER_INFO_SUCCESS,
  UPDATE_ENGINEER_INFO_FAILED,
  GET_SUBMITTED_EVALUATORS_REQ,
  GET_SUBMITTED_EVALUATORS_SUCCESS,
  GET_SUBMITTED_EVALUATORS_FAILED,
  UPDATE_SUBMITTED_EVALUATORS_REQ,
  UPDATE_SUBMITTED_EVALUATORS_SUCCESS,
  UPDATE_SUBMITTED_EVALUATORS_FAILED,
  GET_SUBMITTED_INSPECTOR_REQ,
  GET_SUBMITTED_INSPECTOR_SUCCESS,
  GET_SUBMITTED_INSPECTOR_FAILED,
  UPDATE_SUBMITTED_INSPECTOR_REQ,
  UPDATE_SUBMITTED_INSPECTOR_SUCCESS,
  UPDATE_SUBMITTED_INSPECTOR_FAILED,
  GET_DATEAPPLIED_REQ,
  GET_DATEAPPLIED_SUCCESS,
  GET_DATEAPPLIED_FAILED,
} from '../constants/adminApplicationConstants'

// GET ALL APPLICATIONS
export const retrieveAppByID = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: APP_RETRIEVE_BY_STATUS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app`,
        config
      )

      dispatch({
        type: APP_RETRIEVE_BY_STATUS_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: APP_RETRIEVE_BY_STATUS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const dcAppChange = (info) => {
  return async (dispatch) => {
    try {
      dispatch({ type: APP_DC_CHANGE_STATUS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin-app/create-bp`,
        info,
        config
      )

      dispatch({
        type: APP_DC_CHANGE_STATUS_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: APP_DC_CHANGE_STATUS_FAILED,
        payload: 'Unable to update status',
      })
    }
  }
}

export const getRequiredDocs = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUIRED_DOCUMENTS_REQ })
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/required-documents`,
        config
      )

      dispatch({
        type: REQUIRED_DOCUMENTS_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: REQUIRED_DOCUMENTS_FAILED,
        payload: err,
      })
    }
  }
}

export const getEvaluators = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EVALUATORS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/get-evaluators`,
        config
      )

      dispatch({
        type: GET_EVALUATORS_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_EVALUATORS_FAILED,
        payload: err,
      })
    }
  }
}

export const getInspectors = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_INSPECTORS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/get-inspectors`,
        config
      )

      dispatch({
        type: GET_INSPECTORS_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_INSPECTORS_FAILED,
        payload: err,
      })
    }
  }
}

// Get Application Information
export const getApplicationInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APPLICATION_INFORMATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/application-information/${payload}`,
        config
      )

      dispatch({
        type: GET_APPLICATION_INFORMATION_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_APPLICATION_INFORMATION_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

// Update Application Info
export const updateApplicationInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_APPLICATION_INFORMATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin-app/application-edit-information`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_APPLICATION_INFORMATION_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: UPDATE_APPLICATION_INFORMATION_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

// Add Application Info
export const addApplicationInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_APPLICATION_INFORMATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin-app/application-add-information`,
        payload,
        config
      )

      dispatch({
        type: ADD_APPLICATION_INFORMATION_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: ADD_APPLICATION_INFORMATION_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

// Get Submitted Docs
export const getSubmittedDocs = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBMITTED_DOCS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/application-submitted-docs/${payload}`,
        config
      )

      dispatch({
        type: GET_SUBMITTED_DOCS_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_SUBMITTED_DOCS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

// Update Submitted Docs
export const updateSubmittedDocs = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_SUBMITTED_DOCS_REQ,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin-app/submitted-docs-edit/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_SUBMITTED_DOCS_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveAppByID())
      dispatch(getSubmittedDocs(payload.appID))
    } catch (err) {
      dispatch({
        type: UPDATE_SUBMITTED_DOCS_FAILED,
        payload: 'Failed to Update Info',
      })
    }
  }
}

// Submit Required Docs
export const submitRequiredDocs = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SUBMIT_REQUIRED_DOCS_REQ,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin-app/submitted-docs-add/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: SUBMIT_REQUIRED_DOCS_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveAppByID())
      dispatch(getSubmittedDocs(payload.appID))
    } catch (err) {
      dispatch({
        type: SUBMIT_REQUIRED_DOCS_FAILED,
        payload: 'Failed to Update Info',
      })
    }
  }
}

// Get Submitted Engineer
export const submittedEngInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: APP_ENGINEER_INFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/application-engineer-info/${payload}`,
        config
      )

      dispatch({
        type: APP_ENGINEER_INFO_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: APP_ENGINEER_INFO_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

// Update Engineer Information
export const updateEngInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_ENGINEER_INFO_REQ,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin-app/engineer-info-edit/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_ENGINEER_INFO_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: UPDATE_ENGINEER_INFO_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

// Get Submitted Evaluators
export const getSubEvaluators = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBMITTED_EVALUATORS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/application-permit-evaluators/${payload}`,
        config
      )

      dispatch({
        type: GET_SUBMITTED_EVALUATORS_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_SUBMITTED_EVALUATORS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

// Update Submitted Evaluators
export const updateSubEvaluators = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_SUBMITTED_EVALUATORS_REQ,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin-app/submitted-evaluators-edit/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_SUBMITTED_EVALUATORS_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: UPDATE_SUBMITTED_EVALUATORS_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

// Get Submitted Inspector
export const getSubInspector = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBMITTED_INSPECTOR_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/application-permit-inspector/${payload}`,
        config
      )

      dispatch({
        type: GET_SUBMITTED_INSPECTOR_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_SUBMITTED_INSPECTOR_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

// Update Submitted Inspector
export const updateSubInspector = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_SUBMITTED_INSPECTOR_REQ,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin-app/submitted-inspector-edit/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_SUBMITTED_INSPECTOR_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: UPDATE_SUBMITTED_INSPECTOR_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

// GET Application Date Applied
export const retrieveDateApplied = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DATEAPPLIED_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-app/date-applied/${payload.id}`,
        config
      )

      dispatch({
        type: GET_DATEAPPLIED_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_DATEAPPLIED_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}
