export const CREATE_ACCESSORIESFEE_ASSESSMENT_REQ =
  'CREATE_ACCESSORIESFEE_ASSESSMENT_REQ'
export const CREATE_ACCESSORIESFEE_ASSESSMENT_SUCCESS =
  'CREATE_ACCESSORIESFEE_ASSESSMENT_SUCCESS'
export const CREATE_ACCESSORIESFEE_ASSESSMENT_FAILED =
  'CREATE_ACCESSORIESFEE_ASSESSMENT_FAILED'

export const UPDATE_ACCESSORIESFEE_ASSESSMENT_REQ =
  'UPDATE_ACCESSORIESFEE_ASSESSMENT_REQ'
export const UPDATE_ACCESSORIESFEE_ASSESSMENT_SUCCESS =
  'UPDATE_ACCESSORIESFEE_ASSESSMENT_SUCCESS'
export const UPDATE_ACCESSORIESFEE_ASSESSMENT_FAILED =
  'UPDATE_ACCESSORIESFEE_ASSESSMENT_FAILED'

export const GET_ACCESSORIESFEE_ASSESSMENT_REQ =
  'GET_ACCESSORIESFEE_ASSESSMENT_REQ'
export const GET_ACCESSORIESFEE_ASSESSMENT_SUCCESS =
  'GET_ACCESSORIESFEE_ASSESSMENT_SUCCESS'
export const GET_ACCESSORIESFEE_ASSESSMENT_FAILED =
  'GET_ACCESSORIESFEE_ASSESSMENT_FAILED'
