import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const UserFooter = () => {
  return (
    <footer>
      <Navbar className='py-1' expand='lg' bg='white'>
        <Nav style={{ marginRight: '10px' }}>
          {/* <LinkContainer to='/about'>
            <Nav.Link>About</Nav.Link>
          </LinkContainer> */}
        </Nav>
        <Nav className='me-auto'>
          {/* <LinkContainer to='/privacy'>
            <Nav.Link>Privacy & Terms</Nav.Link>
          </LinkContainer> */}
        </Nav>
        <Nav className='pt-3 px-3'>
          <p>&copy; Office of the Building Official 2023</p>
        </Nav>
      </Navbar>
    </footer>
  )
}

export default UserFooter
