import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProtectedRoute from '../reusable/ProtectedRoute'

// Components
import ForInsPectionList from '../screens/InspectorScreens/ForInsPectionList'
import InspectionListReport from '../screens/InspectorScreens/InspectionListReport'
import CreateReportScreen from '../screens/InspectorScreens/CreateReportScreen'
import DcDashboardScreen from '../screens/DcScreens/DcDashboardScreen'
import ExpiredTokenScreen from '../screens/ExpiredTokenScreen'
import EmployeeProfileScreen from '../screens/DcScreens/EmployeeProfileScreen'
import NoticeOfViolationListScreen from '../screens/InspectorScreens/NoticeOfViolationListScreen'
import EditReportScreen from '../screens/InspectorScreens/EditReportScreen'

// Supervisor
import ForRecommendationListScreen from '../screens/InspectorScreens/Supervisor/ForRecommendationListScreen'
import RecommendedListScreen from '../screens/InspectorScreens/Supervisor/RecommendedListScreen'

const InspectorRoutes = ({ user, subroles }) => {
  if (subroles !== 'supervisor') {
    return (
      <Switch>
        {/* <ProtectedRoute
          exact
          path='/dashboard'
          component={DcDashboardScreen}
          isAuth={user === null ? false : true}
        /> */}

        <ProtectedRoute
          exact
          path='/dashboard'
          component={ForInsPectionList}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/inspection-list'
          component={InspectionListReport}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/create-edit-report/:id?'
          component={CreateReportScreen}
          isAuth={user === null ? false : true}
        />
        <ProtectedRoute
          exact
          path='/edit-report/:id?'
          component={EditReportScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/profile'
          component={EmployeeProfileScreen}
          isAuth={user === null ? false : true}
        />

        <ProtectedRoute
          exact
          path='/notice-of-violation'
          component={NoticeOfViolationListScreen}
          isAuth={user === null ? false : true}
        />

        <Route component={ExpiredTokenScreen} />
      </Switch>
    )
  }

  return (
    <Switch>
      <ProtectedRoute
        exact
        path='/dashboard'
        component={DcDashboardScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/for-recommendation-list'
        component={ForRecommendationListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/recommendation-list'
        component={RecommendedListScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/profile'
        component={EmployeeProfileScreen}
        isAuth={user === null ? false : true}
      />

      <ProtectedRoute
        exact
        path='/notice-of-violation'
        component={NoticeOfViolationListScreen}
        isAuth={user === null ? false : true}
      />

      <Route component={ExpiredTokenScreen} />
    </Switch>
  )
}

export default InspectorRoutes
