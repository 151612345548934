import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FaBars, FaPlay } from 'react-icons/fa'

// Components
import UserHeader from './UserHeader'
import UserFooter from './UserFooter'
import UserSidebar from './UserSidebar'

// Custom Hooks
import useToggle from '../../customHooks/useToggle'

const Layout = ({ children }) => {
  const { toggled, handleToggleSidebar } = useToggle()

  return (
    <div className='app'>
      <UserSidebar
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      />

      <div id='main-content'>
        {/* <div className='btn-toggle' onClick={() => handleToggleSidebar(true)}>
          <FaPlay />
        </div> */}
        <header>
          <UserHeader
            icons={
              <FaPlay
                className='mx-2 fs-5 text-primary d-xl-none'
                onClick={() => handleToggleSidebar(true)}
              />
            }
          />
        </header>

        <main
          style={{
            background: '#f9f6f1',
            color: '#00304b',
            // backgroundImage: 'url(./assets/img/lp-bldgo-bg.webp)',
            // backgroundSize: 'cover',
            // backgroundPosition: 'center center',
          }}
        >
          {children}
        </main>

        <footer className='mt-auto'>
          <UserFooter />
        </footer>
      </div>
    </div>
  )
}

export default Layout
