import React, { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  Table,
  Modal,
  Pagination,
  Spinner,
  Card,
} from 'react-bootstrap'
import {
  FaCreditCard,
  FaCheckCircle,
  FaEye,
  FaCalendarAlt,
  FaRegFileAlt,
  FaPlusCircle,
} from 'react-icons/fa'
import _ from 'lodash'
import { useToast } from 'rc-toastr'
import io from 'socket.io-client'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import Loading from '../../reusable/Loading'
import Error from '../../reusable/Error'
import MessagesColumn from '../../reusable/MessagesColumn'
import NewsNUpdatesColumn from '../../reusable/NewsNUpdatesColumn'

// Redux Requirement
import { useDispatch, useSelector } from 'react-redux'
import { retrieveAppByID } from '../../redux/actions/adminApplicationActions'
import {
  messagesRetrieve,
  messagesRetrieveById,
} from '../../redux/actions/messageActions'
import { insertNewsAndUpdate } from '../../redux/actions/DocumentControllers/documentControllerActions'
import { newsandupdatesRetrieve } from '../../redux/actions/newsandupdatesActions'

const socket = io.connect(process.env.REACT_APP_URL)

const DcDashboardScreen = () => {
  const [show, setShow] = useState(false)
  const [viewMessage, setViewMessage] = useState(false)
  const [viewNewsNUpdates, setViewNewsNUpdates] = useState(false)
  const [payload, setPayload] = useState({
    header: '',
    subheader: '',
    body: '',
    url: '',
  })

  const dispatch = useDispatch()
  const messages = useSelector((state) => state.messages)
  const { messageList, message, messageID } = messages

  const newsandupdates = useSelector((state) => state.newsandupdates)
  const { createdNewsAndUpdates, newsDetails } = newsandupdates

  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const documentControllerReducers = useSelector(
    (state) => state.documentControllerReducer
  )
  const { insertedNewsandUpdate } = documentControllerReducers

  const applications = useSelector((state) => state.adminApplication)
  const { applicationDetails } = applications

  const handleViewMessage = (id) => {
    dispatch(messagesRetrieveById(id))
    setViewMessage(true)
  }

  const handleViewNewsNUpdates = (payload) => {
    setPayload({
      header: payload.header,
      subheader: payload.subheader,
      body: payload.body,
      url: payload.url,
    })
    setViewNewsNUpdates(true)
  }

  const { toast } = useToast()
  const sayHello = (data) => {
    toast.success(data)
  }

  useEffect(() => {
    dispatch(retrieveAppByID())
    dispatch(messagesRetrieve())
    dispatch(newsandupdatesRetrieve())
    socket.on('received_new_application', (data) => {
      // if (window.confirm(`${data.message} do you want to reload the page ?`)) {
      sayHello(data.message)
      // console.log(data)
      // }
    })
  }, [dispatch, socket])

  // console.log(newsDetails)
  // console.log(messageList)

  return (
    <>
      <UserMain>
        <Container fluid>
          {/* For Payment Verification Table */}
          <Row>
            <Col md={3} className='my-2'>
              <Row
                className='pt-4 px-4 border border-secondary rounded mx-2 shadow-sm'
                style={{ background: '#FFF' }}
              >
                <Col sm={12} md={12} lg={6} xl={6} className='pb-3'>
                  <img
                    src='assets/img/file.png'
                    alt='/'
                    // className='img-fluid'
                    style={{ height: '80px', width: '80px' }}
                  />
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className='text-end fw-bold fs-3'
                >
                  <p className='fs-6'>TOTAL NUMBER</p>
                  {applicationDetails && applicationDetails.loading ? (
                    <Spinner animation='border' />
                  ) : applicationDetails && applicationDetails.message ? (
                    applicationDetails.message.filter(
                      (x) => x.status === 'APPLICATION VERIFICATION'
                    ).length
                  ) : (
                    0
                  )}
                </Col>
                <hr />
                <h6 className='pb-2'>APPLICATION VERIFICATION</h6>
              </Row>
            </Col>

            <Col md={3} className='my-2'>
              <Row
                className='pt-4 px-4 border border-secondary rounded mx-2 shadow-sm'
                style={{ background: '#FFF' }}
              >
                <Col sm={12} md={12} lg={6} xl={6} className='pb-3'>
                  <img
                    src='assets/img/calendar.png'
                    alt='/'
                    // className='img-fluid'
                    style={{ height: '80px', width: '80px' }}
                  />
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className='text-end fw-bold fs-3'
                >
                  <p className='fs-6'>TOTAL NUMBER</p>
                  {applicationDetails && applicationDetails.loading ? (
                    <Spinner animation='border' />
                  ) : applicationDetails && applicationDetails.message ? (
                    applicationDetails.message.filter(
                      (x) => x.status === 'FOR INSPECTION AND EVALUATION'
                    ).length
                  ) : (
                    0
                  )}
                </Col>
                <hr />
                <h6 className='pb-2'>FOR INSPECTION AND EVALUATION</h6>
              </Row>
            </Col>

            <Col md={3} className='my-2'>
              <Row
                className='pt-4 px-4 border border-secondary rounded mx-2 shadow-sm'
                style={{ background: '#FFF' }}
              >
                <Col sm={12} md={12} lg={6} xl={6} className='pb-3'>
                  <img
                    src='assets/img/folder.png'
                    alt='/'
                    // className='img-fluid'
                    style={{ height: '80px', width: '80px' }}
                  />
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className='text-end fw-bold fs-3'
                >
                  <p className='fs-6'>TOTAL NUMBER</p>
                  {applicationDetails && applicationDetails.loading ? (
                    <Spinner animation='border' />
                  ) : applicationDetails && applicationDetails.message ? (
                    applicationDetails.message.filter(
                      (x) => x.status === 'FOR COMPLIANCE'
                    ).length
                  ) : (
                    0
                  )}
                </Col>
                <hr />
                <h6 className='pb-2'>FOR COMPLIANCE</h6>
              </Row>
            </Col>

            <Col md={3} className='my-2'>
              <Row
                className='pt-4 px-4 border border-secondary rounded mx-2 shadow-sm'
                style={{ background: '#FFF' }}
              >
                <Col sm={12} md={12} lg={6} xl={6} className='pb-3'>
                  <img
                    src='assets/img/approved.png'
                    alt='/'
                    // className='img-fluid'
                    style={{ height: '80px', width: '80px' }}
                  />
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className='text-end fw-bold fs-3'
                >
                  <p className='fs-6'>TOTAL NUMBER</p>
                  {applicationDetails && applicationDetails.loading ? (
                    <Spinner animation='border' />
                  ) : applicationDetails && applicationDetails.message ? (
                    applicationDetails.message.filter(
                      (x) => x.status === 'APPROVED APPLICATION'
                    ).length
                  ) : (
                    0
                  )}
                </Col>
                <hr />
                <h6 className='pb-2'>APPROVED APPLICATION</h6>
              </Row>
            </Col>
          </Row>
          {/* 
          <hr className='mt-5 mb-1' /> */}

          {/* Messages & News N Updates */}
          <Row>
            <Col xl={6}>
              <MessagesColumn
                messageList={messageList}
                handleViewMessage={handleViewMessage}
              />
            </Col>
            <Col xl={6}>
              <NewsNUpdatesColumn
                newsDetails={newsDetails}
                handleViewNewsNUpdates={handleViewNewsNUpdates}
              />
            </Col>
          </Row>

          {/* <button onClick={() => sayHello('hi')}> Say Hello </button> */}

          {/* Message Modal */}
          <Modal
            show={viewMessage}
            onHide={() => setViewMessage(false)}
            centered
          >
            <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaEye className='mx-3' />
                View Message
              </Modal.Title>
            </Modal.Header>
            {messageID && messageID.message && (
              <Form>
                <Modal.Body>
                  <Row>
                    <Col md={3} className='py-2 fw-bold'>
                      Name:
                    </Col>
                    <Form.Group as={Col} md={9}>
                      <Form.Control
                        disabled
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={messageID.message[0].Name}
                        type='text'
                      />
                    </Form.Group>
                  </Row>

                  <Row className='py-2'>
                    <Col md={3} className='py-2 fw-bold'>
                      Email:
                    </Col>
                    <Form.Group as={Col} md={9}>
                      <Form.Control
                        disabled
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={messageID.message[0].Email}
                        type='text'
                      />
                    </Form.Group>
                  </Row>

                  <Row className='py-1'>
                    <Col md={3} className='py-2 fw-bold'>
                      Contact No.:
                    </Col>
                    <Form.Group as={Col} md={9}>
                      <Form.Control
                        disabled
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={messageID.message[0].ContactNo}
                        type='text'
                      />
                    </Form.Group>
                  </Row>

                  <Row className='py-2'>
                    <Col md={3} className='py-2 fw-bold'>
                      Message:
                    </Col>
                    <Form.Group as={Col} md={9}>
                      <Form.Control
                        disabled
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={messageID.message[0].Message}
                        as='textarea'
                      />
                    </Form.Group>
                  </Row>

                  <Row className='py-2'>
                    <Col md={3} className='py-2 fw-bold'>
                      Date:
                    </Col>
                    <Form.Group as={Col} md={9}>
                      <Form.Control
                        disabled
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={messageID.message[0].TimeStamp}
                        onKeyDown={(e) => e.preventDefault()}
                        type='text'
                      />
                    </Form.Group>
                  </Row>
                  <Row className='py-2'>
                    <Col md={3} className='py-2 fw-bold'>
                      Image:
                    </Col>
                    <img
                      src={`data:image/png;base64,${messageID.message[0].image}`}
                    />
                  </Row>
                  <hr />
                </Modal.Body>
                <Modal.Footer className='p-1'>
                  <Button
                    variant='danger'
                    onClick={() => setViewMessage(false)}
                  >
                    Back
                  </Button>
                  {/* <Button variant='info'>Next</Button> */}
                </Modal.Footer>
              </Form>
            )}

            {messageID && messageID.loading && (
              <div className='text-center my-5'>
                <Spinner animation='border' />
                <h6 className='mt-3'>please wait a minute</h6>
              </div>
            )}
          </Modal>

          {/* News And Update Modal */}
          <Modal
            show={viewNewsNUpdates}
            onHide={() => setViewNewsNUpdates(false)}
            centered
          >
            <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaEye className='mx-3' />
                News & Updates
              </Modal.Title>
            </Modal.Header>
            {newsDetails && newsDetails.message && (
              <Form>
                <Modal.Body>
                  <Row>
                    <Col md={3} className='py-2 fw-bold'>
                      Header:
                    </Col>
                    <Form.Group as={Col} md={9}>
                      <Form.Control
                        disabled
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={payload.header}
                        type='text'
                      />
                    </Form.Group>
                  </Row>

                  <Row className='py-2'>
                    <Col md={3} className='py-2 fw-bold'>
                      Sub-header:
                    </Col>
                    <Form.Group as={Col} md={9}>
                      <Form.Control
                        disabled
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={payload.subheader}
                        type='text'
                      />
                    </Form.Group>
                  </Row>

                  <Row className='py-1'>
                    <Col md={3} className='py-2 fw-bold'>
                      Body:
                    </Col>
                    <Form.Group as={Col} md={9}>
                      <Form.Control
                        disabled
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={payload.body}
                        as='textarea'
                      />
                    </Form.Group>
                  </Row>

                  <Row className='py-2'>
                    <Col md={3} className='py-2 fw-bold'>
                      Attachments:
                    </Col>
                    <img src={`data:image/png;base64,${payload.url}`} />
                  </Row>
                  <hr />
                </Modal.Body>
                <Modal.Footer className='p-1'>
                  <Button
                    variant='danger'
                    onClick={() => setViewNewsNUpdates(false)}
                  >
                    Back
                  </Button>
                  {/* <Button variant='info'>Next</Button> */}
                </Modal.Footer>
              </Form>
            )}
          </Modal>
        </Container>
      </UserMain>
    </>
  )
}

export default DcDashboardScreen
