import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Button,
  InputGroup,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import AnnualCollectionSheet from '../../reusable/AnnualCollectionSheet'

// Actions
import { retrieveAnnualCollection } from '../../redux/actions/Administrator/administratorActions'

const AnnualReportScreen = () => {
  const dispatch = useDispatch()

  let arrayMonth = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ]

  const [totalAmount, setTotalAmount] = useState({
    totalBuildingPermit: 0,
    totalElectricalPermit: 0,
    totalPlumbingPermit: 0,
    totalMechanicalPermit: 0,
    totalElectronicsPermit: 0,
    totalOccupancyPermit: 0,
    totalDemolitionPermit: 0,
    totalFencingPermit: 0,
    totalSignPermit: 0,
    totalAnnualInspection: 0,
    totalPenalty: 0,
    totalAdminFine: 0,
    totalCollection: 0,
    totalDPWH: 0,
    totalOBO: 0,
    totalEighty: 0,
    totalTen: 0,
    totalRevenue: 0,
  })

  const [year, setYear] = useState('')

  const [state, setState] = useState([])

  const administratorReducers = useSelector(
    (state) => state.administratorReducer
  )

  const { retrievedAnnualCollection } = administratorReducers

  function formatCurrency(value) {
    // console.log(value)
    if (isNaN(value)) {
      return 0
    } else {
      if (value !== null) {
        return value.toLocaleString('en-PH', {
          style: 'currency',
          currency: 'PHP',
        })
      } else {
        return value
      }
    }
  }

  function formatCurrencyPrint(value) {
    // console.log(value)
    if (isNaN(value)) {
      return 0
    } else {
      if (value !== null) {
        return value.toLocaleString('en-PH')
      } else {
        return value
      }
    }
  }

  useEffect(() => {
    if (retrievedAnnualCollection && retrievedAnnualCollection.message) {
      setState(retrievedAnnualCollection.message)

      setTotalAmount({
        totalBuildingPermit: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.BuildingPermit,
          0
        ),
        totalElectricalPermit: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.ElectricalPermit,
          0
        ),
        totalPlumbingPermit: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.PlumbingPermit,
          0
        ),
        totalMechanicalPermit: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.MechanicalPermit,
          0
        ),
        totalElectronicsPermit: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.ElectronicPermit,
          0
        ),
        totalOccupancyPermit: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.OccupancyPermit,
          0
        ),
        totalDemolitionPermit: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.DemolitionPermit,
          0
        ),
        totalFencingPermit: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.FencingPermit,
          0
        ),
        totalSignPermit: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.SignPermit,
          0
        ),
        totalAnnualInspection: 0,
        totalPenalty: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.PenaltySurcharge,
          0
        ),
        totalAdminFine: retrievedAnnualCollection.message.reduce(
          (acc, curr) => acc + curr.AdminFine,
          0
        ),
        totalCollection: 0,
        totalDPWH: 0,
        totalOBO: 0,
        totalEighty: 0,
      })
    }

    if (state.length > 0) {
      setTotalAmount({
        ...totalAmount,
        totalCollection:
          totalAmount.totalBuildingPermit +
          totalAmount.totalElectricalPermit +
          totalAmount.totalPlumbingPermit +
          totalAmount.totalMechanicalPermit +
          totalAmount.totalElectronicsPermit +
          totalAmount.totalOccupancyPermit +
          totalAmount.totalDemolitionPermit +
          totalAmount.totalFencingPermit +
          totalAmount.totalSignPermit +
          totalAmount.totalPenalty +
          totalAmount.totalAdminFine,
        totalDPWH:
          (totalAmount.totalBuildingPermit +
            totalAmount.totalElectricalPermit +
            totalAmount.totalPlumbingPermit +
            totalAmount.totalMechanicalPermit +
            totalAmount.totalElectronicsPermit +
            totalAmount.totalOccupancyPermit +
            totalAmount.totalDemolitionPermit +
            totalAmount.totalFencingPermit +
            totalAmount.totalSignPermit +
            totalAmount.totalPenalty +
            totalAmount.totalAdminFine) *
          0.05,
        totalOBO:
          (totalAmount.totalBuildingPermit +
            totalAmount.totalElectricalPermit +
            totalAmount.totalPlumbingPermit +
            totalAmount.totalMechanicalPermit +
            totalAmount.totalElectronicsPermit +
            totalAmount.totalOccupancyPermit +
            totalAmount.totalDemolitionPermit +
            totalAmount.totalFencingPermit +
            totalAmount.totalSignPermit +
            totalAmount.totalPenalty +
            totalAmount.totalAdminFine) *
          0.15,
        totalEighty:
          (totalAmount.totalBuildingPermit +
            totalAmount.totalElectricalPermit +
            totalAmount.totalPlumbingPermit +
            totalAmount.totalMechanicalPermit +
            totalAmount.totalElectronicsPermit +
            totalAmount.totalOccupancyPermit +
            totalAmount.totalDemolitionPermit +
            totalAmount.totalFencingPermit +
            totalAmount.totalSignPermit +
            totalAmount.totalPenalty +
            totalAmount.totalAdminFine) *
          0.8,
        totalTen:
          (totalAmount.totalBuildingPermit +
            totalAmount.totalElectricalPermit +
            totalAmount.totalPlumbingPermit +
            totalAmount.totalMechanicalPermit +
            totalAmount.totalElectronicsPermit +
            totalAmount.totalOccupancyPermit +
            totalAmount.totalDemolitionPermit +
            totalAmount.totalFencingPermit +
            totalAmount.totalSignPermit +
            totalAmount.totalPenalty +
            totalAmount.totalAdminFine) *
          0.1,
        totalRevenue:
          (totalAmount.totalBuildingPermit +
            totalAmount.totalElectricalPermit +
            totalAmount.totalPlumbingPermit +
            totalAmount.totalMechanicalPermit +
            totalAmount.totalElectronicsPermit +
            totalAmount.totalOccupancyPermit +
            totalAmount.totalDemolitionPermit +
            totalAmount.totalFencingPermit +
            totalAmount.totalSignPermit +
            totalAmount.totalPenalty +
            totalAmount.totalAdminFine) *
            0.1 +
          (totalAmount.totalBuildingPermit +
            totalAmount.totalElectricalPermit +
            totalAmount.totalPlumbingPermit +
            totalAmount.totalMechanicalPermit +
            totalAmount.totalElectronicsPermit +
            totalAmount.totalOccupancyPermit +
            totalAmount.totalDemolitionPermit +
            totalAmount.totalFencingPermit +
            totalAmount.totalSignPermit +
            totalAmount.totalPenalty +
            totalAmount.totalAdminFine),
      })
    }
  }, [retrievedAnnualCollection, state])

  // console.log(totalAmount)

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg py-5'
          style={{ background: '#FFF' }}
        >
          <Row>
            <Col xl='3'>
              <h6 className='fw-bold'>Choose Year of Collection</h6>
              <InputGroup>
                <Form.Control
                  type='date'
                  size='sm'
                  onChange={(e) => {
                    let selectedYear = new Date(e.target.value).getFullYear()
                    setYear(selectedYear)
                  }}
                />
                <Button
                  variant='success'
                  size='sm'
                  onClick={() =>
                    dispatch(
                      retrieveAnnualCollection({
                        year: year,
                      })
                    )
                  }
                >
                  Submit
                </Button>
              </InputGroup>
            </Col>
          </Row>

          <hr />

          <h6 className='fw-bold pt-3'>
            Republic of Philippines <br />
            City of Las Piñas <br />
            Engineering Abstract of Collection
          </h6>

          <Table size='sm' responsive className='my-3'>
            <thead>
              <tr>
                <th></th>
                <th className='fw-bold'>January</th>
                <th className='fw-bold'>February</th>
                <th className='fw-bold'>March</th>
                <th className='fw-bold'>April</th>
                <th className='fw-bold'>May</th>
                <th className='fw-bold'>June</th>
                <th className='fw-bold'>July</th>
                <th className='fw-bold'>August</th>
                <th className='fw-bold'>September</th>
                <th className='fw-bold'>October</th>
                <th className='fw-bold'>November</th>
                <th className='fw-bold'>December</th>
                <th className='fw-bold'>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Building Permit</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.BuildingPermit)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalBuildingPermit)}</td>
                )}
              </tr>
              <tr>
                <td>Electrical Permit</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.ElectricalPermit)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalElectricalPermit)}</td>
                )}
              </tr>
              <tr>
                <td>Plumbing Permit</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.PlumbingPermit)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalPlumbingPermit)}</td>
                )}
              </tr>
              <tr>
                <td>Mechanical Permit</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.MechanicalPermit)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalMechanicalPermit)}</td>
                )}
              </tr>
              <tr>
                <td>Electronics Permit</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.ElectronicPermit)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalElectronicsPermit)}</td>
                )}
              </tr>
              <tr>
                <td>Occupancy Permit</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.OccupancyPermit)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalOccupancyPermit)}</td>
                )}
              </tr>
              <tr>
                <td>Demolition Permit</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.DemolitionPermit)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalDemolitionPermit)}</td>
                )}
              </tr>
              <tr>
                <td>Fencing Permit</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.FencingPermit)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalFencingPermit)}</td>
                )}
              </tr>
              <tr>
                <td>Sign Permit</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.SignPermit)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalSignPermit)}</td>
                )}
              </tr>
              <tr>
                <td>Annual Ins. Fees</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>{value !== undefined ? '-' : '-'}</td>
                    )
                  })}
                {state.length > 0 && <td>-</td>}

                {/* {state.length > 0 && (
                  <td>
                    {state.reduce((acc, curr) => acc + curr.SignPermit, 0)}
                  </td>
                )} */}
              </tr>
              <tr>
                <td>Penalty/Surcharge</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.PenaltySurcharge)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalPenalty)}</td>
                )}
              </tr>
              <tr>
                <td>Admin. Fine</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(value.AdminFine)
                          : '-'}
                      </td>
                    )
                  })}

                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalAdminFine)}</td>
                )}
              </tr>
              <tr className='fw-bold'>
                <td>Total</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(
                              value.BuildingPermit +
                                value.ElectricalPermit +
                                value.PlumbingPermit +
                                value.MechanicalPermit +
                                value.ElectronicPermit +
                                value.OccupancyPermit +
                                value.DemolitionPermit +
                                value.FencingPermit +
                                value.SignPermit +
                                value.PenaltySurcharge +
                                value.AdminFine
                            )
                          : '-'}
                      </td>
                    )
                  })}
                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalCollection)}</td>
                )}
              </tr>
              <tr className='fw-bold'>
                <td>5% (DPWH)</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(
                              (value.BuildingPermit +
                                value.ElectricalPermit +
                                value.PlumbingPermit +
                                value.MechanicalPermit +
                                value.ElectronicPermit +
                                value.OccupancyPermit +
                                value.DemolitionPermit +
                                value.FencingPermit +
                                value.SignPermit +
                                value.PenaltySurcharge +
                                value.AdminFine) *
                                0.05
                            )
                          : '-'}
                      </td>
                    )
                  })}
                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalDPWH)}</td>
                )}
              </tr>
              <tr className='fw-bold'>
                <td>15% (OBO)</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(
                              (value.BuildingPermit +
                                value.ElectricalPermit +
                                value.PlumbingPermit +
                                value.MechanicalPermit +
                                value.ElectronicPermit +
                                value.OccupancyPermit +
                                value.DemolitionPermit +
                                value.FencingPermit +
                                value.SignPermit +
                                value.PenaltySurcharge +
                                value.AdminFine) *
                                0.15
                            )
                          : '-'}
                      </td>
                    )
                  })}
                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalOBO)}</td>
                )}
              </tr>
              <tr className='fw-bold'>
                <td>80%</td>
                {state.length > 0 &&
                  arrayMonth.map((el, index) => {
                    let value = state.find((e) => e.Month === el)

                    return (
                      <td key={index}>
                        {value !== undefined
                          ? formatCurrency(
                              (value.BuildingPermit +
                                value.ElectricalPermit +
                                value.PlumbingPermit +
                                value.MechanicalPermit +
                                value.ElectronicPermit +
                                value.OccupancyPermit +
                                value.DemolitionPermit +
                                value.FencingPermit +
                                value.SignPermit +
                                value.PenaltySurcharge +
                                value.AdminFine) *
                                0.8
                            )
                          : '-'}
                      </td>
                    )
                  })}
                {state.length > 0 && (
                  <td>{formatCurrency(totalAmount.totalEighty)}</td>
                )}
              </tr>
            </tbody>
          </Table>

          {state.length > 0 && (
            <Row>
              <Col xl='10'></Col>
              <Col xl='2'>
                <div className='d-flex'>
                  <h6 className='w-50 fw-bold'>10%</h6>
                  <h6 className='fw-bold'>
                    {formatCurrency(totalAmount.totalTen)}
                  </h6>
                </div>
              </Col>
            </Row>
          )}

          {state.length > 0 && (
            <Row>
              <Col xl='10' className='text-end fw-bold'>
                PROJECTED REVENUE ESTIMATE FOR NEXT CY
              </Col>
              <Col xl='2'>
                <div className='d-flex '>
                  <h6 className='w-50 fw-bold'>TOTAL</h6>
                  <h6 className='fw-bold'>
                    {formatCurrency(totalAmount.totalRevenue)}
                  </h6>
                </div>
              </Col>
            </Row>
          )}

          {state.length > 0 && (
            <div className='text-end mt-3 mx-4'>
              <PDFDownloadLink
                document={
                  <AnnualCollectionSheet
                    state={state}
                    arrayMonth={arrayMonth}
                    totalAmount={totalAmount}
                    formatCurrency={formatCurrencyPrint}
                  />
                }
                fileName='Annual Report.pdf'
              >
                {({ loading }) =>
                  loading ? (
                    'Loading document...'
                  ) : (
                    <Button variant='info'>PRINT</Button>
                  )
                }
              </PDFDownloadLink>
            </div>
          )}
        </Container>
      </Container>
    </UserMain>
  )
}

export default AnnualReportScreen
