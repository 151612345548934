import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_PLUMBING_EVALUATION_DETAILS_REQ,
  GET_PLUMBING_EVALUATION_DETAILS_SUCCESS,
  GET_PLUMBING_EVALUATION_DETAILS_FAILED,
  CREATE_PLUMBING_EVALUATION_REQ,
  CREATE_PLUMBING_EVALUATION_SUCCESS,
  CREATE_PLUMBING_EVALUATION_FAILED,
  UPDATE_PLUMBING_EVALUATION_REQ,
  UPDATE_PLUMBING_EVALUATION_SUCCESS,
  UPDATE_PLUMBING_EVALUATION_FAILED,
} from '../constants/evaluatorPlumbingConstants'

export const retrieveEvaluationDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PLUMBING_EVALUATION_DETAILS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/evaluator/plumbing/evaluation-details/${payload.userID}`,
        payload,
        config
      )

      dispatch({
        type: GET_PLUMBING_EVALUATION_DETAILS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_PLUMBING_EVALUATION_DETAILS_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const addEvaluation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_PLUMBING_EVALUATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/evaluator/plumbing`,
        payload,
        config
      )

      dispatch({
        type: CREATE_PLUMBING_EVALUATION_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: CREATE_PLUMBING_EVALUATION_FAILED,
        payload: 'TRANSACTION FAILED',
      })
    }
  }
}

export const editEvaluation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PLUMBING_EVALUATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/evaluator/plumbing`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_PLUMBING_EVALUATION_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_PLUMBING_EVALUATION_FAILED,
        payload: 'TRANSACTION FAILED',
      })
    }
  }
}
