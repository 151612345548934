import {
  CREATE_MECHANICAL_ASSESSMENT_REQ,
  CREATE_MECHANICAL_ASSESSMENT_SUCCESS,
  CREATE_MECHANICAL_ASSESSMENT_FAILED,
  GET_MECHANICAL_ASSESSMENT_REQ,
  GET_MECHANICAL_ASSESSMENT_SUCCESS,
  GET_MECHANICAL_ASSESSMENT_FAILED,
  UPDATE_MECHANICAL_ASSESSMENT_REQ,
  UPDATE_MECHANICAL_ASSESSMENT_SUCCESS,
  UPDATE_MECHANICAL_ASSESSMENT_FAILED,
} from '../../constants/Assessment/mechanicalAssessmentConstants'

export const mechanicalAssessmentReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_MECHANICAL_ASSESSMENT_REQ:
      return {
        ...state,
        createAssessment: { loading: true },
      }

    case CREATE_MECHANICAL_ASSESSMENT_SUCCESS:
      return {
        ...state,
        createAssessment: { loading: false, message: action.payload },
      }

    case CREATE_MECHANICAL_ASSESSMENT_FAILED:
      return {
        ...state,
        createAssessment: { loading: false, error: action.payload },
      }
    case UPDATE_MECHANICAL_ASSESSMENT_REQ:
      return {
        ...state,
        updateAssessment: { loading: true },
      }

    case UPDATE_MECHANICAL_ASSESSMENT_SUCCESS:
      return {
        ...state,
        updateAssessment: { loading: false, message: action.payload },
      }

    case UPDATE_MECHANICAL_ASSESSMENT_FAILED:
      return {
        ...state,
        updateAssessment: { loading: false, error: action.payload },
      }
    case GET_MECHANICAL_ASSESSMENT_REQ:
      return {
        ...state,
        assessmentDetails: { loading: true },
      }

    case GET_MECHANICAL_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessmentDetails: { loading: false, message: action.payload },
      }

    case GET_MECHANICAL_ASSESSMENT_FAILED:
      return {
        ...state,
        assessmentDetails: { loading: false, error: action.payload },
      }

    default:
      return state
  }
}
