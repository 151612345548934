export const plumbingData = [
  {
    id: 1,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 100',
    code_name: 'Permits Required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 2,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 101 & 102.1.7',
    code_name: 'Professional services, signed and sealed',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 3,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 103.1',
    code_name: 'Permits Issuance',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 4,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 103.2',
    code_name: 'Retention of plans',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 5,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 103.3',
    code_name: 'Validity of plans',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 6,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 103.4',
    code_name: 'Expiration',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 7,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 103.5',
    code_name: 'Suspension of Revocation',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 8,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 104',
    code_name: 'Application for Existing Plumbing System',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 9,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 105',
    code_name: 'Inspections',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 10,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 106',
    code_name: 'Connection Approval',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 11,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 107',
    code_name: 'Special Provisions',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 12,
    code_header: 'Chapter 1',
    header: 'Administration',
    code_id: 'Sec 108',
    code_name: 'Plumbing Fixtures required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 13,
    code_header: 'Chapter 2',
    header: 'Definitions',
    code_id: 'Sec 201',
    code_name: 'Terms and Definitions',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 14,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 301',
    code_name: 'Disposal of Waste Water',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 15,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 302',
    code_name: 'Grade of Horizontal Drainage Piping',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 16,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 303',
    code_name: 'Changes in Direction of Drainage Flow',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 17,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 304',
    code_name: 'Connection to Plumbing System Required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 18,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 305',
    code_name: 'Sewer Required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 19,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 306',
    code_name: 'Damage to Drainage System or Public Sewer',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 20,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 307',
    code_name: 'Industrial Waste',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 21,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 308',
    code_name: 'Location',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 22,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 309',
    code_name: 'Improper Location',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 23,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 310',
    code_name: 'Workmanship',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 24,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 311',
    code_name: 'Prohibited Fittings and Practices',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 25,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 312',
    code_name: 'Independent System',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 26,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 313',
    code_name: 'Repairs and Alterations',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 27,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 313',
    code_name: 'Repairs and Alterations',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 28,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 314',
    code_name: 'Protection of Piping, Materials and Structures',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 29,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 315',
    code_name: 'Hangers and Supports',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 30,
    code_header: 'Chapter 3',
    header: 'General Regulations',
    code_id: 'Sec 316',
    code_name: 'Trenching, Excavation and Backfill',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 31,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 401',
    code_name: 'Materials',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 32,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 402',
    code_name: 'Materials Alternatives',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 33,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 403',
    code_name: 'Overflows',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 34,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 404',
    code_name: 'Strainers and Connections',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 35,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 405',
    code_name: 'Prohibited Fixtures',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 36,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 406',
    code_name: 'Special Fixtures and Specialties',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 37,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 407',
    code_name: 'Installations',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 38,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 408',
    code_name: 'Urinals',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 39,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 409',
    code_name: 'Floor Drains and Shower Stalls',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 40,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 410',
    code_name: 'Plumbing Fixtures required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 41,
    code_header: 'Chapter 4',
    header: 'Plumbing Fixtures',
    code_id: 'Sec 411',
    code_name: 'Whirlpool Bathtubs',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 42,
    code_header: 'Chapter 5',
    header: 'Inspection and Test',
    code_id: 'Sec 501',
    code_name: 'Inspection and Testing',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 44,
    code_header: 'Chapter 5',
    header: 'Inspection and Test',
    code_id: 'Sec 502',
    code_name: 'Maintenance',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 45,
    code_header: 'Chapter 5',
    header: 'Inspection and Test',
    code_id: 'Sec 503',
    code_name: 'Existing Construction',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 46,
    code_header: 'Chapter 5',
    header: 'Inspection and Test',
    code_id: 'Sec 504',
    code_name: 'Health and Safety',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 47,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 601',
    code_name: 'Running Water required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 48,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 602',
    code_name: 'Unlawful Connections',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 49,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 603',
    code_name: 'Cross-connection Control',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 50,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 604',
    code_name: 'Materials',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 51,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 605',
    code_name: 'Valves',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 52,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 606',
    code_name: 'Gravity Supply Tanks',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 53,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 607',
    code_name:
      'Water Pressure, Pressure Regulators, and Pressure Relief Valves',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 54,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 608',
    code_name: 'Installation, Inspection and Testing',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 55,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 609',
    code_name: 'Size of Potable Water Piping',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 56,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 610',
    code_name: 'Water Conservation',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 57,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 611',
    code_name: 'Piping Installed in Fire Resistive Construction',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 58,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 612',
    code_name: 'Hangers and Supports Abrasion',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 59,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 613',
    code_name: 'Support',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 60,
    code_header: 'Chapter 6',
    header: 'Water Supply and Distribution',
    code_id: 'Sec 614',
    code_name: 'Thrust Blocking',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 61,
    code_header: 'Chapter 7',
    header: 'Excreta Drainage System',
    code_id: 'Sec 701',
    code_name: 'Materials',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 62,
    code_header: 'Chapter 7',
    header: 'Excreta Drainage System',
    code_id: 'Sec 702',
    code_name: 'Fixture Unit Equivalent',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 63,
    code_header: 'Chapter 7',
    header: 'Excreta Drainage System',
    code_id: 'Sec 703',
    code_name: 'Size of Drainage Piping',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 64,
    code_header: 'Chapter 7',
    header: 'Excreta Drainage System',
    code_id: 'Sec 704',
    code_name: 'Fixture Connections (Excreta Drainage)',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 65,
    code_header: 'Chapter 7',
    header: 'Excreta Drainage System',
    code_id: 'Sec 705',
    code_name: 'Change in Direction of Excreta Drainage Flow',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 66,
    code_header: 'Chapter 7',
    header: 'Excreta Drainage System',
    code_id: 'Sec 706',
    code_name: 'Cleanouts',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 67,
    code_header: 'Chapter 7',
    header: 'Excreta Drainage System',
    code_id: 'Sec 707',
    code_name: 'Grade of Horizontal Excreta Drainage Piping',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 68,
    code_header: 'Chapter 7',
    header: 'Excreta Drainage System',
    code_id: 'Sec 708',
    code_name: 'Gravity Drainage Required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 69,
    code_header: 'Chapter 7',
    header: 'Excreta Drainage System',
    code_id: 'Sec 709',
    code_name:
      'Drainage of Fixtures located below the Upstream Manhole or below the Main Sewer Level',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 70,
    code_header: 'Chapter 7',
    header: 'Excreta Drainage System',
    code_id: 'Sec 710',
    code_name: 'Suds Relief',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 71,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 801',
    code_name: 'Indirect Waste Conditions',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 72,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 802',
    code_name: 'Approvals',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 73,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 803',
    code_name: 'Indirect Waste Piping',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 74,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 804',
    code_name: 'Indirect Waste Receptors',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 75,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 805',
    code_name: 'Pressure Drainage Connection',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 76,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 806',
    code_name: 'Sterile Equipment',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 77,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 807',
    code_name: 'Appliances',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 78,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 808',
    code_name: 'Cooling Water',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 79,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 809',
    code_name: 'Drinking Fountain',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 80,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 810',
    code_name: 'Steam and Hot Water Drainage Condensers and Sumps',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 81,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 811',
    code_name: 'Chemical Wastes',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 82,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 812',
    code_name: 'Vertical Wet Venting',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 83,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 813',
    code_name: 'Special Venting Waste and Vent Systems',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 84,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 814',
    code_name: 'Combination Waste and Vent Systems',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 85,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 815',
    code_name: 'Direct Waste to Ground',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 86,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 816',
    code_name: 'Clear Water Wastes',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 87,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 817',
    code_name: 'Swimming Pools',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 88,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 818',
    code_name: 'Refrigeration Wastes',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 89,
    code_header: 'Chapter 8',
    header: 'Indirect Waste Piping, Wet-Vented Systems and Special Wastes',
    code_id: 'Sec 819',
    code_name: 'Air-conditioning Equipment',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 90,
    code_header: 'Chapter 9',
    header: 'Vents and Venting',
    code_id: 'Sec 901',
    code_name: 'Vents Required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 91,
    code_header: 'Chapter 9',
    header: 'Vents and Venting',
    code_id: 'Sec 902',
    code_name: 'Vents not Required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 92,
    code_header: 'Chapter 9',
    header: 'Vents and Venting',
    code_id: 'Sec 903',
    code_name: 'Materials',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 93,
    code_header: 'Chapter 9',
    header: 'Vents and Venting',
    code_id: 'Sec 904',
    code_name: 'Size of Vents',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 94,
    code_header: 'Chapter 9',
    header: 'Vents and Venting',
    code_id: 'Sec 905',
    code_name: 'Vent Pipes Grades and Connections',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 95,
    code_header: 'Chapter 9',
    header: 'Vents and Venting',
    code_id: 'Sec 906',
    code_name: 'Vent Termination',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 96,
    code_header: 'Chapter 9',
    header: 'Vents and Venting',
    code_id: 'Sec 907',
    code_name: 'Vents Stacks and Relief Vents',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 97,
    code_header: 'Chapter 9',
    header: 'Vents and Venting',
    code_id: 'Sec 908',
    code_name: 'Vents Not Required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 98,
    code_header: 'Chapter 9',
    header: 'Vents and Venting',
    code_id: 'Sec 909',
    code_name: 'Materials',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 99,
    code_header: 'Chapter 9',
    header: 'Vents and Venting',
    code_id: 'Sec 910',
    code_name: 'Size of Vents',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 100,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1001',
    code_name: 'Traps Required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 101,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1002',
    code_name: 'Traps Protected by Vent Pipes',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 102,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1003',
    code_name: 'Traps Described',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 103,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1004',
    code_name: 'Traps Prohibited',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 104,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1005',
    code_name: 'Trap Seals',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 105,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1006',
    code_name: 'Floor Drain Traps',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 106,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1007',
    code_name: 'Trap Seal Protection',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 107,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1008',
    code_name: 'Industrial Interceptors (Clarifiers) and Separators',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 108,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1010',
    code_name: 'Minimum Requirements for Auto Wash Rack',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 109,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1011',
    code_name: 'Grease Traps',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 110,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1012',
    code_name: 'Grease Interceptors for Commercial Kitchens',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 111,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1013',
    code_name: 'Food Waste Disposal Prohibited',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 112,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1014',
    code_name: 'Traps - Described',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 113,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1015',
    code_name: 'Laundries',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 114,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1016',
    code_name: 'Sand Interceptors where required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 115,
    code_header: 'Chapter 10',
    header: 'Traps and Interceptors',
    code_id: 'Sec 1017',
    code_name: 'Construction and Size',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 116,
    code_header: 'Chapter 11',
    header: 'Storm Drainage System',
    code_id: 'Sec 1101',
    code_name: 'General - Required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 117,
    code_header: 'Chapter 11',
    header: 'Storm Drainage System',
    code_id: 'Sec 1102',
    code_name: 'Rainwater Systems',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 118,
    code_header: 'Chapter 11',
    header: 'Storm Drainage System',
    code_id: 'Sec 1103',
    code_name: 'Roof Drain',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 119,
    code_header: 'Chapter 11',
    header: 'Storm Drainage System',
    code_id: 'Sec 1104',
    code_name: 'Sizing of Rainwater Piping',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 120,
    code_header: 'Chapter 12',
    header: 'House Drains and House Sewers',
    code_id: 'Sec 1201',
    code_name: 'Sewer Required',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 121,
    code_header: 'Chapter 12',
    header: 'House Drains and House Sewers',
    code_id: 'Sec 1202',
    code_name: 'Damage to Public Sewer or Private',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 122,
    code_header: 'Chapter 12',
    header: 'House Drains and House Sewers',
    code_id: 'Sec 1203',
    code_name: 'Building Sewer Materials',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 123,
    code_header: 'Chapter 12',
    header: 'House Drains and House Sewers',
    code_id: 'Sec 1204',
    code_name: 'Building Sewer Materials',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 124,
    code_header: 'Chapter 12',
    header: 'House Drains and House Sewers',
    code_id: 'Sec 1205',
    code_name: 'Markings',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 125,
    code_header: 'Chapter 12',
    header: 'House Drains and House Sewers',
    code_id: 'Sec 1206',
    code_name: 'Size of Building Sewers',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 126,
    code_header: 'Chapter 12',
    header: 'House Drains and House Sewers',
    code_id: 'Sec 1207',
    code_name: 'Grade, Support, and Protection of Building Sewer',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 127,
    code_header: 'Chapter 12',
    header: 'House Drains and House Sewers',
    code_id: 'Sec 1208',
    code_name: 'Cleanouts',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 128,
    code_header: 'Chapter 12',
    header: 'House Drains and House Sewers',
    code_id: 'Sec 1209',
    code_name: 'Sewer and Water Pipes',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 129,
    code_header: 'Chapter 12',
    header: 'House Drains and House Sewers',
    code_id: 'Sec 1210',
    code_name: 'Abandoned Sewers and Sewage Disposal Facilities',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 130,
    code_header: 'Chapter 13',
    header: 'Joints and Connections',
    code_id: 'Sec 1301',
    code_name: 'Tightness',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 131,
    code_header: 'Chapter 13',
    header: 'Joints and Connections',
    code_id: 'Sec 1302',
    code_name: 'Use of Joints',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 132,
    code_header: 'Chapter 13',
    header: 'Joints and Connections',
    code_id: 'Sec 1303',
    code_name: 'Use of Joints',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 133,
    code_header: 'Chapter 13',
    header: 'Joints and Connections',
    code_id: 'Sec 1304',
    code_name: 'Special Joints',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 134,
    code_header: 'Chapter 13',
    header: 'Joints and Connections',
    code_id: 'Sec 1305',
    code_name: 'Flanged Fixture Connection',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 135,
    code_header: 'Chapter 13',
    header: 'Joints and Connections',
    code_id: 'Sec 1306',
    code_name: 'Prohibited Joints and Connections',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 136,
    code_header: 'Chapter 13',
    header: 'Joints and Connections',
    code_id: 'Sec 1307',
    code_name: 'Waterproofing of Openings',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 137,
    code_header: 'Chapter 13',
    header: 'Joints and Connections',
    code_id: 'Sec 1308',
    code_name: 'Increasers and Reducers',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 138,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: 'Sec 1401',
    code_name: 'Minimum Standards',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 139,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: 'Sec 1402',
    code_name: 'Iron Pipe Size (I.P.S.) Pipe',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 140,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: 'Sec 1403',
    code_name: 'Use of Copper Tubing',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 141,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: 'Sec 1404',
    code_name: 'Lead',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 142,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: 'Sec 1405',
    code_name: 'Ferrules and Bushings',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 143,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: 'Sec 1406',
    code_name: 'Closet Rings (Closet Flanges)',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 144,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: 'Sec 1407',
    code_name: 'Cleanout Fittings',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 145,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: 'Sec 1408',
    code_name: 'Threaded Type Fittings',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 146,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: 'Sec 1410',
    code_name: 'Valves and Fitting',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 147,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: 'Sec 1411',
    code_name: 'Zinc Alloy Components',
    compliance: '',
    id_remarks: '',
  },
  {
    id: 148,
    code_header: 'Chapter 14',
    header:
      'Quality and Weights of Materials, Plumbing materials and Referenced Standards',
    code_id: '301.9.a-e',
    code_name:
      'Required Plumbing Drawings: 1. Location Plan, Vicinity Map 2. Site Drainage Plan 3. Plumbing layout/s (sewer/sewage, drainage, cold waterline, hot water line, if any), same scale as architectural floor plan/s, showing: layout of corresponding pipes (per system) with proper labels of its types, sizes, etc. 4. Isometric Diagrams (per system as cited above) 5. Plumbing Legends/Notes 6. Details: septic vault/tank; catch basin; cistern/underground water tank (if any); overhead water tank (if any); pipe hangers/supports',
    compliance: '',
    id_remarks: '',
  },
]
