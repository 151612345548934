import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  Accordion,
  Form,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

// Components
import { toFixed } from '../reusable/DecimalWoutRound'

const AccessoriesFee = ({
  handleRangeDivA1,
  obj,
  bTotal,
  handleChange,
  handleRangeDivA2,
  cTotal,
  handleRangeDivA3,
  handleRangeDivA4,
  handleRangeDivA5,
  hTotal,
  iTotal,
  handleRangeDivA6,
  handleRangeDivA7,
  kTotal,
  grandTotal,
}) => {
  // console.log(iTotal)

  // const toFixed = (n, fixed) =>
  //   `${n}`.match(new RegExp(`^-?\\d+(?:\.\\d{0,${fixed}})?`))[0]
  return (
    <>
      <h1 className='text-center mb-4'>ACCESSORIES FEES</h1>
      {/* A. Line and Grade */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='0'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          A. LINE AND GRADE
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='0'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th></th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>UNIT</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap' colSpan={2}>
                    Establishment of Line and Grade, all sides fronting or
                    abutting streets, esteros, rivers and creeks, first 10.00
                  </td>
                  <td className='text-center'>₱24.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Line Grade'
                      placeholder='i.'
                      onChange={handleRangeDivA1}
                      value={obj[0].units}
                      // onKeyDown={(e) =>
                      //   e.target.value.length > 14 && e.preventDefault()
                      // }
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Line Grade').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>i.</td>
                  <td className='text-wrap' colSpan={2}>
                    Every meter or fraction thereof in excess of 10.00 meters
                  </td>
                  <td className='text-center'>₱2.40</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End A */}

      {/* B. Ground Preparation and Excavation Fee */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='1'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          B. GROUND PREPARATION AND EXCAVATION FEE
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='1'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th></th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>UNIT</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>i.</td>
                  <td className='text-wrap' colSpan={2}>
                    While the application for Building Permit is still being
                    processed, the Building Official may issue Ground
                    Preparation and Excavation Permit (GP&EP) for foundation,
                    subject to the verification, inspection and review by the
                    Line and Grade Section of the Inspection and Enforcement
                    Division to determine compliance to line and grade,
                    setbacks, yards/easements and parking requirements.
                  </td>
                  <td className='text-center'></td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap' colSpan={2}>
                    (a) Inspection and Verification Fee
                  </td>
                  <td className='text-center'>₱200.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Inspect Verify'
                      placeholder='a.'
                      onChange={handleChange}
                      value={obj[1].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Inspect Verify').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap' colSpan={2}>
                    (b) Per cu. meters of excavation
                  </td>
                  <td className='text-center'>₱3.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Excavation'
                      placeholder='b.'
                      onChange={handleChange}
                      value={obj[2].units}
                      // onKeyDown={(e) =>
                      //   e.target.value.length > 14 && e.preventDefault()
                      // }
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Excavation').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap' colSpan={2}>
                    (c) Issuance of GP & EP, valid only for thirty (30) days or
                    superseded upon issuance of Building Permit
                  </td>
                  <td className='text-center'>₱50.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Issuance'
                      placeholder='c.'
                      onChange={handleChange}
                      value={obj[3].units}
                      // onKeyDown={(e) =>
                      //   e.target.value.length > 14 && e.preventDefault()
                      // }
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Issuance').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap' colSpan={2}>
                    (d) Per cu. meter of excavation for foundation with basement
                  </td>
                  <td className='text-center'>₱4.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Excav Foundation'
                      placeholder='d.'
                      onChange={handleChange}
                      value={obj[4].units}
                      // onKeyDown={(e) =>
                      //   e.target.value.length > 14 && e.preventDefault()
                      // }
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Excav Foundation')
                          .estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap' colSpan={2}>
                    (e) Excavation other than foundation or basement, per cu.
                    meter
                  </td>
                  <td className='text-center'>₱3.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Other Excav'
                      placeholder='e.'
                      onChange={handleChange}
                      value={obj[5].units}
                      maxLength={13}
                      min={0}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Other Excav').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap' colSpan={2}>
                    (f) Enroachment of footings or foundations of
                    buildings/structures to public areas as permitted, per sq.
                    meter or fraction thereof of footing or foundation
                    enroachment
                  </td>
                  <td className='text-center'>₱250.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Enroachment'
                      placeholder='f.'
                      onChange={handleChange}
                      value={obj[6].units}
                      maxLength={13}
                      min={0}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Enroachment').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan='5' className='text-end fw-bold'>
                    Total :
                  </td>
                  <td>{toFixed(bTotal, 2)}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End B */}

      {/* C. Fencing Fees */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='2'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          C. FENCING FEES
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='2'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th></th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>UNIT</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>i.</td>
                  <td className='text-wrap' colSpan={2}>
                    Made of masonry, metal, concrete up to 1.80 meters in
                    height, per lineal meter or fraction thereof
                  </td>
                  <td className='text-center'>₱3.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Fencing Masonry'
                      placeholder='i.-ii.'
                      onChange={handleRangeDivA2}
                      value={obj[7].units}
                      maxLength={13}
                      min={0}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Fencing Masonry')
                          .estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>ii.</td>
                  <td className='text-wrap' colSpan={2}>
                    In excess of 1.80 meters in height, per lineal meter or
                    fraction thereof
                  </td>
                  <td className='text-center'>₱4.00</td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>iii.</td>
                  <td className='text-wrap' colSpan={2}>
                    Made of indigenous materials, barbed, chicken or hog wires,
                    per lineal meter
                  </td>
                  <td className='text-center'>₱2.40</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Indigenous'
                      placeholder='iii.'
                      onChange={handleChange}
                      value={obj[8].units}
                      maxLength={13}
                      min={0}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Indigenous').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5} className='text-end fw-bold'>
                    TOTAL :
                  </td>
                  <td>{toFixed(cTotal, 2)}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End C */}

      {/* D. Pavements */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='4'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          D. PAVEMENTS
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='4'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th></th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>UNIT</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>i.</td>
                  <td className='text-wrap' colSpan={2}>
                    Construction of Pavements, up to 20.00 sq. meters
                  </td>
                  <td className='text-center'>₱24.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Pavements'
                      placeholder='i.'
                      onChange={handleChange}
                      value={obj[9].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Pavements').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End D */}

      {/*  E. Excess of 20% of paved areas  */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='5'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          E. EXCESS OF 20% OF PAVED AREAS
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='5'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th></th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>UNIT</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>i.</td>
                  <td className='text-wrap' colSpan={2}>
                    In excess of 20% or fraction thereof of paved areas intended
                    for commercial/industrial/institutional use, such as parking
                    and sidewalk areas, gasoline station premises, skating
                    rinks, pelota courts, tennis and basketball courts and the
                    like
                  </td>
                  <td className='text-center'>₱3.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Excess Paved'
                      placeholder='i.'
                      onChange={handleChange}
                      value={obj[10].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Excess Paved').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End E */}

      {/*  F. Streets and Sidewalks */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='6'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          F. STREETS AND SIDEWALKS
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='6'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th></th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>UNIT</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap' colSpan={2}>
                    Use of Streets and Sidewalks, Enclosures and Occupancy of
                    Sidewalks up to 20.00 sq. meters, per calendar month
                  </td>
                  <td className='text-center'>₱240.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Sidewalks'
                      placeholder='i.'
                      onChange={handleRangeDivA3}
                      value={obj[11].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Sidewalks').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>i.</td>
                  <td className='text-wrap' colSpan={2}>
                    Every sq. meter or fraction thereof in excess of 20.00 sq.
                    meters
                  </td>
                  <td className='text-center'>₱12.00</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End F */}

      {/* G. Erection of Scaffoldings Occupying Public Areas, per calendar month */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='7'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          G. ERECTION OF SCAFFOLDINGS OCCUPYING PUBLIC AREAS, PER CALENDAR MONTH
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='7'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th></th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>UNIT</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap' colSpan={2}>
                    Up to 10.00 meters in length
                  </td>
                  <td className='text-center'>₱150.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Scaffoldings'
                      placeholder='i.'
                      onChange={handleRangeDivA4}
                      value={obj[12].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Scaffoldings').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>i.</td>
                  <td className='text-wrap' colSpan={2}>
                    Every lineal meter or fraction thereof in excess of 10.00
                    meters
                  </td>
                  <td className='text-center'>₱12.00</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End G */}

      {/* H. Sign Fees */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='8'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          H. SIGN FEES
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='8'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th></th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>UNIT</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>i.</td>
                  <td className='text-wrap' colSpan={2}>
                    Erection and anchorage of display surface, up to 4.00 sq.
                    meters of signboard area
                  </td>
                  <td className='text-center'>₱120.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Anchorage'
                      placeholder='i.-ii.'
                      onChange={handleRangeDivA5}
                      value={obj[13].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Anchorage').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap' colSpan={2}>
                    (a) Every sq. meter or fraction thereof inexcess of 4.00 sq.
                    meters
                  </td>
                  <td className='text-center'>₱24.00</td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>ii.</td>
                  <td className='text-wrap' colSpan={2}>
                    Installation Fees, per sq. meter or fraction thereof of
                    display surface
                  </td>
                  <td className='text-center'></td>
                </tr>
              </tbody>
            </Table>
            <Table responsive>
              <thead>
                <tr className='text-center'>
                  <th>TYPE OF SIGN DISPLAY</th>
                  <th>BUSINESS SIGNS</th>
                  <th>ADVERTISING SIGNS</th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>FEE</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='text-center align-middle'>Neon</td>
                  <td className='text-center align-middle'>₱36.00</td>
                  <td className='text-center align-middle'>₱52.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='NeonBus'
                      placeholder='Business Signs'
                      onChange={handleChange}
                      value={obj[14].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='NeonAd'
                      placeholder='Advertising Signs'
                      onChange={handleChange}
                      value={obj[15].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'NeonBus').estimate +
                          obj.find((el) => el.name === 'NeonAd').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='text-center align-middle'>Illuminated</td>
                  <td className='text-center align-middle'>₱24.00</td>
                  <td className='text-center align-middle'>₱36.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='IllumBus'
                      placeholder='Business Signs'
                      onChange={handleChange}
                      value={obj[16].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='IllumAd'
                      placeholder='Advertising Signs'
                      onChange={handleChange}
                      value={obj[17].units}
                      maxLength={13}
                      min={0}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'IllumBus').estimate +
                          obj.find((el) => el.name === 'IllumAd').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='text-center align-middle'>Others</td>
                  <td className='text-center align-middle'>₱15.00</td>
                  <td className='text-center align-middle'>₱24.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='OtherBus'
                      placeholder='Business Signs'
                      onChange={handleChange}
                      value={obj[18].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='OtherAd'
                      placeholder='Advertising Signs'
                      onChange={handleChange}
                      value={obj[19].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'OtherBus').estimate +
                          obj.find((el) => el.name === 'OtherAd').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='text-center align-middle'>Painted-on</td>
                  <td className='text-center align-middle'>₱9.60</td>
                  <td className='text-center align-middle'>₱18.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='PaintBus'
                      placeholder='Business Signs'
                      onChange={handleChange}
                      value={obj[20].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='PaintAd'
                      placeholder='Advertising Signs'
                      onChange={handleChange}
                      value={obj[21].units}
                      maxLength={13}
                      min={0}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'PaintBus').estimate +
                          obj.find((el) => el.name === 'PaintAd').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5} className='text-end fw-bold'>
                    Total :
                  </td>
                  <td>{toFixed(hTotal, 2)}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End H */}

      {/* I. Repair Fees */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='9'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          I. REPAIR FEES
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='9'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>UNIT</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>i.</td>
                  <td className='text-wrap'>
                    Alteration/renovation/improvement on vertical dimensions of
                    buildings/structures in square meter, such as facades,
                    exterior and interior walls, shall be assessed in accordance
                    with the following rate, For all Groups
                  </td>
                  <td className='text-center'>₱5.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Vertical Dim'
                      placeholder='Vertical Dimension'
                      onChange={handleChange}
                      value={obj[22].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Vertical Dim').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>ii.</td>
                  <td className='text-wrap'>
                    Alteration/renovation/improvement on horizontal dimensions
                    of buildings/structures, such as floorings, ceilings and
                    roofing shall be assessed in accordance with the following
                    rate, For all Groups
                  </td>
                  <td className='text-center'>₱5.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Horizontal Dim'
                      placeholder='Horizontal Dimension'
                      onChange={handleChange}
                      value={obj[23].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Horizontal Dim').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>iii.</td>
                  <td className='text-wrap'>
                    Repairs on buildings/structures in all Groups costing more
                    than five thousand pesos (P 5,000.00) shall be charged 1% of
                    the detailed repair cost (itemized original materials to be
                    replaced with same or new substitute and labor)
                  </td>
                  <td className='text-center'></td>
                </tr>
                <tr>
                  <td colSpan={4} className='text-end fw-bold'>
                    Total :
                  </td>
                  <td>{toFixed(iTotal, 2)}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End I */}

      {/* J. Raising of Buildings/Structures Fees */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='10'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          J. RAISING OF BUILDINGS/STRUCTURES FEES
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='10'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center align-middle'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr className=' text-justify align-middle'>
                  <td className='align-middle'>i.</td>
                  <td className='align-middle'>
                    Assessment of fees for raising of any buildings/structures
                    shall be based on the new usable area generated.
                  </td>
                  <td className='text-center align-middle'></td>
                </tr>
                <tr className='text-justify'>
                  <td className='align-middle'>ii.</td>
                  <td className='align-middle'>
                    The fees to be charged shall be as prescribed under Sections
                    3.a to 3.e of this Schedule, whichever Group applies
                  </td>
                  <td className='text-center align-middle'></td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End J */}

      {/* K. Demolition/Moving of Buildings/Structures Fees, per sq. meter of area or dimensions involved  */}
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='11'
          className='accordion-top'
          style={{
            background: '#4bbf73',
            color: '#FFF',
            fontWeight: 'bold',
          }}
        >
          K. DEMOLITION/MOVING OF BUILDINGS/STRUCTURES FEES, PER SQ. METER OF
          AREA OF AREA OR DIMENSIONS INVOLVED
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='11'>
          <Card.Body className='accordion-description'>
            <Table responsive className='mb-3'>
              <thead>
                <tr className='text-center'>
                  <th>#</th>
                  <th>PARTICULARS</th>
                  <th>FEE</th>
                  <th className='text-center pr-0'>UNIT</th>
                  <th>EST. FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>i.</td>
                  <td className='text-wrap'>
                    Buildings in all Groups per sq. meter floor area
                  </td>
                  <td className='text-center'>₱3.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Demolition Bldg'
                      placeholder='Floor Area'
                      onChange={handleChange}
                      value={obj[24].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Demolition Bldg')
                          .estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>ii.</td>
                  <td className='text-wrap'>
                    Building Systems/Frames or portion thereof per vertical or
                    horizontal dimensions, including Fences
                  </td>
                  <td className='text-center'>₱4.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Bldg Frame'
                      placeholder='Vertical or Horizontal Dimension'
                      onChange={handleChange}
                      value={obj[25].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Bldg Frame').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>iii.</td>
                  <td className='text-wrap'>
                    Structures of up to 10.00 meters in height
                  </td>
                  <td className='text-center'>₱800.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Structure Height'
                      placeholder='Unit'
                      onChange={handleRangeDivA6}
                      value={obj[26].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Structure Height')
                          .estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap'>
                    (a) Every meter or portion thereof in excess of 10.00 meters
                  </td>
                  <td className='text-center'>₱50.00</td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>iv.</td>
                  <td className='text-wrap'>
                    Appendage of up to 3.00 cu. meter/unit
                  </td>
                  <td className='text-center'>₱50.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Appendage'
                      placeholder='Unit'
                      onChange={handleRangeDivA7}
                      value={obj[27].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Appendage').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'></td>
                  <td className='text-wrap'>
                    (a) Every cu. meter or portion thereof in excess of 3.00 cu.
                    meters
                  </td>
                  <td className='text-center'>₱3.00</td>
                </tr>
                <tr className='text-justify align-middle text-wrap'>
                  <td className='align-middle'>v.</td>
                  <td className='text-wrap'>
                    Moving Fee, per sq. meter of area of building/structure to
                    be moved
                  </td>
                  <td className='text-center'>₱3.00</td>
                  <td>
                    <Form.Control
                      type='text'
                      className='border border-secondary align-middle'
                      style={{
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                      name='Moving Fee'
                      placeholder='Unit'
                      onChange={handleChange}
                      value={obj[28].units}
                      min={0}
                      maxLength={13}
                    />
                  </td>
                  <td className='align-middle'>
                    <p className='mb-0'>
                      ₱
                      {toFixed(
                        obj.find((el) => el.name === 'Moving Fee').estimate,
                        2
                      )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} className='text-end fw-bold'>
                    Total :
                  </td>
                  <td>{kTotal}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* End K */}

      {/* <Row className='mt-3 mx-1'>
        <Col xl={8} className='text-end align-middle mt-2'>
          <h4>GRAND TOTAL:</h4>
        </Col>
        <Col xl={4} className='mb-5'>
          <Form.Control
            type='text'
            className='border border-secondary'
            style={{
              marginLeft: '8px',
              marginRight: '5px',
            }}
            name='GrandTotal'
            value={toFixed(grandTotal, 2)}
            placeholder='Grand Total'
            disabled
          />
        </Col>
      </Row> */}
    </>
  )
}

export default withRouter(AccessoriesFee)
