import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  headerCenter: {
    textAlign: 'right',
    fontSize: 12,
    width: '100%',
    fontFamily: 'Roboto',
    fontWeight: '700',
    marginRight: '40%',
  },
})

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
      fontWeight: '700',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf',
      fontStyle: 'italic',
    },
  ],
})

const EvaluationSheet = ({ info }) => {
  const renderEvaluationSheet = [
    ['RECEIVING', 'document controller'],
    ['LINE AND GRADE / ARCHITECTURAL', 'Architectural'],
    ['STRUCTURAL', 'Structural'],
    ['SANITARY', 'Plumbing'],
    ['ELECTRICAL', 'Electrical'],
    ['MECHANICAL', 'Mechanical'],
    ['ELECTRONICS', 'Electronics'],
  ].map((el, index) => {
    if (info !== '') {
      const match = info.find((x) => x.trades === el[1])

      return (
        <View
          key={index}
          style={{
            marginTop: '15px',
            borderTopColor: '#e4e1e3',
            borderTopWidth: 1,
            borderBottomColor: '#e4e1e3',
            borderBottomWidth: 1,
            paddingVertical: '5px',
          }}
        >
          <Text
            style={{
              textDecoration: 'underline',
              fontSize: 11,
              fontFamily: 'Roboto',
            }}
          >
            {el[0]}
          </Text>
          <View style={{ marginTop: '5px', flexDirection: 'row' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  fontSize: 10,
                  width: '25%',
                  marginLeft: '5px',
                  fontFamily: 'Roboto',
                }}
              >
                {match !== undefined ? 'DATE:' : 'DATE:   ____________ '}
              </Text>
              {match !== undefined && (
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: 10,
                    textDecoration: 'underline',
                  }}
                >
                  {match.timeStamp}
                </Text>
              )}
            </View>

            <Text
              style={{
                width: '40%',
                fontSize: 10,
                marginLeft: '5px',
                fontFamily: 'Roboto',
              }}
            >
              SIGNATURE: _______________
            </Text>
          </View>
          <Text
            style={{
              marginTop: '5px',
              fontSize: 10,
              marginLeft: '5px',
              fontFamily: 'Roboto',
              fontWeight: '700',
            }}
          >
            REMARKS:
          </Text>
          <Text
            style={{
              marginVertical: '5px',
              fontSize: 10,
              marginLeft: '5px',
              fontFamily: 'Roboto',
            }}
          >
            {match && match.remarks}
          </Text>
        </View>
      )
    } else {
      return (
        <View
          key={index}
          style={{
            marginTop: '15px',
            borderTopColor: '#e4e1e3',
            borderTopWidth: 1,
            borderBottomColor: '#e4e1e3',
            borderBottomWidth: 1,
            paddingVertical: '5px',
          }}
        >
          <Text
            style={{
              textDecoration: 'underline',
              fontSize: 11,
              fontFamily: 'Roboto',
            }}
          >
            {el[0]}
          </Text>
          <View style={{ marginTop: '5px', flexDirection: 'row' }}>
            <Text
              style={{
                fontSize: 10,
                width: '25%',
                marginLeft: '5px',
                fontFamily: 'Roboto',
              }}
            >
              DATE: _______________
            </Text>
            <Text
              style={{
                width: '40%',
                fontSize: 10,
                marginLeft: '5px',
                fontFamily: 'Roboto',
              }}
            >
              SIGNATURE: _______________
            </Text>
          </View>
          <Text
            style={{
              marginTop: '5px',
              fontSize: 10,
              marginLeft: '5px',
              fontFamily: 'Roboto',
              fontWeight: '700',
            }}
          >
            REMARKS:
          </Text>
          <View style={{ width: '50%' }}>
            <Text
              style={{
                marginVertical: '5px',
                fontSize: 10,
                marginLeft: '5px',
                fontFamily: 'Roboto',
              }}
            >
              ______________________________________________________________________________________________________________________________
              ______________________________________________________________________________________________________________________________
            </Text>
          </View>
        </View>
      )
    }
  })
  return (
    <Document file='EvaluationSheet.pdf'>
      <Page size='Folio' style={styles.body} wrap>
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{ marginBottom: '10px', width: '80%', paddingTop: '5%' }}
          >
            <Text style={styles.headerCenter}>
              CITY GOVERNMENT OF LAS PINAS
            </Text>
            <Text style={styles.headerCenter}>
              OFFICE OF THE BUILDING OFFICIAL
            </Text>
          </View>
          <View style={{ width: '14%', height: '73%' }}>
            <Image src='assets/logo/obo.png' />
          </View>
        </View>

        <Text
          style={{
            textDecoration: 'underline',
            fontSize: 12,
            fontFamily: 'Roboto',
          }}
        >
          EVALUATION SHEET
        </Text>

        <View style={{ marginTop: '15px', flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', width: '50%' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
              REFERENCE NO :
            </Text>
            {info !== '' ? (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                {info[0].appID}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>

          <View style={{ flexDirection: 'row', width: '50%' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
              APPLICANT:
            </Text>
            {info !== '' ? (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                {info[0].FullName}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>
        </View>
        <View style={{ marginTop: '5px', flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', width: '50%' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>DATE :</Text>
            {info !== '' ? (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                {info[0].timeStamp}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>

          <View style={{ flexDirection: 'row', width: '40%' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
              LOCATION :
            </Text>
            {info !== '' ? (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                {info[0].Address}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>
        </View>
        <View style={{ marginTop: '5px', flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', width: '50%' }}></View>

          <View style={{ flexDirection: 'row', width: '50%' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>PROJECT:</Text>
            {info !== '' ? (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                {info[0].ApplicationName}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>
        </View>
        <View style={{ marginTop: '5px', flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', width: '50%' }}></View>

          <View style={{ flexDirection: 'row', width: '50%' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
              CONTACT NO.:
            </Text>
            {info !== '' ? (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                {info[0].MobileNo}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>
        </View>
        <View style={{ marginTop: '5px', flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', width: '50%' }}></View>

          <View style={{ flexDirection: 'row', width: '50%' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
              EMAIL ADDRESS:
            </Text>
            {info !== '' ? (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                {info[0].EmailAddress}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>
        </View>

        {renderEvaluationSheet}
        <View
          style={{
            marginTop: '15px',
            borderTopColor: '#000',
            borderTopWidth: 1,
            paddingVertical: '5px',
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: 8,
              fontFamily: 'Roboto',
              fontWeight: '700',
            }}
          >
            NOTE: THIS IS A COMPUTER GENERATED COPY OF EVALUATION. INCLUDE THIS
            EVALUATION SHEET ON YOUR RESUBMISSION OF DOCUMENTS
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default EvaluationSheet
