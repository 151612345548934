import React, { useEffect, useRef, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Image,
} from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
// Redux Requirements
import { useDispatch, useSelector } from 'react-redux'
import { employeeLogin } from '../redux/actions/userActions'
import { Link } from 'react-router-dom'

// import axios from 'axios'

const LoginScreen = ({ history, location }) => {
  // Redux Requirements
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users)
  const { userCredentials, error } = user

  // const [logInstatus, setlogInStatus] = useState(false)

  const [userInformation, setuserInformation] = useState({
    username: '',
    password: '',
    longitude: null,
    latitude: null,
  })

  const [showPassword, setShowPassword] = useState('password')
  const inputRef = useRef()

  const handleChange = (e) => {
    setuserInformation({
      ...userInformation,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    dispatch(employeeLogin(userInformation))
    // setlogInStatus(true)
  }

  const redirect = location.search
    ? location.search.split('=')[1]
    : '/dashboard'

  useEffect(() => {
    inputRef.current.focus()

    if (userCredentials) {
      history.push(redirect)
    }
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setuserInformation({
          ...userInformation,
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        })
      })
    }
  }, [userCredentials, history, redirect])

  // useEffect(() => {
  //   dispatch(isUserAuth())
  // }, [dispatch])

  return (
    <div
      className='login-main d-flex justify-content-center align-items-center
  bg-img center-vh'
      style={{ backgroundImage: 'url(./assets/img/sample15.webp)' }}
    >
      <Row>
        <Col md={12}>
          <div className=' border border-secondary shadow-lg p-3 bg-white '>
            <Row className='pt-2'>
              <Col md={12} className='text-center'>
                <div className='py-3'>
                  <Image
                    src='assets/logo/Ootb-logo.png'
                    roundedCircle
                    width='80'
                    height='80'
                    className='shadow-lg'
                  />
                </div>

                <h4>LP-BLDGO</h4>
                {/* <p className='fs-6'>
                  " Online Application of permit on Office of the Building
                  Officials "
                </p> */}
                <h5>Login</h5>
              </Col>
              <Col md={12} className='px-5 py-2'>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className='py-2'>
                    <Form.Control
                      type='text'
                      placeholder='Username'
                      onChange={handleChange}
                      name='username'
                      ref={inputRef}
                    />
                  </Form.Group>
                  <Form.Group className='py-2'>
                    <InputGroup>
                      <Form.Control
                        type={showPassword}
                        placeholder='Password'
                        name='password'
                        maxLength={12}
                        onChange={handleChange}
                      />
                      {showPassword === 'password' ? (
                        <Button
                          variant='outline-secondary'
                          onClick={() => setShowPassword('text')}
                        >
                          <FaEye />
                        </Button>
                      ) : (
                        <Button
                          variant='outline-secondary'
                          onClick={() => setShowPassword('password')}
                        >
                          <FaEyeSlash />
                        </Button>
                      )}
                    </InputGroup>
                  </Form.Group>
                  {error && (
                    <Alert className='my-0' variant='danger'>
                      {error}
                    </Alert>
                  )}
                  {/* <Form.Group className='py-2'>
                    <Form.Check type='checkbox' label='Remember me' />
                  </Form.Group> */}
                  {/* {logInstatus && (
                    <div className='d-grid gap-2 py-2'>
                      <Button
                        variant='success'
                        onClick={handleAuthentication}
                        type='button'
                      >
                        check Authentication
                      </Button>
                    </div>
                  )} */}
                  <div className='d-grid gap-2 mt-3'>
                    <Button variant='success' type='submit'>
                      Submit
                    </Button>
                  </div>
                  {/* <Link to='/' className='text-info'>
                    <p className='pt-2 fw-bold text-info fs-6'>
                      Forgot Password?
                    </p>
                  </Link> */}
                </Form>
              </Col>
            </Row>
            <hr />
            <h6 className='text-center'>Strictly for Employees only.</h6>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default LoginScreen
