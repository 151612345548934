import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
} from 'react-bootstrap'
import { FaFileContract, FaPencilAlt, FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  insertEvaluationSheet,
  retrieveEvaluationSheet,
  deleteEvaluationSheet,
} from '../../redux/actions/DocumentControllers/documentControllerActions'
import { withRouter } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import Loading from '../../reusable/Loading'
import SuccessModal from '../../reusable/SuccessModal'
import EvaluationSheet from '../../reusable/EvaluationSheet'

const EvaluationSheetScreen = ({ match, history }) => {
  //   Declaration
  const dispatch = useDispatch()

  const evaluatorHeader = [
    ['RECEIVING', 'document controller'],
    ['LINE AND GRADE / ARCHITECTURAL', 'Architectural'],
    ['STRUCTURAL', 'Structural'],
    ['SANITARY/PLUMBING', 'Plumbing'],
    ['ELECTRICAL', 'Electrical'],
    ['MECHANICAL', 'Mechanical'],
    ['ELECTRONICS', 'Electronics'],
  ]

  const [userLevel, setUserLevel] = useState('')

  const [evaluationSheet, setEvaluationSheet] = useState({
    id: 0,
    trades: '',
    remarks: '',
    appID: 0,
    empID: 0,
  })

  const [modal, setModal] = useState({
    editRemarks: false,
    successModal: false,
    deleteSuccessModal: false,
  })

  const users = useSelector((state) => state.users)

  const { userCredentials } = users

  const documentControllerReducer = useSelector(
    (state) => state.documentControllerReducer
  )

  const {
    retrievedEvaluationSheet,
    insertedEvaluationSheet,
    deletedEvaluationSheet,
  } = documentControllerReducer

  // End Declaration

  // Function
  const submitEvaluation = (e) => {
    e.preventDefault()
    dispatch(
      insertEvaluationSheet({
        id: evaluationSheet.id,
        trades: evaluationSheet.trades,
        remarks: evaluationSheet.remarks,
        appID: evaluationSheet.appID,
        empID: evaluationSheet.empID,
      })
    )
    setModal({
      editRemarks: false,
      successModal: true,
    })
  }

  const handleEditRemarks = (e, selectedItem) => {
    setModal({ editRemarks: true })
    // console.log(selectedItem)
    if (selectedItem === undefined) {
      setEvaluationSheet({
        ...evaluationSheet,
        trades: e[1],
        appID: parseInt(match.params.id),
        empID: userCredentials.id,
      })
    } else {
      setEvaluationSheet({
        ...evaluationSheet,
        id: selectedItem.id,
        trades: e[1],
        appID: parseInt(match.params.id),
        empID: userCredentials.id,
        remarks: selectedItem.remarks,
      })
    }
  }

  const renderEvaluationSheet = evaluatorHeader.map((e, index) => {
    if (retrievedEvaluationSheet && retrievedEvaluationSheet.message) {
      const match = retrievedEvaluationSheet.message.find(
        (x) => x.trades === e[1]
      )

      // console.log(match)
      return (
        <tr key={index}>
          <td>
            <h5>{e[0]}</h5>
          </td>

          <td key={index}>
            <h6>Remarks:</h6> {match && match.remarks}
          </td>

          {userLevel === e[1] ? (
            <td className='align-middle d-flex'>
              <Button
                size='sm'
                variant='info'
                onClick={() => handleEditRemarks(e, match)}
              >
                <FaPencilAlt />
              </Button>
              {match !== undefined && (
                <Button
                  size='sm'
                  variant='danger'
                  className='mx-2'
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to update status of application?'
                      )
                    ) {
                      dispatch(
                        deleteEvaluationSheet({
                          id: match.id,
                          appID: match.appID,
                        })
                      )
                      setEvaluationSheet({
                        ...evaluationSheet,
                        id: 0,
                        trades: '',
                        remarks: '',
                        appID: 0,
                      })
                      setModal({
                        deleteSuccessModal: true,
                      })
                    }
                  }}
                >
                  <FaTrashAlt />
                </Button>
              )}
            </td>
          ) : (
            <td></td>
          )}
        </tr>
      )
    } else {
      return (
        <tr key={index}>
          <td>
            <h5>{e[0]}</h5>
          </td>

          <td key={index}>
            <h6>Remarks:</h6>
          </td>

          {userLevel === e[1] && (
            <td className='align-middle'>
              <Button
                size='sm'
                variant='info'
                onClick={() => handleEditRemarks(e)}
              >
                <FaPencilAlt />
              </Button>
            </td>
          )}
        </tr>
      )
    }
  })
  // End Function

  useEffect(() => {
    if (userCredentials.roles !== 'evaluator') {
      setUserLevel(userCredentials.roles)
    } else {
      setUserLevel(userCredentials.subroles)
    }
    dispatch(retrieveEvaluationSheet({ id: match.params.id }))
  }, [userCredentials, dispatch])

  useEffect(() => {})

  return (
    <UserMain>
      <Container>
        <Row>
          <Col
            xl='12'
            className='shadow-lg pt-4'
            style={{ background: '#FFF', height: '86vh', overflowY: 'auto' }}
          >
            <h5 className='text-center'>
              CITY GOVERNMENT OF LAS PINAS <br /> OFFICE OF THE BUILDING
              OFFICIAL
            </h5>
            <h4 className='mt-5'>Evaluation Sheet</h4>
            <hr />

            {retrievedEvaluationSheet && retrievedEvaluationSheet.loading ? (
              <Loading />
            ) : (
              <Table responsive>
                <tbody>{renderEvaluationSheet}</tbody>
              </Table>
            )}

            <div className='my-3 d-flex justify-content-end'>
              {retrievedEvaluationSheet &&
                !retrievedEvaluationSheet.loading && (
                  <PDFDownloadLink
                    document={
                      <EvaluationSheet
                        info={
                          retrievedEvaluationSheet &&
                          retrievedEvaluationSheet.message
                            ? retrievedEvaluationSheet.message
                            : ''
                        }
                      />
                    }
                    fileName='EvaluationSheet.pdf'
                  >
                    {({ loading }) =>
                      loading ? (
                        'Loading document...'
                      ) : (
                        <Button variant='info'>PRINT</Button>
                      )
                    }
                  </PDFDownloadLink>
                )}

              <Button
                variant='danger'
                className='mx-2'
                onClick={() => history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        size='md'
        show={modal.editRemarks}
        onHide={() =>
          setModal({
            editRemarks: false,
          })
        }
        centered
        backdrop='static'
      >
        <Form onSubmit={(e) => submitEvaluation(e)}>
          <Modal.Header>
            <Modal.Title>
              <FaFileContract className='mx-3' /> add / edit remarks
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              onChange={(e) =>
                setEvaluationSheet({
                  ...evaluationSheet,
                  remarks: e.target.value,
                })
              }
              as='textarea'
              placeholder='input remarks here ...'
              style={{
                background: '#FFF',
                border: '1px solid #ced4da',
              }}
              value={evaluationSheet.remarks}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' variant='success' type='submit'>
              Submit
            </Button>
            <Button
              size='sm'
              variant='danger'
              onClick={() => {
                setModal({
                  editRemarks: false,
                })
                setEvaluationSheet({
                  ...evaluationSheet,
                  id: 0,
                  remarks: '',
                })
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/*Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          // window.location.reload(true)
        }}
        transLoading={
          insertedEvaluationSheet && insertedEvaluationSheet.loading
        }
        success={insertedEvaluationSheet && insertedEvaluationSheet.message}
        error={insertedEvaluationSheet && insertedEvaluationSheet.error}
      />
      {/* End Success Modal */}

      {/*Success Modal */}
      <SuccessModal
        show={modal.deleteSuccessModal}
        onHide={() => {
          setModal({ deleteSuccessModal: false })
          // window.location.reload(true)
        }}
        transLoading={deletedEvaluationSheet && deletedEvaluationSheet.loading}
        success={deletedEvaluationSheet && deletedEvaluationSheet.message}
        error={deletedEvaluationSheet && deletedEvaluationSheet.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(EvaluationSheetScreen)
