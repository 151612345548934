import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  headerCenter: {
    textAlign: 'left',
    fontSize: 12,
    width: '100%',
    fontFamily: 'Roboto',
    fontWeight: '700',
    marginBottom: '10px',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 60,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
      fontWeight: '700',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf',
      fontStyle: 'italic',
    },
  ],
})

const CollectionReport = ({ values, dateStart, dateEnd }) => {
  function formatCurrency(value) {
    if (value !== null) {
      return value.toLocaleString('en-PH', {
        style: 'currency',
        currency: 'PHP',
      })
    }
  }

  const totalAmount = values.reduce((accu, current) => accu + current.amount, 0)

  return (
    <Document file='CollectionReport.pdf'>
      <Page size='Folio' style={styles.body} wrap orientation='landscape'>
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{ marginBottom: '10px', width: '80%', paddingTop: '10px' }}
          >
            <Text style={styles.headerCenter}>
              CITY GOVERNMENT OF LAS PINAS
            </Text>
            <Text style={styles.headerCenter}>
              OFFICE OF THE BUILDING OFFICIAL
            </Text>
          </View>
          {/* <View style={{ width: '8%', height: '50%' }}>
            <Image src='assets/logo/obo.png' />
          </View> */}
        </View>

        <Text
          style={{
            textDecoration: 'underline',
            fontSize: 12,
            fontFamily: 'Roboto',
          }}
        >
          COLLECTION REPORT
        </Text>

        <View style={{ marginTop: '15px', flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', width: '50%' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
              NO. OF RELEASE :
            </Text>

            {values.length > 0 ? (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                {values.length}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>
        </View>

        <View style={{ marginTop: '15px', flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', width: '50%' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
              DATE START :
            </Text>
            {values.length > 0 ? (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                {dateStart}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>
        </View>

        <View style={{ marginTop: '15px', flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', width: '50%' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
              DATE END :
            </Text>
            {values.length > 0 ? (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                {dateEnd}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>
        </View>

        <View
          style={{
            marginTop: '15px',
            border: '1px black solid',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              borderBottom: '1px black solid',
            }}
          >
            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                width: '5%',
                textAlign: 'center',
                fontWeight: '700',
                paddingVertical: '10px',
                borderRight: '1px black solid',
              }}
            >
              APP ID
            </Text>
            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                width: '30%',
                textAlign: 'center',
                fontWeight: '700',
                paddingVertical: '10px',
                borderRight: '1px black solid',
              }}
            >
              APPLICATION
            </Text>
            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                width: '20%',
                textAlign: 'center',
                fontWeight: '700',
                paddingVertical: '10px',
                borderRight: '1px black solid',
              }}
            >
              STATUS
            </Text>
            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                width: '10%',
                textAlign: 'center',
                fontWeight: '700',
                paddingVertical: '10px',
                borderRight: '1px black solid',
              }}
            >
              OR NO
            </Text>
            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                width: '10%',
                textAlign: 'center',
                fontWeight: '700',
                paddingVertical: '10px',
                borderRight: '1px black solid',
              }}
            >
              OR DATE
            </Text>
            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                width: '25%',
                textAlign: 'center',
                fontWeight: '700',
                paddingVertical: '10px',
                borderRight: '1px black solid',
              }}
            >
              APPLICATION
            </Text>
            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                width: '10%',
                textAlign: 'center',
                fontWeight: '700',
                paddingVertical: '10px',
              }}
            >
              AMOUNT
            </Text>
            <Text
              style={{
                fontSize: 10,
                fontFamily: 'Roboto',
                width: '10%',
                textAlign: 'center',
                fontWeight: '700',
                paddingVertical: '10px',
                borderLeft: '1px black solid',
              }}
            >
              Encoded By
            </Text>
          </View>

          {values.map((el, index) => (
            <View
              key={index}
              style={{
                flexDirection: 'row',
                borderBottom: '1px',
                borderColor: '#0d3206',
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  width: '5%',
                  textAlign: 'center',
                  paddingVertical: '10px',
                  borderRight: '1px',
                  borderColor: '#0d3206',
                }}
              >
                {el.appID}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  width: '30%',
                  textAlign: 'center',

                  paddingVertical: '10px',
                  borderRight: '1px',
                  borderColor: '#0d3206',
                }}
              >
                {el.ApplicationName}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  width: '20%',
                  textAlign: 'center',

                  paddingVertical: '10px',
                  borderRight: '1px',
                  borderColor: '#0d3206',
                }}
              >
                {el.status}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  width: '10%',
                  textAlign: 'center',
                  fontWeight: '700',
                  paddingVertical: '10px',
                  borderRight: '1px',
                  borderColor: '#0d3206',
                }}
              >
                {el.orNo}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  width: '10%',
                  textAlign: 'center',
                  fontWeight: '700',
                  paddingVertical: '10px',
                  borderRight: '1px',
                  borderColor: '#0d3206',
                }}
              >
                {el.orDate}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  width: '25%',
                  textAlign: 'center',
                  fontWeight: '700',
                  paddingVertical: '10px',
                  borderRight: '1px',
                  borderColor: '#0d3206',
                }}
              >
                {el.Applicant}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  width: '10%',
                  textAlign: 'center',
                  fontWeight: '700',
                  paddingVertical: '10px',
                }}
              >
                {formatCurrency(el.amount)}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: 'Roboto',
                  width: '10%',
                  textAlign: 'center',
                  fontWeight: '700',
                  paddingVertical: '10px',
                  borderLeft: '1px',
                  borderColor: '#0d3206',
                }}
              >
                {el.EncodedBy !== null ? el.EncodedBy : 'Paid Online'}
              </Text>
            </View>
          ))}
        </View>

        <View style={{ marginTop: '15px', flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', width: '50%' }}></View>
          <View
            style={{
              flexDirection: 'row',
              width: '50%',
              justifyContent: 'flex-end',
            }}
          >
            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
              TOTAL COLLECTION :
            </Text>

            {values.length > 0 ? (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                  fontWeight: '700',
                }}
              >
                {formatCurrency(totalAmount)}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  marginHorizontal: '10px',
                }}
              >
                _________
              </Text>
            )}
          </View>
        </View>

        <Text style={styles.footer} fixed>
          This is a computer-generated document. No signature is required.
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  )
}

export default CollectionReport
