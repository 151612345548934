import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'

const ApplicationManager = (props) => {
  // console.log(props)
  return (
    <Modal
      size='md'
      show={props.show}
      onHide={props.onHide}
      centered
      backdrop='static'
    >
      <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
        <Modal.Title style={{ color: '#FFF' }}>
          <FaCheckCircle className='mx-3' />
          Application Manager
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive size='sm'>
          <tbody>
            <tr>
              <td className='fw-bold'>Change Status</td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleChangeStatus}
                >
                  View
                </Button>
              </td>
            </tr>
            <tr>
              <td className='fw-bold'>Add / Edit (Application Information)</td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleApplicationInfo}
                >
                  View
                </Button>
              </td>
            </tr>
            <tr>
              <td className='fw-bold'>Add / Edit (Required Documents)</td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleRequiredDocuments}
                >
                  View
                </Button>
              </td>
            </tr>
            <tr>
              <td className='fw-bold'>
                Add / Edit (Engineer / Architect Information)
              </td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleEngArchModal}
                >
                  View
                </Button>
              </td>
            </tr>
            <tr>
              <td className='fw-bold'>Add / Edit (Application Evaluator/s)</td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleApplicationEvaluator}
                >
                  View
                </Button>
              </td>
            </tr>
            <tr>
              <td className='fw-bold'>Add / Edit (Application Inspector)</td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleApplicationInspector}
                >
                  View
                </Button>
              </td>
            </tr>
            <tr>
              <td className='fw-bold'>View Routing Slip</td>
              <td>
                <Button
                  size='sm'
                  variant='success'
                  onClick={props.handleRoutingSlip}
                >
                  View
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' size='sm' onClick={props.onHide}>
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ApplicationManager
