export const GET_MECH_EVALUATION_DETAILS_REQ = 'GET_MECH_EVALUATION_DETAILS_REQ'
export const GET_MECH_EVALUATION_DETAILS_SUCCESS =
  'GET_MECH_EVALUATION_DETAILS_SUCCESS'
export const GET_MECH_EVALUATION_DETAILS_FAILED =
  'GET_MECH_EVALUATION_DETAILS_FAILED'

export const CREATE_MECH_EVALUATION_REQ = 'CREATE_MECH_EVALUATION_REQ'
export const CREATE_MECH_EVALUATION_SUCCESS = 'CREATE_MECH_EVALUATION_SUCCESS'
export const CREATE_MECH_EVALUATION_FAILED = 'CREATE_MECH_EVALUATION_FAILED'

export const UPDATE_MECH_EVALUATION_REQ = 'UPDATE_MECH_EVALUATION_REQ'
export const UPDATE_MECH_EVALUATION_SUCCESS = 'UPDATE_MECH_EVALUATION_SUCCESS'
export const UPDATE_MECH_EVALUATION_FAILED = 'UPDATE_MECH_EVALUATION_FAILED'
