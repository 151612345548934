import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
  Accordion,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  addAssessment,
  editAssessment,
  retrieveAssessmentDetails,
} from '../../redux/actions/Assessment/structuralAssessmentActions'
import { retrieveAppDetails } from '../../redux/actions/evaluatorActions'

const StructuralAssessmentScreen = ({ history, match }) => {
  // Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const { getAppDetails } = evaluator

  const structuralAssessment = useSelector(
    (state) => state.structuralAssessment
  )

  const { assessmentDetails, createAssessment, updateAssessment } =
    structuralAssessment

  const [obj, setObj] = useState([
    {
      id_per_name: 1,
      name: 'Bank Vaults',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 2,
      name: 'Grp Resident',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 3,
      name: 'Commercial Grp',
      fee: 36,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 4,
      name: 'Social Grp',
      fee: 24,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 5,
      name: 'GrpA Rooms',
      fee: 6,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 6,
      name: 'GrpB Rooms',
      fee: 18,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 7,
      name: 'GrpC Rooms',
      fee: 12,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 8,
      name: 'Firewall Add',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 9,
      name: 'Fwall Min',
      fee: 48,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 10,
      name: 'Storage Silos',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 11,
      name: 'Smokestacks',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 12,
      name: 'Chimney',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 13,
      name: 'Fixed Ovens',
      fee: 48,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 14,
      name: 'Kiln Furnace',
      fee: 12,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 15,
      name: 'Steel Tanks',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 16,
      name: 'SteelTank Excess',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 17,
      name: 'Waste Water',
      fee: 7,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 18,
      name: 'Industrial Tank',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 19,
      name: 'Underground Tank',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 20,
      name: 'Excav Tank',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 21,
      name: 'Saddle',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 22,
      name: 'Permanent Type',
      fee: 10,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 23,
      name: 'Temp Type',
      fee: 5,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 24,
      name: 'Inspection Type',
      fee: 24,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 25,
      name: 'Tombs',
      fee: 5,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 26,
      name: 'Mausoleums',
      fee: 5,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 27,
      name: 'Enclosed',
      fee: 12,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 28,
      name: 'Interment',
      fee: 5,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 29,
      name: 'Columbarium',
      fee: 18,
      units: 0,
      estimate: 0,
    },
  ])

  const [state, setState] = useState([])

  const [grandTotal, setGrandTotal] = useState(0)

  const [modal, setModal] = useState({
    successModal: false,
    updateModal: false,
  })

  const total = obj.reduce(
    (prevData, currData) => prevData + currData.estimate,
    0
  )

  const filtered = obj.filter((e) => e.estimate > 0)

  const isUpdating = JSON.parse(localStorage.getItem('assessmentSelected'))
    ? JSON.parse(localStorage.getItem('assessmentSelected'))
    : null
  // End Declaration

  console.log(obj)
  //   Functions
  // CAT C, G, H, K, M
  //CAT C
  const handleRangeDivA1 = (e) => {
    let tempEstimate = 0
    let value = e.target.value.replace(/[^\d.]/g, '')
    switch (true) {
      case value <= 20:
        tempEstimate = 20
        break
      case value > 20:
        tempEstimate = 8 * (value - 20) + 20
        break
      default:
        tempEstimate = value * 20
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: value.replace(/[^\d.]/g, ''),
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT G
  const handleRangeDivA2 = (e) => {
    let tempEstimate = 0
    let value = e.target.value.replace(/[^\d.]/g, '')
    switch (true) {
      case value <= 10:
        tempEstimate = 2400
        break
      case value > 10:
        tempEstimate = 150 * (value - 10) + 2400
        break
      default:
        tempEstimate = value * 2400
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT H1
  const handleRangeDivA3 = (e) => {
    let tempEstimate = 0
    let value = e.target.value.replace(/[^\d.]/g, '')
    switch (true) {
      case value <= 10:
        tempEstimate = 240
        break
      case value > 10:
        tempEstimate = 12 * (value - 10) + 240
        break
      default:
        tempEstimate = value * 240
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT H2
  const handleRangeDivA4 = (e) => {
    let tempEstimate = 0
    let value = e.target.value.replace(/[^\d.]/g, '')
    switch (true) {
      case value <= 10:
        tempEstimate = 48
        break
      case value > 10:
        tempEstimate = 2 * (value - 10) + 48
        break
      default:
        tempEstimate = value * 48
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT K1
  const handleRangeDivA5 = (e) => {
    let tempEstimate = 0
    let value = e.target.value.replace(/[^\d.]/g, '')
    switch (true) {
      case value <= 2:
        tempEstimate = 12
        break
      case value > 2:
        tempEstimate = 12 * (value - 2) + 12
        break
      default:
        tempEstimate = value * 12
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT K2
  const handleRangeDivA6 = (e) => {
    let tempEstimate = 0
    let value = e.target.value.replace(/[^\d.]/g, '')
    switch (true) {
      case value <= 10:
        tempEstimate = 480
        break
      case value > 10:
        tempEstimate = 24 * (value - 10) + 24
        break
      default:
        tempEstimate = value * 480
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT M1
  const handleRangeDivA7 = (e) => {
    let tempEstimate = 0
    let value = e.target.value.replace(/[^\d.]/g, '')
    switch (true) {
      case value <= 10:
        tempEstimate = 480
        break
      case value > 10:
        tempEstimate = 24 * (value - 10) + 480
        break
      default:
        tempEstimate = value * 480
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  //CAT M2
  const handleRangeDivA8 = (e) => {
    let tempEstimate = 0
    let value = e.target.value.replace(/[^\d.]/g, '')
    switch (true) {
      case value <= 20:
        tempEstimate = 540
        break
      case value > 20:
        tempEstimate = 24 * (value - 20) + 540
        break
      default:
        tempEstimate = value * 540
        break
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleChange = (e) => {
    let value = e.target.value.replace(/[^\d.]/g, '')
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: value,
            estimate: value * el.fee,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleCreateSubmit = () => {
    // console.log('hello')
    setModal({ successModal: true })
    dispatch(
      addAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
      })
    )
  }

  const handleUpdateSubmit = () => {
    // console.log('hi')
    setModal({ updateModal: true })
    dispatch(
      editAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
      })
    )
  }

  const handleBtnCancel = () => {
    if (isUpdating !== null) {
      history.push('/assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    } else {
      history.push('/for-assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    }
  }
  // End Functions

  //UseEffect
  useEffect(() => {
    dispatch(
      retrieveAppDetails({ appID: match.params.id, userID: userCredentials.id })
    )

    isUpdating !== null &&
      dispatch(
        retrieveAssessmentDetails({
          evalAppID: isUpdating.evalAppID,
          userID: userCredentials.id,
        })
      )
  }, [dispatch, match, userCredentials])

  useEffect(() => {
    setGrandTotal(() => total)

    setState(() => filtered)
  }, [total])

  useEffect(() => {
    if (isUpdating !== null) {
      if (assessmentDetails && assessmentDetails.message) {
        assessmentDetails.message.map((submitted) => {
          let temp = obj
          let index = obj.findIndex(
            (el) => el.id_per_name === submitted.id_per_name
          )
          temp[index].units = submitted.units
          temp[index].estimate = submitted.estimate
          temp[index] = { ...temp[index], id: submitted.id }
          setObj(temp)
        })
        setGrandTotal(() => assessmentDetails.message[0].grandTotal)
      }
    }
  }, [assessmentDetails])

  return (
    <UserMain>
      <Container className='bg-light pt-3'>
        <>
          <h1 className='text-center mb-4'>
            ACCESSORIES BUILDING STRUCTURE FEES
          </h1>
          <Row>
            <Col
              xl={12}
              className='mb-4 pt-3'
              style={{ background: '#f9f6f1' }}
            >
              <h6>Note: </h6>
              <p>Click category to be assessed.</p>
            </Col>
          </Row>

          <Form
            onSubmit={(e) => {
              e.preventDefault()
              isUpdating !== null ? handleUpdateSubmit() : handleCreateSubmit()
            }}
          >
            <Accordion className='mx-0'>
              {/* A. */}
              <Row>
                <Col xl={12}>
                  <h5 className='text-center'>A.</h5>
                  <hr />
                  <p className='text-center'>
                    All parts of buildings which are open on two (2) or more
                    sides, such as balconies, terraces, lanais and the like,
                    shall be charged 50% of the rate of the principal building
                    of which they are a part (Sections 3.a. to 3.d. of this
                    Schedule).
                  </p>
                </Col>
              </Row>
              {/* End A */}

              {/* B. */}
              <Row>
                <Col xl={12}>
                  <h5 className='text-center'>B.</h5>
                  <hr />
                  <p className='text-center'>
                    Buildings with a height of more than 8.00 meters shall be
                    charged an additional fee of twenty-five centavos (P 0.25)
                    per cu. meter above 8.00 meters. The height shall be
                    measured from the ground level up to the bottom of the roof
                    slab or the top of girts, whichever applies.
                  </p>
                </Col>
              </Row>
              {/* End B */}

              {/* C. Bank and Records Vaults  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='0'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  C. BANK AND RECORDS VAULTS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Bank and Records Vaults with interior volume up to
                            20.00 cu. meters
                          </td>
                          <td className='text-center'>₱20.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Bank Vaults'
                              placeholder='i.-ii.'
                              onChange={handleRangeDivA1}
                              value={obj[0].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Bank Vaults')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            In excess of 20.00 cu. meters
                          </td>
                          <td className='text-center'>₱8.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End C */}

              {/* D. Swimming Pools, per cu. meter or fraction thereof  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='1'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  D. SWIMMING POOLS, PER CU. METER OR FRACTION THEREOF
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='1'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Group A Residential
                          </td>
                          <td className='text-center'>₱3.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Grp Resident'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[1].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Grp Resident')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Commercial/Industrial Groups B, E, F, G
                          </td>
                          <td className='text-center'>₱36.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Commercial Grp'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[2].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Commercial Grp')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Social/Recreational/Institutional Groups C, D, H, I
                          </td>
                          <td className='text-center'>₱24.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Social Grp'
                              placeholder='iii.'
                              onChange={handleChange}
                              value={obj[3].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Social Grp')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Swimming pools improvised from local indigenous
                            materials such as rocks, stones and/or small
                            boulders and with plain cement flooring shall be
                            charged 50% of the above rates
                          </td>
                          <td className='text-center'></td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Swimming pool shower rooms/locker rooms:
                          </td>
                          <td className='text-center'></td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (a) Residential Group A
                          </td>
                          <td className='text-center'>₱6.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='GrpA Rooms'
                              placeholder='v.-a.'
                              onChange={handleChange}
                              value={obj[4].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'GrpA Rooms')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (b) Group B, E, F, G
                          </td>
                          <td className='text-center'>₱18.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='GrpB Rooms'
                              placeholder='v.-b.'
                              onChange={handleChange}
                              value={obj[5].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'GrpB Rooms')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (c) Group C, D, H
                          </td>
                          <td className='text-center'>₱12.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='GrpC Rooms'
                              placeholder='v.-c.'
                              onChange={handleChange}
                              value={obj[6].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'GrpC Rooms')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End D */}

              {/* E. Construction of firewalls separate from the building */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='2'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  E. CONSTRUCTION OF FIREWALLS SEPARATE FROM THE BUILDING
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='2'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Per sq. meter of fraction thereof
                          </td>
                          <td className='text-center'>₱3.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Firewall Add'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[7].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Firewall Add')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Provided, that the minimum fee shall be
                          </td>
                          <td className='text-center'>₱48.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Fwall Min'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[8].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Fwall Min')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End E */}

              {/* F.  Construction/Erection of towers: Including Radio and TV Towers, water tank */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='3'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  F. CONSTRUCTION/ERECTION OF TOWERS: INCLUDING RADIO AND TV
                  TOWERS, WATER TANKS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='3'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center align-middle'>
                          <th>#</th>
                          <th>Use or Character of Occupancy</th>
                          <th>Self Supporting</th>
                          <th>Trilon (Guyed)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className=' text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='align-middle'>
                            Single detached dwelling units
                          </td>
                          <td className='text-center align-middle'>₱500.00</td>
                          <td className='text-center align-middle'>₱150.00</td>
                        </tr>
                        <tr className='text-justify'>
                          <td className='align-middle'>ii.</td>
                          <td className='align-middle'>
                            Commercial/Industrial (Groups B, E, F, G) up to
                            10.00 meters in height
                          </td>
                          <td className='text-center align-middle'>
                            ₱2,400.00
                          </td>
                          <td className='text-center align-middle'>₱240.00</td>
                        </tr>
                        <tr className='text-justify'>
                          <td className='align-middle'></td>
                          <td className='align-middle'>
                            (a) Every meter or fraction thereof in excess of
                            10.00 meters
                          </td>
                          <td className='text-center align-middle'>₱120.00</td>
                          <td className='text-center align-middle'>₱12.00</td>
                        </tr>
                        <tr className='text-justify'>
                          <td className='align-middle'>iii.</td>
                          <td className='align-middle'>
                            Educational/Recreational/Institutional (Groups C, D,
                            H, I) up to 10.00 meters in height
                          </td>
                          <td className='text-center align-middle'>
                            ₱1,800.00
                          </td>
                          <td className='text-center align-middle'>₱120.00</td>
                        </tr>
                        <tr className='text-justify'>
                          <td className='align-middle'></td>
                          <td className='align-middle'>
                            (a) Every meter or fraction thereof in excess of
                            10.00 meters
                          </td>
                          <td className='text-center align-middle'>₱120.00</td>
                          <td className='text-center align-middle'>₱12.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End F */}

              {/* G. Storage Silos */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='4'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  G. STORAGE SILOS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Storage Silos, up to 10.00 meters in height
                          </td>
                          <td className='text-center'>₱2,400.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Storage Silos'
                              placeholder='i.'
                              onChange={handleRangeDivA2}
                              value={obj[9].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Storage Silos')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Every meter or fraction thereof in excess of 10.00
                            meters
                          </td>
                          <td className='text-center'>₱150.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Silos with platforms or floors shall be charged an
                            additional fee in accordance with Section 3.e. of
                            this Schedule
                          </td>
                          <td className='text-center'></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End G */}

              {/* H. Construction of Smokestacks and Chimneys for Commercial/Industrial Use Groups B, E, F and G */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='5'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  H. CONSTRUCTION OF SMOKESTACKS AND CHIMNEYS FOR
                  COMMERCIAL/INDUSTRIAL USE GROUP B, E, F, AND G
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='5'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Smokestacks, up to 10.00 meters in height, measured
                            from the base
                          </td>
                          <td className='text-center'>₱240.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Smokestacks'
                              placeholder='i.'
                              onChange={handleRangeDivA3}
                              value={obj[10].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Smokestacks')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (a) Every meter or fraction thereof in excess of
                            10.00 meters
                          </td>
                          <td className='text-center'>₱12.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Chimney up to 10.00 meters in height, measured from
                            the base
                          </td>
                          <td className='text-center'>₱48.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Chimney'
                              placeholder='ii.'
                              onChange={handleRangeDivA4}
                              value={obj[11].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {obj.find((el) => el.name === 'Chimney').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (a) Every meter or fraction thereof in excess of
                            10.00 meters
                          </td>
                          <td className='text-center'>₱2.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End H */}

              {/* I. Construction of Commercial/Industrial Fixed Ovens, per sq. meters or fraction thereof of interior floor areas */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='6'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  I. CONSTRUCTION OF COMMERCIAL/INDUSTRIAL FIXED OVENS, PER SQ.
                  METERS OR FRACTION THEREOF OF INTERIOR FLOOR AREAS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='6'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Construction of Commercial/Industrial Fixed Ovens,
                            per sq. meters or fraction thereof of interior floor
                            areas
                          </td>
                          <td className='text-center'>₱48.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Fixed Ovens'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[12].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Fixed Ovens')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End I */}

              {/* J. Construction of Industrial Kiln/Furnace, per cu. meter or fraction thereof of volume*/}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='7'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  J. CONSTRUCTION OF INDUSTRIAL KILN/FURNACE, PER CU. METER OR
                  FRACTION THEREOF OF VOLUME
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='7'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Construction of Industrial Kiln/Furnace, per cu.
                            meter or fraction thereof of volume
                          </td>
                          <td className='text-center'>₱12.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Kiln Furnace'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[13].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Kiln Furnace')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End J */}

              {/* K.  Construction of reinforced concrete or steel tanks or above ground Groups A and B */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='8'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  K. CONSTRUCTION OF REINFORCED CONCRETE OR STEEL TANKS OR ABOVE
                  GROUND GROUPS A AND B
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='8'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Construction of reinforced concrete or steel tanks
                            or above ground Groups A and B, up to 2.00 cu.
                            meters
                          </td>
                          <td className='text-center'>₱12.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Steel Tanks'
                              placeholder='i.'
                              onChange={handleRangeDivA5}
                              value={obj[14].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Steel Tanks')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Every cu. m or fraction thereof in excess of 2.00
                            cu. meters
                          </td>
                          <td className='text-center'>₱12.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            For all other than Groups A and B up to 10.00 cu.
                            meters
                          </td>
                          <td className='text-center'>₱480.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='SteelTank Excess'
                              placeholder='ii.'
                              onChange={handleRangeDivA6}
                              value={obj[15].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'SteelTank Excess')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            (a) Every cu. meter or fraction thereof inexcess of
                            10.00 cu. meters
                          </td>
                          <td className='text-center'>₱24.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End K */}

              {/* L. Construction of Water and Waste Water Treatment Tanks */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='9'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  L. Construction of Water and Waste Water Treatment Tanks
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='9'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Construction of Water and Waste Water Treatment
                            Tanks: (including Cisterns, Sedimentation and
                            Chemical Treatment Tanks) per cu. meter of volume
                          </td>
                          <td className='text-center'>₱12.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Waste Water'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[16].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Waste Water')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End L */}

              {/* M. Construction of reinforced concrete or steel tanks for Commercial/Industrial Use  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='10'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  M. CONSTRUCTION OF REINFORCED CONCRETE OR STEEL TANKS FOR
                  COMMERCIAL/INDUSTRIAL USE
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='10'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above ground, up to 10.00 cu. meters
                          </td>
                          <td className='text-center'>₱480.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Industrial Tank'
                              placeholder='i.'
                              onChange={handleRangeDivA7}
                              value={obj[17].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Industrial Tank')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Every cu. m or fraction thereof in excess of 10.00
                            cu. meters
                          </td>
                          <td className='text-center'>₱24.00</td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Underground, up to 20.00 cu. meters
                          </td>
                          <td className='text-center'>₱540.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Underground Tank'
                              placeholder='ii.'
                              onChange={handleRangeDivA8}
                              value={obj[18].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Underground Tank')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'></td>
                          <td className='text-wrap' colSpan={2}>
                            Every cu. meter or fraction thereof in excess of
                            20.00 cu. meters
                          </td>
                          <td className='text-center'>₱24.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End M */}

              {/* N. Pull-outs and Reinstallation of Commercial/Industrial Steel Tanks */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='11'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  N. PULL-OUTS AND REINSTALLATION OF COMMERCIAL/INDUSTRIAL STEEL
                  TANKS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='11'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Underground, per cu. meter or fraction thereof of
                            excavation
                          </td>
                          <td className='text-center'>₱3.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Excav Tank'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[19].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Excav Tank')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Saddle or trestle mounted horizontal tanks, per cu.
                            meter or fraction thereof of volume of tank
                          </td>
                          <td className='text-center'>₱3.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Saddle'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[20].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Saddle').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Reinstallation of vertical storage tanks shall be
                            the same as new construction fess in accordance with
                            Section 8.k. above
                          </td>
                          <td className='text-center'></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End N */}

              {/*O. Booths, Kiosks, Platforms, Stages and the like, per sq. meter or fraction thereof of floor area  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='12'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  O. BOOTHS, KIOSK, PLATFORMS, STAGES, AND THE LIKE, PER SQ.
                  METER OR FRACTION THEREOF OF FLOOR AREA
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='12'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Construction of permanent type
                          </td>
                          <td className='text-center'>₱10.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Permanent Type'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[21].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Permanent Type')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Construction of temporary type
                          </td>
                          <td className='text-center'>₱5.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Temp Type'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[22].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Temp Type')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Inspection of knock-down temporary type, per unit
                          </td>
                          <td className='text-center'>₱24.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Inspection Type'
                              placeholder='iii.'
                              onChange={handleChange}
                              value={obj[23].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Inspection Type')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End O */}

              {/* P. Construction of buildings and other accessory structures within cemeteries and memorial parks */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='13'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  P. CONSTRUCTION OF BUILDINGS AND OTHER ACCESSORY STRUCTURES
                  WITHIN CEMETRIES AND MEMORIAL PARKS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='13'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Tombs, per sq. meter of covered ground areas
                          </td>
                          <td className='text-center'>₱5.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Tombs'
                              placeholder='i.'
                              onChange={handleChange}
                              value={obj[24].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱{obj.find((el) => el.name === 'Tombs').estimate}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Semi-enclosed mausoleums whether canopied or not,
                            per sq. meter of built-up area
                          </td>
                          <td className='text-center'>₱5.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Mausoleums'
                              placeholder='ii.'
                              onChange={handleChange}
                              value={obj[25].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Mausoleums')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Totally enclosed mausoleums, per sq. meter of floor
                            area
                          </td>
                          <td className='text-center'>₱12.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Enclosed'
                              placeholder='iii.'
                              onChange={handleChange}
                              value={obj[26].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Enclosed')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Multi-level interment inches per sq. meter, per
                            level
                          </td>
                          <td className='text-center'>₱5.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Interment'
                              placeholder='iv.'
                              onChange={handleChange}
                              value={obj[27].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Interment')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle text-wrap'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Columbarium, per sq. meter
                          </td>
                          <td className='text-center'>₱18.00</td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Columbarium'
                              placeholder='v.'
                              onChange={handleChange}
                              value={obj[28].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'Columbarium')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End P */}
            </Accordion>

            <Row className='mt-3 mx-1'>
              <Col xl={8} className='text-end align-middle mt-2'>
                <h4>GRAND TOTAL:</h4>
              </Col>
              <Col xl={4} className='mb-5'>
                <Form.Control
                  type='text'
                  className='border border-secondary'
                  style={{
                    marginLeft: '8px',
                    marginRight: '5px',
                  }}
                  name='GrandTotal'
                  value={grandTotal}
                  placeholder='Grand Total'
                  disabled
                />
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col xl={12} className='text-end'>
                <Button type='submit' variant='success'>
                  Submit
                </Button>
                <Button
                  variant='danger'
                  className='mx-2'
                  onClick={handleBtnCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      </Container>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          localStorage.removeItem('assessmentSelected')
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={createAssessment && createAssessment.loading}
        success={createAssessment && createAssessment.message}
        error={createAssessment && createAssessment.error}
      />
      {/* End Success Modal */}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateModal}
        onHide={() => {
          setModal({ updateModal: false })
          localStorage.removeItem('assessmentSelected')
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={updateAssessment && updateAssessment.loading}
        success={updateAssessment && updateAssessment.message}
        error={updateAssessment && updateAssessment.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(StructuralAssessmentScreen)
