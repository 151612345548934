import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  body: {
    paddingTop: 20,
    paddingBottom: 65,
    paddingHorizontal: 20,
  },
  HeaderCenter: {
    textAlign: 'center',
    fontSize: 10,
    width: '100%',
    paddingTop: '20px',
  },
  code: {
    textAlign: 'left',
    fontSize: 10,
    width: '50%',
    paddingTop: '10px',
    fontFamily: 'Times-Bold',
  },
  date: {
    textAlign: 'right',
    fontSize: 10,
    width: '50%',
    paddingTop: '10px',
    fontFamily: 'Times-Bold',
  },
  owner: {
    textAlign: 'left',
    fontSize: 10,
    width: '50%',
    paddingTop: '10px',
    fontFamily: 'Times-Bold',
  },
  OrdPay: {
    textAlign: 'center',
    fontSize: 14,
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '20px',
    fontFamily: 'Times-Bold',
  },
  numbers: {
    textAlign: 'left',
    paddingLeft: '50px',
    width: '100%',
    fontSize: 10,
    paddingRight: '20px',
    paddingTop: '3px',
  },
  trades: {
    fontSize: 10,
    paddingRight: '10px',
    textAlign: 'left',
    paddingTop: '3px',
  },
  price: {
    fontSize: 10,
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'right',
    paddingTop: '3px',
  },
})

const OrderPay = ({ info, penaltyValue }) => {
  let uline2 = true
  return (
    <Document>
      <Page size='Legal' style={styles.body} wrap>
        <View>
          <View style={styles.row}>
            <Text style={styles.HeaderCenter}>
              Metropolitan Manila Authority
            </Text>
          </View>
          <View style={styles.row}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 10,
                width: '100%',
              }}
            >
              Action Center for Infrastructure Development
            </Text>
          </View>
          <View style={styles.row}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 10,
                width: '100%',
                fontFamily: 'Times-Bold',
              }}
            >
              OFFICE OF THE BUILDING OFFICIAL
            </Text>
          </View>

          <View style={styles.row}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 10,
                width: '100%',
              }}
            >
              City of Las Piñas
            </Text>
          </View>
          <View style={styles.row}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 10,
                width: '100%',
              }}
            >
              Metro Manila
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <Text style={styles.code}>
            CODE:
            <Text>
              {info !== undefined &&
                info[0].categCode !== null &&
                info[0].id !== null &&
                info[0].categCode + ' ' + info[0].id}
            </Text>
          </Text>

          <Text style={styles.date}>
            DATE :{' '}
            <Text>
              {info !== undefined &&
                info[0].recommendDate !== null &&
                info[0].recommendDate}
            </Text>
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.owner}>
            Owner/Firm/Applicant:{' '}
            <Text>{info !== undefined && info[0].Owner}</Text>
          </Text>
        </View>

        <View style={styles.row}>
          <Text
            style={{
              textAlign: 'left',
              fontSize: 10,
              paddingTop: '5px',
              fontFamily: 'Times-Bold',
            }}
          >
            Project:{' '}
            <Text>
              {info !== undefined &&
                info[0].scopeOfWork !== null &&
                info[0].scopeOfWork}
            </Text>
          </Text>
        </View>

        <View style={styles.row}>
          <Text
            style={{
              textAlign: 'left',
              fontSize: 10,
              paddingTop: '5px',
              fontFamily: 'Times-Bold',
            }}
          >
            Location:{' '}
            <Text>
              {info !== undefined &&
                info[0].Project_Location !== null &&
                info[0].Project_Location}
            </Text>
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.OrdPay}>ORDER OF PAYMENT</Text>
        </View>

        <View style={styles.row}>
          <Text
            style={{
              textAlign: 'left',
              fontSize: 10,
              paddingBottom: '10px',
              fontFamily: 'Times-Bold',
            }}
          >
            TO:{' '}
            <Text style={{ fontFamily: 'Times-Bold' }}>
              CITY/MUNICIPAL TREASURER/CASHIER
            </Text>
          </Text>
        </View>

        <View style={styles.row}>
          <Text
            style={{
              paddingBottom: '10px',
              width: '100%',
              fontSize: 10,
            }}
          >
            Please collect the amount specified below as perscribed in the
            implementing Rules and Regulations of the National Building Code
            (PD-1096).
          </Text>
        </View>

        {/* LINE AND GRADE */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            001{'   '}
            <Text style={styles.trades}>
              Line and
              Grade....................................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].LineAndGrade.toLocaleString() : '-'}
          </Text>
        </View>

        {/* BUILDING PERMIT FEES */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            002{'   '}
            <Text>
              Building Permit
              Fees............................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].BuildingPermitFees : '-'}
          </Text>
        </View>

        {/* ADDITIONAL/RENOVATION */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            {'   '}
            <Text>
              {'        '}
              Additional/Renovation..........................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].AdditionalRenovation.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* ROOF DECK */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            {'   '}
            <Text>
              {'        '}
              Roof
              Deck............................................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].RoofDeck.toLocaleString() : '-'}
          </Text>
        </View>

        {/* DEMOLITION PERMIT */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            003{'   '}
            <Text>
              Demolition Permit
              Fees........................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].DemolitionPermitFees.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* ANCILLARY STRUCTURE */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            004{'   '}
            <Text>
              Ancillary Structure
              ...............................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].AncillaryStructure.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* CONSTRUCTION OF TOMBS/CANOPIES */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            005{'   '}
            <Text>
              Construction of Tombs/Canopies
              ........................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].TombsCanopies.toLocaleString() : '-'}
          </Text>
        </View>

        {/* PLUMBING PERMIT FEES */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            006{'   '}
            <Text>
              Plumbing Permit Fees
              .........................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].PlumbingPermitFees.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* ELECTRICAL PERMIT FEES */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            007{'   '}
            <Text>
              Electrical Permit Fees
              .........................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].ElectricalPermitFees.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* MECHANICAL PERMITS FEES */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            008{'   '}
            <Text>
              Mechanical Permit Fees
              ......................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].MechanicalPermitFees.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* FENCING PERMIT FEES */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            009{'   '}
            <Text>
              Fencing Permit Fees
              ...........................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].FencingPermitFees.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* CONSTRUCTION OF SIDEWALKS */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            010{'   '}
            <Text>
              Construction of Sidewalks
              ...................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].Sidewalks.toLocaleString() : '-'}
          </Text>
        </View>

        {/* PAVED AREAS */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            011{'   '}
            <Text>
              Paved Areas
              ........................................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].PavedAreas.toLocaleString() : '-'}
          </Text>
        </View>

        {/* USE OF STREET AND SIDEWALKS */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            012{'   '}
            <Text>
              Use of Street and Sidewalks
              ...............................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].StreetSidewalk.toLocaleString() : '-'}
          </Text>
        </View>

        {/* CERTIFICATE OF USE/OCCUPANCY */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            013{'   '}
            <Text>
              Certificate of Use/Occupancy
              .............................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].CertificateOccupancy.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* CHANGE IN USE/OCCUPANCY */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            014{'   '}
            <Text>
              Change in Use/Occupancy
              .................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].ChangeOccupancy.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* CERTIFICATE OF OCCUPANCY PRIOR TO 10 */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            015{'   '}
            <Text>
              Certificate of Occupancy prior to 10
              ...................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].CertificateOccupancy10.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* ELECTRONICS PERMIT */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            016{'   '}
            <Text>
              Electronics Permit
              ...............................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].ElectronicsPermit.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* ANNUAL INSPECTION */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            017{'   '}
            <Text>
              Annual Inspection
              ...............................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].AnnualInspection.toLocaleString()
              : '-'}
          </Text>
        </View>

        {/* SIGN PERMIT FEES */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            018{'   '}
            <Text>
              Sign Permit Fees
              ................................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].SignPermitFees.toLocaleString() : '-'}
          </Text>
        </View>

        {/* CERTIFICATION */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            019{'   '}
            <Text>
              Certification
              .........................................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].Certification.toLocaleString() : '-'}
          </Text>
        </View>

        {/* PENALTY/SURCHARGE */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            020{'   '}
            <Text>
              Penalty/Surcharge
              ...............................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? penaltyValue.toLocaleString() : '-'}
          </Text>
        </View>

        {/* ADMINISTRATIVE FINE */}
        <View style={styles.row}>
          <Text style={styles.numbers}>
            021{'   '}
            <Text>
              Administrative Fine
              ..............................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].AdministrativeFive.toLocaleString()
              : '-'}
          </Text>
        </View>

        <View style={styles.row}>
          <View style={{ width: '100%', textAlign: 'right' }}>
            <Text
              style={{
                fontSize: 10,
                paddingLeft: '10px',
                textAlign: 'right',
                paddingTop: '25px',
                fontFamily: 'Times-Bold',
              }}
            >
              SUB - TOTAL{'      '}P{'                                '}
              {uline2 ? (
                <Text
                  style={{
                    fontSize: 10,
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    textAlign: 'right',
                    paddingTop: '3px',
                    textDecoration: 'underline',
                    fontFamily: 'Times-Bold',
                  }}
                >
                  {info !== undefined ? info[0].subTotal.toLocaleString() : '-'}
                </Text>
              ) : (
                '__________________________________________'
              )}
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <Text
            style={{
              paddingTop: '25px',
              paddingBottom: '20px',
              width: '100%',
              fontSize: 10,
            }}
          >
            Please collect the amount specified below as perscribed in the
            approved City Ordinance No. 1373 - 16 series of 2016 Las Piñas City.
          </Text>
        </View>

        <View style={styles.row}>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 14,
              width: '100%',
              fontFamily: 'Times-Bold',
            }}
          >
            Building Permit
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.numbers}>
            001{'   '}
            <Text>
              Filling Fee
              ............................................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].FilingFee.toLocaleString() : '-'}
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.numbers}>
            002{'   '}
            <Text>
              Processing Fee
              ....................................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].ProcessingFee.toLocaleString() : '-'}
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.numbers}>
            003{'   '}
            <Text>
              Complaint Filling Fee
              ...........................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined
              ? info[0].ComplaintFilingFee.toLocaleString()
              : '-'}
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.numbers}>
            004{'   '}
            <Text>
              Others (Specify)
              ...................................................................................................
              {'     '}P
            </Text>
          </Text>
          <Text style={styles.price}>
            {info !== undefined ? info[0].Others.toLocaleString() : '-'}
          </Text>
        </View>

        <View style={styles.row}>
          <View style={{ width: '100%', textAlign: 'right' }}>
            <Text
              style={{
                fontSize: 10,
                paddingLeft: '10px',
                textAlign: 'right',
                paddingTop: '25px',
                fontFamily: 'Times-Bold',
              }}
            >
              SUB - TOTAL{'      '}P{'                                '}
              {uline2 ? (
                <Text
                  style={{
                    fontSize: 10,
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    textAlign: 'right',
                    paddingTop: '3px',
                    textDecoration: 'underline',
                    fontFamily: 'Times-Bold',
                  }}
                >
                  {info !== undefined
                    ? info[0].subTotal1.toLocaleString()
                    : '-'}
                </Text>
              ) : (
                '__________________________________________'
              )}
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={{ width: '100%', textAlign: 'right' }}>
            <Text
              style={{
                fontSize: 10,
                paddingLeft: '10px',
                textAlign: 'right',
                paddingTop: '25px',
                fontFamily: 'Times-Bold',
              }}
            >
              TOTAL AMOUNT{'      '}P{'                                '}
              {uline2 ? (
                <Text
                  style={{
                    fontSize: 10,
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    textAlign: 'right',
                    paddingTop: '3px',
                    textDecoration: 'underline',
                    fontFamily: 'Times-Bold',
                  }}
                >
                  {info !== undefined
                    ? info[0].totalAmount.toLocaleString()
                    : '-'}
                </Text>
              ) : (
                '__________________________________________'
              )}
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <Text
            style={{
              textAlign: 'left',
              fontSize: 10,
              width: '50%',
              paddingTop: '30px',
              paddingLeft: '50px',
            }}
          >
            GENERATED BY:
          </Text>
          <Text
            style={{
              textAlign: 'right',
              fontSize: 10,
              width: '50%',
              paddingTop: '30px',
              paddingRight: '96px',
            }}
          >
            APPROVED FOR PAYMENT:
          </Text>
        </View>

        <View style={styles.row}>
          <Text
            style={{
              textAlign: 'left',
              fontSize: 10,
              width: '50%',
              paddingTop: '20px',
              paddingLeft: '50px',
              fontFamily: 'Times-Bold',
            }}
          >
            {info !== undefined ? info[0].RecommendedBy : '-'}
          </Text>
          <Text
            style={{
              textAlign: 'right',
              fontSize: 10,
              width: '50%',
              paddingTop: '20px',
              paddingRight: '75px',
              fontFamily: 'Times-Bold',
            }}
          >
            ENGR. {info !== undefined ? info[0].CityEngineer : '-'}
          </Text>
        </View>

        <View style={styles.row}>
          <Text
            style={{
              textAlign: 'left',
              fontSize: 10,
              width: '50%',
              paddingLeft: '50px',
            }}
          >
            {/* REY */}
          </Text>
          <Text
            style={{
              textAlign: 'right',
              fontSize: 10,
              width: '50%',
              paddingRight: '83px',
              fontWeight: 'bold',
            }}
          >
            City Engineer/Building Official
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default OrderPay
