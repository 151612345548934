import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_EMPLOYEEDETAILS_REQ,
  GET_EMPLOYEEDETAILS_SUCCESS,
  GET_EMPLOYEEDETAILS_FAILED,
  GET_ALLAPPLICATIONS_REQ,
  GET_ALLAPPLICATIONS_SUCCESS,
  GET_ALLAPPLICATIONS_FAILED,
  GET_FILTEREDAPPLICATION_REQ,
  GET_FILTEREDAPPLICATION_SUCCESS,
  GET_FILTEREDAPPLICATION_FAILED,
  GET_APPCOMMENTS_REQ,
  GET_APPCOMMENTS_SUCCESS,
  GET_APPCOMMENTS_FAILED,
  GET_APPLICATIONINFORMATION_REQ,
  GET_APPLICATIONINFORMATION_SUCCESS,
  GET_APPLICATIONINFORMATION_FAILED,
  GET_REQUIREMENTLIST_REQ,
  GET_REQUIREMENTLIST_SUCCESS,
  GET_REQUIREMENTLIST_FAILED,
  GET_SUBMITTEDREQUIREMENTS_REQ,
  GET_SUBMITTEDREQUIREMENTS_SUCCESS,
  GET_SUBMITTEDREQUIREMENTS_FAILED,
  GET_TRADEEVALUATORS_REQ,
  GET_TRADEEVALUATORS_SUCCESS,
  GET_TRADEEVALUATORS_FAILED,
  GET_INSPECTORS_REQ,
  GET_INSPECTORS_SUCCESS,
  GET_INSPECTORS_FAILED,
  GET_SUBMITTEDEVALUATORS_REQ,
  GET_SUBMITTEDEVALUATORS_SUCCESS,
  GET_SUBMITTEDEVALUATORS_FAILED,
  GET_SUBMITTEDINSPECTOR_REQ,
  GET_SUBMITTEDINSPECTOR_SUCCESS,
  GET_SUBMITTEDINSPECTOR_FAILED,
  GET_ENGINEERINFORMATION_REQ,
  GET_ENGINEERINFORMATION_SUCCESS,
  GET_ENGINEERINFORMATION_FAILED,
  GET_ORDERPAYMENT_REQ,
  GET_ORDERPAYMENT_SUCCESS,
  GET_ORDERPAYMENT_FAILED,
  GET_AMOUNTPAID_REQ,
  GET_AMOUNTPAID_SUCCESS,
  GET_AMOUNTPAID_FAILED,
  GET_COLLECTIONREPORT_REQ,
  GET_COLLECTIONREPORT_SUCCESS,
  GET_COLLECTIONREPORT_FAILED,
  GET_CREATEDAPP_REQ,
  GET_CREATEDAPP_SUCCESS,
  GET_CREATEDAPP_FAILED,
  GET_EVALUATIONSHEET_REQ,
  GET_EVALUATIONSHEET_SUCCESS,
  GET_EVALUATIONSHEET_FAILED,
  GET_GUEST_APPLICATION_REQ,
  GET_GUEST_APPLICATION_SUCCESS,
  GET_GUEST_APPLICATION_FAILED,
  GET_GUEST_APPLICATION_BYID_REQ,
  GET_GUEST_APPLICATION_BYID_SUCCESS,
  GET_GUEST_APPLICATION_BYID_FAILED,
  GET_CFEI_REQ,
  GET_CFEI_SUCCESS,
  GET_CFEI_FAILED,
  GET_CONTRACTORSTAX_REQ,
  GET_CONTRACTORSTAX_SUCCESS,
  GET_CONTRACTORSTAX_FAILED,
  GET_APPSTATUSTRACKING_REQ,
  GET_APPSTATUSTRACKING_SUCCESS,
  GET_APPSTATUSTRACKING_FAILED,
  GET_RPTVERIFICATION_REQ,
  GET_RPTVERIFICATION_SUCCESS,
  GET_RPTVERIFICATION_FAILED,
  GET_CTVERIFICATION_REQ,
  GET_CTVERIFICATION_SUCCESS,
  GET_CTVERIFICATION_FAILED,
  UPDATE_APPSTATUS_REQ,
  UPDATE_APPSTATUS_SUCCESS,
  UPDATE_APPSTATUS_FAILED,
  UPDATE_RELEASEPERMIT_REQ,
  UPDATE_RELEASEPERMIT_SUCCESS,
  UPDATE_RELEASEPERMIT_FAILED,
  UPDATE_EMPLOYEEDETAILS_REQ,
  UPDATE_EMPLOYEEDETAILS_SUCCESS,
  UPDATE_EMPLOYEEDETAILS_FAILED,
  UPDATE_EMPLOYEECREDENTIALS_REQ,
  UPDATE_EMPLOYEECREDENTIALS_SUCCESS,
  UPDATE_EMPLOYEECREDENTIALS_FAILED,
  UPDATE_APPINFORMATION_REQ,
  UPDATE_APPINFORMATION_SUCCESS,
  UPDATE_APPINFORMATION_FAILED,
  UPDATE_SUBMITTEDREQUIREMENTS_REQ,
  UPDATE_SUBMITTEDREQUIREMENTS_SUCCESS,
  UPDATE_SUBMITTEDREQUIREMENTS_FAILED,
  UPDATE_ENGINEERINFORMATION_REQ,
  UPDATE_ENGINEERINFORMATION_SUCCESS,
  UPDATE_ENGINEERINFORMATION_FAILED,
  UPDATE_EVALUATORS_REQ,
  UPDATE_EVALUATORS_SUCCESS,
  UPDATE_EVALUATORS_FAILED,
  UPDATE_INSPECTOR_REQ,
  UPDATE_INSPECTOR_SUCCESS,
  UPDATE_INSPECTOR_FAILED,
  UPDATE_ORDEROFPAYMENT_REQ,
  UPDATE_ORDEROFPAYMENT_SUCCESS,
  UPDATE_ORDEROFPAYMENT_FAILED,
  UPDATE_GUEST_APPLICATION_REQ,
  UPDATE_GUEST_APPLICATION_SUCCESS,
  UPDATE_GUEST_APPLICATION_FAILED,
  UPDATE_CFEI_REQ,
  UPDATE_CFEI_SUCCESS,
  UPDATE_CFEI_FAILED,
  UPDATE_CONTRACTORSTAX_REQ,
  UPDATE_CONTRACTORSTAX_SUCCESS,
  UPDATE_CONTRACTORSTAX_FAILED,
  INSERT_APPLICATION_REQ,
  INSERT_APPLICATION_SUCCESS,
  INSERT_APPLICATION_FAILED,
  INSERT_APPINFO_REQ,
  INSERT_APPINFO_SUCCESS,
  INSERT_APPINFO_FAILED,
  INSERT_ORDEROFPAYMENT_REQ,
  INSERT_ORDEROFPAYMENT_SUCCESS,
  INSERT_ORDEROFPAYMENT_FAILED,
  INSERT_NEWSANDUPDATES_REQ,
  INSERT_NEWSANDUPDATES_SUCCESS,
  INSERT_NEWSANDUPDATES_FAILED,
  INSERT_EVALUATIONSHEET_REQ,
  INSERT_EVALUATIONSHEET_SUCCESS,
  INSERT_EVALUATIONSHEET_FAILED,
  INSERT_GUEST_APPLICATION_REQ,
  INSERT_GUEST_APPLICATION_SUCCESS,
  INSERT_GUEST_APPLICATION_FAILED,
  INSERT_CFEI_REQ,
  INSERT_CFEI_SUCCESS,
  INSERT_CFEI_FAILED,
  DELETE_EVALUATIONSHEET_REQ,
  DELETE_EVALUATIONSHEET_SUCCESS,
  DELETE_EVALUATIONSHEET_FAILED,
  DELETE_GUEST_APPLICATION_REQ,
  DELETE_GUEST_APPLICATION_SUCCESS,
  DELETE_GUEST_APPLICATION_FAILED,
  DELETE_CFEI_REQ,
  DELETE_CFEI_SUCCESS,
  DELETE_CFEI_FAILED,
  GET_RPTLOGIN_REQ,
  GET_RPTLOGIN_SUCCESS,
  GET_RPTLOGIN_FAILED,
  GET_CTLOGIN_REQ,
  GET_CTLOGIN_SUCCESS,
  GET_CTLOGIN_FAILED,
} from '../../constants/DocumentControllers/documentControllerConstants'

// Retrieve

export const retrieveEmployeeDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EMPLOYEEDETAILS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/employee-details/${payload.id}`,
        config
      )

      dispatch({
        type: GET_EMPLOYEEDETAILS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_EMPLOYEEDETAILS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveAllApplications = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALLAPPLICATIONS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      // console.log(config)

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/all-applications`,
        config
      )

      // console.log(data)

      dispatch({
        type: GET_ALLAPPLICATIONS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ALLAPPLICATIONS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrievefilterApplications = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FILTEREDAPPLICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      // console.log(config)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentcontroller/filter-applications`,
        payload,
        config
      )

      // console.log(data)

      dispatch({
        type: GET_FILTEREDAPPLICATION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_FILTEREDAPPLICATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveApplicationComments = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APPCOMMENTS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/comments-list/${payload.id}`,
        config
      )

      dispatch({
        type: GET_APPCOMMENTS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_APPCOMMENTS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveApplicationInformation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APPLICATIONINFORMATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/application-information/${payload.id}`,
        config
      )

      dispatch({
        type: GET_APPLICATIONINFORMATION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_APPLICATIONINFORMATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveRequirementList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_REQUIREMENTLIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/requirement-list/${payload.pmCode}`,
        config
      )

      dispatch({
        type: GET_REQUIREMENTLIST_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_REQUIREMENTLIST_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveSubmittedRequirements = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBMITTEDREQUIREMENTS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/submitted-requirements/${payload.id}`,
        config
      )

      dispatch({
        type: GET_SUBMITTEDREQUIREMENTS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_SUBMITTEDREQUIREMENTS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveEvaluators = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TRADEEVALUATORS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/evaluators`,
        config
      )

      dispatch({
        type: GET_TRADEEVALUATORS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_TRADEEVALUATORS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveInspectors = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_INSPECTORS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/inspectors`,
        config
      )

      dispatch({
        type: GET_INSPECTORS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_INSPECTORS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveSubmittedEvaluators = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBMITTEDEVALUATORS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/submitted-evaluators/${payload.id}`,
        config
      )

      dispatch({
        type: GET_SUBMITTEDEVALUATORS_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_SUBMITTEDEVALUATORS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveSubmittedInspector = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBMITTEDINSPECTOR_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/submitted-inspector/${payload.id}`,
        config
      )

      dispatch({
        type: GET_SUBMITTEDINSPECTOR_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_SUBMITTEDINSPECTOR_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveSubmittedEngineers = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ENGINEERINFORMATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/submitted-engineers/${payload.id}`,
        config
      )

      dispatch({
        type: GET_ENGINEERINFORMATION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ENGINEERINFORMATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveSubmittedOrderofPayment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ORDERPAYMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/submitted-orderofpayment/${payload.id}`,
        config
      )

      dispatch({
        type: GET_ORDERPAYMENT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_ORDERPAYMENT_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveAmountPaid = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_AMOUNTPAID_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/paid-amount/${payload.id}`,
        config
      )

      dispatch({
        type: GET_AMOUNTPAID_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_AMOUNTPAID_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveEvaluationSheet = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EVALUATIONSHEET_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/evaluation-sheet/${payload.id}`,
        config
      )

      dispatch({
        type: GET_EVALUATIONSHEET_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_EVALUATIONSHEET_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveCollectionReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COLLECTIONREPORT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentcontroller/reports`,
        payload,
        config
      )

      dispatch({
        type: GET_COLLECTIONREPORT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_COLLECTIONREPORT_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveCreatedApp = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CREATEDAPP_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/created-application/${payload.empID}`,
        config
      )

      dispatch({
        type: GET_CREATEDAPP_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_CREATEDAPP_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retriveGuestApplication = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_GUEST_APPLICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/application-list`,
        config
      )

      dispatch({
        type: GET_GUEST_APPLICATION_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_GUEST_APPLICATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retriveCFEI = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CFEI_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/cfei/${payload.id}`,
        config
      )

      dispatch({
        type: GET_CFEI_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_CFEI_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveContractorsTax = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CONTRACTORSTAX_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/contractors-tax/${payload.id}`,
        config
      )

      dispatch({
        type: GET_CONTRACTORSTAX_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_CONTRACTORSTAX_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retrieveAppStatTracking = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APPSTATUSTRACKING_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/application-status/${payload.appID}`,
        config
      )

      dispatch({
        type: GET_APPSTATUSTRACKING_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_APPSTATUSTRACKING_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retriveRPT = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_RPTVERIFICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('rptAccess')}`,
        },
      }

      // console.log(payload)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/getAssessors`,
        payload,
        config
      )

      dispatch({
        type: GET_RPTVERIFICATION_SUCCESS,
        payload: data,
      })
    } catch {
      dispatch({
        type: GET_RPTVERIFICATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retriveCT = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CTVERIFICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('ctAccess')}`,
        },
      }

      // console.log(payload)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/contractors-computation`,
        payload,
        config
      )

      dispatch({
        type: GET_CTVERIFICATION_SUCCESS,
        payload: data,
      })
    } catch {
      dispatch({
        type: GET_CTVERIFICATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const rptLogin = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_RPTLOGIN_REQ })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/RptApi?extensions=/token`
      )

      dispatch({
        type: GET_RPTLOGIN_SUCCESS,
        payload: 'success',
      })

      Cookie.set('rptAccess', data.access_token, {
        expires: 1,
        sameSite: 'strict',
        secure: true,
      })
    } catch {
      dispatch({
        type: GET_RPTLOGIN_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const ctLogin = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CTLOGIN_REQ })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/LPeToims_API`
      )

      dispatch({
        type: GET_CTLOGIN_SUCCESS,
        payload: 'success',
      })

      Cookie.set('ctAccess', data.access_token, {
        expires: 1,
        sameSite: 'strict',
        secure: true,
      })
    } catch {
      dispatch({
        type: GET_CTLOGIN_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const retriveGuestApplicationByID = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_GUEST_APPLICATION_BYID_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documentcontroller/application-list/${payload.id}`,
        config
      )

      dispatch({
        type: GET_GUEST_APPLICATION_BYID_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_GUEST_APPLICATION_BYID_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// UPDATE

export const updateEmployeeDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_EMPLOYEEDETAILS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/employee-details/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_EMPLOYEEDETAILS_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_EMPLOYEEDETAILS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const upateEmployeeCredentials = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_EMPLOYEECREDENTIALS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/employee-credentials/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_EMPLOYEECREDENTIALS_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_EMPLOYEECREDENTIALS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateApplicationStatus = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_APPSTATUS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentcontroller/update-status/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_APPSTATUS_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveAllApplications())

      const textConfig = {
        headers: {
          'Content-Type': 'application/json',
          'X-MEMS-API-ID': '45745164',
          'X-MEMS-API-KEY': 'd9b33ea724fb4e2f810232a406bb1062',
        },
      }

      const message =
        'The status of your application in Office of the Building Official in City Governement of Las Piñas has changed; Please login to your account at lp-bldgo.com website to check the application.'

      // await axios.get(
      //   `/cgphttp/servlet/sendmsg?destination=63${payload.mobileNo}&text=${message}`,
      //   textConfig
      // )
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/messaging`,
        params: {
          extensions: `destination=63${payload.mobileNo}&text=${message}`,
        },
        withCredentials: false,
      })

      // dispatch(retrieveSubmittedRequirements({ id: payload.id }))
      // dispatch(retrieveApplicationComments({ id: payload.id }))
      // dispatch(retrieveSubmittedEvaluators({ id: payload.id }))
      // dispatch(retrieveSubmittedInspector({ id: payload.id }))
      // dispatch(retrieveSubmittedEngineers({ id: payload.id }))
    } catch {
      dispatch({
        type: UPDATE_APPSTATUS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateReleasePermit = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_RELEASEPERMIT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/update-permit/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_RELEASEPERMIT_SUCCESS,
        payload: data.message,
      })
      dispatch(retrieveAllApplications())
    } catch {
      dispatch({
        type: UPDATE_RELEASEPERMIT_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateApplicationInformation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_APPINFORMATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/update-application-info/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_APPINFORMATION_SUCCESS,
        payload: data.message,
      })
      dispatch(retrieveAllApplications())
    } catch {
      dispatch({
        type: UPDATE_APPINFORMATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateSubmittedRequirements = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SUBMITTEDREQUIREMENTS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/submitted-requirements/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_SUBMITTEDREQUIREMENTS_SUCCESS,
        payload: data.message,
      })

      // dispatch(retrieveAllApplications())
      dispatch(retrieveSubmittedRequirements({ id: payload.id }))
      // dispatch(retrieveApplicationComments({ id: payload.id }))
      // dispatch(retrieveSubmittedEvaluators({ id: payload.id }))
      // dispatch(retrieveSubmittedInspector({ id: payload.id }))
      // dispatch(retrieveSubmittedEngineers({ id: payload.id }))
    } catch {
      dispatch({
        type: UPDATE_SUBMITTEDREQUIREMENTS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateEngineerInformation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ENGINEERINFORMATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/engineer-information/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_ENGINEERINFORMATION_SUCCESS,
        payload: data.message,
      })
      // dispatch(retrieveAllApplications())
      // dispatch(retrieveSubmittedRequirements({ id: payload.id }))
      // dispatch(retrieveApplicationComments({ id: payload.id }))
      // dispatch(retrieveSubmittedEvaluators({ id: payload.id }))
      // dispatch(retrieveSubmittedInspector({ id: payload.id }))
      // dispatch(retrieveSubmittedEngineers({ id: payload.id }))
    } catch {
      dispatch({
        type: UPDATE_ENGINEERINFORMATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateEvaluators = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_EVALUATORS_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/submit-evaluators/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_EVALUATORS_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveAllApplications())
      dispatch(retrieveEvaluators())
      dispatch(retrieveSubmittedRequirements({ id: payload.id }))
      dispatch(retrieveApplicationComments({ id: payload.id }))
      dispatch(retrieveSubmittedEvaluators({ id: payload.id }))
      dispatch(retrieveSubmittedInspector({ id: payload.id }))
      dispatch(retrieveSubmittedEngineers({ id: payload.id }))
    } catch {
      dispatch({
        type: UPDATE_EVALUATORS_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateInspector = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_INSPECTOR_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/submit-inspector/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_INSPECTOR_SUCCESS,
        payload: data.message,
      })
      // dispatch(retrieveAllApplications())
      // dispatch(retrieveSubmittedRequirements({ id: payload.id }))
      // dispatch(retrieveApplicationComments({ id: payload.id }))
      // dispatch(retrieveSubmittedEvaluators({ id: payload.id }))
      // dispatch(retrieveSubmittedInspector({ id: payload.id }))
      // dispatch(retrieveSubmittedEngineers({ id: payload.id }))
    } catch {
      dispatch({
        type: UPDATE_INSPECTOR_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const updateOrderOfPayment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ORDEROFPAYMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/submit-orderofpayment/${payload.oid}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_ORDEROFPAYMENT_SUCCESS,
        payload: data.message,
      })
      dispatch(retrieveAllApplications())
      dispatch(retrieveSubmittedRequirements({ id: payload.id }))
      dispatch(retrieveApplicationComments({ id: payload.id }))
      dispatch(retrieveSubmittedEvaluators({ id: payload.id }))
      dispatch(retrieveSubmittedInspector({ id: payload.id }))
      dispatch(retrieveSubmittedEngineers({ id: payload.id }))
      dispatch(retrieveSubmittedOrderofPayment({ id: payload.id }))
    } catch {
      dispatch({
        type: UPDATE_ORDEROFPAYMENT_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateGuestApplication = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_GUEST_APPLICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/edit-application/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_GUEST_APPLICATION_SUCCESS,
        payload: data.message,
      })
      dispatch(retriveGuestApplication())
    } catch {
      dispatch({
        type: UPDATE_GUEST_APPLICATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateCFEI = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CFEI_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/edit-cfei`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_CFEI_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_CFEI_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const updateContractorsTax = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CONTRACTORSTAX_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documentcontroller/edit-contractors-tax`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_CONTRACTORSTAX_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: UPDATE_CONTRACTORSTAX_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// INSERT
export const insertWalkinApplication = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_APPLICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentcontroller/insert-application/`,
        payload,
        config
      )

      dispatch({
        type: INSERT_APPLICATION_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_APPLICATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

export const insertApplicationInformation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_APPINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentcontroller/add-application-info/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_APPINFO_SUCCESS,
        payload: data.message,
      })
      dispatch(retrieveApplicationInformation({ id: payload.id }))
    } catch {
      dispatch({
        type: INSERT_APPINFO_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// INSERT Order of Payment
export const insertOrderofPayment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_ORDEROFPAYMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentcontroller/order-of-payment/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_ORDEROFPAYMENT_SUCCESS,
        payload: data.message,
      })
      dispatch(retrieveAllApplications())
      dispatch(retrieveSubmittedRequirements({ id: payload.id }))
      dispatch(retrieveApplicationComments({ id: payload.id }))
      dispatch(retrieveSubmittedEvaluators({ id: payload.id }))
      dispatch(retrieveSubmittedInspector({ id: payload.id }))
      dispatch(retrieveSubmittedEngineers({ id: payload.id }))
      dispatch(retrieveSubmittedOrderofPayment({ id: payload.id }))
    } catch {
      dispatch({
        type: INSERT_ORDEROFPAYMENT_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// INSERT News and Update
export const insertNewsAndUpdate = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_NEWSANDUPDATES_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentcontroller/add-newsandupdate`,
        payload,
        config
      )

      dispatch({
        type: INSERT_NEWSANDUPDATES_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_NEWSANDUPDATES_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// INSERT Evaluation Sheet
export const insertEvaluationSheet = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_EVALUATIONSHEET_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentcontroller/add-evaluationsheet/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: INSERT_EVALUATIONSHEET_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveEvaluationSheet({ id: payload.appID }))
    } catch {
      dispatch({
        type: INSERT_EVALUATIONSHEET_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// INSERT Guest Application
export const insertGuestApplication = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_GUEST_APPLICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentcontroller/add-application`,
        payload,
        config
      )

      dispatch({
        type: INSERT_GUEST_APPLICATION_SUCCESS,
        payload: data.message,
      })
      dispatch(retriveGuestApplication())
    } catch {
      dispatch({
        type: INSERT_GUEST_APPLICATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// INSERT CFEI
export const insertCFEI = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INSERT_CFEI_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documentcontroller/add-cfei`,
        payload,
        config
      )

      dispatch({
        type: INSERT_CFEI_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: INSERT_CFEI_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

// DELETE

// DELETE Evalauation Sheet
export const deleteEvaluationSheet = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_EVALUATIONSHEET_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/documentcontroller/delete-evaluation/${payload.id}`,
        config
      )

      dispatch({
        type: DELETE_EVALUATIONSHEET_SUCCESS,
        payload: data.message,
      })

      dispatch(retrieveEvaluationSheet({ id: payload.appID }))
    } catch {
      dispatch({
        type: DELETE_EVALUATIONSHEET_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// DELETE Evalauation Sheet
export const deleteGuestApplication = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_GUEST_APPLICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/documentcontroller/delete-application/${payload.id}`,
        config
      )

      dispatch({
        type: DELETE_GUEST_APPLICATION_SUCCESS,
        payload: data.message,
      })
      dispatch(retriveGuestApplication())
    } catch {
      dispatch({
        type: DELETE_GUEST_APPLICATION_FAILED,
        payload: 'No data found',
      })
    }
  }
}

// DELETE Evalauation Sheet
export const deleteCFEI = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_CFEI_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/documentcontroller/delete-cfei/${payload.id}`,
        config
      )

      dispatch({
        type: DELETE_CFEI_SUCCESS,
        payload: data.message,
      })
    } catch {
      dispatch({
        type: DELETE_CFEI_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}
