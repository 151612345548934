import {
  GET_PLUMBING_EVALUATION_DETAILS_REQ,
  GET_PLUMBING_EVALUATION_DETAILS_SUCCESS,
  GET_PLUMBING_EVALUATION_DETAILS_FAILED,
  CREATE_PLUMBING_EVALUATION_REQ,
  CREATE_PLUMBING_EVALUATION_SUCCESS,
  CREATE_PLUMBING_EVALUATION_FAILED,
  UPDATE_PLUMBING_EVALUATION_REQ,
  UPDATE_PLUMBING_EVALUATION_SUCCESS,
  UPDATE_PLUMBING_EVALUATION_FAILED,
} from '../constants/evaluatorPlumbingConstants'

export const evaluatorPlumbingReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_PLUMBING_EVALUATION_DETAILS_REQ:
      return {
        ...state,
        evaluationPlumbingDetails: { loading: true },
      }
    case GET_PLUMBING_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        evaluationPlumbingDetails: { loading: false, message: action.payload },
      }
    case GET_PLUMBING_EVALUATION_DETAILS_FAILED:
      return {
        ...state,
        evaluationPlumbingDetails: { loading: false, error: action.payload },
      }
    case CREATE_PLUMBING_EVALUATION_REQ:
      return {
        ...state,
        createPlumbingEvaluation: { loading: true },
      }
    case CREATE_PLUMBING_EVALUATION_SUCCESS:
      return {
        ...state,
        createPlumbingEvaluation: { loading: false, message: action.payload },
      }
    case CREATE_PLUMBING_EVALUATION_FAILED:
      return {
        ...state,
        createPlumbingEvaluation: { loading: false, error: action.payload },
      }
    case UPDATE_PLUMBING_EVALUATION_REQ:
      return {
        ...state,
        updatePlumbingEvaluation: { loading: true },
      }
    case UPDATE_PLUMBING_EVALUATION_SUCCESS:
      return {
        ...state,
        updatePlumbingEvaluation: { loading: false, message: action.payload },
      }
    case UPDATE_PLUMBING_EVALUATION_FAILED:
      return {
        ...state,
        updatePlumbingEvaluation: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
