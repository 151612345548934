import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'

// Style
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'

// Components
import PrintRoutingSlip from '../../reusable/PrintRoutingSlip'

// Actions
import {
  retrieveDcProcessTime,
  retrieveEvaluatorProcessTime,
  retrieveInspectorProcessTime,
  retrieveTradesInformation,
} from '../../redux/actions/routingSlipActions'

// Revised Actions
import {
  retrieveApplicationInformation,
  retrieveSubmittedRequirements,
} from '../../redux/actions/DocumentControllers/documentControllerActions'

// Print Pdf

import { PDFDownloadLink } from '@react-pdf/renderer'

const RoutingSlipInfoScreen = ({ match, history }) => {
  // Declaration

  const dispatch = useDispatch()

  const dc = useSelector((state) => state.documentControllerReducer)

  const { applicationInformation, submittedRequirements } = dc

  const routingSlip = useSelector((state) => state.routingSlip)

  const {
    dcTimeProcess,
    evaluatorsTimeProcess,
    inspectorTimeProcess,
    tradesInfo,
  } = routingSlip
  // End Declaration

  // useEffect
  useEffect(() => {
    // dispatch(retrieveAppByID())
    dispatch(retrieveApplicationInformation({ id: match.params.id }))
    dispatch(retrieveSubmittedRequirements({ id: match.params.id }))
    dispatch(retrieveDcProcessTime({ appID: match.params.id }))
    dispatch(retrieveEvaluatorProcessTime({ appID: parseInt(match.params.id) }))
    dispatch(retrieveInspectorProcessTime({ appID: parseInt(match.params.id) }))
    dispatch(retrieveTradesInformation({ appID: parseInt(match.params.id) }))
  }, [dispatch, match])

  console.log(applicationInformation && applicationInformation.message)

  return (
    <UserMain>
      <Container fluid>
        {applicationInformation && applicationInformation.message && (
          <Row className='bg-light mb-3 shadow-lg'>
            <Col xl={12} className='mt-3'>
              <h5 style={{ color: '#1f9bcf' }}>Routing Slip</h5>
              <hr />
            </Col>
            {/* Application No. */}

            <h5 className='mt-2 mb-3 fw-bold'>Application No.</h5>
            <Row>
              <h6 className='text-danger'>
                {applicationInformation.message[0].appID}
              </h6>
            </Row>

            {/* Applicant  */}
            <h5 className='mt-4 mb-3'>Applicant</h5>
            <Row>
              {['Last Name: ', 'First Name: ', 'Middle Name: '].map(
                (label, index) => (
                  <Col key={index} xl={3}>
                    <p className='mx-4'>{label}</p>
                  </Col>
                )
              )}
            </Row>

            <Row>
              <Col xl={12}>
                {applicationInformation.message.map((name, index) => (
                  <Row key={index}>
                    <Col xl={3} className='my-1'>
                      <h6 className='mx-5'>{name.lastName}</h6>
                    </Col>
                    <Col xl={3} className='my-1'>
                      <h6 className='mx-5'>{name.firstName}</h6>
                    </Col>
                    <Col xl={3} className='my-1'>
                      <h6 className='mx-5'>{name.middleName}</h6>
                    </Col>
                    <Col xl={3} className='my-1'></Col>
                  </Row>
                ))}
              </Col>
            </Row>

            <Row>
              <Col xl={12} className='my-1 mx-4'>
                <p>Address</p>
              </Col>
            </Row>
            <Row>
              <Col xl={12} className='my-1'>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].Address}
                </h6>
              </Col>
            </Row>
            {/*End Applicant */}

            {/* Site and Lot Info */}
            <h5 className='mt-4 mb-3'>Site</h5>
            <Row>
              {['Street: ', 'Area: ', 'Barangay: '].map((label, index) => (
                <Col key={index} xl={3} className='my-1'>
                  <p className='mx-4'>{label}</p>
                </Col>
              ))}
            </Row>
            <Row>
              <Col xl={3}>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].ConstStreet}
                </h6>
              </Col>
              <Col xl={3}>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].ConstArea}
                </h6>
              </Col>
              <Col xl={3}>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].ConstBarangay}
                </h6>
              </Col>
            </Row>
            {/*End Site and Lot Info */}
            <hr className='bg-dark mt-0' />

            {/* Permits */}
            <h5 className='mt-4 mb-3'>Building Permit</h5>
            <Row>
              <Col xl={3} className='my-1'>
                <p className='mx-4'>Scope of Work: </p>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].scopeOfWork}
                </h6>
              </Col>
              <Col xl={3} className='my-1'>
                <p className='mx-4'>Specified Scope of Work: </p>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].sw_specify}
                </h6>
              </Col>
              <Col xl={3} className='my-1'>
                <p className='mx-4'>Use Type: </p>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].useType}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col xl={3} className='my-1'>
                <p className='mx-4'>No. of Storeys: </p>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].noOfStoreys}
                </h6>
              </Col>
              <Col xl={3} className='my-1'>
                <p className='mx-4'>Floor Area: </p>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].floorArea}
                </h6>
              </Col>
              <Col xl={3} className='my-1'>
                <p className='mx-4'>Compute As: </p>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].computeAs}
                </h6>
              </Col>
              <Col xl={3} className='my-1'>
                <p className='mx-4'>No. of Units: </p>
                <h6 className='mx-5'>
                  {applicationInformation.message[0].noOfUnits}
                </h6>
              </Col>
            </Row>

            {tradesInfo &&
              tradesInfo.message &&
              tradesInfo.message.map((el, index) => (
                <>
                  <h5 className='mt-4 mb-3' key={index}>
                    {el.trade}
                  </h5>
                  <Row>
                    <Col xl={3} className='my-1'>
                      <p className='mx-4'>Scope of Work: </p>
                      <h6 className='mx-5'>
                        {applicationInformation.message[0].scopeOfWork}
                      </h6>
                    </Col>
                    <Col xl={3} className='my-1'>
                      <p className='mx-4'>Specified Scope of Work: </p>
                      <h6 className='mx-5'>
                        {applicationInformation.message[0].sw_specify}
                      </h6>
                    </Col>
                  </Row>
                </>
              ))}
            {/* End Permits */}
            <hr className='bg-dark mt-0' />

            {/* Sections */}
            <Row className='mt-3 mb-2'>
              <Col xl={3}></Col>
              {['Date/Time In', 'Date/Time Out', 'Person in-charge'].map(
                (label, index) => (
                  <Col key={index} xl={3}>
                    <p className='mx-4'>{label}</p>
                  </Col>
                )
              )}
            </Row>

            {/* Document Controller */}
            <Row>
              <Col xl={3} className='my-1 pt-2'>
                <h6 className='mx-4'>Document Controller</h6>
              </Col>
              <Col xl={3} className='my-1 pt-2'>
                <h6 className='mx-4'>
                  {(dcTimeProcess.message &&
                    dcTimeProcess.message[0].Time_In) ||
                    ''}
                </h6>
              </Col>
              <Col xl={3} className='my-1 pt-2'>
                <h6 className='mx-4'>
                  {(dcTimeProcess.message &&
                    dcTimeProcess.message[0].Time_out) ||
                    ''}
                </h6>
              </Col>
              <Col xl={3} className='my-1 pt-2'>
                <h6 className='mx-4 pt-1 text-center'>
                  {dcTimeProcess.message &&
                    dcTimeProcess.message[0].Time_out &&
                    dcTimeProcess.message[0].EmployeeName}
                </h6>
              </Col>
              <hr className='bg-dark mt-0' />
            </Row>
            {/* End Document Controller */}

            {/* Inspector */}
            <Row>
              <Col xl={3} className='my-1 pt-2'>
                <h6 className='mx-4'>Inspector</h6>
              </Col>
              <Col xl={3} className='my-1 pt-2'>
                <h6 className='mx-4'>
                  {(inspectorTimeProcess.message &&
                    inspectorTimeProcess.message[0].timeStamp) ||
                    ''}
                </h6>
              </Col>
              <Col xl={3} className='my-1 pt-2'>
                <h6 className='mx-4'>
                  {(inspectorTimeProcess.message &&
                    inspectorTimeProcess.message[0].inspectionDate) ||
                    ''}
                </h6>
              </Col>
              <Col xl={3} className='my-1 pt-2'>
                <h6 className='mx-4 pt-1 text-center'>
                  {inspectorTimeProcess.message &&
                    inspectorTimeProcess.message[0].EmployeeName}
                </h6>
              </Col>
              <hr className='bg-dark mt-0' />
            </Row>
            {/* End Inspector */}

            {tradesInfo &&
              tradesInfo.message &&
              tradesInfo.message.map((el, index) => (
                <Row key={index}>
                  <Col xl={3} className='my-1 pt-2'>
                    <h6 className='mx-4'>{el.trade}</h6>
                  </Col>
                  <Col xl={3} className='my-1 pt-2'>
                    <h6 className='mx-4'>{el.timeStamp}</h6>
                  </Col>
                  <Col xl={3} className='my-1 pt-2'>
                    <h6 className='mx-4'>
                      {el.withAssessment !== null && el.withAssessment}
                    </h6>
                  </Col>
                  <Col xl={3} className='my-1 pt-2'>
                    <h6 className='mx-4 pt-1 text-center'>
                      {el.EmployeeName !== null && el.EmployeeName}
                    </h6>
                  </Col>
                  <hr className='bg-dark mt-0' />
                </Row>
              ))}

            {/* Evaluators */}
            {/* {evaluatorsTimeProcess.message &&
              evaluatorsTimeProcess.message !== 'No Data Found' &&
              evaluatorsTimeProcess.message.map((data, index) => (
                <Row key={index}>
                  <Col xl={3} className='my-1 pt-2'>
                    <h6 className='mx-4'>{data.trade}</h6>
                  </Col>
                  <Col xl={3} className='my-1 pt-2'>
                    <h6 className='mx-4'>{data.Time_In}</h6>
                  </Col>
                  <Col xl={3} className='my-1 pt-2'>
                    <h6 className='mx-4'>{data.Time_Out}</h6>
                  </Col>
                  <Col xl={3} className='my-1 pt-2'>
                    <h6 className='mx-4 border-top border-dark pt-1 text-center'></h6>
                  </Col>
                  <hr className='bg-dark mt-0' />
                </Row>
              ))} */}
            {/* End Evaluators */}

            {/*End Sections */}

            {/* Required Documents */}
            <h5 className='mt-4 mb-3'>Submitted Documents</h5>
            <Row>
              {['File Name: ', 'Date Submitted: '].map((label, index) => (
                <Col key={index} xl={3}>
                  <p className='mx-4'>{label}</p>
                </Col>
              ))}
            </Row>

            <Row>
              <Col xl={12}>
                {submittedRequirements &&
                  submittedRequirements.message &&
                  submittedRequirements.message.map((docs, index) => (
                    <Row key={index}>
                      <Col xl={3} className='my-1'>
                        <h6 className='mx-5'>{docs.FileName}</h6>
                      </Col>
                      <Col xl={6} className='my-1'>
                        <h6 className='mx-5'>{docs.timeStamp}</h6>
                      </Col>

                      <Col xl={3} className='my-1'></Col>
                    </Row>
                  ))}
              </Col>
            </Row>
            {/* End Required Documents */}
            <hr className='bg-dark mt-0' />

            <hr className='bg-dark mt-0' />

            <Row className='text-center pb-3'>
              <Col xl={12}>
                <PDFDownloadLink
                  document={
                    <PrintRoutingSlip
                      appInfo={applicationInformation && applicationInformation}
                      submittedDocs={
                        submittedRequirements && submittedRequirements
                      }
                      dcTimeProcess={
                        dcTimeProcess &&
                        dcTimeProcess.message !== 'No Data Found' &&
                        dcTimeProcess.message
                      }
                      evaluatorsTimeProcess={
                        evaluatorsTimeProcess &&
                        evaluatorsTimeProcess.message !== 'No Data Found' &&
                        evaluatorsTimeProcess.message
                      }
                      inspectorTimeProcess={
                        inspectorTimeProcess &&
                        inspectorTimeProcess.message !== 'No Data Found' &&
                        inspectorTimeProcess.message
                      }
                      tradesInfo={
                        tradesInfo &&
                        tradesInfo.message !== 'No Data Found' &&
                        tradesInfo.message
                      }
                    />
                  }
                  fileName='RoutingSlip'
                >
                  {({ loading }) =>
                    !loading && (
                      <Button size='sm' variant='info' className='mx-2'>
                        Print
                      </Button>
                    )
                  }
                </PDFDownloadLink>

                <Button
                  size='sm'
                  onClick={() => history.push(`/applications`)}
                  variant='danger'
                >
                  Back
                </Button>
              </Col>
            </Row>
          </Row>
        )}
        {applicationInformation && applicationInformation.error && (
          <Row className='bg-light mb-3 mx-5 py-3 shadow-lg'>
            <Col xl={12} className='text-center'>
              <h3>No data found</h3>
              <h4>
                Document Controller must input application information first.
              </h4>
              <h1>Sorry for inconvenience.</h1>
            </Col>
            <Col xl={12} className='text-center mt-2'>
              <Button
                size='sm'
                onClick={() => history.push(`/applications`)}
                variant='danger'
              >
                Back
              </Button>
            </Col>
          </Row>
        )}

        {applicationInformation && applicationInformation.loading && (
          <div className='text-center pt-3'>
            <Spinner animation='border' />
            <h3>Loading...</h3>
          </div>
        )}
      </Container>
    </UserMain>
  )
}

export default withRouter(RoutingSlipInfoScreen)
