import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  Header: {
    fontSize: 8,
    width: '100%',
    fontFamily: 'Helvetica-Bold',
  },
  TableHeader: {
    fontSize: 9,
    width: '120%',
    fontFamily: 'Helvetica-Bold',
    borderRight: '1px',
    borderLeft: '1px',
    textAlign: 'center',
  },
  TableData: {
    fontSize: 9,
    width: '120%',
    borderRight: '1px',
    borderLeft: '1px',
    textAlign: 'center',
    paddingVertical: '5px',
  },
  TotalTableData: {
    fontSize: 9,
    width: '120%',
    fontFamily: 'Helvetica-Bold',
    borderRight: '1px',
    borderLeft: '1px',
    textAlign: 'center',
    paddingVertical: '5px',
  },
  sideBarData: {
    width: '150%',
    fontSize: 10,
    paddingVertical: '5px',
  },
})

const AnnualCollectionSheet = ({
  state,
  arrayMonth,
  totalAmount,
  formatCurrency,
}) => {
  return (
    <Document file='AnnualCollection.pdf'>
      <Page size='legal' orientation='landscape' style={styles.body} wrap>
        <View>
          <View style={styles.row}>
            <Text style={styles.Header}>Republic of the Philippines</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.Header}>City of Las Piñas</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.Header}>
              Engineering Abstract of Collection
            </Text>
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderTop: '1px',
            borderLeft: '1px',
            marginTop: '20px',
          }}
        >
          <View style={styles.row}>
            <Text style={{ width: '150%' }}></Text>
            <Text style={styles.TableHeader}>January</Text>
            <Text style={styles.TableHeader}>February</Text>
            <Text style={styles.TableHeader}>March</Text>
            <Text style={styles.TableHeader}>April</Text>
            <Text style={styles.TableHeader}>May</Text>
            <Text style={styles.TableHeader}>June</Text>
            <Text style={styles.TableHeader}>July</Text>
            <Text style={styles.TableHeader}>August</Text>
            <Text style={styles.TableHeader}>September</Text>
            <Text style={styles.TableHeader}>October</Text>
            <Text style={styles.TableHeader}>November</Text>
            <Text style={styles.TableHeader}>December</Text>
            <Text style={styles.TableHeader}>Total</Text>
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Building Permit</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.BuildingPermit)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalBuildingPermit)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Electrical Permit</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.ElectricalPermit)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalElectricalPermit)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Plumbing Permit</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.PlumbingPermit)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalPlumbingPermit)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Mechanical Permit</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.MechanicalPermit)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalMechanicalPermit)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Electronics Permit</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.ElectronicPermit)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalElectronicsPermit)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Occupancy Permit</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.OccupancyPermit)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalOccupancyPermit)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Demolition Permit</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.DemolitionPermit)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalDemolitionPermit)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Fencing Permit</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.FencingPermit)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalFencingPermit)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Sign Permit</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.SignPermit)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalSignPermit)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Annual Ins. Fee</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined ? '-' : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {/* {formatCurrency(totalAmount.totalSignPermit)} */}-
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text
              style={{ width: '150%', fontSize: 9, paddingVertical: '5px' }}
            >
              Penalty / Surcharge
            </Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.PenaltySurcharge)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalPenalty)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>Admin Fine</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(value.AdminFine)
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalAdminFine)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text
              style={{
                width: '150%',
                fontSize: 10,
                fontFamily: 'Helvetica-Bold',
                paddingVertical: '5px',
              }}
            >
              Total
            </Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(
                          value.BuildingPermit +
                            value.ElectricalPermit +
                            value.PlumbingPermit +
                            value.MechanicalPermit +
                            value.ElectronicPermit +
                            value.OccupancyPermit +
                            value.DemolitionPermit +
                            value.FencingPermit +
                            value.SignPermit +
                            value.PenaltySurcharge +
                            value.AdminFine
                        )
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalCollection)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>5% (DPWH)</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(
                          (value.BuildingPermit +
                            value.ElectricalPermit +
                            value.PlumbingPermit +
                            value.MechanicalPermit +
                            value.ElectronicPermit +
                            value.OccupancyPermit +
                            value.DemolitionPermit +
                            value.FencingPermit +
                            value.SignPermit +
                            value.PenaltySurcharge +
                            value.AdminFine) *
                            0.05
                        )
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalDPWH)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>15% (OBO)</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(
                          (value.BuildingPermit +
                            value.ElectricalPermit +
                            value.PlumbingPermit +
                            value.MechanicalPermit +
                            value.ElectronicPermit +
                            value.OccupancyPermit +
                            value.DemolitionPermit +
                            value.FencingPermit +
                            value.SignPermit +
                            value.PenaltySurcharge +
                            value.AdminFine) *
                            0.15
                        )
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalOBO)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            borderBottom: '1px',
            borderLeft: '1px',
          }}
        >
          <View style={styles.row}>
            <Text></Text>
            <Text style={styles.sideBarData}>80%</Text>
            {state.length > 0 &&
              arrayMonth.map((el, index) => {
                let value = state.find((e) => e.Month === el)

                return (
                  <Text style={styles.TableData} key={index}>
                    {value !== undefined
                      ? formatCurrency(
                          (value.BuildingPermit +
                            value.ElectricalPermit +
                            value.PlumbingPermit +
                            value.MechanicalPermit +
                            value.ElectronicPermit +
                            value.OccupancyPermit +
                            value.DemolitionPermit +
                            value.FencingPermit +
                            value.SignPermit +
                            value.PenaltySurcharge +
                            value.AdminFine) *
                            0.8
                        )
                      : '-'}
                  </Text>
                )
              })}

            {state.length > 0 && (
              <Text style={styles.TableData}>
                {formatCurrency(totalAmount.totalEighty)}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            marginTop: '10px',
          }}
        >
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Text style={{ width: '5%', fontSize: 10 }}>10%</Text>
            <Text style={{ width: '7%', fontSize: 10, textAlign: 'center' }}>
              {formatCurrency(totalAmount.totalTen)}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: '10px',
          }}
        >
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Text style={{ width: '25%', fontSize: 10 }}>
              PROJECTED REVENUE ESTIMATE FOR NEXT CY
            </Text>
            <Text style={{ width: '7%', fontSize: 10, textAlign: 'center' }}>
              {formatCurrency(totalAmount.totalRevenue)}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: '5px',
          }}
        >
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View style={{ width: '50%', fontSize: 10, marginLeft: '20px' }}>
              <Text>Prepared by:</Text>
              <Text style={{ marginTop: '30px', fontFamily: 'Helvetica-Bold' }}>
                PETERSON S. LIMJUCO
              </Text>
              <Text>Computer Programmer II</Text>
            </View>
            <View
              style={{
                width: '50%',
                fontSize: 10,
                textAlign: 'right',
                marginRight: '20px',
              }}
            >
              <Text>Checked by:</Text>
              <Text style={{ marginTop: '30px', fontFamily: 'Helvetica-Bold' }}>
                ROMNICK U. CARTUSIANO
              </Text>
              <Text>ENGINEER II</Text>
            </View>
          </View>
        </View>
        <View>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <View style={{ width: '50%', fontSize: 10, textAlign: 'center' }}>
              <Text style={{ marginTop: '30px', fontFamily: 'Helvetica-Bold' }}>
                ENGR. ROSABELLA A. BANTOG
              </Text>
              <Text>City Engineer / Building Official</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default AnnualCollectionSheet
