export const APP_RETRIEVE_BY_STATUS_REQ = 'APP_RETRIEVE_BY_STATUS_REQ'
export const APP_RETRIEVE_BY_STATUS_SUCCESS = 'APP_RETRIEVE_BY_STATUS_SUCCESS'
export const APP_RETRIEVE_BY_STATUS_FAILED = 'APP_RETRIEVE_BY_STATUS_FAILED'

export const APP_DC_CHANGE_STATUS_REQ = 'APP_DC_CHANGE_STATUS_REQ'
export const APP_DC_CHANGE_STATUS_SUCCESS = 'APP_DC_CHANGE_STATUS_SUCCESS'
export const APP_DC_CHANGE_STATUS_FAILED = 'APP_DC_CHANGE_STATUS_FAILED'

export const REQUIRED_DOCUMENTS_REQ = 'REQUIRED_DOCUMENTS_REQ'
export const REQUIRED_DOCUMENTS_SUCCESS = 'REQUIRED_DOCUMENTS_SUCCESS'
export const REQUIRED_DOCUMENTS_FAILED = 'REQUIRED_DOCUMENTS_FAILED'

export const GET_EVALUATORS_REQ = 'GET_EVALUATORS_REQ'
export const GET_EVALUATORS_SUCCESS = 'GET_EVALUATORS_SUCCESS'
export const GET_EVALUATORS_FAILED = 'GET_EVALUATORS_FAILED'

export const GET_INSPECTORS_REQ = 'GET_INSPECTORS_REQ'
export const GET_INSPECTORS_SUCCESS = 'GET_INSPECTORS_SUCCESS'
export const GET_INSPECTORS_FAILED = 'GET_INSPECTORS_FAILED'

export const GET_APPLICATION_INFORMATION_REQ = 'GET_APPLICATION_INFORMATION_REQ'
export const GET_APPLICATION_INFORMATION_SUCCESS =
  'GET_APPLICATION_INFORMATION_SUCCESS'
export const GET_APPLICATION_INFORMATION_FAILED =
  'GET_APPLICATION_INFORMATION_FAILED'

export const UPDATE_APPLICATION_INFORMATION_REQ =
  'UPDATE_APPLICATION_INFORMATION_REQ'
export const UPDATE_APPLICATION_INFORMATION_SUCCESS =
  'UPDATE_APPLICATION_INFORMATION_SUCCESS'
export const UPDATE_APPLICATION_INFORMATION_FAILED =
  'UPDATE_APPLICATION_INFORMATION_FAILED'

export const ADD_APPLICATION_INFORMATION_REQ = 'ADD_APPLICATION_INFORMATION_REQ'
export const ADD_APPLICATION_INFORMATION_SUCCESS =
  'ADD_APPLICATION_INFORMATION_SUCCESS'
export const ADD_APPLICATION_INFORMATION_FAILED =
  'ADD_APPLICATION_INFORMATION_FAILED'

export const GET_SUBMITTED_DOCS_REQ = 'GET_SUBMITTED_DOCS_REQ'
export const GET_SUBMITTED_DOCS_SUCCESS = 'GET_SUBMITTED_DOCS_SUCCESS'
export const GET_SUBMITTED_DOCS_FAILED = 'GET_SUBMITTED_DOCS_FAILED'

export const UPDATE_SUBMITTED_DOCS_REQ = 'UPDATE_SUBMITTED_DOCS_REQ'
export const UPDATE_SUBMITTED_DOCS_SUCCESS = 'UPDATE_SUBMITTED_DOCS_SUCCESS'
export const UPDATE_SUBMITTED_DOCS_FAILED = 'UPDATE_SUBMITTED_DOCS_FAILED'

export const SUBMIT_REQUIRED_DOCS_REQ = 'SUBMIT_REQUIRED_DOCS_REQ'
export const SUBMIT_REQUIRED_DOCS_SUCCESS = 'SUBMIT_REQUIRED_DOCS_SUCCESS'
export const SUBMIT_REQUIRED_DOCS_FAILED = 'SUBMIT_REQUIRED_DOCS_FAILED'

export const APP_ENGINEER_INFO_REQ = 'APP_ENGINEER_INFO_REQ'
export const APP_ENGINEER_INFO_SUCCESS = 'APP_ENGINEER_INFO_SUCCESS'
export const APP_ENGINEER_INFO_FAILED = 'APP_ENGINEER_INFO_FAILED'

export const UPDATE_ENGINEER_INFO_REQ = 'UPDATE_ENGINEER_INFO_REQ'
export const UPDATE_ENGINEER_INFO_SUCCESS = 'UPDATE_ENGINEER_INFO_SUCCESS'
export const UPDATE_ENGINEER_INFO_FAILED = 'UPDATE_ENGINEER_INFO_FAILED'

export const GET_SUBMITTED_EVALUATORS_REQ = 'GET_SUBMITTED_EVALUATORS_REQ'
export const GET_SUBMITTED_EVALUATORS_SUCCESS =
  'GET_SUBMITTED_EVALUATORS_SUCCESS'
export const GET_SUBMITTED_EVALUATORS_FAILED = 'GET_SUBMITTED_EVALUATORS_FAILED'

export const UPDATE_SUBMITTED_EVALUATORS_REQ = 'UPDATE_SUBMITTED_EVALUATORS_REQ'
export const UPDATE_SUBMITTED_EVALUATORS_SUCCESS =
  'UPDATE_SUBMITTED_EVALUATORS_SUCCESS'
export const UPDATE_SUBMITTED_EVALUATORS_FAILED =
  'UPDATE_SUBMITTED_EVALUATORS_FAILED'

export const GET_SUBMITTED_INSPECTOR_REQ = 'GET_SUBMITTED_INSPECTOR_REQ'
export const GET_SUBMITTED_INSPECTOR_SUCCESS = 'GET_SUBMITTED_INSPECTOR_SUCCESS'
export const GET_SUBMITTED_INSPECTOR_FAILED = 'GET_SUBMITTED_INSPECTOR_FAILED'

export const UPDATE_SUBMITTED_INSPECTOR_REQ = 'UPDATE_SUBMITTED_INSPECTOR_REQ'
export const UPDATE_SUBMITTED_INSPECTOR_SUCCESS =
  'UPDATE_SUBMITTED_INSPECTOR_SUCCESS'
export const UPDATE_SUBMITTED_INSPECTOR_FAILED =
  'UPDATE_SUBMITTED_INSPECTOR_FAILED'

export const GET_DATEAPPLIED_REQ = 'GET_DATEAPPLIED_REQ'
export const GET_DATEAPPLIED_SUCCESS = 'GET_DATEAPPLIED_SUCCESS'
export const GET_DATEAPPLIED_FAILED = 'GET_DATEAPPLIED_FAILED'
