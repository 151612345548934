import React, { useState, useEffect } from 'react'
import {
  Button,
  Container,
  Table,
  Modal,
  Form,
  Row,
  Pagination,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

// Actions
import {
  retrieveNewsNUpdateList,
  createNewsNUpdateInfo,
  updateNewsNUpdateInfo,
  deleteNewsNUpdate,
} from '../../redux/actions/SuperAdmin/superAdminActions'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import Loading from '../../reusable/Loading'
import Error from '../../reusable/Error'
import { FaCheckCircle, FaPencilAlt, FaPlus, FaTrashAlt } from 'react-icons/fa'

const NewsNUpdateScreen = () => {
  const dispatch = useDispatch()

  const [details, setDetails] = useState({
    id: '',
    header: '',
    subHeader: '',
    body: '',
    url: '',
    userID: '',
  })

  const [list, setList] = useState([])

  const [uploadImages, setUploadImages] = useState(null)

  const [modal, setModal] = useState({
    create: false,
    update: false,
  })

  const admin = useSelector((state) => state.superAdminReducer)
  const { retrievedNewsNUpdateList, createdNewsNUpdateInfo } = admin

  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const handleCreateSubmit = (e) => {
    if (window.confirm('Are you sure you want to add this information ?')) {
      dispatch(
        createNewsNUpdateInfo({
          header: details.header,
          subheader: details.subHeader,
          body: details.body,
          url: uploadImages,
          userID: userCredentials.id,
        })
      )

      setModal({
        ...modal,
        create: false,
      })

      setDetails({
        ...details,
        id: '',
        header: '',
        subHeader: '',
        body: '',
        url: '',
        userID: '',
      })
      setUploadImages(null)
    }
    e.preventDefault()
  }

  const handleEditSubmit = (e) => {
    if (window.confirm('Are you sure you want to add this information ?')) {
      dispatch(
        updateNewsNUpdateInfo({
          id: details.id,
          header: details.header,
          subheader: details.subHeader,
          body: details.body,
          url: uploadImages,
          userID: userCredentials.id,
        })
      )

      setModal({
        ...modal,
        update: false,
      })

      setDetails({
        ...details,
        id: '',
        header: '',
        subHeader: '',
        body: '',
        url: '',
        userID: '',
      })
      setUploadImages(null)
    }
    e.preventDefault()
  }

  const handleChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    })
  }

  const handleViewDetails = (payload) => {
    setModal({ ...modal, update: true })
    setDetails({
      ...details,
      id: payload.id,
      header: payload.header,
      subHeader: payload.subheader,
      body: payload.body,
      url: payload.url,
      userID: userCredentials.id,
    })
  }

  const handleUploadImage = async (e) => {
    let files = e.target.files
    setUploadImages(files)
  }

  // Pagination Area Forms
  const [paginatedForms, setpaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(10)
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(10)
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0)

  const pageCount = list ? Math.ceil(list.length / pageNumberLimit) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageNumberLimit
    const paginationForms = _(list)
      .slice(startIndex)
      .take(pageNumberLimit)
      .value()
    setpaginatedForms(paginationForms)
  }

  //   Display Pagination list
  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })
  // End Display Pagination list

  // Buttons
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Buttons

  // End Pagination Area Forms

  useEffect(() => {
    dispatch(retrieveNewsNUpdateList())
  }, [dispatch])

  useEffect(() => {
    if (retrievedNewsNUpdateList && retrievedNewsNUpdateList.message) {
      setList(retrievedNewsNUpdateList.message)
    }
  }, [retrievedNewsNUpdateList])

  // console.log(details)

  useEffect(() => {
    setpaginatedForms(_(list).slice(0).take(pageNumberLimit).value())
    setcurrentPage(1)
  }, [list, pageNumberLimit])

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          <h5 className='fw-bold pt-5'>NEWS AND UPDATES</h5>

          <Button
            variant='info'
            size='sm'
            className='my-2'
            onClick={() => setModal({ ...modal, create: true })}
          >
            <FaPlus /> Create
          </Button>
          {retrievedNewsNUpdateList && retrievedNewsNUpdateList.loading && (
            <Loading />
          )}

          {retrievedNewsNUpdateList && retrievedNewsNUpdateList.error && (
            <Error message={retrievedNewsNUpdateList.error} />
          )}

          {retrievedNewsNUpdateList && retrievedNewsNUpdateList.message && (
            <Table responsive size='sm' className='mt-3'>
              <thead>
                <tr>
                  <th className='fw-bold'>ID</th>
                  <th className='fw-bold'>Header</th>
                  <th className='fw-bold'>Sub-header</th>
                  <th className='fw-bold'>Date Created</th>
                  <th className='fw-bold'>Created</th>
                  <th className='fw-bold'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedForms.map((x, index) => (
                  <tr key={index}>
                    <td>{x.id}</td>
                    <td>{x.header}</td>
                    <td>{x.subheader}</td>
                    <td>{moment(x.dateCreated).format('MMMM DD, YYYY')}</td>
                    <td>{x.CreatedBy}</td>
                    <td>
                      <Button
                        size='sm'
                        variant='success'
                        className='mx-1'
                        onClick={() => handleViewDetails(x)}
                      >
                        <FaPencilAlt />
                      </Button>
                      <Button
                        size='sm'
                        variant='danger'
                        className='mx-1'
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to delete the item?'
                            )
                          ) {
                            dispatch(deleteNewsNUpdate({ id: x.id }))
                          }
                        }}
                      >
                        <FaTrashAlt />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {retrievedNewsNUpdateList && retrievedNewsNUpdateList.message && (
            <Pagination size='sm' className='d-flex justify-content-end mx-2'>
              <Pagination.Item
                onClick={handlePrevbtn}
                disabled={currentPage === pages[0] ? true : false}
              >
                &lt;
              </Pagination.Item>

              {renderPageNumber}

              <Pagination.Item
                onClick={handleNextbtn}
                disabled={
                  currentPage === pages[pages.length - 1] ? true : false
                }
              >
                &gt;
              </Pagination.Item>
            </Pagination>
          )}
        </Container>
      </Container>

      {/* Create News & Update */}
      <Modal
        size='md'
        show={modal.create}
        onHide={() => setModal({ ...modal, create: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle /> Create News & Update
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleCreateSubmit}>
          <Modal.Body>
            <Row>
              <Form.Group>
                <Form.Label>Header</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='header'
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Sub-Header</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='subHeader'
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Body</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='body'
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Attachments</Form.Label>
                <Form.File size='sm' name='url' onChange={handleUploadImage} />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' size='sm' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              size='sm'
              onClick={() => {
                setModal({ ...modal, create: false })
                setDetails({
                  ...details,
                  id: '',
                  header: '',
                  subHeader: '',
                  body: '',
                  url: '',
                  userID: '',
                })
                setUploadImages(null)
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit News & Update */}
      <Modal
        size='md'
        show={modal.update}
        onHide={() => setModal({ ...modal, update: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle /> Update News & Update
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleEditSubmit}>
          <Modal.Body>
            <Row>
              <Form.Group>
                <Form.Label>Header</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={details.header}
                  name='header'
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Sub-Header</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={details.subHeader}
                  name='subHeader'
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Body</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={details.body}
                  name='body'
                  onChange={handleChange}
                />
              </Form.Group>
              {details.url !== null && (
                <img
                  className='my-2'
                  src={`data:image/png;base64,${details.url}`}
                />
              )}
              <Form.Group>
                <Form.Label>Attachments</Form.Label>
                <Form.File size='sm' onChange={handleUploadImage} />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' size='sm' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              size='sm'
              onClick={() => {
                setModal({ ...modal, update: false })
                setDetails({
                  ...details,
                  id: '',
                  header: '',
                  subHeader: '',
                  body: '',
                  url: '',
                  userID: '',
                })
                setUploadImages(null)
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </UserMain>
  )
}

export default NewsNUpdateScreen
