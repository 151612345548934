import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
  Accordion,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'
import ConstructionRenovationScreen from './ConstructionRenovationScreen'
import AccessoriesFee from '../../reusable/AccessoriesFee'
import ConstructionRenovation from '../../reusable/ConstructionRenovation'

// Actions
import {
  addAssessment,
  editAssessment,
  retrieveAssessmentDetails,
} from '../../redux/actions/Assessment/accessoriesFeeAssessmentActions'
import { retrieveAppDetails } from '../../redux/actions/evaluatorActions'

const AccessoriesFeeScreen = ({ history, match }) => {
  // Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const { getAppDetails } = evaluator

  const accessoriesFeeAssessment = useSelector(
    (state) => state.accessoriesFeeAssessment
  )

  const { assessmentDetails, createAssessment, updateAssessment } =
    accessoriesFeeAssessment

  const [obj, setObj] = useState([
    {
      id_per_name: 1,
      name: 'Line Grade',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 2,
      name: 'Inspect Verify',
      fee: 200,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 3,
      name: 'Excavation',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 4,
      name: 'Issuance',
      fee: 50,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 5,
      name: 'Excav Foundation',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 6,
      name: 'Other Excav',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 7,
      name: 'Enroachment',
      fee: 250,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 8,
      name: 'Fencing Masonry',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 9,
      name: 'Indigenous',
      fee: 2.4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 10,
      name: 'Pavements',
      fee: 24,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 11,
      name: 'Excess Paved',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 12,
      name: 'Sidewalks',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 13,
      name: 'Scaffoldings',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 14,
      name: 'Anchorage',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 15,
      name: 'NeonBus',
      fee: 36,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 16,
      name: 'NeonAd',
      fee: 52,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 17,
      name: 'IllumBus',
      fee: 24,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 18,
      name: 'IllumAd',
      fee: 36,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 19,
      name: 'OtherBus',
      fee: 15,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 20,
      name: 'OtherAd',
      fee: 24,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 21,
      name: 'PaintBus',
      fee: 9.6,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 22,
      name: 'PaintAd',
      fee: 18,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 23,
      name: 'Vertical Dim',
      fee: 5,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 24,
      name: 'Horizontal Dim',
      fee: 5,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 25,
      name: 'Demolition Bldg',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 26,
      name: 'Bldg Frame',
      fee: 4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 27,
      name: 'Structure Height',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 28,
      name: 'Appendage',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 29,
      name: 'Moving Fee',
      fee: 3,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 30,
      name: 'OrigConstruction',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 31,
      name: 'RenovationConstruction',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 32,
      name: 'OrigComplete',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 33,
      name: 'RenovationComplete',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 34,
      name: 'DivisionC',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 35,
      name: 'DivisionD',
      units: 0,
      estimate: 0,
    },
  ])

  const [state, setState] = useState([])

  const [grandTotal, setGrandTotal] = useState(0)

  const [modal, setModal] = useState({
    successModal: false,
    updateModal: false,
  })

  const [disabled, setDisabled] = useState({
    A1: false,
    A2: false,
    A3: false,
    A4: false,
    A5: false,
    A6: false,
  })

  const total = obj.reduce(
    (prevData, currData) => prevData + currData.estimate,
    0
  )

  const filtered = obj.filter((e) => e.estimate > 0)

  const isUpdating = JSON.parse(localStorage.getItem('assessmentSelected'))
    ? JSON.parse(localStorage.getItem('assessmentSelected'))
    : null
  // End Declaration

  // Functions

  //CAT A, C, F, G, H, K
  //CAT A
  const handleRangeDivA1 = (e) => {
    let tempEstimate = 0
    if (e.target.value > 10) {
      tempEstimate = 2.4 * (e.target.value - 10) + 24
    } else if (e.target.value === 0 || e.target.value === '') {
      tempEstimate = 0
    } else {
      tempEstimate = 24
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  //CAT C
  const handleRangeDivA2 = (e) => {
    let tempEstimate = 0

    if (e.target.value > 1.8) {
      tempEstimate = 4 * (e.target.value - 1.8) + 5.4
    } else if (e.target.value === 0 || e.target.value === '') {
      tempEstimate = 0
    } else {
      tempEstimate = 3
    }

    // switch (true) {
    //   case e.target.value <= 1.8:
    //     tempEstimate = e.target.value * 3
    //     break
    //   case e.target.value > 1.8:
    //     tempEstimate = 4 * (e.target.value - 1.8) + 5.4
    //     break
    //   default:
    //     tempEstimate = e.target.value * 1.8
    //     break
    // }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  //CAT F
  const handleRangeDivA3 = (e) => {
    let tempEstimate = 0

    if (e.target.value > 20) {
      tempEstimate = 12 * (e.target.value - 20) + 240
    } else if (e.target.value === 0 || e.target.value === '') {
      tempEstimate = 0
    } else {
      tempEstimate = 240
    }
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  //CAT G
  const handleRangeDivA4 = (e) => {
    let tempEstimate = 0

    if (e.target.value > 10) {
      tempEstimate = 12 * (e.target.value - 10) + 150
    } else if (e.target.value === 0 || e.target.value === '') {
      tempEstimate = 0
    } else {
      tempEstimate = 150
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  //CAT H
  const handleRangeDivA5 = (e) => {
    let tempEstimate = 0

    if (e.target.value > 4) {
      tempEstimate = 24 * (e.target.value - 4) + 120
    } else if (e.target.value === 0 || e.target.value === '') {
      tempEstimate = 0
    } else {
      tempEstimate = 120
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  //CAT K1
  const handleRangeDivA6 = (e) => {
    let tempEstimate = 0

    if (e.target.value > 10) {
      tempEstimate = 50 * (e.target.value - 10) + 800
    } else if (e.target.value === 0 || e.target.value === '') {
      tempEstimate = 0
    } else {
      tempEstimate = 800
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  //CAT K2
  const handleRangeDivA7 = (e) => {
    let tempEstimate = 0

    if (e.target.value > 3) {
      tempEstimate = 3 * (e.target.value - 3) + 50
    } else if (e.target.value === 0 || e.target.value === '') {
      tempEstimate = 0
    } else {
      tempEstimate = 50
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleChangeConst1 = (e) => {
    let tempEstimate = 0

    if (e.target.value > 20) {
      tempEstimate = 2.4 * (e.target.value - 20) + 40
    } else if (e.target.value === 0 || e.target.value === '') {
      tempEstimate = 0
    } else {
      tempEstimate = 40
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })

    if (e.target.value >= 1) {
      setDisabled({ ...disabled, A2: true })
    } else {
      setDisabled({ ...disabled, A2: false })
    }
  }

  const handleChangeConst2 = (e) => {
    let tempEstimate = 0

    switch (true) {
      case e.target.value <= 20:
        tempEstimate = e.target.value * 2.4
        break
      case e.target.value > 20 && e.target.value <= 50:
        tempEstimate = e.target.value * 3.4
        break
      case e.target.value > 50 && e.target.value <= 100:
        tempEstimate = e.target.value * 4.8
        break
      case e.target.value > 100 && e.target.value <= 150:
        tempEstimate = e.target.value * 6
        break
      case e.target.value > 150:
        tempEstimate = e.target.value * 7.2
        break
      default:
        tempEstimate = e.target.value * 2.0
        break
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })

    if (e.target.value >= 1) {
      setDisabled({ ...disabled, A1: true })
    } else {
      setDisabled({ ...disabled, A1: false })
    }
  }

  const handleChangeConst3 = (e) => {
    let tempEstimate = 0

    if (e.target.value > 20) {
      tempEstimate = 3.4 * (e.target.value - 20) + 60
    } else if (e.target.value === 0 || e.target.value === '') {
      tempEstimate = 0
    } else {
      tempEstimate = 60
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })

    if (e.target.value >= 1) {
      setDisabled({ ...disabled, A4: true })
    } else {
      setDisabled({ ...disabled, A4: false })
    }
  }

  const handleChangeConst4 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 20:
        tempEstimate = e.target.value * 3.4
        break
      case e.target.value > 20 && e.target.value <= 50:
        tempEstimate = e.target.value * 5.2
        break
      case e.target.value > 50 && e.target.value <= 100:
        tempEstimate = e.target.value * 8.0
        break
      case e.target.value > 100 && e.target.value <= 150:
        tempEstimate = e.target.value * 8.0
        break
      case e.target.value > 150:
        tempEstimate = e.target.value * 8.4
        break
      default:
        tempEstimate = e.target.value * 3
        break
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })

    if (e.target.value >= 1) {
      setDisabled({ ...disabled, A3: true })
    } else {
      setDisabled({ ...disabled, A3: false })
    }
  }

  const handleChangeConst5 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 5000:
        tempEstimate = e.target.value * 23
        break
      case e.target.value > 5000 && e.target.value <= 6000:
        tempEstimate = 137000 + (e.target.value - 6000) * 22
        break
      case e.target.value > 6000 && e.target.value <= 7000:
        tempEstimate = 157500 + (e.target.value - 7000) * 20.5
        break
      case e.target.value > 7000 && e.target.value <= 8000:
        tempEstimate = 177000 + (e.target.value - 8000) * 19.5
        break
      case e.target.value > 8000 && e.target.value <= 9000:
        tempEstimate = 195000 + (e.target.value - 9000) * 18
        break
      case e.target.value > 9000 && e.target.value <= 10000:
        tempEstimate = 212000 + (e.target.value - 10000) * 17
        break
      case e.target.value > 10000 && e.target.value <= 15000:
        tempEstimate = 292000 + (e.target.value - 15000) * 16
        break
      case e.target.value > 15000 && e.target.value <= 20000:
        tempEstimate = 367000 + (e.target.value - 20000) * 15
        break
      case e.target.value > 20000 && e.target.value <= 30000:
        tempEstimate = 507000 + (e.target.value - 30000) * 14
        break
      case e.target.value > 30000:
        tempEstimate = 507000 + (e.target.value - 30000) * 12
        break
      default:
        tempEstimate = 0
        break
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleChangeConst6 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 5000:
        tempEstimate = e.target.value * 12
        break
      case e.target.value > 5000 && e.target.value <= 6000:
        tempEstimate = 71000 + (e.target.value - 6000) * 11
        break
      case e.target.value > 6000 && e.target.value <= 7000:
        tempEstimate = 81200 + (e.target.value - 7000) * 10.2
        break
      case e.target.value > 7000 && e.target.value <= 8000:
        tempEstimate = 90800 + (e.target.value - 8000) * 9.6
        break
      case e.target.value > 8000 && e.target.value <= 9000:
        tempEstimate = 99800 + (e.target.value - 9000) * 9
        break
      case e.target.value > 9000 && e.target.value <= 10000:
        tempEstimate = 108200 + (e.target.value - 10000) * 8.4
        break
      case e.target.value > 10000 && e.target.value <= 15000:
        tempEstimate = 144200 + (e.target.value - 15000) * 7.2
        break
      case e.target.value > 15000 && e.target.value <= 20000:
        tempEstimate = 177200 + (e.target.value - 20000) * 6.6
        break
      case e.target.value > 20000 && e.target.value <= 30000:
        tempEstimate = 237200 + (e.target.value - 30000) * 6
        break
      case e.target.value > 30000:
        tempEstimate = 237200 + (e.target.value - 30000) * 5
        break
      default:
        tempEstimate = 0
        break
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleChange = (e) => {
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: e.target.value * el.fee,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleCreateSubmit = () => {
    setModal({ successModal: true })
    dispatch(
      addAssessment({
        grandTotal,
        assessments: state,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
        LineGrade: obj.find((el) => el.name === 'Line Grade').estimate,
        Demolition: kTotal,
        Fencing: cTotal,
        ConstSideWalk: obj.find((el) => el.name === 'Pavements').estimate,
        PavedAreas: obj.find((el) => el.name === 'Excess Paved').estimate,
        StreetSideWalk: obj.find((el) => el.name === 'Sidewalks').estimate,
        SignPermit: hTotal,
        BuildingPermitFees: buildingTotal,
      })
    )
  }

  const handleUpdateSubmit = () => {
    setModal({ updateModal: true })
    dispatch(
      editAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
        LineGrade: obj.find((el) => el.name === 'Line Grade').estimate,
        Demolition: kTotal,
        Fencing: cTotal,
        ConstSideWalk: obj.find((el) => el.name === 'Pavements').estimate,
        PavedAreas: obj.find((el) => el.name === 'Excess Paved').estimate,
        StreetSideWalk: obj.find((el) => el.name === 'Sidewalks').estimate,
        SignPermit: hTotal,
        BuildingPermitFees: buildingTotal,
      })
    )
  }

  const handleBtnCancel = () => {
    if (isUpdating !== null) {
      history.push('/assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    } else {
      history.push('/for-assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    }
  }

  const bTotal = [
    obj.find((el) => el.name === 'Enroachment').estimate,
    obj.find((el) => el.name === 'Other Excav').estimate,
    obj.find((el) => el.name === 'Excav Foundation').estimate,
    obj.find((el) => el.name === 'Issuance').estimate,
    obj.find((el) => el.name === 'Excavation').estimate,
    obj.find((el) => el.name === 'Inspect Verify').estimate,
  ].reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const cTotal = [
    obj.find((el) => el.name === 'Fencing Masonry').estimate,
    obj.find((el) => el.name === 'Indigenous').estimate,
  ].reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const hTotal = [
    obj.find((el) => el.name === 'Anchorage').estimate,
    obj.find((el) => el.name === 'NeonBus').estimate,
    obj.find((el) => el.name === 'NeonAd').estimate,
    obj.find((el) => el.name === 'IllumBus').estimate,
    obj.find((el) => el.name === 'IllumAd').estimate,
    obj.find((el) => el.name === 'OtherBus').estimate,
    obj.find((el) => el.name === 'OtherAd').estimate,
    obj.find((el) => el.name === 'PaintBus').estimate,
    obj.find((el) => el.name === 'PaintAd').estimate,
  ].reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const iTotal = [
    obj.find((el) => el.name === 'Vertical Dim').estimate,
    obj.find((el) => el.name === 'Horizontal Dim').estimate,
  ].reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const kTotal = [
    obj.find((el) => el.name === 'Demolition Bldg').estimate,
    obj.find((el) => el.name === 'Bldg Frame').estimate,
    obj.find((el) => el.name === 'Structure Height').estimate,
    obj.find((el) => el.name === 'Appendage').estimate,
    obj.find((el) => el.name === 'Moving Fee').estimate,
  ].reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const buildingTotal = [
    obj.find((el) => el.name === 'OrigConstruction').estimate,
    obj.find((el) => el.name === 'RenovationConstruction').estimate,
    obj.find((el) => el.name === 'OrigComplete').estimate,
    obj.find((el) => el.name === 'RenovationComplete').estimate,
    obj.find((el) => el.name === 'DivisionC').estimate,
    obj.find((el) => el.name === 'DivisionD').estimate,
  ].reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  // End Functions

  //Use Effect
  useEffect(() => {
    dispatch(
      retrieveAppDetails({ appID: match.params.id, userID: userCredentials.id })
    )

    isUpdating !== null &&
      dispatch(
        retrieveAssessmentDetails({
          evalAppID: isUpdating.evalAppID,
          userID: userCredentials.id,
        })
      )
  }, [dispatch, match, userCredentials])

  useEffect(() => {
    setGrandTotal(() => total)

    setState(() => filtered)
  }, [total])

  useEffect(() => {
    if (isUpdating !== null) {
      if (assessmentDetails && assessmentDetails.message) {
        assessmentDetails.message.map((submitted) => {
          let temp = obj
          let index = obj.findIndex(
            (el) => el.id_per_name === submitted.id_per_name
          )
          temp[index].units = submitted.units
          temp[index].estimate = submitted.estimate
          temp[index] = { ...temp[index], id: submitted.id }
          setObj(temp)
        })
        setGrandTotal(() => assessmentDetails.message[0].grandTotal)
      }
    }
  }, [assessmentDetails])

  // console.log(obj)

  return (
    <UserMain>
      <Container className='bg-light py-3'>
        <>
          {/* <h1 className='text-center mb-4'>ACCESSORIES FEES</h1> */}
          <Row>
            <Col
              xl={12}
              className='mb-4 pt-3'
              style={{ background: '#f9f6f1' }}
            >
              <h6>Note: </h6>
              <p>Click category to be assessed.</p>
            </Col>
          </Row>

          <Form
            onSubmit={(e) => {
              e.preventDefault()
              isUpdating !== null ? handleUpdateSubmit() : handleCreateSubmit()
            }}
          >
            <Accordion className='mx-0'>
              <AccessoriesFee
                handleRangeDivA1={handleRangeDivA1}
                handleChange={handleChange}
                bTotal={bTotal}
                handleRangeDivA2={handleRangeDivA2}
                cTotal={cTotal}
                obj={obj}
                handleRangeDivA3={handleRangeDivA3}
                handleRangeDivA4={handleRangeDivA4}
                handleRangeDivA5={handleRangeDivA5}
                hTotal={hTotal}
                iTotal={iTotal === undefined ? 0 : iTotal}
                handleRangeDivA6={handleRangeDivA6}
                handleRangeDivA7={handleRangeDivA7}
                kTotal={kTotal}
                grandTotal={grandTotal}
              />

              <ConstructionRenovation
                disabled={disabled}
                obj={obj}
                handleChangeConst1={handleChangeConst1}
                handleChangeConst2={handleChangeConst2}
                handleChangeConst3={handleChangeConst3}
                handleChangeConst4={handleChangeConst4}
                handleChangeConst5={handleChangeConst5}
                handleChangeConst6={handleChangeConst6}
              />
              {/* <div className='d-flex justify-content-end my-2 mx-3'>
                <h6 className='my-1 mx-2'>GRAND TOTAL: </h6>
                <h2 className='text-danger'> {grandTotal.toLocaleString()}</h2>
              </div> */}
              <Row className='mt-3 mx-1'>
                {/* <h6>{buildingTotal}</h6> */}
                <Col xl={8} className='text-end align-middle mt-2'>
                  <h4>GRAND TOTAL:</h4>
                </Col>
                <Col xl={4} className='mb-5'>
                  <Form.Control
                    type='text'
                    className='border border-secondary'
                    style={{
                      marginLeft: '8px',
                      marginRight: '5px',
                    }}
                    name='GrandTotal'
                    value={grandTotal.toLocaleString()}
                    placeholder='Grand Total'
                    disabled
                  />
                </Col>
              </Row>
              <div className='d-flex justify-content-end'>
                <Button
                  size='sm'
                  variant='danger'
                  type='button'
                  className='mx-1'
                  onClick={handleBtnCancel}
                >
                  Cancel
                </Button>
                <Button
                  size='sm'
                  variant='success'
                  type='submit'
                  className='mx-1'
                >
                  Submit
                </Button>
              </div>
            </Accordion>
          </Form>
        </>
      </Container>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={createAssessment && createAssessment.loading}
        success={createAssessment && createAssessment.message}
        error={createAssessment && createAssessment.error}
      />
      {/* End Success Modal */}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateModal}
        onHide={() => {
          setModal({ updateModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={updateAssessment && updateAssessment.loading}
        success={updateAssessment && updateAssessment.message}
        error={updateAssessment && updateAssessment.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(AccessoriesFeeScreen)
