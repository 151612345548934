import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Spinner,
} from 'react-bootstrap'
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaMinusCircle,
  FaPlusCircle,
} from 'react-icons/fa'
import _ from 'lodash'
import Autosuggest from 'react-autosuggest'
import io from 'socket.io-client'

import { withRouter } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { categoriesRetrive } from '../redux/actions/categoriesActions'
import {
  userApplicationInsert,
  retrieveEngrList,
} from '../redux/actions/userApplicationActions'
import { getUserProfile } from '../redux/actions/userProfileAction'

// Components
import UserMain from '../layout/UserLayout/UserMain'

const socket = io.connect(process.env.REACT_APP_URL)

const CategoryScreen = () => {
  const dispatch = useDispatch()
  const categories = useSelector((state) => state.categories)
  const { categoriesDetail, loading } = categories

  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const userApplication = useSelector((state) => state.applications)
  const { application, retrievedEngrList } = userApplication

  const userProfile = useSelector((state) => state.userProfile)
  const { profile } = userProfile

  const [InitialState, setInitialState] = useState({
    userID: userCredentials.id,
    ApplicationName: '',
    Link: '',
    ModeofForms: '',
    Structural: 0,
    Mechanical: 0,
    Architectural: 0,
    Sanitary: 0,
    Electrical: 0,
    Plumbing_Sanitary: 0,
    Electronics: 0,
    BpNo: '',
  })

  const [modalShow, setModalShow] = useState({
    show: false,
    successShow: false,
    warning: false,
    isWebApp: false,
    isDownloadableForm: false,
    isProfession: false,
  })

  const [suggestions, setSuggestions] = useState([])

  const [value, setValue] = useState('')

  const [recordEngineer, setRecordEngineer] = useState([])

  const [isTrade, setIsTrade] = useState('')

  const [engineerDetails, setEngineerDetails] = useState({
    trade: '',
    inChargeName: '',
    inChargeType: '',
    inChargeProfession: '',
    inChargePrcNo: '',
    inChargePrcValidity: '',
    inChargePtrNo: '',
    inChargePtrValidity: '',
    inChargeID: '',
    designName: '',
    designProfession: '',
    designType: '',
    designPrcNo: '',
    designPrcValidity: '',
    designPtrNo: '',
    designPtrValidity: '',
    designChargeID: '',
  })

  const [dataList, setDataList] = useState([])

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      if (event.target.checked) {
        setInitialState({
          ...InitialState,
          [event.target.name]: 1,
        })
        // let index = recordEngineer.findIndex(
        //   (e) => e.trade === event.target.name
        // )

        // if (index < 0) {
        //   let temp = recordEngineer
        //   temp.push({
        //     trade: event.target.name,
        //     inCharge: {
        //       type: `Supervisor in-charge of ${event.target.name} works`,
        //       engName: '',
        //       profession: '',
        //     },
        //     design: {
        //       type: 'Design professional, plans, and specification',
        //       engName: '',
        //       profession: '',
        //     },
        //   })
        // setRecordEngineer(temp)
        setIsTrade(event.target.name)
        // }

        setEngineerDetails({
          ...engineerDetails,
          trade: event.target.name,
          inChargeType: `Supervisor in-charge of ${event.target.name} works`,
          designType: 'Design professional, plans, and specification',
        })

        setModalShow({
          ...modalShow,
          isProfession: true,
          isDownloadableForm: false,
        })
      } else {
        setInitialState({
          ...InitialState,
          [event.target.name]: 0,
        })
        let filtered = recordEngineer.filter(
          (e) => e.trade !== event.target.name
        )
        setRecordEngineer(filtered)
      }
    } else {
      setInitialState({
        ...InitialState,
        [event.target.name]: event.target.value,
      })
    }
  }

  const handleShowModal = (permit) => {
    setModalShow({
      ...modalShow,
      show: true,
    })
    setInitialState({
      ...InitialState,
      ApplicationName: permit,
    })
  }

  const handleCheckDlForm = () => {
    if (InitialState.ModeofForms !== '') {
      if (InitialState.ModeofForms === 'Downloadable Forms') {
        setInitialState({
          ...InitialState,
          isDownloadableForm: true,
        })
        setModalShow({ ...modalShow, isDownloadableForm: true, show: false })
      } else {
        setModalShow({
          ...modalShow,
          show: false,
          warning: true,
        })
        // history.push({
        //   pathname: '/building-permit',
        //   state: {
        //     InitialState,
        //   },
        // })
      }
    }
  }

  const handleWebAppBack = () => {
    setModalShow({ ...modalShow, show: true, warning: false })
  }

  // const handeEngrChange = (e) => {
  //   // let index = recordEngineer.findIndex((e) => e.trade === isTrade)
  //   // recordEngineer[index].inCharge.engName = e.target.value

  //   // dispatch(retrieveEngrList({ name: recordEngineer[index].inCharge.engName }))
  //   setEngineerDetails({
  //     ...engineerDetails,
  //     [e.target.name]: e.target.value,
  //   })
  // }

  // const handeDesignChange = (e) => {
  //   let index = recordEngineer.findIndex((e) => e.trade === isTrade)
  //   recordEngineer[index].design.engName = e.target.value

  //   dispatch(retrieveEngrList({ name: recordEngineer[index].design.engName }))
  // }

  // const handleWebAppSubmit = () => {
  //   setInitialState({
  //     ...InitialState,
  //   })
  //   dispatch(userApplicationInsert(InitialState))
  //   history.push({
  //     pathname: '/building-permit',
  //     state: {
  //       InitialState,
  //     },
  //   })
  // }

  const handleSubmit = (event) => {
    event.preventDefault()
    dispatch(
      userApplicationInsert({
        ApplicationName: InitialState.ApplicationName,
        Link: InitialState.Link,
        ModeofForms: InitialState.ModeofForms,
        userID: InitialState.userID,
        BpNo: InitialState.BpNo,
        Structural: InitialState.Structural,
        Mechanical: InitialState.Mechanical,
        Architectural: InitialState.Architectural,
        Sanitary: InitialState.Sanitary,
        Electrical: InitialState.Electrical,
        Plumbing_Sanitary: InitialState.Plumbing_Sanitary,
        Electronics: InitialState.Electronics,
        recordEngineer: recordEngineer,
      })
    )

    setModalShow({
      ...modalShow,
      show: false,
      isDownloadableForm: false,
      successShow: true,
    })

    setInitialState({
      ...InitialState,
      ApplicationName: '',
      Link: '',
      ModeofForms: '',
      Structural: 0,
      Mechanical: 0,
      Architectural: 0,
      Sanitary: 0,
      Electrical: 0,
      Plumbing_Sanitary: 0,
      Electronics: 0,
      BpNo: '',
      isDownloadableForm: false,
    })

    setEngineerDetails({
      trade: '',
      inChargeName: '',
      inChargeType: '',
      inChargeProfession: '',
      inChargePrcNo: '',
      inChargePrcValidity: '',
      inChargePtrNo: '',
      inChargePtrValidity: '',
      inChargeID: '',
      designName: '',
      designProfession: '',
      designType: '',
      designPrcNo: '',
      designPrcValidity: '',
      designPtrNo: '',
      designPtrValidity: '',
      designChargeID: '',
    })
    setRecordEngineer([])
  }

  useEffect(() => {
    dispatch(categoriesRetrive())
    dispatch(getUserProfile({ id: userCredentials.id }))
    dispatch(retrieveEngrList({ name: '' }))
  }, [dispatch])

  useEffect(() => {
    if (retrievedEngrList && retrievedEngrList.message) {
      setDataList(retrievedEngrList.message)
    }
    if (application && application.message) {
      socket.emit('send_new_application', {
        message: 'New Application Created',
      })
    }
  }, [retrievedEngrList, recordEngineer, application, socket])

  // Practice

  //handle Change
  const onChange = (e) => {
    setEngineerDetails({
      ...engineerDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleOnClick = (data) => {
    setEngineerDetails({
      ...engineerDetails,
      inChargeName: data.Name,
      inChargeProfession: data.ProfessionName,
      inChargePrcNo: data.prcNo,
      inChargePrcValidity: data.prcValidity,
      inChargePtrNo: data.ptrNo,
      inChargePtrValidity: data.ptrValidity,
      inChargeID: data.id,
    })
  }

  const handleOnClickDesign = (data) => {
    setEngineerDetails({
      ...engineerDetails,
      designName: data.Name,
      designProfession: data.ProfessionName,
      designPrcNo: data.prcNo,
      designPrcValidity: data.prcValidity,
      designPtrNo: data.ptrNo,
      designPtrValidity: data.ptrValidity,
      designChargeID: data.id,
    })
  }

  const handleSubmitEngrDetails = (e) => {
    e.preventDefault()
    let index = recordEngineer.findIndex((value) => value.trade === isTrade)

    if (index < 0) {
      let tempValue = recordEngineer

      tempValue.push(engineerDetails)

      setModalShow({
        ...modalShow,
        isProfession: false,
        isDownloadableForm: true,
      })

      setEngineerDetails({
        trade: '',
        inChargeName: '',
        inChargeType: '',
        inChargeProfession: '',
        inChargePrcNo: '',
        inChargePrcValidity: '',
        inChargePtrNo: '',
        inChargePtrValidity: '',
        inChargeID: '',
        designName: '',
        designProfession: '',
        designType: '',
        designPrcNo: '',
        designPrcValidity: '',
        designPtrNo: '',
        designPtrValidity: '',
        designChargeID: '',
      })
    }
  }

  const renderApplicationForm = (applicationName) => {
    switch (applicationName) {
      case 'OCCUPANCY PERMIT':
        return (
          <>
            <h5>
              Link <i className='text-danger'>*</i>
            </h5>
            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='Link'
                  value={InitialState.Link}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={(event) => {
                    setInitialState({
                      ...InitialState,
                      [event.target.name]: event.target.value,
                      Architectural: 1,
                    })
                  }}
                  required
                />
              </Form.Group>
            </Row>
            <div className='mt-3' style={{ background: '#f9f6f1' }}>
              <h6 className='pt-2'>Note: </h6>
              <i className='fs-6 text-dark'>
                Kindly make sure that the requirements indicated on the
                checklist of application are included in the link you are going
                to attach in this submission.
              </i>
            </div>
          </>
        )
      case 'FENCING PERMIT':
        return (
          <>
            <h5>List of registered engineer & architect</h5>

            <p className='p-0 m-0'>SUPERVISOR / IN-CHARGE</p>

            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='inChargeName'
                  onChange={onChange}
                  value={engineerDetails.inChargeName}
                  autoComplete='off'
                  required
                  size='sm'
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.inChargeName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => {
                            recordEngineer.push({
                              inChargeName: data.Name,
                              inChargeProfession: data.ProfessionName,
                              inChargePrcNo: data.prcNo,
                              inChargePrcValidity: data.prcValidity,
                              inChargePtrNo: data.ptrNo,
                              inChargePtrValidity: data.ptrValidity,
                              inChargeID: data.id,
                              trade: 'Architectural',
                              inChargeType: `Supervisor in-charge of Architectural works`,
                            })
                            setEngineerDetails({
                              ...engineerDetails,
                              inChargeName: data.Name,
                              inChargeProfession: data.ProfessionName,
                              inChargePrcNo: data.prcNo,
                              inChargePrcValidity: data.prcValidity,
                              inChargePtrNo: data.ptrNo,
                              inChargePtrValidity: data.ptrValidity,
                              inChargeID: data.id,
                              trade: 'Architectural',
                              inChargeType: `Supervisor in-charge of Architectural works`,
                            })

                            setInitialState({
                              ...InitialState,
                              Architectural: 1,
                            })
                          }}
                        >
                          {data.Name}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargeProfession}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePrcNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePtrNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <h5>
              Link <i className='text-danger'>*</i>
            </h5>
            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='Link'
                  value={InitialState.Link}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>
            <div className='mt-3' style={{ background: '#f9f6f1' }}>
              <h6 className='pt-2'>Note: </h6>
              <i className='fs-6 text-dark'>
                Kindly make sure that the requirements indicated on the
                checklist of application are included in the link you are going
                to attach in this submission.
              </i>
            </div>
          </>
        )
      case 'DEMOLITION PERMIT':
        return (
          <>
            <h5>List of registered engineer & architect</h5>

            <p className='p-0 m-0'>SUPERVISOR / IN-CHARGE</p>

            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='inChargeName'
                  onChange={onChange}
                  value={engineerDetails.inChargeName}
                  autoComplete='off'
                  required
                  size='sm'
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.inChargeName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => {
                            recordEngineer.push({
                              inChargeName: data.Name,
                              inChargeProfession: data.ProfessionName,
                              inChargePrcNo: data.prcNo,
                              inChargePrcValidity: data.prcValidity,
                              inChargePtrNo: data.ptrNo,
                              inChargePtrValidity: data.ptrValidity,
                              inChargeID: data.id,
                              trade: 'Architectural',
                              inChargeType: `Supervisor in-charge of Architectural works`,
                            })
                            setEngineerDetails({
                              ...engineerDetails,
                              inChargeName: data.Name,
                              inChargeProfession: data.ProfessionName,
                              inChargePrcNo: data.prcNo,
                              inChargePrcValidity: data.prcValidity,
                              inChargePtrNo: data.ptrNo,
                              inChargePtrValidity: data.ptrValidity,
                              inChargeID: data.id,
                              trade: 'Architectural',
                              inChargeType: `Supervisor in-charge of Architectural works`,
                            })

                            setInitialState({
                              ...InitialState,
                              Architectural: 1,
                            })
                          }}
                        >
                          {data.Name}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargeProfession}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePrcNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePtrNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <h5 className='mt-2'>
              Link <i className='text-danger'>*</i>
            </h5>
            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='Link'
                  value={InitialState.Link}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>
            <div className='mt-3' style={{ background: '#f9f6f1' }}>
              <h6 className='pt-2'>Note: </h6>
              <i className='fs-6 text-dark'>
                Kindly make sure that the requirements indicated on the
                checklist of application are included in the link you are going
                to attach in this submission.
              </i>
            </div>
          </>
        )
      case 'ELECTRICAL PERMIT':
        return (
          <>
            <div className='d-flex justify-content-end'>
              <Form.Check
                type='checkbox'
                label='Click to tag registered engineer'
                name='Electrical'
                onChange={handleChange}
                checked={InitialState.Electrical === 1 && true}
                required
              />
            </div>

            <h5>
              Link <i className='text-danger'>*</i>
            </h5>
            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='Link'
                  value={InitialState.Link}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>
            <div className='mt-3' style={{ background: '#f9f6f1' }}>
              <h6 className='pt-2'>Note: </h6>
              <i className='fs-6 text-dark'>
                Kindly make sure that the requirements indicated on the
                checklist of application are included in the link you are going
                to attach in this submission.
              </i>
            </div>
          </>
        )
      default:
        return (
          <>
            <h5>ATTACHED PERMIT TRADES</h5>
            <p>Click the trades that include to your files</p>
            <Row>
              {_.zip(
                [
                  'STRUCTURAL',
                  'MECHANICAL',
                  'ARCHITECTURAL',
                  'PLUMBING/SANITARY',
                  'ELECTRICAL',
                  'ELECTRONICS',
                ],
                [
                  'Structural',
                  'Mechanical',
                  'Architectural',
                  'Plumbing_Sanitary',
                  'Electrical',
                  'Electronics',
                ]
              ).map((trades) => (
                <Form.Group
                  className='my-2'
                  as={Col}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={3}
                  key={trades[0]}
                >
                  <Form.Check
                    type='checkbox'
                    label={trades[0]}
                    name={trades[1]}
                    onChange={handleChange}
                    checked={InitialState[`${trades[1]}`] === 1 && true}
                  />
                </Form.Group>
              ))}
            </Row>
            <hr />

            <h5>
              Link <i className='text-danger'>*</i>
            </h5>
            <Row>
              <Form.Group as={Col} md={12}>
                <Form.Control
                  type='text'
                  className='mt-2'
                  name='Link'
                  value={InitialState.Link}
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>
            <div className='mt-3' style={{ background: '#f9f6f1' }}>
              <h6 className='pt-2'>Note: </h6>
              <i className='fs-6 text-dark'>
                Kindly make sure that the requirements indicated on the
                checklist of application are included in the link you are going
                to attach in this submission.
              </i>
            </div>
          </>
        )
    }
  }

  // console.log(InitialState)

  return (
    <UserMain>
      <Container>
        <Container
          fluid
          className='my-2 shadow-lg pb-5 px-4'
          style={{ background: '#FFF' }}
        >
          {!loading && categoriesDetail ? (
            <>
              <Row className='text-center py-3'>
                <Col xl={12}>
                  <h3>Our list of Permit</h3>
                  <div className='d-flex justify-content-center'>
                    <hr
                      style={{
                        width: '200px',
                        margin: 0,
                      }}
                    />
                  </div>
                  <p className='mt-2'>"Choose desired permit to apply for"</p>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <Row className='justify-content-center'>
                    {categoriesDetail &&
                      categoriesDetail.map((category) => (
                        <Col
                          xl={4}
                          key={category.id}
                          className='py-3'
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Card
                            border='success'
                            style={{
                              width: '18rem',
                            }}
                          >
                            <Card.Header
                              style={{ background: '#28a745', color: '#FFF' }}
                              className='py-3'
                            >
                              {category.PermitName}
                            </Card.Header>
                            <Card.Body className='d-flex'>
                              <Card.Text>
                                {category.PermitDescription}
                              </Card.Text>
                            </Card.Body>
                            <Card.Footer className='bg-white'>
                              <Row>
                                <Col
                                  xl={12}
                                  className='d-flex justify-content-end'
                                >
                                  {/* <Button
                            variant='success'
                            onClick={() => history.push(`${category.url}`)}
                          > */}
                                  {profile && profile[0].PRA_id !== null ? (
                                    <Button
                                      variant='success'
                                      onClick={() =>
                                        handleShowModal(category.PermitName)
                                      }
                                    >
                                      Apply
                                    </Button>
                                  ) : (
                                    <Button
                                      variant='success'
                                      onClick={() =>
                                        alert(
                                          'You must complete your account details first at the Profile page'
                                        )
                                      }
                                    >
                                      Apply
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </Card.Footer>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <div className='text-center pt-5'>
              <Spinner animation='border' />
              <h3>Loading...</h3>
            </div>
          )}
        </Container>
      </Container>

      {/* Show Modal Apply */}
      <Modal
        size='lg'
        show={modalShow.show}
        onHide={() => setModalShow({ ...modalShow, show: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            {InitialState.ApplicationName}
          </Modal.Title>
        </Modal.Header>
        {InitialState.ApplicationName === 'OCCUPANCY PERMIT' ? (
          <>
            <Modal.Body>
              Building Permit No. <i className='text-danger'>*</i>
              <Row className='mb-2'>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    type='text'
                    className='mt-2'
                    name='BpNo'
                    placeholder='Input building permit no. here ...'
                    value={InitialState.BpNo}
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              Please choose what kind of forms you are going to use for your
              application
              <Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    value={InitialState.ModeofForms}
                    as='select'
                    className='mt-2'
                    name='ModeofForms'
                    onChange={handleChange}
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                  >
                    <option value=''>Choose...</option>
                    {['Downloadable Forms', 'Website Application Forms'].map(
                      (x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              {InitialState.BpNo === '' ? (
                <Button variant='info' disabled>
                  Next
                </Button>
              ) : (
                <Button variant='info' onClick={() => handleCheckDlForm()}>
                  Next
                </Button>
              )}
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              Please choose what kind of forms you are going to use for your
              application
              <Row>
                <Form.Group as={Col} md={12}>
                  <Form.Control
                    value={InitialState.ModeofForms}
                    as='select'
                    className='mt-2'
                    name='ModeofForms'
                    onChange={handleChange}
                    style={{
                      background: '#FFF',
                      border: '1px solid #ced4da',
                    }}
                    required
                  >
                    <option value=''>Choose...</option>
                    {['Downloadable Forms', 'Website Application Forms'].map(
                      (x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              <Button
                size='sm'
                variant='info'
                onClick={() => handleCheckDlForm()}
              >
                Next
              </Button>

              <Button
                size='sm'
                variant='danger'
                onClick={() => {
                  setInitialState({ ...InitialState, ModeofForms: '' })
                  setModalShow({ ...modalShow, show: false })
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>

      <Modal
        show={modalShow.successShow}
        onHide={() => setModalShow({ ...modalShow, successShow: false })}
        centered
      >
        {application && application.message ? (
          <>
            <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheckCircle className='mx-3' />
                Success
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-center'>{application.message}</h5>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              <Button
                variant='danger'
                onClick={() =>
                  setModalShow({ ...modalShow, successShow: false })
                }
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : application && application.error ? (
          <>
            <Modal.Header style={{ background: '#d9534f', color: '#FFF' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheckCircle className='mx-3' />
                Failed
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-center'>{application.error}</h5>
            </Modal.Body>
            <Modal.Footer className='p-1'>
              <Button
                variant='info'
                onClick={() =>
                  setModalShow({ ...modalShow, successShow: false })
                }
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : (
          application && (
            <>
              <div className='text-center pt-3'>
                <Spinner animation='border' />
                <h3>Loading...</h3>
              </div>
            </>
          )
        )}
      </Modal>

      <Modal
        show={modalShow.warning}
        onHide={() => setModalShow({ ...modalShow, warning: false })}
        centered
      >
        <Modal.Header style={{ background: '#1f9bcf', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaExclamationCircle className='mx-3' />
            Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Sorry for the inconvinience, this feature will be available soon.
          </h5>
          {/* <Row>
            <Col md={12} className='text-center'>
              Web App Forms needs to fill out forms in the website to fill the
              permit
            </Col>
          </Row>
          <Row className='my-2'>
            <Col md={12} className='text-center'>
              <p>
                <i className='fw-bold mx-2'>Note:</i>
                by clicking submit button it will insert the application into
                yuor drafts, until you are not finish with the application.
              </p>
            </Col>
          </Row> */}
        </Modal.Body>
        <Modal.Footer className='p-1'>
          <Button variant='danger' onClick={handleWebAppBack}>
            Back
          </Button>
          {/* <Button variant='info' onClick={handleWebAppSubmit}>
            Submit
          </Button> */}
        </Modal.Footer>
      </Modal>

      {/* Downloadable Form Modal Application */}
      <Modal
        size='xl'
        show={modalShow.isDownloadableForm}
        onHide={() => setModalShow({ ...modalShow, isDownloadableForm: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            {InitialState.ApplicationName}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {renderApplicationForm(InitialState.ApplicationName)}
          </Modal.Body>
          <Modal.Footer className='p-1'>
            <Button variant='info' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setModalShow({
                  ...modalShow,
                  isDownloadableForm: false,
                  show: true,
                })

                setInitialState({
                  ...InitialState,
                  Link: '',
                  ModeofForms: '',
                  Structural: 0,
                  Mechanical: 0,
                  Architectural: 0,
                  Sanitary: 0,
                  Electrical: 0,
                  Plumbing_Sanitary: 0,
                  Electronics: 0,
                  BpNo: '',
                })

                setRecordEngineer([])

                setIsTrade('')
              }}
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* End Downloadable Form Modal Application */}

      <Modal
        size='md'
        show={modalShow.isProfession}
        onHide={() => setModalShow({ ...modalShow, isProfession: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            {isTrade} Professional
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitEngrDetails}>
          <Modal.Body className='pb-1'>
            <h5>List of registered engineer & architect</h5>

            <p className='p-0 m-0'>SUPERVISOR / IN-CHARGE</p>

            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='inChargeName'
                  onChange={onChange}
                  value={engineerDetails.inChargeName}
                  autoComplete='off'
                  required
                  size='sm'
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.inChargeName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClick(data)}
                        >
                          {data.Name + ' ' + data.ProfessionName}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargeProfession}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePrcNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePtrNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <p className='p-0 m-0 mt-3'>
              DESIGN PROFESSIONAL, PLANS AND SPECIFICATION
            </p>
            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>

                <Form.Control
                  type='text'
                  name='designName'
                  onChange={onChange}
                  value={engineerDetails.designName}
                  autoComplete='off'
                  required
                  size='sm'
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.designName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClickDesign(data)}
                        >
                          {data.Name + ' ' + data.ProfessionName}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designProfession}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designPrcNo}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designPtrNo}
                  required
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer className='p-1'>
            <Button variant='info' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setModalShow({
                  ...modalShow,
                  isDownloadableForm: true,
                  isProfession: false,
                })

                setEngineerDetails({
                  trade: '',
                  inChargeName: '',
                  inChargeType: '',
                  inChargeProfession: '',
                  inChargePrcNo: '',
                  inChargePrcValidity: '',
                  inChargePtrNo: '',
                  inChargePtrValidity: '',
                  inChargeID: '',
                  designName: '',
                  designProfession: '',
                  designType: '',
                  designPrcNo: '',
                  designPrcValidity: '',
                  designPtrNo: '',
                  designPtrValidity: '',
                  designChargeID: '',
                })

                setInitialState({
                  ...InitialState,
                  [`${isTrade}`]: 0,
                })
              }}
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </UserMain>
  )
}

export default withRouter(CategoryScreen)
