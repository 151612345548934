import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
  Accordion,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  addAssessment,
  editAssessment,
  retrieveAssessmentDetails,
} from '../../redux/actions/Assessment/renovationAssessmentActions'
import { retrieveAppDetails } from '../../redux/actions/evaluatorActions'

const ConstructionRenovationScreen = ({ history, match }) => {
  // Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const { getAppDetails } = evaluator

  const renovationAssessment = useSelector(
    (state) => state.renovationAssessment
  )

  const { assessmentDetails, createAssessment, updateAssessment } =
    renovationAssessment

  const [obj, setObj] = useState([
    {
      id_per_name: 1,
      name: 'OrigConstruction',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 2,
      name: 'RenovationConstruction',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 3,
      name: 'OrigComplete',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 4,
      name: 'RenovationComplete',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 5,
      name: 'DivisionC',
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 6,
      name: 'DivisionD',
      units: 0,
      estimate: 0,
    },
  ])

  const [state, setState] = useState([])

  const [grandTotal, setGrandTotal] = useState(0)

  const [modal, setModal] = useState({
    successModal: false,
    updateModal: false,
  })

  const [disabled, setDisabled] = useState({
    A1: false,
    A2: false,
    A3: false,
    A4: false,
    A5: false,
    A6: false,
  })

  const total = obj.reduce(
    (prevData, currData) => prevData + currData.estimate,
    0
  )

  const filtered = obj.filter((e) => e.estimate > 0)

  const isUpdating = JSON.parse(localStorage.getItem('assessmentSelected'))
    ? JSON.parse(localStorage.getItem('assessmentSelected'))
    : null
  // End Declaration

  // Functions
  const handleRangeDivA1 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 20:
        tempEstimate = e.target.value * 2.0
        break
      default:
        tempEstimate = e.target.value * 2.0
        break
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })

    if (e.target.value >= 1) {
      setDisabled({ ...disabled, A2: true })
    } else {
      setDisabled({ ...disabled, A2: false })
    }
  }
  const handleRangeDivA2 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 20:
        tempEstimate = e.target.value * 2.4
        break
      case e.target.value > 20 && e.target.value <= 50:
        tempEstimate = e.target.value * 3.4
        break
      case e.target.value > 50 && e.target.value <= 100:
        tempEstimate = e.target.value * 4.8
        break
      case e.target.value > 100 && e.target.value <= 150:
        tempEstimate = e.target.value * 6
        break
      case e.target.value > 150:
        tempEstimate = e.target.value * 7.2
        break
      default:
        tempEstimate = e.target.value * 2.0
        break
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })

    if (e.target.value >= 1) {
      setDisabled({ ...disabled, A1: true })
    } else {
      setDisabled({ ...disabled, A1: false })
    }
  }

  const handleRangeDivA3 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 20:
        tempEstimate = e.target.value * 3
        break
      default:
        tempEstimate = e.target.value * 3.0
        break
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })

    if (e.target.value >= 1) {
      setDisabled({ ...disabled, A4: true })
    } else {
      setDisabled({ ...disabled, A4: false })
    }
  }
  const handleRangeDivA4 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 20:
        tempEstimate = e.target.value * 3.4
        break
      case e.target.value > 20 && e.target.value <= 50:
        tempEstimate = e.target.value * 5.2
        break
      case e.target.value > 50 && e.target.value <= 100:
        tempEstimate = e.target.value * 8.0
        break
      case e.target.value > 100 && e.target.value <= 150:
        tempEstimate = e.target.value * 8.0
        break
      case e.target.value > 150:
        tempEstimate = e.target.value * 8.4
        break
      default:
        tempEstimate = e.target.value * 3
        break
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })

    if (e.target.value >= 1) {
      setDisabled({ ...disabled, A3: true })
    } else {
      setDisabled({ ...disabled, A3: false })
    }
  }

  const handleRangeDivA5 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 5000:
        tempEstimate = e.target.value * 23
        break
      case e.target.value > 5000 && e.target.value <= 6000:
        tempEstimate = 137000 + (e.target.value - 6000) * 22
        break
      case e.target.value > 6000 && e.target.value <= 7000:
        tempEstimate = 157500 + (e.target.value - 7000) * 20.5
        break
      case e.target.value > 7000 && e.target.value <= 8000:
        tempEstimate = 177000 + (e.target.value - 8000) * 19.5
        break
      case e.target.value > 8000 && e.target.value <= 9000:
        tempEstimate = 195000 + (e.target.value - 9000) * 18
        break
      case e.target.value > 9000 && e.target.value <= 10000:
        tempEstimate = 212000 + (e.target.value - 10000) * 17
        break
      case e.target.value > 10000 && e.target.value <= 15000:
        tempEstimate = 292000 + (e.target.value - 15000) * 16
        break
      case e.target.value > 15000 && e.target.value <= 20000:
        tempEstimate = 367000 + (e.target.value - 20000) * 15
        break
      case e.target.value > 20000 && e.target.value <= 30000:
        tempEstimate = 507000 + (e.target.value - 30000) * 14
        break
      case e.target.value > 30000:
        tempEstimate = 507000 + (e.target.value - 30000) * 12
        break
      default:
        tempEstimate = 0
        break
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }
  const handleRangeDivA6 = (e) => {
    let tempEstimate = 0
    switch (true) {
      case e.target.value <= 5000:
        tempEstimate = e.target.value * 12
        break
      case e.target.value > 5000 && e.target.value <= 6000:
        tempEstimate = 71000 + (e.target.value - 6000) * 11
        break
      case e.target.value > 6000 && e.target.value <= 7000:
        tempEstimate = 81200 + (e.target.value - 7000) * 10.2
        break
      case e.target.value > 7000 && e.target.value <= 8000:
        tempEstimate = 90800 + (e.target.value - 8000) * 9.6
        break
      case e.target.value > 8000 && e.target.value <= 9000:
        tempEstimate = 99800 + (e.target.value - 9000) * 9
        break
      case e.target.value > 9000 && e.target.value <= 10000:
        tempEstimate = 108200 + (e.target.value - 10000) * 8.4
        break
      case e.target.value > 10000 && e.target.value <= 15000:
        tempEstimate = 144200 + (e.target.value - 15000) * 7.2
        break
      case e.target.value > 15000 && e.target.value <= 20000:
        tempEstimate = 177200 + (e.target.value - 20000) * 6.6
        break
      case e.target.value > 20000 && e.target.value <= 30000:
        tempEstimate = 237200 + (e.target.value - 30000) * 6
        break
      case e.target.value > 30000:
        tempEstimate = 237200 + (e.target.value - 30000) * 5
        break
      default:
        tempEstimate = 0
        break
    }

    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: e.target.value,
            estimate: tempEstimate,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleCreateSubmit = () => {
    setModal({ successModal: true })
    dispatch(
      addAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
      })
    )
  }

  const handleUpdateSubmit = () => {
    setModal({ updateModal: true })
    dispatch(
      editAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
      })
    )
  }

  const handleBtnCancel = () => {
    if (isUpdating !== null) {
      history.push('/assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    } else {
      history.push('/for-assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    }
  }
  //   End Functions

  //UseEffect
  useEffect(() => {
    dispatch(
      retrieveAppDetails({ appID: match.params.id, userID: userCredentials.id })
    )

    isUpdating !== null &&
      dispatch(
        retrieveAssessmentDetails({
          evalAppID: isUpdating.evalAppID,
          userID: userCredentials.id,
        })
      )
  }, [dispatch, match, userCredentials])

  useEffect(() => {
    setGrandTotal(() => total)
  }, [obj])

  useEffect(() => {
    if (isUpdating !== null) {
      if (assessmentDetails && assessmentDetails.message) {
        assessmentDetails.message.map((submitted) => {
          let temp = obj
          let index = obj.findIndex(
            (el) => el.id_per_name === submitted.id_per_name
          )
          temp[index].units = submitted.units
          temp[index].estimate = submitted.estimate
          temp[index] = { ...temp[index], id: submitted.id }
          setObj(temp)
        })
        setGrandTotal(() => assessmentDetails.message[0].grandTotal)
      }
    }
  }, [assessmentDetails])

  return (
    <UserMain>
      <Container>
        <>
          <h1 className='text-center mb-4'>CONSTRUCTION RENOVATION</h1>
          <h6>
            Construction/addition/renovation/alteration of buildings/structures
            under Group/s and Sub-Divisions shall be assessed as follows:
          </h6>
          <Row>
            <Col
              xl={12}
              className='mb-4 pt-3'
              style={{ background: '#f9f6f1' }}
            >
              <h6>Note: </h6>
              <p>Click category to be assessed.</p>
            </Col>
          </Row>

          <Form
            onSubmit={(e) => {
              e.preventDefault()

              isUpdating !== null ? handleUpdateSubmit() : handleCreateSubmit()
            }}
          >
            <Accordion className='mx-0'>
              {/*A. Division A-1  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='0'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  A. DIVISION A-1
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Original complete construction up to 20.00 sq.meters
                          </td>
                          <td>₱2.00 </td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              disabled={disabled.A1}
                              name='OrigConstruction'
                              placeholder='i.'
                              onChange={handleRangeDivA1}
                              value={obj[0].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {!disabled.A1 &&
                                disabled.A2 &&
                                obj.find((el) => el.name === 'OrigConstruction')
                                  .estimate}
                              {disabled.A1 &&
                                !disabled.A2 &&
                                obj.find(
                                  (el) => el.name === 'RenovationConstruction'
                                ).estimate}
                              {!disabled.A1 && !disabled.A2 && 0}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Additional/renovation/alteration up to 20.00 sq.
                            meters regardless of floor area of original
                            construction
                          </td>
                          <td>₱2.40 </td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              disabled={disabled.A2}
                              name='RenovationConstruction'
                              placeholder='ii.-vi.'
                              onChange={handleRangeDivA2}
                              value={obj[1].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 20.00 sq. meters to 50.00 sq. meters
                          </td>
                          <td>₱3.40 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 50.00 sq. meters to 100.00 sq. meters
                          </td>
                          <td>₱4.80 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 100.00 sq. m to 150 sq. meters
                          </td>
                          <td>₱6.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 150.00 sq. meters
                          </td>
                          <td>₱7.20 </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End A */}

              {/*B. Division A-2  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='1'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  B. DIVISION A-2
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='1'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Original complete construction up to 20.00 sq.meters
                          </td>
                          <td>₱3.00 </td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='OrigComplete'
                              placeholder='i.'
                              onChange={handleRangeDivA3}
                              value={obj[2].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                              disabled={disabled.A3}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {!disabled.A3 &&
                                disabled.A4 &&
                                obj.find((el) => el.name === 'OrigComplete')
                                  .estimate}
                              {disabled.A3 &&
                                !disabled.A4 &&
                                obj.find(
                                  (el) => el.name === 'RenovationComplete'
                                ).estimate}
                              {!disabled.A3 && !disabled.A4 && 0}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Additional/renovation/alteration up to 20.00 sq.
                            meters regardless of floor area of original
                            construction
                          </td>
                          <td>₱3.40 </td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='RenovationComplete'
                              placeholder='ii.-vi.'
                              onChange={handleRangeDivA4}
                              value={obj[3].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                              disabled={disabled.A4}
                            />
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 20.00 sq. meters to 50.00 sq. meters
                          </td>
                          <td>₱5.20 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 50.00 sq. meters to 100.00 sq. meters
                          </td>
                          <td>₱8.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 100.00 sq. m to 150 sq. meters
                          </td>
                          <td>₱8.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 150.00 sq. meters
                          </td>
                          <td>₱8.40 </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End B */}

              {/*C. DIVISION B-1/C-1/E-1, 2, 3/F-1/G-1, 2, 3, 4, 5/H-1, 2, 3, 4/I-1 AND J-1, 2, 3  */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='2'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  C. DIVISION B-1/C-1/E-1, 2, 3/F-1/G-1, 2, 3, 4, 5/H-1, 2, 3,
                  4/I-1 AND J-1, 2, 3
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='2'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Up to 5,000
                          </td>
                          <td>₱23.00 </td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='DivisionC'
                              placeholder='i.-x.'
                              onChange={handleRangeDivA5}
                              value={obj[4].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'DivisionC')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 5,000 to 6,000
                          </td>
                          <td>₱22.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 6,000 to 7,000
                          </td>
                          <td>₱20.50 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 7,000 to 8,000
                          </td>
                          <td>₱19.50 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 8,000 to 9,000
                          </td>
                          <td>₱18.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 9,000 to 10,000
                          </td>
                          <td>₱17.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 10,000 to 15,000
                          </td>
                          <td>₱16.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>viii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 15,000 to 20,000
                          </td>
                          <td>₱15.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ix.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 20,000 to 30,000
                          </td>
                          <td>₱14.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>x.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 30,000
                          </td>
                          <td>₱12.00 </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End C */}

              {/* D. DIVISION C-2/D-1, 2, 3 */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='3'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  D. DIVISION C-2/D-1, 2, 3
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='3'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-3'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Up to 5,000
                          </td>
                          <td>₱12.00 </td>
                          <td>
                            <Form.Control
                              type='text'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='DivisionD'
                              placeholder='i.-x.'
                              onChange={handleRangeDivA6}
                              value={obj[5].units}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              min={0}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {
                                obj.find((el) => el.name === 'DivisionD')
                                  .estimate
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 5,000 to 6,000
                          </td>
                          <td>₱11.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 6,000 to 7,000
                          </td>
                          <td>₱10.20</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>iv.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 7,000 to 8,000
                          </td>
                          <td>₱9.60 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>v.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 8,000 to 9,000
                          </td>
                          <td>₱9.00 </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vi.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 9,000 to 10,000
                          </td>
                          <td>₱8.40</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>vii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 10,000 to 15,000
                          </td>
                          <td>₱7.20</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>viii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 15,000 to 20,000
                          </td>
                          <td>₱6.60</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ix.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 20,000 to 30,000
                          </td>
                          <td>₱6.00</td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>x.</td>
                          <td className='text-wrap' colSpan={2}>
                            Above 30,000
                          </td>
                          <td>₱5.00</td>
                        </tr>
                      </tbody>
                    </Table>
                    <p>
                      NOTE: Computation of the building fee in item 3.d. follows
                      the example of Section 3.c. of this Schedule.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End D */}
            </Accordion>

            <Row className='pt-3'>
              <Col xl={12}>
                <Table responsive className='mb-5'>
                  <thead>
                    <tr className='text-center align-middle'>
                      <th colSpan={3}>
                        <h5>E. Division J-2 structures</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr colSpan={3}>
                      <td className='align-middle'>
                        Structures shall be assessed 50% of the rate of the
                        principal building of which they are accessories
                        (Sections 3.a. to 3.d.).
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className='mt-3 mx-1'>
              <Col xl={8} className='text-end align-middle mt-2'>
                <h4>GRAND TOTAL:</h4>
              </Col>
              <Col xl={4} className='mb-5'>
                <Form.Control
                  type='text'
                  className='border border-secondary'
                  style={{
                    marginLeft: '8px',
                    marginRight: '5px',
                  }}
                  name='GrandTotal'
                  value={grandTotal}
                  placeholder='Grand Total'
                  disabled
                />
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col xl={12} className='text-end'>
                <Button type='submit' variant='success'>
                  Submit
                </Button>
                <Button
                  variant='danger'
                  className='mx-2'
                  onClick={handleBtnCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      </Container>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={createAssessment && createAssessment.loading}
        success={createAssessment && createAssessment.message}
        error={createAssessment && createAssessment.error}
      />
      {/* End Success Modal */}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateModal}
        onHide={() => {
          setModal({ updateModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={updateAssessment && updateAssessment.loading}
        success={updateAssessment && updateAssessment.message}
        error={updateAssessment && updateAssessment.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(ConstructionRenovationScreen)
