import Cookie from 'js-cookie'
import axios from 'axios'
import {
  USER_APPLICATIONS_REQUEST,
  USER_APPLICATIONS_SUCCESS,
  USER_APPLICATIONS_FAILED,
  USER_INSERT_APPLICATION_REQUEST,
  USER_INSERT_APPLICATION_SUCCESS,
  USER_INSERT_APPLICATION_FAILED,
  // USER_APPLICATIONS_REQUEST_BYID,
  // USER_APPLICATIONS_SUCCESS_BYID,
  // USER_APPLICATIONS_FAILED_BYID,
  // DC_APPLICATIONS_RETRIEVE_BYSTATUS,
  // DC_APPLICATIONS_RETRIEVE_BYSTATUS_REQUEST,
  // USER_APPLICATIONS_RETRIEVE_BYSTATUS_REQUEST,
  // USER_APPLICATIONS_RETRIEVE_BYSTATUS,
  // USER_APPLICATIONS_RETRIEVE_BYSTATUS_FAILED,
  USER_APPLICATION_UPDATE_REQ,
  USER_APPLICATION_UPDATE_SUCCESS,
  USER_APPLICATION_UPDATE_FAILED,
  USER_APPLICATION_DELETE_REQ,
  USER_APPLICATION_DELETE_SUCCESS,
  USER_APPLICATION_DELETE_FAILED,
  USER_APPLICATION_HISTORY_REQ,
  USER_APPLICATION_HISTORY_SUCCESS,
  USER_APPLICATION_HISTORY_FAILED,
  USER_GETAPPLICATIONFORVERIFICATION_REQ,
  USER_GETAPPLICATIONFORVERIFICATION_SUCCESS,
  USER_GETAPPLICATIONFORVERIFICATION_FAILED,
  USER_GETAPPLICATIONFORINSPECTION_REQ,
  USER_GETAPPLICATIONFORINSPECTION_SUCCESS,
  USER_GETAPPLICATIONFORINSPECTION_FAILED,
  USER_GETAPPLICATIONFORCOMPLIANCE_REQ,
  USER_GETAPPLICATIONFORCOMPLIANCE_SUCCESS,
  USER_GETAPPLICATIONFORCOMPLIANCE_FAILED,
  USER_GETAPPLICATIONAPPROVED_REQ,
  USER_GETAPPLICATIONAPPROVED_SUCCESS,
  USER_GETAPPLICATIONAPPROVED_FAILED,
  USER_APPSTATUSTRACK_REQ,
  USER_APPSTATUSTRACK_SUCCESS,
  USER_APPSTATUSTRACK_FAILED,
  GET_ORDEROFPAYMENT_REQ,
  GET_ORDEROFPAYMENT_SUCCESS,
  GET_ORDEROFPAYMENT_FAILED,
  GET_RELEASEPERMIT_REQ,
  GET_RELEASEPERMIT_SUCCESS,
  GET_RELEASEPERMIT_FAILED,
  GET_LIST_REPORT_REQ,
  GET_LIST_REPORT_SUCCESS,
  GET_LIST_REPORT_FAILED,
  GET_ENGAPPLIST_REQ,
  GET_ENGAPPLIST_SUCCESS,
  GET_ENGAPPLIST_FAILED,
  ACCEPT_ENGAPPLICATION_REQ,
  ACCEPT_ENGAPPLICATION_SUCCESS,
  ACCEPT_ENGAPPLICATION_FAILED,
  DECLINE_ENGAPPLICATION_REQ,
  DECLINE_ENGAPPLICATION_SUCCESS,
  DECLINE_ENGAPPLICATION_FAILED,
  UPDATE_ENGIMAGEATTACHED_REQ,
  UPDATE_ENGIMAGEATTACHED_SUCCESS,
  UPDATE_ENGIMAGEATTACHED_FAILED,
  UPDATE_RECORDED_ENG_REQ,
  UPDATE_RECORDED_ENG_SUCCESS,
  UPDATE_RECORDED_ENG_FAILED,
  GET_ENGLIST_REQ,
  GET_ENGLIST_SUCCESS,
  GET_ENGLIST_FAILED,
  GET_RECORDED_ENG_REQ,
  GET_RECORDED_ENG_SUCCESS,
  GET_RECORDED_ENG_FAILED,
  GET_RELEASED_OCCU_REQ,
  GET_RELEASED_OCCU_SUCCESS,
  GET_RELEASED_OCCU_FAILED,
  PAYMENT_INITIALIZE_REQ,
  PAYMENT_INITIALIZE_SUCCESS,
  PAYMENT_INITIALIZE_FAILED,
  GET_RELEASECFEI_REQ,
  GET_RELEASECFEI_SUCCESS,
  GET_RELEASECFEI_FAILED,
} from '../constants/userApplicationsConstants'

import {
  retrieveEngrProfile,
  retrieveEngAttachments,
} from '../actions/userProfileAction'

// Get All Applications
export const userApplicationRetrieve = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_APPLICATIONS_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications`,
        payload,
        config
      )

      dispatch({
        type: USER_APPLICATIONS_SUCCESS,
        payload: data,
      })
    } catch (err) {
      dispatch({
        type: USER_APPLICATIONS_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// Get ENG APP LIST
export const retrieveEngAppList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ENGAPPLIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/registered-eng/applist-eng`,
        payload,
        config
      )

      dispatch({
        type: GET_ENGAPPLIST_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_ENGAPPLIST_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// Get ENGR LIST
export const retrieveEngrList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ENGLIST_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/eng-list`,
        payload,
        config
      )

      dispatch({
        type: GET_ENGLIST_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_ENGLIST_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// Get Recorded List
export const retrieveRecordedEng = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_RECORDED_ENG_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/engineer`,
        payload,
        config
      )

      dispatch({
        type: GET_RECORDED_ENG_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_RECORDED_ENG_FAILED,
        payload: 'Engineer Found!!!!',
      })
    }
  }
}

// Get  Order of Payment
export const userGetOrderOfPayment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ORDEROFPAYMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/orderofpayment/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: GET_ORDEROFPAYMENT_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_ORDEROFPAYMENT_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// GET release permit
export const userRetrievedReleaseCFEI = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_RELEASECFEI_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/userapplications/release-cfei/${payload.id}`,
        config
      )

      dispatch({
        type: GET_RELEASECFEI_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_RELEASECFEI_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// GET release permit
export const userRetrievedReleasePermit = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_RELEASEPERMIT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/releasepermits/${payload.id}`,
        payload,
        config
      )

      dispatch({
        type: GET_RELEASEPERMIT_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_RELEASEPERMIT_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// GET release permit Occu
export const retrieveReleasedOccu = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_RELEASED_OCCU_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/userapplications/release-occu/${payload.id}`,
        config
      )

      dispatch({
        type: GET_RELEASED_OCCU_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: GET_RELEASED_OCCU_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// export const userApplicationRetrieveById = (id, payload) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: USER_APPLICATIONS_REQUEST_BYID })

//       const { data } = await axios.post(
//         `/userapplications/${id}`,
//         payload
//       )

//       dispatch({
//         type: USER_APPLICATIONS_SUCCESS_BYID,
//         payload: data.result,
//       })
//     } catch (error) {
//       dispatch({
//         type: USER_APPLICATIONS_FAILED_BYID,
//         payload: 'Application not found',
//       })
//     }
//   }
// }

// export const dcApplicationRetrieveByStatus = () => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: DC_APPLICATIONS_RETRIEVE_BYSTATUS_REQUEST,
//       })

//       const forpayment = await axios.get(
//         '/userapplications/verifying-payment'
//       )

//       const forcompliance = await axios.get(
//         '/userapplications/for-compliance'
//       )

//       const forscheduling = await axios.get(
//         '/userapplications/for-scheduling'
//       )

//       const approved = await axios.get(
//         '/userapplications/approved-application'
//       )

//       const rejected = await axios.get(
//         '/userapplications/rejected-application'
//       )

//       dispatch({
//         type: DC_APPLICATIONS_RETRIEVE_BYSTATUS,
//         payload: {
//           forPayment: forpayment.data.result,
//           forCompliance: forcompliance.data.result,
//           forScheduling: forscheduling.data.result,
//           Approved: approved.data.result,
//           Rejected: rejected.data.result,
//         },
//       })
//     } catch (error) {
//       dispatch({
//         type: USER_APPLICATIONS_RETRIEVE_BYSTATUS_FAILED,
//         payload: 'Unable to Retrieve Data',
//       })
//     }
//   }
// }

// Add Application
export const userApplicationInsert = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_INSERT_APPLICATION_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/add`,
        payload,
        config
      )

      dispatch({
        type: USER_INSERT_APPLICATION_SUCCESS,
        payload: data.message,
      })
    } catch (error) {
      dispatch({
        type: USER_INSERT_APPLICATION_FAILED,
        payload: 'Insert Application Incomplete',
      })
    }
  }
}

// Get Application By Status
// export const userApplicationRetrieveByStatus = (payload) => {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: USER_APPLICATIONS_RETRIEVE_BYSTATUS_REQUEST,
//       })

//       const config = {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${Cookie.get('userInfo')}`,
//         },
//       }

//       const forpayment = await axios.post(
//         '/userapplications/user-verifying-payment',
//         payload,
//         config
//       )

//       const forcompliance = await axios.post(
//         '/userapplications/user-for-compliance',
//         payload,
//         config
//       )

//       const forInspection = await axios.post(
//         '/userapplications/user-for-inspection',
//         payload,
//         config
//       )

//       const forApproved = await axios.post(
//         '/userapplications/user-approved-application',
//         payload,
//         config
//       )

//       dispatch({
//         type: USER_APPLICATIONS_RETRIEVE_BYSTATUS,
//         payload: {
//           forPayment: forpayment.data.result,
//           forCompliance: forcompliance.data.result,
//           forInspection: forInspection.data.result,
//           forApproved: forApproved.data.result,
//         },
//       })
//     } catch (error) {
//       dispatch({
//         type: USER_APPLICATIONS_RETRIEVE_BYSTATUS_FAILED,
//         payload: 'No Application Found',
//       })
//     }
//   }
// }

// GET APPLICATION FOR VERIFICATION
export const getApplicationForVerification = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_GETAPPLICATIONFORVERIFICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/user-verifying-payment`,
        payload,
        config
      )

      dispatch({
        type: USER_GETAPPLICATIONFORVERIFICATION_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: USER_GETAPPLICATIONFORVERIFICATION_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// GET APPLICATION FOR INSPECTION
export const getApplicationForInspection = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_GETAPPLICATIONFORINSPECTION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/user-for-inspection`,
        payload,
        config
      )

      dispatch({
        type: USER_GETAPPLICATIONFORINSPECTION_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: USER_GETAPPLICATIONFORINSPECTION_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// GET APPLICATION FOR COMPLIANCE
export const getApplicationForCompliance = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_GETAPPLICATIONFORCOMPLIANCE_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/user-for-compliance`,
        payload,
        config
      )

      dispatch({
        type: USER_GETAPPLICATIONFORCOMPLIANCE_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: USER_GETAPPLICATIONFORCOMPLIANCE_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// GET APPLICATION APPROVED
export const getApplicationApproved = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_GETAPPLICATIONAPPROVED_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/user-approved-application`,
        payload,
        config
      )

      dispatch({
        type: USER_GETAPPLICATIONAPPROVED_SUCCESS,
        payload: data.result,
      })
    } catch (err) {
      dispatch({
        type: USER_GETAPPLICATIONAPPROVED_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// Update Application
export const userUpdateApplication = (payload) => {
  return async (dispatch) => {
    // console.log(payload)
    try {
      dispatch({
        type: USER_APPLICATION_UPDATE_REQ,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/userapplications/update`,
        payload,
        config
      )

      dispatch({
        type: USER_APPLICATION_UPDATE_SUCCESS,
        payload: data.message,
      })
    } catch (error) {
      dispatch({
        type: USER_APPLICATION_UPDATE_FAILED,
        payload: 'Unable to update record',
      })
    }
  }
}

// Delete Application
export const userDeleteApplication = (payload, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_APPLICATION_DELETE_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/remove/${id}`,
        payload,
        config
      )

      dispatch({
        type: USER_APPLICATION_DELETE_SUCCESS,
        payload: data.message,
      })
    } catch (error) {
      dispatch({
        type: USER_APPLICATION_DELETE_FAILED,
        payload: 'Unable to Delete Application',
      })
    }
  }
}

// Get Application History
export const userRetrieveApplicationHistory = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_APPLICATION_HISTORY_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/history`,
        payload,
        config
      )

      dispatch({
        type: USER_APPLICATION_HISTORY_SUCCESS,
        payload: data.result,
      })
    } catch (error) {
      dispatch({
        type: USER_APPLICATION_HISTORY_FAILED,
        payload: 'No Application Found',
      })
    }
  }
}

// Get Application Status Track
export const RetrieveStatusTrack = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_APPSTATUSTRACK_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapplications/${payload.appID}`,
        payload,
        config
      )

      dispatch({
        type: USER_APPSTATUSTRACK_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: USER_APPSTATUSTRACK_FAILED,
        payload: 'No data Found',
      })
    }
  }
}

// Get List report
export const retrieveListReport = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_LIST_REPORT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/list-report/${payload.id}`,
        config
      )

      dispatch({
        type: GET_LIST_REPORT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_LIST_REPORT_FAILED,
        payload: 'No data Found',
      })
    }
  }
}

// Accept Eng Application
export const acceptEngApplication = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ACCEPT_ENGAPPLICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/registered-eng/accept-application`,
        payload,
        config
      )

      dispatch({
        type: ACCEPT_ENGAPPLICATION_SUCCESS,
        payload: data.result,
      })

      dispatch(
        retrieveEngAppList({ name: payload.name, taggedID: payload.userID })
      )
    } catch {
      dispatch({
        type: ACCEPT_ENGAPPLICATION_FAILED,
        payload: 'No data Found',
      })
    }
  }
}

// Decline Eng Application
export const declineEngApplication = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DECLINE_ENGAPPLICATION_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/registered-eng/decline-application`,
        payload,
        config
      )

      dispatch({
        type: DECLINE_ENGAPPLICATION_SUCCESS,
        payload: data.result,
      })

      dispatch(
        retrieveEngAppList({ name: payload.name, taggedID: payload.userID })
      )
    } catch {
      dispatch({
        type: DECLINE_ENGAPPLICATION_FAILED,
        payload: 'No data Found',
      })
    }
  }
}

// Update Eng Image Attached
export const updateEngAttachedImage = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ENGIMAGEATTACHED_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()

      Object.values(payload.images).forEach((element) => {
        formData.append('images', element)
      })
      formData.append('userID', payload.userID)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/registered-eng/update-image`,
        formData,
        config
      )

      dispatch({
        type: UPDATE_ENGIMAGEATTACHED_SUCCESS,
        payload: data.result,
      })

      dispatch(retrieveEngAttachments({ userID: payload.userID }))
      dispatch(retrieveEngrProfile({ userID: payload.userID }))
    } catch {
      dispatch({
        type: UPDATE_ENGIMAGEATTACHED_FAILED,
        payload: 'No data Found',
      })
    }
  }
}

// Update Recorded Engineer
export const updateRecordedEng = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_RECORDED_ENG_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/userapplications/update-recorded-eng`,
        payload,
        config
      )

      dispatch({
        type: UPDATE_RECORDED_ENG_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: UPDATE_RECORDED_ENG_FAILED,
        payload: 'Failed to update record',
      })
    }
  }
}

// POST PAYMENT INITIALIZATION
export const paymentInitialization = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PAYMENT_INITIALIZE_REQ })

      const config = {
        headers: {
          'X-MultiPay-Token': '2c1816316e65dbfcb0c34a25f3d6fe5589aef65d',
          'X-MultiPay-Code': 'MSYS_TEST_BILLER',
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `/payment-api/generate`,
        {
          amount: payload.amount,
          txnid: payload.txnid,
          digest: payload.digest,
          withCredentials: true,
        },
        config
      )

      dispatch({
        type: PAYMENT_INITIALIZE_SUCCESS,
        payload: data.data.url,
      })
    } catch {
      dispatch({
        type: PAYMENT_INITIALIZE_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}
