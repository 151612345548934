export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'

export const VERIFY_QR_REQ = 'VERIFY_QR_REQ'
export const VERIFY_QR_SUCCESS = 'VERIFY_QR_SUCCESS'
export const VERIFY_QR_FAILED = 'VERIFY_QR_FAILED'

export const USER_EMPLOYEELOGIN_REQUEST = 'USER_EMPLOYEELOGIN_REQUEST'
export const USER_EMPLOYEELOGIN_SUCCESS = 'USER_EMPLOYEELOGIN_SUCCESS'
export const USER_EMPLOYEELOGIN_FAILED = 'USER_EMPLOYEELOGIN_FAILED'

export const USER_ISAUTH_REQUEST = 'USER_ISAUTH_REQUEST'
export const USER_ISAUTH_SUCCESS = 'USER_ISAUTH_SUCCESS'
export const USER_ISAUTH_FAILED = 'USER_ISAUTH_FAILED'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED'

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED'

export const GET_ENGRPROFILE_REQUEST = 'GET_ENGRPROFILE_REQUEST'
export const GET_ENGRPROFILE_SUCCESS = 'GET_ENGRPROFILE_SUCCESS'
export const GET_ENGRPROFILE_FAILED = 'GET_ENGRPROFILE_FAILED'

export const GET_EMAIL_REQUEST = 'GET_EMAIL_REQUEST'
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS'
export const GET_EMAIL_FAILED = 'GET_EMAIL_FAILED'

export const GET_RESET_PASSWORD_REQUEST = 'GET_RESET_PASSWORD_REQUEST'
export const GET_RESET_PASSWORD_SUCCESS = 'GET_RESET_PASSWORD_SUCCESS'
export const GET_RESET_PASSWORD_FAILED = 'GET_RESET_PASSWORD_FAILED'

export const PUT_RESET_PASSWORD_REQUEST = 'PUT_RESET_PASSWORD_REQUEST'
export const PUT_RESET_PASSWORD_SUCCESS = 'PUT_RESET_PASSWORD_SUCCESS'
export const PUT_RESET_PASSWORD_FAILED = 'PUT_RESET_PASSWORD_FAILED'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const CREATE_ENGR_REQUEST = 'CREATE_ENGR_REQUEST'
export const CREATE_ENGR_SUCCESS = 'CREATE_ENGR_SUCCESS'
export const CREATE_ENGR_FAILED = 'CREATE_ENGR_FAILED'

export const GET_VERIFYPERMIT_REQUEST = 'GET_VERIFYPERMIT_REQUEST'
export const GET_VERIFYPERMIT_SUCCESS = 'GET_VERIFYPERMIT_SUCCESS'
export const GET_VERIFYPERMIT_FAILED = 'GET_VERIFYPERMIT_FAILED'

export const CREATE_PROFINFO_REQ = 'CREATE_PROFINFO_REQ'
export const CREATE_PROFINFO_SUCCESS = 'CREATE_PROFINFO_SUCCESS'
export const CREATE_PROFINFO_FAILED = 'CREATE_PROFINFO_FAILED'

export const DELETE_PROFINFO_REQ = 'DELETE_PROFINFO_REQ'
export const DELETE_PROFINFO_SUCCESS = 'DELETE_PROFINFO_SUCCESS'
export const DELETE_PROFINFO_FAILED = 'DELETE_PROFINFO_FAILED'

export const USER_RPTLOGIN_REQUEST = 'USER_RPTLOGIN_REQUEST'
export const USER_RPTLOGIN_SUCCESS = 'USER_RPTLOGIN_SUCCESS'
export const USER_RPTLOGIN_FAILED = 'USER_RPTLOGIN_FAILED'
