import { useState } from 'react'

export default function useToggle() {
  const [toggled, setToggled] = useState(false)

  const handleToggleSidebar = (value) => {
    setToggled(value)
  }

  return { toggled, setToggled, handleToggleSidebar }
}
