import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
  Accordion,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'

// Actions
import {
  addAssessment,
  editAssessment,
  retrieveAssessmentDetails,
} from '../../redux/actions/Assessment/electronicsAssessmentActions'
import { retrieveAppDetails } from '../../redux/actions/evaluatorActions'

const ElectronicsAssessmentScreen = ({ history, match }) => {
  // Declaration
  const dispatch = useDispatch()

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const evaluator = useSelector((state) => state.evaluator)

  const { getAppDetails } = evaluator

  const electronicsAssessment = useSelector(
    (state) => state.electronicsAssessment
  )

  const { assessmentDetails, createAssessment, updateAssessment } =
    electronicsAssessment

  const [obj, setObj] = useState([
    {
      id_per_name: 1,
      name: 'Central Office',
      fee: 2.4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 2,
      name: 'Broadcast Station',
      fee: 1000,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 3,
      name: 'Dispensing Machines',
      fee: 10,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 4,
      name: 'Outlets',
      fee: 2.4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 5,
      name: 'CCTV',
      fee: 2.4,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 6,
      name: 'Auditoriums',
      fee: 1000,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 7,
      name: 'Antenna Tower',
      fee: 1000,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 8,
      name: 'Electronic Signages',
      fee: 50,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 9,
      name: 'Per Pole',
      fee: 20,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 10,
      name: 'Per Attachment',
      fee: 20,
      units: 0,
      estimate: 0,
    },
    {
      id_per_name: 11,
      name: 'Other Electronics',
      fee: 50,
      units: 0,
      estimate: 0,
    },
  ])

  const [state, setState] = useState([])

  const [grandTotal, setGrandTotal] = useState(0)

  const [modal, setModal] = useState({
    successModal: false,
    updateModal: false,
  })

  const total = obj.reduce(
    (prevData, currData) => prevData + currData.estimate,
    0
  )

  const filtered = obj.filter((e) => e.estimate > 0)

  const isUpdating = JSON.parse(localStorage.getItem('assessmentSelected'))
    ? JSON.parse(localStorage.getItem('assessmentSelected'))
    : null
  // End Declaration

  // Functions
  const handleChange = (e) => {
    setObj((currObj) => {
      return currObj.map((el) => {
        if (el.name === e.target.name) {
          return {
            ...el,
            units: parseFloat(e.target.value),
            estimate: e.target.value * el.fee,
          }
        } else {
          return el
        }
      })
    })
  }

  const handleCreateSubmit = () => {
    setModal({ successModal: true })
    dispatch(
      addAssessment({
        grandTotal,
        assessments: state,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
      })
    )
  }

  const handleUpdateSubmit = () => {
    setModal({ updateModal: true })
    dispatch(
      editAssessment({
        grandTotal,
        assessments: filtered,
        evalAppID:
          getAppDetails &&
          getAppDetails.message &&
          getAppDetails.message[0].evalAppID,
        evaluatorID: userCredentials.id,
        appID: match.params.id,
      })
    )
  }

  const handleBtnCancel = () => {
    if (isUpdating !== null) {
      history.push('/assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    } else {
      history.push('/for-assessment-list')
      localStorage.removeItem('assessmentSelected')
      setState([])
      setGrandTotal(0)
    }
  }
  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(
      retrieveAppDetails({ appID: match.params.id, userID: userCredentials.id })
    )

    isUpdating !== null &&
      dispatch(
        retrieveAssessmentDetails({
          evalAppID: isUpdating.evalAppID,
          userID: userCredentials.id,
        })
      )
  }, [dispatch, match, userCredentials])

  useEffect(() => {
    setGrandTotal(() => parseInt(total * 100) / 100)

    setState(() => filtered)
  }, [total])

  useEffect(() => {
    if (isUpdating !== null) {
      if (assessmentDetails && assessmentDetails.message) {
        assessmentDetails.message.map((submitted) => {
          let temp = obj
          let index = obj.findIndex(
            (el) => el.id_per_name === submitted.id_per_name
          )
          temp[index].units = submitted.units
          temp[index].estimate = submitted.estimate
          temp[index] = { ...temp[index], id: submitted.id }
          setObj(temp)
        })
        setGrandTotal(() => assessmentDetails.message[0].grandTotal)
      }
    }
  }, [assessmentDetails])

  return (
    <UserMain>
      <Container className='bg-light pt-3 mb-3'>
        <>
          <h1 className='text-center mb-4'>ELECTRONIC FEES</h1>
          <Row>
            <Col
              xl={12}
              className='mb-4 pt-3'
              style={{ background: '#f9f6f1' }}
            >
              <h6>Note: </h6>
              <p>Click category to be assessed.</p>
            </Col>
          </Row>

          <Form
            onSubmit={(e) => {
              e.preventDefault()

              isUpdating !== null ? handleUpdateSubmit() : handleCreateSubmit()
            }}
          >
            <Accordion className='mx-0'>
              {/* A. */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='0'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  A. CENTRAL OFFICE SWITCHING EQUIPMENT
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-justify text-wrap' colSpan={2}>
                            Central Office switching equipment, remote switching
                            units, concentrators, PABX/PBX’s, cordless/ wireless
                            telephone and communication systems,
                            intercommunication system and other types of
                            switching/ routing/distribution equipment used for
                            voice, data image text, facsimile, internet service,
                            cellular, paging and other types/forms of wired or
                            wireless communications, per port
                          </td>
                          <td>₱2.40</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Central Office'
                              value={obj[0].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                              // onBlur={() => {
                              //   obj.ElexFees1A !== 0 &&
                              //     setObj({
                              //       ...obj,
                              //       ElexFees1A_Est: obj.ElexFees1A * 2.4,
                              //     })
                              // }}
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find((el) => el.name === 'Central Office')
                                  .estimate * 100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End A */}

              {/* B */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='1'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  B. BROADCAST / COMMUNICATION STATIONS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='1'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-justify text-wrap' colSpan={2}>
                            Broadcast station for radio and TV for both
                            commercial and training purposes, CATV headed,
                            transmitting/ receiving/relay radio and broadcasting
                            communications stations, communications centers,
                            switching centers, control centers, operation and/or
                            maintenance centers, call centers, cellsites,
                            equipment silos/shelters and other similar
                            locations/structures used for electronics and
                            communications services, including those used for
                            navigational aids, radar, telemetry, tests and
                            measurements, global positioning and
                            personnel/vehicle location, per location
                          </td>
                          <td>₱1000.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Broadcast Station'
                              value={obj[1].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find(
                                  (el) => el.name === 'Broadcast Station'
                                ).estimate * 100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End B */}

              {/* C */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='2'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  C. ELECTRONICS DISPENSING MACHINES
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='2'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Automated teller machines, ticketing, vending and
                            other types of electronic dispensing machines,
                            telephone booths, pay phones, coin changers,
                            location or direction-finding systems, navigational
                            equipment used for land, aeronautical or maritime
                            applications, photography and reproduction machines
                            x-ray, scanners, ultrasound and other
                            apparatus/equipment used for medical, biomedical,
                            laboratory and testing purposes and other similar
                            electronic or electronically- controlled apparatus
                            or devices, whether located indoor or outdoors per
                            unit
                          </td>
                          <td>₱10.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Dispensing Machines'
                              value={obj[2].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find(
                                  (el) => el.name === 'Dispensing Machines'
                                ).estimate * 100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End C */}

              {/* D */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='3'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  D. ELECTRONICS AND COMMUNICATION OUTLETS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='3'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Electronics and communications outlets used for
                            connection and termination of voice, data, computer
                            (including workstations, servers, routers, etc.),
                            audio, video, or any form of electronics and
                            communications services, irrespective of whether a
                            user terminal is connected, per outlet
                          </td>
                          <td>₱2.40</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Outlets'
                              value={obj[3].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find((el) => el.name === 'Outlets')
                                  .estimate * 100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End D */}

              {/* E */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='4'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  E. SECURITY AND ALARM SYSTEMS (CCTV) AND BGM
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Station/terminal/control point/port/central or
                            remote panels/outlets for security and alarm systems
                            (including watchman system, burglar alarms,
                            intrusion detection systems, lighting controls,
                            monitoring and surveillance system, sensors,
                            detectors, parking management system, barrier
                            controls, signal lights, etc.), electronics fire
                            alarm (including early-detection systems, smoke
                            detectors, etc.), sound-reinforcement/background,
                            music/paging/conference systems and the like,
                            CATV/MATV/CCTV and off-air television,
                            electronically-controlled conveyance systems,
                            building automation, management systems and similar
                            types of electronic or electronically-controlled
                            installations whether a user terminal is connected,{' '}
                            <em>per termination</em>
                          </td>
                          <td>₱2.40</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='CCTV'
                              value={obj[4].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find((el) => el.name === 'CCTV').estimate *
                                  100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End E */}

              {/* F */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='5'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  F. STUDIOS / AUDITORIUMS / THEATERS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='5'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Studios, auditoriums, theaters, and similar
                            structures for radio and TV broadcast, recording,
                            audio/video reproduction/simulation and similar
                            activities,
                            <em>per location</em>
                          </td>
                          <td>₱1000.00</td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Auditoriums'
                              value={obj[5].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find((el) => el.name === 'Auditoriums')
                                  .estimate * 100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End F */}

              {/* G */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='6'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  G. ANTENNA TOWER / MASTS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='6'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Antenna towers/masts or other structures for
                            installation of any electronic and/or communications
                            transmission/reception, <em>per structure</em>
                          </td>
                          <td>₱1,000.00 </td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Antenna Tower'
                              value={obj[6].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find((el) => el.name === 'Antenna Tower')
                                  .estimate * 100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End G */}

              {/* H */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='7'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  H. ELECTRONIC SIGNAGES
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='7'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Electronic or electronically-controlled indoor and
                            outdoor signages and display systems, including TV
                            monitors, multi-media signs, etc., <em>per unit</em>
                          </td>
                          <td>₱50.00 </td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Electronic Signages'
                              value={obj[7].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find(
                                  (el) => el.name === 'Electronic Signages'
                                ).estimate * 100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End H */}

              {/* I. Poles and Attachments */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='8'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  I. POLES AND ATTACHMENTS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='8'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Per pole (to be paid by pole owner)
                          </td>
                          <td>₱20.00 </td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Per Pole'
                              value={obj[8].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find((el) => el.name === 'Per Pole')
                                  .estimate * 100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>ii.</td>
                          <td className='text-wrap' colSpan={2}>
                            Per attachment (to be paid by any entity who
                            attaches to the pole of others)
                          </td>
                          <td>₱20.00 </td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Per Attachment'
                              value={obj[9].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find((el) => el.name === 'Per Attachment')
                                  .estimate * 100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End I */}

              {/* J*/}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey='9'
                  className='accordion-top'
                  style={{
                    background: '#4bbf73',
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  J. ELECTRONIC DEVICES NOT SPECIFIED ABOVE
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='9'>
                  <Card.Body className='accordion-description'>
                    <Table responsive className='mb-5'>
                      <thead>
                        <tr className='text-center'>
                          <th>#</th>
                          <th>PARTICULARS</th>
                          <th></th>
                          <th>FEE</th>
                          <th className='text-center pr-0'>UNIT</th>
                          <th>EST. FEE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-justify align-middle'>
                          <td className='align-middle'>i.</td>
                          <td className='text-wrap' colSpan={2}>
                            Other types of electronics or
                            electronically-controlled device, apparatus,
                            equipment, instrument or units not specifically
                            identified above, <em>per unit</em>
                          </td>
                          <td>₱50.00 </td>
                          <td>
                            <Form.Control
                              type='number'
                              className='border border-secondary align-middle'
                              style={{
                                marginLeft: '25px',
                                marginRight: '25px',
                              }}
                              name='Other Electronics'
                              value={obj[10].units}
                              required
                              min={0}
                              placeholder='Unit'
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                e.target.value.length > 14 && e.preventDefault()
                              }
                            />
                          </td>
                          <td className='align-middle'>
                            <p className='mb-0'>
                              ₱
                              {parseInt(
                                obj.find(
                                  (el) => el.name === 'Other Electronics'
                                ).estimate * 100
                              ) / 100}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* End J */}
            </Accordion>

            <Row className='mt-3 mx-1'>
              <Col xl={8} className='text-end align-middle mt-2'>
                <h4>GRAND TOTAL:</h4>
              </Col>
              <Form.Group as={Col} xl={4} className='mb-5'>
                <Form.Control
                  type='text'
                  className='border border-secondary'
                  style={{
                    marginLeft: '8px',
                    marginRight: '5px',
                  }}
                  name='GrandTotal'
                  value={parseInt(grandTotal * 100) / 100}
                  placeholder='Grand Total'
                  disabled
                />
              </Form.Group>
            </Row>
            <Row className='mb-4'>
              <Col xl={12} className='text-end'>
                <Button type='submit' variant='success'>
                  Submit
                </Button>
                <Button
                  variant='danger'
                  className='mx-2'
                  onClick={handleBtnCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      </Container>

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={createAssessment && createAssessment.loading}
        success={createAssessment && createAssessment.message}
        error={createAssessment && createAssessment.error}
      />
      {/* End Success Modal */}

      {/*Update Success Modal */}
      <SuccessModal
        show={modal.updateModal}
        onHide={() => {
          setModal({ updateModal: false })
          isUpdating !== null
            ? history.push('/assessment-list')
            : history.push('/for-assessment-list')
        }}
        transLoading={updateAssessment && updateAssessment.loading}
        success={updateAssessment && updateAssessment.message}
        error={updateAssessment && updateAssessment.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(ElectronicsAssessmentScreen)
