import {
  GET_EMPLOYEEDETAILS_REQ,
  GET_EMPLOYEEDETAILS_SUCCESS,
  GET_EMPLOYEEDETAILS_FAILED,
  GET_ALLAPPLICATIONS_REQ,
  GET_ALLAPPLICATIONS_SUCCESS,
  GET_ALLAPPLICATIONS_FAILED,
  GET_FILTEREDAPPLICATION_REQ,
  GET_FILTEREDAPPLICATION_SUCCESS,
  GET_FILTEREDAPPLICATION_FAILED,
  GET_APPCOMMENTS_REQ,
  GET_APPCOMMENTS_SUCCESS,
  GET_APPCOMMENTS_FAILED,
  GET_APPLICATIONINFORMATION_REQ,
  GET_APPLICATIONINFORMATION_SUCCESS,
  GET_APPLICATIONINFORMATION_FAILED,
  GET_REQUIREMENTLIST_REQ,
  GET_REQUIREMENTLIST_SUCCESS,
  GET_REQUIREMENTLIST_FAILED,
  GET_SUBMITTEDREQUIREMENTS_REQ,
  GET_SUBMITTEDREQUIREMENTS_SUCCESS,
  GET_SUBMITTEDREQUIREMENTS_FAILED,
  GET_TRADEEVALUATORS_REQ,
  GET_TRADEEVALUATORS_SUCCESS,
  GET_TRADEEVALUATORS_FAILED,
  GET_INSPECTORS_REQ,
  GET_INSPECTORS_SUCCESS,
  GET_INSPECTORS_FAILED,
  GET_SUBMITTEDEVALUATORS_REQ,
  GET_SUBMITTEDEVALUATORS_SUCCESS,
  GET_SUBMITTEDEVALUATORS_FAILED,
  GET_SUBMITTEDINSPECTOR_REQ,
  GET_SUBMITTEDINSPECTOR_SUCCESS,
  GET_SUBMITTEDINSPECTOR_FAILED,
  GET_ENGINEERINFORMATION_REQ,
  GET_ENGINEERINFORMATION_SUCCESS,
  GET_ENGINEERINFORMATION_FAILED,
  GET_ORDERPAYMENT_REQ,
  GET_ORDERPAYMENT_SUCCESS,
  GET_ORDERPAYMENT_FAILED,
  GET_AMOUNTPAID_REQ,
  GET_AMOUNTPAID_SUCCESS,
  GET_AMOUNTPAID_FAILED,
  GET_COLLECTIONREPORT_REQ,
  GET_COLLECTIONREPORT_SUCCESS,
  GET_COLLECTIONREPORT_FAILED,
  GET_CREATEDAPP_REQ,
  GET_CREATEDAPP_SUCCESS,
  GET_CREATEDAPP_FAILED,
  GET_GUEST_APPLICATION_REQ,
  GET_GUEST_APPLICATION_SUCCESS,
  GET_GUEST_APPLICATION_FAILED,
  GET_GUEST_APPLICATION_BYID_REQ,
  GET_GUEST_APPLICATION_BYID_SUCCESS,
  GET_GUEST_APPLICATION_BYID_FAILED,
  GET_CFEI_REQ,
  GET_CFEI_SUCCESS,
  GET_CFEI_FAILED,
  GET_CONTRACTORSTAX_REQ,
  GET_CONTRACTORSTAX_SUCCESS,
  GET_CONTRACTORSTAX_FAILED,
  GET_APPSTATUSTRACKING_REQ,
  GET_APPSTATUSTRACKING_SUCCESS,
  GET_APPSTATUSTRACKING_FAILED,
  GET_RPTVERIFICATION_REQ,
  GET_RPTVERIFICATION_SUCCESS,
  GET_RPTVERIFICATION_FAILED,
  GET_CTVERIFICATION_REQ,
  GET_CTVERIFICATION_SUCCESS,
  GET_CTVERIFICATION_FAILED,
  UPDATE_APPSTATUS_REQ,
  UPDATE_APPSTATUS_SUCCESS,
  UPDATE_APPSTATUS_FAILED,
  UPDATE_RELEASEPERMIT_REQ,
  UPDATE_RELEASEPERMIT_SUCCESS,
  UPDATE_RELEASEPERMIT_FAILED,
  UPDATE_EMPLOYEEDETAILS_REQ,
  UPDATE_EMPLOYEEDETAILS_SUCCESS,
  UPDATE_EMPLOYEEDETAILS_FAILED,
  UPDATE_EMPLOYEECREDENTIALS_REQ,
  UPDATE_EMPLOYEECREDENTIALS_SUCCESS,
  UPDATE_EMPLOYEECREDENTIALS_FAILED,
  UPDATE_APPINFORMATION_REQ,
  UPDATE_APPINFORMATION_SUCCESS,
  UPDATE_APPINFORMATION_FAILED,
  UPDATE_SUBMITTEDREQUIREMENTS_REQ,
  UPDATE_SUBMITTEDREQUIREMENTS_SUCCESS,
  UPDATE_SUBMITTEDREQUIREMENTS_FAILED,
  UPDATE_ENGINEERINFORMATION_REQ,
  UPDATE_ENGINEERINFORMATION_SUCCESS,
  UPDATE_ENGINEERINFORMATION_FAILED,
  UPDATE_EVALUATORS_REQ,
  UPDATE_EVALUATORS_SUCCESS,
  UPDATE_EVALUATORS_FAILED,
  UPDATE_INSPECTOR_REQ,
  UPDATE_INSPECTOR_SUCCESS,
  UPDATE_INSPECTOR_FAILED,
  UPDATE_ORDEROFPAYMENT_REQ,
  UPDATE_ORDEROFPAYMENT_SUCCESS,
  UPDATE_ORDEROFPAYMENT_FAILED,
  UPDATE_GUEST_APPLICATION_REQ,
  UPDATE_GUEST_APPLICATION_SUCCESS,
  UPDATE_GUEST_APPLICATION_FAILED,
  UPDATE_CFEI_REQ,
  UPDATE_CFEI_SUCCESS,
  UPDATE_CFEI_FAILED,
  UPDATE_CONTRACTORSTAX_REQ,
  UPDATE_CONTRACTORSTAX_SUCCESS,
  UPDATE_CONTRACTORSTAX_FAILED,
  INSERT_APPLICATION_REQ,
  INSERT_APPLICATION_SUCCESS,
  INSERT_APPLICATION_FAILED,
  INSERT_APPINFO_REQ,
  INSERT_APPINFO_SUCCESS,
  INSERT_APPINFO_FAILED,
  INSERT_ORDEROFPAYMENT_REQ,
  INSERT_ORDEROFPAYMENT_SUCCESS,
  INSERT_ORDEROFPAYMENT_FAILED,
  INSERT_NEWSANDUPDATES_REQ,
  INSERT_NEWSANDUPDATES_SUCCESS,
  INSERT_NEWSANDUPDATES_FAILED,
  INSERT_EVALUATIONSHEET_REQ,
  INSERT_EVALUATIONSHEET_SUCCESS,
  INSERT_EVALUATIONSHEET_FAILED,
  INSERT_GUEST_APPLICATION_REQ,
  INSERT_GUEST_APPLICATION_SUCCESS,
  INSERT_GUEST_APPLICATION_FAILED,
  INSERT_CFEI_REQ,
  INSERT_CFEI_SUCCESS,
  INSERT_CFEI_FAILED,
  GET_EVALUATIONSHEET_REQ,
  GET_EVALUATIONSHEET_SUCCESS,
  GET_EVALUATIONSHEET_FAILED,
  DELETE_EVALUATIONSHEET_REQ,
  DELETE_EVALUATIONSHEET_SUCCESS,
  DELETE_EVALUATIONSHEET_FAILED,
  DELETE_GUEST_APPLICATION_REQ,
  DELETE_GUEST_APPLICATION_SUCCESS,
  DELETE_GUEST_APPLICATION_FAILED,
  DELETE_CFEI_REQ,
  DELETE_CFEI_SUCCESS,
  DELETE_CFEI_FAILED,
  GET_RPTLOGIN_REQ,
  GET_RPTLOGIN_SUCCESS,
  GET_RPTLOGIN_FAILED,
  GET_CTLOGIN_REQ,
  GET_CTLOGIN_SUCCESS,
  GET_CTLOGIN_FAILED,
} from '../../constants/DocumentControllers/documentControllerConstants'

export const documentControllerReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_EMPLOYEEDETAILS_REQ:
      return {
        ...state,
        employeeDetails: { loading: true },
      }
    case GET_EMPLOYEEDETAILS_SUCCESS:
      return {
        ...state,
        employeeDetails: { loading: false, message: action.payload },
      }
    case GET_EMPLOYEEDETAILS_FAILED:
      return {
        ...state,
        employeeDetails: { loading: false, error: action.payload },
      }
    case GET_ALLAPPLICATIONS_REQ:
      return {
        ...state,
        allApplications: { loading: true },
      }
    case GET_ALLAPPLICATIONS_SUCCESS:
      return {
        ...state,
        allApplications: { loading: false, message: action.payload },
      }
    case GET_ALLAPPLICATIONS_FAILED:
      return {
        ...state,
        allApplications: { loading: false, error: action.payload },
      }
    case GET_FILTEREDAPPLICATION_REQ:
      return {
        ...state,
        filteredApplication: { loading: true },
      }
    case GET_FILTEREDAPPLICATION_SUCCESS:
      return {
        ...state,
        filteredApplication: { loading: false, message: action.payload },
      }
    case GET_FILTEREDAPPLICATION_FAILED:
      return {
        ...state,
        filteredApplication: { loading: false, error: action.payload },
      }
    case GET_APPCOMMENTS_REQ:
      return {
        ...state,
        applicationComments: { loading: true },
      }
    case GET_APPCOMMENTS_SUCCESS:
      return {
        ...state,
        applicationComments: { loading: false, message: action.payload },
      }
    case GET_APPCOMMENTS_FAILED:
      return {
        ...state,
        applicationComments: { loading: false, error: action.payload },
      }
    case GET_APPLICATIONINFORMATION_REQ:
      return {
        ...state,
        applicationInformation: { loading: true },
      }
    case GET_APPLICATIONINFORMATION_SUCCESS:
      return {
        ...state,
        applicationInformation: { loading: false, message: action.payload },
      }
    case GET_APPLICATIONINFORMATION_FAILED:
      return {
        ...state,
        applicationInformation: { loading: false, error: action.payload },
      }
    case GET_REQUIREMENTLIST_REQ:
      return {
        ...state,
        requirementList: { loading: true },
      }
    case GET_REQUIREMENTLIST_SUCCESS:
      return {
        ...state,
        requirementList: { loading: false, message: action.payload },
      }
    case GET_REQUIREMENTLIST_FAILED:
      return {
        ...state,
        requirementList: { loading: false, error: action.payload },
      }
    case GET_SUBMITTEDREQUIREMENTS_REQ:
      return {
        ...state,
        submittedRequirements: { loading: true },
      }
    case GET_SUBMITTEDREQUIREMENTS_SUCCESS:
      return {
        ...state,
        submittedRequirements: { loading: false, message: action.payload },
      }
    case GET_SUBMITTEDREQUIREMENTS_FAILED:
      return {
        ...state,
        submittedRequirements: { loading: false, error: action.payload },
      }
    case GET_TRADEEVALUATORS_REQ:
      return {
        ...state,
        evaluatorList: { loading: true },
      }
    case GET_TRADEEVALUATORS_SUCCESS:
      return {
        ...state,
        evaluatorList: { loading: false, message: action.payload },
      }
    case GET_TRADEEVALUATORS_FAILED:
      return {
        ...state,
        evaluatorList: { loading: false, error: action.payload },
      }
    case GET_INSPECTORS_REQ:
      return {
        ...state,
        inspectionList: { loading: true },
      }
    case GET_INSPECTORS_SUCCESS:
      return {
        ...state,
        inspectionList: { loading: false, message: action.payload },
      }
    case GET_INSPECTORS_FAILED:
      return {
        ...state,
        inspectionList: { loading: false, error: action.payload },
      }
    case GET_SUBMITTEDEVALUATORS_REQ:
      return {
        ...state,
        submittedEvaluatorList: { loading: true },
      }
    case GET_SUBMITTEDEVALUATORS_SUCCESS:
      return {
        ...state,
        submittedEvaluatorList: { loading: false, message: action.payload },
      }
    case GET_SUBMITTEDEVALUATORS_FAILED:
      return {
        ...state,
        submittedEvaluatorList: { loading: false, error: action.payload },
      }
    case GET_SUBMITTEDINSPECTOR_REQ:
      return {
        ...state,
        submittedInspector: { loading: true },
      }
    case GET_SUBMITTEDINSPECTOR_SUCCESS:
      return {
        ...state,
        submittedInspector: { loading: false, message: action.payload },
      }
    case GET_SUBMITTEDINSPECTOR_FAILED:
      return {
        ...state,
        submittedInspector: { loading: false, error: action.payload },
      }
    case GET_ENGINEERINFORMATION_REQ:
      return {
        ...state,
        submittedEngineers: { loading: true },
      }
    case GET_ENGINEERINFORMATION_SUCCESS:
      return {
        ...state,
        submittedEngineers: { loading: false, message: action.payload },
      }
    case GET_ENGINEERINFORMATION_FAILED:
      return {
        ...state,
        submittedEngineers: { loading: false, error: action.payload },
      }
    case GET_ORDERPAYMENT_REQ:
      return {
        ...state,
        submittedOrderofPayment: { loading: true },
      }
    case GET_ORDERPAYMENT_SUCCESS:
      return {
        ...state,
        submittedOrderofPayment: { loading: false, message: action.payload },
      }
    case GET_ORDERPAYMENT_FAILED:
      return {
        ...state,
        submittedOrderofPayment: { loading: false, error: action.payload },
      }
    case GET_AMOUNTPAID_REQ:
      return {
        ...state,
        paidAmountList: { loading: true },
      }
    case GET_AMOUNTPAID_SUCCESS:
      return {
        ...state,
        paidAmountList: { loading: false, message: action.payload },
      }
    case GET_AMOUNTPAID_FAILED:
      return {
        ...state,
        paidAmountList: { loading: false, error: action.payload },
      }
    case GET_EVALUATIONSHEET_REQ:
      return {
        ...state,
        retrievedEvaluationSheet: { loading: true },
      }
    case GET_EVALUATIONSHEET_SUCCESS:
      return {
        ...state,
        retrievedEvaluationSheet: { loading: false, message: action.payload },
      }
    case GET_EVALUATIONSHEET_FAILED:
      return {
        ...state,
        retrievedEvaluationSheet: { loading: false, error: action.payload },
      }
    case GET_COLLECTIONREPORT_REQ:
      return {
        ...state,
        retrievedCollectionReport: { loading: true },
      }
    case GET_COLLECTIONREPORT_SUCCESS:
      return {
        ...state,
        retrievedCollectionReport: { loading: false, message: action.payload },
      }
    case GET_COLLECTIONREPORT_FAILED:
      return {
        ...state,
        retrievedCollectionReport: { loading: false, error: action.payload },
      }
    case GET_CREATEDAPP_REQ:
      return {
        ...state,
        retrievedCreatedApp: { loading: true },
      }
    case GET_CREATEDAPP_SUCCESS:
      return {
        ...state,
        retrievedCreatedApp: { loading: false, message: action.payload },
      }
    case GET_CREATEDAPP_FAILED:
      return {
        ...state,
        retrievedCreatedApp: { loading: false, error: action.payload },
      }
    case GET_GUEST_APPLICATION_REQ:
      return {
        ...state,
        retrievedGuestApplication: { loading: true },
      }
    case GET_GUEST_APPLICATION_SUCCESS:
      return {
        ...state,
        retrievedGuestApplication: { loading: false, message: action.payload },
      }
    case GET_GUEST_APPLICATION_FAILED:
      return {
        ...state,
        retrievedGuestApplication: { loading: false, error: action.payload },
      }
    case GET_GUEST_APPLICATION_BYID_REQ:
      return {
        ...state,
        retrievedGuestApplicationByID: { loading: true },
      }
    case GET_GUEST_APPLICATION_BYID_SUCCESS:
      return {
        ...state,
        retrievedGuestApplicationByID: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_GUEST_APPLICATION_BYID_FAILED:
      return {
        ...state,
        retrievedGuestApplicationByID: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_CFEI_REQ:
      return {
        ...state,
        retrievedCFEI: { loading: true },
      }
    case GET_CFEI_SUCCESS:
      return {
        ...state,
        retrievedCFEI: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_CFEI_FAILED:
      return {
        ...state,
        retrievedCFEI: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_CONTRACTORSTAX_REQ:
      return {
        ...state,
        retrievedContractorsTax: { loading: true },
      }
    case GET_CONTRACTORSTAX_SUCCESS:
      return {
        ...state,
        retrievedContractorsTax: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_CONTRACTORSTAX_FAILED:
      return {
        ...state,
        retrievedContractorsTax: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_APPSTATUSTRACKING_REQ:
      return {
        ...state,
        retrievedAppStatTracking: { loading: true },
      }
    case GET_APPSTATUSTRACKING_SUCCESS:
      return {
        ...state,
        retrievedAppStatTracking: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_APPSTATUSTRACKING_FAILED:
      return {
        ...state,
        retrievedAppStatTracking: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_RPTVERIFICATION_REQ:
      return {
        ...state,
        retrievedRPT: { loading: true },
      }
    case GET_RPTVERIFICATION_SUCCESS:
      return {
        ...state,
        retrievedRPT: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_RPTVERIFICATION_FAILED:
      return {
        ...state,
        retrievedRPT: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_CTVERIFICATION_REQ:
      return {
        ...state,
        retrievedCT: { loading: true },
      }
    case GET_CTVERIFICATION_SUCCESS:
      return {
        ...state,
        retrievedCT: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_CTVERIFICATION_FAILED:
      return {
        ...state,
        retrievedCT: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_CTLOGIN_REQ:
      return {
        ...state,
        ctAccount: { loading: true },
      }
    case GET_CTLOGIN_SUCCESS:
      return {
        ...state,
        ctAccount: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_CTLOGIN_FAILED:
      return {
        ...state,
        ctAccount: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_RPTLOGIN_REQ:
      return {
        ...state,
        rptAccount: { loading: true },
      }
    case GET_RPTLOGIN_SUCCESS:
      return {
        ...state,
        rptAccount: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_RPTLOGIN_FAILED:
      return {
        ...state,
        rptAccount: {
          loading: false,
          error: action.payload,
        },
      }
    case UPDATE_APPSTATUS_REQ:
      return {
        ...state,
        updatedStatus: { loading: true },
      }
    case UPDATE_APPSTATUS_SUCCESS:
      return {
        ...state,
        updatedStatus: { loading: false, message: action.payload },
      }
    case UPDATE_APPSTATUS_FAILED:
      return {
        ...state,
        updatedStatus: { loading: false, error: action.payload },
      }
    case UPDATE_RELEASEPERMIT_REQ:
      return {
        ...state,
        releasedPermit: { loading: true },
      }
    case UPDATE_RELEASEPERMIT_SUCCESS:
      return {
        ...state,
        releasedPermit: { loading: false, message: action.payload },
      }
    case UPDATE_RELEASEPERMIT_FAILED:
      return {
        ...state,
        releasedPermit: { loading: false, error: action.payload },
      }
    case UPDATE_EMPLOYEEDETAILS_REQ:
      return {
        ...state,
        updatedEmployeeDetails: { loading: true },
      }
    case UPDATE_EMPLOYEEDETAILS_SUCCESS:
      return {
        ...state,
        updatedEmployeeDetails: { loading: false, message: action.payload },
      }
    case UPDATE_EMPLOYEEDETAILS_FAILED:
      return {
        ...state,
        updatedEmployeeDetails: { loading: false, error: action.payload },
      }
    case UPDATE_EMPLOYEECREDENTIALS_REQ:
      return {
        ...state,
        updateEmployeeCredentials: { loading: true },
      }
    case UPDATE_EMPLOYEECREDENTIALS_SUCCESS:
      return {
        ...state,
        updateEmployeeCredentials: { loading: false, message: action.payload },
      }
    case UPDATE_EMPLOYEECREDENTIALS_FAILED:
      return {
        ...state,
        updateEmployeeCredentials: { loading: false, error: action.payload },
      }
    case UPDATE_APPINFORMATION_REQ:
      return {
        ...state,
        updatedApplicationInfo: { loading: true },
      }
    case UPDATE_APPINFORMATION_SUCCESS:
      return {
        ...state,
        updatedApplicationInfo: { loading: false, message: action.payload },
      }
    case UPDATE_APPINFORMATION_FAILED:
      return {
        ...state,
        updatedApplicationInfo: { loading: false, error: action.payload },
      }
    case UPDATE_SUBMITTEDREQUIREMENTS_REQ:
      return {
        ...state,
        updateRequirements: { loading: true },
      }
    case UPDATE_SUBMITTEDREQUIREMENTS_SUCCESS:
      return {
        ...state,
        updateRequirements: { loading: false, message: action.payload },
      }
    case UPDATE_SUBMITTEDREQUIREMENTS_FAILED:
      return {
        ...state,
        updateRequirements: { loading: false, error: action.payload },
      }
    case UPDATE_ENGINEERINFORMATION_REQ:
      return {
        ...state,
        updatedEngineerInformation: { loading: true },
      }
    case UPDATE_ENGINEERINFORMATION_SUCCESS:
      return {
        ...state,
        updatedEngineerInformation: { loading: false, message: action.payload },
      }
    case UPDATE_ENGINEERINFORMATION_FAILED:
      return {
        ...state,
        updatedEngineerInformation: { loading: false, error: action.payload },
      }
    case UPDATE_EVALUATORS_REQ:
      return {
        ...state,
        updatedEvaluators: { loading: true },
      }
    case UPDATE_EVALUATORS_SUCCESS:
      return {
        ...state,
        updatedEvaluators: { loading: false, message: action.payload },
      }
    case UPDATE_EVALUATORS_FAILED:
      return {
        ...state,
        updatedEvaluators: { loading: false, error: action.payload },
      }
    case UPDATE_INSPECTOR_REQ:
      return {
        ...state,
        updatedInspector: { loading: true },
      }
    case UPDATE_INSPECTOR_SUCCESS:
      return {
        ...state,
        updatedInspector: { loading: false, message: action.payload },
      }
    case UPDATE_INSPECTOR_FAILED:
      return {
        ...state,
        updatedInspector: { loading: false, error: action.payload },
      }
    case UPDATE_ORDEROFPAYMENT_REQ:
      return {
        ...state,
        updatedOrderOfPayment: { loading: true },
      }
    case UPDATE_ORDEROFPAYMENT_SUCCESS:
      return {
        ...state,
        updatedOrderOfPayment: { loading: false, message: action.payload },
      }
    case UPDATE_ORDEROFPAYMENT_FAILED:
      return {
        ...state,
        updatedOrderOfPayment: { loading: false, error: action.payload },
      }
    case UPDATE_GUEST_APPLICATION_REQ:
      return {
        ...state,
        updatedGuestApplication: { loading: true },
      }
    case UPDATE_GUEST_APPLICATION_SUCCESS:
      return {
        ...state,
        updatedGuestApplication: { loading: false, message: action.payload },
      }
    case UPDATE_GUEST_APPLICATION_FAILED:
      return {
        ...state,
        updatedGuestApplication: { loading: false, error: action.payload },
      }
    case UPDATE_CFEI_REQ:
      return {
        ...state,
        updatedCFEI: { loading: true },
      }
    case UPDATE_CFEI_SUCCESS:
      return {
        ...state,
        updatedCFEI: { loading: false, message: action.payload },
      }
    case UPDATE_CFEI_FAILED:
      return {
        ...state,
        updatedCFEI: { loading: false, error: action.payload },
      }
    case UPDATE_CONTRACTORSTAX_REQ:
      return {
        ...state,
        updatedContractorsTax: { loading: true },
      }
    case UPDATE_CONTRACTORSTAX_SUCCESS:
      return {
        ...state,
        updatedContractorsTax: { loading: false, message: action.payload },
      }
    case UPDATE_CONTRACTORSTAX_FAILED:
      return {
        ...state,
        updatedContractorsTax: { loading: false, error: action.payload },
      }
    case INSERT_APPINFO_REQ:
      return {
        ...state,
        insertedApplicationInfo: { loading: true },
      }
    case INSERT_APPINFO_SUCCESS:
      return {
        ...state,
        insertedApplicationInfo: { loading: false, message: action.payload },
      }
    case INSERT_APPINFO_FAILED:
      return {
        ...state,
        insertedApplicationInfo: { loading: false, error: action.payload },
      }
    case INSERT_APPLICATION_REQ:
      return {
        ...state,
        insertedApplication: { loading: true },
      }
    case INSERT_APPLICATION_SUCCESS:
      return {
        ...state,
        insertedApplication: { loading: false, message: action.payload },
      }
    case INSERT_APPLICATION_FAILED:
      return {
        ...state,
        insertedApplication: { loading: false, error: action.payload },
      }
    case INSERT_ORDEROFPAYMENT_REQ:
      return {
        ...state,
        insertedOrderPayment: { loading: true },
      }
    case INSERT_ORDEROFPAYMENT_SUCCESS:
      return {
        ...state,
        insertedOrderPayment: { loading: false, message: action.payload },
      }
    case INSERT_ORDEROFPAYMENT_FAILED:
      return {
        ...state,
        insertedOrderPayment: { loading: false, error: action.payload },
      }
    case INSERT_NEWSANDUPDATES_REQ:
      return {
        ...state,
        insertedNewsandUpdate: { loading: true },
      }
    case INSERT_NEWSANDUPDATES_SUCCESS:
      return {
        ...state,
        insertedNewsandUpdate: { loading: false, message: action.payload },
      }
    case INSERT_NEWSANDUPDATES_FAILED:
      return {
        ...state,
        insertedNewsandUpdate: { loading: false, error: action.payload },
      }
    case INSERT_EVALUATIONSHEET_REQ:
      return {
        ...state,
        insertedEvaluationSheet: { loading: true },
      }
    case INSERT_EVALUATIONSHEET_SUCCESS:
      return {
        ...state,
        insertedEvaluationSheet: { loading: false, message: action.payload },
      }
    case INSERT_EVALUATIONSHEET_FAILED:
      return {
        ...state,
        insertedEvaluationSheet: { loading: false, error: action.payload },
      }
    case INSERT_GUEST_APPLICATION_REQ:
      return {
        ...state,
        insertedGuestApplication: { loading: true },
      }
    case INSERT_GUEST_APPLICATION_SUCCESS:
      return {
        ...state,
        insertedGuestApplication: { loading: false, message: action.payload },
      }
    case INSERT_GUEST_APPLICATION_FAILED:
      return {
        ...state,
        insertedGuestApplication: { loading: false, error: action.payload },
      }
    case INSERT_CFEI_REQ:
      return {
        ...state,
        insertedCFEI: { loading: true },
      }
    case INSERT_CFEI_SUCCESS:
      return {
        ...state,
        insertedCFEI: { loading: false, message: action.payload },
      }
    case INSERT_CFEI_FAILED:
      return {
        ...state,
        insertedCFEI: { loading: false, error: action.payload },
      }
    case DELETE_EVALUATIONSHEET_REQ:
      return {
        ...state,
        deletedEvaluationSheet: { loading: true },
      }
    case DELETE_EVALUATIONSHEET_SUCCESS:
      return {
        ...state,
        deletedEvaluationSheet: { loading: false, message: action.payload },
      }
    case DELETE_EVALUATIONSHEET_FAILED:
      return {
        ...state,
        deletedEvaluationSheet: { loading: false, error: action.payload },
      }
    case DELETE_GUEST_APPLICATION_REQ:
      return {
        ...state,
        deletedGuesApplication: { loading: true },
      }
    case DELETE_GUEST_APPLICATION_SUCCESS:
      return {
        ...state,
        deletedGuesApplication: { loading: false, message: action.payload },
      }
    case DELETE_GUEST_APPLICATION_FAILED:
      return {
        ...state,
        deletedGuesApplication: { loading: false, error: action.payload },
      }
    case DELETE_CFEI_REQ:
      return {
        ...state,
        deletedCFEI: { loading: true },
      }
    case DELETE_CFEI_SUCCESS:
      return {
        ...state,
        deletedCFEI: { loading: false, message: action.payload },
      }
    case DELETE_CFEI_FAILED:
      return {
        ...state,
        deletedCFEI: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
