export const GET_PLUMBING_EVALUATION_DETAILS_REQ =
  'GET_PLUMBING_EVALUATION_DETAILS_REQ'
export const GET_PLUMBING_EVALUATION_DETAILS_SUCCESS =
  'GET_PLUMBING_EVALUATION_DETAILS_SUCCESS'
export const GET_PLUMBING_EVALUATION_DETAILS_FAILED =
  'GET_PLUMBING_EVALUATION_DETAILS_FAILED'

export const CREATE_PLUMBING_EVALUATION_REQ = 'CREATE_PLUMBING_EVALUATION_REQ'
export const CREATE_PLUMBING_EVALUATION_SUCCESS =
  'CREATE_PLUMBING_EVALUATION_SUCCESS'
export const CREATE_PLUMBING_EVALUATION_FAILED =
  'CREATE_PLUMBING_EVALUATION_FAILED'

export const UPDATE_PLUMBING_EVALUATION_REQ = 'UPDATE_PLUMBING_EVALUATION_REQ'
export const UPDATE_PLUMBING_EVALUATION_SUCCESS =
  'UPDATE_PLUMBING_EVALUATION_SUCCESS'
export const UPDATE_PLUMBING_EVALUATION_FAILED =
  'UPDATE_PLUMBING_EVALUATION_FAILED'
