import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const GuestFooter = () => {
  return (
    <footer className='footer mt-auto'>
      <Container>
        <Row>
          <Col className='text-center py-3'>
            &copy; OFFICE OF THE CITY ENGINEER
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default GuestFooter
