import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'

const AddEditEvaluationSheet = ({
  show,
  onHide,
  handleSubmitEvaluationSheet,
  setEvaluationSheet,
  evaluationSheet,
}) => {
  return (
    <Modal size='md' show={show} onHide={onHide} centered backdrop='static'>
      <Modal.Header style={{ background: '#4bbf73' }}>
        <Modal.Title style={{ color: '#FFF' }}>
          <FaCheckCircle className='mx-1' /> Add / Edit Evaluation Sheet
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmitEvaluationSheet}>
        <Modal.Body>
          <Form.Control
            onChange={(e) => setEvaluationSheet(e)}
            as='textarea'
            placeholder='input remarks here ...'
            style={{
              background: '#FFF',
              border: '1px solid #ced4da',
            }}
            value={evaluationSheet.remarks}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' variant='info' size='sm'>
            Submit
          </Button>
          <Button variant='danger' size='sm' onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditEvaluationSheet
