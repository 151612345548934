import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Button,
  Pagination,
  InputGroup,
  Card,
  Spinner,
  Modal,
  ListGroup,
} from 'react-bootstrap'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import {
  FaSearch,
  FaFileAlt,
  FaTrashAlt,
  FaCheck,
  FaEllipsisH,
} from 'react-icons/fa'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import CommentsCard from '../../reusable/CommentsCard'
import SuccessModal from '../../reusable/SuccessModal'
import ViewAppInfo from '../../reusable/ViewAppInfo'

// Actions
import {
  retrieveSchedApplication,
  retrieveListReport,
  retrieveReport,
  retrieveCommentList,
  insertComment,
  retrieveAppInfo,
} from '../../redux/actions/inspectionActions'
import { retrieveDateApplied } from '../../redux/actions/adminApplicationActions'

const ForInsPectionList = ({ history, match }) => {
  // Declaration
  const [state, setState] = useState([])

  const [searchTerm, setSearchTerm] = useState('')

  const dispatch = useDispatch()

  const inspection = useSelector((state) => state.inspection)

  const {
    forInspection,
    listReport,
    commentList,
    createdComment,
    retrievedAppInfo,
  } = inspection

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const adminApplication = useSelector((state) => state.adminApplication)

  const { dateApplied } = adminApplication

  const [modal, setModal] = useState({
    viewApplication: false,
    viewManager: false,
    viewComments: false,
    successComment: false,
    applicationViewing: false,
  })

  const [payload, setPayload] = useState({})

  const [commentDetails, setCommentDetails] = useState({
    commentToName: '',
    commentTo: '',
    comments: '',
  })

  // End Declaration

  // Pagination Area Forms
  const [dfpaginatedForms, setdfpaginatedForms] = useState([])
  const [dfcurrentPage, setdfcurrentPage] = useState(1)

  const [dfpageNumberLimit] = useState(10)
  const [maxdfPageNumberLimit, setmaxdfPageNumberLimit] = useState(5)
  const [mindfPageNumberLimit, setmindfPageNumberLimit] = useState(0)

  const dfpageSize = 10

  useEffect(() => {
    setdfpaginatedForms(_(_.reverse(state)).slice(0).take(dfpageSize).value())
    setdfcurrentPage(1)
  }, [state])

  const dfpageCount = state ? Math.ceil(state.length / dfpageSize) : 0

  const dfpages = _.range(1, dfpageCount + 1)

  const dfpagination = (pageNo) => {
    setdfcurrentPage(pageNo)
    const dfstartIndex = (pageNo - 1) * dfpageSize
    const dfpaginationForms = _(state)
      .slice(dfstartIndex)
      .take(dfpageSize)
      .value()
    setdfpaginatedForms(dfpaginationForms)
  }

  const dfrenderPageNumber = dfpages.map((page) => {
    if (page < maxdfPageNumberLimit + 1 && page > mindfPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => dfpagination(page)}
          active={page === dfcurrentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const dfhandleNextbtn = () => {
    setdfcurrentPage(dfcurrentPage + 1)
    dfpagination(dfcurrentPage + 1)

    if (dfcurrentPage + 1 > maxdfPageNumberLimit) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit + dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit + dfpageNumberLimit)
    }
  }

  const dfhandlePrevbtn = () => {
    setdfcurrentPage(dfcurrentPage - 1)
    dfpagination(dfcurrentPage - 1)

    if ((dfcurrentPage - 1) % dfpageNumberLimit === 0) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit - dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit - dfpageNumberLimit)
    }
  }
  // End Pagination Area Form

  // useEffect
  useEffect(() => {
    dispatch(retrieveSchedApplication({ userID: userCredentials.id }))
    localStorage.removeItem('reportSelected')
  }, [dispatch, userCredentials])

  useEffect(() => {
    if (forInspection && forInspection.message) {
      setState(() =>
        forInspection.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.applicationID === parseInt(searchTerm)
            )
          }
        })
      )
    }
  }, [forInspection, searchTerm])
  // End useEffect

  console.log(forInspection)
  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg'
          style={{ background: '#FFF' }}
        >
          {forInspection && forInspection.message && (
            <>
              <Form>
                <Row className='justify-content-end px-3 pt-5'>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              <Row className='mt-4'>
                <Col xl={12} style={{ background: '#FFF' }}>
                  <h5 className='pt-3 pb-2 mb-0'>For Inspection List</h5>
                  <hr
                    className='mt-1 mb-3'
                    style={{
                      background: '#ffeb36',
                      paddingBottom: '2px',
                      width: '200px',
                    }}
                  />
                  <Table bordered hover responsive size='sm'>
                    <thead className='bg-info text-light'>
                      <tr>
                        <th className='text-nowrap'>Application No.</th>
                        <th>Application</th>
                        <th>Trades</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Date Transferred</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dfpaginatedForms.map((x, index) => (
                        <tr key={index}>
                          <td className='text-center'>{x.applicationID}</td>
                          <td>{x.ApplicationName}</td>
                          <td>{x.trades}</td>
                          <td>{x.status}</td>
                          <td>{x.remarks}</td>
                          <td>
                            {moment(x.timeStamp).format('MMMM D, YYYY h:mma')}
                          </td>
                          <td className='text-center'>
                            <Button
                              size='sm'
                              variant='outline-success'
                              onClick={() => {
                                setModal({
                                  viewManager: true,
                                })
                                setPayload(x)

                                dispatch(
                                  retrieveListReport({
                                    appID: x.applicationID,
                                    userID: userCredentials.id,
                                  })
                                )
                                dispatch(
                                  retrieveCommentList({
                                    id: x.applicationID,
                                  })
                                )

                                dispatch(
                                  retrieveAppInfo({ id: x.applicationID })
                                )

                                dispatch(
                                  retrieveDateApplied({ id: x.applicationID })
                                )
                              }}
                            >
                              <FaEllipsisH />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    size='sm'
                    className='d-flex justify-content-end mx-2'
                  >
                    <Pagination.Item
                      className=' mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={dfhandlePrevbtn}
                      disabled={dfcurrentPage === dfpages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {dfrenderPageNumber}

                    <Pagination.Item
                      className=' mt-0 fs-6 text-nowrap'
                      size='sm'
                      variant='info'
                      onClick={dfhandleNextbtn}
                      disabled={
                        dfcurrentPage === dfpages[dfpages.length - 1]
                          ? true
                          : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </>
          )}

          {forInspection && forInspection.loading && (
            <Row className='py-5 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {forInspection && forInspection.error && (
            <Row className='py-5 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Scheduled for Inspection</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      <Modal
        size='md'
        show={modal.viewManager}
        onHide={() => setModal({ viewManager: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaFileAlt className='mx-3' />
            Report Manager
          </Modal.Title>
        </Modal.Header>
        {listReport && !listReport.loading && (
          <Modal.Body>
            {listReport && listReport.message && (
              <ListGroup variant='flush'>
                <ListGroup.Item active>
                  List of Inspection Report - Date &#38; Time Encoded
                </ListGroup.Item>
                {listReport.message.map((el, index) => (
                  <div key={index}>
                    {el.reportID !== null && (
                      <ListGroup.Item
                        key={index}
                        action
                        onClick={() => {
                          el.id !== undefined &&
                            dispatch(
                              retrieveReport({
                                inspectionID: el.id,
                                userID: userCredentials.id,
                                category: el.category,
                                timeStamp: el.timeStamp,
                              })
                            )

                          localStorage.setItem(
                            'reportSelected',
                            JSON.stringify({
                              isEditting: true,
                              inspectionID: el.id,
                              category: el.category,
                              timeStamp: el.timeStamp,
                            })
                          )

                          history.push({
                            pathname: `/create-edit-report/${listReport.message[0].appID}`,
                            state: { isEditting: true },
                          })
                        }}
                      >
                        {el.category} -{' '}
                        {moment(el.timeStamp).format('MMMM D, YYYY h:mma') ||
                          ''}
                      </ListGroup.Item>
                    )}
                  </div>
                ))}
              </ListGroup>
            )}
            <Button
              size='sm'
              variant='info'
              className='fs-4 mt-2'
              onClick={() =>
                history.push(
                  `/create-edit-report/${listReport.message[0].appID}`
                )
              }
            >
              +
            </Button>
          </Modal.Body>
        )}

        {listReport && listReport.loading && (
          <Row className='my-4 mx-1'>
            <Col xl={12}>
              <Card>
                <Card.Body className='mt-2'>
                  <div className='text-center pt-3'>
                    <Spinner animation='border' />
                    <h3>Loading...</h3>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <Modal.Footer>
          <Button
            onClick={() =>
              setModal({ viewManager: false, applicationViewing: true })
            }
            variant='info'
            size='sm'
          >
            Application Details
          </Button>
          <Button
            onClick={() => {
              setModal({ viewManager: false, viewComments: true })
              dispatch(
                retrieveCommentList({
                  id: payload.applicationID,
                })
              )
            }}
            variant='success'
            size='sm'
          >
            View Comments
          </Button>
          <Button
            onClick={() => setModal({ viewManager: false })}
            variant='danger'
            size='sm'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ViewAppInfo
        show={modal.applicationViewing}
        onHide={() => {
          setModal({ applicationViewing: false, viewManager: true })
        }}
        payload={retrievedAppInfo && retrievedAppInfo.message}
        dateApplied={
          dateApplied &&
          dateApplied.message &&
          dateApplied.message[0].DateApplied
        }
      />

      {/* Change Status */}
      <Modal
        size='lg'
        show={modal.viewComments}
        onHide={() => setModal({ viewComments: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            Comment Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6>NOTE:</h6>

            <Row className='pb-2'>
              <Col xl='10'>
                <Form.Control
                  as='textarea'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  size='sm'
                  name='r_remarks'
                  required
                  placeholder='Insert remarks here ...'
                  onChange={(e) => {
                    setCommentDetails({
                      ...commentDetails,
                      comments: e.target.value,
                    })
                  }}
                />
              </Col>
              <Col className='d-flex align-items-end pt-2' xl='2'>
                <Button
                  onClick={() => {
                    dispatch(
                      insertComment({
                        id: payload.applicationID,
                        comments: commentDetails.comments,
                        createdBy: userCredentials.id,
                      })
                    )
                    setModal({
                      viewComments: false,
                      successComment: true,
                    })
                  }}
                  variant='success'
                  size='sm'
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
          <hr />
          <CommentsCard
            state={commentList && commentList.message && commentList.message}
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button
            variant='danger'
            onClick={() => setModal({ viewManager: true, viewComments: false })}
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Change Status */}

      {/* Success Modal */}
      <SuccessModal
        show={modal.successComment}
        onHide={() => {
          setModal({ successComment: false })
          window.location.reload(true)
        }}
        transLoading={createdComment && createdComment.loading}
        success={createdComment && createdComment.message}
        error={createdComment && createdComment.error}
      />
      {/* End  Success Modal */}
    </UserMain>
  )
}

export default withRouter(ForInsPectionList)
