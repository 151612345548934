import React from 'react'
import { Row } from 'react-bootstrap'

const FaqAppSubmit = () => {
  return (
    <div>
      <h6>
        The requirements for you application must be complied in a folder, be
        uploaded to your chosen cloud storage and share the link to the link
        section of your application.
      </h6>

      <div className='text-center mt-3'>
        <img src='assets/img/faqsubmit1.jpg' />
        <p className='mt-2'>
          Figure 3.1: Folder Name for Compilation of your files & requirements
        </p>
      </div>

      <Row>
        <img src='assets/img/faqsubmit2.png' />
        <p className='mt-2 text-center'>
          Figure 3.2: Section where to place the link of your uploaded folder
        </p>
      </Row>
    </div>
  )
}

export default FaqAppSubmit
