import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

// Layout
import UserMain from '../../layout/UserLayout/UserMain'
import { FaSearch, FaPrint } from 'react-icons/fa'
import Loading from '../../reusable/Loading'

// Actions
import {
  retrieveViolationStatus,
  retrieveInspectorSummary,
} from '../../redux/actions/inspectionActions'

const NovReportScreen = () => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    year: '',
  })

  const inspection = useSelector((state) => state.inspection)
  const { retrievedViolationStatus, retrievedInspectorSummary } = inspection

  let sampleValue =
    retrievedViolationStatus &&
    retrievedViolationStatus.message &&
    retrievedViolationStatus.message.find(
      ({ offenseNo, Month }) => offenseNo === '2nd' && Month === '08'
    ).TOTAL

  // Functions
  const handleChangeYear = (e) => {
    let temp = e.target.value.split('-')
    setState({
      ...state,
      year: temp[0],
      month: temp[1],
    })
  }

  console.log(retrievedInspectorSummary)

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          <h5 className='fw-bold pt-5'>BUILDING PERMIT SECTION</h5>
          <p className='mb-1'>
            as of <i>July 28, 2023</i>
          </p>

          <Row className='mb-2'>
            <Col xl={2}>
              <InputGroup>
                <Form.Control
                  type='month'
                  size='sm'
                  onChange={handleChangeYear}
                />

                <InputGroup.Text
                  className='bg-info text-light'
                  onClick={() => {
                    dispatch(retrieveViolationStatus({ year: state.year }))
                    dispatch(retrieveInspectorSummary({ year: state.year }))
                  }}
                >
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>

          <div className='d-flex justify-content-between'>
            <h5>I. NOTICE OF VIOLATION STATUS</h5>
            <Button variant='outline-success' size='sm'>
              <FaPrint /> Print
            </Button>
          </div>
          <hr
            className='mt-1 mb-1'
            style={{
              background: '#ffeb36',
              paddingBottom: '2px',
              width: '200px',
            }}
          />

          <Table responsive bordered hover size='sm' striped>
            <thead>
              <tr className='bg-info text-light'>
                <td>Month</td>
                {[
                  { rowHeader: '1st', total: '' },
                  { rowHeader: '2nd', total: sampleValue },
                  { rowHeader: '3rd', total: '' },
                  { rowHeader: 'Stoppage', total: '' },
                  { rowHeader: 'Final Letter', total: '' },
                  { rowHeader: 'Endorsed To Legal', total: '' },
                ].map((value) => (
                  <td key={value.rowHeader} className='text-center'>
                    {value.rowHeader}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {[
                { value: 'JANUARY', id: '01' },
                { value: 'FEBRUARY', id: '02' },
                { value: 'MARCH', id: '03' },
                { value: 'APRIL', id: '04' },
                { value: 'MAY', id: '05' },
                { value: 'JUNE', id: '06' },
                { value: 'JULY', id: '07' },
                { value: 'AUGUST', id: '08' },
                { value: 'SEPTEMBER', id: '09' },
                { value: 'OCTOBER', id: '10' },
                { value: 'NOVEMBER', id: '11' },
                { value: 'DECEMBER', id: '12' },
                { value: 'TOTAL', id: '13' },
              ].map((e) => (
                <tr className='fw-bold' key={e.id}>
                  <td>{e.value}</td>
                  <td className='text-center'>
                    {
                      (
                        (retrievedViolationStatus &&
                          retrievedViolationStatus.message &&
                          retrievedViolationStatus.message.find(
                            ({ offenseNo, Month }) =>
                              offenseNo === '1st' && Month === e.id
                          )) ||
                        {}
                      ).TOTAL
                    }
                  </td>
                  <td className='text-center'>
                    {
                      (
                        (retrievedViolationStatus &&
                          retrievedViolationStatus.message &&
                          retrievedViolationStatus.message.find(
                            ({ offenseNo, Month }) =>
                              offenseNo === '2nd' && Month === e.id
                          )) ||
                        {}
                      ).TOTAL
                    }
                  </td>
                  <td className='text-center'>
                    {
                      (
                        (retrievedViolationStatus &&
                          retrievedViolationStatus.message &&
                          retrievedViolationStatus.message.find(
                            ({ offenseNo, Month }) =>
                              offenseNo === '3rd' && Month === e.id
                          )) ||
                        {}
                      ).TOTAL
                    }
                  </td>
                  <td className='text-center'>
                    {
                      (
                        (retrievedViolationStatus &&
                          retrievedViolationStatus.message &&
                          retrievedViolationStatus.message.find(
                            ({ offenseNo, Month }) =>
                              offenseNo === 'Stoppage Order' && Month === e.id
                          )) ||
                        {}
                      ).TOTAL
                    }
                  </td>
                  <td className='text-center'>
                    {
                      (
                        (retrievedViolationStatus &&
                          retrievedViolationStatus.message &&
                          retrievedViolationStatus.message.find(
                            ({ offenseNo, Month }) =>
                              offenseNo === 'Final Letter' && Month === e.id
                          )) ||
                        {}
                      ).TOTAL
                    }
                  </td>
                  <td className='text-center'>
                    {
                      (
                        (retrievedViolationStatus &&
                          retrievedViolationStatus.message &&
                          retrievedViolationStatus.message.find(
                            ({ offenseNo, Month }) =>
                              offenseNo === 'Endorsed To Legal' &&
                              Month === e.id
                          )) ||
                        {}
                      ).TOTAL
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <hr />

          <div className='d-flex justify-content-between'>
            <h5>II. INSPECTOR SUMMARY</h5>
            <Button variant='outline-success' size='sm'>
              <FaPrint /> Print
            </Button>
          </div>

          <hr
            className='mt-1 mb-1'
            style={{
              background: '#ffeb36',
              paddingBottom: '2px',
              width: '200px',
            }}
          />

          {retrievedInspectorSummary && retrievedInspectorSummary.loading && (
            <Loading />
          )}

          <Table responsive bordered hover size='sm' striped>
            <thead className='bg-info text-light'>
              <tr>
                <td colSpan={2}>INSPECTOR'S NAME</td>
                <td>ISSUED NOV (APPLICANT)</td>
                <td>SECURED PERMIT</td>
                <td>ISSUED NOV</td>
              </tr>
            </thead>
            {retrievedInspectorSummary && retrievedInspectorSummary.message && (
              <tbody>
                {retrievedInspectorSummary &&
                  retrievedInspectorSummary.message.map((e, index) => (
                    <tr key={index}>
                      <td>{e.Category}</td>
                      <td>{e.EMPLOYEE}</td>
                      <td>{e.ISSUED_NOV_APPLICANT}</td>
                      <td>{e.SECURED_PERMIT}</td>
                      <td>{e.ISSUED_NOV}</td>
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>

          {/* <Row className='mt-2'>
            <Col xl='9' className='shadow-lg py-2  mb-3'>
              <div className='d-flex justify-content-between'>
                <h5>III. DETAILED SUMMARY PER MONTH</h5>

                <Button variant='outline-success' size='sm'>
                  <FaPrint /> Print
                </Button>
              </div>

              <hr
                className='mt-1 mb-3'
                style={{
                  background: '#ffeb36',
                  paddingBottom: '2px',
                }}
              />

              <Table responsive bordered hover size='sm' striped>
                <thead>
                  <tr className='bg-info text-light'>
                    <td className='text-center' colSpan={8}>
                      DETAILED SUMMARY
                    </td>
                  </tr>
                  <tr>
                    <td className='text-center' colSpan={8}>
                      For the month of January
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>1st Notice of Violation</td>
                    <td colSpan={2}>2nd Notice of Violation</td>
                    <td colSpan={2}>3rd Notice of Violation</td>
                    <td colSpan={2}>Stoppage Order</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>MARK</td>
                    <td>1</td>
                    <td>PETE</td>
                    <td>1</td>
                    <td>CELINE</td>
                    <td>5</td>
                    <td>LN</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row> */}
        </Container>
      </Container>
    </UserMain>
  )
}

export default NovReportScreen
