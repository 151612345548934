import React, { useState, useEffect } from 'react'
import {
  Container,
  Col,
  Row,
  Form,
  Button,
  Table,
  Modal,
  Spinner,
  Pagination,
  InputGroup,
} from 'react-bootstrap'
import { FaCheckCircle, FaSearch, FaPenAlt } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import io from 'socket.io-client'
import { useToast } from 'rc-toastr'

// Components
import UserMain from '../../layout/UserLayout/UserMain'
import SuccessModal from '../../reusable/SuccessModal'
import Error from '../../reusable/Error'
import Loading from '../../reusable/Loading'

// Actions
import {
  userApplicationRetrieve,
  userUpdateApplication,
  userDeleteApplication,
  retrieveEngrList,
  retrieveRecordedEng,
  updateRecordedEng,
} from '../../redux/actions/userApplicationActions'

import { retrieveCreatedApp } from '../../redux/actions/DocumentControllers/documentControllerActions'

const socket = io.connect(process.env.REACT_APP_URL)

const DcCreateScreen = () => {
  // Declaration
  const [show, setShow] = useState(false)
  const [transactShow, setTransactShow] = useState(false)
  const [isTrade, setIsTrade] = useState()
  const [dataList, setDataList] = useState([])
  const [engineerDetails, setEngineerDetails] = useState({
    trade: '',
    inChargeName: '',
    inChargeType: '',
    inChargeProfession: '',
    inChargePrcNo: '',
    inChargePrcValidity: '',
    inChargePtrNo: '',
    inChargePtrValidity: '',
    inChargeID: '',
    designName: '',
    designProfession: '',
    designType: '',
    designPrcNo: '',
    designPrcValidity: '',
    designPtrNo: '',
    designPtrValidity: '',
    designChargeID: '',
    idInCharge: '',
    idDesign: '',
    disabled: true,
    selectedTrade: '',
  })
  const [recordEngineer, setRecordEngineer] = useState([])

  const [modal, setModal] = useState({
    updated: false,
    deleted: false,
    filter: false,
    viewEngineer: false,
  })

  const [filterValue, setFilterValue] = useState({
    category: '',
    status: '',
  })

  const dispatch = useDispatch()
  const applications = useSelector((state) => state.applications)
  const {
    applicationsDetail,
    loading,
    error,
    updatedMessage,
    deletedMessage,
    retrievedEngrList,
    retrievedRecordedEng,
    updatedRecordedEng,
  } = applications

  const documentControllerReducer = useSelector(
    (state) => state.documentControllerReducer
  )

  const { retrievedCreatedApp } = documentControllerReducer

  const users = useSelector((state) => state.users)
  const { userCredentials } = users

  const [InitialState] = useState({
    userID: userCredentials.id,
    roles: userCredentials.roles,
  })

  const [forUpdate, setForUpdate] = useState({
    id: '',
    AppInfo_ID: '',
    Application_ID: '',
    AppID: '',
    ApplicationName: '',
    Link: '',
    BpNo: '',
    Structural: 0,
    Mechanical: 0,
    Architectural: 0,
    Sanitary: 0,
    Electrical: 0,
    Plumbing: 0,
    Electronics: 0,
    Status: '',
    Remarks: '',
    TimeStamp: '',
    userID: '',
    trades: '',
    status: 'APPLICATION VERIFICATION',
    remarks: 'UPDATED',
    applicantName: '',
  })

  const [searchTerm, setSearchTerm] = useState('')

  const [appTrack, setAppTrack] = useState(false)

  const [showViewApp, setShowViewApp] = useState(false)

  const [list, setList] = useState([])

  // End Declaration

  // Functions

  const handleEditModal = (payload) => {
    // dispatch(userApplicationRetrieveById(id, InitialState))
    setShow(true)
    setForUpdate({
      ...forUpdate,
      id: payload.id,
      AppInfo_ID: payload.AppInfo_ID,
      AppID: payload.appID,
      ApplicationName: payload.ApplicationName,
      Link: payload.Link,
      BpNo: payload.BpNo,
      Structural: payload.Structural,
      Mechanical: payload.Mechanical,
      Architectural: payload.Architectural,
      Sanitary: payload.Sanitary,
      Electrical: payload.Electrical,
      Plumbing: payload.Plumbing,
      Electronics: payload.Electronics,
      Status: payload.status,
      Remarks: payload.remarks,
      TimeStamp: payload.TimeStamp,
      userID: payload.userID,
      applicantName: payload.applicantName !== null && payload.applicantName,
    })
  }

  const handleChange = (event) => {
    if (event.target.type === 'checkbox') {
      if (event.target.checked) {
        setForUpdate({
          ...forUpdate,
          [event.target.name]: 1,
        })

        setIsTrade(event.target.name)
        // setEngineerDetails({
        //   ...engineerDetails,
        //   trade: event.target.name,
        //   inChargeType: `Supervisor in-charge of ${event.target.name} works`,
        //   designType: 'Design professional, plans, and specification',
        // })
        // setShow(false)
        // setModal({
        //   ...modal,
        //   isProfession: true,
        // })
      } else {
        if (
          window.confirm(
            'Are you sure you want to delete this trade in your application ?'
          )
        ) {
          setForUpdate({
            ...forUpdate,
            [event.target.name]: 0,
          })
        }
      }
    } else {
      setForUpdate({
        ...forUpdate,
        [event.target.name]: event.target.value,
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(
      userUpdateApplication({
        id: forUpdate.id,
        AppInfo_ID: forUpdate.AppInfo_ID,
        Application_ID: forUpdate.Application_ID,
        AppID: forUpdate.AppID,
        ApplicationName: forUpdate.ApplicationName,
        Link: forUpdate.Link,
        BpNo: forUpdate.BpNo,
        Structural: forUpdate.Structural,
        Mechanical: forUpdate.Mechanical,
        Architectural: forUpdate.Architectural,
        Sanitary: forUpdate.Sanitary,
        Electrical: forUpdate.Electrical,
        Plumbing: forUpdate.Plumbing,
        Electronics: forUpdate.Electronics,
        Status: forUpdate.Status,
        Remarks: forUpdate.Remarks,
        TimeStamp: forUpdate.TimeStamp,
        userID: forUpdate.userID,
        trades: forUpdate.trades,
        status: 'APPLICATION VERIFICATION',
        remarks: 'UPDATED',
        recordEngineer: [],
      })
    )
    setShow(false)
    // setTransactShow(true)
    setModal({ updated: true })
  }

  const handleDelete = (id) => {
    if (window.confirm('Are you sure wanted to delete this application?')) {
      dispatch(userDeleteApplication(InitialState, id))
      // setTransactShow(true)
      setModal({ deleted: true })
    }
  }

  const handleConfirm = () => {
    setTransactShow(false)
    dispatch(userApplicationRetrieve(InitialState))
  }

  const handleViewApp = (payload) => {
    setShowViewApp(true)
    setForUpdate({
      ...forUpdate,
      Application_ID: payload.Application_ID,
      ApplicationName: payload.ApplicationName,
      Link: payload.Link,
      FirstName: payload.FirstName,
      MiddleName: payload.MiddleName,
      LastName: payload.LastName,
      Street: payload.Street,
      Area: payload.Area,
      Barangay: payload.Barangay,
      ScopeOfWork: payload.ScopeOfWork,
      UseType: payload.UseType,
      Storey: payload.Storey,
      Unit: payload.Unit,
      FloorArea: payload.FloorArea,
      EstimateCost: payload.EstimateCost,
      Structural: payload.Structural,
      Mechanical: payload.Mechanical,
      Architectural: payload.Architectural,
      Sanitary: payload.Sanitary,
      Electrical: payload.Electrical,
      Plumbing: payload.Plumbing,
      Electronics: payload.Electronics,
      Status: payload.status,
      Remarks: payload.remarks,
      TimeStamp: payload.TimeStamp,
      applicantName: payload.applicantName !== null && payload.applicantName,
    })
  }

  const handleSubmitEngrDetails = (e) => {
    e.preventDefault()
    let index = recordEngineer.findIndex((value) => value.trade === isTrade)

    if (index < 0) {
      let tempValue = recordEngineer

      tempValue.push(engineerDetails)

      setModal({
        ...modal,
        isProfession: false,
      })

      setEngineerDetails({
        trade: '',
        inChargeName: '',
        inChargeType: '',
        inChargeProfession: '',
        inChargePrcNo: '',
        inChargePrcValidity: '',
        inChargePtrNo: '',
        inChargePtrValidity: '',
        inChargeID: '',
        designName: '',
        designProfession: '',
        designType: '',
        designPrcNo: '',
        designPrcValidity: '',
        designPtrNo: '',
        designPtrValidity: '',
        designChargeID: '',
      })
    }
    setShow(true)
  }

  const onChange = (e) => {
    setEngineerDetails({
      ...engineerDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleUpdateEngineerSubmit = (e) => {
    e.preventDefault()
    dispatch(
      updateRecordedEng({
        inChargeID: engineerDetails.inChargeID,
        inChargeName: engineerDetails.inChargeName,
        inChargeProfession: engineerDetails.inChargeProfession,
        inChargeType: engineerDetails.inChargeType,
        idInCharge: engineerDetails.idInCharge,
        designChargeID: engineerDetails.designChargeID,
        designName: engineerDetails.designName,
        designProfession: engineerDetails.designProfession,
        designType: engineerDetails.designType,
        idDesign: engineerDetails.idDesign,
      })
    )
    setModal({ ...modal, viewEngineer: false })
    setShow(true)
    setEngineerDetails({
      trade: '',
      inChargeName: '',
      inChargeType: '',
      inChargeProfession: '',
      inChargePrcNo: '',
      inChargePrcValidity: '',
      inChargePtrNo: '',
      inChargePtrValidity: '',
      inChargeID: '',
      designName: '',
      designProfession: '',
      designType: '',
      designPrcNo: '',
      designPrcValidity: '',
      designPtrNo: '',
      designPtrValidity: '',
      designChargeID: '',
      disabled: true,
    })
  }

  const handleOnClick = (data) => {
    setEngineerDetails({
      ...engineerDetails,
      inChargeName: data.Name,
      inChargeProfession: data.ProfessionName,
      inChargePrcNo: data.prcNo,
      inChargePrcValidity: data.prcValidity,
      inChargePtrNo: data.ptrNo,
      inChargePtrValidity: data.ptrValidity,
      inChargeID: data.id,
    })
  }

  const handleOnClickDesign = (data) => {
    setEngineerDetails({
      ...engineerDetails,
      designName: data.Name,
      designProfession: data.ProfessionName,
      designPrcNo: data.prcNo,
      designPrcValidity: data.prcValidity,
      designPtrNo: data.ptrNo,
      designPtrValidity: data.ptrValidity,
      designChargeID: data.id,
    })
  }

  const { toast } = useToast()
  const sayHello = (data) => {
    toast.success(data)
  }

  // End Functions

  // Pagination Area Forms
  const [dfpaginatedForms, setdfpaginatedForms] = useState([])
  const [dfcurrentPage, setdfcurrentPage] = useState(1)

  const [dfpageNumberLimit] = useState(5)
  const [maxdfPageNumberLimit, setmaxdfPageNumberLimit] = useState(5)
  const [mindfPageNumberLimit, setmindfPageNumberLimit] = useState(0)

  const dfpageSize = 10

  useEffect(() => {
    setdfpaginatedForms(_(_.reverse(list)).slice(0).take(dfpageSize).value())
    setdfcurrentPage(1)
  }, [list])

  const dfpageCount = list ? Math.ceil(list.length / dfpageSize) : 0

  const dfpages = _.range(1, dfpageCount + 1)

  const dfpagination = (pageNo) => {
    setdfcurrentPage(pageNo)
    const dfstartIndex = (pageNo - 1) * dfpageSize
    const dfpaginationForms = _(list)
      .slice(dfstartIndex)
      .take(dfpageSize)
      .value()
    setdfpaginatedForms(dfpaginationForms)
  }

  const dfrenderPageNumber = dfpages.map((page) => {
    if (page < maxdfPageNumberLimit + 1 && page > mindfPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => dfpagination(page)}
          active={page === dfcurrentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const dfhandleNextbtn = () => {
    setdfcurrentPage(dfcurrentPage + 1)
    dfpagination(dfcurrentPage + 1)

    if (dfcurrentPage + 1 > maxdfPageNumberLimit) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit + dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit + dfpageNumberLimit)
    }
  }

  const dfhandlePrevbtn = () => {
    setdfcurrentPage(dfcurrentPage - 1)
    dfpagination(dfcurrentPage - 1)

    if ((dfcurrentPage - 1) % dfpageNumberLimit === 0) {
      setmaxdfPageNumberLimit(maxdfPageNumberLimit - dfpageNumberLimit)
      setmindfPageNumberLimit(mindfPageNumberLimit - dfpageNumberLimit)
    }
  }
  // End Pagination Area Forms

  // useEffect
  useEffect(() => {
    // dispatch(userApplicationRetrieve(InitialState))
    dispatch(retrieveCreatedApp({ empID: userCredentials.id }))
    dispatch(retrieveEngrList({ name: '' }))
    socket.on('received_new_application', (data) => {
      // if (window.confirm(`${data.message} do you want to reload the page ?`)) {
      sayHello(data.message)
      // }
    })
  }, [InitialState, dispatch, socket])

  useEffect(() => {
    if (retrievedCreatedApp && retrievedCreatedApp.message) {
      setList(() =>
        retrievedCreatedApp.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.appID === parseInt(searchTerm)
            )
          }
        })
      )
    }

    if (retrievedEngrList && retrievedEngrList.message) {
      setDataList(retrievedEngrList.message)
    }

    if (retrievedRecordedEng && retrievedRecordedEng.message) {
      setEngineerDetails({
        ...engineerDetails,
        inChargeName: retrievedRecordedEng.message[0].Name,
        inChargeType: retrievedRecordedEng.message[0].Type,
        inChargeProfession: retrievedRecordedEng.message[0].Engineer,
        inChargePrcNo:
          retrievedRecordedEng.message[0].taggedID !== 0
            ? retrievedRecordedEng.message[0].prcNo
            : retrievedRecordedEng.message[0].PrcID,
        inChargePtrNo:
          retrievedRecordedEng.message[0].taggedID !== 0
            ? retrievedRecordedEng.message[0].ptrNo
            : retrievedRecordedEng.message[0].ptrID,
        inChargeID: retrievedRecordedEng.message[0].taggedID,
        idInCharge: retrievedRecordedEng.message[0].id,
        idDesign: retrievedRecordedEng.message[1].id,
        designName: retrievedRecordedEng.message[1].Name,
        designProfession: retrievedRecordedEng.message[1].Engineer,
        designType: retrievedRecordedEng.message[1].Type,
        designPrcNo:
          retrievedRecordedEng.message[1].taggedID !== 0
            ? retrievedRecordedEng.message[1].prcNo
            : retrievedRecordedEng.message[1].PrcID,
        designPtrNo:
          retrievedRecordedEng.message[1].taggedID !== 0
            ? retrievedRecordedEng.message[1].ptrNo
            : retrievedRecordedEng.message[0].ptrID,
        designChargeID: retrievedRecordedEng.message[1].taggedID,
      })
    } else {
      setEngineerDetails({
        ...engineerDetails,
        inChargeName: '',
        inChargeType: '',
        inChargeProfession: '',
        inChargePrcNo: '',
        inChargePtrNo: '',
        inChargeID: '',
        idInCharge: '',
        idDesign: '',
        designName: '',
        designProfession: '',
        designType: '',
        designPrcNo: '',
        designPtrNo: '',
        designChargeID: '',
      })
    }
  }, [
    retrievedCreatedApp,
    searchTerm,
    retrievedEngrList,
    recordEngineer,
    retrievedRecordedEng,
  ])

  // console.log(forUpdate)

  // End useEffect
  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {retrievedCreatedApp && retrievedCreatedApp.message && (
            <>
              <Form className='mx-3'>
                <Row className='justify-content-end pt-5'>
                  {/* <Col md={8} className='my-2'>
                    <Button
                      variant='info'
                      size='sm'
                      onClick={() => setModal({ ...modal, filter: true })}
                    >
                      Filter
                    </Button>
                  </Col> */}
                  <Col md={4} className='my-2'>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input application no. / applicant name here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              {/* For Application Status Table */}
              <Container fluid>
                <Row className='my-4'>
                  <Col xl={12} style={{ background: '#FFF' }}>
                    <h5 className='pt-3 pb-2 mb-0'>Created Application List</h5>
                    <hr
                      className='mt-1 mb-3'
                      style={{
                        background: '#ffeb36',
                        paddingBottom: '2px',
                        width: '200px',
                      }}
                    />

                    <Table size='sm' bordered striped hover responsive>
                      <thead className='bg-info text-light'>
                        <tr>
                          <th>Application No.</th>
                          <th>Application</th>
                          <th>Status</th>
                          <th>Remarks</th>
                          <th>Date Transferred</th>
                          <th>Applicant Name</th>
                          <th className='text-center'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dfpaginatedForms.map((x) => (
                          <tr key={x.appID}>
                            <td className='text-center'>{x.appID}</td>
                            <td>{x.ApplicationName}</td>
                            <td>{x.status}</td>
                            <td>{x.remarks}</td>
                            <td>
                              {moment(x.TimeStamp).format('MMMM D, YYYY h:mma')}
                            </td>
                            <td>
                              {x.applicantName !== null && x.applicantName}
                            </td>
                            <td>
                              <div className='d-flex justify-content-center'>
                                {x.status === 'APPLICATION VERIFICATION' ||
                                x.status === 'FOR COMPLIANCE' ? (
                                  <>
                                    <Button
                                      size='sm'
                                      variant='info'
                                      className='text-nowrap'
                                      onClick={() => handleEditModal(x)}
                                    >
                                      {/* <FaRegEdit className='fs-4 mx-1' /> */}
                                      EDIT
                                    </Button>
                                    <Button
                                      size='sm'
                                      variant='danger'
                                      className='text-nowrap mx-1'
                                      onClick={() => handleDelete(x.appID)}
                                    >
                                      {/* <FaTrashAlt className='fs-4 mx-1' /> */}
                                      DELETE
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    variant='success'
                                    className='text-nowrap'
                                    onClick={() => handleViewApp(x)}
                                    size='sm'
                                  >
                                    {/* <FaEye className='fs-4 mx-1' /> */}
                                    VIEW
                                  </Button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination
                      size='sm'
                      className='d-flex justify-content-end mx-2 my-2'
                    >
                      <Pagination.Item
                        className=' mt-0 fs-6 text-nowrap'
                        size='sm'
                        variant='info'
                        onClick={dfhandlePrevbtn}
                        disabled={dfcurrentPage === dfpages[0] ? true : false}
                      >
                        &lt;
                      </Pagination.Item>

                      {dfrenderPageNumber}

                      <Pagination.Item
                        className=' mt-0 fs-6 text-nowrap'
                        size='sm'
                        variant='info'
                        onClick={dfhandleNextbtn}
                        disabled={
                          dfcurrentPage === dfpages[dfpages.length - 1]
                            ? true
                            : false
                        }
                      >
                        &gt;
                      </Pagination.Item>
                    </Pagination>
                  </Col>
                </Row>
              </Container>
            </>
          )}
          {retrievedCreatedApp && retrievedCreatedApp.loading && (
            <div className='pt-5 pb-2'>
              <Loading />
            </div>
          )}
          {retrievedCreatedApp && retrievedCreatedApp.error && (
            <div className='pt-5 pb-2'>
              <Error message='No created application found' />
            </div>
          )}
        </Container>
      </Container>

      {/* Edit Application */}
      <Modal size='xl' show={show} onHide={() => setShow(false)} centered>
        {InitialState.id !== '' ? (
          <>
            <Form onSubmit={handleSubmit}>
              <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
                <Modal.Title style={{ color: '#FFF' }}>
                  Edit Application
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={12}>
                    {forUpdate.ApplicationName ===
                      'BUILDING CONSTRUCTION PERMIT' && (
                      <>
                        <Row>
                          <Col xl={9}>
                            <h5>ATTACHED PERMIT TRADES</h5>
                            <p>Click the trades that include to your files</p>
                          </Col>
                          {/* <Col className='text-end' xl={3}>
                            Edit Registered Engineer
                            <Button
                              className='mx-1'
                              variant='outline-success'
                              size='sm'
                              onClick={() => {
                                setModal({ ...modal, viewEngineer: true })
                                setShow(false)
                              }}
                            >
                              <FaPenAlt />
                            </Button>
                          </Col> */}
                        </Row>

                        <Row>
                          {_.zip(
                            [
                              'STRUCTURAL',
                              'MECHANICAL',
                              'ARCHITECTURAL',
                              'ELECTRICAL',
                              'PLUMBING/SANITARY',
                              'ELECTRONICS',
                            ],
                            [
                              'Structural',
                              'Mechanical',
                              'Architectural',
                              'Electrical',
                              'Plumbing',
                              'Electronics',
                            ],
                            [
                              forUpdate.Structural,
                              forUpdate.Mechanical,
                              forUpdate.Architectural,
                              forUpdate.Electrical,
                              forUpdate.Plumbing,
                              forUpdate.Electronics,
                            ]
                          ).map((trades) => (
                            <Form.Group
                              className='my-2'
                              as={Col}
                              md={3}
                              key={trades[0]}
                            >
                              <Form.Check
                                type='checkbox'
                                label={trades[0]}
                                name={trades[1]}
                                checked={trades[2] === 1 ? true : false}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          ))}
                        </Row>
                        <hr />
                      </>
                    )}

                    {forUpdate.ApplicationName === 'OCCUPANCY PERMIT' && (
                      <>
                        <h5>Building Permit No.</h5>
                        <Form.Group className='mb-3' as={Col} md={12}>
                          <Form.Control
                            type='text'
                            className='mt-2'
                            name='BpNo'
                            placeholder='Input building permit no. here ...'
                            value={forUpdate.BpNo}
                            style={{
                              background: '#FFF',
                              border: '1px solid #ced4da',
                            }}
                            required
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </>
                    )}

                    <h5>Link</h5>

                    <Form.Group as={Col} md={12}>
                      <Form.Control
                        type='text'
                        className='mt-2'
                        name='Link'
                        value={forUpdate.Link}
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        required
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Applicant Name:</Form.Label>
                      <Form.Control
                        type='text'
                        className='mt-2'
                        name='applicantName'
                        required
                        onChange={handleChange}
                        value={forUpdate.applicantName}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='info' className='mx-1' type='submit'>
                  SUBMIT
                </Button>
                <Button variant='danger' onClick={() => setShow(false)}>
                  CANCEL
                </Button>
              </Modal.Footer>
            </Form>
          </>
        ) : (
          <></>
        )}
      </Modal>
      {/* End Edit Application */}

      {/* View Records */}
      <Modal
        size='lg'
        show={showViewApp}
        onHide={() => setShowViewApp(false)}
        centered
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> View Records
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!appTrack ? (
            <>
              <Form>
                <Row>
                  <Col md={12}>
                    {forUpdate.ApplicationName ===
                      'BUILDING CONSTRUCTION PERMIT' && (
                      <>
                        <h5>ATTACHED PERMIT TRADES</h5>
                        <p>Click the trades that include your files</p>
                        <Row>
                          {_.zip(
                            [
                              'STRUCTURAL',
                              'MECHANICAL',
                              'ARCHITECTURAL',
                              'ELECTRICAL',
                              'PLUMBING/SANITARY',
                              'ELECTRONICS',
                            ],
                            [
                              'Structural',
                              'Mechanical',
                              'Architectural',
                              'Electrical',
                              'Plumbing',
                              'Electronics',
                            ],
                            [
                              forUpdate.Structural,
                              forUpdate.Mechanical,
                              forUpdate.Architectural,
                              forUpdate.Electrical,
                              forUpdate.Plumbing,
                              forUpdate.Electronics,
                            ]
                          ).map((trades) => (
                            <Form.Group
                              className='my-2'
                              as={Col}
                              md={3}
                              key={trades[0]}
                            >
                              <Form.Check
                                type='checkbox'
                                label={trades[0]}
                                name={trades[1]}
                                checked={trades[2] === 1 ? true : false}
                                disabled={true}
                              />
                            </Form.Group>
                          ))}
                        </Row>
                        <hr />
                      </>
                    )}

                    <h5>Link</h5>
                    <Row>
                      <Form.Group as={Col} md={12}>
                        <Form.Control
                          type='text'
                          className='mt-2'
                          name='Link'
                          value={forUpdate.Link}
                          style={{
                            background: '#FFF',
                            border: '1px solid #ced4da',
                          }}
                          disabled={true}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </>
          ) : (
            <>
              <Row className='py-3 text-center'>
                <hr />
                <Col md={4}>Date</Col>
                <Col md={4}>Status</Col>
                <Col md={4}>Remarks</Col>
              </Row>
              <Row className='py-3 text-center'>
                <Col md={4}>{forUpdate.TimeStamp}</Col>
                <Col md={4}>{forUpdate.Status}</Col>
                <Col md={4}>{forUpdate.Remarks}</Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {appTrack ? (
            <Button variant='info' onClick={() => setAppTrack(false)}>
              Application Summary
            </Button>
          ) : (
            <Button variant='info' onClick={() => setAppTrack(true)}>
              Track Application
            </Button>
          )}
          <Button variant='danger' onClick={() => setShowViewApp(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* View Records */}

      <Modal
        size='md'
        show={modal.isProfession}
        onHide={() => setModal({ ...modal, isProfession: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            {isTrade} Professional
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitEngrDetails}>
          <Modal.Body className='pb-1'>
            <h5>List of registered engineer & architect</h5>

            <p className='p-0 m-0'>SUPERVISOR / IN-CHARGE</p>

            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='inChargeName'
                  onChange={onChange}
                  value={engineerDetails.inChargeName}
                  autoComplete='off'
                  required
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.inChargeName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClick(data)}
                        >
                          {data.Name}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargeProfession}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePrcNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePtrNo}
                  onChange={() => {}}
                  required
                />
              </Form.Group>
            </Row>

            <p className='p-0 m-0 mt-3'>
              DESIGN PROFESSIONAL, PLANS AND SPECIFICATION
            </p>
            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>

                <Form.Control
                  type='text'
                  name='designName'
                  onChange={onChange}
                  value={engineerDetails.designName}
                  autoComplete='off'
                  required
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.designName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClickDesign(data)}
                        >
                          {data.Name}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designProfession}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designPrcNo}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designPtrNo}
                  required
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer className='p-1'>
            <Button variant='info' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  ...modal,
                  isProfession: false,
                })

                setEngineerDetails({
                  trade: '',
                  inChargeName: '',
                  inChargeType: '',
                  inChargeProfession: '',
                  inChargePrcNo: '',
                  inChargePrcValidity: '',
                  inChargePtrNo: '',
                  inChargePtrValidity: '',
                  inChargeID: '',
                  designName: '',
                  designProfession: '',
                  designType: '',
                  designPrcNo: '',
                  designPrcValidity: '',
                  designPtrNo: '',
                  designPtrValidity: '',
                  designChargeID: '',
                })

                setForUpdate({
                  ...forUpdate,
                  [`${isTrade}`]: 0,
                })
                setShow(true)
              }}
            >
              Back
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        size='md'
        show={modal.viewEngineer}
        onHide={() => setModal({ ...modal, viewEngineer: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>Edit Engineers</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleUpdateEngineerSubmit}>
          <Modal.Body>
            <Form.Control
              as='select'
              size='sm'
              onChange={(e) => {
                if (e.target.value === '') {
                  setEngineerDetails({
                    ...engineerDetails,
                    disabled: true,
                    selectedTrade: '',
                  })
                } else {
                  setEngineerDetails({
                    ...engineerDetails,
                    disabled: false,
                    selectedTrade: e.target.value,
                  })
                  dispatch(
                    retrieveRecordedEng({
                      appID: forUpdate.AppID,
                      trade: e.target.value,
                    })
                  )
                }
              }}
            >
              <option value=''>Choose trades here...</option>
              {[
                ['STRUCTURAL', 'Structural'],
                ['ARCHITECTURAL', 'Architectural'],
                ['MECHANICAL', 'Mechanical'],
                ['ELECTRICAL', 'Electrical'],
                ['PLUMBING/SANITARY', 'Plumbing'],
                ['ELECTRONICS', 'Electronics'],
              ].map((e, index) => (
                <option key={index} value={e[1]}>
                  {e[0]}
                </option>
              ))}
            </Form.Control>
            <hr />
            <h6>List of registered engineer & architect</h6>

            <p className='p-0 m-0'>SUPERVISOR / IN-CHARGE</p>

            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='inChargeName'
                  onChange={onChange}
                  value={engineerDetails.inChargeName}
                  autoComplete='off'
                  required
                  size='sm'
                  disabled={engineerDetails.disabled}
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.inChargeName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClick(data)}
                        >
                          {data.Name}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargeProfession}
                  onChange={() => {}}
                  required
                  disabled={engineerDetails.disabled}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePrcNo}
                  onChange={() => {}}
                  required
                  disabled={engineerDetails.disabled}
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  value={engineerDetails.inChargePtrNo}
                  onChange={() => {}}
                  required
                  disabled={engineerDetails.disabled}
                />
              </Form.Group>
            </Row>

            <p className='p-0 m-0 mt-3'>
              DESIGN PROFESSIONAL, PLANS AND SPECIFICATION
            </p>
            <hr
              className='my-1'
              style={{
                background: '#ffeb36',
                paddingBottom: '2px',
                width: '200px',
              }}
            />
            <i style={{ fontSize: '11px' }}>
              Note: Last Name First Name Middle Name "Select an engineer or
              architect on the list"
            </i>
            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Engineer / Architect : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>

                <Form.Control
                  type='text'
                  name='designName'
                  onChange={onChange}
                  value={engineerDetails.designName}
                  autoComplete='off'
                  required
                  size='sm'
                  disabled={engineerDetails.disabled}
                />
                <div className='dropdown'>
                  {retrievedEngrList &&
                    retrievedEngrList.message &&
                    dataList
                      .filter((item) => {
                        let searchTerm =
                          engineerDetails.designName.toLowerCase()
                        let fullName = item.Name.toLowerCase()

                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        )
                      })
                      .map((data, index) => (
                        <div
                          key={index}
                          className='dropdown-row'
                          onClick={() => handleOnClickDesign(data)}
                        >
                          {data.Name}
                        </div>
                      ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    Profession : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designProfession}
                  required
                  disabled={engineerDetails.disabled}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PRC No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designPrcNo}
                  required
                  disabled={engineerDetails.disabled}
                />
              </Form.Group>
              <Form.Group as={Col} xl={6}>
                <Form.Label className='m-0'>
                  <h6 className='my-2'>
                    PTR No. : <i className='text-danger'>*</i>
                  </h6>
                </Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  onChange={() => {}}
                  value={engineerDetails.designPtrNo}
                  required
                  disabled={engineerDetails.disabled}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' size='sm' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              size='sm'
              onClick={() => {
                setModal({ ...modal, viewEngineer: false })
                setShow(true)
                setEngineerDetails({
                  trade: '',
                  inChargeName: '',
                  inChargeType: '',
                  inChargeProfession: '',
                  inChargePrcNo: '',
                  inChargePrcValidity: '',
                  inChargePtrNo: '',
                  inChargePtrValidity: '',
                  inChargeID: '',
                  designName: '',
                  designProfession: '',
                  designType: '',
                  designPrcNo: '',
                  designPrcValidity: '',
                  designPtrNo: '',
                  designPtrValidity: '',
                  designChargeID: '',
                  disabled: true,
                })
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Filter */}
      <Modal
        size='lg'
        show={modal.filter}
        onHide={() => setModal({ ...modal, filter: false })}
        centered
      >
        <Modal.Header style={{ background: '#4bbf73' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-3' /> Filter
          </Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            // console.log(filterValue)
          }}
        >
          <Modal.Body>
            <Form.Group>
              <Form.Label>Permit Category</Form.Label>
              <Form.Control
                as='select'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                size='sm'
                value={filterValue.category}
                onChange={(e) =>
                  setFilterValue({ ...filterValue, category: e.target.value })
                }
              >
                <option>Choose category here ...</option>
                <option value='BUILDING CONSTRUCTION PERMIT'>
                  BUILDING CONSTRUCTION PERMIT
                </option>
                <option value='OCCUPANCY PERMIT'>OCCUPANCY PERMIT</option>
                <option value='FENCING PERMIT'>FENCING PERMIT</option>
                <option value='DEMOLITION PERMIT'>DEMOLITION PERMIT</option>
                <option value='ELECTRICAL PERMIT'>ELECTRICAL PERMIT</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label className='mt-2'>Status</Form.Label>
              <Form.Control
                as='select'
                style={{
                  background: '#FFF',
                  border: '1px solid #ced4da',
                }}
                size='sm'
                value={filterValue.status}
                onChange={(e) =>
                  setFilterValue({ ...filterValue, status: e.target.value })
                }
              >
                <option>Choose status here ...</option>
                <option value='APPLICATION VERIFICATION'>
                  APPLICATION VERIFICATION
                </option>
                <option value='FOR INSPECTION AND EVALUATION'>
                  FOR INSPECTION AND EVALUATION
                </option>
                <option value='FOR APPROVAL'>FOR APPROVAL</option>
                <option value='APPROVED APPLICATION'>
                  APPROVED APPLICATION
                </option>
                <option value='RELEASE APPLICATION WITH REMARKS'>
                  RELEASE APPLICATION WITH REMARKS
                </option>
                <option value='RELEASE APPLICATION '>
                  RELEASE APPLICATION
                </option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='info' type='submit'>
              Submit
            </Button>
            <Button
              variant='danger'
              onClick={() => setModal({ filter: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* End Filter */}

      {/*Record Update Success Modal */}
      <SuccessModal
        show={modal.updated}
        onHide={() => {
          setModal({ updated: false })
          window.location.reload(true)
        }}
        transLoading={updatedMessage && updatedMessage.loading}
        success={updatedMessage && updatedMessage.message}
        error={updatedMessage && updatedMessage.error}
      />
      {/* End Record Update Success Modal */}

      {/*Record Deleted Success Modal */}
      <SuccessModal
        show={modal.deleted}
        onHide={() => {
          setModal({ deleted: false })
          window.location.reload(true)
        }}
        transLoading={deletedMessage && deletedMessage.loading}
        success={deletedMessage && deletedMessage.message}
        error={deletedMessage && deletedMessage.error}
      />
      {/* End Record Deleted Success Modal */}
    </UserMain>
  )
}

export default DcCreateScreen
