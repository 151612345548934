import Cookie from 'js-cookie'
import axios from 'axios'
import {
  GET_FOR_ASSESSMENT_REQ,
  GET_FOR_ASSESSMENT_SUCCESS,
  GET_FOR_ASSESSMENT_FAILED,
  GET_WITH_ASSESSMENT_REQ,
  GET_WITH_ASSESSMENT_SUCCESS,
  GET_WITH_ASSESSMENT_FAILED,
} from '../constants/assessmentConstants'

export const retrieveForAssessment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FOR_ASSESSMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/assessment/${payload.userID}`,
        config
      )

      dispatch({
        type: GET_FOR_ASSESSMENT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_FOR_ASSESSMENT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}

export const retrieveWithAssessment = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_WITH_ASSESSMENT_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/assessment/with-assessment-list/${payload.userID}`,
        config
      )

      dispatch({
        type: GET_WITH_ASSESSMENT_SUCCESS,
        payload: data.result,
      })
    } catch {
      dispatch({
        type: GET_WITH_ASSESSMENT_FAILED,
        payload: 'No Data Found',
      })
    }
  }
}
