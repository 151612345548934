import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  InputGroup,
  Button,
  Table,
  Pagination,
  Modal,
} from 'react-bootstrap'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FaSearch, FaEye, FaCheck, FaClipboardCheck } from 'react-icons/fa'
import moment from 'moment'

//Layout
import UserMain from '../../../layout/UserLayout/UserMain'
import SuccessModal from '../../../reusable/SuccessModal'
import CommentsCard from '../../../reusable/CommentsCard'
import EvaluationSheetScreen from '../../../reusable/EvaluationSheetScreen'

//Actions
import {
  retrieveForRecommendationList,
  retrieveRecommendedList,
  retrieveEvaluationList,
  insertRecommendApplication,
  insertEvaluationComments,
} from '../../../redux/actions/Evaluators/Supervisors/supervisorActions'
import { retrieveDateApplied } from '../../../redux/actions/adminApplicationActions'
import { retrieveEvaluationDetails as CivilEvaluation } from '../../../redux/actions/evaluationCivilActions'
import { retrieveEvaluationDetails as ArchEvaluation } from '../../../redux/actions/evaluatorArchActions'
import {
  retrieveEvaluationDetails as ElectronicsEvaluation,
  retrieveCommentList,
  insertComment,
} from '../../../redux/actions/evaluatorActions'
import { retrieveEvaluationDetails as ElecEvaluation } from '../../../redux/actions/evaluatorElectricalActions'
import { retrieveEvaluationDetails as MechEvaluation } from '../../../redux/actions/evaluatorMechActions'
import { retrieveEvaluationDetails as PlumEvaluation } from '../../../redux/actions/evaluatorPlumbingActions'
import { retrieveAssessmentDetails as CivilAssessment } from '../../../redux/actions/Assessment/structuralAssessmentActions'
import { retrieveAssessmentDetails as ElecAssessment } from '../../../redux/actions/Assessment/electricalAssessmentActions'
import { retrieveAssessmentDetails as ElectronicsAssessment } from '../../../redux/actions/Assessment/electronicsAssessmentActions'
import { retrieveAssessmentDetails as MechAssessment } from '../../../redux/actions/Assessment/mechanicalAssessmentActions'
import { retrieveAssessmentDetails as PlumAssessment } from '../../../redux/actions/Assessment/plumbingAssessmentActions'
import { retrieveAssessmentDetails as ArchAssessment } from '../../../redux/actions/Assessment/accessoriesFeeAssessmentActions'
import { retrieveAssessmentDetails as OccuAssessement } from '../../../redux/actions/Assessment/certOccupancyAssessmentActions'
import { retrieveEvaluationSheet } from '../../../redux/actions/DocumentControllers/documentControllerActions'

const RecommendedListScreen = ({ history }) => {
  // Declaration
  const dispatch = useDispatch()

  const [state, setState] = useState([])

  const [payload, setPayload] = useState({})

  const [searchTerm, setSearchTerm] = useState('')

  const [modal, setModal] = useState({
    viewManager: false,
    viewEvaluation: false,
    viewEvaluationList: false,
    viewAssessment: false,
    successModal: false,
    viewComments: false,
    viewEvaluationSheet: false,
  })

  const [commentDetails, setCommentDetails] = useState({
    comments: '',
    appID: 0,
    commentToName: '',
    commentTo: '',
  })

  const [evalDetails, setEvalDetails] = useState([])

  const [assessDetails, setAssessDetails] = useState([])

  const [activeTrade, setActiveTrade] = useState('')

  const [userLevel, setUserLevel] = useState('')

  const users = useSelector((state) => state.users)

  const { userCredentials } = users

  const esupervisor = useSelector((state) => state.esupervisor)

  const {
    forRecommendation,
    evaluationList,
    recommendApplication,
    recommendedList,
  } = esupervisor

  const adminApplication = useSelector((state) => state.adminApplication)

  const { dateApplied } = adminApplication

  const documentControllerReducer = useSelector(
    (state) => state.documentControllerReducer
  )

  const { retrievedEvaluationSheet } = documentControllerReducer

  // Evaluators

  const evaluator = useSelector((state) => state.evaluator)

  const { evaluationDetails, commentList } = evaluator

  const civilEvaluator = useSelector((state) => state.civilEvaluator)

  const { evaluationCivilDetails } = civilEvaluator

  const archEvaluator = useSelector((state) => state.archEvaluator)

  const { evaluationArchDetails } = archEvaluator

  const electricalEvaluator = useSelector((state) => state.electricalEvaluator)

  const { evaluationElectricalDetails } = electricalEvaluator

  const mechanicalEvaluator = useSelector((state) => state.mechanicalEvaluator)

  const { evaluationMechDetails } = mechanicalEvaluator

  const plumbingEvaluator = useSelector((state) => state.plumbingEvaluator)

  const { evaluationPlumbingDetails } = plumbingEvaluator

  // End Evaluators

  // Assessments

  const structuralAssessment = useSelector(
    (state) => state.structuralAssessment
  )

  const CivilAssessDetails = structuralAssessment.assessmentDetails

  const electronicsAssessment = useSelector(
    (state) => state.electronicsAssessment
  )

  const ElectronicsAssesDetails = electronicsAssessment.assessmentDetails

  const electricalAssessment = useSelector(
    (state) => state.electricalAssessment
  )

  const ElectricalAssessDetails = electricalAssessment.assessmentDetails

  const mechanicalAssessment = useSelector(
    (state) => state.mechanicalAssessment
  )

  const MechAssessDetails = mechanicalAssessment.assessmentDetails

  const plumbingAssessment = useSelector((state) => state.plumbingAssessment)

  const PlumAssessDetails = plumbingAssessment.assessmentDetails

  const certOccupancyAssessment = useSelector(
    (state) => state.certOccupancyAssessment
  )

  const certOccupancyAssessDetails = certOccupancyAssessment.assessmentDetails

  const accessoriesFee = useSelector((state) => state.accessoriesFeeAssessment)

  const { assessmentDetails } = accessoriesFee

  // End Assessments

  // End Declaration

  // Pagination Area Forms
  const [paginatedForms, setPaginatedForms] = useState([])
  const [currentPage, setcurrentPage] = useState(1)

  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  const pageSize = 5

  useEffect(() => {
    setPaginatedForms(_(_.reverse(state)).slice(0).take(pageSize).value())
    setcurrentPage(1)
  }, [state])

  const pageCount = state ? Math.ceil(state.length / pageSize) : 0

  const pages = _.range(1, pageCount + 1)

  const pagination = (pageNo) => {
    setcurrentPage(pageNo)
    const startIndex = (pageNo - 1) * pageSize
    const paginationForms = _(state).slice(startIndex).take(pageSize).value()
    setPaginatedForms(paginationForms)
  }

  const renderPageNumber = pages.map((page) => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <Pagination.Item
          key={page}
          onClick={() => pagination(page)}
          active={page === currentPage}
          activeLabel
        >
          {page}
        </Pagination.Item>
      )
    } else {
      return null
    }
  })

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1)
    pagination(currentPage + 1)

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1)
    pagination(currentPage - 1)

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  // End Pagination Area Form

  // Funtions
  const handleViewEvaluation = (id, evaluatorID, trade) => {
    switch (trade) {
      case 'Electronics':
        dispatch(ElectronicsEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(ElectronicsAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Structural':
        dispatch(CivilEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(CivilAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Architectural':
        if (payload.ApplicationName !== 'OCCUPANCY PERMIT') {
          dispatch(ArchEvaluation({ userID: evaluatorID, evalAppID: id }))
          dispatch(ArchAssessment({ userID: evaluatorID, evalAppID: id }))
          setModal({
            viewEvaluation: true,
            viewEvaluationList: false,
          })
          setActiveTrade(trade)
          break
        } else {
          dispatch(ArchEvaluation({ userID: evaluatorID, evalAppID: id }))
          dispatch(OccuAssessement({ userID: evaluatorID, evalAppID: id }))
          setModal({
            viewEvaluation: true,
            viewEvaluationList: false,
          })
          setActiveTrade(trade)
          break
        }

      case 'Mechanical':
        dispatch(MechEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(MechAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Electrical':
        dispatch(ElecEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(ElecAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      case 'Plumbing':
        dispatch(PlumEvaluation({ userID: evaluatorID, evalAppID: id }))
        dispatch(PlumAssessment({ userID: evaluatorID, evalAppID: id }))
        setModal({
          viewEvaluation: true,
          viewEvaluationList: false,
        })
        setActiveTrade(trade)
        break
      default:
        // console.log('NO Evaluation')
        break
    }
  }

  const disabledRecommendation = () => {
    if (evaluationList && evaluationList.message) {
      let value = evaluationList.message.map((list) => {
        if (list.withEvaluation !== null && list.withAssessment !== null) {
          return false
        }
        return true
      })
      let checker = value.some((x) => x === true)

      return checker
    }
  }

  const handleRecommentApplication = () => {
    dispatch(
      insertRecommendApplication({
        id: payload.appID,
        userID: userCredentials.id,
      })
    )
    setModal({ viewEvaluationList: false, successModal: true })
  }
  // End Functions

  // UseEffect
  useEffect(() => {
    dispatch(retrieveRecommendedList())
  }, [])

  useEffect(() => {
    if (recommendedList && recommendedList.message) {
      setState(() =>
        recommendedList.message.filter((column) => {
          if (searchTerm === '') {
            return column
          } else {
            return Object.values(column).some((value) =>
              typeof value === 'string'
                ? value.toLowerCase().includes(searchTerm.toLowerCase())
                : column.id === parseInt(searchTerm)
            )
          }
        })
      )
    }
    if (activeTrade !== '') {
      switch (activeTrade) {
        case 'Structural':
          if (evaluationCivilDetails && evaluationCivilDetails.message) {
            setEvalDetails(evaluationCivilDetails.message)
          }
          if (CivilAssessDetails && CivilAssessDetails.message) {
            setAssessDetails(CivilAssessDetails.message)
          }
          break
        case 'Electronics':
          if (evaluationDetails && evaluationDetails.message) {
            setEvalDetails(evaluationDetails.message)
          }
          if (ElectronicsAssesDetails && ElectronicsAssesDetails.message) {
            setAssessDetails(ElectronicsAssesDetails.message)
          }
          break
        case 'Architectural':
          if (payload.ApplicationName !== 'OCCUPANCY PERMIT') {
            if (evaluationArchDetails && evaluationArchDetails.message) {
              setEvalDetails(evaluationArchDetails.message)
            }
            if (assessmentDetails && assessmentDetails.message) {
              setAssessDetails(assessmentDetails.message)
            }
            break
          } else {
            if (evaluationArchDetails && evaluationArchDetails.message) {
              setEvalDetails(evaluationArchDetails.message)
            }
            if (
              certOccupancyAssessDetails &&
              certOccupancyAssessDetails.message
            ) {
              setAssessDetails(certOccupancyAssessDetails.message)
            }
            break
          }
        case 'Mechanical':
          if (evaluationMechDetails && evaluationMechDetails.message) {
            setEvalDetails(evaluationMechDetails.message)
          }
          if (MechAssessDetails && MechAssessDetails.message) {
            setAssessDetails(MechAssessDetails.message)
          }
          break
        case 'Electrical':
          if (
            evaluationElectricalDetails &&
            evaluationElectricalDetails.message
          ) {
            setEvalDetails(evaluationElectricalDetails.message)
          }
          if (ElectricalAssessDetails && ElectricalAssessDetails.message) {
            setAssessDetails(ElectricalAssessDetails.message)
          }
          break
        case 'Plumbing':
          if (evaluationPlumbingDetails && evaluationPlumbingDetails.message) {
            setEvalDetails(evaluationPlumbingDetails.message)
          }
          if (PlumAssessDetails && PlumAssessDetails.message) {
            setAssessDetails(PlumAssessDetails.message)
          }
          break
        default:
          // console.log('Empty')
          break
      }
    }
    if (userCredentials.roles !== 'evaluator') {
      setUserLevel(userCredentials.roles)
    } else {
      setUserLevel(userCredentials.subroles)
    }
  }, [
    userCredentials,
    recommendedList,
    searchTerm,
    evaluationCivilDetails,
    CivilAssessDetails,
    evaluationDetails,
    ElectronicsAssesDetails,
    evaluationArchDetails,
    assessmentDetails,
    evaluationMechDetails,
    MechAssessDetails,
    evaluationElectricalDetails,
    ElectricalAssessDetails,
    evaluationPlumbingDetails,
    PlumAssessDetails,
    certOccupancyAssessDetails,
  ])
  // End UseEffect

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5'
          style={{ background: '#FFF' }}
        >
          {recommendedList && recommendedList.message && (
            <>
              <Form>
                <Row className='justify-content-end px-3 pt-5'>
                  <Col md={4}>
                    <InputGroup>
                      <Form.Control
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                        value={searchTerm}
                        placeholder='Input search keyword here . . .'
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {searchTerm === '' ? (
                        <InputGroup.Text className='bg-info text-light'>
                          <FaSearch />
                        </InputGroup.Text>
                      ) : (
                        <Button
                          variant='danger'
                          onClick={() => setSearchTerm('')}
                        >
                          x
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Form>

              <Row className='my-4 mx-3 shadow-lg'>
                <Col
                  md={12}
                  className='border border-secondary rounded px-0 bg-light'
                >
                  <h5 className='mx-3 pt-4 pb-2'>Application List</h5>
                  <Table bordered hover responsive size='sm'>
                    <thead className='bg-info text-light'>
                      <tr>
                        <th>Application No.</th>
                        <th>Application</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Date Transferred</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedForms.map((x, index) => (
                        <tr key={index}>
                          <td className='text-center'>{x.id}</td>
                          <td>{x.ApplicationName}</td>
                          <td>{x.status}</td>
                          <td>{x.remarks}</td>
                          <td>
                            {moment(x.TimeStamp).format('MMMM D, YYYY h:mma')}
                          </td>
                          <td className='text-center'>
                            <Button
                              variant='success'
                              onClick={() => {
                                dispatch(retrieveDateApplied({ id: x.id }))
                                setModal({
                                  viewManager: true,
                                })
                                setPayload(x)
                                dispatch(retrieveCommentList({ id: x.id }))
                              }}
                              size='sm'
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    size='sm'
                    className='d-flex justify-content-end mx-2'
                  >
                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      variant='info'
                      onClick={handlePrevbtn}
                      disabled={currentPage === pages[0] ? true : false}
                    >
                      &lt;
                    </Pagination.Item>

                    {renderPageNumber}

                    <Pagination.Item
                      className='mt-0 fs-6 text-nowrap'
                      variant='info'
                      onClick={handleNextbtn}
                      disabled={
                        currentPage === pages[pages.length - 1] ? true : false
                      }
                    >
                      &gt;
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </>
          )}

          {recommendedList && recommendedList.loading && (
            <Row className='my-4 mx-1  pt-5'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {recommendedList && recommendedList.error && (
            <Row className='my-4 mx-1 pt-5'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Application Recommended</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      {/* Application Viewing */}
      <Modal
        size='lg'
        show={modal.viewManager}
        onHide={() => setModal({ viewManager: false })}
        centered
        backdrop='static'
      >
        {dateApplied && dateApplied.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Application Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table responsive size='sm'>
                <tbody>
                  <tr className='text-nowrap'>
                    <td className='fw-bold'>Application No.:</td>
                    <td>{payload.id}</td>
                  </tr>
                  <tr className='text-nowrap'>
                    <td className='fw-bold'>Application Name:</td>
                    <td>{payload.ApplicationName}</td>
                  </tr>
                  <tr className='text-nowrap'>
                    <td className='fw-bold'>Mode of Application:</td>
                    <td>{payload.ModeofForms}</td>
                  </tr>
                  <tr className='text-nowrap'>
                    <td className='fw-bold'>Application Status:</td>
                    <td>{payload.status}</td>
                  </tr>
                  <tr className='text-nowrap'>
                    <td className='fw-bold'>Application Date Applied:</td>
                    <td>
                      {moment(dateApplied.message[0].DateApplied).format(
                        'MMMM D, YYYY h:mma'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>
                      Applicant Attached Link/Remarks:
                    </td>
                    <td>{payload.Link}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>
                      Document Controller Attached Link/Remarks:
                    </td>
                    <td>{payload.remarks}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Evaluate and Assessment</td>
                    <td>
                      <Button
                        variant='success'
                        onClick={() => {
                          dispatch(retrieveEvaluationList({ id: payload.id }))
                          setModal({
                            viewEvaluationList: true,
                            viewManager: false,
                          })
                        }}
                        size='sm'
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Evaluation Sheet</td>
                    <td>
                      <Button
                        variant='success'
                        onClick={() => {
                          dispatch(retrieveEvaluationSheet({ id: payload.id }))
                          setModal({
                            viewEvaluationSheet: true,
                            viewManager: false,
                          })
                        }}
                        size='sm'
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewManager: false,
                  })
                }}
                size='sm'
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        )}

        {dateApplied && dateApplied.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}

        {dateApplied && dateApplied.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              className='mb-3'
              variant='danger'
              onClick={() => {
                setModal({
                  viewManager: false,
                })
              }}
            >
              Close
            </Button>
          </>
        )}
      </Modal>
      {/* End Application Viewing */}

      {/* Evaluation and Assessment List */}
      <Modal
        size='xl'
        show={modal.viewEvaluationList}
        onHide={() => setModal({ viewEvaluationList: false })}
        centered
        backdrop='static'
      >
        {evaluationList && evaluationList.message && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Evaluation and Assessment List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Trade</th>
                    <th>Date Evaluated</th>
                    <th>Date Assessed</th>
                    <th>Evaluator</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {evaluationList.message.map((list) => (
                    <tr key={list.id}>
                      <td className='fw-bold'>{list.trade}</td>
                      <td>
                        {moment(list.withEvaluation).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>
                        {moment(list.withAssessment).format(
                          'MMMM D, YYYY h:mma'
                        )}
                      </td>
                      <td>{list.EmployeeName}</td>
                      <td className='text-center'>
                        <Button
                          variant='success'
                          disabled={
                            list.withEvaluation && list.withAssessment !== null
                              ? false
                              : true
                          }
                          onClick={() => {
                            setCommentDetails({
                              comments: list.comments,
                              appID: list.id,
                            })
                            handleViewEvaluation(
                              list.id,
                              list.evaluatorID,
                              list.trade
                            )
                          }}
                          size='sm'
                        >
                          <FaEye />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='info'
                onClick={() => {
                  dispatch(retrieveCommentList({ id: payload.id }))
                  setModal({
                    viewEvaluationList: false,
                    viewComments: true,
                  })
                }}
                size='sm'
              >
                View Comments
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewEvaluationList: false,
                    viewManager: true,
                  })
                }}
                size='sm'
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {evaluationList && evaluationList.loading && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluationList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}

        {evaluationList && evaluationList.error && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <h3>No Data Found</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluationList: false,
                  viewManager: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/*End Evaluation and Assessment List */}

      {/* Evaluation & Assessment Details */}
      <Modal
        size='xl'
        show={modal.viewEvaluation}
        onHide={() => setModal({ viewEvaluation: false })}
        centered
        backdrop='static'
      >
        {evalDetails.length > 0 && activeTrade !== '' && (
          <>
            <Modal.Header style={{ background: '#28a745' }}>
              <Modal.Title style={{ color: '#FFF' }}>
                <FaCheck className='mx-3' />
                Evaluation and Assessment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>SCOPE OF WORK</h5>
              <p className='mx-2'>
                <i>{evalDetails.length > 0 && evalDetails[0].scope}</i>
              </p>
              <Table bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>Code ID</th>
                    <th>JMC AND CODE REGULATIONS</th>
                    <th>COMPLIANCE (MARK CHECK / STATUS)</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {evalDetails.map((list) => (
                    <tr key={list.id}>
                      <td>{list.code_id}</td>
                      <td>{list.code_name}</td>
                      <td className='text-center'>
                        {list.compliance === 1 ? 'YES' : 'NO'}
                      </td>
                      <td>{list.id_remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <h5>Assessment</h5>
              <p className='mx-2'>
                <i className='fs-5'>Total Amount : </i>
                <i className='text-danger fs-3 fw-bold'>
                  ₱{' '}
                  {assessDetails.length > 0 &&
                    assessDetails[0].grandTotal.toLocaleString()}
                </i>
              </p>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant='info'
                onClick={() => {
                  setModal({
                    viewEvaluation: false,
                    viewAssessment: true,
                  })
                }}
              >
                Next
              </Button> */}
              <Button
                variant='danger'
                onClick={() => {
                  setModal({
                    viewEvaluation: false,
                    viewEvaluationList: true,
                  })
                }}
              >
                Back
              </Button>
            </Modal.Footer>
          </>
        )}

        {evalDetails.length <= 0 && (
          <>
            <Row className='my-4 mx-1'>
              <Col xl={12}>
                <Card>
                  <Card.Body className='mt-2'>
                    <div className='text-center pt-3'>
                      <Spinner animation='border' />
                      <h3>Loading...</h3>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              variant='danger'
              onClick={() => {
                setModal({
                  viewEvaluation: false,
                  viewEvaluationList: true,
                })
              }}
            >
              Back
            </Button>
          </>
        )}
      </Modal>
      {/* End Evaluation & Assessment Details */}

      {/* Modal Evaluation Sheet */}
      <EvaluationSheetScreen
        show={modal.viewEvaluationSheet}
        onHide={() => {
          setModal({
            ...modal,
            viewEvaluationSheet: false,
            viewManager: true,
          })
        }}
        retrievedEvaluationSheet={retrievedEvaluationSheet}
        userLevel={userLevel}
      />

      {/* Change Status */}
      <Modal
        size='lg'
        show={modal.viewComments}
        onHide={() => setModal({ viewComments: false })}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheck className='mx-3' />
            Comment Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className='pb-3'>
              <h6>NOTE:</h6>
              <i>Comment on this section will be shown to bldgo staff only</i>
            </div>

            <Row className='pb-2'>
              <Col sm={12} xl='10'>
                <Form.Control
                  as='textarea'
                  style={{
                    background: '#FFF',
                    border: '1px solid #ced4da',
                  }}
                  size='sm'
                  name='r_remarks'
                  required
                  placeholder='Insert remarks here ...'
                  onChange={(e) => {
                    setCommentDetails({
                      ...commentDetails,
                      comments: e.target.value,
                    })
                  }}
                />
              </Col>
              <Col className='d-flex align-items-end pt-2' sm={12} xl={2}>
                <Button
                  onClick={() => {
                    dispatch(
                      insertComment({
                        id: payload.id,
                        comments: commentDetails.comments,
                        createdBy: userCredentials.id,
                      })
                    )
                    setModal({
                      viewComments: false,
                      viewEvaluationList: true,
                    })
                  }}
                  variant='success'
                  size='sm'
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
          <hr />
          <CommentsCard
            state={commentList && commentList.message && commentList.message}
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button
            variant='danger'
            onClick={() =>
              setModal({ viewEvaluationList: true, viewComments: false })
            }
          >
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Change Status */}

      {/*Create Success Modal */}
      <SuccessModal
        show={modal.successModal}
        onHide={() => {
          setModal({ successModal: false })
          window.location.reload(true)
        }}
        transLoading={recommendApplication && recommendApplication.loading}
        success={recommendApplication && recommendApplication.message}
        error={recommendApplication && recommendApplication.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

export default withRouter(RecommendedListScreen)
