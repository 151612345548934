export const CREATE_ELECTRONICS_ASSESSMENT_REQ =
  'CREATE_ELECTRONICS_ASSESSMENT_REQ'
export const CREATE_ELECTRONICS_ASSESSMENT_SUCCESS =
  'CREATE_ELECTRONICS_ASSESSMENT_SUCCESS'
export const CREATE_ELECTRONICS_ASSESSMENT_FAILED =
  'CREATE_ELECTRONICS_ASSESSMENT_FAILED'

export const UPDATE_ELECTRONICS_ASSESSMENT_REQ =
  'UPDATE_ELECTRONICS_ASSESSMENT_REQ'
export const UPDATE_ELECTRONICS_ASSESSMENT_SUCCESS =
  'UPDATE_ELECTRONICS_ASSESSMENT_SUCCESS'
export const UPDATE_ELECTRONICS_ASSESSMENT_FAILED =
  'UPDATE_ELECTRONICS_ASSESSMENT_FAILED'

export const GET_ELECTRONICS_ASSESSMENT_REQ = 'GET_ELECTRONICS_ASSESSMENT_REQ'
export const GET_ELECTRONICS_ASSESSMENT_SUCCESS =
  'GET_ELECTRONICS_ASSESSMENT_SUCCESS'
export const GET_ELECTRONICS_ASSESSMENT_FAILED =
  'GET_ELECTRONICS_ASSESSMENT_FAILED'
