import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'

// Components
import CommentsCard from '../reusable/CommentsCard'
import Loading from '../reusable/Loading'
import Error from '../reusable/Error'

const CommentModal = (props) => {
  const dc = useSelector((state) => state.documentControllerReducer)

  const { applicationComments } = dc
  return (
    <Modal
      size='md'
      show={props.show}
      onHide={props.onHide}
      centered
      backdrop='static'
    >
      <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
        <Modal.Title style={{ color: '#FFF' }}>View Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {applicationComments && applicationComments.message && (
          <CommentsCard
            state={
              applicationComments &&
              applicationComments.message &&
              applicationComments.message
            }
          />
        )}

        {applicationComments && applicationComments.loading && (
          <div className='pt-2'>
            <Loading />
          </div>
        )}

        {applicationComments && applicationComments.error && (
          <div className='pt-2'>
            <Error message='No comment yet ...' />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={props.onHide}>
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CommentModal
