export const GET_ELECTRICAL_EVALUATION_DETAILS_REQ =
  'GET_ELECTRICAL_EVALUATION_DETAILS_REQ'
export const GET_ELECTRICAL_EVALUATION_DETAILS_SUCCESS =
  'GET_ELECTRICAL_EVALUATION_DETAILS_SUCCESS'
export const GET_ELECTRICAL_EVALUATION_DETAILS_FAILED =
  'GET_ELECTRICAL_EVALUATION_DETAILS_FAILED'

export const CREATE_ELECTRICAL_EVALUATION_REQ =
  'CREATE_ELECTRICAL_EVALUATION_REQ'
export const CREATE_ELECTRICAL_EVALUATION_SUCCESS =
  'CREATE_ELECTRICAL_EVALUATION_SUCCESS'
export const CREATE_ELECTRICAL_EVALUATION_FAILED =
  'CREATE_ELECTRICAL_EVALUATION_FAILED'

export const UPDATE_ELECTRICAL_EVALUATION_REQ =
  'UPDATE_ELECTRICAL_EVALUATION_REQ'
export const UPDATE_ELECTRICAL_EVALUATION_SUCCESS =
  'UPDATE_ELECTRICAL_EVALUATION_SUCCESS'
export const UPDATE_ELECTRICAL_EVALUATION_FAILED =
  'UPDATE_ELECTRICAL_EVALUATION_FAILED'
