import React, { useEffect, useRef, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Image,
} from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
// Redux Requirements
import { useDispatch, useSelector } from 'react-redux'
import { Login } from '../redux/actions/userActions'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'

// import axios from 'axios'

const LoginScreen = ({ history, location }) => {
  // Redux Requirements
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users)
  const { userCredentials, error } = user

  // console.log(Cookies.get('userInfo'))

  // const [logInstatus, setlogInStatus] = useState(false)

  const [userInformation, setuserInformation] = useState({
    username: '',
    password: '',
    longitude: null,
    latitude: null,
  })

  const [showPassword, setShowPassword] = useState('password')
  const inputRef = useRef()

  const handleChange = (e) => {
    setuserInformation({
      ...userInformation,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    dispatch(Login(userInformation))
    // setlogInStatus(true)
  }

  const redirect = location.search
    ? location.search.split('=')[1]
    : '/dashboard'

  useEffect(() => {
    inputRef.current.focus()

    if (userCredentials) {
      history.push(redirect)
    }
    // if ('geolocation' in navigator) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     setuserInformation({
    //       ...userInformation,
    //       longitude: position.coords.longitude,
    //       latitude: position.coords.latitude,
    //     })
    //   })
    // }
  }, [userCredentials, history, redirect])

  return (
    <div
      className='login-main d-flex justify-content-center align-items-center
  bg-img center-vh'
      style={{ backgroundImage: 'url(./assets/img/login-bg.webp)' }}
    >
      <Row>
        <Col md={12}>
          <div className=' border border-secondary shadow-lg p-3 bg-white '>
            <Row className='pt-2'>
              <Col md={12} className='text-center'>
                <div className='py-3'>
                  <Image
                    src='assets/logo/Ootb-logo.webp'
                    roundedCircle
                    width='80'
                    height='80'
                    className='shadow-lg'
                  />
                </div>

                <h4>LP-BLDGO</h4>

                <h5>Login</h5>
              </Col>
              <Col md={12} className='px-5 py-2'>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className='py-2'>
                    <Form.Control
                      type='text'
                      placeholder='Username'
                      onChange={handleChange}
                      name='username'
                      ref={inputRef}
                      style={{
                        background: '#FFF',
                        border: '1px solid #ced4da',
                      }}
                    />
                  </Form.Group>
                  <Form.Group className='py-2'>
                    <InputGroup>
                      <Form.Control
                        type={showPassword}
                        placeholder='Password'
                        name='password'
                        maxLength={16}
                        onChange={handleChange}
                        style={{
                          background: '#FFF',
                          border: '1px solid #ced4da',
                        }}
                      />
                      {showPassword === 'password' ? (
                        <Button
                          variant='outline-secondary'
                          onClick={() => setShowPassword('text')}
                        >
                          <FaEyeSlash />
                        </Button>
                      ) : (
                        <Button
                          variant='outline-secondary'
                          onClick={() => setShowPassword('password')}
                        >
                          <FaEye />
                        </Button>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <h6
                    className='text-end mt-2 mb-1'
                    onClick={() => history.push('/forgotpassword')}
                    style={{ cursor: 'pointer' }}
                  >
                    Forgot Password?
                  </h6>
                  {error && (
                    <Alert className='my-0' variant='danger'>
                      {error}
                    </Alert>
                  )}

                  <div className='d-grid gap-2 mt-3'>
                    <Button variant='success' type='submit'>
                      Submit
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={7}>
                <h6>New to Online BP Evaluation?</h6>
              </Col>
              <Col md={5}>
                <Link to='/registration' className='text-decoration-none'>
                  <p className='fw-bold fs-6 text-info text-end'>
                    CREATE ACCOUNT
                  </p>
                </Link>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default LoginScreen
