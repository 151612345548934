import {
  GET_DC_TIME_PROCESS,
  GET_EVALUATORS_TIME_PROCESS,
  GET_INSPECTOR_TIME_PROCESS,
  GET_TRADES_INFO_REQ,
  GET_TRADES_INFO_SUCCESS,
  GET_TRADES_INFO_FAILED,
} from '../constants/routingSlipConstants'

export const routingSlipReducers = (
  state = {
    dcTimeProcess: {},
    evaluatorsTimeProcess: {},
    inspectorTimeProcess: {},
  },
  action
) => {
  switch (action.type) {
    case GET_DC_TIME_PROCESS:
      return {
        ...state,
        dcTimeProcess: { message: action.payload },
      }
    case GET_EVALUATORS_TIME_PROCESS:
      return {
        ...state,
        evaluatorsTimeProcess: { message: action.payload },
      }
    case GET_INSPECTOR_TIME_PROCESS:
      return {
        ...state,
        inspectorTimeProcess: { message: action.payload },
      }
    case GET_TRADES_INFO_REQ:
      return {
        ...state,
        tradesInfo: { loading: true },
      }
    case GET_TRADES_INFO_SUCCESS:
      return {
        ...state,
        tradesInfo: { loading: false, message: action.payload },
      }
    case GET_TRADES_INFO_FAILED:
      return {
        ...state,
        tradesInfo: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
