import {
  USER_APPLICATIONS_REQUEST,
  USER_APPLICATIONS_SUCCESS,
  USER_APPLICATIONS_FAILED,
  USER_APPLICATION_UPDATE_REQ,
  USER_APPLICATION_UPDATE_SUCCESS,
  USER_APPLICATION_UPDATE_FAILED,
  USER_APPLICATIONS_REQUEST_BYID,
  USER_APPLICATIONS_SUCCESS_BYID,
  USER_APPLICATION_DELETE_REQ,
  USER_APPLICATION_DELETE_SUCCESS,
  USER_APPLICATION_DELETE_FAILED,
  USER_INSERT_APPLICATION_REQUEST,
  USER_INSERT_APPLICATION_SUCCESS,
  USER_INSERT_APPLICATION_FAILED,
  USER_APPLICATIONS_RETRIEVE_BYSTATUS_REQUEST,
  USER_APPLICATIONS_RETRIEVE_BYSTATUS,
  USER_APPLICATIONS_RETRIEVE_BYSTATUS_FAILED,
  USER_APPLICATION_HISTORY_REQ,
  USER_APPLICATION_HISTORY_SUCCESS,
  USER_APPLICATION_HISTORY_FAILED,
  USER_GETAPPLICATIONFORVERIFICATION_REQ,
  USER_GETAPPLICATIONFORVERIFICATION_SUCCESS,
  USER_GETAPPLICATIONFORVERIFICATION_FAILED,
  USER_GETAPPLICATIONFORINSPECTION_REQ,
  USER_GETAPPLICATIONFORINSPECTION_SUCCESS,
  USER_GETAPPLICATIONFORINSPECTION_FAILED,
  USER_GETAPPLICATIONFORCOMPLIANCE_REQ,
  USER_GETAPPLICATIONFORCOMPLIANCE_SUCCESS,
  USER_GETAPPLICATIONFORCOMPLIANCE_FAILED,
  USER_GETAPPLICATIONAPPROVED_REQ,
  USER_GETAPPLICATIONAPPROVED_SUCCESS,
  USER_GETAPPLICATIONAPPROVED_FAILED,
  USER_APPSTATUSTRACK_REQ,
  USER_APPSTATUSTRACK_SUCCESS,
  USER_APPSTATUSTRACK_FAILED,
  GET_ORDEROFPAYMENT_REQ,
  GET_ORDEROFPAYMENT_SUCCESS,
  GET_ORDEROFPAYMENT_FAILED,
  GET_RELEASEPERMIT_REQ,
  GET_RELEASEPERMIT_SUCCESS,
  GET_RELEASEPERMIT_FAILED,
  GET_LIST_REPORT_REQ,
  GET_LIST_REPORT_SUCCESS,
  GET_LIST_REPORT_FAILED,
  GET_ENGAPPLIST_REQ,
  GET_ENGAPPLIST_SUCCESS,
  GET_ENGAPPLIST_FAILED,
  ACCEPT_ENGAPPLICATION_REQ,
  ACCEPT_ENGAPPLICATION_SUCCESS,
  ACCEPT_ENGAPPLICATION_FAILED,
  DECLINE_ENGAPPLICATION_REQ,
  DECLINE_ENGAPPLICATION_SUCCESS,
  DECLINE_ENGAPPLICATION_FAILED,
  UPDATE_ENGIMAGEATTACHED_REQ,
  UPDATE_ENGIMAGEATTACHED_SUCCESS,
  UPDATE_ENGIMAGEATTACHED_FAILED,
  GET_ENGLIST_REQ,
  GET_ENGLIST_SUCCESS,
  GET_ENGLIST_FAILED,
  GET_RECORDED_ENG_REQ,
  GET_RECORDED_ENG_SUCCESS,
  GET_RECORDED_ENG_FAILED,
  GET_RELEASED_OCCU_REQ,
  GET_RELEASED_OCCU_SUCCESS,
  GET_RELEASED_OCCU_FAILED,
  UPDATE_RECORDED_ENG_REQ,
  UPDATE_RECORDED_ENG_SUCCESS,
  UPDATE_RECORDED_ENG_FAILED,
  PAYMENT_INITIALIZE_REQ,
  PAYMENT_INITIALIZE_SUCCESS,
  PAYMENT_INITIALIZE_FAILED,
  GET_RELEASECFEI_REQ,
  GET_RELEASECFEI_SUCCESS,
  GET_RELEASECFEI_FAILED,
} from '../constants/userApplicationsConstants'

export const userApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_APPLICATIONS_REQUEST:
      return {
        loading: true,
        state,
      }
    case USER_APPLICATIONS_SUCCESS:
      return { loading: false, applicationsDetail: action.payload }
    case USER_APPLICATIONS_FAILED:
      return { loading: false, error: action.payload }
    case USER_APPLICATION_UPDATE_REQ:
      return {
        ...state,
        updatedMessage: { loading: true },
      }
    case USER_APPLICATION_UPDATE_SUCCESS:
      return {
        ...state,
        updatedMessage: { loading: false, message: action.payload },
      }
    case USER_APPLICATION_UPDATE_FAILED:
      return {
        ...state,
        updatedMessage: { loading: false, error: action.payload },
      }
    case USER_APPLICATIONS_REQUEST_BYID:
      return { ...state, applicationID: { loading: true } }
    case USER_APPLICATIONS_SUCCESS_BYID:
      return {
        ...state,
        applicationID: { loading: false, result: action.payload },
      }
    case USER_APPLICATION_DELETE_REQ:
      return {
        ...state,
        deletedMessage: { loading: true },
      }
    case USER_APPLICATION_DELETE_SUCCESS:
      return {
        ...state,
        deletedMessage: { loading: false, message: action.payload },
      }
    case USER_APPLICATION_DELETE_FAILED:
      return {
        ...state,
        deletedMessage: { loading: false, error: action.payload },
      }
    case USER_INSERT_APPLICATION_REQUEST:
      return {
        ...state,
        application: { loading: true },
      }
    case USER_INSERT_APPLICATION_SUCCESS:
      return {
        ...state,
        application: { loading: false, message: action.payload },
      }
    case USER_INSERT_APPLICATION_FAILED:
      return {
        ...state,
        application: { loading: false, error: action.payload },
      }
    case USER_APPLICATIONS_RETRIEVE_BYSTATUS_REQUEST:
      return {
        ...state,
        userApplicationsDetails: { loading: true },
      }
    case USER_APPLICATIONS_RETRIEVE_BYSTATUS:
      return {
        ...state,
        userApplicationsDetails: {
          loading: false,
          forPayment: action.payload.forPayment,
          forCompliance: action.payload.forCompliance,
          forInspection: action.payload.forInspection,
          forApproved: action.payload.forApproved,
        },
      }
    case USER_APPLICATIONS_RETRIEVE_BYSTATUS_FAILED:
      return {
        ...state,
        userApplicationsDetails: {
          loading: false,
          error: action.payload,
        },
      }
    case USER_APPLICATION_HISTORY_REQ:
      return {
        ...state,
        appHistory: {
          loading: true,
        },
      }
    case USER_APPLICATION_HISTORY_SUCCESS:
      return {
        ...state,
        appHistory: {
          loading: false,
          applicationHistory: action.payload,
        },
      }
    case USER_APPLICATION_HISTORY_FAILED:
      return {
        ...state,
        appHistory: {
          loading: false,
          error: action.payload,
        },
      }
    case USER_GETAPPLICATIONFORVERIFICATION_REQ:
      return {
        ...state,
        appVerification: {
          loading: true,
        },
      }
    case USER_GETAPPLICATIONFORVERIFICATION_SUCCESS:
      return {
        ...state,
        appVerification: {
          loading: false,
          message: action.payload,
        },
      }
    case USER_GETAPPLICATIONFORVERIFICATION_FAILED:
      return {
        ...state,
        appVerification: {
          loading: false,
          error: action.payload,
        },
      }
    case USER_GETAPPLICATIONFORINSPECTION_REQ:
      return {
        ...state,
        appInspection: {
          loading: true,
        },
      }
    case USER_GETAPPLICATIONFORINSPECTION_SUCCESS:
      return {
        ...state,
        appInspection: {
          loading: false,
          message: action.payload,
        },
      }
    case USER_GETAPPLICATIONFORINSPECTION_FAILED:
      return {
        ...state,
        appInspection: {
          loading: false,
          error: action.payload,
        },
      }
    case USER_GETAPPLICATIONFORCOMPLIANCE_REQ:
      return {
        ...state,
        appCompliance: {
          loading: true,
        },
      }
    case USER_GETAPPLICATIONFORCOMPLIANCE_SUCCESS:
      return {
        ...state,
        appCompliance: {
          loading: false,
          message: action.payload,
        },
      }
    case USER_GETAPPLICATIONFORCOMPLIANCE_FAILED:
      return {
        ...state,
        appCompliance: {
          loading: false,
          error: action.payload,
        },
      }
    case USER_GETAPPLICATIONAPPROVED_REQ:
      return {
        ...state,
        appApproved: {
          loading: true,
        },
      }
    case USER_GETAPPLICATIONAPPROVED_SUCCESS:
      return {
        ...state,
        appApproved: {
          loading: false,
          message: action.payload,
        },
      }
    case USER_GETAPPLICATIONAPPROVED_FAILED:
      return {
        ...state,
        appApproved: {
          loading: false,
          error: action.payload,
        },
      }
    case USER_APPSTATUSTRACK_REQ:
      return {
        ...state,
        statusTracking: {
          loading: true,
        },
      }
    case USER_APPSTATUSTRACK_SUCCESS:
      return {
        ...state,
        statusTracking: {
          loading: false,
          message: action.payload,
        },
      }
    case USER_APPSTATUSTRACK_FAILED:
      return {
        ...state,
        statusTracking: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_ORDEROFPAYMENT_REQ:
      return {
        ...state,
        retrievedOrderofPayment: {
          loading: true,
        },
      }
    case GET_ORDEROFPAYMENT_SUCCESS:
      return {
        ...state,
        retrievedOrderofPayment: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_ORDEROFPAYMENT_FAILED:
      return {
        ...state,
        retrievedOrderofPayment: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_RELEASEPERMIT_REQ:
      return {
        ...state,
        retrievedReleasePermit: {
          loading: true,
        },
      }
    case GET_RELEASEPERMIT_SUCCESS:
      return {
        ...state,
        retrievedReleasePermit: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_RELEASEPERMIT_FAILED:
      return {
        ...state,
        retrievedReleasePermit: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_RELEASECFEI_REQ:
      return {
        ...state,
        retrievedCFEI: {
          loading: true,
        },
      }
    case GET_RELEASECFEI_SUCCESS:
      return {
        ...state,
        retrievedCFEI: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_RELEASECFEI_FAILED:
      return {
        ...state,
        retrievedCFEI: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_LIST_REPORT_REQ:
      return {
        ...state,
        retrievedListReport: {
          loading: true,
        },
      }
    case GET_LIST_REPORT_SUCCESS:
      return {
        ...state,
        retrievedListReport: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_LIST_REPORT_FAILED:
      return {
        ...state,
        retrievedListReport: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_ENGAPPLIST_REQ:
      return {
        ...state,
        retrievedEngAppList: {
          loading: true,
        },
      }
    case GET_ENGAPPLIST_SUCCESS:
      return {
        ...state,
        retrievedEngAppList: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_ENGAPPLIST_FAILED:
      return {
        ...state,
        retrievedEngAppList: {
          loading: false,
          error: action.payload,
        },
      }
    case ACCEPT_ENGAPPLICATION_REQ:
      return {
        ...state,
        acceptedEngApplication: {
          loading: true,
        },
      }
    case ACCEPT_ENGAPPLICATION_SUCCESS:
      return {
        ...state,
        acceptedEngApplication: {
          loading: false,
          message: action.payload,
        },
      }
    case ACCEPT_ENGAPPLICATION_FAILED:
      return {
        ...state,
        acceptedEngApplication: {
          loading: false,
          error: action.payload,
        },
      }
    case DECLINE_ENGAPPLICATION_REQ:
      return {
        ...state,
        declinedEngApplication: {
          loading: true,
        },
      }
    case DECLINE_ENGAPPLICATION_SUCCESS:
      return {
        ...state,
        declinedEngApplication: {
          loading: false,
          message: action.payload,
        },
      }
    case DECLINE_ENGAPPLICATION_FAILED:
      return {
        ...state,
        declinedEngApplication: {
          loading: false,
          error: action.payload,
        },
      }
    case UPDATE_ENGIMAGEATTACHED_REQ:
      return {
        ...state,
        updatedEngAttachedImage: {
          loading: true,
        },
      }
    case UPDATE_ENGIMAGEATTACHED_SUCCESS:
      return {
        ...state,
        updatedEngAttachedImage: {
          loading: false,
          message: action.payload,
        },
      }
    case UPDATE_ENGIMAGEATTACHED_FAILED:
      return {
        ...state,
        updatedEngAttachedImage: {
          loading: false,
          error: action.payload,
        },
      }
    case UPDATE_RECORDED_ENG_REQ:
      return {
        ...state,
        updatedRecordedEng: {
          loading: true,
        },
      }
    case UPDATE_RECORDED_ENG_SUCCESS:
      return {
        ...state,
        updatedRecordedEng: {
          loading: false,
          message: action.payload,
        },
      }
    case UPDATE_RECORDED_ENG_FAILED:
      return {
        ...state,
        updatedRecordedEng: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_ENGLIST_REQ:
      return {
        ...state,
        retrievedEngrList: {
          loading: true,
        },
      }
    case GET_ENGLIST_SUCCESS:
      return {
        ...state,
        retrievedEngrList: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_ENGLIST_FAILED:
      return {
        ...state,
        retrievedEngrList: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_RECORDED_ENG_REQ:
      return {
        ...state,
        retrievedRecordedEng: {
          loading: true,
        },
      }
    case GET_RECORDED_ENG_SUCCESS:
      return {
        ...state,
        retrievedRecordedEng: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_RECORDED_ENG_FAILED:
      return {
        ...state,
        retrievedRecordedEng: {
          loading: false,
          error: action.payload,
        },
      }
    case GET_RELEASED_OCCU_REQ:
      return {
        ...state,
        retrievedReleasedOccu: {
          loading: true,
        },
      }
    case GET_RELEASED_OCCU_SUCCESS:
      return {
        ...state,
        retrievedReleasedOccu: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_RELEASED_OCCU_FAILED:
      return {
        ...state,
        retrievedReleasedOccu: {
          loading: false,
          error: action.payload,
        },
      }
    case PAYMENT_INITIALIZE_REQ:
      return {
        ...state,
        initializingPayment: { loading: true },
      }
    case PAYMENT_INITIALIZE_SUCCESS:
      return {
        ...state,
        initializingPayment: { loading: false, message: action.payload },
      }
    case PAYMENT_INITIALIZE_FAILED:
      return {
        ...state,
        initializingPayment: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
