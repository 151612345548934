export const GET_TOTAL_APP_REQ = 'GET_TOTAL_APP_REQ'
export const GET_TOTAL_APP_SUCCESS = 'GET_TOTAL_APP_SUCCESS'
export const GET_TOTAL_APP_FAILED = 'GET_TOTAL_APP_FAILED'

export const GET_EMPLOYEES_REQ = 'GET_EMPLOYEES_REQ'
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS'
export const GET_EMPLOYEES_FAILED = 'GET_EMPLOYEES_FAILED'

export const GET_USERS_REQ = 'GET_USERS_REQ'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'

export const GET_USERS_FOR_VERIFICATION_REQ = 'GET_USERS_FOR_VERIFICATION_REQ'
export const GET_USERS_FOR_VERIFICATION_SUCCESS =
  'GET_USERS_FOR_VERIFICATION_SUCCESS'
export const GET_USERS_FOR_VERIFICATION_FAILED =
  'GET_USERS_FOR_VERIFICATION_FAILED'

export const GET_REGISTERED_ENG_REQ = 'GET_REGISTERED_ENG_REQ'
export const GET_REGISTERED_ENG_SUCCESS = 'GET_REGISTERED_ENG_SUCCESS'
export const GET_REGISTERED_ENG_FAILED = 'GET_REGISTERED_ENG_FAILED'

export const GET_NOTICE_OF_VIOLATION_REQ = 'GET_NOTICE_OF_VIOLATION_REQ'
export const GET_NOTICE_OF_VIOLATION_SUCCESS = 'GET_NOTICE_OF_VIOLATION_SUCCESS'
export const GET_NOTICE_OF_VIOLATION_FAILED = 'GET_NOTICE_OF_VIOLATION_FAILED'

export const GET_NEWS_N_UPDATES_REQ = 'GET_NEWS_N_UPDATES_REQ'
export const GET_NEWS_N_UPDATES_SUCCESS = 'GET_NEWS_N_UPDATES_SUCCESS'
export const GET_NEWS_N_UPDATES_FAILED = 'GET_NEWS_N_UPDATES_FAILED'

export const GET_MESSAGES_REQ = 'GET_MESSAGES_REQ'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED'

export const GET_USERLIST_REQ = 'GET_USERLIST_REQ'
export const GET_USERLIST_SUCCESS = 'GET_USERLIST_SUCCESS'
export const GET_USERLIST_FAILED = 'GET_USERLIST_FAILED'

export const GET_APPSUMMARY_REQ = 'GET_APPSUMMARY_REQ'
export const GET_APPSUMMARY_SUCCESS = 'GET_APPSUMMARY_SUCCESS'
export const GET_APPSUMMARY_FAILED = 'GET_APPSUMMARY_FAILED'

export const GET_NEWSNUPDATELIST_REQ = 'GET_NEWSNUPDATELIST_REQ'
export const GET_NEWSNUPDATELIST_SUCCESS = 'GET_NEWSNUPDATELIST_SUCCESS'
export const GET_NEWSNUPDATELIST_FAILED = 'GET_NEWSNUPDATELIST_FAILED'

export const GET_EMPSIGNATURE_REQ = 'GET_EMPSIGNATURE_REQ'
export const GET_EMPSIGNATURE_SUCCESS = 'GET_EMPSIGNATURE_SUCCESS'
export const GET_EMPSIGNATURE_FAILED = 'GET_EMPSIGNATURE_FAILED'

export const UPDATE_DLFORMS_REQ = 'UPDATE_DLFORMS_REQ'
export const UPDATE_DLFORMS_SUCCESS = 'UPDATE_DLFORMS_SUCCESS'
export const UPDATE_DLFORMS_FAILED = 'UPDATE_DLFORMS_FAILED'

export const UPDATE_USERPASSWORD_REQ = 'UPDATE_USERPASSWORD_REQ'
export const UPDATE_USERPASSWORD_SUCCESS = 'UPDATE_USERPASSWORD_SUCCESS'
export const UPDATE_USERPASSWORD_FAILED = 'UPDATE_USERPASSWORD_FAILED'

export const UPDATE_NEWSNUPDATEINFO_REQ = 'UPDATE_NEWSNUPDATEINFO_REQ'
export const UPDATE_NEWSNUPDATEINFO_SUCCESS = 'UPDATE_NEWSNUPDATEINFO_SUCCESS'
export const UPDATE_NEWSNUPDATEINFO_FAILED = 'UPDATE_NEWSNUPDATEINFO_FAILED'

export const UPDATE_EMPSIGNATURE_REQ = 'UPDATE_EMPSIGNATURE_REQ'
export const UPDATE_EMPSIGNATURE_SUCCESS = 'UPDATE_EMPSIGNATURE_SUCCESS'
export const UPDATE_EMPSIGNATURE_FAILED = 'UPDATE_EMPSIGNATURE_FAILED'

export const CREATE_DLFORMS_REQ = 'CREATE_DLFORMS_REQ'
export const CREATE_DLFORMS_SUCCESS = 'CREATE_DLFORMS_SUCCESS'
export const CREATE_DLFORMS_FAILED = 'CREATE_DLFORMS_FAILED'

export const CREATE_NEWSNUPDATEINFO_REQ = 'CREATE_NEWSNUPDATEINFO_REQ'
export const CREATE_NEWSNUPDATEINFO_SUCCESS = 'CREATE_NEWSNUPDATEINFO_SUCCESS'
export const CREATE_NEWSNUPDATEINFO_FAILED = 'CREATE_NEWSNUPDATEINFO_FAILED'

export const CREATE_EMPSIGNATURE_REQ = 'CREATE_EMPSIGNATURE_REQ'
export const CREATE_EMPSIGNATURE_SUCCESS = 'CREATE_EMPSIGNATURE_SUCCESS'
export const CREATE_EMPSIGNATURE_FAILED = 'CREATE_EMPSIGNATURE_FAILED'

export const DELETE_DLFORMS_REQ = 'DELETE_DLFORMS_REQ'
export const DELETE_DLFORMS_SUCCESS = 'DELETE_DLFORMS_SUCCESS'
export const DELETE_DLFORMS_FAILED = 'DELETE_DLFORMS_FAILED'

export const DELETE_USER_REQ = 'DELETE_USER_REQ'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const DELETE_NEWSNUPDATEINFO_REQ = 'DELETE_NEWSNUPDATEINFO_REQ'
export const DELETE_NEWSNUPDATEINFO_SUCCESS = 'DELETE_NEWSNUPDATEINFO_SUCCESS'
export const DELETE_NEWSNUPDATEINFO_FAILED = 'DELETE_NEWSNUPDATEINFO_FAILED'
