export const CREATE_CERTIFICATES_ASSESSMENT_REQ =
  'CREATE_CERTIFICATES_ASSESSMENT_REQ'
export const CREATE_CERTIFICATES_ASSESSMENT_SUCCESS =
  'CREATE_CERTIFICATES_ASSESSMENT_SUCCESS'
export const CREATE_CERTIFICATES_ASSESSMENT_FAILED =
  'CREATE_CERTIFICATES_ASSESSMENT_FAILED'

export const UPDATE_CERTIFICATES_ASSESSMENT_REQ =
  'UPDATE_CERTIFICATES_ASSESSMENT_REQ'
export const UPDATE_CERTIFICATES_ASSESSMENT_SUCCESS =
  'UPDATE_CERTIFICATES_ASSESSMENT_SUCCESS'
export const UPDATE_CERTIFICATES_ASSESSMENT_FAILED =
  'UPDATE_CERTIFICATES_ASSESSMENT_FAILED'

export const GET_CERTIFICATES_ASSESSMENT_REQ = 'GET_CERTIFICATES_ASSESSMENT_REQ'
export const GET_CERTIFICATES_ASSESSMENT_SUCCESS =
  'GET_CERTIFICATES_ASSESSMENT_SUCCESS'
export const GET_CERTIFICATES_ASSESSMENT_FAILED =
  'GET_CERTIFICATES_ASSESSMENT_FAILED'
