export const USER_APPOINTMENT_REQUEST = 'USER_APPOINTMENT_REQUEST'
export const USER_APPOINTMENT_SUCCESS = 'USER_APPOINTMENT_SUCCESS'
export const USER_APPOINTMENT_FAILED = 'USER_APPOINTMENT_FAILED'

export const USER_APPOINTMENT_GETBYID_REQUEST =
  'USER_APPOINTMENT_GETBYID_REQUEST'
export const USER_APPOINTMENT_GETBYID_SUCCESS =
  'USER_APPOINTMENT_GETBYID_SUCCESS'
export const USER_APPOINTMENT_GETBYID_FAILED = 'USER_APPOINTMENT_GETBYID_FAILED'

export const USER_APPOINTMENT_INSERT_REQUEST = 'USER_APPOINTMENT_INSERT_REQUEST'
export const USER_APPOINTMENT_INSERT_SUCCESS = 'USER_APPOINTMENT_INSERT_SUCCESS'
export const USER_APPOINTMENT_INSERT_FAILED = 'USER_APPOINTMENT_INSERT_FAILED'

export const USER_APPOINTMENT_UPDATE_REQUEST = 'USER_APPOINTMENT_UPDATE_REQUEST'
export const USER_APPOINTMENT_UPDATE_SUCCESS = 'USER_APPOINTMENT_UPDATE_SUCCESS'
export const USER_APPOINTMENT_UPDATE_FAILED = 'USER_APPOINTMENT_UPDATE_FAILED'

export const USER_APPOINTMENT_DELETE_REQUEST = 'USER_APPOINTMENT_DELETE_REQUEST'
export const USER_APPOINTMENT_DELETE_SUCCESS = 'USER_APPOINTMENT_DELETE_SUCCESS'
export const USER_APPOINTMENT_DELETE_FAILED = 'USER_APPOINTMENT_DELETE_FAILED'

export const GET_APPOINTMENT_COUNT_REQ = 'GET_APPOINTMENT_COUNT_REQ'
export const GET_APPOINTMENT_COUNT_SUCCESS = 'GET_APPOINTMENT_COUNT_SUCCESS'
export const GET_APPOINTMENT_COUNT_FAILED = 'GET_APPOINTMENT_COUNT_FAILED'
