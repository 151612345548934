export const GET_ISUPERVISOR_FORRECOMMENDATION_REQ =
  'GET_ISUPERVISOR_FORRECOMMENDATION_REQ'
export const GET_ISUPERVISOR_FORRECOMMENDATION_SUCCESS =
  'GET_ISUPERVISOR_FORRECOMMENDATION_SUCCESS'
export const GET_ISUPERVISOR_FORRECOMMENDATION_FAILED =
  'GET_ISUPERVISOR_FORRECOMMENDATION_FAILED'

export const GET_INSPECTIONDETAILS_REQ = 'GET_INSPECTIONDETAILS_REQ'
export const GET_INSPECTIONDETAILS_SUCCESS = 'GET_INSPECTIONDETAILS_SUCCESS'
export const GET_INSPECTIONDETAILS_FAILED = 'GET_INSPECTIONDETAILS_FAILED'

export const GET_ISUPERVISOR_RECOMMENDED_REQ = 'GET_ISUPERVISOR_RECOMMENDED_REQ'
export const GET_ISUPERVISOR_RECOMMENDED_SUCCESS =
  'GET_ISUPERVISOR_RECOMMENDED_SUCCESS'
export const GET_ISUPERVISOR_RECOMMENDED_FAILED =
  'GET_ISUPERVISOR_RECOMMENDED_FAILED'

export const GET_ISUPERVISOR_LISTREPORT_REQ = 'GET_ISUPERVISOR_LISTREPORT_REQ'
export const GET_ISUPERVISOR_LISTREPORT_SUCCESS =
  'GET_ISUPERVISOR_LISTREPORT_SUCCESS'
export const GET_ISUPERVISOR_LISTREPORT_FAILED =
  'GET_ISUPERVISOR_LISTREPORT_FAILED'

export const GET_ISUPERVISOR_PENALTY_REQ = 'GET_ISUPERVISOR_PENALTY_REQ'
export const GET_ISUPERVISOR_PENALTY_SUCCESS = 'GET_ISUPERVISOR_PENALTY_SUCCESS'
export const GET_ISUPERVISOR_PENALTY_FAILED = 'GET_ISUPERVISOR_PENALTY_FAILED'

export const INSERT_ISUPERVISOR_COMMENT_REQ = 'INSERT_ISUPERVISOR_COMMENT_REQ'
export const INSERT_ISUPERVISOR_COMMENT_SUCCESS =
  'INSERT_ISUPERVISOR_COMMENT_SUCCESS'
export const INSERT_ISUPERVISOR_COMMENT_FAILED =
  'INSERT_ISUPERVISOR_COMMENT_FAILED'

export const INSERT_ISUPERVISOR_RECOMMENDATION_REQ =
  'INSERT_ISUPERVISOR_RECOMMENDATION_REQ'
export const INSERT_ISUPERVISOR_RECOMMENDATION_SUCCESS =
  'INSERT_ISUPERVISOR_RECOMMENDATION_SUCCESS'
export const INSERT_ISUPERVISOR_RECOMMENDATION_FAILED =
  'INSERT_ISUPERVISOR_RECOMMENDATION_FAILED'

export const INSERT_UPDATE_PENALTY_REQ = 'INSERT_UPDATE_PENALTY_REQ'
export const INSERT_UPDATE_PENALTY_SUCCESS = 'INSERT_UPDATE_PENALTY_SUCCESS'
export const INSERT_UPDATE_PENALTY_FAILED = 'INSERT_UPDATE_PENALTY_FAILED'

export const UPDATE_ADMINFINE_REQ = 'UPDATE_ADMINFINE_REQ'
export const UPDATE_ADMINFINE_SUCCESS = 'UPDATE_ADMINFINE_SUCCESS'
export const UPDATE_ADMINFINE_FAILED = 'UPDATE_ADMINFINE_FAILED'

export const INSERT_RETURNAPPINSPECTION_REQ = 'INSERT_RETURNAPPINSPECTION_REQ'
export const INSERT_RETURNAPPINSPECTION_SUCCESS =
  'INSERT_RETURNAPPINSPECTION_SUCCESS'
export const INSERT_RETURNAPPINSPECTION_FAILED =
  'INSERT_RETURNAPPINSPECTION_FAILED'
