import {
  GET_FOR_ASSESSMENT_REQ,
  GET_FOR_ASSESSMENT_SUCCESS,
  GET_FOR_ASSESSMENT_FAILED,
  GET_WITH_ASSESSMENT_REQ,
  GET_WITH_ASSESSMENT_SUCCESS,
  GET_WITH_ASSESSMENT_FAILED,
} from '../constants/assessmentConstants'

export const assessmentReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_FOR_ASSESSMENT_REQ:
      return {
        ...state,
        forAssessment: { loading: true },
      }
    case GET_FOR_ASSESSMENT_SUCCESS:
      return {
        ...state,
        forAssessment: { loading: false, message: action.payload },
      }
    case GET_FOR_ASSESSMENT_FAILED:
      return {
        ...state,
        forAssessment: { loading: false, error: action.payload },
      }

    case GET_WITH_ASSESSMENT_REQ:
      return {
        ...state,
        withAssessment: { loading: true },
      }
    case GET_WITH_ASSESSMENT_SUCCESS:
      return {
        ...state,
        withAssessment: { loading: false, message: action.payload },
      }
    case GET_WITH_ASSESSMENT_FAILED:
      return {
        ...state,
        withAssessment: { loading: false, error: action.payload },
      }

    default:
      return state
  }
}
