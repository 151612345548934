import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Col,
  Row,
  Card,
  Image,
  Container,
  Form,
  Button,
  Tabs,
  Tab,
  Modal,
  Spinner,
} from 'react-bootstrap'
import {
  FaMobileAlt,
  FaEnvelope,
  FaInfoCircle,
  FaCheckCircle,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'

import UserMain from '../layout/UserLayout/UserMain'
import {
  getUserProfile,
  updateUserBasicInfo,
  updateUserPerAddress,
  updateUserPreAddress,
  updateUserProfessionInfo,
  updateUserCredentials,
  deleteUserAccount,
  retrieveRegion,
  retrieveCities,
} from '../redux/actions/userProfileAction'
import { Logout } from '../redux/actions/userActions'

// Data
import { provinces } from '../../data/provinces'

// Components
import SuccessModal from '../reusable/SuccessModal'

const ProfileScreen = ({
  fontWeight,
  background,
  border,
  borderRadius,
  history,
}) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users)
  const { userCredentials } = user

  const userProfile = useSelector((state) => state.userProfile)
  const {
    loading,
    profile,
    message,
    userDeleted,
    retrievedRegion,
    updatedUserCredentials,
  } = userProfile

  const [details] = useState({
    id: userCredentials.id,
  })

  const [passwordValid, setPasswordValid] = useState(false)

  const [key, setKey] = useState('profile')
  const [showProfession, setShowProfession] = useState(false)
  const [success, setSuccess] = useState(false)

  const [successCredentials, setSuccessCredentials] = useState(false)

  const [profileInfo, setProfileInfo] = useState({
    title: '',
    B_id: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    DOB: '',
    CivilStatus: '',
    MobileNo: '',
    Gender: '',
    EmailAddress: '',
    PA_id: '',
    Permanent_NoStreet: '',
    Permanent_Subd: '',
    Permanent_BrgyDistrict: '',
    Permanent_Province: '',
    Permanent_City: '',
    Permanent_Country: '',
    Permanent_ZipCode: '',
    PRA_id: '',
    Present_NoStreet: '',
    Present_Subd: '',
    Present_BrgyDistrict: '',
    Present_Province: '',
    Present_City: '',
    Present_Country: '',
    Present_ZipCode: '',
    PI_id: '',
    ProfessionName: '',
    ProfessionNo: '',
    id: '',
    username: '',
    password: '',
    confirmPassword: '',
    userID: '',
    notification: '',
    provinces: [],
    cities: [],
    barangays: [],
    Permanentcities: [],
    Permanentbarangays: [],
    disabled: false,
  })

  const [deleteModal, setDeleteModal] = useState(false)

  const [address, setAddress] = useState({
    presentCities: [],
    permanentCities: [],
    presentBarangays: [],
    permanentBarangays: [],
  })

  const handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        setProfileInfo({
          ...profileInfo,
          [e.target.name]: 1,
          Present_NoStreet: profile[0].Permanent_NoStreet,
          Present_Subd: profile[0].Permanent_Subd,
          Present_BrgyDistrict: profile[0].Permanent_BrgyDistrict,
          Present_Province: profile[0].Permanent_Province,
          Present_City: profile[0].Permanent_City,
          Present_Country: profile[0].Permanent_Country,
          Present_ZipCode: profile[0].Permanent_ZipCode,
          disabled: true,
        })
      } else {
        setProfileInfo({
          ...profileInfo,
          [e.target.name]: 0,
          Present_NoStreet: profile[0].Present_NoStreet,
          Present_Subd: profile[0].Present_Subd,
          Present_BrgyDistrict: profile[0].Present_BrgyDistrict,
          Present_Province: profile[0].Present_Province,
          Present_City: profile[0].Present_City,
          Present_Country: profile[0].Present_Country,
          Present_ZipCode: profile[0].Present_ZipCode,
          cities: [],
          barangays: [],
          disabled: false,
        })
      }
    } else {
      if (e.target.name === 'MobileNo') {
        setProfileInfo({
          ...profileInfo,
          [e.target.name]: e.target.value.replace(/\D/g, ''),
        })
      } else {
        setProfileInfo({
          ...profileInfo,
          [e.target.name]: e.target.value,
        })
      }
    }
  }

  // const handleChangePassword = (e) => {

  // }

  const handleEditProfession = (id, ProfessionName, ProfessionNo) => {
    setShowProfession(true)
    setProfileInfo({
      ...profileInfo,
      title: 'Edit Professional Info',
      PI_id: id,
      ProfessionName: ProfessionName,
      ProfessionNo: ProfessionNo,
      userID: userCredentials.id,
    })
  }

  const handleEditPermanent = () => {
    setShowProfession(true)
    setProfileInfo({
      ...profileInfo,
      title: 'Edit Permanent Address',
      PA_id: profile[0].PA_id,
      Permanent_NoStreet: profile[0].Permanent_NoStreet,
      Permanent_Subd: profile[0].Permanent_Subd,
      Permanent_BrgyDistrict: profile[0].Permanent_BrgyDistrict,
      Permanent_Province: profile[0].Permanent_Province,
      Permanent_City: profile[0].Permanent_City,
      Permanent_Country: profile[0].Permanent_Country,
      Permanent_ZipCode: profile[0].Permanent_ZipCode,
      userID: userCredentials.id,
    })
    dispatch(retrieveRegion({ extensions: '/regions' }))
  }

  const handleEditPresent = () => {
    setShowProfession(true)
    setProfileInfo({
      ...profileInfo,
      title: 'Edit Present Address',
      PRA_id: profile[0].PRA_id,
      Present_NoStreet: profile[0].Present_NoStreet,
      Present_Subd: profile[0].Present_Subd,
      Present_BrgyDistrict: profile[0].Present_BrgyDistrict,
      Present_Province: profile[0].Present_Province,
      Present_City: profile[0].Present_City,
      Present_Country: profile[0].Present_Country,
      Present_ZipCode: profile[0].Present_ZipCode,
      userID: userCredentials.id,
    })
    dispatch(retrieveRegion({ extensions: '/regions' }))
  }

  const handleSubmitPermanent = (e) => {
    e.preventDefault()
    dispatch(
      updateUserPerAddress({
        Permanent_NoStreet: profileInfo.Permanent_NoStreet,
        Permanent_Subd: profileInfo.Permanent_Subd,
        Permanent_BrgyDistrict: profileInfo.Permanent_BrgyDistrict,
        Permanent_Province: profileInfo.Permanent_Province,
        Permanent_City: profileInfo.Permanent_City,
        Permanent_Country: profileInfo.Permanent_Country,
        Permanent_ZipCode: profileInfo.Permanent_ZipCode,
        PA_id: profileInfo.PA_id,
      })
    )
    setSuccess(true)
    setShowProfession(false)
  }

  const handleSubmitPresent = (e) => {
    e.preventDefault()
    dispatch(
      updateUserPreAddress({
        Present_NoStreet: profileInfo.Present_NoStreet,
        Present_Subd: profileInfo.Present_Subd,
        Present_BrgyDistrict: profileInfo.Present_BrgyDistrict,
        Present_Province: profileInfo.Present_Province,
        Present_City: profileInfo.Present_City,
        Present_Country: profileInfo.Present_Country,
        Present_ZipCode: profileInfo.Present_ZipCode,
        PRA_id: profileInfo.PRA_id,
      })
    )
    setSuccess(true)
    setShowProfession(false)
  }

  const handleSubmitProfession = (e) => {
    e.preventDefault()
    dispatch(
      updateUserProfessionInfo({
        ProfessionName: profileInfo.ProfessionName,
        ProfessionNo: profileInfo.ProfessionNo,
        PI_id: profileInfo.PI_id,
      })
    )
    setSuccess(true)
    setShowProfession(false)
  }

  const handleBasicInfoSubmit = (e) => {
    if (window.confirm('Are you sure you want to update basic information?')) {
      e.preventDefault()
      dispatch(updateUserBasicInfo(profileInfo))
      setSuccess(true)
    }
  }

  const handleSubmitCredentials = (e) => {
    e.preventDefault()
    if (
      window.confirm('Are you sure you want to change your user credentials?')
    ) {
      e.preventDefault()
      if (profileInfo.password === profileInfo.confirmPassword) {
        dispatch(
          updateUserCredentials({
            username: profileInfo.username,
            password: profileInfo.password,
          })
        )
        setSuccessCredentials(true)
        // setTimeout(() => {
        //   dispatch(Logout())
        //   history.push('/')
        // }, 2000)
      } else {
        e.preventDefault()
        alert('Password and Confirm Password mismatch!')
      }
    }
  }

  const ClearInfo = () => {
    setShowProfession(false)
  }

  const handleDeleteUser = () => {
    if (window.confirm('Are you sure wanted to delete your account?')) {
      dispatch(deleteUserAccount(details))
      setDeleteModal(true)
    }
  }

  const [DOBLimit, setDOBLimit] = useState({
    maxDate: '',
    minDate: '',
  })

  const maxDate = () => {
    let dateMax = new Date()
    let dateMin = new Date()

    dateMax.setFullYear(dateMax.getFullYear() - 18)

    dateMin.setFullYear(dateMin.getFullYear() - 85)

    let maxYear = dateMax.getFullYear()
    let maxMonth = ('0' + (dateMax.getMonth() + 1)).slice(-2)
    let maxDay = ('0' + dateMax.getDate()).slice(-2)

    let minYear = dateMin.getFullYear()
    let minMonth = ('0' + (dateMin.getMonth() + 1)).slice(-2)
    let minDay = ('0' + dateMin.getDate()).slice(-2)

    let maxDate = maxYear + '-' + maxMonth + '-' + maxDay

    let minDate = minYear + '-' + minMonth + '-' + minDay

    if (DOBLimit.maxDate === '' && DOBLimit.minDate === '') {
      setDOBLimit({
        maxDate: maxDate,
        minDate: minDate,
      })
    }
  }

  useEffect(() => {
    maxDate()
  })

  useEffect(() => {
    dispatch(getUserProfile(details))
  }, [dispatch, details])

  useEffect(() => {
    if (profile) {
      switch (key) {
        case 'profile':
          return setProfileInfo((profileInfo) => ({
            ...profileInfo,
            B_id: profile[0].id,
            FirstName: profile[0].FirstName,
            MiddleName: profile[0].MiddleName,
            LastName: profile[0].LastName,
            DOB: profile[0].DOB,
            CivilStatus: profile[0].CivilStatus,
            MobileNo: profile[0].MobileNo,
            Gender: profile[0].Gender,
            EmailAddress: profile[0].EmailAddress,
            userID: userCredentials.id,
            provinces: provinces,
          }))
        case 'user-credentials':
          return setProfileInfo((profileInfo) => ({
            ...profileInfo,
            id: userCredentials.id,
            username: profile[0].username,
            provinces: provinces,
          }))
        default:
          return profile
      }
    }
  }, [profile, key, userCredentials.id])

  // console.log(profileInfo)

  return (
    <UserMain>
      <Container fluid>
        <Container
          fluid
          className='my-2 shadow-lg pb-5 px-4'
          style={{ background: '#FFF' }}
        >
          {loading && (
            <div className='text-center pt-5'>
              <Spinner animation='border' />
              <h3>Loading...</h3>
            </div>
          )}

          {!loading &&
            profileInfo.notification === '' &&
            profile &&
            profile[0].PRA_id !== null && (
              <Row className='mx-2'>
                <Col xl={3} className='my-3'>
                  <Row>
                    <Col
                      xl={12}
                      style={{ background }}
                      className='d-flex justify-content-center py-4 shadow-lg'
                    >
                      <Image
                        src='assets/img/userAvatar3.webp'
                        roundedCircle
                        width='100'
                        height='100'
                      />
                    </Col>
                    <Col xl={12} style={{ background }} className='text-center'>
                      {profile &&
                        profile[0].FirstName +
                          ' ' +
                          profile[0].MiddleName +
                          ' ' +
                          profile[0].LastName}
                    </Col>
                    <Col
                      xl={12}
                      style={{ background }}
                      className='text-center pt-2'
                    >
                      <FaMobileAlt className='mr-1' />
                      {profile && profile[0].MobileNo}
                    </Col>
                    <Col
                      xl={12}
                      style={{ background }}
                      className='text-center pt-2 pb-5'
                    >
                      <FaEnvelope /> {profile && profile[0].EmailAddress}
                      <hr />
                    </Col>
                  </Row>
                </Col>
                <Col xl={1}></Col>
                <Col xl={8} className='my-3'>
                  <Row>
                    <Col
                      xl={12}
                      className='py-3 mb-5 px-3 shadow-lg'
                      style={{ background }}
                    >
                      <h3>Edit Profile</h3>
                      <hr className='mt-0 mb-2' />
                      <Tabs
                        defaultActiveKey={key}
                        className='mb-3'
                        onSelect={(k) => setKey(k)}
                      >
                        <Tab eventKey='profile' title='BASIC INFO'>
                          <Form onSubmit={handleBasicInfoSubmit}>
                            <Card
                              border='success'
                              className='p-3'
                              id='basic-info'
                            >
                              <Form.Label
                                className='m-0 p-0'
                                style={{ fontWeight }}
                              >
                                Basic Information
                              </Form.Label>
                              <hr className='m-0 mb-3' />
                              <Row>
                                <Form.Group as={Col} xl='4'>
                                  <Form.Label className='m-0'>
                                    Last Name
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    style={{ background, border, borderRadius }}
                                    onChange={handleChange}
                                    value={profileInfo.LastName}
                                    name='LastName'
                                  ></Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xl='4'>
                                  <Form.Label className='m-0'>
                                    First Name
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    style={{ background, border, borderRadius }}
                                    onChange={handleChange}
                                    value={profileInfo.FirstName}
                                    name='FirstName'
                                  ></Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xl='4'>
                                  <Form.Label className='m-0'>
                                    Middle Name
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    style={{ background, border, borderRadius }}
                                    onChange={handleChange}
                                    value={profileInfo.MiddleName}
                                    name='MiddleName'
                                  ></Form.Control>
                                </Form.Group>
                              </Row>
                              <Row>
                                <Form.Group as={Col} xl='6'>
                                  <Form.Label>BirthDate</Form.Label>
                                  <Form.Control
                                    type='date'
                                    style={{ background, border, borderRadius }}
                                    onChange={handleChange}
                                    value={profileInfo.DOB}
                                    name='DOB'
                                    max={DOBLimit.maxDate}
                                    min={DOBLimit.minDate}
                                    className='mt-1'
                                    onKeyDown={(e) => e.preventDefault()}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} xl='6'>
                                  <Form.Label>Mobile No.</Form.Label>
                                  <Row>
                                    <Col xl='3'>
                                      <Form.Control
                                        type='text'
                                        placeholder='639'
                                        className='mt-1'
                                        style={{ background, border }}
                                        name='MobileNo'
                                        disabled
                                      />
                                    </Col>
                                    <Col xl='9'>
                                      <Form.Control
                                        type='text'
                                        style={{
                                          background,
                                          border,
                                          borderRadius,
                                        }}
                                        onChange={handleChange}
                                        value={profileInfo.MobileNo}
                                        className='mt-1'
                                        name='MobileNo'
                                        maxLength={10}
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Row>
                              <Row>
                                <Form.Group as={Col} xl='6'>
                                  <Form.Label>Gender</Form.Label>
                                  <Form.Control
                                    as='select'
                                    style={{ background, border, borderRadius }}
                                    onChange={handleChange}
                                    value={profileInfo.Gender}
                                    name='Gender'
                                  >
                                    <option value=''>Choose...</option>
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xl='6'>
                                  <Form.Label>Civil Status</Form.Label>
                                  <Form.Control
                                    as='select'
                                    style={{ background, border, borderRadius }}
                                    onChange={handleChange}
                                    value={profileInfo.CivilStatus}
                                    name='CivilStatus'
                                  >
                                    <option value=''>Choose...</option>
                                    <option>Single</option>
                                    <option>Married</option>
                                    <option>Divorse</option>
                                    <option>Widowed</option>
                                  </Form.Control>
                                </Form.Group>
                              </Row>
                              <Row>
                                <Form.Group as={Col} xl='12'>
                                  <Form.Label>Email Address</Form.Label>
                                  <Form.Control
                                    type='text'
                                    style={{ background, border, borderRadius }}
                                    onChange={handleChange}
                                    value={profileInfo.EmailAddress}
                                    name='EmailAddress'
                                  ></Form.Control>
                                </Form.Group>
                              </Row>
                            </Card>
                            <Row>
                              <Col
                                xl={12}
                                className='d-flex justify-content-end'
                              >
                                <Button
                                  type='submit'
                                  className='mt-4'
                                  variant='info'
                                >
                                  Update
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Tab>

                        <Tab eventKey='address' title='ADDRESS'>
                          {profile && (
                            <Form>
                              <Card
                                border='success'
                                className='mt-3 p-3'
                                id='present-address'
                              >
                                <Form.Label
                                  className='m-0 p-0'
                                  style={{ fontWeight }}
                                >
                                  Present Address
                                </Form.Label>
                                <hr className='m-0 mb-3' />

                                <Row>
                                  <Form.Group as={Col} xl='4'>
                                    <Form.Label>No. / Street</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Present_NoStreet}
                                      name='Present_NoStreet'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                  <Form.Group as={Col} xl='4'>
                                    <Form.Label>Subd.</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Present_Subd}
                                      name='Present_Subd'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                  <Form.Group as={Col} xl='4'>
                                    <Form.Label>Brgy./District</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Present_BrgyDistrict}
                                      name='Present_BrgyDistrict'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                </Row>

                                <Row>
                                  <Form.Group as={Col} xl='3'>
                                    <Form.Label>Province</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Present_Province}
                                      name='Present_Province'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                  <Form.Group as={Col} xl='3'>
                                    <Form.Label>City/Municipality</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Present_City}
                                      name='Present_City'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                  <Form.Group as={Col} xl='3'>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Present_Country}
                                      name='Present_Country'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                  <Form.Group as={Col} xl='3'>
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Present_ZipCode}
                                      name='Present_ZipCode'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                </Row>
                                <Row>
                                  <Col
                                    xl={12}
                                    className='d-flex justify-content-end'
                                  >
                                    <Button
                                      onClick={handleEditPresent}
                                      className='mt-4'
                                      variant='info'
                                    >
                                      EDIT
                                    </Button>
                                  </Col>
                                </Row>
                              </Card>

                              <Card
                                border='success'
                                className='mt-3 p-3'
                                id='permanent-address'
                              >
                                <Form.Label
                                  className='m-0 p-0'
                                  style={{ fontWeight }}
                                >
                                  Permanent Address
                                </Form.Label>
                                <hr className='m-0 mb-3' />

                                <Row>
                                  <Form.Group as={Col} xl='4'>
                                    <Form.Label>No. / Street</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Permanent_NoStreet}
                                      name='Permanent_NoStreet'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                  <Form.Group as={Col} xl='4'>
                                    <Form.Label>Subd.</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Permanent_Subd}
                                      name='Permanent_Subd'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                  <Form.Group as={Col} xl='4'>
                                    <Form.Label>Brgy./District</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Permanent_BrgyDistrict}
                                      name='Permanent_BrgyDistrict'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                </Row>

                                <Row>
                                  <Form.Group as={Col} xl='3'>
                                    <Form.Label>Province</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Permanent_Province}
                                      name='Permanent_Province'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                  <Form.Group as={Col} xl='3'>
                                    <Form.Label>City/Municipality</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Permanent_City}
                                      name='Permanent_City'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                  <Form.Group as={Col} xl='3'>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Permanent_Country}
                                      name='Permanent_Country'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                  <Form.Group as={Col} xl='3'>
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control
                                      type='text'
                                      style={{
                                        background,
                                        border,
                                        borderRadius,
                                      }}
                                      onChange={handleChange}
                                      value={profile[0].Permanent_ZipCode}
                                      name='Permanent_ZipCode'
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                </Row>
                                <Row>
                                  <Col
                                    xl={12}
                                    className='d-flex justify-content-end'
                                  >
                                    <Button
                                      onClick={handleEditPermanent}
                                      className='mt-4'
                                      variant='info'
                                    >
                                      EDIT
                                    </Button>
                                  </Col>
                                </Row>
                              </Card>
                            </Form>
                          )}
                        </Tab>

                        <Tab
                          eventKey='professional-info'
                          title='PROFESSIONAL INFORMATION'
                        >
                          <Form>
                            <Card
                              border='success'
                              className='mt-3 p-3'
                              id='professional-details'
                            >
                              <Form.Label
                                className='m-0 p-0'
                                style={{ fontWeight }}
                              >
                                Professional Details
                              </Form.Label>
                              <hr className='m-0 mb-3' />

                              {profile &&
                                profile.map((x) => (
                                  <Row key={x.PI_id}>
                                    <Form.Group as={Col} xl={5}>
                                      <Form.Label>Profession</Form.Label>
                                      <Form.Control
                                        type='text'
                                        style={{
                                          background,
                                          border,
                                          borderRadius,
                                        }}
                                        disabled={true}
                                        value={x.ProfessionName || ''}
                                      ></Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} xl={5}>
                                      <Form.Label>Profession No.</Form.Label>

                                      <Form.Control
                                        type='text'
                                        style={{
                                          background,
                                          border,
                                          borderRadius,
                                        }}
                                        value={x.ProfessionNo || ''}
                                        disabled={true}
                                      ></Form.Control>
                                    </Form.Group>

                                    <Col xl={2} className='mt-2'>
                                      <Button
                                        className='mt-4'
                                        variant='info'
                                        onClick={() =>
                                          handleEditProfession(
                                            x.PI_id,
                                            x.ProfessionName,
                                            x.ProfessionNo
                                          )
                                        }
                                      >
                                        EDIT
                                      </Button>
                                    </Col>
                                  </Row>
                                ))}
                            </Card>
                          </Form>
                        </Tab>

                        <Tab
                          eventKey='user-credentials'
                          title='USER CREDENTIALS'
                        >
                          <Form onSubmit={handleSubmitCredentials}>
                            <Card
                              border='success'
                              className='mt-3 p-3'
                              id='professional-details'
                            >
                              <Form.Label
                                className='m-0 p-0'
                                style={{ fontWeight }}
                              >
                                User Credentials
                              </Form.Label>
                              <hr className='m-0 mb-3' />
                              <Row>
                                <Form.Group as={Col} xl='6'>
                                  <Form.Label>Username</Form.Label>
                                  <Form.Control
                                    type='text'
                                    style={{ background, border, borderRadius }}
                                    onChange={handleChange}
                                    value={profileInfo.username}
                                    required
                                    name='username'
                                  ></Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xl='6'>
                                  <Form.Label className='d-flex justify-content-between'>
                                    Password
                                  </Form.Label>
                                  <Form.Control
                                    type='password'
                                    style={{ background, border, borderRadius }}
                                    onChange={handleChange}
                                    value={profileInfo.password}
                                    name='password'
                                    required
                                    minLength={8}
                                    maxLength={16}
                                    pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$'
                                  ></Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xl='6' className='pt-2'>
                                  <Form.Label className='d-flex justify-content-between'>
                                    Confirm Password
                                  </Form.Label>
                                  <Form.Control
                                    type='password'
                                    style={{ background, border, borderRadius }}
                                    onChange={handleChange}
                                    value={profileInfo.confirmPassword}
                                    name='confirmPassword'
                                    required
                                    minLength={8}
                                    maxLength={16}
                                  ></Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xl='6' className='pt-2'>
                                  <i>
                                    "NOTE: <br />
                                    Password has more than 8 characters <br />
                                    Password has special characters <br />
                                    Password has a number <br />
                                  </i>
                                </Form.Group>
                              </Row>
                            </Card>
                            <Row>
                              <Col
                                xl={12}
                                className='d-flex justify-content-end'
                              >
                                <Button
                                  type='submit'
                                  className='mt-4'
                                  variant='info'
                                >
                                  Update
                                </Button>
                                <Button
                                  onClick={handleDeleteUser}
                                  className='mt-4 mx-1'
                                  variant='danger'
                                >
                                  Delete
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Tab>
                      </Tabs>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

          {!loading &&
            profileInfo.notification === '' &&
            profile &&
            profile[0].PRA_id === null && (
              <Row className='my-4 mx-1 pt-5'>
                <Col xl={12}>
                  <Card>
                    <Card.Body className='mt-2'>
                      <h5 className='my-3'>No Profile Information Yet ...</h5>
                      <h6 className='my-3'>
                        Register information and Verify your account
                      </h6>
                      <Button
                        onClick={() => history.push(`/create-user-profile`)}
                        variant='info'
                        className='my-2'
                      >
                        Add Information
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
        </Container>
      </Container>

      <Modal
        show={showProfession}
        onHide={ClearInfo}
        centered
        backdrop='static'
      >
        <Modal.Header>
          <Modal.Title>
            <FaInfoCircle className='text-info' /> {profileInfo.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {profileInfo.title === 'Edit Professional Info' ? (
            <Form onSubmit={handleSubmitProfession}>
              {/* Professional Name */}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Professional Name
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    type='text'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.ProfessionName}
                    name='ProfessionName'
                    required
                  />
                </Form.Group>
              </Row>

              {/* Professional No*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Professional No.
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    type='text'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.ProfessionNo}
                    name='ProfessionNo'
                    required
                  />
                </Form.Group>
              </Row>
              {/* Buttons */}
              <Row>
                <Col xl={12} className='d-flex justify-content-end my-2 px-3'>
                  <Button
                    className='mx-2'
                    variant='outline-danger'
                    onClick={ClearInfo}
                  >
                    Cancel
                  </Button>
                  <Button variant='outline-success' type='submit'>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : profileInfo.title === 'Edit Permanent Address' ? (
            <Form onSubmit={handleSubmitPermanent}>
              {/* No. / Street */}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  No. / Street
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    type='text'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.Permanent_NoStreet}
                    name={'Permanent_NoStreet'}
                    required
                  />
                </Form.Group>
              </Row>

              {/*Subd.*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Subd.
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    type='text'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.Permanent_Subd}
                    name={'Permanent_Subd'}
                    required
                  />
                </Form.Group>
              </Row>

              {/*Region*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Region
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    as='select'
                    style={{ background, border, borderRadius }}
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setProfileInfo({
                            ...profileInfo,
                            Permanent_Province: e.target.value,
                          })
                        : setProfileInfo({
                            ...profileInfo,
                            Permanent_Province: '',
                            Permanent_City: '',
                            Permanent_BrgyDistrict: '',
                            Permanentcities: [],
                            Permanentbarangays: [],
                          })

                      const region =
                        retrievedRegion &&
                        retrievedRegion.message &&
                        retrievedRegion.message.find(
                          (region) => region.regionName === e.target.value
                        )

                      axios({
                        method: 'GET',
                        url: `${process.env.REACT_APP_API_URL}/address`,
                        params: {
                          extensions: `/regions/${region.code}/cities/`,
                        },
                      }).then((res) => {
                        setAddress({
                          ...address,
                          permanentCities: res.data,
                        })
                      })

                      // axios
                      //   .get(`/api/regions/${region.code}/cities/`)
                      //   .then((res) => {
                      //     setAddress({
                      //       ...address,
                      //       permanentCities: res.data,
                      //     })
                      //   })
                    }}
                    value={profileInfo.Permanent_Province}
                    name={'Permanent_Province'}
                    required
                  >
                    <option value={profileInfo.Permanent_Province}>
                      {profileInfo.Permanent_Province}
                    </option>
                    {retrievedRegion &&
                      retrievedRegion.message &&
                      retrievedRegion.message.map((e, index) => (
                        <option key={index} value={e.regionName}>
                          {e.regionName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Row>

              {/*City/Municipality.*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  City/Municipality
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    as='select'
                    style={{ background, border, borderRadius }}
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setProfileInfo({
                            ...profileInfo,
                            Permanent_City: e.target.value,
                          })
                        : setProfileInfo({
                            ...profileInfo,
                            Permanent_City: e.target.value,
                            Permanent_BrgyDistrict: '',
                            Permanentbarangays: [],
                          })

                      const city =
                        address.permanentCities.length !== 0 &&
                        address.permanentCities.find(
                          (cities) => cities.name === e.target.value
                        )

                      axios({
                        method: 'GET',
                        url: `${process.env.REACT_APP_API_URL}/address`,
                        params: {
                          extensions: `/cities/${city.code}/barangays/`,
                        },
                      }).then((res) => {
                        setAddress({
                          ...address,
                          permanentBarangays: res.data,
                        })
                      })

                      // axios
                      //   .get(`/api/cities/${city.code}/barangays/`)
                      //   .then((res) => {
                      //     setAddress({
                      //       ...address,
                      //       permanentBarangays: res.data,
                      //     })
                      //   })
                    }}
                    value={profileInfo.Permanent_City}
                    name={'Permanent_City'}
                    required
                  >
                    {address.permanentCities.length === 0 && (
                      <option value={profileInfo.Permanent_City}>
                        {profileInfo.Permanent_City}
                      </option>
                    )}
                    {address.permanentCities.length !== 0 &&
                      address.permanentCities.map((element, index) => (
                        <option key={index} value={element.name}>
                          {element.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Row>

              {/*Brgy. / District*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Brgy. / District
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    as='select'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.Permanent_BrgyDistrict}
                    name={'Permanent_BrgyDistrict'}
                    required
                  >
                    {address.permanentBarangays.length === 0 && (
                      <option value={profileInfo.Permanent_BrgyDistrict}>
                        {profileInfo.Permanent_BrgyDistrict}
                      </option>
                    )}
                    {address.permanentBarangays.length !== 0 &&
                      address.permanentBarangays.map((element, index) => (
                        <option key={index} value={element.name}>
                          {element.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Row>

              {/*Country.*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Country
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    type='text'
                    style={{ background, border, borderRadius }}
                    disabled
                    // onChange={handleChange}
                    value={profileInfo.Permanent_Country}
                    name={'Permanent_Country'}
                    required
                  />
                </Form.Group>
              </Row>

              {/*Zipcode.*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Zipcode
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    type='text'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.Permanent_ZipCode}
                    name={'Permanent_ZipCode'}
                    required
                  />
                </Form.Group>
              </Row>

              {/* Buttons */}
              <Row>
                <Col xl={12} className='d-flex justify-content-end my-2 px-3'>
                  <Button variant='info' type='submit'>
                    Submit
                  </Button>
                  <Button className='mx-2' variant='danger' onClick={ClearInfo}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Form onSubmit={handleSubmitPresent}>
              <Row>
                <Col xl={8}>Same from permanent address</Col>
                <Form.Group
                  as={Col}
                  xl={4}
                  className='d-flex justify-content-end'
                >
                  <Form.Check
                    name='same'
                    type='checkbox'
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              {/* No. / Street */}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  No. / Street
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    type='text'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.Present_NoStreet}
                    name={'Present_NoStreet'}
                    required
                    disabled={profileInfo.disabled ? true : false}
                  />
                </Form.Group>
              </Row>

              {/*Subd.*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Subd.
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    type='text'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.Present_Subd}
                    name={'Present_Subd'}
                    required
                    disabled={profileInfo.disabled ? true : false}
                  />
                </Form.Group>
              </Row>

              {/*Region*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Region
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    as='select'
                    style={{ background, border, borderRadius }}
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setProfileInfo({
                            ...profileInfo,
                            Present_Province: e.target.value,
                          })
                        : setProfileInfo({
                            ...profileInfo,
                            Present_Province: '',
                            Present_City: '',
                            Present_BrgyDistrict: '',
                            cities: [],
                            barangays: [],
                          })
                      const region =
                        retrievedRegion &&
                        retrievedRegion.message &&
                        retrievedRegion.message.find(
                          (region) => region.regionName === e.target.value
                        )

                      axios({
                        method: 'GET',
                        url: `${process.env.REACT_APP_API_URL}/address`,
                        params: {
                          extensions: `/regions/${region.code}/cities/`,
                        },
                      }).then((res) => {
                        setAddress({
                          ...address,
                          presentCities: res.data,
                        })
                      })
                    }}
                    value={profileInfo.Present_Province}
                    name={'Present_Province'}
                    required
                    disabled={profileInfo.disabled ? true : false}
                  >
                    <option value={profileInfo.Present_Province}>
                      {profileInfo.Present_Province}
                    </option>
                    {retrievedRegion &&
                      retrievedRegion.message &&
                      retrievedRegion.message.map((e, index) => (
                        <option key={e.code} value={e.regionName}>
                          {e.regionName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Row>

              {/*City/Municipality.*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  City/Municipality
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    as='select'
                    style={{ background, border, borderRadius }}
                    onChange={(e) => {
                      e.target.value !== ''
                        ? setProfileInfo({
                            ...profileInfo,
                            Present_City: e.target.value,
                          })
                        : setProfileInfo({
                            ...profileInfo,
                            Present_City: e.target.value,
                            Present_BrgyDistrict: '',
                            barangays: [],
                          })

                      const city =
                        address.presentCities.length !== 0 &&
                        address.presentCities.find(
                          (cities) => cities.name === e.target.value
                        )

                      axios({
                        method: 'GET',
                        url: `${process.env.REACT_APP_API_URL}/address`,
                        params: {
                          extensions: `/cities/${city.code}/barangays/`,
                        },
                      }).then((res) => {
                        setAddress({
                          ...address,
                          presentBarangays: res.data,
                        })
                      })

                      // axios
                      //   .get(`/api/cities/${city.code}/barangays/`)
                      //   .then((res) => {
                      //     setAddress({
                      //       ...address,
                      //       presentBarangays: res.data,
                      //     })
                      //   })
                    }}
                    value={profileInfo.Present_City}
                    name={'Present_City'}
                    required
                    disabled={profileInfo.disabled ? true : false}
                  >
                    {address.presentCities.length === 0 && (
                      <option value={profileInfo.Present_City}>
                        {profileInfo.Present_City}
                      </option>
                    )}

                    {address.presentCities.length !== 0 &&
                      address.presentCities.map((element, index) => (
                        <option key={index} value={element.name}>
                          {element.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Row>

              {/*Brgy. / District*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Brgy. / District
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    as='select'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.Present_BrgyDistrict}
                    name={'Present_BrgyDistrict'}
                    required
                    disabled={profileInfo.disabled ? true : false}
                  >
                    {address.presentBarangays.length === 0 && (
                      <option value={profileInfo.Present_BrgyDistrict}>
                        {profileInfo.Present_BrgyDistrict}
                      </option>
                    )}
                    {address.presentBarangays.length !== 0 &&
                      address.presentBarangays.map((element, index) => (
                        <option key={index} value={element.name}>
                          {element.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Row>

              {/*Country.*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Country
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    type='text'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.Present_Country}
                    name={'Present_Country'}
                    required
                    disabled
                  />
                </Form.Group>
              </Row>

              {/*Zipcode.*/}
              <Row className='py-2 align-items-center text-nowrap'>
                <Col md={4} className='fw-bold'>
                  Zipcode
                </Col>
                <Form.Group as={Col} md={8}>
                  <Form.Control
                    type='text'
                    style={{ background, border, borderRadius }}
                    onChange={handleChange}
                    value={profileInfo.Present_ZipCode}
                    name={'Present_ZipCode'}
                    required
                    disabled={profileInfo.disabled ? true : false}
                  />
                </Form.Group>
              </Row>

              {/* Buttons */}
              <Row>
                <Col xl={12} className='d-flex justify-content-end my-2 px-3'>
                  <Button variant='info' type='submit'>
                    Submit
                  </Button>
                  <Button className='mx-2' variant='danger' onClick={ClearInfo}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      <SuccessModal
        show={success}
        onHide={() => {
          setSuccess(false)
        }}
        transLoading={message && message.loading}
        success={message && message.message}
        error={message && message.error}
      />

      <SuccessModal
        show={successCredentials}
        onHide={() => {
          setSuccessCredentials(false)
        }}
        transLoading={updatedUserCredentials && updatedUserCredentials.loading}
        success={updatedUserCredentials && updatedUserCredentials.message}
        error={updatedUserCredentials && updatedUserCredentials.error}
      />

      {/*Submit Inspector Success Modal */}
      <SuccessModal
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false)
          dispatch(Logout())
          history.push('/')
        }}
        transLoading={userDeleted && userDeleted.loading}
        success={userDeleted && userDeleted.message}
        error={userDeleted && userDeleted.error}
      />
      {/* End Success Modal */}
    </UserMain>
  )
}

ProfileScreen.defaultProps = {
  fontWeight: '600',
  background: '#FFF',
  border: '1px solid #ced4da',
  borderRadius: '5px',
}

export default withRouter(ProfileScreen)
