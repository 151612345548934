import {
  GET_ELECTRICAL_EVALUATION_DETAILS_REQ,
  GET_ELECTRICAL_EVALUATION_DETAILS_SUCCESS,
  GET_ELECTRICAL_EVALUATION_DETAILS_FAILED,
  CREATE_ELECTRICAL_EVALUATION_REQ,
  CREATE_ELECTRICAL_EVALUATION_SUCCESS,
  CREATE_ELECTRICAL_EVALUATION_FAILED,
  UPDATE_ELECTRICAL_EVALUATION_REQ,
  UPDATE_ELECTRICAL_EVALUATION_SUCCESS,
  UPDATE_ELECTRICAL_EVALUATION_FAILED,
} from '../constants/evaluatorElectricalConstants'

export const evaluatorElectricalReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ELECTRICAL_EVALUATION_DETAILS_REQ:
      return {
        ...state,
        evaluationElectricalDetails: { loading: true },
      }
    case GET_ELECTRICAL_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        evaluationElectricalDetails: {
          loading: false,
          message: action.payload,
        },
      }
    case GET_ELECTRICAL_EVALUATION_DETAILS_FAILED:
      return {
        ...state,
        evaluationElectricalDetails: { loading: false, error: action.payload },
      }
    case CREATE_ELECTRICAL_EVALUATION_REQ:
      return {
        ...state,
        createElectricalEvaluation: { loading: true },
      }
    case CREATE_ELECTRICAL_EVALUATION_SUCCESS:
      return {
        ...state,
        createElectricalEvaluation: { loading: false, message: action.payload },
      }
    case CREATE_ELECTRICAL_EVALUATION_FAILED:
      return {
        ...state,
        createElectricalEvaluation: { loading: false, error: action.payload },
      }
    case UPDATE_ELECTRICAL_EVALUATION_REQ:
      return {
        ...state,
        updateElectricalEvaluation: { loading: true },
      }
    case UPDATE_ELECTRICAL_EVALUATION_SUCCESS:
      return {
        ...state,
        updateElectricalEvaluation: { loading: false, message: action.payload },
      }
    case UPDATE_ELECTRICAL_EVALUATION_FAILED:
      return {
        ...state,
        updateElectricalEvaluation: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
