import {
  CREATE_CERTOCCUPANCY_ASSESSMENT_REQ,
  CREATE_CERTOCCUPANCY_ASSESSMENT_SUCCESS,
  CREATE_CERTOCCUPANCY_ASSESSMENT_FAILED,
  GET_CERTOCCUPANCY_ASSESSMENT_REQ,
  GET_CERTOCCUPANCY_ASSESSMENT_SUCCESS,
  GET_CERTOCCUPANCY_ASSESSMENT_FAILED,
  UPDATE_CERTOCCUPANCY_ASSESSMENT_REQ,
  UPDATE_CERTOCCUPANCY_ASSESSMENT_SUCCESS,
  UPDATE_CERTOCCUPANCY_ASSESSMENT_FAILED,
} from '../../constants/Assessment/certOccupancyAssessmentConstants'

export const certOccupancyAssessmentReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CERTOCCUPANCY_ASSESSMENT_REQ:
      return {
        ...state,
        createAssessment: { loading: true },
      }

    case CREATE_CERTOCCUPANCY_ASSESSMENT_SUCCESS:
      return {
        ...state,
        createAssessment: { loading: false, message: action.payload },
      }

    case CREATE_CERTOCCUPANCY_ASSESSMENT_FAILED:
      return {
        ...state,
        createAssessment: { loading: false, error: action.payload },
      }
    case UPDATE_CERTOCCUPANCY_ASSESSMENT_REQ:
      return {
        ...state,
        updateAssessment: { loading: true },
      }

    case UPDATE_CERTOCCUPANCY_ASSESSMENT_SUCCESS:
      return {
        ...state,
        updateAssessment: { loading: false, message: action.payload },
      }

    case UPDATE_CERTOCCUPANCY_ASSESSMENT_FAILED:
      return {
        ...state,
        updateAssessment: { loading: false, error: action.payload },
      }
    case GET_CERTOCCUPANCY_ASSESSMENT_REQ:
      return {
        ...state,
        assessmentDetails: { loading: true },
      }

    case GET_CERTOCCUPANCY_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessmentDetails: { loading: false, message: action.payload },
      }

    case GET_CERTOCCUPANCY_ASSESSMENT_FAILED:
      return {
        ...state,
        assessmentDetails: { loading: false, error: action.payload },
      }
    default:
      return state
  }
}
