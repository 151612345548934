import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaCheckCircle, FaPencilAlt, FaTrashAlt } from 'react-icons/fa'
import { PDFDownloadLink } from '@react-pdf/renderer'

import EvaluationSheet from '../reusable/EvaluationSheet'

// Layout
import Loading from './Loading'

const EvaluationSheetScreen = ({
  show,
  onHide,
  retrievedEvaluationSheet,
  userLevel,
  appID,
  openAddEdit,
  handleEditEvaluationSheet,
  handleDeleteEvaluationSheet,
}) => {
  const evaluatorHeader = [
    ['RECEIVING', 'document controller'],
    ['LINE AND GRADE / ARCHITECTURAL', 'Architectural'],
    ['STRUCTURAL', 'Structural'],
    ['SANITARY/PLUMBING', 'Plumbing'],
    ['ELECTRICAL', 'Electrical'],
    ['MECHANICAL', 'Mechanical'],
    ['ELECTRONICS', 'Electronics'],
  ]

  const renderEvaluationSheet = evaluatorHeader.map((e, index) => {
    if (retrievedEvaluationSheet && retrievedEvaluationSheet.message) {
      const match = retrievedEvaluationSheet.message.find(
        (x) => x.trades === e[1]
      )

      // console.log(match)
      return (
        <div key={index}>
          <div className='d-flex justify-content-between'>
            <h6>{e[0]}</h6>
            {userLevel === e[1] && (
              <div className='mx-2'>
                <Button
                  className='mx-1'
                  variant='info'
                  size='sm'
                  onClick={() => {
                    openAddEdit()
                    handleEditEvaluationSheet(e, match)
                  }}
                >
                  <FaPencilAlt />
                </Button>
                {match !== undefined && (
                  <Button
                    size='sm'
                    variant='danger'
                    className='mx-2'
                    onClick={() => {
                      handleDeleteEvaluationSheet(match)
                    }}
                  >
                    <FaTrashAlt />
                  </Button>
                )}
              </div>
            )}
          </div>

          <p className='mb-0 mx-2'>{match && match.remarks}</p>
          <hr className='my-3' />
        </div>
      )
    } else {
      return (
        <div key={index}>
          <div className='d-flex justify-content-between'>
            <h6>{e[0]}</h6>
            {userLevel === e[1] && (
              <Button
                className='mx-1'
                variant='info'
                size='sm'
                onClick={() => {
                  openAddEdit()
                  handleEditEvaluationSheet(e)
                }}
              >
                <FaPencilAlt />
              </Button>
            )}
          </div>
          <p className='mb-0 mx-2'></p>
          <hr className='my-1' />
        </div>
      )
    }
  })
  // End Function

  return (
    <Modal size='lg' show={show} onHide={onHide} centered backdrop='static'>
      <Modal.Header style={{ background: '#4bbf73' }}>
        <Modal.Title style={{ color: '#FFF' }}>
          <FaCheckCircle className='mx-3' /> View Evaluation Sheet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {retrievedEvaluationSheet && !retrievedEvaluationSheet.loading ? (
          renderEvaluationSheet
        ) : (
          <Loading />
        )}
      </Modal.Body>
      <Modal.Footer>
        {retrievedEvaluationSheet && !retrievedEvaluationSheet.loading && (
          <PDFDownloadLink
            document={
              <EvaluationSheet
                info={
                  retrievedEvaluationSheet && retrievedEvaluationSheet.message
                    ? retrievedEvaluationSheet.message
                    : ''
                }
              />
            }
            fileName='EvaluationSheet.pdf'
          >
            {({ loading }) =>
              loading ? (
                'Loading document...'
              ) : (
                <Button variant='info' size='sm'>
                  PRINT
                </Button>
              )
            }
          </PDFDownloadLink>
        )}
        <Button variant='danger' size='sm' onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EvaluationSheetScreen
