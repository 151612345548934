import React, { useState, useEffect } from 'react'
import { Button, Form, Modal, Row, Col } from 'react-bootstrap'
import { FaCheckCircle, FaPencilAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { updateEvaluators } from '../redux/actions/DocumentControllers/documentControllerActions'

// Layout
import SuccessModal from './SuccessModal'
import Loading from './Loading'

const AppEvaluators = (props) => {
  const dispatch = useDispatch()

  const [editEvaluator, setEditEvaluator] = useState({
    structural: false,
    mechanical: false,
    architectural: false,
    sanitary: false,
    electrical: false,
    plumbing: false,
    electronics: false,
    isEditting: false,
  })

  const [evalUpdateValue, setEvalUpdateValue] = useState([])

  const [tradesEvaluators, setTradesEvaluators] = useState([
    ['Structural', 0, ''],
    ['Mechanical', 0, ''],
    ['Architectural', 0, ''],
    ['Sanitary', 0, ''],
    ['Electrical', 0, ''],
    ['Plumbing', 0, ''],
    ['Electronics', 0, ''],
  ])

  const [modal, setModal] = useState({
    success: false,
  })

  const user = useSelector((state) => state.users)

  const { userCredentials } = user

  const dc = useSelector((state) => state.documentControllerReducer)

  const { evaluatorList, submittedEvaluatorList, updatedEvaluators } = dc

  //   Functions
  const handleEvaluatorChange = (e) => {
    let tradeIndex = tradesEvaluators.findIndex((el) => el[0] === e.target.name)
    let evalIndex = evalUpdateValue.findIndex((el) => el[1] === e.target.name)
    let selectedIndex =
      evaluatorList &&
      !evaluatorList.loading &&
      evaluatorList.message.find(
        (employee) => employee.EmployeeID === parseInt(e.target.value)
      )
    if (submittedEvaluatorList && submittedEvaluatorList.message) {
      let response = submittedEvaluatorList.message
      let index = response.findIndex((el) => el.trade === e.target.name)
      let sample =
        evaluatorList &&
        !evaluatorList.loading &&
        evaluatorList.message.find(
          (employee) => employee.EmployeeID === parseInt(e.target.value)
        )

      if (evalIndex < 0) {
        setEvalUpdateValue([
          ...evalUpdateValue,
          [
            response[index].id,
            e.target.name,
            parseInt(e.target.value),
            sample.EmailAddress,
          ],
        ])
      } else {
        if (evalUpdateValue[evalIndex][0] === response[index].id) {
          let response3 = evalUpdateValue
          response3[evalIndex][2] = parseInt(e.target.value)
          response3[evalIndex][3] =
            selectedIndex.EmailAddress !== undefined
              ? selectedIndex.EmailAddress
              : ''
          setEvalUpdateValue([...response3])
        }
      }

      let response1 = tradesEvaluators
      response1[tradeIndex][1] = parseInt(e.target.value)
      response1[tradeIndex][2] =
        selectedIndex.EmailAddress !== undefined
          ? selectedIndex.EmailAddress
          : ''
      setTradesEvaluators([...response1])
      // console.log('edit evaluator')
    } else {
      let response = tradesEvaluators
      response[tradeIndex][1] = parseInt(e.target.value)
      response[tradeIndex][2] =
        selectedIndex.EmailAddress !== undefined
          ? selectedIndex.EmailAddress
          : ''
      setTradesEvaluators([...response])
    }
  }

  const handleOnSubmit = (e) => {
    if (window.confirm('Are you sure you want to submit information?')) {
      e.preventDefault()
      let trades = tradesEvaluators.filter((el) => el[1] > 0)
      dispatch(
        updateEvaluators({
          dcID: userCredentials.id,
          id: props.payload.id,
          tradesEvaluators: trades,
          isEditting: false,
          updateValues: [],
          // updateValues: evalUpdateValue,
        })
      )
      setModal({ success: true })
      props.onClose()
      // dispatch(retrieveSubmittedEvaluators({ id: match.params.id }))
      //   setModal({ evaluators: true })
      //   setShowEvaluator(false)
    }
  }
  // End Functions

  //   useEffect
  useEffect(() => {
    if (submittedEvaluatorList && submittedEvaluatorList.message) {
      submittedEvaluatorList.message.forEach((evaluator) => {
        const response = tradesEvaluators
        let index = response.findIndex((el) => el[0] === evaluator.trade)
        response[index][1] = evaluator.evaluatorID

        setTradesEvaluators(response)
        setEditEvaluator({
          structural: true,
          mechanical: true,
          architectural: true,
          sanitary: true,
          electrical: true,
          plumbing: true,
          electronics: true,
        })
      })
    }
  }, [submittedEvaluatorList])
  // End useEffect
  // console.log(evalUpdateValue)
  return (
    <>
      <Modal
        size='md'
        show={props.show}
        onHide={props.onHide}
        centered
        backdrop='static'
      >
        <Modal.Header style={{ background: '#28a745', color: '#FFF' }}>
          <Modal.Title style={{ color: '#FFF' }}>
            <FaCheckCircle className='mx-1' />
            Application Evaluator/s
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleOnSubmit}>
          {evaluatorList && evaluatorList.message && (
            <Modal.Body>
              {props.payload.Structural === 1 && (
                <Form.Group className='my-1'>
                  <Form.Label>
                    STRUCTURAL
                    <Button
                      size='sm'
                      variant='outline'
                      className='mx-3'
                      onClick={() =>
                        setEditEvaluator({
                          isEditting: true,
                          structural: false,
                          mechanical: true,
                          architectural: true,
                          sanitary: true,
                          electrical: true,
                          plumbing: true,
                          electronics: true,
                        })
                      }
                      disabled={
                        submittedEvaluatorList &&
                        submittedEvaluatorList.error &&
                        true
                      }
                    >
                      <FaPencilAlt className='text-success' />
                    </Button>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    name='Structural'
                    required
                    value={tradesEvaluators[0][1]}
                    onChange={(e) => {
                      handleEvaluatorChange(e)
                    }}
                    disabled={editEvaluator.structural}
                  >
                    <option value=''>Choose evaluator here ...</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Structural' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>

                  {submittedEvaluatorList &&
                    submittedEvaluatorList.message &&
                    editEvaluator.structural === false && (
                      <div className='text-center my-2'>
                        <Button
                          variant='info'
                          size='sm'
                          className='mx-2'
                          onClick={() => {
                            if (
                              window.confirm(
                                'Are you sure you want to update status of application?'
                              )
                            ) {
                              dispatch(
                                updateEvaluators({
                                  dcID: userCredentials.id,
                                  id: props.payload.id,
                                  tradesEvaluators: '',
                                  isEditting: editEvaluator.isEditting,
                                  updateValues: evalUpdateValue,
                                })
                              )
                              setEditEvaluator({
                                ...editEvaluator,
                                structural: true,
                              })
                              props.onClose()
                              setModal({ success: true })
                            }
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant='danger'
                          size='sm'
                          onClick={() =>
                            setEditEvaluator({
                              ...editEvaluator,
                              structural: true,
                            })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                </Form.Group>
              )}

              {props.payload.Architectural === 1 && (
                <Form.Group className='my-1'>
                  <Form.Label>
                    ARCHITECTURAL
                    <Button
                      size='sm'
                      variant='outline'
                      className='mx-3'
                      onClick={() =>
                        setEditEvaluator({
                          isEditting: true,
                          architectural: false,
                          structural: true,
                          mechanical: true,
                          sanitary: true,
                          electrical: true,
                          plumbing: true,
                          electronics: true,
                        })
                      }
                      disabled={
                        submittedEvaluatorList &&
                        submittedEvaluatorList.error &&
                        true
                      }
                    >
                      <FaPencilAlt className='text-success' />
                    </Button>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    name='Architectural'
                    required
                    value={tradesEvaluators[2][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.architectural}
                  >
                    <option value=''>Choose evaluator here ...</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Architectural' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                  {submittedEvaluatorList &&
                    submittedEvaluatorList.message &&
                    editEvaluator.architectural === false && (
                      <div className='text-center my-2'>
                        <Button
                          variant='info'
                          size='sm'
                          className='mx-2'
                          onClick={() => {
                            if (
                              window.confirm(
                                'Are you sure you want to update status of application?'
                              )
                            ) {
                              dispatch(
                                updateEvaluators({
                                  dcID: userCredentials.id,
                                  id: props.payload.id,
                                  tradesEvaluators: '',
                                  isEditting: editEvaluator.isEditting,
                                  updateValues: evalUpdateValue,
                                })
                              )
                              setEditEvaluator({
                                ...editEvaluator,
                                architectural: true,
                              })
                              props.onClose()
                              setModal({ success: true })
                            }
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant='danger'
                          size='sm'
                          onClick={() =>
                            setEditEvaluator({
                              ...editEvaluator,
                              architectural: true,
                            })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                </Form.Group>
              )}

              {props.payload.Mechanical === 1 && (
                <Form.Group className='my-1'>
                  <Form.Label>
                    MECHANICAL
                    <Button
                      size='sm'
                      variant='outline'
                      className='mx-3'
                      onClick={() =>
                        setEditEvaluator({
                          isEditting: true,
                          mechanical: false,
                          structural: true,
                          architectural: true,
                          sanitary: true,
                          electrical: true,
                          plumbing: true,
                          electronics: true,
                        })
                      }
                      disabled={
                        submittedEvaluatorList &&
                        submittedEvaluatorList.error &&
                        true
                      }
                    >
                      <FaPencilAlt className='text-success' />
                    </Button>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    name='Mechanical'
                    required
                    value={tradesEvaluators[1][1]}
                    onChange={(e) => {
                      handleEvaluatorChange(e)
                    }}
                    disabled={editEvaluator.mechanical}
                  >
                    <option value=''>Choose evaluator here ...</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Mechanical' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                  {submittedEvaluatorList &&
                    submittedEvaluatorList.message &&
                    editEvaluator.mechanical === false && (
                      <div className='text-center my-2'>
                        <Button
                          variant='info'
                          size='sm'
                          className='mx-2'
                          onClick={() => {
                            if (
                              window.confirm(
                                'Are you sure you want to update status of application?'
                              )
                            ) {
                              dispatch(
                                updateEvaluators({
                                  dcID: userCredentials.id,
                                  id: props.payload.id,
                                  tradesEvaluators: '',
                                  isEditting: editEvaluator.isEditting,
                                  updateValues: evalUpdateValue,
                                })
                              )
                              setEditEvaluator({
                                ...editEvaluator,
                                mechanical: true,
                              })
                              props.onClose()
                              setModal({ success: true })
                            }
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant='danger'
                          size='sm'
                          onClick={() =>
                            setEditEvaluator({
                              ...editEvaluator,
                              mechanical: true,
                            })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                </Form.Group>
              )}

              {props.payload.Electrical === 1 && (
                <Form.Group className='my-1'>
                  <Form.Label>
                    ELECTRICAL
                    <Button
                      size='sm'
                      variant='outline'
                      className='mx-3'
                      onClick={() =>
                        setEditEvaluator({
                          isEditting: true,
                          mechanical: true,
                          structural: true,
                          architectural: true,
                          sanitary: true,
                          electrical: false,
                          plumbing: true,
                          electronics: true,
                        })
                      }
                      disabled={
                        submittedEvaluatorList &&
                        submittedEvaluatorList.error &&
                        true
                      }
                    >
                      <FaPencilAlt className='text-success' />
                    </Button>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    name='Electrical'
                    required
                    value={tradesEvaluators[4][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.electrical}
                  >
                    <option value=''>Choose evaluator here ...</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Electrical' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                  {submittedEvaluatorList &&
                    submittedEvaluatorList.message &&
                    editEvaluator.electrical === false && (
                      <div className='d-flex justify-content-center my-2'>
                        <Button
                          variant='info'
                          size='sm'
                          className='mx-2'
                          onClick={() => {
                            if (
                              window.confirm(
                                'Are you sure you want to update status of application?'
                              )
                            ) {
                              dispatch(
                                updateEvaluators({
                                  dcID: userCredentials.id,
                                  id: props.payload.id,
                                  tradesEvaluators: '',
                                  isEditting: editEvaluator.isEditting,
                                  updateValues: evalUpdateValue,
                                })
                              )
                              setEditEvaluator({
                                ...editEvaluator,
                                electrical: true,
                              })
                              props.onClose()
                              setModal({ success: true })
                            }
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant='danger'
                          size='sm'
                          onClick={() =>
                            setEditEvaluator({
                              ...editEvaluator,
                              electrical: true,
                            })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                </Form.Group>
              )}

              {props.payload.Plumbing === 1 && (
                <Form.Group className='my-1'>
                  <Form.Label>
                    PLUMBING
                    <Button
                      size='sm'
                      variant='outline'
                      className='mx-3'
                      onClick={() =>
                        setEditEvaluator({
                          isEditting: true,
                          plumbing: false,
                          structural: true,
                          mechanical: true,
                          architectural: true,
                          sanitary: true,
                          electrical: true,
                          electronics: true,
                        })
                      }
                      disabled={
                        submittedEvaluatorList &&
                        submittedEvaluatorList.error &&
                        true
                      }
                    >
                      <FaPencilAlt className='text-success' />
                    </Button>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    name='Plumbing'
                    required
                    value={tradesEvaluators[5][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.plumbing}
                  >
                    <option value=''>Choose evaluator here ...</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Plumbing' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                  {submittedEvaluatorList &&
                    submittedEvaluatorList.message &&
                    editEvaluator.plumbing === false && (
                      <div className='text-center my-2'>
                        <Button
                          variant='info'
                          size='sm'
                          className='mx-2'
                          onClick={() => {
                            if (
                              window.confirm(
                                'Are you sure you want to update status of application?'
                              )
                            ) {
                              dispatch(
                                updateEvaluators({
                                  dcID: userCredentials.id,
                                  id: props.payload.id,
                                  tradesEvaluators: '',
                                  isEditting: editEvaluator.isEditting,
                                  updateValues: evalUpdateValue,
                                })
                              )
                              setEditEvaluator({
                                ...editEvaluator,
                                plumbing: true,
                              })
                              props.onClose()
                              setModal({ success: true })
                            }
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant='danger'
                          size='sm'
                          onClick={() =>
                            setEditEvaluator({
                              ...editEvaluator,
                              plumbing: true,
                            })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                </Form.Group>
              )}

              {props.payload.Electronics === 1 && (
                <Form.Group className='my-1'>
                  <Form.Label>
                    ELECTRONICS
                    <Button
                      size='sm'
                      variant='outline'
                      className='mx-3'
                      onClick={() =>
                        setEditEvaluator({
                          isEditting: true,
                          plumbing: true,
                          structural: true,
                          mechanical: true,
                          architectural: true,
                          sanitary: true,
                          electrical: true,
                          electronics: false,
                        })
                      }
                      disabled={
                        submittedEvaluatorList &&
                        submittedEvaluatorList.error &&
                        true
                      }
                    >
                      <FaPencilAlt className='text-success' />
                    </Button>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    name='Electronics'
                    required
                    value={tradesEvaluators[6][1]}
                    onChange={(e) => handleEvaluatorChange(e)}
                    disabled={editEvaluator.electronics}
                  >
                    <option value=''>Choose evaluator here ...</option>
                    {evaluatorList &&
                      evaluatorList.message &&
                      evaluatorList.message.map(
                        (x) =>
                          x.subroles === 'Electronics' && (
                            <option value={x.EmployeeID} key={x.EmployeeID}>
                              {x.EmployeeName}
                            </option>
                          )
                      )}
                  </Form.Control>
                  {submittedEvaluatorList &&
                    submittedEvaluatorList.message &&
                    editEvaluator.electronics === false && (
                      <div className='text-center my-2'>
                        <Button
                          variant='info'
                          size='sm'
                          className='mx-2'
                          onClick={() => {
                            if (
                              window.confirm(
                                'Are you sure you want to update status of application?'
                              )
                            ) {
                              dispatch(
                                updateEvaluators({
                                  dcID: userCredentials.id,
                                  id: props.payload.id,
                                  tradesEvaluators: '',
                                  isEditting: editEvaluator.isEditting,
                                  updateValues: evalUpdateValue,
                                })
                              )
                              setEditEvaluator({
                                ...editEvaluator,
                                electronics: true,
                              })
                              props.onClose()
                              setModal({ success: true })
                            }
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant='danger'
                          size='sm'
                          onClick={() =>
                            setEditEvaluator({
                              ...editEvaluator,
                              electronics: true,
                            })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                </Form.Group>
              )}
            </Modal.Body>
          )}
          {evaluatorList && evaluatorList.loading && (
            <Modal.Body>
              <Loading />
            </Modal.Body>
          )}

          <Modal.Footer>
            {submittedEvaluatorList && submittedEvaluatorList.error && (
              <Button variant='info' size='sm' type='submit'>
                Submit
              </Button>
            )}
            <Button variant='danger' size='sm' onClick={props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/*Success Modal */}
      <SuccessModal
        show={modal.success}
        onHide={() => {
          props.onHide()
          setModal({ success: false })
        }}
        transLoading={updatedEvaluators && updatedEvaluators.loading}
        success={updatedEvaluators && updatedEvaluators.message}
        error={updatedEvaluators && updatedEvaluators.error}
      />
      {/* End Success Modal */}
    </>
  )
}

export default AppEvaluators
